import { useSelector } from 'react-redux'
import { appSelectors } from 'duck/AppDuck'
import { RootState } from 'duck'
import { MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import { useBooleanDelayedData } from 'utils/Hooks'
import loadable from 'utils/Loadable'

const DialogUpscaleDownload = loadable(
  () => import(/* webpackChunkName: "DialogUpscaleDownload" */ './index')
)

const DialogUpscaleDownloadContainer = () => {
  const activeDialog = useSelector((state: RootState) =>
    appSelectors.dialog.activeDialogOverlay(state)
  )

  const showDialog = useBooleanDelayedData(activeDialog === MonetizationDialog.IMAGE_DOWNLOAD)

  if (!showDialog) return null

  return <DialogUpscaleDownload />
}

export default DialogUpscaleDownloadContainer
