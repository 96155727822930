import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const DownloadIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.9844 9.65625L16.6094 7.07813L18.0156 8.48438L13 13.5L7.98437 8.48438L9.39063 7.07813L12.0156 9.65625L12.0156 9.63017e-07L13.9844 1.13513e-06L13.9844 9.65625ZM19.9844 9L22 9L22 15.9844C22 16.5156 21.7969 16.9844 21.3906 17.3906C20.9844 17.7969 20.5156 18 19.9844 18L6.01562 18C5.48437 18 5.01562 17.7969 4.60937 17.3906C4.20312 16.9844 4 16.5156 4 15.9844L4 9L6.01562 9L6.01562 15.9844L19.9844 15.9844L19.9844 9Z" />
    </SvgIcon>
  )
}

export default DownloadIcon
