import {
  catchError,
  concatMap,
  filter,
  switchMap,
  mergeMap,
  takeUntil,
  delay,
  exhaustMap,
  map,
  withLatestFrom
} from 'rxjs/operators'

import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf } from 'typesafe-actions'
import { of, merge, from } from 'rxjs'
import { apiActions, sharedActions } from 'duck/ApiDuck'
import { apiSelectors } from '../selectors'

const retrieveUpscaleImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.retrieveUpscaleImage)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: `${payload}`, type })),
        ApiUtils.imageEnhancement.retrieveUpscaleImage(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.retrieveUpscaleImageResponse({
              data: response.data,
              req: payload
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveUserImageUpscalesEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.retrieveUserImageUpscales)),
    map(({ type, payload }) => ({
      param: {
        limit: 20,
        offset: 0,
        image: payload.userImageId
      },
      type,
      payload
    })),
    mergeMap(({ type, param, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.imageEnhancement.listUpscaleImage(param).pipe(
          map(response => {
            type ResponseType = ActionType<
              typeof apiActions.imageEnhancement.retrieveUserImageUpscalesResponse
            >['payload']

            const shouldDeleted: number[] = []
            const shouldFetched: number[] = []

            const upscaleImages: ResponseType['upscaleImages'] = []
            const userImageUpscales: ResponseType['userImageUpscales'] = {
              bicubic: undefined,
              drn: undefined,
              waifu2x: undefined,
              enh: undefined
            }

            const results = response.data.results ?? []

            results.forEach(upscaleImage => {
              if (!userImageUpscales[upscaleImage.method]) {
                userImageUpscales[upscaleImage.method] = upscaleImage.id
                upscaleImages.push(upscaleImage)
                shouldFetched.push(upscaleImage.id)
              } else {
                shouldDeleted.push(upscaleImage.id)
              }
            })

            return { userImageUpscales, shouldDeleted, upscaleImages, shouldFetched }
          }),
          concatMap(({ upscaleImages, userImageUpscales, shouldDeleted, shouldFetched }) => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.retrieveUserImageUpscalesResponse({
              userImageUpscales,
              upscaleImages,
              userImageId: param.image
            }),
            ...shouldDeleted.map(id =>
              apiActions.imageEnhancement.deleteUpscaleImage({ upscaleImageId: id })
            ),
            ...shouldFetched.map(id => apiActions.imageEnhancement.retrieveUpscaleImage(id))
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const deleteUpscaleImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.deleteUpscaleImage)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.imageEnhancement.deleteUpscaleImage(payload.upscaleImageId).pipe(
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.deleteUpscaleImageResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const createUpscaleImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.createUpscaleImage)),
    map(({ type, payload }) => {
      const formData = new FormData()

      if (payload.image_file) {
        formData.append('image_file', payload.image_file)
      }
      if (payload.image) {
        formData.append('image', `${payload.image}`)
      }
      formData.append('scale', `${payload.scale}`)
      formData.append('controls', JSON.stringify(payload.controls))
      formData.append('method', `${payload.method}`)

      return {
        type,
        payload,
        formData
      }
    }),
    switchMap(({ type, formData, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: 'Upscaling Image', type })),
        ApiUtils.imageEnhancement.createUpscaleImage(formData).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.createUpscaleImageResponse({
              data: response.data,
              req: payload
            })
          ]),
          takeUntil(
            action$.pipe(
              filter(isActionOf(sharedActions.cancelRequest)),
              filter(
                ({ payload }) => payload === getType(apiActions.imageEnhancement.createUpscaleImage)
              )
            )
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listUpscaleImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.listUpscaleImage)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, silentUpdate } = action.payload
      const nextData = state.api.imageEnhancement.upscaleImage.lastReq?.next

      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      return { next, params, extraParams, type: action.type, silentUpdate, param }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    delay(300),
    exhaustMap(({ next, params, extraParams, type, silentUpdate, param }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.imageEnhancement.listUpscaleImage(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.listUpscaleImageResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const createAdjustedImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.createAdjustedImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        from(ApiUtils.imageEnhancement.createAdjustedImage(payload)).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.createAdjustedImageResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const connectAdjustedImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.connectAdjustedImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        from(ApiUtils.imageEnhancement.connectAdjustedImage(payload.id, payload)).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.connectAdjustedImageResponse({
              req: payload,
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const retrieveAdjustedImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.retrieveAdjustedImage)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: `${payload.adjustedImageId}`, type })),
        from(ApiUtils.imageEnhancement.retrieveAdjustedImage(payload.adjustedImageId)).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.retrieveAdjustedImageResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const deleteAdjustedImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.deleteAdjustedImage)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        from(ApiUtils.imageEnhancement.deleteAdjustedImage(payload.adjustedImageId)).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.imageEnhancement.deleteAdjustedImageResponse({
              adjustedImageId: payload.adjustedImageId,
              userImageId: payload.userImageId
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const checkOnUserImagesAdjustEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.imageEnhancement.checkOnUserImagesAdjust)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      images: action.payload.userImages,
      image: action.payload.userImage,
      adjustedImagesData: apiSelectors.adjustedImages(state)
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ images }) => !!images?.length),
          map(({ images, adjustedImagesData }) =>
            images
              ?.filter(
                value => Boolean(value.adjust) && !Boolean(adjustedImagesData[value.adjust ?? 0])
              )
              .map(value => value.adjust)
          ),
          mergeMap(
            unretrievedImageAdjust =>
              unretrievedImageAdjust?.map(adjustedImageId =>
                apiActions.imageEnhancement.retrieveAdjustedImage({
                  adjustedImageId: adjustedImageId ?? 0
                })
              ) || []
          )
        ),
        of(param).pipe(
          filter(({ image }) => !!image),
          filter(
            ({ image, adjustedImagesData }) =>
              !!image?.adjust && !adjustedImagesData[image?.adjust ?? 0]
          ),
          map(({ image }) =>
            apiActions.imageEnhancement.retrieveAdjustedImage({
              adjustedImageId: image?.adjust ?? 0
            })
          )
        )
      )
    )
  )

const imageEnhancementEpics = [
  checkOnUserImagesAdjustEpic,
  retrieveUserImageUpscalesEpic,
  listUpscaleImageEpic,
  retrieveUpscaleImageEpic,
  deleteUpscaleImageEpic,
  createUpscaleImageEpic,
  createAdjustedImageEpic,
  deleteAdjustedImageEpic,
  connectAdjustedImageEpic,
  retrieveAdjustedImageEpic
]

export default imageEnhancementEpics
