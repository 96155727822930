import React, { useCallback, useEffect, useState } from 'react'
import _debounce from 'lodash/debounce'
import { brainTreePaymentActions, brainTreePaymentSelector } from '../../../duck'
import clsx from 'clsx'
import braintree from 'braintree-web'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ReduxProps } from 'utils/Types'
import { RootState, RootActionType } from 'duck'
import CardForm from './CardForm'
import { CircularLoading } from 'components/Loading'
import SentryUtils from 'utils/SentryUtils'
import { CardPanelProps } from '..'

export type CardType =
  | `american-express`
  | `diners-club`
  | `discover`
  | `jcb`
  | `maestro`
  | `master-card`
  | `unionpay`
  | `visa`

export const FORM_CONFIG = {
  CARD_NUMBER: {
    id: 'card-number',
    placeholder: 'Card number'
  },
  CVV: {
    id: 'cvv',
    placeholder: 'CVV'
  },
  EXPIRE: {
    id: 'expiration-date',
    placeholder: 'MM/YYYY'
  },
  POSTAL_CODE: {
    id: 'postal-code',
    placeholder: 'Postal Code'
  }
}

const mapStateToProps = (state: RootState) => ({
  brainTreePayment: brainTreePaymentSelector.brainTreePayment(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      initBrainTree: brainTreePaymentActions.initBrainTree,
      getNonceResponse: brainTreePaymentActions.getNonceResponse,
      setCardError: brainTreePaymentActions.setCardError,
      setHasEmpty: brainTreePaymentActions.setHasEmpty
    },
    dispatch
  )
export type DialogTrainingSetupProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
> &
  Pick<CardPanelProps, 'className' | 'hide' | 'hideErrorMessage'>

const BraintreeHostedField: React.FC<DialogTrainingSetupProps> = props => {
  const {
    brainTreePayment,
    className,
    hide,
    hideErrorMessage,
    initBrainTree,
    setCardError,
    setHasEmpty,
    getNonceResponse
  } = props
  const { cardError, clientInstance, shouldGetNonce } = brainTreePayment

  const [cardType, setCardType] = useState<CardType | undefined>()
  const [hostedFieldInstance, setHostedFieldInstance] = useState<
    braintree.HostedFields | undefined
  >()

  const loading = !Boolean(clientInstance) || !Boolean(hostedFieldInstance)
  const [focusState, setFocusState] = useState(false)

  useEffect(() => {
    initBrainTree()
  }, [initBrainTree])

  useEffect(() => {
    if (clientInstance && !hostedFieldInstance) {
      braintree.hostedFields
        .create({
          client: clientInstance,
          styles: {
            input: {
              'font-size': '14px',
              'font-family':
                'Space Grotesk, system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
              color: '#fff'
            },
            '.cvv': {
              'text-align': 'right'
            },
            '.expirationDate': {
              'text-align': 'right'
            },
            '.postalCode': {
              'text-align': 'right'
            }
          },
          fields: {
            number: {
              selector: `#${FORM_CONFIG.CARD_NUMBER.id}`,
              placeholder: FORM_CONFIG.CARD_NUMBER.placeholder
            },
            cvv: {
              selector: `#${FORM_CONFIG.CVV.id}`,
              placeholder: FORM_CONFIG.CVV.placeholder
            },
            expirationDate: {
              selector: `#${FORM_CONFIG.EXPIRE.id}`,
              placeholder: FORM_CONFIG.EXPIRE.placeholder
            },
            postalCode: {
              selector: `#${FORM_CONFIG.POSTAL_CODE.id}`,
              placeholder: FORM_CONFIG.POSTAL_CODE.placeholder
            }
          }
        })
        .then(hostedFieldsInstance => {
          setHostedFieldInstance(hostedFieldsInstance)
        })
        .catch(err => {
          SentryUtils.captureMessage(`Failed To Instantiate Hosted Field Instance`, err, 'error')
          console.error(err)
        })
    }
  }, [clientInstance, hostedFieldInstance, setHostedFieldInstance])

  useEffect(() => {
    if (hostedFieldInstance) {
      hostedFieldInstance?.on('cardTypeChange', hostedFieldEvent => {
        const type = !hostedFieldEvent.fields.number.isEmpty
          ? (hostedFieldEvent.cards?.[0]?.type as CardType)
          : undefined

        setCardType(type)

        /* Reconfigure CVV label / placeholder */

        const cvvText =
          hostedFieldEvent.cards.length === 1
            ? hostedFieldEvent.cards[0].code.name
            : FORM_CONFIG.CVV.placeholder

        hostedFieldInstance?.setPlaceholder('cvv', cvvText)
      })

      hostedFieldInstance.on('empty', hostedFieldEvent => {
        const number = hostedFieldEvent.fields.number
        const cvv = hostedFieldEvent.fields.cvv
        const expirationDate = hostedFieldEvent.fields.expirationDate
        const postalCode = hostedFieldEvent.fields.postalCode
        if (number.isEmpty || cvv.isEmpty || expirationDate.isEmpty || postalCode.isEmpty) {
          setHasEmpty(true)
        } else {
          setHasEmpty(false)
        }
      })

      hostedFieldInstance.on('notEmpty', hostedFieldEvent => {
        const number = hostedFieldEvent.fields.number
        const cvv = hostedFieldEvent.fields.cvv
        const expirationDate = hostedFieldEvent.fields.expirationDate
        const postalCode = hostedFieldEvent.fields.postalCode
        if (number.isEmpty || cvv.isEmpty || expirationDate.isEmpty || postalCode.isEmpty) {
          setHasEmpty(true)
        } else {
          setHasEmpty(false)
        }
      })

      hostedFieldInstance?.on(
        'validityChange',
        _debounce<(param: braintree.HostedFieldsEvent) => void>(hostedFieldEvent => {
          const number = hostedFieldEvent.fields.number
          const cvv = hostedFieldEvent.fields.cvv
          const expirationDate = hostedFieldEvent.fields.expirationDate
          const postalCode = hostedFieldEvent.fields.postalCode

          if (!number.isValid && !number.isEmpty) {
            setCardError('Please enter the valid credit card number')
          } else if (!cvv.isValid && !cvv.isEmpty) {
            setCardError('Please enter the valid CVV')
          } else if (!expirationDate.isValid && !expirationDate.isEmpty) {
            setCardError('Please enter the valid expiration date')
          } else if (!postalCode.isValid && !postalCode.isEmpty) {
            setCardError('Please enter the valid postal code')
          } else {
            setCardError(undefined)
          }

          if (number.isEmpty || cvv.isEmpty || expirationDate.isEmpty || postalCode.isEmpty) {
            setHasEmpty(true)
          } else {
            setHasEmpty(false)
          }
        }, 800)
      )

      hostedFieldInstance?.on('focus', hostedFieldEvent => {
        setFocusState(true)
      })

      hostedFieldInstance?.on('blur', hostedFieldEvent => {
        setFocusState(false)
      })
    }
  }, [hostedFieldInstance, setCardError, setHasEmpty])

  const tokenize = useCallback(() => {
    if (hostedFieldInstance) {
      hostedFieldInstance
        .tokenize()
        .then(payload => {
          getNonceResponse({ nonce: payload.nonce })
        })
        .catch((error: braintree.BraintreeError) => {
          getNonceResponse({ error })

          SentryUtils.captureMessage(
            `Failed To Tokenize card`,
            {
              message: error.message,
              code: error.code,
              details: error.details
            },
            'error'
          )
        })
    }
  }, [getNonceResponse, hostedFieldInstance])

  useEffect(() => {
    if (shouldGetNonce) {
      tokenize()
    }
  }, [shouldGetNonce, tokenize])

  return (
    <div className={clsx(hide ? 'hidden' : null, className)}>
      {loading ? (
        <div className="flex justify-center py-3">
          <CircularLoading loading={true} size={24} disableShrink />
        </div>
      ) : null}

      <CardForm
        error={!hideErrorMessage ? cardError : undefined}
        cardType={cardType}
        focused={focusState}
        mounted={Boolean(hostedFieldInstance)}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BraintreeHostedField)
