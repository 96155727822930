import React, { CSSProperties, MutableRefObject } from 'react'

// import FixedSizeList, { FixedSizeListProps } from './FixedSizeList'
// import FixedSizeListImages, { FixedSizeListImagesProps } from './FixedSizeListImages'
// import MasonryGrid, { MasonryGridProps } from './MasonryGrid'
// import SimpleMasonryGrid, { SimpleMasonryGridProps } from './SimpleMasonryGrid'
// import SimpleList, { SimpleListProps } from './SimpleList'

export type OnScrollParams = {
  clientHeight: number
  clientWidth: number
  scrollHeight: number
  scrollLeft: number
  scrollTop: number
  scrollWidth: number
}

export type OnScrollCallback = (params: {
  clientHeight: number
  scrollHeight: number
  scrollTop: number
}) => void

export type ListConfigType = Partial<{
  columns: number
  fixedHeight: boolean
  maxWidth: number
  paddingY: number
  paddingX: number
  paddingTop: number
  paddingBottom: number
  paddingLeft: number
  paddingRight: number
  defaultRowHeight: number
  defaultColumnWidth: number
  gutter: number
  rowHeight: number
  square: boolean
}>

export type ListVirtualizedConfigsProps = {
  [key: string]: ListConfigType
}

export const CONFIG_EMPTY: ListVirtualizedConfigsProps = {}

export type ListVirtualizedClassesProps = {
  gridContainer?: string
  loadingFixed?: string
}

export type RenderItemProps<Item> = {
  id?: number
  key?: number
  height?: number
  index: number
  style?: CSSProperties
  measure?: any
  width?: number
}

export type ListVirtualizedProps<Item> = {
  autoHeight?: boolean
  dataLength?: number
  calculateCellHeight?: (index: number, columnWidth: number, defaultRowHeight: number) => number
  classes?: ListVirtualizedClassesProps
  className?: string
  config?: ListVirtualizedConfigsProps
  loading?: boolean
  loadingPosition?: 'absolute' | 'fixed'
  loadingSize?: number
  onEndOfGrid?: () => void
  onScroll?: OnScrollCallback
  renderItem: (props: RenderItemProps<Item>) => React.ReactNode
  scrollRef?: MutableRefObject<any> | Window
  virtualized?: boolean | number
}

// function ListVirtualized2<Item>(props: ListVirtualizedProps<Item>) {
//   const { virtualized, ...restProps } = props
//   if (
//     virtualized === true ||
//     (typeof virtualized === 'number' && props.dataLength && props.dataLength > virtualized)
//   )
//     return <FixedSizeList {...restProps} />

//   return null

//   // return <SimpleList {...restProps} />
// }

// export // FixedSizeListImagesProps,
// // MasonryGridProps,
// // SimpleListProps,
// // SimpleMasonryGridProps
// // FixedSizeListProps
//  type {}
// export // FixedSizeList
// // FixedSizeListImages, MasonryGrid, SimpleList, SimpleMasonryGrid
// // FixedSizeList
//  {}

// export default ListVirtualized2
