import {
  catchError,
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
  mergeMap,
  withLatestFrom,
  take,
  startWith,
  delay,
  takeUntil
} from 'rxjs/operators'
import _compact from 'lodash/compact'
import { from, of, interval, concat, merge } from 'rxjs'
import { firebaseActions, SignInProvider, SIGN_IN_PROVIDER_TEXT_MAP } from './actions'
import { Epic, combineEpics } from 'redux-observable'
import { LSKey, REFRESH_TOKEN_INTERVAL } from 'appConstants'
import { LocalStorage, SessionStorage } from 'utils'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import {
  getFirebaseAuth,
  getIdToken,
  getRecaptchaVerifier,
  getAuthProvider,
  getPhoneAuthProviderCredential,
  getCurrentUser
} from 'utils/FirebaseUtils'
import {
  AuthProvider,
  EmailAuthCredential,
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  linkWithCredential,
  PhoneAuthProvider,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  unlink,
  updatePhoneNumber
} from 'firebase/auth'

import { firebaseSelectors } from './selectors'
import { sharedActions } from 'duck/ApiDuck/sharedActions'
import MixPanelUtils from 'utils/MixPanelUtils'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import { appActions } from 'duck/AppDuck'
import SentryUtils from 'utils/SentryUtils'
import { apiActions } from 'duck/ApiDuck'
import { dialogActions, FormDialog } from 'duck/AppDuck/DialogDuck'
import { route } from 'routes'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'

const ACCOUNT_EXIST_WITH_DIFF_CREDENTIAL = 'auth/account-exists-with-different-credential'

const Utils = {
  getSnackbarText: (signInProvider: SignInProvider) => {
    const text = SIGN_IN_PROVIDER_TEXT_MAP[signInProvider] ?? 'third party service'
    return `Your Playform account successfully connected to ${text}.`
  }
}

const requestAuthEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.requestAuth)),
    map(({ type }) => ({
      isSignIn: isSignInWithEmailLink(getFirebaseAuth(), window.location.href),
      type,
      email: LocalStorage.get(LSKey.EMAIL_FOR_LINK_LOGIN)
    })),
    mergeMap(({ isSignIn }) =>
      merge(
        of(isSignIn).pipe(
          filter(isSignIn => !Boolean(isSignIn)),
          map(() => firebaseActions.setLoading(false))
        ),
        of(isSignIn).pipe(
          filter(isSignIn => Boolean(isSignIn)),
          map(() => LocalStorage.get(LSKey.EMAIL_FOR_LINK_LOGIN)),
          mergeMap(email =>
            _compact([
              email && firebaseActions.signInWithEmailLink({ email }),
              !email &&
                dialogActions.openDialog({
                  [FormDialog.EMAIL_FOR_SIGNIN_LINK]: {
                    dialogName: FormDialog.EMAIL_FOR_SIGNIN_LINK
                  }
                })
            ])
          )
        )
      )
    )
  )

const signInWithEmailLinkEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.signInWithEmailLink)),
    tap(({ payload: { email } }) => {
      SessionStorage.save(LSKey.EMAIL_FOR_LINK_LOGIN_LOGGING, email ?? '')
    }),
    mergeMap(({ payload: { email }, type }) =>
      concat(
        of(firebaseActions.setLoading(true)),
        from(
          signInWithEmailLink(getFirebaseAuth(), email?.trim() ?? '', window.location.href)
        ).pipe(
          tap(() => {
            MixPanelUtils.track<'USER__LOGIN'>('User - Login', {
              $email: email ?? '',
              sign_in_provider: 'emailLink'
            })
            FacebookPixelUtils.track<'USER__LOGIN'>('User - Login', { email: email ?? '' })
          }),
          map(result => {
            const pendingCredentialJson = SessionStorage.getJSON(LSKey.PENDING_CREDENTIAL) ?? ''

            const pendingCredential = pendingCredentialJson
              ? EmailAuthCredential.fromJSON(pendingCredentialJson)
              : undefined

            LocalStorage.remove(LSKey.EMAIL_FOR_LINK_LOGIN)

            return {
              userCredential: result,
              pendingCredential: pendingCredential
            }
          }),
          mergeMap(data =>
            merge(
              of(firebaseActions.setError({ error: null, action: type })),
              of(firebaseActions.setLoading(false)),
              of(firebaseActions.linkWithCredential(data))
            )
          ),
          catchError(err => concat(of(firebaseActions.setError({ error: err, action: type }))))
        )
      )
    )
  )

const actionCodeSettings = {
  url: `${process.env.REACT_APP_FIREBASE_APP_LINK_REDIRECT}/login`,
  handleCodeInApp: true
}
const requestLoginEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.requestLogin)),
    switchMap(({ type, payload: { email, pendingCredential } }) =>
      concat(
        of(apiActions.users.submitPresignupEmail({ email })),
        of(firebaseActions.setLoading(true)),
        from(sendSignInLinkToEmail(getFirebaseAuth(), email, actionCodeSettings)).pipe(
          tap(() => {
            if (pendingCredential) {
              SessionStorage.saveJSON(LSKey.PENDING_CREDENTIAL, pendingCredential.toJSON())
            }
          }),
          tap(() => LocalStorage.save(LSKey.EMAIL_FOR_LINK_LOGIN, email)),
          tap(() => {
            MixPanelUtils.track<'USER__SIGNUP'>('User - Signup', { $email: email })
            FacebookPixelUtils.track<'USER__SIGNUP'>('User - Signup', { email: email })
          }),
          mergeMap(() => [
            firebaseActions.setLoading(false),
            firebaseActions.setPendingCredential(undefined),
            firebaseActions.setError({ error: null, action: type })
          ]),
          catchError(err => concat(of(firebaseActions.setError({ error: err, action: type }))))
        )
      )
    )
  )

const signinWithEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.signinWith)),
    map(({ payload, type }) => {
      const { pendingCredential, signInProvider } = payload
      const providers: Record<SignInProvider, AuthProvider | undefined> = {
        'facebook.com': getAuthProvider.facebook(),
        'google.com': getAuthProvider.google(),
        'twitter.com': getAuthProvider.twitter(),
        emailLink: undefined,
        phone: undefined,
        password: undefined
      }

      return { provider: providers[signInProvider], pendingCredential, signInProvider, type }
    }),
    filter(({ provider }) => Boolean(provider)),
    switchMap(({ provider, pendingCredential, signInProvider, type }) =>
      concat(
        of(firebaseActions.setPendingCredential(undefined)),
        of(firebaseActions.setLoading(true)),
        from(signInWithPopup(getFirebaseAuth(), provider ?? getAuthProvider.google())).pipe(
          tap(result => {
            const email = result.user?.email ?? ''
            MixPanelUtils.track<'USER__LOGIN'>('User - Login', {
              $email: email,
              sign_in_provider: signInProvider
            })
            FacebookPixelUtils.track<'USER__LOGIN'>('User - Login', {
              email: email
            })
          }),
          mergeMap(result =>
            merge(
              of(firebaseActions.setLoading(false)),
              of(dialogActions.closeAllDialog()),
              of(appActions.pushTo(route.TRAIN_PROJECTS.getUrl())),
              of(firebaseActions.setError({ error: null, action: type })),
              of(firebaseActions.linkWithCredential({ pendingCredential, userCredential: result }))
            )
          ),
          catchError(err =>
            concat(
              of(
                firebaseActions.setError({ error: err, action: type }),
                firebaseActions.handleSignInError({ error: err, signInProvider })
              )
            )
          )
        )
      )
    )
  )

/* TODO */
const handleSignInErrorEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.handleSignInError)),
    map(({ payload, type }) => ({ ...payload, type })),
    mergeMap(({ error, signInProvider, type }) =>
      of(error).pipe(
        filter(error => error?.code === ACCOUNT_EXIST_WITH_DIFF_CREDENTIAL),
        map(error => {
          const pendingCred = ''
          const email = ''
          // const pendingCred = error?.credential
          // const email = error?.email
          return {
            pendingCred,
            email
          }
        }),
        filter(({ email }) => Boolean(email)),
        mergeMap(({ email = '', pendingCred }) =>
          concat(
            of(firebaseActions.setError({ error: null, action: type })),
            from(fetchSignInMethodsForEmail(getFirebaseAuth(), email)).pipe(
              map(signinProviders => signinProviders as SignInProvider[]),
              ignoreElements()
              // map(signinProviders =>
              //   firebaseActions.setPendingCredential({
              //     credential: pendingCred,
              //     email,
              //     signinProviders,
              //     currentSignInProvider: signInProvider
              //   })
              // )
            )
          )
        )
      )
    )
  )

const startTokenIntervalEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.startTokenInterval)),
    mergeMap(({ type }) =>
      interval(REFRESH_TOKEN_INTERVAL).pipe(
        takeUntil(action$.pipe(filter(isActionOf(firebaseActions.restartTokenInterval)))),
        mergeMap(() =>
          concat(
            of(firebaseActions.setLoading(true)),
            from(getIdToken(true)).pipe(
              mergeMap(resp => [
                firebaseActions.setIdToken(resp),
                firebaseActions.setLoading(false)
              ]),
              catchError(err => of(firebaseActions.setError({ error: err, action: type })))
            )
          )
        )
      )
    )
  )
const restartTokenIntervalEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.restartTokenInterval)),
    delay(300),
    map(() => firebaseActions.startTokenInterval())
  )

const unlinkSignInEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.unlinkSignIn)),
    map(({ payload, type }) => ({
      providerId: payload.providerId,
      currentUser: getCurrentUser(),
      type
    })),
    filter(({ providerId, currentUser }) => Boolean(providerId) && Boolean(currentUser)),
    mergeMap(({ providerId, currentUser, type }) =>
      from(currentUser ? unlink(currentUser, providerId) : EMPTY_PROMISE).pipe(
        map(() => firebaseActions.unlinkSignInResponse({ providerId })),
        catchError(err => of(firebaseActions.setError({ error: err, action: type })))
      )
    )
  )

const DEFAULT_VERIFIER = {
  type: '',
  verify: () => new Promise<string>(() => {})
}
const EMPTY_PROMISE = new Promise<string>(() => {})

const updatePhoneNumberEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.updatePhoneNumber)),
    map(({ payload, type }) => ({
      phoneNumber: payload.phoneNumber,
      appVerifier: getRecaptchaVerifier(),
      type
    })),
    filter(({ phoneNumber, appVerifier }) => Boolean(phoneNumber) && Boolean(appVerifier)),
    mergeMap(param =>
      concat(
        of(sharedActions.setLoading({ loading: true, type: param.type })),
        of(param).pipe(
          filter(({ appVerifier }) => Boolean(appVerifier)),
          mergeMap(({ phoneNumber, appVerifier, type }) =>
            from(
              getAuthProvider
                .phoneAuth(getFirebaseAuth())
                .verifyPhoneNumber(phoneNumber, appVerifier || DEFAULT_VERIFIER)
            ).pipe(
              mergeMap(resp => [
                firebaseActions.setPhoneVerificationId(resp),
                sharedActions.setLoading({ loading: false, type: param.type })
              ]),
              catchError(err => of(sharedActions.setError({ firebaseError: err, type })))
            )
          )
        )
      )
    )
  )

const verifyPhoneEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(firebaseActions.verifyPhone)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      code: action.payload,
      type: action.type,
      currentUser: getCurrentUser(),
      phoneVerificationId: firebaseSelectors.phoneVerificationId(state)
    })),
    filter(({ code, phoneVerificationId }) => Boolean(code) && Boolean(phoneVerificationId)),
    map(({ phoneVerificationId, code, ...restParam }) => ({
      ...restParam,
      phoneVerificationId,
      phoneCredential: getPhoneAuthProviderCredential(phoneVerificationId, code)
    })),
    mergeMap(param =>
      concat(
        of(sharedActions.setLoading({ loading: true, type: param.type })),
        of(param).pipe(
          mergeMap(({ currentUser, phoneCredential, phoneVerificationId = '', type }) =>
            from(
              currentUser ? updatePhoneNumber(currentUser, phoneCredential) : EMPTY_PROMISE
            ).pipe(
              mergeMap(param =>
                merge(
                  of(sharedActions.setLoading({ loading: false, type: type })),
                  of(param).pipe(
                    mergeMap(() =>
                      action$.pipe(
                        filter(isActionOf(firebaseActions.setFirebaseUser)),
                        take(1),
                        map(({ payload }) =>
                          firebaseActions.verifyPhoneResponse({
                            firebaseUser: payload,
                            phoneVerificationId
                          })
                        ),
                        startWith(firebaseActions.reloadUser())
                      )
                    )
                  ),
                  of(firebaseActions.setPhoneVerificationId(''))
                )
              ),
              catchError(err => of(sharedActions.setError({ firebaseError: err, type })))
            )
          )
        )
      )
    )
  )

const refreshTokenEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.refreshToken)),
    mergeMap(({ type }) =>
      from(getIdToken(true)).pipe(
        map(resp => firebaseActions.setIdToken(resp)),
        catchError(err => of(firebaseActions.setError({ error: err, action: type })))
      )
    )
  )

const reloadUserEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.reloadUser)),
    mergeMap(({ type }) =>
      from(getCurrentUser()?.reload?.() || EMPTY_PROMISE).pipe(
        tap(() => {
          MixPanelUtils.updatePeople({
            $phone: getCurrentUser()?.phoneNumber ?? ''
          })
        }),
        map(() => firebaseActions.setFirebaseUser(getCurrentUser() ?? null)),
        catchError(err => of(firebaseActions.setError({ error: err, action: type })))
      )
    )
  )

const removePhoneEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(firebaseActions.removePhone)),
    withLatestFrom(state$),
    map(([action, _]) => ({
      type: action.type,
      currentUser: getCurrentUser()
    })),
    mergeMap(param =>
      concat(
        of(sharedActions.setLoading({ loading: true, type: param.type })),
        of(param).pipe(
          mergeMap(({ currentUser }) =>
            from(
              currentUser ? unlink(currentUser, PhoneAuthProvider.PROVIDER_ID) : EMPTY_PROMISE
            ).pipe(
              mergeMap(() => [
                sharedActions.setLoading({ loading: false, type: param.type }),
                firebaseActions.reloadUser()
              ]),
              catchError(err => of(firebaseActions.setError({ error: err, action: param.type })))
            )
          )
        )
      )
    )
  )

const resetEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.reset)),
    tap(() => {
      getFirebaseAuth().signOut()
    }),
    ignoreElements()
  )

const listenErrorEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.setError)),
    filter(({ payload }) => Boolean(payload)),
    tap(({ payload }) => {
      const firebaseError = payload.error
      const userEmail = LocalStorage.get(LSKey.EMAIL_FOR_LINK_LOGIN)
      const userEmail2 = SessionStorage.get(LSKey.EMAIL_FOR_LINK_LOGIN_LOGGING)

      if (firebaseError) {
        SentryUtils.captureMessage(
          `${firebaseError?.code} ${firebaseError?.message}`,
          {
            name: firebaseError.name,
            statusText: firebaseError.stack,
            message: firebaseError.message,
            userEmail: userEmail || userEmail2,
            action: payload.action
          },
          'error'
        )

        MixPanelUtils.track<'USER__FIREBASE_ERROR'>('User - Firebase Error', {
          content: `${firebaseError.name} - ${payload.action}`,
          value: firebaseError.message,
          $email: userEmail
        })
      }
    }),
    map(() => firebaseActions.setLoading(false))
  )

const linkWithCredentialEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(firebaseActions.linkWithCredential)),
    map(({ payload, type }) => ({ ...payload, type })),
    filter(
      ({ pendingCredential, userCredential }) =>
        Boolean(pendingCredential?.signInMethod) && Boolean(userCredential.user)
    ),
    tap(() => {
      SessionStorage.remove(LSKey.PENDING_CREDENTIAL)
    }),
    map(({ pendingCredential, userCredential, type }) => ({
      linkWithCredential:
        pendingCredential && userCredential.user
          ? linkWithCredential(userCredential.user, pendingCredential) ?? EMPTY_PROMISE
          : EMPTY_PROMISE,
      providerId: pendingCredential?.providerId as SignInProvider,
      type
    })),
    mergeMap(({ linkWithCredential, providerId, type }) =>
      from(linkWithCredential).pipe(
        mergeMap(response =>
          merge(
            of(firebaseActions.reloadUser()),
            of(
              snackBarActions.show({
                show: true,
                content: Utils.getSnackbarText(providerId)
              })
            ),
            of(response).pipe(
              delay(5000),
              map(() => snackBarActions.close())
            )
          )
        ),
        catchError(err => concat(of(firebaseActions.setError({ error: err, action: type }))))
      )
    )
  )

export const epics = combineEpics(
  signInWithEmailLinkEpic,
  linkWithCredentialEpic,
  listenErrorEpic,
  handleSignInErrorEpic,
  signinWithEpic,
  requestAuthEpic,
  unlinkSignInEpic,
  reloadUserEpic,
  verifyPhoneEpic,
  removePhoneEpic,
  updatePhoneNumberEpic,
  requestLoginEpic,
  resetEpic,
  refreshTokenEpic,
  startTokenIntervalEpic,
  restartTokenIntervalEpic
)
