import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { route } from 'routes'
import ProtectedRoute from './ProtectedRoute'

type TestingRouteProps = { isProtectedRoute?: Boolean; page: JSX.Element }

const TestingRoute: React.FC<TestingRouteProps> = ({ isProtectedRoute = false, page }) => {
  const navigate = useNavigate()
  const isProduction = process.env.NODE_ENV === 'production'

  useEffect(() => {
    if (isProduction) navigate(route.NOT_FOUND.getUrl())
  }, [isProduction, navigate])

  return !isProtectedRoute ? page : <ProtectedRoute page={page} />
}

export default TestingRoute
