import { combineReducers } from 'redux'
import addCreditReducer, {
  AddCreditState,
  epics as addCreditEpic,
  addCreditSelectors
} from './AddCredit'
import braintreePaymentReducer, {
  BraintreePaymentState,
  epics as brainTreeEpics,
  brainTreePaymentSelector
} from './BraintreePayment'
import trainingFormReducer, {
  TrainingFormState,
  epics as trainingFormEpic,
  trainingFormSelectors
} from './TrainingForm'

import { combineEpics } from 'redux-observable'
export const epics = combineEpics(trainingFormEpic, brainTreeEpics, addCreditEpic)

export type MonetizationDialogState = {
  addCredit: AddCreditState
  brainTreePayment: BraintreePaymentState
  trainingForm: TrainingFormState
}

export default combineReducers<MonetizationDialogState>({
  addCredit: addCreditReducer,
  brainTreePayment: braintreePaymentReducer,
  trainingForm: trainingFormReducer
})

export * from './AddCredit'
export * from './BraintreePayment'
export * from './TrainingForm'

export const selectors = {
  ...addCreditSelectors,
  ...brainTreePaymentSelector,
  ...trainingFormSelectors
}
