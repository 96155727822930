import {
  ActionType,
  PayloadAction,
  createReducer,
  createAction,
  EmptyAction
} from 'typesafe-actions'

import { RootState } from 'duck'
import { MouseEventHandler } from 'react'

// Actions
export const snackBarActions = {
  show: createAction('@@page/App/snackbar/SHOW')<SnackbarState>(),
  close: createAction('@@page/App/snackbar/CLOSE')(),
  update: createAction('@@page/App/snackbar/UPDATE')<SnackbarState>()
}

export type SnackbarActions = ActionType<typeof snackBarActions>

// Selectors
const selectSnackbar = (state: RootState) => state.container.appPage.snackBar

export const selectors = {
  snackBar: selectSnackbar
}
export type SnackbarAction = PayloadAction<string, any> | EmptyAction<string>

// Reducer
export type SnackbarState = {
  show?: boolean
  content?: string
  actionText?: string
  onClick?: MouseEventHandler
  onClickAction?: SnackbarAction
}

const initialState: SnackbarState = {
  show: false,
  content: '',
  actionText: '',
  onClick: undefined,
  onClickAction: undefined
}

const reducer = createReducer<SnackbarState, SnackbarActions>(initialState)
  .handleAction(snackBarActions.show, (state, { type, payload }) => ({
    ...state,
    content: payload.content,
    actionText: payload.actionText,
    onClick: payload.onClick,
    onClickAction: payload.onClickAction,
    show: true
  }))
  .handleAction(snackBarActions.update, (state, { type, payload }) => ({
    ...state,
    content: payload.content,
    actionText: payload.actionText,
    onClick: payload.onClick,
    onClickAction: payload.onClickAction
  }))
  .handleAction(snackBarActions.close, state => initialState)

export default reducer
