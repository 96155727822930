import { FormDialog } from 'duck/AppDuck/DialogDuck'
import loadable from 'utils/Loadable'
import { useDialogDetector } from 'components/Dialog/Hooks'

const QuestionDialog = loadable(() => import(/* webpackChunkName: "QuestionDialog" */ './index'))

const DIALOG_NAMES = [FormDialog.SEND_QUESTION, FormDialog.SEND_QUESTION_SUCCESS]

const QuestionDialogContainer = () => {
  const open = useDialogDetector(DIALOG_NAMES)

  if (!open) return null

  return <QuestionDialog />
}

export default QuestionDialogContainer
