import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import { RootState } from 'duck'
import { MixImageGenre } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { currentMixImageProjectId } from './CommonSelectors'

const mixImage = (state: RootState) => {
  return state.api.mixImage
}

const spaceImage = createSelector(mixImage, mixImage => mixImage.spaceImage)
const spaceImages = createSelector(spaceImage, spaceImage => spaceImage.spaceImages)
const spaceImageList = createSelector(spaceImage, spaceImage => spaceImage.spaceImageList)
const currentFetchState = createSelector(spaceImage, spaceImage => spaceImage.currentFetchState)

const mixImageProjectData = createSelector(mixImage, mixImage => mixImage.mixImageProject.data)

const currentMixImageProject = createSelector(
  currentMixImageProjectId,
  mixImageProjectData,
  (currentMixImageProjectId, mixImageProjectData) => mixImageProjectData[currentMixImageProjectId]
)

const currentMixImageProjectGenre = createSelector(
  currentMixImageProject,
  currentMixImageProject => currentMixImageProject?.genre
)
const hasCurrentMixImageProject = createSelector(currentMixImageProject, currentMixImageProject =>
  Boolean(currentMixImageProject)
)

const mixImageGenres = createSelector(mixImage, mixImage => mixImage.mixImageGenres?.results ?? [])

/* Object key version */
const initialMixGenre: { [id: number]: MixImageGenre } = {}
const mixImageGenreData = createSelector(mixImageGenres, mixImageGenres =>
  _reduce(
    mixImageGenres,
    (result, value) => {
      result[value.id] = value
      return result
    },
    initialMixGenre
  )
)
const hasMixImageGenre = createSelector(mixImageGenres, mixImageGenres =>
  Boolean(mixImageGenres.length)
)

const uploadImages = createSelector(mixImage, mixImage => mixImage.uploadImages.data)
const uploadImageListId = createSelector(mixImage, mixImage => mixImage.uploadImages.list)
const hasUploadImageList = createSelector(uploadImageListId, uploadImageListId =>
  Boolean(uploadImageListId.length)
)

const uploadImageList = createSelector(uploadImages, uploadImageListId, (data, list) =>
  list.map(id => data[id])
)

const mixImageProjectListId = (state: RootState) => {
  return state.api.mixImage.mixImageProject.list || []
}
const mixImageProjects = (state: RootState) => {
  return state.api.mixImage.mixImageProject.data || []
}

const mixImageProjectList = createSelector(
  mixImageProjects,
  mixImageProjectListId,
  (mixImageProjects, mixImageProjectListId) =>
    _map(mixImageProjectListId, id => mixImageProjects[id] || {})
)

const mixImageSelectors = {
  spaceImage,
  spaceImages,
  spaceImageList,
  currentFetchState,
  mixImageGenres,
  mixImageGenreData,
  hasMixImageGenre,
  mixImageProjectListId,
  currentMixImageProjectId,
  currentMixImageProject,
  hasCurrentMixImageProject,
  currentMixImageProjectGenre,
  mixImageProjects,
  mixImageProjectList,
  uploadImages,
  uploadImageList,
  hasUploadImageList
}

export default mixImageSelectors
