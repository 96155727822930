import React from 'react'
import { appSelectors } from 'duck/AppDuck'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import increaseGasPrice1 from 'assets/images/increase-gas-1.jpg'
import increaseGasPrice2 from 'assets/images/increase-gas-2.jpg'
import increaseGasPrice3 from 'assets/images/increase-gas-3.jpg'
import DialogWrapper from './DialogWrapper'
import { RootState } from 'duck'
import { InformationDialog } from 'duck/AppDuck/DialogDuck'
import Button from 'components/Button'
import styles from './Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'
import { apiSelectors } from 'duck/ApiDuck'
import { useDispatch } from 'react-redux'
import { DialogUtils } from './Utils'
import { GeneralDialogItemProps } from '.'
import Img from 'components/Img'
import { link } from 'appConstants'

const mapStateToProps = (state: RootState) => ({
  dialogData: appSelectors.dialog.dialogData(state),
  activeDialogOverlay: appSelectors.dialog.activeDialogOverlay(state),
  currentUserAddress: apiSelectors.currentUserAddress(state)
})

type IncreaseGasDialogProps = ReduxProps<typeof mapStateToProps> & GeneralDialogItemProps

const IncreaseGasDialog: React.FC<IncreaseGasDialogProps> = ({
  onClose,
  dialogData,
  activeDialog
}) => {
  const dialogDatum = dialogData[InformationDialog.INCREASE_GAS]
  const dispatch = useDispatch()

  const continueText = dialogDatum?.continueText
  const continueAction = dialogDatum?.continueAction

  return (
    <DialogWrapper
      dialogConfig={{ contentDividers: false }}
      onClose={() => {
        onClose?.()
      }}
      activeDialog={activeDialog}
      title={'Increase Gas Price'}
      actions={
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            continueAction && DialogUtils.executeAction([continueAction], dispatch)
            onClose?.()
          }}
          fullWidth
        >
          {continueText}
        </Button>
      }
      content={
        <div className={styles.DialogContent}>
          <Typography variant="body2" className="mb-3">
            To guarantee the project published without delays and failure, please increase the gas
            fees as described below :
          </Typography>
          <div className={styles.ImageGuideContainer}>
            <div>
              <Img src={increaseGasPrice1} />
              <Typography variant="h5" className="mt-3">
                1
              </Typography>
            </div>
            <div>
              <Img src={increaseGasPrice2} />
              <Typography variant="h5" className="mt-3">
                2
              </Typography>
            </div>
            <div>
              <Img src={increaseGasPrice3} />
              <Typography variant="h5" className="mt-3">
                3
              </Typography>
            </div>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              You can also check this page for more information :
              <a
                target="_blank"
                href={link.ARTMINE_CHANGE_GAS_PRICE}
                rel="noreferrer"
              >{` ${link.ARTMINE_CHANGE_GAS_PRICE}`}</a>
            </Typography>
          </div>
        </div>
      }
    />
  )
}

export default connect(mapStateToProps)(IncreaseGasDialog)
