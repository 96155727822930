import { withLatestFrom, map, mergeMap, filter, take, startWith, tap } from 'rxjs/operators'
import { combineEpics, Epic } from 'redux-observable'
import { RootActionType, RootState } from 'duck'
import { merge, of } from 'rxjs'
import { isActionOf, ActionType, getType } from 'typesafe-actions'
import { apiSelectors, apiActions, CollectionListType } from 'duck/ApiDuck'
import { actions } from './actions'
import { selectors } from './selectors'
import { getDuplicateCollections } from 'utils/DataProcessingUtils'

import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import { checkIsRecentlyUsed } from './reducers'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'

// Epics
type CollectionSelectorPanelActionType = RootActionType | ActionType<typeof actions>

const loadCategoriesEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadCategories)),
    withLatestFrom(state$),
    map(([, state]) => ({
      categories: apiSelectors.categories(state)
    })),
    filter(({ categories }) => !Boolean(categories && categories.length)),
    map(() => apiActions.collections.listCategories())
  )

const openSingleCollection: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.openSingleCollection)),
    map(({ payload }) => payload),
    mergeMap(({ collectionId, tab }) => [
      actions.myCollection.setSortBy('-modified'),
      actions.expandSheetBar({
        expanded: true,
        selectedImageSet: 'inspiration'
      }),
      actions.setHideCollFooter(true),
      actions.setCurrentTab(tab),
      actions.setOpenedCollection(collectionId)
    ])
  )

const loadMyCollectionEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.myCollection.setSelectedCategory,
        actions.myCollection.setSortBy,
        actions.myCollection.reloadCollection,
        actions.myCollection.loadMoreMyCollection
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({ myCollection: selectors.myCollection(state), type: action.type })),
    map(({ myCollection, type }) => {
      const { selectedCategory, listParams } = myCollection
      const listParamsAdjusted =
        selectedCategory === 'all' ? listParams : { ...listParams, category: selectedCategory }

      const isRecentlyUsed = checkIsRecentlyUsed(listParams)

      const listParamsAdjusted2 = listParamsAdjusted.search
        ? {
            ...listParamsAdjusted,
            category: undefined
          }
        : listParamsAdjusted

      return {
        listParams: listParamsAdjusted2,
        reloadList: type !== getType(actions.myCollection.loadMoreMyCollection),
        listType: isRecentlyUsed ? 'recently_used' : 'my_collection'
      }
    }),
    map(({ listParams, listType, reloadList }) =>
      apiActions.collections.list({
        param: listParams,
        reloadList,
        listType: listType as CollectionListType
      })
    )
  )
const setMyCollectionSearchKeywordEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.myCollection.setSearchKeyword)),
    mergeMap(action =>
      merge(
        of(actions.setSearchLoading(true)),
        of(action).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.collections.listResponse)),
              take(1),
              map(() => actions.setSearchLoading(false)),
              startWith(actions.myCollection.reloadCollection())
            )
          )
        )
      )
    )
  )

const setBookmarkCollectionSearchKeywordEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.bookmarkCollection.setSearchKeyword)),
    mergeMap(action =>
      merge(
        of(actions.setSearchLoading(true)),
        of(action).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.collections.listResponse)),
              take(1),
              map(() => actions.setSearchLoading(false)),
              startWith(actions.bookmarkCollection.reloadCollection())
            )
          )
        )
      )
    )
  )

const setPublicCollectionSearchKeywordEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.publicCollection.setSearchKeyword)),
    mergeMap(action =>
      merge(
        of(actions.setSearchLoading(true)),
        of(action).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.collections.listResponse)),
              take(1),
              map(() => actions.setSearchLoading(false)),
              startWith(actions.publicCollection.reloadCollection())
            )
          )
        )
      )
    )
  )
const reloadPublicCollectionEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.publicCollection.setSelectedCategory,
        actions.publicCollection.setSortBy,
        actions.publicCollection.reloadCollection
      ])
    ),
    withLatestFrom(state$),
    map(([_, state]) => selectors.publicCollection(state)),
    map(({ selectedCategory, listParams }) => {
      return selectedCategory === 'all' || selectedCategory === ''
        ? listParams
        : { ...listParams, category: selectedCategory }
    }),
    map(listParam =>
      apiActions.collections.list({ param: listParam, reloadList: true, listType: 'public' })
    )
  )

const reloadBookmarkCollectionEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.bookmarkCollection.setSortBy,
        actions.bookmarkCollection.reloadCollection,
        actions.bookmarkCollection.loadMoreBookmarkCollection
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      bookmarkCollection: selectors.bookmarkCollection(state),
      type: action.type
    })),
    map(({ bookmarkCollection, type }) => ({
      listParams: bookmarkCollection.listParams,
      reloadList: type !== getType(actions.bookmarkCollection.loadMoreBookmarkCollection)
    })),
    map(({ listParams, reloadList }) =>
      apiActions.collections.list({ param: listParams, reloadList, listType: 'bookmark' })
    )
  )

const listenOnAppEvent: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    mergeMap(({ payload }) =>
      merge(
        of(payload).pipe(
          filter(({ [AppEvents.COLLECTION_DELETED]: event }) => Boolean(event)),
          map(() => actions.setOpenedCollection(undefined))
        )
      )
    )
  )
// Add upload to collection
const addCollectionEpic: Epic<
  CollectionSelectorPanelActionType,
  CollectionSelectorPanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.addCollection)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      source: action.payload.source,
      collectionId: action.payload.collectionId,
      imageSet: selectors.selectedImageSet(state),
      currentProject: apiSelectors.currentProject(state)
    })),
    map(({ source, imageSet, currentProject, collectionId }) => {
      const process = currentProject?.categoryName

      const id = currentProject?.[imageSet] ?? 0

      const duplicates = getDuplicateCollections(currentProject, [collectionId], imageSet)
      return {
        param: {
          id,
          collection: collectionId,
          exclude_images: [],
          update_existing: true
        },
        imageSet,
        process,
        duplicates,
        source,
        currentProjectId: currentProject?.id ?? 0
      }
    }),
    mergeMap(({ source, param, currentProjectId, duplicates, process, imageSet }) =>
      merge(
        of(duplicates).pipe(
          filter(duplicates => Boolean(duplicates.length)),
          map(() =>
            dialogActions.openDialog({
              [ErrorDialog.REUSED_COLLECTION]: {
                dialogName: ErrorDialog.REUSED_COLLECTION,
                content: { process }
              }
            })
          )
        ),
        of(duplicates).pipe(
          filter(duplicates => !Boolean(duplicates.length)),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf([apiActions.inputs.updateResponse])),
              take(1),
              withLatestFrom(state$),
              tap(([_, state]) => {
                const currentProject = apiSelectors.currentProject(state)
                const collection = apiSelectors.collections(state)[param.collection]
                const categories = apiSelectors.categoriesObject(state)

                MixPanelUtils.track<'PROJECT__ADD_COLLECTION_TO_PROJECT'>(
                  'Project - Add Collection To Project',
                  {
                    ...DataUtils.getProjectParam<'training_project'>('training_project', {
                      trainProject: currentProject
                    }),
                    ...DataUtils.getCollectionData({
                      collection,
                      category: categories
                    }),
                    image_set: imageSet,
                    add_collection_source: source
                  }
                )
              }),

              mergeMap(() => [
                actions.expandSheetBar({ expanded: false }),
                actions.myCollection.reloadCollection(),
                eventEmiterActions.emit({
                  [AppEvents.COLLECTION_ADDED]: {
                    event: AppEvents.COLLECTION_ADDED
                  }
                }),
                apiActions.projects.updateThumbnail(currentProjectId),
                actions.setOpenedCollection(undefined),
                actions.setCurrentTab(null)
              ]),
              // This one is executed first, and code above is listening finished collection.
              startWith(apiActions.inputs.update(param))
            )
          )
        )
      )
    )
  )

export const epics = combineEpics(
  openSingleCollection,
  loadCategoriesEpic,
  setMyCollectionSearchKeywordEpic,
  setPublicCollectionSearchKeywordEpic,
  setBookmarkCollectionSearchKeywordEpic,
  listenOnAppEvent,
  addCollectionEpic,
  loadMyCollectionEpic,
  reloadPublicCollectionEpic,
  reloadBookmarkCollectionEpic
)
