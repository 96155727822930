import { FormDialog } from 'duck/AppDuck/DialogDuck'
import loadable from 'utils/Loadable'
import { useDialogDetector } from 'components/Dialog/Hooks'

const PhoneVerificationDialog = loadable(
  () => import(/* webpackChunkName: "PhoneVerificationDialog" */ './index')
)

const PhoneVerificationDialogContainer = () => {
  const open = useDialogDetector(FormDialog.PHONE_VERIFICATION)

  if (!open) return null

  return <PhoneVerificationDialog />
}

export default PhoneVerificationDialogContainer
