import { RootState } from 'duck'
import { createSelector } from 'reselect'
import { adjustedImages } from './CommonSelectors'

const upscaleImages = (state: RootState) => {
  return state.api.imageEnhancement?.upscaleImage.data ?? {}
}

const upscaleImageListId = (state: RootState) => {
  return state.api.imageEnhancement?.upscaleImage.list || []
}

const userImageUpscales = (state: RootState) => {
  return state.api.imageEnhancement.upscaleImage.userImageUpscales || {}
}

const upscaleImageList = createSelector(
  upscaleImages,
  upscaleImageListId,
  (upscaleImages, upscaleImageListId) => upscaleImageListId.map(id => upscaleImages[id] || {})
)

const unfinishedUpscaleImageList = createSelector(upscaleImageList, upscaleImageList =>
  upscaleImageList
    .filter(upscaleImage => !Boolean(upscaleImage.output))
    .map(upscaleImage => upscaleImage.id)
)

const hasUnfinishedUpscaleImage = createSelector(
  unfinishedUpscaleImageList,
  unfinishedUpscaleImageList => Boolean(unfinishedUpscaleImageList.length)
)

export const imageEnhancementSelectors = {
  unfinishedUpscaleImageList,
  hasUnfinishedUpscaleImage,
  userImageUpscales,
  upscaleImages,
  upscaleImageListId,
  upscaleImageList,
  adjustedImages
}

export default imageEnhancementSelectors
