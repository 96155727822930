import { ActionType, createReducer, createAction, isActionOf } from 'typesafe-actions'
import { RootActionType, RootState } from 'duck'
import { BannerSubscriptionUpsellProps } from 'components/Banner/BannerSubscriptionUpsell'
import { combineEpics, Epic } from 'redux-observable'
import { filter, ignoreElements, tap } from 'rxjs/operators'
import MixPanelUtils from 'utils/MixPanelUtils'
import { createSelector } from 'reselect'
import { ActionTypeParam } from '.'
import { ButtonProps } from 'components/Button'

// Actions
export type UpsellLocation =
  | 'home'
  | 'mix-image'
  | 'sketch'
  | 'global'
  | 'clip'
  | 'style-transfer'
  | 'ai-app'
export type ShowUpsellParam = UpsellBannerState & { upsellLocation: UpsellLocation }

export const bannerActions = {
  show: createAction('@@page/App/banner/SHOW')<BannerState['generalBanner']>(),
  close: createAction('@@page/App/banner/CLOSE')(),
  update: createAction('@@page/App/banner/UPDATE')<BannerState['generalBanner']>(),
  nftBanner: {
    show: createAction('@@page/App/banner/nftBanner/SHOW')<{ artmineProjectId: number }>(),
    close: createAction('@@page/App/banner/nftBanner/CLOSE')()
  },
  upsell: {
    show: createAction('@@page/App/banner/upsell/SHOW')<ShowUpsellParam>(),
    close: createAction('@@page/App/banner/upsell/CLOSE')<{
      upsellLocation: UpsellLocation
    }>()
  }
}

export type BannerActions = ActionType<typeof bannerActions>

// Selectors
const selectBanner = (state: RootState) => state.container.appPage.banner
const selectGeneralBanner = createSelector(selectBanner, banner => banner.generalBanner)
const selectUpsellHomeBanner = createSelector(selectBanner, banner => banner.upsells['home'])
const selectNftBanner = createSelector(selectBanner, banner => banner.nftBanner)
const selectUpsellMixImageBanner = createSelector(
  selectBanner,
  banner => banner.upsells['mix-image']
)
const selectUpsellGlobalBanner = createSelector(selectBanner, banner => banner.upsells['global'])
const selectUpsellClipBanner = createSelector(selectBanner, banner => banner.upsells['clip'])
const selectUpsellSketchBanner = createSelector(selectBanner, banner => banner.upsells.sketch)
const selectUpsellAiAppBanner = createSelector(selectBanner, banner => banner.upsells['ai-app'])
const selectUpsellProArtFilterBanner = createSelector(
  selectBanner,
  banner => banner.upsells['style-transfer']
)

export const selectors = {
  banner: selectGeneralBanner,
  selectUpsellHomeBanner: selectUpsellHomeBanner,
  upsellMixImageBanner: selectUpsellMixImageBanner,
  upsellSketchBanner: selectUpsellSketchBanner,
  upsellProArtFilterBanner: selectUpsellProArtFilterBanner,
  upsellGlobalBanner: selectUpsellGlobalBanner,
  upsellClipBanner: selectUpsellClipBanner,
  aiAppBanner: selectUpsellAiAppBanner,
  nftBanner: selectNftBanner
}

// Reducer
export type UpsellBannerState = {
  show?: boolean
  dismissable?: boolean
  contentMode?: BannerSubscriptionUpsellProps['contentMode']
  position?: BannerSubscriptionUpsellProps['position']
}

export type BannerAction = {
  label: string
  onClick: ActionTypeParam | Function
  color?: ButtonProps['color']
  variant?: ButtonProps['variant']
  className?: string
}

export type BannerState = {
  generalBanner: {
    show?: boolean
    message?: string
    autoCloseDuration?: number
    actions?: BannerAction[]
  }
  nftBanner: {
    show: boolean
    artmineProjectId?: number
  }
  upsells: { [key in UpsellLocation]: UpsellBannerState }
}

const initialUpsellState: UpsellBannerState = {
  show: false,
  contentMode: 'used-up',
  position: 'float-bottom-banner-overlay',
  dismissable: true
}

const initialState: BannerState = {
  generalBanner: {
    show: false,
    message: '',
    actions: undefined,
    autoCloseDuration: 0
  },
  nftBanner: {
    show: false,
    artmineProjectId: undefined
  },
  // generalBanner: {
  //   show: true,
  //   message: 'dadsdad',
  //   actions: [
  //     {
  //       label: 'DISMISS',
  //       onClick: () => {},
  //       variant: 'outlined'
  //     }
  //   ],
  //   autoCloseDuration: 0
  // },
  upsells: {
    clip: { ...initialUpsellState },
    home: { ...initialUpsellState },
    'mix-image': { ...initialUpsellState },
    sketch: { ...initialUpsellState },
    global: { ...initialUpsellState },
    'style-transfer': { ...initialUpsellState },
    'ai-app': { ...initialUpsellState }
  }
}

const reducer = createReducer<BannerState, BannerActions>(initialState)
  .handleAction(bannerActions.show, (state, { payload }) => ({
    ...state,
    generalBanner: {
      ...state.generalBanner,
      message: payload.message,
      actions: payload.actions,
      autoCloseDuration: payload.autoCloseDuration || 0,
      show: true
    }
  }))
  .handleAction(bannerActions.update, (state, { payload }) => ({
    ...state,
    generalBanner: {
      ...state.generalBanner,
      message: payload.message,
      actions: payload.actions
    }
  }))
  .handleAction(bannerActions.close, state => ({
    ...state,
    generalBanner: { ...initialState.generalBanner }
  }))
  .handleAction(bannerActions.upsell.show, (state, { payload }) => ({
    ...state,
    upsells: {
      ...state.upsells,
      [payload.upsellLocation]: {
        ...state.upsells[payload.upsellLocation],
        ...payload,
        show: true
      }
    }
  }))
  .handleAction(bannerActions.upsell.close, (state, { payload }) => ({
    ...state,
    upsells: {
      ...state.upsells,
      [payload.upsellLocation]: {
        ...initialUpsellState,
        show: false
      }
    }
  }))
  .handleAction(bannerActions.nftBanner.show, (state, { payload }) => ({
    ...state,
    nftBanner: {
      show: true,
      artmineProjectId: payload.artmineProjectId
    }
  }))
  .handleAction(bannerActions.nftBanner.close, state => ({
    ...state,
    nftBanner: {
      show: false,
      artmineProjectId: undefined
    }
  }))

const showUpsellEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(bannerActions.upsell.show)),
    tap(({ payload }) => {
      MixPanelUtils.track<'USER__SHOW_UPSELL_BANNER'>('User - Show Upsell Banner', {
        upsell_content_mode: payload.contentMode,
        upsell_location: payload.upsellLocation
      })
    }),
    ignoreElements()
  )
export const epics = combineEpics(showUpsellEpic)

export default reducer
