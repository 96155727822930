import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _get from 'lodash/get'
import _mapValues from 'lodash/mapValues'
import _pick from 'lodash/pick'
import _reduce from 'lodash/reduce'
import { combineEpics, Epic } from 'redux-observable'
import {
  mergeMap,
  map,
  filter,
  concatMap,
  withLatestFrom,
  delay,
  startWith,
  take,
  tap,
  ignoreElements
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { sharedActions, apiActions } from 'duck/ApiDuck'
import { firebaseActions } from 'duck/FirebaseDuck'
import { Equity, User } from 'models/ApiModels'
import Validator, { VALIDATION_MESSAGES, isHasErrors } from 'utils/Validator'
import { ActionType, isActionOf, getType, createAction } from 'typesafe-actions'
import { merge, of } from 'rxjs'
import { appSelectors } from 'duck/AppDuck'
import { AxiosResponse } from 'axios'
import subscriptionReducer, {
  subscriptionEpic,
  SubscriptionState
} from 'containers/SubscriptionPanel/duck'
import { combineReducers } from 'redux'
import { route } from 'routes'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'
import { matchPath } from 'react-router'

export type ProfileForm = Partial<{
  -readonly [P in keyof Pick<User, 'first_name' | 'last_name' | 'portfolio' | 'email'>]-?: User[P]
}>
export type ProfileFromKey = keyof ProfileForm
export type ProfileFormInput = { key: ProfileFromKey; value: string }

// Constants
const NAMESPACE = '@@page/ProfilePage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

type GetSubscriptionButtonDataReturnType = { title: string; disabled?: boolean }
type GetSubscriptionButtonDataParam = {
  type: Equity['type']
  isCanceled: boolean
  isInactive: boolean
}

export const Utils = {
  getSubscriptionButtonData: (
    param: GetSubscriptionButtonDataParam
  ): GetSubscriptionButtonDataReturnType => {
    const { type, isCanceled, isInactive } = param

    const data: {
      [key in Equity['type']]: {
        title: string
      }
    } = {
      studio: {
        title: isCanceled && !isInactive ? 'UPGRADE' : 'MANAGE'
      },
      pro: {
        title: isCanceled && !isInactive ? 'UPGRADE' : 'MANAGE'
      },
      free: {
        title: isInactive ? 'MANAGE' : 'UPGRADE'
      },
      plus: {
        title: isCanceled && !isInactive ? 'UPGRADE' : 'MANAGE'
      }
    }
    return data[type]
  }
}

// Actions
export const actions = {
  logout: createAction(creator('LOGOUT'))(),
  getInitialData: createAction(creator('GET_INITIAL_DATA'))(),
  profile: {
    updateAll: createAction(creator('profile/UPDATE_ALL'))<User>(),
    change: createAction(creator('profile/CHANGE'))<ProfileFormInput>(),
    save: createAction(creator('profile/SAVE'))(),
    setError: createAction(creator('profile/SET_ERROR'))<ProfileFormInput>(),
    setErrors: createAction(creator('profile/SET_ERRORS'))<ProfileForm>()
  }
}

// Selectors
const selectProfilePage = (state: RootState) => state.container.profilePage.root

export const selectors = {
  profilePage: selectProfilePage
}

// Reducer
export type StopResponse = {
  payload: AxiosResponse<any> | false
  projectCount: number
  projectName: string
}
export type ProfileState = {
  stopProjectResponses: Array<StopResponse>
  profile: {
    formData: ProfileForm
    formError: ProfileForm
  }
}

const initial: ProfileState = {
  stopProjectResponses: [],
  profile: {
    formData: {
      portfolio: '',
      first_name: '',
      last_name: '',
      email: ''
    },
    formError: {}
  }
}

const reducer = produce((state: ProfileState, { type, payload }) => {
  switch (type) {
    case getType(actions.profile.updateAll): {
      const value = payload as ActionType<typeof actions.profile.updateAll>['payload']

      state.profile.formData = value
      state.profile.formError = initial.profile.formError
      return
    }
    case getType(actions.profile.change): {
      const { key, value } = payload as ActionType<typeof actions.profile.change>['payload']

      state.profile.formData[key] = value
      state.profile.formError[key] = undefined
      return
    }
    case getType(actions.profile.setError): {
      const { key, value } = payload as ActionType<typeof actions.profile.setError>['payload']

      state.profile.formError[key] = value
      return
    }
    case getType(actions.profile.setErrors): {
      const value = payload as ActionType<typeof actions.profile.setErrors>['payload']

      state.profile.formError = value
      return
    }
    default:
  }
}, initial)

// Epics
const logoutEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.logout)),
    tap(() => {
      MixPanelUtils.track<'USER__LOGOUT'>('User - Logout')
      MixPanelUtils.onLogout()
      SentryUtils.onLogout()
    }),
    mergeMap(() => [firebaseActions.reset(), sharedActions.reset()])
  )

const getInitialDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getInitialData)),
    mergeMap(() => [
      apiActions.payment.braintreeRetrievePaymentMethod(),
      apiActions.payment.retrieveProducts(),
      apiActions.payment.listSubscription(),
      apiActions.users.retrieveEquity(),
      apiActions.projects.retrieveSummary(),
      apiActions.projects.listActiveProjects(),
      apiActions.payment.listCreditChangeLog({
        param: { limit: 15, offset: 0 },
        reloadList: true
      })
    ])
  )

const listenAddCreditSuccessEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    withLatestFrom(state$),
    map(([action, state]) => ({ payload: action.payload, state })),
    map(({ payload, state }) => ({
      addCreditSuccess: payload[AppEvents.ADD_CREDIT_SUCCESS],
      getCreditAfterPhone: payload[AppEvents.GET_CREDIT_AFTER_PHONE_VERIFIED],
      state
    })),
    filter(
      ({ addCreditSuccess, getCreditAfterPhone }) =>
        Boolean(addCreditSuccess) || Boolean(getCreditAfterPhone)
    ),
    map(({ state }) => ({
      location: appSelectors.routerLocation(state)
    })),
    filter(({ location }) =>
      Boolean(matchPath(`${route.PROFILE.getPath(1)}/*`, location?.pathname ?? ''))
    ),
    map(() =>
      apiActions.payment.listCreditChangeLog({
        param: { limit: 15, offset: 0 },
        reloadList: true
      })
    )
  )

const validateEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.profile.change)),
    map(({ payload }) => {
      const { key, value } = payload
      const validateActions: any[] = []
      let changeAction: any = ''

      switch (key) {
        case 'first_name':
          !Validator.required(value) &&
            validateActions.push(
              actions.profile.setError({ key: 'first_name', value: VALIDATION_MESSAGES.required })
            )
          break
        case 'portfolio': {
          const valid = Validator.url(value)
          if (valid) {
            if (value && !(value.startsWith('http://') || value.startsWith('https://'))) {
              changeAction = actions.profile.change({ key: 'portfolio', value: `http://${value}` })
            }
          } else {
            validateActions.push(
              actions.profile.setError({ key: 'portfolio', value: VALIDATION_MESSAGES.url })
            )
          }
          break
        }
        default:
          break
      }

      return { changeAction, validateActions }
    }),
    mergeMap(({ validateActions, changeAction }) =>
      merge(
        of(validateActions).pipe(
          filter(validateActions => Boolean(validateActions.length)),
          concatMap(validateActions => validateActions)
        ),
        of(changeAction).pipe(
          delay(800),
          filter(changeAction => Boolean(changeAction)),
          map(changeAction => changeAction)
        )
      )
    )
  )

const saveProfileEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.profile.save)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      ...selectors.profilePage(state).profile
    })),
    filter(({ formError }) => !isHasErrors(formError)),
    map(({ formData }) => {
      let portfolio = formData.portfolio
      if (portfolio && !(portfolio.startsWith('http://') || portfolio.startsWith('https://'))) {
        portfolio = `http://${portfolio}`
      }

      return { ...formData, portfolio }
    }),
    map(formData => _pick(formData, ['first_name', 'last_name', 'portfolio'])),
    mergeMap(formData =>
      action$.pipe(
        filter(isActionOf(apiActions.users.updateResponse)),
        take(1),
        tap(() => {
          MixPanelUtils.track<'USER__UPDATE_PROFILE'>('User - Update Profile', {
            updated_forms: DataUtils.getObjectKeys(formData)
          })
        }),
        ignoreElements(),
        startWith(apiActions.users.update(formData))
      )
    )
  )

const setErrorEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(({ payload }) => payload.type === getType(apiActions.users.update)),
    map(({ payload }) =>
      _mapValues(_get(payload, 'error.data'), value => {
        return _reduce(value, (result, value) => `${result} ${value}`, '')
      })
    ),
    mergeMap(errors => [
      actions.profile.setErrors(errors),
      sharedActions.resetError(getType(apiActions.users.update))
    ])
  )

export const epics = combineEpics(
  subscriptionEpic,
  logoutEpic,
  listenAddCreditSuccessEpic,
  saveProfileEpic,
  validateEpic,
  setErrorEpic,
  getInitialDataEpic
)

export type ProfilePageState = {
  root: ProfileState
  subscription: SubscriptionState
}

export default combineReducers<ProfilePageState>({
  root: reducer,
  subscription: subscriptionReducer
})
