import { apiActions, UserActionsType, InputsActionType, artMine } from '../actions'
import { SharedActionsType, sharedActions, ErrorBundleType, RequestType } from '../sharedActions'
import {
  PaginationResponse,
  User,
  PermissionsType,
  InputImageSet,
  Equity,
  EquityConfig,
  ConfigVariables,
  Notification,
  MineProject,
  Signature,
  ArtMineConfig,
  NotificationTemplate,
  NotificationPreference
} from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _without from 'lodash/without'
import _uniq from 'lodash/uniq'

import { combineReducers } from 'redux'
import { projectsReducer } from './ProjectsReducers'
import { collectionsReducer } from './CollectionReducers'
import { paymentReducer } from './PaymentReducers'
import { sketchToImageReducer } from './SketchToImageReducers'
import { socialReducer } from './SocialReducers'
import { mixImageReducer } from './MixImageReducers'
import { engineReducer } from './EngineReducers'
import { proArtFilterReducer } from './ProArtFilterReducers'
import { textToImageReducers } from './TextToImageReducers'
import { genericAppReducers } from './GenericAppReducers'
import { imageEnhancementReducers } from './ImageEnhancementReducers'
import { sketchTextToImageReducers } from './SketchTextToImageReducers'
import { ArtMineActionType } from '..'

export * from './CollectionReducers'
export * from './ProjectsReducers'
export * from './PaymentReducers'
export * from './ImageEnhancementReducers'
export * from './SketchToImageReducers'
export * from './SocialReducers'
export * from './MixImageReducers'
export * from './EngineReducers'
export * from './ProArtFilterReducers'

export type UserState = {
  userHasSubscriptionNoPaymentMethod: boolean
  user?: User
  permissions: PermissionsType
  equity?: Equity
  equityRetrieved: boolean
  equityConfigs?: PaginationResponse<EquityConfig>
  configVariables?: ConfigVariables
  notification: {
    list?: number[]
    data: { [id: number]: Notification }
    unreadCount: number
    templateLoading: Record<number, boolean>
    lastReq?: PaginationResponse<Notification>
    notificationTemplates?: PaginationResponse<NotificationTemplate>
    notificationPreference: {
      list?: number[]
      data: { [id: number]: NotificationPreference }
    }
  }
}

const INITIAL_USER_STATE: UserState = {
  userHasSubscriptionNoPaymentMethod: false,
  user: undefined,
  permissions: {},
  equityRetrieved: false,
  equity: undefined,
  equityConfigs: undefined,
  configVariables: undefined,
  notification: {
    list: undefined,
    data: {},
    unreadCount: 0,
    lastReq: undefined,
    templateLoading: {},
    notificationTemplates: undefined,
    notificationPreference: {
      list: undefined,
      data: {}
    }
  }
}

export const usersReducer = createReducer<UserState, UserActionsType | SharedActionsType>(
  INITIAL_USER_STATE
)
  .handleAction(apiActions.users.retrieveResponse, (state, action) => ({
    ...state,
    user: action.payload
  }))
  .handleAction(apiActions.users.setUserHasSubscriptionNoPaymentMethod, (state, action) => ({
    ...state,
    userHasSubscriptionNoPaymentMethod: action.payload
  }))
  .handleAction(apiActions.users.updateResponse, (state, action) => ({
    ...state,
    user: action.payload
  }))
  .handleAction(apiActions.users.retrievePermissionResponse, (state, action) => ({
    ...state,
    permissions: action.payload
  }))
  .handleAction(apiActions.users.retrieveEquityResponse, (state, action) => ({
    ...state,
    equity: action.payload,
    equityRetrieved: true
  }))
  .handleAction(apiActions.users.retrieveEquityConfigResponse, (state, action) => ({
    ...state,
    equityConfigs: action.payload
  }))
  .handleAction(apiActions.users.retrieveConfigVariableResponse, (state, action) => ({
    ...state,
    configVariables: action.payload
  }))
  .handleAction(apiActions.users.listNotificationResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.notification.list ?? [] : []

    return {
      ...state,
      notification: {
        ...state.notification,
        list: _uniq([...prevData, ..._map(results, result => result.id)]),
        data: {
          ...state.notification.data,
          ..._keyBy(results, result => result.id)
        },
        lastReq: data
      }
    }
  })
  .handleAction(apiActions.users.retrieveUnreadNotificationCountResponse, (state, action) => {
    return {
      ...state,
      notification: {
        ...state.notification,
        unreadCount: action.payload
      }
    }
  })
  .handleAction(
    [apiActions.users.retrieveNotificationResponse, apiActions.users.updateNotificationResponse],
    (state, action) => {
      return {
        ...state,
        notification: {
          ...state.notification,
          data: {
            ...state.notification.data,
            [action.payload.id]: action.payload
          }
        }
      }
    }
  )
  .handleAction(apiActions.users.deleteNotificationResponse, (state, action) => {
    const { [action.payload]: deletedNotification, ...notifications } = state.notification.data
    const notificationList = _without(state.notification.list || [], deletedNotification.id)
    return {
      ...state,
      notification: {
        ...state.notification,
        data: notifications,
        list: notificationList
      }
    }
  })
  .handleAction(apiActions.users.listNotificationTemplateResponse, (state, action) => {
    return {
      ...state,
      notification: {
        ...state.notification,
        templateLoading: INITIAL_USER_STATE.notification.templateLoading,
        notificationTemplates: action.payload.data
      }
    }
  })
  .handleAction(apiActions.users.listNotificationPreferencesResponse, (state, action) => {
    return {
      ...state,
      notification: {
        ...state.notification,
        templateLoading: INITIAL_USER_STATE.notification.templateLoading,
        notificationPreference: {
          list: action.payload.data.results?.map(value => value.id) ?? [],
          data:
            action.payload.data.results?.reduce((results, data) => {
              const newResults = { ...results, [data.id]: { ...data } }
              return newResults
            }, {}) ?? {}
        }
      }
    }
  })
  .handleAction(apiActions.users.createNotificationPreferences, (state, action) => {
    return {
      ...state,
      notification: {
        ...state.notification,
        templateLoading: {
          ...state.notification.templateLoading,
          [action.payload.template]: true
        }
      }
    }
  })
  .handleAction(apiActions.users.updateNotificationPreferences, (state, action) => {
    return {
      ...state,
      notification: {
        ...state.notification,
        templateLoading: {
          ...state.notification.templateLoading,
          [action.payload.template]: true
        }
      }
    }
  })
  .handleAction(
    [
      apiActions.users.createNotificationPreferencesResponse,
      apiActions.users.updateNotificationPreferencesResponse
    ],
    (state, action) => {
      const data = action.payload
      const currentData = state.notification.notificationPreference.data

      return {
        ...state,
        notification: {
          ...state.notification,
          templateLoading: {
            ...state.notification.templateLoading,
            [action.payload.template]: false
          },
          notificationPreference: {
            ...state.notification.notificationPreference,
            data: {
              ...currentData,
              [data.id]: { ...data }
            }
          }
        }
      }
    }
  )
  .handleAction(sharedActions.reset, state => ({
    ...INITIAL_USER_STATE,
    configVariables: state.configVariables
  }))

export type InputsState = {
  inputs: {
    [inputsId: number]: InputImageSet
  }
}
export const INITIAL_INPUT_STATE: InputsState = {
  inputs: {}
}
export const inputsReducer = createReducer<InputsState, InputsActionType | SharedActionsType>(
  INITIAL_INPUT_STATE
)
  .handleAction(
    [apiActions.inputs.retrieveResponse, apiActions.inputs.updateResponse],
    (state, action) => ({
      ...state,
      inputs: {
        ...state.inputs,
        [action.payload.id]: action.payload
      }
    })
  )
  .handleAction(apiActions.inputs.moveCollectionResponse, (state, action) => ({
    ...state,
    inputs: {
      ...state.inputs,
      ..._keyBy(action.payload, result => result.id)
    }
  }))
  .handleAction(sharedActions.reset, () => INITIAL_INPUT_STATE)

export type ErrorsType = {
  [key in RequestType]?: ErrorBundleType
}

export type LoadingsType = {
  [key in RequestType]?: boolean | string
}

export type SharedState = {
  errors: ErrorsType
  loadings: LoadingsType
  imageListFetchState: { [key: string]: boolean }
}
export const initialSharedState: SharedState = {
  errors: {},
  loadings: {},
  imageListFetchState: {}
}

export const sharedReducer = createReducer<SharedState, SharedActionsType>(initialSharedState)
  .handleAction(sharedActions.setError, (state, action) => {
    const { type, ...data } = action.payload
    return {
      ...state,
      errors: {
        ...state.errors,
        [type]: data
      }
    }
  })
  .handleAction(sharedActions.setLoading, (state, action) => ({
    ...state,
    loadings: {
      ...state.loadings,
      [action.payload.type]: action.payload.loading
    }
  }))
  .handleAction(sharedActions.setImageListFetchState, (state, action) => ({
    ...state,
    imageListFetchState: {
      ...state.imageListFetchState,
      [action.payload.key]: action.payload.value
    }
  }))
  .handleAction(sharedActions.resetError, (state, action) => {
    const newData: ErrorsType = {}
    if (Array.isArray(action.payload)) {
      action.payload.forEach(actionType => {
        newData[actionType] = undefined
      })
    } else {
      newData[action.payload] = undefined
    }

    return {
      ...state,
      errors: {
        ...state.errors,
        ...newData
      }
    }
  })
  .handleAction(sharedActions.resetAllErrors, state => ({
    ...state,
    errors: {}
  }))

export type ArtMineState = {
  mineProject: {
    list: number[]
    data: Record<number, MineProject>
    lastReq?: PaginationResponse<MineProject>
  }
  mineConfig?: ArtMineConfig
  accounts?: string[]
  signatures: Record<number, Signature>
}
export const INITIAL_ARTMINE_STATE: ArtMineState = {
  mineProject: {
    list: [],
    data: {},
    lastReq: undefined
  },
  mineConfig: undefined,
  accounts: undefined,
  signatures: {}
}

export const artMineReducer = createReducer<ArtMineState, ArtMineActionType>(INITIAL_ARTMINE_STATE)
  .handleAction(
    [artMine.createArtMineProjectResponse, artMine.retrieveProjectResponse],
    (state, action) => {
      return {
        ...state,
        mineProject: {
          ...state.mineProject,
          data: {
            ...state.mineProject.data,
            [action.payload.id]: action.payload
          }
        }
      }
    }
  )
  .handleAction(artMine.listUserArtmineProjectsResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.mineProject.list : []

    return {
      ...state,
      mineProject: {
        list: _uniq([...prevData, ..._map(results, result => result.id)]),
        data: {
          ...state.mineProject.data,
          ..._keyBy(results, result => result.id)
        },
        lastReq: data
      }
    }
  })
  .handleAction(artMine.retrieveConfigResponse, (state, action) => {
    return {
      ...state,
      mineConfig: action.payload
    }
  })
  .handleAction(artMine.retrieveSignatureResponse, (state, action) => {
    const { projectId, signature } = action.payload
    return {
      ...state,
      signatures: {
        ...state.signatures,
        [projectId]: signature
      }
    }
  })
  .handleAction(artMine.setAccount, (state, action) => ({
    ...state,
    accounts: action.payload
  }))

export const reducers = combineReducers({
  artMine: artMineReducer,
  users: usersReducer,
  projects: projectsReducer,
  inputs: inputsReducer,
  collections: collectionsReducer,
  shared: sharedReducer,
  payment: paymentReducer,
  engine: engineReducer,
  sketchToImage: sketchToImageReducer,
  imageEnhancement: imageEnhancementReducers,
  social: socialReducer,
  mixImage: mixImageReducer,
  proArtFilter: proArtFilterReducer,
  textToImage: textToImageReducers,
  genericApp: genericAppReducers,
  sketchTextToImage: sketchTextToImageReducers
})
