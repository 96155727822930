import React, { MouseEventHandler } from 'react'
import clsx from 'clsx'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Slide from '@mui/material/Slide'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Button from 'components/Button'
import ImagePreview from './ImagePreview'
import Banner from 'components/Banner'
import { RootState, RootActionType } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './UpscaleDetail.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  banner: appSelectors.banner.banner(state),
  userDeviceInfo: appSelectors.userDeviceInfo(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeBanner: bannerActions.close
    },
    dispatch
  )

type UpscaleDetailMobileProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  onClose: MouseEventHandler
  open?: boolean
  children: JSX.Element
}

const UpscaleDetailMobile: React.FC<UpscaleDetailMobileProps> = props => {
  const { closeBanner, onClose, banner, open, children, userDeviceInfo } = props
  const isTouchDevice = userDeviceInfo?.summary?.isTouchDevice

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div
        className={clsx(
          'fixed left-0 top-0 flex h-full w-full flex-col bg-light',
          styles.UpscaleDetailMobile
        )}
      >
        <div
          className={clsx(
            'flex min-h-12 items-center pl-2 pr-4',
            stylesGlobal.BorderBottomOnSurface
          )}
        >
          <Button size="small" onClick={onClose} startIcon={<ChevronLeftIcon />}>
            Back
          </Button>
        </div>

        <div>
          <Banner {...banner} mode="dialog" onClose={closeBanner} />
        </div>

        <div className={clsx('pb-2', styles.UpscaleDetailPreviewContainerMobile)}>
          <ImagePreview open={Boolean(open)} isTouchDevice={isTouchDevice} />
        </div>
        {children}
      </div>
    </Slide>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpscaleDetailMobile)
