import React, { useLayoutEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import InputMaterial, { InputProps as InputMaterialProps } from '@mui/material/Input'

import { InputBaseMaskProps } from 'components/Input/InputBaseMask'

import styles from './Input.module.scss'

type InputSizeProps = 'tiny' | 'small' | 'large'
type InputVariantProps = 'filled'

export type InputProps = Omit<InputMaterialProps, 'size'> &
  InputBaseMaskProps & {
    autoWidth?: boolean
    maxWidth?: number
    minWidth?: number
    size?: InputSizeProps
    variant?: InputVariantProps
  }

const setStyles = (size?: InputSizeProps, variant?: InputVariantProps) => {
  let stylesClassName = styles.InputDefault

  if (variant === 'filled') {
    stylesClassName = styles.InputFilled
  }

  if (size === 'tiny') {
    stylesClassName = variant
      ? clsx(stylesClassName, styles.InputTinyWithPaddingY)
      : styles.InputTiny
  }

  return stylesClassName
}

const setClasses = (classes: InputMaterialProps['classes'], variant?: InputVariantProps) => {
  if (!classes && variant !== 'filled') return undefined

  const classesAdjusted = { ...classes }

  if (variant === 'filled') {
    classesAdjusted.input = clsx(classes?.input, styles.InputFilledInput)
  }

  return classesAdjusted
}

const InputAutoWidth: React.FC<InputProps> = props => {
  const { className, value, maxWidth, minWidth = 100, onChange, ...restProps } = props
  const inputHelperRef = useRef<HTMLDivElement>(null)
  const inputHelperRefCurrent = inputHelperRef?.current
  const inputWidthRef = useRef(0)
  const [inputWidthState, setInputWidthState] = useState(0)
  const [valueState, setValueState] = useState(restProps.defaultValue || '')
  const valueAdjusted = typeof value !== 'undefined' ? value : valueState

  useLayoutEffect(() => {
    if (
      (inputHelperRefCurrent?.clientWidth || 0) <= inputWidthRef.current ||
      ((inputHelperRefCurrent?.clientWidth || 0) > inputWidthRef.current &&
        (!maxWidth || (maxWidth && inputWidthRef.current <= maxWidth)))
    ) {
      setInputWidthState(inputHelperRefCurrent?.clientWidth || 0)
      inputWidthRef.current = inputHelperRefCurrent?.clientWidth || 0
    }
  }, [inputHelperRefCurrent, valueAdjusted, minWidth, maxWidth])

  return (
    <>
      <InputMaterial
        {...restProps}
        className={clsx(styles.InputAutoWidth, className)}
        onChange={event => {
          if (typeof value === 'undefined') {
            setValueState(event?.target?.value)
          }

          onChange?.(event)
        }}
        size={undefined}
        style={{
          ...restProps.style,
          width: inputWidthState,
          minWidth,
          maxWidth
        }}
      />
      <div ref={inputHelperRef} className={clsx(styles.InputAutoWidthHelper, className)}>
        <div className={clsx(styles.InputAutoWidthHelperInner, restProps.classes?.input)}>
          {valueAdjusted as string | number}
        </div>
      </div>
    </>
  )
}

const Input: React.FC<InputProps> = props => {
  const {
    // iMask
    autofix,
    blocks,
    definitions,
    eager,
    format,
    lazy,
    mask,
    max,
    min,
    overwrite,
    pattern,
    placeholderChar,
    scale,
    signed,

    //
    autoWidth,
    classes,
    className,
    inputProps,
    minWidth,
    maxWidth,
    size,
    variant,
    value,
    ...restProps
  } = props
  const stylesClassName = setStyles(size, variant)
  const classesAdjusted = setClasses(classes, variant)
  const hasMaskProp =
    autofix ||
    blocks ||
    definitions ||
    eager ||
    format ||
    lazy ||
    mask ||
    max ||
    min ||
    overwrite ||
    pattern ||
    placeholderChar ||
    scale ||
    signed
  const inputPropsAdjusted =
    (!inputProps && hasMaskProp) || inputProps
      ? {
          ...inputProps,
          autofix,
          blocks,
          definitions,
          eager,
          format,
          lazy,
          mask,
          max,
          min,
          overwrite,
          pattern,
          placeholderChar,
          scale,
          signed
        }
      : undefined

  if (autoWidth)
    return (
      <InputAutoWidth
        {...restProps}
        classes={classesAdjusted}
        inputProps={inputPropsAdjusted}
        value={value || undefined}
        className={clsx(className, classesAdjusted?.root, styles.Input, stylesClassName)}
        maxWidth={maxWidth}
        minWidth={minWidth}
      />
    )

  return (
    <InputMaterial
      {...restProps}
      classes={classesAdjusted}
      inputProps={inputPropsAdjusted}
      value={value}
      className={clsx(className, classesAdjusted?.root, styles.Input, stylesClassName)}
    />
  )
}

export default Input
