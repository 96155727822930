import { TrainProject, ActiveProjectStatus } from 'models/ApiModels'
import _compact from 'lodash/compact'
import { Location } from 'history'
import { DropdownItem } from 'components/DropdownMenu'
import {
  TrainProjectActionType,
  SketchProjectActionType,
  ProArtFilterProjectActionType,
  MixProjectActionType,
  AppState,
  TextToImageProjectActionType,
  GenericAppProjectActionType,
  SketchTextProjectActionType
} from 'duck/AppDuck'
import { EXPLORE } from 'routes'

export const AppDuckUtils = {
  getProjectSource: (location?: Location | null): AppState['openProjectSource'] => {
    const path = location?.pathname
    const isExplore = path?.startsWith(EXPLORE)
    if (isExplore) {
      return 'explore'
    } else {
      return 'home'
    }
  }
}

export type ActionOnClickType = (projectId: number) => void
export type SketchProjectActionsType = {
  [key in SketchProjectActionType]?: ActionOnClickType
}
export type MixProjectActionsType = { [key in MixProjectActionType]?: ActionOnClickType }
export type TrainProjectActionsType = { [key in TrainProjectActionType]?: ActionOnClickType }
export type ProArtFilterProjectActionsType = {
  [key in ProArtFilterProjectActionType]?: ActionOnClickType
}

export type TextToImageProjectActionsType = {
  [key in TextToImageProjectActionType]?: ActionOnClickType
}

export type TrainProjectActionListType = DropdownItem<TrainProjectActionType, () => void>[]
export type SketchTextProjectActionListType = DropdownItem<
  SketchTextProjectActionType,
  () => void
>[]
export type SketchProjectActionListType = DropdownItem<SketchProjectActionType, () => void>[]
export type ProArtFilterProjectActionListType = DropdownItem<
  ProArtFilterProjectActionType,
  () => void
>[]

export type TextToImageProjectActionListType = DropdownItem<
  TextToImageProjectActionType,
  () => void
>[]

export type MixProjectActionListType = DropdownItem<MixProjectActionType, () => void>[]

export type GenericAppProjectActionListType = DropdownItem<
  GenericAppProjectActionType,
  () => void
>[]

export type GenericAppProjectActionsType = {
  [key in GenericAppProjectActionType]?: ActionOnClickType
}

export const combineProjectActions = {
  sketch: (param: {
    projectId: number
    projectActionList: SketchProjectActionListType
    projectActions?: SketchProjectActionsType
    showRenameDialog?: boolean
    showSellAsNft?: boolean
  }) =>
    _compact(
      param.projectActionList?.map(value => {
        if (value.key === 'rename' && !param.showRenameDialog) {
          return null
        }

        if (value.key === 'sellAsNft' && !param.showSellAsNft) return null

        return {
          ...value,
          onClick: value.onClick
            ? value.onClick
            : () => param.projectActions?.[value.key]?.(param.projectId ?? 0)
        }
      })
    ),
  style_transfer: (param: {
    projectId: number
    projectActionList: ProArtFilterProjectActionListType
    projectActions?: ProArtFilterProjectActionsType
    showRenameDialog?: boolean
    showSellAsNft?: boolean
  }) =>
    _compact(
      param.projectActionList?.map(value => {
        if (value.key === 'rename' && !param.showRenameDialog) {
          return null
        }

        if (value.key === 'sellAsNft' && !param.showSellAsNft) return null

        return {
          ...value,
          onClick: value.onClick
            ? value.onClick
            : () => param.projectActions?.[value.key]?.(param.projectId ?? 0)
        }
      })
    ),
  text_to_image: (param: {
    projectId: number
    projectActionList: TextToImageProjectActionListType
    projectActions?: ProArtFilterProjectActionsType
    showRenameDialog?: boolean
    showSellAsNft?: boolean
  }) =>
    _compact(
      param.projectActionList?.map(value => {
        if (value.key === 'rename' && !param.showRenameDialog) {
          return null
        }

        if (value.key === 'sellAsNft' && !param.showSellAsNft) return null

        return {
          ...value,
          onClick: value.onClick
            ? value.onClick
            : () => param.projectActions?.[value.key]?.(param.projectId ?? 0)
        }
      })
    ),
  mix: (param: {
    projectId: number | undefined | null
    projectActionList: MixProjectActionListType
    projectActions?: MixProjectActionsType
    showRenameDialog?: boolean
    showSellAsNft?: boolean
  }) =>
    _compact(
      param.projectActionList?.map(value => {
        if (value.key === 'rename' && !param.showRenameDialog) {
          return null
        }

        if (value.key === 'sellAsNft' && !param.showSellAsNft) return null

        return {
          ...value,
          onClick: value.onClick
            ? value.onClick
            : () => param.projectActions?.[value.key]?.(param.projectId ?? 0)
        }
      })
    ),
  train: (param: {
    project: Pick<TrainProject, 'id' | 'is_private' | 'isProjectOwner' | 'status'> | null
    projectActionList: TrainProjectActionListType
    projectActions?: TrainProjectActionsType
    isCurrentUserStaff?: boolean
    noCurrentUser?: boolean
    showRenameDialog?: boolean
    showSellAsNft?: boolean
  }) => {
    const {
      project,
      projectActionList,
      projectActions,
      isCurrentUserStaff,
      noCurrentUser,
      showRenameDialog,
      showSellAsNft
    } = param
    const isPrivate = project?.is_private
    const isProjectOwner = project?.isProjectOwner
    const isProjectOwnerOrStaff = isProjectOwner || isCurrentUserStaff
    const isProjectActive = ActiveProjectStatus.includes(project?.status ?? 'drafted')
    const isProjectDraft = project?.status === 'drafted'

    return _compact(
      projectActionList?.map(value => {
        const onClick = value.onClick
          ? value.onClick
          : () => projectActions?.[value.key]?.(project?.id ?? 0)

        if (value.key === 'changeScope') {
          return isProjectOwnerOrStaff
            ? {
                ...value,
                disabled: isProjectActive || isProjectDraft,
                label: !isPrivate ? 'Make Private' : value.label,
                onClick
              }
            : null
        }
        if (value.key === 'deleteProject' && isProjectOwner) {
          return {
            ...value,
            disabled: isProjectActive,
            onClick
          }
        }
        if (value.key === 'deleteProject' && (!isProjectOwner || noCurrentUser)) {
          return null
        }
        if (value.key === 'duplicate' && (!isProjectOwner || noCurrentUser)) {
          return null
        }
        if (value.key === 'rename' && !showRenameDialog) {
          return null
        }
        if (value.key === 'sellAsNft' && !showSellAsNft) return null

        return { ...value, onClick }
      })
    )
  },
  genericApp: (param: {
    projectId: number
    projectActionList: GenericAppProjectActionListType // TextToImageProjectActionListType
    projectActions?: GenericAppProjectActionsType
    showRenameDialog?: boolean
    showSellAsNft?: boolean
  }) =>
    _compact(
      param.projectActionList?.map(value => {
        if (value.key === 'rename' && !param.showRenameDialog) {
          return null
        }

        if (value.key === 'sellAsNft' && !param.showSellAsNft) return null

        return {
          ...value,
          onClick: value.onClick
            ? value.onClick
            : () => param.projectActions?.[value.key]?.(param.projectId ?? 0)
        }
      })
    )
}
