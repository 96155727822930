import { RootState } from 'duck'
import { createSelector } from 'reselect'
import { CollectionSelectorPanelState } from './reducers'

// Selectors
const selectCollectionSelectorPanel = (state: RootState) =>
  state.container.trainProjectPage.collectionSelectorPanel

const selectImageSetFromProjectPage = (projectPage: CollectionSelectorPanelState) =>
  projectPage.selectedImageSet || 'inspiration'

const selectSelectedImageSet = createSelector(selectCollectionSelectorPanel, projectPage =>
  selectImageSetFromProjectPage(projectPage)
)
const selectMyCollection = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage.myCollection
)
const selectHideCollFooter = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage.hideCollFooter
)

const selectOpenUploadWindow = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage.openUploadWindow
)

const selectCollectionSearchLoading = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage.searchLoading
)
const selectPublicCollection = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage.publicCollection
)
const selectBookmarkCollection = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage.bookmarkedCollection
)
const selectCurrentTab = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage?.currentTab
)
const selectOpenedCollection = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage?.openedCollection
)

const selectIsSheetBarExpanded = createSelector(
  selectCollectionSelectorPanel,
  projectPage => projectPage?.isSheetBarExpanded
)

export const selectors = {
  collectionSelectorPanel: selectCollectionSelectorPanel,
  currentTab: selectCurrentTab,
  openUploadWindow: selectOpenUploadWindow,
  hideCollFooter: selectHideCollFooter,

  selectedImageSet: selectSelectedImageSet,
  imageSetFromProjectPage: selectImageSetFromProjectPage,
  collectionSearchLoading: selectCollectionSearchLoading,
  myCollection: selectMyCollection,
  publicCollection: selectPublicCollection,
  bookmarkCollection: selectBookmarkCollection,
  openedCollection: selectOpenedCollection,
  isSheetBarExpanded: selectIsSheetBarExpanded
}
