import React from 'react'
import { appActions, appSelectors } from 'duck/AppDuck'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RootActionType, RootState } from 'duck'
import { dialogActions, FormDialog } from 'duck/AppDuck/DialogDuck'
import Dialog from 'components/Dialog'
import Button from 'components/Button'
import styles from './Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'
import { TextTransform } from 'utils/TextUtils'
import { apiSelectors } from 'duck/ApiDuck'

const DIALOG_STYLE = {
  zIndex: 1401
}

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state),
  activeDialogOverlay: appSelectors.dialog.activeDialogOverlay(state),
  currentUserAddress: apiSelectors.currentUserAddress(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      disconnect: appActions.metamask.disconnect,
      closeDialog: dialogActions.closeDialog
    },
    dispatch
  )

type MetamaskWalletProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const MetamaskWallet: React.FC<MetamaskWalletProps> = ({
  closeDialog,
  disconnect,
  activeDialogOverlay,
  activeDialog,
  currentUserAddress
}) => {
  const showDialog =
    activeDialog === FormDialog.METAMASK_WALLET ||
    activeDialogOverlay === FormDialog.METAMASK_WALLET

  return (
    <Dialog
      open={showDialog}
      scroll="body"
      style={DIALOG_STYLE}
      onClose={() => {
        closeDialog()
      }}
      actionContent={
        <Button
          color="error"
          onClick={() => {
            disconnect()
            closeDialog()
          }}
          fullWidth
        >
          DISCONNECT WALLET
        </Button>
      }
      contentDividerBottom
      disableActionSpacing
    >
      <div className={styles.MetamaskWalletDialogContent}>
        <Typography variant="overline" color={'gray'}>
          Connected To:
        </Typography>
        <Typography variant="h5">{TextTransform.trimAddress(currentUserAddress)}</Typography>
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MetamaskWallet)
