import React from 'react'
import Helmet, { HelmetProps } from 'react-helmet'
import Logo from 'assets/images/playform-logo.svg'

type SEOHelmetProps = {
  children?: HelmetProps['children']
  description?: string
  image?: string
  link?: HelmetProps['link']
  title?: string
  url?: string
}

const INITIAL_CONTENT = {
  description:
    'Harness the power of artificial intelligence to expand your imagination and productivity, without learning how to code.',
  title: 'Playform'
}

const SEOHelmet: React.FC<SEOHelmetProps> = props => {
  const {
    children,
    description = INITIAL_CONTENT.description,
    image = Logo,
    link,
    title = INITIAL_CONTENT.title,
    url = window.location.href
  } = props

  return (
    <Helmet link={link}>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} /> : null
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      {children}
    </Helmet>
  )
}

export default SEOHelmet
