import React from 'react'
import { twMerge } from 'tailwind-merge'

import { ImgPure } from 'components/Img'

import emptyThumbnail from 'assets/images/empty-thumbnail.svg'

type ImgEmptyThumbnailProps = {
  className?: string
}

const ImgEmptyThumbnail: React.FC<ImgEmptyThumbnailProps> = props => {
  return (
    <ImgPure
      {...props}
      className={twMerge(
        'pointer-events-none h-full w-full select-none object-scale-down object-center',
        props.className
      )}
      src={emptyThumbnail}
    />
  )
}

export default ImgEmptyThumbnail
