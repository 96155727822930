import React from 'react'
import clsx from 'clsx'
import CreditCardIcon from 'components/Icon/CreditCardIcon'
import { twMerge } from 'tailwind-merge'
import { ImgPure } from 'components/Img'

import styles from './Icon.module.scss'
import visaIcon from 'assets/images/cc-icon/visa.png'
import mastercardIcon from 'assets/images/cc-icon/mastercard.png'
import unionpayIcon from 'assets/images/cc-icon/union-pay.png'
import dinersIcon from 'assets/images/cc-icon/dinersclub.png'
import discoverIcon from 'assets/images/cc-icon/discover.png'
import jcbIcon from 'assets/images/cc-icon/jcb.png'
import amexIcon from 'assets/images/cc-icon/americanexpress.png'
import maestroIcon from 'assets/images/cc-icon/maestro.png'

const cardIcon = {
  visa: visaIcon,
  mastercard: mastercardIcon,
  americanexpress: amexIcon,
  dinersclub: dinersIcon,
  discover: discoverIcon,
  jcb: jcbIcon,
  unionpay: unionpayIcon,
  maestro: maestroIcon
  // [creditCardType.types.ELO]: undefined,
  // [creditCardType.types.MIR]: undefined,
  // [creditCardType.types.HIPER]: undefined,
  // [creditCardType.types.HIPERCARD]: undefined
} as const

type CardNameType = keyof typeof cardIcon // typeof cardIcon[string] // keyof typeof cardIcon

export type CreditCardBrandIconProps = {
  className?: string
  type?: string
}

const CreditCardBrandIcon: React.FC<CreditCardBrandIconProps> = ({ className, type }) => {
  const brandText = type
  const brandId = type ? (type.toLowerCase().replace(/-|\s/g, '') as CardNameType) : ''

  if (brandId) {
    const icon = cardIcon[brandId]

    if (icon) {
      return (
        <ImgPure
          src={icon}
          title={brandText}
          alt={brandText}
          className={twMerge(
            'pointer-events-none select-none',
            styles.CreditCardInfoIcon,
            className
          )}
        />
      )
    }
  }

  return <CreditCardIcon alt={brandText} className={clsx(styles.CreditCardInfoIcon, className)} />
}

export default CreditCardBrandIcon
