import { apiActions, MixImageActionType } from '../actions'
import { SharedActionsType } from '../sharedActions'
import {
  PaginationResponse,
  SpaceImage,
  MixImageListResponse,
  MixImageProject,
  UploadImage,
  MixImageGenreListResponse
} from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _without from 'lodash/without'
import _uniq from 'lodash/uniq'

import { GetText } from 'utils/TextUtils'

export type MixImageState = {
  currentMixImageId?: number
  spaceImage: {
    currentFetchState: {
      [key: string]: boolean
    }
    spaceImageList: number[]
    spaceImageMap: {
      [id: string]: number
    }
    spaceImages: {
      [id: number]: SpaceImage
    }
    originImagesSpaceMap: {
      [spaceKey: string]: number //refer to UserImages
    }
  }
  mixImageGenres: MixImageGenreListResponse | null
  mixImageProject: {
    list: number[]
    data: {
      [id: number]: MixImageProject
    }
    lastReq?: MixImageListResponse
  }
  uploadImages: {
    data: {
      [uploadImageId: number]: UploadImage
    }
    list: number[]
    lastReq?: PaginationResponse<UploadImage>
  }
}

export const initialMixImageState: MixImageState = {
  currentMixImageId: undefined,
  spaceImage: {
    currentFetchState: {},
    spaceImageList: [],
    spaceImageMap: {},
    spaceImages: {},
    originImagesSpaceMap: {}
  },
  mixImageGenres: null,
  mixImageProject: {
    list: [],
    data: {},
    lastReq: undefined
  },

  uploadImages: {
    data: {},
    list: [],
    lastReq: undefined
  }
}

export const mixImageReducer = createReducer<MixImageState, MixImageActionType | SharedActionsType>(
  initialMixImageState
)
  .handleAction(apiActions.mixImage.setCurrentMixProject, (state, action) => {
    const id = action?.payload
    return {
      ...state,
      currentMixImageId: id
    }
  })
  .handleAction(apiActions.mixImage.setSpaceImageFetchState, (state, action) => {
    const payload = action.payload
    const key = GetText.spaceImageNaturalKey(payload)
    return {
      ...state,
      spaceImage: {
        ...state.spaceImage,
        currentFetchState: {
          ...state.spaceImage.currentFetchState,
          [key]: true
        }
      }
    }
  })
  .handleAction(apiActions.mixImage.retrieveSpaceImageResponse, (state, action) => {
    const { data, req } = action.payload
    const key = GetText.spaceImageNaturalKey(req)
    return {
      ...state,
      spaceImage: {
        ...state.spaceImage,
        spaceImageMap: {
          ...state.spaceImage.spaceImageMap,
          [GetText.spaceImageNaturalKey(req)]: data.id
        },
        currentFetchState: {
          ...state.spaceImage.currentFetchState,
          [key]: false
        },
        spaceImageList:
          data.level === 0
            ? [data.id, ...state.spaceImage.spaceImageList]
            : state.spaceImage.spaceImageList,
        spaceImages: {
          ...state.spaceImage.spaceImages,
          [data.id]: data
        }
      }
    }
  })
  .handleAction(apiActions.mixImage.retrieveOriginImageResponse, (state, action) => {
    const { data, req } = action.payload
    const key = GetText.originImageKey(req)
    return {
      ...state,
      spaceImage: {
        ...state.spaceImage,
        originImagesSpaceMap: {
          ...state.spaceImage.originImagesSpaceMap,
          [key]: data.id
        }
      }
    }
  })
  .handleAction(apiActions.mixImage.listMixImageResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.mixImageProject.list : []

    return {
      ...state,
      mixImageProject: {
        list: _uniq([...prevData, ..._map(results, result => result.id)]),
        data: {
          ...state.mixImageProject.data,
          ..._keyBy(results, result => result.id)
        },
        lastReq: data
      }
    }
  })
  .handleAction(apiActions.mixImage.createMixImageResponse, (state, action) => {
    const mixImageProject = action.payload
    const prevData = state.mixImageProject.list ?? []

    return {
      ...state,
      mixImageProject: {
        ...state.mixImageProject,
        list: _uniq([mixImageProject.id, ...prevData]),
        data: {
          ...state.mixImageProject.data,
          [mixImageProject.id]: mixImageProject
        }
      }
    }
  })
  .handleAction(apiActions.mixImage.deleteMixImageResponse, (state, action) => {
    const { [action.payload]: deletedProject, ...data } = state.mixImageProject.data
    const list = _without(state.mixImageProject.list || [], deletedProject.id)

    return {
      ...state,
      mixImageProject: {
        ...state.mixImageProject,
        list,
        data
      }
    }
  })
  .handleAction(apiActions.mixImage.listMixImageGenreResponse, (state, action) => {
    return {
      ...state,
      mixImageGenres: action.payload
    }
  })
  .handleAction(
    [
      apiActions.mixImage.updateMixImageResponse,
      apiActions.mixImage.generateMixImageResponse,
      apiActions.mixImage.generateMixImagePreviewResponse,
      apiActions.mixImage.retrieveMixImageResponse
    ],
    (state, action) => {
      const mixImageProject = action.payload
      return {
        ...state,
        mixImageProject: {
          ...state.mixImageProject,
          data: {
            ...state.mixImageProject.data,
            [mixImageProject.id]: mixImageProject
          }
        }
      }
    }
  )
  .handleAction(apiActions.mixImage.listUploadImageResponse, (state, action) => {
    const { data, next } = action.payload
    const results = data.results || []
    const prevData = next ? state.uploadImages.list : []

    return {
      ...state,
      uploadImages: {
        list: _uniq([...prevData, ..._map(results, result => result.id)]),
        lastReq: data,
        data: {
          ...state.uploadImages.data,
          ..._keyBy(results, result => result.id)
        }
      }
    }
  })
  .handleAction(apiActions.mixImage.createUploadImageResponse, (state, action) => {
    const { data } = action.payload

    return {
      ...state,
      uploadImages: {
        ...state.uploadImages,
        list: [data.id, ...state.uploadImages.list],
        data: {
          ...state.uploadImages.data,
          [data.id]: data
        }
      }
    }
  })
  .handleAction(apiActions.mixImage.resetMixImageQueueData, (state, action) => {
    const payload = action.payload
    const currentProject = state.mixImageProject.data[payload]
    return currentProject
      ? {
          ...state,
          mixImageProject: {
            ...state.mixImageProject,
            data: {
              ...state.mixImageProject.data,
              [payload]: {
                ...currentProject,
                queue_length: undefined,
                expect_finished: undefined
              }
            }
          }
        }
      : state
  })
  .handleAction(apiActions.mixImage.retrieveUploadImageResponse, (state, action) => {
    return {
      ...state,
      uploadImages: {
        ...state.uploadImages,
        data: {
          ...state.uploadImages.data,
          [action.payload.id]: action.payload
        }
      }
    }
  })
  .handleAction(apiActions.mixImage.deleteUploadImageResponse, (state, action) => {
    const { [action.payload]: deletedImage, ...data } = state.uploadImages.data
    const list = _without(state.uploadImages.list || [], deletedImage.id)

    return {
      ...state,
      uploadImages: {
        ...state.uploadImages,
        data,
        list
      }
    }
  })
