import React, { Fragment } from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { connect } from 'react-redux'
import Dialog, { DialogProps } from 'components/Dialog'
import { appSelectors } from 'duck/AppDuck'
import { bindActionCreators, Dispatch } from 'redux'
import { RootState, RootActionType } from 'duck'
import { brainTreePaymentActions, brainTreePaymentSelector, selectors } from './duck'
import Banner from 'components/Banner'
import { useBreakpoint } from 'utils/Hooks'
import Button from 'components/Button'
import { addCreditActions } from './duck/AddCredit'
import { trainingFormActions } from './duck/TrainingForm'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import styles from './MonetizationDialog.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state),
  hasMultipleDialog: appSelectors.dialog.hasMultipleDialog(state),
  addCredit: selectors.addCredit(state),
  cardLoading: brainTreePaymentSelector.brainTreePayment(state).cardLoading,
  shouldDisplayBackButton: selectors.shouldDisplayBackButton(state),
  banner: appSelectors.banner.banner(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      closeBanner: bannerActions.close,
      resetAddCreditForm: addCreditActions.resetForm,
      resetTrainingForm: trainingFormActions.resetForm,
      resetData: brainTreePaymentActions.resetData
    },
    dispatch
  )

export type MonetizationDialogTemplateProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
> &
  DialogProps & { onClose: () => void }

const MonetizationDialogTemplate: React.FC<MonetizationDialogTemplateProps> = props => {
  const {
    resetAddCreditForm,
    resetTrainingForm,
    resetData,
    closeDialog,
    closeBanner,
    onClose,

    cardLoading,
    shouldDisplayBackButton,
    hasMultipleDialog,
    addCredit,
    activeDialog,
    banner,
    open,
    classes,
    children,
    titleContent,
    actionContent,
    contentDividers,
    contentDividerTop,
    contentDividerBottom,
    disableActionSpacing,
    disableActionTopSpacing,
    scroll,
    ...dialogProps
  } = props

  const { isSmallscreenAbove } = useBreakpoint()

  const paymentLoading = addCredit.paymentLoading

  return (
    <Dialog
      open={open}
      scroll={isSmallscreenAbove ? scroll : undefined}
      classes={classes}
      transition={!isSmallscreenAbove ? 'slideLeft' : undefined}
      onClose={() => {
        onClose?.()
        resetData()
        resetTrainingForm()
        resetAddCreditForm()
      }}
      titleContent={
        <Fragment>
          {shouldDisplayBackButton && (
            <Button
              className={styles.BtnBack}
              size="small"
              onClick={() => closeDialog()}
              startIcon={<KeyboardArrowLeftIcon />}
            >
              BACK
            </Button>
          )}

          {shouldDisplayBackButton ? (
            <div className={styles.TitleCenter}>{titleContent}</div>
          ) : (
            titleContent
          )}
        </Fragment>
      }
      actionContent={actionContent}
      disableEscapeKeyDown={Boolean(paymentLoading || cardLoading)}
      fullScreen={!isSmallscreenAbove}
      contentDividers={contentDividers}
      contentDividerTop={contentDividerTop}
      contentDividerBottom={contentDividerBottom}
      disableActionSpacing={disableActionSpacing}
      disableActionTopSpacing={disableActionTopSpacing}
      disableContentSpacing
      disableBackdropClick
      {...dialogProps}
    >
      <Banner {...banner} mode="dialog" onClose={closeBanner} />
      {children}
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MonetizationDialogTemplate)
