import produce from 'immer'
import { TextTransform } from 'utils/TextUtils'
import { combineEpics, Epic } from 'redux-observable'
import _slice from 'lodash/slice'
import _map from 'lodash/map'
import _get from 'lodash/get'
import {
  filter,
  withLatestFrom,
  map,
  mergeMap,
  take,
  startWith,
  concatMap,
  delay,
  tap
} from 'rxjs/operators'
import { of, merge } from 'rxjs'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { apiActions, apiSelectors, sharedActions, ErrorBundleType } from 'duck/ApiDuck'
import { errorUtils } from 'utils/DataProcessingUtils'
import { errorMessage, values } from 'appConstants'
import { createSelector } from 'reselect'
import { dialogActions, ErrorDialog, InformationDialog } from 'duck/AppDuck/DialogDuck'
import { UploadProgressType, UploadErrorType, EngineConfigKeyType } from 'models/ApiModels'
import SentryUtils from 'utils/SentryUtils'

// Constants
const NAMESPACE = '@@page/ResultPage/GeneratePanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)
const UPLOAD_LIMIT = 30
const POOL_INFERENCE_INTERVAL = 5000
const POOL_INFERENCE_INTERVAL_2 = 8000 // When there an error while fetching

// Actions
export const actions = {
  initInference: createAction(creator('INIT_INFERENCE'))(),
  selectImage: createAction(creator('SELECT_IMAGE'))<number>(),
  download: createAction(creator('DOWNLOAD'))(),
  downloadUpscaled: createAction(creator('DOWNLOAD_UPSCALED'))<string>(),
  startOver: createAction(creator('START_OVER'))<{ project: number }>(),
  setCurrentProjectCategory: createAction(
    creator('SET_CURRENT_PROJECT_CATEGORY')
  )<ResolutionOptionsKey>(),
  setInferenceRecord: createAction(creator('SET_INFERENCE_RECORD'))<{
    id: number
    project: number
  }>(),
  startInference: createAction(creator('START_INFERENCE'))(),
  retrieveInferenceProgress: createAction(creator('RETRIEVE_INFERENCE_PROGRESS'))<number>(),
  upload: {
    receivedImages: createAction(creator('UPLOAD/RECEIVED_IMAGES'))<File[]>(),
    uploadImages: createAction(creator('UPLOAD/UPLOAD_IMAGES'))<{
      images: File[]
      inferenceId: number
    }>(),
    onFileInvalid: createAction(creator('UPLOAD/ON_FILE_INVALID'))<UploadErrorType>(),
    onUploadSuccess: createAction(creator('UPLOAD/ON_UPLOAD_SUCCESS'))(),
    onUploadFailed: createAction(creator('UPLOAD/ON_UPLOAD_FAILED'))<UploadErrorType>(),
    onUploadFinished: createAction(creator('UPLOAD/ON_UPLOAD_FINISHED'))(),
    showUploadError: createAction(creator('UPLOAD/SHOW_UPLOAD_ERROR'))()
  },
  updateGenerateOpened: createAction(creator('UPDATE_GENERATE_OPENED'))(),
  setResolution: createAction(creator('SET_RESOLUTION'))<string>()
}

// Selectors
const selectGeneratePanel = (state: RootState) => state.container.trainProjectPage.generatePanel

const selectActiveInferences = createSelector(selectGeneratePanel, generatePanel => {
  return generatePanel.activeInferences
})
const selectCurrentInference = createSelector(
  selectActiveInferences,
  apiSelectors.currentProjectId,
  apiSelectors.inferences,
  apiSelectors.userImages,
  (activeInferences, currentProjectId, inferences, userImages) => {
    const inferenceId = activeInferences[currentProjectId]
    if (inferenceId) {
      const currentInference = inferences[inferenceId]
      return {
        ...currentInference,
        outputs: currentInference.outputs?.map(output => userImages[output.id]),
        inputs: currentInference.inputs?.map(input => userImages[input.id])
      }
    }
    return undefined
  }
)
const selectCurrentInferenceId = createSelector(
  selectActiveInferences,
  apiSelectors.currentProjectId,
  (activeInferences, currentProjectId) => {
    return activeInferences[currentProjectId]
  }
)
const selectSelectedInferenceOutput = createSelector(
  selectCurrentInference,
  selectGeneratePanel,
  (currentInference, generatePanel) => {
    const selectedImage = generatePanel.selectedImage
    const outputs = currentInference?.outputs ?? []

    return outputs[selectedImage]
  }
)

const selectIsCanDownloadSelectedInference = createSelector(
  selectSelectedInferenceOutput,
  selectedInferenceOutput => Boolean(selectedInferenceOutput)
)

export const selectors = {
  generatePanel: selectGeneratePanel,
  uploadProgress: createSelector(selectGeneratePanel, generatePanel => {
    const { total, success, failed } = generatePanel.uploadProgress
    const isFinished = total > 0 && total <= success + failed
    const percentage = total > 0 ? ((success + failed) / total) * 100 : 0
    return {
      ...generatePanel.uploadProgress,
      isFinished,
      percentage
    }
  }),
  resolution: createSelector(selectGeneratePanel, generatePanel => generatePanel.resolution),
  resolutionOptions: createSelector(selectGeneratePanel, generatePanel => {
    return (
      generatePanel.resolutionOptions[generatePanel.currentProjectCategory ?? 'progressive'] ?? []
    )
  }),
  selectedImage: createSelector(selectGeneratePanel, generatePanel => generatePanel.selectedImage),
  currentInference: selectCurrentInference,
  currentInferenceId: selectCurrentInferenceId,
  isInferenceFinished: createSelector(selectCurrentInference, currentInference => {
    return currentInference?.status === 'ready' || currentInference?.status === 'failed'
  }),
  isInferenceFailed: createSelector(selectCurrentInference, currentInference => {
    return currentInference?.status === 'failed'
  }),
  hasCurrentInference: createSelector(selectCurrentInference, currentInference =>
    Boolean(currentInference)
  ),
  isCanDownloadSelectedInference: selectIsCanDownloadSelectedInference,
  selectedInferenceOutput: selectSelectedInferenceOutput
}

export type ResolutionOptionsKey = EngineConfigKeyType | 'style_transfer_old'

export type ResolutionOptionsType = {
  value: string
  label: string
  caption?: string
  disabled?: boolean
}

export type GeneratePanelState = {
  selectedImage: number
  activeInferences: {
    [projectId: number]: number | undefined
  }
  uploadProgress: UploadProgressType
  resolution: string
  currentProjectCategory?: ResolutionOptionsKey
  resolutionOptions: { [key in ResolutionOptionsKey]?: ResolutionOptionsType[] }
}
const initial: GeneratePanelState = {
  selectedImage: 0,
  activeInferences: {},
  resolution: 'R512',
  uploadProgress: {
    uploading: false,
    total: 0,
    success: 0,
    failed: 0,
    errors: []
  },
  currentProjectCategory: undefined,
  resolutionOptions: {
    style_transfer_old: [
      {
        value: 'R256',
        label: '256 X 256 px',
        caption: 'Best Generation Quality'
      },
      {
        value: 'R512',
        label: '512 X 512 px',
        caption: 'Large Output',
        disabled: true
      }
    ],
    style_transfer: [
      {
        value: 'R512',
        label: '512 X 512 px',
        caption: 'Best Generation Quality'
      },
      {
        value: 'R1024',
        label: '1024 X 1024 px',
        caption: 'Large Output'
      }
    ],
    morph: [
      {
        value: 'R256',
        label: '256 X 256 px',
        caption: 'Best Generation Quality'
      },
      {
        value: 'R512',
        label: '512 X 512 px',
        caption: 'Large Output'
      }
    ],
    morph_512: [
      {
        value: 'R512',
        label: '512 X 512 px',
        caption: 'Best Generation Quality'
      },
      {
        value: 'R1024',
        label: '1024 X 1024 px',
        caption: 'Large Output'
      }
    ]
  }
}

const reducer = produce((state: GeneratePanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setResolution): {
      state.resolution = payload
      return
    }
    case getType(actions.selectImage): {
      state.selectedImage = payload
      return
    }
    case getType(actions.setInferenceRecord): {
      const { id, project } = payload
      state.activeInferences[project] = id
      return
    }
    case getType(actions.startOver): {
      const { project } = payload
      state.activeInferences[project] = undefined
      state.uploadProgress = { ...initial.uploadProgress }
      state.selectedImage = initial.selectedImage
      return
    }
    case getType(actions.upload.uploadImages): {
      const payloadTyped = payload as ActionType<typeof actions.upload.uploadImages>['payload']

      const total = payloadTyped.images.length ?? 0
      state.uploadProgress = { ...initial.uploadProgress, total, uploading: true }
      return
    }
    case getType(actions.upload.onUploadFailed): {
      state.uploadProgress.failed += 1
      state.uploadProgress.errors = [...state.uploadProgress.errors, payload]
      return
    }
    case getType(actions.upload.onUploadSuccess): {
      state.uploadProgress.success += 1
      return
    }
    case getType(actions.upload.onUploadFinished): {
      state.uploadProgress.uploading = false
      return
    }
    case getType(actions.setCurrentProjectCategory): {
      const value = payload as ActionType<typeof actions.setCurrentProjectCategory>['payload']

      state.currentProjectCategory = value
      return
    }
    default:
  }
}, initial)

// Epics
type GeneratePanelActionType = RootActionType | ActionType<typeof actions>

const initInferenceEpic: Epic<GeneratePanelActionType, GeneratePanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.initInference)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      currentProject: apiSelectors.currentProject(state),
      currentProjectSampleOutput: apiSelectors.currentProjectSampleOutput(state),
      resolutionOptions: selectGeneratePanel(state).resolutionOptions
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ currentProject }) => currentProject.category !== 'style_transfer'),
          map(({ currentProject }) => currentProject.category as ResolutionOptionsKey),
          mergeMap(category => [
            actions.setCurrentProjectCategory(category),
            actions.setResolution(param.resolutionOptions[category]?.[0]?.value ?? '')
          ])
        ),
        of(param).pipe(
          filter(
            ({ currentProject, currentProjectSampleOutput }) =>
              currentProject.category === 'style_transfer' && Boolean(currentProjectSampleOutput)
          ),
          map(({ currentProjectSampleOutput }) => ({
            size: Math.max(
              currentProjectSampleOutput?.width ?? 0,
              currentProjectSampleOutput?.height ?? 0
            )
          })),
          map(({ size }) => (size > 500 ? 'style_transfer' : 'style_transfer_old')),
          mergeMap(category => [
            actions.setCurrentProjectCategory(category),
            actions.setResolution(param.resolutionOptions[category]?.[0]?.value ?? '')
          ])
        ),
        of(param).pipe(
          filter(
            ({ currentProject, currentProjectSampleOutput }) =>
              currentProject.category === 'style_transfer' && !Boolean(currentProjectSampleOutput)
          ),
          mergeMap(({ currentProject }) =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.retrieveOutputImagesResponse)),
              take(1),
              map(action => actions.initInference()),
              startWith(
                apiActions.projects.retrieveOutputImages({
                  data: {
                    project: currentProject.id,
                    batch: 0,
                    ordering: 'batch'
                  },
                  next: false
                })
              )
            )
          )
        )
      )
    )
  )

const receivedImagesEpic: Epic<GeneratePanelActionType, GeneratePanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.upload.receivedImages)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const currentProject = apiSelectors.currentProject(state)
      const resolution = selectors.resolution(state) || undefined
      const isOutsideLimit = action.payload.length > UPLOAD_LIMIT
      const imagesSliced = _slice(action.payload, 0, UPLOAD_LIMIT)

      return {
        project: currentProject?.id ?? 0,
        resolution,
        images: imagesSliced,
        isOutsideLimit
      }
    }),
    //Don't continue if condition not fullfiled
    filter(({ project }) => Boolean(project)),
    mergeMap(({ project, resolution, images, isOutsideLimit }) =>
      // First step, create inference record
      action$.pipe(
        filter(isActionOf(apiActions.projects.createInferenceRecordResponse)),
        take(1),
        mergeMap(action =>
          merge(
            //Show notice dialog when uploaded files more than 30
            of(action).pipe(
              filter(() => isOutsideLimit),
              map(() =>
                dialogActions.openDialog({
                  [InformationDialog.INFORMATION]: {
                    dialogName: InformationDialog.INFORMATION,
                    content:
                      'We have only uploaded the first 30 images that you attempted to drop in.'
                  }
                })
              )
            ),
            //Continue to Upload and set active inference
            of(action).pipe(
              mergeMap(({ payload }) => [
                actions.upload.uploadImages({ images, inferenceId: payload.id }),
                actions.setInferenceRecord({ id: payload.id, project })
              ])
            )
          )
        ),
        startWith(apiActions.projects.createInferenceRecord({ project, resolution }))
      )
    )
  )

const uploadImagesEpic: Epic<GeneratePanelActionType, GeneratePanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.upload.uploadImages)),
    map(action => action.payload),
    map(({ images, inferenceId }) =>
      _map(images, image => {
        if (image.size > values.MAX_IMAGE_SIZE) {
          const error = errorMessage.ERROR_UPLOAD_TOO_LARGE.content
          return actions.upload.onFileInvalid({ error, image })
        } else {
          return apiActions.projects.addInferenceInput({
            image,
            id: inferenceId
          })
        }
      })
    ),
    mergeMap((uploadActions: any[]) => uploadActions)
  )

/* Listen when upload is succeed
 *  -  Dispatch success action
 */
const listenOnUploadSucceedEpic: Epic<
  GeneratePanelActionType,
  GeneratePanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.addInferenceInputResponse)),
    withLatestFrom(state$),
    map(([, state]) => ({
      uploading: selectors.generatePanel(state).uploadProgress.uploading
    })),
    filter(({ uploading }) => uploading),
    map(() => actions.upload.onUploadSuccess())
  )

/* Listen when upload is failed
 *  - Add error message in uploadErrors Redux.
 *  - Dispatch failed action
 */

const listenOnUploadFailedEpic: Epic<
  GeneratePanelActionType,
  GeneratePanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(action => action.payload.type === getType(apiActions.projects.addInferenceInput)),
    map(({ payload }) => {
      let error = errorUtils.flattenMessage(payload).toString()

      if (payload?.error?.status === 413) {
        error = errorMessage.ERROR_UPLOAD_TOO_LARGE.content
      }
      return {
        image: _get(payload, 'req.image'),
        error
      }
    }),
    map(param => actions.upload.onUploadFailed(param))
  )

const onFileInvalidEpic: Epic<GeneratePanelActionType, GeneratePanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.upload.onFileInvalid)),
    map(({ payload }) => actions.upload.onUploadFailed(payload))
  )

/*  Epic Listen when upload finished. */
const listenUploadFinishedEpic: Epic<
  GeneratePanelActionType,
  GeneratePanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.upload.onUploadFailed,
        actions.upload.onUploadSuccess,
        actions.upload.onFileInvalid
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      uploadProgress: selectors.uploadProgress(state)
    })),
    filter(({ uploadProgress }) => uploadProgress.isFinished),
    concatMap(() => [
      actions.upload.showUploadError(),
      actions.startInference(),
      actions.upload.onUploadFinished()
    ])
  )
const showUploadErrorEpic: Epic<GeneratePanelActionType, GeneratePanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.upload.showUploadError)),
    withLatestFrom(state$),
    map(([_, state]) => selectors.uploadProgress(state).errors),
    filter(errors => Boolean(errors && errors.length)),
    map(errors =>
      _map(
        errors,
        value =>
          `${value?.image?.name ?? ''} (${
            value?.error || errorMessage.ERROR_UPLOAD_UNKNOWN.content
          })`
      )
    ),
    tap(errorList => {
      SentryUtils.captureMessage(
        `Unable To Upload Generate Images`,
        {
          errorList
        },
        'error'
      )
    }),
    map(errorList =>
      dialogActions.openDialog({
        [ErrorDialog.UPLOAD_FAILED]: {
          dialogName: ErrorDialog.UPLOAD_FAILED,
          content: { errorList, count: errorList.length }
        }
      })
    )
  )
const startInferenceEpic: Epic<GeneratePanelActionType, GeneratePanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.startInference)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const currentInference = selectors.currentInference(state)
      return {
        id: currentInference?.id ?? 0,
        inputs: currentInference?.inputs ?? [],
        currentProject: apiSelectors.currentProject(state)
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ id, inputs }) => Boolean(id) && Boolean(inputs.length)),
          mergeMap(param =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.startInferenceResponse)),
              take(1),
              map(() => actions.retrieveInferenceProgress(param.id)),
              startWith(apiActions.projects.startInference(param))
            )
          )
        ),
        of(param).pipe(
          filter(({ inputs }) => !Boolean(inputs.length)),
          map(({ currentProject }) => actions.startOver({ project: currentProject.id }))
        )
      )
    )
  )

/* Recursively refresh inference until the process finished */
const retrieveInferenceProgressEpic: Epic<
  GeneratePanelActionType,
  GeneratePanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.retrieveInferenceProgress)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const currentInference = selectors.currentInference(state)
      return currentInference?.id ?? 0
    }),
    filter(id => Boolean(id)),
    mergeMap(id =>
      action$.pipe(
        filter(isActionOf([apiActions.projects.retrieveInferenceResponse, sharedActions.setError])),
        take(1),
        withLatestFrom(state$),
        mergeMap(param =>
          merge(
            /* If success flow */
            of(param).pipe(
              filter(
                ([action]) => action.type === getType(apiActions.projects.retrieveInferenceResponse)
              ),
              filter(([_, state]) => !selectors.isInferenceFinished(state)),
              delay(POOL_INFERENCE_INTERVAL),
              map(() => actions.retrieveInferenceProgress(id))
            ),
            /* If Failed flow */
            of(param).pipe(
              filter(
                ([action]) => action.type === getType(apiActions.projects.retrieveInferenceResponse)
              ),
              filter(([_, state]) => selectors.isInferenceFailed(state)),
              map(([_, state]) => {
                const currentInferencee = selectors.currentInference(state)
                SentryUtils.captureMessage(
                  `Failed to generate inference of images`,
                  {
                    currentInferencee
                  },
                  'error'
                )
                return currentInferencee
              }),
              mergeMap(currentInferencee => [
                dialogActions.openDialog({
                  [ErrorDialog.ERROR]: {
                    dialogName: ErrorDialog.ERROR,
                    content: 'Failed to generate inference of images'
                  }
                }),
                actions.startOver({ project: currentInferencee?.project ?? 0 })
              ])
            ),
            /* 
              If error flow, then repeat the process 
            */
            of(param).pipe(
              filter(([action]) => action.type === getType(sharedActions.setError)),
              filter(
                ([{ payload }]) =>
                  (payload as ErrorBundleType)?.type ===
                  getType(apiActions.projects.retrieveInferenceResponse)
              ),
              filter(([_, state]) => !selectors.isInferenceFinished(state)),
              delay(POOL_INFERENCE_INTERVAL_2),
              map(() => actions.retrieveInferenceProgress(id))
            )
          )
        ),
        startWith(apiActions.projects.retrieveInference(id))
      )
    )
  )
const updateGenerateOpenedEpic: Epic<
  GeneratePanelActionType,
  GeneratePanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.updateGenerateOpened)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const project = apiSelectors.currentProject(state)
      return {
        param: {
          id: project.id,
          ui_extras: { ...(project?.ui_extras ?? {}), is_generated_opened: true }
        },
        isProjectOwner: project.isProjectOwner
      }
    }),
    filter(({ param, isProjectOwner }) => Boolean(param.id) && Boolean(isProjectOwner)),
    map(({ param }) => apiActions.projects.update(param))
  )

export const epics = combineEpics(
  initInferenceEpic,
  receivedImagesEpic,
  onFileInvalidEpic,
  uploadImagesEpic,
  listenOnUploadSucceedEpic,
  listenOnUploadFailedEpic,
  listenUploadFinishedEpic,
  showUploadErrorEpic,
  startInferenceEpic,
  retrieveInferenceProgressEpic,
  updateGenerateOpenedEpic
)
export default reducer
