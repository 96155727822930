import React, { CSSProperties, RefObject, useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import ListComponent from 'react-virtualized/dist/commonjs/List'
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller'
import AutoSizer from 'react-virtualized-auto-sizer'
import _throttle from 'lodash/throttle'
import { CircularLoading } from 'components/Loading'
import { useBreakpoint } from 'utils/Hooks'
import { toNumber } from 'utils/NumberUtils'
import List from './List'
import { FixedSizeListProps } from '../index'
import { OnScrollParams, ListConfigType } from '../../index'

import styles from '../../ListVirtualized.module.scss'
import { fontSizeBase } from 'playformTheme'

function FixedSizeList<Item>(
  props: Omit<FixedSizeListProps<Item>, 'config'> & {
    config: ListConfigType
    setRef?: React.MutableRefObject<ListComponent>
  }
) {
  const {
    classes,
    className,
    config,
    containerRef,
    debug,
    dataLength,
    loading,
    loadingPosition = 'absolute',
    loadingSize,
    scrollRef,

    // calculateCellHeight,
    noRowsRenderer,
    onEndOfGrid,
    onScroll,
    renderItem,
    overscanRowCount,
    setRef
  } = props
  const { breakpoint } = useBreakpoint()
  const listContainerRef = useRef<HTMLDivElement | null>(null)
  const listContainerRefAdjusted = containerRef || listContainerRef
  const useWindow = scrollRef === window ? true : false

  const onBottomThrottle = useMemo(() => {
    if (!onEndOfGrid) return undefined

    return _throttle(() => {
      onEndOfGrid()
    }, 800)
  }, [onEndOfGrid])

  const containerStyle: CSSProperties = useMemo(() => {
    const containerStyle: CSSProperties = {}

    if (config?.paddingRight || config?.paddingLeft || config?.paddingX) {
      containerStyle.paddingRight = toNumber(config?.paddingRight || config?.paddingX)
      containerStyle.paddingLeft = toNumber(config?.paddingLeft || config?.paddingX)
    }

    if (config?.paddingTop || config?.paddingBottom || config?.paddingY) {
      containerStyle.paddingTop = toNumber(config?.paddingTop || config?.paddingY)
      containerStyle.paddingBottom = toNumber(config?.paddingBottom || config?.paddingY)
    }

    return containerStyle
  }, [config])

  // Added to solve load data when grid height smaller than parent height.
  // No need to scroll to load new data
  useEffect(() => {
    const listContainerHeight = listContainerRefAdjusted?.current?.clientHeight ?? 0
    const scrollContainerHeight = useWindow
      ? window.innerHeight
      : (scrollRef as RefObject<any>)?.current?.clientHeight ?? 0

    if (
      onEndOfGrid &&
      !loading &&
      listContainerHeight &&
      scrollContainerHeight &&
      listContainerHeight <= scrollContainerHeight
    ) {
      onEndOfGrid()
    }
  }, [loading, onEndOfGrid, listContainerRefAdjusted, useWindow, scrollRef])

  if (!scrollRef) return null

  return (
    <div
      ref={listContainerRefAdjusted}
      className={clsx(styles.OverflowHidden, className)}
      style={containerStyle}
    >
      <div className="relative">
        <WindowScroller
          key={useWindow ? window : (scrollRef as RefObject<any>)?.current}
          scrollElement={useWindow ? window : (scrollRef as RefObject<any>)?.current}
        >
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => {
            if (!height) return null

            return (
              <AutoSizer disableHeight>
                {(props: { width?: number }) => {
                  const { width = 0 } = props

                  if (!dataLength || width <= 0) return null

                  return (
                    <div ref={registerChild as any /* TODO Temporary fix */}>
                      <List<Item>
                        breakpoint={breakpoint}
                        // calculateCellHeight={calculateCellHeight}
                        containerRef={listContainerRefAdjusted}
                        setRef={setRef}
                        config={config}
                        dataLength={dataLength}
                        debug={debug}
                        height={height}
                        isScrolling={isScrolling}
                        noRowsRenderer={noRowsRenderer}
                        onBottom={onEndOfGrid ? onBottomThrottle : undefined}
                        onScroll={(event: OnScrollParams) => {
                          onScroll?.(event)
                          onChildScroll(event)
                        }}
                        overscanRowCount={overscanRowCount}
                        renderItem={renderItem}
                        scrollTop={scrollTop}
                        width={width}
                      />

                      {loading ? (
                        <div
                          className={clsx(
                            styles.LoadingContainer,
                            loadingPosition === 'fixed' && styles.LoadingContainerFixed,
                            loadingPosition === 'fixed' && classes?.loadingFixed
                          )}
                          style={
                            loadingPosition === 'fixed'
                              ? {
                                  width,
                                  left: `calc((100vw - ${width / fontSizeBase}rem) / 2)` // To fix centered position bug
                                }
                              : undefined
                          }
                        >
                          <CircularLoading size={loadingSize} loading disableShrink />
                        </div>
                      ) : null}
                    </div>
                  )
                }}
              </AutoSizer>
            )
          }}
        </WindowScroller>
      </div>
    </div>
  )
}

export default FixedSizeList
