import React from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'
import LogoIcon from 'components/Icon/LogoIcon'
import TextField from 'components/TextField'
import { SigninPanelProps } from './SigninPanel'
import { INITIAL_HIDE_CONFIG, ThirdPartySigninList } from './Components'
import styles from './Signin.module.scss'
import { THIRD_PARTY_SIGN_IN } from 'duck/FirebaseDuck'
import { FirebaseError } from '@firebase/util'

type SigninFormProps = {
  requestLogin: () => void
  setEmail: (email: string) => void
  email: string
  emailError: string
  firebaseErr: FirebaseError | null
  isSmallscreenAbove?: boolean
  isDialogMode?: boolean
} & Pick<SigninPanelProps, 'signinWith'>

const SigninForm: React.FC<SigninFormProps> = props => {
  const {
    signinWith,
    requestLogin,
    setEmail,
    emailError,
    email,
    firebaseErr,
    isDialogMode,
    isSmallscreenAbove
  } = props

  const hideConfig = THIRD_PARTY_SIGN_IN.reduce((result, value) => {
    result[value] = false
    return result
  }, INITIAL_HIDE_CONFIG)

  return (
    <div className={clsx('w-full text-center sm:max-w-md', isDialogMode && 'mx-auto')}>
      <Typography
        className="mb-8 font-medium"
        variant={isSmallscreenAbove && !isDialogMode ? 'h5' : 'body1'}
        component="div"
      >
        Sign In or Sign Up
      </Typography>

      <form
        className="mb-6"
        onSubmit={e => {
          e.preventDefault()
          requestLogin()
        }}
      >
        <div>
          <div className={!isDialogMode ? 'mb-7' : 'mb-4'}>
            <TextField
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              onChange={e => {
                e.preventDefault()
                setEmail(e.currentTarget.value)
              }}
              error={firebaseErr !== null || Boolean(props.emailError)}
              value={email}
              variant="filled"
              fullWidth
            />

            {emailError && (
              <Typography
                className="mt-2 text-left"
                variant="caption"
                color="error"
                component="div"
              >
                {emailError}
              </Typography>
            )}
          </div>

          <div className="mt-7">
            <Button
              variant="contained"
              color="secondary"
              disabled={emailError !== '' || email === ''}
              onClick={() => requestLogin()}
              fullWidth
            >
              CONTINUE
            </Button>
          </div>
        </div>
      </form>

      <div>
        <Typography className="text-white-med-on-dark" variant="subtitle2" component="div">
          or
        </Typography>
      </div>
      <ThirdPartySigninList signinWith={signinWith} hideConfig={hideConfig} />
    </div>
  )
}

const SigninFormContainer: React.FC<
  SigninFormProps & {
    isDialogMode?: boolean
  } & Pick<SigninPanelProps, 'phoneValidationCreditText'>
> = props => {
  const {
    setEmail,
    requestLogin,
    signinWith,
    isDialogMode,
    email,
    emailError,
    phoneValidationCreditText,
    firebaseErr,
    isSmallscreenAbove
  } = props

  return (
    <Grid
      className={clsx(
        isDialogMode ? styles.SigninContainerDialog : styles.SigninContainer,
        !isDialogMode && styles.SigninFormSection
      )}
      container
    >
      <Grid item xs={12} sm={!isDialogMode ? true : undefined}>
        <div
          className={twMerge(
            'sm:max-w-md',
            !isSmallscreenAbove && !isDialogMode && 'text-center',
            isSmallscreenAbove && !isDialogMode && 'pr-6',
            isDialogMode && 'mx-auto',
            isDialogMode && 'text-center'
          )}
        >
          <LogoIcon className="mb-3 h-12 w-12" />

          <Typography variant="h5" component="h2" className="mb-8 font-medium">
            Welcome to Playform!
          </Typography>

          {isSmallscreenAbove && !isDialogMode ? (
            <Typography variant="h6" component="div">
              {`Playform makes AI accessible for all creative people, without learning new terminology
              or learning how to code. New users get ${phoneValidationCreditText} in free credits.`}
            </Typography>
          ) : null}
        </div>
      </Grid>

      <Grid
        className={isSmallscreenAbove && !isDialogMode ? 'flex justify-end' : undefined}
        item
        xs={12}
        sm={!isDialogMode ? true : undefined}
      >
        <SigninForm
          signinWith={signinWith}
          email={email}
          requestLogin={requestLogin}
          setEmail={setEmail}
          emailError={emailError}
          firebaseErr={firebaseErr}
          isSmallscreenAbove={isSmallscreenAbove}
          isDialogMode={isDialogMode}
        />
      </Grid>
    </Grid>
  )
}

export default SigninFormContainer
