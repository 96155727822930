import { useCallback, useRef, useState, useLayoutEffect } from 'react'

type UseDimensionArgs = {
  liveMeasure?: boolean
}

type DimensionObject = {
  width: number
  height: number
  top: number
  left: number
  x: number
  y: number
  right: number
  bottom: number
}

type UseDimensionsHook = [(node: HTMLElement | null) => void, DimensionObject, any | null, Function]

const emptyObject: any = {}

const DEFAULT_DIMENSION: DimensionObject = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  x: 0,
  y: 0,
  right: 0,
  bottom: 0
}

function getDimensionObject(node: HTMLElement | null): DimensionObject {
  if (node) {
    const rect: any = node.getBoundingClientRect()

    if (rect)
      return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom
      }
  }

  return DEFAULT_DIMENSION
}

export const useDimension = (props?: UseDimensionArgs): UseDimensionsHook => {
  const { liveMeasure } = props || emptyObject
  const nodeRef = useRef<HTMLElement | null>(null)
  const [node, setNode] = useState<HTMLElement | null>(null)
  const [dimension, setDimension] = useState(DEFAULT_DIMENSION)

  const ref = useCallback<UseDimensionsHook[0]>(node => {
    setNode(node)
    nodeRef.current = node
  }, [])

  const refreshDimension = useCallback(() => {
    setDimension(getDimensionObject(node))
  }, [node])

  useLayoutEffect(() => {
    if (window && node) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          setDimension(getDimensionObject(node))
        })
      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)
        // window.addEventListener('scroll', measure)

        return () => {
          window.removeEventListener('resize', measure)
          // window.removeEventListener('scroll', measure)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  return [ref, dimension, nodeRef, refreshDimension]
}
