import { FirebaseError } from 'firebase/app'
import { AuthCredential, AuthError, User, UserCredential } from 'firebase/auth'
import { createAction } from 'typesafe-actions'
import { FirebaseState } from './reducers'

export type SignInProvider =
  | 'google.com'
  | 'twitter.com'
  | 'facebook.com'
  | 'emailLink'
  | 'phone'
  | 'password'

export const THIRD_PARTY_SIGN_IN: SignInProvider[] = ['google.com']

export const SIGN_IN_PROVIDER_TEXT_MAP: Record<SignInProvider, string> = {
  'google.com': 'Google',
  'twitter.com': 'Twitter',
  'facebook.com': 'Facebook',
  emailLink: '',
  phone: '',
  password: ''
}

export const firebaseActions = {
  requestLogin: createAction('@@firebase/REQUEST_LOGIN')<{
    email: string
    pendingCredential?: AuthCredential
  }>(),
  requestAuth: createAction('@@firebase/REQUEST_AUTH')(),
  signInWithEmailLink: createAction('@@firebase/SIGN_IN_WITH_EMAIL_LINK')<{ email: string }>(),
  signinWith: createAction('@@firebase/SIGN_IN_WITH')<{
    signInProvider: SignInProvider
    pendingCredential?: AuthCredential
  }>(),
  handleSignInError: createAction('@@firebase/HANDLE_SIGN_IN_ERROR')<{
    error: AuthError | null
    signInProvider: SignInProvider
  }>(),
  linkWithCredential: createAction('@@firebase/LINK_WITH_CREDENTIAL')<{
    pendingCredential: AuthCredential | null | undefined
    userCredential: UserCredential
  }>(),
  setError: createAction('@@firebase/SET_ERROR')<{
    error: FirebaseError | null
    action: string
  }>(),
  setLoading: createAction('@@firebase/SET_LOADING')<boolean>(),
  setPendingCredential: createAction('@@firebase/SET_PENDING_CREDENTIAL')<
    FirebaseState['pendingCredential']
  >(),
  startTokenInterval: createAction('@@firebase/START_TOKEN_INTERVAL')(),
  restartTokenInterval: createAction('@@firebase/RESTART_TOKEN_INTERVAL')(),
  setFirebaseUser: createAction('@@firebase/SET_FIREBASE_USER')<User | null>(),
  setIdToken: createAction('@@firebase/SET_ID_TOKEN')<string | null>(),
  unlinkSignIn: createAction('@@firebase/UNLINK_SIGN_IN')<{ providerId: SignInProvider }>(),
  unlinkSignInResponse: createAction('@@firebase/UNLINK_SIGN_IN_RESPONSE')<{
    providerId: SignInProvider
  }>(),
  reloadUser: createAction('@@firebase/RELOAD_USER')(),
  reset: createAction('@@firebase/RESET')(),
  setPhoneVerificationId: createAction('@@firebase/SET_PHONE_VERIFICATION_ID')<string>(),
  refreshToken: createAction('@@firebase/REFRESH_TOKEN')(),
  updatePhoneNumber: createAction('@@firebase/UPDATE_PHONE_NUMBER')<{
    phoneNumber: string
  }>(),
  removePhone: createAction('@@firebase/REMOVE_PHONE')(),
  verifyPhone: createAction('@@firebase/VERIFY_PHONE')<string>(),
  verifyPhoneResponse: createAction('@@firebase/VERIFY_PHONE_RESPONSE')<{
    firebaseUser: User | null
    phoneVerificationId: string
  }>()
}
