import React, { forwardRef, Ref } from 'react'
import clsx from 'clsx'
import Button, { ButtonProps } from '@mui/material/Button'
import { Link as LinkRouter } from 'react-router-dom'
import Link, { LinkProps } from '@mui/material/Link'

import styles from './Button.module.scss'

type ButtonColorProps = ButtonProps['color'] | 'light' | 'secondary' | 'error'

type ButtonTextProps = Omit<ButtonProps, 'color'> &
  Omit<LinkProps, 'color' | 'onClick'> & {
    component?: any
    color?: ButtonColorProps
    isLoading?: boolean
    to?: string
  }

const getColor = (color?: ButtonColorProps): [ButtonProps['color'], string | undefined] => {
  if (color === 'light') {
    return [undefined, styles.ButtonTextLight]
  } else if (color === 'secondary') {
    return [undefined, styles.ButtonTextSecondary]
  } else if (color === 'error') {
    return [undefined, styles.ButtonTextError]
  }

  return [color, undefined]
}

const ButtonText = forwardRef((props: ButtonTextProps, ref: Ref<HTMLButtonElement>) => {
  const { color, className, isLoading, href, target, rel, underline, ...restProps } = props
  const [componentColor, componentColorClassName] = getColor(color)

  if (href || target || rel) {
    return (
      <Link
        className={clsx(styles.ButtonTextLink, componentColorClassName, className)}
        href={href}
        rel={rel}
        target={target}
        underline={underline}
      >
        {restProps.children}
      </Link>
    )
  }

  return (
    <Button
      ref={ref}
      className={clsx(styles.ButtonText, componentColorClassName, className)}
      color={componentColor}
      {...restProps}
    ></Button>
  )
})

const ButtonTextLink = forwardRef((props: ButtonTextProps, ref: Ref<HTMLButtonElement>) => {
  return <ButtonText ref={ref} {...props} component={LinkRouter} />
})

const ButtonTextContainer = (props: ButtonTextProps, ref: Ref<HTMLButtonElement>) => {
  if (props.to) return <ButtonTextLink ref={ref} {...props} />

  return <ButtonText ref={ref} {...props} />
}

export default forwardRef(ButtonTextContainer)
