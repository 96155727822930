import React from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { CardType, FORM_CONFIG } from '.'
import CreditCardBrandIcon from 'components/Icon/CreditCardBrandIcon'
import styles from './BraintreeHostedField.module.scss'

export type CardFormProps = {
  cardType?: CardType
  error?: string
  focused: boolean
  mounted: boolean
}

const CardForm: React.FC<CardFormProps> = props => {
  const { cardType, error, focused, mounted } = props

  return (
    <>
      <div
        className={clsx(
          'flex select-none',
          styles.TextField,
          !mounted && styles.TextFieldUnmounted,
          mounted && styles.TextFieldBordered,
          mounted && focused && styles.TextFieldFocused
        )}
      >
        {mounted ? (
          <div className="mr-2 flex items-center">
            <CreditCardBrandIcon
              className={error ? 'text-danger' : 'text-white-med-on-dark'}
              type={cardType}
            />
          </div>
        ) : null}

        <div
          id={FORM_CONFIG.CARD_NUMBER.id}
          className={clsx('w-1/100 min-w-0 flex-auto', styles.HostedField)}
        ></div>

        <div
          id={FORM_CONFIG.EXPIRE.id}
          className={clsx(styles.HostedField, styles.TextFieldExpiredDate)}
        ></div>

        <div
          id={FORM_CONFIG.CVV.id}
          className={clsx(styles.HostedField, styles.TextFieldCVV)}
        ></div>

        <div
          id={FORM_CONFIG.POSTAL_CODE.id}
          className={clsx(styles.HostedField, styles.TextFieldPostalCode)}
        ></div>
      </div>

      {mounted && error ? (
        <Typography
          color="error"
          variant="caption"
          component="div"
          className="pointer-events-none mt-2 select-none text-center"
        >
          {error}
        </Typography>
      ) : null}
    </>
  )
}

export default CardForm
