import { apiActions, EngineActionType } from '../actions'
import { SharedActionsType, sharedActions } from '../sharedActions'
import { EngineConfigResponse, Clip, PaginationResponse } from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import _without from 'lodash/without'
import { GetText } from 'utils/TextUtils'

export * from './CollectionReducers'
export * from './ProjectsReducers'
export * from './PaymentReducers'
export * from './SketchToImageReducers'
export * from './SocialReducers'
export * from './MixImageReducers'

export type EngineState = {
  clips: {
    [clipId: number]: Clip
  }
  clipLists: {
    [projectKey: string]: {
      list: number[]
      lastReq?: PaginationResponse<Clip>
    }
  }
  engineConfig: EngineConfigResponse | null
}

export const initialEngineState: EngineState = {
  clips: {},
  clipLists: {},
  engineConfig: null
}
export const engineReducer = createReducer<EngineState, EngineActionType | SharedActionsType>(
  initialEngineState
)
  .handleAction(apiActions.engine.listEngineConfigResponse, (state, action) => {
    return {
      ...state,
      engineConfig: { ...action.payload }
    }
  })
  .handleAction(
    [apiActions.engine.copyClipResponse, apiActions.engine.createClipResponse],
    (state, action) => {
      const clip = action.payload
      const clipListKey = GetText.clipListKey(clip.project)
      const currentClipList = state.clipLists?.[clipListKey] ?? {}
      const currentList = currentClipList?.list ?? []

      return {
        ...state,
        clips: {
          ...state.clips,
          [clip.id]: clip
        },
        clipLists: {
          ...state.clipLists,
          [clipListKey]: {
            ...currentClipList,
            list: _uniq([clip.id, ...currentList])
          }
        }
      }
    }
  )
  .handleAction(apiActions.engine.listClipResponse, (state, action) => {
    const { next, data, request } = action.payload
    const results = data.results
    const clipListKey = GetText.clipListKey({ id: request.project, type: request.type })

    const currentClipList = state.clipLists?.[clipListKey] ?? {}
    const currentList = next ? currentClipList?.list ?? [] : []

    return {
      ...state,
      clips: {
        ...state.clips,
        ..._keyBy(results, result => result.id)
      },
      clipLists: {
        ...state.clipLists,
        [clipListKey]: {
          lastReq: data,
          list: _uniq([...currentList, ..._map(results, result => result.id)])
        }
      }
    }
  })
  .handleAction(
    [apiActions.engine.updateClipResponse, apiActions.engine.retrieveClipResponse],
    (state, action) => {
      return {
        ...state,
        clips: {
          ...state.clips,
          [action.payload.id]: action.payload
        }
      }
    }
  )
  .handleAction(apiActions.engine.deleteClipResponse, (state, action) => {
    const { clip, project } = action.payload
    const clipListKey = GetText.clipListKey(project)

    const currentClipList = state.clipLists?.[clipListKey] ?? {}
    const currentList = currentClipList?.list ?? []

    return {
      ...state,
      clipLists: {
        ...state.clipLists,
        [clipListKey]: {
          ...currentClipList,
          list: _without(currentList, clip)
        }
      }
    }
  })
  .handleAction(sharedActions.reset, () => initialEngineState)
