import React, { CSSProperties, useCallback, useMemo, useEffect, useRef, RefObject } from 'react'
import ListComponent, {
  ListProps as ListComponentProps
} from 'react-virtualized/dist/commonjs/List'
import CellMeasurer, { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer'
import { OverscanIndicesGetter } from 'react-virtualized/dist/commonjs/Grid'
import { ListRowRenderer } from 'react-virtualized/dist/commonjs/List'
import { usePrevious } from 'utils/Hooks'
import { toNumber } from 'utils/NumberUtils'
import { ListConfigType, OnScrollParams } from '../../index'
import { FixedSizeListProps } from '../index'

const DEFAULT_COLUMN_WIDTH = 0

type GridProps<Item> = Pick<
  ListComponentProps,
  'height' | 'isScrolling' | 'onScroll' | 'overscanRowCount' | 'scrollTop' | 'width'
> &
  Pick<
    FixedSizeListProps<Item>,
    // 'calculateCellHeight' |
    'debug' | 'renderItem' | 'noRowsRenderer'
  > & {
    breakpoint?: string
    config: ListConfigType
    containerRef: RefObject<HTMLDivElement | null>
    dataLength: number
    onBottom?: () => void
    setRef?: React.MutableRefObject<ListComponent | null>
  }

function List<Item>(props: GridProps<Item>) {
  const {
    breakpoint,
    config,
    containerRef,
    dataLength,
    // debug,
    height,
    isScrolling,
    overscanRowCount,
    scrollTop,
    setRef,
    width,

    onBottom,
    // calculateCellHeight,
    noRowsRenderer,
    onScroll,
    renderItem
  } = props

  const scrollData = useRef<{
    currentOverscanStopIndex: number
    currentStopIndex: number
  }>({ currentOverscanStopIndex: 0, currentStopIndex: 0 })

  const overscanIndicesGetter = useCallback<OverscanIndicesGetter>(props => {
    const {
      cellCount, // Number of rows or columns in the current axis
      overscanCellsCount, // Maximum number of cells to over-render in either direction
      startIndex, // Begin of range of visible cells
      stopIndex // End of range of visible cells
    } = props

    return {
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount)
    }
  }, [])

  const listScrollableElement = containerRef?.current?.children[0]?.children[0] as HTMLElement
  const scrollbarSize = useMemo(() => {
    if (listScrollableElement) {
      return listScrollableElement?.offsetWidth - listScrollableElement?.clientWidth
    }

    return 0
  }, [listScrollableElement])

  const adjustedWidth =
    config?.maxWidth && width > config?.maxWidth
      ? toNumber(config?.maxWidth)
      : width - scrollbarSize
  const gutter = toNumber(config?.gutter ?? 0)

  const useMaxWidth = config?.maxWidth && width > config?.maxWidth
  const adjustedOffsetLeft =
    config?.maxWidth && width > config?.maxWidth ? (width - scrollbarSize - adjustedWidth) / 2 : 0

  const paddingY = toNumber(config.paddingY) || 0
  const paddingTop = toNumber(config.paddingTop || config.paddingY) || 0
  const paddingBottom = toNumber(config.paddingBottom || config.paddingY) || 0

  const paddingX = toNumber(config.paddingX) || 0
  const paddingLeft = toNumber(config.paddingLeft || config.paddingX) || 0
  const paddingRight = toNumber(config.paddingRight || config.paddingX) || 0

  const columnCount = config?.columns
    ? toNumber(config?.columns)
    : config?.defaultColumnWidth
      ? Math.floor(adjustedWidth / (toNumber(config?.defaultColumnWidth) || DEFAULT_COLUMN_WIDTH))
      : 1

  const adjustedColumnWidth = Math.floor((adjustedWidth - gutter * (columnCount - 1)) / columnCount)
  const rowCount = Math.ceil(dataLength / columnCount)

  const cache = useMemo(() => {
    if (!width && !breakpoint) return {} as CellMeasurerCache

    return new CellMeasurerCache({
      defaultWidth: adjustedWidth,
      defaultHeight: config.square ? adjustedWidth : config.rowHeight || config.defaultRowHeight,
      minHeight: config.square ? adjustedWidth : config.rowHeight || config.defaultRowHeight,
      fixedWidth: true
    })
  }, [adjustedWidth, width, config, breakpoint])

  const hasFixedRowHeight = !config.square ? Boolean(config?.rowHeight) : true
  const adjustedRowHeight = toNumber(
    config.square
      ? adjustedColumnWidth
      : hasFixedRowHeight
        ? toNumber(config?.rowHeight)
        : cache.rowHeight
  )

  const rowRenderer = useCallback<ListRowRenderer>(
    props => {
      const { index, key, parent, style } = props

      const contentHeight = adjustedRowHeight
      // || calculateCellHeight?.(
      //   index,
      //   adjustedColumnWidth,
      //   data ? data[index] : undefined,
      //   toNumber(config?.defaultRowHeight)
      // )

      const renderContent = (registerChild?: any, measure?: any) => {
        const isMultiColumn = columnCount > 1

        if (isMultiColumn) {
          const Components: any = isMultiColumn ? [] : undefined
          const adjustedIndex = index * columnCount

          for (let i = 0; i < columnCount; i++) {
            const styleCell = gutter
              ? {
                  padding: gutter / 2 || undefined
                }
              : undefined

            Components.push(
              <div key={`${key}-${i}`} className="relative h-full w-full" style={styleCell}>
                {renderItem({
                  index: adjustedIndex + i,
                  measure,
                  width: adjustedColumnWidth,
                  height: contentHeight
                })}
              </div>
            )
          }

          const rowStyle = { ...style }
          const rowInnerStyle: CSSProperties = {}

          if (paddingTop || paddingY || paddingLeft || paddingRight || paddingX || gutter) {
            rowStyle.top = toNumber(style.top) - gutter / 2

            if (gutter) {
              rowInnerStyle.left = -(gutter / 2)
              rowInnerStyle.right = -(gutter / 2)
              rowInnerStyle.width = gutter ? `calc(100% + ${gutter}px)` : '100%'
            }
          }

          return (
            <div key={key} ref={registerChild as any} style={rowStyle} className="box-border">
              <div className="relative flex h-full" style={rowInnerStyle}>
                {Components}
              </div>
            </div>
          )
        }

        // Render Content with single column

        return (
          <div
            key={key}
            ref={registerChild as any}
            className="box-border"
            style={{
              ...style,
              top: toNumber(style.top) - gutter / 2,
              left: adjustedOffsetLeft ? adjustedOffsetLeft : toNumber(style.left),
              width: adjustedColumnWidth,
              height: contentHeight ? contentHeight + gutter : style.height,
              paddingTop: gutter ? gutter / 2 : undefined,
              paddingBottom: gutter ? gutter / 2 : undefined
            }}
          >
            {renderItem({
              index,
              measure,
              width: adjustedColumnWidth,
              height: contentHeight || toNumber(style.height)
            })}
          </div>
        )
      }

      if (contentHeight) return renderContent()

      return (
        <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
          {({ measure, registerChild }) => {
            return renderContent(registerChild, measure)
          }}
        </CellMeasurer>
      )
    },
    [
      adjustedOffsetLeft,
      adjustedRowHeight,
      cache,
      columnCount,
      gutter,
      adjustedColumnWidth,
      paddingRight,
      paddingLeft,
      paddingX,
      paddingY,
      paddingTop,
      renderItem
    ]
  )

  const listRefCurrent = setRef?.current
  const listContainerOffsetTop = containerRef?.current?.offsetTop
  const listContainerOffsetTopPrevious = usePrevious(listContainerOffsetTop)
  const isListOffsetTopChange = listContainerOffsetTop !== listContainerOffsetTopPrevious

  const dataLengthPrevious = usePrevious(dataLength)
  const isDataChange = dataLength !== dataLengthPrevious

  const widthPrevious = usePrevious(width)
  const isWidthChange = width !== widthPrevious

  const containerInnerStyle: CSSProperties | undefined = useMemo(() => {
    const containerInnerStyle: CSSProperties = {}

    if (useMaxWidth) {
      containerInnerStyle.width = adjustedWidth
      containerInnerStyle.marginLeft = 'auto'
      containerInnerStyle.marginRight = 'auto'
    }

    if ((paddingY || paddingTop || paddingBottom || gutter) && adjustedRowHeight && rowCount) {
      if (paddingY || paddingTop || paddingBottom || gutter) {
        const adjustedHeight = (adjustedRowHeight + gutter) * rowCount - gutter
        containerInnerStyle.maxHeight = adjustedHeight
        containerInnerStyle.height = adjustedHeight
      }
    }

    return containerInnerStyle
  }, [
    adjustedRowHeight,
    adjustedWidth,
    paddingBottom,
    paddingTop,
    paddingY,
    gutter,
    rowCount,
    useMaxWidth
  ])

  const handleScroll = useCallback(
    (props: OnScrollParams) => {
      onScroll?.(props)
      if (onBottom)
        if (
          scrollData.current.currentStopIndex >= rowCount - 2 &&
          scrollData.current.currentStopIndex <= rowCount
        )
          onBottom()
    },
    [onBottom, onScroll, rowCount]
  )

  const handleRowsRendered = useCallback(
    (params: any) => {
      scrollData.current.currentOverscanStopIndex = params.overscanStopIndex
      scrollData.current.currentStopIndex = params.stopIndex
    },
    [scrollData]
  )

  useEffect(() => {
    if (isListOffsetTopChange || isDataChange || isWidthChange) {
      /* Refresh cache after resize or data change */
      if (!hasFixedRowHeight) cache.clearAll()
      listRefCurrent?.recomputeRowHeights()
    }
  }, [listRefCurrent, isListOffsetTopChange, cache, isDataChange, isWidthChange, hasFixedRowHeight])

  return (
    <ListComponent
      autoHeight
      ref={setRef}
      estimatedColumnSize={adjustedWidth}
      estimatedRowSize={
        config?.square ? adjustedWidth : config?.rowHeight || config?.defaultRowHeight
      }
      className="outline-none"
      deferredMeasurementCache={!hasFixedRowHeight ? cache : undefined}
      rowRenderer={rowRenderer}
      height={height}
      isScrolling={isScrolling}
      noRowsRenderer={noRowsRenderer}
      onScroll={onScroll || onBottom ? handleScroll : undefined}
      onRowsRendered={onBottom ? handleRowsRendered : undefined}
      overscanRowCount={overscanRowCount}
      overscanIndicesGetter={overscanIndicesGetter}
      rowCount={rowCount}
      rowHeight={!hasFixedRowHeight ? cache.rowHeight : adjustedRowHeight + gutter}
      scrollTop={scrollTop}
      width={width}
      containerStyle={containerInnerStyle}
    />
  )
}

export default List
