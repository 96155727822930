import React from 'react'
import loadable from 'utils/Loadable'
import { Route, Routes } from 'react-router-dom'
import { NEW_PROJECT_ACTION, route } from 'routes'

import styles from './NavBar.module.scss'

const NavBarMixImage = loadable(
  () => import(/* webpackChunkName: "NavBarMixImage" */ './NavBarMixImage')
)
const NavBarProject = loadable(
  () => import(/* webpackChunkName: "NavBarProject" */ './NavBarProject')
)
const NavBarSketchToImage = loadable(
  () => import(/* webpackChunkName: "NavBarSketchToImage" */ './NavBarS2I')
)
const NavBarProFilter = loadable(
  () => import(/* webpackChunkName: "NavBarProFilter" */ './NavBarProFilter')
)
const NavBarUpscale = loadable(
  () => import(/* webpackChunkName: "NavBarUpscale" */ './NavBarUpscale')
)
const NavBarTextToImage = loadable(
  () => import(/* webpackChunkName: "NavBarTextToImage" */ './NavBarTextToImage')
)
const NavBarGenericApp = loadable(
  () => import(/* webpackChunkName: "NavBarGenericApp" */ './NavBarGenericApp')
)
const NavBarST2I = loadable(() => import(/* webpackChunkName: "NavBarGenericApp" */ './NavBarST2I'))

const NavBarSubpage: React.FC = () => {
  return (
    <div className={styles.NavBar}>
      <Routes>
        <Route path={`${route.TRAIN_PROJECTS.getPath(2)}/*`} element={<NavBarProject />} />
        <Route path={route.SKETCH_PROJECTS.getPath()} element={<NavBarSketchToImage />} />
        <Route path={route.PRO_FILTER_PROJECTS.getPath()} element={<NavBarProFilter />} />
        <Route path={`${route.MIX_PROJECTS.getPath(2)}/*`} element={<NavBarMixImage />} />
        <Route
          path={`${route.TEXT_TO_IMAGE_PROJECTS.getPath(2)}/*`}
          element={<NavBarTextToImage />}
        />
        <Route path={`${route.GENERIC_APP_PROJECTS.getPath(2)}/*`} element={<NavBarGenericApp />} />
        <Route
          path={`${route.SKETCHTEXT_TO_IMAGE_PROJECTS.getPath(2)}/*`}
          element={<NavBarST2I />}
        />
        <Route
          path={route.UPSCALES.getPath(1, `/${NEW_PROJECT_ACTION}`)}
          element={<NavBarUpscale />}
        />
      </Routes>
    </div>
  )
}

export default NavBarSubpage
