import _forEach from 'lodash/forEach'
import _toNumber from 'lodash/toNumber'

export const ValidatorUtils = {
  toInt: (data: any) => {
    if (!data) {
      return 0
    }

    const result = parseInt(data, 10)

    if (Number.isNaN(result)) {
      return 0
    }

    return result
  },
  isExisty: (value: any) => value !== null && value !== undefined,
  isEmpty: (value: any) => {
    if (value instanceof Array) {
      return value.length === 0
    }
    return value === '' || !ValidatorUtils.isExisty(value)
  },
  isEmptyTrimed: (value: any) => {
    if (typeof value === 'string') {
      return value.trim() === ''
    }
    return true
  },
  /* Receive data object and check it whether has content or not
   * Key with empty value not considered as content.
   */
  isHasContent: (errorObject: { [key: string]: any }) => {
    let hasContent = false
    _forEach(errorObject, (v: string) => {
      if (v) {
        hasContent = true
      }
    })
    return hasContent
  }
}

export type ValidatorParam = {
  matchRegexp: (value: string, regexp: RegExp | string) => boolean
  email: (value: string) => boolean
  url: (value: any) => boolean
  isEmpty: (value: any) => boolean
  required: (value: any) => boolean
  trim: (value: any) => boolean
  number: (value: any) => boolean
  float: (value: any) => boolean
  positive: (value: string) => boolean
  maxNumber: (value: string, max: number) => boolean
  minNumber: (value: string, min: number) => boolean
  maxFloat: (value: string, max: number) => boolean
  minFloat: (value: string, min: number) => boolean
  string: (value: any) => boolean
  lessThanFloat: (value: string, max: number) => boolean
  moreThanFloat: (value: string, min: number) => boolean
  minStringLength: (value: string, length: number) => boolean
  maxStringLength: (value: string, length: number) => boolean
  equalWith: (value: any, equalityAttribute: any, formData: any) => boolean
}

export type ValidatorType = keyof ValidatorParam

const Validator: ValidatorParam = {
  matchRegexp: (value: any, regexp: RegExp | string) => {
    const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp)
    return ValidatorUtils.isEmpty(value) || validationRegexp.test(value)
  },

  // eslint-disable-next-line
  email: (value: any)  => Validator.matchRegexp(value, /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i),
  // eslint-disable-next-line
  url: (value: any = '')=> Validator.matchRegexp(value, /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),
  isEmpty: (value: any) => ValidatorUtils.isEmpty(value),

  required: (value: any) => !ValidatorUtils.isEmpty(value),

  trim: (value: any) => !ValidatorUtils.isEmptyTrimed(value),

  number: (value: any) => Validator.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),

  float: (value: any) => Validator.matchRegexp(value, /^(?:[1-9]\d*|0)?(?:\.\d+)?$/i),

  positive: value => {
    if (ValidatorUtils.isExisty(value)) {
      return (Validator.number(value) || Validator.float(value)) && _toNumber(value) >= 0
    }
    return true
  },

  maxNumber: (value: any, max: number) =>
    ValidatorUtils.isEmpty(value) || parseInt(value, 10) <= max,

  minNumber: (value: any, min: number) =>
    ValidatorUtils.isEmpty(value) || parseInt(value, 10) >= min,

  maxFloat: (value: any, max: number) => ValidatorUtils.isEmpty(value) || parseFloat(value) <= max,

  minFloat: (value: any, min: number) => ValidatorUtils.isEmpty(value) || parseFloat(value) >= min,
  lessThanFloat: (value: any, max: number) =>
    ValidatorUtils.isEmpty(value) || parseFloat(value) < max,
  moreThanFloat: (value: any, min: number) =>
    ValidatorUtils.isEmpty(value) || parseFloat(value) > min,
  string: (value: any) =>
    !ValidatorUtils.isEmpty(value) || typeof value === 'string' || value instanceof String,
  minStringLength: (value: any, length: number) =>
    Validator.string(value) && value.length >= ValidatorUtils.toInt(length),
  maxStringLength: (value: any, length: number) =>
    Validator.string(value) && value.length <= length,
  equalWith: (value: any, equalityAttribute: any, formData: any) => {
    const valueCompared = formData.get(equalityAttribute)
    if (!valueCompared || !value) {
      return true
    }
    return value === valueCompared
  }
}

export const VALIDATION_MESSAGES = {
  url: 'Please enter a valid url',
  email: 'Please enter a valid email address',
  required: 'This field may not be blank',
  number: 'This field must be integer number',
  float: 'This field must be a number'
}

/* Receive error object and check it whether has error or not
 * Key with empty value not considered as error.
 */

export const isHasErrors = (errorObject: any) => {
  let hasError = false
  _forEach(errorObject, (v: string) => {
    if (v) {
      hasError = true
    }
  })
  return hasError
}

export default Validator
