import { Vector2d } from 'konva/lib/types'
import { Size } from '../CanvasBuilder'
import { ImagePreviewProps } from '..'

export type ImagePosition = {
  zoom: ImageState['zoom']
  camera: ImageState['camera']
}

export type ImageState = {
  stageSize: Size
  zoom: number
  camera: Vector2d
  disablePreview?: boolean
  image?: ImagePreviewProps['image']
  home?: ImagePosition
  maxCamera: {
    x1: number
    y1: number
    x2: number
    y2: number
  }
  lastImagePosition: Record<number, ImagePosition>
}

export type ImageStateManagerType = ReturnType<typeof ImageStateManager>

export function ImageStateManager(initial: ImageState) {
  const state: ImageState = {
    ...initial
  }

  function setMaxCamera() {
    const imageHeight = state.image?.height ?? 1
    const imageWidth = state.image?.width ?? 1
    const zoom = state.zoom
    const { width, height } = state.stageSize

    const zoomHeight = (zoom * imageHeight) / 100
    const zoomWidth = (zoom * imageWidth) / 100
    const widthLimit = width * 0.6
    const heightLimit = height * 0.6

    state.maxCamera = {
      x1: -(zoomWidth - widthLimit),
      y1: -(zoomHeight - heightLimit),
      x2: widthLimit,
      y2: heightLimit
    }
  }

  return {
    maxCamera: () => {
      return state.maxCamera
    },
    camera: (newCameraPos?: Partial<Vector2d>) => {
      if (newCameraPos) {
        const { x1, y1, x2, y2 } = state.maxCamera
        const x = newCameraPos.x ?? state.camera.x
        const y = newCameraPos.y ?? state.camera.y

        const newCamera = { x, y }

        newCamera.x = newCamera.x < x1 ? x1 : newCamera.x
        newCamera.x = newCamera.x > x2 ? x2 : newCamera.x

        newCamera.y = newCamera.y < y1 ? y1 : newCamera.y
        newCamera.y = newCamera.y > y2 ? y2 : newCamera.y

        state.camera = newCamera
      }
      return state.camera
    },
    zoom: (newZoom?: number) => {
      if (newZoom) {
        state.zoom = newZoom
        setMaxCamera()
      }
      return state.zoom
    },
    stageSize: (newStageSize?: Partial<Size>) => {
      if (newStageSize) {
        state.stageSize = { ...state.stageSize, ...newStageSize }
        setMaxCamera()
      }
      return state.stageSize
    },
    image: (image?: ImageState['image']) => {
      if (image) {
        const imageId = state.image?.id
        if (imageId && imageId !== image.id) {
          state.lastImagePosition[imageId] = {
            zoom: state.zoom,
            camera: { ...state.camera }
          }
        }

        state.image = image

        setMaxCamera()
      }

      return state.image
    },
    imagePosition: () => {
      return state.lastImagePosition[state.image?.id ?? 0]
    },
    disablePreview: (disable?: boolean) => {
      if (disable !== null && disable !== undefined) {
        state.disablePreview = disable
      }

      return state.disablePreview
    },
    home: (home?: ImageState['home']) => {
      if (home) {
        state.home = home
      }

      return state.home
    }
  }
}
