import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators'
import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { push } from 'redux-first-history'
import { route } from 'routes'
import { GenericAppOutputImageListReq, PaginationRequestParams } from 'models/ApiModels'

const retrieveGenericAppEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf([apiActions.genericApp.retrieveGenericApp])),
    filter(({ payload }) => Boolean(payload)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.retrieveGenericApp(payload ?? 0).pipe(
          mergeMap(response => [
            apiActions.genericApp.retrieveGenericAppResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listGenericAppEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.listGenericApp)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const params = next ? { next: state.api.genericApp.genericApp.lastReq?.next } : {}
      const extraParams = next ? undefined : (param as PaginationRequestParams)
      return { next, params, extraParams, type: action.type }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.listGenericApp(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.genericApp.listGenericAppResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const deleteGenericAppProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.deleteGenericAppProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      projectData: apiSelectors.textToImageProjects(state)[action.payload]
    })),
    switchMap(({ type, payload, projectData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.deleteGenericAppProject(payload).pipe(
          // tap(() => {
          //   MixPanelUtils.track<'PROJECT__DELETE'>(
          //     'Project - Delete',
          //     DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
          //       textToImageProject: projectData
          //     })
          //   )
          // }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.genericApp.deleteGenericAppProjectResponse(payload),
            apiActions.users.retrieveEquity(),
            push(route.GENERIC_APP_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createGenericAppProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.createGenericAppProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.createGenericAppProject(payload).pipe(
          concatMap(response => [
            apiActions.genericApp.createGenericAppProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateGenericAppProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.updateGenericAppProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(
          sharedActions.setLoading({
            loading: true,
            type
          })
        ),
        ApiUtils.genericApp.updateGenericAppProject(payload.id, payload).pipe(
          // tap(response => {
          //   MixPanelUtils.track<'PROJECT__RENAME'>(
          //     'Project - Rename',
          //     DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
          //       textToImageProject: response.data
          //     })
          //   )
          // }),
          concatMap(response => [
            apiActions.genericApp.updateGenericAppProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveGenericAppProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.genericApp.retrieveGenericAppProject,
        apiActions.genericApp.setCurrentGenericAppProject
      ])
    ),
    filter(({ payload }) => Boolean(payload)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.retrieveGenericAppProject(payload ?? 0).pipe(
          mergeMap(response => [
            apiActions.genericApp.retrieveGenericAppProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listGenericAppProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.listGenericAppProject)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const params = next ? { next: state.api.genericApp.genericAppProject.lastReq?.next } : {}
      const extraParams = next ? undefined : (param as PaginationRequestParams)
      return { next, params, extraParams, type: action.type }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.listGenericAppProject(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.genericApp.listGenericAppProjectResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listGenericAppOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.listGenericAppOutput)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, loadingMessage } = action.payload
      const projectId = param?.project || 0
      const params = next
        ? { next: state.api.genericApp.outputLists[projectId]?.lastListReq?.next }
        : {}
      const extraParams = next ? undefined : (param as GenericAppOutputImageListReq)
      return { next, params, extraParams, type: action.type, param, loadingMessage }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type, param, loadingMessage }) =>
      merge(
        of(sharedActions.setLoading({ loading: loadingMessage ?? true, type })),
        ApiUtils.genericApp.listGenericAppOutput(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),

            apiActions.genericApp.listGenericAppOutputResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const deleteGenericAppProjectOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.deleteGenericAppProjectOutput)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      payload: action.payload
    })),

    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.deleteGenericAppProjectOutput(payload).pipe(
          // tap(() => {
          //   if (currentTextToImageProject) {
          //     const projectData = DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
          //       textToImageProject: currentTextToImageProject
          //     })
          //     MixPanelUtils.track<'PROJECT__TEXT_TO_IMAGE_DO_ACTION'>(
          //       'Project Text To Image - Do Action',
          //       {
          //         ...projectData,
          //         do_action_type: 'delete_result',
          //         do_action_value: payload
          //       }
          //     )
          //   }
          // }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.genericApp.deleteGenericAppProjectOutputResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const deleteAllGenericAppProjectOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.genericApp.deleteAllGenericAppProjectOutput)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      payload: action.payload
      // currentTextToImageProject: apiSelectors.currentTextToImageProject(state)
    })),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.genericApp.deleteAllGenericAppProjectOutput(payload).pipe(
          // tap(() => {
          //   if (currentTextToImageProject) {
          //     const projectData = DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
          //       textToImageProject: currentTextToImageProject
          //     })
          //     MixPanelUtils.track<'PROJECT__TEXT_TO_IMAGE_DO_ACTION'>(
          //       'Project Text To Image - Do Action',
          //       {
          //         ...projectData,
          //         do_action_type: 'clear_all_result',
          //         do_action_value: payload
          //       }
          //     )
          //   }
          // }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.genericApp.deleteAllGenericAppProjectOutputResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const genericAppEpics = [
  retrieveGenericAppEpic,
  listGenericAppEpic,
  deleteAllGenericAppProjectOutputEpic,
  listGenericAppProjectEpic,
  deleteGenericAppProjectOutputEpic,
  listGenericAppOutputEpic,
  deleteGenericAppProjectEpic,
  createGenericAppProjectEpic,
  retrieveGenericAppProjectEpic,
  updateGenericAppProjectEpic
]

export default genericAppEpics
