import { Vector2d } from 'konva/lib/types'
import { Size } from '../CanvasBuilder'
import { InfinityGridProps } from '..'

//Number is In Grid unit
export type DisplayedImage = {
  id: number
  file: string
  gridX: number
  gridY: number
  gridWidth: number
  gridHeight: number
  scaleX: number
  scaleY: number
}

export type GridState = {
  stageSize: Size
  zoom: number
  camera: Vector2d
  displayedImages: DisplayedImage[][]
  displayedImagesFlat: { [id: number]: DisplayedImage }
  screenOffset: Vector2d
  selectedImage: InfinityGridProps['selectedImage']
}

export type GridStateManagerType = ReturnType<typeof GridStateManager>

export function GridStateManager(initial: GridState) {
  const state: GridState = {
    ...initial
  }

  return {
    camera: (newCameraPos?: Partial<Vector2d>) => {
      if (newCameraPos) {
        state.camera = { ...state.camera, ...newCameraPos }
      }
      return state.camera
    },
    zoom: (newZoom?: number) => {
      if (newZoom) {
        state.zoom = newZoom
      }
      return state.zoom
    },
    stageSize: (newStageSize?: Partial<Size>) => {
      if (newStageSize) {
        state.stageSize = { ...state.stageSize, ...newStageSize }
      }
      return state.stageSize
    },
    displayedImages: (newDisplayedImages?: GridState['displayedImages']) => {
      if (newDisplayedImages) {
        state.displayedImages = newDisplayedImages
      }

      return state.displayedImages
    },
    displayedImagesFlat: (newDisplayedImagesFlat?: GridState['displayedImagesFlat']) => {
      if (newDisplayedImagesFlat) {
        state.displayedImagesFlat = newDisplayedImagesFlat
      }
      return state.displayedImagesFlat
    },
    screenOffset: (newScreenOffset?: Vector2d) => {
      if (newScreenOffset) {
        state.screenOffset = newScreenOffset
      }
      return state.screenOffset
    },
    selectedImage: (newSelectedImage?: InfinityGridProps['selectedImage'] | null) => {
      if (newSelectedImage) {
        state.selectedImage = newSelectedImage
      }
      if (newSelectedImage === null) {
        state.selectedImage = undefined
      }
      return state.selectedImage
    }
  }
}
