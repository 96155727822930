import { route } from 'routes'
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  switchMap,
  withLatestFrom,
  mergeMap,
  exhaustMap,
  delay,
  takeUntil,
  tap
} from 'rxjs/operators'

import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { getType, isActionOf } from 'typesafe-actions'
import _forEach from 'lodash/forEach'
import _toNumber from 'lodash/toNumber'
import { of, merge } from 'rxjs'
import { push } from 'redux-first-history'
import { TrainProjectListReq, DEFAULT_BOOKMARK_SCOPE } from 'models/ApiModels'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { AUTOSAVE_INTERVAL } from './index'
import { appSelectors } from 'duck/AppDuck'
import { generateRequestHistoryKey } from 'utils/DataProcessingUtils'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'

const retrieveProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.retrieve)),
    withLatestFrom(store),
    map(([action, state]) => ({
      currentUserId: apiSelectors.currentUserId(state),
      action
    })),
    filter(({ currentUserId }) => Boolean(currentUserId)),
    mergeMap(({ action }) =>
      merge(
        of(
          sharedActions.setLoading({
            loading: !Boolean(action.payload.silentUpdate),
            type: action.type
          })
        ),
        ApiUtils.projects.retrieve(action.payload.id).pipe(
          mergeMap(response => [
            sharedActions.setLoading({
              loading: false,
              type: action.type
            }),
            apiActions.projects.retrieveResponse({
              data: response.data,
              silentUpdate: action.payload.silentUpdate
            })
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const listProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.list)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, reloadList = false } = action.payload
      const scope = param?.scope
      const params = reloadList
        ? {}
        : {
            next:
              scope === 'current_user'
                ? state.api?.projects?.lastListReq?.next
                : state.api?.projects?.lastProjectPublicListReq?.next
          }
      const extraParams = reloadList ? (param as TrainProjectListReq) : undefined
      return { reloadList, params, extraParams, type: action.type, param }
    }),
    map(props => {
      const { extraParams } = props
      const urlParams = new URLSearchParams()

      _forEach(extraParams, (values, key) => {
        if (Array.isArray(values)) {
          values.forEach((value: string) => {
            urlParams.append(key, value)
          })
        } else if (values) {
          urlParams.append(key, `${values}`)
        }
      })

      return { ...props, urlParams }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ reloadList, params, urlParams, param, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.listWithUrlSearchParams(urlParams, params.next).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.listResponse({ data: response.data, reloadList, req: param })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { urlParams, params } }))
          )
        )
      )
    )
  )

const listActiveProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.listActiveProjects)),
    withLatestFrom(store),
    map(([action]) => {
      const params = new URLSearchParams()
      params.append('limit', '60')
      params.append('offset', '0')
      params.append('scope', 'current_user')
      params.append('status', 'waiting')
      params.append('status', 'processing')

      return { params, type: action.type }
    }),
    switchMap(({ params, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.listWithUrlSearchParams(params).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.listActiveProjectsResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: params })))
        )
      )
    )
  )

const createProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.create)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.create(payload).pipe(
          concatMap(response => [
            apiActions.projects.createResponse(response.data),
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.retrieveSummary(),
            push(route.TRAIN_PROJECTS.getUrl({ id: response.data.id }))
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const copyProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.copy)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.copy(payload).pipe(
          concatMap(response => [
            apiActions.projects.copyResponse(response.data),
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.retrieveSummary(),
            push(route.TRAIN_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.update)),
    debounceTime(AUTOSAVE_INTERVAL),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.update(payload.id, payload).pipe(
          tap(response => {
            if (payload.name) {
              MixPanelUtils.track<'PROJECT__RENAME'>(
                'Project - Rename',
                DataUtils.getProjectParam<'training_project'>('training_project', {
                  trainProject: response.data
                })
              )
            }
          }),
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.updateResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const startProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.start)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: type })),
        ApiUtils.projects.start(payload.id, payload).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: type }),
            apiActions.projects.startResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const moreProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.more)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.more(payload.id, payload).pipe(
          concatMap(response => [
            apiActions.projects.moreResponse(response.data),
            apiActions.projects.retrieve({ id: response.data.id, silentUpdate: true }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const stopProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.stop)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: `${payload}`, type })),
        ApiUtils.projects.stop(payload).pipe(
          concatMap(response => [
            apiActions.projects.stopResponse(response.data),
            apiActions.projects.listActiveProjects(),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const deleteProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.delete)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.delete(payload).pipe(
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.deleteResponse(payload),
            apiActions.projects.retrieveSummary(),
            apiActions.users.retrieveEquity(),
            push(route.TRAIN_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const retrieveSummaryEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.retrieveSummary)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectSummaryRetrieved: apiSelectors.projectSummaryRetrieved(state),
      type: action.type
    })),
    switchMap(({ type, projectSummaryRetrieved }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.retrieveSummary().pipe(
          tap(({ data }) => {
            !projectSummaryRetrieved && MixPanelUtils.updatePeople(data)
          }),
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.retrieveSummaryResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const updateThumbnailProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.updateThumbnail)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.updateThumbnail(payload).pipe(
          mergeMap(() => [
            apiActions.projects.updateThumbnailResponse(),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveOutputImagesEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.retrieveOutputImages)),
    withLatestFrom(store),
    map(([action, state]) => {
      const trainOutputImages = apiSelectors.trainOutputImages(state)
      const projectId = action.payload.data.project
      const data = action.payload.data

      const requestHistoryKey = generateRequestHistoryKey(data)
      const next = trainOutputImages[projectId]?.requestHistory?.[requestHistoryKey]?.next

      const result = { req: data, type: action.type }

      /* If next is true, then only return data with next URL */
      if (action.payload.next) {
        return next
          ? {
              data: { next },
              ...result
            }
          : {
              data: null, //If there is no next page, then dont fetch.
              ...result
            }
      }

      return {
        data,
        ...result,
        type: action.type
      }
    }),
    filter(({ data }) => Boolean(data)),
    mergeMap(({ data, req, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.retrieveOutputImages(data ?? { next: '' }).pipe(
          mergeMap(response => [
            apiActions.projects.retrieveOutputImagesResponse({
              data: response.data,
              req
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: data ?? '' }))
          )
        )
      )
    )
  )

const listRandomImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.listRandomImages)),
    withLatestFrom(store$),
    map(([action]) => {
      const { next = false } = action.payload
      return { next, type: action.type, params: action.payload.params }
    }),
    exhaustMap(({ next, type, params }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.listRandomImages(undefined, params).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.listRandomImagesResponse({
              data: response.data,
              next,
              params
            })
          ]),
          takeUntil(
            action$.pipe(
              filter(isActionOf(sharedActions.cancelRequest)),
              filter(({ payload }) => payload === getType(apiActions.projects.listRandomImages))
            )
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: params })))
        )
      )
    )
  )

const generateProjectMixEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.generateProjectMix)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      canTrain: apiSelectors.equity(state)?.upsellState?.canTrain ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrain }) => !canTrain),
          map(() =>
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'mix-image',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            })
          )
        ),
        of(param).pipe(
          filter(({ canTrain }) => Boolean(canTrain)),
          switchMap(({ type, payload }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.projects.generateProjectMix(payload.id, payload).pipe(
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.projects.generateProjectMixResponse(response.data)
                ]),
                catchError(err =>
                  of(sharedActions.setError({ error: err.response, type, req: payload }))
                )
              )
            )
          )
        )
      )
    )
  )

const generateRandomProjectMixEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.generateRandomProjectMix)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      canTrain: apiSelectors.equity(state)?.upsellState?.canTrain ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrain }) => !canTrain),
          map(() =>
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'mix-image',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            })
          )
        ),
        of(param).pipe(
          filter(({ canTrain }) => Boolean(canTrain)),
          switchMap(({ type, payload }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.projects.generateRandomProjectMix(payload.id, payload).pipe(
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.projects.generateRandomProjectMixResponse(response.data)
                ]),
                catchError(err =>
                  of(sharedActions.setError({ error: err.response, type, req: payload }))
                )
              )
            )
          )
        )
      )
    )
  )

const createProjectMixEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.createProjectMix)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.createProjectMix(payload).pipe(
          concatMap(response => [
            apiActions.projects.createProjectMixResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveProjectMixEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.retrieveProjectMix)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.retrieveProjectMix(payload).pipe(
          concatMap(response => [
            apiActions.projects.retrieveProjectMixResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listUserImageBookmark: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.listUserImageBookmark)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, silentUpdate } = action.payload
      const nextData =
        state.api.projects.userImageBookmarkLists?.[param.scope ?? DEFAULT_BOOKMARK_SCOPE]
          ?.lastRequest?.next

      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      return { next, params, extraParams, type: action.type, silentUpdate, param }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    delay(300), //Delay to prevent loadMore code get canceled because it fired immediately after listBookmarkResponse
    exhaustMap(({ next, params, extraParams, type, silentUpdate, param }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.projects.listUserImageBookmark(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.projects.listUserImageBookmarkResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )
const toggleUserImageBookmarkEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.toggleUserImageBookmark)),
    withLatestFrom(store),
    map(([action, store]) => ({
      type: action.type,
      userImage: apiSelectors.userImages(store)[action.payload.item],
      projectData: appSelectors.projectData(store),
      isLoading: Boolean(apiSelectors.loading['projects.toggleUserImageBookmark'](store)),
      ...action.payload
    })),
    filter(({ userImage, isLoading }) => Boolean(userImage) && !isLoading),
    mergeMap(({ userImage, type, scope, projectData, bookmark_location }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        of(userImage).pipe(
          filter(({ bookmark }) => Boolean(bookmark)),
          map(({ id }) =>
            apiActions.projects.toggleUserImageBookmarkInstantResponse({
              userImageId: id,
              bookmark: false
            })
          )
        ),
        of(userImage).pipe(
          filter(({ bookmark }) => Boolean(bookmark) && _toNumber(bookmark) > 0),
          mergeMap(({ bookmark, id }) =>
            merge(
              ApiUtils.projects.deleteUserImageBookmark(bookmark ?? 0).pipe(
                tap(() => {
                  MixPanelUtils.track<'PROJECT__USER_IMAGE_BOOKMARK'>(
                    'Project - User Image Bookmark',
                    {
                      ...projectData,
                      bookmark_location,
                      bookmark_scope: scope,
                      bookmark_action_type: 'unsave'
                    }
                  )
                }),
                mergeMap(() => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.projects.toggleUserImageBookmarkResponse({
                    deletedData: { bookmarkId: bookmark ?? 0, userImageId: id ?? 0, scope }
                  })
                ]),
                catchError(err =>
                  of(err).pipe(
                    mergeMap(err => [
                      sharedActions.setError({ error: err.response, type, req: id }),
                      apiActions.projects.toggleUserImageBookmarkInstantResponse({
                        userImageId: id,
                        bookmark: true
                      }),
                      sharedActions.setLoading({ loading: false, type })
                    ])
                  )
                )
              )
            )
          )
        ),
        of(userImage).pipe(
          filter(({ bookmark }) => !Boolean(bookmark)),
          mergeMap(userImage =>
            merge(
              of(
                apiActions.projects.toggleUserImageBookmarkInstantResponse({
                  userImageId: userImage.id,
                  bookmark: true
                })
              ),
              ApiUtils.projects.createUserImageBookmark({ item: userImage.id, scope }).pipe(
                tap(() => {
                  MixPanelUtils.track<'PROJECT__USER_IMAGE_BOOKMARK'>(
                    'Project - User Image Bookmark',
                    {
                      ...projectData,
                      bookmark_location,
                      bookmark_scope: scope,
                      bookmark_action_type: 'save'
                    }
                  )
                }),
                mergeMap(response =>
                  merge(
                    of(sharedActions.setLoading({ loading: false, type })),
                    of(
                      apiActions.projects.toggleUserImageBookmarkResponse({
                        data: response.data
                      })
                    ),
                    of(snackBarActions.show({ content: 'Image Saved!' })),
                    of(response).pipe(
                      delay(1000),
                      map(() => snackBarActions.close())
                    )
                  )
                ),
                catchError(err =>
                  of(err).pipe(
                    mergeMap(err => [
                      sharedActions.setError({ error: err.response, type, req: userImage.id }),
                      apiActions.projects.toggleUserImageBookmarkInstantResponse({
                        userImageId: userImage.id,
                        bookmark: false
                      }),
                      sharedActions.setLoading({ loading: false, type })
                    ])
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const retrieveInferenceEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.retrieveInference)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.retrieveInference(payload).pipe(
          mergeMap(response => [
            apiActions.projects.retrieveInferenceResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createInferenceRecordEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.createInferenceRecord)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.createInferenceRecord(payload).pipe(
          mergeMap(response => [
            apiActions.projects.createInferenceRecordResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const addInferenceInputEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.addInferenceInput)),
    filter(action => Boolean(action?.payload?.image)),
    map(action => {
      const formData = new FormData()
      formData.append('image', action?.payload?.image as Blob)
      return { formData, payload: action.payload, type: action.type }
    }),
    mergeMap(({ formData, payload, type }) =>
      ApiUtils.projects.addInferenceInput(payload.id, formData).pipe(
        map(response =>
          apiActions.projects.addInferenceInputResponse({ data: response.data, req: payload })
        ),
        catchError(err =>
          of(
            sharedActions.setError({
              error: err.response,
              req: payload,
              type
            })
          )
        )
      )
    )
  )

const startInferenceEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.startInference)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.startInference(payload.id, payload).pipe(
          mergeMap(response => [
            apiActions.projects.startInferenceResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const projectEpics = [
  retrieveProjectsEpic,
  listProjectsEpic,
  listActiveProjectsEpic,
  createProjectEpic,
  copyProjectEpic,
  updateProjectEpic,
  startProjectEpic,
  moreProjectEpic,
  stopProjectEpic,
  deleteProjectEpic,
  retrieveSummaryEpic,
  updateThumbnailProjectEpic,
  retrieveOutputImagesEpic,
  listRandomImagesEpic,
  generateProjectMixEpic,
  generateRandomProjectMixEpic,
  createProjectMixEpic,
  retrieveProjectMixEpic,
  toggleUserImageBookmarkEpic,
  listUserImageBookmark,
  retrieveInferenceEpic,
  createInferenceRecordEpic,
  addInferenceInputEpic,
  startInferenceEpic
]

export default projectEpics
