import React, { useCallback, useState, useEffect } from 'react'
import clsx from 'clsx'
import Canvas from 'components/Canvas'
import CanvasBuilder, { Size } from './CanvasBuilder'
import { Stage } from 'konva/lib/Stage'
import { Layer } from 'konva/lib/Layer'
import { useExecuteOnChange, useWindowDimension } from 'utils/Hooks'
import { ImageType } from 'models/ApiModels'
import { CircularLoading } from 'components/Loading'
import Typography from 'components/Typography'
import { getImageSize } from 'utils/DataProcessingUtils'
import { Format } from 'utils/TextUtils'
import DropdownMenu from 'components/DropdownMenu'
import styles from './LargeImagePreview.module.scss'

export const MAX_ZOOM_VALUE = 1000
export const MIN_ZOOM_VALUE = 1
export const DEFAULT_ZOOM_VALUE = 100

export type ImagePreviewCommand = { type: ImagePreviewCommandType; payload?: any }
export const EMPTY_COMMAND = { type: null }
export const INITIAL_CAMERA = {
  x: 0,
  y: 0
}
export type ImagePreviewCommandType = 'home' | null

export type ImagePreviewProps = {
  noBorder?: boolean
  loading?: boolean | string
  image?: ImageType
  imageFile?: File
  command?: ImagePreviewCommand
  setCommand?: (command: ImagePreviewCommand) => void
  setZoomValue?: (value: number) => void
  size: Size
  disablePreview?: boolean
}

const ImagePreview: React.FC<ImagePreviewProps> = props => {
  const {
    size,
    noBorder,
    imageFile,
    image,
    command,
    loading,
    disablePreview,
    setZoomValue,
    setCommand
  } = props
  const { width, height } = size
  const [imageLoading, setImageLoading] = useState<boolean | string>(false)
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM_VALUE)
  const [imageFileData, setImageFileData] = useState<
    { width: number; height: number; fileUrl: string } | undefined
  >(undefined)

  const loadingCombined = loading || imageLoading

  useEffect(() => {
    if (imageFile) {
      getImageSize(imageFile).then(data => setImageFileData(data))
    } else {
      setImageFileData(undefined)
    }
  }, [imageFile])

  const [stage, setStage] = useState<Stage | null>(null)
  const [layer, setLayer] = useState<Layer | null>(null)
  const [canvasBuilder, setCanvasBuilder] = useState<ReturnType<typeof CanvasBuilder> | null>(null)

  if (layer && stage && !canvasBuilder) {
    const canvasBuilder = CanvasBuilder({
      layer,
      camera: INITIAL_CAMERA,
      stage,
      stageSize: {
        width,
        height
      }
    })
    setCanvasBuilder(canvasBuilder)
  }

  useEffect(() => {
    if (canvasBuilder) {
      canvasBuilder.setStageSize({ width, height })
    }
  }, [width, height, canvasBuilder])

  const updateImage = useCallback(() => {
    if (canvasBuilder) {
      if (image || imageFileData) {
        setImageLoading('Loading Image')
      }
      const imageData = image ?? {
        id: 1,
        file: imageFileData?.fileUrl ?? '',
        width: imageFileData?.width ?? 0,
        height: imageFileData?.height ?? 0
      }
      if (width && height) {
        canvasBuilder.updateImage(
          imageData,
          () => {
            const imageWidth = imageData?.width ?? width
            const imageHeight = imageData?.height ?? height

            const largestSide = Math.max(imageWidth, imageHeight)
            const largestStageSize = Math.max(width, height)

            const zoom = (largestStageSize / largestSide) * 100
            const cameraX = ((imageWidth * zoom) / 100 - width) / 2
            const cameraY = ((imageHeight * zoom) / 100 - height) / 2
            const camera = { x: -cameraX, y: -cameraY }

            canvasBuilder.setZoom(zoom)
            canvasBuilder.setCamera(camera)
            canvasBuilder.setHome({ zoom, camera })

            setImageLoading(false)
          },
          () => {
            setImageLoading(false)
          }
        )
      }
    }
  }, [canvasBuilder, height, image, imageFileData, width])

  const executeUpdateImage = `${Boolean(canvasBuilder)} ${image?.file} ${Boolean(
    size.width && size.height
  )}`

  useExecuteOnChange({
    executor: updateImage,
    executeOn: executeUpdateImage
  })

  const zoomListener = useCallback(
    (zoom: number) => {
      setZoomValue?.(zoom)
      setZoom(zoom)
    },
    [setZoomValue]
  )

  useEffect(() => {
    if (disablePreview !== null && disablePreview !== undefined) {
      canvasBuilder?.disablePreview(disablePreview)
    }
  }, [width, height, canvasBuilder, disablePreview])
  useEffect(() => {
    if (canvasBuilder) {
      canvasBuilder.setStageSize({ width, height })
    }
  }, [width, height, canvasBuilder])

  useEffect(() => {
    if (canvasBuilder) {
      canvasBuilder.setStageSize({ width, height })
    }
  }, [width, height, canvasBuilder])

  useEffect(() => {
    canvasBuilder?.setListener('zoomListener', zoomListener)
  }, [canvasBuilder, zoomListener])

  useEffect(() => {
    const type = command?.type
    if (type) {
      switch (type) {
        case 'home': {
          canvasBuilder?.goHome()
          break
        }

        default:
          break
      }
      setCommand?.(EMPTY_COMMAND)
    }
  }, [canvasBuilder, command, setCommand])

  useWindowDimension()

  return (
    <div className={clsx(styles.LargeImagePreviewContainer, !noBorder && styles.Border)}>
      {loadingCombined ? (
        <div className={styles.ImageLoadingContainer}>
          <CircularLoading loading disableShrink />

          {typeof loadingCombined === 'string' ? (
            <Typography className="mt-3" color="secondary" variant="body1">
              {loadingCombined}
            </Typography>
          ) : null}
        </div>
      ) : null}

      <div className={styles.DropdownContainer}>
        <DropdownMenu
          disabled={Boolean(loadingCombined)}
          placement="top-start"
          color={'light'}
          buttonLabel={`${Format.number(zoom)}%`}
          disablePortal={true}
          items={[
            {
              key: 'zoom-out',
              label: 'Zoom Out',
              onClick: () => canvasBuilder?.setZoom(zoom - 10)
            },
            { key: 'zoom-in ', label: 'Zoom In', onClick: () => canvasBuilder?.setZoom(zoom + 10) },
            {
              disabled: (canvasBuilder?.getHome()?.zoom || DEFAULT_ZOOM_VALUE) > DEFAULT_ZOOM_VALUE,
              key: 'zoom-to-100 ',
              label: 'Zoom To 100%',
              onClick: () => {
                canvasBuilder?.setZoom(DEFAULT_ZOOM_VALUE)
                canvasBuilder?.setCamera(INITIAL_CAMERA)
              }
            },
            {
              key: 'zoom-to-fit ',
              label: 'Zoom To Fit',
              onClick: () => canvasBuilder?.goHome()
            }
          ]}
        />
      </div>

      <Canvas
        className={styles.Canvas}
        getStage={setStage}
        getLayer={setLayer}
        width={width - 2}
        height={height - 2}
        fill="#121212"
      />
    </div>
  )
}

export default ImagePreview
