import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  map,
  withLatestFrom,
  concatMap,
  mergeMap,
  take,
  tap,
  startWith,
  switchMap,
  throttleTime,
  ignoreElements,
  delay
} from 'rxjs/operators'
import { merge, of, concat } from 'rxjs'
import { RootState, RootActionType } from 'duck'
import {
  ActionType,
  getType,
  isActionOf,
  createAction,
  PayloadActionCreator
} from 'typesafe-actions'
import { createSelector } from 'reselect'
import _toInteger from 'lodash/toInteger'
import _slice from 'lodash/slice'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _compact from 'lodash/compact'
import {
  SelectDirection,
  SelectedFormat,
  errorUtils,
  SelectUtils,
  getCurrentCollectionKey
} from 'utils/DataProcessingUtils'
import {
  Collection,
  TagListReq,
  CollectionUpdateReq,
  ImageSetType,
  TrainProjectListReq,
  TrainProjectOrderingType
} from 'models/ApiModels'
import { appActions } from 'duck/AppDuck'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { LocalStorage, SessionStorage } from 'utils'
import { errorMessage, LSKey, values } from 'appConstants'
import { ErrorDialog } from 'duck/AppDuck/DialogDuck/Models'
import SentryUtils from 'utils/SentryUtils'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { push } from 'redux-first-history'
import { route } from 'routes'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'
import { dialogActions } from 'duck/AppDuck/DialogDuck'

// Constants
const NAMESPACE = '@@page/CollectionEditorPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const MAXIMUM_TAGS = 30
export const UPLOAD_BATCH_COUNT = 5
export const TAG_SEPARATOR = ','
export const ANIMATION_DURATION = 250

export type FileReceivedParam = {
  source?: CollectionSourceType
  files?: File[]
}

export type UploadFailedParam = {
  file: File
  errors: string | string[]
}

type SaveCollectionReq = Pick<CollectionUpdateReq, 'id' | 'name' | 'category' | 'is_private'>

export type OpenCollectionParam = ActionType<typeof actions.openCollection>['payload']

export type CollectionActionParam = {
  source?: CollectionSourceType
  collectionId: number
}

// Actions
export const actions = {
  setIsNewCollection: createAction(creator('SET_IS_NEW_COLLECTION'))<boolean>(),
  getTags: createAction(creator('GET_TAGS'))<string>(),
  setEditMode: createAction(creator('SET_EDIT_MODE'))<{
    editMode?: CollectionEditorPanelState['editMode']
  }>(),
  setShowDialog: createAction(creator('SET_SHOW_DIALOG'))<{ showDialog: boolean }>(),
  setHasUnsavedChanges: createAction(creator('SET_HAS_UNSAVED_CHANGES'))<{
    hasUnsavedChanges: boolean
  }>(),
  deleteCollection: createAction(creator('DELETE_COLLECTION'))(),
  openCollection: createAction(creator('UPLOAD/OPEN_COLLECTION'))<{
    source?: CollectionSourceType
    collectionId?: number
  }>(),
  setSelectedImages: createAction(creator('UPLOAD/SET_SELECTED_IMAGES'))<{
    imageId: number
  }>(),
  setSelectedImagesValue: createAction(creator('UPLOAD/SET_SELECTED_IMAGES_VALUE'))<{
    imageId: number
    value: boolean
  }>(),
  deselectAllImages: createAction(creator('UPLOAD/DESELECT_ALL_IMAGES'))(),
  selectAllImages: createAction(creator('UPLOAD/SELECT_ALL_IMAGES'))(),
  loadImages: createAction(creator('UPLOAD/LOAD_IMAGES'))<{
    next?: boolean
  }>(),
  saveCollection: createAction(creator('UPLOAD/SAVE_COLLECTION'))<{
    updateReq: SaveCollectionReq
    afterSavedAction?: PayloadActionCreator<string, CollectionActionParam>
  }>(),
  resetCollectionState: createAction(creator('UPLOAD/RESET_COLLECTION_STATE'))(),
  addExistingProject: {
    openPanel: createAction(creator('ADD_EXISTING_PROJECT/OPEN_PANEL'))<number>(),
    closePanel: createAction(creator('ADD_EXISTING_PROJECT/CLOSE_PANEL'))(),
    addToProject: createAction(creator('ADD_EXISTING_PROJECT/ADD_TO_PROJECT'))<{
      projectId: number
      imageSet: ImageSetType
    }>(),
    setSortBy: createAction(
      creator('ADD_EXISTING_PROJECT/SET_SORT_BY')
    )<TrainProjectOrderingType>(),
    openNewProject: createAction(creator('ADD_EXISTING_PROJECT/OPEN_NEW_PROJECT'))()
  },
  upload: {
    resetUploadProgress: createAction(creator('UPLOAD/RESET_UPLOAD_PROGRESS'))(),
    onFileReceived: createAction(creator('UPLOAD/ON_FILE_RECEIVED'))<FileReceivedParam>(),
    onFileInvalid: createAction(creator('UPLOAD/ON_FILE_INVALID'))<UploadFailedParam>(),
    onUploadSuccess: createAction(creator('UPLOAD/ON_UPLOAD_SUCCESS'))(),
    onUploadFailed: createAction(creator('UPLOAD/ON_UPLOAD_FAILED'))<UploadFailedParam>(),
    onUploadFinished: createAction(creator('UPLOAD/ON_UPLOAD_FINISHED'))(),
    showUploadError: createAction(creator('SHOW_UPLOAD_ERROR'))(),
    startUploadBatch: createAction(creator('UPLOAD/START_UPLOAD_BATCH'))(),
    cancelUpload: createAction(creator('UPLOAD/CANCEL_UPLOAD'))()
  }
}

// Selectors

export const utils = {
  getImageCount: (collection?: Collection) => {
    const imageResultCount = collection?.imagesData?.results?.length ?? 0
    const imageCount = collection?.imagesData?.count ?? 0

    return imageResultCount > imageCount ? imageResultCount : imageCount
  },
  getUploadProgressCalculated: (
    progress: UploadProgressType,
    uploadBatch: UploadStateType['uploadBatch'],
    isCanceled: boolean
  ) => {
    const processed = progress.succeed + progress.failed
    const canceled = isCanceled ? progress.total - processed : 0

    return {
      ...progress,
      canceled,
      processed,
      uploadLeft: progress.total - (progress.succeed + progress.failed + canceled),
      progressPercent: ((progress.succeed + progress.failed + canceled) / progress.total) * 100,
      cancelingLeft: uploadBatch.length - progress.currentBatchProcessed //When cancel is clicked but some images still uploading
    }
  }
}

const selectCollectionEditor = (state: RootState) => state.container.collectionEditorPanel

const selectUploadState = createSelector(
  selectCollectionEditor,
  collectionEditor => collectionEditor.uploadState
)
const selectCollectionId = createSelector(
  selectCollectionEditor,
  collectionEditor => collectionEditor.currentCollection
)

const selectCollectionData = createSelector(
  selectCollectionId,
  apiSelectors.images,
  apiSelectors.collections,
  (collectionId = 0, images, collections) => {
    const collection = collections[collectionId]
    const imagesData = images[collectionId]
    return collection
      ? {
          ...collection,
          imagesData
        }
      : undefined
  }
)

const selectSelectedImagesCount = createSelector(selectCollectionEditor, collectionEditor => {
  const { selectedImages } = collectionEditor

  return SelectUtils.countSelected(selectedImages)
})

export const selectors = {
  collectionEditor: selectCollectionEditor,
  uploadState: selectUploadState,
  collectionData: selectCollectionData,
  isNewCollection: createSelector(
    selectCollectionEditor,
    collectionEditor => collectionEditor.isNewCollection
  ),
  showDialogEdit: createSelector(
    selectCollectionEditor,
    collectionEditor => collectionEditor.showDialogEdit
  ),
  showDialog: createSelector(
    selectCollectionEditor,
    collectionEditor => collectionEditor.showDialog
  ),
  source: createSelector(selectCollectionEditor, collectionEditor => collectionEditor.source),
  editMode: createSelector(selectCollectionEditor, collectionEditor => collectionEditor.editMode),
  selectedImages: createSelector(
    selectCollectionEditor,
    collectionEditor => collectionEditor.selectedImages
  ),
  selectedImagesCount: selectSelectedImagesCount,
  hasUnsavedChanges: createSelector(
    selectCollectionEditor,
    selectSelectedImagesCount,
    (collectionEditor, selectedImagesCount) => {
      const { hasUnsavedChanges } = collectionEditor
      return hasUnsavedChanges || selectedImagesCount
    }
  ),
  isUploading: createSelector(
    selectUploadState,
    uploadState => uploadState.uploadProgress.isUploading
  ),
  collectionIdToBeAddedToExistingProject: createSelector(
    selectCollectionEditor,
    collectionEditor => collectionEditor.collectionIdToBeAddedToExistingProject
  ),
  projectListReq: createSelector(
    selectCollectionEditor,
    collectionEditor => collectionEditor.projectListReq
  )
}
export type UploadProgressType = {
  isUploading: boolean
  isFinished: boolean
  uploadStarted: boolean
  currentBatchProcessed: number
  total: number
  succeed: number
  failed: number

  //derived
  canceled?: number
  uploadLeft?: number
  progressPercent?: number
  processed?: number
  cancelingLeft?: number
}
export type UploadErrorsType = { file: File; errors: string | string[] }[]

export const COLLECTION_SOURCE_TYPES = [
  'aesthetic',
  'inspiration',
  'bottomsheet_my_collections',
  'bottomsheet_public_collections',
  'bottomsheet_bookmark_collections',
  'explore_collections',
  'home_collections',
  'feed_detail',
  'input_panel',
  'artist_profile'
] as const

export const BOTTOMSHEET_BAR_TYPE: CollectionSourceType[] = [
  'aesthetic',
  'inspiration',
  'bottomsheet_my_collections',
  'bottomsheet_public_collections',
  'bottomsheet_bookmark_collections'
]
export const COLLECTION_LIST_TYPE: CollectionSourceType[] = [
  'explore_collections',
  'home_collections',
  'feed_detail',
  'artist_profile'
]
export const BOTTOMSHEET_BAR_LIST_TYPE: CollectionSourceType[] = [
  'bottomsheet_public_collections',
  'bottomsheet_my_collections',
  'bottomsheet_bookmark_collections'
]

export const NEW_COLLECTION_FROM_INPUT_PANEL: CollectionSourceType[] = ['aesthetic', 'inspiration']

export type CollectionSourceType = (typeof COLLECTION_SOURCE_TYPES)[number]

export type UploadStateType = {
  isCanceled: boolean
  uploadImages: File[] //Collection of queued uploaded images
  uploadBatch: File[] //Current batch that need to be uploaded
  uploadProgress: UploadProgressType
  // During up  loading progress, error file will be contained in there.
  // And collectively displayed to user after all images uploaded.
  uploadErrors: UploadErrorsType
}

export type CollectionEditorPanelState = {
  isNewCollection?: boolean
  source?: CollectionSourceType
  collectionIdToBeAddedToExistingProject?: number
  hasUnsavedChanges?: boolean
  projectListReq: TrainProjectListReq
  fileReceivedQueued?: File[]
  showDialogEdit: boolean | ''
  showDialog: boolean
  editMode?: 'draft' | 'edit' | false | 0 //Use 0 as falsy value
  currentCollection?: number
  selectedImages: SelectedFormat
  selectDirection: SelectDirection
  uploadState: UploadStateType
}
const INITIAL_UPLOAD_STATE: UploadStateType = {
  isCanceled: false,
  uploadImages: [],
  uploadBatch: [],
  uploadProgress: {
    isUploading: false,
    isFinished: false,
    uploadStarted: false,
    currentBatchProcessed: 0,
    total: 0,
    succeed: 0,
    failed: 0
  },
  uploadErrors: []
}

const INITIAL: CollectionEditorPanelState = {
  source: undefined,
  isNewCollection: false,
  hasUnsavedChanges: undefined,
  collectionIdToBeAddedToExistingProject: undefined,
  projectListReq: {
    ordering: '-modified',
    scope: 'current_user',
    limit: 30
  },
  fileReceivedQueued: undefined,
  showDialogEdit: false,
  showDialog: false,
  editMode: undefined,
  currentCollection: 0,
  selectedImages: {},
  selectDirection: 'normal' as SelectDirection, // it's mean if selectedImages empty, then all images selected
  uploadState: { ...INITIAL_UPLOAD_STATE }
}

const reducer = produce((state: CollectionEditorPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setIsNewCollection): {
      const isNewCollection = payload as ActionType<typeof actions.setIsNewCollection>['payload']

      state.isNewCollection = isNewCollection
      return
    }

    case getType(actions.setHasUnsavedChanges): {
      const { hasUnsavedChanges } = payload as ActionType<
        typeof actions.setHasUnsavedChanges
      >['payload']

      state.hasUnsavedChanges = hasUnsavedChanges
      return
    }
    case getType(actions.setEditMode): {
      const { editMode } = payload as ActionType<typeof actions.setEditMode>['payload']

      state.editMode = editMode
      return
    }
    /* Upload Image set Block */
    case getType(actions.openCollection): {
      const { collectionId, source } = payload as ActionType<
        typeof actions.openCollection
      >['payload']
      state.currentCollection = collectionId
      state.source = source
      return
    }
    case getType(actions.setShowDialog): {
      const { showDialog } = payload as ActionType<typeof actions.setShowDialog>['payload']
      state.showDialog = showDialog
      return
    }
    case getType(actions.setSelectedImages): {
      const { imageId } = payload as ActionType<typeof actions.setSelectedImages>['payload']
      const { selectedImages } = state
      selectedImages[imageId] = !selectedImages[imageId]
      state.selectedImages = selectedImages
      return
    }
    case getType(actions.setSelectedImagesValue): {
      const { imageId, value } = payload as ActionType<
        typeof actions.setSelectedImagesValue
      >['payload']

      const selectedImages = { ...state.selectedImages }
      selectedImages[imageId] = value
      state.selectedImages = selectedImages
      return
    }
    case getType(actions.deselectAllImages): {
      state.selectedImages = INITIAL.selectedImages
      state.selectDirection = INITIAL.selectDirection
      return
    }
    case getType(actions.selectAllImages): {
      state.selectedImages = {}
      state.selectDirection = 'inverse'
      return
    }

    case getType(actions.resetCollectionState): {
      state.isNewCollection = INITIAL.isNewCollection
      state.fileReceivedQueued = INITIAL.fileReceivedQueued
      state.showDialogEdit = INITIAL.showDialogEdit
      state.showDialog = INITIAL.showDialog
      state.editMode = INITIAL.editMode
      state.currentCollection = INITIAL.currentCollection
      state.selectedImages = INITIAL.selectedImages
      state.hasUnsavedChanges = INITIAL.hasUnsavedChanges
      state.selectDirection = INITIAL.selectDirection
      state.source = INITIAL.source
      state.uploadState = { ...INITIAL_UPLOAD_STATE }

      return
    }

    /* Upload General Block */
    case getType(actions.upload.resetUploadProgress): {
      state.uploadState = { ...INITIAL_UPLOAD_STATE }
      return
    }
    case getType(actions.upload.onFileReceived): {
      const { files = [] } = payload as ActionType<typeof actions.upload.onFileReceived>['payload']
      if (files && files.length) {
        const isUploading = state.uploadState.uploadProgress.isUploading

        //Reset uploading progress value if just get started.
        if (!isUploading) {
          state.uploadState.uploadProgress = {
            ...INITIAL.uploadState.uploadProgress
          }
          state.uploadState.isCanceled = false
          state.uploadState.uploadErrors = []
          state.uploadState.uploadImages = []
        }

        const currentUpload = state.uploadState.uploadImages || []
        state.uploadState.uploadImages = [...currentUpload, ...files]
        state.uploadState.uploadProgress.total += files.length || 0
      }
      return
    }
    case getType(actions.upload.startUploadBatch): {
      const currentUpload = state.uploadState.uploadImages || []
      const succeed = state.uploadState.uploadProgress.succeed
      const failed = state.uploadState.uploadProgress.failed
      const processed = succeed + failed

      state.uploadState.uploadBatch = _slice(
        currentUpload,
        processed,
        processed + UPLOAD_BATCH_COUNT
      )
      state.uploadState.uploadProgress.isUploading = true
      state.uploadState.uploadProgress.currentBatchProcessed = 0
      return
    }
    case getType(actions.upload.onUploadSuccess): {
      state.uploadState.uploadProgress.succeed += 1
      state.uploadState.uploadProgress.currentBatchProcessed += 1
      return
    }
    case getType(actions.upload.onUploadFailed): {
      const { file, errors } = payload as ActionType<
        typeof actions.upload.onUploadFailed
      >['payload']

      state.uploadState.uploadProgress.failed += 1
      state.uploadState.uploadProgress.currentBatchProcessed += 1
      state.uploadState.uploadErrors = [...state.uploadState.uploadErrors, { file, errors }]
      return
    }
    case getType(actions.upload.onUploadFinished): {
      state.uploadState.uploadProgress.isUploading = false
      state.uploadState.uploadProgress.isFinished = true
      state.uploadState.uploadImages = []
      return
    }
    case getType(actions.upload.cancelUpload): {
      state.uploadState.isCanceled = true
      return
    }
    case getType(actions.addExistingProject.openPanel): {
      const collectionId = payload as ActionType<
        typeof actions.addExistingProject.openPanel
      >['payload']

      state.collectionIdToBeAddedToExistingProject = collectionId
      return
    }
    case getType(actions.addExistingProject.closePanel): {
      state.collectionIdToBeAddedToExistingProject = undefined
      return
    }
    case getType(actions.addExistingProject.setSortBy): {
      const sortBy = payload as ActionType<typeof actions.addExistingProject.setSortBy>['payload']

      state.projectListReq.ordering = sortBy
      return
    }
    default:
  }
}, INITIAL)

// Epics
const getTagsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.getTags)),
    map(({ payload }) => {
      const param: TagListReq = {
        ordering: 'name',
        search: payload,
        limit: 10
      }
      return param
    }),
    map(param => apiActions.collections.listTags(param))
  )

const openNewProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.addExistingProject.openNewProject)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const collectionId = selectors.collectionData(state)?.id ?? 0
      const source = selectors.collectionEditor(state).source

      return { collectionId, source }
    }),
    map(({ collectionId, source }) =>
      appActions.useCollectionInNewProject({ collectionId, source })
    )
  )
const addToProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.addExistingProject.addToProject)),
    withLatestFrom(state$),
    map(([payload, state]) => {
      const collectionId = selectors.collectionIdToBeAddedToExistingProject(state) ?? 0
      const imageSet = payload.payload.imageSet
      const projectId = payload.payload.projectId

      return { collectionId, projectId, imageSet }
    }),
    tap(({ collectionId, imageSet }) => {
      SessionStorage.saveJSON(LSKey.COLLECTION_ADDED_TO_EXISTING_PROJECT, {
        collectionId,
        imageSet
      })
    }),
    concatMap(({ projectId }) => [
      actions.addExistingProject.closePanel(),
      push(route.TRAIN_PROJECTS.getUrl({ id: projectId, subRoute: 'input' }))
    ])
  )

const openPanelEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([actions.addExistingProject.openPanel, actions.addExistingProject.setSortBy])
    ),
    withLatestFrom(state$),
    delay(300),
    map(([action, state]) => {
      const hasProject = Boolean(apiSelectors.projectList(state).length)
      const projectListReq = selectors.projectListReq(state)
      return { hasProject, projectListReq, type: action.type }
    }),
    filter(
      ({ hasProject, type }) =>
        !hasProject || type === getType(actions.addExistingProject.setSortBy)
    ),
    map(({ projectListReq }) =>
      apiActions.projects.list({ param: projectListReq, reloadList: true })
    )
  )

/*  Load saved collection */
const openCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.openCollection)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      collections: apiSelectors.collections(state),
      collectionId: action.payload.collectionId
    })),
    map(({ collections, collectionId }) => ({
      collections,
      collectionId
    })),
    map(({ collections, collectionId }) => ({
      currentCollection: collectionId,
      collections
    })),
    mergeMap(({ currentCollection, collections }) =>
      merge(
        of({ currentCollection, collections }).pipe(
          filter(({ currentCollection }) => Boolean(currentCollection)),
          map(({ currentCollection = 0, collections }) => ({
            currentCollection,
            resultImages: collections[currentCollection]?.imagesData?.results
          })),
          map(({ currentCollection, resultImages }) => ({
            currentCollection,
            hasImages: Boolean(resultImages?.length)
          })),
          concatMap(param =>
            concat(
              of(param).pipe(
                filter(({ hasImages }) => !hasImages),
                map(() => actions.loadImages({ next: false }))
              ),
              of(param).pipe(
                mergeMap(() =>
                  action$.pipe(
                    filter(isActionOf(apiActions.collections.retrieveResponse)),
                    take(1),
                    map(({ payload }) =>
                      actions.setEditMode({ editMode: payload.is_draft ? 'draft' : 0 })
                    ),
                    startWith(apiActions.collections.retrieve(_toInteger(param.currentCollection)))
                  )
                )
              )
            )
          )
        )
      )
    )
  )

/*
 *   UPLOAD EPIC
 *
 */

const onFileReceivedAndNoCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.upload.onFileReceived)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      source: action.payload.source,
      collectionEditor: selectors.collectionEditor(state)
    })),
    map(({ collectionEditor, source }) => ({
      source,
      files: collectionEditor.uploadState.uploadImages,
      currentCollection: collectionEditor.currentCollection
    })),
    filter(({ currentCollection }) => !currentCollection),
    mergeMap(param =>
      merge(
        of(actions.setEditMode({ editMode: 'draft' })),
        of(actions.setShowDialog({ showDialog: true })),
        of(param).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.collections.createResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => ({
                files: param.files,
                source: param.source,
                currentCollection: action.payload.id,
                collection: action.payload,
                projectId: apiSelectors.currentProjectId(state),
                collectionCategories: apiSelectors.categoriesObject(state)
              })),
              tap(({ collection, collectionCategories }) => {
                const trackData = DataUtils.getCollectionData({
                  collection,
                  category: collectionCategories
                })
                MixPanelUtils.track<'COLLECTION__CREATE'>('Collection - Create', {
                  ...trackData,
                  upload_submit_count: param.files.length
                })
              }),
              mergeMap(param =>
                concat(
                  // Store collection in redux
                  of(param).pipe(
                    map(({ currentCollection, source }) => ({
                      source,
                      collectionId: _toInteger(currentCollection)
                    })),
                    concatMap(param => [
                      actions.openCollection({
                        source: param.source,
                        collectionId: param.collectionId
                      }),
                      actions.setIsNewCollection(true)
                    ])
                  ),
                  of(
                    actions.upload.onFileReceived({
                      files: undefined
                    })
                  )
                )
              ),
              // This one is executed first, and code above is listening finished collection.
              startWith(
                apiActions.collections.create({
                  is_private: true,
                  name: ''
                })
              )
            )
          )
        )
      )
    )
  )

// Trigger upload batch
const onFileReceivedAndHasCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.upload.onFileReceived)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      collectionEditor: selectors.collectionEditor(state)
    })),
    map(({ collectionEditor }) => ({
      collectionEditor,
      uploadProgress: utils.getUploadProgressCalculated(
        collectionEditor.uploadState.uploadProgress,
        collectionEditor.uploadState.uploadBatch,
        collectionEditor.uploadState.isCanceled
      )
    })),
    map(({ collectionEditor, uploadProgress }) => ({
      currentCollection: collectionEditor.currentCollection,
      isUploading: uploadProgress.isUploading
    })),
    filter(({ currentCollection }) => Boolean(currentCollection)),
    //If Uploading, then don't continue. Let the batch clock do the work
    filter(({ isUploading }) => !Boolean(isUploading)),
    mergeMap(() => [actions.setEditMode({ editMode: 'edit' }), actions.upload.startUploadBatch()])
  )

// Trigger upload batch
const startUploadBatchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.upload.startUploadBatch)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      collectionEditor: selectors.collectionEditor(state)
    })),
    map(({ collectionEditor }) => ({
      files: collectionEditor.uploadState.uploadBatch,
      currentCollection: collectionEditor.currentCollection
    })),
    filter(({ currentCollection }) => Boolean(currentCollection)),
    map(({ files, currentCollection = 0 }) =>
      _map(files, file => {
        if ((file?.size ?? 0) > values.MAX_IMAGE_SIZE) {
          const errors = errorMessage.ERROR_UPLOAD_TOO_LARGE.content
          return actions.upload.onFileInvalid({ errors, file })
        } else {
          return apiActions.collections.createImage({
            file,
            collection: currentCollection
          })
        }
      })
    ),
    mergeMap((uploadActions: any[]) => uploadActions)
  )

/* Listen when upload is succeed
 *  -  Dispatch success action
 */
const onUploadSucceedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.createImageResponse)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      action,
      state
    })),
    map(({ action, state }) => ({
      collectionEditor: selectors.collectionEditor(state),
      action
    })),
    map(({ collectionEditor, action }) => ({
      selectDirection: collectionEditor.selectDirection,
      currentCollection: collectionEditor.currentCollection,
      imageId: action?.payload?.data?.id || 0,
      uploadProgress: collectionEditor.uploadState.uploadProgress
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(map(() => actions.upload.onUploadSuccess())),
        of(param).pipe(
          filter(({ uploadProgress }) => !uploadProgress.isUploading),
          map(({ currentCollection = 0 }) => apiActions.collections.retrieve(currentCollection))
        )
      )
    )
  )

/* Listen when upload is failed
 *  - Add error message in uploadErrors Redux.
 *  - Dispatch failed action
 *  - Add conditon to detect whether upload finished or not
 */

const onUploadFailedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(action => action.payload.type === getType(apiActions.collections.createImage)),
    map(({ payload }) => {
      let errors = errorUtils.flattenMessage(payload)

      if (_get(payload, 'error.status') === 413) {
        errors = errorMessage.ERROR_UPLOAD_TOO_LARGE.content
      }

      return {
        file: _get(payload, 'req.file'),
        errors
      }
    }),
    map(param =>
      actions.upload.onUploadFailed({
        ...param
      })
    )
  )
const onFileInvalidEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.upload.onFileInvalid)),
    map(({ payload }) => actions.upload.onUploadFailed(payload))
  )

// Epic Listen when upload finished.
const listenUploadFinishedEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.upload.onUploadFailed,
        actions.upload.cancelUpload,
        actions.upload.onUploadSuccess,
        actions.upload.onFileInvalid
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      collections: apiSelectors.collections(state),
      collectionCategories: apiSelectors.categoriesObject(state),
      collectionEditor: selectors.collectionEditor(state),
      action
    })),
    map(({ collectionEditor, action, collections, collectionCategories }) => ({
      collectionEditor,
      source: collectionEditor.source,
      uploadProgress: utils.getUploadProgressCalculated(
        collectionEditor.uploadState.uploadProgress,
        collectionEditor.uploadState.uploadBatch,
        collectionEditor.uploadState.isCanceled
      ),
      currentCollection: collectionEditor.currentCollection || 0,
      collectionCategories,
      collection: collections[collectionEditor.currentCollection ?? 0],
      isCancel: action.type === getType(actions.upload.cancelUpload)
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(
            ({ uploadProgress, collectionEditor, isCancel }) =>
              uploadProgress.currentBatchProcessed >=
                collectionEditor.uploadState.uploadBatch.length &&
              !isCancel &&
              !collectionEditor.uploadState.isCanceled
          ),
          filter(({ uploadProgress }) => uploadProgress.total > uploadProgress.processed),
          map(() => actions.upload.startUploadBatch())
        ),
        of(param).pipe(
          filter(
            ({ uploadProgress, isCancel }) =>
              uploadProgress.total === uploadProgress.processed || isCancel
          ),
          tap(({ collection, collectionCategories, uploadProgress, isCancel }) => {
            const trackData = DataUtils.getCollectionData({
              collection,
              category: collectionCategories
            })

            MixPanelUtils.track<'COLLECTION__UPLOAD_FINISHED'>('Collection - Upload Finished', {
              ...trackData,
              upload_submit_count: uploadProgress.total,
              is_cancel: isCancel,
              uploaded_count: uploadProgress.succeed
            })
          }),
          concatMap(({ collectionEditor, currentCollection }) =>
            _compact([
              actions.upload.showUploadError(),
              actions.upload.onUploadFinished(),
              currentCollection && apiActions.collections.updateThumbnail(currentCollection),
              currentCollection && apiActions.collections.retrieve(currentCollection)
            ])
          )
        )
      )
    )
  )

const showUploadErrorEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.upload.showUploadError)),
    withLatestFrom(state$),
    map(([action, state]) => selectors.collectionEditor(state).uploadState?.uploadErrors),
    filter(uploadErrors => Boolean(uploadErrors && uploadErrors.length)),
    map(uploadErrors =>
      _map(
        uploadErrors,
        value =>
          `${value?.file?.name ?? ''} (${
            value?.errors || errorMessage.ERROR_UPLOAD_UNKNOWN.content
          })`
      )
    ),
    tap(errorList => {
      SentryUtils.captureMessage(
        `Unable To Upload Collection Images`,
        {
          errorList
        },
        'log'
      )
    }),
    map(errorList =>
      dialogActions.openDialog({
        [ErrorDialog.UPLOAD_FAILED]: {
          dialogName: ErrorDialog.UPLOAD_FAILED,
          content: { errorList, count: errorList.length }
        }
      })
    )
  )

const loadImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadImages)),
    withLatestFrom(state$),
    throttleTime(200),
    map(([action, state]) => ({
      collection: selectors.collectionEditor(state),
      next: action.payload.next
    })),
    map(({ collection, next }) => ({
      param: { collection: collection.currentCollection ?? 0 },
      next
    })),
    filter(({ param }) => Boolean(param.collection)),
    switchMap(({ next, param }) => [apiActions.collections.listImage({ param, next })])
  )

const saveCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.saveCollection)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      updateReq: action.payload.updateReq,
      collectionState: selectors.collectionEditor(state),
      afterSavedAction: action.payload.afterSavedAction
    })),
    map(({ collectionState, updateReq, afterSavedAction }) => ({
      afterSavedAction,
      source: collectionState.source,
      currentCollectionId: collectionState.currentCollection,
      exclude_images: SelectUtils.getSelectedList(collectionState.selectedImages),
      updateReq
    })),
    map(param => ({
      ...param,
      hasExcludeImages: Boolean(param.exclude_images.length)
    })),
    mergeMap(param =>
      merge(
        of(actions.setHasUnsavedChanges({ hasUnsavedChanges: false })),
        of(param).pipe(
          mergeMap(
            ({
              hasExcludeImages,
              exclude_images,
              updateReq,
              afterSavedAction,
              currentCollectionId = 0,
              source
            }) =>
              action$.pipe(
                filter(isActionOf([apiActions.collections.updateResponse])),
                take(1),
                map(({ payload }) => {
                  const imageCount = payload.count

                  if (!payload.is_private && imageCount < values.MIN_PUBLIC_COLLECTION_IMAGE) {
                    return { shouldSetPrivate: true }
                  }

                  return { shouldSetPrivate: false }
                }),
                mergeMap(({ shouldSetPrivate }) =>
                  _compact([
                    actions.setEditMode({ editMode: false }),
                    hasExcludeImages && actions.deselectAllImages(),
                    hasExcludeImages && actions.loadImages({ next: false }),
                    hasExcludeImages && apiActions.collections.updateThumbnail(updateReq.id),
                    shouldSetPrivate &&
                      apiActions.collections.update({ id: updateReq.id, is_private: true }),
                    afterSavedAction &&
                      afterSavedAction({ collectionId: currentCollectionId, source })
                  ])
                ),
                // This one is executed first, and code above is listening finished collection.
                startWith(apiActions.collections.update({ ...updateReq, exclude_images }))
              )
          )
        )
      )
    )
  )

const resetCollectionStateEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.resetCollectionState)),
    withLatestFrom(state$),
    tap(([action, state]) => {
      const collectionState = selectors.collectionEditor(state)
      const source = collectionState.source ?? 'inspiration'
      const projectId = apiSelectors.currentProjectId(state)
      LocalStorage.remove(getCurrentCollectionKey(projectId, source))
    }),
    ignoreElements()
  )

const deleteCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.deleteCollection)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      currentCollection: selectors.collectionEditor(state).currentCollection,
      source: selectors.collectionEditor(state).source
    })),
    mergeMap(({ currentCollection, source }) =>
      action$.pipe(
        filter(isActionOf([apiActions.collections.deleteResponse])),
        take(1),
        mergeMap(() => [
          actions.resetCollectionState(),
          eventEmiterActions.emit({
            [AppEvents.COLLECTION_DELETED]: {
              event: AppEvents.COLLECTION_DELETED
            }
          })
        ]),
        // This one is executed first, and code above is listening finished collection.
        startWith(apiActions.collections.delete(`${currentCollection}`))
      )
    )
  )

const listenOnAppEvent: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    mergeMap(({ payload }) =>
      merge(
        of(payload).pipe(
          filter(({ [AppEvents.COLLECTION_ADDED]: event }) => Boolean(event)),
          mergeMap(() => [
            actions.setShowDialog({ showDialog: false }),
            actions.resetCollectionState()
          ])
        ),
        of(payload).pipe(
          filter(({ [AppEvents.ON_FILE_RECEIVED]: event }) => Boolean(event)),
          map(({ [AppEvents.ON_FILE_RECEIVED]: event }) => event?.payload),
          filter(fileReceived => Boolean(fileReceived)),
          map(fileReceived =>
            actions.upload.onFileReceived(fileReceived ?? { files: undefined, source: undefined })
          )
        )
      )
    )
  )

export const epics = combineEpics(
  addToProjectEpic,
  openNewProjectEpic,
  openPanelEpic,
  getTagsEpic,
  openCollectionEpic,
  onFileReceivedAndNoCollectionEpic,
  onFileReceivedAndHasCollectionEpic,
  startUploadBatchEpic,
  onUploadSucceedEpic,
  onUploadFailedEpic,
  onFileInvalidEpic,
  listenUploadFinishedEpic,
  showUploadErrorEpic,
  loadImagesEpic,
  saveCollectionEpic,
  resetCollectionStateEpic,
  deleteCollectionEpic,
  listenOnAppEvent
)
export default reducer
