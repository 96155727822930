import 'firebase/auth'
import { initializeApp, FirebaseApp } from 'firebase/app'

import {
  getAuth,
  PhoneAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  RecaptchaVerifier,
  FacebookAuthProvider,
  Auth
} from 'firebase/auth'

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_API_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_API_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

let app: FirebaseApp | undefined | null = null
let recaptchaVerifier: RecaptchaVerifier | undefined = undefined
let auth: Auth | undefined | null = null

export const FirebaseUtils = {}

export const initializeFirebase = () => {
  if (!app) {
    app = initializeApp(FIREBASE_CONFIG)
    auth = getAuth(app)

    recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {}
      },
      auth
    )
  }

  return app
}

export const getFirebaseApp = () => {
  if (app === null || app === undefined) {
    return initializeFirebase()
  }

  return app
}

export const getAuthProvider = {
  phoneAuth: (auth: Auth) => {
    const provider = new PhoneAuthProvider(auth)
    return provider
  },
  google: () => {
    const provider = new GoogleAuthProvider()
    return provider
  },

  twitter: () => {
    const provider = new TwitterAuthProvider()
    return provider
  },
  facebook: () => {
    const provider = new FacebookAuthProvider()
    return provider
  }
}

export const getPhoneAuthProviderCredential = (
  verificationId: string = '',
  verificationCode: string = ''
) => {
  return PhoneAuthProvider.credential(verificationId, verificationCode)
}

export const getRecaptchaVerifier = () => {
  return recaptchaVerifier
}

export async function getIdToken(forceRefresh = false) {
  const currentUser = getCurrentUser()

  if (currentUser) {
    return currentUser.getIdToken(forceRefresh)
  }
  return null
}

export function getCurrentUser() {
  app = getFirebaseApp()
  return auth?.currentUser
}

export function getFirebaseAuth() {
  return getAuth(getFirebaseApp())
}

export const logout = () => auth?.signOut()
