import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
  mergeMap
} from 'rxjs/operators'

import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { apiActions, sharedActions } from 'duck/ApiDuck'
import { GetText } from 'utils/TextUtils'

const listEngineConfigEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.listEngineConfig)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.engine.listEngineConfig().pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.listEngineConfigResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveClipEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.retrieveClip)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.engine.retrieveClip(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.retrieveClipResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateClipEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.updateClip)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: `${payload.id}`, type })),
        ApiUtils.engine.updateClip(payload.id, payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.updateClipResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createClipEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.createClip)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.engine.createClip(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.createClipResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const generateClipVideoEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.generateClipVideo)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.engine.generateClipVideo(payload.id, payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.generateClipVideoResponse(payload.id)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const generateClipPreviewEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.generateClipPreview)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.engine.generateClipPreview(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.generateClipPreviewResponse()
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.listClip)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, silentUpdate } = action.payload
      const projectKey = GetText.clipListKey({ id: param.project, type: param.type })
      const nextData = state.api.engine.clipLists?.[projectKey]?.lastReq?.next
      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      return { next, params, extraParams, type: action.type, silentUpdate, param }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type, silentUpdate, param }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.engine.listClip(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.listClipResponse({
              data: response.data,
              next,
              request: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const deleteClipEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.deleteClip)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.engine.deleteClip(payload.clip).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.deleteClipResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const copyClipEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.copyClip)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.engine.copyClip(payload.id).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.engine.copyClipResponse(response.data),
            apiActions.engine.updateClip({
              id: response.data.id,
              name: payload.name
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const engineEpic = [
  copyClipEpic,
  deleteClipEpic,
  listEngineConfigEpic,
  retrieveClipEpic,
  listClipEpic,
  generateClipPreviewEpic,
  generateClipVideoEpic,
  updateClipEpic,
  createClipEpic
]

export default engineEpic
