import {
  ActionType,
  createReducer,
  isActionOf,
  createAction,
  PayloadAction,
  EmptyAction,
  getType
} from 'typesafe-actions'
import { RootActionType, RootState } from 'duck'
import { Epic, combineEpics } from 'redux-observable'
import { XOR } from 'ts-xor'
import {
  catchError,
  concatMap,
  delay,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import _compact from 'lodash/compact'
import _toInteger from 'lodash/toInteger'
import _map from 'lodash/map'
import { from, bindCallback, of, merge } from 'rxjs'
import { imageDownloader } from 'utils/DownloaderUtils'
import { saveAs, SaveAsParam } from 'utils/FileSaver'
import JSZipUtils from 'jszip-utils'
import { createSelector } from 'reselect'
import SentryUtils from 'utils/SentryUtils'
import { appSelectors } from '.'
import { dialogActions, InformationDialog, MonetizationDialog } from './DialogDuck'
import { CreateDownloadImageReq, UserImage, UserVideo } from 'models/ApiModels'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { snackBarActions } from './SnackBarDuck'
import MixPanelUtils, { DownloadLocation } from 'utils/MixPanelUtils'
import { store } from 'index'

const CLOSE_DELAY = 2000

export type ExecuteParam = {
  files: { fileUrl: string; imageName: string }[]
  fileName: string
  withIncrementPrefix?: boolean
}

export type DownloadSingleImageParam = XOR<
  { imageFile: string },
  { image: UserImage | undefined }
> & {
  imageLoadingId?: number
  fileNamePrefix?: string
  fileName?: string
  downloadLocation: DownloadLocation
  originalUserImage?: UserImage
}

export type DownloadVideoParam = {
  downloadLocation: DownloadLocation
  video: UserVideo
  fileName?: string
}

export const downloaderActions = {
  multiple: {
    onDownloadFinished: createAction('DOWNLOADER/ON_DOWNLOAD_FINISHED')(),
    incrementDownloadProgress: createAction('DOWNLOADER/INCREMENT_DOWNLOAD_PROGRESS')<{
      status: boolean
      error?: any
    }>(),
    executeDownloadMultipleImage: createAction(
      'DOWNLOADER/EXECUTED_DOWNLOAD_MULTIPLE_IMAGE'
    )<ExecuteParam>(),
    resetDownload: createAction('DOWNLOADER/RESET_DOWNLOAD')()
  },
  single: {
    downloadSingleVideo: createAction('DOWNLOADER/DOWNLOAD_SINGLE_VIDEO')<DownloadVideoParam>(),
    downloadSingleImage: createAction(
      'DOWNLOADER/DOWNLOAD_SINGLE_IMAGE'
    )<DownloadSingleImageParam>(),
    setDownloading: createAction('DOWNLOADER/SET_DOWNLOADING')<
      {
        imageId: number
      } & Partial<DownloadProgressSingletype>
    >(),
    resetDownloading: createAction('DOWNLOADER/RESET_DOWNLOADING')<{
      imageId: number
    }>(),
    downloadPaidUserImage: createAction('DOWNLOADER/DOWNLOAD_PAID_USER_IMAGE')<{
      downloadLocation: DownloadLocation
      originalUserImage?: UserImage
      userImage: UserImage
      fileName?: string
      fileNamePrefix?: string
      setAsFree?: boolean
    }>(),
    payAndDownloadResponse: createAction('DOWNLOADER/PAY_AND_DOWNLOAD_RESPONSE')(),
    payAndDownload: createAction('DOWNLOADER/PAY_AND_DOWNLOAD')<{
      price: number
      downloadLocation: DownloadLocation
      showPaidSnackBar: boolean
      originalUserImage?: UserImage
      userImage: UserImage
      fileName: string
      nextAction?: PayloadAction<string, any> | EmptyAction<string>
    }>()
  }
}

export type DownloaderActions = ActionType<typeof downloaderActions>

// Selectors
const selectDownloader = (state: RootState) => state.container.appPage.downloader

const downloadProgressMultiple = createSelector(selectDownloader, downloader => {
  return downloader.downloadProgressMultiple
})

const downloadProgressSingle = createSelector(selectDownloader, downloader => {
  return downloader.downloadProgressSingle
})

export const downloaderSelectors = {
  downloadProgressMultiple: createSelector(
    downloadProgressMultiple,
    (downloadProgressMultiple = INITIAL_STATE.downloadProgressMultiple) => {
      const { total, success, failed } = downloadProgressMultiple
      return {
        ...downloadProgressMultiple,
        processed: success + failed,
        percentage: total > 1 ? ((success + failed + 1) / total) * 100 : 0
      }
    }
  ),
  downloadProgressSingleText: createSelector(
    downloadProgressSingle,
    (downloadProgressSingle = {}) => {
      const downloadProgressArray = _map(downloadProgressSingle, progress => {
        const { retrieveAdjusted, createDownload, retrieveDownload, downloading } = progress

        const isDownloading = retrieveAdjusted || createDownload || retrieveDownload || downloading

        return {
          ...progress,
          isDownloading
        }
      })

      const downloadingItem = downloadProgressArray.filter(progress =>
        Boolean(progress.isDownloading)
      )

      if (downloadingItem.length > 1) {
        const progressValue = downloadingItem.reduce((result, value) => {
          result = result + (value.downloadProgress ?? 0)
          return result
        }, 0)
        const downloadProgress = Math.round((progressValue / (downloadingItem.length * 100)) * 100)
        return {
          text: `Downloading  ${downloadingItem.length} images`,
          downloadProgress
        }
      } else if (downloadingItem.length === 1) {
        const {
          type,
          retrieveAdjusted,
          isPaid,
          createDownload,
          retrieveDownload,
          downloadProgress,
          downloading
        } = downloadingItem[0]

        const text1 = createDownload && isPaid ? 'Purchasing Image...' : ''
        const text2 =
          retrieveAdjusted || createDownload || retrieveDownload ? 'Preparing Download Data...' : ''
        const text3 =
          downloading && isPaid
            ? `Image Purchased, Downloading ${type}...`
            : downloading
              ? `Downloading ${type}...`
              : ''

        return {
          text: text1 || text2 || text3,
          downloadProgress: downloading ? downloadProgress : undefined
        }
      } else {
        return undefined
      }
    }
  )
}

// Reducer

export type DownloadProgressSingletype = {
  type: 'image' | 'video'
  isPaid: boolean
  retrieveAdjusted: boolean
  createDownload: boolean
  retrieveDownload: boolean
  downloading: string | boolean
  downloadProgress: number | undefined
}
export type DownloaderState = {
  downloadProgressSingle: {
    [userImageId: number]: DownloadProgressSingletype
  }
  downloadProgressMultiple: {
    downloading: boolean
    total: number
    success: number
    failed: number
    errorLog: object[]
  }
}

const DOWNLOAD_PROGRESS_SINGLE_INITIAL: DownloadProgressSingletype = {
  type: 'image',
  isPaid: false,
  createDownload: false,
  retrieveAdjusted: false,
  retrieveDownload: false,
  downloading: false,
  downloadProgress: undefined
}

export const INITIAL_STATE: DownloaderState = {
  downloadProgressSingle: {},
  downloadProgressMultiple: {
    downloading: false,
    total: 0,
    success: 0,
    failed: 0,
    errorLog: []
  }
}

const reducer = createReducer<DownloaderState, DownloaderActions>(INITIAL_STATE)
  .handleAction(downloaderActions.multiple.onDownloadFinished, state => ({
    ...state,
    downloadProgressMultiple: {
      ...state.downloadProgressMultiple,
      downloading: false
    }
  }))
  .handleAction(downloaderActions.multiple.executeDownloadMultipleImage, (state, { payload }) => ({
    ...state,
    downloadProgressMultiple: {
      ...INITIAL_STATE.downloadProgressMultiple,
      downloading: true,
      total: payload.files.length
    }
  }))
  .handleAction(downloaderActions.multiple.incrementDownloadProgress, (state, { payload }) => {
    const { success, failed, errorLog } = state.downloadProgressMultiple

    return {
      ...state,
      downloadProgressMultiple: {
        ...state.downloadProgressMultiple,
        success: payload.status ? success + 1 : success,
        failed: !payload.status ? failed + 1 : failed,
        errorLog
      }
    }
  })
  .handleAction(downloaderActions.multiple.resetDownload, state => {
    return {
      ...state,
      downloadProgressMultiple: INITIAL_STATE.downloadProgressMultiple
    }
  })
  .handleAction(downloaderActions.single.setDownloading, (state, { payload }) => {
    const { imageId } = payload
    return imageId
      ? {
          ...state,
          downloadProgressSingle: {
            ...state.downloadProgressSingle,
            [imageId]: {
              ...(state.downloadProgressSingle[imageId] ?? DOWNLOAD_PROGRESS_SINGLE_INITIAL),
              ...payload
            }
          }
        }
      : state
  })
  .handleAction(downloaderActions.single.resetDownloading, (state, { payload }) => {
    const { imageId } = payload
    return {
      ...state,
      downloadProgressSingle: {
        ...state.downloadProgressSingle,
        [imageId]: DOWNLOAD_PROGRESS_SINGLE_INITIAL
      }
    }
  })

// Epic

const executeDownloadMultipleImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.multiple.executeDownloadMultipleImage)),
    map(({ payload }) => payload),
    filter(({ files }) => Boolean(files.length)),
    tap(({ files, fileName }) => imageDownloader.startDownloadSession(fileName, files.length)),
    mergeMap(param =>
      merge(
        of(
          snackBarActions.show({
            content: `Downloading...`,
            actionText: '0 %'
          })
        ),
        of(param).pipe(
          mergeMap(({ files, withIncrementPrefix }) =>
            from(files).pipe(
              concatMap(({ fileUrl, imageName }) =>
                bindCallback(JSZipUtils.getBinaryContent)(fileUrl).pipe(
                  tap(([err, file]) => {
                    imageDownloader.addImages({
                      err,
                      file,
                      imageName,
                      withIncrementPrefix
                    })
                  }),
                  withLatestFrom(state$),
                  map(([param, state]) => {
                    const { processed } = downloaderSelectors.downloadProgressMultiple(state)
                    return processed + 1 < files.length
                      ? downloaderActions.multiple.incrementDownloadProgress({ status: !param[0] })
                      : downloaderActions.multiple.onDownloadFinished()
                  }),
                  catchError(err =>
                    of(err).pipe(
                      withLatestFrom(state$),
                      map(([param, state]) => {
                        const { processed } = downloaderSelectors.downloadProgressMultiple(state)
                        return processed + 1 < files.length
                          ? downloaderActions.multiple.incrementDownloadProgress({
                              status: false,
                              error: err
                            })
                          : downloaderActions.multiple.onDownloadFinished()
                      })
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const onDownloadFinishedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.multiple.onDownloadFinished)),
    withLatestFrom(state$),
    map(([_, state]) => downloaderSelectors.downloadProgressMultiple(state)),
    filter(({ failed }) => Boolean(failed)),
    tap(({ success, failed }) => {
      SentryUtils.captureMessage(
        `Some images not downloaded - ${success} images successfully downloaded, ${failed} images failed to download`,
        {},
        'log'
      )
    }),
    mergeMap(({ success, failed }) => [
      dialogActions.addDialog({
        [InformationDialog.INFORMATION]: {
          dialogName: InformationDialog.INFORMATION,
          content: `${success} images successfully downloaded, ${failed} images failed to download`
        }
      }),
      downloaderActions.multiple.resetDownload()
    ])
  )

const getOnProgressCallback = (userImageId: number): SaveAsParam['onProgress'] => {
  return (progress: number) => {
    store.dispatch(
      downloaderActions.single.setDownloading({ imageId: userImageId, downloadProgress: progress })
    )
  }
}
const downloadSingleVideoEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.single.downloadSingleVideo)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: appSelectors.projectData(state),
      payload: action.payload
    })),
    map(({ projectData, payload }) => {
      const { fileName, video, downloadLocation } = payload

      const file = video.file

      const imageLoadingId = video.id * -1
      return { imageLoadingId, file, fileName, downloadLocation, projectData }
    }),
    mergeMap(param =>
      merge(
        of(
          downloaderActions.single.setDownloading({
            type: 'video',
            imageId: param.imageLoadingId,
            downloading: true
          })
        ),
        of(param).pipe(
          mergeMap(({ file, fileName, downloadLocation, imageLoadingId, projectData }) =>
            from(
              saveAs({
                url: file,
                filename: fileName,
                onProgress: getOnProgressCallback(imageLoadingId)
              })
            ).pipe(
              tap(() => {
                MixPanelUtils.track<'PROJECT__DOWNLOAD'>('Project - Download', {
                  ...projectData,
                  download_file_type: 'video',
                  download_location: downloadLocation
                })
              }),
              map(() =>
                downloaderActions.single.setDownloading({
                  imageId: imageLoadingId,
                  downloading: false
                })
              ),
              catchError(err =>
                of(err).pipe(
                  map(() =>
                    downloaderActions.single.setDownloading({
                      imageId: imageLoadingId,
                      downloading: false
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const downloadSingleImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.single.downloadSingleImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: appSelectors.projectData(state),
      payload: action.payload
    })),
    map(({ projectData, payload }) => {
      const {
        image,
        originalUserImage,
        fileNamePrefix = '',
        downloadLocation,
        fileName,
        imageFile
      } = payload
      const separator = fileNamePrefix ? '_' : ''
      const name =
        fileName ?? `${fileNamePrefix ?? ''}${separator}${image?.object_type}_${image?.id}`
      const file = imageFile ?? image?.file ?? ''

      const imageLoadingId = payload.imageLoadingId ?? originalUserImage?.id ?? image?.id ?? 0
      return { imageLoadingId, file, name, projectData, downloadLocation }
    }),
    mergeMap(param =>
      merge(
        of(
          downloaderActions.single.setDownloading({
            type: 'image',
            imageId: param.imageLoadingId,
            downloading: true
          })
        ),
        of(param).pipe(
          mergeMap(({ file, name, imageLoadingId, projectData, downloadLocation }) =>
            from(
              saveAs({
                url: file,
                filename: name,
                onProgress: getOnProgressCallback(imageLoadingId)
              })
            ).pipe(
              tap(() => {
                MixPanelUtils.track<'PROJECT__DOWNLOAD'>('Project - Download', {
                  ...projectData,
                  download_file_type: 'image',
                  download_location: downloadLocation
                })
              }),
              map(() =>
                downloaderActions.single.setDownloading({
                  imageId: imageLoadingId,
                  downloading: false
                })
              ),
              catchError(err =>
                of(err).pipe(
                  map(() =>
                    downloaderActions.single.setDownloading({
                      imageId: imageLoadingId,
                      downloading: false
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const incrementDownloadProgressEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.multiple.incrementDownloadProgress)),
    withLatestFrom(state$),
    map(([_, state]) => downloaderSelectors.downloadProgressMultiple(state)),
    filter(({ downloading }) => Boolean(downloading)),
    map(({ percentage }) =>
      snackBarActions.show({
        content: `Downloading...`,
        actionText: Boolean(percentage) ? `${Math.ceil(percentage)} %` : undefined
      })
    )
  )

const onDownloadFinishedDelayedEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.multiple.onDownloadFinished)),
    withLatestFrom(state$),
    map(([_, state]) => downloaderSelectors.downloadProgressMultiple(state)),
    filter(({ downloading }) => Boolean(!downloading)),
    delay(CLOSE_DELAY),
    map(() => snackBarActions.close())
  )

const payAndDownloadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.single.payAndDownload)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const userImageData = action.payload.userImage
      const originalUserImage = action.payload.originalUserImage
      const price = action.payload.price

      const imageLoadingId = originalUserImage?.id ?? userImageData.id

      const projectData = appSelectors.projectData(state)
      const downloadData = apiSelectors.downloadImages(state)[userImageData?.download ?? 0]

      const fileName = action.payload.fileName
      const hasDownload = Boolean(userImageData?.download)
      const hasDownloadData = Boolean(downloadData)

      return {
        imageLoadingId,
        projectData,
        price,
        downloadLocation: action.payload.downloadLocation,
        showPaidSnackBar: action.payload.showPaidSnackBar,
        originalUserImage,
        hasDownloadData,
        downloadData,
        hasDownload,
        userImageData,
        fileName,
        nextAction: action.payload.nextAction
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ hasDownload, hasDownloadData }) => hasDownload && hasDownloadData),
          mergeMap(
            ({
              downloadData,
              originalUserImage,
              imageLoadingId,
              fileName,
              downloadLocation,
              nextAction
            }) =>
              _compact([
                downloaderActions.single.downloadSingleImage({
                  imageLoadingId,
                  downloadLocation,
                  image: downloadData.image,
                  fileName,
                  originalUserImage
                }),
                downloaderActions.single.payAndDownloadResponse(),
                nextAction
              ])
          )
        ),
        of(param).pipe(
          filter(({ hasDownload, hasDownloadData }) => hasDownload && !hasDownloadData),
          mergeMap(param =>
            merge(
              of(
                downloaderActions.single.setDownloading({
                  imageId: param.imageLoadingId,
                  retrieveDownload: true
                })
              ),
              of(param).pipe(
                mergeMap(
                  ({
                    userImageData,
                    originalUserImage,
                    imageLoadingId,
                    fileName,
                    nextAction,
                    downloadLocation
                  }) =>
                    action$.pipe(
                      filter(isActionOf(apiActions.payment.retrieveDownloadImageResponse)),
                      take(1),
                      mergeMap(response =>
                        _compact([
                          downloaderActions.single.downloadSingleImage({
                            imageLoadingId,
                            downloadLocation,
                            image: response?.payload?.data?.image ?? 0,
                            fileName,
                            originalUserImage
                          }),
                          downloaderActions.single.setDownloading({
                            imageId: param.imageLoadingId,
                            retrieveDownload: false
                          }),
                          downloaderActions.single.payAndDownloadResponse(),
                          nextAction
                        ])
                      ),
                      startWith(
                        apiActions.payment.retrieveDownloadImage(userImageData.download ?? 0)
                      )
                    )
                )
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ hasDownload }) => !hasDownload),
          mergeMap(param =>
            merge(
              of(
                downloaderActions.single.setDownloading({
                  imageId: param.imageLoadingId,
                  createDownload: true
                })
              ),
              of(param).pipe(
                mergeMap(
                  ({
                    price,
                    userImageData,
                    fileName,
                    showPaidSnackBar,
                    nextAction,
                    originalUserImage,
                    downloadLocation,
                    projectData,
                    imageLoadingId
                  }) =>
                    action$.pipe(
                      filter(isActionOf(apiActions.payment.createDownloadImageResponse)),
                      take(1),
                      tap(() => {
                        MixPanelUtils.track<'PROJECT__PURCHASE_DOWNLOAD_IMAGE'>(
                          'Project - Purchase Download Image',
                          {
                            ...projectData,
                            download_location: downloadLocation,
                            price
                          }
                        )
                      }),
                      mergeMap(response =>
                        _compact([
                          downloaderActions.single.downloadSingleImage({
                            imageLoadingId,
                            downloadLocation,
                            image: response?.payload?.data?.image ?? 0,
                            fileName,
                            originalUserImage
                          }),
                          downloaderActions.single.payAndDownloadResponse(),
                          apiActions.payment.retrieveCreditOverview(),
                          nextAction,
                          downloaderActions.single.setDownloading({
                            imageId: imageLoadingId,
                            isPaid: showPaidSnackBar,
                            createDownload: false
                          })
                        ])
                      ),
                      startWith(
                        apiActions.payment.createDownloadImage({
                          originalImage: originalUserImage?.id,
                          image: userImageData.id ?? 0
                        })
                      )
                    )
                )
              )
            )
          )
        )
      )
    )
  )

const downloadPaidUserImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(downloaderActions.single.downloadPaidUserImage)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const userImages = apiSelectors.userImages(state)
      const userImageData = action.payload.userImage
      const originalUserImageData = action.payload.originalUserImage
      const downloadLocation = action.payload.downloadLocation
      const setAsFree = action.payload.setAsFree

      const adjustedImageDataRaw = apiSelectors.adjustedImages(state)[userImageData?.adjust ?? 0]
      const adjustedImageData = adjustedImageDataRaw
        ? { ...adjustedImageDataRaw, output: userImages[adjustedImageDataRaw.output?.id ?? 0] }
        : undefined

      const { price, endBalance, hasEnoughBalance } = apiSelectors.downloadPricing(state)

      const isFree = setAsFree || price === 0
      const fileNamePrefix = action.payload.fileName
      const hasDownload =
        Boolean(userImageData?.download) || Boolean(originalUserImageData?.download)

      const hasAdjustedImage = Boolean(userImageData.adjust)
      const hasAdjustedImageData = Boolean(adjustedImageData)

      const hasDownloadOrFree = !hasAdjustedImage && (hasDownload || isFree)
      const hasNoDownloadAndEnoughBalance =
        !hasAdjustedImage && !hasDownloadOrFree && hasEnoughBalance
      const hasNoDownloadAndNotEnoughBalance =
        !hasAdjustedImage && !hasDownloadOrFree && !hasEnoughBalance

      const separator = fileNamePrefix ? '_' : ''
      const fileName =
        action.payload.fileName ??
        `${fileNamePrefix ?? ''}${separator}${userImageData?.object_type}_${userImageData?.id}`

      return {
        userImages,
        userImageData,
        adjustedImageData,
        originalUserImageData,
        downloadLocation,

        fileName,
        price,
        endBalance,
        hasAdjustedImage,
        hasAdjustedImageData,
        hasDownloadOrFree,
        hasNoDownloadAndEnoughBalance,
        hasNoDownloadAndNotEnoughBalance
      }
    }),
    mergeMap(param =>
      merge(
        /* Download the adjusted version if it have it */
        of(param).pipe(
          filter(
            ({ hasAdjustedImageData, hasAdjustedImage }) => hasAdjustedImageData && hasAdjustedImage
          ),
          mergeMap(({ adjustedImageData, fileName, userImageData, downloadLocation }) =>
            _compact([
              adjustedImageData?.output &&
                downloaderActions.single.downloadPaidUserImage({
                  downloadLocation,
                  originalUserImage: userImageData,
                  userImage: adjustedImageData?.output,
                  fileName: `enhanced-${fileName}`
                })
            ])
          )
        ),
        /* Retrieve adjusted image and download if it have it */
        of(param).pipe(
          filter(
            ({ hasAdjustedImageData, hasAdjustedImage }) =>
              !hasAdjustedImageData && hasAdjustedImage
          ),
          mergeMap(param =>
            merge(
              of(
                downloaderActions.single.setDownloading({
                  imageId: param.userImageData.id,
                  retrieveAdjusted: true
                })
              ),
              of(param).pipe(
                mergeMap(({ userImageData, fileName, downloadLocation }) =>
                  action$.pipe(
                    filter(isActionOf(apiActions.imageEnhancement.retrieveAdjustedImageResponse)),
                    take(1),
                    mergeMap(response =>
                      _compact([
                        response.payload.output &&
                          downloaderActions.single.downloadPaidUserImage({
                            downloadLocation,
                            originalUserImage: userImageData,
                            userImage: response.payload.output,
                            fileName: `enhanced-${fileName}`
                          }),
                        downloaderActions.single.setDownloading({
                          imageId: userImageData.id,
                          retrieveAdjusted: false
                        })
                      ])
                    ),
                    startWith(
                      apiActions.imageEnhancement.retrieveAdjustedImage({
                        adjustedImageId: userImageData.adjust ?? 0
                      })
                    )
                  )
                )
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ hasDownloadOrFree }) => hasDownloadOrFree),
          map(({ userImageData, fileName, originalUserImageData, price, downloadLocation }) =>
            downloaderActions.single.payAndDownload({
              price,
              downloadLocation,
              originalUserImage: originalUserImageData,
              showPaidSnackBar: false,
              userImage: userImageData,
              fileName
            })
          )
        ),
        of(param).pipe(
          filter(({ hasNoDownloadAndEnoughBalance }) => hasNoDownloadAndEnoughBalance),
          map(({ userImageData, price, originalUserImageData, downloadLocation, fileName }) =>
            dialogActions.addDialogOverlay({
              [MonetizationDialog.IMAGE_DOWNLOAD]: {
                dialogName: MonetizationDialog.IMAGE_DOWNLOAD,
                price,
                downloadAction: downloaderActions.single.payAndDownload({
                  price,
                  downloadLocation,
                  showPaidSnackBar: true,
                  originalUserImage: originalUserImageData,
                  userImage: userImageData,
                  fileName,
                  nextAction: dialogActions.closeDialog()
                })
              }
            })
          )
        ),
        of(param).pipe(
          filter(({ hasNoDownloadAndNotEnoughBalance }) => hasNoDownloadAndNotEnoughBalance),
          map(
            ({
              userImageData,
              endBalance,
              fileName,
              originalUserImageData,
              price,
              downloadLocation
            }) =>
              dialogActions.addDialog({
                [MonetizationDialog.ADD_CREDIT]: {
                  dialogName: MonetizationDialog.ADD_CREDIT,
                  source: 'download',
                  requiredCredit: Math.abs(endBalance),
                  additionalAction: {
                    creditConsumed: price,
                    text: 'PURCHASE CREDIT AND DOWNLOAD IMAGE',
                    textAfterSuccess: 'Payment successful and image as been purchased',
                    action: downloaderActions.single.payAndDownload({
                      price,
                      downloadLocation,
                      showPaidSnackBar: false,
                      originalUserImage: originalUserImageData,
                      userImage: userImageData,
                      fileName
                    })
                  }
                }
              })
          )
        )
      )
    )
  )

export const listenOnErrorDownload: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    mergeMap(({ payload }) =>
      merge(
        of(payload).pipe(
          filter(payload => payload.type === getType(apiActions.payment.retrieveDownloadImage)),
          map(payload =>
            downloaderActions.single.setDownloading({
              imageId: _toInteger(payload.req),
              retrieveDownload: false
            })
          )
        ),
        of(payload).pipe(
          filter(payload => payload.type === getType(apiActions.payment.createDownloadImage)),
          map(({ req }) =>
            downloaderActions.single.setDownloading({
              imageId: _toInteger((req as CreateDownloadImageReq).originalImage),
              createDownload: false
            })
          )
        )
      )
    )
  )

export const downloaderEpics = combineEpics(
  downloadSingleVideoEpic,
  listenOnErrorDownload,
  downloadPaidUserImageEpic,
  payAndDownloadEpic,
  incrementDownloadProgressEpic,
  executeDownloadMultipleImageEpic,
  onDownloadFinishedEpic,
  onDownloadFinishedDelayedEpic,
  downloadSingleImageEpic
)

export default reducer
