import _toInteger from 'lodash/toInteger'
import _map from 'lodash/map'
import _compact from 'lodash/compact'
import _reduce from 'lodash/reduce'
import { RootState } from 'duck'
import { Category } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { LocalStorage } from 'utils'
import { LSKey } from 'appConstants'

const collections = (state: RootState) => {
  return state.api.collections.collections
}
const currentCollectionId = (state: RootState) => {
  return _toInteger(state.api.collections.currentCollectionId)
}

const collectionListId = (state: RootState) => {
  return state.api.collections.collectionLists.list_page?.list || []
}

const recentlyUsedCollectionListId = (state: RootState) => {
  return state.api.collections.collectionLists.recently_used?.list || []
}
const myCollectionListId = (state: RootState) => {
  return state.api.collections.collectionLists.my_collection?.list || []
}
const publicCollectionListId = (state: RootState) => {
  return state.api.collections.collectionLists.public?.list || []
}
const bookmarkCollectionListId = (state: RootState) => {
  return state.api.collections.collectionLists.bookmark?.list || []
}

const explorePageCollectionListId = (state: RootState) => {
  return state.api.collections.collectionLists.explore_page?.list || []
}
const homePageCollectionListId = (state: RootState) => {
  return state.api.collections.collectionLists.home_page?.list || []
}
const collectionList = createSelector(
  collections,
  collectionListId,
  (collections, collectionListId) =>
    _map(collectionListId, collectionId => collections[collectionId] || {})
)

const publicCollectionList = createSelector(
  collections,
  publicCollectionListId,
  (collections, collectionListId) =>
    _map(collectionListId, collectionId => collections[collectionId] || {})
)
const bookmarkCollectionList = createSelector(
  collections,
  bookmarkCollectionListId,
  (collections, collectionListId) =>
    _map(collectionListId, collectionId => collections[collectionId] || {})
)
const myCollectionList = createSelector(
  collections,
  myCollectionListId,
  (collections, collectionListId) =>
    _compact(_map(collectionListId, collectionId => collections[collectionId]))
)

const explorePageCollectionList = createSelector(
  collections,
  explorePageCollectionListId,
  (collections, collectionListId) =>
    _compact(_map(collectionListId, collectionId => collections[collectionId]))
)
const homePageCollectionList = createSelector(
  collections,
  homePageCollectionListId,
  (collections, collectionListId) =>
    _compact(_map(collectionListId, collectionId => collections[collectionId]))
)

const categoriesRaw = (state: RootState) => {
  return state?.api?.collections?.categories
}
const categories = createSelector(categoriesRaw, categoriesRaw => categoriesRaw?.results)
const isHasCategories = createSelector(categories, categories => categories && categories?.length)
const categoriesObject = createSelector(categories, categories => {
  const initial: { [key: number]: Category } = {}
  return _reduce(
    categories,
    (result, category) => {
      result[category.id] = category
      return result
    },
    initial
  )
})
const tags = (state: RootState) => {
  return state?.api?.collections?.tags
}

/* Exclude collection that still in upload stage */
const recentlyUsedCollectionList = createSelector(
  collections,
  recentlyUsedCollectionListId,
  (collections, collectionListId) => {
    //Remove the just uploaded collection from recently used list.
    const justUploadedColls = _map(LocalStorage.utils.getContains(LSKey.CURRENT_COLLECTION), val =>
      _toInteger(val)
    )

    return _compact(
      _map(collectionListId, collectionId => {
        if (!justUploadedColls.includes(collectionId)) {
          return collections[collectionId] || {}
        }
        return null
      })
    )
  }
)
const collectionSelectors = {
  currentCollectionId,
  collections,
  collectionListId,
  collectionList,
  recentlyUsedCollectionList,
  recentlyUsedCollectionListId,
  publicCollectionList,
  publicCollectionListId,
  myCollectionListId,
  myCollectionList,
  explorePageCollectionListId,
  explorePageCollectionList,
  bookmarkCollectionListId,
  bookmarkCollectionList,
  homePageCollectionListId,
  homePageCollectionList,
  categories,
  categoriesObject,
  isHasCategories,
  tags
}
export default collectionSelectors
