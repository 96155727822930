/**
 * Asynchronously loads the component for Sketch Text to Image Project page
 */

import loadable from 'utils/Loadable'
import { LoadingPage } from 'components/Loading'

export default loadable(
  () => import(/* webpackChunkName: "SketchTextToImageProjectPage" */ './index'),
  {
    fallback: <LoadingPage />
  }
)
