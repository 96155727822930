import React, { useMemo } from 'react'
import clsx from 'clsx'
import Slider, { SliderProps as SliderMaterialProps } from '@mui/material/Slider'
import styles from './Slider.module.scss'

export type SliderColorProps = SliderMaterialProps['color'] | 'secondary-alt'

export type SliderProps = Omit<SliderMaterialProps, 'color'> &
  Pick<SliderMaterialProps, 'slots' | 'slotProps'> & {
    color?: SliderColorProps
    className?: string
    classNameSlider?: string
    component?: any
    disabledThumb?: boolean
    dots?: boolean
    // slots?: any
    // slotProps?: any
  }

const getColor = (color: SliderColorProps) => {
  let colorProp = color
  let classNameColor

  if (color === 'secondary') {
    classNameColor = styles.SliderColorSecondary
  } else if (color === 'secondary-alt') {
    colorProp = 'secondary'
    classNameColor = styles.SliderColorSecondaryAlt
  }

  return {
    colorProp,
    classNameColor
  }
}

const SliderComponent: React.FC<SliderProps> = props => {
  const {
    className,
    classNameSlider,
    classes,
    color,
    component,
    components,
    defaultValue,
    disabled,
    disabledThumb,
    dots,
    marks,
    max,
    min,
    name,
    orientation,
    step,
    track,
    value,
    valueLabelDisplay,
    valueLabelFormat,
    slots,
    slotProps,
    getAriaLabel,
    onChange,
    onChangeCommitted,
    scale
  } = props

  const ariaLabel = props['aria-label']
  const ariaLabelledby = props['aria-labelledby']
  const ariaValuetext = props['aria-valuetext']
  let classesComponent = classes || {}

  if (disabledThumb) {
    classesComponent = {
      ...classes,
      root: styles.SliderDisabledThumb,
      thumb: 'hidden'
    }
  }

  const { colorProp, classNameColor } = getColor(color)

  const marksAdjusted = useMemo(() => {
    if (!dots) return marks

    let marksAdjusted: Array<any> = []

    if (marks && Array.isArray(marks) && max && min && step) {
      const marksHelper: any = {}

      for (let i = 0, marksLength = marks.length; i < marksLength; i++) {
        if (marks[i]?.value && marks[i]?.label) marksHelper[marks[i].value] = marks[i].label
      }

      const dotsAmount = (max - min) / step
      let currentLoopValue = min

      for (let i = 0; i <= dotsAmount; i++) {
        marksAdjusted[i] = {
          value: currentLoopValue
        }

        if (marksHelper[currentLoopValue]) marksAdjusted[i].label = marksHelper[currentLoopValue]

        currentLoopValue += step
      }
    }

    return marksAdjusted
  }, [dots, marks, max, min, step])

  return (
    <div className={clsx(styles.SliderContainer, className)}>
      <Slider
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        aria-valuetext={ariaValuetext}
        classes={classesComponent}
        className={clsx(classNameSlider, classNameColor)}
        color={colorProp as SliderMaterialProps['color']}
        slots={slots}
        slotProps={slotProps}
        component={component}
        components={components}
        defaultValue={defaultValue}
        disabled={disabled}
        getAriaLabel={getAriaLabel}
        marks={marksAdjusted}
        max={max}
        min={min}
        name={name}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        orientation={orientation}
        scale={scale}
        step={step}
        track={track}
        value={value}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
      ></Slider>
    </div>
  )
}

export default SliderComponent
