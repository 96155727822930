import _reduce from 'lodash/reduce'
import { RootState } from 'duck'
import _compact from 'lodash/compact'
import _some from 'lodash/some'
import {
  ConfigVariables,
  DownloadPricing,
  Equity,
  EquityConfig,
  NotificationPreference,
  ScaleList,
  ScaleType
} from 'models/ApiModels'
import { createSelector } from 'reselect'
import {
  NOTIFICATION_TEMPLATE_TITLE_MAP,
  NO_ACCESS_GENERICAPP_SUBSCRIPTION,
  NO_ACCESS_T2I_ADVANCED_OPTIONS,
  NO_ACCESS_ST2I_ADVANCED_OPTIONS,
  NO_BATCH_DOWNLOAD_SUPPORT,
  NO_ACCESS_MIXVIDEO_GENERATE,
  PAID_DOWNLOAD_LIST,
  SubscriptionData,
  UpscaleDownloadPricingConfig
} from 'appConstants'
import { NumUtils } from 'utils/math'
import { TextTransform } from 'utils/TextUtils'
import { user } from './CommonSelectors'

/* User related data */

const creditBalance = (state: RootState) => {
  return state.api?.payment?.creditOverview?.balance
}

const isDidSubscribePlus = createSelector(user, user => user?.ui_extras?.is_did_subscribe_plus)
const hasUIExtras = createSelector(user, user => Boolean(user?.ui_extras))
const hasCheckedTextToImageAgreement = createSelector(
  user,
  user => user?.ui_extras?.has_checked_text_to_image_agreement
)

const userPermissions = (state: RootState) => {
  return state.api.users.permissions
}
const equityRaw = (state: RootState) => {
  return state.api.users.equity
}
const equityRetrieved = (state: RootState) => {
  return state.api.users.equityRetrieved
}

const equityConfigs = (state: RootState) => {
  return state.api.users.equityConfigs?.results
}

const configVariables = (state: RootState) => {
  return state.api.users.configVariables
}

const phoneValidationCreditText = createSelector(configVariables, configVariables =>
  configVariables ? `$${configVariables?.phone_validation_credit}` : ''
)

type UpscalePricingType = { [key in ScaleType]: number }

const INITIAL_PRICING: UpscalePricingType = {
  2: 0,
  4: 0,
  6: 0,
  8: 0,
  10: 0,
  12: 0
}

const upscalePricingReduceGenerator =
  (
    type: 'standalone' | 'embed',
    freeScale: ScaleType[],
    disabledScale: ScaleType[],
    removeWatermarkPrice?: ConfigVariables['remove_watermark']
  ) =>
  (result: UpscalePricingType, value: ScaleType): UpscalePricingType => {
    const isFree = type === 'standalone' ? false : freeScale.includes(value)
    const isDisabled = disabledScale.includes(value)
    result[value] = isDisabled ? -1 : isFree ? 0 : removeWatermarkPrice?.[`${value}x`] ?? 0

    return result
  }

const equityConfigData = createSelector(equityConfigs, (equityConfigs = []) =>
  _reduce(
    equityConfigs,
    (result, equityConfig) => {
      result[equityConfig.type] = {
        ...equityConfig,
        cost_per_credit: (equityConfig.cost_per_credit ?? 0) / 100,
        typeName: SubscriptionData[equityConfig.type]?.typeName,
        typeTitle: SubscriptionData[equityConfig.type]?.typeTitle,
        typeSubtitle: SubscriptionData[equityConfig.type]?.typeSubtitle
      }
      return result
    },
    {} as { [key in EquityConfig['type']]: EquityConfig }
  )
)

const equity = createSelector(equityRaw, equityConfigData, (equityRaw, equityConfigData) => {
  // const equity = equityRaw
  //   ? ({
  //       ...equityRaw,
  //       type: 'free',
  //       canceled: false,
  //       interval: 'monthly',
  //       advance_control: false,
  //       upscale_image: false,
  //       pre_train_limit: 5,
  //       pre_train_usage: 5
  //     } as Equity)
  //   : undefined

  const equity = equityRaw

  const equityConfig = equityConfigData[equity?.type ?? 'free']

  const pre_train_usage = equity?.pre_train_usage ?? 0
  const pre_train_limit = equity?.pre_train_limit ?? 0
  const preTrainLeft = pre_train_limit - pre_train_usage
  const preTrainLeftAlmostEmpty = preTrainLeft < pre_train_limit * 0.1
  const showBannerFromPretrain = preTrainLeftAlmostEmpty && pre_train_limit > 0
  const preTrainContentMode = (preTrainLeft ?? 0) <= 0 ? 'used-up' : 'almost-used-up'

  const storage_usage_total = NumUtils.sumValues(equity?.storage_usage ?? {})

  const pre_train_clip_usage = equity?.pre_train_clip_usage ?? 0
  const pre_train_clip_limit =
    equity?.pre_train_clip_limit ?? equityConfig?.pre_train_clip_limit ?? 0
  const preTrainClipLeft = pre_train_clip_limit - pre_train_clip_usage
  const preTrainClipLeftAlmostEmpty = preTrainClipLeft < pre_train_clip_limit * 0.1
  const preTrainClipContentMode = (preTrainClipLeft ?? 0) <= 0 ? 'used-up' : 'almost-used-up'

  // const upsellState: Equity['upsellState'] = {
  //   canUpscale: equity?.type === 'free',
  //   canBatchDownload: equity?.type === 'free',
  //   canUploadMultipleFaceMix: true,
  //   canTrain: false,
  //   showBanner: true,
  //   canGenerateClip: false,
  //   contentMode: (equity?.preTrainLeft ?? 0) <= 0 ? 'used-up' : 'almost-used-up'
  // }

  // If equity not available, then allow train by default

  const upsellState: Equity['upsellState'] = {
    canAccessT2IAdvanceOptions: !NO_ACCESS_T2I_ADVANCED_OPTIONS.includes(equity?.type ?? 'free'),
    canAccessST2IAdvanceOptions: !NO_ACCESS_ST2I_ADVANCED_OPTIONS.includes(equity?.type ?? 'free'),
    canUpscale: Boolean(equity?.upscale_image),
    canBatchDownload: !NO_BATCH_DOWNLOAD_SUPPORT.includes(equity?.type ?? 'free'),
    canTrain: (pre_train_limit > 0 && preTrainLeft > 0) || pre_train_limit === 0,
    canGenerateClip:
      (pre_train_clip_limit > 0 && preTrainClipLeft > 0) || pre_train_clip_limit === 0,
    canUseAIAppSubscription: !NO_ACCESS_GENERICAPP_SUBSCRIPTION.includes(equity?.type ?? 'free'),
    canGenerateMixVideo: !NO_ACCESS_MIXVIDEO_GENERATE.includes(equity?.type ?? 'free'),
    showBanner: false, // showBannerFromPretrain || showBannerFromPretrainClip,
    contentMode: showBannerFromPretrain ? preTrainContentMode : preTrainClipContentMode
  }

  return equity
    ? ({
        ...equity,
        cost_per_credit: equity.cost_per_credit / 100,
        typeTitle: SubscriptionData[equity?.type || 'free']?.typeTitle,
        preTrainLeft,
        preTrainLeftAlmostEmpty,
        preTrainClipLeft,
        preTrainClipLeftAlmostEmpty,
        pre_train_clip_limit,
        pre_train_clip_usage,
        storage_usage_total,
        upsellState
      } as Equity)
    : undefined
})

const downloadUpscalePricing = createSelector(
  configVariables,
  equity,
  (configVariables, equity) => {
    const subscriptionType = equity?.type
    const removeWatermarkPrice = configVariables?.remove_watermark
    const { freeScale, disabledScale } = UpscaleDownloadPricingConfig[subscriptionType ?? 'free']

    const pricingEmbed: UpscalePricingType = ScaleList.reduce(
      upscalePricingReduceGenerator('embed', freeScale, disabledScale, removeWatermarkPrice),
      { ...INITIAL_PRICING }
    )

    const pricingStandalone: UpscalePricingType = ScaleList.reduce(
      upscalePricingReduceGenerator('standalone', freeScale, disabledScale, removeWatermarkPrice),
      { ...INITIAL_PRICING }
    )
    return { pricingEmbed, pricingStandalone }
  }
)

const downloadPricing = createSelector(
  configVariables,
  equity,
  creditBalance,
  (configVariables, equity, creditBalance = 0) => {
    const currentPrice = configVariables?.download_image_atx_credit ?? 0
    const isPaid = PAID_DOWNLOAD_LIST.includes(equity?.type ?? 'free')
    const price = isPaid ? currentPrice : 0
    const endBalance = creditBalance - price

    const result: DownloadPricing = {
      creditBalance,
      price,
      endBalance,
      hasEnoughBalance: endBalance >= 0
    }

    return result
  }
)

const hasConfigVariables = createSelector(configVariables, configVariables => {
  return Boolean(configVariables)
})

const hasEquityConfigs = createSelector(equityConfigs, data => Boolean(data))

const isSawWelcomeSlider = createSelector(user, data =>
  Boolean(data?.ui_extras?.is_saw_welcome_slider)
)

export const currentUserId = createSelector(user, user => user?.id || undefined)
const currentUserEmail = createSelector(user, user => user?.email || null)
const currentUserName = createSelector(
  user,
  user => `${user?.first_name || ''} ${user?.last_name || ''}`
)
const isCurrentUserStaff = createSelector(user, user => user?.is_staff || false)
const isCurrentUserFree = createSelector(equity, equity => equity?.type === 'free')

const firebaseUser = (state: RootState) => {
  return state.firebase.firebaseUser
}

const notifications = (state: RootState) => {
  return state.api.users.notification.data ?? {}
}

const notificationTemplatesRaw = (state: RootState) => {
  return state.api.users.notification.notificationTemplates?.results ?? []
}

const templateLoading = (state: RootState) => {
  return state.api.users.notification.templateLoading
}

const notificationPreferences = (state: RootState) => {
  return state.api.users.notification.notificationPreference.data
}

const notificationPreferenceListId = (state: RootState) => {
  return state.api.users.notification.notificationPreference.list
}

const notificationsListId = (state: RootState) => {
  return state.api.users.notification.list
}

const unreadCount = (state: RootState) => {
  return state.api.users.notification.unreadCount || 0
}

const notificationPreferenceList = createSelector(
  notificationPreferences,
  notificationPreferenceListId,
  (data, listId) => listId?.map(id => data[id]) ?? []
)

//Map template to preference
const notificationTemplateAcceptState = createSelector(
  notificationPreferenceList,
  notificationTemplatesRaw,
  (
    notificationPreferenceList,
    notificationTemplates
  ): Record<number, NotificationPreference | undefined> => {
    const INITIAL_PREFERENCE_TEMPLATE_MAP: Record<number, NotificationPreference | undefined> = {}
    return notificationTemplates.reduce((result, value) => {
      const currentPreference = notificationPreferenceList.find(
        preference => preference.template === value.id
      )
      const newResult = { ...result, [value.id]: currentPreference }

      return newResult
    }, INITIAL_PREFERENCE_TEMPLATE_MAP)
  }
)

const notificationTemplates = createSelector(
  notificationTemplatesRaw,
  notificationTemplateAcceptState,
  (notificationTemplatesRaw, notificationTemplateAcceptState) =>
    _compact(
      notificationTemplatesRaw.map(value =>
        NOTIFICATION_TEMPLATE_TITLE_MAP[value.codename] !== ''
          ? {
              ...value,
              title:
                value.title ||
                NOTIFICATION_TEMPLATE_TITLE_MAP[value.codename] ||
                TextTransform.codeNameToTitle(value.codename),
              notificationPreference: notificationTemplateAcceptState[value.id],
              displayOption: NOTIFICATION_TEMPLATE_TITLE_MAP[value.codename] !== ''
            }
          : undefined
      )
    )
)

const notificationTemplatesEmail = createSelector(notificationTemplates, notificationTemplates =>
  notificationTemplates.filter(value => value.type === 'email')
)

const notificationTemplatesInSite = createSelector(notificationTemplates, notificationTemplates =>
  notificationTemplates.filter(value => value.type === 'in_site')
)

const notificationsList = createSelector(
  notifications,
  notificationsListId,
  (notifications, notificationsListId) => {
    if (notificationsListId) {
      return notificationsListId.map(id => notifications[id])
    }
  }
)

const notificationUpdateCreateLoading = createSelector(templateLoading, templateLoading =>
  _some(templateLoading, Boolean)
)

const userSelectors = {
  user,
  unreadCount,
  userPermissions,
  firebaseUser,
  equityRetrieved,
  configVariables,
  hasUIExtras,
  hasCheckedTextToImageAgreement,
  notificationTemplatesEmail,
  notificationTemplates,
  notificationTemplatesInSite,
  phoneValidationCreditText,
  downloadPricing,
  downloadUpscalePricing,
  notificationUpdateCreateLoading,
  hasConfigVariables,
  isSawWelcomeSlider,
  isDidSubscribePlus,
  equity,
  hasEquityConfigs,
  equityConfigData,
  currentUserId,
  currentUserName,
  currentUserEmail,
  isCurrentUserStaff,
  isCurrentUserFree,
  notificationsList
}

export default userSelectors

/*

  const result: ('Earlier' | 'New' | Notification)[] = []
  let isEarlierAdded = false

  notificationsListId.forEach((id, index) => {
    const notification = notifications[id]

    if (index === 0) {
      if (dayjs(notification.created).isAfter(dayjs().subtract(1, 'week'))) {
        result.push('New')
      }
      result.push(notification)
    } else {
      if (
        !isEarlierAdded &&
        dayjs(notification.created).isBefore(dayjs().subtract(1, 'week'))
      ) {
        isEarlierAdded = true
        result.push('Earlier')
      }
      result.push(notification)
    }
  })
*/
