import Konva from 'konva'
import { CanvasBuilderProps } from './CanvasBuilder'
import { ImageStateManagerType } from './utils'

export type GridCanvasProps = Pick<CanvasBuilderProps, 'layer'> & {
  imageState: ImageStateManagerType
}

const LargeImage = (props: GridCanvasProps) => {
  const { layer, imageState } = props

  const imageGroup = new Konva.Group({
    x: 0,
    y: 0
  })

  const largeImage = new Konva.Image({
    id: `largeImage`,
    image: undefined,
    x: 0,
    y: 0
  })
  imageGroup.add(largeImage)

  return {
    get: () => {
      return imageGroup
    },
    /* Called when image updated */
    updateImage: (onLoadCallback?: () => void, onError?: () => void) => {
      const image = imageState.image()
      if (image?.file) {
        const imageObj = new Image()
        imageObj.onload = function () {
          largeImage.image(imageObj)
          onLoadCallback?.()
          layer.batchDraw()
        }
        imageObj.onerror = () => {
          onError?.()
        }

        imageObj.src = image?.file

        layer.batchDraw()
      }
    },
    /* Called when data updated */
    draw: (noDrawLayer?: boolean) => {
      const zoom = imageState.zoom()
      const camera = imageState.camera()

      imageGroup.x(camera.x)
      imageGroup.y(camera.y)
      imageGroup.scaleX(zoom / 100)
      imageGroup.scaleY(zoom / 100)

      !noDrawLayer && layer.batchDraw()
    }
  }
}

export default LargeImage
