import React, { useCallback, useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import TextureIcon from '@mui/icons-material/Texture'
import { connect } from 'react-redux'
import _toNumber from 'lodash/toNumber'
import { Dispatch, bindActionCreators } from 'redux'
import GridLabelTwo from 'components/GridLabel/GridLabelTwo'
import Slider from 'components/Slider'
import Input from 'components/Input'
import { RootState, RootActionType } from 'duck'
import { actions, selectors } from '../../duck'
import { FORM_RANGES } from 'containers/UpscalePanel/Models'
import { ReduxProps } from 'utils/Types'

import styles from '../UpscaleGenerate.module.scss'

const INPUT_CLASSES = {
  input: styles.UpscaleControlsInput
}

const GRID_LABEL_CLASSES = {
  label: 'text-white-med-on-dark'
}

const mapStateToProps = (state: RootState) => ({
  currentFormData: selectors.currentFormData(state),
  disabled: selectors.disableForm(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      updateFormData: actions.updateFormData
    },
    dispatch
  )

type UpscaleControlsProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const checkTextureFormValidity = (value: string, originalValue: number) => {
  const localContent = _toNumber(value)

  const [min, max] = FORM_RANGES['texture']

  if (isNaN(localContent)) {
    return { valid: false, changeTo: originalValue }
  } else if (localContent > max) {
    return { valid: false, changeTo: max }
  } else if (localContent < min) {
    return { valid: false, changeTo: min }
  }
  return { valid: true, changeTo: 0 }
}

const UpscaleTextureForm: React.FC<UpscaleControlsProps> = props => {
  const { disabled, updateFormData, currentFormData } = props
  const [min, max, initialTexture, step] = FORM_RANGES['texture']

  const { formData } = currentFormData
  const texture = formData?.texture ?? initialTexture
  const showTexture = formData?.showTexture ?? false

  const [textureForm, setTextureForm] = useState<string>(`${texture}`)

  useEffect(() => {
    setTextureForm(`${texture}`)
  }, [texture])

  const updateTextureTimeoutRef = useRef<number | undefined>(undefined)

  const updateTextureFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const formValue = e.target.value
      setTextureForm(formValue)

      const { valid, changeTo } = checkTextureFormValidity(formValue, texture)

      if (valid) {
        updateFormData({ texture: _toNumber(formValue) })

        window.clearTimeout(updateTextureTimeoutRef.current)
      } else {
        updateTextureTimeoutRef.current = window.setTimeout(() => {
          updateFormData({ texture: changeTo ?? initialTexture })
          setTextureForm(`${changeTo}`)
        }, 1000)
      }
    },
    [texture, updateFormData, initialTexture]
  )

  return (
    <>
      <GridLabelTwo
        classes={GRID_LABEL_CLASSES}
        label={
          <>
            <TextureIcon />
            <Typography className="ml-2 break-words" variant="overline">
              TEXTURE ENHANCEMENT
            </Typography>
          </>
        }
        content={
          <Switch
            disabled={disabled}
            checked={Boolean(showTexture)}
            onChange={(e, checked) =>
              updateFormData({
                showTexture: checked
              })
            }
            name="texture"
          />
        }
        borderless
      />

      <Grid alignItems="center" container className={!showTexture ? 'hidden' : undefined}>
        <Grid className="pl-8 pr-2" xs item>
          <Slider
            value={texture}
            step={step}
            disabled={disabled}
            min={min}
            max={max}
            onChange={(e, value) => {
              updateFormData({ texture: value as number })
            }}
          />
        </Grid>

        <Grid xs="auto" item>
          <Input
            color="secondary"
            value={textureForm}
            onChange={updateTextureFormValue}
            disabled={disabled}
            className={styles.UpscaleControlsInputRoot}
            classes={INPUT_CLASSES}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(UpscaleTextureForm)
