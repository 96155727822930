import React, { FormEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Button from 'components/Button'
import { LSKey } from 'appConstants'
import { SessionStorage } from 'utils'
import { route } from 'routes'
import { RootActionType, RootState } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import { ReduxProps } from 'utils/Types'
import validator from 'utils/Validator'
import { dialogActions } from 'duck/AppDuck/DialogDuck'

const mapStateToProps = (state: RootState) => ({
  user: apiSelectors.user(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      openDialog: dialogActions.openDialog
    },
    dispatch
  )

export type UserDebugPageProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const UserDebugPage: React.FC<UserDebugPageProps> = props => {
  const { user } = props

  const navigate = useNavigate()
  const can_debug_user = user?.can_debug_user
  const storedEmail = SessionStorage.get(LSKey.USER_DEBUG_EMAIL)
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const shouldRedirect = user && !can_debug_user && !storedEmail

  const handleSubmit = useCallback(
    (event: FormEvent | MouseEvent) => {
      event.preventDefault()
      const valid = validator.email(email)
      if (valid) {
        SessionStorage.save(LSKey.USER_DEBUG_EMAIL, email)
        window.location.reload()
      } else {
        setError('Please provide valid email')
      }
    },
    [email]
  )

  useEffect(() => {
    if (shouldRedirect) {
      navigate(route.TRAIN_PROJECTS.getUrl())
    }
  }, [navigate, shouldRedirect])

  return (
    <div className="flex h-1/100 min-h-0 flex-auto items-center justify-center">
      {storedEmail ? (
        <div className="py-3">
          <Typography>
            Currently you're seeing as <b>{storedEmail}</b>
          </Typography>
          <Button
            className="mt-6 pb-3"
            color="secondary"
            variant="contained"
            onClick={() => {
              SessionStorage.remove(LSKey.USER_DEBUG_EMAIL)
              window.location.reload()
            }}
          >
            Reset User Debug
          </Button>
        </div>
      ) : (
        <div>
          <form className="py-3" onSubmit={handleSubmit}>
            <TextField
              color="secondary"
              value={email ?? ''}
              onChange={event => {
                setEmail(event.target.value)
                setError(undefined)
              }}
              placeholder="Insert email"
              autoFocus
              fullWidth
              error={Boolean(error)}
              helperText={error}
            />
            <Button
              className="mt-6 pb-3"
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
            >
              Apply User Debug
            </Button>
          </form>
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDebugPage)
