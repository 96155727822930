import { TextTransform, GetText } from 'utils/TextUtils'
import produce from 'immer'
import { createSelector } from 'reselect'
import _compact from 'lodash/compact'
import _without from 'lodash/without'
import _uniq from 'lodash/uniq'
import { combineEpics, Epic } from 'redux-observable'
import {
  withLatestFrom,
  map,
  filter,
  mergeMap,
  debounceTime,
  delay,
  startWith,
  take,
  tap
} from 'rxjs/operators'
import { merge } from 'rxjs'
import { RootState, RootActionType } from 'duck'
import {
  OriginImageRequest,
  SpaceImageRequest,
  BookmarkCreateReq,
  UserImage
} from 'models/ApiModels'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { Vector2d } from 'konva/lib/types'
import { applyExtraAttributeConfig } from './ExtraAttributes'
import { mixImageActions, mixImageSelectors } from './MixImage'
import {
  INITIAL_ANCHOR,
  ShareMixData,
  BookmarkDataType,
  EditType,
  ApplyEditImageParam,
  UpdateEditParam,
  UpdateResultParam,
  MixPageTabDataType,
  PanelType,
  PanelList
} from './Models'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { errorUtils } from 'utils/DataProcessingUtils'
import { appActions } from 'duck/AppDuck'
import { errorMessage, values } from 'appConstants'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import { of } from 'rxjs'
import { SessionStorage } from 'utils'
import { LSKey } from 'appConstants'
import { route } from 'routes'
import { projectMixActions } from './ProjectMix'
import { actions, selectors } from '.'
import SentryUtils from 'utils/SentryUtils'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'

// Constants
const NAMESPACE = '@@page/MixImagePage/Panels'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const INITIAL_ZOOM_VALUE = 100
export const MAX_ZOOM_VALUE = 2500

// Actions
export const panelsActions = {
  resetSelectedItem: createAction(creator('RESET_SELECTED_ITEM'))(),
  updateResult: createAction(creator('UPDATE_RESULT'))<UpdateResultParam>(),
  applyEdit: createAction(creator('APPLY_EDIT'))<ApplyEditImageParam>(),
  generateResult: createAction(creator('GENERATE_RESULT'))(),
  setGenerateResultHasQueue: createAction(creator('SET_GENERATE_RESULT_HAS_QUEUE'))<boolean>(),
  updateEdit: createAction(creator('UPDATE_EDIT'))<UpdateEditParam>(),
  savePreview: createAction(creator('SAVE_PREVIEW'))<number>(),
  closeMixImagePanel: createAction(creator('CLOSE_MIX_IMAGE_PANEL'))(),
  setSelectedImage: createAction(creator('SET_SELECTED_IMAGE'))<{
    panel: PanelType
    selected: number | undefined
  }>(),
  bottomSheet: {
    selectSketchStyle: createAction(creator('SELECT_SKETCH_STYLE'))<UserImage>(),
    setActiveTab: createAction(creator('SET_ACTIVE_TAB'))<PanelType | undefined>(),
    setStartOver: createAction(creator('SET_START_OVER'))<boolean>(),
    setShowBottomSheet: createAction(creator('SET_SHOW_BOTTOM_SHEET'))<
      PanelsState['bottomSheet']['show']
    >()
  },
  sketch: {
    initSketchPanel: createAction(creator('INIT_SKETCH_PANEL'))()
  },
  explore: {
    loadInitialImage: createAction(creator('EXPLORE/LOAD_INITIAL_IMAGE'))(),
    loadRequestQueue: createAction(creator('EXPLORE/LOAD_REQUEST_QUEUE'))<SpaceImageRequest[]>(),
    setCamera: createAction(creator('EXPLORE/SET_CAMERA'))<Vector2d>(),
    setZoomValue: createAction(creator('EXPLORE/SET_ZOOM_VALUE'))<number>(),
    setSelectedImage: createAction(creator('EXPLORE/SET_SELECTED_IMAGE'))<
      OriginImageRequest | undefined
    >()
  },
  upload: {
    uploadImages: createAction(creator('UPLOAD/UPLOAD_IMAGES'))<{
      images: File[]
    }>(),
    addUnfinishedUpload: createAction(creator('UPLOAD/ADD_UNFINISHED_UPLOAD'))<number>(),
    removeUnfinishedUpload: createAction(creator('UPLOAD/REMOVE_UNFINISHED_UPLOAD'))<number>(),
    clearUnfinishedUpload: createAction(creator('UPLOAD/CLEAR_UNFINISHED_UPLOAD'))(),
    refreshUnfinishedUpload: createAction(creator('UPLOAD/REFRESH_UNFINISHED_UPLOAD'))()
  }
}

// Selectors
const selectPanels = (state: RootState) => state.container.mixImagePanel.panels

const selectExplore = createSelector(selectPanels, panels => panels.explore)
const selectEdits = createSelector(selectPanels, panels => panels.edits)

// Bottom Sheet selector
const selectBottomSheetMixImage = (state: RootState) =>
  state.container.mixImagePanel.panels.bottomSheet

const selectShowBottomSheet = createSelector(
  selectBottomSheetMixImage,
  bottomSheetMixImage => bottomSheetMixImage.show
)
const selectIsStartOver = createSelector(
  selectBottomSheetMixImage,
  bottomSheetMixImage => bottomSheetMixImage.show === 'start-over'
)
const selectIsSketchStyle = createSelector(
  selectBottomSheetMixImage,
  bottomSheetMixImage => bottomSheetMixImage.show === 'sketch-style'
)
const selectActiveTab = createSelector(
  selectBottomSheetMixImage,
  bottomSheetMixImage => bottomSheetMixImage.activeTab
)

const selectSelectedExploreImage = createSelector(selectExplore, explore => explore.selectedImage)
const selectSelectedExploreImageData = createSelector(
  selectExplore,
  apiSelectors.userImages,
  apiSelectors.spaceImage,
  (explore, userImages, spaceImage) => {
    const spaceOriginImages = spaceImage.originImagesSpaceMap
    const selectedImage = explore.selectedImage
    if (selectedImage) {
      const key = GetText.originImageKey(selectedImage)
      const userImagesId = spaceOriginImages[key]
      return userImages[userImagesId]
    }
    return undefined
  }
)

const selectSelectedImages = createSelector(selectPanels, data => data.selectedImages)
const selectSelectedImagesData = createSelector(
  selectSelectedImages,
  apiSelectors.userImageBookmarks,
  apiSelectors.userImages,
  apiSelectors.uploadImages,
  selectSelectedExploreImageData,
  apiSelectors.sketchToImageOutputs,
  (
    selectedImages,
    userImageBookmarks,
    userImages,
    uploadImages,
    exploreImageData,
    sketchToImageOutputs
  ) => {
    const { saved, upload, random, train_saved, train_random, train_result, sketch } =
      selectedImages

    const uploadImage = upload ? uploadImages[upload] : undefined
    const sketchImage = sketch ? sketchToImageOutputs[sketch] : undefined

    return {
      upload: uploadImage
        ? {
            ...uploadImage,
            image: userImages[uploadImage.image.id]
          }
        : undefined,
      saved: saved ? userImageBookmarks[saved] : undefined,
      explore: exploreImageData,
      random: random ? userImages[random] : undefined,
      train_saved: train_saved ? userImageBookmarks[train_saved] : undefined,
      train_random: train_random ? userImages[train_random] : undefined,
      train_result: train_result ? userImages[train_result] : undefined,
      sketch: sketchImage
        ? {
            ...sketchImage,
            image: userImages[sketchImage.image?.id ?? 0],
            bookmark: userImages[sketchImage.image?.id ?? 0].bookmark
          }
        : undefined
    }
  }
)
const selectSelectedImage = createSelector(
  selectSelectedImages,
  (_: RootState, panel: PanelType) => panel,
  (selectedImages, panel) => selectedImages[panel]
)

const selectSelectedItemShareRelatedData = createSelector(
  mixImageSelectors.source,
  apiSelectors.currentMixImageProject,
  apiSelectors.currentProjectWithMix,
  (source, currentMixImageProject, currentProject) =>
    source === 'train'
      ? {
          /*
            Use the project id for mix project
          */
          id: currentProject?.id ?? 0,
          type: currentProject?.object_type ?? ''
        }
      : {
          id: currentMixImageProject?.id ?? 0,
          type: currentMixImageProject?.object_type ?? ''
        }
)

const selectMixImage = (state: RootState) => state.container.mixImagePanel.root
const selectPageTab = createSelector(selectMixImage, mixImage => mixImage.pageTab)

const selectSelectedPanel = createSelector(
  selectActiveTab,
  selectPageTab,
  selectShowBottomSheet,
  (activeTab, pageTab, showBottomSheet) => {
    if (showBottomSheet) {
      return activeTab
    } else return pageTab
  }
)

const selectIsHasSelectedPanel = createSelector(
  selectSelectedPanel,
  selectShowBottomSheet,
  (selectedPanel, showBottomSheet) => {
    return showBottomSheet || (selectedPanel && selectedPanel !== 'mix')
  }
)

const selectSelectedItem = createSelector(
  selectSelectedImagesData,
  selectSelectedPanel,
  selectEdits,
  mixImageSelectors.bookmarkScope,
  selectSelectedItemShareRelatedData,
  apiSelectors.currentSketchProject,
  apiSelectors.userImages,
  (
    selectedImagesData,
    selectedPanel,
    edits,
    bookmarkScope,
    selectedItemShareRelatedData,
    currentSketchProject,
    userImages
  ) => {
    const { saved, upload, random, explore, train_saved, train_random, train_result, sketch } =
      selectedImagesData
    const tabData: MixPageTabDataType = {
      explore: {
        item: explore,
        bookmark: explore?.bookmark ?? undefined,
        currentEdit: edits[explore?.id || 0]
      },
      saved: {
        item: saved?.item,
        bookmark: saved?.id ?? undefined,
        currentEdit: edits[saved?.item?.id || 0]
      },
      random: {
        item: random,
        bookmark: random?.bookmark ?? undefined,
        currentEdit: edits[random?.id || 0]
      },
      upload: {
        item: upload?.image,
        bookmark: upload?.image?.bookmark ?? undefined,
        currentEdit: edits[upload?.image?.id || 0],
        uploadImageId: upload?.id
      },
      train_saved: {
        item: train_saved?.item,
        bookmark: train_saved?.id ?? undefined
      },
      train_random: {
        item: train_random,
        bookmark: train_random?.bookmark ?? undefined
      },
      train_result: {
        item: train_result,
        bookmark: train_result?.bookmark ?? undefined
      },
      sketch: {
        item: sketch?.image,
        bookmark: sketch?.bookmark ?? undefined,
        sketchOutputId: sketch?.id,
        currentEdit: edits[sketch?.image?.id || 0]
      }
    }

    if (selectedPanel) {
      const data = tabData[selectedPanel]
      const currentEdit = data?.currentEdit
      const sketchOutputId = data?.sketchOutputId
      const uploadImageId = data?.uploadImageId

      const previewImage = userImages[currentEdit?.previewId ?? 0]

      const hasPreviewImage = Boolean(previewImage)
      const item = hasPreviewImage ? previewImage : data?.item

      const bookmark = hasPreviewImage ? previewImage?.bookmark : data?.bookmark

      const bookmarkRequest: BookmarkCreateReq<'user-image'> = {
        scope: selectedPanel === 'sketch' ? currentSketchProject.bookmark_scope : bookmarkScope,
        item: hasPreviewImage ? previewImage?.id ?? 0 : item?.id ?? 0
      }

      const bookmarkData: BookmarkDataType = {
        isBookmarkAvailable: Boolean(item?.id),
        bookmarkRequest,
        bookmark,
        isBookmarked: Boolean(bookmark)
      }

      const shareData: ShareMixData = {
        related: selectedItemShareRelatedData,
        entity: {
          id: item?.id ?? 0,
          type: item?.object_type ?? 'user_image'
        }
      }

      return {
        imageId: data?.item?.id ?? 0,
        selectedPanel,
        hasSelected: Boolean(item),
        item,
        hasPreviewImage,
        edit: currentEdit,
        shareData,
        bookmarkData,
        sketchOutputId,
        uploadImageId
      }
    }
  }
)

const selectEditedExtraAttributesValues = createSelector(selectSelectedItem, selectedItem => {
  return selectedItem?.edit?.extra_attributes
})
const selectEditedExtraAttributesLabels = createSelector(selectSelectedItem, selectedItem => {
  return selectedItem?.edit?.labels
})

const selectIsUploading = createSelector(selectPanels, mixImage => mixImage.upload.isUploading)

export const panelsSelectors = {
  panels: selectPanels,
  generateResultHasQueue: createSelector(selectPanels, panels => panels.generateResultHasQueue),
  explore: selectExplore,
  isHasSelectedPanel: selectIsHasSelectedPanel,
  selectedImage: selectSelectedImage,
  selectedExploreImageData: selectSelectedExploreImageData,
  selectedExploreImage: selectSelectedExploreImage,
  edits: selectEdits,
  selectedItemShareRelatedData: selectSelectedItemShareRelatedData,

  selectedItem: selectSelectedItem,
  isUploading: selectIsUploading,
  editedExtraAttributesValues: selectEditedExtraAttributesValues,
  editedExtraAttributesLabels: selectEditedExtraAttributesLabels,
  unfinishedUploadCount: createSelector(
    selectPanels,
    mixImage => mixImage.upload.unfinishedUploads.length
  ),
  unfinishedUploads: createSelector(selectPanels, mixImage => mixImage.upload.unfinishedUploads),
  unfinishedUploadsData: createSelector(
    selectPanels,
    apiSelectors.uploadImages,
    (mixImage, uploadImages) =>
      mixImage.upload.unfinishedUploads.map(
        unfinishedUpload => uploadImages[unfinishedUpload] ?? undefined
      )
  ),
  showBottomSheet: selectShowBottomSheet,
  isStartOver: selectIsStartOver,
  isSketchStyle: selectIsSketchStyle,
  activeTab: selectActiveTab
}

export type PanelsState = {
  generateResultHasQueue: boolean
  explore: {
    requestQueue: SpaceImageRequest[]
    initialExploreLoaded: boolean
    zoomValue: number
    camera: Vector2d
    selectedImage?: OriginImageRequest
  }
  bottomSheet: {
    show: false | 'add-mix' | 'sketch-style' | 'start-over'
    activeTab?: PanelType
  }
  selectedImages: {
    [key in PanelType]?: number
  }
  edits: { [id: number]: EditType }
  upload: {
    isUploading: boolean
    unfinishedUploads: number[]
    shouldPoolUpload: boolean
  }
}

export const initial: PanelsState = {
  generateResultHasQueue: false,
  explore: {
    requestQueue: [],
    zoomValue: INITIAL_ZOOM_VALUE,
    camera: { x: 0, y: 0 },
    initialExploreLoaded: false,
    selectedImage: undefined
  },
  bottomSheet: {
    show: false,
    activeTab: undefined
  },
  selectedImages: {},
  edits: {},
  upload: {
    isUploading: false,
    unfinishedUploads: [],
    shouldPoolUpload: false
  }
}

const reducer = produce((state: PanelsState, { type, payload }) => {
  switch (type) {
    case getType(panelsActions.updateResult): {
      const typedPayload = payload as ActionType<typeof panelsActions.updateResult>['payload']

      const { imageId, result } = typedPayload
      const currentData = state.edits[imageId] || {}

      state.edits[imageId] = {
        ...currentData,
        previewId: result.id
      }

      return
    }
    case getType(panelsActions.applyEdit): {
      const typedPayload = payload as ActionType<typeof panelsActions.applyEdit>['payload']

      const { imageId, extra_attributes, previewId, labels } = typedPayload
      const currentData = state.edits[imageId]

      state.edits[imageId] = {
        ...(currentData || {}),
        labels,
        extra_attributes,
        previewId
      }
      return
    }
    case getType(panelsActions.updateEdit): {
      const typedPayload = payload as ActionType<typeof panelsActions.updateEdit>['payload']

      const { extra_attributes, imageId, previewId } = typedPayload
      const currentData = state.edits[imageId]

      state.edits[imageId] = {
        ...(currentData ?? {}),
        previewId: previewId ?? currentData?.previewId,
        extra_attributes: {
          ...(currentData?.extra_attributes ?? {}),
          ...extra_attributes
        }
      }
      return
    }
    case getType(panelsActions.bottomSheet.setShowBottomSheet): {
      const typedPayload = payload as ActionType<
        typeof panelsActions.bottomSheet.setShowBottomSheet
      >['payload']

      state.bottomSheet.show = typedPayload
      return
    }
    case getType(panelsActions.bottomSheet.setActiveTab): {
      const typedPayload = payload as ActionType<
        typeof panelsActions.bottomSheet.setActiveTab
      >['payload']

      state.bottomSheet.activeTab = typedPayload
      return
    }
    case getType(panelsActions.explore.loadRequestQueue): {
      state.explore.requestQueue = payload
      return
    }
    case getType(panelsActions.resetSelectedItem): {
      PanelList.forEach(panel => {
        state.selectedImages[panel] = undefined
      })
      return
    }
    case getType(panelsActions.explore.loadInitialImage): {
      state.explore.initialExploreLoaded = true
      return
    }
    case getType(panelsActions.explore.setZoomValue): {
      if (MAX_ZOOM_VALUE >= payload) {
        state.explore.zoomValue = payload
      }
      return
    }
    case getType(panelsActions.explore.setCamera): {
      state.explore.camera = payload
      return
    }
    case getType(panelsActions.explore.setSelectedImage): {
      state.explore.selectedImage = payload
      return
    }

    case getType(panelsActions.setSelectedImage): {
      const { panel, selected } = payload as ActionType<
        typeof panelsActions.setSelectedImage
      >['payload']

      state.selectedImages[panel] = selected
      return
    }
    case getType(panelsActions.upload.addUnfinishedUpload): {
      const typedPayload = payload as ActionType<
        typeof panelsActions.upload.addUnfinishedUpload
      >['payload']

      state.upload.unfinishedUploads = _uniq([...state.upload.unfinishedUploads, typedPayload])
      return
    }
    case getType(panelsActions.upload.removeUnfinishedUpload): {
      const typedPayload = payload as ActionType<
        typeof panelsActions.upload.removeUnfinishedUpload
      >['payload']

      const currentData = [...state.upload.unfinishedUploads]

      state.upload.unfinishedUploads = _without(currentData, typedPayload)
      return
    }
    case getType(panelsActions.upload.clearUnfinishedUpload): {
      state.upload.unfinishedUploads = []
      return
    }
    case getType(panelsActions.setGenerateResultHasQueue): {
      const typedPayload = payload as ActionType<
        typeof panelsActions.setGenerateResultHasQueue
      >['payload']
      state.generateResultHasQueue = typedPayload
      return
    }
    default:
  }
}, initial)

// Epics

const setShowBottomSheetEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(panelsActions.bottomSheet.setShowBottomSheet)),
    filter(({ payload }) => !payload),
    map(() => mixImageActions.setReplaceCandidate(undefined))
  )
const selectSketchStyleEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(panelsActions.bottomSheet.selectSketchStyle)),
    filter(({ payload }) => Boolean(payload)),
    mergeMap(({ payload }) => [
      panelsActions.bottomSheet.setShowBottomSheet(false),
      eventEmiterActions.emit({
        [AppEvents.ON_RECEIVE_MIX_STYLE]: {
          event: 'on_receive_mix_style',
          payload
        }
      })
    ])
  )
const closeMixImagePanelEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(panelsActions.closeMixImagePanel)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      backRoute: SessionStorage.get(LSKey.PROJECT_PAGE_SOURCE),
      mixSource: mixImageSelectors.mixImage(state).source
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          mergeMap(({ backRoute, mixSource }) =>
            _compact([
              mixSource === 'standalone' &&
                appActions.pushTo(backRoute ?? route.MIX_PROJECTS.getUrl()),
              panelsActions.bottomSheet.setShowBottomSheet(false)
            ])
          )
        ),
        //Close when bottomsheet animation finished
        of(param).pipe(
          delay(225),
          filter(({ mixSource }) => mixSource === 'train'),
          map(() => projectMixActions.setStartExploreMode(undefined))
        )
      )
    )
  )

const loadInitialImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(panelsActions.explore.loadInitialImage)),
    mergeMap(() => [
      apiActions.mixImage.retrieveSpaceImage({ parent_image_id: 0, pos_x: 0, pos_y: 0 })
    ])
  )
const loadRequestQueue: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(panelsActions.explore.loadRequestQueue)),
    mergeMap(({ payload }) => payload.map(param => apiActions.mixImage.retrieveSpaceImage(param)))
  )

const setSelectedImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(panelsActions.explore.setSelectedImage)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const { selectedImage } = panelsSelectors.explore(state)
      const selectedImagesKey = selectedImage ? GetText.originImageKey(selectedImage) : ''
      const { originImagesSpaceMap } = apiSelectors.spaceImage(state)
      const project = apiSelectors.currentMixImageProjectId(state)

      const hasOriginImages = selectedImagesKey && Boolean(originImagesSpaceMap[selectedImagesKey])

      return {
        hasOriginImages,
        hasSelectedImage: Boolean(selectedImage),
        selectedImage: { project, pos_x: 0, pos_y: 0, ...selectedImage }
      }
    }),
    filter(({ hasSelectedImage, hasOriginImages }) => hasSelectedImage && !hasOriginImages),
    map(({ selectedImage }) => apiActions.mixImage.retrieveOriginImage(selectedImage))
  )

const initSketchPanelEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(panelsActions.sketch.initSketchPanel)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const currentMixImageProject = apiSelectors.currentMixImageProject(state)
      const hasSketch = Boolean(currentMixImageProject?.sketch)
      return {
        currentMixImageProjectId: currentMixImageProject?.id ?? 0,
        hasSketch
      }
    }),
    filter(
      ({ hasSketch, currentMixImageProjectId }) => !hasSketch && Boolean(currentMixImageProjectId)
    ),
    mergeMap(({ currentMixImageProjectId }) =>
      action$.pipe(
        filter(isActionOf(apiActions.sketchToImage.createSketchProjectResponse)),
        take(1),
        map(() => apiActions.mixImage.retrieveMixImage(currentMixImageProjectId)),
        startWith(
          apiActions.sketchToImage.createSketchProject({
            name: `Mix Sketch Project ${currentMixImageProjectId}`,
            mix_project: currentMixImageProjectId
          })
        )
      )
    )
  )

const updateEditEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(panelsActions.updateEdit)),
    filter(({ payload }) => !payload.previewId),
    debounceTime(600),
    map(() => panelsActions.generateResult())
  )

const generateResultEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(panelsActions.generateResult)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const selectedItem = selectSelectedItem(state)
      const editedExtraAttributesValues = panelsSelectors.editedExtraAttributesValues(state)
      const project = apiSelectors.currentMixImageProjectId(state)
      const generateMixImagePreview = mixImageSelectors.generateMixImagePreviewLoading(state)

      return {
        project,
        generateMixImagePreview,
        anchor: {
          id: selectedItem?.item?.id ?? 0,
          extra_attributes: editedExtraAttributesValues ?? {},
          controls: INITIAL_ANCHOR.controls
        }
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ generateMixImagePreview }) => Boolean(generateMixImagePreview)),
          map(() => panelsActions.setGenerateResultHasQueue(true))
        ),
        of(param).pipe(
          filter(({ generateMixImagePreview }) => !Boolean(generateMixImagePreview)),
          mergeMap(param =>
            action$.pipe(
              filter(isActionOf(apiActions.mixImage.generateMixImagePreviewResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => {
                const hasQueue = selectPanels(state).generateResultHasQueue
                const preview = action.payload.preview
                return {
                  preview,
                  hasQueue
                }
              }),
              map(() => mixImageActions.setGenerateType({ generateType: 'panel' })),
              startWith(
                apiActions.mixImage.generateMixImagePreview({
                  id: param?.project ?? 0,
                  anchor: applyExtraAttributeConfig(param?.anchor ?? INITIAL_ANCHOR)
                })
              )
            )
          )
        )
      )
    )
  )

/* Upload section */
const uploadImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(panelsActions.upload.uploadImages)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      genre: selectors.selectedGenre(state),
      file: action.payload.images[0]
    })),
    mergeMap(({ file, genre }) =>
      merge(
        of(file).pipe(
          filter(file => (file?.size ?? 0) > values.MAX_IMAGE_SIZE),
          tap(() => {
            SentryUtils.captureMessage(
              `Unable To Upload Images - ${errorMessage.ERROR_UPLOAD_TOO_LARGE.content}`,
              {},
              'log'
            )
          }),
          map(() =>
            dialogActions.openDialog({
              [ErrorDialog.ERROR]: {
                dialogName: ErrorDialog.ERROR,
                title: `Unable To Upload Images`,
                content: errorMessage.ERROR_UPLOAD_TOO_LARGE.content
              }
            })
          )
        ),
        of(file).pipe(
          filter(file => (file?.size ?? 0) <= values.MAX_IMAGE_SIZE),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.mixImage.createUploadImageResponse)),
              take(1),
              mergeMap(({ payload }) => [
                actions.logDoAction({ actionType: 'upload_image' }),
                panelsActions.upload.addUnfinishedUpload(payload.data.id)
              ]),
              startWith(
                apiActions.mixImage.createUploadImage({
                  image: file,
                  genre
                })
              )
            )
          )
        )
      )
    )
  )

const refreshUnfinishedUploadEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(panelsActions.upload.refreshUnfinishedUpload)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      unfinishedUploads: panelsSelectors.unfinishedUploads(state),
      currentTrainProject: apiSelectors.currentMixImageProject(state),
      mixImageGenreData: apiSelectors.mixImageGenreData(state)
    })),
    filter(({ unfinishedUploads }) => Boolean(unfinishedUploads.length)),
    tap(({ mixImageGenreData, currentTrainProject, unfinishedUploads }) => {
      MixPanelUtils.track<'PROJECT__MIX_REFRESH_UNFINISHED_UPLOAD'>(
        'Project Mix - Refresh Unfinished Upload',
        {
          ...DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
            mixProject: currentTrainProject,
            mixGenreData: mixImageGenreData
          }),
          refreshed_upload_count: unfinishedUploads.length
        }
      )
    }),
    mergeMap(({ unfinishedUploads }) =>
      unfinishedUploads.map(unfinishedUpload =>
        apiActions.mixImage.retrieveUploadImage(unfinishedUpload ?? 0)
      )
    )
  )

/* Listen when upload list received */
const listenOnUploadImageReceived: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.retrieveUploadImageResponse)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      unfinishedUploads: panelsSelectors.unfinishedUploads(state),
      id: action.payload.id,
      status: action.payload.status
    })),
    filter(
      ({ unfinishedUploads, id, status }) => unfinishedUploads.includes(id) && status !== 'WAITING'
    ),
    map(({ id }) => panelsActions.upload.removeUnfinishedUpload(id))
  )

/* Listen when upload list received */
const listenOnUploadListReceived: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.listUploadImageResponse)),
    map(({ payload }) => payload.data.results ?? []),
    map(results => results.filter(result => result.status === 'WAITING')),
    map(processingResults => processingResults.map(result => result.id)),
    mergeMap(processingResultIds =>
      processingResultIds.map(id => panelsActions.upload.addUnfinishedUpload(id))
    )
  )

/* Listen when upload is failed */
const listenOnUploadFailedEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(action => action.payload.type === getType(apiActions.mixImage.createUploadImage)),
    map(({ payload }) => {
      let error = errorUtils.flattenMessage(payload)
      if (payload.error?.status === 413) {
        error = errorMessage.ERROR_UPLOAD_TOO_LARGE.content
      }
      return {
        error
      }
    }),
    map(param =>
      dialogActions.openDialog({
        [ErrorDialog.ERROR]: {
          dialogName: ErrorDialog.ERROR,
          title: `Unable To Upload Images`,
          content: param.error
        }
      })
    )
  )

const listenOnClickStyle: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    filter(({ payload }) => Boolean(payload[AppEvents.ON_CLICK_ADD_MIX_STYLE])),
    map(() => panelsActions.bottomSheet.setShowBottomSheet('sketch-style'))
  )

export const panelsEpics = combineEpics(
  selectSketchStyleEpic,
  listenOnClickStyle,
  loadRequestQueue,
  setShowBottomSheetEpic,
  listenOnUploadListReceived,
  refreshUnfinishedUploadEpic,
  listenOnUploadImageReceived,
  initSketchPanelEpic,

  closeMixImagePanelEpic,
  generateResultEpic,
  updateEditEpic,
  loadInitialImageEpic,
  setSelectedImageEpic,

  uploadImagesEpic,
  listenOnUploadFailedEpic
)
export default reducer
