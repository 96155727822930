import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import Banner from 'components/Banner'
import { useBannerMode } from './AppPageHooks'
import { bannerActions } from 'duck/AppDuck/BannerDuck'

const AppPageBanner: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const banner = useSelector((state: RootState) => appSelectors.banner.banner(state))
  const hasActiveDialog = useSelector((state: RootState) =>
    appSelectors.dialog.hasActiveDialog(state)
  )

  const bannerMode = useBannerMode()

  return (
    <Banner
      {...banner}
      mode={bannerMode || 'static'}
      hasActiveDialog={hasActiveDialog}
      onClose={() => {
        dispatch(bannerActions.close())
      }}
    />
  )
}

export default AppPageBanner
