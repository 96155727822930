import { useEffect, useState } from 'react'
import _toNumber from 'lodash/toNumber'
import {
  RouteHomeType,
  TRAIN_PROJECTS,
  MIX_PROJECTS,
  SKETCH_PROJECTS,
  COLLECTIONS,
  SAVED_RESULTS,
  route,
  UPSCALES,
  DOWNLOADS,
  NFTS,
  HomeParamType,
  PRO_FILTER_PROJECTS,
  TEXT_TO_IMAGE_PROJECTS,
  TEXT_TO_VIDEO_PROJECTS,
  GENERIC_APP_PROJECTS,
  SKETCHTEXT_TO_IMAGE_PROJECTS
} from 'routes'
import { HomePageProps } from './index'

type InitializeHomePageParam = Pick<
  HomePageProps,
  'initSearchParam' | 'routerLocation' | 'setPage' | 'openItem'
> & { params?: HomeParamType }

export const getHomeRoute = (page: RouteHomeType, id?: string): string => {
  const routeHomeMap: { [key in RouteHomeType]: string } = {
    [TRAIN_PROJECTS]: route.TRAIN_PROJECTS.getUrl({ id }),
    [MIX_PROJECTS]: route.MIX_PROJECTS.getUrl({ id }),
    [SKETCH_PROJECTS]: route.SKETCH_PROJECTS.getUrl({ id }),
    [TEXT_TO_IMAGE_PROJECTS]: route.TEXT_TO_IMAGE_PROJECTS.getUrl({ id }),
    [TEXT_TO_VIDEO_PROJECTS]: route.TEXT_TO_VIDEO_PROJECTS.getUrl({ id }),
    [GENERIC_APP_PROJECTS]: route.GENERIC_APP_PROJECTS.getUrl({ id }),
    [SKETCHTEXT_TO_IMAGE_PROJECTS]: route.SKETCHTEXT_TO_IMAGE_PROJECTS.getUrl({ id }),
    [COLLECTIONS]: route.COLLECTIONS.getUrl({ id }),
    [SAVED_RESULTS]: route.SAVED_RESULTS.getUrl({ id }),
    [PRO_FILTER_PROJECTS]: route.PRO_FILTER_PROJECTS.getUrl({ id }),
    [UPSCALES]: route.UPSCALES.getUrl({ id }),
    [DOWNLOADS]: route.DOWNLOADS.getUrl({ id }),
    [NFTS]: route.NFTS.getUrl({ id })
  }

  return routeHomeMap[page]
}

export const useInitializeHomePage = (param: InitializeHomePageParam) => {
  const { routerLocation, params, setPage, openItem, initSearchParam } = param

  const id = params?.id ?? ''
  const page = params?.route

  const search = routerLocation?.search || ''

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized && page) {
      setInitialized(true)
      initSearchParam({ search, page })
    }
  }, [initSearchParam, initialized, page, search])

  useEffect(() => {
    page && setPage(page)
  }, [page, setPage])

  useEffect(() => {
    page && openItem(_toNumber(id))
  }, [id, openItem, page])
}
