import SortBy from 'components/SortBy'
import { useBreakpoint } from 'utils/Hooks'
import { RootState, RootActionType } from 'duck'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { actions, selectors } from '../duck'
import { UpscaleImage, UpscaleMethod, UpscaleOrderingType } from 'models/ApiModels'
import { ALL_TEXT, FILTER_LIST_OPTIONS, ScaleOption } from '../Models'
import SelectBase from 'components/Select/SelectBase'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  upscaleListListParam: selectors.upscaleListListParam(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      updateListParam: actions.upscaleList.updateListParam
    },
    dispatch
  )

export type ListFiltersProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const ListFilters: React.FC<ListFiltersProps> = props => {
  const { updateListParam, upscaleListListParam } = props

  const { isSmallscreenAbove } = useBreakpoint()

  return (
    <>
      <div className="ml-6">
        <SortBy
          sortBy={FILTER_LIST_OPTIONS.sort}
          setSortBy={(ordering: UpscaleOrderingType) => {
            updateListParam({
              ordering
            })
          }}
          value={upscaleListListParam.ordering}
        />
      </div>

      {isSmallscreenAbove ? (
        <>
          <div className="ml-6">
            <SelectBase
              value={
                upscaleListListParam.method === undefined ? ALL_TEXT : upscaleListListParam.method
              }
              onChange={value => {
                const method = value !== ALL_TEXT ? (value as UpscaleMethod) : undefined
                updateListParam({
                  method
                })
              }}
              items={FILTER_LIST_OPTIONS.methods}
            />
          </div>

          <div className="ml-6">
            <SelectBase<ScaleOption['value']>
              value={
                upscaleListListParam.scale === undefined ? ALL_TEXT : upscaleListListParam.scale
              }
              onChange={value => {
                const scale =
                  value !== ALL_TEXT ? (value as unknown as UpscaleImage['scale']) : undefined

                updateListParam({
                  scale
                })
              }}
              items={FILTER_LIST_OPTIONS.scales}
            />
          </div>
        </>
      ) : null}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ListFilters)
