import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import useInterval from 'use-interval'
import NFTSellInfo from './NFTSellInfo/Loadable'
import NFTSellForm from './NFTSellForm/Loadable'
import { RootState, RootActionType } from 'duck'
import { ReduxProps } from 'utils/Types'
import { actions, selectors } from './duck'
import { REFRESH_ARTMINE_PUBLISHING_INTERVAL } from 'appConstants'

const mapStateToProps = (state: RootState) => ({
  publishingProject: selectors.publishingProject(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      retrievePublishStatus: actions.retrievePublishStatus
    },
    dispatch
  )

type NFTSellContainerProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const NFTSellContainer: React.FC<NFTSellContainerProps> = props => {
  const { publishingProject, retrievePublishStatus } = props

  const hasPublishingProject = Boolean(publishingProject.length)

  useInterval(
    () => {
      publishingProject.forEach(value => {
        retrievePublishStatus(value)
      })
    },
    hasPublishingProject ? REFRESH_ARTMINE_PUBLISHING_INTERVAL : null
  )

  return (
    <>
      <NFTSellInfo />
      <NFTSellForm />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NFTSellContainer)
