import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { createSelector } from 'reselect'
import _keys from 'lodash/keys'
import _reduce from 'lodash/reduce'
import _forEach from 'lodash/forEach'
import _cloneDeep from 'lodash/cloneDeep'
import { combineEpics, Epic } from 'redux-observable'
import {
  withLatestFrom,
  map,
  filter,
  mergeMap,
  startWith,
  take,
  concatMap,
  debounceTime,
  delay,
  tap
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { AnchorType, BookmarkCreateReq } from 'models/ApiModels'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { mixImageSelectors, mixImageActions } from './MixImage'

import {
  BookmarkDataType,
  ShareMixData,
  INITIAL_EXTRA_ATTRIBUTES,
  ExtraAttributesMode,
  INITIAL_ANCHOR,
  EditType,
  ExtraAttributeConfigType,
  ExtraAttributeConfigTypes,
  ExtraAttributeSectionConfigType
} from './Models'
import { panelsSelectors, panelsActions } from './Panels'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { actions } from '.'

// Constants
const NAMESPACE = '@@page/MixImagePage/ExtraAttribute'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

const CONFIG_DEFAULT: ExtraAttributeConfigType = {
  MIN: -1,
  MAX: 1,
  STEP: 0.01,
  DEFAULT: 0,
  DECIMAL: 2,
  MULTIPLY_ON_SUBMIT_VALUE: 1
}
export const EXTRA_ATTRIBUTE_CONFIGS: ExtraAttributeConfigTypes = {
  Intensity: {
    ...CONFIG_DEFAULT,
    MIN: 1,
    MAX: 5,
    STEP: 1,
    DEFAULT: 1,
    DECIMAL: 0,
    OPTIONS: [
      { value: '1', label: 'Subtle' },
      { value: '2', label: 'Moderate' },
      { value: '3', label: 'Potent' },
      { value: '4', label: 'Intense' },
      { value: '5', label: 'Extreme' }
    ],
    LABEL: 'Expressiveness',
    HIDE_ON_EDIT_PANEL: true
  },
  Blurry: {
    ...CONFIG_DEFAULT,
    HIDE: true
  },
  Pale_Skin: {
    ...CONFIG_DEFAULT,
    HIDE: true
  },
  Mouth_Slightly_Open: {
    ...CONFIG_DEFAULT,
    LABEL: 'Open'
  },
  Attractive: {
    ...CONFIG_DEFAULT,
    MIN: 0,
    MAX: 1
  },
  Young: {
    ...CONFIG_DEFAULT,
    MULTIPLY_ON_SUBMIT_VALUE: -1
  },
  Male: {
    ...CONFIG_DEFAULT,
    MULTIPLY_ON_SUBMIT_VALUE: -1
  },
  Oval_Face: {
    ...CONFIG_DEFAULT,
    MULTIPLY_ON_SUBMIT_VALUE: -1
  },
  DEFAULT: { ...CONFIG_DEFAULT }
}

export const EXTRA_ATTRIBUTE_SECTION_CONFIG: { [key: string]: ExtraAttributeSectionConfigType } = {
  Accessories: {
    HIDE: true
  }
}

export const applyExtraAttributeConfig = (anchorData: AnchorType): AnchorType => {
  return {
    id: anchorData.id,
    controls: anchorData.controls,
    extra_attributes: _reduce(
      anchorData?.extra_attributes,
      (result, value = 0, key) => {
        const extraAttributeConfig = EXTRA_ATTRIBUTE_CONFIGS[key] ?? EXTRA_ATTRIBUTE_CONFIGS.DEFAULT
        return {
          ...result,
          [key]: value * extraAttributeConfig?.MULTIPLY_ON_SUBMIT_VALUE
        }
      },
      {}
    )
  }
}

// Actions
export const extraAttributeActions = {
  setShowAttributePanel: createAction(creator('SET_SHOW_ATTRIBUTE_PANEL'))<boolean>(),
  setInitialData: createAction(creator('SET_INITIAL_DATA'))<AnchorType['extra_attributes']>(),
  setMode: createAction(creator('SET_MODE'))<ExtraAttributesMode>(),
  applyEdit: createAction(creator('APPLY_EDIT'))(),
  triggerUndo: createAction(creator('TRIGGER_UNDO'))(),
  undo: createAction(creator('UNDO'))(),
  redo: createAction(creator('REDO'))(),
  refreshPreview: createAction(creator('REFRESH_PREVIEW'))(),
  setPreview: createAction(creator('SET_PREVIEW'))<number | undefined>(),
  revert: createAction(creator('REVERT'))(),
  reset: createAction(creator('RESET'))<string>(),
  updateExtraAttribute: createAction(creator('UPDATE_EXTRA_ATTRIBUTE'))<
    AnchorType['extra_attributes']
  >(),
  setPreviewHasQueue: createAction(creator('SET_PREVIEW_HAS_QUEUE'))<boolean>()
}

// Selectors
const selectExtraAttribute = (state: RootState) => state.container.mixImagePanel.extraAttribute

const selectCurrentExtraAttributesRaw = createSelector(
  apiSelectors.mixImageGenres,
  apiSelectors.currentMixImageProject,
  (mixImageGenres, currentMixImageProject) => {
    const selectedGenreId = currentMixImageProject?.genre
    const genre = mixImageGenres.find(genres => genres.id === selectedGenreId)
    return genre?.extra_attributes
  }
)

const selectCurrentExtraAttributes = createSelector(
  selectCurrentExtraAttributesRaw,
  extraAttributesInput => {
    const attributeMap: { [key: string]: number } = {}
    const extraAttributes: { [key: string]: { [key: string]: string } } = {}
    const attributeFlat: { [key: string]: string } = {}
    let totalCount = 0

    _keys(extraAttributesInput).forEach(value => {
      const sectionConfig = EXTRA_ATTRIBUTE_SECTION_CONFIG[value]
      if (!sectionConfig?.HIDE) {
        const attributes = extraAttributesInput?.[value] || {}
        const length = _keys(attributes).length
        extraAttributes[value] = {}

        _keys(attributes).forEach(attribute => {
          const attributeConfig = EXTRA_ATTRIBUTE_CONFIGS[attribute]
          if (!attributeConfig?.HIDE) {
            extraAttributes[value][attribute] = attributes[attribute]
            attributeFlat[attribute] = attributes[attribute]
          }
        })

        totalCount = totalCount + length
        attributeMap[value] = length
      }
    })
    return { extraAttributes, totalCount, attributeMap, attributeFlat }
  }
)

const selectExtraAttributeValues = createSelector(
  selectExtraAttribute,
  extraAttribute => extraAttribute?.value?.extra_attributes
)
const selectExtraAttributeEditedList = createSelector(
  selectExtraAttribute,
  extraAttribute => extraAttribute.value.is_edited
)
const selectChangedExtraAttributes = createSelector(
  selectExtraAttributeValues,
  selectCurrentExtraAttributes,
  (extraAttributeValues, currentExtraAttribute) => {
    const { attributeFlat } = currentExtraAttribute
    const changedExtraAttributeValues: AnchorType['extra_attributes'] = {}
    const changedExtraAttributeLabels: AnchorType['labels'] = {}
    _forEach(extraAttributeValues, (value, key) => {
      if (Boolean(value)) {
        const ExtraAttributeConfig = EXTRA_ATTRIBUTE_CONFIGS[key] ?? EXTRA_ATTRIBUTE_CONFIGS.DEFAULT
        changedExtraAttributeValues[key] = value || ExtraAttributeConfig.DEFAULT
        changedExtraAttributeLabels[key] = attributeFlat[key]
      }
    })
    return {
      changedExtraAttributeLabels,
      changedExtraAttributeValues
    }
  }
)

const selectEditedExtraAttributeValues = createSelector(
  selectExtraAttributeValues,
  selectExtraAttributeEditedList,
  (extraAttributeValues, extraAttributeEditedList) => {
    const editedExtraAttributeValues: AnchorType['extra_attributes'] = {}
    _forEach(extraAttributeEditedList, (value, key) => {
      if (Boolean(value)) {
        const ExtraAttributeConfig = EXTRA_ATTRIBUTE_CONFIGS[key] ?? EXTRA_ATTRIBUTE_CONFIGS.DEFAULT
        editedExtraAttributeValues[key] = extraAttributeValues[key] || ExtraAttributeConfig.DEFAULT
      }
    })
    return editedExtraAttributeValues
  }
)

const selectEditedExtraAttributeLabels = createSelector(
  selectCurrentExtraAttributes,
  selectExtraAttributeEditedList,
  (currentExtraAttribute, extraAttributeEditedList) => {
    const { attributeFlat } = currentExtraAttribute
    const editedExtraAttributeLabels: AnchorType['labels'] = {}
    _forEach(extraAttributeEditedList, (value, key) => {
      if (Boolean(value)) {
        editedExtraAttributeLabels[key] = attributeFlat[key]
      }
    })
    return editedExtraAttributeLabels
  }
)

const selectHasExtraAttributeUndo = createSelector(selectExtraAttribute, extraAttribute =>
  Boolean(extraAttribute.previousValue.length)
)

const selectHasExtraAttributeRedo = createSelector(selectExtraAttribute, extraAttribute =>
  Boolean(extraAttribute.nextValue.length)
)

const selectCanRevert = createSelector(selectExtraAttributeValues, values => {
  const valueKeys = _keys(values)
  const hasValue = Boolean(valueKeys.length)
  let isAllEmpty = true

  for (const valueKey in valueKeys) {
    if (Boolean(values[valueKey])) {
      isAllEmpty = false
      break
    }
  }

  return hasValue || isAllEmpty
})

const selectCanRefreshPreview = createSelector(
  selectExtraAttribute,
  extraAttribute => !extraAttribute.isRefreshed
)

const selectCanApplyEdit = createSelector(
  selectExtraAttribute,
  extraAttribute => extraAttribute.isChanged
)
const selectExtraAttributePreview = createSelector(
  selectExtraAttribute,
  apiSelectors.userImages,
  (extraAttribute, userImages) => userImages[extraAttribute.value.previewId ?? 0]
)
const selectExtraAttributePreviewData = createSelector(
  selectExtraAttributePreview,
  mixImageSelectors.bookmarkScope,
  apiSelectors.currentMixImageProject,
  mixImageSelectors.source,
  apiSelectors.currentProjectWithMix,
  (preview, bookmarkScope, currentMixImageProject, source, currentProject) => {
    const bookmarkRequest: BookmarkCreateReq<'user-image'> = {
      scope: bookmarkScope,
      item: preview?.id ?? 0
    }

    const bookmark = preview?.bookmark

    const bookmarkData: BookmarkDataType = {
      isBookmarkAvailable: Boolean(preview?.id),
      bookmarkRequest,
      bookmark,
      isBookmarked: Boolean(bookmark)
    }

    /*  
      For project mix should use current project mix instead of the train project,
     */
    const shareData: ShareMixData = {
      related:
        source === 'train'
          ? {
              /*
                Select project mix data, if the mix is not available yet, then use the project 
                id instead
              */
              id: currentProject?.id ?? 0,
              type: currentProject?.object_type ?? ''
            }
          : {
              id: currentMixImageProject?.id ?? 0,
              type: currentMixImageProject?.object_type ?? ''
            },
      entity: {
        id: preview?.id ?? 0,
        type: preview?.object_type ?? 'user_image'
      }
    }

    return {
      preview,
      bookmarkData,
      shareData
    }
  }
)
const selectShowAttributePanel = createSelector(
  selectExtraAttribute,
  extraAttribute => extraAttribute.showAttributePanel
)

export const extraAttributeSelectors = {
  extraAttribute: selectExtraAttribute,
  currentExtraAttributes: selectCurrentExtraAttributes,
  extraAttributeValues: selectExtraAttributeValues,
  changedExtraAttributes: selectChangedExtraAttributes, // Extra attribute that has non default value

  editedExtraAttributeLabels: selectEditedExtraAttributeLabels, // Extra attribute labels that has been changed
  editedExtraAttributeValues: selectEditedExtraAttributeValues, // Extra attribute values that has been changed

  canApplyEdit: selectCanApplyEdit,
  canRefreshPreview: selectCanRefreshPreview,
  canRevert: selectCanRevert,
  hasExtraAttributeRedo: selectHasExtraAttributeRedo,
  hasExtraAttributeUndo: selectHasExtraAttributeUndo,
  showAttributePanel: selectShowAttributePanel,
  extraAttributePreview: selectExtraAttributePreview,
  extraAttributePreviewData: selectExtraAttributePreviewData,
  mode: createSelector(selectExtraAttribute, extraAttribute => extraAttribute.mode)
}

// Reducer
export type EditValueType = EditType & {
  is_edited: { [key: string]: boolean }
}

export type ExtraAttributeState = {
  showAttributePanel: boolean
  mode: ExtraAttributesMode
  isChanged: boolean
  isRefreshed: boolean
  value: EditValueType
  temporaryUndo?: EditValueType
  nextValue: EditValueType[]
  previewHasQueue: boolean
  previousValue: EditValueType[]
}

export const INITIAL_IS_EDITED = _reduce(
  INITIAL_EXTRA_ATTRIBUTES,
  (result, _, key) => ({ ...result, [key]: true }),
  {}
)

export const INITIAL_EXTRA_ATTRIBUTE_STATE: ExtraAttributeState = {
  showAttributePanel: false,
  mode: 'mix-image',
  isChanged: false,
  isRefreshed: false,
  value: {
    is_edited: { ...INITIAL_IS_EDITED },
    extra_attributes: { ...INITIAL_EXTRA_ATTRIBUTES },
    previewId: undefined
  },
  previewHasQueue: false,
  temporaryUndo: undefined,
  nextValue: [],
  previousValue: []
}

const INITIAL_VALUE_DATA: EditValueType = INITIAL_EXTRA_ATTRIBUTE_STATE.value

const reducer = produce((state: ExtraAttributeState, { type, payload }) => {
  switch (type) {
    case getType(extraAttributeActions.setShowAttributePanel): {
      const typedPayload = payload as ActionType<
        typeof extraAttributeActions.setShowAttributePanel
      >['payload']

      state.showAttributePanel = typedPayload
      return
    }
    case getType(extraAttributeActions.setMode): {
      const typedPayload = payload as ActionType<typeof extraAttributeActions.setMode>['payload']
      state.mode = typedPayload
      return
    }
    case getType(extraAttributeActions.setInitialData): {
      const typedPayload = payload as ActionType<
        typeof extraAttributeActions.setInitialData
      >['payload']

      const extra_attributes = typedPayload
      state.value = {
        is_edited: {
          ...INITIAL_IS_EDITED,
          ..._reduce(extra_attributes, (result, _, key) => ({ ...result, [key]: true }), {})
        },
        extra_attributes: { ...INITIAL_VALUE_DATA.extra_attributes, ...extra_attributes },
        previewId: undefined
      }

      state.previousValue = []
      state.nextValue = []
      state.isChanged = false
      state.isRefreshed = false
      return
    }

    case getType(extraAttributeActions.undo): {
      const currentValue = _cloneDeep(state.value)
      const [oldData, ...restData] = state.previousValue
      const nextValue = state.nextValue

      state.nextValue = [currentValue, ...nextValue]
      state.value = oldData
      state.previousValue = restData
      state.isRefreshed = false
      state.isChanged = true

      return
    }
    case getType(extraAttributeActions.redo): {
      const currentValue = _cloneDeep(state.value)
      const [nextData, ...restData] = state.nextValue
      const previousValue = state.previousValue

      state.previousValue = [currentValue, ...previousValue]
      state.value = nextData
      state.nextValue = restData
      state.isRefreshed = false
      state.isChanged = true
      return
    }
    case getType(extraAttributeActions.refreshPreview): {
      state.isRefreshed = true

      return
    }
    case getType(extraAttributeActions.setPreview): {
      const typedPayload = payload as ActionType<typeof extraAttributeActions.setPreview>['payload']
      state.value.previewId = typedPayload
      return
    }
    case getType(extraAttributeActions.revert): {
      //Store Undo first
      const previousValue = _cloneDeep(state.value)
      state.previousValue = [previousValue, ...state.previousValue]
      state.nextValue = []

      state.value = _cloneDeep(INITIAL_VALUE_DATA)
      state.isChanged = true
      state.isRefreshed = false
      return
    }
    case getType(extraAttributeActions.triggerUndo): {
      const temporaryUndo = state.temporaryUndo

      const previousValue = temporaryUndo ? { ...temporaryUndo } : { ...state.value }

      state.previousValue = [_cloneDeep(previousValue), ...state.previousValue]

      state.temporaryUndo = undefined
      state.isRefreshed = false
      state.nextValue = []

      return
    }
    case getType(extraAttributeActions.reset): {
      const key = payload as ActionType<typeof extraAttributeActions.reset>['payload']

      //Store Undo first
      const previousValue = _cloneDeep(state.value)
      state.previousValue = [previousValue, ...state.previousValue]
      state.nextValue = []

      state.value.extra_attributes[key] = undefined
      state.isRefreshed = false
      state.isChanged = true

      return
    }
    case getType(extraAttributeActions.updateExtraAttribute): {
      const typedPayload = payload as ActionType<
        typeof extraAttributeActions.updateExtraAttribute
      >['payload']

      const extra_attributes = typedPayload

      const currentValue = state.value
      if (!state.temporaryUndo) {
        state.temporaryUndo = { ...currentValue }
      }

      state.value = {
        ...currentValue,
        is_edited: {
          ...currentValue.is_edited,
          ..._reduce(extra_attributes, (result, _, key) => ({ ...result, [key]: true }), {})
        },
        extra_attributes: {
          ...(currentValue?.extra_attributes ?? {}),
          ...extra_attributes
        }
      }
      state.isChanged = true
      state.isRefreshed = false

      return
    }
    case getType(extraAttributeActions.setPreviewHasQueue): {
      const previewHasQueue = payload as ActionType<
        typeof extraAttributeActions.setPreviewHasQueue
      >['payload']

      state.previewHasQueue = previewHasQueue
      return
    }
    default:
  }
}, INITIAL_EXTRA_ATTRIBUTE_STATE)

// Epics

const refreshPreviewEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(extraAttributeActions.refreshPreview)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const mode = extraAttributeSelectors.mode(state)
      const project = apiSelectors.currentMixImageProjectId(state)
      const { changedExtraAttributeValues } = extraAttributeSelectors.changedExtraAttributes(state)
      const generateMixImagePreviewLoading = mixImageSelectors.generateMixImagePreviewLoading(state)

      if (mode === 'mix-image') {
        const selectedGridData = mixImageSelectors.selectedGridData(state)

        return {
          generateMixImagePreviewLoading,
          project,
          anchor: {
            id: selectedGridData?.imageId || 0,
            extra_attributes: changedExtraAttributeValues,
            controls: INITIAL_ANCHOR.controls
          }
        }
      }
      if (mode === 'panels') {
        const selectedItem = panelsSelectors.selectedItem(state)
        return {
          generateMixImagePreviewLoading,
          project,
          anchor: {
            id: selectedItem?.item?.id || 0,
            extra_attributes: changedExtraAttributeValues,
            controls: INITIAL_ANCHOR.controls
          }
        }
      }
      return {
        generateMixImagePreviewLoading
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ generateMixImagePreviewLoading }) => Boolean(generateMixImagePreviewLoading)),
          map(() => extraAttributeActions.setPreviewHasQueue(true))
        ),
        of(param).pipe(
          filter(({ generateMixImagePreviewLoading }) => !Boolean(generateMixImagePreviewLoading)),
          mergeMap(param =>
            action$.pipe(
              filter(isActionOf(apiActions.mixImage.generateMixImagePreviewResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => {
                const hasQueue = selectExtraAttribute(state).previewHasQueue

                return {
                  action,
                  hasQueue
                }
              }),
              map(() =>
                mixImageActions.setGenerateType({ generateType: 'extra-attribute-preview' })
              ),
              startWith(
                apiActions.mixImage.generateMixImagePreview({
                  id: param?.project ?? 0,
                  anchor: applyExtraAttributeConfig(param?.anchor ?? INITIAL_ANCHOR)
                })
              )
            )
          )
        )
      )
    )
  )

const setShowAttributePanelEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(extraAttributeActions.setShowAttributePanel)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const mode = selectExtraAttribute(state).mode
      const editedExtraAttributesValues =
        mode === 'mix-image'
          ? mixImageSelectors.editedExtraAttributesValues(state)
          : panelsSelectors.editedExtraAttributesValues(state)

      return {
        show: action.payload,
        mode,
        editedExtraAttributesValues: editedExtraAttributesValues ?? {}
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ show, mode }) => show && Boolean(mode)),
          map(({ editedExtraAttributesValues }) =>
            extraAttributeActions.setInitialData(editedExtraAttributesValues)
          )
        ),
        of(param).pipe(
          filter(({ show }) => !show),
          delay(800), //Delay animation to finished
          map(() => extraAttributeActions.setInitialData({}))
        ),
        of(actions.logDoAction({ actionType: 'extra_attribute_open' })),
        of(param).pipe(map(({ show }) => mixImageActions.setExpandExtraAttributePanel(show)))
      )
    )
  )
const applyEditEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(extraAttributeActions.applyEdit)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      mixGenreData: apiSelectors.mixImageGenreData(state),
      currentProject: apiSelectors.currentProject(state),
      currentMixProject: apiSelectors.currentMixImageProject(state),
      source: mixImageSelectors.source(state),
      changedExtraAttributes: extraAttributeSelectors.changedExtraAttributes(state),
      extraAttributePreviewId: extraAttributeSelectors.extraAttributePreview(state)?.id,
      selectedAnchor: mixImageSelectors.selectedGridData(state)?.anchorIndex,
      selectedGridImageId: mixImageSelectors.selectedGridData(state)?.imageId ?? 0,
      selectedGridIsAnchor: mixImageSelectors.selectedGridData(state)?.isAnchor ?? false,
      mode: extraAttributeSelectors.mode(state),
      selectedItem: panelsSelectors.selectedItem(state)
    })),
    map(({ changedExtraAttributes, ...restParam }) => {
      const { changedExtraAttributeValues, changedExtraAttributeLabels } = changedExtraAttributes

      return {
        changedExtraAttributeLabels,
        changedExtraAttributeValues,
        ...restParam
      }
    }),
    tap(
      ({
        currentProject,
        mixGenreData,
        currentMixProject,
        source,
        mode,
        changedExtraAttributeLabels
      }) => {
        const projectData =
          source === 'standalone'
            ? DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
                mixProject: currentMixProject,
                mixGenreData
              })
            : DataUtils.getProjectParam<'training_project'>('training_project', {
                trainProject: currentProject
              })

        MixPanelUtils.track<'PROJECT__MIX_APPLY_FACE_EDIT'>('Project Mix - Apply Face Edit', {
          ...projectData,
          extra_attribute_labels: _keys(changedExtraAttributeLabels),
          extra_attribute_mode: mode
        })
      }
    ),
    concatMap(param =>
      merge(
        of(param).pipe(
          filter(({ mode }) => mode === 'mix-image'),
          map(
            ({
              changedExtraAttributeValues,
              changedExtraAttributeLabels,
              selectedGridImageId,
              extraAttributePreviewId,
              selectedGridIsAnchor
            }) =>
              mixImageActions.updateEdit({
                isReplace: true,
                imageId: selectedGridImageId,
                extra_attributes: changedExtraAttributeValues,
                labels: changedExtraAttributeLabels,
                previewId: extraAttributePreviewId,
                isAnchor: selectedGridIsAnchor
              })
          )
        ),
        of(param).pipe(
          filter(({ mode }) => mode === 'panels'),
          map(
            ({
              selectedItem,
              extraAttributePreviewId,
              changedExtraAttributeValues,
              changedExtraAttributeLabels
            }) =>
              panelsActions.applyEdit({
                imageId: selectedItem?.imageId ?? 0,
                extra_attributes: changedExtraAttributeValues,
                labels: changedExtraAttributeLabels,
                previewId: extraAttributePreviewId
              })
          )
        ),
        of(param).pipe(map(() => extraAttributeActions.setShowAttributePanel(false)))
      )
    )
  )

const updateExtraAttributeEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(extraAttributeActions.updateExtraAttribute)),
    debounceTime(700),
    mergeMap(() => [extraAttributeActions.refreshPreview(), extraAttributeActions.triggerUndo()])
  )
const revertResetEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf([extraAttributeActions.revert, extraAttributeActions.reset])),
    map(() => extraAttributeActions.refreshPreview())
  )
export const extraAttributesEpic = combineEpics(
  setShowAttributePanelEpic,
  revertResetEpic,
  refreshPreviewEpic,
  applyEditEpic,
  updateExtraAttributeEpic
)

export default reducer
