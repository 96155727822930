import { useSelector } from 'react-redux'
import { RootState } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import { selectors } from '../duck'
import { FormDialog } from 'duck/AppDuck/DialogDuck'
import { useDelayedData } from 'utils/Hooks'
import { values } from 'appConstants'
import loadable from 'utils/Loadable'

const ShareFeedDialog = loadable(() => import(/* webpackChunkName: "ShareFeedDialog" */ './index'))

const ShareFeedDialogContainer = () => {
  const activeDialog = useSelector((state: RootState) => appSelectors.dialog.activeDialog(state))
  const shareData = useSelector((state: RootState) => selectors.shareData(state))
  const showDialog = activeDialog === FormDialog.SHARE_FEED && Boolean(shareData)
  const showDialogState = useDelayedData(showDialog, values.DIALOG_TRANSITION_DURATION)

  if (!showDialog && !showDialogState) return null

  return <ShareFeedDialog />
}

export default ShareFeedDialogContainer
