import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ChangeEmailDialog, dialogActions } from 'duck/AppDuck/DialogDuck'
import { RootState, RootActionType } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import Button from 'components/Button'
import { GeneralDialogItemProps } from '..'
import DialogWrapper from '../DialogWrapper'
import styles from '../Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  userEmail: apiSelectors.currentUserEmail(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      addDialog: dialogActions.addDialog
    },
    dispatch
  )

type DialogResentCurrentEmailProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  GeneralDialogItemProps

const DialogResentCurrentEmail: React.FC<DialogResentCurrentEmailProps> = props => {
  const { userEmail, activeDialog, onClose, addDialog } = props

  return (
    <DialogWrapper
      dialogConfig={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        className: styles.EmailDialog
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">Magic link was sent again</Typography>}
      actions={
        <Fragment>
          <Button
            color="secondary"
            onClick={() =>
              addDialog({
                [ChangeEmailDialog.SENT_CURRENT_EMAIL_HELP]: {
                  dialogName: ChangeEmailDialog.SENT_CURRENT_EMAIL_HELP
                }
              })
            }
          >
            Need Help?
          </Button>
          <Button color="secondary" onClick={onClose}>
            OK
          </Button>
        </Fragment>
      }
      content={
        <Fragment>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              To change to a new email address, check your current email address:
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography color="secondary" variant="body2">
              {userEmail}
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">A special, secure link is enclosed.</Typography>
          </div>
        </Fragment>
      }
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogResentCurrentEmail)
