import { BookmarkUtils, TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _compact from 'lodash/compact'
import _difference from 'lodash/difference'
import _isNil from 'lodash/isNil'
import _uniq from 'lodash/uniq'
import _toNumber from 'lodash/toNumber'
import { combineEpics, Epic } from 'redux-observable'
import {
  map,
  withLatestFrom,
  debounceTime,
  mergeMap,
  take,
  startWith,
  catchError,
  concatMap,
  tap
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { getType, isActionOf, createAction, ActionType } from 'typesafe-actions'
import { SelectBaseItemType } from 'components/Select/SelectBase'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { createSelector } from 'reselect'
import Validator, { isHasErrors, VALIDATION_MESSAGES } from 'utils/Validator'
import { values } from 'appConstants'
import {
  CreateMineProjectReq,
  EngineConfigFreeformList,
  EngineConfigKeyType,
  ProjectObjectType,
  RoyaltyType
} from 'models/ApiModels'
import { dialogActions, ErrorDialog, FormDialog, InformationDialog } from 'duck/AppDuck/DialogDuck'
import {
  completeMineProjectData,
  MINE_PROJECT_SUBMIT_WAIT_TIME,
  SelectDirection,
  SelectedFormat,
  SelectUtils
} from 'utils/DataProcessingUtils'
import { of, filter, merge, from } from 'rxjs'
import CryptoUtils from 'utils/CryptoUtils'
import SentryUtils from 'utils/SentryUtils'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import MixPanelUtils from 'utils/MixPanelUtils'

// Constants
const NAMESPACE = '@@page/NFTSellPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)
export const BASE_POINT = 10000
export const BASE_POINT_TO_PERCENT = 100 / BASE_POINT
export const MIN_SELECTED_IMAGE = 10000

export const STEP = {
  CURATE_INFO: 0,
  SELECT_COVER: 1,
  CURATE_COLLECTION: 2,
  DETAILS: 3,
  SUBMIT: 4
} as const

export type STEP_NAMES = keyof typeof STEP
export const STEP_NAME_MAP: STEP_NAMES[] = Object.keys(STEP) as STEP_NAMES[]

export const FIRST_STEPS = [0, 1, 2]

export const Utils = {
  getFormKey: (param: NFTSellPanelState) =>
    `${param?.selectedProject?.id}-${param?.selectedProject?.projectType}`,
  addRoyalty: (currentWallets: RoyaltyType[]) => {
    const walletLength = currentWallets.length + 1
    return [...currentWallets, INITIAL_ROYALTY].map(({ address }) => ({
      address,
      initial_sale_fee_in_bp: BASE_POINT / walletLength
    }))
  },
  adjustAfterRemoveRoyalty: (param: RoyaltyType[]) => {
    const currentTotal = param.reduce((result, value) => {
      result = result + value.initial_sale_fee_in_bp
      return result
    }, 0)
    const totalMinus = BASE_POINT - currentTotal
    const distributedTotalMinus = totalMinus / param.length
    return param.map((item, index) => {
      return {
        address: item.address,
        initial_sale_fee_in_bp: item.initial_sale_fee_in_bp + distributedTotalMinus
      }
    })
  }
}

// Actions
export const actions = {
  setStep: createAction(creator('NFT_SELL_PANEL/SET_STEP'))<number>(),
  initForm: createAction(creator('NFT_SELL_PANEL/INIT_FORM'))<
    NonNullable<NFTSellPanelState['selectedProject']>
  >(),
  continueForm: createAction(creator('NFT_SELL_PANEL/CONTINUE'))(),
  acceptSubmit: createAction(creator('NFT_SELL_PANEL/ACCEPT_SUBMIT'))(),
  retrySubmit: createAction(creator('NFT_SELL_PANEL/RETRY_SUBMIT'))(),
  finishSubmit: createAction(creator('NFT_SELL_PANEL/FINISH_SUBMIT'))(),
  resetForm: createAction(creator('NFT_SELL_PANEL/RESET_FORM'))(),
  updateFormData: createAction(creator('NFT_SELL_PANEL/UPDATE_FORM_DATA'))<Partial<FormDataType>>(),
  updateFormDataWithoutValidation: createAction(
    creator('NFT_SELL_PANEL/UPDATE_FORM_DATA_WITHOUT_VALIDATION')
  )<Partial<FormDataType>>(),
  updateFormError: createAction(creator('NFT_SELL_PANEL/UPDATE_FORM_ERROR'))<Partial<FormErrors>>(),
  loadAllImageGrid: createAction(creator('NFT_SELL_PANEL/LOAD_IMAGE_GRID'))<{ reload: boolean }>(),
  imageSelect: {
    updateSelection: createAction(creator('NFT_SELL_PANEL/IMAGE_SELECT/UPDATE_SELECTION'))<
      FormItem['imageSelection']
    >(),
    toggleSelect: createAction(creator('NFT_SELL_PANEL/IMAGE_SELECT/TOGGLE_SELECT'))<number>(),
    selectAll: createAction(creator('NFT_SELL_PANEL/IMAGE_SELECT/SELECT_ALL'))(),
    deselectAll: createAction(creator('NFT_SELL_PANEL/IMAGE_SELECT/DESELECT_ALL'))(),
    apply: createAction(creator('NFT_SELL_PANEL/IMAGE_SELECT/APPLY'))(),
    cancel: createAction(creator('NFT_SELL_PANEL/IMAGE_SELECT/CANCEL'))(),
    resetSelection: createAction(creator('NFT_SELL_PANEL/IMAGE_SELECT/RESET_SELECTION'))()
  },
  setProjectPublishing: createAction(creator('NFT_SELL_PANEL/SET_PROJECT_PUBLISHING'))<
    NFTSellPanelState['projectPublishing']
  >(),
  retrievePublishStatus: createAction(creator('NFT_SELL_PANEL/RETRIEVE_PUBLISH_STATUS'))<number>()
}

// Selectors
const selectNFTSellPanel = (state: RootState) => state.container.nftSellPanel

const selectSelectedProject = createSelector(
  selectNFTSellPanel,
  nftSellPanel => nftSellPanel.selectedProject
)
const selectFormKey = createSelector(selectNFTSellPanel, nftSellPanel =>
  Utils.getFormKey(nftSellPanel)
)

const selectImageSelectionData = createSelector(
  selectFormKey,
  selectNFTSellPanel,
  (formKey, sellAsNFT) => sellAsNFT.forms[formKey]?.imageSelection
)

const selectImageSelectionAppliedData = createSelector(
  selectFormKey,
  selectNFTSellPanel,
  (formKey, sellAsNFT) => sellAsNFT.forms[formKey]?.imageSelectionApplied
)

const selectFormErrors = createSelector(
  selectFormKey,
  selectNFTSellPanel,
  (formKey, sellAsNFT) => sellAsNFT.forms[formKey]?.formErrors ?? {}
)

const selectFormData = createSelector(
  selectFormKey,
  selectNFTSellPanel,
  (formKey, sellAsNFT) => sellAsNFT.forms[formKey]?.formData ?? INITIAL_FORM_DATA
)

const selectStep = createSelector(
  selectFormKey,
  selectNFTSellPanel,
  (formKey, sellAsNFT) => sellAsNFT.forms[formKey]?.step ?? 0
)
const selectMaxStep = createSelector(
  selectFormKey,
  selectNFTSellPanel,
  (formKey, sellAsNFT) => sellAsNFT.forms[formKey]?.maxStep ?? 0
)

const selectIsProjectFreeform = createSelector(
  selectSelectedProject,
  apiSelectors.projects,
  (selectedProject, projects) => {
    if (selectedProject?.projectType === 'training_project') {
      const projectCategory = projects[selectedProject?.id].category as EngineConfigKeyType

      return EngineConfigFreeformList.includes(projectCategory)
    }

    return false
  }
)

const selectGridImagesRaw = createSelector(
  selectNFTSellPanel,
  apiSelectors.currentProArtFilterProject,
  apiSelectors.currentSketchProject,
  apiSelectors.currentMixProjectSavedList,
  apiSelectors.currentProjectSavedImageList,
  apiSelectors.currentTextToImageProject,
  (
    sellAsNFT,
    currentProArtFilterProject,
    currentSketchProject,
    currentMixProjectSavedList,
    currentProjectSavedImageList,
    currentTextToImageProject
  ) => {
    const projectType = sellAsNFT.selectedProject?.projectType

    if (projectType === 'pretrain_mix_project') {
      return currentMixProjectSavedList.map(value => value.item)
    } else if (projectType === 'sketch_project') {
      return currentSketchProject.outputs.map(value => value.image)
    } else if (projectType === 'transfer_project') {
      return currentProArtFilterProject?.outputs.map(value => value.image)
    } else if (projectType === 'training_project') {
      return currentProjectSavedImageList
    } else if (projectType === 't2i_project') {
      return currentTextToImageProject?.outputs.map(value => value.image) ?? []
    }

    return []
  }
)

const selectGridImages = createSelector(
  selectGridImagesRaw,
  apiSelectors.adjustedImages,
  (gridImagesRaw, adjustedImages) => {
    return gridImagesRaw?.map(value => adjustedImages[value.adjust ?? 0]?.output ?? value) ?? []
  }
)
const selectSelectedGridImages = createSelector(
  selectGridImages,
  selectImageSelectionAppliedData,
  (gridImages, imageSelection) => {
    const selected = imageSelection.selected
    const direction = imageSelection.direction

    if (direction === 'inverse') {
      return gridImages?.filter(value => !Boolean(selected[value.id]))
    }
    if (direction === 'normal') {
      return gridImages?.filter(value => Boolean(selected[value.id]))
    }
    return []
  }
)

const selectGridLoading = createSelector(
  selectNFTSellPanel,
  apiSelectors.loading['proArtFilter.listProArtFilterOutput'],
  apiSelectors.loading['sketchToImage.listSketchOutput'],
  apiSelectors.loading['projects.listUserImageBookmark'],
  apiSelectors.loading['textToImage.listTIProjectOutput'],
  apiSelectors.loading['projects.listUserImageBookmark'],
  (
    sellAsNFT,
    listProArtFilterOutputLoading,
    listSketchOutputLoading,
    listTIProjectOutputLoading,
    listUserImageBookmarkLoading
  ) => {
    const projectType = sellAsNFT.selectedProject?.projectType

    if (projectType === 'transfer_project') {
      return listProArtFilterOutputLoading
    } else if (projectType === 'sketch_project') {
      return listSketchOutputLoading
    } else if (projectType === 't2i_project') {
      return listTIProjectOutputLoading
    } else {
      return listUserImageBookmarkLoading
    }
  }
)
const selectGridCount = createSelector(
  selectNFTSellPanel,
  apiSelectors.currentProArtFilterProjectOutputCount,
  apiSelectors.currentSketchProjectOutputListCount,
  apiSelectors.currentMixProjectSavedListCount,
  apiSelectors.currentProjectSavedImageListCount,
  apiSelectors.currentTextToImageProjectOutputCount,
  (
    sellAsNFT,
    currentProArtFilterProjectOutputCount,
    currentSketchProjectOutputListCount,
    currentMixProjectSavedListCount,
    currentProjectSavedImageListCount,
    currentTextToImageProjectOutputCount
  ) => {
    const projectType = sellAsNFT.selectedProject?.projectType

    if (projectType === 'pretrain_mix_project') {
      return currentMixProjectSavedListCount
    } else if (projectType === 'sketch_project') {
      return currentSketchProjectOutputListCount
    } else if (projectType === 'transfer_project') {
      return currentProArtFilterProjectOutputCount
    } else if (projectType === 'training_project') {
      return currentProjectSavedImageListCount
    } else if (projectType === 't2i_project') {
      return currentTextToImageProjectOutputCount
    }

    return undefined
  }
)

const selectAppliedSelectedGridCount = createSelector(
  selectGridCount,
  selectImageSelectionAppliedData,
  (gridCount, imageSelection) => {
    const selected = imageSelection?.selected
    const direction = imageSelection?.direction
    return SelectUtils.countSelected(selected, gridCount, direction)
  }
)

const selectSelectedGridCount = createSelector(
  selectGridCount,
  selectImageSelectionData,
  (gridCount, imageSelection) => {
    const selected = imageSelection?.selected
    const direction = imageSelection?.direction

    return SelectUtils.countSelected(selected, gridCount, direction)
  }
)

const selectNotEnoughErrorText = createSelector(
  selectNFTSellPanel,
  selectGridCount,
  (sellAsNFT, gridCount) => {
    const projectType = sellAsNFT.selectedProject?.projectType
    const isFromSaved = (['pretrain_mix_project', 'training_project'] as NFTProjectType[]).includes(
      projectType ?? 'training_project'
    )

    const generateOrSavedText = isFromSaved ? 'saved' : 'generated'

    const gridCountText = !Boolean(gridCount)
      ? `You have no image ${generateOrSavedText}`
      : (gridCount ?? 0) > 1
        ? `You have ${gridCount} images ${generateOrSavedText} in this project.`
        : `You have ${gridCount} image ${generateOrSavedText} in this project.`

    if (isFromSaved) {
      return `You must first save at least ${values.MINIMUM_SELL_NFT_IMAGE} images to continue. ${gridCountText}`
    } else {
      return `You must first generate at least ${values.MINIMUM_SELL_NFT_IMAGE} images to continue. ${gridCountText}`
    }
  }
)

const selectPublishingProject = createSelector(selectNFTSellPanel, ({ projectPublishing }) =>
  _compact(
    Object.keys(projectPublishing).map(key =>
      projectPublishing[_toNumber(key)] ? _toNumber(key) : undefined
    )
  )
)

const selectSelectableImagesCount = createSelector(
  selectFormKey,
  selectNFTSellPanel,
  (formKey, sellAsNFT) => {
    return sellAsNFT.forms[formKey]?.formData?.images?.length || 0
  }
)

const currentMineProject = createSelector(
  selectSelectedProject,
  apiSelectors.artMineProjectData,
  (selectedProject, artmineProjectData) => {
    if (selectedProject?.projectType === 'artmineProject') {
      const id = selectedProject?.id
      const item = artmineProjectData[id ?? 0]
      return item ? completeMineProjectData(item) : undefined
    }
  }
)

export const selectors = {
  nftSellPanel: selectNFTSellPanel,
  step: selectStep,
  stepName: createSelector(selectStep, step => STEP_NAME_MAP[step]),
  maxStep: selectMaxStep,
  selectableImagesCount: selectSelectableImagesCount,
  notEnoughErrorText: selectNotEnoughErrorText,
  submitLoading: createSelector(selectNFTSellPanel, sellAsNFT => sellAsNFT.submitLoading),
  formData: selectFormData,
  isResubmitForm: createSelector(
    selectNFTSellPanel,
    panel => panel.selectedProject?.projectType === 'artmineProject'
  ),
  isProjectFreeform: selectIsProjectFreeform,
  hasFormErrors: createSelector(selectFormErrors, formErrors => isHasErrors(formErrors)),
  formErrors: selectFormErrors,
  selectedProject: selectSelectedProject,
  gridImages: selectGridImages,
  gridLoading: selectGridLoading,
  gridCount: selectGridCount,
  selectedGridImages: selectSelectedGridImages,
  selectedGridCount: selectSelectedGridCount,
  appliedSelectedGridCount: selectAppliedSelectedGridCount,
  imageSelectionData: selectImageSelectionData,

  isSelectedImageEnough: createSelector(
    selectSelectedGridCount,
    count => count >= values.MINIMUM_SELL_NFT_IMAGE
  ),
  isAppliedSelectedImageEnough: createSelector(
    selectAppliedSelectedGridCount,
    count => count >= values.MINIMUM_SELL_NFT_IMAGE
  ),
  currentMineProject,
  publishingProject: selectPublishingProject
}

export type FormDataType = Pick<
  CreateMineProjectReq,
  | 'type'
  | 'title'
  | 'description'
  | 'user_full_name'
  | 'thumbnail'
  | 'email'
  | 'twitter_url'
  | 'instagram_url'
  | 'network'
  | 'license'
  | 'images'
  | 'royalties'
  | 'start_price'
  | 'end_price'
> & {
  price_method: 'fixed' | 'increase' | 'decrease'

  //For future implementation
  number_of_nft: number
}

export const FORM_OPTIONS: {
  networks: SelectBaseItemType<FormDataType['network']>[]
  licenses: SelectBaseItemType<string>[]
  price_method: SelectBaseItemType<FormDataType['price_method']>[]
} = {
  networks: [
    {
      label: 'Ethereum',
      value: 'ETHEREUM'
    },
    {
      label: 'Polygon',
      value: 'POLYGON'
    }
  ],
  licenses: [
    { label: 'NFT License 2.0', value: 'nft_license_2.0' },
    { label: 'CC BY-NC 4.0', value: 'cc_by-nc_4.0' },
    { label: 'Unrestricted', value: 'unrestricted' }
  ],
  price_method: [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Increasing Price', value: 'increase' },
    { label: 'Decreasing Price', value: 'decrease' }
  ]
}

type FormTypes = keyof FormDataType
type FormErrors = Partial<Record<FormTypes, string | undefined>>

type SelectionData = {
  selected: SelectedFormat
  direction: SelectDirection
}

export type NFTProjectType = ProjectObjectType | 'artmineProject'

type FormItem = {
  step: number
  maxStep: number
  imageSelection: SelectionData
  imageSelectionApplied: SelectionData
  formData: FormDataType
  formErrors: FormErrors
}

export type NFTSellPanelState = {
  selectedProject?: {
    id: number
    projectType: NFTProjectType
  }
  submitLoading: boolean
  projectPublishing: Record<number, boolean>
  showForm: boolean
  forms: Record<string, FormItem>
}

const INITIAL: NFTSellPanelState = {
  selectedProject: undefined,
  showForm: false,
  submitLoading: false,
  forms: {},
  projectPublishing: {}
}

const INITIAL_ROYALTY: RoyaltyType = {
  address: '',
  initial_sale_fee_in_bp: 10000
}

const INITIAL_FORM_DATA: FormDataType = {
  type: 'artist',
  images: [],
  thumbnail: 0,
  title: '',
  user_full_name: '',
  description: '',
  email: '',
  twitter_url: '',
  instagram_url: '',
  end_price: '',
  start_price: '',
  network: 'ETHEREUM',
  license: 'nft_license_2.0',
  royalties: [INITIAL_ROYALTY],
  price_method: 'fixed',

  number_of_nft: 0
}

const REQUIRED_FORM_DATA: FormTypes[] = [
  'title',
  'user_full_name',
  'description',
  'end_price',
  'start_price'
]

const INITIAL_FORM_ITEM: FormItem = {
  step: 0,
  maxStep: 0,
  imageSelection: {
    selected: {},
    direction: 'inverse'
  },
  imageSelectionApplied: {
    selected: {},
    direction: 'inverse'
  },
  formData: INITIAL_FORM_DATA,
  formErrors: {}
}

const reducer = produce((state: NFTSellPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setStep): {
      const step = payload as ActionType<typeof actions.setStep>['payload']
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM
      const maxStep = currentData.maxStep
      state.forms[id] = {
        ...currentData,
        step,
        maxStep: step > maxStep ? step : maxStep
      }
      return
    }
    case getType(actions.acceptSubmit): {
      state.submitLoading = true
      return
    }
    case getType(actions.retrySubmit): {
      state.submitLoading = true
      return
    }
    case getType(actions.finishSubmit): {
      state.submitLoading = false
      return
    }

    case getType(actions.initForm): {
      const { id, projectType } = payload as ActionType<typeof actions.initForm>['payload']

      state.selectedProject = {
        id,
        projectType
      }
      return
    }

    case getType(actions.updateFormDataWithoutValidation):
    case getType(actions.updateFormData): {
      const data = payload as ActionType<typeof actions.updateFormData>['payload']
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM
      state.forms[id] = {
        ...currentData,
        formData: {
          ...currentData.formData,
          ...data
        }
      }
      return
    }
    case getType(actions.updateFormError): {
      const data = payload as ActionType<typeof actions.updateFormError>['payload']
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM
      state.forms[id] = {
        ...currentData,
        formErrors: {
          ...currentData.formErrors,
          ...data
        }
      }
      return
    }
    case getType(actions.resetForm): {
      const id = Utils.getFormKey(state)

      state.forms[id] = { ...INITIAL_FORM_ITEM }
      return
    }
    case getType(actions.imageSelect.updateSelection): {
      const imageSelection = payload as ActionType<
        typeof actions.imageSelect.updateSelection
      >['payload']
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM

      state.forms[id] = {
        ...currentData,
        imageSelection
      }
      return
    }
    case getType(actions.imageSelect.toggleSelect): {
      const data = payload as ActionType<typeof actions.imageSelect.toggleSelect>['payload']
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM
      const currentSelected = currentData.imageSelection.selected

      state.forms[id] = {
        ...currentData,
        imageSelection: {
          ...currentData.imageSelection,
          selected: {
            ...currentSelected,
            [data]: !Boolean(currentSelected[data])
          }
        }
      }
      return
    }
    case getType(actions.imageSelect.deselectAll): {
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM

      state.forms[id] = {
        ...currentData,
        imageSelection: {
          direction: 'normal',
          selected: {}
        }
      }
      return
    }
    case getType(actions.imageSelect.selectAll): {
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM

      state.forms[id] = {
        ...currentData,
        imageSelection: {
          direction: 'inverse',
          selected: {}
        }
      }
      return
    }
    case getType(actions.imageSelect.resetSelection): {
      const id = Utils.getFormKey(state)
      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM

      state.forms[id] = {
        ...currentData,
        imageSelection: { ...INITIAL_FORM_ITEM.imageSelection },
        imageSelectionApplied: { ...INITIAL_FORM_ITEM.imageSelection },
        formData: {
          ...currentData.formData,
          images: [...INITIAL_FORM_DATA.images]
        }
      }
      return
    }
    case getType(actions.imageSelect.apply): {
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM

      state.forms[id] = {
        ...currentData,
        imageSelectionApplied: { ...currentData.imageSelection }
      }
      return
    }
    case getType(actions.imageSelect.cancel): {
      const id = Utils.getFormKey(state)

      const currentData = state.forms[id] ?? INITIAL_FORM_ITEM

      state.forms[id] = {
        ...currentData,
        imageSelection: { ...currentData.imageSelectionApplied }
      }
      return
    }
    case getType(actions.setProjectPublishing): {
      const data = payload as ActionType<typeof actions.setProjectPublishing>['payload']

      state.projectPublishing = {
        ...state.projectPublishing,
        ...data
      }
      return
    }
    default:
  }
}, INITIAL)

// Epics
const setStepEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.setStep)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      selectedProject: selectSelectedProject(state),
      stepName: STEP_NAME_MAP[action.payload],
      isStepForm: action.payload === STEP.DETAILS,
      email: selectors.formData(state).email || apiSelectors.user(state)?.email,
      user_full_name:
        selectors.formData(state).user_full_name ||
        `${apiSelectors.user(state)?.first_name} ${apiSelectors.user(state)?.last_name}`
    })),
    tap(({ stepName, selectedProject }) => {
      MixPanelUtils.track<'NFT__SET_FORM_STEP'>('NFT - Set Form Step', {
        form_step: stepName,
        project_id: selectedProject?.id ?? 0,
        sell_ass_nft_source: selectedProject?.projectType
      })
    }),
    filter(({ isStepForm }) => isStepForm),
    map(({ email, user_full_name }) => actions.updateFormData({ user_full_name, email }))
  )

const validateContentEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.updateFormData)),
    debounceTime(800),
    withLatestFrom(state$),

    map(([action, state]) => {
      const { payload } = action
      const keys: FormTypes[] = Object.keys(payload) as FormTypes[]
      const currentFormData = selectors.formData(state)
      const formErrors: FormErrors = {}
      const formData: Partial<FormDataType> = {}
      const isResubmitForm = selectors.isResubmitForm(state)

      keys.forEach(key => {
        const value = payload[key]

        switch (key) {
          case 'email': {
            if (value && Validator.email(value.toString())) {
              formErrors['email'] = ''
            } else {
              formErrors['email'] = VALIDATION_MESSAGES.email
            }
            break
          }
          case 'twitter_url': {
            const valueString = `${value}`

            if (!value) {
              formErrors['twitter_url'] = ''
            } else {
              const valid = Validator.url(valueString) && valueString?.includes(values.TWITTER_URL)

              if (valid) {
                if (!(valueString.startsWith('http://') || valueString.startsWith('https://'))) {
                  formData['twitter_url'] = `http://${value}`
                }
                formErrors['twitter_url'] = ''
              } else {
                formErrors['twitter_url'] = 'Please enter a valid twitter profile'
              }
            }

            break
          }
          case 'instagram_url': {
            const valueString = `${value}`
            if (!value) {
              formErrors['instagram_url'] = ''
            } else {
              const valid =
                Validator.url(valueString) && valueString?.includes(values.INSTAGRAM_URL)
              if (valid) {
                if (!(valueString.startsWith('http://') || valueString.startsWith('https://'))) {
                  formData['instagram_url'] = `http://${value}`
                }
                formErrors['instagram_url'] = ''
              } else {
                formErrors['instagram_url'] = 'Please enter a valid instagram profile'
              }
            }

            break
          }
          case 'royalties': {
            const royalties = value as RoyaltyType[]
            const hasEmptyFee = royalties.reduce((hasEmpty, value) => {
              if (_isNil(value.initial_sale_fee_in_bp) || value.initial_sale_fee_in_bp === 0) {
                hasEmpty = true
              }
              return hasEmpty
            }, false)

            const totalInBp = royalties.reduce((total, value) => {
              total += value.initial_sale_fee_in_bp
              return total
            }, 0)

            const hasInvalidAddress = royalties.reduce((isInvalid, value) => {
              if (!isInvalid) {
                isInvalid = !value.address.match(/^0x[a-fA-F0-9]{40}$/)
              }
              return isInvalid
            }, false)

            const address = _compact(royalties.map(value => value.address))

            const walletHasSameAddress = _uniq(address).length !== address.length

            const isNot100Percent = Math.round(totalInBp * 100) !== BASE_POINT * 100

            const totalPercent = (totalInBp / BASE_POINT) * 100

            if (isNot100Percent) {
              formErrors['royalties'] =
                `Total percent should be exactly 100%, you have ${Math.round(totalPercent)}%`
            } else if (walletHasSameAddress) {
              formErrors['royalties'] = `You can’t use the same wallet address twice`
            } else if (hasEmptyFee) {
              formErrors['royalties'] = `Percentage should not be empty or zero`
            } else if (hasInvalidAddress) {
              formErrors['royalties'] =
                royalties.length > 1
                  ? 'One of the address is invalid, make sure you have full address copied in the form.'
                  : 'The address is invalid, make sure you have full address copied in the form.'
            } else {
              formErrors['royalties'] = ''
            }
            break
          }
          case 'end_price':
          case 'start_price':
          case 'price_method': {
            const start_price = _toNumber(currentFormData.start_price)
            const end_price = _toNumber(currentFormData.end_price)

            const startPriceNegative = start_price < 0
            const endPriceNegative = end_price < 0

            if (endPriceNegative || startPriceNegative) {
              formErrors['end_price'] = endPriceNegative
                ? `Price should be larger than zero`
                : undefined
              formErrors['start_price'] = startPriceNegative
                ? `Price should be larger than zero`
                : undefined

              break
            }

            if (currentFormData.price_method === 'fixed') {
              formData['end_price'] = currentFormData.start_price
            }

            if (!start_price || !end_price) {
              break
            }

            if (currentFormData.price_method === 'decrease') {
              if (start_price <= end_price) {
                formErrors['end_price'] = `End Price should be lower than Star Price`
                formErrors['start_price'] = `Start Price should be higher than End Price`
              } else {
                formErrors['end_price'] = undefined
                formErrors['start_price'] = undefined
              }
            }
            if (currentFormData.price_method === 'increase') {
              if (start_price >= end_price) {
                formErrors['end_price'] = `End Price should be higher than Star Price`
                formErrors['start_price'] = `Start Price should be lower than End Price`
              } else {
                formErrors['end_price'] = undefined
                formErrors['start_price'] = undefined
              }
            }
            break
          }
          default:
            break
        }
      })

      const hasFormData = Boolean(Object.keys(formData).length)
      const hasFormErrors = Boolean(Object.keys(formErrors).length)

      return { hasFormData, hasFormErrors, formErrors, formData, isResubmitForm }
    }),
    filter(({ isResubmitForm }) => !isResubmitForm),
    mergeMap(({ hasFormErrors, hasFormData, formErrors, formData }) =>
      _compact([
        hasFormErrors && actions.updateFormError(formErrors),
        hasFormData && actions.updateFormDataWithoutValidation(formData)
      ])
    )
  )

const continueFormEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.continueForm)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      formData: selectors.formData(state)
    })),
    map(({ formData }) => {
      const formErrors: FormErrors = {}

      REQUIRED_FORM_DATA.forEach(key => {
        const value = formData[key]
        if (Validator.isEmpty(value)) {
          formErrors[key] = VALIDATION_MESSAGES.required
        }
      })

      const royalties = formData['royalties']

      const hasEmptyRoyalties = royalties.reduce((hasEmpty, royalty) => {
        if (!royalty.address) {
          hasEmpty = true
        }
        return hasEmpty
      }, false)

      formErrors['royalties'] = hasEmptyRoyalties ? "Wallet can't be empty" : undefined

      const hasError = isHasErrors(formErrors)
      return { formErrors, hasError }
    }),
    mergeMap(({ formErrors, hasError }) =>
      _compact([
        hasError && actions.updateFormError(formErrors),
        !hasError && actions.setStep(STEP.SUBMIT)
      ])
    )
  )

const loadAllImageGridEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadAllImageGrid)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      selectedProject: selectSelectedProject(state),
      currentTrainingProject: apiSelectors.currentProject(state),
      currentMixImageProject: apiSelectors.currentMixImageProject(state),
      reload: action.payload.reload
    })),
    map(({ selectedProject, reload, currentMixImageProject, currentTrainingProject }) => {
      const projectType = selectedProject?.projectType
      const id = selectedProject?.id ?? 0
      const scope =
        projectType === 'pretrain_mix_project'
          ? currentMixImageProject.bookmark_scope
          : projectType === 'training_project'
            ? currentTrainingProject.bookmark_scope
            : ''

      const adjustedScope = BookmarkUtils.adjustBookmarkScope(scope)
      const next = !reload
      return { next, id, adjustedScope, projectType }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ projectType }) => projectType === 'sketch_project'),
          mergeMap(({ id = 0, next }) =>
            action$.pipe(
              filter(isActionOf(apiActions.sketchToImage.listSketchOutputResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => ({
                hasNext: Boolean(action.payload.data.next),
                publishedImages: _compact(
                  selectors
                    .gridImages(state)
                    ?.map(value => (value.is_published ? value.id : undefined))
                )
              })),
              concatMap(({ hasNext, publishedImages }) =>
                _compact([
                  hasNext && actions.loadAllImageGrid({ reload: false }),
                  actions.imageSelect.updateSelection({
                    direction: 'inverse',
                    selected: publishedImages.reduce(
                      (result, value) => {
                        result[value] = true

                        return result
                      },
                      {} as Record<number, boolean>
                    )
                  }),
                  actions.imageSelect.apply()
                ])
              ),
              startWith(
                apiActions.sketchToImage.listSketchOutput({
                  param: { project: id, limit: 100 },
                  next
                })
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ projectType }) => projectType === 'transfer_project'),
          mergeMap(({ id = 0, next }) =>
            action$.pipe(
              filter(isActionOf(apiActions.proArtFilter.listProArtFilterOutputResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => ({
                hasNext: Boolean(action.payload.data.next),
                publishedImages: _compact(
                  selectors
                    .gridImages(state)
                    ?.map(value => (value.is_published ? value.id : undefined))
                )
              })),
              concatMap(({ hasNext, publishedImages }) =>
                _compact([
                  hasNext && actions.loadAllImageGrid({ reload: false }),
                  actions.imageSelect.updateSelection({
                    direction: 'inverse',
                    selected: publishedImages.reduce(
                      (result, value) => {
                        result[value] = true

                        return result
                      },
                      {} as Record<number, boolean>
                    )
                  }),
                  actions.imageSelect.apply()
                ])
              ),
              startWith(
                apiActions.proArtFilter.listProArtFilterOutput({
                  param: { project: id, limit: 100 },
                  next
                })
              )
            )
          )
        ),
        of(param).pipe(
          filter(
            ({ projectType }) =>
              projectType === 'training_project' || projectType === 'pretrain_mix_project'
          ),
          mergeMap(({ id = 0, next, adjustedScope }) =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.listUserImageBookmarkResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => ({
                hasNext: Boolean(action.payload.data.next),
                publishedImages: _compact(
                  selectors
                    .gridImages(state)
                    ?.map(value => (value.is_published ? value.id : undefined))
                )
              })),
              concatMap(({ hasNext, publishedImages }) =>
                _compact([
                  hasNext && actions.loadAllImageGrid({ reload: false }),
                  actions.imageSelect.updateSelection({
                    direction: 'inverse',
                    selected: publishedImages.reduce(
                      (result, value) => {
                        result[value] = true

                        return result
                      },
                      {} as Record<number, boolean>
                    )
                  }),
                  actions.imageSelect.apply()
                ])
              ),
              startWith(
                apiActions.projects.listUserImageBookmark({
                  next,
                  param: {
                    scope: adjustedScope,
                    limit: 100,
                    offset: 0
                  }
                })
              )
            )
          )
        )
      )
    )
  )

const acceptSubmitEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.acceptSubmit)),
    withLatestFrom(state$),
    map(([_, state]) => {
      return {
        formData: selectors.formData(state),
        stepName: selectors.stepName(state)
      }
    }),
    map(({ formData, stepName }) => ({
      formData: {
        type: formData.type,
        title: formData.title,
        description: formData.description,
        user_full_name: formData.user_full_name,
        email: formData.email,
        twitter_url: formData.twitter_url,
        instagram_url: formData.instagram_url,
        network: formData.network,
        license: formData.license,
        start_price: `${_toNumber(formData.start_price) * values.WEI_SCALE}`,
        end_price: `${_toNumber(formData.end_price) * values.WEI_SCALE}`,
        thumbnail: formData.thumbnail,
        images: formData.images,
        royalties: formData.royalties
      },
      stepName
    })),
    tap(({ formData, stepName }) => {
      const { end_price, license, start_price, network, images, title } = formData

      MixPanelUtils.track<'NFT__SUBMIT'>('NFT - Submit', {
        project_name: title,
        network,
        license,
        image_count: images.length,
        end_price: _toNumber(end_price),
        start_price: _toNumber(start_price),
        form_step: stepName
      })
    }),
    mergeMap(({ formData }) =>
      action$.pipe(
        filter(isActionOf(apiActions.artMine.createArtMineProjectResponse)),
        take(1),
        mergeMap(({ payload }) =>
          action$.pipe(
            filter(isActionOf(apiActions.artMine.retrieveSignatureResponse)),
            take(1),
            map(({ payload }) => payload),
            mergeMap(({ signature, projectId }) =>
              action$.pipe(
                filter(isActionOf(apiActions.artMine.startTransactionResponse)),
                take(1),
                map(() => ({
                  royalties: signature.royalties,
                  expire_at: signature.expire_at,
                  signature: signature.signature,
                  projectId: projectId
                })),
                mergeMap(param =>
                  from(CryptoUtils.addProject(param)).pipe(
                    tap(() => {
                      const { end_price, id, license, mine_limit, start_price, network, title } =
                        payload

                      MixPanelUtils.track<'NFT__SUBMIT_SUCCESSFULL'>('NFT - Submit Successfull', {
                        project_id: id,
                        project_name: title,
                        network,
                        license,
                        image_count: mine_limit,
                        end_price: _toNumber(end_price),
                        start_price: _toNumber(start_price)
                      })
                    }),
                    mergeMap(() => [
                      actions.finishSubmit(),
                      actions.resetForm(),
                      actions.setProjectPublishing({ [param.projectId]: true }),
                      dialogActions.openDialog({
                        [InformationDialog.INFORMATION]: {
                          dialogName: InformationDialog.INFORMATION,
                          title: 'Your Project Has Been Submitted',
                          closeText: 'CLOSE',
                          content:
                            'Your project is being submitted to the blockchain. Once published, you will see the “View on Art Mine” link on your project page.'
                        }
                      })
                    ]),
                    catchError(err =>
                      of(err).pipe(
                        map((err: { code: number; message: string }) => {
                          SentryUtils.captureMessage(
                            `Error On Add Project `,
                            { errorCode: err.code, message: err.message },
                            'error'
                          )
                          const isUserReject = err.code === 4001
                          const isTransactionUnderpriced =
                            err.code === -32603 || err.code === 32603 || err.code === -32000

                          const isSpecialError = isUserReject || isTransactionUnderpriced

                          return {
                            err,
                            isSpecialError,
                            isTransactionUnderpriced,
                            isUserReject
                          }
                        }),
                        concatMap(
                          ({ err, isUserReject, isSpecialError, isTransactionUnderpriced }) =>
                            _compact([
                              actions.finishSubmit(),
                              !isSpecialError &&
                                dialogActions.addDialogOverlay({
                                  [ErrorDialog.ERROR]: {
                                    dialogName: ErrorDialog.ERROR,
                                    title: 'Submit Failed',
                                    content: err.message
                                  }
                                }),
                              payload.id && actions.resetForm(),
                              payload.id &&
                                dialogActions.openDialog({
                                  [FormDialog.SELL_AS_NFT_FORM]: {
                                    dialogName: FormDialog.SELL_AS_NFT_FORM,
                                    content: { artMineProjectId: payload.id }
                                  }
                                }),
                              isUserReject &&
                                dialogActions.addDialogOverlay({
                                  [ErrorDialog.ERROR]: {
                                    dialogName: ErrorDialog.ERROR,
                                    title: 'Submit Canceled',
                                    content:
                                      'The submission process was canceled. The project is saved and you can always resubmit the project on My NFT page.'
                                  }
                                }),
                              isTransactionUnderpriced &&
                                dialogActions.addDialogOverlay({
                                  [ErrorDialog.NOT_ENOUGH_GAS_PRICE]: {
                                    dialogName: ErrorDialog.NOT_ENOUGH_GAS_PRICE
                                  }
                                }),
                              isSpecialError && apiActions.artMine.retrieveProject(payload.id)
                            ])
                        )
                      )
                    )
                  )
                ),
                startWith(apiActions.artMine.startTransaction(payload.id))
              )
            ),
            startWith(apiActions.artMine.retrieveSignature(payload.id))
          )
        ),
        startWith(apiActions.artMine.createArtMineProject(formData))
      )
    )
  )

const retrySubmitEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.retrySubmit)),
    withLatestFrom(state$),
    map(([_, state]) => {
      return {
        selectedProject: selectors.selectedProject(state),
        artMineProjectData: apiSelectors.artMineProjectData(state)
      }
    }),
    filter(({ selectedProject }) =>
      Boolean(selectedProject?.projectType === 'artmineProject' && selectedProject?.id)
    ),
    map(({ selectedProject, artMineProjectData }) => {
      return {
        selectedProject,
        projectData: artMineProjectData[selectedProject?.id ?? 0]
      }
    }),
    tap(({ projectData }) => {
      MixPanelUtils.track<'NFT__RESUBMIT'>('NFT - Resubmit', {
        project_id: projectData?.id,
        project_name: projectData?.title,
        network: projectData?.network,
        license: projectData?.license,
        image_count: projectData?.mine_limit,
        end_price: _toNumber(projectData?.end_price),
        start_price: _toNumber(projectData?.start_price)
      })
    }),
    mergeMap(({ selectedProject, projectData }) =>
      action$.pipe(
        filter(isActionOf(apiActions.artMine.retrieveSignatureResponse)),
        take(1),
        map(({ payload }) => payload),
        mergeMap(({ signature, projectId }) =>
          action$.pipe(
            filter(isActionOf(apiActions.artMine.startTransactionResponse)),
            take(1),
            map(() => ({
              royalties: signature.royalties,
              expire_at: signature.expire_at,
              signature: signature.signature,
              projectId
            })),
            mergeMap(param =>
              from(CryptoUtils.addProject(param)).pipe(
                tap(() => {
                  MixPanelUtils.track<'NFT__RESUBMIT_SUCCESSFULL'>('NFT - Resubmit Successfull', {
                    project_id: projectData?.id,
                    project_name: projectData?.title,
                    network: projectData?.network,
                    license: projectData?.license,
                    image_count: projectData?.mine_limit,
                    end_price: _toNumber(projectData?.end_price),
                    start_price: _toNumber(projectData?.start_price)
                  })
                }),
                mergeMap(() => [
                  apiActions.artMine.retrieveProject(selectedProject?.id ?? 0),
                  actions.finishSubmit(),
                  actions.resetForm(),
                  actions.setProjectPublishing({ [param.projectId]: true }),
                  dialogActions.openDialog({
                    [InformationDialog.INFORMATION]: {
                      dialogName: InformationDialog.INFORMATION,
                      title: 'Your Project Has Been Submitted',
                      closeText: 'CLOSE',
                      content:
                        'Your project is being submitted to the blockchain. Once published, you will see the “View on Art Mine” link on your project page.'
                    }
                  })
                ]),
                catchError(err =>
                  of(err).pipe(
                    map((err: { code: number; message: string }) => {
                      SentryUtils.captureMessage(
                        `Error On Add Project `,
                        { errorCode: err.code, message: err.message },
                        'error'
                      )
                      const isUserReject = err.code === 4001
                      const isTransactionUnderpriced =
                        err.code === -32603 || err.code === 32603 || err.code === -32000

                      const isSpecialError = isUserReject || isTransactionUnderpriced

                      return {
                        err,
                        isSpecialError,
                        isTransactionUnderpriced,
                        isUserReject
                      }
                    }),
                    concatMap(({ err, isSpecialError, isTransactionUnderpriced, isUserReject }) =>
                      _compact([
                        actions.finishSubmit(),
                        !isSpecialError &&
                          dialogActions.addDialogOverlay({
                            [ErrorDialog.ERROR]: {
                              dialogName: ErrorDialog.ERROR,
                              title: 'Submit Failed',
                              content: err.message
                            }
                          }),
                        isUserReject &&
                          dialogActions.addDialogOverlay({
                            [ErrorDialog.ERROR]: {
                              dialogName: ErrorDialog.ERROR,
                              title: 'Submit Canceled',
                              content: `The submission process was canceled. The project is saved and you can always resubmit the project on My NFT page after ${MINE_PROJECT_SUBMIT_WAIT_TIME} minutes from the last submit.`
                            }
                          }),
                        isTransactionUnderpriced &&
                          dialogActions.addDialogOverlay({
                            [ErrorDialog.NOT_ENOUGH_GAS_PRICE]: {
                              dialogName: ErrorDialog.NOT_ENOUGH_GAS_PRICE
                            }
                          }),
                        isSpecialError &&
                          selectedProject?.id &&
                          apiActions.artMine.retrieveProject(selectedProject?.id ?? 0)
                      ])
                    )
                  )
                )
              )
            ),
            startWith(apiActions.artMine.startTransaction(selectedProject?.id ?? 0))
          )
        ),
        startWith(apiActions.artMine.retrieveSignature(selectedProject?.id ?? 0))
      )
    )
  )

const initFormEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.initForm)),
    withLatestFrom(state$),
    map(([action, state]) => {
      return {
        id: action.payload.id,
        isArtmine: action.payload.projectType === 'artmineProject'
      }
    }),
    filter(({ isArtmine }) => Boolean(isArtmine)),
    mergeMap(({ id }) =>
      action$.pipe(
        filter(isActionOf(apiActions.artMine.retrieveProjectResponse)),
        take(1),
        map(({ payload }) => {
          const start_price = _toNumber(payload.start_price)
          const end_price = _toNumber(payload.end_price)
          const price_method: FormDataType['price_method'] =
            start_price > end_price ? 'decrease' : end_price > start_price ? 'increase' : 'fixed'

          return {
            payload,
            start_price,
            end_price,
            price_method
          }
        }),
        mergeMap(({ payload, price_method, start_price, end_price }) => [
          actions.setStep(STEP.SUBMIT),
          actions.updateFormDataWithoutValidation({
            type: payload.type,
            title: payload.title,
            description: payload.description,
            user_full_name: payload.user_full_name,
            thumbnail: payload.thumbnail.id,
            email: payload.email,
            twitter_url: payload.twitter_url,
            instagram_url: payload.instagram_url,
            network: payload.network,
            license: payload.license,
            images: [],
            royalties: [],
            price_method,
            start_price: `${start_price / values.WEI_SCALE}`,
            end_price: `${end_price / values.WEI_SCALE}`
          })
        ]),
        startWith(apiActions.artMine.retrieveProject(id))
      )
    )
  )

const retrievePublishStatusEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.retrievePublishStatus)),
    map(({ payload }) => {
      return {
        id: payload
      }
    }),
    mergeMap(({ id }) =>
      action$.pipe(
        filter(isActionOf(apiActions.artMine.retrieveProjectResponse)),
        take(1),
        filter(({ payload }) => Boolean(payload.is_published)),
        mergeMap(({ payload }) => [
          actions.setProjectPublishing({ [payload.id]: false }),
          bannerActions.nftBanner.show({ artmineProjectId: payload.id })
        ]),
        startWith(apiActions.artMine.retrieveProject(id))
      )
    )
  )

const imageSelectApplyEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.imageSelect.apply)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      imageSelection: selectImageSelectionData(state),
      gridImages: selectors.gridImages(state),
      formData: selectors.formData(state)
    })),
    map(({ imageSelection, gridImages, formData }) => ({
      imageSelection,
      gridImages,
      thumbnail: formData.thumbnail,
      selectedImageIds: SelectUtils.getSelectedList(imageSelection.selected)
    })),
    map(({ thumbnail, gridImages, imageSelection, selectedImageIds }) => {
      const formData: Partial<FormDataType> = {}

      if (imageSelection.direction === 'normal') {
        formData['images'] = selectedImageIds
      }
      if (imageSelection.direction === 'inverse') {
        const gridImageIds = gridImages?.map(value => value.id)
        formData['images'] = _difference(gridImageIds, selectedImageIds)
      }

      if (!formData.images?.includes(thumbnail)) {
        formData['thumbnail'] = undefined
      }

      return formData
    }),
    map(formData => actions.updateFormData(formData))
  )

const selectAllEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.imageSelect.selectAll)),
    withLatestFrom(state$),
    map(([, state]) => ({
      gridImages: selectors.gridImages(state)
    })),
    map(({ gridImages }) => ({
      publishedImages: gridImages
        ?.filter(value => Boolean(value.is_published))
        .map(value => value.id)
    })),
    map(({ publishedImages }) =>
      actions.imageSelect.updateSelection({
        direction: 'inverse',
        selected:
          publishedImages?.reduce(
            (result, value) => {
              result[value] = true
              return result
            },
            {} as Record<number, boolean>
          ) ?? {}
      })
    )
  )

export const listenOnCreateArtmineProjectErrorEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(
      ({ payload }) =>
        payload.type === getType(apiActions.artMine.createArtMineProject) ||
        payload.type === getType(apiActions.artMine.retrieveSignature) ||
        payload.type === getType(apiActions.artMine.startTransaction)
    ),
    map(() => actions.finishSubmit())
  )

const checkOnUserImagesAdjust: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.setStep)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isStepCover: action.payload === STEP.SELECT_COVER,
      images: selectGridImages(state)
    })),
    filter(({ isStepCover }) => isStepCover),
    map(({ images }) => apiActions.imageEnhancement.checkOnUserImagesAdjust({ userImages: images }))
  )

export const epics = combineEpics(
  checkOnUserImagesAdjust,
  listenOnCreateArtmineProjectErrorEpic,
  initFormEpic,
  selectAllEpic,
  retrievePublishStatusEpic,
  imageSelectApplyEpic,
  loadAllImageGridEpic,
  setStepEpic,
  retrySubmitEpic,
  acceptSubmitEpic,
  continueFormEpic,
  validateContentEpic
)

export default reducer
