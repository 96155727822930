import {
  BookmarkUtils,
  TextTransform,
  GENERICAPP_TEXT,
  LATENT_TEXT,
  SKETCH_TEXT,
  TRAINING_TEXT,
  UrlUtils,
  TEXT_TO_IMAGE_TEXT
} from 'utils/TextUtils'
import produce from 'immer'
import _compact from 'lodash/compact'
import { combineEpics, Epic } from 'redux-observable'
import {
  withLatestFrom,
  map,
  mergeMap,
  filter,
  delay,
  concatMap,
  startWith,
  take,
  tap,
  ignoreElements
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import {
  TrainProjectListReq,
  SketchProjectListReq,
  PaginationRequestParams,
  TrainProjectOrderingTypeList,
  TrainProjectOrderingType,
  CollectionListReq,
  CollectionOrderingType,
  CollectionOrderingTypeList,
  Bookmark,
  DEFAULT_BOOKMARK_SCOPE,
  UserImageBookmarkListReq,
  ListMineProjectReq,
  TIProjectListReq
} from 'models/ApiModels'
import { LSKey, values } from 'appConstants'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { appActions } from 'duck/AppDuck'
import {
  isActionOf,
  getType,
  createAction,
  ActionType,
  PayloadActionCreator
} from 'typesafe-actions'
import { createSelector } from 'reselect'
import {
  RouteHomeType,
  TRAIN_PROJECTS,
  MIX_PROJECTS,
  SKETCH_PROJECTS,
  COLLECTIONS,
  SAVED_RESULTS,
  UPSCALES,
  PRO_FILTER_PROJECTS,
  DOWNLOADS,
  TEXT_TO_IMAGE_PROJECTS,
  TEXT_TO_VIDEO_PROJECTS,
  SKETCHTEXT_TO_IMAGE_PROJECTS,
  NFTS,
  route,
  GENERIC_APP_PROJECTS,
  RouteProjectsPageType
} from 'routes'
import { getHomeRoute } from './Hooks'
import { SessionStorage } from 'utils'
import { MenuOneItem } from 'components/Menu/MenuOne'

// Constants
const NAMESPACE = '@@page/HomePage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const ANIMATION_DURATION = 250
export const HOME_LIST_TYPE = 'home_page'
export const URL_PARAM = {
  ID: 'id',
  ORDERING: 'ordering'
}

export type PageListValueType = RouteHomeType | 'separator' | 'projects' | ''
export type ProjectsPageListValueType = RouteProjectsPageType | ''

export const PROJECTMENU_LIST = [
  GENERIC_APP_PROJECTS,
  TRAIN_PROJECTS,
  SKETCH_PROJECTS,
  MIX_PROJECTS,
  PRO_FILTER_PROJECTS,
  TEXT_TO_IMAGE_PROJECTS,
  SKETCHTEXT_TO_IMAGE_PROJECTS
]

export const HomeSubmenuList: MenuOneItem<ProjectsPageListValueType>[] = _compact([
  { label: '', value: '' },
  { label: 'Prompt To Image Pro', value: SKETCHTEXT_TO_IMAGE_PROJECTS },
  { label: 'Apps', value: GENERIC_APP_PROJECTS },
  { label: 'Training', value: TRAIN_PROJECTS },
  !values.HIDE_STYLIZE_PROJECT
    ? { label: 'Stylize Filter', value: PRO_FILTER_PROJECTS }
    : undefined,
  { label: 'Sketch (Legacy)', value: SKETCH_PROJECTS },
  { label: 'Text To Image (Legacy)', value: TEXT_TO_IMAGE_PROJECTS },
  { label: 'Faces', value: MIX_PROJECTS }
])

// Actions
export const actions = {
  openPage: createAction(creator('OPEN_PAGE'))<{ id?: number; page?: RouteHomeType }>(),
  setPage: createAction(creator('SET_PAGE'))<RouteHomeType>(),
  syncParamAndUrl: createAction(creator('SYNC_PARAM_AND_URL'))(),
  initSearchParam: createAction(creator('INIT_SEARCH_PARAM'))<{
    search: string
    page?: RouteHomeType
  }>(),
  openItem: createAction(creator('OPEN_ITEM'))<number | undefined>(),
  openProject: createAction(creator('OPEN_PROJECT'))<{ scope: Bookmark<'user-image'>['scope'] }>(),
  loadTrainProjects: createAction(creator('LOAD_TRAIN_PROJECTS'))(),
  loadSketchProjects: createAction(creator('LOAD_SKETCH_PROJECTS'))(),
  loadSketchTextProjects: createAction(creator('LOAD_SKETCH_TEXT_PROJECTS'))(),
  loadProArtFilterProjects: createAction(creator('LOAD_STYLIZE_PROJECT'))(),
  loadMixImageProjects: createAction(creator('LOAD_MIX_IMAGE_PROJECTS'))(),
  loadTextToImageProjects: createAction(creator('LOAD_TEXT_TO_IMAGE_PROJECTS'))(),
  loadTextToVideoProjects: createAction(creator('LOAD_TEXT_TO_VIDEO_PROJECTS'))(),
  loadAiAppProjects: createAction(creator('LOAD_GENERIC_APP_PROJECTS'))(),
  loadCollectionList: createAction(creator('LOAD_COLLECTION_LIST'))(),
  loadSavedResultList: createAction(creator('LOAD_SAVED_RESULT_LIST'))(),
  loadDownloadList: createAction(creator('LOAD_DOWNLOAD_LIST'))(),
  loadArtmineProject: createAction(creator('LOAD_ARTMINE_PROJECTS'))(),
  setLoaded: createAction(creator('SET_LOADED'))<RouteHomeType>(),
  projectSetSortBy: createAction(creator('SET_SORT_BY'))<TrainProjectOrderingType>(),
  collectionSetSortBy: createAction(creator('COLLECTION_SET_SORT_BY'))<CollectionOrderingType>()
}

// Selectors
const selectHomePage = (state: RootState) => state.container.homePage
const selectOpenedId = createSelector(selectHomePage, homePage => homePage.openedId)
const selectPage = createSelector(selectHomePage, homePage => homePage.page)

export const selectors = {
  homePage: selectHomePage,
  loaded: createSelector(selectHomePage, homePage => homePage.loaded),
  selectOpenedId: selectOpenedId,
  openedSavedResultData: createSelector(
    selectOpenedId,
    selectPage,
    apiSelectors.userImageBookmarks,
    apiSelectors.userImages,
    apiSelectors.adjustedImages,
    (openedSavedResult, page, userImageBookmarks, userImages, adjustedImages) => {
      const userImageBookmark =
        openedSavedResult && page === SAVED_RESULTS
          ? userImageBookmarks[openedSavedResult]
          : undefined
      if (userImageBookmark) {
        const userImage = userImages[userImageBookmark?.item?.id ?? 0]

        const result: Bookmark<'user-image'> = {
          ...userImageBookmark,
          item: userImage
            ? {
                ...userImage,
                adjustData: adjustedImages[userImage.adjust ?? 0]
              }
            : userImageBookmark.item
        }
        return result
      }
    }
  ),
  listSavedImagesParam: createSelector(selectHomePage, homePage => homePage.listSavedImagesParam),
  listArtmineProjectParam: createSelector(
    selectHomePage,
    apiSelectors.currentUserId,
    (homePage, currentUserId) => ({
      ...homePage.listArtmineProjectParam,
      user: currentUserId
    })
  ),
  openedItemId: createSelector(
    selectHomePage,
    apiSelectors.currentProArtFilterId,
    apiSelectors.currentCollectionId,
    apiSelectors.currentProjectId,
    apiSelectors.currentSketchProjectId,
    apiSelectors.currentMixImageProjectId,
    apiSelectors.currentTextToImageProjectId,
    apiSelectors.currentGenericAppProjectId,
    apiSelectors.currentSketchTextToImageProjectId,
    (
      homePage,
      currentProArtFilterId,
      currentCollectionId,
      currentProjectId,
      currentSketchProjectId,
      currentMixImageProjectId,
      currentTextToImageProjectId,
      currentGenericAppProjectId,
      currentSketchTextToImageProjectId
    ) => {
      const { openedId, page } = homePage

      const openId: { [key in RouteHomeType]: number | undefined } = {
        [COLLECTIONS]: currentCollectionId,
        [MIX_PROJECTS]: currentMixImageProjectId,
        [TRAIN_PROJECTS]: currentProjectId,
        [SKETCH_PROJECTS]: currentSketchProjectId,
        [UPSCALES]: 0,
        [TEXT_TO_IMAGE_PROJECTS]: currentTextToImageProjectId,
        [TEXT_TO_VIDEO_PROJECTS]: 0,
        [GENERIC_APP_PROJECTS]: currentGenericAppProjectId,
        [SKETCHTEXT_TO_IMAGE_PROJECTS]: currentSketchTextToImageProjectId,
        [PRO_FILTER_PROJECTS]: currentProArtFilterId,
        [SAVED_RESULTS]: openedId,
        [DOWNLOADS]: 0,
        [NFTS]: 0
      }

      return openId[page]
    }
  ),
  savedImagesLastRequest: createSelector(
    apiSelectors.userImageBookmarkLists,
    userImageBookmarkLists => {
      const scope = DEFAULT_BOOKMARK_SCOPE
      return userImageBookmarkLists?.[scope]?.lastRequest
    }
  )
}

// Reducer
export type HomePageState = {
  loaded: { [key in RouteHomeType]: boolean }
  page: RouteHomeType
  pageList: {
    value: PageListValueType
    label?: string
  }[]
  openedId?: number
  listProjectParams: TrainProjectListReq
  listSketchProjectParams: SketchProjectListReq
  listSketchTextProjectParams: PaginationRequestParams
  listMixImageProjectParams: PaginationRequestParams
  listProArtFilterProjectParams: PaginationRequestParams
  listGenericAppProjectParams: PaginationRequestParams
  textToImageProjectParams: TIProjectListReq
  textToVideoProjectParams: TIProjectListReq
  listCollectionParam: CollectionListReq
  listSavedImagesParam: UserImageBookmarkListReq
  listDownloadParam: PaginationRequestParams
  listArtmineProjectParam: ListMineProjectReq
}

const initial: HomePageState = {
  loaded: {
    [TRAIN_PROJECTS]: false,
    [MIX_PROJECTS]: false,
    [SKETCH_PROJECTS]: false,
    [COLLECTIONS]: false,
    [SAVED_RESULTS]: false,
    [UPSCALES]: false,
    [PRO_FILTER_PROJECTS]: false,
    [DOWNLOADS]: false,
    [NFTS]: false,
    [TEXT_TO_IMAGE_PROJECTS]: false,
    [TEXT_TO_VIDEO_PROJECTS]: false,
    [GENERIC_APP_PROJECTS]: false,
    [SKETCHTEXT_TO_IMAGE_PROJECTS]: false
  },
  openedId: undefined,
  page: TRAIN_PROJECTS,

  pageList: _compact([
    { label: 'Ai Apps', value: GENERIC_APP_PROJECTS },
    { label: 'Training', value: TRAIN_PROJECTS },
    { label: 'Sketch', value: SKETCH_PROJECTS },
    { label: 'Mix', value: MIX_PROJECTS },
    !values.HIDE_STYLIZE_PROJECT
      ? { label: 'Stylize Filter', value: PRO_FILTER_PROJECTS }
      : undefined,
    { label: 'Text To Art', value: TEXT_TO_IMAGE_PROJECTS },
    { value: 'separator' },
    values.ENABLE_SELL_NFT ? { label: 'My NFTs', value: NFTS } : undefined,
    { label: 'My Collections', value: COLLECTIONS },
    { label: 'Saved Results', value: SAVED_RESULTS },
    { label: 'My Upscales', value: UPSCALES },
    { label: 'My Downloads', value: DOWNLOADS }
  ]),
  listProjectParams: {
    ordering: '-modified',
    scope: 'current_user',
    limit: 30
  },
  listSketchProjectParams: {
    limit: 30
  },
  listMixImageProjectParams: {
    limit: 30
  },
  textToImageProjectParams: {
    limit: 30
  },
  textToVideoProjectParams: {
    limit: 30
  },
  listProArtFilterProjectParams: {
    limit: 30
  },
  listGenericAppProjectParams: {
    limit: 30
  },
  listSketchTextProjectParams: {
    limit: 30
  },
  listCollectionParam: {
    limit: 30,
    scope: 'current_user',
    ordering: '-modified'
  },
  listSavedImagesParam: {
    limit: 30
  },
  listDownloadParam: {
    limit: 30
  },
  listArtmineProjectParam: {
    limit: 30,
    sort: '-date'
  }
}

const reducer = produce((state: HomePageState, { type, payload }) => {
  switch (type) {
    case getType(actions.setPage): {
      const value = payload as ActionType<typeof actions.setPage>['payload']
      state.page = value
      return
    }
    case getType(actions.setLoaded): {
      const mode = payload as ActionType<typeof actions.setLoaded>['payload']

      state.loaded[mode] = true
      return
    }
    case getType(actions.projectSetSortBy): {
      const value = payload as ActionType<typeof actions.projectSetSortBy>['payload']

      state.listProjectParams.ordering = value
      return
    }
    case getType(actions.collectionSetSortBy): {
      const value = payload as ActionType<typeof actions.collectionSetSortBy>['payload']

      state.listCollectionParam.ordering = value
      return
    }
    case getType(actions.openItem): {
      const value = payload as ActionType<typeof actions.openItem>['payload']
      state.openedId = value

      return
    }
    case getType(actions.initSearchParam): {
      const { search, page } = payload as ActionType<typeof actions.initSearchParam>['payload']

      const data = UrlUtils.paramToObject(search, Object.values(URL_PARAM))

      const ordering = data[URL_PARAM.ORDERING]

      if (page) {
        state.page = page

        if (page === TRAIN_PROJECTS) {
          const trainingProjectOrdering = ordering as TrainProjectOrderingType
          if (TrainProjectOrderingTypeList.includes(trainingProjectOrdering)) {
            state.listProjectParams.ordering = trainingProjectOrdering
          }
        }
        if (page === COLLECTIONS) {
          const myCollectionOrdering = ordering as CollectionOrderingType
          if (CollectionOrderingTypeList.includes(myCollectionOrdering)) {
            state.listCollectionParam.ordering = myCollectionOrdering
          }
        }
      }

      return
    }

    default:
  }
}, initial)

// Epics

const initSearchParamEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.initSearchParam)),
    map(({ payload }) => {
      const page = payload.page
      const reloadActions: { [key in RouteHomeType]: Function } = {
        [TRAIN_PROJECTS]: actions.loadTrainProjects,
        [MIX_PROJECTS]: actions.loadMixImageProjects,
        [SKETCH_PROJECTS]: actions.loadSketchProjects,
        [SKETCHTEXT_TO_IMAGE_PROJECTS]: actions.loadSketchTextProjects,
        [COLLECTIONS]: actions.loadCollectionList,
        [SAVED_RESULTS]: actions.loadSavedResultList,
        [TEXT_TO_IMAGE_PROJECTS]: actions.loadTextToImageProjects,
        [TEXT_TO_VIDEO_PROJECTS]: actions.loadTextToVideoProjects,
        [GENERIC_APP_PROJECTS]: actions.loadAiAppProjects,
        [PRO_FILTER_PROJECTS]: actions.loadProArtFilterProjects,
        [UPSCALES]: actions.loadProArtFilterProjects,
        [DOWNLOADS]: actions.loadDownloadList,
        [NFTS]: actions.loadDownloadList
      }
      return {
        page,
        reloadActions
      }
    }),
    filter(({ page }) => Boolean(page)),
    map(({ page, reloadActions }) => reloadActions[page ?? TRAIN_PROJECTS]())
  )

const getUrlParam = (homePage: HomePageState) => {
  const { page, listProjectParams, listCollectionParam } = homePage
  const param: { [key: string]: string } = {}

  if (page === TRAIN_PROJECTS) {
    param[URL_PARAM.ORDERING] = listProjectParams.ordering
  }
  if (page === COLLECTIONS) {
    param[URL_PARAM.ORDERING] = listCollectionParam.ordering
  }

  return UrlUtils.objectToParam(param || {})
}

const updateUrlEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.projectSetSortBy, actions.collectionSetSortBy])),
    withLatestFrom(state$),
    map(([, state]) => {
      const homePage = selectors.homePage(state)
      const { page } = selectors.homePage(state)

      const openedItemId = selectors.openedItemId(state)

      return {
        id: openedItemId,
        param: getUrlParam(homePage),
        page
      }
    }),
    map(({ param, id, page }) => ({
      param,
      url: getHomeRoute(page, id ? `${id}` : undefined),
      id,
      page
    })),
    tap(({ page, id = '' }) =>
      SessionStorage.save(LSKey.PROJECT_PAGE_SOURCE, getHomeRoute(page, `${id}`))
    ),
    tap(({ param, url }) => UrlUtils.pushState(`${url}?${param}`)),
    ignoreElements()
  )

// If input mounted and project is fetched
const loadTrainProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadTrainProjects)),
    withLatestFrom(state$),
    //Give time for animation to finish to prevent lag
    delay(300),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listProjectParams
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.projects.listResponse)),
        take(1),
        map(response => Boolean(response.payload.data.count)),
        concatMap(hasProject =>
          _compact([
            // actions.refreshActiveProject(),
            actions.setLoaded(TRAIN_PROJECTS),
            !hasProject && actions.loadSketchProjects(),
            !hasProject && actions.loadMixImageProjects(),
            !hasProject && actions.loadProArtFilterProjects()
          ])
        ),
        startWith(apiActions.projects.list({ param: listParams, reloadList: true }))
      )
    )
  )
const openProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.openProject)),
    map(({ payload }) => {
      return BookmarkUtils.splitBookmarkScope(payload.scope)
    }),
    mergeMap(({ id, scope }) =>
      _compact([
        scope === TRAINING_TEXT
          ? appActions.openProject({ id, object_type: 'training_project' })
          : null,
        scope === LATENT_TEXT
          ? appActions.openProject({ id, object_type: 'pretrain_mix_project' })
          : null,
        scope === SKETCH_TEXT
          ? appActions.openProject({ id, object_type: 'sketch_project' })
          : null,
        scope === TEXT_TO_IMAGE_TEXT
          ? appActions.openProject({ id, object_type: 't2i_project' })
          : null,
        scope === SKETCHTEXT_TO_IMAGE_PROJECTS
          ? appActions.openProject({ id, object_type: 'ST2I_project' })
          : null,
        scope === GENERICAPP_TEXT
          ? appActions.openProject({ id, object_type: 'app_project' })
          : null
      ])
    )
  )
const openPageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.openPage)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const homePage = selectors.homePage(state)
      const { page } = homePage
      return {
        id: action.payload.id,
        page: action.payload.page ?? page,
        param: getUrlParam(homePage)
      }
    }),
    map(({ id, page, param }) => {
      let url = ''

      switch (page) {
        case GENERIC_APP_PROJECTS:
          url = route.GENERIC_APP_PROJECTS.getUrl({ id })
          break
        case TRAIN_PROJECTS:
          url = route.TRAIN_PROJECTS.getUrl()
          break
        case MIX_PROJECTS:
          url = route.MIX_PROJECTS.getUrl()
          break
        case SKETCH_PROJECTS:
          url = route.SKETCH_PROJECTS.getUrl()
          break
        case PRO_FILTER_PROJECTS:
          url = route.PRO_FILTER_PROJECTS.getUrl()
          break
        case TEXT_TO_IMAGE_PROJECTS:
          url = route.TEXT_TO_IMAGE_PROJECTS.getUrl({ id })
          break
        case TEXT_TO_VIDEO_PROJECTS:
          url = route.TEXT_TO_VIDEO_PROJECTS.getUrl({ id })
          break
        case SKETCHTEXT_TO_IMAGE_PROJECTS:
          url = route.SKETCHTEXT_TO_IMAGE_PROJECTS.getUrl({ id })
          break
        case COLLECTIONS:
          url = route.COLLECTIONS.getUrl({ id })
          break
        case SAVED_RESULTS:
          url = route.SAVED_RESULTS.getUrl({ id })
          break
        case UPSCALES:
          url = route.UPSCALES.getUrl({ id })
          break
        case DOWNLOADS:
          url = route.DOWNLOADS.getUrl({ id })
          break
        case NFTS:
          url = route.NFTS.getUrl({ id })
          break
      }

      return `${url}?${param}`
    }),
    tap(url => {
      SessionStorage.save(LSKey.PROJECT_PAGE_SOURCE, url)
    }),
    mergeMap(url => [appActions.pushTo(url)])
  )
const loadSketchProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadSketchProjects)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listSketchProjectParams
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.sketchToImage.listSketchProjectResponse)),
        take(1),
        map(() => actions.setLoaded(SKETCH_PROJECTS)),
        startWith(apiActions.sketchToImage.listSketchProject({ param: listParams, next: false }))
      )
    )
  )

const loadSketchTextProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.loadSketchTextProjects)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listSketchTextProjectParams
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.sketchTextToImage.listSTIProjectResponse)),
        take(1),
        map(() => actions.setLoaded(SKETCHTEXT_TO_IMAGE_PROJECTS)),
        startWith(apiActions.sketchTextToImage.listSTIProject({ param: listParams, next: false }))
      )
    )
  )
const loadCollectionListEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.loadCollectionList, actions.collectionSetSortBy])),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listCollectionParam
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.collections.listResponse)),
        take(1),
        map(() => actions.setLoaded(COLLECTIONS)),
        startWith(
          apiActions.collections.list({
            param: listParams,
            reloadList: true,
            listType: HOME_LIST_TYPE
          })
        )
      )
    )
  )

const loadSavedResultListEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.loadSavedResultList)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listSavedImagesParam
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.projects.listUserImageBookmarkResponse)),
        take(1),
        map(() => actions.setLoaded(SAVED_RESULTS)),
        startWith(
          apiActions.projects.listUserImageBookmark({
            param: listParams,
            next: false
          })
        )
      )
    )
  )

const loadDownloadListEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadDownloadList)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listDownloadParam
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.payment.listDownloadImageResponse)),
        take(1),
        map(() => actions.setLoaded(DOWNLOADS)),
        startWith(
          apiActions.payment.listDownloadImage({
            param: listParams,
            reloadList: true
          })
        )
      )
    )
  )
const loadArtmineProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadArtmineProject)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.listArtmineProjectParam(state)
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.artMine.listUserArtmineProjectsResponse)),
        take(1),
        map(() => actions.setLoaded(NFTS)),
        startWith(
          apiActions.artMine.listUserArtmineProjects({
            param: listParams,
            next: false
          })
        )
      )
    )
  )
const loadMixImageProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.loadMixImageProjects)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listMixImageProjectParams
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mixImage.listMixImageResponse)),
        take(1),
        map(() => actions.setLoaded(MIX_PROJECTS)),
        startWith(apiActions.mixImage.listMixImage({ param: listParams, next: false }))
      )
    )
  )

const loadProArtFilterProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.loadProArtFilterProjects)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).listProArtFilterProjectParams
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.proArtFilter.listProArtFilterResponse)),
        take(1),
        map(() => actions.setLoaded(PRO_FILTER_PROJECTS)),
        startWith(apiActions.proArtFilter.listProArtFilter({ param: listParams, next: false }))
      )
    )
  )

const loadTextToImageProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.loadTextToImageProjects)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).textToImageProjectParams
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.textToImage.listTIProjectResponse)),
        take(1),
        map(() => actions.setLoaded(TEXT_TO_IMAGE_PROJECTS)),
        startWith(apiActions.textToImage.listTIProject({ param: listParams, next: false }))
      )
    )
  )

const loadAiAppProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadAiAppProjects)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.homePage(state).textToImageProjectParams // !!!!!!!!!!
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.genericApp.listGenericAppProjectResponse)),
        take(1),
        map(() => actions.setLoaded(GENERIC_APP_PROJECTS)),
        startWith(apiActions.genericApp.listGenericAppProject({ param: listParams, next: false }))
      )
    )
  )

const openItemEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.openItem)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { payload } = action
      const setIdAction: {
        [key in RouteHomeType]?: PayloadActionCreator<string, number | undefined>
      } = {
        [TRAIN_PROJECTS]: apiActions.projects.setCurrentProject,
        [MIX_PROJECTS]: apiActions.mixImage.setCurrentMixProject,
        [SKETCH_PROJECTS]: apiActions.sketchToImage.setCurrentSketchProject,
        [COLLECTIONS]: apiActions.collections.setCurrentCollection
      }
      const page = selectors.homePage(state).page

      return { page, setIdAction, id: payload }
    }),
    mergeMap(({ page, setIdAction, id }) => _compact([setIdAction?.[page]?.(id)]))
  )

const onProjectsUpdatedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.projectSetSortBy)),
    withLatestFrom(state$),
    map(([_, state]) => selectors.homePage(state).listProjectParams),
    map(listParam => apiActions.projects.list({ param: listParam, reloadList: true }))
  )

export const epics = combineEpics(
  loadTextToImageProjectsEpic,
  loadAiAppProjectsEpic,
  loadDownloadListEpic,
  initSearchParamEpic,
  openPageEpic,
  openProjectEpic,
  openItemEpic,
  loadSavedResultListEpic,
  loadCollectionListEpic,
  updateUrlEpic,
  loadTrainProjectsEpic,
  loadSketchProjectsEpic,
  loadSketchTextProjectsEpic,
  onProjectsUpdatedEpic,
  loadArtmineProjectEpic,
  loadMixImageProjectsEpic,
  loadProArtFilterProjectsEpic
)
export default reducer
