import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import Button from 'components/Button'
import { CircularLoading } from 'components/Loading/CircularLoading'
import { RootState, RootActionType } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import { GeneralDialogItemProps } from '..'
import DialogWrapper from '../DialogWrapper'
import styles from '../Dialogs.module.scss'
import { changeEmailActions } from 'duck/AppDuck/ChangeEmailDuck'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  changeEmailLoading: appSelectors.changeEmail.isLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      resubmitChangeEmailRequest: changeEmailActions.resubmitChangeEmailRequest
    },
    dispatch
  )

type DialogSentCurrentEmailHelpProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
> &
  GeneralDialogItemProps

const DialogSentCurrentEmailHelp: React.FC<DialogSentCurrentEmailHelpProps> = props => {
  const { onClose, resubmitChangeEmailRequest, activeDialog, changeEmailLoading } = props

  return (
    <DialogWrapper
      dialogConfig={{
        className: styles.EmailDialog
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">Having trouble?</Typography>}
      actions={
        <Fragment>
          <Button
            disabled={changeEmailLoading}
            color="secondary"
            onClick={() => resubmitChangeEmailRequest()}
          >
            Resend Email
          </Button>
          <Button disabled={changeEmailLoading} color="secondary" onClick={onClose}>
            OK
          </Button>
        </Fragment>
      }
      content={
        <Fragment>
          {changeEmailLoading ? (
            <CircularLoading className={styles.LoadingStyle} loading={true} />
          ) : (
            <Fragment>
              <div className={clsx(styles.ContentGroup, styles.DottedList, styles.CompactList)}>
                <span>
                  <Typography variant="body2">Common fixes:</Typography>
                </span>
                <ul>
                  <li>
                    <Typography variant="body2">
                      Checking if the email was marked as spam or filtered.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">Check your internet connection.</Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Check that you did not misspell your email.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Check that your inbox space is not running out or other inbox issues
                    </Typography>
                  </li>
                </ul>
              </div>
              <div className={styles.ContentGroup}>
                <Typography variant="body2">
                  If these steps didn’t work, you can resend the email. Doing so will deactivate the
                  link in the older email.
                </Typography>
              </div>
            </Fragment>
          )}
        </Fragment>
      }
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogSentCurrentEmailHelp)
