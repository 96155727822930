/*
 * Based on FileSaver.js , Promise Implementation by : Adnan HP
 * Promise Implementation by : Adnan Hidayat P, Artrendex Inc
 * A saveAs() FileSaver implementation.
 *
 * By Eli Grey, http://eligrey.com
 *
 * License : https://github.com/eligrey/FileSaver.js/blob/master/LICENSE.md (MIT)
 * source  : http://purl.eligrey.com/github/FileSaver.js
 */

interface FileSaverOptions {
  autoBom: boolean
}

type Status = {
  success: boolean
}

export type SaveAsParam = {
  data?: File
  url?: string
  filename?: string
  options?: FileSaverOptions
  onProgress?: (percent: number) => void
}

type SaveAsFunctionParam = SaveAsParam & {
  resolve: (value: Status | PromiseLike<Status>) => void
  reject: (reason: string) => void
}

type DownloadParam = Omit<SaveAsFunctionParam, 'data'>
type CorsEnabledParam = Pick<SaveAsFunctionParam, 'url' | 'onProgress'> & {
  onEnable: () => void
  onDisable: () => void
}

const Utils = {
  download: (param: DownloadParam) => {
    const { url = '', filename, options, resolve, reject, onProgress } = param
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      saveAsFunction({ data: xhr.response, filename, options, resolve, reject })
    }

    xhr.onloadstart = param => {
      onProgress?.(0)
    }
    xhr.onprogress = param => {
      onProgress?.(Math.round((param.loaded / param.total) * 100))
    }
    xhr.onloadend = param => {
      onProgress?.(100)
    }

    xhr.onerror = function () {
      reject('Could not download file')
    }

    xhr.send()
  },
  corsEnabled: (param: CorsEnabledParam) => {
    const { url = '', onProgress, onEnable, onDisable } = param
    var xhr = new XMLHttpRequest()

    xhr.open('GET', url, true)

    xhr.onloadstart = param => {
      onProgress?.(0)
    }
    xhr.onprogress = param => {
      onProgress?.(Math.round((param.loaded / param.total) * 100))
    }
    xhr.onloadend = param => {
      onProgress?.(100)
    }

    xhr.onload = function () {
      var isCorsEnable = xhr.status >= 200 && xhr.status <= 299
      isCorsEnable ? onEnable() : onDisable()
    }

    try {
      xhr.send()
    } catch (e) {}

    return xhr.status >= 200 && xhr.status <= 299
  },
  click: (node: HTMLAnchorElement) => {
    try {
      node.dispatchEvent(new MouseEvent('click'))
    } catch (e) {
      const evt = document.createEvent('MouseEvents')
      node.dispatchEvent(evt)
    }
  }
}

const saveAsFunctions = {
  saveAsDownload: (param: SaveAsFunctionParam) => {
    const { data, url, filename, options, reject, resolve, onProgress } = param
    var URL = window.URL || window.webkitURL
    var a = document.createElement('a')
    const name = filename || data?.name || 'download'
    a.download = name
    a.rel = 'noopener' // tabnabbing

    if (url) {
      a.href = url

      if (a.origin !== window.location.origin) {
        Utils.corsEnabled({
          onProgress,
          url: a.href,
          onEnable: function () {
            Utils.download({ url, filename: name, options, reject, resolve })
          },
          onDisable: function () {
            Utils.click(a)
            resolve({ success: true })
          }
        })
      } else {
        resolve({ success: true })
        Utils.click(a)
      }
    }
    if (data) {
      // Support blobs
      a.href = URL.createObjectURL(data)
      setTimeout(function () {
        URL.revokeObjectURL(a.href)
      }, 4e4) // 40s

      setTimeout(function () {
        resolve({ success: true })
        Utils.click(a)
      }, 0)
    }
  },
  saveAsFallBack: (param: SaveAsFunctionParam) => {
    const { data, url, filename, options, reject, resolve } = param
    const name = filename || data?.name || 'download'
    let popup = window.open('', '_blank')

    if (popup) {
      popup.document.title = popup.document.body.innerText = 'downloading...'
    }

    if (url) return Utils.download({ url, filename: name, options, reject, resolve })

    if (data) {
      const force = data?.type === 'application/octet-stream'
      const isSafari = /constructor/i.test(window.HTMLElement.toString())
      const isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent)

      if ((isChromeIOS || (force && isSafari)) && typeof FileReader !== 'undefined') {
        // Safari doesn't allow downloading of blob URLs
        const reader = new FileReader()

        reader.onloadend = function () {
          let url = reader.result ?? ''
          url = isChromeIOS ? url : (url as string).replace(/^data:[^;]*;/, 'data:attachment/file;')
          if (popup) {
            popup.location.href = url.toString()
          } else window.location = url as unknown as Location
          popup = null // reverse-tabnabbing #460

          resolve({ success: true })
        }

        reader.readAsDataURL(data)
      } else {
        const URL = window.URL || window.webkitURL
        const url = URL.createObjectURL(data)
        if (popup) popup.location = url as unknown as Location
        else window.location.href = url
        popup = null // reverse-tabnabbing #460
        resolve({ success: true })

        setTimeout(function () {
          URL.revokeObjectURL(url)
        }, 4e4) // 40s
      }
    }
  }
}

export const saveAsFunction =
  'download' in HTMLAnchorElement.prototype
    ? saveAsFunctions['saveAsDownload']
    : saveAsFunctions['saveAsFallBack']

export const saveAs = (param: SaveAsParam) => {
  return new Promise<Status>((resolve, reject) => {
    saveAsFunction({ ...param, resolve, reject })
  })
}
