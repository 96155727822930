import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import _startsWith from 'lodash/startsWith'
import _find from 'lodash/find'
import { connect } from 'react-redux'
import { LoadingPage } from 'components/Loading'
import { appSelectors } from 'duck/AppDuck'
import { RootState } from 'duck'
import { route, routeUtils } from 'routes'
import { LSKey } from 'appConstants'
import { firebaseSelectors } from 'duck/FirebaseDuck'
import { LocalStorage } from 'utils'
import { apiSelectors } from 'duck/ApiDuck'
import { useExecuteOnChange, useIsWelcomePage } from 'utils/Hooks'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  isUserLoading: apiSelectors.loading['users.retrieve'](state),
  authorized: appSelectors.isAuthorized(state),
  routerLocation: appSelectors.routerLocation(state),
  isAfterLogout: firebaseSelectors.isAfterLogout(state),
  user: apiSelectors.user(state)
})

type ProtectedRouteProps = ReduxProps<typeof mapStateToProps> & { page: JSX.Element }

const routeArray = routeUtils.getRoutesArray()

const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const { authorized, page, user, isUserLoading, routerLocation, isAfterLogout } = props

  const navigate = useNavigate()
  const isWelcomePage = useIsWelcomePage()
  const isUserDataIncomplete = authorized && user && !Boolean(user?.is_agree_toc) && !isWelcomePage
  const isUserUnauthorized = !authorized
  const pathname = routerLocation?.pathname
  const search = routerLocation?.search
  const onAuthorized = useCallback(() => {
    const isPartOfRoute = Boolean(_find(routeArray, value => _startsWith(pathname, value)))

    if (!authorized && !isAfterLogout) {
      isPartOfRoute && LocalStorage.save(LSKey.REDIRECT_AFTER_LOGIN, `${pathname}${search}`)
    } else {
      LocalStorage.remove(LSKey.REDIRECT_AFTER_LOGIN)
    }
  }, [authorized, isAfterLogout, pathname, search])

  useExecuteOnChange({
    executor: onAuthorized,
    executeOn: authorized
  })

  useEffect(() => {
    if (isUserDataIncomplete) navigate(route.WELCOME.getUrl())
  }, [isUserDataIncomplete, navigate])

  useEffect(() => {
    if (isUserUnauthorized) navigate(route.SIGN_IN.getUrl())
  }, [isUserUnauthorized, navigate])

  if (isUserLoading !== false) return <LoadingPage delay={1} />

  if (!authorized) return null

  return page
}

export default connect(mapStateToProps)(ProtectedRoute)
