import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import Button from 'components/Button'
import CardPanel from '../components/CardPanel'
import { RootState } from 'duck'
import { apiSelectors } from 'duck/ApiDuck/selectors'
import { ReduxProps } from 'utils/Types'
import { BraintreePaymentMethod } from 'models/ApiModels'
import { useBreakpoint } from 'utils/Hooks'
import { values } from 'appConstants'

const GRID_SPACINGS = {
  xs: 1.5,
  sm: 3
}

const mapStateToProps = (state: RootState) => ({
  brainTreePaymentMethod: apiSelectors.brainTreePaymentMethod(state),
  channelData: apiSelectors.channelData(state)
})

type ChangePaymentMethodProps = ReduxProps<typeof mapStateToProps> & {
  selectedMethod: BraintreePaymentMethod['type']
  setSelectedMethod: (param: BraintreePaymentMethod['type']) => void
}

const ChangePaymentMethod: React.FC<ChangePaymentMethodProps> = ({
  selectedMethod,
  brainTreePaymentMethod,
  channelData,
  setSelectedMethod
}) => {
  const { isSmallscreenAbove } = useBreakpoint()

  const isPaypalActive = channelData[values.PAYPAL_CHANNEL_NAME]?.is_active
  const isCardActive = channelData[values.BRAINTREE_CHANNEL_NAME]?.is_active

  const paymentMethodType = brainTreePaymentMethod?.type

  useEffect(() => {
    if (paymentMethodType) {
      setSelectedMethod(paymentMethodType)
    }
  }, [paymentMethodType, setSelectedMethod])

  return (
    <>
      <Grid spacing={GRID_SPACINGS} container>
        {isCardActive ? (
          <Grid xs={6} item>
            <Button
              onClick={() => setSelectedMethod('creditcard')}
              color={selectedMethod === 'creditcard' ? 'secondary' : 'light'}
              variant="outlined"
              size={!isSmallscreenAbove ? 'small' : undefined}
              fullWidth
            >
              <Typography variant={isSmallscreenAbove ? 'button' : 'overline'}>Card</Typography>
            </Button>
          </Grid>
        ) : null}
        {isPaypalActive ? (
          <Grid xs={6} item>
            <Button
              onClick={() => setSelectedMethod('paypal')}
              color={selectedMethod === 'paypal' ? 'secondary' : 'light'}
              variant="outlined"
              size={!isSmallscreenAbove ? 'small' : undefined}
              fullWidth
            >
              <Typography variant={isSmallscreenAbove ? 'button' : 'overline'}>Paypal</Typography>
            </Button>
          </Grid>
        ) : null}
      </Grid>

      <CardPanel className="mt-6" hide={selectedMethod !== 'creditcard'} disableChangeCard={true} />
    </>
  )
}

export default connect(mapStateToProps)(ChangePaymentMethod)
