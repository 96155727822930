import { SelectBaseItemType } from 'components/Select/SelectBase'
import { Clip, GenerateClipReq, UserVideo } from 'models/ApiModels'

export const MAXIMUM_SHOWING_TAB = 25
export const REFRESH_CLIP_INTERVAL = 5000

export type ClipVideoOutput = {
  key: string
  name: string
  numFrame: number
  length: string
  date: string
  clip: Clip
  video: UserVideo
  outputIndex: number
  unixDate: number
}

export const RENDER_VIDEO_FORM_OPTIONS: {
  resolution: SelectBaseItemType<UserVideo['resolution']>[]
  fps: SelectBaseItemType[]
  mode: SelectBaseItemType<GenerateClipReq['mode']>[]
  smooth: { min: number; max: number; initial: number; step: number }
} = {
  mode: [
    {
      label: 'Stretch',
      value: 'stretch'
    },
    {
      label: 'Fit',
      value: 'fit'
    },
    {
      label: 'Crop',
      value: 'crop'
    }
  ],
  resolution: [
    {
      label: 'HD 1280×720',
      value: '1280×720'
    },
    {
      label: 'Full HD 1920×1080',
      value: '1920×1080'
    },
    {
      label: '2K 2560×1440',
      value: '2560×1440'
    },
    {
      label: 'UHD 3840×2160',
      value: '3840×2160'
    }
  ],
  fps: [
    {
      label: '30 FPS',
      value: '30'
    }
  ],
  smooth: { min: 0, max: 10, initial: 0, step: 1 }
}
