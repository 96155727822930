import { UserImage, AnchorType, ControlInfluence, BookmarkCreateReq } from 'models/ApiModels'
import _keys from 'lodash/keys'
import _reduce from 'lodash/reduce'
import { ShareData } from 'containers/ShareFeedPanel/duck'
import { SelectBaseItemType } from 'components/Select/SelectBase'

export type EditType = Pick<AnchorType, 'labels' | 'extra_attributes' | 'previewId'>

export const MixPanels = {
  MIX: 'mix',
  EXPLORE: 'explore',
  VIDEO: 'video',
  UPLOAD: 'upload',
  RANDOM: 'random',
  SAVED: 'saved',
  SKETCH: 'sketch',
  TRAIN_SAVED: 'train_saved',
  TRAIN_RANDOM: 'train_random',
  TRAIN_RESULT: 'train_result'
} as const

export const MixPageTabList = [
  MixPanels.EXPLORE,
  MixPanels.MIX,
  MixPanels.VIDEO,
  MixPanels.UPLOAD,
  MixPanels.RANDOM,
  MixPanels.SAVED,
  MixPanels.SKETCH,
  MixPanels.TRAIN_SAVED,
  MixPanels.TRAIN_RANDOM,
  MixPanels.TRAIN_RESULT
] as const

export const MixPageTabWithPanelEdit: string[] = [
  MixPanels.UPLOAD,
  MixPanels.RANDOM,
  MixPanels.SAVED,
  MixPanels.SKETCH
]
export const MixPageTabPanel: string[] = [
  MixPanels.UPLOAD,
  MixPanels.RANDOM,
  MixPanels.SAVED,
  MixPanels.SKETCH,
  MixPanels.TRAIN_SAVED,
  MixPanels.TRAIN_RANDOM,
  MixPanels.TRAIN_RESULT
]
export type MixPageTabType = (typeof MixPageTabList)[number]

export const UpscalePageSubrouteList = ['edit'] as const

export type UpscalePageSubrouteType = (typeof UpscalePageSubrouteList)[number]

export const MixPageWithoutPaidDownload: MixPageTabType[] = [MixPanels.UPLOAD, MixPanels.RANDOM]

export const MixSidebarTabList = ['preview', 'saved'] as const
export type MixSidebarTabType = (typeof MixSidebarTabList)[number]

export const PanelList = [
  MixPanels.EXPLORE,
  MixPanels.UPLOAD,
  MixPanels.RANDOM,
  MixPanels.SAVED,
  MixPanels.SKETCH,
  MixPanels.TRAIN_SAVED,
  MixPanels.TRAIN_RANDOM,
  MixPanels.TRAIN_RESULT
] as const

export type PanelType = (typeof PanelList)[number]

export const PanelWithoutPaidDownload: PanelType[] = [MixPanels.UPLOAD, MixPanels.RANDOM]

export type MixPageTabDataType = {
  [key in MixPageTabType]?: {
    item?: UserImage
    bookmark?: number | boolean
    currentEdit?: EditType
    sketchOutputId?: number
    uploadImageId?: number
  }
}

/* Block For Mix Image */
export const GRID_COUNT = 25
export const GRID_ARRAY = Array.from(Array(GRID_COUNT))
export const ANCHOR_PATTERN: { [key: number]: number[] } = {
  1: [12],
  2: [4, 20],
  3: [4, 20, 0],
  4: [4, 20, 0, 24]
}
export const MAX_ANCHOR = _keys(ANCHOR_PATTERN).length

export const INITIAL_CONTROL: ControlInfluence = {
  style_weight: 1,
  content_weight: 1
}
export const INITIAL_EXTRA_ATTRIBUTES = {
  Intensity: 1
}
export const INITIAL_EXTRA_ATTRIBUTE_LABELS = _reduce(
  INITIAL_EXTRA_ATTRIBUTES,
  (result, value, key) => ({ ...result, [key]: value }),
  {}
)

export const INITIAL_ANCHOR: AnchorType = {
  id: 0,
  controls: { ...INITIAL_CONTROL },
  extra_attributes: {},
  labels: {}
}

export type BookmarkDataType = {
  isBookmarkAvailable: boolean
  bookmarkRequest: BookmarkCreateReq<'user-image'>
  bookmark?: number | boolean | null
  isBookmarked?: boolean
}

export type ShareMixData = Pick<ShareData, 'related' | 'entity'>

export type GridImage = {
  index: number
  image?: UserImage
  outputImage?: UserImage
  edit?: EditType
  isAnchor: boolean
  anchorIndex: number
  imageId?: number
}

export type DragItemType = 'OutputImage' | 'Anchor' | 'SavedImage'
export const ACCEPTED_DRAG_TYPE: DragItemType[] = ['OutputImage', 'Anchor', 'SavedImage']

export type DragItemData = {
  data?: GridImage | null
  type: DragItemType
}

export type CreateNewMixParam = {
  anchor: Partial<AnchorType>
  projectId?: number
}

export type SaveGeneratedAnchorEditPreviewParam = {
  anchorIndex: number
  image: UserImage | undefined
}

export type UpdateInfluenceParam = {
  index: number
  key: keyof ControlInfluence
  value: number
}

export type ApplyEditData = Pick<AnchorType, 'extra_attributes' | 'labels' | 'previewId'>

export type UpdateEditParam = {
  isReplace?: boolean
  imageId: number
  isAnchor?: boolean
} & ApplyEditData

export type SetEditPreviewParam = { previewImageId: number | undefined; imageId: number }

/* Extra Attribute */
export type ExtraAttributesMode = 'panels' | 'mix-image'

export type ExtraAttributeConfigType = {
  MIN: number
  MAX: number
  STEP: number
  DEFAULT: number
  DECIMAL: number
  OPTIONS?: SelectBaseItemType[]
  LABEL?: string //Optional label to replace label from backend
  HIDE_ON_EDIT_PANEL?: boolean
  HIDE?: boolean
  MULTIPLY_ON_SUBMIT_VALUE: number
}
export type ExtraAttributeConfigTypes = {
  [key: string]: ExtraAttributeConfigType
}

export type ExtraAttributeSectionConfigType = {
  HIDE?: boolean
}

/* Panels */

export type UpdateResultParam = {
  imageId: number
  result: UserImage
}

export type ApplyEditImageParam = {
  imageId: number
} & ApplyEditData

export type SketchViewType = '' | 'styles' | 'sketch' | 'result'
