import React, { useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import ListComponent from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized-auto-sizer'
import _throttle from 'lodash/throttle'
import { ListConfigType } from '../../index'
import { FixedSizeListProps } from '../index'
import { CircularLoading } from 'components/Loading'
import List from './List'
import styles from '../../ListVirtualized.module.scss'

function FixedSizeListWindow<Item>(
  props: Omit<FixedSizeListProps<Item>, 'autoHeight' | 'calculateCellHeight' | 'config'> & {
    config: ListConfigType
    setRef?: React.MutableRefObject<ListComponent | null>
  }
) {
  const {
    classes,
    className,
    config,
    containerRef,
    debug,
    dataLength,
    loading,
    loadingPosition = 'absolute',
    loadingSize,

    // noRowsRenderer,
    onEndOfGrid,
    onScroll,
    renderItem,
    // overscanRowCount
    setRef
  } = props
  const listContainerRef = useRef<HTMLDivElement | null>(null)
  const listContainerRefAdjusted = containerRef || listContainerRef
  const listRef = useRef<ListComponent | null>(null)
  const listRefAdjusted = setRef || listRef

  const onBottomThrottle = useMemo(() => {
    if (!onEndOfGrid) return undefined

    return _throttle(() => {
      onEndOfGrid()
    }, 800)
  }, [onEndOfGrid])

  // Added to solve load data when grid height smaller than parent height.
  // No need to scroll to load new data
  useEffect(() => {
    const listContainerHeight =
      listContainerRefAdjusted?.current?.children?.[0]?.children?.[0]?.children?.[0]
        ?.clientHeight || 0
    const scrollContainerHeight =
      listContainerRefAdjusted?.current?.children?.[0]?.children?.[0]?.clientHeight || 0

    if (
      onEndOfGrid &&
      !loading &&
      listContainerHeight &&
      scrollContainerHeight &&
      listContainerHeight <= scrollContainerHeight
    ) {
      onEndOfGrid()
    }
  }, [loading, onEndOfGrid, listContainerRefAdjusted])

  return (
    <div
      ref={listContainerRefAdjusted}
      className={twMerge('h-full w-full overflow-hidden', className)}
    >
      <AutoSizer>
        {(props: { width?: number; height?: number }) => {
          const width = props.width ?? 0
          const height = props.height ?? 0

          if (!dataLength || width <= 0) return null

          return (
            <List
              containerRef={listContainerRefAdjusted}
              debug={debug}
              setRef={listRefAdjusted}
              config={config}
              dataLength={dataLength}
              height={height}
              // noRowsRenderer={noRowsRenderer}
              onBottom={onBottomThrottle}
              onScroll={onScroll}
              renderItem={renderItem}
              width={width}
            />
          )
        }}
      </AutoSizer>

      {loading ? (
        <div
          className={clsx(
            styles.LoadingContainer,
            loadingPosition === 'fixed' && styles.LoadingContainerFixed,
            loadingPosition === 'fixed' && classes?.loadingFixed
          )}
        >
          <CircularLoading size={loadingSize} loading disableShrink />
        </div>
      ) : null}
    </div>
  )
}

export default FixedSizeListWindow
