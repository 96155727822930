import _map from 'lodash/map'
import { RootState } from 'duck'
import { createSelector } from 'reselect'
import _compact from 'lodash/compact'
import { projects, collections, user } from './CommonSelectors'

const currentPostId = (state: RootState) => {
  return state.api.social.currentPostId ?? 0
}

const posts = (state: RootState) => {
  return state.api.social.posts ?? {}
}
const threads = (state: RootState) => {
  return state.api.social.threads ?? {}
}
const comments = (state: RootState) => {
  return state.api.social.comments ?? {}
}
const commentLists = (state: RootState) => {
  return state.api.social.commentLists ?? {}
}
const threadLists = (state: RootState) => {
  return state.api.social.threadLists ?? {}
}
const profileLists = (state: RootState) => {
  return state.api.social.profileLists ?? {}
}
const currentUserProfile = (state: RootState) => {
  return state.api.social.currentUserProfile ?? ''
}

const currentUserProfileList = createSelector(
  profileLists,
  currentUserProfile,
  (profileLists, currentUserProfile) =>
    currentUserProfile ? profileLists[currentUserProfile] : undefined
)

const currentUserProfileUserData = createSelector(
  currentUserProfileList,
  user,
  (currentUserProfileList, user) => {
    return currentUserProfileList?.userData.id === user?.id && user
      ? user
      : currentUserProfileList?.userData
  }
)
const currentUserProfileListProjects = createSelector(
  currentUserProfileList,
  projects,
  (currentUserProfileList, projects) =>
    currentUserProfileList?.projectList?.map(value => projects[value]) ?? []
)

const currentUserProfileListCollections = createSelector(
  currentUserProfileList,
  collections,
  (currentUserProfileList, collections) =>
    currentUserProfileList?.collectionList?.map(value => collections[value]) ?? []
)

const currentUserProfileListPosts = createSelector(
  currentUserProfileList,
  posts,
  (currentUserProfileList, posts) =>
    currentUserProfileList?.postList?.map(value => posts[value]) ?? []
)

const bannerImages = (state: RootState) => {
  return state.api.social.bannerImages
}

const currentThreads = createSelector(
  currentPostId,
  threads,
  comments,
  threadLists,
  commentLists,
  (currentPostId, threads, comments, threadLists, commentLists) => {
    const threadListData = threadLists[currentPostId]
    const threadList = threadListData?.lists ?? []

    return threadList.map(threadId => {
      const thread = threads[threadId]
      const commentListData = commentLists[threadId]
      const commentListId = commentListData?.lists ?? []
      const threadComments = commentListId.map(commentId => comments[commentId])
      const hasMoreComment = Boolean(commentListData?.lastRequest?.next)
      const commentList = threadComments.length ? threadComments : thread.comments
      const commentListWithReplyTo = commentList.map(comment => {
        if (comment.reply_to) {
          return { ...comment, replyToData: comments[comment.reply_to] }
        } else {
          return comment
        }
      })

      return {
        ...thread,
        hasMoreComment,
        comments: commentListWithReplyTo
      }
    })
  }
)

const currentPost = createSelector(currentPostId, posts, (currentPostId, posts) => {
  const postData = posts[currentPostId]
  return postData
})

const postListId = (state: RootState) => {
  return state.api.social.postList || []
}

const postList = createSelector(posts, postListId, (posts, postListId) =>
  _compact(postListId.map(postId => posts[postId]))
)

const featuredPostListId = (state: RootState) => {
  return state.api.social.featuredPostList
}

const featuredPostList = createSelector(posts, featuredPostListId, (posts, postListId) =>
  _compact(_map(postListId, postId => posts[postId])).sort((post1, post2) => {
    const created1 = new Date(post1.created)
    const created2 = new Date(post2.created)
    return created2.getTime() - created1.getTime()
  })
)

const socialSelectors = {
  currentUserProfile,
  currentUserProfileListProjects,
  currentUserProfileListCollections,
  currentUserProfileListPosts,
  currentUserProfileUserData,
  currentPostId,
  threads,
  comments,
  featuredPostListId,
  posts,
  bannerImages,
  postList,
  featuredPostList,
  currentThreads,
  currentPost
}
export default socialSelectors
