import {
  catchError,
  filter,
  switchMap,
  mergeMap,
  withLatestFrom,
  map,
  concatMap,
  tap
} from 'rxjs/operators'

import { ApiUtils } from 'utils'
import _pick from 'lodash/pick'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'

import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { push } from 'redux-first-history'
import { route } from 'routes'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { ProArtFilterOutputListReq } from 'models/ApiModels'
import { bannerActions } from 'duck/AppDuck/BannerDuck'

const listProArtFilterGenresEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.listProArtFilterGenres)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.listProArtFilterGenres(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.listProArtFilterGenresResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listProArtFilterOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.listProArtFilterOutput)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, loadingMessage } = action.payload
      const projectId = param?.project || 0
      const params = next
        ? { next: state.api.proArtFilter.outputLists[projectId]?.lastListReq?.next }
        : {}
      const extraParams = next ? undefined : (param as ProArtFilterOutputListReq)
      return { next, params, extraParams, type: action.type, param, loadingMessage }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type, param, loadingMessage }) =>
      merge(
        of(sharedActions.setLoading({ loading: loadingMessage ?? true, type })),
        ApiUtils.proArtFilter.listProArtFilterOutput(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.listProArtFilterOutputResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )
const listProArtFilterStylesEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.listProArtFilterStyles)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.listProArtFilterStyles(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.listProArtFilterStylesResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listProArtFilterEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.listProArtFilter)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const params = next
        ? { next: state.api.proArtFilter.proArtFilterProjects.lastReq?.next }
        : param
      return { next, params, type: action.type }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.listProArtFilter(params).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.listProArtFilterResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params } }))
          )
        )
      )
    )
  )
const createProArtFilterEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.createProArtFilter)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.createProArtFilter(payload).pipe(
          concatMap(response => [
            apiActions.proArtFilter.createProArtFilterResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveProArtFilterEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.retrieveProArtFilter)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.retrieveProArtFilter(payload).pipe(
          mergeMap(response => [
            apiActions.proArtFilter.retrieveProArtFilterResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateProArtFilterEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.updateProArtFilter)),
    switchMap(({ type, payload: { silentUpdate, ...formData } }) =>
      merge(
        of(sharedActions.setLoading({ loading: silentUpdate ? false : true, type })),
        ApiUtils.proArtFilter.updateProArtFilter(formData.id, formData).pipe(
          withLatestFrom(state$),
          tap(([response, state]) => {
            const proArtFilterGenreData = apiSelectors.proArtFilterGenreData(state)
            if (formData.name) {
              MixPanelUtils.track<'PROJECT__RENAME'>(
                'Project - Rename',
                DataUtils.getProjectParam<'transfer_project'>('transfer_project', {
                  proArtFilterProject: response.data,
                  proArtFilterGenreData
                })
              )
            }
          }),
          concatMap(([response]) => [
            apiActions.proArtFilter.updateProArtFilterResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: formData }))
          )
        )
      )
    )
  )

const uploadProArtFilterContentEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.uploadProArtFilterContent)),
    filter(action => Boolean(action.payload.content)),
    map(action => {
      const formData = new FormData()
      formData.append('content', action.payload.content)
      return { formData, action, payload: action.payload }
    }),
    mergeMap(({ formData, action, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.proArtFilter.uploadProArtFilterContent(payload.id, formData).pipe(
          mergeMap(response => [
            apiActions.proArtFilter.uploadProArtFilterContentResponse(response.data),
            sharedActions.setLoading({ loading: false, type: action.type })
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: {
                  ...payload,
                  file: _pick(payload.content, 'name', 'lastModified', 'size', 'type', 'slice')
                }
              })
            )
          )
        )
      )
    )
  )

const deleteProArtFilterImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.deleteProArtFilter)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: { ...(apiSelectors.proArtFilterProjects(state)?.[action.payload] ?? {}) },
      proArtFilterGenreData: apiSelectors.proArtFilterGenreData(state),
      ...action
    })),
    switchMap(({ type, payload, projectData, proArtFilterGenreData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.deleteProArtFilter(payload).pipe(
          tap(() => {
            MixPanelUtils.track<'PROJECT__DELETE'>(
              'Project - Delete',
              DataUtils.getProjectParam<'transfer_project'>('transfer_project', {
                proArtFilterProject: projectData,
                proArtFilterGenreData
              })
            )
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.deleteProArtFilterResponse(payload),
            apiActions.users.retrieveEquity(),
            push(route.PRO_FILTER_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const generateProArtFilterEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.generateProArtFilter)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      canTrain: apiSelectors.equity(state)?.upsellState?.canTrain ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrain }) => !canTrain),
          map(() =>
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'style-transfer',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            })
          )
        ),
        of(param).pipe(
          filter(({ canTrain }) => Boolean(canTrain)),
          map(({ payload, type }) => {
            const { style, custom_style = '', mask, style_weight, id, content_weight } = payload

            const formData = new FormData()
            formData.append('style_weight', `${style_weight}`)
            formData.append('content_weight', `${content_weight}`)

            if (mask && typeof mask === 'number') {
              formData.append('mask', `${mask}`)
            }
            if (mask && typeof mask !== 'number') {
              formData.append('mask', mask)
            }

            if (style) {
              formData.append('style', `${style}`)
            }
            if (custom_style) {
              const data = typeof custom_style === 'number' ? `${custom_style}` : custom_style
              formData.append('custom_style', data)
            }

            return { formData, type, id, payload }
          }),
          switchMap(({ type, formData, id, payload }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.proArtFilter.generateProArtFilter(id, formData).pipe(
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.proArtFilter.generateProArtFilterResponse(response.data)
                ]),
                catchError(err =>
                  of(sharedActions.setError({ error: err.response, type, req: payload }))
                )
              )
            )
          )
        )
      )
    )
  )

const deleteProArtFilterOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.deleteProArtFilterOutput)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: { ...(apiSelectors.proArtFilterProjects(state)?.[action.payload] ?? {}) },
      proArtFilterGenreData: apiSelectors.proArtFilterGenreData(state),
      ...action
    })),
    switchMap(({ type, payload, projectData, proArtFilterGenreData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.deleteProArtFilterOutput(payload).pipe(
          tap(() => {
            MixPanelUtils.track<'PROJECT__PROARTFILTER_DO_ACTION'>(
              'Project ProArtFilter - Do Action',
              {
                ...DataUtils.getProjectParam<'transfer_project'>('transfer_project', {
                  proArtFilterProject: projectData,
                  proArtFilterGenreData
                }),
                do_action_type: 'delete_result',
                do_action_value: payload
              }
            )
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.deleteProArtFilterOutputResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const cleanOutputsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.cleanOutputs)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: { ...(apiSelectors.proArtFilterProjects(state)?.[action.payload] ?? {}) },
      proArtFilterGenreData: apiSelectors.proArtFilterGenreData(state),
      ...action
    })),
    switchMap(({ type, payload, projectData, proArtFilterGenreData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.cleanOutputs(payload).pipe(
          tap(() => {
            MixPanelUtils.track<'PROJECT__PROARTFILTER_DO_ACTION'>(
              'Project ProArtFilter - Do Action',
              {
                ...DataUtils.getProjectParam<'transfer_project'>('transfer_project', {
                  proArtFilterProject: projectData,
                  proArtFilterGenreData
                }),
                do_action_type: 'clear_style_transfer_output'
              }
            )
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.cleanOutputsResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listProArtFilterBannerEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.listProArtFilterBanner)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.proArtFilter.listProArtFilterBanner().pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.proArtFilter.listProArtFilterBannerResponse({
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const proArtFilterEpic = [
  cleanOutputsEpic,
  deleteProArtFilterOutputEpic,
  listProArtFilterOutputEpic,
  listProArtFilterGenresEpic,
  createProArtFilterEpic,
  uploadProArtFilterContentEpic,
  updateProArtFilterEpic,
  retrieveProArtFilterEpic,
  listProArtFilterStylesEpic,
  listProArtFilterEpic,
  deleteProArtFilterImageEpic,
  generateProArtFilterEpic,
  listProArtFilterBannerEpic
]

export default proArtFilterEpic
