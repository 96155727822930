/**
 * Asynchronously loads the component for legacy Mix Image Page
 */

import React from 'react'
import loadable from 'utils/Loadable'
import { LoadingPage } from 'components/Loading'

export default loadable(() => import(/* webpackChunkName: "MixImagePage" */ './index'), {
  fallback: <LoadingPage />
})
