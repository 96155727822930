/*
 * Image component on steroid
 *  - Handle empty with grey image
 *  - Display File object
 */

import React, {
  CSSProperties,
  ElementType,
  memo,
  useState,
  useEffect,
  SyntheticEvent,
  Ref,
  MouseEventHandler
} from 'react'
import { twMerge } from 'tailwind-merge'
import { ImageType } from 'models/ApiModels'
import styles from './Img.module.scss'

export type ImgProps = {
  // Accept 3 type of src ordered by priority
  image?: ImageType
  file?: File
  src?: string

  alt?: string
  backgroundImage?: boolean
  classes?: {
    background?: string
    backgroundContainer?: string
    empty?: string
    image?: string
  }
  className?: string
  component?: 'span'
  crossOrigin?: '' | 'anonymous' | 'use-credentials' | undefined
  onClick?: MouseEventHandler
  onContextMenu?: MouseEventHandler
  loading?: 'lazy' | 'eager'
  fullHeight?: boolean
  fullSize?: boolean
  fullWidth?: boolean
  objectFit?: 'cover' | 'contain' | 'scale-down' | false
  onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void
  onError?: (event: SyntheticEvent<HTMLImageElement, Event>) => void
  style?: CSSProperties
  title?: string
}

type DivImageProps = Pick<
  ImgProps,
  | 'classes'
  | 'className'
  | 'objectFit'
  | 'onLoad'
  | 'onClick'
  | 'onContextMenu'
  | 'src'
  | 'style'
  | 'title'
> & {
  component: 'div' | 'span'
}

const Utils = {
  getObjectTypeClassName: (objectFit?: ImgProps['objectFit'], type?: 'img' | 'div') => {
    if (type === 'div')
      return objectFit === 'contain'
        ? styles.BgContain
        : objectFit === 'cover'
          ? styles.BgCover
          : undefined

    return objectFit === 'contain'
      ? styles.ObjectContain
      : objectFit === 'cover'
        ? styles.ObjectCover
        : objectFit === 'scale-down'
          ? styles.ObjectScaleDown
          : undefined
  }
}

const DivImage: React.FC<DivImageProps> = props => {
  const { classes, className, objectFit, onLoad, onClick, onContextMenu, src, style, title } = props
  const Component = props.component as ElementType

  const objectFitClassName = Utils.getObjectTypeClassName(objectFit, 'div')

  return (
    <Component
      className={twMerge(
        styles.DivImage,
        styles.PointerEventsNone,
        styles.UserSelectNone,
        className,
        classes?.backgroundContainer
      )}
      title={title}
      style={style}
    >
      <Component
        onContextMenu={onContextMenu}
        onClick={onClick}
        onLoad={onLoad}
        className={twMerge(styles.DivImageInner, objectFitClassName, classes?.background)}
        style={{
          backgroundImage: `url('${src}')`
        }}
      />
    </Component>
  )
}

const ImgComponent: React.FC<
  ImgProps & {
    setUseAnonymous?: Function
    setUseBackgroundImage?: Function
    setRef?: React.Ref<any>
  }
> = props => {
  const {
    alt,
    className,
    classes,
    crossOrigin,
    fullWidth,
    fullHeight,
    objectFit,
    onClick,
    onContextMenu,
    onError,
    onLoad,
    setRef,
    title,
    style,
    setUseAnonymous,
    setUseBackgroundImage,
    src
  } = props

  const objectFitClassName = Utils.getObjectTypeClassName(objectFit, 'img')

  return (
    <img
      onClick={onClick}
      onContextMenu={onContextMenu}
      ref={setRef}
      crossOrigin={crossOrigin}
      alt={alt || ''}
      title={title}
      style={style}
      className={twMerge(
        objectFitClassName,
        styles.PointerEventsNone,
        styles.UserSelectNone,
        fullWidth && styles.FullWidth,
        fullHeight && styles.FullHeight,
        className,
        classes?.image
      )}
      src={src}
      onLoad={onLoad}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        onError?.(e)

        /* if not anonymous, set anonymous first. */
        /* If in anonymous mode still gives error, change to use background image */
        if (setUseAnonymous) {
          setUseAnonymous(true)
        } else if (setUseBackgroundImage) setUseBackgroundImage(true)
      }}
    />
  )
}

const ImgWrapper: React.FC<
  ImgProps & {
    setRef?: React.Ref<any>
  }
> = props => {
  const {
    backgroundImage = false,
    alt,
    className,
    classes,
    component: Component = 'div',
    crossOrigin,
    file,
    fullWidth,
    fullHeight,
    fullSize,
    image,
    objectFit = 'contain',
    onClick,
    onContextMenu,
    onError,
    onLoad,
    title,
    style,
    setRef
  } = props
  const [useAnonymous, setUseAnonymous] = useState<boolean>(crossOrigin === 'anonymous')
  const [srcFile, setSrcFile] = useState<string>('')
  /* 
      Some error happen because of CORS issue, which can be solved by displaying image  using
      css 'background-image'. So when error happen, set the display mode using div instead of img. 
    */
  const [useBackgroundImage, setUseBackgroundImage] = useState<boolean>(backgroundImage)
  const crossOriginAdjusted = useAnonymous ? 'anonymous' : crossOrigin
  const srcFromImage = fullSize ? image?.file : image?.thumbnail ?? image?.file
  const src = srcFromImage || props.src || srcFile || ''

  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onload = event => {
        setSrcFile(event?.target?.result?.toString() ?? '')
      }
      reader.readAsDataURL(file)
    } else {
      setSrcFile('')
    }
  }, [file])

  if (!src)
    return (
      <Component className={twMerge(styles.EmptyImage, className, classes?.empty)} style={style} />
    )

  if (useBackgroundImage)
    return (
      <DivImage
        className={twMerge(
          fullWidth && styles.FullWidth,
          fullHeight && styles.FullHeight,
          className
        )}
        component={Component}
        onContextMenu={onContextMenu}
        onClick={onClick}
        classes={classes}
        onLoad={onLoad}
        objectFit={objectFit}
        src={src}
        style={style}
        title={title}
      />
    )

  return (
    <ImgComponent
      crossOrigin={crossOriginAdjusted}
      alt={alt}
      className={className}
      classes={classes}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      objectFit={objectFit}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onError={onError}
      onLoad={onLoad}
      setRef={setRef}
      title={title}
      style={style}
      src={src}
      setUseAnonymous={crossOriginAdjusted !== 'anonymous' ? setUseAnonymous : undefined}
      setUseBackgroundImage={setUseBackgroundImage}
    />
  )
}

const ImgContainer = React.forwardRef((props: ImgProps, ref: Ref<HTMLImageElement>) => {
  return <ImgWrapper {...props} setRef={ref} crossOrigin="anonymous" />
})

export const ImgPure = React.forwardRef((props: ImgProps, ref: Ref<HTMLImageElement>) => {
  return <ImgWrapper {...props} setRef={ref} crossOrigin={props.crossOrigin || undefined} />
})

export const ImgMemo = memo(ImgContainer)

export default ImgContainer
