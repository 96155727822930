import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  mergeMap,
  withLatestFrom,
  tap
} from 'rxjs/operators'
import _isNil from 'lodash/isNil'
import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { apiActions, sharedActions } from 'duck/ApiDuck'
import { SketchTextListOutputReq, TIProjectListReq } from 'models/ApiModels'
import { push } from 'redux-first-history'
import { route } from 'routes'
import { errorMessage, values } from 'appConstants'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import SentryUtils from 'utils/SentryUtils'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'

const deleteSTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.deleteSTIProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action
    })),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.deleteSTIProject(payload).pipe(
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.deleteSTIProjectResponse(payload),
            apiActions.users.retrieveEquity(),
            push(route.SKETCHTEXT_TO_IMAGE_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createSTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.createSTIProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.createSTIProject(payload).pipe(
          concatMap(response => [
            apiActions.sketchTextToImage.createSTIProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateSTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.updateSTIProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(
          sharedActions.setLoading({
            loading: !payload.silentUpdate,
            type
          })
        ),
        ApiUtils.sketchTextToImage.updateSTIProject(payload.data.id, payload.data).pipe(
          concatMap(response => [
            apiActions.sketchTextToImage.updateSTIProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveSTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.sketchTextToImage.retrieveSTIProject,
        apiActions.sketchTextToImage.setCurrentSketchTextToImageProject
      ])
    ),
    filter(({ payload }) => Boolean(payload)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.retrieveSTIProject(payload ?? 0).pipe(
          mergeMap(response => [
            apiActions.sketchTextToImage.retrieveSTIProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listSTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.listSTIProject)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const params = next
        ? { next: state.api.sketchTextToImage.sketchTextProjects.lastReq?.next }
        : {}
      const extraParams = next ? undefined : (param as TIProjectListReq)
      return { next, params, extraParams, type: action.type }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.listSTIProject(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.listSTIProjectResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listTIProjectOutputEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.listSTIProjectOutput)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, loadingMessage } = action.payload
      const projectId = param?.project || 0
      const params = next
        ? { next: state.api.sketchTextToImage.outputLists[projectId]?.lastListReq?.next }
        : {}
      const extraParams = next ? undefined : (param as SketchTextListOutputReq)
      return { next, params, extraParams, type: action.type, param, loadingMessage }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type, param, loadingMessage }) =>
      merge(
        of(sharedActions.setLoading({ loading: loadingMessage ?? true, type })),
        ApiUtils.sketchTextToImage.listSTIProjectOutput(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),

            apiActions.sketchTextToImage.listSTIProjectOutputResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const generateSTIProject: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.generateSTIProject)),
    withLatestFrom(state$),
    map(([action]) => ({
      ...action
    })),
    map(({ payload, type }) => {
      const { id, generate_api_url, image, mask, ...generateData } = payload
      const formData = new FormData()

      Object.keys(generateData).forEach(key => {
        formData.append(key, `${generateData[key as keyof typeof generateData]}`)
      })

      if (image) {
        formData.append('image', image)
      }
      if (mask) {
        formData.append('mask', mask)
      }

      return { id, payload, type, formData }
    }),
    mergeMap(param =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: param.type })),
        of(param).pipe(
          mergeMap(({ type, payload, formData, id }) =>
            ApiUtils.sketchTextToImage
              .generateSTIProjectFormData(id, formData, payload.generate_api_url)
              .pipe(
                tap(response => {
                  const projectData = DataUtils.getProjectParam<'ST2I_project'>('ST2I_project', {
                    sketchTextToImageProject: response.data
                  })

                  MixPanelUtils.track<'PROJECT__TEXT_TO_IMAGE_GENERATE'>(
                    'Project Text To Image - Generate Image',
                    {
                      ...projectData,
                      text_to_image_model: payload.model,
                      text_to_image_process: payload.process,
                      text_to_image_resolution: `${payload.width} x ${payload.height}`
                    }
                  )
                }),
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.sketchTextToImage.generateSTIProjectResponse(response.data)
                ]),
                catchError(err =>
                  of(
                    sharedActions.setError({
                      error: err.response,
                      type,
                      req: payload
                    })
                  )
                )
              )
          )
        )
      )
    )
  )

const deleteSTIProjectOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.deleteSTIProjectOutput)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      payload: action.payload
    })),

    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.deleteSTIProjectOutput(payload).pipe(
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.deleteSTIProjectOutputResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const cleanOutputsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.cleanOutputs)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.cleanOutputs(payload).pipe(
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.cleanOutputsResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const uploadSketchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.uploadSketch)),
    map(({ payload, type }) => {
      const { id, image } = payload
      const formData = new FormData()

      const error =
        (image?.size ?? 0) > values.MAX_IMAGE_SIZE
          ? errorMessage.ERROR_UPLOAD_TOO_LARGE.content
          : undefined

      formData.append('image', image as Blob)

      return { formData, type, id, payload, error }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ error }) => Boolean(error)),
          tap(({ error }) => {
            SentryUtils.captureMessage(
              `Unable To Upload Sketch`,
              {
                error
              },
              'log'
            )
          }),
          map(({ error }) =>
            dialogActions.openDialog({
              [ErrorDialog.ERROR]: {
                dialogName: ErrorDialog.ERROR,
                title: `Unable To Upload Images`,
                content: error
              }
            })
          )
        ),
        of(param).pipe(
          filter(({ error }) => !Boolean(error)),
          mergeMap(({ formData, type, id }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.sketchTextToImage.uploadSketch(id, formData).pipe(
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.sketchTextToImage.uploadSketchResponse(response.data)
                ]),
                catchError(err => of(sharedActions.setError({ error: err.response, type })))
              )
            )
          )
        )
      )
    )
  )

const updateThresholdEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.updateThreshold)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action
    })),
    filter(
      ({ payload }) =>
        !_isNil(payload.sketch_threshold_high) || !_isNil(payload.sketch_threshold_low)
    ),
    map(({ payload, type }) => {
      const { id } = payload

      return { type, id, payload }
    }),
    switchMap(({ type, id, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.updateThreshold(id, payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.updateThresholdResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const removeSketchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.removeSketch)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.removeSketch(payload.id, payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.removeSketchResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listSketchTextGenreEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.listSTIGenre)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.listSTIGenre(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.listSTIGenreResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listSketchTextProcessEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.listSTIProcess)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.listSTIProcess(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.listSTIProcessResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listSketchTextModelpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.listSTIModel)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.listSTIModel(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.listSTIModelResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listSketchTextStylespic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchTextToImage.listSTIStyles)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchTextToImage.listSTIStyles(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchTextToImage.listSTIStylesResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const sketchTextToImageEpics = [
  listSketchTextGenreEpic,
  listSketchTextProcessEpic,
  listSketchTextModelpic,
  deleteSTIProjectEpic,
  createSTIProjectEpic,
  updateSTIProjectEpic,
  retrieveSTIProjectEpic,
  listSTIProjectEpic,
  listTIProjectOutputEpic,
  generateSTIProject,
  deleteSTIProjectOutputEpic,
  cleanOutputsEpic,
  updateThresholdEpic,
  removeSketchEpic,
  uploadSketchEpic,
  listSketchTextStylespic
]

export default sketchTextToImageEpics
