import { forwardRef, Ref } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import useInterval from 'use-interval'
import { GENERATING_POOL_INTERVAL } from '../Models'
import Banner from 'components/Banner'
import { appSelectors } from 'duck/AppDuck'
import { RootActionType, RootState } from 'duck'
import Button from 'components/Button'
import { selectors, actions } from '../duck'
import ImagePreview from './ImagePreview'
import UpscaleControls from './UpscaleControls'
import UpscaleMethods from './UpscaleMethods'
import { useBreakpoint, useInTrainProjectResultPage } from 'utils/Hooks'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import { ReduxProps } from 'utils/Types'

import styles from './UpscaleGenerate.module.scss'

const mapStateToProps = (state: RootState) => ({
  mode: selectors.mode(state),
  banner: appSelectors.banner.banner(state),
  shouldRetrieveUpscaleData: selectors.shouldRetrieveUpscaleData(state),
  selectedMethod: selectors.selectedMethod(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeBanner: bannerActions.close,
      setOpenUpscaleList: actions.upscaleList.setOpenUpscaleList,
      retrieveUpscaleData: actions.retrieveUpscaleData,
      closeDialog: dialogActions.closeDialog
    },
    dispatch
  )

export type UpscaleGenerateProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  className?: string
  position?: 'fixed' | 'static' | 'absolute'
  variant?: 'video-panel' | 'home-page'
  onClose?: Function
  hideListLink?: boolean
}

const STYLE_MAP = {
  '': undefined,
  'home-page': styles.HomePage,
  'video-panel': styles.VideoPanel
}

const UpscaleGenerate = (props: UpscaleGenerateProps, ref: Ref<HTMLDivElement>) => {
  const {
    retrieveUpscaleData,
    setOpenUpscaleList,
    closeDialog,
    closeBanner,
    mode,
    className,
    selectedMethod,
    banner,
    variant,
    shouldRetrieveUpscaleData,
    position = 'absolute',
    onClose,
    hideListLink
  } = props

  const showBackButton = mode !== 'standalone'
  const { isMediumscreenAbove } = useBreakpoint()

  const isProjectResultPage = useInTrainProjectResultPage()

  useInterval(
    () => {
      if (shouldRetrieveUpscaleData && Boolean(selectedMethod)) {
        retrieveUpscaleData('upscaleGenerate')
      }
    },
    Boolean(selectedMethod && shouldRetrieveUpscaleData) ? GENERATING_POOL_INTERVAL : null,
    true
  )

  return (
    <div
      ref={ref}
      className={clsx(
        styles.UpscalePanelContainer,
        position === 'absolute' && styles.UpscalePanelContainerAbsolute,
        position === 'fixed' && styles.UpscalePanelContainerFixed,
        isProjectResultPage ? styles.UpscalePanelContainerProjectResult : '',
        STYLE_MAP[variant ?? ''],
        className
      )}
    >
      <Banner {...banner} onClose={closeBanner} />

      <div className="relative flex min-h-12 items-center justify-between pr-3 md:min-h-18 md:px-18">
        <div>
          {showBackButton ? (
            <Button
              onClick={() => {
                closeDialog()
                onClose?.()
              }}
              color="light"
              size="small"
              startIcon={<ChevronLeftIcon />}
            >
              EXIT {isMediumscreenAbove ? 'UPSCALING' : ''}
            </Button>
          ) : null}
        </div>

        {!hideListLink ? (
          <div>
            <Button color="secondary" onClick={() => setOpenUpscaleList(true)} size="small">
              MY UPSCALES
            </Button>
          </div>
        ) : null}
      </div>

      <div className="relative grow">
        <div className="mx-auto flex h-full w-full flex-col sm:flex-row md:max-h-full md:pt-4">
          <div
            className={clsx(
              'mb-1 w-full sm:mb-0 sm:w-1/2 md:w-1/100 md:min-w-0 md:flex-auto',
              styles.UpscalePanelImageWrapper
            )}
          >
            <ImagePreview />
          </div>

          <div
            className={clsx(
              'relative sm:flex sm:w-1/2 sm:flex-col md:w-auto md:flex-row',
              styles.UpscalePanelSidebar
            )}
          >
            <UpscaleMethods />
            <UpscaleControls />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(forwardRef(UpscaleGenerate))
