import { apiActions, TextToImageActionType } from '../actions'
import { SharedActionsType } from '../sharedActions'
import {
  TIProject,
  TIOutputImage,
  TIProjectListResponse,
  TIProjectOutputListResponse
} from 'models/ApiModels'
import { createReducer, getType } from 'typesafe-actions'
import _map from 'lodash/map'
import _keyBy from 'lodash/keyBy'
import _uniq from 'lodash/uniq'
import _without from 'lodash/without'

export type TextToImageVideoState = {
  currentTextToImage?: number
  textToImageProjects: {
    list: number[]
    data: {
      [id: number]: TIProject
    }
    lastReq?: TIProjectListResponse
  }
  outputLists: {
    [proArtFilterProjectId: string]:
      | {
          lastListReq: TIProjectOutputListResponse
          list: number[]
        }
      | undefined
  }
  outputs: { [proArtFilterOutput: number]: TIOutputImage }
}

export const INITIAL_STATE: TextToImageVideoState = {
  currentTextToImage: undefined,
  textToImageProjects: {
    list: [],
    data: {},
    lastReq: undefined
  },
  outputLists: {},
  outputs: {}
}

export const textToImageReducers = createReducer<
  TextToImageVideoState,
  TextToImageActionType | SharedActionsType
>(INITIAL_STATE)
  .handleAction(apiActions.textToImage.setCurrentTextToImageProject, (state, action) => {
    const id = action?.payload
    return {
      ...state,
      currentTextToImage: id
    }
  })
  .handleAction(apiActions.textToImage.listTIProjectResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.textToImageProjects.list || [] : []

    const combinedResults = results.map(result => ({
      ...(state.textToImageProjects.data[result.id] ?? {}),
      ...result,
      image: state.textToImageProjects.data[result.id]?.image
    }))
    return {
      ...state,
      textToImageProjects: {
        list: [...prevData, ..._map(results, result => result.id)],
        lastReq: data,
        data: {
          ...state.textToImageProjects.data,
          ..._keyBy(combinedResults, result => result.id)
        }
      }
    }
  })
  .handleAction(apiActions.textToImage.listTIProjectOutputResponse, (state, action) => {
    const { data, next, req } = action.payload

    const projectId = req?.project || 0
    const results = data?.results ?? []
    const prevData = next ? state.outputLists[projectId]?.list || [] : []

    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [projectId]: {
          lastListReq: data,
          list: _uniq([...prevData, ..._map(results, result => result.id)])
        }
      },
      outputs: {
        ...state.outputs,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(
    [
      apiActions.textToImage.createTIProjectResponse,
      apiActions.textToImage.retrieveTIProjectResponse,
      apiActions.textToImage.generateTIProjectResponse,
      apiActions.textToImage.updateTIProjectResponse
    ],
    (state, action) => {
      const isCreate = action.type === getType(apiActions.textToImage.createTIProjectResponse)
      const id = action.payload?.id || 0
      const currentData = state.textToImageProjects.data[id] ?? {}
      const currentList = state.textToImageProjects.list ?? []

      return {
        ...state,
        textToImageProjects: {
          ...state.textToImageProjects,
          data: {
            ...state.textToImageProjects.data,
            [id]: {
              ...currentData,
              ...action.payload
            }
          },
          list: isCreate ? [id, ...currentList] : currentList
        }
      }
    }
  )
  .handleAction(apiActions.textToImage.deleteTIProject, (state, action) => {
    const { [action.payload]: deletedProject, ...textToImageProjects } =
      state.textToImageProjects.data

    const itProjectList = _without(state.textToImageProjects.list || [], deletedProject.id)

    return {
      ...state,
      textToImageProjects: {
        ...state.textToImageProjects,
        data: textToImageProjects,
        list: itProjectList
      }
    }
  })
  .handleAction(apiActions.textToImage.resetTextToImageQueueData, (state, action) => {
    const payload = action.payload
    const currentProject = state.textToImageProjects.data[payload]
    return currentProject
      ? {
          ...state,
          textToImageProjects: {
            ...state.textToImageProjects,
            data: {
              ...state.textToImageProjects.data,
              [payload]: {
                ...currentProject,
                queue_length: undefined,
                expect_finished: undefined
              }
            }
          }
        }
      : state
  })
  .handleAction(apiActions.textToImage.deleteTIProjectOutputResponse, (state, action) => {
    const currentTextToImageId = state.currentTextToImage ?? 0
    const currentList = state.outputLists[currentTextToImageId]?.list ?? []
    const newList = _without(currentList || [], action.payload)
    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [currentTextToImageId]: {
          ...state.outputLists[currentTextToImageId],
          list: newList
        }
      }
    }
  })
  .handleAction(apiActions.textToImage.deleteAllTIProjectOutputResponse, (state, action) => {
    const currentTextToImageId = state.currentTextToImage ?? 0

    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [currentTextToImageId]: undefined
      }
    }
  })
