import { useDialogDetector } from 'components/Dialog/Hooks'
import { FormDialog } from 'duck/AppDuck/DialogDuck'
import loadable from 'utils/Loadable'

const NFTSellForm = loadable(() => import(/* webpackChunkName: "NFTSellForm" */ './index'))

const NFTSellFormContainer = () => {
  const open = useDialogDetector(FormDialog.SELL_AS_NFT_FORM)

  if (!open) return null

  return <NFTSellForm />
}

export default NFTSellFormContainer
