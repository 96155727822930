import React, { useRef, useState, useEffect, useCallback } from 'react'
import styles from './InfinityGrid.module.scss'
import Canvas from 'components/Canvas'
import _throttle from 'lodash/throttle'

import _toNumber from 'lodash/toNumber'

import CanvasBuilder from './CanvasBuilder'
import { Stage } from 'konva/lib/Stage'
import { Layer } from 'konva/lib/Layer'
import { useWindowDimension } from 'utils/Hooks'
import { Vector2d } from 'konva/lib/types'
import { gridUtils } from './utils'
import { OriginImageRequest, SpaceImageRequest } from 'models/ApiModels'
import { MixImageState } from 'duck/ApiDuck/reducers/MixImageReducers'
import { emptyFunction } from 'utils'

export const DEFAULT_ZOOM_LEVEL = 20
export const INITIAL_WIDTH = 500
export const INITIAL_HEIGHT = 500

export const BASE_GRID_SIZE = 7

export const IMAGE_GRID_COUNT_X = 136
export const IMAGE_GRID_COUNT_Y = 136

export type InfinityGridCommand = { type: InfinityGridCommandType; payload?: any }
export const EMPTY_COMMAND = { type: null }
export const DEFAULT_SCREEN_OFFSET = {
  x: 0,
  y: 0
}
export type InfinityGridCommandType = 'zoom' | 'refreshImage' | 'home' | null

export type InfinityGridProps = {
  screenOffset?: Vector2d // grid padding from the top and left screen
  spaceImages: MixImageState['spaceImage']['spaceImages']
  currentFetchState: MixImageState['spaceImage']['currentFetchState']
  spaceImageList: MixImageState['spaceImage']['spaceImageList']
  className?: string
  command?: InfinityGridCommand
  setCommand?: (command: InfinityGridCommand) => void
  alt?: string
  zoom?: number // in percent, base : 100
  maxZoomValue?: number
  setZoomValue?: (value: number) => void
  camera: Vector2d
  setCamera?: (pos: Vector2d) => void
  setSelectedImage?: (param: Vector2d) => void
  setRequestQueue?: (param: SpaceImageRequest[]) => void
  selectedImage?: OriginImageRequest
  debugMode?: boolean
}

const InfinityGrid: React.FC<InfinityGridProps> = props => {
  const {
    zoom = DEFAULT_ZOOM_LEVEL,
    camera,
    setZoomValue,
    command,
    maxZoomValue,
    setCommand,
    setSelectedImage,
    setRequestQueue,
    selectedImage,
    screenOffset = DEFAULT_SCREEN_OFFSET,
    debugMode = false
  } = props
  const { spaceImages, spaceImageList, currentFetchState } = props

  const containerRef = useRef<HTMLDivElement | null>(null)
  const width = containerRef.current?.clientWidth ?? INITIAL_WIDTH
  const height = containerRef.current?.clientHeight ?? INITIAL_HEIGHT
  const [stage, setStage] = useState<Stage | null>(null)
  const [layer, setLayer] = useState<Layer | null>(null)
  const [canvasBuilder, setCanvasBuilder] = useState<ReturnType<typeof CanvasBuilder> | null>(null)

  const cameraListener = useCallback(
    (param: Vector2d, zoom: number, source?: string) => {
      const grid = gridUtils.getGridCoordinate({
        camera: param,
        zoom,
        screenOffset,
        screenLocation: { x: 0, y: 0 }
      })
      const gridCount = gridUtils.getGridInScreenCount({ zoom, stageSize: { width, height } })
      const gridSize = gridUtils.getGridSize(zoom)
      const { displayedImages, displayedImagesFlat, requestQueue } = gridUtils.getDisplayedImage(
        {
          grid,
          currentFetchState,
          spaceImageList,
          gridSize,
          gridCount,
          spaceImages
        },
        source
      )
      setRequestQueue?.(requestQueue)
      canvasBuilder?.updateImage(displayedImages, displayedImagesFlat)
    },
    [
      screenOffset,
      setRequestQueue,
      currentFetchState,
      spaceImageList,
      width,
      height,
      spaceImages,
      canvasBuilder
    ]
  )
  const refreshSpaceImageData = useCallback(
    (source?: string) => {
      const camera = canvasBuilder?.getCamera() || { x: 0, y: 0 }
      const zoom = canvasBuilder?.getZoom() || DEFAULT_ZOOM_LEVEL
      cameraListener(camera, zoom, source)
    },
    [canvasBuilder, cameraListener]
  )

  if (layer && stage && !canvasBuilder) {
    const canvasBuilder = CanvasBuilder({
      layer,
      camera,
      stage,
      zoom,
      maxZoomValue,
      screenOffset,
      stageSize: {
        width,
        height
      },
      debugMode
    })
    setCanvasBuilder(canvasBuilder)
  }

  useEffect(() => {
    if (canvasBuilder) {
      canvasBuilder.setStageSize({ width, height })
    }
  }, [width, height, canvasBuilder])

  useEffect(() => {
    if (canvasBuilder) {
      canvasBuilder.setStageSize({ width, height })
    }
  }, [width, height, canvasBuilder])

  useEffect(() => {
    canvasBuilder?.setSelectedImage(selectedImage)
  }, [canvasBuilder, selectedImage])

  useEffect(() => {
    refreshSpaceImageData('use effect space image')
  }, [spaceImages, spaceImageList.length, refreshSpaceImageData])

  useEffect(() => {
    if (canvasBuilder) {
      canvasBuilder.setStageSize({ width, height })
    }
  }, [width, height, canvasBuilder])

  useEffect(() => {
    canvasBuilder?.setListener('cameraListener', _throttle(cameraListener, 200))
    canvasBuilder?.setListener('clickTapListener', setSelectedImage || emptyFunction)
    canvasBuilder?.setListener('zoomListener', setZoomValue || emptyFunction)
  }, [canvasBuilder, setSelectedImage, cameraListener, setZoomValue])

  useEffect(() => {
    canvasBuilder?.setScreenOffset(screenOffset)
  }, [canvasBuilder, screenOffset, screenOffset.x, screenOffset.y])

  useEffect(() => {
    const type = command?.type
    const payload = command?.payload
    if (type) {
      switch (type) {
        case 'zoom': {
          const zoom = _toNumber(payload)
          canvasBuilder?.setZoom(zoom)
          refreshSpaceImageData('zoom')
          break
        }
        case 'home': {
          canvasBuilder?.goHome()
          refreshSpaceImageData('go home')
          break
        }
        case 'refreshImage': {
          refreshSpaceImageData(' image data')
          break
        }
        default:
          break
      }
      setCommand?.(EMPTY_COMMAND)
    }
  }, [canvasBuilder, command, refreshSpaceImageData, setCommand])

  useWindowDimension()

  return (
    <div className={styles.InfinityGridContainer} ref={containerRef}>
      <Canvas
        getStage={setStage}
        getLayer={setLayer}
        width={width}
        height={height}
        fill="#121212"
      />
    </div>
  )
}

export default InfinityGrid
