import { RootState } from 'duck'
import { createSelector } from 'reselect'

const engine = (state: RootState) => {
  return state.api.engine
}

const clips = createSelector(engine, engine => engine.clips)
const clipLists = createSelector(engine, engine => engine.clipLists)

const engineSelectors = {
  clipLists,
  clips
}

export default engineSelectors
