import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import { CircularLoading } from 'components/Loading/CircularLoading'
import DialogWrapper from '../DialogWrapper'
import { GeneralDialogItemProps } from '..'
import { RootState, RootActionType } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import { changeEmailActions } from 'duck/AppDuck/ChangeEmailDuck'
import Button from 'components/Button'
import { ReduxProps } from 'utils/Types'

import styles from '../Dialogs.module.scss'

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state),
  changeEmailData: appSelectors.changeEmail.changeEmail(state),
  changeEmailLoading: appSelectors.changeEmail.isLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeAllDialog: dialogActions.closeAllDialog,
      setFormEmailData: changeEmailActions.setFormData,
      submitEmail: changeEmailActions.submitEmail
    },
    dispatch
  )

type DialogChangeEmailProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  GeneralDialogItemProps

const DialogChangeEmail: React.FC<DialogChangeEmailProps> = props => {
  const {
    activeDialog,
    changeEmailLoading,
    changeEmailData,
    onClose,
    closeAllDialog,
    setFormEmailData,
    submitEmail
  } = props

  const { formData, formError } = changeEmailData

  return (
    <DialogWrapper
      dialogConfig={{
        className: styles.EmailDialog
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">Change Email</Typography>}
      actions={
        <Fragment>
          <Button disabled={changeEmailLoading} color="secondary" onClick={closeAllDialog}>
            Cancel
          </Button>
          <Button disabled={changeEmailLoading} color="secondary" onClick={() => submitEmail()}>
            Submit
          </Button>
        </Fragment>
      }
      content={
        <Fragment>
          {changeEmailLoading ? (
            <CircularLoading className={styles.LoadingStyle} loading={true} disableShrink />
          ) : (
            <Fragment>
              <div className={styles.ContentGroup}>
                <Typography variant="body2">Please enter a new email address.</Typography>
              </div>
              <div className={clsx(styles.FormContainer, styles.ContentGroup)}>
                <TextField
                  id="email"
                  label="Email"
                  placeholder="Email"
                  variant="filled"
                  type="email"
                  value={formData.email}
                  color="secondary"
                  onChange={e => setFormEmailData({ email: e.target.value })}
                  helperText={formError.email || ''}
                  FormHelperTextProps={{
                    error: Boolean(formError.email || false)
                  }}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      }
    />
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(DialogChangeEmail)
