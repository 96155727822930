import { forwardRef, Ref, useCallback } from 'react'
import clsx from 'clsx'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Button from 'components/Button'
import { useBreakpoint } from 'utils/Hooks'
import { RootActionType } from 'duck'
import { actions } from './duck'
import UpscaleList from './components/UpscaleList'
import UpscaleDetail from './UpscaleDetail'
import ListFilters from './components/ListFilters'
import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './UpscalePanel.module.scss'

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      showEdit: actions.upscaleList.showEdit,
      setOpenedUpscaleImage: actions.upscaleList.setOpenedUpscaleImage,
      setOpenUpscaleList: actions.upscaleList.setOpenUpscaleList
    },
    dispatch
  )

type UpscaleListPanelProps = ReturnType<typeof mapDispatchToProps>

const UpscaleListPanel = (props: UpscaleListPanelProps, ref: Ref<HTMLDivElement>) => {
  const { setOpenUpscaleList, showEdit, setOpenedUpscaleImage } = props

  const { isSmallscreenAbove } = useBreakpoint()

  const onClickItem = useCallback(
    (id: number) => {
      setOpenedUpscaleImage(id)
    },
    [setOpenedUpscaleImage]
  )

  const onClickEditUpscale = useCallback(
    (id: number) => {
      showEdit({ upscaleImageId: id })
    },
    [showEdit]
  )

  return (
    <div ref={ref} className="flex h-full w-full flex-col">
      <div
        className={clsx(
          'flex items-center pl-4 pr-6 md:px-18',
          stylesGlobal.BorderBottomOnSurface,
          styles.UpscaleListHead
        )}
      >
        <Button
          size="small"
          onClick={() => setOpenUpscaleList(false)}
          icon={!isSmallscreenAbove}
          startIcon={isSmallscreenAbove ? <ChevronLeftIcon /> : undefined}
        >
          {isSmallscreenAbove ? 'Back' : <ChevronLeftIcon />}
        </Button>

        <ListFilters />
      </div>

      <UpscaleList onClickItem={onClickItem} />
      <UpscaleDetail onClickEditUpscale={onClickEditUpscale} />
    </div>
  )
}

export default connect(null, mapDispatchToProps, null, {
  forwardRef: true
})(forwardRef(UpscaleListPanel))
