import React, { Fragment, useCallback } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import DialogWrapper from '../DialogWrapper'
import { ChangeEmailDialog, dialogActions } from 'duck/AppDuck/DialogDuck'
import { RootState, RootActionType } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import Button from 'components/Button'
import { GeneralDialogItemProps } from '..'
import { changeEmailActions } from 'duck/AppDuck/ChangeEmailDuck'
import styles from '../Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state),
  changeEmailData: appSelectors.changeEmail.changeEmail(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      addDialog: dialogActions.addDialog,
      resetFormErrors: changeEmailActions.resetFormErrors
    },
    dispatch
  )

type DialogConfirmNewEmailProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  GeneralDialogItemProps

const DialogConfirmNewEmail: React.FC<DialogConfirmNewEmailProps> = props => {
  const { changeEmailData, activeDialog, onClose, closeDialog, resetFormErrors, addDialog } = props
  const { formData } = changeEmailData

  const errorBackClick = useCallback(() => {
    closeDialog()
    resetFormErrors()
  }, [closeDialog, resetFormErrors])

  return (
    <DialogWrapper
      dialogConfig={{
        className: styles.EmailDialog
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">Verify your new email</Typography>}
      actions={
        <Fragment>
          <Button
            color="secondary"
            onClick={() =>
              addDialog({
                [ChangeEmailDialog.CONFIRM_NEW_EMAIL_HELP]: {
                  dialogName: ChangeEmailDialog.CONFIRM_NEW_EMAIL_HELP
                }
              })
            }
          >
            Need Help?
          </Button>

          <Button color="secondary" onClick={errorBackClick}>
            Back
          </Button>
        </Fragment>
      }
      content={
        <Fragment>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              To finalize your changes, please verify your new email:
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography color="secondary" variant="body2">
              {formData.email}
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              Click the magic link that was just sent to your new email address to confirm.
            </Typography>
          </div>
        </Fragment>
      }
    />
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmNewEmail)
