import _replace from 'lodash/replace'
import JSZip from 'jszip'
import { saveAs } from 'utils/FileSaver'

export const imageDownloader = (function () {
  let zip = new JSZip()
  let fileName = `images.zip`
  let count = 0
  let countTotal = 0

  type AddImagesType = {
    err: any
    file: any
    imageName: string
    withIncrementPrefix?: boolean
  }

  //Using an increment to differentiate between image with same name.
  const decideName = (imageName: string, count: number, withIncrementPrefix: boolean) => {
    if (withIncrementPrefix) {
      const imageNameSplitted = imageName.split('.')
      const extension = imageNameSplitted[imageNameSplitted.length - 1]
      const cleanedImageName = _replace(imageName, `.${extension}`, '')
      return `${cleanedImageName}-${count}.${extension}`
    }
    return imageName
  }

  return {
    startDownloadSession: (name: String, total: number) => {
      zip = new JSZip()
      count = 0
      countTotal = total
      fileName = name ? `${name}.zip` : fileName
    },
    addImages: (param: AddImagesType) => {
      const { err, file, imageName, withIncrementPrefix = false } = param
      count++
      if (err) {
        return
      }

      zip.file(decideName(imageName, count, withIncrementPrefix), file, { binary: true })
      if (count === countTotal) {
        zip.generateAsync({ type: 'blob' }).then(content => {
          const data = new File([content], 'zip-file')
          saveAs({ data, filename: fileName })
        })
      }
    }
  }
})()
