import { useSelector } from 'react-redux'
import { RootState } from 'duck'
import { useBooleanDelayedData } from 'utils/Hooks'
import { appSelectors } from 'duck/AppDuck'
import loadable from 'utils/Loadable'

const BannerNFTSuccess = loadable(
  () => import(/* webpackChunkName: "BannerNFTSuccess" */ './index')
)

const BannerNFTSuccessContainer = () => {
  const nftBanner = useSelector((state: RootState) => appSelectors.banner.nftBanner(state))

  const show = useBooleanDelayedData(nftBanner.show)

  if (!show) return null

  return <BannerNFTSuccess />
}

export default BannerNFTSuccessContainer
