import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import _compact from 'lodash/compact'
import Typography from '@mui/material/Typography'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import MenuItem from '@mui/material/MenuItem'

import Button from 'components/Button'
import GridLabelTwo from 'components/GridLabel/GridLabelTwo'
import SelectBase from 'components/Select/SelectBase'
import { UPSCALE_LIST, UPSCALE_LIST_CONFIG } from '../Models'
import { selectors, actions } from '../duck'
import { RootState, RootActionType } from 'duck'
import { useBreakpoint } from 'utils/Hooks'
import { UpscaleMethod } from 'models/ApiModels'
import { ReduxProps } from 'utils/Types'

import styles from './UpscaleGenerate.module.scss'

const classesGridLabel = {
  label: 'text-white-med-on-dark'
}

const mapStateToProps = (state: RootState) => ({
  upscaleSmallImage: selectors.upscaleImage(state).smallImage,
  imageFile: selectors.imageFile(state),
  upscaleListLoadingState: selectors.upscaleListLoadingState(state),
  selectedMethod: selectors.selectedMethod(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      setSelectedMethod: actions.setSelectedMethod
    },
    dispatch
  )

export type UpscaleMethodsProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const TitleComponent: React.FC<{
  isSmallscreenAbove?: boolean
  isMediumscreenAbove?: boolean
}> = props => {
  const { isMediumscreenAbove, isSmallscreenAbove } = props

  if (isMediumscreenAbove)
    return (
      <div className={clsx(styles.UpscalePanelSidebarColTitle, styles.UpscaleMethodsTitle)}>
        <Typography variant="overline" component="div">
          UPSCALING METHOD
        </Typography>
      </div>
    )
  else if (isSmallscreenAbove)
    return (
      <div className={clsx(styles.UpscalePanelSidebarColTitle, styles.UpscaleMethodsTitle)}>
        <div className="flex items-center">
          <PhotoSizeSelectLargeIcon />
          <Typography className="ml-2" variant="overline" component="div">
            METHOD
          </Typography>
        </div>
      </div>
    )

  return null
}

const UpscaleMethods: React.FC<UpscaleMethodsProps> = props => {
  const {
    setSelectedMethod,
    upscaleListLoadingState,
    selectedMethod,
    upscaleSmallImage,
    imageFile
  } = props
  const [modeChanged, setModeChanged] = useState(false)
  const disableMethodButton = !upscaleSmallImage && !imageFile

  const { isSmallscreenAbove, isMediumscreenAbove } = useBreakpoint()

  const handleClickUpscaleItem = useCallback(
    (mode: UpscaleMethod) => {
      if (selectedMethod === mode) {
        setSelectedMethod(undefined)
      } else {
        setSelectedMethod(mode)
      }
    },
    [selectedMethod, setSelectedMethod]
  )

  const MenuItemsComponent = useMemo(() => {
    return _compact(
      UPSCALE_LIST.map(method => {
        const upscaleConfig = UPSCALE_LIST_CONFIG[method]
        const loading = upscaleListLoadingState[method]
        const title = upscaleConfig?.title ?? ''
        return (
          <MenuItem key={method} value={method}>
            {loading ? (
              <span className="flex">
                {title}
                <HourglassEmptyIcon color="secondary" className="pl-2" />
              </span>
            ) : (
              title
            )}
          </MenuItem>
        )
      })
    )
  }, [upscaleListLoadingState])

  useEffect(() => {
    if (selectedMethod && !modeChanged) setModeChanged(true)
  }, [selectedMethod, modeChanged])

  return (
    <div className={styles.UpscaleMethodsWrapper}>
      <TitleComponent
        isMediumscreenAbove={isMediumscreenAbove}
        isSmallscreenAbove={isSmallscreenAbove}
      />

      <div>
        {!isSmallscreenAbove ? (
          <GridLabelTwo
            classes={classesGridLabel}
            label={
              <>
                <PhotoSizeSelectLargeIcon />
                <Typography className="ml-2" variant="overline">
                  METHOD
                </Typography>
              </>
            }
            labelColor="light"
            content={
              <SelectBase
                onChange={value => {
                  if (value === 'none') setSelectedMethod(undefined)
                  else setSelectedMethod(value as UpscaleMethod)
                }}
                disabled={disableMethodButton}
                renderValue={value => {
                  const valueAdjusted = value as UpscaleMethod
                  const upscaleConfig = valueAdjusted
                    ? UPSCALE_LIST_CONFIG[valueAdjusted]
                    : undefined

                  if (!upscaleConfig)
                    return <span className="text-white-med-on-dark">Select Upscale Method</span>

                  return <>{upscaleConfig?.title}</>
                }}
                value={selectedMethod ?? 'none'}
                size="small"
                borderless
              >
                <MenuItem value={'none'} disabled={!modeChanged || !selectedMethod}>
                  {!selectedMethod ? (
                    <span className="text-white-med-on-dark">Select Upscale Method</span>
                  ) : (
                    `Original`
                  )}
                </MenuItem>
                {MenuItemsComponent}
              </SelectBase>
            }
          />
        ) : null}

        {isSmallscreenAbove &&
          UPSCALE_LIST.map(method => {
            const upscaleConfig = UPSCALE_LIST_CONFIG[method]
            const loading = upscaleListLoadingState[method]
            const title = upscaleConfig?.title

            return (
              <Button
                key={method}
                className={styles.ButtonUpscaleItem}
                align="left"
                variant="outlined"
                color={selectedMethod === method ? 'secondary' : 'light'}
                onClick={() => {
                  handleClickUpscaleItem(method)
                }}
                disabled={disableMethodButton}
                disableUppercase
                fullWidth
              >
                <span className="block">
                  <span className="flex items-center">
                    <Typography
                      className={clsx(
                        'mb-1.5 w-full',
                        !loading && 'block',
                        loading && `flex items-center`
                      )}
                      variant="subtitle1"
                      component="span"
                    >
                      {loading ? (
                        <>
                          {title}
                          <HourglassEmptyIcon color="secondary" className="ml-2 text-base" />
                        </>
                      ) : (
                        title
                      )}
                    </Typography>
                  </span>

                  <Typography className="block" variant="caption">
                    {upscaleConfig?.subtitle}
                  </Typography>
                </span>
              </Button>
            )
          })}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpscaleMethods)
