import { RootState } from 'duck'
import _find from 'lodash/find'
import { createSelector } from 'reselect'
import {
  ConfirmationDialog,
  ErrorDialog,
  FormDialog,
  InformationDialog,
  MonetizationDialog
} from './Models'

// Selectors
const selectDialog = (state: RootState) => state.container.appPage.dialog

const selectActiveDialog = createSelector(selectDialog, dialog => {
  const { show } = dialog
  return show[0]
})

const selectActiveDialogOverlay = createSelector(selectDialog, dialog => {
  const { showOverlay, show } = dialog
  return showOverlay || show[0]
})

const selectDialogData = createSelector(selectDialog, dialog => {
  return dialog.data
})

const selectHasActiveDialog = createSelector(selectActiveDialog, activeDialog =>
  Boolean(activeDialog)
)

const selectHasMultipleDialog = createSelector(selectDialog, dialog => {
  const { show } = dialog

  return (show?.length ?? 0) > 1
})

const selectHasTrainingTimeDialog = createSelector(selectDialog, dialog => {
  const { show } = dialog

  return Boolean(_find(show, show => show === MonetizationDialog.TRAINING_SETUP))
})

const selectHasUpscaleDialog = createSelector(selectActiveDialog, activeDialog => {
  return activeDialog === FormDialog.UPSCALE_RESULT
})

const selectHasImageEnhancementDialog = createSelector(selectActiveDialog, activeDialog => {
  return activeDialog === FormDialog.IMAGE_ENHANCEMENT
})

const selectIsSubscriptionPanelOpened = createSelector(selectDialog, ({ show }) => {
  return show.find(value => value === 'monetization.subscriptionPanel')
})

const dialogDatum = {
  [FormDialog.NEW_PROJECT]: createSelector(
    selectDialogData,
    dialogData => dialogData[FormDialog.NEW_PROJECT]
  ),
  [InformationDialog.INFORMATION]: createSelector(
    selectDialogData,
    dialogData => dialogData[InformationDialog.INFORMATION]
  ),
  [ErrorDialog.ERROR]: createSelector(
    selectDialogData,
    dialogData => dialogData[ErrorDialog.ERROR]
  ),
  [ErrorDialog.UPLOAD_FAILED]: createSelector(
    selectDialogData,
    dialogData => dialogData[ErrorDialog.UPLOAD_FAILED]
  ),
  [ErrorDialog.NOT_ENOUGH_IMAGES]: createSelector(
    selectDialogData,
    dialogData => dialogData[ErrorDialog.NOT_ENOUGH_IMAGES]
  ),
  [ErrorDialog.TOO_MANY_IMAGES]: createSelector(
    selectDialogData,
    dialogData => dialogData[ErrorDialog.TOO_MANY_IMAGES]
  ),
  [ErrorDialog.REUSED_COLLECTION]: createSelector(
    selectDialogData,
    dialogData => dialogData[ErrorDialog.REUSED_COLLECTION]
  ),
  [ConfirmationDialog.CONFIRMATION]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.CONFIRMATION]
  ),
  [ConfirmationDialog.REMOVE_PAYMENT_METHOD]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.REMOVE_PAYMENT_METHOD]
  ),
  [ConfirmationDialog.DELETE_PROJECT]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.DELETE_PROJECT]
  ),
  [ConfirmationDialog.DELETE_COLLECTION]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.DELETE_COLLECTION]
  ),
  [ConfirmationDialog.LOGOUT]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.LOGOUT]
  ),
  [ConfirmationDialog.PAUSE]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.PAUSE]
  ),
  [ConfirmationDialog.DELETE_POST_ADMIN]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.DELETE_POST_ADMIN]
  ),
  [ConfirmationDialog.DELETE_POST_OWNER]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.DELETE_POST_OWNER]
  ),
  [MonetizationDialog.IMAGE_DOWNLOAD]: createSelector(
    selectDialogData,
    dialogData => dialogData[MonetizationDialog.IMAGE_DOWNLOAD]
  ),
  [MonetizationDialog.ADD_CREDIT]: createSelector(
    selectDialogData,
    dialogData => dialogData[MonetizationDialog.ADD_CREDIT]
  ),
  [FormDialog.SELL_AS_NFT_INFO]: createSelector(
    selectDialogData,
    dialogData => dialogData[FormDialog.SELL_AS_NFT_INFO]
  ),
  [FormDialog.SELL_AS_NFT_FORM]: createSelector(
    selectDialogData,
    dialogData => dialogData[FormDialog.SELL_AS_NFT_FORM]
  ),
  [ConfirmationDialog.UNSAVED_CHANGES]: createSelector(
    selectDialogData,
    dialogData => dialogData[ConfirmationDialog.UNSAVED_CHANGES]
  )
}

export const dialogSelectors = {
  dialog: selectDialog,
  dialogData: selectDialogData,
  isSubscriptionPanelOpened: selectIsSubscriptionPanelOpened,
  hasUpscaleDialog: selectHasUpscaleDialog,
  activeDialogOverlay: selectActiveDialogOverlay,
  hasImageEnhancementDialog: selectHasImageEnhancementDialog,
  activeDialog: selectActiveDialog,
  hasActiveDialog: selectHasActiveDialog,
  hasMultipleDialog: selectHasMultipleDialog,
  hasTrainingTimeDialog: selectHasTrainingTimeDialog,
  dialogDatum
}
