import _map from 'lodash/map'
import { RootState } from 'duck'
import { Product, Channel, Subscription } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { values } from 'appConstants'
import { PaymentState } from '../reducers'
import projectsSelectors from './ProjectsSelectors'
import userSelectors from './UserSelectors'

const payment = (state: RootState): PaymentState => {
  return state.api?.payment
}

const initialProductData: { [key: string]: Product } = {}
const initialProductIdData: { [key: string]: number } = {}

/* 
    - Convert array into key value object 
    - Convert cent into dollar
  */
const productsData = createSelector(payment, payment => {
  const results = payment?.products?.results ?? []
  return results.reduce((result, value) => {
    return {
      ...result,
      [value.name]: {
        ...value,
        price: value.price / 100
      }
    }
  }, initialProductData)
})

const productsIdData = createSelector(payment, payment => {
  const results = payment?.products?.results ?? []

  return results.reduce((result, value) => {
    return {
      ...result,
      [value.id]: {
        ...value,
        price: value.price / 100
      }
    }
  }, initialProductData)
})

const channels = createSelector(payment, payment => payment?.channels?.results ?? [])

const initialChannelData: { [key: string]: Channel } = {}

const channelData = createSelector(channels, channels => {
  return channels.reduce((result, value) => {
    return {
      ...result,
      [value.name]: value
    }
  }, initialChannelData)
})

const hasChannels = createSelector(channels, channels => Boolean(channels.length))

const productsDataAddCredit = createSelector(productsData, products => {
  return products[values.ADD_CREDIT_PRODUCT_CODE]
})
const productNameToIdMap = createSelector(payment, payment => {
  const results = payment?.products?.results ?? []

  return results.reduce((result, value) => {
    return {
      ...result,
      [value.name]: value.id
    }
  }, initialProductIdData)
})

const paymentData = createSelector(payment, payment => payment?.paymentData ?? {})
const hasProducts = createSelector(
  payment,
  ({ products }) => products && products.results && products.results.length
)

const currentPaymentData = createSelector(payment, payment => {
  const paymentData = payment?.paymentData ?? {}
  const currentId = payment?.currentPayment ?? 0

  return paymentData[currentId]
})

const brainTreePaymentMethod = createSelector(payment, payment => payment?.brainTreePaymentMethod)
const hasBraintreePaymentMethod = createSelector(brainTreePaymentMethod, paymentMethod =>
  Boolean(paymentMethod?.masked_number)
)

const creditChangeLogs = createSelector(payment, payment => payment?.creditChangeLogs ?? {})
const creditOverview = createSelector(payment, payment => payment?.creditOverview)
const creditChangeLogListId = createSelector(payment, payment => payment?.creditChangeLogList ?? [])

const isCreditChangeLogsHasNext = createSelector(payment, payment =>
  Boolean(payment.lastCreditChangeLogRequest?.next ?? null)
)

const creditChangeLogList = createSelector(
  creditChangeLogs,
  creditChangeLogListId,
  projectsSelectors.projects,
  paymentData,
  (trainingTimeHistories, trainingTimeHistoriesListId, projects, paymentData) =>
    _map(trainingTimeHistoriesListId, id => {
      const data = trainingTimeHistories[id] || {}
      const projectId = data?.details?.project ?? 0
      const paymentId = data?.details.payment_id ?? 0
      return {
        ...data,
        details: {
          ...data.details,
          paymentData: paymentData[paymentId],
          projectData: projects[projectId]
        }
      }
    })
)
const creditBalance = createSelector(creditOverview, overview => overview?.balance ?? 0)

const downloadImageListId = (state: RootState) => {
  return state.api.payment.downloadImage.list || []
}

const downloadImages = (state: RootState) => {
  return state.api.payment.downloadImage.data || {}
}

const downloadImageList = createSelector(
  downloadImages,
  downloadImageListId,
  (upscaleImages, upscaleImageListId) => upscaleImageListId.map(id => upscaleImages[id] || {})
)

const brainTreeToken = createSelector(payment, payment => payment.brainTreeToken)

const currentSubscriptionRaw = createSelector(payment, payment => payment.currentSubscription)

const currentSubscription = createSelector(
  userSelectors.equity,
  currentSubscriptionRaw,
  productsIdData,
  (equity, currentSubscription, productsIdData): Subscription | undefined => {
    return currentSubscription
      ? {
          ...currentSubscription,
          next_product_data: productsIdData[currentSubscription?.next_product ?? 0],
          product_data: productsIdData[currentSubscription?.product ?? 0],
          is_inactive: currentSubscription.status === 'INACTIVE',
          is_canceled:
            currentSubscription.status === 'SUSPENDED' ||
            currentSubscription.status === 'CANCELED' ||
            equity?.canceled
        }
      : undefined
  }
)

const paymentSelectors = {
  payment,
  productsData,
  productsIdData,
  currentSubscription,
  productsDataAddCredit,
  productNameToIdMap,
  paymentData,
  creditBalance,
  hasProducts,
  currentPaymentData,
  hasBraintreePaymentMethod,
  brainTreePaymentMethod,
  creditChangeLogList,
  isCreditChangeLogsHasNext,
  creditChangeLogListId,
  creditOverview,
  creditChangeLogs,
  downloadImageList,
  channels,
  hasChannels,
  channelData,
  brainTreeToken,
  downloadImages
}

export default paymentSelectors
