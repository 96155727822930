import Base from './Base'
import Grid from './Grid'
import Konva from 'konva'
import {
  gridUtils,
  GridStateManager,
  GridStateManagerType,
  GridState,
  AnimationStateManager,
  AnimationStateManagerType,
  DisplayedImage
} from './utils'
import { DEFAULT_ZOOM_LEVEL, InfinityGridProps } from '.'
import { Layer } from 'konva/lib/Layer'
import { Stage } from 'konva/lib/Stage'
import { Vector2d } from 'konva/lib/types'

Konva.pixelRatio = 1

export type Size = {
  width: number
  height: number
}

export type CanvasBuilderProps = {
  layer: Layer
  stage: Stage
  stageSize: Size
  zoom: number
  camera: Vector2d
  screenOffset: Vector2d
  maxZoomValue?: number
  debugMode: boolean
}

export type ParentListenerType = 'clickTapListener' | 'zoomListener' | 'cameraListener'
export type ParentListeners = { [key in ParentListenerType]?: Function }

export type ListenerType =
  | 'clickTapListener'
  | 'dragMoveListener'
  | 'zoomListener'
  | 'clickListener'

export type Listeners = { [key in ListenerType]?: Function }

export type CanvasBuilderConfig = Pick<CanvasBuilderProps, 'stageSize' | 'zoom'>

const CanvasBuilder = (props: CanvasBuilderProps) => {
  const {
    layer,
    stage,
    stageSize,
    zoom = DEFAULT_ZOOM_LEVEL,
    screenOffset,
    maxZoomValue = Infinity,
    debugMode
  } = props

  const initial: GridState = {
    stageSize,
    camera: gridUtils.getInitialCamera(zoom, stageSize),
    zoom,
    screenOffset,
    displayedImages: [[]],
    displayedImagesFlat: {},
    selectedImage: undefined
  }
  const gridState: GridStateManagerType = GridStateManager(initial)
  const animationState: AnimationStateManagerType = AnimationStateManager({ gridState })

  const parentListeners: ParentListeners = {}
  const listeners: Listeners = {
    dragMoveListener: (deltaCamera: Vector2d) => {
      const currentCamera = gridState.camera()
      const newCamera = gridState.camera({
        x: currentCamera.x + deltaCamera.x,
        y: currentCamera.y + deltaCamera.y
      })
      animationState.updateCameraPosition(newCamera)
      parentListeners?.cameraListener?.(newCamera, gridState.zoom(), 'drag move listener')
      grid.draw()
      base.draw()
    },
    clickTapListener: (position: Vector2d) => {
      const clickedGrid = grid.getGridCoordinate(position)
      parentListeners?.clickTapListener?.(clickedGrid)
    },
    zoomListener: (zoomValue: number, newCameraPos?: Vector2d) => {
      if (maxZoomValue > zoomValue) {
        const zoom = gridState.zoom(zoomValue)
        gridState.camera(newCameraPos ? newCameraPos : undefined)
        parentListeners?.cameraListener?.(newCameraPos, zoom, 'zoom')
        parentListeners?.zoomListener?.(zoom)
        grid.draw()
        base.draw()
      }
    }
  }

  //Initialize
  const base = Base({ listeners, layer, gridState, stage, animationState, debugMode })
  const grid = Grid({ debugMode, layer, gridState })
  layer.add(grid.get())
  layer.add(base.get())

  //Init animation
  const animation = new Konva.Animation(frame => {
    const isAnimate = animationState.animate({ frame })

    if (isAnimate) {
      parentListeners?.cameraListener?.(gridState.camera(), gridState.zoom(), 'animation move')
      grid.draw(true)
      base.draw(true)
    }
    return isAnimate
  }, layer)

  animation.start()
  return {
    setStageSize: (param: Size) => {
      gridState.stageSize({ ...param })
      grid.draw()
      base.draw()
    },
    updateImage: (input: DisplayedImage[][], inputFlat: GridState['displayedImagesFlat']) => {
      gridState.displayedImages(input)
      gridState.displayedImagesFlat(inputFlat)
      grid.updateImage()
      grid.draw()
      base.draw()
    },
    setZoom: (zoomValue: CanvasBuilderConfig['zoom']) => {
      const zoomCenter = {
        x: stageSize.width / 2,
        y: stageSize.height / 2
      }
      gridState.camera(
        gridUtils.getCameraPosAfterZoom({
          zoomCenter,
          newZoom: zoomValue,
          oldZoom: gridState.zoom(),
          camera: gridState.camera()
        })
      )
      const zoom = gridState.zoom(zoomValue)
      grid.draw()
      base.draw()
      parentListeners?.zoomListener?.(zoom)
    },
    goHome: () => {
      gridState.camera({ x: 20, y: 20 })
      gridState.zoom(DEFAULT_ZOOM_LEVEL)
      parentListeners?.cameraListener?.(gridState.camera(), gridState.zoom(), 'go home listener')
      parentListeners?.zoomListener?.(zoom)
      grid.draw()
      base.draw()
    },
    getZoom: () => {
      return gridState.zoom()
    },
    getCamera: () => {
      return gridState.camera()
    },
    setCamera: (camera: Vector2d) => {
      gridState.camera(camera)
      grid.draw()
      base.draw()
    },
    setScreenOffset: (screenOffset: Vector2d) => {
      gridState.screenOffset(screenOffset)
    },
    setSelectedImage: (selectedImage?: InfinityGridProps['selectedImage']) => {
      gridState.selectedImage(selectedImage || null)
      grid.draw()
    },
    setListener: (listenerType: ParentListenerType, listener: Function) => {
      parentListeners[listenerType] = listener
    }
  }
}
export default CanvasBuilder
