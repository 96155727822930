import { TextTransform, Format } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { merge } from 'rxjs'
import _compact from 'lodash/compact'
import {
  filter,
  withLatestFrom,
  mergeMap,
  map,
  take,
  startWith,
  tap,
  ignoreElements
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { of } from 'rxjs'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { SubscriptionProducts, values } from 'appConstants'
import {
  Product,
  Equity,
  UserUiExtrasType,
  SubscriptionUpdateReq,
  EquityType,
  Subscription
} from 'models/ApiModels'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import { createSelector } from 'reselect'
import {
  brainTreePaymentActions,
  brainTreePaymentSelector
} from 'containers/MonetizationDialog/duck'

// Constants
const NAMESPACE = '@@page/ProfilePage/Subscription'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const EquityWeightMap: { [key in Equity['type']]: number } = {
  free: 0,
  plus: 1,
  pro: 2,
  studio: 3
}

export type ButtonState = {
  disabled?: boolean
  isCurrentSubs: boolean
  showUpgrade: boolean
  showButton: boolean
}

export const Utils = {
  calculateDiscount: (priceNormal: number, priceDiscount: number) => {
    return Math.round(((priceNormal - priceDiscount) / priceNormal) * 100)
  },
  getPriceTextVersion: (productsData?: { [key: string]: Product }) => {
    const plusMonthly = productsData?.[SubscriptionProducts.PLUS_MONTHLY]
    const plusMonthlyPrice = plusMonthly?.price ?? 0
    const plusMonthlyCurrency = plusMonthly?.currency ?? values.BASE_CURRENCY
    const plusMonthlyPriceText = Format.currency(plusMonthlyPrice, plusMonthlyCurrency, 2)

    const plusYearly = productsData?.[SubscriptionProducts.PLUS_YEARLY]
    const plusYearlyPrice = plusYearly?.price ?? 0
    const plusYearlyCurrency = plusYearly?.currency ?? values.BASE_CURRENCY
    const plusYearlyPriceText = Format.currency(plusYearlyPrice, plusYearlyCurrency, 2)
    const plusYearlySave = plusMonthlyPrice * 12 - plusYearlyPrice
    const plusYearlySaveText = Format.currency(plusYearlySave, plusYearlyCurrency, 2)

    const proMonthly = productsData?.[SubscriptionProducts.PRO_MONTHLY]
    const proMonthlyPrice = proMonthly?.price ?? 0
    const proMonthlyCurrency = proMonthly?.currency ?? values.BASE_CURRENCY
    const proMonthlyPriceText = Format.currency(proMonthlyPrice, proMonthlyCurrency, 2)

    const proYearly = productsData?.[SubscriptionProducts.PRO_YEARLY]
    const proYearlyPrice = proYearly?.price ?? 0
    const proYearlyCurrency = proYearly?.currency ?? values.BASE_CURRENCY
    const proYearlyPriceText = Format.currency(proYearlyPrice, proYearlyCurrency, 2)
    const proYearlySave = proMonthlyPrice * 12 - proYearlyPrice
    const proYearlySaveText = Format.currency(proYearlySave, proYearlyCurrency, 2)

    const studioMonthly = productsData?.[SubscriptionProducts.STUDIO_MONTHLY]
    const studioMonthlyPrice = studioMonthly?.price ?? 0
    const studioMonthlyCurrency = studioMonthly?.currency ?? values.BASE_CURRENCY
    const studioMonthlyPriceText = Format.currency(studioMonthlyPrice, studioMonthlyCurrency, 2)

    const studioYearly = productsData?.[SubscriptionProducts.STUDIO_YEARLY]
    const studioYearlyPrice = studioYearly?.price ?? 0
    const studioYearlyCurrency = studioYearly?.currency ?? values.BASE_CURRENCY
    const studioYearlyPriceText = Format.currency(studioYearlyPrice, studioYearlyCurrency, 2)
    const studioYearlySave = studioMonthlyPrice * 12 - studioYearlyPrice
    const studioYearlySaveText = Format.currency(studioYearlySave, studioYearlyCurrency, 2)

    return {
      plusMonthlyPriceText,
      plusYearlyPriceText,
      plusYearlySaveText,

      proMonthlyPriceText,
      proYearlyPriceText,
      proYearlySaveText,

      studioMonthlyPriceText,
      studioYearlyPriceText,
      studioYearlySaveText
    }
  },
  getSubscriptionCost: (equityConfigsData?: ReturnType<typeof apiSelectors.equityConfigData>) => {
    const free = equityConfigsData?.free
    const plus = equityConfigsData?.plus
    const studio = equityConfigsData?.studio
    const pro = equityConfigsData?.pro

    const freeCostPerCreditText = Format.currency(
      free?.cost_per_credit ?? 0,
      values.BASE_CURRENCY,
      2
    )
    const plusCostPerCreditText = Format.currency(
      plus?.cost_per_credit ?? 0,
      values.BASE_CURRENCY,
      2
    )

    const studioCostPerCreditText = Format.currency(
      studio?.cost_per_credit ?? 0,
      values.BASE_CURRENCY,
      2
    )
    const proCostPerCreditText = Format.currency(pro?.cost_per_credit ?? 0, values.BASE_CURRENCY, 2)

    const plusCostPerCreditPercentText = Utils.calculateDiscount(
      free?.cost_per_credit ?? 0,
      plus?.cost_per_credit ?? 0
    )

    const proCostPerCreditPercentText = Utils.calculateDiscount(
      free?.cost_per_credit ?? 0,
      pro?.cost_per_credit ?? 0
    )
    const studioCostPerCreditPercentText = Utils.calculateDiscount(
      free?.cost_per_credit ?? 0,
      studio?.cost_per_credit ?? 0
    )

    const proTrainingCreditPerMonth = Format.creditAmount(pro?.monthly_credit ?? 0)
    const studioTrainingCreditPerMonth = Format.creditAmount(studio?.monthly_credit ?? 0)

    return {
      freeCostPerCreditText,

      plusCostPerCreditText,
      plusCostPerCreditPercentText,

      proCostPerCreditText,
      proCostPerCreditPercentText,

      studioCostPerCreditText,
      studioCostPerCreditPercentText,

      proTrainingCreditPerMonth,
      studioTrainingCreditPerMonth
    }
  },
  getButtonStates: (
    currentEquity: Equity['type'],
    currentSubscription?: Subscription
  ): {
    [key in Equity['type']]: ButtonState
  } => {
    const isInactive = currentSubscription?.status === 'INACTIVE'
    const productGrade = currentSubscription?.product_data?.grade ?? 'free'

    const equityAdjusted = isInactive ? productGrade : currentEquity

    const plusShowUpgrade = EquityWeightMap['plus'] > EquityWeightMap[equityAdjusted]
    const proShowUpgrade = EquityWeightMap['pro'] > EquityWeightMap[equityAdjusted]
    const studioShowUpgrade = EquityWeightMap['studio'] > EquityWeightMap[equityAdjusted]

    return {
      free: {
        isCurrentSubs: equityAdjusted === 'free',
        showUpgrade: false,
        showButton: equityAdjusted === 'free'
      },
      plus: {
        disabled: values.DISABLE_PLUS_SUBSCRIPTION,
        // disabled: false,
        isCurrentSubs: equityAdjusted === 'plus',
        showUpgrade: plusShowUpgrade,
        showButton: equityAdjusted === 'plus' || plusShowUpgrade
      },
      pro: {
        isCurrentSubs: equityAdjusted === 'pro',
        showUpgrade: proShowUpgrade,
        showButton: equityAdjusted === 'pro' || proShowUpgrade
      },
      studio: {
        isCurrentSubs: equityAdjusted === 'studio',
        showUpgrade: studioShowUpgrade,
        showButton: equityAdjusted === 'studio' || studioShowUpgrade
      }
    }
  }
}

export type SubscriptionStep = 'form' | 'success' | 'thankyou' | 'cancel'
export type SetStepParam = { step: SubscriptionStep; type?: Equity['type'] }

// Actions
export const subscriptionActions = {
  setSelectedPlan: createAction(creator('SET_SELECTED_PLAN'))<number | undefined>(),
  setStep: createAction(creator('SET_STEP'))<SetStepParam | undefined>(),
  setIsFirstTime: createAction(creator('SET_IS_FIRST_TIME'))<boolean>(),
  setIsDowngrade: createAction(creator('SET_IS_DOWNLOAD'))<boolean>(),

  cancelSubscription: createAction(creator('CANCEL_SUBSCRIPTION'))(),
  upgradeSubscription: createAction(creator('UPGRADE_SUBSCRIPTION'))(),
  executeUpgradeSubscription: createAction(creator('EXECUTE_UPGRADE_SUBSCRIPTION'))<
    Partial<SubscriptionUpdateReq>
  >(),
  afterUpgradeSubscription: createAction(creator('AFTER_UPGRADE_SUBSCRIPTION'))<{
    currentEquity: Equity
    previousEquity?: Equity
    currentSubscription: EquityType
    nextSubscription: EquityType
  }>(),
  initSubscriptionPanel: createAction(creator('INIT_SUBSCRIPTION_PANEL'))(),
  resetSubscriptionPage: createAction(creator('RESET_SUBSCRIPTION_PAGE'))()
}

// Selectors
const selectSubscription = (state: RootState) => state.container.profilePage.subscription

export const subscriptionSelectors = {
  subscription: selectSubscription,
  getSubscription: createSelector(apiSelectors.equityConfigData, equityConfigsData => {
    const { free, plus, studio, pro } = equityConfigsData

    const freeCostPerCreditText = Format.currency(
      free?.cost_per_credit ?? 0,
      values.BASE_CURRENCY,
      2
    )
    const plusCostPerCreditText = Format.currency(
      plus?.cost_per_credit ?? 0,
      values.BASE_CURRENCY,
      2
    )

    const proCostPerCreditPercentText = Utils.calculateDiscount(
      free?.cost_per_credit ?? 0,
      pro?.cost_per_credit ?? 0
    )
    const studioCostPerCreditPercentText = Utils.calculateDiscount(
      free?.cost_per_credit ?? 0,
      studio?.cost_per_credit ?? 0
    )

    const proTrainingCreditPerMonth = Format.creditAmount(pro?.monthly_credit ?? 0)
    const studioTrainingCreditPerMonth = Format.creditAmount(studio?.monthly_credit ?? 0)

    return {
      freeCostPerCreditText,
      plusCostPerCreditText,
      proCostPerCreditPercentText,
      studioCostPerCreditPercentText,

      proTrainingCreditPerMonth,
      studioTrainingCreditPerMonth
    }
  })
}

// Reducer
export type SubscriptionState = {
  step?: SetStepParam
  selectedPlan?: number
  isFirstTime?: boolean
  isDowngrade?: boolean
}

export const initial: SubscriptionState = {
  step: undefined,
  isFirstTime: undefined,
  selectedPlan: undefined,
  isDowngrade: false
}

const reducer = produce((state: SubscriptionState, { type, payload }) => {
  switch (type) {
    case getType(subscriptionActions.setSelectedPlan): {
      const selectedPlan = payload as ActionType<
        typeof subscriptionActions.setSelectedPlan
      >['payload']
      state.selectedPlan = selectedPlan
      return
    }
    case getType(subscriptionActions.setStep): {
      const step = payload as ActionType<typeof subscriptionActions.setStep>['payload']
      state.step = step
      return
    }
    case getType(subscriptionActions.setIsDowngrade): {
      const isDowngrade = payload as ActionType<
        typeof subscriptionActions.setIsDowngrade
      >['payload']
      state.isDowngrade = isDowngrade
      return
    }
    case getType(subscriptionActions.setIsFirstTime): {
      const isFirstTime = payload as ActionType<
        typeof subscriptionActions.setIsFirstTime
      >['payload']

      state.isFirstTime = isFirstTime
      return
    }
    case getType(subscriptionActions.resetSubscriptionPage): {
      state.selectedPlan = initial.selectedPlan
      state.step = initial.step
      state.isDowngrade = initial.isDowngrade
      state.isFirstTime = initial.isFirstTime
      return
    }
    default:
  }
}, initial)

// Epics
const initSubscriptionPanelEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(subscriptionActions.initSubscriptionPanel)),
    withLatestFrom(state$),
    tap(([_, state]) => {
      const equity = apiSelectors.equity(state)
      MixPanelUtils.track<'USER__OPEN_SUBSCRIBE_PAGE'>('User - Open Subscribe Page', {
        current_subscription: DataUtils.getSubscriptionType(equity)
      })
    }),
    mergeMap(([_, state]) =>
      merge(
        of(state).pipe(
          filter(() => !apiSelectors.hasProducts(state)),
          map(() => apiActions.payment.retrieveProducts())
        ),
        of(state).pipe(
          filter(() => !apiSelectors.hasChannels(state)),
          map(() => apiActions.payment.retrieveChannels())
        ),
        of(apiActions.payment.listSubscription()),
        of(state).pipe(
          filter(
            () =>
              !apiSelectors.hasEquityConfigs(state) &&
              !apiSelectors.loading['users.retrieveEquityConfig'](state)
          ),
          map(() => apiActions.users.retrieveEquityConfig())
        )
      )
    )
  )

const setStepSetOpenTableEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf([subscriptionActions.setStep])),
    withLatestFrom(state$),
    map(([_, state]) => ({
      equity: apiSelectors.equity(state),
      subscription: subscriptionSelectors.subscription(state)
    })),
    tap(({ equity, subscription }) => {
      if (subscription?.step?.type) {
        MixPanelUtils.track<'USER__OPEN_SUBSCRIBE_FORM'>('User - Open Subscribe Form', {
          current_subscription: DataUtils.getSubscriptionType(equity),
          subscription_form_type: subscription?.step?.type
        })
      }
    }),
    map(({ subscription }) => subscription),
    ignoreElements()
  )

const setStepEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(subscriptionActions.setStep)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      productNameToIdMap: apiSelectors.productNameToIdMap(state),
      currentSubscription: apiSelectors.currentSubscription(state),
      equity: apiSelectors.equity(state),
      step: action.payload?.step,
      selectedType: action.payload?.type
    })),
    filter(
      ({ step, selectedType }) =>
        step === 'form' && ['pro', 'plus', 'studio'].includes(selectedType ?? '')
    ),
    map(({ productNameToIdMap, equity, selectedType, currentSubscription }) => {
      const currentType = equity?.type

      if (currentType === selectedType) {
        return currentSubscription?.product
      } else {
        if (selectedType === 'plus') {
          return productNameToIdMap['playform_plus_yearly']
        }
        if (selectedType === 'pro') {
          return productNameToIdMap['playform_pro_yearly']
        }
        if (selectedType === 'studio') {
          return productNameToIdMap['playform_studio_yearly']
        }
      }
      return undefined
    }),
    mergeMap(id => _compact([id && subscriptionActions.setSelectedPlan(id)]))
  )

const upgradeSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(subscriptionActions.upgradeSubscription)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      subscription: subscriptionSelectors.subscription(state),
      hasBraintreePaymentMethod: apiSelectors.hasBraintreePaymentMethod(state),
      currentSubscription: apiSelectors.currentSubscription(state),
      brainTreePayment: brainTreePaymentSelector.brainTreePayment(state)
    })),
    map(({ subscription, currentSubscription, hasBraintreePaymentMethod, brainTreePayment }) => ({
      currentSubscription,
      product: subscription.selectedPlan,
      useCurrentPaymentMethod: hasBraintreePaymentMethod && !brainTreePayment.isChangePaymentMethod
    })),
    filter(({ product }) => Boolean(product)),
    mergeMap(param =>
      merge(
        /* Use existing card 
           - The payment_method variable is not required here, 
           because backend will use the one that already saved
        */
        of(param).pipe(
          filter(({ useCurrentPaymentMethod }) => useCurrentPaymentMethod),
          map(({ product = 0, currentSubscription }) =>
            subscriptionActions.executeUpgradeSubscription({
              product,
              id: currentSubscription?.id ?? undefined
            })
          )
        ),
        //Create new card (new payment method)
        of(param).pipe(
          filter(({ useCurrentPaymentMethod }) => !useCurrentPaymentMethod),
          mergeMap(({ product = 0, currentSubscription }) =>
            action$.pipe(
              filter(isActionOf(brainTreePaymentActions.submitCardPaymentMethodResponse)),
              take(1),
              map(() =>
                subscriptionActions.executeUpgradeSubscription({
                  product,
                  id: currentSubscription?.id ?? undefined
                })
              ),
              startWith(brainTreePaymentActions.submitCardPaymentMethod({ isSubscription: true }))
            )
          )
        )
      )
    )
  )

const executeUpgradeSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(subscriptionActions.executeUpgradeSubscription)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      currentEquity: apiSelectors.equity(state),
      productsIdData: apiSelectors.productsIdData(state),
      currentSubscription: apiSelectors.currentSubscription(state),
      param: action.payload
    })),
    /* 
      If free , then use create subscription
      if other than free, use change subscription
    */
    map(({ productsIdData, currentSubscription, currentEquity, param }) => ({
      currentEquity,
      param,
      nextEquity: productsIdData[param.product ?? 0]?.grade,
      shouldUsingCreate:
        currentEquity?.type === 'free' && currentSubscription?.status !== 'INACTIVE'
    })),
    mergeMap(data =>
      merge(
        of(data).pipe(
          filter(({ shouldUsingCreate }) => shouldUsingCreate),
          mergeMap(({ param, currentEquity, nextEquity }) =>
            action$.pipe(
              filter(isActionOf(apiActions.payment.createSubscriptionResponse)),
              take(1),
              mergeMap(() =>
                action$.pipe(
                  filter(isActionOf(apiActions.users.retrieveEquityResponse)),
                  take(1),
                  tap(({ payload }) => {
                    const current_subscription = DataUtils.getSubscriptionType(payload)
                    MixPanelUtils.track<'USER__UPDATE_SUBSCRIPTION'>('User - Update Subscription', {
                      current_subscription,
                      prev_subscription: DataUtils.getSubscriptionType(currentEquity)
                    })

                    current_subscription === 'plus' &&
                      FacebookPixelUtils.track<'BUY_SUBSCRIPTION_PLUS'>('buy_subscription_plus')
                    current_subscription === 'pro' &&
                      FacebookPixelUtils.track<'BUY_SUBSCRIPTION_PRO'>('buy_subscription_pro')
                    current_subscription === 'studio' &&
                      FacebookPixelUtils.track<'BUY_SUBSCRIPTION_STUDIO'>('buy_subscription_studio')
                  }),
                  map(({ payload }) =>
                    subscriptionActions.afterUpgradeSubscription({
                      currentEquity: payload,
                      previousEquity: currentEquity,
                      currentSubscription: currentEquity?.type ?? 'free',
                      nextSubscription: nextEquity ?? 'free'
                    })
                  ),
                  startWith(apiActions.users.retrieveEquity())
                )
              ),
              startWith(apiActions.payment.createSubscription({ product: param.product ?? 0 }))
            )
          )
        ),
        of(data).pipe(
          filter(({ shouldUsingCreate }) => !shouldUsingCreate),
          mergeMap(({ param, currentEquity, nextEquity }) =>
            action$.pipe(
              filter(isActionOf(apiActions.payment.updateSubscriptionResponse)),
              take(1),
              mergeMap(() =>
                action$.pipe(
                  filter(isActionOf(apiActions.users.retrieveEquityResponse)),
                  take(1),
                  tap(({ payload }) => {
                    MixPanelUtils.track<'USER__UPDATE_SUBSCRIPTION'>('User - Update Subscription', {
                      current_subscription: DataUtils.getSubscriptionType(payload),
                      prev_subscription: DataUtils.getSubscriptionType(currentEquity)
                    })
                  }),
                  mergeMap(({ payload }) => [
                    subscriptionActions.afterUpgradeSubscription({
                      currentEquity: payload,
                      previousEquity: currentEquity,
                      currentSubscription: currentEquity?.type ?? 'free',
                      nextSubscription: nextEquity
                    }),
                    apiActions.payment.listSubscription()
                  ]),
                  startWith(apiActions.users.retrieveEquity())
                )
              ),
              startWith(
                apiActions.payment.updateSubscription({
                  product: param.product ?? 0,
                  id: param.id ?? 0
                })
              )
            )
          )
        )
      )
    )
  )

const afterUpgradeSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(subscriptionActions.afterUpgradeSubscription)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      previousEquity: action.payload.previousEquity,
      currentEquity: action.payload.currentEquity,
      nextSubscription: action.payload.nextSubscription,
      currentSubscription: action.payload.currentSubscription,
      userUiExtras: apiSelectors.user(state)?.ui_extras,
      is_did_subscribe_plus: apiSelectors.user(state)?.ui_extras?.is_did_subscribe_plus,
      is_did_subscribe_pro: apiSelectors.user(state)?.ui_extras?.is_did_subscribe_pro,
      is_did_subscribe_studio: apiSelectors.user(state)?.ui_extras?.is_did_subscribe_studio
    })),
    map(
      ({
        is_did_subscribe_plus,
        is_did_subscribe_pro,
        is_did_subscribe_studio,
        currentEquity,
        nextSubscription,
        currentSubscription,
        previousEquity
      }) => {
        const didSubscribeParamMap: {
          [key in Equity['type']]: Partial<UserUiExtrasType> | undefined
        } = {
          free: undefined,
          plus: { is_did_subscribe_plus: true },
          pro: { is_did_subscribe_pro: true },
          studio: { is_did_subscribe_studio: true }
        }

        const isDidSubscribeMap: {
          [key in Equity['type']]: boolean | undefined
        } = {
          free: undefined,
          plus: is_did_subscribe_plus,
          pro: is_did_subscribe_pro,
          studio: is_did_subscribe_studio
        }

        return {
          nextSubscription,
          currentSubscription,
          didSubscribeParamMap,
          isDidSubscribeMap,
          currentEquityType: currentEquity.type,
          previousEquityType: previousEquity?.type ?? 'free',
          currentEquityInterval: currentEquity.interval,
          previousEquityInterval: previousEquity?.interval
        }
      }
    ),
    map(
      ({
        isDidSubscribeMap,
        didSubscribeParamMap,
        currentEquityType,
        previousEquityType,
        currentEquityInterval,
        previousEquityInterval,
        nextSubscription,
        currentSubscription
      }) => ({
        didSubscribeParam: didSubscribeParamMap[currentEquityType],
        isDidSubscribe: isDidSubscribeMap[currentEquityType],
        isDowngrade: EquityWeightMap[nextSubscription] < EquityWeightMap[currentSubscription],
        isUpgrade: EquityWeightMap[nextSubscription] > EquityWeightMap[currentSubscription],
        isChangeBilling:
          currentEquityType !== 'free' &&
          currentEquityType === previousEquityType &&
          currentEquityInterval !== previousEquityInterval
      })
    ),
    mergeMap(({ isDidSubscribe, didSubscribeParam, isDowngrade, isUpgrade, isChangeBilling }) =>
      _compact([
        apiActions.payment.retrieveCreditOverview(),
        subscriptionActions.setIsFirstTime(!isDidSubscribe),
        subscriptionActions.setStep({ step: 'success' }),
        (isUpgrade || isDowngrade) && subscriptionActions.setStep({ step: 'success' }),
        isDowngrade && subscriptionActions.setIsDowngrade(true),
        isChangeBilling && subscriptionActions.setStep({ step: 'thankyou' }),
        !isDidSubscribe && didSubscribeParam && apiActions.users.updateUiExtras(didSubscribeParam)
      ])
    )
  )

const cancelSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(subscriptionActions.cancelSubscription)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      currentSubscription: apiSelectors.currentSubscription(state)
    })),
    filter(({ currentSubscription }) => Boolean(currentSubscription?.id)),
    mergeMap(({ currentSubscription }) =>
      action$.pipe(
        filter(isActionOf(apiActions.payment.cancelSubscriptionResponse)),
        take(1),
        withLatestFrom(state$),
        tap(([_, state]) => {
          const currentEquity = apiSelectors.equity(state)
          MixPanelUtils.track<'USER__CANCEL_SUBSCRIPTION'>('User - Cancel Subscription', {
            current_subscription: DataUtils.getSubscriptionType(currentEquity)
          })
        }),
        mergeMap(() => [
          apiActions.users.retrieveEquity(),
          apiActions.payment.retrieveCreditOverview(),
          subscriptionActions.setStep(undefined),
          apiActions.payment.listSubscription()
        ]),
        startWith(apiActions.payment.cancelSubscription(currentSubscription?.id ?? 0))
      )
    )
  )

export const subscriptionEpic = combineEpics(
  setStepEpic,
  afterUpgradeSubscriptionEpic,
  initSubscriptionPanelEpic,
  setStepSetOpenTableEpic,
  cancelSubscriptionEpic,
  upgradeSubscriptionEpic,
  executeUpgradeSubscriptionEpic
)
export default reducer
