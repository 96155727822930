import React from 'react'
import { route, MAIN_PAGE } from 'routes'
import { Link as RouterLink } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'

import Button from 'components/Button'
import { ImgPure } from 'components/Img'
import LogoIcon from 'components/Icon/LogoIcon'
import Logo from 'assets/images/logo.svg'
import { ActionKeyCreator } from 'utils/TextUtils'
import { WelcomePageState } from 'containers/WelcomePage/duck'

import styles from './NavBar.module.scss'

const LogoWrapper: React.FC = () => (
  <RouterLink className={styles.LogoContainer} to={MAIN_PAGE}>
    <LogoIcon className={styles.NavbarGeneralLogoMobile} alt="Playform" />
    <ImgPure src={Logo} className={styles.NavbarGeneralLogo} alt="Playform" title="Playform" />
  </RouterLink>
)

type NavBarGeneralProps = {}

const param: Pick<WelcomePageState, 'view'> = {
  view: 'start-project-panel'
}

const action = `/?action=${ActionKeyCreator.generate(param)}`

const NavbarFirstProject: React.FC<NavBarGeneralProps> = () => {
  return (
    <AppBar className={styles.NavBarInner} position="relative">
      <div className="relative flex h-full w-full justify-between">
        <LogoWrapper />
        <div className={styles.NavBarButtonContainer}>
          <Button to={`${route.WELCOME.getUrl()}${action}`} variant="text">
            <Typography variant="button">BACK TO PROJECT SELECT</Typography>
          </Button>
          <Button to={MAIN_PAGE} variant="text">
            <Typography variant="button">SKIP TO DASHBOARD</Typography>
          </Button>
        </div>
      </div>
    </AppBar>
  )
}

export default NavbarFirstProject
