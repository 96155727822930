import * as ApiUtils from './ApiUtils'
import * as FirebaseUtils from './FirebaseUtils'
import * as TextUtils from './TextUtils'
import * as DataProcessingUtils from './DataProcessingUtils'
import * as Hooks from './Hooks'
import * as LocalStorage from './LocalStorage'
import * as SessionStorage from './SessionStorage'

const emptyFunction = () => {}
export {
  emptyFunction,
  ApiUtils,
  FirebaseUtils,
  TextUtils,
  DataProcessingUtils,
  Hooks,
  LocalStorage,
  SessionStorage
}
