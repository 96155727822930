import {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo
  // useEffect,
  // useRef,
  // RefObject
} from 'react'
import ListComponent, {
  ListProps as ListComponentProps,
  ListRowRenderer
} from 'react-virtualized/dist/commonjs/List'
import CellMeasurer, { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer'
// import { OverscanIndicesGetter } from 'react-virtualized/dist/commonjs/Grid'
// import { usePrevious } from 'utils/Hooks'
import { toNumber } from 'utils/NumberUtils'
import { ListConfigType, OnScrollParams } from '../../index'
import { FixedSizeListProps } from '../index'

const DEFAULT_COLUMN_WIDTH = 0

type GridProps<Item> = Pick<
  ListComponentProps,
  | 'height'
  | 'isScrolling'
  | 'noRowsRenderer'
  | 'onScroll'
  | 'overscanRowCount'
  | 'scrollTop'
  | 'width'
> &
  Pick<FixedSizeListProps<Item>, 'debug' | 'renderItem'> & {
    breakpoint?: string
    config: ListConfigType
    // containerRef: RefObject<HTMLDivElement | null>
    dataLength: number
    onBottom?: () => void
    setRef?: React.MutableRefObject<ListComponent | null>
  }

function overscanIndicesGetter(props: any) {
  const {
    cellCount, // Number of rows or columns in the current axis
    overscanCellsCount, // Maximum number of cells to over-render in either direction
    startIndex, // Begin of range of visible cells
    stopIndex // End of range of visible cells
  } = props

  return {
    overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
    overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount)
  }
}

function List<Item>(props: GridProps<Item>) {
  const {
    breakpoint,
    config,
    // containerRef,
    dataLength,
    // debug,
    height,
    isScrolling,
    overscanRowCount,
    scrollTop,
    setRef,
    width,

    onBottom,
    noRowsRenderer,
    onScroll,
    renderItem
  } = props

  /* Config */
  const configColumn = config.columns
  const configSquare = config.square
  const configDefaultRowHeight = config.defaultRowHeight
  const configRowHeight = toNumber(config.rowHeight)

  // const dataLength = data?.length || 0
  // const scrollData = useRef<{
  //   currentOverscanStopIndex: number
  //   currentStopIndex: number
  // }>({ currentOverscanStopIndex: 0, currentStopIndex: 0 })

  // const listScrollableElement = containerRef.current?.children[0]?.children[0] as HTMLElement
  const scrollbarSize = 0
  // useMemo(() => {
  //   if (listScrollableElement) {
  //     return listScrollableElement?.offsetWidth - listScrollableElement?.clientWidth
  //   }

  //   return 0
  // }, [listScrollableElement])

  const adjustedWidth =
    config.maxWidth && width > config.maxWidth ? toNumber(config.maxWidth) : width - scrollbarSize
  const gutter = toNumber(config.gutter ?? 0)

  const adjustedOffsetLeft =
    config.maxWidth && width > config.maxWidth ? (width - scrollbarSize - adjustedWidth) / 2 : 0

  const paddingY = toNumber(config.paddingY) || 0
  const paddingTop = toNumber(config.paddingTop || config.paddingY) || 0
  const paddingBottom = toNumber(config.paddingBottom || config.paddingY) || 0

  const paddingX = toNumber(config.paddingX) || 0
  const paddingLeft = toNumber(config.paddingLeft || config.paddingX) || 0
  const paddingRight = toNumber(config.paddingRight || config.paddingX) || 0

  const columnCount = Math.max(
    1,
    configColumn
      ? configColumn
      : config.defaultColumnWidth
        ? Math.floor(
            adjustedWidth / (toNumber(config.defaultColumnWidth) + gutter || DEFAULT_COLUMN_WIDTH)
          )
        : 1
  )

  const adjustedColumnWidth = Math.floor(
    (adjustedWidth -
      (!adjustedOffsetLeft ? (paddingLeft || paddingX) + (paddingRight || paddingX) : 0) -
      gutter * (columnCount - 1)) /
      columnCount
  )
  const rowCount = Math.ceil(dataLength / columnCount)

  const cache = useMemo(() => {
    if (!width && !breakpoint) return {} as CellMeasurerCache

    return new CellMeasurerCache({
      defaultWidth: adjustedWidth,
      defaultHeight: configSquare ? adjustedWidth : configRowHeight || configDefaultRowHeight,
      minHeight: configSquare ? adjustedWidth : configRowHeight || configDefaultRowHeight,
      fixedWidth: true,
      keyMapper: configSquare || configRowHeight ? () => 1 : undefined // If has fixed row height, optimize
    })
  }, [adjustedWidth, configDefaultRowHeight, configRowHeight, width, configSquare, breakpoint])

  const hasFixedRowHeight = !configSquare ? Boolean(configRowHeight) : true
  const adjustedRowHeight = toNumber(
    configSquare ? adjustedColumnWidth : hasFixedRowHeight ? configRowHeight : undefined
  )

  const rowRenderer = useCallback<ListRowRenderer>(
    props => {
      const { index, key, parent, style } = props

      const contentHeight = adjustedRowHeight

      const renderContent = (
        registerChild?: ((element?: React.ReactNode) => void) | undefined,
        measure?: () => void
      ) => {
        const isMultiColumn = columnCount > 1

        if (isMultiColumn) {
          const adjustedIndex = index * columnCount
          const Components: ReactNode[] = []

          for (let i = 0; i < columnCount; i++) {
            const styleCell = gutter
              ? {
                  padding: gutter / 2 || undefined
                }
              : undefined

            Components.push(
              <div key={`${key}-${i}`} className="relative h-full w-full" style={styleCell}>
                {renderItem({
                  index: adjustedIndex + i,
                  measure,
                  width: adjustedColumnWidth,
                  height: contentHeight
                })}
              </div>
            )
          }

          const rowStyle = { ...style }
          const rowInnerStyle: CSSProperties = {}

          if (paddingTop || paddingY || paddingLeft || paddingRight || paddingX || gutter) {
            rowStyle.top = toNumber(style.top) + (paddingTop || paddingY) - gutter / 2
            rowStyle.paddingLeft = adjustedOffsetLeft
              ? adjustedOffsetLeft
              : toNumber(style.left) + (paddingLeft || paddingX)
            rowStyle.paddingRight = adjustedOffsetLeft
              ? adjustedOffsetLeft
              : toNumber(style.left) + (paddingRight || paddingX)

            if (gutter) {
              rowInnerStyle.left = -(gutter / 2)
              rowInnerStyle.right = -(gutter / 2)
              rowInnerStyle.width = gutter ? `calc(100% + ${gutter}px)` : '100%'
            }
          }

          return (
            <div key={key} ref={registerChild as any} style={rowStyle} className="box-border">
              <div className="relative flex h-full" style={rowInnerStyle}>
                {Components}
              </div>
            </div>
          )
        }

        // Render Content with single column

        return (
          <div
            key={key}
            ref={registerChild as any}
            className="box-border"
            style={{
              ...style,
              top: toNumber(style.top) + (paddingTop || paddingY) - gutter / 2,
              left: adjustedOffsetLeft
                ? adjustedOffsetLeft
                : toNumber(style.left) + (paddingLeft || paddingX),
              width: adjustedColumnWidth,
              height: contentHeight ? contentHeight + gutter : style.height,
              paddingTop: gutter ? gutter / 2 : undefined,
              paddingBottom: gutter ? gutter / 2 : undefined
            }}
          >
            {renderItem({
              index,
              measure,
              width: adjustedColumnWidth,
              height: contentHeight || toNumber(style.height)
            })}
          </div>
        )
      }

      if (contentHeight) return renderContent()

      return (
        <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
          {({ measure, registerChild }) => {
            return renderContent(registerChild, measure)
          }}
        </CellMeasurer>
      )
    },
    [
      adjustedOffsetLeft,
      adjustedRowHeight,
      cache,
      columnCount,
      gutter,
      adjustedColumnWidth,
      paddingRight,
      paddingLeft,
      paddingX,
      paddingY,
      paddingTop,
      renderItem
    ]
  )

  // const listRefCurrent = setRef?.current
  // const listContainerOffsetTop = containerRef?.current?.offsetTop
  // const listContainerOffsetTopPrevious = usePrevious(listContainerOffsetTop)
  // const isListOffsetTopChange = listContainerOffsetTop !== listContainerOffsetTopPrevious

  // const dataLengthPrevious = usePrevious(dataLength)
  // const isDataChange = dataLength !== dataLengthPrevious

  // const widthPrevious = usePrevious(width)
  // const isWidthChange = width !== widthPrevious

  const containerInnerStyle: CSSProperties | undefined = useMemo(() => {
    const containerInnerStyle: CSSProperties = {
      boxSizing: 'content-box'
    }

    if (
      (paddingY || paddingTop || paddingBottom || gutter) &&
      // adjustedRowHeight &&
      rowCount
    ) {
      if (paddingY || paddingTop || paddingBottom || gutter) {
        // const adjustedHeight =
        // (adjustedRowHeight + gutter) * rowCount - gutter + (paddingTop || paddingY)
        // containerInnerStyle.maxHeight = adjustedHeight
        // containerInnerStyle.height = adjustedHeight
        containerInnerStyle.paddingTop = (paddingTop || paddingY || 0) - gutter ? gutter / 2 : 0
      }

      if (paddingY || paddingBottom)
        containerInnerStyle.paddingBottom = (paddingBottom || paddingY) - gutter / 2
    }

    return containerInnerStyle
  }, [
    // adjustedRowHeight,
    paddingBottom,
    paddingTop,
    paddingY,
    gutter,
    rowCount
  ])

  const handleScroll = useCallback(
    (props: OnScrollParams) => {
      onScroll?.(props)
      if (onBottom && props.scrollTop + props.clientHeight >= props.scrollHeight - 200) onBottom()
    },
    [onBottom, onScroll]
  )

  // const handleRowsRendered = useCallback(
  //   (params: any) => {
  //     scrollData.current.currentOverscanStopIndex = params.overscanStopIndex
  //     scrollData.current.currentStopIndex = params.stopIndex
  //   },
  //   [scrollData]
  // )

  // useEffect(() => {
  //   if (
  //     // isListOffsetTopChange ||
  //     isDataChange ||
  //     isWidthChange
  //   ) {
  //     /* Refresh cache after resize or data change */
  //     // if (!hasFixedRowHeight) cache.clearAll()
  //     listRefCurrent?.recomputeRowHeights()
  //   }
  // }, [listRefCurrent, isListOffsetTopChange, cache, isDataChange, isWidthChange, hasFixedRowHeight])

  return (
    <ListComponent
      ref={setRef}
      estimatedColumnSize={adjustedWidth}
      estimatedRowSize={configSquare ? adjustedWidth : configRowHeight || configDefaultRowHeight}
      className="outline-none"
      deferredMeasurementCache={!hasFixedRowHeight ? cache : undefined}
      rowRenderer={rowRenderer}
      height={height}
      isScrolling={isScrolling}
      noRowsRenderer={noRowsRenderer}
      onScroll={onScroll || onBottom ? handleScroll : undefined}
      // onRowsRendered={onBottom ? handleRowsRendered : undefined}
      overscanRowCount={overscanRowCount}
      overscanIndicesGetter={overscanIndicesGetter}
      rowCount={rowCount}
      rowHeight={!hasFixedRowHeight ? cache.rowHeight : adjustedRowHeight + gutter}
      scrollTop={scrollTop}
      width={width}
      containerStyle={containerInnerStyle}
    />
  )
}

export default List
