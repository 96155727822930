import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _compact from 'lodash/compact'
import { of, from, merge } from 'rxjs'
import { combineEpics, Epic } from 'redux-observable'
import Braintree from 'braintree-web'
import {
  filter,
  map,
  mergeMap,
  take,
  startWith,
  tap,
  catchError,
  delay,
  ignoreElements,
  withLatestFrom,
  concatMap
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, isActionOf, getType, createAction } from 'typesafe-actions'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import SentryUtils from 'utils/SentryUtils'
import { dialogActions, ConfirmationDialog } from 'duck/AppDuck/DialogDuck'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
import MixPanelUtils from 'utils/MixPanelUtils'

// Constants
const NAMESPACE = '@@page/MonetizationDialog/BRAINTREE_PAYMENT'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const brainTreePaymentActions = {
  initBrainTree: createAction(creator('INIT_BRAINTREE'))(),

  getDeviceData: createAction(creator('GET_DEVICE_DATA'))(),
  getDeviceDataResponse: createAction(creator('GET_DEVICE_DATA_RESPONSE'))<string>(),

  setHasEmpty: createAction(creator('SET_HAS_EMPTY'))<boolean>(),
  setCardError: createAction(creator('SET_CARD_ERROR'))<string | undefined>(),
  getNonce: createAction(creator('GET_NONCE'))(),
  getNonceResponse: createAction(creator('GET_NONCE_RESPONSE'))<{
    nonce?: string
    error?: Braintree.BraintreeError
  }>(),
  setClientInstance: createAction(creator('SET_CLIENT_INSTANCE'))<Braintree.Client>(),

  submitPaypalPaymentMethod: createAction(creator('SUBMIT_PAYPAL_PAYMENT_METHOD'))<{
    nonce: string
    isSubscription?: boolean
  }>(),
  submitCardPaymentMethod: createAction(creator('SUBMIT_CARD_PAYMENT_METHOD'))<
    { isSubscription: boolean } | undefined
  >(),
  submitCardPaymentMethodResponse: createAction(creator('SUBMIT_CARD_PAYMENT_METHOD_RESPONSE'))(),

  setChangePaymentMethod: createAction(creator('SET_CHANGE_PAYMENT_METHOD'))<boolean>(),
  setCardLoading: createAction(creator('SET_CARD_LOADING'))<boolean>(),
  removePaymentMethod: createAction(creator('REMOVE_PAYMENT_METHOD'))(),
  executeRemovePaymentMethod: createAction(creator('EXECUTE_REMOVE_PAYMENT_METHOD'))(),
  resetData: createAction(creator('RESET_DATA'))(),
  reactivateSubscription: createAction(creator('REACTIVATE_SUBSCRIPTION'))()
}

// Selectors
const selectBrainTreePayment = (state: RootState) =>
  state.container.monetizationDialog.brainTreePayment

export const brainTreePaymentSelector = {
  brainTreePayment: selectBrainTreePayment
}

// Reducer
export type BraintreePaymentState = {
  shouldGetNonce: boolean
  hasEmpty: boolean
  cardError?: string
  cardLoading?: boolean | string
  clientInstance?: Braintree.Client
  isChangePaymentMethod: boolean
  deviceData?: string
}

export const BRAIN_TREE_PAYMENT_STATE: BraintreePaymentState = {
  shouldGetNonce: false,
  hasEmpty: true,
  clientInstance: undefined,
  cardError: undefined,
  cardLoading: false,
  isChangePaymentMethod: false,
  deviceData: undefined
}

const reducer = produce((state: BraintreePaymentState, { type, payload }) => {
  switch (type) {
    case getType(brainTreePaymentActions.setClientInstance): {
      const value = payload as ActionType<
        typeof brainTreePaymentActions.setClientInstance
      >['payload']

      state.clientInstance = value
      return
    }
    case getType(brainTreePaymentActions.setCardError): {
      const cardError = payload as ActionType<
        typeof brainTreePaymentActions.setCardError
      >['payload']

      state.cardError = cardError
      return
    }
    case getType(brainTreePaymentActions.setHasEmpty): {
      const hasEmpty = payload as ActionType<typeof brainTreePaymentActions.setHasEmpty>['payload']
      state.hasEmpty = hasEmpty
      return
    }
    case getType(brainTreePaymentActions.setChangePaymentMethod): {
      const isChangePaymentMethod = payload as ActionType<
        typeof brainTreePaymentActions.setChangePaymentMethod
      >['payload']

      state.isChangePaymentMethod = isChangePaymentMethod
      return
    }
    case getType(brainTreePaymentActions.setCardLoading): {
      const cardLoading = payload as ActionType<
        typeof brainTreePaymentActions.setCardLoading
      >['payload']

      state.cardLoading = cardLoading
      return
    }
    case getType(brainTreePaymentActions.getNonce): {
      state.shouldGetNonce = true
      state.cardLoading = true
      return
    }
    case getType(brainTreePaymentActions.getNonceResponse): {
      state.shouldGetNonce = false
      state.cardLoading = false
      return
    }

    case getType(brainTreePaymentActions.resetData): {
      state.shouldGetNonce = BRAIN_TREE_PAYMENT_STATE.shouldGetNonce
      state.hasEmpty = BRAIN_TREE_PAYMENT_STATE.hasEmpty
      state.clientInstance = BRAIN_TREE_PAYMENT_STATE.clientInstance
      state.cardError = BRAIN_TREE_PAYMENT_STATE.cardError
      state.cardLoading = BRAIN_TREE_PAYMENT_STATE.cardLoading
      state.isChangePaymentMethod = BRAIN_TREE_PAYMENT_STATE.isChangePaymentMethod
      return
    }
  }
}, BRAIN_TREE_PAYMENT_STATE)

// Epics

const initBrainTree: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(brainTreePaymentActions.initBrainTree)),
    mergeMap(actions =>
      merge(
        of(apiActions.payment.braintreeRetrievePaymentMethod()),
        of(actions).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.payment.braintreeGenerateTokenResponse)),
              take(1),
              mergeMap(response =>
                from(
                  Braintree.client.create({
                    authorization: response.payload.client_token
                  })
                ).pipe(
                  concatMap(clientInstance => [
                    brainTreePaymentActions.setClientInstance(clientInstance),
                    brainTreePaymentActions.getDeviceData()
                  ])
                )
              ),
              catchError(err =>
                of(err).pipe(
                  tap(error => {
                    SentryUtils.captureMessage(`Error Initialize Braintree`, { error }, 'error')
                  }),
                  ignoreElements()
                )
              ),
              startWith(apiActions.payment.braintreeGenerateToken())
            )
          )
        )
      )
    )
  )

const getDeviceDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(brainTreePaymentActions.getDeviceData)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      clientInstance: brainTreePaymentSelector.brainTreePayment(state).clientInstance
    })),
    filter(({ clientInstance }) => Boolean(clientInstance)),
    mergeMap(({ clientInstance }) =>
      merge(
        of(clientInstance).pipe(
          mergeMap(clientInstance =>
            from(
              Braintree.dataCollector.create({
                client: clientInstance as Braintree.Client
              })
            ).pipe(
              map(dataCollectorInstance =>
                brainTreePaymentActions.getDeviceDataResponse(dataCollectorInstance.deviceData)
              )
            )
          ),
          catchError(err =>
            of(err).pipe(
              tap(error => {
                SentryUtils.captureMessage(`Error Initialize Braintree`, { error }, 'error')
              }),
              ignoreElements()
            )
          ),
          startWith(apiActions.payment.braintreeGenerateToken())
        )
      )
    )
  )

const removePaymentMethodepic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(brainTreePaymentActions.removePaymentMethod)),
    map(() =>
      dialogActions.addDialog({
        [ConfirmationDialog.REMOVE_PAYMENT_METHOD]: {
          dialogName: ConfirmationDialog.REMOVE_PAYMENT_METHOD,
          yesAction: {
            label: 'REMOVE',
            actions: [brainTreePaymentActions.executeRemovePaymentMethod()]
          }
        }
      })
    )
  )

const executeRemovePaymentMethodEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(brainTreePaymentActions.executeRemovePaymentMethod)),
    mergeMap(() =>
      action$.pipe(
        filter(isActionOf(apiActions.payment.braintreeRemovePaymentMethodResponse)),
        take(1),
        tap(() => {
          MixPanelUtils.track<'USER__REMOVE_CARD'>('User - Remove Card')
        }),
        mergeMap(param =>
          merge(
            of(param).pipe(
              mergeMap(() => [
                dialogActions.closeDialog(),
                snackBarActions.show({
                  show: true,
                  content: 'Card removed'
                }),
                apiActions.payment.braintreeRetrievePaymentMethod(),
                brainTreePaymentActions.resetData()
              ])
            ),
            of(param).pipe(
              delay(2500),
              map(() => snackBarActions.close())
            )
          )
        ),
        startWith(apiActions.payment.braintreeRemovePaymentMethod())
      )
    )
  )

const submitCardPaymentMethodEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(brainTreePaymentActions.submitCardPaymentMethod)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      hasBraintreePaymentMethod: apiSelectors.hasBraintreePaymentMethod(state),
      deviceData: brainTreePaymentSelector.brainTreePayment(state).deviceData,
      isSubscription: action.payload?.isSubscription
    })),
    mergeMap(({ hasBraintreePaymentMethod, deviceData, isSubscription }) =>
      merge(
        of(brainTreePaymentActions.setCardLoading(true)),
        of(hasBraintreePaymentMethod).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(brainTreePaymentActions.getNonceResponse)),
              take(1),
              mergeMap(({ payload }) =>
                merge(
                  of(payload).pipe(
                    filter(({ nonce }) => Boolean(nonce)),
                    mergeMap(({ nonce }) =>
                      action$.pipe(
                        filter(isActionOf(apiActions.payment.braintreeCreatePaymentMethodResponse)),
                        take(1),
                        mergeMap(param =>
                          merge(
                            of(param).pipe(
                              mergeMap(() =>
                                _compact([
                                  !isSubscription && dialogActions.closeDialog(),
                                  !isSubscription &&
                                    snackBarActions.show({
                                      show: true,
                                      content: hasBraintreePaymentMethod
                                        ? 'Payment Method Changed'
                                        : 'Payment Method Added'
                                    }),
                                  brainTreePaymentActions.submitCardPaymentMethodResponse(),
                                  brainTreePaymentActions.resetData(),
                                  brainTreePaymentActions.setCardLoading(false),
                                  apiActions.payment.braintreeRetrievePaymentMethod()
                                ])
                              )
                            ),
                            of(param).pipe(
                              delay(2500),
                              map(() => snackBarActions.close())
                            )
                          )
                        ),
                        startWith(
                          apiActions.payment.braintreeCreatePaymentMethod({
                            nonce,
                            device_data: deviceData
                          })
                        )
                      )
                    )
                  ),
                  of(payload).pipe(
                    filter(({ nonce }) => !Boolean(nonce)),
                    mergeMap(() => [
                      brainTreePaymentActions.setCardError(payload.error?.message),
                      brainTreePaymentActions.setCardLoading(false)
                    ])
                  )
                )
              ),
              startWith(brainTreePaymentActions.getNonce())
            )
          )
        )
      )
    )
  )

const submitPaypalPaymentMethodEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(brainTreePaymentActions.submitPaypalPaymentMethod)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      deviceData: brainTreePaymentSelector.brainTreePayment(state).deviceData
    })),
    mergeMap(({ deviceData, payload }) =>
      merge(
        of(brainTreePaymentActions.setCardLoading(true)),
        of(payload).pipe(
          mergeMap(({ nonce, isSubscription }) =>
            action$.pipe(
              filter(isActionOf(apiActions.payment.braintreeCreatePaymentMethodResponse)),
              take(1),
              mergeMap(param =>
                merge(
                  of(param).pipe(
                    mergeMap(() =>
                      _compact([
                        !isSubscription && dialogActions.closeDialog(),
                        !isSubscription &&
                          snackBarActions.show({
                            show: true,
                            content: 'Paypal payment method successfully added'
                          }),
                        brainTreePaymentActions.submitCardPaymentMethodResponse(),
                        brainTreePaymentActions.resetData(),
                        brainTreePaymentActions.setCardLoading(false),
                        apiActions.payment.braintreeRetrievePaymentMethod()
                      ])
                    )
                  ),
                  of(param).pipe(
                    delay(2500),
                    map(() => snackBarActions.close())
                  )
                )
              ),
              startWith(
                apiActions.payment.braintreeCreatePaymentMethod({ nonce, device_data: deviceData })
              )
            )
          )
        )
      )
    )
  )

const reactivateSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(brainTreePaymentActions.reactivateSubscription)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      hasBraintreePaymentMethod: apiSelectors.hasBraintreePaymentMethod(state),
      currentSubscription: apiSelectors.currentSubscription(state)
    })),
    map(({ hasBraintreePaymentMethod, currentSubscription }) => ({
      hasBraintreePaymentMethod,
      param: {
        product: currentSubscription?.product ?? 0,
        id: currentSubscription?.id ?? 0
      }
    })),
    filter(
      ({ param, hasBraintreePaymentMethod }) =>
        Boolean(hasBraintreePaymentMethod) && Boolean(param.id)
    ),
    mergeMap(({ param }) =>
      action$.pipe(
        filter(isActionOf(apiActions.payment.updateSubscriptionResponse)),
        take(1),
        mergeMap(param =>
          merge(
            of(param).pipe(
              mergeMap(() => [
                apiActions.users.retrieveEquity(),
                dialogActions.closeDialog(),
                apiActions.payment.listSubscription(),
                snackBarActions.show({
                  show: true,
                  content: 'Subscription Reactivated'
                })
              ])
            ),
            of(param).pipe(
              delay(2500),
              map(() => snackBarActions.close())
            )
          )
        ),
        startWith(
          apiActions.payment.updateSubscription({
            product: param.product ?? 0,
            id: param.id ?? 0
          })
        )
      )
    )
  )

export const epics = combineEpics(
  reactivateSubscriptionEpic,
  submitPaypalPaymentMethodEpic,
  submitCardPaymentMethodEpic,
  initBrainTree,
  getDeviceDataEpic,
  removePaymentMethodepic,
  executeRemovePaymentMethodEpic
)
export default reducer
