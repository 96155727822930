import React, { Fragment, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RootState, RootActionType } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import Button from 'components/Button'
import { GeneralDialogItemProps } from '..'
import DialogWrapper from '../DialogWrapper'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import { changeEmailActions } from 'duck/AppDuck/ChangeEmailDuck'
import styles from '../Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state),
  changeEmailData: appSelectors.changeEmail.changeEmail(state),
  dialog: appSelectors.dialog.dialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      closeAllDialog: dialogActions.closeAllDialog,
      resetFormErrors: changeEmailActions.resetFormErrors
    },
    dispatch
  )

type DialogErrorProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  GeneralDialogItemProps

const DialogError: React.FC<DialogErrorProps> = props => {
  const {
    changeEmailData,
    activeDialog,
    dialog,
    onClose,
    closeAllDialog,
    resetFormErrors,
    closeDialog
  } = props
  const { generalError } = changeEmailData

  useEffect(() => {
    if (!generalError) {
      closeDialog()
    }
  }, [closeDialog, generalError])

  const errorExitClick = useCallback(() => {
    closeAllDialog()
    resetFormErrors()
  }, [closeAllDialog, resetFormErrors])
  const errorBackClick = useCallback(() => {
    closeDialog()
    resetFormErrors()
  }, [closeDialog, resetFormErrors])

  const show = dialog.show ?? []
  const message = generalError?.message
  const title = generalError?.title

  return (
    <DialogWrapper
      dialogConfig={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        className: clsx(styles.EmailDialog, show.length <= 1 && styles.ActionRight)
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">{title}</Typography>}
      actions={
        <Fragment>
          <Button color="secondary" onClick={errorExitClick}>
            Exit
          </Button>
          {show.length > 1 && (
            <Button color="secondary" onClick={errorBackClick}>
              Back
            </Button>
          )}
        </Fragment>
      }
      content={
        <Fragment>
          {Array.isArray(message) ? (
            message.map((value, index) => {
              return (
                <div className={styles.ContentGroup} key={index}>
                  <Typography variant="body2">{value}</Typography>
                </div>
              )
            })
          ) : (
            <div className={styles.ContentGroup}>
              <Typography variant="body2">{message}</Typography>
            </div>
          )}
        </Fragment>
      }
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogError)
