import React, { useCallback, useMemo } from 'react'
import _find from 'lodash/find'
import clsx from 'clsx'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'

import DropdownMenu, { DropdownItem, DropdownMenuProps } from 'components/DropdownMenu'
import { TrainProjectOrderingType, CollectionOrderingType } from 'models/ApiModels'
import { sortUtils } from 'utils/DataProcessingUtils'
import Button from 'components/Button'
import { emptyFunction } from 'utils'

import styles from './SortByStyle.module.scss'

export type SortByProps = {
  hideTitle?: boolean
  sortBy: DropdownItem[]
  setSortBy: Function
  value?: TrainProjectOrderingType | CollectionOrderingType
  title?: string
  className?: string
  hideSortDirection?: boolean
}

const SortBy: React.FC<SortByProps> = ({
  hideTitle,
  sortBy,
  value = '',
  setSortBy = emptyFunction,
  title = 'Sort by:',
  className,
  hideSortDirection = false
}) => {
  const { sortDirection, sortValue } = sortUtils.toObject(value)

  const onChangeDropdown = useCallback(
    (key: string | number) => () => {
      setSortBy(`${sortDirection}${key}`)
    },
    [sortDirection, setSortBy]
  )

  const onClickDirection = useCallback(() => {
    const currentDirection = sortDirection ? '' : '-'
    setSortBy(`${currentDirection}${sortValue}`)
  }, [sortDirection, sortValue, setSortBy])

  const buttonLabel = useMemo(() => {
    return _find(sortBy, ['key', sortValue])?.label
  }, [sortValue, sortBy])

  const buttonSortDirectionTitle = sortDirection === '-' ? 'Set Ascending' : 'Set Descending'

  const ButtonComponent = useCallback<NonNullable<DropdownMenuProps['CustomButtonComponent']>>(
    ({ buttonRef, ...restProps }) => (
      <Button
        {...restProps}
        ref={buttonRef}
        className={styles.SortByButtonComponent}
        disableUppercase
      >
        <Typography variant="body1">{buttonLabel}</Typography>
      </Button>
    ),
    [buttonLabel]
  )
  return (
    <div className={clsx(styles.SortBy, className)}>
      {!hideTitle && <Typography variant="body1">{`${title} `}</Typography>}

      <DropdownMenu
        className={styles.SortByDropdownMenu}
        CustomButtonComponent={ButtonComponent}
        items={sortBy.map(option => ({
          key: option.key,
          label: option.label,
          onClick: onChangeDropdown(option.key)
        }))}
        placement="bottom-start"
      />
      {!hideSortDirection ? (
        <ButtonBase
          title={buttonSortDirectionTitle}
          onClick={onClickDirection}
          className={styles.SortBy__Ordering}
        >
          {sortDirection === '-' ? <ArrowDownward /> : <ArrowUpward />}
        </ButtonBase>
      ) : null}
    </div>
  )
}

export default SortBy
