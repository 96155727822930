import { TextTransform, UrlUtils } from 'utils/TextUtils'
import produce from 'immer'
import _toInteger from 'lodash/toInteger'
import _map from 'lodash/map'
import { matchPath, Params } from 'react-router'
import { combineEpics, Epic } from 'redux-observable'
import {
  withLatestFrom,
  map,
  mergeMap,
  startWith,
  take,
  filter,
  tap,
  throttleTime,
  concatMap,
  switchMap,
  debounceTime,
  delay
} from 'rxjs/operators'
import { ProjectResultParamType, route, SUB_RESULT, SUB_ROUTE_RESULT_LIST } from 'routes'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { RootState, RootActionType } from 'duck'
import { of, merge, concat } from 'rxjs'
import { isActionOf, createAction, ActionType, getType } from 'typesafe-actions'
import { createSelector } from 'reselect'
import {
  TrainOutputImagesReq,
  TrainOutputImage,
  TrainProject,
  FinishedProjectStatus,
  ImageType,
  UserImage,
  ContinueAbleProjectStatus,
  AdjustedImage,
  Bookmark
} from 'models/ApiModels'
import { SelectedFormat, SelectUtils, generateRequestHistoryKey } from 'utils/DataProcessingUtils'
import collectionSelectorPanelReducer, {
  CollectionSelectorPanelState
} from './CollectionSelectorPanel/duck/reducers'
import { epics as collectionSelectorPanelEpics } from './CollectionSelectorPanel/duck/epics'
import generatePanelReducer, {
  epics as generatePanelEpics,
  selectors as generatePanelSelectors,
  GeneratePanelState
} from './GeneratePanel/duck'
import inputPanelReducer, { InputPanelState, epics as inputPanelEpics } from './InputPanel/duck'
import { LSKey } from 'appConstants'
import { combineReducers } from 'redux'
import { appActions, appSelectors } from 'duck/AppDuck'
import { SessionStorage } from 'utils'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
// import sellAsNFTReducer, {
//   SellAsNFTState,
//   epics as sellAsNFTEpics
// } from './SellAsNFT_DEPRECATED/duck'

const DEFAULT_SLIDER_MAX = 50
export const MAX_UPSCALE = 1024
export const ANIMATION_DURATION = 250
export const GRID_PROJECTS_CONFIG = {
  xs: {
    paddingX: 24
  },
  sm: {
    paddingX: 24
  },
  lg: {
    maxWidth: 1440 // + 72 + 72
  },
  xl: {
    maxWidth: 1440 // + 72 + 72
  },
  default: {
    defaultColumnWidth: 112,
    square: true,
    gutter: 36,
    paddingTop: 24,
    paddingBottom: 72,
    paddingX: 72
  }
}

// Type Definition
export type SliderData = {
  max: number
  total: number
  value: number
  disabled: boolean
  order: number
}

// Constants
const NAMESPACE = '@@page/TrainProjectPage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export type DownloadOption = {
  key: number | string
  label: React.ReactNode
  onClick: Function
}

const SourceTypeInference = ['inference'] as const
const SourceTypeSaved = ['selected-saved', 'saved'] as const
const SourceTypeResult = ['byOrder', 'byBatch', 'selected'] as const

export type ExecuteDownloadInference = {
  fileNamePrefix?: string
  imageFiles?: string[]
  sourceType?: (typeof SourceTypeInference)[number]
}
export type ExecuteDownloadSaved = {
  fileNamePrefix?: string
  images?: UserImage[]
  sourceType?: (typeof SourceTypeSaved)[number]
}
export type ExecuteDownloadResult = {
  fileNamePrefix?: string
  outputImages?: TrainOutputImage[]
  sourceType?: (typeof SourceTypeResult)[number]
}

export const Utils = {
  getParam: (params: Params<keyof ProjectResultParamType>): ProjectResultParamType | undefined => {
    const subRoute = SUB_ROUTE_RESULT_LIST.includes(
      (params.subRoute ?? '') as NonNullable<ProjectResultParamType['subRoute']>
    )
      ? (params.subRoute as ProjectResultParamType['subRoute'])
      : undefined

    return {
      id: params.id,
      subRoute,
      order: params.order,
      batch: params.batch
    }
  },
  getAdjustedImage: (
    userImage: UserImage | undefined,
    adjustedImages: { [id: number]: AdjustedImage | undefined }
  ) => {
    if (userImage?.adjust) {
      return adjustedImages[userImage.adjust]?.output
    }
  },
  getOutputImagePrefix: (param: {
    currentProject?: TrainProject
    trainOutputImage: TrainOutputImage
    isDownloadCurrentOrder?: boolean
  }) => {
    const { currentProject, trainOutputImage, isDownloadCurrentOrder } = param
    const snapshotCount = currentProject?.current_snapshot ?? 0
    const outputsCount = currentProject?.outputs_count ?? 0
    const orderCount = `${Math.round(outputsCount / snapshotCount)}`

    const batch = `${(trainOutputImage?.batch ?? 0) + 1}`.padStart(
      isDownloadCurrentOrder ? 4 : `${snapshotCount}`.length,
      '0'
    )
    const order = `${(trainOutputImage?.order ?? 0) + 1}`.padStart(
      !isDownloadCurrentOrder ? 4 : orderCount.length,
      '0'
    )

    return isDownloadCurrentOrder ? `${order}_${batch}` : `${batch}_${order}`
  },
  getOutputImageName: (param: {
    currentProject?: TrainProject
    trainOutputImage: TrainOutputImage
    isDownloadCurrentOrder?: boolean
  }) => {
    const { currentProject, trainOutputImage, isDownloadCurrentOrder } = param

    const projectName = currentProject?.name ?? ''

    const hasAdjustedImage = Boolean(trainOutputImage?.adjustedImage?.file)
    const file = trainOutputImage?.adjustedImage?.file ?? trainOutputImage?.image?.file
    const fileType = UrlUtils.getFileTypeFromUrl(file)
    const prefix = Utils.getOutputImagePrefix({
      currentProject,
      trainOutputImage,
      isDownloadCurrentOrder
    })
    return `${hasAdjustedImage ? 'enhanced_' : ''}${projectName}_${prefix}.${fileType}`
  },
  getImageName: (param: {
    currentProject?: TrainProject
    image?: Blob | string | ImageType
    prefix?: string
  }) => {
    const { currentProject, image, prefix = '' } = param
    const projectName = currentProject?.name ?? ''

    if (typeof image === 'string') {
      const fileType = UrlUtils.getFileTypeFromUrl(image)
      return `${projectName}${prefix ? '_' : ''}${prefix}.${fileType}`
    } else if (image instanceof Blob) {
      const type = image?.type
      const splitted = type ? type.split('/') : []
      const fileType = splitted[1] ? splitted[1] : 'jpeg'
      return `${projectName}${prefix ? '_' : ''}${prefix}.${fileType}`
    } else {
      const fileType = UrlUtils.getFileTypeFromUrl(image?.file ?? 'jpeg')

      return `${projectName}${prefix ? '_' : ''}${prefix}_${image?.id ?? ''}.${fileType}`
    }
  }
}

// Actions
export const actions = {
  onUnmount: createAction(creator('ON_UNMOUNT'))(),
  retrieveInitData: createAction(creator('RETRIEVE_INIT_DATA'))<ProjectResultParamType>(),
  updateUrlParam: createAction(creator('SET_URL_PARAM'))<ProjectResultParamType>(),
  setSliderValue: createAction(creator('SET_SLIDER_VALUE'))<number>(),
  setSlideshowValue: createAction(creator('SET_SLIDESHOW_VALUE'))<number>(),
  openSlideshow: createAction(creator('OPEN_SLIDESHOW'))<{
    batch?: string
    order?: string
  }>(),
  reloadSlideshow: createAction(creator('RELOAD_SLIDESHOW'))(),
  loadMoreImageGrid: createAction(creator('LOAD_MORE_IMAGE_GRID'))(),
  loadAllCurrentBatchImages: createAction(creator('LOAD_ALL_CURRENT_BATCH_IMAGES'))(),
  loadAllCurrentGridImages: createAction(creator('LOAD_ALL_CURRENT_GRID_IMAGES'))(),
  loadAllSavedImages: createAction(creator('LOAD_ALL_SAVED_IMAGES'))(),
  loadMoreImageSlider: createAction(creator('LOAD_MORE_IMAGE_SLIDER'))(),
  handleError: createAction(creator('HANDLE_ERROR'))<any>(),
  checkGridAndReload: createAction(creator('CHECK_GRID_AND_RELOAD'))(),
  reloadGrid: createAction(creator('RELOAD_GRID'))(),
  setSelectedSaved: createAction(creator('SET_SELECTED)SAVED'))<number | undefined>(),
  download: {
    selectSavedImage: createAction(creator('DOWNLOAD/SELECT_SAVED_IMAGE'))<UserImage>(),
    resetSavedImageSelector: createAction(creator('DOWNLOAD/RESET_SAVED_IMAGE_SELECTOR'))(),
    downloadSelectedSaved: createAction(creator('DOWNLOAD/DOWNLOAD_SELECTED_SAVED'))(),
    downloadAllSaved: createAction(creator('DOWNLOAD/DOWNLOAD_ALL_SAVED'))(),

    selectImage: createAction(creator('DOWNLOAD/SELECT_IMAGE'))<TrainOutputImage>(),
    openImageSelector: createAction(creator('DOWNLOAD/OPEN_IMAGE_SELECTOR'))(),
    closeImageSelector: createAction(creator('DOWNLOAD/CLOSE_IMAGE_SELECTOR'))(),
    resetImageSelector: createAction(creator('DOWNLOAD/RESET_IMAGE_SELECTOR'))(),
    downloadAllInference: createAction(creator('DOWNLOAD/DOWNLOAD_ALL_INFERENCE'))(),

    downloadCurrentBatch: createAction(creator('DOWNLOAD/DOWNLOAD_CURRENT_BATCH'))(),
    downloadCurrentOrder: createAction(creator('DOWNLOAD/DOWNLOAD_CURRENT_ORDER'))(),
    downloadSelected: createAction(creator('DOWNLOAD/DOWNLOAD_SELECTED'))()
  }
}

// Selector
const selectResultPanel = (state: RootState) => state.container.trainProjectPage.resultPanel
const selectMaxTotal = createSelector(apiSelectors.currentProject, currentProject => ({
  max: currentProject?.current_snapshot ?? 0,
  total: currentProject?.total_snapshot ?? DEFAULT_SLIDER_MAX
}))
const selectShowImageSelector = createSelector(
  selectResultPanel,
  resultPanel => resultPanel?.download?.showImageSelector ?? false
)

const selectCurrentImageGrid = createSelector(
  apiSelectors.currentProjectOutputImages,
  selectResultPanel,
  apiSelectors.userImages,
  apiSelectors.adjustedImages,
  (outputImages, resultPanel, userImages, adjustedImages) =>
    _map(
      outputImages?.snapshotData?.[resultPanel.sliderValue - 1] ?? [],
      (outputImageId, index) => {
        const image = outputImageId ? userImages[outputImageId] : undefined
        const imageWithAdjust: UserImage | undefined = image
          ? { ...image, adjustData: adjustedImages[image.adjust ?? 0] }
          : undefined

        return {
          id: outputImageId,
          image: imageWithAdjust,
          batch: resultPanel.sliderValue - 1,
          order: index
        }
      }
    )
)
const selectUrlParam = createSelector(selectResultPanel, resultPanel => resultPanel.urlParam)
const selectCurrentImageGridRequestHistory = createSelector(
  apiSelectors.currentProjectOutputImages,
  selectResultPanel,
  (outputImages, resultPanel) =>
    outputImages?.requestHistory?.[`order-${resultPanel.sliderValue - 1}`]
)
const selectSavedImagesLastRequest = createSelector(
  apiSelectors.currentProject,
  apiSelectors.userImageBookmarkLists,
  (currentProject, userImageBookmarkLists) => {
    const scope = currentProject?.bookmark_scope
    return userImageBookmarkLists?.[scope]?.lastRequest
  }
)
const selectOrder = createSelector(
  selectResultPanel,
  resultPanel => _toInteger(resultPanel.order) - 1
)
const selectCurrentImageSlideshow = createSelector(
  apiSelectors.currentProjectOutputImages,
  selectOrder,
  selectMaxTotal,
  apiSelectors.userImages,
  apiSelectors.adjustedImages,
  (outputImages, order, maxTotal, userImages, adjustedImages) => {
    const snapshotData = outputImages?.snapshotData ?? []
    const { max } = maxTotal
    const result = []

    for (let i = 0; i < max; i++) {
      const data = snapshotData[i]?.[order] ?? null
      result.push(data)
    }
    return _map(result, (outputImageId, index) => ({
      id: outputImageId,
      adjustedImage: Utils.getAdjustedImage(userImages[outputImageId], adjustedImages),
      image: outputImageId ? userImages[outputImageId] : undefined,
      batch: index,
      order
    }))
  }
)

const selectCurrentProjectConfig = createSelector(
  apiSelectors.currentProject,
  apiSelectors.engineConfigData,
  (currentProject, engineConfig) => {
    const projectCategory = currentProject?.category || 'morph'
    const projectStatus = currentProject?.status || 'drafted'
    const isProjectOwner = Boolean(currentProject?.isProjectOwner)

    const currentEngineConfig = engineConfig?.[projectCategory] ?? undefined
    const canInference = currentEngineConfig?.can_inference ?? false
    const hasResults = currentProject.current_snapshot > 0
    const enableInference =
      canInference && ContinueAbleProjectStatus.includes(projectStatus) && hasResults

    const canMix = (currentEngineConfig?.can_mix ?? false) && isProjectOwner
    const canVideo = (currentEngineConfig?.can_video ?? false) && isProjectOwner

    const enableMix = canMix && ContinueAbleProjectStatus.includes(projectStatus)
    const enableVideo = canVideo && ContinueAbleProjectStatus.includes(projectStatus)
    const enableSave =
      currentProject?.total_snapshot && currentProject?.outputs_count
        ? currentProject?.total_snapshot > 0 && currentProject?.outputs_count > 0
        : false

    return {
      canInference,
      canMix,
      canVideo,
      enableInference,
      enableMix,
      enableVideo,
      enableSave
    }
  }
)

/* Use image from user images to get latest userImage bookmark data */
export const selectDisplayedImage = createSelector(
  selectCurrentImageSlideshow,
  selectResultPanel,
  apiSelectors.userImages,
  (currentImageSlideshow, resultPanel, userImages) => {
    const snapshot = _toInteger(resultPanel.slideshowValue) - 1
    const outputImage = currentImageSlideshow[snapshot]

    return (
      outputImage && {
        ...outputImage,
        image: userImages[outputImage.image?.id ?? 0] ?? outputImage.image
      }
    )
  }
)

export const selectSelectedSavedId = createSelector(
  selectResultPanel,
  resultPanel => resultPanel.selectedSaved
)

export const selectSelectedSaved = createSelector(
  selectSelectedSavedId,
  apiSelectors.userImages,
  apiSelectors.adjustedImages,
  apiSelectors.userImageBookmarks,
  (id, userImages, adjustedImages, userImageBookmarks) => {
    const userImageBookmark = id ? userImageBookmarks[id] : undefined
    const userImage = userImages[userImageBookmark?.item?.id ?? 0]

    if (userImageBookmark && userImage) {
      const adjustedImage = userImage.adjust ? adjustedImages[userImage.adjust] : undefined
      const adjustedUserImageBookmark: Bookmark<'user-image'> = {
        ...userImageBookmark,
        item: {
          ...userImage,
          adjustData: adjustedImage
        }
      }
      return adjustedUserImageBookmark
    }
  }
)

export const selectors = {
  resultPanel: selectResultPanel,
  urlParam: selectUrlParam,
  currentImageGrid: selectCurrentImageGrid,
  currentImageSlideshow: selectCurrentImageSlideshow,
  displayedImage: selectDisplayedImage,
  selectedSaved: selectSelectedSaved,
  currentProjectConfig: selectCurrentProjectConfig,
  selectedSavedId: selectSelectedSavedId,
  currentImageGridRequestHistory: selectCurrentImageGridRequestHistory,
  showImageSelector: selectShowImageSelector,
  savedImagesLastRequest: selectSavedImagesLastRequest,
  sliderData: createSelector(selectMaxTotal, selectResultPanel, (maxTotal, resultPanel) => {
    const sliderData: SliderData = {
      ...maxTotal,
      value: resultPanel.sliderValue,
      order: _toInteger(resultPanel.order),
      disabled: false
    }
    return sliderData
  }),
  slideshowData: createSelector(selectMaxTotal, selectResultPanel, (maxTotal, resultPanel) => {
    const sliderData: SliderData = {
      ...maxTotal,
      value: resultPanel.slideshowValue,
      order: _toInteger(resultPanel.order),
      disabled: false
    }
    return sliderData
  }),
  selectedSavedImages: createSelector(selectResultPanel, resultPanel => {
    return resultPanel.download.selectedSavedImages
  }),
  selectedCurrentBatchDownload: createSelector(selectResultPanel, resultPanel => {
    const { sliderValue, download } = resultPanel
    return download?.selectedDownloadImages?.[sliderValue - 1]
  }),

  selectDownloadProgress: createSelector(selectResultPanel, resultPanel => {
    const progress = resultPanel?.download?.downloadProgress ?? initial.download.downloadProgress
    const { total, success, failed } = progress
    return {
      ...progress,
      processed: success + failed,
      percentage: total > 1 ? ((success + failed + 1) / total) * 100 : 0
    }
  }),
  shouldDisplayWaiting: createSelector(
    apiSelectors.loading['projects.retrieve'],
    apiSelectors.currentProject,
    (retrieveProjectLoading, currentProject) => {
      /* 
        Either total batch, count  and current batch is still zero. 
        And current project is loaded  (fix display waiting  splashing when project loaded)
      */

      const total_snapshot = currentProject?.total_snapshot
      const current_snapshot = currentProject?.current_snapshot
      const count = currentProject?.outputs_count

      const finishedLoading = retrieveProjectLoading === false
      const shouldDisplayWaiting = !(total_snapshot && current_snapshot && count) && finishedLoading

      return shouldDisplayWaiting
    }
  )
}

// Reducer

/*  Notes :
 *  Order, batch slider and slideshow for UI is start from 1. While API value start from 0
 *  We need converted to +1 or -1 when communicate between API Data and UI.
 */

export type ResultPanelState = ProjectResultParamType & {
  urlParam?: ProjectResultParamType
  sliderValue: number
  slideshowValue: number
  selectedSaved?: number
  download: {
    selectedDownloadImages: {
      [batch: number]: SelectedFormat // We use order of the images, instead of ID
    }
    selectedSavedImages: SelectedFormat
    showImageSelector: boolean
    downloadProgress: {
      downloading: boolean
      total: number
      success: number
      failed: number
      errorLog: object[]
    }
  }
  sellNft: {
    showDialogInfo: boolean
    showForm: boolean
    showImageSelector: boolean
  }
}

const initial: ResultPanelState = {
  id: '',
  subRoute: 'result',
  order: '',
  batch: '',
  sliderValue: 0,
  slideshowValue: 0,
  selectedSaved: undefined,
  download: {
    selectedDownloadImages: {},
    selectedSavedImages: {},
    showImageSelector: false,
    downloadProgress: {
      downloading: false,
      total: 0,
      success: 0,
      failed: 0,
      errorLog: []
    }
  },
  sellNft: {
    showDialogInfo: false,
    showForm: true,
    showImageSelector: false
  }
}

const reducer = produce((state: ResultPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.updateUrlParam): {
      state.urlParam = payload as ProjectResultParamType
      return
    }
    case getType(actions.retrieveInitData): {
      const projectParam = payload as ActionType<typeof actions.retrieveInitData>['payload']

      const { id, subRoute, batch, order } = projectParam
      state.id = id
      state.subRoute = subRoute
      state.batch = batch
      state.order = order
      state.sliderValue = 0
      state.slideshowValue = 0
      return
    }
    case getType(actions.setSliderValue): {
      const sliderValue = payload as ActionType<typeof actions.setSliderValue>['payload']

      state.sliderValue = sliderValue
      return
    }
    case getType(actions.setSlideshowValue): {
      const slideshowValue = payload as ActionType<typeof actions.setSlideshowValue>['payload']

      state.slideshowValue = slideshowValue
      return
    }
    case getType(actions.openSlideshow): {
      const slideshowParam = payload as ActionType<typeof actions.openSlideshow>['payload']

      const { batch, order } = slideshowParam
      state.batch = batch
      state.order = order
      state.slideshowValue = _toInteger(batch)
      return
    }
    case getType(actions.setSelectedSaved): {
      const selectedSaved = payload as ActionType<typeof actions.setSelectedSaved>['payload']

      state.selectedSaved = selectedSaved
      return
    }
    case getType(actions.download.selectImage): {
      const outputImage = payload as ActionType<typeof actions.download.selectImage>['payload']

      const currentBatch = state.sliderValue - 1
      const currentSelected = state.download.selectedDownloadImages[currentBatch] || {}
      const newSelected = {
        ...currentSelected,
        [outputImage.order]: !currentSelected[outputImage.order]
      }
      state.download.selectedDownloadImages[currentBatch] = newSelected
      return
    }
    case getType(actions.download.selectSavedImage): {
      const userImage = payload as ActionType<typeof actions.download.selectSavedImage>['payload']

      const currentSelected = state.download.selectedSavedImages || {}
      const newSelected = {
        ...currentSelected,
        [userImage.id]: !currentSelected[userImage.id]
      }
      state.download.selectedSavedImages = newSelected
      return
    }
    case getType(actions.download.openImageSelector): {
      state.download.showImageSelector = true
      return
    }
    case getType(actions.download.closeImageSelector): {
      state.download.showImageSelector = false
      return
    }
    case getType(actions.download.resetImageSelector): {
      const currentBatch = state.sliderValue - 1
      state.download.selectedDownloadImages[currentBatch] = {}
      return
    }
    case getType(actions.download.resetSavedImageSelector): {
      state.download.selectedSavedImages = {}
      return
    }
    default:
  }
}, initial)

// Utils

const checkHasEmpty = (currentImageGrid?: TrainOutputImage[]) => {
  let hasEmpty = false

  if (!currentImageGrid) return true

  for (let i = 0; i < 20; i++) {
    if (!currentImageGrid[i]?.image) {
      hasEmpty = true
      break
    }
  }
  return hasEmpty
}

// Epic

/* Fetch initial data */
const retrieveInitDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.retrieveInitData)),
    map(action => _toInteger(action.payload?.id)),
    mergeMap(projectId =>
      action$.pipe(
        filter(isActionOf(apiActions.projects.retrieveResponse)),
        take(1),
        withLatestFrom(state$),
        map(([, state]) => ({
          currentProject: apiSelectors.currentProject(state)
        })),
        mergeMap(({ currentProject }) =>
          concat(
            /* Fetch Inspiration 
                   Execute if has inspiration, 
                   and not yet fetch the inspiration/aesthetic
                */
            of(currentProject).pipe(
              filter(
                currentProject =>
                  Boolean(currentProject?.inspiration) &&
                  !Boolean(currentProject?.inspirationData?.id)
              ),
              map(currentProject => apiActions.inputs.retrieve(currentProject.inspiration ?? 0))
            ),
            /* Fetch Aesthetic 
                   Execute if has inspiration,
                   and not yet fetch the inspiration/aesthetic
                */
            of(currentProject).pipe(
              filter(
                currentProject =>
                  Boolean(currentProject?.aesthetic) && !Boolean(currentProject?.aestheticData?.id)
              ),
              map(currentProject => apiActions.inputs.retrieve(currentProject.aesthetic ?? 0))
            ),
            /* Update is_seen_project status */
            of(currentProject).pipe(
              filter(projectData =>
                FinishedProjectStatus.includes(projectData?.status || 'drafted')
              ),
              filter(projectData => Boolean(projectData?.isProjectOwner)),
              filter(projectData => !Boolean(projectData?.ui_extras?.is_finish_checked)),
              map(projectData =>
                apiActions.projects.update({
                  id: projectData.id,
                  ui_extras: { ...(projectData?.ui_extras ?? {}), is_finish_checked: true }
                })
              )
            ),
            //Check whether it has collection that need to be added
            of(currentProject).pipe(
              map(() => SessionStorage.getJSON(LSKey.COLLECTION_ADDED_TO_EXISTING_PROJECT)),
              tap(() => {
                SessionStorage.remove(LSKey.COLLECTION_ADDED_TO_EXISTING_PROJECT)
              }),
              filter(
                addedCollection =>
                  Boolean(addedCollection) &&
                  Boolean(addedCollection?.collectionId) &&
                  Boolean(addedCollection?.imageSet)
              ),
              map(addedCollection => ({
                collectionId: addedCollection?.collectionId,
                imageSet: addedCollection?.imageSet
              })),
              map(({ collectionId, imageSet }) => {
                return {
                  param: {
                    id:
                      imageSet === 'aesthetic'
                        ? currentProject.aesthetic ?? 0
                        : _toInteger(currentProject.inspiration) ?? 0,
                    collection: _toInteger(collectionId),
                    exclude_images: [],
                    update_existing: true
                  }
                }
              }),
              mergeMap(({ param }) =>
                action$.pipe(
                  filter(isActionOf(apiActions.inputs.updateResponse)),
                  take(1),
                  map(() => apiActions.projects.updateThumbnail(currentProject.id)),
                  startWith(apiActions.inputs.update(param))
                )
              )
            )
          )
        ),
        // This one is executed first, and code above is listening on project retrieving finished.
        startWith(apiActions.projects.retrieve({ id: _toInteger(projectId) }))
      )
    )
  )

/*
 *  Fetch Grid image data
 */
const retrieveGridImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.reloadGrid, actions.loadMoreImageGrid])),
    withLatestFrom(state$),
    map(([action, state]) => {
      const currentProject = apiSelectors.currentProject(state)
      const sliderData = selectors.sliderData(state)
      const { type } = action
      const next = type === getType(actions.loadMoreImageGrid)
      const param: TrainOutputImagesReq = {
        project: currentProject.id,
        batch: sliderData.value - 1,
        ordering: 'order',
        limit: 30
      }
      return { param, next }
    }),
    filter(
      ({ param: { batch, project } }) => batch !== undefined && batch >= 0 && Boolean(project)
    ),
    map(({ param, next }) => apiActions.projects.retrieveOutputImages({ data: param, next }))
  )

const setSliderValueEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.setSliderValue)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const currentImageGrid = selectors.currentImageGrid(state)
      const hasEmpty = checkHasEmpty(currentImageGrid)

      const currentProject = apiSelectors.currentProject(state)
      const sliderData = selectors.sliderData(state)

      const { type } = action
      const next = type === getType(actions.loadMoreImageGrid)
      const param: TrainOutputImagesReq = {
        project: currentProject.id,
        batch: sliderData.value - 1,
        ordering: 'order',
        limit: 30
      }
      return { param, next, hasEmpty }
    }),
    filter(
      ({ param: { batch, project }, hasEmpty }) =>
        batch !== undefined && batch >= 0 && Boolean(project) && hasEmpty
    ),
    debounceTime(500),
    map(({ param, next }) => apiActions.projects.retrieveOutputImages({ data: param, next }))
  )

/*
 *  Fetch Slideshow image when slideshow loaded
 */
const retrieveSlideshowImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([actions.openSlideshow, actions.reloadSlideshow, actions.loadMoreImageSlider])
    ),
    withLatestFrom(state$),
    map(([action, state]) => {
      const currentProject = apiSelectors.currentProject(state)
      const outputImages = apiSelectors.currentProjectOutputImages(state)
      const slideshowData = selectors.slideshowData(state)
      const param: TrainOutputImagesReq = {
        project: currentProject.id,
        order: slideshowData.order - 1,
        ordering: 'batch',
        limit: 100
      }

      /* Fix bug when load more called before has request history 
         If this happen, then act like it's calling from OPEN_SLIDESHOW
      */
      const requestHistoryKey = generateRequestHistoryKey(param)
      const requestHistory = outputImages?.requestHistory?.[requestHistoryKey]

      const next = Boolean(requestHistory) && action.type === getType(actions.loadMoreImageSlider)

      /* If current project or resultsData empty, then don't fetch */
      if (!currentProject.id || currentProject.status === 'drafted') {
        param.project = 0
      }
      return { data: param, next }
    }),
    filter(({ data }) => Boolean(data.project)),
    filter(({ data: { order } }) => order !== undefined && order >= 0),
    map(({ data, next }) => apiActions.projects.retrieveOutputImages({ data, next }))
  )
/*
 * When project data arrived
 *  - Set Slider default value
 *  - Fetch Slideshow if order and batch available
 *  - If batch available, then default result slider grid is based on batch.
 *  - If silent update, then reload the slideshow and don't update slider value,
 */

const onReceiveProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.projects.retrieveResponse)),
    withLatestFrom(state$),
    filter(([action, state]) => {
      const routerLocation = appSelectors.routerLocation(state)
      const isNotDraft = action.payload.data.status !== 'drafted'
      const matchTrainProjectPage = matchPath<keyof ProjectResultParamType, string>(
        `${route.TRAIN_PROJECTS.getPath(2)}/*`,
        routerLocation?.pathname ?? ''
      )
      return Boolean(matchTrainProjectPage) && isNotDraft
    }),
    mergeMap(param =>
      concat(
        of(param).pipe(
          filter(([action, _]) => !Boolean(action.payload.silentUpdate)),
          filter(([_, state]) => Boolean((selectors.resultPanel(state).sliderValue ?? 0) <= 1)),
          map(([action]) => action.payload.data),
          map(projectData => actions.setSliderValue(projectData?.current_snapshot ?? 1))
        ),
        of(param).pipe(
          filter(([action, _]) => !Boolean(action.payload.silentUpdate)),
          map(([_, state]) => selectors.resultPanel(state)),
          filter(({ order, batch }) => {
            return !!(order && batch)
          }),
          mergeMap(({ order, batch }) => [
            actions.setSliderValue(_toInteger(batch)),
            actions.openSlideshow({ batch, order })
          ])
        ),
        /* If silent update and slideshow opened, then fetch slideshow image */
        of(param).pipe(
          filter(([action, _]) => Boolean(action.payload.silentUpdate)),
          map(([_, state]) => selectors.resultPanel(state)),
          filter(({ order, batch }) => {
            return Boolean(order && batch)
          }),
          map(() => actions.reloadSlideshow())
        )
      )
    )
    // Filter is slider in initial position (1), if slider value more than 1, then don't change it
  )

/* Happen when user check back to grid view, and the grid still empty. 
   Grid will checked, if there is empty data, then fetch data. 
*/
const checkGridAndReloadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.checkGridAndReload)),
    withLatestFrom(state$),
    map(([, state]) => {
      const currentImageGrid = selectors.currentImageGrid(state)
      return checkHasEmpty(currentImageGrid)
    }),
    filter(hasEmpty => hasEmpty),
    map(() => actions.reloadGrid())
  )

/* Make sure all images in current batch is fetched */
const downloadAllSavedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.download.downloadAllSaved, actions.loadAllSavedImages])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      lastRequest: selectors.savedImagesLastRequest(state),
      currentProjectScope: apiSelectors.currentProject(state).bookmark_scope,
      type: action.type
    })),

    mergeMap(({ lastRequest, currentProjectScope }) =>
      merge(
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          mergeMap(() =>
            // Ensure to fetch all images
            action$.pipe(
              filter(isActionOf(apiActions.projects.listUserImageBookmarkResponse)),
              take(1),
              map(() => actions.loadAllSavedImages()),
              // This one is executed first,
              // and code above is listening on project retrieving finished.
              startWith(
                apiActions.projects.listUserImageBookmark({
                  next: true,
                  param: {
                    scope: currentProjectScope,
                    limit: 30,
                    offset: 0
                  }
                })
              )
            )
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          map(() =>
            snackBarActions.show({
              content: `Load all saved images...`,
              actionText: ''
            })
          )
        ),
        // All collected, just download it
        of(lastRequest).pipe(
          filter(lastRequest => !Boolean(lastRequest && lastRequest.next)),
          withLatestFrom(state$),
          map(([_, state]) => ({
            currentProjectSavedImageList: apiSelectors.currentProjectSavedImageList(state),
            currentProject: apiSelectors.currentProject(state)
          })),

          map(({ currentProjectSavedImageList, currentProject }) => ({
            files: currentProjectSavedImageList.map(image => ({
              fileUrl: image?.file ?? '',
              imageName: Utils.getImageName({ image, currentProject })
            })),
            fileName: `${currentProject.name}-saved-${currentProjectSavedImageList.length ?? ''}`
          })),
          mergeMap(({ files, fileName }) => [
            appActions.log.download({
              download_file_type: 'image',
              download_location: 'Train Project - Download All Saved Image'
            }),
            downloaderActions.multiple.executeDownloadMultipleImage({
              files,
              fileName
            })
          ])
        )
      )
    )
  )

/* Make sure all images in current batch is fetched */
const downloadCurrentBatchEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf([actions.download.downloadCurrentBatch, actions.loadAllCurrentBatchImages])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      requestHistory: selectors.currentImageGridRequestHistory(state),
      type: action.type
    })),

    mergeMap(({ requestHistory }) =>
      merge(
        of(requestHistory).pipe(
          filter(requestHistory => Boolean(requestHistory && requestHistory.next)),
          mergeMap(() =>
            // Ensure to fetch all images
            action$.pipe(
              filter(isActionOf(apiActions.projects.retrieveOutputImagesResponse)),
              take(1),
              map(() => actions.loadAllCurrentBatchImages()),
              startWith(actions.loadMoreImageGrid())
            )
          )
        ),
        of(requestHistory).pipe(
          filter(requestHistory => Boolean(requestHistory && requestHistory.next)),
          map(() =>
            snackBarActions.show({
              content: `Load all current snapshot...`,
              actionText: ''
            })
          )
        ),
        // All collected, just download it
        of(requestHistory).pipe(
          filter(requestHistory => !Boolean(requestHistory && requestHistory.next)),
          withLatestFrom(state$),
          map(([_, state]) => ({
            currentProject: apiSelectors.currentProject(state),
            currentImageGrid: selectors.currentImageGrid(state)
          })),
          map(({ currentImageGrid, currentProject }) => {
            const sampleDownloadedImage = currentImageGrid?.[0]
            const batch = sampleDownloadedImage?.batch ?? 0
            const fileNamePrefix = `${batch + 1}`

            return { currentImageGrid, fileNamePrefix, currentProject }
          }),

          map(({ currentImageGrid, fileNamePrefix, currentProject }) => ({
            files: currentImageGrid.map(trainOutputImage => ({
              fileUrl: trainOutputImage.image?.file ?? '',
              imageName: Utils.getOutputImageName({ trainOutputImage, currentProject })
            })),
            fileName: `${currentProject.name}-snapshot_${fileNamePrefix}`
          })),
          mergeMap(({ files, fileName }) => [
            appActions.log.download({
              download_file_type: 'image',
              download_location: 'Train Project - Download This Snapshot'
            }),
            downloaderActions.multiple.executeDownloadMultipleImage({
              files,
              fileName
            })
          ])
        )
      )
    )
  )

/* Asume all images is fetched */
const downloadCurrentOrderEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.download.downloadCurrentOrder)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      currentImageSlideshow: selectors.currentImageSlideshow(state),
      currentProject: apiSelectors.currentProject(state)
    })),
    filter(({ currentImageSlideshow }) =>
      Boolean(currentImageSlideshow && currentImageSlideshow.length)
    ),
    map(({ currentImageSlideshow, currentProject }) => {
      const sampleDownloadedImage = currentImageSlideshow?.[0]
      const order = sampleDownloadedImage?.order ?? 0
      const fileNamePrefix = `${order + 1}`

      return { currentImageSlideshow, fileNamePrefix, currentProject }
    }),
    map(({ currentImageSlideshow, fileNamePrefix, currentProject }) => ({
      files: currentImageSlideshow.map(trainOutputImage => ({
        fileUrl: trainOutputImage.image?.file ?? '',
        imageName: Utils.getOutputImageName({
          trainOutputImage,
          currentProject,
          isDownloadCurrentOrder: true
        })
      })),
      fileName: `${currentProject.name}-number_${fileNamePrefix}`
    })),
    mergeMap(({ files, fileName }) => [
      appActions.log.download({
        download_file_type: 'image',
        download_location: 'Train Project - Download All Snapshot'
      }),
      downloaderActions.multiple.executeDownloadMultipleImage({
        files,
        fileName
      })
    ])
  )

const downloadAllInferenceEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.download.downloadAllInference)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      isInferenceFinished: generatePanelSelectors.isInferenceFinished(state),
      currentInference: generatePanelSelectors.currentInference(state),
      currentProject: apiSelectors.currentProject(state)
    })),
    filter(
      ({ currentInference, isInferenceFinished }) =>
        Boolean(currentInference) && isInferenceFinished
    ),
    map(({ currentInference, currentProject }) => ({
      files: (currentInference?.outputs ?? []).map(imageFile => ({
        fileUrl: imageFile.file ?? '',
        imageName: Utils.getImageName({
          currentProject,
          image: imageFile,
          prefix: `inference-${currentInference?.id}`
        }),
        withIncrementPrefix: true
      })),
      fileName: `${currentProject.name}-inference-${currentInference?.id}`
    })),
    mergeMap(({ files, fileName }) => [
      appActions.log.download({
        download_file_type: 'image',
        download_location: 'Train Project - Download All Inference'
      }),
      downloaderActions.multiple.executeDownloadMultipleImage({
        files,
        fileName,
        withIncrementPrefix: true
      })
    ])
  )

const downloadSelectedSavedEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.download.downloadSelectedSaved)),
    throttleTime(1000),
    withLatestFrom(state$),
    map(([_, state]) => ({
      selected: selectors.selectedSavedImages(state),
      userImages: apiSelectors.userImages(state),
      currentProject: apiSelectors.currentProject(state)
    })),
    map(({ selected, userImages, currentProject }) => {
      const listSelected = SelectUtils.getSelectedList(selected) || []
      const downloadedImages: UserImage[] = listSelected.map(id => userImages[id])
      return { downloadedImages, currentProject }
    }),
    map(({ downloadedImages, currentProject }) => ({
      files: downloadedImages.map(imageFile => ({
        fileUrl: imageFile.file ?? '',
        imageName: Utils.getImageName({
          currentProject,
          image: imageFile
        })
      })),
      fileName: `${currentProject.name}-saved_images-${downloadedImages.length ?? ''}`
    })),
    concatMap(({ files, fileName }) => [
      appActions.log.download({
        download_file_type: 'image',
        download_location: 'Train Project - Download Selected Saved Image'
      }),
      downloaderActions.multiple.executeDownloadMultipleImage({
        files,
        fileName
      }),
      actions.download.resetSavedImageSelector(),
      actions.download.closeImageSelector()
    ])
  )

const downloadSelectedImagesEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.download.downloadSelected)),
    throttleTime(1000),
    withLatestFrom(state$),
    map(([_, state]) => ({
      selected: selectors.selectedCurrentBatchDownload(state),
      currentImageGrid: selectors.currentImageGrid(state),
      currentProject: apiSelectors.currentProject(state)
    })),
    map(({ selected, currentImageGrid, currentProject }) => {
      const listSelected = SelectUtils.getSelectedList(selected) || []
      const downloadedImages: TrainOutputImage[] = []
      listSelected.forEach(order => {
        downloadedImages.push(currentImageGrid[order])
      })
      const sampleDownloadedImage: TrainOutputImage = downloadedImages?.[0] as TrainOutputImage
      const batch = sampleDownloadedImage?.batch ?? 0
      const fileNamePrefix = `${batch + 1}`
      const length = downloadedImages.length ?? ''

      return { downloadedImages, fileNamePrefix, currentProject, length }
    }),
    map(({ downloadedImages, currentProject, fileNamePrefix, length }) => ({
      files: downloadedImages.map(trainOutputImage => ({
        fileUrl: trainOutputImage.image?.file ?? '',
        imageName: Utils.getOutputImageName({
          currentProject,
          trainOutputImage
        })
      })),
      fileName: `${currentProject.name}-snapshot_${fileNamePrefix}-selected-${length}`
    })),
    concatMap(({ files, fileName }) => [
      appActions.log.download({
        download_file_type: 'image',
        download_location: 'Train Project - Download Selected Images'
      }),
      downloaderActions.multiple.executeDownloadMultipleImage({
        files,
        fileName
      }),
      actions.download.resetImageSelector(),
      actions.download.closeImageSelector()
    ])
  )

/* 
  Update URL when slider value 
  Debounce to fix safari error : 
  SecurityError: Attempt to use history.replaceState() more than 100 times per 30 seconds
*/
const onSliderChangeEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.setSlideshowValue, actions.setSliderValue, actions.onUnmount])),
    withLatestFrom(state$),
    switchMap(param =>
      merge(
        of(param).pipe(
          filter(([action]) => action.type !== getType(actions.onUnmount)),
          delay(500),
          map(([action, state]) => ({
            matchBatch: matchPath<keyof ProjectResultParamType, string>(
              route.TRAIN_PROJECTS.getPath(route.TRAIN_PROJECTS.paths.length - 1) ?? '',
              appSelectors.routerLocation(state)?.pathname ?? ''
            ),
            matchOrder: matchPath<keyof ProjectResultParamType, string>(
              route.TRAIN_PROJECTS.getPath() ?? '',
              appSelectors.routerLocation(state)?.pathname ?? ''
            ),
            sliderData: selectors.sliderData(state),
            slideshowData: selectors.slideshowData(state),
            projectId: apiSelectors.currentProjectId(state)
          })),

          map(({ matchBatch, matchOrder, ...param }) => ({
            ...param,
            showSlideShow: Boolean(matchOrder),
            inResultPage: Boolean(
              matchBatch?.params.subRoute === SUB_RESULT ||
                matchOrder?.params.subRoute === SUB_RESULT
            )
          })),
          filter(({ inResultPage }) => inResultPage),
          map(({ projectId, sliderData, slideshowData, showSlideShow }) =>
            showSlideShow
              ? route.TRAIN_PROJECTS.getUrl({
                  id: projectId,
                  subRoute: SUB_RESULT,
                  batch: slideshowData.value,
                  order: slideshowData.order
                })
              : route.TRAIN_PROJECTS.getUrl({
                  id: projectId,
                  subRoute: SUB_RESULT,
                  batch: sliderData.value
                })
          ),
          tap(url => UrlUtils.replaceState(url)),
          map(() => actions.loadAllCurrentGridImages())
        )
      )
    )
  )

const loadAllCurrentGridImagesEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.loadAllCurrentGridImages)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      requestHistory: selectors.currentImageGridRequestHistory(state)
    })),
    filter(
      ({ requestHistory }) =>
        Boolean(requestHistory && requestHistory.next) || !Boolean(requestHistory)
    ),
    mergeMap(() =>
      action$.pipe(
        filter(isActionOf(apiActions.projects.retrieveOutputImagesResponse)),
        take(1),
        map(() => actions.loadAllCurrentGridImages()),
        startWith(actions.loadMoreImageGrid())
      )
    )
  )

export const epics = combineEpics(
  setSliderValueEpic,
  retrieveInitDataEpic,
  retrieveSlideshowImageEpic,
  retrieveGridImageEpic,
  checkGridAndReloadEpic,
  downloadAllSavedEpic,
  onReceiveProjectEpic,
  downloadSelectedSavedEpic,
  downloadAllInferenceEpic,
  loadAllCurrentGridImagesEpic,
  downloadCurrentOrderEpic,
  downloadCurrentBatchEpic,
  downloadSelectedImagesEpic,
  onSliderChangeEpic,
  generatePanelEpics,
  collectionSelectorPanelEpics,
  inputPanelEpics
  // sellAsNFTEpics
)
export type TrainProjectPageState = {
  collectionSelectorPanel: CollectionSelectorPanelState
  inputPanel: InputPanelState
  resultPanel: ResultPanelState
  generatePanel: GeneratePanelState
  // sellAsNFT: SellAsNFTState
}

export default combineReducers<TrainProjectPageState>({
  collectionSelectorPanel: collectionSelectorPanelReducer,
  inputPanel: inputPanelReducer,
  resultPanel: reducer,
  generatePanel: generatePanelReducer
  // sellAsNFT: sellAsNFTReducer
})
