import React, { ElementType, ReactNode } from 'react'
import TypographyMaterial, {
  TypographyProps as TypographyMaterialProps
} from '@mui/material/Typography'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

export type TypographyProps = TypographyMaterialProps & {
  component?: ElementType
  maxLine?: number
  ellipsis?: ReactNode
}

const Typography: React.FC<TypographyProps> = props => {
  const { maxLine, children, ellipsis, ...restProps } = props

  if (maxLine && maxLine > 0 && typeof children === 'string') {
    return (
      <TypographyMaterial {...restProps}>
        <ResponsiveEllipsis
          className="display-inherit"
          text={children}
          maxLine={maxLine}
          ellipsis={ellipsis || null}
          basedOn="letters"
          component="span"
          trimRight
        />
      </TypographyMaterial>
    )
  }

  return <TypographyMaterial {...restProps}>{children}</TypographyMaterial>
}

export default Typography
