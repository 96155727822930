import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Slide from '@mui/material/Slide'
import { appSelectors } from 'duck/AppDuck'
import { RootActionType, RootState } from 'duck'
import { values } from 'appConstants'
import UpscaleGenerate from './UpscaleGenerate'
import { actions, selectors, UpscalePanelState } from './duck'
import Dialog from 'components/Dialog'
import UpscaleListPanel from './UpscaleListPanel'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  hasUpscaleDialog: appSelectors.dialog.hasUpscaleDialog(state),
  openUpscaleList: selectors.openUpscaleList(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      setMode: actions.setMode,
      setOpenUpscaleList: actions.upscaleList.setOpenUpscaleList,
      reset: actions.reset
    },
    dispatch
  )

export type UpscalePanelProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  generatePanelClassName?: string
  mode?: UpscalePanelState['mode']
  position?: 'fixed' | 'static' | 'absolute'
  variant?: 'video-panel' | 'home-page'
}

const UpscalePanel: React.FC<UpscalePanelProps> = props => {
  const {
    setOpenUpscaleList,
    reset,
    setMode,
    mode,
    generatePanelClassName,
    hasUpscaleDialog,
    openUpscaleList,
    position,
    variant
  } = props

  useEffect(() => {
    return () => {
      setOpenUpscaleList(false)
    }
  }, [setOpenUpscaleList])

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  useEffect(() => {
    if (mode) {
      setMode(mode)
    }
  }, [mode, setMode])

  return (
    <>
      <Slide
        direction="left"
        in={hasUpscaleDialog || mode === 'standalone'}
        timeout={values.DEFAULT_TRANSITION_DURATION}
        mountOnEnter
        unmountOnExit
      >
        <UpscaleGenerate
          className={generatePanelClassName}
          variant={variant}
          position={position}
          onClose={() => reset()}
        />
      </Slide>

      <Dialog
        open={openUpscaleList}
        transition="slideLeft"
        maxWidth={false}
        disableContentSpacing
        hideDialogClose
        fullScreen
      >
        <UpscaleListPanel />
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpscalePanel)
