import { forwardRef, ReactElement, useMemo } from 'react'
import { ListProps as ListComponentProps } from 'react-virtualized/dist/commonjs/List'
import { ListVirtualizedProps } from '../index'
import FixedSizeListFixedHeight from './FixedSizeListFixedHeight'
import FixedSizeListAutoheight from './FixedSizeListAutoheight'
import FixedSizeListWindow from './FixedSizeListWindow'
import { useBreakpoint } from 'utils/Hooks'
import { CONFIG_EMPTY } from '../index'

export type FixedSizeListProps<Item> = Pick<
  ListComponentProps,
  'autoHeight' | 'className' | 'noRowsRenderer' | 'overscanRowCount'
> &
  Pick<
    ListVirtualizedProps<Item>,
    // | 'calculateCellHeight'
    | 'classes'
    | 'config'
    | 'loading'
    | 'loadingPosition'
    | 'loadingSize'
    | 'onScroll'
    | 'renderItem'
    | 'scrollRef'
  > & {
    dataLength?: number
    debug?: boolean
    containerRef?: React.MutableRefObject<any>
    onEndOfGrid?: () => void
  }

function FixedSizeList<Item>(props: FixedSizeListProps<Item>, ref: any) {
  const {
    autoHeight,
    // calculateCellHeight,
    config: configCustom,
    containerRef,
    ...restProps
  } = props

  const { breakpoint } = useBreakpoint()
  const config = useMemo(
    () => ({
      ...(configCustom ? configCustom.default : CONFIG_EMPTY),
      ...(configCustom && breakpoint ? configCustom[breakpoint] : CONFIG_EMPTY)
    }),
    [breakpoint, configCustom]
  )

  if (!props.autoHeight && (config.rowHeight || config.square)) {
    // console.log('FixedSizeListWindow container')
    return (
      <FixedSizeListWindow
        {...restProps}
        setRef={ref}
        containerRef={containerRef}
        config={config}
      />
    )
  }

  if (autoHeight) {
    // console.log('FixedSizeListAutoheight container')
    return (
      <FixedSizeListAutoheight<Item>
        setRef={ref}
        containerRef={containerRef}
        config={config}
        {...restProps}
        autoHeight
      />
    )
  }

  // console.log('FixedSizeListFixedHeight container')

  return (
    <FixedSizeListFixedHeight<Item>
      setRef={ref}
      containerRef={containerRef}
      config={config}
      {...restProps}
    />
  )
}

export default forwardRef(FixedSizeList) as <Item>(
  props: FixedSizeListProps<Item> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReactElement
