import React, { ElementType, forwardRef, Ref } from 'react'
import clsx from 'clsx'
import ButtonMaterial, { ButtonProps as ButtonMaterialProps } from '@mui/material/Button'
import IconButtonMaterial, {
  IconButtonProps as IconButtonMaterialProps
} from '@mui/material/IconButton'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'

import styles from './Button.module.scss'

function setAdjustedButtonProps(
  variant: ButtonMaterialProps['variant'],
  color: ButtonColorProps,
  size: ButtonSizeProps,
  icon?: boolean
) {
  let adjustedColor = color
  let adjustedSize = size
  let adjustedClassName: string | undefined = undefined

  if (color === 'light') {
    adjustedColor = undefined
    adjustedClassName =
      adjustedClassName +
      ' ' +
      clsx(
        (variant === 'text' || !variant) && styles.ButtonTextLight,
        variant === 'outlined' && styles.ButtonOutlinedLight,
        variant === 'contained' && styles.ButtonContainedLight
      )
  }

  if (icon) {
    if (variant === 'outlined') {
      adjustedClassName = clsx(
        adjustedClassName,
        styles.ButtonIconOutlined,
        color === 'primary' && styles.ButtonIconOutlinedPrimary,
        color === 'secondary' && styles.ButtonIconOutlinedSecondary,
        size === 'large' && styles.ButtonIconOutlinedSizeLarge,
        size === 'small' && styles.ButtonIconOutlinedSizeSmall,
        (!size || size === 'medium') && styles.ButtonIconOutlinedSizeMedium
      )
    } else if (variant === 'contained') {
      adjustedClassName = clsx(
        adjustedClassName,
        styles.ButtonIconContained,
        color === 'primary' && styles.ButtonIconContainedPrimary,
        color === 'secondary' && styles.ButtonIconContainedSecondary,
        color === 'info' && styles.ButtonIconContainedInfo,
        color === 'warning' && styles.ButtonIconContainedWarning,
        color === 'success' && styles.ButtonIconContainedSuccess,
        color === 'error' && styles.ButtonIconContainedError
      )
    }
  }

  if (size === 'xsmall') {
    adjustedClassName = clsx(
      adjustedClassName,
      !icon && (variant === 'outlined' ? styles.ButtonOutlinedSizeXsmall : styles.ButtonSizeXsmall),
      icon &&
        (variant === 'outlined' ? styles.ButtonIconOutlinedSizeXsmall : styles.ButtonIconSizeXsmall)
    )
  }

  return [adjustedColor, adjustedSize, adjustedClassName]
}

export type ButtonColorProps = ButtonMaterialProps['color'] | 'light' | 'default'
export type ButtonSizeProps = ButtonMaterialProps['size'] | 'xsmall'

type ButtonIconProps = IconButtonMaterialProps &
  Pick<ButtonProps, 'aria-label' | 'fullWidth'> & {
    component?: ElementType<any>
    iconSquare?: boolean
    setRef?: React.Ref<any>
    to?: string
    variant?: ButtonMaterialProps['variant']
  }

type ButtonTextProps = ButtonMaterialProps &
  Pick<
    ButtonProps,
    'aria-label' | 'align' | 'component' | 'disableMinWidth' | 'disableUppercase' | 'target' | 'to'
  > & {
    setRef?: React.Ref<any>
  }

export type ButtonProps = Omit<ButtonMaterialProps, 'color' | 'size'> & {
  align?: 'left' | 'right'
  color?: ButtonColorProps
  component?: ElementType<any>
  disableMinWidth?: boolean
  disableUppercase?: boolean
  icon?: boolean
  iconSquare?: boolean
  size?: ButtonSizeProps
  target?: '_blank'
  to?: string
  tooltip?: string
  TooltipProps?: Omit<TooltipProps, 'children' | 'title'>
}

const ButtonIcon = forwardRef((props: ButtonIconProps, ref: Ref<any>) => {
  const {
    children,
    className,
    color,
    iconSquare,
    onClick,
    size,
    variant,
    fullWidth,
    to,
    ...restProps
  } = props

  const navigate = useNavigate()
  const [adjustedColor, adjustedSize, adjustedClassName] = setAdjustedButtonProps(
    variant,
    color,
    size,
    true
  )

  return (
    <IconButtonMaterial
      ref={ref}
      {...restProps}
      children={children}
      className={clsx(
        className,
        adjustedClassName,
        iconSquare && 'rounded-none',
        fullWidth && 'w-full'
      )}
      color={adjustedColor as ButtonMaterialProps['color'] | 'default'}
      // onClick={onClick}
      onClick={
        onClick || to
          ? event => {
              onClick?.(event)
              if (to) navigate(to)
            }
          : undefined
      }
      size={adjustedSize as ButtonMaterialProps['size']}
    />
  )
})

const ButtonLink: React.FC<ButtonTextProps> = props => {
  /*
    Utilize useNavigate instead of component={Link} due to conflict with onClick
    at the time of created this 12/30/21
  */

  const { onClick, href, setRef, target, to, ...restProps } = props
  const navigate = useNavigate()

  if (href) {
    return (
      <ButtonMaterial
        {...restProps}
        // href={href}
        // target={target}
        ref={setRef}
        onClick={event => {
          onClick?.(event)
          window?.open(href, target, 'noopener')
        }}
      />
    )
  }

  return (
    <ButtonMaterial
      {...restProps}
      ref={setRef}
      onClick={event => {
        onClick?.(event)
        if (to) navigate(to)
      }}
    />
  )
}

const ButtonText = forwardRef((props: ButtonTextProps, ref: Ref<any>) => {
  const {
    'aria-label': ariaLabel,
    align,
    children,
    className,
    classes,
    color,
    component,
    disableMinWidth,
    disableUppercase,
    endIcon,
    fullWidth,
    href,
    onClick,
    startIcon,
    to,
    size,
    target,
    variant,
    ...restProps
  } = props

  const [adjustedColor, adjustedSize, adjustedClassName] = setAdjustedButtonProps(
    variant,
    color,
    size
  )

  const adjustedClasses =
    adjustedSize === 'xsmall' && (startIcon || endIcon)
      ? {
          startIcon: styles.ButtonSizeXsmallStartIcon,
          endIcon: styles.ButtonSizeXsmallEndIcon,
          ...classes
        }
      : classes

  if (to || href)
    return (
      <ButtonLink
        aria-label={ariaLabel}
        children={children}
        classes={adjustedClasses}
        className={clsx(
          disableMinWidth && 'min-w-0',
          align === 'left' && 'justify-start text-left',
          align === 'right' && 'justify-end text-right',
          !disableUppercase && 'uppercase',
          adjustedClassName,
          className
        )}
        color={adjustedColor as ButtonMaterialProps['color']}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={href}
        onClick={onClick}
        setRef={ref}
        size={adjustedSize as ButtonMaterialProps['size']}
        startIcon={startIcon}
        to={to}
        target={target}
        variant={variant}
      />
    )

  const additionalProps: {
    component?: ButtonProps['component']
  } = {}
  if (component) additionalProps.component = component

  return (
    <ButtonMaterial
      ref={ref}
      {...restProps}
      aria-label={ariaLabel}
      children={children}
      classes={adjustedClasses}
      className={clsx(
        disableMinWidth && 'min-w-0',
        align === 'left' && 'justify-start text-left',
        align === 'right' && 'justify-end text-right',
        !disableUppercase && 'uppercase',
        adjustedClassName,
        className
      )}
      color={adjustedColor as ButtonMaterialProps['color']}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      size={adjustedSize as ButtonMaterialProps['size']}
      startIcon={startIcon}
      variant={variant}
      {...additionalProps}
    />
  )
})

const Button = (props: ButtonProps, ref: any) => {
  const {
    component,
    disableUppercase,
    endIcon,
    icon,
    iconSquare,
    startIcon,
    tooltip,
    TooltipProps,
    ...restProps
  } = props

  const ButtonComponent = icon ? (
    <ButtonIcon
      {...(restProps as ButtonIconProps)}
      ref={ref}
      iconSquare={iconSquare}
      aria-label={
        props['aria-label'] || (typeof props.children === 'string' ? props.children : undefined)
      }
    />
  ) : (
    <ButtonText
      {...(restProps as ButtonTextProps)}
      ref={ref}
      disableUppercase={disableUppercase}
      endIcon={endIcon}
      startIcon={startIcon}
      aria-label={
        props['aria-label'] || (typeof props.children === 'string' ? props.children : undefined)
      }
    />
  )

  if (tooltip)
    return (
      <Tooltip
        title={tooltip}
        {...TooltipProps}
        className={clsx(props.fullWidth && 'w-full', TooltipProps?.className)}
      >
        {props.disabled ? <span className="inline-block">{ButtonComponent}</span> : ButtonComponent}
      </Tooltip>
    )

  return ButtonComponent
}

export default forwardRef(Button)
