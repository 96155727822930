import { Vector2d } from 'konva/lib/types'
import { Size } from '../CanvasBuilder'

export const gridUtils = {
  getInitialCamera: (stageSize: Size) => {
    return {
      x: stageSize.width / 2,
      y: stageSize.height / 2
    }
  },
  /* Get mouse xy when on click */
  getClientXY: (event: any) => {
    const firstTouch = event?.touches?.[0] || event?.changedTouches?.[0]

    if (firstTouch) {
      return [firstTouch.clientX || 0, firstTouch.clientY || 0]
    } else {
      return [event?.clientX || 0, event?.clientY || 0]
    }
  },
  getCameraPosAfterZoom: (param: {
    zoomCenter: Vector2d
    oldZoom: number
    newZoom: number
    camera: Vector2d
  }) => {
    const { zoomCenter, oldZoom, newZoom, camera } = param
    const mousePointTo = {
      x: zoomCenter.x / oldZoom - camera.x / oldZoom,
      y: zoomCenter.y / oldZoom - camera.y / oldZoom
    }

    return {
      x: -(mousePointTo.x - zoomCenter.x / newZoom) * newZoom || 0,
      y: -(mousePointTo.y - zoomCenter.y / newZoom) * newZoom || 0
    }
  }
}

export * from './AnimationStateManager'
export * from './ImageStateManager'
