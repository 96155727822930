import React, { ChangeEvent, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet'
import GoogleIcon from '@mui/icons-material/Google'
import { TextTransform } from 'utils/TextUtils'
import Button from 'components/Button'
import { FirebaseState, SignInProvider, SIGN_IN_PROVIDER_TEXT_MAP } from 'duck/FirebaseDuck'
import Checkbox from 'components/Checkbox'
import ButtonBase from 'components/Button/ButtonBase'
import Tooltip from 'components/Tooltip'
import { SigninModalStatus, SigninPanelProps } from './SigninPanel'

import styles from './Signin.module.scss'

import imgFacebook from 'assets/images/icons/social-facebook-circle.png'
import imgTwitter from 'assets/images/icons/social-twitter-circle.png'
import { FirebaseError } from 'firebase/app'
import { AuthCredential } from 'firebase/auth'
import { twMerge } from 'tailwind-merge'

type HideConfig = Record<SignInProvider, boolean>

export const INITIAL_HIDE_CONFIG: HideConfig = {
  'google.com': true,
  'twitter.com': true,
  'facebook.com': true,
  emailLink: true,
  phone: true,
  password: true
}

type SignInBoxProps = { isDialogMode: boolean; children?: React.ReactNode }

const SigninBox: React.FC<SignInBoxProps> = ({ children, isDialogMode }) => (
  <div className={twMerge('mx-auto my-9 flex flex-col', styles.SigninBox, !isDialogMode && 'p-6')}>
    {children}
  </div>
)

export const CheckResendEmailContent: React.FC<
  {
    setNeedHelp: () => void
    goBack: () => void
    email: string
  } & SignInBoxProps
> = props => (
  <SigninBox isDialogMode={props.isDialogMode}>
    <Helmet>
      <title>Playform - Check your email</title>
    </Helmet>

    <Typography variant="h5" component="p">
      We sent another email to {props.email}
    </Typography>

    <Typography className="mt-2.5 break-words" variant="body1" component="p">
      Check your email to complete sign-up / log-in.
    </Typography>

    <div className="mt-auto flex items-center justify-between">
      <Button color="secondary" onClick={() => props.setNeedHelp()}>
        Trouble Getting Email?
      </Button>

      <Button color="secondary" onClick={() => props.goBack()}>
        Back
      </Button>
    </div>
  </SigninBox>
)

export const ResendEmailContent: React.FC<
  {
    handleResendEmail: () => void
    goBack: () => void
  } & SignInBoxProps
> = props => (
  <SigninBox isDialogMode={props.isDialogMode}>
    <Helmet>
      <title>Playform - Login Help</title>
    </Helmet>

    <Typography variant="h5" component="p">
      Having trouble?
    </Typography>

    <Typography className="mt-2.5" variant="body2" component="p">
      Common fixes:
      <br />
      • Check if the email was marked as spam or filtered.
      <br />
      • Check your internet connection.
      <br />
      • Check that you did not misspell your email.
      <br />
      • Check that your inbox space is not running out or other inbox issues.
      <br />
      <br />
      If these steps didn’t work, you can resend the email. Doing so will deactivate the link in the
      older email.
    </Typography>

    <div className="mt-auto flex items-center justify-between">
      <Button color="secondary" onClick={() => props.handleResendEmail()}>
        Resend Email
      </Button>

      <Button color="secondary" onClick={() => props.goBack()}>
        Back
      </Button>
    </div>
  </SigninBox>
)

export const CheckEmailContent: React.FC<
  {
    setNeedHelp: () => void
    goBack: () => void
    email: string
  } & SignInBoxProps
> = props => (
  <SigninBox isDialogMode={props.isDialogMode}>
    <Helmet>
      <title>Playform - Check your email</title>
    </Helmet>

    <Typography variant="h5" component="p">
      Check your email to complete sign-up / log-in
    </Typography>

    <Typography className="mt-2.5 break-words" variant="body1" component="p">
      A sign-in email with additional instructions was sent to <b>{props.email}</b>
    </Typography>

    <div className="mt-auto flex items-center justify-between">
      <Button color="secondary" onClick={() => props.setNeedHelp()}>
        Trouble Getting Email
      </Button>

      <Button color="secondary" onClick={() => props.goBack()}>
        Back
      </Button>
    </div>
  </SigninBox>
)

export const LoginError: React.FC<
  {
    error: FirebaseError
    goBack: () => void
  } & SignInBoxProps
> = ({ error, goBack, isDialogMode }) => {
  const title = TextTransform.firebaseErrorCodeToTitle(error)
  return (
    <SigninBox isDialogMode={isDialogMode}>
      <Helmet>
        <title>{`Playform - ${title} `}</title>
      </Helmet>

      <Typography color="error" variant="h5" component="p">
        {title}
      </Typography>

      <Typography className="mt-8 break-words" variant="body1" component="p">
        {error.message}
      </Typography>

      <div className="mt-auto flex items-center justify-between">
        <Button size="small" color="secondary" onClick={() => goBack()}>
          Back
        </Button>
      </div>
    </SigninBox>
  )
}

export const ThirdPartySigninList: React.FC<
  Pick<SigninPanelProps, 'signinWith'> & {
    hideConfig?: HideConfig
    additionalSignin?: React.ReactNode
    pendingCredential?: AuthCredential
  }
> = ({ signinWith, hideConfig, additionalSignin = null, pendingCredential }) => {
  return (
    <div className="mt-6 flex justify-center">
      {!hideConfig?.['google.com'] ? (
        <Button
          variant="outlined"
          onClick={() => {
            signinWith({ signInProvider: 'google.com', pendingCredential })
          }}
          startIcon={<GoogleIcon />}
          tooltip="Sign In with Google Account"
          fullWidth
        >
          <span className="w-full">CONTINUE WITH GOOGLE</span>
        </Button>
      ) : null}

      {!hideConfig?.['facebook.com'] ? (
        <Tooltip title="Sign In with Facebook Account">
          <span>
            <ButtonBase
              className="mx-3"
              onClick={() => {
                signinWith({ signInProvider: 'facebook.com', pendingCredential })
              }}
            >
              <img
                className="pointer-events-none select-none"
                src={imgFacebook}
                alt="Sign In with Facebook"
                title="Facebook"
              />
            </ButtonBase>
          </span>
        </Tooltip>
      ) : null}

      {!hideConfig?.['twitter.com'] ? (
        <Tooltip title="Sign In with Twitter Account">
          <span>
            <ButtonBase
              onClick={() => {
                signinWith({ signInProvider: 'twitter.com', pendingCredential })
              }}
            >
              <img
                className="pointer-events-none mx-3 select-none"
                src={imgTwitter}
                alt="Sign In with Twitter"
                title="Twitter"
              />
            </ButtonBase>
          </span>
        </Tooltip>
      ) : null}
      {additionalSignin}
    </div>
  )
}

export const AccountExist: React.FC<
  {
    pendingCredential: FirebaseState['pendingCredential']
    goBack: () => void
    setSigninModalStatus: React.Dispatch<React.SetStateAction<SigninModalStatus>>
  } & SignInBoxProps &
    Pick<SigninPanelProps, 'signinWith' | 'requestLogin'>
> = ({
  pendingCredential,
  isDialogMode,
  goBack,
  signinWith,
  requestLogin,
  setSigninModalStatus
}) => {
  const [addSigninMethod, setAddSigninMethod] = useState<boolean>(true)

  const currentProviders = pendingCredential?.signinProviders ?? ['facebook.com']
  const credential = pendingCredential?.credential
  const currentSignInProvider = pendingCredential?.currentSignInProvider
  const currentSignInProviderText = currentSignInProvider
    ? SIGN_IN_PROVIDER_TEXT_MAP[currentSignInProvider]
    : ''

  const email = pendingCredential?.email

  const hideConfig = currentProviders.reduce((result, value) => {
    result[value] = false
    return result
  }, INITIAL_HIDE_CONFIG)

  const title = `Your email has been registered using another sign-in method`
  return (
    <SigninBox isDialogMode={isDialogMode}>
      <Helmet>
        <title>{`Playform - ${title} `}</title>
      </Helmet>

      <Typography variant="h6" component="p">
        {title}
      </Typography>
      {currentProviders.length ? (
        <>
          <Typography className="mt-8 break-words" variant="body1" component="p">
            Your email is
            <i>
              <b>{` ${email}`}</b>
            </i>
            . You can continue sign-in into playform using following method :
          </Typography>

          <div className="mt-6">
            <ThirdPartySigninList
              pendingCredential={addSigninMethod ? credential : undefined}
              hideConfig={hideConfig}
              signinWith={signinWith}
              additionalSignin={
                email ? (
                  <div>
                    <Tooltip title={`Send an magic link to email : ${email}`}>
                      <span>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setSigninModalStatus('CHECK_EMAIL')
                            requestLogin({
                              email,
                              pendingCredential: addSigninMethod ? credential : undefined
                            })
                          }}
                        >
                          Send Email Link
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                ) : undefined
              }
            />
          </div>

          <div className="mt-6">
            <Checkbox
              color="secondary-alt"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setAddSigninMethod(value => !value)
              }}
              checked={addSigninMethod}
              label={
                <Typography variant="caption">{`Add ${currentSignInProviderText} as one of your sign-in method`}</Typography>
              }
            />
          </div>
        </>
      ) : null}

      <div className="mt-auto flex items-center justify-between">
        <Button size="small" color="secondary" onClick={() => goBack()}>
          {currentProviders.length ? 'Sign-in with Another Email' : 'Back'}
        </Button>
      </div>
    </SigninBox>
  )
}
