import { getType, ActionType, createReducer, isActionOf, createAction } from 'typesafe-actions'
import { Epic, combineEpics } from 'redux-observable'
import Bowser from 'bowser'
import _toNumber from 'lodash/toNumber'
import _compact from 'lodash/compact'
import { ShareData } from 'containers/ShareFeedPanel/duck'
import {
  AppDuckUtils,
  MixProjectActionListType,
  SketchProjectActionListType,
  ProArtFilterProjectActionListType,
  TrainProjectActionListType,
  TextToImageProjectActionListType,
  GenericAppProjectActionListType,
  SketchTextProjectActionListType
} from './Utils'
import dialogReducer, {
  dialogActions,
  dialogSelectors,
  DialogState,
  FormDialog,
  MonetizationDialog
} from './DialogDuck'
import {
  withLatestFrom,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  delay,
  tap,
  ignoreElements,
  concatMap,
  catchError
} from 'rxjs/operators'
import snackBarReducer, {
  snackBarActions,
  selectors as snackBarSelectors,
  SnackbarState
} from './SnackBarDuck'
import phoneReducer, { phoneEpics, PhoneState } from './PhoneDuck'
import downloaderReducer, { downloaderEpics, DownloaderState } from './DownloaderDuck'
import bannerReducer, {
  selectors as bannerSelectors,
  BannerState,
  epics as bannerEpics
} from './BannerDuck'
import changeEmailReducer, {
  changeEmailActions,
  selectors as changeEmailSelector,
  epics as changeEmailEpics,
  ChangeEmailState
} from './ChangeEmailDuck'
import { RootActionType, RootState } from 'duck'
import copy from 'copy-text-to-clipboard'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import {
  StopableProjectStatus,
  ActiveProjectStatus,
  ClapReq,
  ProcessGroupType,
  ProcessType,
  RelatedType,
  ImageSetType,
  ProjectObjectType,
  NetworkType
} from 'models/ApiModels'
import { createSelector } from 'reselect'
import {
  route,
  SUB_VIDEO,
  CLOSE_TAB_ACTION,
  NavbarMenuType,
  NEW_PROJECT_ACTION,
  CREATE_NEW_PROJECT_ACTION
} from 'routes'
import { of, merge, concat, from } from 'rxjs'
import { errorUtils } from 'utils/DataProcessingUtils'
import { combineReducers } from 'redux'
import { SessionStorage } from 'utils'
import { LSKey, urlParam, values } from 'appConstants'
import { ErrorDialog, ConfirmationDialog } from 'duck/AppDuck/DialogDuck'
import MixPanelUtils, { DataUtils, EventProperties } from 'utils/MixPanelUtils'
import ProjectsSelectors from 'duck/ApiDuck/selectors/ProjectsSelectors'
import SketchToImageSelectors from 'duck/ApiDuck/selectors/SketchToImageSelectors'
import MixImageSelectors from 'duck/ApiDuck/selectors/MixImageSelectors'
import { CollectionSourceType } from 'containers/CollectionEditorPanel/duck'
import { AppEvents, eventEmiterActions } from './EventEmitterDuck'
import { errorHandlerEpics } from './ErrorHandlerDuck'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import { push } from 'redux-first-history'
import CryptoUtils from 'utils/CryptoUtils'

export type ActionTypeParam = {
  type: string
  payload?: any
  meta?: any
}

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export type TrainProjectActionType =
  | 'duplicate'
  | 'share'
  | 'deleteProject'
  | 'changeScope'
  | 'rename'
  | 'sellAsNft'

export type SketchProjectActionType = 'deleteProject' | 'rename' | 'sellAsNft'
export type SketchTextProjectActionType = 'deleteProject' | 'rename'
export type MixProjectActionType = 'deleteProject' | 'rename' | 'sellAsNft'
export type ProArtFilterProjectActionType = 'deleteProject' | 'rename' | 'sellAsNft'
export type TextToImageProjectActionType = 'deleteProject' | 'rename' | 'sellAsNft'
export type GenericAppProjectActionType = 'deleteProject' | 'rename' | 'sellAsNft'

export type CreateProjectParam = {
  name: string
  processGroup: ProcessGroupType
  process: ProcessType
}

// Actions
export const appActions = {
  emptyAction: createAction('@@page/App/EMPTY_ACTION')(),
  setAuthenticating: createAction('@@page/App/AUTHENTICATING')<boolean>(),
  setHasSubPage: createAction('@@page/App/SET_HAS_SUBPAGE')<boolean>(),
  setActiveMenu: createAction('@@page/App/SET_ACTIVE_MENU')<NavbarMenuType>(),
  pushTo: createAction('@@page/App/PUSH_TO')<string>(),
  openUpgradePage: createAction('@@page/App/OPEN_UPGRADE_PAGE')(),
  openUpscaleImage: createAction('@@page/App/OPEN_UPSCALE_IMAGE')<{
    userImageId: number
    useAddDialog?: boolean
  }>(),
  openImageEnhancement: createAction('@@page/App/OPEN_IMAGE_ENHANCEMENT')<{
    userImageId: number
    source: ProjectObjectType
  }>(),
  openProject: createAction('@@page/App/OPEN_PROJECT')<
    Pick<RelatedType, 'id' | 'object_type'> & { target?: '_blank' | '_self' }
  >(),
  setOpenProjectSource: createAction('@@page/App/SET_OPEN_PROJECT_SOURCE')<
    AppState['openProjectSource']
  >(),
  openShare: createAction('@@page/App/OPEN_SHARE')<
    Pick<ShareData, 'related' | 'entity'> & { useAddDialog?: boolean }
  >(),
  listEngineConfig: createAction('@@page/App/LIST_ENGINE_CONFIG')(),
  saveNotificationPreferences: createAction('@@page/App/SAVE_NOTIFICATION_PREFERENCES')<
    Record<number, boolean>
  >(),
  setSaveNotificationPreferencesStatus: createAction(
    '@@page/App/SET_SAVE_NOTIFICATION_PREFERENCES_STATUS'
  )<boolean>(),
  submitTextToImageAgreement: createAction('@@page/App/SUBMIT_TEXT_TO_IMAGE_AGREEMENT')(),
  useCollectionInNewProject: createAction('@@page/App/USE_COLLECTION_IN_NEW_PROJECT')<{
    source?: CollectionSourceType
    collectionId: number
  }>(),

  log: {
    clickBackToTrainingDetail: createAction(
      '@@page/App/log/CLICK_BACK_TO_TRAINING_DETAIL'
    )<number>(),
    clickAddCollection: createAction('@@page/App/log/CLICK_ADD_COLLECTION')<ImageSetType>(),
    clickAddCollectionOptions: createAction('@@page/App/log/CLICK_ADD_COLLECTION_OPTIONS')<{
      image_set: ImageSetType
      collection_source_type: CollectionSourceType
    }>(),
    download: createAction('@@page/App/log/DOWNLOAD')<EventProperties['DOWNLOAD']>(),
    projectBookmark: createAction('@@page/App/log/PROJECT_BOOKMARK')<
      EventProperties['PROJECT_BOOKMARK'] & { bookmarkId?: number }
    >()
  },
  projectAction: {
    duplicate: createAction('@@page/App/Project/DUPLICATE')<number>(),
    share: createAction('@@page/App/Project/SHARE')<number>(),
    deleteProject: createAction('@@page/App/Project/DELETE_PROJECT')<number>(),
    changeScope: createAction('@@page/App/Project/CHANGE_SCOPE')<number>(),
    stopProject: createAction('@@page/App/Project/STOP_PROJECT')<number>(),
    executeStopProject: createAction('@@page/App/Project/EXECUTE_STOP_PROJECT')<number>(),
    createProject: createAction('@@page/App/Project/CREATE_PROJECT')<CreateProjectParam>(),
    continueTraining: createAction('@@page/App/Project/CONTINUE_TRANING')()
  },
  sketchProjectAction: {
    deleteProject: createAction('@@page/App/SketchProject/DELETE_PROJECT')<number>()
  },
  sketchTextProjectAction: {
    deleteProject: createAction('@@page/App/SketchTextProject/DELETE_PROJECT')<number>()
  },
  proArtFilterProjectAction: {
    deleteProject: createAction('@@page/App/ProArtFilterProject/DELETE_PROJECT')<number>()
  },
  textToImageProjectAction: {
    deleteProject: createAction('@@page/App/TextToImageProjectAction/DELETE_PROJECT')<number>()
  },
  mixProjectAction: {
    deleteProject: createAction('@@page/App/MixProject/DELETE_PROJECT')<number>()
  },
  genericAppProjectAction: {
    deleteProject: createAction('@@page/App/GenericAppProject/DELETE_PROJECT')<number>()
  },
  postActions: {
    generate: createAction('@@page/App/Post/GENERATE')<number>(),
    clap: createAction('@@page/App/Post/CLAP')<ClapReq>()
  },
  question: {
    updateQuestion: createAction('@@page/App/UPDATE_QUESTION')<string>(),
    sendQuestion: createAction('@@page/App/SEND_QUESTION')<{ content: string }>()
  },
  userDevice: {
    setUserDevice: createAction('@@page/App/USER_DEVICE')<string | null>()
  },
  updateNavBarProps: createAction('@@page/App/UPDATE_NAVBAR_PROPS')<any>(),
  metamask: {
    setConnectLoading: createAction('@@page/App/METAMASK/SET_CONNECT_LOADING')<boolean>(),
    disconnect: createAction('@@page/App/METAMASK/DISCONNECT')(),
    connectToMetamask: createAction('@@page/App/METAMASK/CONNECT_TO_METAMASK')<{
      network: NetworkType
    }>()
  }
}

export type AppActions = ActionType<typeof appActions>

// Selectors
const selectApp = (state: RootState) => state.container.appPage.app
const selectFirebase = (state: RootState) => state.firebase
const selectUsers = (state: RootState) => state.api.users
const selectRouterLocation = (state: RootState) => state.router.location
const selectLoadings = (state: RootState) => {
  return state.api.shared?.loadings ?? {}
}
const selectTrainProjectActionList = (state: RootState) =>
  state.container?.appPage?.app?.trainProjectActionList

const selectMixProjectActionList = (state: RootState) =>
  state.container.appPage.app.mixProjectActionList

const selectProArtFilterProjectActionList = (state: RootState) =>
  state.container.appPage.app.proArtFilterProjectActionList

const selectTextToImageProjectActionList = (state: RootState) =>
  state.container.appPage.app.textToImageProjectActionList

const selectSketchProjectActionList = (state: RootState) =>
  state.container.appPage.app.sketchProjectActionList

const selectGenericAppProjectActionList = (state: RootState) =>
  state.container.appPage.app.genericAppProjectActionList

const selectSketchTextProjectActionList = (state: RootState) =>
  state.container.appPage.app.sketchTextProjectActionList

const selectUserDeviceInfo = (state: RootState) => state.container?.appPage?.app?.userDeviceInfo
const selectNavBarProps = (state: RootState) => state.container?.appPage?.app?.navBarProps
const saveNotificationPreferencesStatus = (state: RootState) =>
  state.container?.appPage?.app?.saveNotificationPreferencesStatus

export const selectIsInMyProjects = createSelector(selectRouterLocation, routerLocation => {
  return Boolean(routerLocation && routerLocation.pathname.includes(route.TRAIN_PROJECTS.getUrl()))
})

export const selectIsInVideoPanel = createSelector(selectRouterLocation, routerLocation => {
  return Boolean(routerLocation && routerLocation.pathname.includes(SUB_VIDEO))
})
const selectIsAuthLoading = createSelector(selectFirebase, selectLoadings, (firebase, loadings) => {
  const { loading } = firebase
  const userFetchLoading = loadings[getType(apiActions.users.retrieve)]
  return loading || userFetchLoading
})
const selectIsUserLoading = createSelector(selectLoadings, loadings => {
  const userFetchLoading = loadings[getType(apiActions.users.retrieve)]
  return userFetchLoading
})
const selectIsAuthorized = createSelector(selectFirebase, selectUsers, (firebase, users) => {
  const { firebaseUser } = firebase
  const { user } = users

  return Boolean(firebaseUser?.uid) || Boolean(user)
})
const selectIsAuthorizationComplete = createSelector(
  selectFirebase,
  selectUsers,
  (firebase, users) => {
    const { firebaseUser } = firebase
    const { user } = users
    return Boolean(firebaseUser?.uid) && Boolean(user)
  }
)

const selectProjectData = createSelector(
  ProjectsSelectors.currentProject,
  SketchToImageSelectors.currentSketchProject,
  MixImageSelectors.currentMixImageProject,
  MixImageSelectors.mixImageGenreData,
  SketchToImageSelectors.sketchGenreData,
  (
    currentProject,
    currentSketchProject,
    currentMixImageProject,
    mixImageGenreData,
    sketchGenreData
  ) => {
    const currentProjectData = currentProject?.id
      ? DataUtils.getProjectParam<'training_project'>('training_project', {
          trainProject: currentProject
        })
      : undefined
    const currentSketchProjectData = currentSketchProject?.id
      ? DataUtils.getProjectParam<'sketch_project'>('sketch_project', {
          sketchProject: currentSketchProject,
          sketchGenreData
        })
      : undefined
    const currentMixImageProjectData = currentMixImageProject?.id
      ? DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
          mixProject: currentMixImageProject,
          mixGenreData: mixImageGenreData
        })
      : undefined

    return currentProjectData ?? currentSketchProjectData ?? currentMixImageProjectData
  }
)

export const appSelectors = {
  app: selectApp,
  activeMenu: createSelector(selectApp, app => app.activeMenu),
  authenticating: createSelector(selectApp, app => app.authenticating),
  hasSubPage: createSelector(selectApp, app => app.hasSubPage),
  isInVideoPanel: selectIsInVideoPanel,
  trainProjectActionList: selectTrainProjectActionList,
  isUserLoading: selectIsUserLoading,
  firebase: selectFirebase,
  users: selectUsers,
  isAuthLoading: selectIsAuthLoading,
  isAuthorized: selectIsAuthorized,
  isAuthorizationComplete: selectIsAuthorizationComplete,
  isInMyProjects: selectIsInMyProjects,
  dialog: dialogSelectors,
  snackBar: snackBarSelectors,
  banner: bannerSelectors,
  mixProjectActionList: selectMixProjectActionList,
  sketchProjectActionList: selectSketchProjectActionList,
  proArtFilterProjectActionList: selectProArtFilterProjectActionList,
  textToImageProjectActionList: selectTextToImageProjectActionList,
  genericAppProjectActionList: selectGenericAppProjectActionList,
  sketchTextProjectActionList: selectSketchTextProjectActionList,
  changeEmail: changeEmailSelector,
  routerLocation: selectRouterLocation,
  userDeviceInfo: selectUserDeviceInfo,
  navBarProps: selectNavBarProps,
  questionContent: createSelector(selectApp, app => app.questionContent),
  openProjectSource: createSelector(selectApp, app => app.openProjectSource),
  projectData: selectProjectData,
  connectToMetaMaskLoading: createSelector(selectApp, app => app.connectMetamaskLoading)
}

// Reducer
export type AppState = {
  saveNotificationPreferencesStatus?: boolean
  connectMetamaskLoading?: boolean
  authenticating?: boolean
  hasSubPage?: boolean
  activeMenu: NavbarMenuType
  openProjectSource?: 'home' | 'explore'

  trainProjectActionList: TrainProjectActionListType
  sketchProjectActionList: SketchProjectActionListType
  sketchTextProjectActionList: SketchTextProjectActionListType
  mixProjectActionList: MixProjectActionListType
  proArtFilterProjectActionList: ProArtFilterProjectActionListType
  textToImageProjectActionList: TextToImageProjectActionListType
  genericAppProjectActionList: GenericAppProjectActionListType

  userDeviceInfo: any
  navBarProps: any
  questionContent: string
  openedPostId?: number
}

const initialAppState: AppState = {
  saveNotificationPreferencesStatus: false,
  connectMetamaskLoading: false,
  activeMenu: '',
  authenticating: undefined,
  hasSubPage: undefined,
  openProjectSource: undefined,
  openedPostId: undefined,
  questionContent: '',
  trainProjectActionList: [
    {
      key: 'rename',
      label: 'Rename'
    },
    {
      key: 'sellAsNft',
      label: 'Sell as NFT',
      hide: !values.ENABLE_SELL_NFT
    },
    {
      key: 'changeScope',
      label: 'Make Public'
    },
    { key: 'share', label: 'Copy shareable link', isDelayedAction: true },
    {
      key: 'duplicate',
      label: 'Duplicate'
    },
    {
      key: 'deleteProject',
      label: 'Delete'
    }
  ],
  mixProjectActionList: [
    {
      key: 'rename',
      label: 'Rename'
    },
    {
      key: 'sellAsNft',
      label: 'Sell as NFT',
      hide: !values.ENABLE_SELL_NFT
    },
    {
      key: 'deleteProject',
      label: 'Delete'
    }
  ],
  sketchProjectActionList: [
    {
      key: 'rename',
      label: 'Rename'
    },
    {
      key: 'sellAsNft',
      label: 'Sell as NFT',
      hide: !values.ENABLE_SELL_NFT
    },
    {
      key: 'deleteProject',
      label: 'Delete'
    }
  ],
  sketchTextProjectActionList: [
    {
      key: 'rename',
      label: 'Rename'
    },
    {
      key: 'deleteProject',
      label: 'Delete'
    }
  ],
  proArtFilterProjectActionList: [
    {
      key: 'rename',
      label: 'Rename'
    },
    {
      key: 'sellAsNft',
      label: 'Sell as NFT',
      hide: !values.ENABLE_SELL_NFT
    },
    {
      key: 'deleteProject',
      label: 'Delete'
    }
  ],
  textToImageProjectActionList: [
    {
      key: 'rename',
      label: 'Rename'
    },
    {
      key: 'sellAsNft',
      label: 'Sell as NFT',
      hide: !values.ENABLE_SELL_NFT
    },
    {
      key: 'deleteProject',
      label: 'Delete'
    }
  ],
  genericAppProjectActionList: [
    {
      key: 'rename',
      label: 'Rename'
    },
    {
      key: 'sellAsNft',
      label: 'Sell as NFT',
      hide: !values.ENABLE_SELL_NFT
    },
    {
      key: 'deleteProject',
      label: 'Delete'
    }
  ],
  userDeviceInfo: {},
  navBarProps: {}
}

const reducer = createReducer<AppState, AppActions>(initialAppState)
  .handleAction(appActions.setActiveMenu, (state, action) => ({
    ...state,
    activeMenu: action.payload
  }))
  .handleAction(appActions.setAuthenticating, (state, action) => ({
    ...state,
    authenticating: action.payload
  }))
  .handleAction(appActions.setHasSubPage, (state, action) => ({
    ...state,
    hasSubPage: action.payload
  }))
  .handleAction(appActions.setSaveNotificationPreferencesStatus, (state, action) => ({
    ...state,
    saveNotificationPreferencesStatus: action.payload
  }))
  .handleAction(appActions.question.updateQuestion, (state, action) => ({
    ...state,
    questionContent: action.payload
  }))
  .handleAction(appActions.updateNavBarProps, (state, action) => ({
    ...state,
    navBarProps: action.payload
  }))
  .handleAction(appActions.setOpenProjectSource, (state, action) => ({
    ...state,
    openProjectSource: action.payload
  }))
  .handleAction(appActions.metamask.setConnectLoading, (state, action) => ({
    ...state,
    connectMetamaskLoading: action.payload
  }))
  .handleAction(appActions.userDevice.setUserDevice, (state, action) => {
    if (!action || !action.payload) return state

    const userAgent = action.payload
    const result = {
      isOnline: navigator.onLine,
      isChrome: /chrome/.test(userAgent),
      isExplorer: /msie/.test(userAgent),
      isExplorer11: /rv:11/.test(userAgent),
      isFirefox: /firefox/.test(userAgent),
      isSafari: /safari/.test(userAgent),
      isOpera: /opr/.test(userAgent),
      isEdgeDesktop: /edge/.test(userAgent),
      isEdgeiOS: /edgios/.test(userAgent),
      isEdgeAndroid: /edga/.test(userAgent),
      isIOS: /ipad|iphone|ipod/.test(userAgent),
      isMobile: /mobile/.test(userAgent),
      isDarkMode: false,
      isStandalone: false,
      whereIsShare: '',
      isTouchDevice: isTouchDevice()
    }

    if (result.isChrome && result.isSafari) {
      result.isSafari = false
    }
    if (result.isChrome && (result.isEdgeDesktop || result.isEdgeiOS || result.isEdgeAndroid)) {
      result.isChrome = false
    }
    if (result.isSafari && (result.isEdgeDesktop || result.isEdgeiOS || result.isEdgeAndroid)) {
      result.isSafari = false
    }
    if (result.isChrome && result.isOpera) {
      result.isChrome = false
    }

    if (/ipad/.test(userAgent)) {
      result.whereIsShare = 'top'
    }

    if (window.matchMedia('(display-mode: standalone)').matches) result.isStandalone = true
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) result.isDarkMode = true

    const userDeviceInfo = {
      ...Bowser.getParser(window.navigator.userAgent),
      summary: result
    }

    return {
      ...state,
      userDeviceInfo
    }
  })

// Epic
const pushToEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.pushTo)),
    map(({ payload }) => push(payload))
  )
const useCollectionInNewProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.useCollectionInNewProject)),
    tap(action => {
      SessionStorage.save(LSKey.COLLECTION_ADDED_TO_NEW_PROJECT, action?.payload?.collectionId)
    }),
    map(() =>
      dialogActions.addDialog({
        [FormDialog.NEW_PROJECT]: {
          dialogName: FormDialog.NEW_PROJECT,
          content: 'use-in-new-project'
        }
      })
    )
  )

const openUpscaleImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.openUpscaleImage)),
    mergeMap(({ payload }) =>
      _compact([
        !payload.useAddDialog &&
          dialogActions.openDialog({
            [FormDialog.UPSCALE_RESULT]: {
              dialogName: FormDialog.UPSCALE_RESULT
            }
          }),
        payload.useAddDialog &&
          dialogActions.addDialog({
            [FormDialog.UPSCALE_RESULT]: {
              dialogName: FormDialog.UPSCALE_RESULT
            }
          }),
        eventEmiterActions.emit({
          [AppEvents.OPEN_UPSCALE_IMAGE]: {
            event: AppEvents.OPEN_UPSCALE_IMAGE,
            payload: { userImageId: payload.userImageId }
          }
        })
      ])
    )
  )

const openImageEnhancementEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.openImageEnhancement)),
    mergeMap(({ payload }) => [
      dialogActions.openDialog({
        [FormDialog.IMAGE_ENHANCEMENT]: {
          dialogName: FormDialog.IMAGE_ENHANCEMENT
        }
      }),
      eventEmiterActions.emit({
        [AppEvents.OPEN_IMAGE_ENHANCEMENT]: {
          event: AppEvents.OPEN_IMAGE_ENHANCEMENT,
          payload: { userImageId: payload.userImageId, source: payload.source }
        }
      })
    ])
  )

const openShareEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.openShare)),
    mergeMap(({ payload }) =>
      _compact([
        payload.useAddDialog &&
          dialogActions.addDialog({
            [FormDialog.SHARE_FEED]: {
              dialogName: FormDialog.SHARE_FEED
            }
          }),
        !payload.useAddDialog &&
          dialogActions.openDialog({
            [FormDialog.SHARE_FEED]: {
              dialogName: FormDialog.SHARE_FEED
            }
          }),
        eventEmiterActions.emit({
          [AppEvents.INIT_SHARE]: { event: AppEvents.INIT_SHARE, payload }
        })
      ])
    )
  )

const logDownloadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.log.download)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: selectProjectData(state),
      payload: action.payload
    })),
    tap(({ projectData, payload }) => {
      projectData &&
        MixPanelUtils.track<'PROJECT__DOWNLOAD'>('Project - Download', {
          ...projectData,
          ...payload
        })
    }),
    ignoreElements()
  )
const logOnClickAddCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.log.clickAddCollection)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: selectProjectData(state),
      image_set: action.payload
    })),
    tap(({ projectData, image_set }) => {
      projectData &&
        MixPanelUtils.track<'PROJECT__CLICK_ADD_COLLECTION'>('Project - Click Add Collection', {
          ...projectData,
          image_set
        })
    }),
    ignoreElements()
  )

const clickBackToTrainingDetailEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.log.clickBackToTrainingDetail)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: selectProjectData(state),
      quantity: action.payload
    })),
    tap(({ projectData, quantity }) => {
      projectData &&
        MixPanelUtils.track<'USER__ON_CLICK_BACK_TO_TRAINING_DETAILS'>(
          'User - On Click Back To Training Detail',
          {
            ...projectData,
            quantity
          }
        )
    }),
    ignoreElements()
  )

const logClickAddCollectionOptionsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.log.clickAddCollectionOptions)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: selectProjectData(state),
      param: action.payload
    })),
    tap(({ projectData, param }) => {
      projectData &&
        MixPanelUtils.track<'PROJECT__CLICK_ADD_COLLECTION_OPTIONS'>(
          'Project - Click Add Collection Options',
          {
            ...projectData,
            ...param
          }
        )
    }),
    ignoreElements()
  )

const openUpgradePageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.openUpgradePage)),
    map(() =>
      dialogActions.addDialog({
        [MonetizationDialog.SUBSCRIPTION_PANEL]: {
          dialogName: MonetizationDialog.SUBSCRIPTION_PANEL,
          fullPage: true
        }
      })
    )
  )

const duplicateProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.projectAction.duplicate)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.projects(state)[action.payload]),
    filter(project => Boolean(project)),
    tap(project => {
      MixPanelUtils.track<'PROJECT__DUPLICATE'>(
        'Project - Duplicate',
        DataUtils.getProjectParam<'training_project'>('training_project', {
          trainProject: project
        })
      )
    }),
    map(project =>
      apiActions.projects.copy({
        project: project.id,
        name: `${project.name} (copy)`
      })
    )
  )

const listEngineConfigEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.listEngineConfig)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const hasEngineConfig = apiSelectors.hasEngineConfig(state)
      const retrieveEngineConfigLoading = apiSelectors.loading['engine.listEngineConfig'](state)

      return { hasEngineConfig, retrieveEngineConfigLoading }
    }),
    filter(
      ({ hasEngineConfig, retrieveEngineConfigLoading }) =>
        !hasEngineConfig && !retrieveEngineConfigLoading
    ),
    map(() => apiActions.engine.listEngineConfig())
  )
const sendQuestionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.question.sendQuestion)),
    mergeMap(({ payload }) =>
      action$.pipe(
        filter(isActionOf(apiActions.users.sendQuestionResponse)),
        take(1),
        tap(() => {
          MixPanelUtils.track<'USER__SUBMIT_QUESTION'>('User - Submit Question', {
            content: payload.content
          })
        }),
        mergeMap(() => [
          dialogActions.openDialog({
            [FormDialog.SEND_QUESTION_SUCCESS]: { dialogName: FormDialog.SEND_QUESTION_SUCCESS }
          }),
          appActions.question.updateQuestion('')
        ]),
        startWith(apiActions.users.sendQuestion(payload))
      )
    )
  )

const saveNotificationPreferencesEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.saveNotificationPreferences)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      saveData: action.payload,
      notificationTemplates: apiSelectors.notificationTemplates(state)
    })),
    mergeMap(data =>
      merge(
        of(appActions.setSaveNotificationPreferencesStatus(true)),
        of(data).pipe(
          mergeMap(({ saveData, notificationTemplates }) =>
            _compact(
              notificationTemplates.map(template => {
                const accept = Boolean(saveData[template.id])
                const hasPreferences = Boolean(template.notificationPreference)
                if (hasPreferences) {
                  return apiActions.users.updateNotificationPreferences({
                    req: { accept, id: template.notificationPreference?.id ?? 0 },
                    template: template.id
                  })
                } else if (!accept && !hasPreferences) {
                  return apiActions.users.createNotificationPreferences({
                    template: template.id,
                    accept
                  })
                }
                return undefined
              })
            )
          )
        )
      )
    )
  )

const listenOnCreateUpdateNotificationPreferencesEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.users.createNotificationPreferencesResponse,
        apiActions.users.updateNotificationPreferencesResponse
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      saveNotificationPreferencesStatus: saveNotificationPreferencesStatus(state),
      notificationUpdateCreateLoading: apiSelectors.notificationUpdateCreateLoading(state)
    })),
    filter(
      ({ saveNotificationPreferencesStatus, notificationUpdateCreateLoading }) =>
        Boolean(saveNotificationPreferencesStatus) && !notificationUpdateCreateLoading
    ),
    mergeMap(param =>
      merge(
        of(dialogActions.closeDialog()),
        of(param).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(snackBarActions.show)),
              take(1),
              delay(1500),
              map(() => snackBarActions.close()),
              startWith(snackBarActions.show({ content: 'Notification Preferences Saved' }))
            )
          )
        )
      )
    )
  )

const submitTextToImageAgreementEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.submitTextToImageAgreement)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notificationTemplates: apiSelectors.user(state)
    })),
    mergeMap(payload =>
      action$.pipe(
        filter(isActionOf(apiActions.users.updateResponse)),
        take(1),
        tap(({ payload }) => {
          MixPanelUtils.track<'PROJECT__SUBMIT_TEXT_TO_IMAGE_AGREEMENT'>(
            'Project Text To Image - Submit Text To Image Agreement'
          )
        }),
        map(() => dialogActions.closeAllDialog()),
        startWith(
          apiActions.users.updateUiExtras({
            has_checked_text_to_image_agreement: true
          })
        )
      )
    )
  )

const createProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.projectAction.createProject)),
    map(({ payload }) => {
      const { name, process, processGroup } = payload

      return {
        name,
        process,
        processGroup,
        urlParam: `?${urlParam.NAME}=${name}${process ? `&${urlParam.GENRE}=${process}` : ''}`
      }
    }),
    tap(({ processGroup }) => {
      if (processGroup !== 'train') {
        SessionStorage.remove(LSKey.COLLECTION_ADDED_TO_NEW_PROJECT)
      }
    }),
    mergeMap(payload =>
      merge(
        of(payload).pipe(
          filter(({ processGroup }) => processGroup === 'mix'),
          mergeMap(({ urlParam }) => [
            push(`${route.MIX_PROJECTS.getUrl({ id: NEW_PROJECT_ACTION })}${urlParam}`),
            dialogActions.closeDialog()
          ])
        ),
        of(payload).pipe(
          filter(({ processGroup }) => processGroup === 'sketch'),
          mergeMap(({ urlParam }) => [
            push(`${route.SKETCH_PROJECTS.getUrl({ id: CREATE_NEW_PROJECT_ACTION })}${urlParam}`),
            dialogActions.closeDialog()
          ])
        ),
        of(payload).pipe(
          filter(({ processGroup }) => processGroup === 'style_transfer'),
          mergeMap(({ urlParam }) => [
            push(
              `${route.PRO_FILTER_PROJECTS.getUrl({ id: CREATE_NEW_PROJECT_ACTION })}${urlParam}`
            ),
            dialogActions.closeDialog()
          ])
        ),
        of(payload).pipe(
          filter(({ processGroup }) => processGroup === 'text_to_image'),
          mergeMap(({ urlParam }) => [
            push(
              `${route.TEXT_TO_IMAGE_PROJECTS.getUrl({ id: CREATE_NEW_PROJECT_ACTION })}${urlParam}`
            ),
            dialogActions.closeDialog()
          ])
        ),
        /* TODO */
        of(payload).pipe(
          filter(({ processGroup }) => processGroup === 'text_to_image'),
          mergeMap(({ urlParam }) => [
            push(
              `${route.TEXT_TO_IMAGE_PROJECTS.getUrl({ id: CREATE_NEW_PROJECT_ACTION })}${urlParam}`
            ),
            dialogActions.closeDialog()
          ])
        ),
        of(payload).pipe(
          filter(({ processGroup }) => processGroup === 'text_to_video'),
          mergeMap(({ urlParam }) => [
            push(
              `${route.TEXT_TO_VIDEO_PROJECTS.getUrl({ id: CREATE_NEW_PROJECT_ACTION })}${urlParam}`
            ),
            dialogActions.closeDialog()
          ])
        ),
        of(payload).pipe(
          filter(({ processGroup }) => processGroup === 'train'),
          mergeMap(payload =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.createResponse)),
              take(1),
              tap(({ payload }) => {
                MixPanelUtils.track<'PROJECT__CREATE'>(
                  'Project - Create',
                  DataUtils.getProjectParam<'training_project'>('training_project', {
                    trainProject: payload
                  })
                )
                FacebookPixelUtils.track<'CREATE_TRAIN'>('create_train')
              }),
              mergeMap(actions =>
                merge(
                  of(dialogActions.closeDialog()),
                  of(actions).pipe(
                    map(() => SessionStorage.get(LSKey.COLLECTION_ADDED_TO_NEW_PROJECT)),
                    filter(collectionAddedId => Boolean(collectionAddedId)),
                    tap(() => {
                      SessionStorage.remove(LSKey.COLLECTION_ADDED_TO_NEW_PROJECT)
                    }),
                    map(collectionAddedId => {
                      return {
                        param: {
                          id: _toNumber(actions.payload.inspiration),
                          collection: _toNumber(collectionAddedId),
                          exclude_images: [],
                          update_existing: true
                        }
                      }
                    }),
                    mergeMap(({ param }) =>
                      action$.pipe(
                        filter(isActionOf(apiActions.inputs.updateResponse)),
                        take(1),
                        map(() => apiActions.projects.updateThumbnail(actions.payload.id)),
                        startWith(apiActions.inputs.update(param))
                      )
                    )
                  )
                )
              ),
              startWith(
                apiActions.projects.create({
                  name: payload.name,
                  category: payload.process,
                  is_private: true
                })
              )
            )
          )
        )
      )
    )
  )

const shareProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.projectAction.share)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      project: apiSelectors.projects(state)[action.payload],
      ...action
    })),
    tap(({ project }) => {
      MixPanelUtils.track<'PROJECT__COPY_LINK'>(
        'Project - Copy Link',
        DataUtils.getProjectParam<'training_project'>('training_project', {
          trainProject: project
        })
      )
    }),
    map(({ payload }) => {
      const projectId = payload
      const baseUrl = window?.location?.origin
      const path = route.TRAIN_PROJECTS.getUrl({ id: projectId })
      return copy(`${baseUrl}${path}`)
    }),
    filter(result => result),
    mergeMap(() =>
      action$.pipe(
        filter(isActionOf(snackBarActions.show)),
        take(1),
        delay(1500),
        map(() => snackBarActions.close()),
        startWith(snackBarActions.show({ content: 'Copied project link to your clipboard!' }))
      )
    )
  )

const changeScopeEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.projectAction.changeScope)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.projects(state)[action.payload]),
    filter(project => Boolean(project)),
    filter(({ status }) => !ActiveProjectStatus.includes(status)),
    tap(project => {
      MixPanelUtils.track<'PROJECT__CHANGE_SCOPE'>(
        'Project - Change Scope',
        DataUtils.getProjectParam<'training_project'>('training_project', {
          trainProject: project
        })
      )
    }),
    map(({ is_private, id }) => apiActions.projects.update({ id, is_private: !is_private }))
  )

const deleteProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.projectAction.deleteProject)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.projects(state)[action.payload]),
    map(project =>
      dialogActions.openDialog({
        [ConfirmationDialog.DELETE_PROJECT]: {
          dialogName: ConfirmationDialog.DELETE_PROJECT,
          content: {
            projectName: project?.name ?? ''
          },
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.projects.delete(project.id ?? 0)]
          }
        }
      })
    )
  )
const deleteMixImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.mixProjectAction.deleteProject)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.mixImageProjects(state)[action.payload]),
    map(project =>
      dialogActions.openDialog({
        [ConfirmationDialog.DELETE_PROJECT]: {
          dialogName: ConfirmationDialog.DELETE_PROJECT,
          content: {
            projectName: project?.name ?? ''
          },
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.mixImage.deleteMixImage(project?.id ?? 0)]
          }
        }
      })
    )
  )
const deleteSketchProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.sketchProjectAction.deleteProject)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.sketchProjects(state)[action.payload]),
    map(project =>
      dialogActions.openDialog({
        [ConfirmationDialog.DELETE_PROJECT]: {
          dialogName: ConfirmationDialog.DELETE_PROJECT,
          content: {
            projectName: project?.name ?? ''
          },
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.sketchToImage.deleteSketchProject(project?.id ?? 0)]
          }
        }
      })
    )
  )
const deleteSketchTextProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.sketchTextProjectAction.deleteProject)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.sketchTextToImageProjects(state)[action.payload]),
    map(project =>
      dialogActions.openDialog({
        [ConfirmationDialog.DELETE_PROJECT]: {
          dialogName: ConfirmationDialog.DELETE_PROJECT,
          content: {
            projectName: project?.name ?? ''
          },
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.sketchTextToImage.deleteSTIProject(project?.id ?? 0)]
          }
        }
      })
    )
  )
const deleteProArtFilterProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.proArtFilterProjectAction.deleteProject)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.proArtFilterProjects(state)[action.payload]),
    map(project =>
      dialogActions.openDialog({
        [ConfirmationDialog.DELETE_PROJECT]: {
          dialogName: ConfirmationDialog.DELETE_PROJECT,
          content: {
            projectName: project?.name ?? ''
          },
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.proArtFilter.deleteProArtFilter(project?.id ?? 0)]
          }
        }
      })
    )
  )

const deleteTextToImageProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.textToImageProjectAction.deleteProject)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.textToImageProjects(state)[action.payload]),
    map(project =>
      dialogActions.openDialog({
        [ConfirmationDialog.DELETE_PROJECT]: {
          dialogName: ConfirmationDialog.DELETE_PROJECT,
          content: {
            projectName: project?.name ?? ''
          },
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.textToImage.deleteTIProject(project?.id ?? 0)]
          }
        }
      })
    )
  )

const deleteGenericAppProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(appActions.genericAppProjectAction.deleteProject)),
    withLatestFrom(state$),
    map(([action, state]) => apiSelectors.genericAppProjects(state)[action.payload]),
    map(project => {
      return dialogActions.openDialog({
        [ConfirmationDialog.DELETE_PROJECT]: {
          dialogName: ConfirmationDialog.DELETE_PROJECT,
          content: {
            projectName: project?.name ?? ''
          },
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.genericApp.deleteGenericAppProject(project?.id ?? 0)]
          }
        }
      })
    })
  )

const stopProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.projectAction.stopProject)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const projectId = action.payload
      const projects = apiSelectors.projects(state)
      return {
        selectedProject: projects[projectId]
      }
    }),
    filter(({ selectedProject }) => Boolean(selectedProject)),
    map(({ selectedProject }) => selectedProject),
    filter(({ status }) => StopableProjectStatus.includes(status)),
    map(({ id, name }) =>
      dialogActions.openDialog({
        [ConfirmationDialog.PAUSE]: {
          dialogName: ConfirmationDialog.PAUSE,
          content: {
            projectName: name,
            unusedCredit: null
          },
          yesAction: {
            label: '',
            actions: [appActions.projectAction.executeStopProject(id)]
          }
        }
      })
    )
  )

const executeStopProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.projectAction.executeStopProject)),
    mergeMap(({ payload }) =>
      action$.pipe(
        filter(isActionOf(sharedActions.setError)),
        take(1),
        mergeMap(({ payload }) =>
          merge(
            of(payload).pipe(
              map(() =>
                dialogActions.addDialog({
                  [ErrorDialog.ERROR]: {
                    dialogName: ErrorDialog.ERROR,
                    title: `Unable To Pause Project`,
                    content: errorUtils.flattenMessage(payload)
                  }
                })
              )
            ),
            of(payload).pipe(map(() => sharedActions.resetError(getType(apiActions.projects.stop))))
          )
        ),
        startWith(apiActions.projects.stop(payload))
      )
    )
  )

const generateEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.postActions.generate)),
    map(({ payload }) => push(route.TRAIN_PROJECTS.getUrl({ id: payload, subRoute: 'generate' })))
  )

const openProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.openProject)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const payload = action.payload
      const routerLocation = appSelectors.routerLocation(state)
      const pageSource = AppDuckUtils.getProjectSource(routerLocation)
      const currentPathname = routerLocation?.pathname
      const { id, object_type, target = '_blank' } = payload
      let url = ''
      switch (object_type) {
        case 'training_project':
        case 'training_mix_project':
          url = route.TRAIN_PROJECTS.getUrl({ id })
          break
        case 'pretrain_mix_project':
          url = route.MIX_PROJECTS.getUrl({ id })
          break
        case 'sketch_project':
          url = route.SKETCH_PROJECTS.getUrl({ id })
          break
        case 'transfer_project':
          url = route.PRO_FILTER_PROJECTS.getUrl({ id })
          break
        case 't2i_project':
          url = route.TEXT_TO_IMAGE_PROJECTS.getUrl({ id })
          break
        case 't2v_project':
          url = route.TEXT_TO_VIDEO_PROJECTS.getUrl({ id })
          break
        case 'ST2I_project':
          url = route.SKETCHTEXT_TO_IMAGE_PROJECTS.getUrl({ id })
          break
        case 'app_project':
          url = route.GENERIC_APP_PROJECTS.getUrl({ id })
          break
        default:
      }

      return {
        currentPathname,
        pageSource,
        url,
        target
      }
    }),
    concatMap(param =>
      concat(
        of(appActions.setOpenProjectSource(param.pageSource)),
        of(param).pipe(
          filter(({ target }) => target === '_blank'),
          tap(({ url }) => {
            window.open(url)
          }),
          ignoreElements()
        ),
        of(param).pipe(
          filter(({ target }) => target === '_self'),
          tap(({ currentPathname }) =>
            SessionStorage.save(LSKey.PROJECT_PAGE_SOURCE, currentPathname ?? '')
          ),
          map(({ url }) => push(url))
        )
      )
    )
  )

const clapEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.postActions.clap)),
    map(({ payload }) => apiActions.social.toggleClap(payload))
  )

const listenOnGenerate: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.projects.generateProjectMixResponse,
        apiActions.projects.generateRandomProjectMixResponse,
        apiActions.mixImage.generateMixImagePreviewResponse,
        apiActions.mixImage.generateMixImageResponse
      ])
    ),
    withLatestFrom(state$),
    map(([_, state]) => ({
      equity: apiSelectors.equity(state)
    })),
    filter(({ equity }) => equity?.type === 'free'),
    map(() => apiActions.users.retrieveEquity())
  )

const readActionParamEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(changeEmailActions.didMountApp)),
    withLatestFrom(state$),
    map(([_, state]) => ({ location: appSelectors.routerLocation(state) })),
    map(({ location }) => {
      const params = new URLSearchParams(location?.search)
      const dialog = params.get('dialog')
      const action = params.get('action')
      return { dialog, action }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ dialog }) => dialog === 'email-preferences'),
          map(() =>
            dialogActions.openDialog({
              [FormDialog.EMAIL_PREFERENCES]: { dialogName: FormDialog.EMAIL_PREFERENCES }
            })
          )
        ),
        of(param).pipe(
          filter(({ dialog }) => dialog === 'new-project'),
          map(() =>
            dialogActions.openDialog({
              [FormDialog.NEW_PROJECT]: { dialogName: FormDialog.NEW_PROJECT }
            })
          )
        ),
        of(param).pipe(
          filter(({ action }) => action === CLOSE_TAB_ACTION),
          tap(() => {
            window.addEventListener('load', () => {
              window.close()
            })
            setTimeout(window.close, 300)
          }),
          ignoreElements()
        )
      )
    )
  )

const connectToMetaMaskEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.metamask.connectToMetamask)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      hasMetamask: CryptoUtils.hasMetamask(),
      network: action.payload.network,
      artMineConfig: apiSelectors.artMineConfig(state)
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ hasMetamask }) => !hasMetamask),
          map(() =>
            dialogActions.addDialogOverlay({
              [ErrorDialog.ERROR]: {
                dialogName: ErrorDialog.ERROR,
                content: CryptoUtils.errorMessage(),
                title: 'No wallet detected'
              }
            })
          )
        ),
        of(param).pipe(
          filter(({ hasMetamask }) => hasMetamask),
          mergeMap(({ network }) =>
            action$.pipe(
              filter(isActionOf(apiActions.artMine.retrieveConfigResponse)),
              take(1),
              withLatestFrom(state$),
              map(([_, state]) => apiSelectors.artMineConfig(state)),
              mergeMap(mineConfig =>
                merge(
                  of(appActions.metamask.setConnectLoading(true)),
                  from(
                    CryptoUtils.init({
                      contract_abi: mineConfig?.contract_abi ?? {},
                      contract_address: mineConfig?.contract_address ?? '',
                      chain_id: mineConfig?.chain_id ?? 0,
                      polygon_chain_id: mineConfig?.polygon_chain_id ?? 0,
                      polygon_contract_abi: mineConfig?.polygon_contract_abi ?? {},
                      polygon_contract_address: mineConfig?.polygon_contract_address ?? '',
                      network
                    })
                  ).pipe(
                    map(param => ({
                      address: param.accounts?.[0] ?? '',
                      chain_id: param.chainId,
                      network: param.network,
                      accounts: param.accounts
                    })),
                    tap(({ address, chain_id, network }) => {
                      network &&
                        MixPanelUtils.track<'NFT__CONNECT_TO_METAMASK'>(
                          'NFT - Connect To Metamask',
                          {
                            network
                          }
                        )
                    }),
                    mergeMap(({ accounts }) => [
                      apiActions.artMine.setAccount(accounts),
                      appActions.metamask.setConnectLoading(false)
                    ])
                  )
                )
              ),
              catchError(err =>
                of(err).pipe(
                  mergeMap((err: Error) => [
                    appActions.metamask.setConnectLoading(false),
                    dialogActions.addDialogOverlay({
                      [ErrorDialog.ERROR]: {
                        dialogName: ErrorDialog.ERROR,
                        content: err.message,
                        title: 'Failed To Connect'
                      }
                    })
                  ])
                )
              ),
              startWith(apiActions.artMine.retrieveConfig())
            )
          )
        )
      )
    )
  )

const disconnectFromMetamaskEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(appActions.metamask.disconnect)),
    tap(() => CryptoUtils.disconnect()),
    map(() => apiActions.artMine.setAccount(undefined))
  )

export const epics = combineEpics(
  submitTextToImageAgreementEpic,
  deleteGenericAppProjectEpic,
  deleteTextToImageProjectEpic,
  deleteSketchTextProjectEpic,
  listenOnCreateUpdateNotificationPreferencesEpic,
  disconnectFromMetamaskEpic,
  connectToMetaMaskEpic,
  readActionParamEpic,
  errorHandlerEpics,
  listenOnGenerate,
  clickBackToTrainingDetailEpic,
  logClickAddCollectionOptionsEpic,
  logOnClickAddCollectionEpic,
  logDownloadEpic,
  openShareEpic,
  pushToEpic,
  openUpscaleImageEpic,
  openImageEnhancementEpic,
  openUpgradePageEpic,
  deleteSketchProjectEpic,
  deleteProArtFilterProjectEpic,
  useCollectionInNewProjectEpic,
  duplicateProjectEpic,
  shareProjectEpic,
  changeScopeEpic,
  deleteProjectEpic,
  deleteMixImageEpic,
  changeEmailEpics,
  stopProjectEpic,
  sendQuestionEpic,
  saveNotificationPreferencesEpic,
  executeStopProjectEpic,
  createProjectEpic,
  listEngineConfigEpic,
  generateEpic,
  openProjectEpic,
  clapEpic,
  downloaderEpics,
  phoneEpics,
  bannerEpics
)

export type AppPageType = {
  downloader: DownloaderState
  app: AppState
  dialog: DialogState
  snackBar: SnackbarState
  banner: BannerState
  changeEmail: ChangeEmailState
  phone: PhoneState
}

const appReducers = {
  app: reducer,
  dialog: dialogReducer,
  snackBar: snackBarReducer,
  banner: bannerReducer,
  phone: phoneReducer,
  downloader: downloaderReducer,
  changeEmail: changeEmailReducer
}

export default combineReducers<AppPageType>({ ...appReducers })
