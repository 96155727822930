import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  PayPalScriptProvider,
  BraintreePayPalButtons,
  usePayPalScriptReducer,
  BraintreePayPalButtonsComponentProps
} from '@paypal/react-paypal-js'
import { Dispatch, bindActionCreators } from 'redux'
import { apiSelectors } from 'duck/ApiDuck'
import { RootActionType, RootState } from 'duck'
import { ReduxProps } from 'utils/Types'
import { CircularLoading } from 'components/Loading'
import { values } from 'appConstants'
import { addCreditActions, brainTreePaymentActions } from '../duck'

const mapStateToProps = (state: RootState) => ({
  brainTreeToken: apiSelectors.brainTreeToken(state),
  channelData: apiSelectors.channelData(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      initBrainTree: brainTreePaymentActions.initBrainTree,
      getInitialMonetizationData: addCreditActions.getInitialMonetizationData
    },
    dispatch
  )
const STYLE_PAYPALBUTTONWRAPPER = { height: 48 }
const INITIAL_STYLE: BraintreePayPalButtonsComponentProps['style'] = {
  label: 'pay',
  layout: 'vertical',
  height: 48
}

export type PaypalButtonProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  style?: BraintreePayPalButtonsComponentProps['style']
  onNonceAvailable?: (nonce: string) => void
}

const PaypalButton: React.FC<PaypalButtonProps> = ({ style = INITIAL_STYLE, onNonceAvailable }) => {
  const [{ isPending }] = usePayPalScriptReducer()

  const styleCombined = useMemo(() => {
    if (!style) return INITIAL_STYLE
    return { ...INITIAL_STYLE, ...style }
  }, [style])

  return (
    <>
      {isPending ? (
        <div className="absolute left-0 top-0 z-101 flex h-full w-full items-center justify-center bg-dialog">
          <CircularLoading size={24} loading disableShrink />
        </div>
      ) : null}

      <div className="bg-secondary">
        <BraintreePayPalButtons
          style={styleCombined}
          disabled={false}
          fundingSource="paypal"
          forceReRender={[styleCombined]}
          createBillingAgreement={(data, actions) => {
            return actions.braintree.createPayment({
              flow: 'vault'
            })
          }}
          onApprove={(data, actions) => {
            return actions.braintree.tokenizePayment(data).then(payload => {
              onNonceAvailable?.(payload.nonce)
            })
          }}
        />
      </div>
    </>
  )
}

const PaypalButtonWrapper: React.FC<PaypalButtonProps> = props => {
  const { brainTreeToken, channelData, initBrainTree, getInitialMonetizationData } = props
  const client_token = brainTreeToken?.client_token
  const clientId = channelData?.[values.PAYPAL_CHANNEL_NAME]?.details.client_id

  useEffect(() => {
    initBrainTree()
    getInitialMonetizationData()
  }, [getInitialMonetizationData, initBrainTree])

  return (
    <div style={STYLE_PAYPALBUTTONWRAPPER}>
      {client_token && clientId ? (
        <div className="absolute h-full bg-dialog text-0 leading-none">
          <PayPalScriptProvider
            options={{
              'client-id': clientId,
              components: 'buttons',
              'data-client-token': client_token,
              intent: 'tokenize',
              vault: true
            }}
          >
            <PaypalButton {...props} />
          </PayPalScriptProvider>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <CircularLoading size={24} loading disableShrink />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalButtonWrapper)
