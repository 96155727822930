import { useEffect, useMemo, useState } from 'react'
import {
  values,
  BREAKPOINTS_SMALLSCREEN_UP,
  BREAKPOINTS_MEDIUMSCREEN_UP,
  BREAKPOINTS_LARGESCREEN_UP
} from 'appConstants'
import { ApiUtils } from 'utils'
import { AppPageProps } from '.'
import ReactGA from 'react-ga'
import MixPanelUtils from 'utils/MixPanelUtils'
import TwitterConvTrkr from 'react-twitter-conversion-tracker'
import {
  useBreakpoint,
  useInExploreSubPage,
  useInMixProjectsPage,
  useInSavedResultPage,
  useInSketchPage,
  useInTrainProjectPage,
  useIsInSignInPage,
  useWidth,
  useWindowDimensionDetector,
  useInTextToImagePage,
  useInGenericAppProjectPage
} from 'utils/Hooks'
import { Location } from 'history'
import { getFirebaseAuth } from 'utils/FirebaseUtils'
import { MonetizationDialog } from 'duck/AppDuck/DialogDuck'

type UseInitializeAuthParam = Pick<
  AppPageProps,
  'startTokenInterval' | 'setIdToken' | 'retrieveApiUser' | 'setFirebaseUser' | 'setAuthenticating'
>

export const useInitializeAuth = (param: UseInitializeAuthParam) => {
  const { startTokenInterval, setAuthenticating, setIdToken, retrieveApiUser, setFirebaseUser } =
    param

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_ID || '')
    startTokenInterval()

    getFirebaseAuth().onAuthStateChanged(async firebaseUser => {
      if (firebaseUser !== null) {
        setFirebaseUser(firebaseUser)
        const idToken = await firebaseUser.getIdToken()

        setIdToken(idToken)
        retrieveApiUser({ uid: firebaseUser.uid, isFirstTime: true })
        ReactGA.set({
          userId: firebaseUser.uid,
          userEmail: firebaseUser.email
        })
      } else {
        setFirebaseUser(firebaseUser)
        retrieveApiUser({ uid: '' })
        ApiUtils.removeAuthCookie()
      }

      setAuthenticating(false)
    })
  }, [startTokenInterval, retrieveApiUser, setAuthenticating, setFirebaseUser, setIdToken])
}

type UsePageViewTracker = {
  location: Location | undefined | null
}

export const usePageViewTracker = (param: UsePageViewTracker) => {
  const { location } = param

  const pathname = location?.pathname ?? ''
  const search = location?.search ?? ''
  const pathnameWithSearch = `${pathname}/${search}`

  useEffect(() => {
    ReactGA.pageview(pathnameWithSearch)
    MixPanelUtils.track<'USER__PAGE_VIEW'>('User - Page View')
    if (values.REACT_APP_TWITTER_CONVERSION_ID) {
      TwitterConvTrkr.pageView()
    }
  }, [pathnameWithSearch])
}

export const useBannerMode = () => {
  const isInSignInPage = useIsInSignInPage()
  const inMixProjectsPage = useInMixProjectsPage()
  const inTrainProjectPage = useInTrainProjectPage()
  const inSketchPage = useInSketchPage()
  const inExplorePage = useInExploreSubPage()
  const inSavedResultPage = useInSavedResultPage()
  const inTextToImagePage = useInTextToImagePage()
  const inGenericAppProjectPage = useInGenericAppProjectPage()

  const shouldHidden =
    inMixProjectsPage ||
    inTrainProjectPage ||
    inSavedResultPage ||
    inSketchPage ||
    inExplorePage ||
    inTextToImagePage ||
    inGenericAppProjectPage

  const bannerMode = isInSignInPage ? 'float' : shouldHidden ? 'hidden' : undefined

  return bannerMode
}

/* Set User Device Information */

export const useUserDeviceDetector = (
  userDeviceInfo: AppPageProps['userDeviceInfo'],
  setUserDevice: AppPageProps['setUserDevice']
) => {
  const { breakpoint } = useBreakpoint()

  const _ua = userDeviceInfo?.['_ua']
  const isStandalone = userDeviceInfo?.summary?.isStandalone

  useEffect(() => {
    setUserDevice(navigator.userAgent.toLowerCase())
  }, [setUserDevice])

  useEffect(() => {
    if (isStandalone) document.body.classList.add('!select-none')
  }, [isStandalone])

  useEffect(() => {
    if (breakpoint) {
      const classNameResponsive = `Body${breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1)}`

      // Add new responsive class
      document.body.classList.add(classNameResponsive)

      return () => {
        document.body.classList.remove(classNameResponsive)
      }
    }
  }, [breakpoint])

  useEffect(() => {
    const userAgentEngineName = userDeviceInfo?.parsedResult?.engine.name
    const isMobile = userDeviceInfo?.summary?.isMobile

    if (
      isMobile ||
      !userAgentEngineName ||
      (userAgentEngineName.toLowerCase() !== 'blink' &&
        userAgentEngineName.toLowerCase() !== 'webkit')
    ) {
      if (document.body.classList.contains('useCustomWebkitScrollbar'))
        document.body.classList.remove('useCustomWebkitScrollbar')
    } else if (!document.body.classList.contains('useCustomWebkitScrollbar'))
      document.body.classList.add('useCustomWebkitScrollbar')
  }, [_ua, userDeviceInfo])
}
/* useUserDeviceDetector END */

export const useBreakpointInit = () => {
  const breakpoint = useWidth() || ''

  const breakpointData = useMemo(() => {
    const isSmallscreenAbove = BREAKPOINTS_SMALLSCREEN_UP.includes(breakpoint)
    const isMediumscreenAbove = BREAKPOINTS_MEDIUMSCREEN_UP.includes(breakpoint)
    const isLargescreenAbove = BREAKPOINTS_LARGESCREEN_UP.includes(breakpoint)

    const isSmallscreenBelow = !isSmallscreenAbove
    const isMediumscreenBelow = !isMediumscreenAbove
    const isLargescreenBelow = !isLargescreenAbove

    return {
      breakpoint,
      isSmallscreenAbove,
      isMediumscreenAbove,
      isLargescreenAbove,
      isSmallscreenBelow,
      isMediumscreenBelow,
      isLargescreenBelow
    }
  }, [breakpoint])

  return breakpointData
}

/* useWindowDimensionInit */
export const useWindowDimensionInit = () => {
  const windowDimension = useWindowDimensionDetector()

  return windowDimension
}
/* useWindowDimensionInit END */

type UseSingleDownloadLoadingParam = Pick<
  AppPageProps,
  'closeSnackbar' | 'showSnackbar' | 'downloadProgressSingleText'
>

const CLOSE_DELAY = 2000

export const useSingleDownloadLoading = (param: UseSingleDownloadLoadingParam) => {
  const { closeSnackbar, showSnackbar, downloadProgressSingleText } = param

  const downloadProgress = downloadProgressSingleText?.downloadProgress
  const text = downloadProgressSingleText?.text

  useEffect(() => {
    if (text) {
      showSnackbar({
        content: text,
        actionText: Boolean(downloadProgress) ? `${Math.ceil(downloadProgress ?? 0)} %` : undefined
      })
    }
  }, [showSnackbar, text, downloadProgress])

  useEffect(() => {
    if (!text) {
      window.setTimeout(() => {
        closeSnackbar()
      }, CLOSE_DELAY)
    }
  }, [closeSnackbar, text])

  useEffect(() => {
    return function () {
      closeSnackbar()
    }
  }, [closeSnackbar])
}

export type UseShouldShowContinueSubscriptionDialogType = Pick<
  AppPageProps,
  | 'equity'
  | 'brainTreePaymentMethod'
  | 'addDialog'
  | 'currentSubscription'
  | 'setUserHasSubscriptionNoPaymentMethod'
  | 'isAuthorizationComplete'
  | 'braintreeRetrievePaymentMethod'
  | 'listSubscription'
  | 'hasBraintreePaymentMethod'
>

export const useShouldShowContinueSubscriptionDialog = (
  param: UseShouldShowContinueSubscriptionDialogType
) => {
  const {
    braintreeRetrievePaymentMethod,
    listSubscription,
    addDialog,
    setUserHasSubscriptionNoPaymentMethod,
    hasBraintreePaymentMethod,
    equity,
    isAuthorizationComplete,
    currentSubscription,
    brainTreePaymentMethod
  } = param

  const [isOpened, setIsOpened] = useState<boolean>(false)

  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false)

  useEffect(() => {
    if (isAuthorizationComplete) {
      braintreeRetrievePaymentMethod()
      listSubscription()
    }
  }, [listSubscription, braintreeRetrievePaymentMethod, isAuthorizationComplete])

  const dataComplete =
    Boolean(brainTreePaymentMethod) && Boolean(currentSubscription) && Boolean(equity)

  const isNotFree = equity?.type && equity?.type !== 'free'

  const haveNoPaymentMethod = !hasBraintreePaymentMethod

  const isCanceled = currentSubscription?.is_canceled

  useEffect(() => {
    if (dataComplete && !isOpened && isNotFree && haveNoPaymentMethod && !isCanceled) {
      setTimeout(() => {
        setShouldShowDialog(true)
      }, 5000)
    }
  }, [dataComplete, haveNoPaymentMethod, isCanceled, isNotFree, isOpened])

  useEffect(() => {
    if (shouldShowDialog) {
      setIsOpened(true)
      setUserHasSubscriptionNoPaymentMethod(true)
      addDialog({
        [MonetizationDialog.CONTINUE_SUBSCRIPTION]: {
          dialogName: MonetizationDialog.CONTINUE_SUBSCRIPTION
        }
      })
    }
  }, [addDialog, setUserHasSubscriptionNoPaymentMethod, shouldShowDialog])
}

export type UseShouldShowInactiveSubscriptionDialogType = Pick<
  AppPageProps,
  'addDialog' | 'currentSubscription'
>

export const useShouldShowInactiveSubscriptionDialog = (
  param: UseShouldShowInactiveSubscriptionDialogType
) => {
  const { addDialog, currentSubscription } = param

  const isInactive = currentSubscription?.is_inactive

  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false)

  useEffect(() => {
    if (isInactive && !isOpened) {
      setTimeout(() => {
        setShouldShowDialog(true)
      }, 5000)
    }
  }, [isInactive, isOpened])

  useEffect(() => {
    if (shouldShowDialog) {
      setIsOpened(true)
      addDialog({
        [MonetizationDialog.CONTINUE_INACTIVE_SUBSCRIPTION]: {
          dialogName: MonetizationDialog.CONTINUE_INACTIVE_SUBSCRIPTION
        }
      })
    }
  }, [addDialog, shouldShowDialog])
}
