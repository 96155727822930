import { TextTransform } from 'utils/TextUtils'
import { createAction } from 'typesafe-actions'
import { CollectionOrderingType, ImageSetType } from 'models/ApiModels'
import { BottomSheetBarTabs } from './reducers'
import { CollectionSourceType } from 'containers/CollectionEditorPanel/duck'

// Constants
const NAMESPACE = '@@page/CollectionSelectorPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

//Type

export type ExpandSheetBarParam = {
  expanded: boolean
  selectedImageSet?: ImageSetType
}
// Actions
export const actions = {
  loadCategories: createAction(creator('LOAD_CATEGORIES'))(),
  setHideCollFooter: createAction(creator('SET_HIDE_COLL_FOOTER'))<boolean>(),
  setOpenUploadWindow: createAction(creator('SET_OPEN_UPLOAD_WINDOW'))<boolean>(),
  expandSheetBar: createAction(creator('EXPAND_SHEET_BAR'))<ExpandSheetBarParam>(), // Force hide halfsheet after clicked
  setSelectedImageSet: createAction(creator('SET_SELECTED_IMAGESET'))<ImageSetType>(),
  setCurrentTab: createAction(creator('SET_CURRENT_TAB'))<BottomSheetBarTabs>(),
  addCollection: createAction(creator('ADD_COLLECTION'))<{
    source?: CollectionSourceType
    collectionId: number
  }>(),
  setOpenedCollection: createAction(creator('SET_OPENED_COLLECTION'))<number | undefined>(),
  openSingleCollection: createAction(creator('OPEN_SINGLE_COLLECTION'))<{
    collectionId: number
    tab: BottomSheetBarTabs
  }>(),
  setSearchLoading: createAction(creator('SET_SEARCH_LOADING'))<boolean>(),
  bookmarkCollection: {
    setSortBy: createAction(creator('BOOKMARK_COLLECTION/SET_SORT_BY'))<CollectionOrderingType>(),
    loadMoreBookmarkCollection: createAction(
      creator('BOOKMARK_COLLECTION/LOAD_MORE_BOOKMARK_COLLECTION')
    )(),
    reloadCollection: createAction(creator('BOOKMARK_COLLECTION/RELOAD_COLLECTION'))(),
    setSearchKeyword: createAction(creator('BOOKMARK_COLLECTION/SET_SEARCH_KEYWORD'))<string>()
  },
  publicCollection: {
    setSelectedCategory: createAction(creator('PUBLIC_COLLECTION/SET_SELECTED_CATEGORY'))<string>(),
    setSortBy: createAction(creator('PUBLIC_COLLECTION/SET_SORT_BY'))<CollectionOrderingType>(),
    setSearchKeyword: createAction(creator('PUBLIC_COLLECTION/SET_SEARCH_KEYWORD'))<string>(),
    reloadCollection: createAction(creator('PUBLIC_COLLECTION/RELOAD_COLLECTION'))()
  },
  myCollection: {
    setSelectedCategory: createAction(creator('MY_COLLECTION/SET_SELECTED_CATEGORY'))<string>(),
    setSortBy: createAction(creator('MY_COLLECTION/SET_SORT_BY'))<CollectionOrderingType>(),
    loadMoreMyCollection: createAction(creator('MY_COLLECTION/LOAD_MORE_MY_COLLECTION'))(),
    reloadCollection: createAction(creator('MY_COLLECTION/RELOAD_COLLECTION'))(),
    setSearchKeyword: createAction(creator('MY_COLLECTION/SET_SEARCH_KEYWORD'))<string>()
  },
  emptyAction: createAction(creator('EMPTY_ACTION'))()
}
