import { getType, isActionOf } from 'typesafe-actions'
import { RootActionType, RootState } from 'duck'
import { Epic, combineEpics } from 'redux-observable'
import { withLatestFrom, filter, map, mergeMap, tap } from 'rxjs/operators'
import { firebaseActions } from 'duck/FirebaseDuck'
import { apiActions, ErrorBundleType, RequestType, sharedActions } from 'duck/ApiDuck'
import { appActions, appSelectors } from '.'
import { merge, of } from 'rxjs'
import _find from 'lodash/find'
import { values } from 'appConstants'
import {
  PRO_FILTER_PROJECTS,
  MAIN_PAGE,
  MIX_PROJECTS,
  SKETCH_PROJECTS,
  TRAIN_PROJECTS,
  TEXT_TO_IMAGE_PROJECTS,
  TEXT_TO_VIDEO_PROJECTS
} from 'routes'
import { ContentMode, CONTENT_MODE, errorUtils } from 'utils/DataProcessingUtils'
import { dialogActions, ErrorDialog } from './DialogDuck'
import { bannerActions } from './BannerDuck'
import SentryUtils from 'utils/SentryUtils'

/* Error 400 series that the error can be generalized */

export const ERROR_4XX_ITEMS = [
  403, 402, 405, 406, 407, 408, 409, 410, 411, 414, 415, 417, 418, 421, 423, 425, 426, 428, 429
]

export const PROJECT_NOT_FOUND = [404, 403]

export const UNHANDLED_ERROR_CODES = [400, 404, 413, 412]

const Utils = {
  getErrorStringDetail: (error: ErrorBundleType) => {
    const errorDetail = error?.error?.data.detail
    return errorDetail
  },
  getServerErrorAction: () => [
    {
      label: 'DISMISS',
      onClick: bannerActions.close()
    }
  ],
  getTypeToDialogTitleMap: () => {
    const TypeToDialogTitleMap: {
      [key in RequestType]?: { title: string; contentMode: ContentMode }
    } = {
      [getType(apiActions.users.update)]: {
        title: 'Unable To Update Profile',
        contentMode: CONTENT_MODE.LIST
      },
      [getType(apiActions.users.updateAlias)]: {
        title: 'Unable To Update Playform URL',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.users.uploadUserPicture)]: {
        title: 'Unable Upload Picture',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.inputs.update)]: {
        title: 'Unable To Add Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.mixImage.createUploadImage)]: {
        title: 'Unable To Upload Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.projects.start)]: {
        title: 'Unable To Begin Training',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.projects.more)]: {
        title: 'Unable To Continue Training',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.projects.startInference)]: {
        title: 'Unable To Generate Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.sketchToImage.generateSketchProject)]: {
        title: 'Unable To Generate Sketch Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.proArtFilter.generateProArtFilter)]: {
        title: 'Unable To Generate Pro-Art Filter Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.mixImage.generateMixImage)]: {
        title: 'Unable To Generate Mix Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.textToImage.generateTIProject)]: {
        title: 'Unable To Generate Text To Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.projects.generateProjectMix)]: {
        title: 'Unable To Generate Mix Images',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.engine.generateClipPreview)]: {
        title: 'Unable To Generate Clip Video',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.imageEnhancement.createUpscaleImage)]: {
        title: 'Unable To Upscale Image',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.payment.cancelSubscription)]: {
        title: 'Unable To Cancel Subscription',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.payment.createSubscription)]: {
        title: 'Unable To Create Subscription',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.payment.updateSubscription)]: {
        title: 'Unable To Change Subscription',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.payment.createPayment)]: {
        title: 'Unable To Create Payment',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.payment.braintreeRemovePaymentMethod)]: {
        title: 'Unable To Remove Payment Method',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.payment.braintreeCreatePaymentMethod)]: {
        title: 'Unable To Create Payment Method',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.projects.delete)]: {
        title: 'Unable To Delete Project',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.mixImage.deleteMixImage)]: {
        title: 'Unable To Delete Mix Project',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.sketchToImage.deleteSketchProject)]: {
        title: 'Unable To Delete Sketch Project',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.proArtFilter.deleteProArtFilter)]: {
        title: 'Unable To Delete Stylize Filter Project',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.collections.delete)]: {
        title: 'Unable To Delete Collection',
        contentMode: CONTENT_MODE.FLAT
      },
      [getType(apiActions.artMine.createArtMineProject)]: {
        title: 'Unable To Create Art Mine Project',
        contentMode: CONTENT_MODE.LIST
      },
      [getType(apiActions.artMine.deleteArtMineProject)]: {
        title: 'Unable To Delete Art Mine Project',
        contentMode: CONTENT_MODE.LIST
      },
      [getType(apiActions.artMine.retrieveSignature)]: {
        title: 'Unable To Submit Art Mine Project',
        contentMode: CONTENT_MODE.LIST
      }
    }
    return TypeToDialogTitleMap
  },
  getTypeToBannerContentMap: () => {
    const TypeToBannerContentMap: { [key in RequestType]?: string } = {
      [getType(apiActions.projects.create)]: 'Failed to create a new project: Detail : ',
      [getType(apiActions.mixImage.createMixImage)]:
        'Failed to create a new mix project: Detail : ',
      [getType(apiActions.proArtFilter.createProArtFilter)]:
        'Failed to create a new Stylize filter project: Detail : ',
      [getType(apiActions.sketchToImage.createSketchProject)]:
        'Failed to create new sketch project: Detail : ',
      [getType(apiActions.textToImage.createTIProject)]:
        'Failed to create Text To Art project: Detail : ',
      [getType(apiActions.textToVideo.createTVProject)]:
        'Failed to create Text To Video project: Detail : '
    }
    return TypeToBannerContentMap
  },
  isErrorAlreadyHandled: (type: RequestType) => {
    const dialog = Object.keys(Utils.getTypeToDialogTitleMap())
    const banner = Object.keys(Utils.getTypeToBannerContentMap())

    return Boolean(_find([...dialog, ...banner], value => value === type))
  },
  isInProjectPage: (pathName: string) => {
    const inProjectPage = pathName.includes(`${TRAIN_PROJECTS}/`)
    const inMixProjectPage = pathName.includes(`${MIX_PROJECTS}/`)
    const inSketchProjectPage = pathName.includes(`${SKETCH_PROJECTS}/`)
    const inProFilterProjectPage = pathName.includes(`${PRO_FILTER_PROJECTS}/`)

    return inProjectPage || inMixProjectPage || inSketchProjectPage || inProFilterProjectPage
  },
  isErrorHandledByProjectError: (pathName: string, type: RequestType, errorCode: number) => {
    const PROJECT_RETRIEVE_TYPE = [
      getType(apiActions.projects.retrieve),
      getType(apiActions.sketchToImage.retrieveSketchProject),
      getType(apiActions.projects.retrieve),
      getType(apiActions.projects.retrieve)
    ] as RequestType[]
    const inProjectPage = Utils.isInProjectPage(pathName)
    const isErrorCodeMatch = PROJECT_NOT_FOUND.includes(errorCode)
    const isProjectRetrieveType = PROJECT_RETRIEVE_TYPE.includes(type)

    return inProjectPage && isErrorCodeMatch && isProjectRetrieveType
  }
}

/* 
  Use dialog for error that caused by user action (click button et)
  Use banner for 500 error from backend. 
*/

const listenRetrieveProjectErrorEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      error: action.payload,
      location: appSelectors.routerLocation(state),
      errorCode: errorUtils.getCode(action.payload) ?? 0
    })),
    mergeMap(payload =>
      merge(
        of(payload).pipe(
          filter(({ error }) => error.type === getType(apiActions.projects.retrieve)),
          filter(({ errorCode }) => PROJECT_NOT_FOUND.includes(errorCode)),
          filter(({ location }) => Boolean(location?.pathname.includes(`${TRAIN_PROJECTS}/`))),
          mergeMap(() => [
            dialogActions.addDialog({
              [ErrorDialog.PROJECT_NOT_FOUND]: { dialogName: ErrorDialog.PROJECT_NOT_FOUND }
            }),
            appActions.pushTo(MAIN_PAGE)
          ])
        ),
        of(payload).pipe(
          filter(
            ({ error }) => error.type === getType(apiActions.sketchToImage.retrieveSketchProject)
          ),
          filter(({ errorCode }) => PROJECT_NOT_FOUND.includes(errorCode)),
          filter(({ location }) => Boolean(location?.pathname.includes(`${SKETCH_PROJECTS}/`))),
          mergeMap(() => [
            dialogActions.addDialog({
              [ErrorDialog.PROJECT_NOT_FOUND]: { dialogName: ErrorDialog.PROJECT_NOT_FOUND }
            }),
            appActions.pushTo(MAIN_PAGE)
          ])
        ),
        of(payload).pipe(
          filter(({ error }) => error.type === getType(apiActions.mixImage.retrieveMixImage)),
          filter(({ errorCode }) => PROJECT_NOT_FOUND.includes(errorCode)),
          filter(({ location }) => Boolean(location?.pathname.includes(`${MIX_PROJECTS}/`))),
          mergeMap(() => [
            dialogActions.addDialog({
              [ErrorDialog.PROJECT_NOT_FOUND]: { dialogName: ErrorDialog.PROJECT_NOT_FOUND }
            }),
            appActions.pushTo(MAIN_PAGE)
          ])
        ),
        of(payload).pipe(
          filter(
            ({ error }) => error.type === getType(apiActions.proArtFilter.retrieveProArtFilter)
          ),
          filter(({ errorCode }) => PROJECT_NOT_FOUND.includes(errorCode)),
          filter(({ location }) => Boolean(location?.pathname.includes(`${PRO_FILTER_PROJECTS}/`))),
          mergeMap(() => [
            dialogActions.addDialog({
              [ErrorDialog.PROJECT_NOT_FOUND]: { dialogName: ErrorDialog.PROJECT_NOT_FOUND }
            }),
            appActions.pushTo(MAIN_PAGE)
          ])
        ),
        of(payload).pipe(
          filter(({ error }) => error.type === getType(apiActions.textToImage.retrieveTIProject)),
          filter(({ errorCode }) => PROJECT_NOT_FOUND.includes(errorCode)),
          filter(({ location }) =>
            Boolean(location?.pathname.includes(`${TEXT_TO_IMAGE_PROJECTS}/`))
          ),
          mergeMap(() => [
            dialogActions.addDialog({
              [ErrorDialog.PROJECT_NOT_FOUND]: { dialogName: ErrorDialog.PROJECT_NOT_FOUND }
            }),
            appActions.pushTo(MAIN_PAGE)
          ])
        ),
        of(payload).pipe(
          filter(({ error }) => error.type === getType(apiActions.textToVideo.retrieveTVProject)),
          filter(({ errorCode }) => PROJECT_NOT_FOUND.includes(errorCode)),
          filter(({ location }) =>
            Boolean(location?.pathname.includes(`${TEXT_TO_VIDEO_PROJECTS}/`))
          ),
          mergeMap(() => [
            dialogActions.addDialog({
              [ErrorDialog.PROJECT_NOT_FOUND]: { dialogName: ErrorDialog.PROJECT_NOT_FOUND }
            }),
            appActions.pushTo(MAIN_PAGE)
          ])
        )
      )
    )
  )

export const listenOnErrorWithDialogMessageEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    map(({ payload }) => {
      return { payload, dialogTitleMap: Utils.getTypeToDialogTitleMap()[payload.type] }
    }),
    filter(({ dialogTitleMap }) => Boolean(dialogTitleMap)),
    tap(({ dialogTitleMap, payload }) => {
      SentryUtils.captureMessage(
        dialogTitleMap?.title ?? '',
        {
          message: errorUtils.flattenMessage(payload, dialogTitleMap?.contentMode)
        },
        'error'
      )
    }),
    map(({ dialogTitleMap, payload }) =>
      dialogActions.addDialogOverlay({
        [ErrorDialog.ERROR]: {
          dialogName: ErrorDialog.ERROR,
          title: dialogTitleMap?.title,
          content: errorUtils.flattenMessage(payload, dialogTitleMap?.contentMode)
        }
      })
    )
  )

//Error for banner
const listenOn401ErrorEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    map(action => {
      const error = action.payload
      const type = action.payload.type
      const errorString = Utils.getErrorStringDetail(error)

      const isErrorAlreadyHandled = Utils.isErrorAlreadyHandled(type)
      const shouldDisplayBanner = errorUtils.getCode(error) === 401 && !isErrorAlreadyHandled
      return { shouldDisplayBanner, errorString }
    }),
    filter(({ shouldDisplayBanner }) => shouldDisplayBanner),
    mergeMap(({ errorString }) => [
      bannerActions.show({
        message: `There are a problem with your credential. Detail : ${errorString} Please try to reload this page, if the error still persists, try to logout and login again.`,
        autoCloseDuration: 10000,
        actions: Utils.getServerErrorAction()
      }),
      firebaseActions.refreshToken()
    ])
  )

const listenOn4xxErrorEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const location = appSelectors.routerLocation(state)

      const type = action.payload.type
      const error = action.payload
      const pathName = location?.pathname ?? ''
      const errorCode = errorUtils.getCode(error) ?? 0

      const isError4xx = ERROR_4XX_ITEMS.includes(errorCode) //All 402 and up, except error 413
      const isErrorHandledByProjectError = Utils.isErrorHandledByProjectError(
        pathName,
        type,
        errorCode
      )
      const isErrorAlreadyHandled = Utils.isErrorAlreadyHandled(type)
      const shouldDisplayBanner =
        !isErrorHandledByProjectError && isError4xx && !isErrorAlreadyHandled

      const errorString = Utils.getErrorStringDetail(error)
      const generalMessage = `There are a problem with some request. Please try again in few minutes, or contact us at ${values.PLAYFORM_CONTACT_EMAIL}. Error Code : ${errorCode}`

      return { shouldDisplayBanner, errorString: errorString || generalMessage }
    }),
    filter(({ shouldDisplayBanner }) => shouldDisplayBanner),
    map(({ errorString }) =>
      bannerActions.show({
        message: errorString,
        autoCloseDuration: 10000,
        actions: Utils.getServerErrorAction()
      })
    )
  )

const listenOn500ErrorEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    map(action => {
      const error = action.payload
      const errorCode = errorUtils.getCode(error) ?? 0
      const isErrorAlreadyHandled = Utils.isErrorAlreadyHandled(action.payload.type)
      const shouldDisplayBanner = errorCode >= 500 && !isErrorAlreadyHandled
      return { shouldDisplayBanner, errorCode }
    }),
    filter(({ shouldDisplayBanner }) => shouldDisplayBanner),
    map(({ errorCode }) =>
      bannerActions.show({
        message: `We can't complete the request because something wrong in the server. Please try again in few minutes, or contact us at ${values.PLAYFORM_CONTACT_EMAIL}. Error Code: ${errorCode} `,
        autoCloseDuration: 10000,
        actions: Utils.getServerErrorAction()
      })
    )
  )

const listenOnErrorWithCustomBannerMessageEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    map(({ payload }) => {
      return { payload, title: Utils.getTypeToBannerContentMap()[payload.type] }
    }),
    filter(({ title }) => Boolean(title)),
    map(({ payload, title }) =>
      bannerActions.show({
        message: `${title} ${errorUtils.flattenMessage(payload)}`,
        autoCloseDuration: 10000,
        actions: Utils.getServerErrorAction()
      })
    )
  )

export const errorHandlerEpics = combineEpics(
  listenOnErrorWithDialogMessageEpic,
  listenOnErrorWithCustomBannerMessageEpic,
  listenRetrieveProjectErrorEpic,
  listenOn401ErrorEpic,
  listenOn4xxErrorEpic,
  listenOn500ErrorEpic
)
