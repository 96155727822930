import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const RoundRoundIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon className={clsx(className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <circle cx="15" cy="15" r="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3178 5C12.3675 4.36815 11.2267 4 10 4C6.68629 4 4 6.68629 4 10C4 11.2267 4.36815 12.3675 5 13.3178V16.2454C3.17107 14.7793 2 12.5264 2 10C2 5.58172 5.58172 2 10 2C12.5264 2 14.7793 3.17107 16.2454 5H13.3178Z"
      />
    </SvgIcon>
  )
}

export default RoundRoundIcon
