import React from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import SaveIcon from '@mui/icons-material/Save'
import { connect } from 'react-redux'
import _toNumber from 'lodash/toNumber'
import { Dispatch, bindActionCreators } from 'redux'
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter'
import { MenuProps } from '@mui/material/Menu'
import { CircularLoading } from 'components/Loading'
import UpscaleTextureForm from './components/UpscaleTextureForm'
import UpscaleDenoiseForm from './components/UpscaleDenoiseForm'
import GridLabelTwo from 'components/GridLabel/GridLabelTwo'
import SelectBase from 'components/Select/SelectBase'
import { RootState, RootActionType } from 'duck'
import { actions, selectors, Utils } from '../duck'
import { UpscaleFormData } from '../Models'
import { UpscaleImage } from 'models/ApiModels'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import { useBreakpoint } from 'utils/Hooks'
import Button from 'components/Button'
import { dialogActions, MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import { apiSelectors } from 'duck/ApiDuck'
import { Format } from 'utils/TextUtils'
import { ReduxProps } from 'utils/Types'
import ButtonText from 'components/Button/ButtonText'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './UpscaleGenerate.module.scss'

const DEFAULT_SELECTMENUPROPS: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
}

const GRID_LABEL_CLASSES = {
  label: 'text-white-med-on-dark'
}

const mapStateToProps = (state: RootState) => ({
  equity: apiSelectors.equity(state),
  selectedMethod: selectors.selectedMethod(state),
  currentFormData: selectors.currentFormData(state),
  disableForm: selectors.disableForm(state),
  hasLargeImage: selectors.hasLargeImage(state),
  isCurrentFormDataChanged: selectors.isCurrentFormDataChanged(state),
  upscaleLoading: selectors.upscaleLoading(state),
  upscaleImageData: selectors.upscaleImageData(state),
  currentDownloadPricing: selectors.currentDownloadPricing(state),
  isCurrentUpscaling: selectors.isCurrentUpscaling(state),
  retrieveUserImageUpscalesLoading:
    apiSelectors.loading['imageEnhancement.retrieveUserImageUpscales'](state),
  downloadingImage: apiSelectors.loading['payment.downloadingImage'](state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      submitUpscaleImage: actions.submitUpscaleImage,
      downloadCurrentUpscale: actions.downloadCurrentUpscale,
      addDialogOverlay: dialogActions.addDialogOverlay,
      updateFormData: actions.updateFormData
    },
    dispatch
  )

type UpscaleControlsProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const UpscaleControls: React.FC<UpscaleControlsProps> = props => {
  const {
    updateFormData,
    downloadCurrentUpscale,
    addDialogOverlay,
    submitUpscaleImage,
    isCurrentUpscaling,
    upscaleImageData,
    selectedMethod,
    retrieveUserImageUpscalesLoading,
    isCurrentFormDataChanged,
    hasLargeImage,
    disableForm,
    currentFormData,
    upscaleLoading,
    downloadingImage,
    currentDownloadPricing
  } = props

  const { formData, formItems } = currentFormData
  const { isMediumscreenAbove } = useBreakpoint()
  const { creditBalance, hasEnoughBalance, price, endBalance } = currentDownloadPricing
  const isFree = price === 0
  const pluralPrefixBalance = creditBalance > 0 ? 's' : ''
  const isUpscaleUnavailable = price < 0

  const showControls = Boolean(selectedMethod)
  const hasDownload = Boolean(upscaleImageData?.output?.download)

  return showControls ? (
    <div className={clsx('pb-9', styles.UpscaleControlsWrapper)}>
      {isMediumscreenAbove ? (
        <div className={styles.UpscalePanelSidebarColTitle}>
          <Typography variant="overline" component="div">
            CONTROLS
          </Typography>
        </div>
      ) : null}

      <div className="mb-6">
        <GridLabelTwo
          classes={GRID_LABEL_CLASSES}
          label={
            <>
              <FullscreenIcon />
              <Typography className="ml-2 break-words" variant="overline">
                RESOLUTION
              </Typography>
            </>
          }
          content={
            <SelectBase
              className={styles.UpscaleControlsSelect}
              onChange={(value: string) =>
                updateFormData({ scale: _toNumber(value) as UpscaleImage['scale'] })
              }
              renderValue={value => {
                const selectedIndex = formItems.scale.findIndex(item => {
                  return _toNumber(item.value) === _toNumber(value)
                })
                const selectedSplitted = formItems.scale[selectedIndex]?.label?.split(' ', 2) ?? [
                  '',
                  ''
                ]

                return (
                  <Typography
                    variant="body2"
                    className="overflow-hidden"
                  >{`${selectedSplitted[0]} ${selectedSplitted[1]}`}</Typography>
                )
              }}
              items={formItems.scale}
              value={formData?.scale ?? null}
              size="small"
              inputAlign="right"
              disabled={disableForm}
              MenuProps={DEFAULT_SELECTMENUPROPS}
              borderless
            />
          }
        />

        <GridLabelTwo
          classes={GRID_LABEL_CLASSES}
          label={
            <>
              <SaveIcon />
              <Typography className="ml-2 break-words" variant="overline">
                EXPORT FILE TYPE
              </Typography>
            </>
          }
          content={
            <SelectBase
              className={clsx('text-white-med-on-dark', styles.UpscaleControlsSelect)}
              onChange={value =>
                updateFormData({ extension: value as UpscaleFormData['extension'] })
              }
              items={formItems.extension}
              value={formData?.extension ?? null}
              disabled
              size="small"
              inputAlign="right"
              menuTextTransform="lowercase"
              borderless
            />
          }
        />

        <GridLabelTwo
          classes={GRID_LABEL_CLASSES}
          label={
            <>
              <PhotoFilterIcon />
              <Typography className="ml-2 break-words" variant="overline">
                JPEG Deblocking
              </Typography>
            </>
          }
          content={
            <Switch
              disabled={disableForm}
              checked={formData?.deblocking ?? false}
              onChange={(e, checked) =>
                updateFormData({
                  deblocking: checked
                })
              }
            />
          }
          borderless
        />

        {formItems.enableTexture ? <UpscaleTextureForm /> : null}
        <UpscaleDenoiseForm />
      </div>

      {hasDownload ? (
        <div className={clsx('mt-6', stylesGlobal.BorderTopOnSurface)}>
          <Typography className="pt-4" variant="caption" component="div">
            You’ve purchased and downloaded this image before, you can re-download with no extra
            cost
          </Typography>
        </div>
      ) : null}

      {!hasDownload ? (
        <div className={clsx('mt-8', stylesGlobal.BorderTopOnSurface)}>
          <div className="flex py-1">
            <div className="w-1/100 min-w-0 flex-auto">
              <Typography variant="subtitle2">Current Balance</Typography>
            </div>

            <Typography variant="body2" component="div">
              {`${Format.creditAmount(creditBalance)} Credit${pluralPrefixBalance}`}
            </Typography>
          </div>

          {isCurrentUpscaling ? (
            <Typography className="mt-4" variant="caption" component="div">
              {formData?.scale && formData?.scale > 4
                ? 'Higher resolution upscales need a little more time to proces. '
                : ''}
              You can always view the progress of your upscales in “My Upscales”.
            </Typography>
          ) : isUpscaleUnavailable ? (
            <Typography className="mt-4" variant="caption" component="div">
              {`Subscribe now to unlock the ${formData?.scale}x upscale. See `}
              <ButtonText
                color="secondary"
                onClick={() => {
                  addDialogOverlay({
                    [MonetizationDialog.SUBSCRIPTION_PANEL]: {
                      dialogName: MonetizationDialog.SUBSCRIPTION_PANEL,
                      fullPage: true
                    }
                  })
                }}
              >
                PLAN AND PRICING
              </ButtonText>
            </Typography>
          ) : (
            <Typography className="mt-4" variant="caption" component="div">
              Upscale and preview for free. Pay only when you want to download.
            </Typography>
          )}
        </div>
      ) : null}

      <div className="mt-4">
        <Button
          variant="contained"
          color="secondary"
          disabled={
            Boolean(retrieveUserImageUpscalesLoading) ||
            Boolean(upscaleLoading) ||
            (!Boolean(isCurrentFormDataChanged) && hasLargeImage) ||
            !selectedMethod
          }
          onClick={() => submitUpscaleImage()}
          startIcon={<PhotoSizeSelectLargeIcon />}
          fullWidth
        >
          UPSCALE
        </Button>

        <Button
          color="secondary"
          onClick={() => {
            if (hasDownload || isFree) {
              downloadCurrentUpscale({ nextAction: undefined })
            } else if (hasEnoughBalance) {
              addDialogOverlay({
                [MonetizationDialog.IMAGE_DOWNLOAD]: {
                  dialogName: MonetizationDialog.IMAGE_DOWNLOAD,
                  price,
                  downloadAction: actions.downloadCurrentUpscale({
                    showPaidSnackBar: true,
                    nextAction: 'closeDialog'
                  })
                }
              })
            } else {
              addDialogOverlay({
                [MonetizationDialog.ADD_CREDIT]: {
                  dialogName: MonetizationDialog.ADD_CREDIT,
                  source: 'download',
                  requiredCredit: Math.abs(endBalance),
                  additionalAction: {
                    creditConsumed: price,
                    text: 'PURCHASE CREDIT AND DOWNLOAD IMAGE',
                    textAfterSuccess: 'Payment successful and image as been purchased',
                    action: actions.downloadCurrentUpscale({ nextAction: undefined })
                  }
                }
              })
            }
          }}
          disabled={!hasLargeImage || !selectedMethod || Boolean(downloadingImage)}
          className="mt-3"
          startIcon={
            Boolean(downloadingImage) ? <CircularLoading disableShrink /> : <SaveAltIcon />
          }
          fullWidth
        >
          {Utils.getDownloadText({ downloadingImage, price, hasDownload })}
        </Button>
      </div>
    </div>
  ) : null
}
export default connect(mapStateToProps, mapDispatchToProps)(UpscaleControls)
