import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { mergeMap, filter, map, startWith, take, withLatestFrom, delay, tap } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { ActionType, isActionOf, getType, createAction } from 'typesafe-actions'
import { Params } from 'react-router'
import copy from 'copy-text-to-clipboard'
import { ArtistParamType, route, SUB_ROUTE_ARTISTS } from 'routes'
import { createSelector } from 'reselect'
import { CollectionListReq, PostListReq, TrainProjectListReq } from 'models/ApiModels'
import { push } from 'redux-first-history'
import { of, merge } from 'rxjs'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
import MixPanelUtils from 'utils/MixPanelUtils'

// Constants
const NAMESPACE = '@@page/ArtistPage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const Utils = {
  getParam: (params: Params<keyof ArtistParamType>): ArtistParamType => {
    const artistUrl = params.artistUrl as ArtistParamType['artistUrl']
    const subRoute =
      params.subRoute &&
      SUB_ROUTE_ARTISTS.includes(params.subRoute as NonNullable<ArtistParamType['subRoute']>)
        ? (params.subRoute as ArtistParamType['subRoute'])
        : undefined

    return {
      artistUrl,
      subRoute
    }
  }
}

// Actions
export const artistPageActions = {
  initArtistPage: createAction(creator('INIT_ARTIST_PAGE'))<ArtistParamType>(),
  shareProfile: createAction(creator('SHARE_PROFILE'))(),
  setOpenedPage: createAction(creator('SET_OPENED_PAGE'))<{ page?: ArtistParamType['subRoute'] }>(),
  setLoaded: createAction(creator('SET_LOADED'))<{
    page: NonNullable<ArtistParamType['subRoute']>
  }>()
}

// Selectors
const selectArtistPage = (state: RootState) => state.container.artistPage

export const selectors = {
  artistPage: selectArtistPage,
  openedPage: createSelector(selectArtistPage, value => value.openedPage),
  loaded: createSelector(selectArtistPage, value => value.loaded),
  postsLoaded: createSelector(selectArtistPage, value => value.loaded.posts),
  collectionsLoaded: createSelector(selectArtistPage, value => value.loaded.collections),
  projectsLoaded: createSelector(selectArtistPage, value => value.loaded.projects),

  listProjectParams: createSelector(selectArtistPage, value => value.listProjectParams),
  listCollectionParam: createSelector(selectArtistPage, value => value.listCollectionParam),
  listPostParam: createSelector(selectArtistPage, value => value.listPostParam)
}

export type ArtistPageState = {
  openedPage: ArtistParamType['subRoute']
  loaded: Record<NonNullable<ArtistParamType['subRoute']>, boolean | undefined>
  listProjectParams: TrainProjectListReq
  listCollectionParam: CollectionListReq
  listPostParam: PostListReq
}

const INITIAL: ArtistPageState = {
  openedPage: undefined,
  loaded: {
    collections: false,
    posts: false,
    projects: false,
    about: false
  },
  listProjectParams: {
    ordering: '-modified',
    scope: 'public',
    limit: 30
  },
  listCollectionParam: {
    limit: 30,
    scope: 'public',
    ordering: '-modified'
  },
  listPostParam: {
    ordering: 'recent',
    scope: 'public',
    limit: 30
  }
}

const reducer = produce((state: ArtistPageState, { type, payload }) => {
  switch (type) {
    case getType(artistPageActions.setOpenedPage): {
      const openedPage = payload as ActionType<typeof artistPageActions.setOpenedPage>['payload']
      state.openedPage = openedPage.page ?? 'projects'
      return
    }
    case getType(artistPageActions.setLoaded): {
      const { page } = payload as ActionType<typeof artistPageActions.setLoaded>['payload']
      state.loaded[page] = true
      return
    }
    default:
  }
}, INITIAL)

// Epics

const setOpenedPageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(artistPageActions.setOpenedPage)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const page = action.payload.page ?? 'posts'
      const loaded = selectors.loaded(state)

      const listCollectionParam = selectors.listCollectionParam(state)
      const listProjectParams = selectors.listProjectParams(state)
      const listPostParam = selectors.listPostParam(state)

      return {
        page,
        loaded,
        listCollectionParam,
        listProjectParams,
        listPostParam
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ loaded, page }) => Boolean(!loaded['collections'] && page === 'collections')),
          mergeMap(({ listCollectionParam }) =>
            action$.pipe(
              filter(isActionOf(apiActions.social.listUserCollectionResponse)),
              take(1),
              map(() => artistPageActions.setLoaded({ page: 'collections' })),
              startWith(
                apiActions.social.listUserCollection({
                  param: listCollectionParam,
                  reloadList: true
                })
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ loaded, page }) => Boolean(!loaded['posts'] && page === 'posts')),
          mergeMap(({ listPostParam }) =>
            action$.pipe(
              filter(isActionOf(apiActions.social.listUserPostResponse)),
              take(1),
              map(() => artistPageActions.setLoaded({ page: 'posts' })),
              startWith(apiActions.social.listUserPost({ param: listPostParam, reloadList: true }))
            )
          )
        ),
        of(param).pipe(
          filter(({ loaded, page }) => Boolean(!loaded['projects'] && page === 'projects')),
          mergeMap(({ listProjectParams }) =>
            action$.pipe(
              filter(isActionOf(apiActions.social.listUserProjectResponse)),
              take(1),
              map(() => artistPageActions.setLoaded({ page: 'projects' })),
              startWith(
                apiActions.social.listUserProject({
                  param: listProjectParams,
                  reloadList: true
                })
              )
            )
          )
        )
      )
    )
  )

const initArtistPageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(artistPageActions.initArtistPage)),
    map(({ payload }) => payload),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ artistUrl }) => !Boolean(artistUrl)),
          map(() => push(route.EXPLORE.getUrl()))
        ),
        of(param).pipe(
          filter(({ artistUrl }) => Boolean(artistUrl)),
          mergeMap(({ artistUrl, subRoute }) =>
            action$.pipe(
              filter(isActionOf(apiActions.social.retrieveUserByAliasResponse)),
              take(1),
              tap(({ payload }) => {
                MixPanelUtils.track<'USER__OPEN_PROFILE'>('User - Open Profile', {
                  profile_alias: payload.alias,
                  profile_name: `${payload.first_name} ${payload.last_name}`,
                  profile_email: payload.email
                })
              }),
              mergeMap(({ payload }) => [
                apiActions.social.setCurrentUserProfile({ userId: payload.id }),
                artistPageActions.setOpenedPage({ page: subRoute ?? 'posts' })
              ]),
              startWith(apiActions.social.retrieveUserByAlias({ alias: artistUrl }))
            )
          )
        )
      )
    )
  )

const shareProfileEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(artistPageActions.shareProfile)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const userData = apiSelectors.currentUserProfileUserData(state)
      const baseUrl = window?.location?.origin
      const path = route.ARTISTS.getUrl({ artistUrl: userData?.alias })
      return { copy: copy(`${baseUrl}${path}`), userData }
    }),
    filter(({ copy }) => copy),
    tap(({ userData }) => {
      MixPanelUtils.track<'USER__SHARE_PROFILE'>('User - Share Profile', {
        profile_alias: userData?.alias ?? '',
        profile_name: `${userData?.first_name ?? ''} ${userData?.last_name ?? ''}`,
        profile_email: userData?.email ?? ''
      })
    }),
    mergeMap(() =>
      action$.pipe(
        filter(isActionOf(snackBarActions.show)),
        take(1),
        delay(1500),
        map(() => snackBarActions.close()),
        startWith(snackBarActions.show({ content: `Artist's link copied to your clipboard!` }))
      )
    )
  )

export const epics = combineEpics(setOpenedPageEpic, initArtistPageEpic, shareProfileEpic)

export default reducer
