import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ChangeEmailDialog, dialogActions } from 'duck/AppDuck/DialogDuck'
import { RootState, RootActionType } from 'duck'
import Button from 'components/Button'
import { appSelectors } from 'duck/AppDuck'
import { GeneralDialogItemProps } from '..'
import DialogWrapper from '../DialogWrapper'

import styles from '../Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  changeEmailData: appSelectors.changeEmail.changeEmail(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      addDialog: dialogActions.addDialog
    },
    dispatch
  )

type DialogResentConfirmNewEmailProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
> &
  GeneralDialogItemProps

const DialogResentConfirmNewEmail: React.FC<DialogResentConfirmNewEmailProps> = props => {
  const { activeDialog, changeEmailData, onClose, addDialog } = props
  const { formData } = changeEmailData

  return (
    <DialogWrapper
      dialogConfig={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        className: styles.EmailDialog
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">Magic link was sent again</Typography>}
      actions={
        <Fragment>
          <Button
            color="secondary"
            onClick={() =>
              addDialog({
                [ChangeEmailDialog.CONFIRM_NEW_EMAIL_HELP]: {
                  dialogName: ChangeEmailDialog.CONFIRM_NEW_EMAIL_HELP
                }
              })
            }
          >
            Need Help?
          </Button>
          <Button color="secondary" onClick={onClose}>
            OK
          </Button>
        </Fragment>
      }
      content={
        <Fragment>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              To finalize your changes, please verify your new email:
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography color="secondary" variant="body2">
              {formData?.email}
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              Click the magic link that was just sent to your new email address to confirm.
            </Typography>
          </div>
        </Fragment>
      }
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogResentConfirmNewEmail)
