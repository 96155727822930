import React, { CSSProperties, useCallback } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import AddIcon from '@mui/icons-material/Add'
import { FormDialog } from 'duck/AppDuck/DialogDuck/Models'

import Button from 'components/Button'
import { RootActionType } from 'duck'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import stylesGlobal from 'stylesGlobal.module.scss'
import { ReduxProps } from 'utils/Types'

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      openDialog: dialogActions.openDialog
    },
    dispatch
  )

export type AddProjectCardProps = ReduxProps<() => {}, typeof mapDispatchToProps> & {
  style?: CSSProperties
}

const AddProjectCard: React.FC<AddProjectCardProps> = ({ openDialog, style }) => {
  const handleClickCreateProject = useCallback(
    () => openDialog({ [FormDialog.NEW_PROJECT]: { dialogName: FormDialog.NEW_PROJECT } }),
    [openDialog]
  )

  return (
    <Button
      color="secondary"
      onClick={handleClickCreateProject}
      className={clsx('absolute left-0 top-0 h-full w-full', stylesGlobal.BorderSecondary)}
      startIcon={<AddIcon />}
    >
      CREATE NEW PROJECT
    </Button>
  )
}

export default connect(null, mapDispatchToProps)(AddProjectCard)
