import _toInteger from 'lodash/toInteger'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _compact from 'lodash/compact'
import _reduce from 'lodash/reduce'
import _toNumber from 'lodash/toNumber'
import { RootState } from 'duck'
import userSelectors from './UserSelectors'
import {
  ActiveProjectStatus,
  FinishedProjectStatus,
  EngineConfigData,
  EngineConfigKeyType,
  DEFAULT_BOOKMARK_SCOPE,
  Bookmark,
  UserImage
} from 'models/ApiModels'
import { createSelector } from 'reselect'
import { constructProjectData } from 'utils/DataProcessingUtils'
import { LEGACY_PROJECT_CATEGORY_TITLE, EngineConfigAdditionalData } from 'appConstants'
import { adjustedImages, currentMixImageProjectId, userImages } from './CommonSelectors'
import { BookmarkUtils } from 'utils/TextUtils'

const images = (state: RootState) => {
  return state.api?.collections?.images ?? {}
}

const projects = (state: RootState) => {
  return state.api.projects?.projects ?? {}
}

const currentProjectId = (state: RootState) => {
  return _toInteger(state.api.projects?.currentProjectId)
}

const projectPublicListId = (state: RootState) => {
  return state.api.projects?.projectPublicList ?? []
}
const projectListId = (state: RootState) => {
  return state.api.projects?.projectList ?? []
}
const projectActiveListId = (state: RootState) => {
  return state.api.projects?.projectActiveList ?? []
}

const activeProjects = createSelector(projects, projects => {
  return _filter(projects, project => {
    return ActiveProjectStatus.includes(project.status)
  })
})

const projectPublicList = createSelector(
  projects,
  projectPublicListId,
  userSelectors.currentUserId,
  (projects, projectPublicListId, currentUserId) =>
    _map(projectPublicListId, projectId => {
      const project = projects[projectId] || {}
      const isProjectOwner = _toNumber(project?.user?.id) === _toNumber(currentUserId)

      return {
        ...project,
        isProjectOwner
      }
    })
)

const projectActiveList = createSelector(
  projects,
  projectActiveListId,
  (projects, projectActiveListId) =>
    _map(projectActiveListId, projectId => projects[projectId] || {})
)
const isAllProjectStopped = createSelector(projectActiveList, projectActiveList => {
  let allProjectStopped = true
  for (let i = 0; i < projectActiveList.length; i++) {
    if (projectActiveList[i].status !== 'stopped') {
      allProjectStopped = false
      break
    }
  }
  return allProjectStopped
})

const projectList = createSelector(projects, projectListId, (projects, projectListId) =>
  _map(projectListId, projectId => {
    const project = projects[projectId]
    return { ...project, isProjectOwner: true }
  })
)
const engineConfigsRaw = (state: RootState) => {
  return state.api.engine.engineConfig?.results ?? []
}
const engineConfigs = createSelector(engineConfigsRaw, data =>
  data.map(datum => {
    const additionalData = EngineConfigAdditionalData[datum.codename as EngineConfigKeyType] ?? {}
    return {
      ...datum,
      ...additionalData
    }
  })
)
const engineConfigSummary = createSelector(engineConfigsRaw, data => {
  return {
    categoryWithoutFreeform: _compact(
      _map(data, datum => (datum.codename !== 'freeform' ? datum.codename : null))
    ),
    singleInputCategory: _compact(
      _map(data, datum => (datum.inputs === 1 ? datum.codename : null))
    ),
    doubleInputCategory: _compact(_map(data, datum => (datum.inputs === 2 ? datum.codename : null)))
  }
})

const initialEngineConfigData: EngineConfigData = {}

const engineConfigData = createSelector(engineConfigs, data =>
  _reduce(
    data,
    (result, value) => {
      return {
        ...result,
        [value.codename]: value
      }
    },
    initialEngineConfigData
  )
)
const hasEngineConfig = createSelector(engineConfigs, engineConfigs =>
  Boolean(engineConfigs.length)
)
const projectSummaryRetrieved = (state: RootState) => {
  return state.api.projects?.projectSummaryRetrieved
}
const projectSummary = (state: RootState) => {
  return state.api.projects?.projectSummary
}

export const projectSummaryData = createSelector(
  projectSummary,
  engineConfigData,
  (projectSummary, engineConfigData) => {
    const projectCount = projectSummary?.project_count
    let total = 0
    const projectCountData = _map(projectCount, value => {
      total = total + value.total
      const title = engineConfigData[value.category]?.name || LEGACY_PROJECT_CATEGORY_TITLE
      return {
        ...value,
        title
      }
    })

    const trainedProjectCount = projectSummary?.trained_project_count
    let trainedTotal = 0
    const trainedProjectCountData = _map(trainedProjectCount, value => {
      trainedTotal = trainedTotal + value.total
      const title = engineConfigData[value.category]?.name || LEGACY_PROJECT_CATEGORY_TITLE
      return {
        ...value,
        title
      }
    })

    return {
      collection_count: projectSummary?.collection_count || 0,
      project_count: projectCountData,
      project_count_total: total,
      trained_project_count: trainedProjectCountData,
      trained_project_count_total: trainedTotal
    }
  }
)

const isUserFirstCreatingProject = createSelector(
  userSelectors.user,
  projectSummaryData,
  (user, projectSummaryData) => {
    const projectTotal = projectSummaryData?.trained_project_count_total

    const isHasProjects = projectTotal > 0

    const is_did_initial_project_creation = user?.ui_extras?.is_did_initial_project_creation

    return !is_did_initial_project_creation && !isHasProjects
  }
)

const inputs = (state: RootState) => {
  return state.api.inputs?.inputs ?? {}
}

const inferences = (state: RootState) => {
  return state.api.projects?.inferences ?? {}
}

const projectMixes = (state: RootState) => {
  return state.api.projects?.projectMixes ?? {}
}

const currentProject = createSelector(
  currentProjectId,
  projects,
  inputs,
  images,
  userSelectors.currentUserId,
  engineConfigData,
  (projectId, projects, inputs, images, currentUserId, engineConfigData) => {
    return constructProjectData({
      projectId,
      projects,
      inputs,
      images,
      currentUserId,
      engineConfigData
    })
  }
)
const currentProjectCurrentSnapshot = createSelector(
  currentProject,
  currentProject => currentProject?.current_snapshot ?? 0
)

const currentProjectWithMix = createSelector(
  currentProject,
  projectMixes,
  (currentProject, projectMixes) => {
    const mix = currentProject?.mix
    const mixData = mix ? projectMixes[mix] : undefined
    return {
      ...currentProject,
      mixData
    }
  }
)

const trainOutputImages = (state: RootState) => {
  return state.api.projects?.trainOutputImages ?? {}
}
const currentProjectOutputImages = createSelector(
  currentProjectId,
  trainOutputImages,
  (projectId, trainOutputImages) => trainOutputImages[projectId]
)

const currentProjectSampleOutput = createSelector(
  currentProjectOutputImages,
  outputImage => outputImage?.sampleImage ?? undefined
)
const currentProjectOutputImageListId = createSelector(
  currentProjectOutputImages,
  currentProject,
  (outputImages, currentProject) => {
    const snapshot = (currentProject.current_snapshot ?? 1) - 1
    return outputImages?.snapshotData?.[snapshot] ?? []
  }
)
const currentProjectOutputImageList = createSelector(
  currentProjectOutputImageListId,
  userImages,
  adjustedImages,
  (list, userImages, adjustedImages): UserImage[] =>
    list.map(id => ({ ...userImages[id], adjustData: adjustedImages[userImages[id].adjust ?? 0] }))
)

const hasCurrentProjectOutputImageList = createSelector(
  currentProjectOutputImageListId,
  currentProjectOutputImageListId => Boolean(currentProjectOutputImageListId.length)
)

//Has Aesthetic
const isCanBeginTraining = createSelector(
  currentProject,
  engineConfigSummary,
  (currentProject, engineConfigSummary) => {
    const hasAesthetics = Boolean(_keys(currentProject?.aestheticData?.collections).length)
    const hasInspirations = Boolean(_keys(currentProject?.inspirationData?.collections).length)

    const status = currentProject?.status || 'drafted'
    const isDraft = status === 'drafted'
    const category = currentProject?.category ?? 'morph'

    if (!isDraft && FinishedProjectStatus.includes(status)) {
      return true
    }
    const { doubleInputCategory } = engineConfigSummary
    return doubleInputCategory.includes(category)
      ? hasAesthetics && hasInspirations
      : hasInspirations
  }
)
const inspirationCount = createSelector(currentProject, currentProject =>
  _reduce(
    currentProject?.inspirationData?.images,
    (result, value) => {
      return result + value.length
    },
    0
  )
)

const aestheticCount = createSelector(currentProject, currentProject =>
  _reduce(
    currentProject?.aestheticData?.images,
    (result, value) => {
      return result + value.length
    },
    0
  )
)
//Not including images that removed
const inspirationTrainedCount = createSelector(currentProject, currentProject =>
  _reduce(
    currentProject?.inspirationData?.images,
    (result, value, index) => {
      const collectionId = _toNumber(index)
      const isRemoved = currentProject?.inspirationData?.tags?.[collectionId]?.removed
      return isRemoved ? result : result + value.length
    },
    0
  )
)
//Not including images that removed
const aestheticTrainedCount = createSelector(currentProject, currentProject =>
  _reduce(
    currentProject?.aestheticData?.images,
    (result, value, index) => {
      const collectionId = _toNumber(index)
      const isRemoved = currentProject?.aestheticData?.tags?.[collectionId]?.removed
      return isRemoved ? result : result + value.length
    },
    0
  )
)

export type CollectionEnoughStateType = {
  isLess: boolean
  isMore: boolean
  inspirationCount: number
  aestheticCount: number
  maxInspiration: number
  maxAesthetic: number
  minInspiration: number
  minAesthetic: number
  isDoubleInput: boolean
}
const collectionEnoughState = createSelector(
  currentProject,
  inspirationTrainedCount,
  aestheticTrainedCount,
  engineConfigData,

  (
    currentProject,
    inspirationCount,
    aestheticCount,
    engineConfigData
  ): CollectionEnoughStateType => {
    const projectCategory = currentProject?.category ?? 'morph'
    const engineConfig = engineConfigData[projectCategory]

    const minInspiration = engineConfig?.inspiration_capacity_floor ?? 0
    const minAesthetic = engineConfig?.aesthetic_capacity_floor ?? 0

    const maxInspiration = engineConfig?.inspiration_capacity_cap ?? Infinity
    const maxAesthetic = engineConfig?.aesthetic_capacity_cap ?? Infinity

    let isMore = false
    let isLess = false
    const isDoubleInput = engineConfig?.inputs === 2 ? true : false

    if (isDoubleInput) {
      isLess = inspirationCount < minInspiration || aestheticCount < minAesthetic
    } else {
      isLess = inspirationCount < minInspiration
    }

    if (isDoubleInput) {
      isMore = inspirationCount > maxInspiration || aestheticCount > maxAesthetic
    } else {
      isMore = inspirationCount > maxInspiration
    }

    return {
      isMore,
      isLess,
      inspirationCount,
      aestheticCount,
      minInspiration,
      minAesthetic,
      maxInspiration,
      maxAesthetic,
      isDoubleInput
    }
  }
)

const hasCurrentProject = createSelector(currentProject, currentProject =>
  Boolean(currentProject.id)
)

const isUserHasTrainingProject = createSelector(projectSummaryData, projectSummaryData => {
  const projectTotal = projectSummaryData?.project_count_total
  return projectTotal >= 1
})

const randomImagesLists = (state: RootState) => {
  return state.api.projects?.randomImagesLists ?? {}
}

const projectRandomImageListId = createSelector(
  currentProjectId,
  randomImagesLists,
  (currentProjectId, randomImagesList) => randomImagesList[currentProjectId] ?? []
)
const projectRandomImageList = createSelector(
  projectRandomImageListId,
  userImages,
  (randomProjectListId, userImages) => randomProjectListId.map(id => userImages[id])
)

const randomMixImageLists = (state: RootState) => {
  return state.api.projects?.randomMixImageLists ?? {}
}

const mixProjectRandomImageListId = createSelector(
  currentMixImageProjectId,
  randomMixImageLists,
  (currentMixImageProjectId, randomMixImageLists) =>
    randomMixImageLists[currentMixImageProjectId] ?? []
)
const mixProjectRandomImageList = createSelector(
  mixProjectRandomImageListId,
  userImages,
  adjustedImages,
  (mixProjectRandomImageListId, userImages, adjustedImages): UserImage[] =>
    mixProjectRandomImageListId.map(id => ({
      ...userImages[id],
      adjustData: adjustedImages[userImages[id]?.adjust ?? 0]
    }))
)

const makeSelectProjectData = () =>
  createSelector(
    projects,
    (_: RootState, projectId: number) => projectId,
    (projects, projectId) => projects?.[projectId]
  )

const userImageBookmarks = (state: RootState) => {
  return state.api.projects.userImageBookmarks || {}
}
const userImageBookmarkLists = (state: RootState) => {
  return state.api.projects.userImageBookmarkLists || {}
}

const mixImageProjectData = (state: RootState) => {
  return state.api.mixImage.mixImageProject.data
}

const currentMixImageProjectScope = createSelector(
  currentMixImageProjectId,
  mixImageProjectData,
  (currentMixImageProjectId, mixImageProjectData) =>
    mixImageProjectData[currentMixImageProjectId]?.bookmark_scope
)

const currentMixProjectSavedListId = createSelector(
  currentMixImageProjectScope,
  userImageBookmarkLists,
  (bookmark_scope, userImageBookmarkLists) =>
    userImageBookmarkLists[bookmark_scope ?? '']?.list ?? []
)
const currentMixProjectSavedListCount = createSelector(
  currentMixImageProjectScope,
  userImageBookmarkLists,
  (bookmark_scope, userImageBookmarkLists) =>
    userImageBookmarkLists[bookmark_scope ?? '']?.lastRequest?.count
)

const currentMixProjectSavedList = createSelector(
  currentMixProjectSavedListId,
  userImageBookmarks,
  adjustedImages,
  (list, userImageBookmarks, adjustedImages): Bookmark<'user-image'>[] =>
    _compact(
      list.map(id => {
        const bookmark = userImageBookmarks[id]
        return bookmark
          ? {
              ...bookmark,
              item: {
                ...bookmark.item,
                adjustData: adjustedImages[bookmark.item.adjust ?? 0]
              }
            }
          : undefined
      })
    )
)

const currentProjectSavedListId = createSelector(
  currentProject,
  userImageBookmarkLists,
  (currentProject, userImageBookmarkLists) => {
    return (
      userImageBookmarkLists[
        BookmarkUtils.adjustBookmarkScope(currentProject?.bookmark_scope ?? '')
      ]?.list ?? []
    )
  }
)
const currentProjectSavedImageListCount = createSelector(
  currentProject,
  userImageBookmarkLists,
  (currentProject, userImageBookmarkLists) =>
    userImageBookmarkLists[BookmarkUtils.adjustBookmarkScope(currentProject?.bookmark_scope ?? '')]
      ?.lastRequest?.count
)
const hasCurrentProjectSavedList = createSelector(
  currentProjectSavedListId,
  currentProjectSavedListId => Boolean(currentProjectSavedListId.length)
)

const currentProjectSavedList = createSelector(
  currentProjectSavedListId,
  userImageBookmarks,
  adjustedImages,
  (list, userImageBookmarks, adjustedImages): Bookmark<'user-image'>[] => {
    return _compact(
      list.map(id => {
        const bookmark = userImageBookmarks[id]
        return bookmark
          ? {
              ...bookmark,
              item: { ...bookmark.item, adjustData: adjustedImages[bookmark.item.id], bookmark: id }
            }
          : undefined
      })
    )
  }
)

const currentProjectSavedImageList = createSelector(currentProjectSavedList, (list): UserImage[] =>
  list.map(value => value.item)
)

const allSavedListId = createSelector(
  userImageBookmarkLists,
  userImageBookmarkLists => userImageBookmarkLists[DEFAULT_BOOKMARK_SCOPE]?.list ?? []
)

const allSavedList = createSelector(
  allSavedListId,
  userImageBookmarks,
  adjustedImages,
  (list, userImageBookmarks, adjustedImages) =>
    _compact(
      list.map(
        id =>
          userImageBookmarks[id] && {
            ...userImageBookmarks[id].item,
            adjustData: adjustedImages[userImageBookmarks[id].item.adjust ?? 0],
            bookmark: id
          }
      )
    )
)

export const projectSelectors = {
  isUserFirstCreatingProject,
  isUserHasTrainingProject,

  currentProjectId,
  currentProject,
  currentProjectWithMix,
  hasCurrentProject,
  projects,
  projectListId,
  activeProjects,
  projectList,
  projectPublicList,
  projectActiveList,
  isAllProjectStopped,
  engineConfigs,
  engineConfigData,
  engineConfigSummary,
  projectSummaryRetrieved,
  hasEngineConfig,
  projectSummaryData,
  currentProjectCurrentSnapshot,
  trainOutputImages,
  currentProjectSampleOutput,
  currentProjectOutputImages,
  currentProjectOutputImageList,
  hasCurrentProjectOutputImageList,

  inputs,
  images,
  inferences,
  isCanBeginTraining,
  inspirationCount,
  aestheticCount,
  collectionEnoughState,

  userImages,
  projectRandomImageList,
  mixProjectRandomImageList,

  makeSelectProjectData,

  userImageBookmarks,
  userImageBookmarkLists,

  currentProjectSavedList,
  hasCurrentProjectSavedList,
  currentProjectSavedImageList,
  currentProjectSavedImageListCount,
  currentMixProjectSavedList,
  currentMixProjectSavedListCount,
  allSavedListId,
  allSavedList
}

export default projectSelectors
