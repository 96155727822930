import { FormDialog } from 'duck/AppDuck/DialogDuck'
import loadable from 'utils/Loadable'
import { useDialogDetector } from 'components/Dialog/Hooks'

const EmailPreferencesDialog = loadable(
  () => import(/* webpackChunkName: "EmailPreferencesDialog" */ './index')
)

const EmailPreferencesDialogContainer = () => {
  const open = useDialogDetector(FormDialog.EMAIL_PREFERENCES)

  if (!open) return null

  return <EmailPreferencesDialog />
}

export default EmailPreferencesDialogContainer
