import React, { useMemo, useState, useCallback, useEffect, useRef, useReducer } from 'react'
import clsx from 'clsx'
import DialogMaterial, { DialogProps as DialogMaterialProps } from '@mui/material/Dialog'
import DialogMaterialActions from '@mui/material/DialogActions'
import DialogMaterialContent from '@mui/material/DialogContent'
import Fade from '@mui/material/Fade'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'

import Button from 'components/Button'
import { values } from 'appConstants'
import { useDelayedData, useWindowDimension } from 'utils/Hooks'

import styles from './Dialog.module.scss'

const EMPTY_OBJECT: any = {}

type ChilrenFunctionType =
  | React.ReactNode
  | React.ReactElement
  | ((floatingCloseHidden: boolean) => React.ReactElement)

type TransitionComponentProps = TransitionProps & {
  children: any
}

export type DialogProps = Pick<
  DialogMaterialProps,
  | 'BackdropComponent'
  | 'children'
  | 'classes'
  | 'className'
  | 'closeAfterTransition'
  | 'disableEscapeKeyDown'
  | 'fullScreen'
  | 'fullWidth'
  | 'hideBackdrop'
  | 'maxWidth'
  | 'onClose'
  | 'open'
  | 'PaperComponent'
  | 'PaperProps'
  | 'scroll'
  | 'style'
  | 'TransitionComponent'
  | 'transitionDuration'
  | 'TransitionProps'
  | 'aria-describedby'
  | 'aria-labelledby'
  | 'keepMounted'
> & {
  disableBackdropClick?: boolean
  actionContent?: React.ReactNode
  disableTitleSpacing?: boolean
  disableTitleBottomSpacing?: boolean
  disableActionSpacing?: boolean
  disableActionTopSpacing?: boolean
  disableContentSpacing?: boolean
  children?: ChilrenFunctionType
  classes?: DialogMaterialProps['classes'] & {
    title?: string
    titleContent?: string
    content?: string
    actions?: string
  }
  contentDividers?: boolean
  contentDividerBottom?: boolean
  contentDividerTop?: boolean
  hideDialogClose?: boolean
  onClose?: () => void
  showFloatingClose?: boolean | 'auto'
  showTitleClose?: boolean | 'auto'
  titleCentered?: boolean
  titleContent?: React.ReactNode
  transition?: 'slideUp' | 'slideLeft'
}

type OnCloseType = NonNullable<DialogMaterialProps['onClose']>

const TransitionComponentDefault = React.forwardRef(function Transition(
  props: TransitionComponentProps,
  ref: any
) {
  return <Fade ref={ref} {...props} />
})

const TransitionComponentSlideUp = React.forwardRef(function Transition(
  props: TransitionComponentProps,
  ref: any
) {
  return <Slide ref={ref} direction="up" {...props} />
})

const TransitionComponentSlideLeft = React.forwardRef(function Transition(
  props: TransitionComponentProps,
  ref: any
) {
  return <Slide ref={ref} direction="left" {...props} />
})

const Dialog: React.FC<DialogProps> = props => {
  const {
    /* Material Dialog Props */
    children,
    classes,
    className,
    closeAfterTransition = true,
    disableEscapeKeyDown,
    fullScreen,
    fullWidth,
    hideBackdrop,
    maxWidth,
    onClose,
    open,
    PaperComponent,
    PaperProps,
    scroll,
    style,
    TransitionComponent,
    transitionDuration,
    TransitionProps,
    /* Material Dialog Props END */

    actionContent,
    disableBackdropClick,
    disableTitleSpacing,
    disableTitleBottomSpacing,
    disableActionSpacing,
    disableActionTopSpacing,
    contentDividers,
    contentDividerBottom,
    contentDividerTop,
    disableContentSpacing,
    hideDialogClose,
    keepMounted,
    showFloatingClose,
    showTitleClose,
    titleCentered,
    titleContent,
    transition
  } = props
  const refDialog = useRef<HTMLDivElement>(null)
  const [refDialogPaperState, setRefDialogPaperState] = useState<any>({
    current: undefined
  })
  const {
    title: classNameTitle,
    content: classNameContent,
    actions: classNameActions,
    titleContent: classNameTitleContent,
    ...restClasses
  } = classes || EMPTY_OBJECT

  useWindowDimension()

  const onCloseAdjusted = useCallback<OnCloseType>(
    (event, reason) => {
      if (!(reason === 'backdropClick' && disableBackdropClick)) {
        onClose?.()
      }
    },
    [disableBackdropClick, onClose]
  )

  // force render method
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const refDialogCurrent = refDialog?.current
  const currentRefDialogPaperState = refDialogPaperState.current
  const currentRefDialogPaperStateClientWidth = currentRefDialogPaperState?.clientWidth || 0
  const currentRefDialogPaperStateClientHeight = currentRefDialogPaperState?.clientHeight || 0

  const showFloatingCloseAdjusted = useMemo(() => {
    if (
      (currentRefDialogPaperStateClientWidth &&
        currentRefDialogPaperStateClientHeight &&
        typeof showFloatingClose === 'undefined') ||
      showFloatingClose === 'auto'
    ) {
      if (
        !fullScreen &&
        (window?.innerWidth - currentRefDialogPaperStateClientWidth >= 60 * 2 ||
          window?.innerHeight - currentRefDialogPaperStateClientHeight >= 60 * 2)
      ) {
        return true
      } else return false
    }

    return showFloatingClose
  }, [
    fullScreen,
    currentRefDialogPaperStateClientWidth,
    currentRefDialogPaperStateClientHeight,
    showFloatingClose
  ])

  const childrenAdjusted = useMemo(() => {
    return (
      <>
        {!hideDialogClose && showFloatingCloseAdjusted ? (
          <Button
            className={styles.DialogButtonCloseFloating}
            aria-label="Close"
            onClick={onClose}
            icon
          >
            <CloseIcon fontSize="inherit" />
          </Button>
        ) : null}

        {titleContent || (!hideDialogClose && showTitleClose) ? (
          <div
            className={clsx(
              styles.DialogTitle,
              titleCentered && styles.TextCenter,
              classNameTitle,
              !disableTitleSpacing && !disableTitleBottomSpacing && styles.Padding24,
              !disableTitleSpacing &&
                disableTitleBottomSpacing &&
                `${styles.PaddingX24} ${styles.PaddingTop24}`
            )}
          >
            {typeof titleContent === 'string' ? (
              <Typography
                className={clsx(
                  styles.UserSelectNone,
                  styles.DialogTitleContent,
                  classNameTitleContent
                )}
                variant="h6"
              >
                {titleContent}
              </Typography>
            ) : (
              <div
                className={clsx(
                  styles.UserSelectNone,
                  styles.DialogTitleContent,
                  classNameTitleContent
                )}
              >
                {titleContent}
              </div>
            )}

            {!hideDialogClose &&
            ((!showFloatingCloseAdjusted &&
              (showTitleClose === 'auto' || typeof showTitleClose === 'undefined')) ||
              showTitleClose === true) ? (
              <div className={styles.DialogTitleCloseWrapper}>
                <Button
                  className={styles.DialogTitleClose}
                  aria-label="Close"
                  onClick={onClose}
                  icon
                >
                  <CloseIcon />
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}

        {children ? (
          <DialogMaterialContent
            className={clsx(
              styles.DialogContent,
              disableContentSpacing ? styles.DialogContentWithoutPadding : styles.PaddingY24,
              contentDividerTop && styles.BorderTopOnSurface,
              contentDividerBottom && styles.BorderBottomOnSurface,
              classNameContent
            )}
            dividers={contentDividers}
          >
            {children instanceof Function
              ? (children as Function)(!showFloatingCloseAdjusted)
              : children}
          </DialogMaterialContent>
        ) : null}

        {actionContent && (
          <DialogMaterialActions
            className={clsx(
              styles.DialogActions,
              !disableActionSpacing && styles.PaddingX24,
              !disableActionSpacing && !disableActionTopSpacing && styles.PaddingY24,
              !disableActionSpacing && disableActionTopSpacing && styles.PaddingBottom24,
              classNameActions
            )}
            disableSpacing
          >
            {actionContent}
          </DialogMaterialActions>
        )}
      </>
    )
  }, [
    onClose,
    classNameTitle,
    classNameTitleContent,
    classNameContent,
    classNameActions,
    titleContent,
    titleCentered,
    hideDialogClose,
    children,
    contentDividerTop,
    contentDividers,
    disableActionSpacing,
    disableActionTopSpacing,
    disableContentSpacing,
    disableTitleBottomSpacing,
    disableTitleSpacing,
    contentDividerBottom,
    actionContent,
    showFloatingCloseAdjusted,
    showTitleClose
  ])
  const [mounted, setMounted] = useState(false)
  const dialogContentDelayed = useDelayedData(childrenAdjusted, values.DIALOG_TRANSITION_DURATION)

  useEffect(() => {
    if (refDialogCurrent) {
      const elemRoleDialog = refDialogCurrent.querySelector('[role=dialog]')
      if (currentRefDialogPaperState !== elemRoleDialog) {
        setRefDialogPaperState({
          current: elemRoleDialog
        })
      }
    }
  }, [currentRefDialogPaperState, refDialogCurrent])

  /* To recheck dialog size and define close button position on first mounting */
  useEffect(() => {
    if (open && !mounted) {
      setTimeout(() => {
        setMounted(true)
      }, values.DIALOG_TRANSITION_DURATION)
    }
  }, [open, mounted])

  return (
    <DialogMaterial
      aria-describedby={props['aria-describedby']}
      aria-labelledby={props['aria-labelledby']}
      classes={{
        ...restClasses,
        paperScrollPaper: clsx(
          !fullScreen && styles.DialogPaperMobileMargin,
          !fullScreen && styles.DialogPaperMobileWidth,
          restClasses?.paperScrollPaper
        ),
        paperFullWidth: clsx(
          !fullScreen && styles.DialogPaperMobileWidth,
          restClasses?.paperFullWidth
        )
      }}
      className={className}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      hideBackdrop={hideBackdrop}
      maxWidth={maxWidth}
      onClose={onCloseAdjusted}
      open={open}
      PaperComponent={PaperComponent}
      PaperProps={PaperProps}
      scroll={scroll}
      style={style}
      TransitionComponent={
        TransitionComponent || transition === 'slideUp'
          ? TransitionComponentSlideUp
          : transition === 'slideLeft'
            ? TransitionComponentSlideLeft
            : TransitionComponentDefault
      }
      transitionDuration={
        transitionDuration || transition === 'slideUp' || transition === 'slideLeft'
          ? values.DEFAULT_TRANSITION_DURATION
          : values.DIALOG_TRANSITION_DURATION
      }
      TransitionProps={{
        ...TransitionProps,
        onEntering: (node: HTMLElement, isAppearing: boolean) => {
          forceUpdate()
          TransitionProps?.onEntering?.(node, isAppearing)
        }
      }}
      ref={refDialog}
      keepMounted={keepMounted}
      closeAfterTransition={closeAfterTransition}
    >
      {dialogContentDelayed}
    </DialogMaterial>
  )
}

export default Dialog
