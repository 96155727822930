import { TextTransform } from 'utils/TextUtils'
import { combineEpics, Epic } from 'redux-observable'
import { filter, map, withLatestFrom, mergeMap, tap, startWith, take } from 'rxjs/operators'
import _find from 'lodash/find'
import { RootState, RootActionType } from 'duck'
import { isActionOf, createAction } from 'typesafe-actions'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { route } from 'routes'
import { push } from 'redux-first-history'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'

// Constants
const NAMESPACE = '@@page/SketchToImagePage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const actions = {
  createSketchProject: createAction(creator('CREATE_SKETCH_PROJECT'))<{
    name: string
    genre: string
  }>()
}

// Epics

const createSketchProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.createSketchProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      sketchGenres: apiSelectors.sketchGenres(state),
      genre: action.payload.genre,
      name: action.payload.name
    })),
    map(({ sketchGenres, genre, name }) => ({
      genreId: _find(sketchGenres, sketchGenre => sketchGenre.codename === genre)?.id,
      name
    })), //get genre ID
    filter(({ genreId, name }) => Boolean(genreId) && Boolean(name)),
    mergeMap(({ genreId = 0, name }) =>
      action$.pipe(
        filter(isActionOf(apiActions.sketchToImage.createSketchProjectResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({
          genreData: apiSelectors.sketchGenreData(state),
          data: action.payload
        })),
        tap(({ data, genreData }) => {
          MixPanelUtils.track<'PROJECT__CREATE'>(
            'Project - Create',
            DataUtils.getProjectParam<'sketch_project'>('sketch_project', {
              sketchProject: data,
              sketchGenreData: genreData
            })
          )
          FacebookPixelUtils.track<'CREATE_SKETCH'>('create_sketch')
        }),
        map(({ data }) => push(route.SKETCH_PROJECTS.getUrl({ id: data?.id }))),
        startWith(
          apiActions.sketchToImage.createSketchProject({
            name,
            genre: genreId
          })
        )
      )
    )
  )

export const epics = combineEpics(createSketchProjectEpic)
