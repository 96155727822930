import { ActionType } from 'typesafe-actions'
import apiReducers, { apiActions, sharedActions } from 'duck/ApiDuck'
import appPage, { epics as appEpic, appActions, AppPageType } from 'duck/AppDuck'
import { firebaseActions } from 'duck/FirebaseDuck'
import { combineEpics, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { epics as apiEpic } from 'duck/ApiDuck'
import mixVideoPanel, {
  mixVideoPanelEpics,
  MixVideoPanelState,
  renderVideoActions,
  mixVideoActions
} from 'containers/MixVideoPanel/duck'
import trainProjectPage, {
  epics as trainProjectPageEpics,
  TrainProjectPageState,
  actions as trainProjectPageActions
} from 'containers/TrainProjectPage/duck'

import artistPage, {
  epics as artistPageEpic,
  ArtistPageState,
  artistPageActions
} from 'containers/ArtistPage/duck'

import textToImagePage, {
  epics as textToImagePanelEpic,
  TextToImageState,
  textToImageActions
} from 'containers/TextToImageProjectPage/duck'

import textToVideoPage, {
  epics as textToVideoPanelEpic,
  TextToVideoState,
  textToVideoActions
} from 'containers/TextToVideoProjectPage/duck'

import aiAppProjectPage, {
  AiAppProjectPageState,
  epics as aiAppPanelEpic,
  actions as aiAppProjectActions
} from 'containers/AiAppProjectPage/duck'

import homePage, {
  epics as homePageEpic,
  HomePageState,
  actions as homePageActions
} from 'containers/HomePage/duck'
import nftSellPanel, {
  epics as nftSellPanelEpic,
  NFTSellPanelState,
  actions as nftSellPanelActions
} from 'containers/NFTSellPanel/duck'
import profilePage, {
  epics as profilePageEpic,
  ProfilePageState,
  actions as profilePageActions
} from 'containers/ProfilePage/duck'
import welcomePage, {
  epics as welcomePageEpic,
  WelcomePageState,
  actions as welcomePageActions
} from 'containers/WelcomePage/duck'

import profileFormPanel, {
  epics as profileFormPanelEpic,
  ProfileFormPanelState,
  profileFormPanelActions
} from 'containers/ProfileFormPanel/duck'

import shareFeedPanel, {
  epics as shareFeedPanelEpics,
  ShareFeedPanelState,
  actions as shareFeedPanelActions
} from 'containers/ShareFeedPanel/duck'

import socialPanel, {
  epics as socialPanelEpic,
  SocialPanelState,
  actions as socialPanelActions
} from 'containers/SocialPanel/duck'

import mixImagePanel, {
  epics as mixImagePanelEpic,
  MixImagePanelState,
  actions as mixImagePanelActions
} from 'containers/MixImagePanel/duck'

import processesPanel, {
  epics as processesPanelEpic,
  ProcessesPanelState,
  actions as processesPanelActions
} from 'containers/ProcessesPanel/duck'
import startProjectPanel, {
  epics as startProjectPanelEpic,
  StartProjectPanelState,
  actions as startProjectPanelActions
} from 'containers/StartProjectPanel/duck'
import collectionEditorPanel, {
  epics as collectionEditorPanelEpic,
  CollectionEditorPanelState,
  actions as collectionEditorPanelActions
} from 'containers/CollectionEditorPanel/duck'

import explorePage, {
  epics as explorePageEpic,
  ExplorePageState,
  actions as explorePageActions
} from 'containers/ExplorePage/duck'
import monetizationDialog, {
  epics as monetizationDialogEpic,
  MonetizationDialogState,
  addCreditActions,
  trainingFormActions
} from 'containers/MonetizationDialog/duck'
import {
  epics as sketchToImagePageEpic,
  actions as sketchToImagePageActions
} from 'containers/SketchToImagePage/duck'
import sketchToImagePanel, {
  epics as sketchToImagePanelEpic,
  SketchToImagePanelState,
  actions as sketchToImagePanelActions
} from 'containers/SketchToImagePanel/duck'

import sketchTextToImagePage, {
  epics as sketchTextToImageEpic,
  SketchTextToImageState,
  sketchTextToImageActions
} from 'containers/SketchTextToImagePage/duck'

import { epics as firebaseEpic } from 'duck/FirebaseDuck'
import upscalePanel, {
  epics as upscalePanelEpics,
  UpscalePanelState,
  actions as upscalePanelActions
} from 'containers/UpscalePanel/duck'
import proArtFilterPage, {
  epics as proArtFilterPageEpic,
  actions as proArtFilterPageActions,
  ProArtFilterPageState
} from 'containers/ProArtFilterPage/duck'
import imageEnhancementPanel, {
  epics as imageEnhancementPanelEpics,
  ImageEnhancementPanelState,
  actions as imageEnhancementPanelActions
} from 'containers/ImageEnhancementPanel/duck'

//Reducer
import firebaseReducer, { FirebaseState } from 'duck/FirebaseDuck'
import { StateType } from 'typesafe-actions'
import { combineReducers } from 'redux'
import { catchError } from 'rxjs/operators'
import SentryUtils from 'utils/SentryUtils'
import { phoneActions } from './AppDuck/PhoneDuck'
import { bannerActions } from './AppDuck/BannerDuck'
import { changeEmailActions } from './AppDuck/ChangeEmailDuck'
import { dialogActions } from './AppDuck/DialogDuck'
import { eventEmiterActions } from './AppDuck/EventEmitterDuck'
import { snackBarActions } from './AppDuck/SnackBarDuck'
import { IHistoryContext, RouterState } from 'redux-first-history'

export const RootActions = {
  firebaseActions,
  apiActions,
  sharedActions,
  appActions,
  snackBarActions,
  bannerActions,
  dialogActions,
  phoneActions,
  eventEmiterActions,
  changeEmailActions,
  trainProjectPageActions,
  homePageActions,
  profilePageActions,
  welcomePageActions,
  shareFeedPanelActions,
  socialPanelActions,
  mixImagePanelActions,
  processesPanelActions,
  startProjectPanelActions,
  collectionEditorPanelActions,
  explorePageActions,
  addCreditActions,
  trainingFormActions,
  sketchToImagePageActions,
  sketchToImagePanelActions,
  upscalePanelActions,
  proArtFilterPageActions,
  imageEnhancementPanelActions,
  renderVideoActions,
  mixVideoActions,
  nftSellPanelActions,
  artistPageActions,
  profileFormPanelActions,
  textToImageActions,
  textToVideoActions,
  aiAppProjectActions,
  sketchTextToImageActions
}

export type RootActionType = ActionType<typeof RootActions>

const epicsArray = [
  apiEpic,
  firebaseEpic,
  appEpic,
  trainProjectPageEpics,
  explorePageEpic,
  collectionEditorPanelEpic,
  homePageEpic,
  profilePageEpic,
  welcomePageEpic,
  monetizationDialogEpic,
  sketchToImagePanelEpic,
  sketchToImagePageEpic,
  socialPanelEpic,
  startProjectPanelEpic,
  mixImagePanelEpic,
  processesPanelEpic,
  mixVideoPanelEpics,
  artistPageEpic,
  upscalePanelEpics,
  proArtFilterPageEpic,
  imageEnhancementPanelEpics,
  shareFeedPanelEpics,
  nftSellPanelEpic,
  profileFormPanelEpic,
  textToImagePanelEpic,
  textToVideoPanelEpic,
  aiAppPanelEpic,
  sketchTextToImageEpic
]

export const epics = (
  action$: Observable<RootActionType>,
  store$: StateObservable<RootState>,
  dependencies: any
) =>
  combineEpics(...epicsArray)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      const message = error?.message
      SentryUtils.captureMessage(`Epic Error ${message}`, error, 'error')
      return source
    })
  )

export type ContainerStateType = {
  collectionEditorPanel: CollectionEditorPanelState
  trainProjectPage: TrainProjectPageState
  homePage: HomePageState
  profilePage: ProfilePageState
  welcomePage: WelcomePageState
  monetizationDialog: MonetizationDialogState
  explorePage: ExplorePageState
  socialPanel: SocialPanelState
  sketchToImagePanel: SketchToImagePanelState
  appPage: AppPageType
  startProjectPanel: StartProjectPanelState
  mixImagePanel: MixImagePanelState
  processesPanel: ProcessesPanelState
  mixVideoPanel: MixVideoPanelState
  upscalePanel: UpscalePanelState
  proArtFilterPage: ProArtFilterPageState
  imageEnhancementPanel: ImageEnhancementPanelState
  shareFeedPanel: ShareFeedPanelState
  nftSellPanel: NFTSellPanelState
  artistPage: ArtistPageState
  profileFormPanel: ProfileFormPanelState
  textToImagePage: TextToImageState
  textToVideoPage: TextToVideoState
  aiAppProjectPage: AiAppProjectPageState
  sketchTextToImagePage: SketchTextToImageState
}

const containerReducer = combineReducers<ContainerStateType>({
  trainProjectPage,
  collectionEditorPanel,
  homePage,
  profilePage,
  welcomePage,
  explorePage,
  socialPanel,
  monetizationDialog,
  sketchToImagePanel,
  appPage,
  startProjectPanel,
  mixImagePanel,
  processesPanel,
  mixVideoPanel,
  upscalePanel,
  proArtFilterPage,
  imageEnhancementPanel,
  shareFeedPanel,
  nftSellPanel,
  artistPage,
  profileFormPanel,
  textToImagePage,
  textToVideoPage,
  aiAppProjectPage,
  sketchTextToImagePage
})

export type RootState = {
  router: RouterState
  firebase: FirebaseState
  api: StateType<typeof apiReducers>
  container: StateType<typeof containerReducer>
}
export const createRootReducer = (routerReducer: IHistoryContext['routerReducer']) =>
  combineReducers<RootState>({
    router: routerReducer,
    firebase: firebaseReducer,
    api: apiReducers,
    container: containerReducer
  })
