import React, { MouseEventHandler } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import clsx from 'clsx'

import { emptyFunction } from 'utils'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './BannerFloating.module.scss'

export type BannerFloatingProps = Pick<
  DialogProps,
  'children' | 'hideBackdrop' | 'keepMounted' | 'open'
> & {
  onClose?: MouseEventHandler
}

const BannerFloating: React.FC<BannerFloatingProps> = props => {
  const { children, hideBackdrop, open, onClose = emptyFunction } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: clsx(styles.RootClass, hideBackdrop && 'top-auto'),
        container: 'items-end',
        paper: clsx('m-0 w-full bg-dialog', stylesGlobal.BorderTopWhiteMed)
      }}
      maxWidth={false}
      hideBackdrop={hideBackdrop}
    >
      {children}
    </Dialog>
  )
}

export default BannerFloating
