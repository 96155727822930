import { matchPath, Params } from 'react-router'
import produce from 'immer'
import _compact from 'lodash/compact'
import _toNumber from 'lodash/toNumber'
import { combineEpics, Epic } from 'redux-observable'
import { combineReducers } from 'redux'
import {
  filter,
  map,
  withLatestFrom,
  ignoreElements,
  delay,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { of, merge, from } from 'rxjs'
import { push, replace } from 'redux-first-history'

import { RootState, RootActionType } from 'duck'
import {
  GenericAppProjectParamType,
  route,
  SUB_RESULT,
  SUB_ROUTE_GENERICAPPPROJECT_LIST
} from 'routes'
import { TextTransform, UrlUtils } from 'utils/TextUtils'

import {
  TrainOutputImagesReq,
  TrainOutputImage,
  GenericAppProjectCreateReq,
  TrainProject,
  FinishedProjectStatus,
  ImageType,
  UserImage,
  ContinueAbleProjectStatus,
  AdjustedImage,
  Bookmark
} from 'models/ApiModels'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { SelectedFormat } from 'utils/DataProcessingUtils'

// Constants
const NAMESPACE = '@@page/GenericAppProjectPage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export type DownloadOption = {
  key: number | string
  label: React.ReactNode
  onClick: Function
}

export const Utils = {
  getParam: (
    params: Params<keyof GenericAppProjectParamType>
  ): GenericAppProjectParamType | undefined => {
    const subRoute = SUB_ROUTE_GENERICAPPPROJECT_LIST.includes(
      (params.subRoute ?? '') as NonNullable<GenericAppProjectParamType['subRoute']>
    )
      ? (params.subRoute as GenericAppProjectParamType['subRoute'])
      : undefined

    return {
      id: params.id,
      subRoute
    }
  }
}

export type ResultPanelState = GenericAppProjectParamType & {
  urlParam?: GenericAppProjectParamType
  selectedResult?: number
  selectedSaved?: number
  download: {
    selectedDownloadImages: SelectedFormat
    selectedSavedImages: SelectedFormat
    showImageSelector: boolean
    downloadProgress: {
      downloading: boolean
      total: number
      success: number
      failed: number
      errorLog: object[]
    }
  }
  sellNft: {
    showDialogInfo: boolean
    showForm: boolean
    showImageSelector: boolean
  }
}

const initial: ResultPanelState = {
  id: '',
  subRoute: 'result',
  selectedSaved: undefined,
  download: {
    selectedDownloadImages: {},
    selectedSavedImages: {},
    showImageSelector: false,
    downloadProgress: {
      downloading: false,
      total: 0,
      success: 0,
      failed: 0,
      errorLog: []
    }
  },
  sellNft: {
    showDialogInfo: false,
    showForm: true,
    showImageSelector: false
  }
}

export type AiProjectPageState = {
  // collectionSelectorPanel: CollectionSelectorPanelState
  // inputPanel: InputPanelState
  resultPanel: ResultPanelState
  // generatePanel: GeneratePanelState
  // sellAsNFT: SellAsNFTState
}

// Actions
export const actions = {
  createGenericAppProject: createAction(creator('INIT_AI_APP'))<GenericAppProjectCreateReq>(),
  onUnmount: createAction(creator('ON_UNMOUNT'))(),
  retrieveInitData: createAction(creator('RETRIEVE_INIT_DATA'))<GenericAppProjectParamType>(),
  updateUrlParam: createAction(creator('SET_URL_PARAM'))<GenericAppProjectParamType>(),
  openSlideshow: createAction(creator('OPEN_SLIDESHOW'))<{
    batch?: string
    order?: string
  }>(),
  reloadSlideshow: createAction(creator('RELOAD_SLIDESHOW'))(),
  loadMoreImageGrid: createAction(creator('LOAD_MORE_IMAGE_GRID'))(),
  loadAllCurrentBatchImages: createAction(creator('LOAD_ALL_CURRENT_BATCH_IMAGES'))(),
  loadAllCurrentGridImages: createAction(creator('LOAD_ALL_CURRENT_GRID_IMAGES'))(),
  loadAllSavedImages: createAction(creator('LOAD_ALL_SAVED_IMAGES'))(),
  loadMoreImageSlider: createAction(creator('LOAD_MORE_IMAGE_SLIDER'))(),
  handleError: createAction(creator('HANDLE_ERROR'))<any>(),
  checkGridAndReload: createAction(creator('CHECK_GRID_AND_RELOAD'))(),
  reloadGrid: createAction(creator('RELOAD_GRID'))(),
  setSelectedResult: createAction(creator('SET_SELECTED)RESULT'))<number | undefined>(),
  setSelectedSaved: createAction(creator('SET_SELECTED)SAVED'))<number | undefined>(),
  download: {
    selectSavedImage: createAction(creator('DOWNLOAD/SELECT_SAVED_IMAGE'))<UserImage>(),
    resetSavedImageSelector: createAction(creator('DOWNLOAD/RESET_SAVED_IMAGE_SELECTOR'))(),
    downloadSelectedSaved: createAction(creator('DOWNLOAD/DOWNLOAD_SELECTED_SAVED'))(),
    downloadAllSaved: createAction(creator('DOWNLOAD/DOWNLOAD_ALL_SAVED'))(),

    selectImage: createAction(creator('DOWNLOAD/SELECT_IMAGE'))<TrainOutputImage>(),
    openImageSelector: createAction(creator('DOWNLOAD/OPEN_IMAGE_SELECTOR'))(),
    closeImageSelector: createAction(creator('DOWNLOAD/CLOSE_IMAGE_SELECTOR'))(),
    resetImageSelector: createAction(creator('DOWNLOAD/RESET_IMAGE_SELECTOR'))(),
    downloadAllInference: createAction(creator('DOWNLOAD/DOWNLOAD_ALL_INFERENCE'))(),

    // downloadCurrentBatch: createAction(creator('DOWNLOAD/DOWNLOAD_CURRENT_BATCH'))(),
    // downloadCurrentOrder: createAction(creator('DOWNLOAD/DOWNLOAD_CURRENT_ORDER'))(),
    downloadAllResults: createAction(creator('DOWNLOAD/DOWNLOAD_ALL_RESULT'))(),
    downloadSelected: createAction(creator('DOWNLOAD/DOWNLOAD_SELECTED'))()
  }
}

// Selector
const selectResultPanel = (state: RootState) => state.container.aiAppProjectPage.resultPanel

const selectShowImageSelector = createSelector(
  selectResultPanel,
  resultPanel => resultPanel?.download?.showImageSelector ?? false
)

export const selectSelectedResultId = createSelector(
  selectResultPanel,
  resultPanel => resultPanel.selectedSaved
)

const selectSelectedResult = createSelector(
  apiSelectors.genericAppProjectOutputs,
  selectResultPanel,
  apiSelectors.userImages,
  apiSelectors.adjustedImages,
  (genericAppProjectOutputs, selectResultPanel, userImages, adjustedImages) => {
    if (
      !selectResultPanel.selectedResult ||
      !genericAppProjectOutputs[selectResultPanel.selectedResult]
    )
      return undefined

    const currentResult = genericAppProjectOutputs[selectResultPanel.selectedResult]
    const currentResultImageId = currentResult.image.id
    const currentResultImage = userImages[currentResultImageId] ?? currentResult.image
    const enhancedImageId = currentResultImage.adjust

    const currentResultAdjusted = {
      ...currentResult,
      bookmark: currentResultImage.bookmark,
      image: {
        ...currentResultImage,
        adjustData: enhancedImageId ? adjustedImages[enhancedImageId] : undefined
      }
    }

    return currentResultAdjusted
  }
)

// Epics
const createGenericAppProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.createGenericAppProject)),
    withLatestFrom(state$),
    map(([action, state]) => {
      return {
        name: action.payload.name,
        project_app: action.payload.project_app
      }
    }),
    filter(({ name }) => Boolean(name)),
    switchMap(({ name, project_app }) =>
      action$.pipe(
        filter(isActionOf(apiActions.genericApp.createGenericAppProjectResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({
          data: action.payload
        })),
        tap(({ data }) => {
          // MixPanelUtils.track<'PROJECT__CREATE'>(
          //   'Project - Create',
          //   DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
          //     textToImageProject: data
          //   })
          // )
        }),
        map(({ data }) => replace(`${route.GENERIC_APP_PROJECTS.getUrl({ id: data?.id })}`)),
        startWith(
          apiActions.genericApp.createGenericAppProject({
            name,
            project_app
          })
        )
      )
    )
  )

// const downloadAllResultsEpic: Epic<RootActionType, RootActionType, RootState> = (
//   action$,
//   state$
// ) =>
//   action$.pipe(
//     filter(isActionOf([actions.download.downloadAllResultsEpic])),
//     withLatestFrom(state$),
//     map(([action, state]) => ({
//       requestHistory: selectors.currentImageGridRequestHistory(state),
//       type: action.type
//     })),

//     mergeMap(({ requestHistory }) =>
//       merge(
//         of(requestHistory).pipe(
//           filter(requestHistory => Boolean(requestHistory && requestHistory.next)),
//           mergeMap(() =>
//             // Ensure to fetch all images
//             action$.pipe(
//               filter(isActionOf(apiActions.projects.retrieveOutputImagesResponse)),
//               take(1),
//               map(() => actions.loadAllCurrentBatchImages()),
//               startWith(actions.loadMoreImageGrid())
//             )
//           )
//         ),
//         of(requestHistory).pipe(
//           filter(requestHistory => Boolean(requestHistory && requestHistory.next)),
//           map(() =>
//             snackBarActions.show({
//               content: `Load all current snapshot...`,
//               actionText: ''
//             })
//           )
//         ),
//         // All collected, just download it
//         of(requestHistory).pipe(
//           filter(requestHistory => !Boolean(requestHistory && requestHistory.next)),
//           withLatestFrom(state$),
//           map(([_, state]) => ({
//             currentProject: apiSelectors.currentProject(state),
//             currentImageGrid: selectors.currentImageGrid(state)
//           })),
//           map(({ currentImageGrid, currentProject }) => {
//             const sampleDownloadedImage = currentImageGrid?.[0]
//             const batch = sampleDownloadedImage?.batch ?? 0
//             const fileNamePrefix = `${batch + 1}`

//             return { currentImageGrid, fileNamePrefix, currentProject }
//           }),

//           map(({ currentImageGrid, fileNamePrefix, currentProject }) => ({
//             files: currentImageGrid.map(trainOutputImage => ({
//               fileUrl: trainOutputImage.image?.file ?? '',
//               imageName: Utils.getOutputImageName({ trainOutputImage, currentProject })
//             })),
//             fileName: `${currentProject.name}-snapshot_${fileNamePrefix}`
//           })),
//           mergeMap(({ files, fileName }) => [
//             appActions.log.download({
//               download_file_type: 'image',
//               download_location: 'Train Project - Download This Snapshot'
//             }),
//             downloaderActions.multiple.executeDownloadMultipleImage({
//               files,
//               fileName
//             })
//           ])
//         )
//       )
//     )
//   )

/* Epics */
export const selectors = {
  selectedResult: selectSelectedResult,
  selectedDownloadImages: createSelector(selectResultPanel, resultPanel => {
    return resultPanel.download.selectedDownloadImages
  }),
  showImageSelector: selectShowImageSelector
}

// Reducers
const reducer = produce((state: ResultPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.updateUrlParam): {
      state.urlParam = payload as GenericAppProjectParamType
      return
    }
    case getType(actions.retrieveInitData): {
      const projectParam = payload as ActionType<typeof actions.retrieveInitData>['payload']

      const {
        id,
        subRoute
        // batch, order
      } = projectParam
      state.id = id
      state.subRoute = subRoute
      // state.batch = batch
      // state.order = order
      // state.sliderValue = 0
      // state.slideshowValue = 0
      return
    }
    // case getType(actions.setSliderValue): {
    //   const sliderValue = payload as ActionType<typeof actions.setSliderValue>['payload']

    //   state.sliderValue = sliderValue
    //   return
    // }
    // // case getType(actions.setSlideshowValue): {
    // //   const slideshowValue = payload as ActionType<typeof actions.setSlideshowValue>['payload']

    // //   state.slideshowValue = slideshowValue
    // //   return
    // // }
    case getType(actions.openSlideshow): {
      // const slideshowParam = payload as ActionType<typeof actions.openSlideshow>['payload']

      // const { batch, order } = slideshowParam
      // state.batch = batch
      // state.order = order
      // state.slideshowValue = _toInteger(batch)
      return
    }
    case getType(actions.setSelectedResult): {
      const selectedResult = payload as ActionType<typeof actions.setSelectedResult>['payload']

      state.selectedResult = selectedResult

      return
    }
    case getType(actions.setSelectedSaved): {
      const selectedSaved = payload as ActionType<typeof actions.setSelectedSaved>['payload']

      state.selectedSaved = selectedSaved

      return
    }
    case getType(actions.download.selectImage): {
      const outputImage = payload as ActionType<typeof actions.download.selectImage>['payload']

      // const currentBatch = state.sliderValue - 1
      // const currentSelected = state.download.selectedDownloadImages[currentBatch] || {}
      // const newSelected = {
      //   ...currentSelected,
      //   [outputImage.order]: !currentSelected[outputImage.order]
      // }
      // state.download.selectedDownloadImages[currentBatch] = newSelected
      return
    }
    case getType(actions.download.selectSavedImage): {
      const userImage = payload as ActionType<typeof actions.download.selectSavedImage>['payload']

      const currentSelected = state.download.selectedSavedImages || {}
      const newSelected = {
        ...currentSelected,
        [userImage.id]: !currentSelected[userImage.id]
      }
      state.download.selectedSavedImages = newSelected
      return
    }
    case getType(actions.download.openImageSelector): {
      state.download.showImageSelector = true
      return
    }
    case getType(actions.download.closeImageSelector): {
      state.download.showImageSelector = false
      return
    }
    case getType(actions.download.resetImageSelector): {
      // const currentBatch = state.sliderValue - 1
      // state.download.selectedDownloadImages[currentBatch] = {}
      return
    }
    case getType(actions.download.resetSavedImageSelector): {
      state.download.selectedSavedImages = {}
      return
    }
    default:
  }
}, initial)

export const epics = combineEpics(createGenericAppProjectEpic)

export type AiAppProjectPageState = {
  // collectionSelectorPanel: CollectionSelectorPanelState
  // inputPanel: InputPanelState
  resultPanel: ResultPanelState
  // generatePanel: GeneratePanelState
  // sellAsNFT: SellAsNFTState
}

export default combineReducers<AiProjectPageState>({
  // collectionSelectorPanel: collectionSelectorPanelReducer,
  // inputPanel: inputPanelReducer,
  resultPanel: reducer
  // generatePanel: generatePanelReducer
  // sellAsNFT: sellAsNFTReducer
})
