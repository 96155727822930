import { apiActions, ImageEnhancementActionsType } from '../actions'
import { SharedActionsType, sharedActions } from '../sharedActions'
import { AdjustedImage, PaginationResponse, UpscaleImage, UpscaleMethod } from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'
import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _without from 'lodash/without'
import _uniq from 'lodash/uniq'

type UserImageUpscale = { [key in UpscaleMethod]: number | undefined }

export type ImageEnhancementState = {
  upscaleImage: {
    list: number[]
    data: {
      [upscaleImageId: number]: UpscaleImage
    }
    lastReq?: PaginationResponse<UpscaleImage>
    userImageUpscales: {
      [userImageId: number]: UserImageUpscale
    }
  }
  adjustedImages: {
    [adjustedImageId: number]: AdjustedImage | undefined
  }
}

export const INITIAL_USER_IMAGE_UPSCALE: UserImageUpscale = {
  waifu2x: undefined,
  drn: undefined,
  enh: undefined,
  bicubic: undefined
}
export const INITIAL: ImageEnhancementState = {
  upscaleImage: {
    list: [],
    data: {},
    lastReq: undefined,
    userImageUpscales: {}
  },
  adjustedImages: {}
}

export const imageEnhancementReducers = createReducer<
  ImageEnhancementState,
  ImageEnhancementActionsType | SharedActionsType
>(INITIAL)
  .handleAction([apiActions.imageEnhancement.retrieveUpscaleImageResponse], (state, action) => {
    const { data, req } = action.payload

    return {
      ...state,
      upscaleImage: {
        ...state.upscaleImage,
        data: {
          ...state.upscaleImage.data,
          [req]: data
        }
      }
    }
  })

  .handleAction(apiActions.imageEnhancement.createUpscaleImageResponse, (state, action) => {
    const { data, req } = action.payload

    return {
      ...state,
      upscaleImage: {
        ...state.upscaleImage,
        data: {
          ...state.upscaleImage.data,
          [data.id]: data
        },
        list: [data.id, ...state.upscaleImage.list],
        userImageUpscales: {
          ...state.upscaleImage.userImageUpscales,
          [req?.image ?? 0]: {
            ...(state.upscaleImage.userImageUpscales[req?.image ?? 0] ??
              INITIAL_USER_IMAGE_UPSCALE),
            [req.method]: data.id
          }
        }
      }
    }
  })

  .handleAction(apiActions.imageEnhancement.listUpscaleImageResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.upscaleImage.list : []

    return {
      ...state,
      upscaleImage: {
        ...state.upscaleImage,
        list: _uniq([...prevData, ..._map(results, result => result.id)]),
        data: {
          ...state.upscaleImage.data,
          ..._keyBy(results, result => result.id)
        },
        lastReq: data
      }
    }
  })
  .handleAction(apiActions.imageEnhancement.retrieveUserImageUpscalesResponse, (state, action) => {
    const { userImageUpscales, upscaleImages, userImageId } = action.payload

    return {
      ...state,
      upscaleImage: {
        ...state.upscaleImage,
        data: {
          ...state.upscaleImage.data,
          ..._keyBy(upscaleImages, result => result.id)
        },
        userImageUpscales: {
          ...state.upscaleImage.userImageUpscales,
          [userImageId]: userImageUpscales
        }
      }
    }
  })
  .handleAction(apiActions.imageEnhancement.deleteUpscaleImageResponse, (state, action) => {
    const { [action.payload.upscaleImageId]: deletedProject, ...data } = state.upscaleImage.data
    const list = _without(state.upscaleImage.list || [], deletedProject?.id)

    return {
      ...state,
      upscaleImage: {
        ...state.upscaleImage,
        list,
        data
      }
    }
  })
  .handleAction(
    [
      apiActions.imageEnhancement.retrieveAdjustedImageResponse,
      apiActions.imageEnhancement.createAdjustedImageResponse
    ],
    (state, action) => {
      const { payload } = action

      return {
        ...state,
        adjustedImages: {
          ...state.adjustedImages,
          [payload.id]: payload
        }
      }
    }
  )
  .handleAction(apiActions.imageEnhancement.connectAdjustedImageResponse, (state, action) => {
    const { data } = action.payload

    return {
      ...state,
      adjustedImages: {
        ...state.adjustedImages,
        [data.id]: data
      }
    }
  })
  .handleAction(apiActions.imageEnhancement.deleteAdjustedImageResponse, (state, action) => {
    const { adjustedImageId } = action.payload

    return {
      ...state,
      adjustedImages: {
        ...state.adjustedImages,
        [adjustedImageId]: undefined
      }
    }
  })
  .handleAction(sharedActions.reset, () => INITIAL)
