import React, { useEffect } from 'react'
import PortalMaterial, { PortalProps as PortalMaterialProps } from '@mui/material/Portal'

const PortalContainerNameList = ['appPage', 'appSubpage', 'body', 'navbar'] as const
const elementsPortal: any = {
  body: document.body
}
type PortalContainerName = (typeof PortalContainerNameList)[number]
type PortalContainerProps = PortalContainerName | PortalMaterialProps['container']

export const usePortalInit = (name: PortalContainerName, ref?: React.MutableRefObject<any>) => {
  useEffect(() => {
    if (name && ref?.current && !elementsPortal[name]?.current) {
      elementsPortal[name] = ref?.current
    }

    return () => {
      if (name && elementsPortal[name]) {
        delete elementsPortal[name]
      }
    }
  }, [name, ref])
}

type PortalProps = Omit<PortalMaterialProps, 'container'> & {
  container?: PortalContainerProps
}

const setContainer = (container?: PortalContainerProps) => {
  if (typeof container === 'string' && PortalContainerNameList.includes(container))
    return elementsPortal[container]

  return container
}

const Portal: React.FC<PortalProps> = props => {
  const { children, container, disablePortal } = props

  const containerAdjusted = setContainer(container)

  if (!containerAdjusted) return null

  return (
    <PortalMaterial container={containerAdjusted} disablePortal={disablePortal}>
      {children}
    </PortalMaterial>
  )
}

export default Portal
