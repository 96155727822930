import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _cloneDeep from 'lodash/cloneDeep'
import _uniq from 'lodash/uniq'
import _map from 'lodash/map'
import _take from 'lodash/take'
import _pull from 'lodash/pull'
import _takeRight from 'lodash/takeRight'
import _compact from 'lodash/compact'
import _without from 'lodash/without'
import _toNumber from 'lodash/toNumber'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  withLatestFrom,
  map,
  mergeMap,
  take,
  startWith,
  debounceTime,
  switchMap,
  takeUntil,
  tap,
  ignoreElements
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import {
  Clip,
  Transition,
  DeleteClipRequest,
  BookmarkCreateReq,
  UserImage,
  ClipProjectType
} from 'models/ApiModels'
import { apiSelectors, apiActions, sharedActions } from 'duck/ApiDuck'
import { Utils } from './Utils'
import { interval, merge, of } from 'rxjs'
import { ConfirmationDialog, dialogActions } from 'duck/AppDuck/DialogDuck'
import { values } from 'appConstants'
import MixPanelUtils, {
  DataUtils,
  DownloadLocation,
  EventProperties,
  UpdateClipType
} from 'utils/MixPanelUtils'
import { bannerActions, ShowUpsellParam } from 'duck/AppDuck/BannerDuck'
import { roundTransitionTime } from 'utils/DataProcessingUtils'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'

// Constants
const NAMESPACE = '@@page/MixVideoPanel/MixVideo'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const MIN_TRANSITION = 0.1
export const MAXIMUM_ACTIVE_CLIP = 6
const REFRESH_CLIP_INTERVAL = 3000
export const UPSELL_VIDEO_PARAM: ShowUpsellParam = {
  dismissable: true,
  upsellLocation: 'clip',
  contentMode: 'used-up',
  position: 'float-bottom-banner-overlay'
}

export const TabList = [
  'train_saved',
  'train_result',
  'train_random',
  'clips',
  'mix_saved',
  'mix_upload',
  'mix_random'
] as const

export type TabType = (typeof TabList)[number]
export type InitMixVideoProjectParam = Pick<MixVideoState, 'id' | 'type'>
export const TabWithFreeImage: TabType[] = ['mix_upload', 'mix_random']

type DeleteKeyFrameParam = {
  clipId: number
  keyframeIndex: number
}
export type ClipIdParam = { clipId: number }

type AddKeyFrameParam = {
  clipId: number
  keyframe: UserImage
}
type SelectKeyFrameParam = ClipIdParam & {
  selectedKeyframe?: number
}

type DuplicateClipParam = ClipIdParam & {
  name: string
}

type UpdateTransitionParam = {
  clipId: number
  transitionIndex: number
  transition: Partial<Transition>
}
export const mixVideoActions = {
  initMixVideoProject: createAction(creator('INIT_MIX_VIDEO_PROJECT'))<InitMixVideoProjectParam>(),
  onMixVideoClosed: createAction(creator('ON_MIX_VIDEO_CLOSED'))(),
  setActiveTab: createAction(creator('SET_ACTIVE_TAB'))<TabType>(),
  setShowMixVideo: createAction(creator('SHOW_MIX_VIDEO'))<boolean>(),
  setShowRenderVideo: createAction(creator('SHOW_RENDER_VIDEO'))<boolean>(),
  logUpdateClip: createAction(creator('LOG_UPDATE_CLIP'))<{
    updateType: UpdateClipType
    clipId: number
  }>(),
  logUpdateKeyframe: createAction(creator('LOG_UPDATE_KEYFRAME'))<
    EventProperties['KEYFRAME_DATA']
  >(),
  downloadImage: createAction(creator('DOWNLOAD_IMAGE'))<{
    downloadLocation: DownloadLocation
    image?: UserImage
  }>(),
  selectImage: createAction(creator('SELECT_IMAGE'))<{
    id: number | undefined
    tab?: TabType
  }>(),
  retrieveResult: createAction(creator('RETRIEVE_RESULT'))(),
  clips: {
    listClip: createAction(creator('CLIPS/LIST_CLIP'))(),
    toggleAlwaysShowClip: createAction(creator('CLIPS/TOGGLE_ALWAYS_SHOW_CLIP'))(),
    selectClip: createAction(creator('CLIPS/SELECT_CLIP'))<{
      clipId?: number
      skipActiveTab?: boolean
    }>(), //Select clip in list
    createClip: createAction(creator('CLIPS/CREATE_CLIP'))(),
    setRenameClip: createAction(creator('CLIPS/SET_RENAME_CLIP'))<{
      clipId: number | undefined
    }>(),
    updateClipName: createAction(creator('CLIPS/UPDATE_CLIP_NAME'))<{
      clipId: number
      name: string
    }>(),
    openClipEditor: createAction(creator('CLIPS/OPEN_CLIP_EDITOR'))<{
      clipId: number
    }>(),
    deleteClip: createAction(creator('CLIPS/DELETE_CLIP'))<ClipIdParam>(),
    executeDeleteClip: createAction(creator('CLIPS/EXECUTE_DELETE_CLIP'))<DeleteClipRequest>(),
    duplicateClip: createAction(creator('CLIPS/DUPLICATE_CLIP'))<DuplicateClipParam>(),
    addFetchClipQueue: createAction(creator('CLIPS/ADD_FETCH_CLIP_QUEUE'))<number>(),
    removeFetchClipQueue: createAction(creator('CLIPS/REMOVE_FETCH_CLIP_QUEUE'))<number>(),

    insertClipData: createAction(creator('CLIPS/INSERT_CLIP_DATA'))<Clip | undefined>(), //Populate form data with clip data from the server
    saveClipData: createAction(creator('CLIPS/SAVE_CLIP_DATA'))<number>(), //Save clip data to the server
    setIsSaved: createAction(creator('CLIPS/SET_IS_SAVED'))<{
      clipId: number
      isSaved: boolean
    }>(), //isSaved

    deleteActiveClip: createAction(creator('DELETE_ACTIVE_CLIP'))<ClipIdParam>(),

    generatePreview: createAction(creator('GENERATE_PREVIEW'))<ClipIdParam>(),

    selectKeyframe: createAction(creator('SELECT_KEYFRAME'))<SelectKeyFrameParam>(),
    addKeyframe: createAction(creator('ADD_KEYFRAME'))<AddKeyFrameParam>(),
    deleteKeyframe: createAction(creator('DELETE_KEYFRAME'))<DeleteKeyFrameParam>(),
    updateTransition: createAction(creator('UPDATE_TRANSITION'))<UpdateTransitionParam>()
  }
}

// Selectors
const selectMixVideo = (state: RootState) => state.container.mixVideoPanel.mixVideo
const selectProjectId = createSelector(selectMixVideo, mixVideo => mixVideo.id)
const selectProjectType = createSelector(selectMixVideo, mixVideo => mixVideo.type)
const selectShowMixVideo = createSelector(selectMixVideo, mixVideo => mixVideo.showMixVideo)
const selectShowRenderVideo = createSelector(selectMixVideo, mixVideo => mixVideo.showRenderVideo)
const selectActiveTab = createSelector(selectMixVideo, mixVideo => mixVideo.activeTab)

const selectCurrentMixVideo = createSelector(selectMixVideo, mixVideo => {
  const id = mixVideo.id
  const type = mixVideo.type
  const key = Utils.getClipListKey({ id, type })

  return mixVideo.data[key]
})
const selectActiveClips = createSelector(
  selectCurrentMixVideo,
  currentMixVideo => currentMixVideo?.activeClips ?? []
)
const selectFetchClipQueue = createSelector(
  selectCurrentMixVideo,
  currentMixVideo => currentMixVideo?.fetchClipQueue ?? []
)
const selectActiveClipsData = createSelector(
  selectActiveClips,
  apiSelectors.clips,
  (activeTab = [], clips) => activeTab.map(activeTab => clips[activeTab])
)

const selectSelectedClipId = createSelector(
  selectCurrentMixVideo,
  currentMixVideo => currentMixVideo?.selectedClip
)
const selectRenameClipData = createSelector(
  selectCurrentMixVideo,
  apiSelectors.clips,
  (currentMixVideo, clips) => {
    const renameClipId = currentMixVideo?.renameClipId

    return renameClipId ? clips[renameClipId] : undefined
  }
)

const selectCurrentProjectResultImages = createSelector(
  apiSelectors.currentProjectOutputImages,
  outputImages => outputImages?.snapshotData?.[0] ?? []
)

const selectPrevSelectedClipId = createSelector(
  selectCurrentMixVideo,
  currentMixVideo => currentMixVideo?.prevSelectedClip
)

const selectClipFormData = createSelector(
  selectCurrentMixVideo,
  currentMixVideo => currentMixVideo?.clipFormData
)

const selectSomeClipFormData = createSelector(
  selectClipFormData,
  (_: RootState, clipId: number) => clipId,
  (clipFormData, clipId = 0) => {
    const clipData = clipFormData?.[clipId]

    const derivedClipData = Utils.getDerivedClipData(clipData)

    return clipData
      ? {
          ...clipData,
          ...derivedClipData
        }
      : undefined
  }
)

const selectSelectedClipFormData = createSelector(
  selectClipFormData,
  selectSelectedClipId,
  (clipFormData, openedClipId = 0) => {
    const formData = clipFormData?.[openedClipId]

    const derivedClipData = Utils.getDerivedClipData(formData)

    return formData
      ? {
          ...formData,
          ...derivedClipData
        }
      : undefined
  }
)
const selectSelectedClipDuration = createSelector(
  selectSelectedClipFormData,
  selectedClipFormData => selectedClipFormData?.clipDuration ?? 0
)

export const selectClipThumbnails = createSelector(
  apiSelectors.clips,
  selectClipFormData,
  (_: RootState, clipId: number) => clipId,
  (clips, formData, clipId) => {
    const keyframesApi = _take(formData?.[clipId]?.keyframes ?? [], 10)
    const keyframesFormData = _take(clips?.[clipId]?.keyframes ?? [], 10)

    return keyframesFormData.length ? keyframesFormData : keyframesApi
  }
)

export const selectKeyframe = createSelector(
  selectSelectedClipFormData,
  (_: RootState, keyframeIndex: number) => keyframeIndex,
  (formData, keyframeIndex) => formData?.keyframes?.[keyframeIndex]
)

export const selectIsKeyframeSelected = createSelector(
  selectSelectedClipFormData,
  (_: RootState, keyframeIndex: number) => keyframeIndex,
  (formData, keyframeIndex) => formData?.selectedKeyframe === keyframeIndex
)

export const selectTransition = createSelector(
  selectSelectedClipFormData,
  (_: RootState, transitionIndex: number) => transitionIndex,
  (formData, transitionIndex) => formData?.transitions?.[transitionIndex]
)

export const selectKeyframesTimePosition = createSelector(
  selectSelectedClipFormData,
  (_: RootState, keyframeIndex: number) => keyframeIndex,
  (formData, keyframeIndex) => formData?.keyframesTimePosition?.[keyframeIndex]
)

const selectSelectedClipRaw = createSelector(
  selectSelectedClipId,
  apiSelectors.clips,
  (openedClipId = 0, clips) => clips[openedClipId]
)

const selectSelectedClip = createSelector(
  selectSelectedClipRaw,
  selectSelectedClipFormData,
  (currentClip, formData) =>
    currentClip
      ? {
          ...currentClip,
          formData,
          ...Utils.getDerivedClipData(formData ?? currentClip)
        }
      : undefined
)

export const selectSelectedKeyframeIndex = createSelector(selectSelectedClipFormData, formData => {
  return formData?.selectedKeyframe ?? undefined
})

export const selectSelectedKeyframeId = createSelector(selectSelectedClipFormData, formData => {
  const selectedKeyframeIndex = formData?.selectedKeyframe ?? undefined
  const keyframeDataId =
    selectedKeyframeIndex !== undefined
      ? formData?.keyframes?.[selectedKeyframeIndex]?.id
      : undefined

  return keyframeDataId
})

const selectSelectedKeyframeImage = createSelector(
  selectSelectedKeyframeId,
  apiSelectors.userImages,
  (selectedKeyframe, userImages) => {
    return selectedKeyframe ? userImages[selectedKeyframe] : undefined
  }
)

const selectSelectedImage = createSelector(selectCurrentMixVideo, currentMixVideo => {
  return currentMixVideo?.selectedImage
})

const selectSelectedImageData = createSelector(
  selectSelectedImage,
  apiSelectors.uploadImages,
  apiSelectors.userImageBookmarks,
  apiSelectors.userImages,
  (selectedImage, uploadImages, userImagesBookmarks, userImages) => {
    const train_saved = selectedImage?.['train_saved'] ?? 0
    const train_result = selectedImage?.['train_result']
    const train_random = selectedImage?.['train_random']
    const mix_saved = selectedImage?.['mix_saved'] ?? 0
    const mix_upload = selectedImage?.['mix_upload'] ?? 0
    const mix_random = selectedImage?.['mix_random']

    const uploadImageUserImageId = uploadImages[mix_upload]?.image?.id
    const trainSavedUserImageId = userImagesBookmarks[train_saved]?.item?.id
    const mixSavedUserImageId = userImagesBookmarks[mix_saved]?.item?.id

    return {
      train_saved: trainSavedUserImageId ? userImages[trainSavedUserImageId] : undefined,
      train_result: train_result ? userImages[train_result] : undefined,
      train_random: train_random ? userImages[train_random] : undefined,
      clips: undefined,
      mix_saved: mixSavedUserImageId ? userImages[mixSavedUserImageId] : undefined,
      mix_upload: uploadImageUserImageId ? userImages[uploadImageUserImageId] : undefined,
      mix_random: mix_random ? userImages[mix_random] : undefined
    }
  }
)

const selectActiveSidebarContent = createSelector(
  selectActiveTab,
  selectSelectedClip,
  selectSelectedImageData,
  selectSelectedKeyframeImage,
  selectSelectedKeyframeIndex,
  (activeTab, selectedClip, selectedImageData, selectedKeyframeImage, selectedKeyframeIndex) => {
    return {
      selectedImage: selectedImageData[activeTab] ?? selectedKeyframeImage,
      selectedClip,
      selectedKeyframe: selectedKeyframeImage,
      isKeyframeSelected: !selectedImageData[activeTab] && selectedKeyframeImage,
      selectedKeyframeIndex
    }
  }
)

export type ActiveSidebarContentType = ReturnType<typeof selectActiveSidebarContent>

const selectBookmarkRequest = createSelector(
  apiSelectors.currentProject,
  selectActiveSidebarContent,
  apiSelectors.currentMixImageProject,
  selectProjectType,
  (currentProject, activeSidebarContent, currentMixProject, projectType) => {
    const scopeTrainProject = currentProject?.bookmark_scope ?? ''
    const scopeMixProject = currentMixProject?.bookmark_scope ?? ''

    const scope = projectType === 'training_project' ? scopeTrainProject : scopeMixProject
    const id = activeSidebarContent.selectedImage?.id ?? 0

    const bookmarkRequest: BookmarkCreateReq<'user-image'> = {
      scope,
      item: id
    }

    return bookmarkRequest
  }
)

/* Clip List selector */

const selectClipProjectParam = createSelector(
  apiSelectors.currentProjectId,
  apiSelectors.currentMixImageProjectGenre,
  selectProjectType,
  (currentProjectId, currentMixImageProjectGenre, projectType = 'training_project') => {
    const id = projectType === 'training_project' ? currentProjectId : currentMixImageProjectGenre
    return {
      id,
      type: projectType
    }
  }
)

const clipListKey = createSelector(selectClipProjectParam, param => {
  return Utils.getClipListKey(param)
})

const currentClipListIds = createSelector(apiSelectors.clipLists, clipListKey, (clipList, key) => {
  return clipList[key]?.list ?? []
})

const hasCurrentClipList = createSelector(currentClipListIds, currentClipListIds =>
  Boolean(currentClipListIds.length)
)

const currentClipListLength = createSelector(
  apiSelectors.clipLists,
  clipListKey,
  (clipLists, key) => {
    const length1 = clipLists[key]?.lastReq?.count ?? 0
    const length2 = clipLists[key]?.list?.length ?? 0
    return Math.max(length1, length2)
  }
)

const currentClipList = createSelector(
  apiSelectors.clips,
  currentClipListIds,
  (clips, currentClipListIds) =>
    _map(currentClipListIds, id => {
      const clip = clips[id]

      return {
        ...clip,
        output: Utils.getFirstOutputs(clip.outputs)
      }
    })
)

const clipData = createSelector(
  apiSelectors.clips,
  (_: RootState, clipId: number) => clipId,
  (clips, clipId) => {
    const clip = clips[clipId]
    return {
      ...clip,
      output: Utils.getFirstOutputs(clip.outputs)
    }
  }
)

export const selectIsPreviewLoading = createSelector(
  selectFetchClipQueue,
  selectSelectedClipId,
  apiSelectors.loading['engine.generateClipPreview'],
  (fetchClipQueue, selectedClipId, generatePreviewLoading) =>
    Boolean(fetchClipQueue.includes(selectedClipId ?? -1) || generatePreviewLoading)
)

export const mixVideoSelectors = {
  mixVideo: selectMixVideo,
  activeTab: selectActiveTab,
  isPreviewLoading: selectIsPreviewLoading,
  fetchClipQueue: selectFetchClipQueue,
  projectId: selectProjectId,
  currentProjectResultImages: selectCurrentProjectResultImages,
  projectType: selectProjectType,
  showMixVideo: selectShowMixVideo,
  showRenderVideo: selectShowRenderVideo,
  currentMixVideo: selectCurrentMixVideo,
  selectedClipId: selectSelectedClipId,
  renameClipData: selectRenameClipData,
  clipFormData: selectClipFormData,
  selectedClipFormData: selectSelectedClipFormData,
  selectedClip: selectSelectedClip,
  bookmarkRequest: selectBookmarkRequest,
  selectedClipDuration: selectSelectedClipDuration,
  activeClips: selectActiveClips,
  selectedImage: selectSelectedImage,
  alwaysShowClip: createSelector(
    selectCurrentMixVideo,
    currentMixVideo => currentMixVideo?.alwaysShowClip
  ),
  activeSidebarContent: selectActiveSidebarContent,
  activeClipsData: selectActiveClipsData,

  projectKey: createSelector(selectMixVideo, mixVideo =>
    Utils.getClipListKey({ id: mixVideo.id, type: mixVideo.type })
  ),
  project: createSelector(selectMixVideo, mixVideo =>
    Utils.getClipListKey({ id: mixVideo.id, type: mixVideo.type })
  ),
  currentClipList,
  hasCurrentClipList,
  currentClipListLength,
  currentClipListIds,
  clipData,
  disableButtonRender: createSelector(
    currentClipList,
    selectSelectedClip,
    (currentClipList, selectedClip) => {
      return (selectedClip?.keyframes?.length || 0) < 2

      // return !(
      //   currentClipList?.length && currentClipList.some(item => item.keyframes?.length > 1)
      // ) /* Enable button render when at least 1 clip with more than 1 keyframe */
    }
  ),
  clipProjectParam: selectClipProjectParam
}

export type MixVideoProjectState = {
  selectedClip?: number
  openedClip?: number
  renameClipId?: number
  prevSelectedClip?: number
  fetchClipQueue?: number[]
  activeClips: number[]
  selectedImage: {
    [key in TabType]?: number
  }
  alwaysShowClip: boolean
  clipFormData: {
    [id: number]: Pick<Clip, 'id' | 'keyframes' | 'transitions'> & {
      isSaved?: boolean
      keyframesTimePosition?: number[]
      clipDuration?: number
      selectedKeyframe?: number
      isMaxedKeyframe?: boolean
      isMaxedDuration?: boolean
      isHasEnoughKeyframe?: boolean
      keyframeArray?: number[]
    }
  }
}

// Reducer
export type MixVideoState = {
  activeTab: TabType
  showMixVideo: boolean
  showRenderVideo: boolean
  id?: number
  type?: ClipProjectType
  previousProjectKey?: string
  data: { [projectKey: string]: MixVideoProjectState }
}

export const INITIAL_VIDEO_PROJECT_STATE: MixVideoProjectState = {
  selectedClip: undefined,
  openedClip: undefined,
  renameClipId: undefined,
  prevSelectedClip: undefined,
  fetchClipQueue: undefined,
  selectedImage: {},
  activeClips: [],
  alwaysShowClip: false,
  clipFormData: {}
}

const INITIAL_TRANSITION: Transition = {
  seconds: 5,
  ease_fn: 'LinearInOut'
}

export const INITIAL_VIDEO_STATE: MixVideoState = {
  activeTab: 'clips',
  showMixVideo: false,
  showRenderVideo: false,
  id: undefined,
  type: undefined,
  previousProjectKey: undefined,
  data: {}
}

const reducer = produce((state: MixVideoState, { type, payload }) => {
  switch (type) {
    case getType(mixVideoActions.initMixVideoProject): {
      const param = payload as ActionType<typeof mixVideoActions.initMixVideoProject>['payload']
      const key = Utils.getClipListKey({ id: param.id, type: param.type })
      const previousProjectKey = Utils.getClipListKey({ id: state.id, type: state.type })

      state.id = param.id
      state.type = param.type
      state.previousProjectKey = previousProjectKey

      if (state.data[previousProjectKey]) {
        state.data[previousProjectKey].fetchClipQueue = INITIAL_VIDEO_PROJECT_STATE.fetchClipQueue
      }

      if (!state.data[key]) {
        state.data[key] = _cloneDeep(INITIAL_VIDEO_PROJECT_STATE)
      }
      return
    }
    case getType(mixVideoActions.onMixVideoClosed): {
      const previousProjectKey = Utils.getClipListKey({ id: state.id, type: state.type })

      state.id = INITIAL_VIDEO_STATE.id
      state.type = INITIAL_VIDEO_STATE.type
      state.previousProjectKey = previousProjectKey
      state.activeTab = INITIAL_VIDEO_STATE.activeTab
      state.showMixVideo = INITIAL_VIDEO_STATE.showMixVideo

      return
    }

    case getType(mixVideoActions.setActiveTab): {
      const tab = payload as ActionType<typeof mixVideoActions.setActiveTab>['payload']
      state.activeTab = tab
      return
    }

    case getType(mixVideoActions.setShowMixVideo): {
      state.showMixVideo = payload
      return
    }
    case getType(mixVideoActions.setShowRenderVideo): {
      state.showRenderVideo = payload
      return
    }
    case getType(mixVideoActions.selectImage): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })
      const { id, tab } = payload as ActionType<typeof mixVideoActions.selectImage>['payload']
      const activeTab = tab ?? state.activeTab ?? 'clips'

      state.data[key].selectedImage[activeTab] = id
      state.data[key].alwaysShowClip = false

      return
    }
    case getType(mixVideoActions.clips.toggleAlwaysShowClip): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      state.data[key].alwaysShowClip = !state.data[key].alwaysShowClip
      return
    }
    case getType(mixVideoActions.clips.setRenameClip): {
      const renameClip = payload as ActionType<
        typeof mixVideoActions.clips.setRenameClip
      >['payload']
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      state.data[key].renameClipId = renameClip.clipId

      return
    }
    case getType(mixVideoActions.clips.removeFetchClipQueue): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const clipId = payload as ActionType<
        typeof mixVideoActions.clips.addFetchClipQueue
      >['payload']

      const currentQueue = state.data[key]?.fetchClipQueue ?? []
      _pull(currentQueue, clipId)
      state.data[key].fetchClipQueue = [...currentQueue]
      return
    }
    case getType(mixVideoActions.clips.addFetchClipQueue): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const clipId = payload as ActionType<
        typeof mixVideoActions.clips.addFetchClipQueue
      >['payload']

      const currentQueue = state.data[key]?.fetchClipQueue ?? []

      state.data[key].fetchClipQueue = [...currentQueue, clipId]
      return
    }

    //Clips
    case getType(mixVideoActions.clips.selectClip): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })
      const { clipId, skipActiveTab } = payload as ActionType<
        typeof mixVideoActions.clips.selectClip
      >['payload']

      if (state.data[key]) {
        state.data[key].prevSelectedClip = state.data[key].selectedClip
        state.data[key].selectedClip = clipId

        if (!skipActiveTab) {
          const currentActiveClips = _takeRight(
            state.data[key].activeClips ?? [],
            MAXIMUM_ACTIVE_CLIP
          )

          state.data[key].activeClips = _compact(_uniq([...currentActiveClips, clipId]))
        }
      }

      return
    }
    case getType(mixVideoActions.clips.deleteActiveClip): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const { clipId } = payload as ActionType<
        typeof mixVideoActions.clips.deleteActiveClip
      >['payload']

      state.data[key].activeClips = _without(state.data[key].activeClips, clipId)
      if (state.data[key].selectedClip === clipId) {
        state.data[key].selectedClip = state.data[key].activeClips[0]
      }
      return
    }
    case getType(mixVideoActions.clips.insertClipData): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const clip = payload as ActionType<typeof mixVideoActions.clips.insertClipData>['payload']

      if (clip) {
        state.data[key].clipFormData = {
          ...state.data[key].clipFormData,
          [clip.id]: {
            id: clip.id,
            transitions: clip.transitions,
            keyframes: clip.keyframes,
            isSaved: true
          }
        }
      }

      return
    }
    case getType(mixVideoActions.clips.setIsSaved): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const { clipId, isSaved } = payload as ActionType<
        typeof mixVideoActions.clips.setIsSaved
      >['payload']

      if (state.data[key]?.clipFormData?.[clipId]) {
        state.data[key].clipFormData[clipId].isSaved = isSaved
      }
      return
    }
    case getType(mixVideoActions.clips.addKeyframe): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const { clipId, keyframe } = payload as ActionType<
        typeof mixVideoActions.clips.addKeyframe
      >['payload']

      const currentKeyframe = state.data[key].clipFormData?.[clipId].keyframes || []
      const addedKeyframes = [...currentKeyframe, keyframe]

      const currentTransition = state.data[key].clipFormData?.[clipId].transitions || []
      const addedTransition =
        addedKeyframes.length > 1
          ? [...currentTransition, _cloneDeep(INITIAL_TRANSITION)]
          : currentTransition

      state.data[key].clipFormData = {
        ...state.data[key].clipFormData,
        [clipId]: {
          ...(state.data[key].clipFormData?.[clipId] ?? {}),
          keyframes: addedKeyframes,
          transitions: addedTransition,
          isSaved: false
        }
      }
      Object.keys(state.data[key].selectedImage).forEach(tabKey => {
        state.data[key].selectedImage[tabKey as TabType] = undefined
      })
      return
    }
    case getType(mixVideoActions.clips.selectKeyframe): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const { clipId, selectedKeyframe } = payload as ActionType<
        typeof mixVideoActions.clips.selectKeyframe
      >['payload']

      state.data[key].clipFormData = {
        ...state.data[key].clipFormData,
        [clipId]: {
          ...(state.data[key].clipFormData?.[clipId] ?? {}),
          selectedKeyframe,
          isSaved: false
        }
      }
      Object.keys(state.data[key].selectedImage).forEach(tabKey => {
        state.data[key].selectedImage[tabKey as TabType] = undefined
      })

      state.data[key].alwaysShowClip = false

      return
    }
    case getType(mixVideoActions.clips.deleteKeyframe): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const { clipId, keyframeIndex } = payload as ActionType<
        typeof mixVideoActions.clips.deleteKeyframe
      >['payload']

      const currentKeyframes = state.data[key].clipFormData?.[clipId].keyframes ?? []
      const isLastKeyframe = currentKeyframes.length - 1 === keyframeIndex

      currentKeyframes.splice(keyframeIndex, 1)

      const currentTransition = state.data[key].clipFormData?.[clipId].transitions || []

      currentTransition.splice(keyframeIndex, 1)
      if (isLastKeyframe) {
        currentTransition.pop()
      }

      state.data[key].clipFormData = {
        ...state.data[key].clipFormData,
        [clipId]: {
          ...(state.data[key].clipFormData?.[clipId] ?? {}),
          keyframes: [...currentKeyframes],
          transitions: [...currentTransition],
          isSaved: false
        }
      }

      return
    }
    case getType(mixVideoActions.clips.updateTransition): {
      const key = Utils.getClipListKey({ id: state.id, type: state.type })

      const { clipId, transitionIndex, transition } = payload as ActionType<
        typeof mixVideoActions.clips.updateTransition
      >['payload']

      const currentTransition =
        state.data[key]?.clipFormData?.[clipId].transitions?.[transitionIndex]

      if (currentTransition) {
        const updatedTransition = { ...currentTransition, ...transition }
        state.data[key].clipFormData[clipId].transitions[transitionIndex] = updatedTransition
        state.data[key].clipFormData[clipId].isSaved = false
      }
      return
    }
    default:
  }
}, INITIAL_VIDEO_STATE)

// Epics
const initMixVideoProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.initMixVideoProject)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      clipProjectParam: mixVideoSelectors.clipProjectParam(state),
      hasCurrentClipList: mixVideoSelectors.hasCurrentClipList(state)
    })),
    map(param => mixVideoActions.clips.listClip())
  )

const logUpdateClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.logUpdateClip)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      clip_id: action.payload.clipId,
      update_clip_type: action.payload.updateType,
      currentProject: apiSelectors.currentProject(state)
    })),
    tap(({ currentProject, clip_id, update_clip_type }) => {
      MixPanelUtils.track<'PROJECT__UPDATE_CLIP'>('Project Video - Update Clip', {
        ...DataUtils.getProjectParam<'training_project'>('training_project', {
          trainProject: currentProject
        }),
        clip_id: clip_id,
        update_clip_type
      })
    }),
    ignoreElements()
  )

const logUpdateKeyframeEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.logUpdateKeyframe)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      currentProject: apiSelectors.currentProject(state)
    })),
    tap(({ currentProject, payload }) => {
      MixPanelUtils.track<'PROJECT__UPDATE_KEYFRAME'>('Project Video - Update Keyframe', {
        ...DataUtils.getProjectParam<'training_project'>('training_project', {
          trainProject: currentProject
        }),
        ...payload
      })
    }),
    ignoreElements()
  )

const retrieveResultEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.retrieveResult)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      currentProject: apiSelectors.currentProject(state)
    })),
    filter(({ currentProject }) => Boolean(currentProject)),
    ignoreElements()
  )

const selectClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.selectClip)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      currentClipFormData: selectSelectedClipFormData(state),
      selectedClip: selectSelectedClip(state),
      prevSelectedClipId: selectPrevSelectedClipId(state),
      clipFormData: selectClipFormData(state)
    })),
    map(param => ({
      ...param,
      isSaved: param.clipFormData?.[param.prevSelectedClipId ?? 0]?.isSaved
    })),
    filter(
      ({ currentClipFormData, selectedClip }) =>
        !Boolean(currentClipFormData?.id) && Boolean(selectedClip)
    ),
    mergeMap(({ selectedClip, prevSelectedClipId, isSaved }) =>
      _compact([
        mixVideoActions.clips.insertClipData(selectedClip),
        !isSaved && prevSelectedClipId && mixVideoActions.clips.saveClipData(prevSelectedClipId)
      ])
    )
  )

const listClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.listClip)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      clipProjectParam: mixVideoSelectors.clipProjectParam(state)
    })),
    map(({ clipProjectParam }) => ({
      project: clipProjectParam.id,
      type: clipProjectParam.type,
      limit: 50,
      offset: 0
    })),
    mergeMap(param =>
      action$.pipe(
        filter(isActionOf(apiActions.engine.listClipResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({
          currentProject: apiSelectors.currentProject(state),
          currentClipList: mixVideoSelectors.currentClipList(state),
          selectedClipId: mixVideoSelectors.selectedClipId(state),
          projectType: mixVideoSelectors.projectType(state),
          hasClips: Boolean(action?.payload?.data?.count),
          payloadResults: action.payload?.data?.results
        })),
        map(
          ({
            currentProject,
            currentClipList,
            selectedClipId,
            hasClips,
            payloadResults,
            projectType
          }) => ({
            hasSelected: Boolean(selectedClipId),
            is_has_clip: currentProject?.ui_extras?.is_has_clip,
            currentProject,
            firstClipId: currentClipList?.[0]?.id ?? payloadResults?.[0]?.id ?? 0,
            hasClips,
            projectType,
            shouldUpdateHasClip:
              !currentProject?.ui_extras?.is_has_clip &&
              hasClips &&
              currentProject &&
              projectType === 'training_project'
          })
        ),
        mergeMap(({ hasSelected, currentProject, firstClipId, shouldUpdateHasClip }) =>
          _compact([
            !hasSelected &&
              firstClipId &&
              mixVideoActions.clips.selectClip({ clipId: firstClipId }),
            shouldUpdateHasClip &&
              apiActions.projects.update({
                id: currentProject?.id,
                ui_extras: { ...(currentProject?.ui_extras ?? {}), is_has_clip: true }
              })
          ])
        ),
        startWith(apiActions.engine.listClip({ param, next: false }))
      )
    )
  )

const createClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.createClip)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      currentProject: apiSelectors.currentProject(state),
      clipProjectParam: mixVideoSelectors.clipProjectParam(state),
      currentMixImageProject: apiSelectors.currentMixImageProject(state),
      name: `CLIP ${mixVideoSelectors.currentClipListLength(state) + 1}`
    })),
    map(({ clipProjectParam, currentProject, currentMixImageProject, name }) => ({
      param: {
        project: clipProjectParam,
        name
      },
      currentMixImageProject,
      currentProject
    })),
    mergeMap(({ param, currentProject, currentMixImageProject }) =>
      action$.pipe(
        filter(isActionOf(apiActions.engine.createClipResponse)),
        take(1),
        tap(({ payload }) => {
          if (param.project.type === 'training_project') {
            MixPanelUtils.track<'PROJECT__CREATE_CLIP'>('Project Video - Create Clip', {
              ...DataUtils.getProjectParam<'training_project'>('training_project', {
                trainProject: currentProject
              }),
              clip_id: payload.id,
              clip_type: param.project.type
            })
          }
          if (param.project.type === 'latent_genre') {
            MixPanelUtils.track<'PROJECT__CREATE_CLIP'>('Project Video - Create Clip', {
              ...DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
                mixProject: currentMixImageProject
              }),
              clip_id: payload.id,
              clip_type: param.project.type
            })
          }
        }),
        mergeMap(({ payload }) => [
          mixVideoActions.clips.insertClipData(payload),
          mixVideoActions.clips.selectClip({ clipId: payload.id }),
          mixVideoActions.clips.openClipEditor({ clipId: payload.id })
        ]),
        startWith(apiActions.engine.createClip(param))
      )
    )
  )

const addFetchClipQueueEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.addFetchClipQueue)),
    switchMap(() =>
      interval(REFRESH_CLIP_INTERVAL).pipe(
        //Take until the array is empty or there a error happen
        takeUntil(
          action$.pipe(
            withLatestFrom(state$),
            map(([_, state]) => ({
              fetchQueue: selectFetchClipQueue(state)
            })),
            filter(({ fetchQueue }) => !Boolean(fetchQueue.length))
          )
        ),
        withLatestFrom(state$),
        map(([_, state]) => ({
          fetchQueue: selectFetchClipQueue(state)
        })),
        filter(({ fetchQueue }) => Boolean(fetchQueue.length)),
        mergeMap(({ fetchQueue }) =>
          _map(fetchQueue, clipId => apiActions.engine.retrieveClip(clipId))
        )
      )
    )
  )

const retrieveClipListenerEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf([apiActions.engine.retrieveClipResponse])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      fetchQueue: selectFetchClipQueue(state),
      hasPreview: Boolean(action.payload.preview),
      clipId: action.payload.id
    })),
    filter(({ fetchQueue, hasPreview }) => Boolean(fetchQueue.length) && hasPreview),
    map(({ clipId }) => mixVideoActions.clips.removeFetchClipQueue(clipId))
  )

/* If there a error on clip listener, then stop the retrieval */
const retrieveClipErrorListenerEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    withLatestFrom(state$),
    filter(([action]) => action.payload.type === getType(apiActions.engine.retrieveClip)),
    map(([action, state]) => ({
      fetchQueue: selectFetchClipQueue(state),
      clipId: _toNumber(action.payload.req)
    })),
    filter(({ fetchQueue, clipId }) => Boolean(fetchQueue.length) && Boolean(clipId)),
    map(({ clipId }) => mixVideoActions.clips.removeFetchClipQueue(clipId))
  )

const removeFetchClipQueueEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.removeFetchClipQueue)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      currentProject: apiSelectors.currentProject(state),
      projectType: mixVideoSelectors.projectType(state),
      clipData: apiSelectors.clips(state)[action.payload],
      clipFormData: selectSomeClipFormData(state, action.payload),
      clipId: action.payload,
      isCurrentUserFree: apiSelectors.isCurrentUserFree(state)
    })),
    tap(({ clipFormData, clipData, currentProject, projectType, clipId }) => {
      MixPanelUtils.track<'PROJECT__GENERATE_VIDEO_PREVIEW_FINISHED'>(
        'Project Video - Generate Video Preview Finished',
        {
          ...DataUtils.getProjectParam<'training_project'>('training_project', {
            trainProject: currentProject
          }),
          video_duration: clipFormData?.id ?? 0,
          keyframe_count: clipFormData?.keyframes?.length ?? 0,
          frame_count: Math.round((clipFormData?.id ?? 0) * values.VIDEO_CLIP_PREVIEW_FRAME_RATE),
          clip_id: clipId,
          has_preview: Boolean(clipData.preview),
          clip_type: projectType ?? 'training_project'
        }
      )
    }),
    filter(({ isCurrentUserFree }) => isCurrentUserFree),
    map(() => apiActions.users.retrieveEquity())
  )

const generatePreviewEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.generatePreview)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      equity: apiSelectors.equity(state),
      payload: action.payload
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ equity }) => !equity?.upsellState?.canGenerateClip),
          map(() => bannerActions.upsell.show(UPSELL_VIDEO_PARAM))
        ),
        of(param).pipe(
          filter(({ equity }) => Boolean(equity?.upsellState?.canGenerateClip)),
          mergeMap(({ payload }) =>
            action$.pipe(
              filter(isActionOf(apiActions.engine.generateClipPreviewResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => ({
                equity: apiSelectors.equity(state),
                currentProject: apiSelectors.currentProject(state),
                projectType: mixVideoSelectors.projectType(state),
                clipFormData: selectSomeClipFormData(state, payload.clipId),
                clipData: apiSelectors.clips(state)[payload.clipId]
              })),
              tap(({ clipFormData, currentProject, projectType, clipData }) => {
                MixPanelUtils.track<'PROJECT__GENERATE_VIDEO_PREVIEW'>(
                  'Project Video - Generate Video Preview',
                  {
                    ...DataUtils.getProjectParam<'training_project'>('training_project', {
                      trainProject: currentProject
                    }),
                    video_duration: clipFormData?.id ?? 0,
                    keyframe_count: clipFormData?.keyframes?.length ?? 0,
                    frame_count: Math.round(
                      (clipFormData?.id ?? 0) * values.VIDEO_CLIP_PREVIEW_FRAME_RATE
                    ),
                    has_preview: Boolean(clipData.preview),
                    clip_id: payload.clipId,
                    clip_type: projectType ?? 'training_project'
                  }
                )
              }),
              map(() => mixVideoActions.clips.addFetchClipQueue(payload.clipId)),
              startWith(apiActions.engine.generateClipPreview(payload.clipId))
            )
          )
        )
      )
    )
  )

const updateClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        mixVideoActions.clips.updateTransition,
        mixVideoActions.clips.addKeyframe,
        mixVideoActions.clips.deleteKeyframe
      ])
    ),
    debounceTime(1500),
    mergeMap(({ payload, type }) =>
      action$.pipe(
        filter(isActionOf(apiActions.engine.updateClipResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({
          fetchQueue: selectFetchClipQueue(state),
          clipId: action.payload.id,
          activeTab: mixVideoSelectors.activeTab(state)
        })),
        map(param => {
          const transitionPayload =
            type === getType(mixVideoActions.clips.updateTransition)
              ? (payload as ActionType<typeof mixVideoActions.clips.updateTransition>['payload'])
              : undefined

          const update_keyframe_type: EventProperties['KEYFRAME_DATA']['update_keyframe_type'] =
            type === getType(mixVideoActions.clips.addKeyframe)
              ? 'add_keyframe'
              : type === getType(mixVideoActions.clips.deleteKeyframe)
                ? 'delete_keyframe'
                : transitionPayload?.transition?.ease_fn
                  ? 'update_transition_type'
                  : 'update_transition_time'

          const transition_type = transitionPayload?.transition?.ease_fn ?? undefined

          const add_keyframe_source =
            type === getType(mixVideoActions.clips.deleteKeyframe) ? param.activeTab : undefined

          return {
            ...param,
            update_keyframe_type,
            transition_type,
            add_keyframe_source
          }
        }),
        mergeMap(
          ({ clipId, fetchQueue, update_keyframe_type, transition_type, add_keyframe_source }) =>
            _compact([
              Boolean(fetchQueue.length) && mixVideoActions.clips.removeFetchClipQueue(clipId),
              mixVideoActions.logUpdateKeyframe({
                clip_id: clipId,
                update_keyframe_type,
                transition_type,
                add_keyframe_source
              })
            ])
        ),
        startWith(mixVideoActions.clips.saveClipData(payload.clipId))
      )
    )
  )

const updateClipNameEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.updateClipName)),
    map(({ payload }) => ({
      id: payload.clipId,
      name: payload.name ?? ''
    })),
    filter(({ name }) => Boolean(name)),
    mergeMap(param =>
      action$.pipe(
        filter(isActionOf(apiActions.engine.updateClipResponse)),
        take(1),
        mergeMap(() => [
          mixVideoActions.clips.setRenameClip({ clipId: undefined }),
          mixVideoActions.logUpdateClip({ clipId: param.id, updateType: 'rename' })
        ]),
        startWith(apiActions.engine.updateClip(param))
      )
    )
  )
const openClipEditorEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.openClipEditor)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectType: mixVideoSelectors.projectType(state),
      clipId: action.payload.clipId
    })),
    mergeMap(({ clipId, projectType }) => [
      mixVideoActions.clips.selectClip({ clipId }),
      mixVideoActions.setShowMixVideo(true),
      mixVideoActions.setActiveTab(projectType === 'latent_genre' ? 'mix_random' : 'train_result')
    ])
  )
const retrieveAfterSelectClipEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.selectClip)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      clipData: mixVideoSelectors.selectedClip(state)
    })),
    filter(({ clipData }) => !Boolean(clipData?.preview?.file)),
    filter(({ clipData }) => Boolean(clipData?.id)),
    map(({ clipData }) => apiActions.engine.retrieveClip(clipData?.id ?? 0))
  )

const saveClipDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.saveClipData)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      formData: selectSomeClipFormData(state, action.payload),
      clipId: action.payload
    })),
    filter(({ formData, clipId }) => Boolean(formData) && Boolean(clipId)),
    map(({ clipId, formData }) => ({
      formData: {
        id: clipId,
        transitions:
          formData?.transitions.map(transition => ({
            ...transition,
            seconds: roundTransitionTime(transition.seconds)
          })) ?? [],
        keyframes: _map(formData?.keyframes, data => data.id) ?? []
      },
      isClipValid:
        !formData?.isMaxedDuration && !formData?.isMaxedKeyframe && formData?.isHasEnoughKeyframe
    })),
    filter(({ isClipValid }) => Boolean(isClipValid)),
    mergeMap(({ formData }) =>
      action$.pipe(
        filter(isActionOf(apiActions.engine.updateClipResponse)),
        take(1),
        map(() => mixVideoActions.clips.setIsSaved({ clipId: formData.id, isSaved: true })),
        startWith(apiActions.engine.updateClip(formData))
      )
    )
  )

const deleteClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.deleteClip)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      clip: apiSelectors.clips(state)[action.payload.clipId],
      clipProjectParam: mixVideoSelectors.clipProjectParam(state)
    })),
    map(({ clip, clipProjectParam }) =>
      dialogActions.openDialog({
        [ConfirmationDialog.CONFIRMATION]: {
          dialogName: ConfirmationDialog.CONFIRMATION,
          content: `Are you sure you would like to delete clip ${clip.name}?`,
          yesAction: {
            label: 'DELETE',
            actions: [
              mixVideoActions.clips.executeDeleteClip({
                project: clipProjectParam,
                clip: clip.id
              }),
              mixVideoActions.logUpdateClip({ clipId: clip.id, updateType: 'delete' })
            ]
          }
        }
      })
    )
  )

const executeDeleteClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.executeDeleteClip)),
    mergeMap(({ payload }) =>
      action$.pipe(
        filter(isActionOf(apiActions.engine.deleteClipResponse)),
        take(1),
        map(() => mixVideoActions.clips.deleteActiveClip({ clipId: payload.clip })),
        startWith(apiActions.engine.deleteClip(payload))
      )
    )
  )

const downloadImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.downloadImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      currentProjectName: apiSelectors.currentProject(state).name,
      activeTab: mixVideoSelectors.activeTab(state),
      projectType: mixVideoSelectors.projectType(state),
      image: action.payload?.image,
      downloadLocation: action.payload.downloadLocation
    })),
    filter(({ image }) => Boolean(image?.id)),
    map(({ image, currentProjectName, activeTab, projectType, downloadLocation }) => {
      const fileName = `${currentProjectName}-${activeTab}-${image?.id}`
      const isFree = projectType === 'training_project' || TabWithFreeImage.includes(activeTab)

      return { isFree, image, fileName, downloadLocation }
    }),
    mergeMap(({ image, fileName, isFree, downloadLocation }) =>
      _compact([
        image && !isFree
          ? downloaderActions.single.downloadPaidUserImage({
              downloadLocation,
              userImage: image,
              fileName
            })
          : undefined,
        image && isFree
          ? downloaderActions.single.downloadSingleImage({ downloadLocation, image, fileName })
          : undefined
      ])
    )
  )

const duplicateClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixVideoActions.clips.duplicateClip)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      id: action.payload.clipId,
      name: `${action.payload.name} Copy`
    })),
    filter(({ id }) => Boolean(id)),
    mergeMap(param =>
      action$.pipe(
        filter(isActionOf(apiActions.engine.copyClipResponse)),
        take(1),
        mergeMap(action => [
          mixVideoActions.clips.selectClip({ clipId: action.payload.id }),
          mixVideoActions.logUpdateClip({ clipId: param.id, updateType: 'duplicate' })
        ]),
        startWith(apiActions.engine.copyClip(param))
      )
    )
  )

export const mixVideoEpics = combineEpics(
  logUpdateClipEpic,
  removeFetchClipQueueEpic,
  initMixVideoProjectEpic,
  logUpdateKeyframeEpic,
  listClipEpic,
  retrieveResultEpic,
  updateClipNameEpic,
  openClipEditorEpic,
  executeDeleteClipEpic,
  addFetchClipQueueEpic,
  selectClipEpic,
  retrieveAfterSelectClipEpic,
  retrieveClipListenerEpic,
  retrieveClipErrorListenerEpic,
  updateClipEpic,
  generatePreviewEpic,
  createClipEpic,
  selectClipEpic,
  saveClipDataEpic,
  duplicateClipEpic,
  deleteClipEpic,
  downloadImageEpic
)

export default reducer
