import React, { useCallback, useMemo, useState } from 'react'
import _debounce from 'lodash/debounce'
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { appSelectors } from 'duck/AppDuck'
import { RootActionType, RootState } from 'duck'
import { dialogActions, FormDialog } from 'duck/AppDuck/DialogDuck'
import Dialog from 'components/Dialog'
import { firebaseActions } from 'duck/FirebaseDuck'
import TextField from 'components/TextField'
import SentryUtils from 'utils/SentryUtils'
import Validator, { VALIDATION_MESSAGES } from 'utils/Validator'
import Button from 'components/Button'
import { ReduxProps } from 'utils/Types'

import styles from '../Dialogs.module.scss'

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      signInWithEmailLink: firebaseActions.signInWithEmailLink,
      closeDialog: dialogActions.closeDialog
    },
    dispatch
  )
type EmailForSignInLinkDialogProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const EmailForSignInLinkDialog: React.FC<EmailForSignInLinkDialogProps> = ({
  activeDialog,
  closeDialog,
  signInWithEmailLink
}) => {
  const showDialog = activeDialog === FormDialog.EMAIL_FOR_SIGNIN_LINK
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string | undefined>(undefined)

  const validate = useCallback(
    (value: string) => {
      const isEmptyError = Validator.isEmpty(value) ? VALIDATION_MESSAGES['required'] : undefined
      const emailError = !Validator.email(value) ? VALIDATION_MESSAGES['email'] : undefined

      setEmailError(isEmptyError || emailError)
    },
    [setEmailError]
  )
  const validateDebounced = useMemo(() => _debounce(validate, 400), [validate])

  const submitEmail = useCallback(() => {
    SentryUtils.captureMessage(
      `Please provide your email for confirmation`,
      {
        email
      },
      'log'
    )
    closeDialog()
    signInWithEmailLink({ email })
  }, [email, signInWithEmailLink, closeDialog])

  return (
    <Dialog
      open={showDialog}
      scroll="body"
      style={{
        zIndex: 1401
      }}
      hideDialogClose
      titleContent={<Typography variant="h6">Sign In To Playform</Typography>}
      actionContent={
        <Button
          disabled={Boolean(emailError) || !email}
          variant="contained"
          color="secondary"
          onClick={() => submitEmail()}
          fullWidth
        >
          SUBMIT EMAIL AND CONTINUE SIGN IN
        </Button>
      }
      contentDividerTop
      disableActionSpacing
    >
      <div className={styles.ContentGroup}>
        <Typography variant="body2">Please provide your email for confirmation.</Typography>
        <TextField
          className="mt-3"
          fullWidth
          placeholder="your email here"
          value={email}
          onChange={e => {
            setEmail(e.target.value)
            validateDebounced(e.target.value)
          }}
          error={Boolean(emailError)}
          helperText={emailError}
        />
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailForSignInLinkDialog)
