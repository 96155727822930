/**
 * Asynchronously loads the component for legacy Monetization Dialog
 */

import { useSelector } from 'react-redux'
import { RootState } from 'duck'
import { useBooleanDelayedData } from 'utils/Hooks'
import { appSelectors } from 'duck/AppDuck'
import loadable from 'utils/Loadable'
import { MonetizationDialogNameArray } from 'duck/AppDuck/DialogDuck'

const MonetizationDialog = loadable(
  () => import(/* webpackChunkName: "MonetizationDialog" */ './index')
)
const MonetizationDialogNameList = MonetizationDialogNameArray.map(value => value as string)

const MonetizationDialogContainer = () => {
  const activeDialog = useSelector((state: RootState) => appSelectors.dialog.activeDialog(state))
  const activeDialogOverlay = useSelector((state: RootState) =>
    appSelectors.dialog.activeDialogOverlay(state)
  )

  const open1 = activeDialog && MonetizationDialogNameList.includes(activeDialog)
  const open2 = activeDialogOverlay && MonetizationDialogNameList.includes(activeDialogOverlay)

  const open = useBooleanDelayedData(open1 || open2)

  if (!open) return null

  return <MonetizationDialog />
}

export default MonetizationDialogContainer
