import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const SquareRoundIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon className={clsx(className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M14 4H4V14H5V16H2V2H16V5H14V4Z" />
      <circle cx="15" cy="15" r="8" />
    </SvgIcon>
  )
}

export default SquareRoundIcon
