import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _pick from 'lodash/pick'
import { combineEpics, Epic } from 'redux-observable'
import {
  map,
  filter,
  concatMap,
  withLatestFrom,
  startWith,
  mergeMap,
  take,
  tap
} from 'rxjs/operators'

import { RootState, RootActionType } from 'duck'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { User } from 'models/ApiModels'
import Validator, { VALIDATION_MESSAGES, isHasErrors } from 'utils/Validator'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'

export type ProfileForm = Partial<{
  -readonly [P in keyof Pick<
    User,
    'first_name' | 'last_name' | 'is_agree_toc' | 'is_receive_news'
  >]-?: User[P]
}>

export type ProfileFromKey = keyof ProfileForm
export type ProfileFormInput = { key: ProfileFromKey; value?: string | boolean }

// Constants
const NAMESPACE = '@@page/WelcomePage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const actions = {
  setView: createAction(creator('SET_VIEW'))<ViewState>(),
  setSelectedStartProject: createAction(creator('SET_SELECTED_START_PROJECT'))<string>(),
  profile: {
    updateAll: createAction(creator('profile/UPDATE_ALL'))<User>(),
    change: createAction(creator('profile/CHANGE'))<ProfileFormInput>(),
    save: createAction(creator('profile/SAVE'))(),
    setError: createAction(creator('profile/SET_ERROR'))<ProfileFormInput>()
  }
}

// Selectors
const selectWelcomePage = (state: RootState) => state.container.welcomePage

export const selectors = {
  welcomePage: selectWelcomePage
}

// Reducer
export type ViewState =
  | 'form-name-panel'
  | 'start-project-panel'
  | 'phone-verification-panel'
  | 'creative-survey'

export type WelcomePageState = {
  view: ViewState
  profile: {
    formData: ProfileForm
    formError: ProfileForm
  }
}
const initial: WelcomePageState = {
  view: 'form-name-panel',
  profile: {
    formData: {
      first_name: '',
      last_name: '',
      is_agree_toc: undefined,
      is_receive_news: undefined
    },
    formError: {}
  }
}

const reducer = produce((state: WelcomePageState, { type, payload }) => {
  switch (type) {
    case getType(actions.setView): {
      const view = payload as ActionType<typeof actions.setView>['payload']

      state.view = view
      return
    }

    case getType(actions.profile.updateAll): {
      const user = payload as ActionType<typeof actions.profile.updateAll>['payload']

      state.profile.formData.first_name = user.first_name
      state.profile.formData.last_name = user.last_name
      state.profile.formData.is_agree_toc = user.is_agree_toc ? true : false
      state.profile.formData.is_receive_news = user.is_receive_news ? true : false
      state.profile.formError = initial.profile.formError
      return
    }
    case getType(actions.profile.change): {
      const { key, value } = payload as ActionType<typeof actions.profile.change>['payload']

      state.profile.formData = {
        ...state.profile.formData,
        [key]: value
      }
      state.profile.formError = {
        ...state.profile.formError,
        [key]: undefined
      }
      return
    }
    case getType(actions.profile.setError): {
      const { key, value } = payload as ActionType<typeof actions.profile.setError>['payload']

      state.profile.formError = {
        ...state.profile.formError,
        [key]: value
      }
      return
    }
    default:
  }
}, initial)

// Epics

const validateEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.profile.change)),
    map(({ payload }) => {
      const { key, value } = payload
      const validateActions: any[] = []

      switch (key) {
        case 'first_name':
          !Validator.required(value) &&
            validateActions.push(
              actions.profile.setError({ key: 'first_name', value: VALIDATION_MESSAGES.required })
            )
          break
        case 'is_agree_toc':
          !Boolean(value) &&
            validateActions.push(
              actions.profile.setError({ key: 'is_agree_toc', value: VALIDATION_MESSAGES.required })
            )
          break
        default:
          break
      }

      return validateActions
    }),
    filter(validateActions => Boolean(validateActions.length)),
    concatMap(validateActions => validateActions)
  )

const saveProfileEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.profile.save)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      ...selectors.welcomePage(state).profile,
      userData: apiSelectors.user(state),
      firebaseData: apiSelectors.firebaseUser(state)
    })),
    filter(({ formError }) => !isHasErrors(formError)),
    map(({ formData, userData, firebaseData }) => {
      return {
        userData,
        firebaseData,
        data: {
          ..._pick(formData, ['first_name', 'last_name']),
          is_agree_toc: Boolean(formData.is_agree_toc),
          is_receive_news: Boolean(formData.is_agree_toc) //News is active by default
        }
      }
    }),
    mergeMap(({ data, userData, firebaseData }) =>
      action$.pipe(
        filter(isActionOf(apiActions.users.updateResponse)),
        take(1),
        withLatestFrom(state$),
        tap(() => {
          MixPanelUtils.track<'USER__UPDATE_PROFILE'>('User - Update Profile', {
            updated_forms: DataUtils.getObjectKeys(data)
          })
        }),
        map(() => actions.setView('phone-verification-panel')),

        // This one is executed first, and code above is listening on project retrieving finished.
        startWith(apiActions.users.update(data))
      )
    )
  )

export const epics = combineEpics(saveProfileEpic, validateEpic)
export default reducer
