import Konva from 'konva/lib/Core'
import { AnimationStateManagerType, gridUtils, GridStateManagerType } from './utils'
import { Listeners, CanvasBuilderProps } from './CanvasBuilder'
import _throttle from 'lodash/throttle'
import { KonvaEventObject } from 'konva/lib/Node'
import { VectorUtils } from 'utils/math'

const INITIAL_START_POSITION = { clientX: 0, clientY: 0 }

export type GridCanvasProps = Pick<CanvasBuilderProps, 'layer' | 'stage' | 'debugMode'> & {
  listeners: Listeners
  gridState: GridStateManagerType
  animationState: AnimationStateManagerType
}

const Base = (props: GridCanvasProps) => {
  const { listeners, layer, gridState, stage, animationState, debugMode } = props
  Konva.hitOnDragEnabled = true
  let lastDist = 0

  const baseGroup = new Konva.Group({
    x: 0,
    y: 0
  })

  stage.draggable(true)
  stage.dragBoundFunc(function (pos) {
    return {
      x: stage.position().y,
      y: stage.position().y
    }
  })
  const cameraText = new Konva.Text({
    x: 15,
    y: 15,
    fontSize: 15,
    fontFamily: 'Calibri',
    fill: 'green'
  })
  const zoomText = new Konva.Text({
    x: 15,
    y: 60,
    text: 'Zoom',
    fontSize: 15,
    fontFamily: 'Calibri',
    fill: 'green'
  })

  //Drag
  let startPosition = INITIAL_START_POSITION

  stage.on('dragstart', (e: any) => {
    const [clientX, clientY] = gridUtils.getClientXY(e.evt)
    startPosition = { clientX, clientY }
    animationState.resetCameraSpeed()
  })

  stage.on(
    'dragmove',
    _throttle((e: any) => {
      const [clientX, clientY] = gridUtils.getClientXY(e.evt)
      const deltaPos = {
        x: clientX - startPosition.clientX,
        y: clientY - startPosition.clientY
      }
      listeners.dragMoveListener?.(deltaPos)
      startPosition = { clientX, clientY }
    }, 30)
  )

  stage.on('click tap', (e: KonvaEventObject<MouseEvent>) => {
    const [clientX, clientY] = gridUtils.getClientXY(e.evt)
    listeners.clickTapListener?.({ x: clientX, y: clientY })
  })

  // stage.on('dblclick dbltap', (e: any) => {
  //   window.clearTimeout(clickTimeout)
  //   const currentZoom = gridState.zoom()
  //   const newZoom = currentZoom + currentZoom * 0.1
  //   const zoomCenter = {
  //     x: stage?.getPointerPosition()?.x || 0,
  //     y: stage?.getPointerPosition()?.y || 0
  //   }
  //   const newCameraPos = gridUtils.getCameraPosAfterZoom({
  //     zoomCenter: zoomCenter,
  //     newZoom,
  //     oldZoom: currentZoom,
  //     camera: gridState.camera()
  //   })

  //   listeners.zoomListener?.(newZoom, newCameraPos)
  // })

  stage.on('mousedown touchstart tap', function () {
    animationState.resetCameraSpeed()
  })
  stage.on('dragend', function (e) {
    if (lastDist === 0) {
      animationState.triggerMomentum()
    }
  })
  stage.on(
    'touchmove',
    _throttle((e: any) => {
      e.evt.preventDefault()
      const touch1 = e.evt?.touches?.[0]
      const touch2 = e.evt?.touches?.[1]

      if (touch1 && touch2) {
        animationState.resetCameraSpeed()
        let dist = VectorUtils.getDistance(
          { x: touch1.screenX, y: touch1.screenY },
          { x: touch2.screenX, y: touch2.screenY }
        )

        if (lastDist) {
          const currentZoom = gridState.zoom()

          const newZoom = (currentZoom * dist) / lastDist
          const zoomCenter = {
            x: touch1.clientX || 0,
            y: touch1.clientY || 0
          }
          const newCameraPos = gridUtils.getCameraPosAfterZoom({
            zoomCenter,
            newZoom,
            oldZoom: currentZoom,
            camera: gridState.camera()
          })
          listeners.zoomListener?.(newZoom, newCameraPos)
        }

        lastDist = dist
      }
    }, 30)
  )

  stage.on('touchend', function () {
    animationState.resetCameraSpeed()
    lastDist = 0
  })

  const scaleBy = 1.03
  stage.on('wheel', (e: any) => {
    e.evt.preventDefault()
    animationState.resetCameraSpeed()

    const currentZoom = gridState.zoom()
    const currentCamera = gridState.camera()

    const newZoom = e.evt.deltaY < 0 ? currentZoom * scaleBy : currentZoom / scaleBy

    const zoomCenter = {
      x: stage?.getPointerPosition()?.x || 0,
      y: stage?.getPointerPosition()?.y || 0
    }
    const newCameraPos = gridUtils.getCameraPosAfterZoom({
      zoomCenter,
      newZoom,
      oldZoom: currentZoom,
      camera: currentCamera
    })
    listeners.zoomListener?.(newZoom, newCameraPos)
  })

  if (debugMode) {
    baseGroup.add(cameraText)
    baseGroup.add(zoomText)
  }

  return {
    get: () => {
      return baseGroup
    },
    draw: (noDrawLayer?: boolean) => {
      const zoom = gridState.zoom()
      const camera = gridState.camera()

      zoomText.text(`zoom: ${zoom}`)
      cameraText.text(`Camera x: ${camera.x},  y:${camera.y}`)
      !noDrawLayer && layer.batchDraw()
    }
  }
}

export default Base
