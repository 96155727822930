import { RootState } from 'duck'
import { GenericAppOutputImage } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { adjustedImages, userImages } from './CommonSelectors'

/* User related data */

const genericApp = (state: RootState) => {
  return state.api?.genericApp
}

const genericAppData = createSelector(genericApp, value => value.genericApp.data)
const genericAppDataList = createSelector(genericApp, value =>
  value.genericApp.list.map(id => value.genericApp.data?.[id] || {})
)

const hasGenericAppDataList = createSelector(genericAppDataList, value => !!value?.length)

const currentGenericAppProjectId = createSelector(genericApp, value => {
  return value.currentGenericApp
})

const genericAppProjects = createSelector(genericApp, value => {
  return value.genericAppProject.data
})

const currentGenericAppProjectRaw = createSelector(
  currentGenericAppProjectId,
  genericAppProjects,
  (id, data) => (id ? data[id] : undefined)
)

const genericAppProjectListId = createSelector(genericApp, genericApp => {
  return genericApp.genericAppProject.list
})

const genericAppProjectList = createSelector(
  genericAppProjects,
  genericAppProjectListId,
  (genericAppProjects, genericAppProjectListId) =>
    genericAppProjectListId.map(id => genericAppProjects?.[id] || {})
)

const outputLists = createSelector(genericApp, genericApp => genericApp.outputLists)
const genericAppProjectOutputs = createSelector(genericApp, genericApp => genericApp.outputs)

const currentGenericAppProjectOutputList = createSelector(
  outputLists,
  currentGenericAppProjectId,
  (outputList, currentGenericAppProjectId) => {
    return currentGenericAppProjectId ? outputList[currentGenericAppProjectId] : undefined
  }
)

const currentGenericAppProjectOutputCount = createSelector(
  currentGenericAppProjectOutputList,
  currentOutputList => currentOutputList?.lastListReq?.count
)

const currentGenericAppProject = createSelector(
  currentGenericAppProjectRaw,
  currentGenericAppProjectOutputList,
  userImages,
  genericAppProjectOutputs,
  adjustedImages,
  genericAppData,
  genericApp,
  (
    currentGenericAppProjectRaw,
    currentGenericAppProjectOutputList,
    userImages,
    genericAppProjectOutputs,
    adjustedImages,
    genericAppData
  ) => {
    if (!currentGenericAppProjectRaw) return undefined

    const currentOutputIdList = currentGenericAppProjectOutputList?.list ?? []
    const outputs: GenericAppOutputImage[] = currentOutputIdList?.map((currentOutputId: number) => {
      const currentOutput = genericAppProjectOutputs[currentOutputId]
      const imageId = currentOutput?.image?.id
      const image = userImages[imageId] ?? currentOutput?.image

      return {
        ...currentOutput,
        image: {
          ...image,
          adjustData: adjustedImages[image.adjust ?? 0]
        },
        bookmark: userImages[imageId]?.bookmark ?? 0
      }
    })

    const projectAppId = currentGenericAppProjectRaw.project_app

    return {
      ...currentGenericAppProjectRaw,
      project_app_data: { ...genericAppData[projectAppId] },
      outputs
    }
  }
)

const currentGenericAppProjectHasResult = createSelector(
  currentGenericAppProject,
  currentProject => {
    return Boolean(currentProject?.outputs?.length)
  }
)

const isCurrentGenericAppOutputHasNext = createSelector(
  currentGenericAppProjectOutputList,
  currentGenericAppProjectOutputList =>
    Boolean(currentGenericAppProjectOutputList?.lastListReq?.next)
)

const genericAppSelectors = {
  hasGenericAppDataList,
  genericAppProjects,
  genericAppDataList,
  currentGenericAppProjectId,
  genericAppProjectList,
  genericAppProjectOutputs,
  currentGenericAppProjectOutputList,
  currentGenericAppProjectOutputCount,
  currentGenericAppProject,
  currentGenericAppProjectHasResult,
  genericApp,
  isCurrentGenericAppOutputHasNext
}

export default genericAppSelectors
