import { RootState } from 'duck'
import { SketchTextGenre, SketchTextOutputImage } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { adjustedImages, userImages } from './CommonSelectors'
import _reduce from 'lodash/reduce'

/* User related data */

const sketchTextToImage = (state: RootState) => {
  return state.api?.sketchTextToImage
}

const currentSketchTextToImageProjectId = createSelector(
  sketchTextToImage,
  value => value.currentSketchTextProject
)
const sketchTextToImageProjectsData = createSelector(
  sketchTextToImage,
  value => value.sketchTextProjects.data
)

const sketchTextProcess = createSelector(
  sketchTextToImage,
  value => value.sketchTextProcess?.results
)

const hasSketchTextProcess = createSelector(sketchTextProcess, sketchTextProcess =>
  Boolean(sketchTextProcess?.length)
)

const sketchTextModels = createSelector(sketchTextToImage, value => value.sketchTextModel?.results)

const hasSketchTextModel = createSelector(sketchTextModels, sketchTextModels =>
  Boolean(sketchTextModels?.length)
)

const sketchTextStyles = createSelector(sketchTextToImage, value => value.sketchTextStyles?.results)

const hasSketchTextStyles = createSelector(sketchTextStyles, sketchTextStyles =>
  Boolean(sketchTextStyles?.length)
)

const currentSketchTextToImageProjectRaw = createSelector(
  currentSketchTextToImageProjectId,
  sketchTextToImageProjectsData,
  (id, data) => (id ? data[id] : undefined)
)

const sketchTextToImageProjects = createSelector(
  sketchTextToImage,
  sketchTextToImage => sketchTextToImage.sketchTextProjects.data
)
const sketchTextToImageProjectListId = createSelector(
  sketchTextToImage,
  sketchTextToImage => sketchTextToImage.sketchTextProjects.list
)

const sketchTextToImageProjectList = createSelector(
  sketchTextToImageProjects,
  sketchTextToImageProjectListId,
  (sketchTextToImageProjects, sketchTextToImageProjectListId) =>
    sketchTextToImageProjectListId.map(id => sketchTextToImageProjects?.[id] || {})
)
const outputLists = createSelector(
  sketchTextToImage,
  sketchTextToImage => sketchTextToImage.outputLists
)
const sketchTextToImageOutputs = createSelector(
  sketchTextToImage,
  sketchTextToImage => sketchTextToImage.outputs
)

const currentSketchTextToImageProjectOutputList = createSelector(
  outputLists,
  currentSketchTextToImageProjectId,
  (outputList, currentSketchTextToImageProjectId) =>
    currentSketchTextToImageProjectId ? outputList[currentSketchTextToImageProjectId] : undefined
)

const currentSketchTextToImageProjectOutputCount = createSelector(
  currentSketchTextToImageProjectOutputList,
  currentOutputList => currentOutputList?.lastListReq?.count
)

const currentSketchTextToImageProject = createSelector(
  currentSketchTextToImageProjectRaw,
  currentSketchTextToImageProjectOutputList,
  userImages,
  sketchTextToImageOutputs,
  adjustedImages,
  (
    currentSketchTextToImageProjectRaw,
    currentSketchTextToImageProjectOutputList,
    userImages,
    textToImageOutputs,
    adjustedImages
  ) => {
    const currentOutputs = currentSketchTextToImageProjectOutputList?.list ?? []
    const outputs: SketchTextOutputImage[] = currentOutputs?.map(sketchOutputId => {
      const currentOutput = textToImageOutputs[sketchOutputId]
      const imageId = currentOutput?.image?.id ?? ''
      const image = userImages[imageId] ?? currentOutput.image

      return {
        ...currentOutput,
        image: {
          ...image,
          adjustData: adjustedImages[image.adjust ?? 0]
        },
        bookmark: userImages[imageId]?.bookmark ?? 0
      }
    })

    return currentSketchTextToImageProjectRaw
      ? { ...currentSketchTextToImageProjectRaw, outputs }
      : undefined
  }
)

const isCurrentSketchTextToImageOutputHasNext = createSelector(
  currentSketchTextToImageProjectOutputList,
  currentSketchTextToImageProjectOutputList =>
    Boolean(currentSketchTextToImageProjectOutputList?.lastListReq?.next)
)

const sketchTextGenres = createSelector(
  sketchTextToImage,
  sketchTextToImage => sketchTextToImage.sketchTextGenres?.results ?? []
)
/* Object key version */
const initial: { [id: number]: SketchTextGenre } = {}
const sketchTextGenreData = createSelector(sketchTextGenres, sketchTextGenres =>
  _reduce(
    sketchTextGenres,
    (result, value) => {
      result[value.id] = value
      return result
    },
    initial
  )
)
const hasSketchTextGenres = createSelector(sketchTextGenres, sketchTextGenres =>
  Boolean(sketchTextGenres.length)
)

const sketchTextToImageSelectors = {
  hasSketchTextProcess,
  hasSketchTextModel,
  sketchTextProcess,
  sketchTextModels,
  hasSketchTextGenres,
  sketchTextGenreData,
  sketchTextGenres,
  currentSketchTextToImageProjectId,
  sketchTextToImageProjectList,
  sketchTextToImageOutputs,
  currentSketchTextToImageProjectOutputList,
  currentSketchTextToImageProjectOutputCount,
  currentSketchTextToImageProject,
  sketchTextStyles,
  hasSketchTextStyles,
  sketchTextToImage,
  sketchTextToImageProjects,
  isCurrentSketchTextToImageOutputHasNext
}

export default sketchTextToImageSelectors
