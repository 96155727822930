import { apiActions, ProArtFilterActionType } from '../actions'
import { SharedActionsType } from '../sharedActions'
import {
  ProArtFilterGenreListResponse,
  ProArtFilterProject,
  ProArtFilterListResponse,
  ProArtFilterStyleListResponse,
  ProArtFilterOutputListResponse,
  ProArtFilterOutput,
  PaginationResponse,
  ProArtFilterBanner
} from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _without from 'lodash/without'
import _uniq from 'lodash/uniq'

export type ProArtFilterState = {
  currentProArtFilterId?: number
  proArtFilterGenres: ProArtFilterGenreListResponse | undefined
  proArtFilterStyles: ProArtFilterStyleListResponse | undefined
  proArtFilterProjects: {
    list: number[]
    data: {
      [id: number]: ProArtFilterProject
    }
    lastReq?: ProArtFilterListResponse
  }
  outputLists: {
    [proArtFilterProjectId: string]: {
      lastListReq: ProArtFilterOutputListResponse
      list: number[]
    }
  }
  outputs: { [proArtFilterOutput: number]: ProArtFilterOutput }
  proArtFilterBanner: PaginationResponse<ProArtFilterBanner> | undefined
}

export const INITIAL_STYLIZE_STATE: ProArtFilterState = {
  currentProArtFilterId: undefined,
  proArtFilterGenres: undefined,
  proArtFilterStyles: undefined,
  proArtFilterProjects: {
    list: [],
    data: {},
    lastReq: undefined
  },
  outputLists: {},
  outputs: {},
  proArtFilterBanner: undefined
}

export const proArtFilterReducer = createReducer<
  ProArtFilterState,
  ProArtFilterActionType | SharedActionsType
>(INITIAL_STYLIZE_STATE)
  .handleAction(apiActions.proArtFilter.setCurrentProArtFilterProject, (state, action) => {
    const id = action?.payload
    return {
      ...state,
      currentProArtFilterId: id
    }
  })
  .handleAction(apiActions.proArtFilter.listProArtFilterResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.proArtFilterProjects.list : []

    return {
      ...state,
      proArtFilterProjects: {
        list: _uniq([...prevData, ..._map(results, result => result.id)]),
        data: {
          ...state.proArtFilterProjects.data,
          ..._keyBy(results, result => result.id)
        },
        lastReq: data
      }
    }
  })

  .handleAction(apiActions.proArtFilter.createProArtFilterResponse, (state, action) => {
    const proArtFilterProject = action.payload
    const prevData = state.proArtFilterProjects.list ?? []

    return {
      ...state,
      proArtFilterProjects: {
        ...state.proArtFilterProjects,
        list: _uniq([proArtFilterProject.id, ...prevData]),
        data: {
          ...state.proArtFilterProjects.data,
          [proArtFilterProject.id]: proArtFilterProject
        }
      }
    }
  })
  .handleAction(apiActions.proArtFilter.deleteProArtFilterResponse, (state, action) => {
    const deletedId = action.payload
    const { [deletedId]: deletedProject, ...data } = state.proArtFilterProjects.data
    const list = _without(state.proArtFilterProjects.list || [], deletedId)

    return {
      ...state,
      proArtFilterProjects: {
        ...state.proArtFilterProjects,
        list,
        data
      }
    }
  })
  .handleAction(apiActions.proArtFilter.listProArtFilterGenresResponse, (state, action) => {
    return {
      ...state,
      proArtFilterGenres: action.payload
    }
  })
  .handleAction(apiActions.proArtFilter.listProArtFilterStylesResponse, (state, action) => {
    return {
      ...state,
      proArtFilterStyles: {
        ...action.payload,
        results: action.payload.results
      }
    }
  })
  .handleAction(apiActions.proArtFilter.deleteProArtFilterOutputResponse, (state, action) => {
    const projectId = state.currentProArtFilterId
    const deletedOutputId = action.payload
    const currentList = state.outputLists[projectId ?? 0].list ?? []
    const newList = _without(currentList, deletedOutputId)

    return projectId
      ? {
          ...state,
          outputLists: {
            ...state.outputLists,
            [projectId]: {
              ...state.outputLists[projectId],
              list: newList
            }
          }
        }
      : state
  })
  .handleAction(apiActions.proArtFilter.cleanOutputsResponse, (state, action) => {
    const projectId = state.currentProArtFilterId

    return projectId
      ? {
          ...state,
          outputLists: {
            ...state.outputLists,
            [projectId]: {
              ...state,
              list: []
            }
          }
        }
      : state
  })
  .handleAction(apiActions.proArtFilter.listProArtFilterOutputResponse, (state, action) => {
    const { data, next, req } = action.payload

    const projectId = req?.project || 0
    const results = data?.results ?? []
    const prevData = next ? state.outputLists[projectId]?.list || [] : []

    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [projectId]: {
          lastListReq: data,
          list: _uniq([...prevData, ..._map(results, result => result.id)])
        }
      },
      outputs: {
        ...state.outputs,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(apiActions.proArtFilter.resetProArtFilterQueueData, (state, action) => {
    const payload = action.payload
    const currentProject = state.proArtFilterProjects.data[payload]
    return currentProject
      ? {
          ...state,
          proArtFilterProjects: {
            ...state.proArtFilterProjects,
            data: {
              ...state.proArtFilterProjects.data,
              [payload]: {
                ...currentProject,
                queue_length: undefined,
                expect_finished: undefined
              }
            }
          }
        }
      : state
  })
  .handleAction(
    [
      apiActions.proArtFilter.updateProArtFilterResponse,
      apiActions.proArtFilter.generateProArtFilterResponse,
      apiActions.proArtFilter.retrieveProArtFilterResponse,
      apiActions.proArtFilter.uploadProArtFilterContentResponse
    ],
    (state, action) => {
      const proArtFilterProject = action.payload
      const currentData = state.proArtFilterProjects.data[proArtFilterProject.id]
      const combinedData = currentData
        ? { ...currentData, ...proArtFilterProject }
        : proArtFilterProject

      return {
        ...state,
        proArtFilterProjects: {
          ...state.proArtFilterProjects,
          data: {
            ...state.proArtFilterProjects.data,
            [proArtFilterProject.id]: combinedData
          }
        }
      }
    }
  )
  .handleAction(apiActions.proArtFilter.listProArtFilterBannerResponse, (state, action) => {
    return {
      ...state,
      proArtFilterBanner: action.payload.data
    }
  })
