import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  switchMap,
  withLatestFrom,
  mergeMap,
  tap,
  delay,
  exhaustMap
} from 'rxjs/operators'

import { ApiUtils } from 'utils'
import { Epic, combineEpics } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import _reduce from 'lodash/reduce'
import _compact from 'lodash/compact'
import { of, merge } from 'rxjs'
import { ListMineProjectReq, PermissionsType, PermissionType } from 'models/ApiModels'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { appSelectors } from 'duck/AppDuck'
import socialEpics from './SocialEpics'
import paymentEpics from './PaymentEpics'
import textToImageEpics from './TextToImageEpics'
import projectEpics from './ProjectEpics'
import collectionEpics from './CollectionEpics'
import sketchToImageEpics from './SketchToImageEpics'
import mixImageEpics from './MixImageEpics'
import engineEpics from './EngineEpics'
import { firebaseSelectors } from 'duck/FirebaseDuck'
import MixPanelUtils from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import proArtFilterEpic from './ProArtFilterEpics'
import { ERROR_4XX_ITEMS } from 'duck/AppDuck/ErrorHandlerDuck'
import imageEnhancementEpics from './ImageEnhancementEpics'
import { SeverityLevel } from '@sentry/react'
import genericAppEpics from './GenericAppEpics'
import sketchTextToImageEpics from './SketchTextToImageEpics'

export const AUTOSAVE_INTERVAL = 500
const PERMISSION_INIT: PermissionsType = {}

const retrieveUserEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.retrieve)),
    withLatestFrom(store),
    filter(([, state]) => state.firebase.firebaseUser !== null),
    switchMap(([{ type, payload }, state]) =>
      merge(
        of(payload).pipe(
          mergeMap(() =>
            _compact([
              payload.uid && sharedActions.setLoading({ loading: true, type }),
              !payload.uid && sharedActions.setLoading({ loading: false, type })
            ])
          )
        ),
        of(payload).pipe(
          filter(payload => Boolean(payload.uid)),
          mergeMap(() =>
            ApiUtils.users.retrieve(payload.uid).pipe(
              tap(({ data }) => {
                if (payload.isFirstTime) {
                  SentryUtils.setUser({
                    id: data.id,
                    username: `${data.first_name} ${data.last_name}`,
                    email: data.email
                  })
                  MixPanelUtils.identify(data.id)
                  MixPanelUtils.track<'USER__AUTHENTICATE'>('User - Authenticate')
                  MixPanelUtils.setPeople({
                    first_name: data.first_name,
                    last_name: data.last_name,
                    portfolio: data.portfolio,
                    $phone: data.phone,
                    $email: data.email
                  })
                  MixPanelUtils.updatePeople({
                    sign_up_date: state.firebase.firebaseUser?.creationTime,
                    last_login_date: state.firebase.firebaseUser?.lastSignInTime
                  })
                }
              }),
              concatMap(response => [
                apiActions.users.retrieveResponse(response.data),
                sharedActions.setLoading({
                  loading: false,
                  type
                })
              ]),
              catchError(err =>
                of(
                  sharedActions.setError({
                    error: err.response,
                    type,
                    req: payload
                  })
                )
              )
            )
          )
        )
      )
    )
  )

const retrievePermissionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.retrievePermission)),
    withLatestFrom(store),
    filter(([_, state]) => state.firebase.firebaseUser !== null),
    switchMap(([{ type }]) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.permission().pipe(
          //Convert from array to object key, for easy access
          map(response =>
            _reduce(
              response.data,
              (result, value) => {
                const castedValue = value as PermissionType
                result[castedValue] = true
                return result
              },
              PERMISSION_INIT
            )
          ),
          concatMap(data => [
            apiActions.users.retrievePermissionResponse(data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type
              })
            )
          )
        )
      )
    )
  )

const updateAliasEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.updateAlias)),
    withLatestFrom(store),
    filter(([, state]) => appSelectors.isAuthorized(state)),
    switchMap(([{ type, payload }, state]) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.updateAlias(state.firebase.firebaseUser?.uid ?? '', payload).pipe(
          concatMap(response => [
            apiActions.users.updateResponse(response.data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const uploadUserPictureEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.uploadUserPicture)),
    withLatestFrom(store),
    filter(([, state]) => appSelectors.isAuthorized(state)),
    map(([action, state]) => {
      const formData = new FormData()

      action.payload.forEach(value => {
        formData.append(value.type, value.file)
      })

      return {
        formData,
        type: action.type,
        payload: action.payload,
        uid: state.firebase.firebaseUser?.uid ?? ''
      }
    }),
    switchMap(({ formData, payload, uid, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.uploadUserPicture(uid, formData).pipe(
          concatMap(response => [
            apiActions.users.updateResponse(response.data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateUserEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.update)),
    debounceTime(AUTOSAVE_INTERVAL),
    withLatestFrom(store),
    filter(([, state]) => appSelectors.isAuthorized(state)),
    switchMap(([{ type, payload }, state]) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.update(state.firebase.firebaseUser?.uid ?? '', payload).pipe(
          tap(({ data }) => {
            MixPanelUtils.updatePeople({
              first_name: data?.first_name,
              last_name: data?.last_name,
              portfolio: data?.portfolio
            })
          }),
          concatMap(response => [
            apiActions.users.updateResponse(response.data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const submitPresignupEmailEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.submitPresignupEmail)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.submitPresignupEmail(payload).pipe(
          map(() =>
            sharedActions.setLoading({
              loading: false,
              type
            })
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateUiExtras: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.updateUiExtras)),
    withLatestFrom(store),
    map(([action, state]) => ({
      user: apiSelectors.user(state),
      firebaseUid: firebaseSelectors.currentUserFirebaseUid(state) ?? '',
      ...action
    })),
    filter(({ user, firebaseUid }) => Boolean(user) && Boolean(firebaseUid)),
    map(({ user, ...rest }) => ({ ui_extras: user?.ui_extras ?? {}, ...rest })),
    switchMap(({ ui_extras, type, payload, firebaseUid }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.update(firebaseUid, { ui_extras: { ...ui_extras, ...payload } }).pipe(
          tap(response => {
            const uiExtras = response.data.ui_extras ?? {}
            MixPanelUtils.updatePeople(uiExtras)
          }),
          concatMap(response => [
            apiActions.users.updateResponse(response.data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const sendQuestionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.sendQuestion)),
    switchMap(({ payload, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.sendQuestion(payload).pipe(
          concatMap(response => [
            apiActions.users.sendQuestionResponse(response.data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const checkPhoneUsedEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.checkPhoneUsed)),
    switchMap(({ payload, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.checkPhoneUsed(payload).pipe(
          concatMap(response => [
            apiActions.users.checkPhoneUsedResponse(response.data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const updatePhoneEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.updatePhone)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.updatePhone(undefined).pipe(
          concatMap(response => [
            apiActions.users.updatePhoneResponse(response.data),
            sharedActions.setLoading({
              loading: false,
              type
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveEquityEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.retrieveEquity)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      equityRetrieved: apiSelectors.equityRetrieved(state)
    })),
    switchMap(({ type, equityRetrieved }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.retrieveEquity().pipe(
          tap(({ data }) => {
            !equityRetrieved &&
              MixPanelUtils.updatePeople({
                subscription: data.type,
                subscription_interval: data.interval,
                subscription_canceled: data.canceled,
                subscription_expire: data.expire,
                storage_usage: data.storage_usage,
                pre_train_limit: data.pre_train_limit,
                pre_train_usage: data.pre_train_usage
              })
          }),
          mergeMap(response => [
            apiActions.users.retrieveEquityResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveEquityConfigEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.retrieveEquityConfig)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.retrieveEquityConfig().pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.users.retrieveEquityConfigResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveConfigVariableEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.retrieveConfigVariable)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.retrieveConfigVariable().pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.users.retrieveConfigVariableResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listNotificationEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.listNotification)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, silentUpdate } = action.payload
      const nextData = state.api.users.notification.lastReq?.next

      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      return { next, params, extraParams, type: action.type, silentUpdate, param }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    delay(300),
    exhaustMap(({ next, params, extraParams, type, silentUpdate, param }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.users.listNotification(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.users.listNotificationResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const retrieveNotification: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.retrieveNotification)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.retrieveNotification(payload).pipe(
          mergeMap(response => [
            apiActions.users.retrieveNotificationResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const deleteNotificationEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.deleteNotification)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.deleteNotification(payload).pipe(
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.users.deleteNotificationResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const updateNotificationEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.users.updateNotification)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.updateNotification(payload.id, payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.users.updateNotificationResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveUnreadNotificationCountEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.retrieveUnreadNotificationCount)),
    map(({ type }) => ({
      type,
      param: { next: undefined },
      extraParam: {
        limit: 10,
        offset: 0,
        read: false
      }
    })),
    switchMap(({ type, param, extraParam }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.listNotification(param, extraParam).pipe(
          mergeMap(response => [
            apiActions.users.retrieveUnreadNotificationCountResponse(response.data.count),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: extraParam }))
          )
        )
      )
    )
  )

const listNotificationTemplateEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.listNotificationTemplate)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.listNotificationTemplate().pipe(
          mergeMap(response => [
            apiActions.users.listNotificationTemplateResponse({ data: response.data }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listNotificationPreferencesEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.listNotificationPreferences)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.listNotificationPreferences().pipe(
          mergeMap(response => [
            apiActions.users.listNotificationPreferencesResponse({ data: response.data }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const createNotificationPreferencesEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.createNotificationPreferences)),
    concatMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.createNotificationPreferences(payload).pipe(
          mergeMap(response => [
            apiActions.users.createNotificationPreferencesResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const updateNotificationPreferencesEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.users.updateNotificationPreferences)),
    concatMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.users.updateNotificationPreferences(payload.req.id, payload.req).pipe(
          mergeMap(response => [
            apiActions.users.updateNotificationPreferencesResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const userEpics = [
  uploadUserPictureEpic,
  updateAliasEpic,
  updateNotificationPreferencesEpic,
  createNotificationPreferencesEpic,
  createNotificationPreferencesEpic,
  listNotificationPreferencesEpic,
  listNotificationTemplateEpic,
  retrieveUnreadNotificationCountEpic,
  updateNotificationEpic,
  deleteNotificationEpic,
  retrieveNotification,
  listNotificationEpic,
  retrieveConfigVariableEpic,
  submitPresignupEmailEpic,
  retrieveUserEpic,
  checkPhoneUsedEpic,
  updatePhoneEpic,
  updateUiExtras,
  updateUserEpic,
  retrievePermissionEpic,
  sendQuestionEpic,
  retrieveEquityEpic,
  retrieveEquityConfigEpic
]

const retrieveInputsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.inputs.retrieve)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.inputs.retrieve(action.payload).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.inputs.retrieveResponse(response.data)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const updateInputsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.inputs.update)),
    mergeMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.inputs.update(action.payload.id, action.payload).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.inputs.updateResponse(response.data)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const moveCollectionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.inputs.moveCollection)),
    switchMap(action =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.inputs.moveCollection(action.payload.sourceId, action.payload).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            apiActions.inputs.moveCollectionResponse(response.data)
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: action.payload
              })
            )
          )
        )
      )
    )
  )

const inputsEpic = [moveCollectionEpic, updateInputsEpic, retrieveInputsEpic]

const listenErrorEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    tap(({ payload }) => {
      const error = payload.error
      const firebaseError = payload.firebaseError
      if (error) {
        const severity: SeverityLevel = error.status >= 500 ? 'fatal' : 'info'
        const logError = !ERROR_4XX_ITEMS.includes(error.status)
        logError &&
          SentryUtils.captureMessage(
            `Api Error ${error?.status} ${payload.type}`,
            {
              status: error.status,
              statusText: error.statusText,
              request: error.request,
              header: error.headers,
              config: error.config,
              data: error.data,
              requestData: payload.req && JSON.stringify(payload.req),
              metadata: payload.metadata && JSON.stringify(payload.metadata)
            },
            severity
          )
      }
      if (firebaseError) {
        SentryUtils.captureMessage(
          `${firebaseError?.code} ${firebaseError?.message}`,
          {
            name: firebaseError.name,
            statusText: firebaseError.stack,
            message: firebaseError.message
          },
          'error'
        )
      }
    }),
    map(({ payload }) =>
      sharedActions.setLoading({
        loading: false,
        type: payload.type
      })
    )
  )

const listenCancelRequestEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.cancelRequest)),
    map(({ payload }) =>
      sharedActions.setLoading({
        loading: false,
        type: payload
      })
    )
  )

const sharedEpic = [listenErrorEpic, listenCancelRequestEpic]

const listUserArtmineProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.artMine.listUserArtmineProjects)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const nextData = state.api.artMine.mineProject.lastReq?.next
      const userId = apiSelectors.user(state)?.id
      const paramAdjusted: ListMineProjectReq = { ...param, user: userId }

      const params = next ? { next: nextData } : {}
      const extraParams = next ? undefined : param
      return { next, params, extraParams, type: action.type, paramAdjusted }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    delay(300),
    exhaustMap(({ next, params, extraParams, type, paramAdjusted }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: type })),
        ApiUtils.artMine.listUserArtmineProjects(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.artMine.listUserArtmineProjectsResponse({
              data: response.data,
              next,
              req: paramAdjusted
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const createArtMineProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.artMine.createArtMineProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.artMine.createArtMineProject(payload).pipe(
          concatMap(response => [
            apiActions.artMine.createArtMineProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveArtMineConfigEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.artMine.retrieveConfig)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.artMine.retrieveConfig().pipe(
          concatMap(response => [
            apiActions.artMine.retrieveConfigResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveArtMineProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.artMine.retrieveProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.artMine.retrieveProject(payload).pipe(
          concatMap(response => [
            apiActions.artMine.retrieveProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveSignatureEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.artMine.retrieveSignature)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.artMine.retrieveSignature(payload).pipe(
          concatMap(response => [
            apiActions.artMine.retrieveSignatureResponse({
              signature: response.data,
              projectId: payload
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const deleteArtMineProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.artMine.deleteArtMineProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.artMine.deleteArtMineProject(payload).pipe(
          tap(() => {
            MixPanelUtils.track<'NFT__DELETE_PROJECT'>('NFT - Delete Project')
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.artMine.deleteArtMineProjectResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const startTransactionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.artMine.startTransaction)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.artMine.startTransaction(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.artMine.startTransactionResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const artMineEpic = [
  listUserArtmineProjectsEpic,
  createArtMineProjectEpic,
  retrieveArtMineConfigEpic,
  retrieveArtMineProjectEpic,
  retrieveSignatureEpic,
  deleteArtMineProjectEpic,
  startTransactionEpic
]

export const epics = combineEpics(
  ...textToImageEpics,
  ...artMineEpic,
  ...imageEnhancementEpics,
  ...userEpics,
  ...projectEpics,
  ...collectionEpics,
  ...inputsEpic,
  ...sharedEpic,
  ...paymentEpics,
  ...engineEpics,
  ...sketchToImageEpics,
  ...socialEpics,
  ...mixImageEpics,
  ...proArtFilterEpic,
  ...genericAppEpics,
  ...sketchTextToImageEpics
)
