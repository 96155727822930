import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import {
  concatMap,
  debounceTime,
  delay,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, isActionOf, getType, createAction } from 'typesafe-actions'
import {
  SocialList,
  SocialType,
  UpdateUserAliasReq,
  UpdateUserReq,
  UploadPhotoType,
  UploadUserPictureReq,
  User,
  UserImage
} from 'models/ApiModels'
import { createSelector } from 'reselect'
import _compact from 'lodash/compact'
import { of, merge, concat } from 'rxjs'
import Validator, { VALIDATION_MESSAGES, ValidatorUtils } from 'utils/Validator'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { ARTISTS } from 'routes'
import { ImageEditData } from 'components/ImageCropper'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'

// Constants
const NAMESPACE = '@@page/ProfileFormPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)
const MAX_ALIAS_LENGTH = 64

export type PhotoType = {
  current?: UserImage
  imageEdit?: ImageEditData
  original?: File
  edited?: File
}
export type FormData = UpdateUserReq & {
  [key in UploadPhotoType]?: PhotoType
} & Partial<UpdateUserAliasReq>

export type FormDataType = keyof FormData

const SOCIAL_URL: Record<SocialType, string> = {
  facebook: 'facebook.com',
  instagram: 'instagram.com',
  twitter: 'twitter.com',
  linkedin: 'linkedin.com'
}

// Actions
export const profileFormPanelActions = {
  setShowCropDialog: createAction(creator('SET_SHOW_CROP_DIALOG'))<
    ProfileFormPanelState['showCropDialog']
  >(),
  setFormData: createAction(creator('SET_FORM_DATA'))<User | undefined>(),
  updateFormData: createAction(creator('UPDATE_FORM_DATA'))<ProfileFormPanelState['formData']>(),
  updateFormDataWithoutValidation: createAction(creator('UPDATE_FORM_DATA_WITHOUT_VALIDATION'))<
    ProfileFormPanelState['formData']
  >(),
  updateFormError: createAction(creator('UPDATE_FORM_ERROR'))<
    ProfileFormPanelState['formErrors']
  >(),
  saveChange: createAction(creator('SAVE_CHANGES'))(),
  checkOnSaveFinished: createAction(creator('CHECK_ON_SAVE_FINISHED'))<string>()
}

// Selectors
const selectProfileFormPanelPage = (state: RootState) => state.container.profileFormPanel
const formData = (state: RootState) => state.container.profileFormPanel.formData
const formErrors = (state: RootState) => state.container.profileFormPanel.formErrors

export const Utils = {
  getArtistUrl: () => {
    const origin = window.location.host

    return `${origin}${ARTISTS}/`
  }
}

export const selectors = {
  profileFormPanel: selectProfileFormPanelPage,
  hasChanged: createSelector(
    selectProfileFormPanelPage,
    profileFormPanelPage => profileFormPanelPage.hasChanged
  ),
  formDatum: createSelector(
    formData,
    (_: RootState, formDataType: FormDataType) => formDataType,
    (formData, formDataType) => {
      return formData[formDataType]
    }
  ),
  avatar: createSelector(formData, formData => {
    return formData.avatar
  }),
  cover: createSelector(formData, formData => {
    return formData.cover
  }),
  showCropDialog: createSelector(selectProfileFormPanelPage, profileFormPanel => {
    return profileFormPanel.showCropDialog
  }),
  formError: createSelector(
    formErrors,
    (_: RootState, formDataType: FormDataType) => formDataType,
    (formErrors, formDataType) => {
      return formErrors[formDataType]
    }
  ),
  hasError: createSelector(formErrors, formErrors => {
    return ValidatorUtils.isHasContent({
      ...formErrors,
      social_urls: undefined,
      ...formErrors.social_urls
    })
  })
}
export type ProfileFormPanelState = {
  showCropDialog: UploadPhotoType | false
  hasChanged: boolean
  formData: FormData
  formErrors: { [key in Exclude<FormDataType, 'social_urls'>]?: string } & {
    social_urls?: { [key in SocialType]?: string }
  }
}

const INITIAL: ProfileFormPanelState = {
  showCropDialog: false,
  hasChanged: false,
  formData: {
    cover: undefined,
    avatar: undefined,

    first_name: undefined,
    last_name: undefined,
    portfolio: undefined,
    description: undefined,
    alias: ''
  },
  formErrors: {
    first_name: undefined,
    last_name: undefined,
    portfolio: undefined,
    description: undefined,
    alias: undefined,
    social_urls: {
      facebook: undefined,
      instagram: undefined,
      linkedin: undefined,
      twitter: undefined
    }
  }
}

const reducer = produce((state: ProfileFormPanelState, { type, payload }) => {
  switch (type) {
    case getType(profileFormPanelActions.setFormData): {
      const user = payload as ActionType<typeof profileFormPanelActions.setFormData>['payload']
      const updatedForm = Object.keys(state.formData) as FormDataType[]

      updatedForm.forEach(value => {
        state.formData = {
          ...state.formData,
          [value]: user?.[value]
        }
      })
      state.formData.cover = {
        ...state.formData.cover,
        current: user?.cover
      }
      state.formData.avatar = {
        ...state.formData.avatar,
        current: user?.avatar
      }
      state.hasChanged = false
      return
    }
    case getType(profileFormPanelActions.updateFormDataWithoutValidation):
    case getType(profileFormPanelActions.updateFormData): {
      const formData = payload as ActionType<
        typeof profileFormPanelActions.updateFormData
      >['payload']

      const shouldRemoveError = type === getType(profileFormPanelActions.updateFormData)

      const updatedForm = Object.keys(formData) as FormDataType[]
      state.formData = {
        ...state.formData,
        ...formData,
        avatar: {
          ...state.formData.avatar,
          ...formData.avatar
        },
        cover: {
          ...state.formData.cover,
          ...formData.cover
        }
      }
      if (shouldRemoveError) {
        updatedForm.forEach(value => {
          state.formErrors[value] = undefined
        })
      }
      state.hasChanged = true
      return
    }
    case getType(profileFormPanelActions.updateFormError): {
      const formErrors = payload as ActionType<
        typeof profileFormPanelActions.updateFormError
      >['payload']

      state.formErrors = {
        ...state.formErrors,
        ...formErrors
      }
      return
    }
    case getType(profileFormPanelActions.setShowCropDialog): {
      const cropDialog = payload as ActionType<
        typeof profileFormPanelActions.setShowCropDialog
      >['payload']

      state.showCropDialog = cropDialog
      return
    }
    default:
  }
}, INITIAL)

// Epics
type ProfileFormPanelActionType = RootActionType | ActionType<typeof profileFormPanelActions>

const validateEpic: Epic<ProfileFormPanelActionType, ProfileFormPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(profileFormPanelActions.updateFormData)),
    debounceTime(1000),
    withLatestFrom(state$),
    map(([_, state]) => selectors.profileFormPanel(state)),
    map(({ formData, formErrors }) => {
      const updatedForm = Object.keys(formData) as FormDataType[]
      const errorData: ProfileFormPanelState['formErrors'] = {}
      const formDataUpdated: ProfileFormPanelState['formData'] = {}

      updatedForm.forEach(form => {
        const value = formData[form]

        switch (form) {
          case 'description':
          case 'first_name': {
            if (!Validator.required(value)) {
              errorData[form] = VALIDATION_MESSAGES.required
            }
            break
          }
          case 'alias': {
            if (!Validator.required(value)) {
              errorData[form] = VALIDATION_MESSAGES.required
            }
            if (!Validator.maxStringLength(`${value}`, MAX_ALIAS_LENGTH)) {
              errorData[form] = `The Url is too long, maximum is ${MAX_ALIAS_LENGTH} character`
            }
            if (!Validator.matchRegexp(`${value}`, `^[a-zA-Z0-9_.-]*$`)) {
              errorData[form] =
                `The url should only contains letters, number, "." "-" and "_" characters.`
            }
            formDataUpdated['alias'] = (value as string).toLowerCase()
            break
          }

          case 'social_urls': {
            const valueCasted = value as FormData['social_urls']
            const currentSocialError = formErrors['social_urls']
            SocialList.forEach(socialType => {
              const socialUrl = valueCasted?.[socialType]

              const valid1 = `${socialUrl}`.includes(SOCIAL_URL[socialType] ?? '')
              if (!valid1 && Boolean(socialUrl)) {
                errorData['social_urls'] = {
                  ...currentSocialError,
                  ...(errorData['social_urls'] ?? INITIAL.formErrors.social_urls),
                  [socialType]: `Please include valid ${socialType} url`
                }
              }

              const valid2 = Validator.url(socialUrl)

              if (valid2) {
                if (
                  socialUrl &&
                  !(socialUrl.startsWith('http://') || socialUrl.startsWith('https://'))
                ) {
                  formDataUpdated['social_urls'] = {
                    ...(valueCasted ?? INITIAL.formData.social_urls),
                    ...(formDataUpdated['social_urls'] ?? INITIAL.formData.social_urls),
                    [socialType]: `http://${socialUrl}`
                  }
                }
              } else {
                errorData['social_urls'] = {
                  ...currentSocialError,
                  ...(errorData['social_urls'] ?? INITIAL.formErrors.social_urls),
                  [socialType]: VALIDATION_MESSAGES.url
                }
              }
            })

            break
          }
          case 'portfolio': {
            const valueString = value as string
            const valid = Validator.url(valueString)
            if (valid) {
              if (
                valueString &&
                !(valueString.startsWith('http://') || valueString.startsWith('https://'))
              ) {
                formDataUpdated[form] = `http://${valueString}`
              }
            } else {
              errorData[form] = VALIDATION_MESSAGES.url
            }
            break
          }
          default:
            break
        }
      })
      return { errorData, formDataUpdated }
    }),
    concatMap(({ errorData, formDataUpdated }) =>
      concat(
        of(errorData).pipe(
          filter(errorData => Boolean(ValidatorUtils.isHasContent(errorData))),
          map(errorData => profileFormPanelActions.updateFormError(errorData))
        ),
        of(formDataUpdated).pipe(
          delay(800),
          filter(formDataUpdated => Boolean(ValidatorUtils.isHasContent(formDataUpdated))),
          map(formDataUpdated =>
            profileFormPanelActions.updateFormDataWithoutValidation(formDataUpdated)
          )
        )
      )
    )
  )

const saveChangeEpic: Epic<ProfileFormPanelActionType, ProfileFormPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(profileFormPanelActions.saveChange)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      profileForm: selectors.profileFormPanel(state),
      hasError: selectors.hasError(state),
      currentUserProfileUserData: apiSelectors.currentUserProfileUserData(state)
    })),
    filter(({ hasError }) => !hasError),
    mergeMap(({ profileForm, currentUserProfileUserData }) =>
      merge(
        of(profileForm).pipe(
          map(profileForm => ({
            formData: {
              first_name: profileForm.formData.first_name,
              last_name: profileForm.formData.last_name,
              portfolio: profileForm.formData.portfolio,
              social_urls: Object.keys(profileForm.formData.social_urls ?? {}).reduce(
                (result, value) => {
                  const socialValue = profileForm.formData?.social_urls?.[value as SocialType]
                  const newResult = socialValue
                    ? {
                        ...result,
                        [value]: socialValue
                      }
                    : result

                  return newResult
                },
                {} as User['social_urls']
              ),
              description: profileForm.formData.description
            }
          })),
          mergeMap(({ formData }) =>
            action$.pipe(
              filter(isActionOf(apiActions.users.updateResponse)),
              take(1),
              tap(() => {
                MixPanelUtils.track<'USER__UPDATE_PROFILE'>('User - Update Profile', {
                  updated_forms: DataUtils.getObjectKeys(formData)
                })
              }),
              delay(500),
              map(() => profileFormPanelActions.checkOnSaveFinished('update user')),
              startWith(apiActions.users.update(formData))
            )
          )
        ),
        of(profileForm).pipe(
          map(({ formData }) => {
            const formDataAdj: UploadUserPictureReq = _compact([
              formData.cover?.edited ? { file: formData.cover?.edited, type: 'cover' } : undefined,
              formData.avatar?.edited
                ? { file: formData.avatar?.edited, type: 'avatar' }
                : undefined
            ])
            return { formData: formDataAdj }
          }),
          filter(({ formData }) => Boolean(formData.length)),
          mergeMap(({ formData }) =>
            action$.pipe(
              filter(isActionOf(apiActions.users.updateResponse)),
              take(1),
              tap(() => {
                MixPanelUtils.track<'USER__UPDATE_PROFILE'>('User - Update Profile', {
                  updated_forms: DataUtils.getObjectKeys(formData)
                })
              }),
              delay(500),
              map(() => profileFormPanelActions.checkOnSaveFinished('update profile')),
              startWith(apiActions.users.uploadUserPicture(formData))
            )
          )
        ),
        of(profileForm).pipe(
          map(profileForm => ({
            formData: {
              alias: profileForm.formData.alias ?? ''
            }
          })),
          filter(
            ({ formData }) =>
              Boolean(formData.alias) && currentUserProfileUserData?.alias !== formData.alias
          ),
          mergeMap(({ formData }) =>
            action$.pipe(
              filter(isActionOf(apiActions.users.updateResponse)),
              take(1),
              tap(() => {
                MixPanelUtils.track<'USER__UPDATE_PROFILE'>('User - Update Profile', {
                  updated_forms: DataUtils.getObjectKeys(formData)
                })
              }),
              delay(500),
              map(() => profileFormPanelActions.checkOnSaveFinished('update alias')),
              startWith(apiActions.users.updateAlias(formData))
            )
          )
        )
      )
    )
  )

const checkOnSaveFinishedEpic: Epic<
  ProfileFormPanelActionType,
  ProfileFormPanelActionType,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(profileFormPanelActions.checkOnSaveFinished)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      source: _.payload,
      updateAliasLoading: apiSelectors.loading['users.updateAlias'](state),
      uploadUserPictureLoading: apiSelectors.loading['users.uploadUserPicture'](state),
      updateUserLoading: apiSelectors.loading['users.update'](state)
    })),
    map(({ updateAliasLoading, updateUserLoading, uploadUserPictureLoading }) => ({
      isLoading: Boolean(updateAliasLoading || updateUserLoading || uploadUserPictureLoading)
    })),
    filter(({ isLoading }) => !isLoading),
    mergeMap(param =>
      merge(
        of(snackBarActions.show({ content: 'Profile Saved!' })),
        of(param).pipe(
          delay(2000),
          map(() => snackBarActions.close())
        )
      )
    )
  )

export const epics = combineEpics(validateEpic, saveChangeEpic, checkOnSaveFinishedEpic)

export default reducer
