import { catchError, filter, map, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators'
import _compact from 'lodash/compact'
import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { PaginationRequestParams } from 'models/ApiModels'
import { apiActions, sharedActions } from 'duck/ApiDuck'
import { apiSelectors } from '../selectors'

const retrieveProductsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.retrieveProducts)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: 'Preparing Payment Data...', type })),
        ApiUtils.payment.retrieveProducts().pipe(
          mergeMap(response => [
            apiActions.payment.retrieveProductsResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveChannelsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.retrieveChannels)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: 'Preparing Payment Data...', type })),
        ApiUtils.payment.retrieveChannels().pipe(
          mergeMap(response => [
            apiActions.payment.retrieveChannelsResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.listSubscription)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.listSubscription().pipe(
          mergeMap(response => [
            apiActions.payment.listSubscriptionResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const createPaymentEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.createPayment)),
    switchMap(({ payload, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.createPayment(payload).pipe(
          mergeMap(response => [
            apiActions.payment.createPaymentResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const retrievePaymentEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.retrievePayment)),
    mergeMap(({ payload, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.retrievePayment(payload).pipe(
          mergeMap(response => [
            apiActions.payment.retrievePaymentResponse({ result: response.data }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(err).pipe(
              mergeMap(err => [
                sharedActions.setError({ error: err.response, type, req: payload }),
                apiActions.payment.retrievePaymentResponse({ error: err.response })
              ])
            )
          )
        )
      )
    )
  )

const listCreditChangeLogEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.listCreditChangeLog)),
    withLatestFrom(store),
    map(([action, state]) => {
      const paymentData = apiSelectors.paymentData(state)

      const { param, reloadList = false } = action.payload
      const params = reloadList
        ? {}
        : {
            next: state.api?.payment?.lastCreditChangeLogRequest?.next
          }
      const extraParams = reloadList ? (param as PaginationRequestParams) : undefined
      return { reloadList, params, extraParams, type: action.type, paymentData }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }

      return true
    }),
    switchMap(({ reloadList, params, extraParams, type, paymentData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.listCreditChangeLog(params, extraParams).pipe(
          mergeMap(response =>
            _compact([
              ...(response.data.results?.map(value =>
                value.details?.payment_id && !paymentData[value.details?.payment_id]
                  ? apiActions.payment.retrievePayment(value.details?.payment_id)
                  : undefined
              ) ?? []),
              sharedActions.setLoading({ loading: false, type }),
              apiActions.payment.listCreditChangeLogResponse({
                data: response.data,
                reloadList
              })
            ])
          ),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const retrieveCreditOverviewEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.retrieveCreditOverview)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.retrieveCreditOverview().pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.payment.retrieveCreditOverviewResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const createSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.createSubscription)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.createSubscription(payload).pipe(
          mergeMap(response => [
            apiActions.users.retrieveEquity(),
            apiActions.payment.createSubscriptionResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.updateSubscription)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.updateSubscription(payload.id, payload).pipe(
          mergeMap(response => [
            apiActions.payment.updateSubscriptionResponse(response.data),
            apiActions.users.retrieveEquity(),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const cancelSubscriptionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.cancelSubscription)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.cancelSubscription(payload).pipe(
          mergeMap(response => [
            apiActions.payment.cancelSubscriptionResponse(),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const createDownloadImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.createDownloadImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.createDownloadImage(payload).pipe(
          mergeMap(response => [
            apiActions.payment.createDownloadImageResponse({
              data: response.data,
              image: payload.image,
              originalImage: payload.originalImage
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const braintreeGenerateTokenEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.braintreeGenerateToken)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.braintreeGenerateToken(undefined).pipe(
          mergeMap(response => [
            apiActions.payment.braintreeGenerateTokenResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const braintreeCreatePaymentMethodEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.braintreeCreatePaymentMethod)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.braintreeCreatePaymentMethod(payload).pipe(
          mergeMap(response => [
            apiActions.payment.braintreeCreatePaymentMethodResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const braintreeRemovePaymentMethodEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.braintreeRemovePaymentMethod)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.braintreeRemovePaymentMethod(undefined).pipe(
          mergeMap(response => [
            apiActions.payment.braintreeRemovePaymentMethodResponse(),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const braintreeRetrievePaymentMethodEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.braintreeRetrievePaymentMethod)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.braintreeRetrievePaymentMethod().pipe(
          mergeMap(response => [
            apiActions.payment.braintreeRetrievePaymentMethodResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const retrieveDownloadImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.retrieveDownloadImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.retrieveDownloadImage(payload).pipe(
          mergeMap(response => [
            apiActions.payment.retrieveDownloadImageResponse({
              data: response.data,
              image: payload
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listDownloadImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.payment.listDownloadImage)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, reloadList = false } = action.payload
      const params = reloadList
        ? {}
        : {
            next: state.api?.payment?.downloadImage.lastReq?.next
          }
      const extraParams = reloadList ? (param as PaginationRequestParams) : undefined
      return { reloadList, params, extraParams, type: action.type }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }

      return true
    }),
    switchMap(({ reloadList, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.payment.listDownloadImage(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.payment.listDownloadImageResponse({
              data: response.data,
              reloadList
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const paymentEpics = [
  braintreeGenerateTokenEpic,
  listSubscriptionEpic,
  retrieveChannelsEpic,
  retrieveCreditOverviewEpic,
  listCreditChangeLogEpic,
  createPaymentEpic,
  retrieveProductsEpic,
  retrievePaymentEpic,
  createSubscriptionEpic,
  updateSubscriptionEpic,
  cancelSubscriptionEpic,
  createDownloadImageEpic,
  retrieveDownloadImageEpic,
  listDownloadImageEpic,
  braintreeRetrievePaymentMethodEpic,
  braintreeCreatePaymentMethodEpic,
  braintreeRemovePaymentMethodEpic
]
export default paymentEpics
