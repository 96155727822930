import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
  mergeMap,
  delay,
  tap,
  ignoreElements
} from 'rxjs/operators'
import _clone from 'lodash/clone'
import _toNumber from 'lodash/toNumber'
import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge, concat } from 'rxjs'
import {
  PostListReq,
  ThreadListReq,
  CommentListReq,
  TrainProjectListReq,
  CollectionListReq
} from 'models/ApiModels'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'

const retrieveUserByAliasEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.retrieveUserByAlias)),
    withLatestFrom(store),
    switchMap(([{ type, payload }, state]) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        of(payload).pipe(
          mergeMap(() =>
            ApiUtils.users.retrieveByAlias(payload.alias).pipe(
              concatMap(response => [
                apiActions.social.retrieveUserByAliasResponse(response.data),
                sharedActions.setLoading({
                  loading: false,
                  type
                })
              ]),
              catchError(err =>
                of(
                  sharedActions.setError({
                    error: err.response,
                    type,
                    req: payload
                  })
                )
              )
            )
          )
        )
      )
    )
  )

const listPostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listPost)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, reloadList = false } = action.payload
      let params: { next?: string } = {}

      if (!reloadList) {
        params = {
          next: state.api.social.lastPostListReq?.next
        }
      }

      const extraParams = reloadList ? (param as PostListReq) : undefined

      return {
        reloadList,
        params,
        extraParams,
        type: action.type,
        payload: action.payload
      }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ reloadList, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.listPost(params, extraParams).pipe(
          concatMap(response => [
            apiActions.social.listPostResponse({
              data: response.data,
              reloadList
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listUserPostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listUserPost)),
    withLatestFrom(store),
    map(([action, state]) => {
      const user = apiSelectors.currentUserProfile(state)
      const { param, reloadList = false } = action.payload
      let params: { next?: string } = {}

      const paramWithUser: PostListReq | undefined = param ? { ...param, user } : undefined

      if (!reloadList) {
        params = {
          next: state.api.social.profileLists[user ?? 0]?.lastPostListReq?.next
        }
      }

      const extraParams = reloadList ? (paramWithUser as PostListReq) : undefined

      return {
        param: paramWithUser,
        reloadList,
        params,
        extraParams,
        type: action.type,
        payload: action.payload
      }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ reloadList, params, extraParams, param, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.listPost(params, extraParams).pipe(
          concatMap(response => [
            apiActions.social.listUserPostResponse({
              data: response.data,
              reloadList,
              req: param
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listUserProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listUserProject)),
    withLatestFrom(store),
    map(([action, state]) => {
      const user = apiSelectors.currentUserProfile(state)

      const { param, reloadList = false } = action.payload
      let params: { next?: string } = {}

      const paramWithUser: TrainProjectListReq | undefined = param ? { ...param, user } : undefined

      if (!reloadList) {
        params = {
          next: state.api.social.profileLists[user ?? 0]?.lastProjectListReq?.next
        }
      }

      const extraParams = reloadList ? (paramWithUser as TrainProjectListReq) : undefined

      return {
        param: paramWithUser,
        reloadList,
        params,
        extraParams,
        type: action.type,
        payload: action.payload
      }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ reloadList, params, extraParams, param, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.projects.list(params, extraParams).pipe(
          concatMap(response => [
            apiActions.social.listUserProjectResponse({
              data: response.data,
              reloadList,
              req: param
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listUserCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listUserCollection)),
    withLatestFrom(store),
    map(([action, state]) => {
      const user = apiSelectors.currentUserProfile(state)

      const { param, reloadList = false } = action.payload
      let params: { next?: string } = {}

      const paramWithUser: CollectionListReq | undefined = param ? { ...param, user } : undefined

      if (!reloadList) {
        params = {
          next: state.api.social.profileLists[user ?? 0]?.lastProjectListReq?.next
        }
      }

      const extraParams = reloadList ? (paramWithUser as CollectionListReq) : undefined

      return {
        param: paramWithUser,
        reloadList,
        params,
        extraParams,
        type: action.type,
        payload: action.payload
      }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ reloadList, params, extraParams, param, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.collections.list(params, extraParams).pipe(
          concatMap(response => [
            apiActions.social.listUserCollectionResponse({
              data: response.data,
              reloadList,
              req: param
            }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listFeaturedPostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listFeaturedPost)),
    withLatestFrom(store),
    map(([action, state]) => ({
      type: action.type,
      listPost: apiSelectors.featuredPostListId(state)
    })),
    filter(({ listPost }) => listPost === undefined),
    map(({ type }) => ({
      type,
      param: { ordering: 'trending', scope: 'featured', limit: 100 } as PostListReq
    })),
    switchMap(({ type, param }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.listPost(undefined, param).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.social.listFeaturedPostResponse({
              data: response.data
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { param } }))
          )
        )
      )
    )
  )

const deletePostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.deletePost)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      payload: action.payload,
      postData: _clone(apiSelectors.posts(state)[action.payload])
    })),
    switchMap(({ type, payload, postData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.deletePost(payload).pipe(
          tap(response => {
            MixPanelUtils.track<'SOCIAL__DELETE_POST'>(
              'Social - Delete Post',
              DataUtils.getPostData({ post: postData })
            )
          }),
          concatMap(response => [
            apiActions.social.deletePostResponse(payload),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const createPostEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.social.createPost)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.createPost(payload).pipe(
          tap(response => {
            MixPanelUtils.track<'SOCIAL__SHARE'>(
              'Social - Share',
              DataUtils.getPostData({ post: response.data })
            )
          }),
          concatMap(response => [
            apiActions.social.createPostResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const updatePostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.updatePost)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        of(payload).pipe(
          filter(payload => payload.is_featured !== undefined),
          withLatestFrom(state$),
          map(([payload, state]) => ({
            is_featured: payload.is_featured,
            currentPostData: apiSelectors.posts(state)[payload.id]
          })),
          filter(({ currentPostData }) => Boolean(currentPostData)),
          map(({ currentPostData, is_featured }) =>
            apiActions.social.updatePostResponse({ ...currentPostData, is_featured })
          )
        ),
        ApiUtils.social.updatePost(payload.id, payload).pipe(
          tap(response => {
            MixPanelUtils.track<'SOCIAL__UPDATE_POST'>('Social - Update Post', {
              ...DataUtils.getPostData({ post: response.data }),
              updated_forms: DataUtils.getObjectKeys(payload)
            })
          }),
          concatMap(response =>
            concat(
              of(apiActions.social.updatePostResponse(response.data)),
              of(sharedActions.setLoading({ loading: false, type })),
              of(response).pipe(
                filter(() => payload.is_featured),
                map(() => snackBarActions.show({ content: 'Post is now featured!' }))
              ),
              of(response).pipe(
                delay(1000),
                map(() => snackBarActions.close())
              )
            )
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const retrievePostpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.social.retrievePost)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.retrievePost(payload).pipe(
          mergeMap(response => [
            apiActions.social.retrievePostResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const setCurrentPostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.setCurrentPost)),
    withLatestFrom(state$),
    filter(([action]) => Boolean(action.payload)),
    tap(([action, state]) => {
      const postData = apiSelectors.posts(state)[action.payload ?? 0]

      MixPanelUtils.track<'SOCIAL__OPEN_DETAIL'>(
        'Social - Open Detail',
        DataUtils.getPostData({ post: postData })
      )
    }),
    ignoreElements()
  )

const toggleClapEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.toggleClap)),
    withLatestFrom(store),
    map(([action, store]) => ({
      ...action,
      post: apiSelectors.posts(store)[action.payload.post]
    })),
    filter(({ post }) => Boolean(post)),
    map(param => {
      const { post } = param
      const is_clapped = post?.is_clapped

      return {
        is_clapped,
        ...param
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ is_clapped }) => is_clapped),
          mergeMap(({ payload }) =>
            merge(
              of(
                apiActions.social.toggleClapInstantResponse({
                  req: payload,
                  clapType: 'remove'
                })
              ),
              ApiUtils.social.removeClap(payload).pipe(
                tap(() => {
                  MixPanelUtils.track<'SOCIAL__CLAP'>('Social - Clap', {
                    ...DataUtils.getPostData({ post: param.post }),
                    clap_type: 'remove'
                  })
                }),
                map(response =>
                  apiActions.social.toggleClapResponse({
                    req: payload,
                    data: response.data,
                    clapType: 'remove'
                  })
                ),
                catchError(() =>
                  of(
                    apiActions.social.toggleClapResponse({
                      req: payload,
                      data: { status: 0 },
                      clapType: 'remove'
                    })
                  )
                )
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ is_clapped }) => !is_clapped),
          mergeMap(({ payload }) =>
            merge(
              of(
                apiActions.social.toggleClapInstantResponse({
                  req: payload,
                  clapType: 'add'
                })
              ),
              ApiUtils.social.createClap(payload).pipe(
                tap(() => {
                  MixPanelUtils.track<'SOCIAL__CLAP'>('Social - Clap', {
                    ...DataUtils.getPostData({ post: param.post }),
                    clap_type: 'add'
                  })
                }),
                map(response =>
                  apiActions.social.toggleClapResponse({
                    req: payload,
                    data: response.data,
                    clapType: 'add'
                  })
                ),
                catchError(() =>
                  of(
                    apiActions.social.toggleClapResponse({
                      req: payload,
                      data: { status: 0 },
                      clapType: 'add'
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const togglePostBookmarkEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.togglePostBookmark)),
    withLatestFrom(store),
    map(([action, store]) => apiSelectors.posts(store)[action.payload.postId]),
    filter(post => Boolean(post)),
    mergeMap(post =>
      merge(
        of(post).pipe(
          filter(({ bookmark }) => Boolean(bookmark)),
          map(post =>
            apiActions.social.togglePostBookmarkInstantResponse({
              postId: post.id,
              bookmark: false
            })
          )
        ),
        of(post).pipe(
          filter(({ bookmark }) => Boolean(bookmark) && _toNumber(bookmark) > 0),
          mergeMap(post =>
            merge(
              ApiUtils.social.deletePostBookmark(post.bookmark ?? 0).pipe(
                map(response => apiActions.social.togglePostBookmarkResponse({})),
                tap(() => {
                  MixPanelUtils.track<'SOCIAL__BOOKMARK'>('Social - Bookmark', {
                    ...DataUtils.getPostData({ post }),
                    bookmark_action_type: 'unsave'
                  })
                }),
                catchError(() =>
                  of(
                    apiActions.social.togglePostBookmarkInstantResponse({
                      postId: post.id,
                      bookmark: true
                    })
                  )
                )
              )
            )
          )
        ),
        of(post).pipe(
          filter(({ bookmark }) => !Boolean(bookmark)),
          mergeMap(post =>
            merge(
              of(
                apiActions.social.togglePostBookmarkInstantResponse({
                  postId: post.id,
                  bookmark: true
                })
              ),
              ApiUtils.social.createPostBookmark({ item: post.id }).pipe(
                tap(() => {
                  MixPanelUtils.track<'SOCIAL__BOOKMARK'>('Social - Bookmark', {
                    ...DataUtils.getPostData({ post }),
                    bookmark_action_type: 'save'
                  })
                }),
                mergeMap(response =>
                  merge(
                    of(
                      apiActions.social.togglePostBookmarkResponse({
                        data: response.data
                      })
                    ),
                    of(snackBarActions.show({ content: 'Post Saved!' })),
                    of(response).pipe(
                      delay(1000),
                      map(() => snackBarActions.close())
                    )
                  )
                ),
                catchError(() =>
                  of(
                    apiActions.social.togglePostBookmarkInstantResponse({
                      postId: post.id,
                      bookmark: false
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const createThreadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.createThread)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.createThread(payload).pipe(
          withLatestFrom(state$),
          tap(([_, state]) => {
            const postData = apiSelectors.posts(state)[payload.post]

            MixPanelUtils.track<'SOCIAL__COMMENT'>('Social - Comment', {
              ...DataUtils.getPostData({ post: postData }),
              comment_type: 'thread',
              comment_length: payload.body.length
            })
          }),
          concatMap(([response]) =>
            merge(
              of(apiActions.social.createThreadResponse(response.data)),
              of(sharedActions.setLoading({ loading: false, type })),
              of(response).pipe(
                delay(1000),
                map(() => apiActions.social.resetNewThreadHighlight(response.data.id))
              )
            )
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createCommentEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.createComment)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.createComment(payload).pipe(
          withLatestFrom(state$),
          tap(([_, state]) => {
            const threadData = apiSelectors.threads(state)[payload.thread]
            const postData = apiSelectors.posts(state)[threadData.post]

            MixPanelUtils.track<'SOCIAL__COMMENT'>('Social - Comment', {
              ...DataUtils.getPostData({ post: postData }),
              comment_length: payload.body.length,
              comment_type: 'comment',
              reply_to: payload.reply_to,
              thread_id: payload.thread
            })
          }),
          concatMap(([response]) =>
            merge(
              of(apiActions.social.createCommentResponse(response.data)),
              of(sharedActions.setLoading({ loading: false, type })),
              of(response).pipe(
                delay(1000),
                map(() => apiActions.social.resetNewCommentHighlight(response.data.id))
              )
            )
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listThreadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listThread)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, reloadList = false } = action.payload
      const postId = param?.post || 0
      let params: { next?: string } = {}

      if (!reloadList) {
        params = {
          next: state.api.social.threadLists?.[postId]?.lastRequest?.next
        }
      }

      const extraParams = reloadList ? (param as ThreadListReq) : undefined

      return {
        reloadList,
        params,
        extraParams,
        type: action.type,
        payload: action.payload
      }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ reloadList, params, extraParams, type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.listThread(params, extraParams).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.social.listThreadResponse({
              data: response.data,
              reloadList,
              req: payload.param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listCommentEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listComment)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, reloadList = false } = action.payload
      const threadId = param?.thread || 0
      let params: { next?: string } = {}

      if (!reloadList) {
        params = {
          next: state.api.social.commentLists?.[threadId]?.lastRequest?.next
        }
      }

      const extraParams = reloadList ? (param as CommentListReq) : undefined

      return {
        reloadList,
        params,
        extraParams,
        type: action.type,
        payload: action.payload
      }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ reloadList, params, extraParams, type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.listComment(params, extraParams).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.social.listCommentResponse({
              data: response.data,
              reloadList,
              req: payload.param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listBannerImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(apiActions.social.listBannerImages)),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.social.listBannerImages().pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.social.listBannerImagesResponse({
              data: response.data
            })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const socialEpics = [
  retrieveUserByAliasEpic,
  listUserCollectionEpic,
  listUserProjectEpic,
  listUserPostEpic,
  updatePostEpic,
  deletePostEpic,
  listFeaturedPostEpic,
  setCurrentPostEpic,
  listPostEpic,
  createCommentEpic,
  createThreadEpic,
  listThreadEpic,
  listCommentEpic,
  retrievePostpic,
  createPostEpic,
  toggleClapEpic,
  togglePostBookmarkEpic,
  listBannerImagesEpic
]

export default socialEpics
