import { TextTransform, UrlUtils } from 'utils/TextUtils'
import produce from 'immer'
import {
  SketchStyle,
  SketchGenre,
  GenerateSketchProjectReq,
  SketchOutputImage,
  UploadSketchRequest
} from 'models/ApiModels'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  map,
  withLatestFrom,
  mergeMap,
  tap,
  startWith,
  take,
  ignoreElements,
  delay,
  catchError
} from 'rxjs/operators'
import _find from 'lodash/find'
import _toNumber from 'lodash/toNumber'
import _compact from 'lodash/compact'
import _truncate from 'lodash/truncate'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { from, merge, of } from 'rxjs'
import { createSelector } from 'reselect'
import { values } from 'appConstants'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import MixPanelUtils, { DataUtils, Events } from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { SKETCH_SAVE_LOADING } from 'duck/ApiDuck/epics/SketchToImageEpics'
import { errorUtils } from 'utils/DataProcessingUtils'
import { eventEmiterActions, AppEvents } from 'duck/AppDuck/EventEmitterDuck'
import { SketchCommandType } from 'components/Sketch'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
import { ImageEditData } from 'components/ImageCropper'

// Constants
const NAMESPACE = '@@page/SketchToImagePanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

type ViewType = '' | 'styles' | 'sketch' | 'result'
export type SketchSourceType = 'standalone' | 'mix'

type LogSketchDoActionParam = Pick<
  Events['PROJECT__SKETCH_DO_ACTION']['properties'],
  'do_action_type' | 'do_action_value'
>

export const GENERATING_POOL_INTERVAL = 2000
export const ADDITIONAL_THRESHOLD_GENERATE = 4 //in second
export const LOAD_OUTPUT_AFTER_GENERATE_LOADING = 'load_output_after_generate'

// Actions
export const actions = {
  uploadSketch: createAction(creator('UPLOAD_SKETCH'))<UploadSketchRequest>(),
  setSketchCommand: createAction(creator('SET_SKETCH_COMMAND'))<SketchCommandType | null>(),

  initSketchPanel: createAction(creator('SET_SOURCE'))<{ source: SketchSourceType; id: number }>(),
  retrieveSketchStyle: createAction(creator('RETRIEVE_SKETCH_STYLE'))(),

  addMixStyle: createAction(creator('ADD_MIX_STYLE'))<File>(),
  removeMixStyle: createAction(creator('REMOVE_MIX_STYLE'))<{ index: number }>(), //index

  addCustomStyles: createAction(creator('ADD_CUSTOM_STYLE_ORIGINAL'))<File[]>(),
  updateCustomStyle: createAction(creator('UPDATE_CUSTOM_STYLE'))<{
    index: number
    data: Partial<CustomStyle>
  }>(),
  removeCustomStyle: createAction(creator('REMOVE_CUSTOM_STYLE'))<{ index: number }>(),

  updateBackgroundSketchFile: createAction(creator('UPDATE_BACKGROUND_SKETCH_FILE'))<
    File | undefined
  >(), //index
  updateBackgroundSketchData: createAction(creator('UPDATE_BACKGROUND_SKETCH_DATA'))<
    ImageEditData | undefined
  >(), //index
  toggleStyleImages: createAction(creator('TOGGLE_STYLE_IMAGES'))<{ id: number }>(),
  addStyleImages: createAction(creator('ADD_STYLE_IMAGES'))<{ id: number }>(),
  removeStyleImages: createAction(creator('REMOVE_STYLE_IMAGES'))<{ id: number }>(),
  renameStyleImage: createAction(creator('RENAME_STYLE_IMAGE'))<{ index: number; name: string }>(),
  setScrollToTop: createAction(creator('SCROLL_TO_TOP'))<boolean>(),

  setStyle: createAction(creator('SET_STYLE'))<string>(),
  handleError: createAction(creator('HANDLE_ERROR'))<any>(),
  logSketchDoAction: createAction(creator('LOG_SKETCH_DRAW_ACTION'))<LogSketchDoActionParam>(),
  generateImage: createAction(creator('GENERATE_IMAGE'))<{
    input: Blob | null
    input_data: string
  }>(),
  downloadAll: createAction(creator('DOWNLOAD_ALL_RESULT'))(),
  loadAllResult: createAction(creator('LOAD_ALL_RESULT'))(),
  setActiveView: createAction(creator('SET_ACTIVE_VIEW'))<ViewType>(),
  setGenerating: createAction(creator('SET_GENERATING'))<boolean>()
}

export type ListIdType = {
  id: number
  source: 'style' | 'custom_style' | 'style_image' | 'mix_image'
}
export const SEPARATOR = '-_-'

export const SketchUtils = {
  getListIdKey: (param: ListIdType) => `${param.source}${SEPARATOR}${param.id}`,
  getListIdObject: (listIdKey: string): ListIdType => {
    const param = listIdKey.split(SEPARATOR)

    return {
      id: _toNumber(param[1]),
      source: param[0] as ListIdType['source']
    }
  },
  getFileName: (input?: File) => {
    const splited = input?.name?.split('.') ?? []

    return input ? `${splited[0].substring(0, 20)}.${splited[splited.length - 1]}` : ''
  },
  extractStyleName: (formData: FormData, sketchStyles?: SketchStyle[]): string => {
    const { style_key, mix_images, customStyles } = formData
    const { source, id } = SketchUtils.getListIdObject(style_key)

    switch (source) {
      case 'custom_style':
        return `${source}-${customStyles?.[id]?.original.name ?? ''}`
      case 'style_image':
        return `${source}_${id}`
      case 'mix_image':
        return `${source}-${mix_images?.[id]?.name ?? ''}`
      case 'style':
        return sketchStyles?.find(sketchStyle => sketchStyle.id === id)?.name ?? ''
      default:
        return ''
    }
  },
  getGenerateParam: (
    formData: FormData,
    outputs: SketchOutputImage[]
  ): Pick<GenerateSketchProjectReq, 'style' | 'custom_style' | 'style_image'> => {
    const { style_key, customStyles } = formData
    const { id, source } = SketchUtils.getListIdObject(style_key)

    switch (source) {
      case 'custom_style':
        return { custom_style: customStyles?.[id]?.file }
      case 'style_image':
        return {
          style_image: outputs.find(output => output.image.id === id)?.style_image ?? 0
        }
      case 'mix_image':
        return { custom_style: formData.mix_images?.[id] }
      case 'style': {
        return { style: id }
      }
      default:
        return {}
    }
  }
}

// Selectors
const selectSketchToImagePanel = (state: RootState) => state.container.sketchToImagePanel
const selectSketchToImageData = createSelector(selectSketchToImagePanel, sketchPanel => {
  const data = sketchPanel.data[sketchPanel.id] ?? INITIAL_DATA
  return {
    ...data,
    formData: {
      ...data.formData,
      customStyles: sketchPanel.customStyles
    }
  }
})

const selectSketchGenres = createSelector(apiSelectors.sketchGenres, genres =>
  genres.map(genre => {
    let result: SketchGenre = {
      ...genre
    }
    if (genre.codename === 'human_face') {
      result = {
        ...result,
        guides: ['face1', 'face2']
      }
    }
    if (genre.codename === 'fashion') {
      result = {
        ...result,
        guides: ['fashion1', 'fashion2']
      }
    }

    return result
  })
)
const selectSelectedGenre = createSelector(
  apiSelectors.currentSketchProject,
  selectSketchGenres,
  (currentSketchProject, sketchGenres) => {
    const genre = currentSketchProject?.genre
    return _find(sketchGenres, value => value.id === genre)
  }
)
const selectSelectedStyleImages = createSelector(
  selectSketchToImageData,
  sketchToImageData => sketchToImageData.formData.selected_style_images
)
const selectSelectedStyleImagesData = createSelector(
  selectSelectedStyleImages,
  apiSelectors.currentSketchProject,
  (selectedStyleImages, currentSketchProject) => {
    const outputs = currentSketchProject?.outputs ?? []

    return selectedStyleImages?.map(({ id, name }) => ({
      id,
      name,
      output: outputs.find(item => item.image.id === id)
    }))
  }
)
const selectFormData = createSelector(
  selectSketchToImageData,
  sketchToImageData => sketchToImageData.formData
)
const selectActiveView = createSelector(
  selectSketchToImageData,
  sketchToImageData => sketchToImageData.activeView
)
const selectIsGenerating = createSelector(
  selectSketchToImageData,
  sketchToImageData => sketchToImageData?.isGenerating
)

export const selectors = {
  sketchCommand: createSelector(
    selectSketchToImagePanel,
    sketchToImagePanel => sketchToImagePanel.sketchCommand
  ),
  source: createSelector(selectSketchToImagePanel, sketchToImagePanel => sketchToImagePanel.source),
  activeView: selectActiveView,
  unprocessedCustomStyles: createSelector(
    selectSketchToImagePanel,
    ({ customStyles }) =>
      customStyles
        ?.map((value, index) => ({ ...value, index }))
        .filter(value => !Boolean(value.file)) ?? []
  ),
  formData: selectFormData,
  selectedStyleImages: selectSelectedStyleImages,
  selectedStyleImagesData: selectSelectedStyleImagesData,
  sketchGenres: selectSketchGenres,
  selectedGenre: selectSelectedGenre,
  lastOutputRequest: createSelector(
    apiSelectors.currentSketchProjectOutputList,
    outputList => outputList?.lastListReq
  ),
  scrollToTop: createSelector(
    selectSketchToImageData,
    sketchToImageData => sketchToImageData.scrollToTop
  ),
  selectedGenreText: createSelector(selectSelectedGenre, selectedGenre => selectedGenre?.name),
  isGenerating: selectIsGenerating,
  generateCount: createSelector(
    selectSketchToImageData,
    sketchToImageData => sketchToImageData.generateCount
  ),
  generateProjectLoading: createSelector(
    selectIsGenerating,
    apiSelectors.loading['sketchToImage.generateSketchProject'],
    apiSelectors.loading['sketchToImage.updateSketchProject'],
    apiSelectors.loading['sketchToImage.listSketchOutput'],
    (isGenerating, generateSketchProject, updateSketchProject, listSketchOutput) => {
      return Boolean(
        generateSketchProject ||
          updateSketchProject === SKETCH_SAVE_LOADING ||
          isGenerating ||
          listSketchOutput === LOAD_OUTPUT_AFTER_GENERATE_LOADING
      )
    }
  ),
  currentStyleData: createSelector(
    selectFormData,
    apiSelectors.currentSketchProject,
    apiSelectors.sketchStyles,
    (formData, currentSketchProject, sketchStyles) => {
      const { style_key, customStyles, mix_images, selected_style_images } = formData
      const { id, source } = SketchUtils.getListIdObject(style_key)
      const outputs = currentSketchProject.outputs
      const current_custom_style = currentSketchProject?.custom_style

      if (!style_key && current_custom_style) {
        return { image: current_custom_style, name: 'Uploaded Style', isLastUsedStyle: true }
      }

      if (source === 'style') {
        const sketchStyle = sketchStyles.find(value => value.id === id)
        return { image: sketchStyle?.cover, name: sketchStyle?.name }
      }
      if (source === 'custom_style') {
        return {
          file: customStyles?.[id]?.file,
          name: SketchUtils.getFileName(customStyles?.[id]?.original)
        }
      }
      if (source === 'mix_image') {
        return { file: mix_images?.[id], name: SketchUtils.getFileName(mix_images?.[id]) }
      }
      if (source === 'style_image') {
        const name = selected_style_images?.find(style => style.id === id)?.name
        return {
          image: outputs?.find(output => output.image.id === id)?.image?.file,
          name
        }
      }
    }
  )
}

export type CustomStyle = {
  original: File
  file?: File
  imageEdit?: ImageEditData
}

export type FormData = {
  style_key: string
  mix_images?: File[]
  selected_style_images?: { id: number; name: string }[]
  background_sketch_file?: File
  background_sketch_data?: ImageEditData
  customStyles: CustomStyle[]
}

export type SketchToImagePanelData = {
  formData: Omit<FormData, 'customStyles'>
  activeView: ViewType
  isGenerating: boolean
  generateCount?: number
  scrollToTop: boolean
}

export type SketchToImagePanelState = {
  id: number
  source: SketchSourceType
  sketchCommand: SketchCommandType | null
  customStyles: CustomStyle[]
  data: {
    [sketchId: number]: SketchToImagePanelData
  }
}

export const INITIAL_DATA: SketchToImagePanelData = {
  formData: {
    style_key: '',
    mix_images: undefined,
    selected_style_images: undefined,
    background_sketch_file: undefined,
    background_sketch_data: undefined
  },
  activeView: '',
  isGenerating: false,
  generateCount: undefined,
  scrollToTop: false
}

const INITIAL: SketchToImagePanelState = {
  id: 0,
  sketchCommand: null,
  source: 'standalone',
  customStyles: [],
  data: {}
}

const reducer = produce((state: SketchToImagePanelState, { type, payload }) => {
  const currentId = state.id
  switch (type) {
    case getType(actions.initSketchPanel): {
      const data = payload as ActionType<typeof actions.initSketchPanel>['payload']

      state.source = data.source
      state.id = data.id

      if (!state.data[data.id]) {
        state.data[data.id] = INITIAL_DATA
      }

      return
    }
    case getType(actions.addStyleImages): {
      if (!currentId) return

      const { id } = payload as ActionType<typeof actions.addStyleImages>['payload']

      const currentSelectedStyles = state.data[currentId].formData.selected_style_images ?? []

      const name = `Style ${currentSelectedStyles.length + 1}`
      state.data[currentId].formData.selected_style_images = [
        { id, name },
        ...currentSelectedStyles
      ]
      state.data[currentId].formData.style_key = SketchUtils.getListIdKey({
        id,
        source: 'style_image'
      })
      return
    }
    case getType(actions.setSketchCommand): {
      const sketchCommand = payload as ActionType<typeof actions.setSketchCommand>['payload']
      state.sketchCommand = sketchCommand
      return
    }
    case getType(actions.renameStyleImage): {
      if (!currentId) return

      const { name, index } = payload as ActionType<typeof actions.renameStyleImage>['payload']

      const currentData = state.data[currentId]

      if (currentData?.formData?.selected_style_images?.[index]) {
        currentData.formData.selected_style_images[index].name = name
      }

      state.data[currentId] = currentData

      return
    }
    case getType(actions.addMixStyle): {
      if (!currentId) return

      const file = payload as ActionType<typeof actions.addMixStyle>['payload']

      const mix_images = state.data[currentId].formData.mix_images ?? []

      state.data[currentId].formData.mix_images = [file, ...mix_images]
      state.data[currentId].formData.style_key = SketchUtils.getListIdKey({
        id: 0,
        source: 'mix_image'
      })

      return
    }
    case getType(actions.removeMixStyle): {
      if (!currentId) return

      const { index } = payload as ActionType<typeof actions.removeMixStyle>['payload']

      const mix_images = state.data[currentId].formData.mix_images ?? []
      mix_images.splice(index, 1)
      state.data[currentId].formData.mix_images = [...mix_images]

      state.data[currentId].formData.style_key = INITIAL_DATA.formData.style_key

      return
    }
    case getType(actions.updateCustomStyle): {
      const { index, data } = payload as ActionType<typeof actions.updateCustomStyle>['payload']

      const customStyles = state.customStyles ?? []

      if (customStyles[index]) {
        customStyles[index] = {
          ...customStyles[index],
          ...data
        }

        state.customStyles = customStyles
      }

      return
    }
    case getType(actions.addCustomStyles): {
      const files = payload as ActionType<typeof actions.addCustomStyles>['payload']

      state.customStyles = [
        ...files.map(file => ({ original: file }), ...(state.customStyles ?? []))
      ]
      return
    }
    case getType(actions.removeCustomStyle): {
      const { index } = payload as ActionType<typeof actions.removeCustomStyle>['payload']

      const customStyles = state.customStyles ?? []
      customStyles.splice(index, 1)
      state.customStyles = [...customStyles]

      if (!customStyles.length) {
        state.data[currentId].formData.style_key = INITIAL_DATA.formData.style_key
      }

      return
    }
    case getType(actions.removeStyleImages): {
      if (!currentId) return

      const { id } = payload as ActionType<typeof actions.removeStyleImages>['payload']

      const currentSelectedStyles = state.data[currentId].formData.selected_style_images ?? []
      const index = currentSelectedStyles.findIndex(value => value.id === id)
      currentSelectedStyles.splice(index, 1)

      state.data[currentId].formData.selected_style_images = [...currentSelectedStyles]

      state.data[currentId].formData.style_key = INITIAL_DATA.formData.style_key

      return
    }
    case getType(actions.updateBackgroundSketchFile): {
      if (!currentId) return

      const file = payload as ActionType<typeof actions.updateBackgroundSketchFile>['payload']
      state.data[currentId].formData.background_sketch_file = file

      return
    }
    case getType(actions.updateBackgroundSketchData): {
      if (!currentId) return

      const data = payload as ActionType<typeof actions.updateBackgroundSketchData>['payload']
      state.data[currentId].formData.background_sketch_data = data

      return
    }

    case getType(actions.setStyle): {
      if (!currentId) return

      const value = payload as ActionType<typeof actions.setStyle>['payload']

      const currentStyleKey = state.data[currentId].formData.style_key
      if (currentStyleKey && currentStyleKey === value) {
        state.data[currentId].formData.style_key = INITIAL_DATA.formData.style_key
      } else {
        state.data[currentId].formData.style_key = value
      }
      return
    }
    case getType(actions.setActiveView): {
      if (!currentId) return

      state.data[currentId].activeView = payload
      return
    }
    case getType(actions.setGenerating): {
      if (!currentId) return

      const generating = payload as ActionType<typeof actions.setGenerating>['payload']

      state.data[currentId].isGenerating = generating
      state.data[currentId].generateCount = generating
        ? (state.data[currentId].generateCount ?? 0) + GENERATING_POOL_INTERVAL / 1000
        : undefined
      return
    }
    case getType(actions.setScrollToTop): {
      if (!currentId) return

      const scrollToTop = payload as ActionType<typeof actions.setScrollToTop>['payload']

      state.data[currentId].scrollToTop = scrollToTop
      return
    }
    default:
  }
}, INITIAL)

// Epics

const logSketchDoActionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.logSketchDoAction)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      currentSketchProject: apiSelectors.currentSketchProject(state),
      sketchGenreData: apiSelectors.sketchGenreData(state)
    })),
    tap(({ currentSketchProject, sketchGenreData, ...payload }) => {
      const projectData = DataUtils.getProjectParam<'sketch_project'>('sketch_project', {
        sketchProject: currentSketchProject ?? undefined,
        sketchGenreData: sketchGenreData
      })
      MixPanelUtils.track<'PROJECT__SKETCH_DO_ACTION'>('Project Sketch - Do Action', {
        ...projectData,
        ...payload
      })
    }),
    ignoreElements()
  )

const toggleStyleImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.toggleStyleImages)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      id: action.payload.id,
      selected_style_images: selectors.formData(state).selected_style_images
    })),
    map(({ id, selected_style_images }) => ({
      id,
      hasSelectedStyle: Boolean(selected_style_images?.find(value => value.id === id))
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ hasSelectedStyle }) => hasSelectedStyle),
          mergeMap(({ id }) => [
            actions.removeStyleImages({ id }),
            actions.logSketchDoAction({ do_action_type: 'remove_reused_style' }),
            snackBarActions.show({
              show: true,
              content: 'Style has been removed.'
            })
          ])
        ),
        of(param).pipe(
          filter(({ hasSelectedStyle }) => !hasSelectedStyle),
          mergeMap(({ id }) => [
            actions.addStyleImages({ id }),
            actions.logSketchDoAction({ do_action_type: 'reuse_style' }),
            snackBarActions.show({
              show: true,
              content: 'Style added.'
            })
          ])
        ),
        of(param).pipe(
          delay(2500),
          map(() => snackBarActions.close())
        )
      )
    )
  )

const retrieveSketchStyleEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.retrieveSketchStyle)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      genre: apiSelectors.currentSketchProject(state)?.genre
    })),
    filter(({ genre }) => Boolean(genre)),
    map(({ genre = 0 }) => apiActions.sketchToImage.listSketchStyles({ genre }))
  )

const generateImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.generateImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      formData: selectors.formData(state),
      sketchStyles: apiSelectors.sketchStyles(state),
      input: action.payload.input,
      input_data: action.payload.input_data,
      selectedGenre: selectors.selectedGenre(state),
      currentSketchProject: apiSelectors.currentSketchProject(state),
      sketchGenreData: apiSelectors.sketchGenreData(state),
      sketchSource: selectors.source(state)
    })),
    map(
      ({
        input,
        formData,
        sketchStyles,
        input_data,
        currentSketchProject,
        sketchGenreData,
        sketchSource
      }) => {
        const id = currentSketchProject?.id || 0
        const genre = currentSketchProject?.genre || 0

        const styleName = SketchUtils.extractStyleName(formData, sketchStyles)
        const { source } = SketchUtils.getListIdObject(formData.style_key)
        return {
          source,
          currentSketchProject,
          sketchGenreData,
          styleName,
          sketchSource,
          generateParam: {
            id,
            genre,
            input,
            ...SketchUtils.getGenerateParam(formData, currentSketchProject.outputs ?? [])
          },
          updateParam: {
            id,
            input_data
          }
        }
      }
    ),
    filter(({ generateParam: { id, style, input, style_image, custom_style, genre } }) => {
      const hasId = Boolean(id)
      const hasInput = Boolean(input)
      const hasStyle = Boolean(style || custom_style || style_image)
      const hasGenre = Boolean(genre)

      return hasId && hasInput && hasStyle && hasGenre
    }),
    mergeMap(
      ({
        generateParam,
        styleName,
        source,
        updateParam,
        currentSketchProject,
        sketchGenreData,
        sketchSource
      }) =>
        action$.pipe(
          filter(isActionOf(apiActions.sketchToImage.updateSketchProjectResponse)),
          take(1),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.sketchToImage.generateSketchProjectResponse)),
              take(1),
              tap(() => {
                const projectData = DataUtils.getProjectParam<'sketch_project'>('sketch_project', {
                  sketchProject: currentSketchProject ?? undefined,
                  sketchGenreData: sketchGenreData
                })
                MixPanelUtils.track<'PROJECT__SKETCH_GENERATE'>(
                  'Project Sketch - Generate Sketch',
                  {
                    ...projectData,
                    sketch_style: `${styleName ?? ''}`,
                    style_source: source,
                    sketch_source: sketchSource
                  }
                )
              }),
              ignoreElements(),
              startWith(apiActions.sketchToImage.generateSketchProject(generateParam))
            )
          ),
          startWith(apiActions.sketchToImage.updateSketchProject({ data: updateParam }))
        )
    )
  )

const addCustomStyleEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.uploadSketch)),
    withLatestFrom(state$),
    map(([_, state]) => {
      return {
        formData: selectors.formData(state)
      }
    }),
    filter(
      ({ formData }) => !Boolean(formData.style_key) && Boolean(formData.customStyles?.length)
    ),
    map(() => actions.setStyle(SketchUtils.getListIdKey({ id: 0, source: 'custom_style' })))
  )

const uploadSketchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.uploadSketch)),
    mergeMap(({ payload }) =>
      action$.pipe(
        filter(isActionOf(apiActions.sketchToImage.uploadSketchResponse)),
        take(1),
        map(() => actions.setSketchCommand('resetBackground')),
        startWith(apiActions.sketchToImage.uploadSketch(payload))
      )
    )
  )
const addMixStyleEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.addMixStyle)),
    withLatestFrom(state$),
    map(([_, state]) => {
      return {
        formData: selectors.formData(state)
      }
    }),
    filter(({ formData }) => !Boolean(formData.style_key) && Boolean(formData.mix_images?.length)),
    map(() => actions.setStyle(SketchUtils.getListIdKey({ id: 0, source: 'mix_image' })))
  )

const downloadAllEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.downloadAll, actions.loadAllResult])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      outputs: apiSelectors.currentSketchProject(state).outputs,
      currentSketchProjectId: apiSelectors.currentSketchProjectId(state),
      lastRequest: selectors.lastOutputRequest(state)
    })),
    mergeMap(({ lastRequest, currentSketchProjectId, outputs }) =>
      merge(
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.sketchToImage.listSketchOutputResponse)),
              take(1),
              map(() => actions.loadAllResult()),
              startWith(
                apiActions.sketchToImage.listSketchOutput({
                  param: { project: currentSketchProjectId, limit: 10 },
                  next: true
                })
              )
            )
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          map(() =>
            snackBarActions.show({
              content: `Load all sketch results...`,
              actionText: `${outputs.length} / ${lastRequest?.count}`
            })
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => !Boolean(lastRequest && lastRequest.next)),
          withLatestFrom(state$),
          map(([_, state]) => ({
            sketchProjectName: apiSelectors.currentSketchProject(state).name,
            outputs: apiSelectors.currentSketchProject(state).outputs
          })),
          map(({ outputs, sketchProjectName }) => ({
            files: outputs.map(output => ({
              fileUrl: output.image.file,
              imageName: `sketch-to-image-result_${sketchProjectName}_${
                output.image.id
              }.${UrlUtils.getFileTypeFromUrl(output.image.file)}`
            })),
            sketchProjectName
          })),
          map(({ files, sketchProjectName }) =>
            downloaderActions.multiple.executeDownloadMultipleImage({
              files,
              fileName: `sketch-to-image_${sketchProjectName}`
            })
          )
        )
      )
    )
  )

/* Listen when upload list received */
const listenOnRetrieveSketchEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.sketchToImage.retrieveSketchProjectResponse,
        apiActions.sketchToImage.generateSketchProjectResponse
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isCurrentUserFree: apiSelectors.isCurrentUserFree(state),
      isGenerating: selectors.isGenerating(state),
      generateCount: selectors.generateCount(state),
      currentSketchProject: apiSelectors.currentSketchProject(state),
      id: action.payload.id
    })),
    map(({ isGenerating, generateCount = 0, currentSketchProject, id, isCurrentUserFree }) => {
      const currentSketchProjectId = currentSketchProject?.id ?? 0
      const expect_finished = currentSketchProject?.expect_finished ?? 0
      const queue_length = currentSketchProject?.queue_length ?? 0

      const status = currentSketchProject?.status
      const hasQueue =
        expect_finished && generateCount < expect_finished + ADDITIONAL_THRESHOLD_GENERATE

      const isGeneratingButFinished = isGenerating && status === 'FINISHED'
      const shouldContinueGenerateUnknownStatus = hasQueue && status === 'UNKNOWN'
      const isProcessing = status === 'PROCESSING'
      const shouldShowErrorDialog =
        isGenerating &&
        !shouldContinueGenerateUnknownStatus &&
        !isProcessing &&
        !isGeneratingButFinished

      return {
        errorExtra: {
          elapsed_pooling: generateCount,
          expect_finished: currentSketchProject?.expect_finished,
          queue_length
        },
        currentSketchProjectId,
        id,
        isGeneratingButFinished,
        shouldContinueGenerateUnknownStatus,
        isProcessing,
        shouldShowErrorDialog,
        isCurrentUserFree
      }
    }),
    tap(({ shouldShowErrorDialog, errorExtra }) => {
      if (shouldShowErrorDialog) {
        SentryUtils.captureMessage(
          `Unable To Generate Sketch Result in expected time`,
          errorExtra,
          'error'
        )
      }
    }),
    filter(({ currentSketchProjectId, id }) => currentSketchProjectId === id),
    mergeMap(
      ({
        currentSketchProjectId,
        isGeneratingButFinished,
        shouldContinueGenerateUnknownStatus,
        isProcessing,
        shouldShowErrorDialog,
        isCurrentUserFree
      }) =>
        _compact([
          actions.setGenerating(shouldContinueGenerateUnknownStatus || isProcessing),
          isGeneratingButFinished
            ? apiActions.sketchToImage.listSketchOutput({
                param: { project: currentSketchProjectId, limit: 30 },
                loadingMessage: LOAD_OUTPUT_AFTER_GENERATE_LOADING
              })
            : null,
          isGeneratingButFinished
            ? apiActions.sketchToImage.resetSketchQueueData(currentSketchProjectId)
            : null,
          isGeneratingButFinished && isCurrentUserFree ? apiActions.users.retrieveEquity() : null,
          isGeneratingButFinished ? actions.setScrollToTop(true) : null,
          shouldShowErrorDialog
            ? dialogActions.openDialog({
                [ErrorDialog.ERROR]: {
                  dialogName: ErrorDialog.ERROR,
                  title: 'Unable To Generate Sketch Result',
                  content: [
                    `We're unable to generate sketch  result in expected time.`,
                    `Please try again in few minutes`,
                    `If this keeps happening, contact us by click on the "Question" button or send an email to  ${values.PLAYFORM_CONTACT_EMAIL}.`
                  ]
                }
              })
            : null
        ])
    )
  )
const setScrollToTopEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.setScrollToTop)),
    filter(({ payload }) => Boolean(payload)),
    delay(1000),
    map(() => actions.setScrollToTop(false))
  )

const listenRetrieveProArtFilterErrorEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(
      ({ payload }) => payload.type === getType(apiActions.sketchToImage.retrieveSketchProject)
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      error: action.payload,
      isGenerating: selectors.isGenerating(state)
    })),
    mergeMap(({ isGenerating, error }) =>
      _compact([
        actions.setGenerating(false),
        isGenerating &&
          dialogActions.addDialog({
            [ErrorDialog.ERROR]: {
              dialogName: ErrorDialog.ERROR,
              title: `Unable to retrieve sketch project data`,
              content: [
                errorUtils.flattenMessage(error).toString(),
                'Generating process is still working in the background, the result will be available when it finished. Try to refresh this page to see the finished results'
              ]
            }
          })
      ])
    )
  )

const listenOnReceiveMixStyle: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    filter(({ payload }) => Boolean(payload[AppEvents.ON_RECEIVE_MIX_STYLE])),
    map(({ payload }) => payload?.[AppEvents.ON_RECEIVE_MIX_STYLE]?.payload),
    filter(image => Boolean(image)),
    mergeMap(image =>
      from(fetch(image?.file ?? '')).pipe(
        mergeMap(response =>
          from(response.blob()).pipe(
            map(data => {
              const name = image?.id ?? 'file'
              const nameAdj = `${_truncate(`${name}`, { length: 8, omission: '' })}.jpg`
              const metadata = {
                type: 'image/jpeg'
              }
              return new File([data], nameAdj, metadata)
            }),
            map(file => actions.addMixStyle(file)),
            catchError(err =>
              of(
                dialogActions.openDialog({
                  [ErrorDialog.ERROR]: {
                    dialogName: ErrorDialog.ERROR,
                    content: 'Unable to add images'
                  }
                })
              )
            )
          )
        )
      )
    )
  )

export const epics = combineEpics(
  listenOnReceiveMixStyle,
  setScrollToTopEpic,
  listenRetrieveProArtFilterErrorEpic,
  listenOnRetrieveSketchEpic,
  toggleStyleImagesEpic,
  logSketchDoActionEpic,
  retrieveSketchStyleEpic,
  generateImageEpic,
  downloadAllEpic,
  addCustomStyleEpic,
  addMixStyleEpic,
  uploadSketchEpic
)
export default reducer
