import { apiActions, CollectionActionsType, SocialActionType } from '../actions'
import { SharedActionsType, sharedActions } from '../sharedActions'
import { Collection, PaginationResponse, Category, ImageType } from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _without from 'lodash/without'
import _reduce from 'lodash/reduce'
import _union from 'lodash/union'
import _concat from 'lodash/concat'
import _uniq from 'lodash/uniq'
import { generateImagesKey } from 'utils/DataProcessingUtils'

export type CollectionListType =
  | 'my_collection'
  | 'public'
  | 'bookmark'
  | 'list_page'
  | 'recently_used'
  | 'explore_page'
  | 'home_page'

export type CollectionsState = {
  currentCollectionId?: number
  collectionLists: {
    [key in CollectionListType]: {
      list: number[] | null
      lastReq: PaginationResponse<Collection> | null
    }
  }
  collections: {
    [collectionId: string]: Collection
  }
  categories: PaginationResponse<Category> | null
  tags: string[]
  images: {
    [paramKey: string]: PaginationResponse<ImageType>
  }
}
const INITIAL_COLLECTION_LIST = {
  list: null,
  lastReq: null
}
export const initialCollectionsState: CollectionsState = {
  currentCollectionId: undefined,
  collectionLists: {
    bookmark: { ...INITIAL_COLLECTION_LIST },
    my_collection: { ...INITIAL_COLLECTION_LIST },
    public: { ...INITIAL_COLLECTION_LIST },
    list_page: { ...INITIAL_COLLECTION_LIST },
    recently_used: { ...INITIAL_COLLECTION_LIST },
    explore_page: { ...INITIAL_COLLECTION_LIST },
    home_page: { ...INITIAL_COLLECTION_LIST }
  },
  collections: {},
  categories: null,
  tags: [],
  images: {}
}
export const collectionsReducer = createReducer<
  CollectionsState,
  CollectionActionsType | SharedActionsType | SocialActionType
>(initialCollectionsState)
  .handleAction(apiActions.collections.setCurrentCollection, (state, action) => ({
    ...state,
    currentCollectionId: action.payload
  }))
  .handleAction(apiActions.collections.listResponse, (state, action) => {
    const { data, reloadList, listType } = action.payload

    const results = data?.results ?? []
    const prevData = reloadList ? [] : state.collectionLists[listType].list ?? []
    const addedData = {
      list: [...prevData, ..._map(results, result => result.id)],
      lastReq: data
    }

    return {
      ...state,
      collectionLists: {
        ...state.collectionLists,
        [listType]: addedData
      },
      collections: {
        ...state.collections,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(
    [apiActions.collections.retrieveResponse, apiActions.collections.updateResponse],
    (state, action) => ({
      ...state,
      collections: {
        ...state.collections,
        [action.payload.id]: action.payload
      }
    })
  )
  .handleAction(apiActions.collections.createResponse, (state, action) => {
    const { id } = action.payload
    return {
      ...state,
      collections: {
        ...state.collections,
        [id]: action.payload
      },
      collectionLists: {
        ...state.collectionLists,
        home_page: {
          ...state.collectionLists.home_page,
          list: [id, ...(state.collectionLists.home_page.list ?? [])]
        },
        my_collection: {
          ...state.collectionLists.my_collection,
          list: [id, ...(state.collectionLists.my_collection.list ?? [])]
        }
      }
    }
  })
  .handleAction(apiActions.collections.copyResponse, (state, action) => {
    const { collection } = action.payload
    return {
      ...state,
      collections: {
        ...state.collections,
        [collection.id]: collection
      }
    }
  })
  .handleAction(apiActions.collections.deleteResponse, (state, action) => {
    const { [action.payload]: deletedCollection, ...collections } = state.collections
    const oldLists = state.collectionLists
    const initial = { ...oldLists }

    return {
      ...state,
      collectionLists: _reduce(
        oldLists,
        (result, value, key) => {
          result[key as CollectionListType] = {
            ...value,
            list: _without(value.list ?? [], deletedCollection.id)
          }
          return result
        },
        initial
      ),
      collections
    }
  })
  .handleAction(apiActions.collections.listCategoriesResponse, (state, action) => {
    return {
      ...state,
      categories: action.payload
    }
  })
  .handleAction(apiActions.collections.listTagsResponse, (state, action) => {
    const combinedTags = _union(state.tags, action.payload.results)
    return {
      ...state,
      tags: combinedTags
    }
  })
  .handleAction(
    [apiActions.collections.removeTagResponse, apiActions.collections.addTagResponse],
    (state, action) => {
      const { collectionId, tags } = action.payload
      const currentCollection = state.collections[collectionId]
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...currentCollection,
            tags
          }
        }
      }
    }
  )
  .handleAction(apiActions.collections.toggleCollectionBookmarkInstantResponse, (state, action) => {
    const { collectionId, bookmark } = action.payload
    const collection = state.collections[collectionId]

    return {
      ...state,
      collections: {
        ...state.collections,
        [collectionId]: {
          ...collection,
          bookmark: bookmark ? -1 : null
        }
      }
    }
  })
  .handleAction(apiActions.collections.toggleCollectionBookmarkResponse, (state, action) => {
    const { data } = action.payload
    const collection = data?.item ? state.collections[data?.item] : undefined

    return collection
      ? {
          ...state,
          collections: {
            ...state.collections,
            [collection.id]: { ...collection, bookmark: data?.id ?? null }
          }
        }
      : state
  })
  .handleAction(apiActions.collections.createImageResponse, (state, action) => {
    const data = action?.payload?.data
    const collectionId = action?.payload?.req?.collection ?? 0
    const imagesData = state.images[collectionId]
    const results = _uniq([...(imagesData?.results ?? []), data])

    return {
      ...state,
      images: {
        ...state.images,
        [collectionId]: {
          ...imagesData,
          results
        }
      }
    }
  })
  .handleAction(apiActions.collections.listImageResponse, (state, action) => {
    const { data, req, next } = action.payload
    const paramKey = generateImagesKey(req)
    const currentImages = state.images[paramKey] || {}

    /* Without next, images will be replaced, not added */
    const currentResults = next && currentImages.results ? currentImages.results : []

    return {
      ...state,
      images: {
        ...state.images,
        [paramKey]: {
          ...currentImages,
          ...data,
          results: _uniq(_concat(currentResults, data.results || []))
        }
      }
    }
  })
  .handleAction(apiActions.social.listUserCollectionResponse, (state, action) => {
    const { data } = action.payload

    const results = data?.results ?? []

    return {
      ...state,
      collections: {
        ...state.collections,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(sharedActions.reset, () => initialCollectionsState)
