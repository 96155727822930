import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const EraseIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon
      className={clsx(className)}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path d="M17.1399 3C16.6299 3 16.1199 3.2 15.7299 3.59L4.58988 14.73C3.80988 15.5 3.80988 16.77 4.58988 17.56L7.02988 20H14.6899L23.4099 11.27C24.1999 10.5 24.1999 9.23 23.4099 8.44L18.5599 3.59C18.1699 3.2 17.6499 3 17.1399 3ZM18.9999 18L16.9999 20H23.9999V18" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-2"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  )
}

export default EraseIcon
