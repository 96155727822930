import React from 'react'
import clsx from 'clsx'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'

import { EMPTY_OBJECT } from 'appConstants'

import styles from './Checkbox.module.scss'

type CheckboxColorType = CheckboxProps['color'] | 'secondary-alt'

type CheckboxComponentProps = Omit<CheckboxProps, 'color'> & {
  checked?: boolean
  children?: React.ReactNode
  classes?: CheckboxProps['classes'] & {
    checkbox?: string
    label?: string
  }
  className?: string
  // classNameCheckbox?: string
  // classNameLabel?: string
  color?: CheckboxColorType
  label?: FormControlLabelProps['label']
  labelPlacement?: FormControlLabelProps['labelPlacement']
}

const getColor = (color?: CheckboxColorType) => {
  let colorProp: CheckboxProps['color']
  let colorClassName: string = ''

  if (color === 'secondary-alt') {
    colorProp = 'secondary'
    colorClassName = styles.ColorSecondaryAlt
  } else {
    colorProp = color
  }

  return {
    colorProp,
    colorClassName
  }
}

const CheckboxComponentBasic: React.FC<CheckboxComponentProps> = props => {
  const { checked, classes, className, color, onChange } = props

  const { colorProp, colorClassName } = getColor(color)

  return (
    <Checkbox
      classes={classes}
      className={clsx(styles.Checkbox, colorClassName, className)}
      checked={checked}
      color={colorProp}
      onChange={onChange}
    />
  )
}

const CheckboxComponentWithLabel: React.FC<Partial<CheckboxComponentProps>> = props => {
  const { checked, classes, className, color, label, labelPlacement, onChange } = props

  const {
    checkbox: classNameCheckbox,
    label: classNameLabel,
    ...restClassesCheckbox
  } = classes || EMPTY_OBJECT

  return (
    <FormControlLabel
      className={clsx(styles.FormControlCheckbox, className)}
      control={
        <CheckboxComponentBasic
          classes={restClassesCheckbox}
          className={classNameCheckbox}
          checked={checked}
          color={color}
          onChange={onChange}
        />
      }
      classes={{
        label: clsx(
          styles.FormControlCheckboxLabel,
          React.isValidElement(label) ? styles.FormControlCheckboxLabelCustom : '',
          classNameLabel
        )
      }}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = props => {
  if (props.label) return <CheckboxComponentWithLabel {...props} />

  return <CheckboxComponentBasic {...props} className={props.className} />
}

export default CheckboxComponent
