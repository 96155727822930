import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  mergeMap,
  withLatestFrom,
  tap,
  debounceTime
} from 'rxjs/operators'
import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { SketchProjectListReq, SketchOutputListReq } from 'models/ApiModels'
import { push } from 'redux-first-history'
import { route } from 'routes'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { errorMessage, values } from 'appConstants'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import SentryUtils from 'utils/SentryUtils'
import { bannerActions } from 'duck/AppDuck/BannerDuck'

const listSketchStylesEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.listSketchStyles)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.listSketchStyles(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.listSketchStylesResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const deleteSketchProject: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.deleteSketchProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: { ...(apiSelectors.sketchProjects(state)?.[action.payload] ?? {}) },
      sketchGenreData: apiSelectors.sketchGenreData(state),
      ...action
    })),
    switchMap(({ type, payload, projectData, sketchGenreData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.deleteSketchProject(payload).pipe(
          tap(() => {
            MixPanelUtils.track<'PROJECT__DELETE'>(
              'Project - Delete',
              DataUtils.getProjectParam<'sketch_project'>('sketch_project', {
                sketchProject: projectData,
                sketchGenreData: sketchGenreData
              })
            )
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.deleteSketchProjectResponse(payload),
            apiActions.users.retrieveEquity(),
            push(route.SKETCH_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const listSketchGenreEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.listSketchGenre)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.listSketchGenre(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.listSketchGenreResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createSketchProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.createSketchProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.createSketchProject(payload).pipe(
          concatMap(response => [
            apiActions.sketchToImage.createSketchProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

export const SKETCH_SAVE_LOADING = 'sketch-save'

const updateSketchProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.updateSketchProject)),
    switchMap(
      ({
        type,
        payload: {
          data: { id, ...param },
          silentUpdate
        }
      }) =>
        merge(
          of(
            sharedActions.setLoading({
              loading: silentUpdate ? false : param.input_data ? SKETCH_SAVE_LOADING : true,
              type
            })
          ),
          ApiUtils.sketchToImage.updateSketchProject(id, param).pipe(
            withLatestFrom(state$),
            tap(([response, state]) => {
              const sketchGenreData = apiSelectors.sketchGenreData(state)
              if (param.name) {
                MixPanelUtils.track<'PROJECT__RENAME'>(
                  'Project - Rename',
                  DataUtils.getProjectParam<'sketch_project'>('sketch_project', {
                    sketchProject: response.data,
                    sketchGenreData
                  })
                )
              }
            }),
            concatMap(([response]) => [
              apiActions.sketchToImage.updateSketchProjectResponse(response.data),
              sharedActions.setLoading({ loading: false, type })
            ]),
            catchError(err =>
              of(sharedActions.setError({ error: err.response, type, req: { id, ...param } }))
            )
          )
        )
    )
  )
const retrieveSketchProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.sketchToImage.retrieveSketchProject,
        apiActions.sketchToImage.setCurrentSketchProject
      ])
    ),
    filter(({ payload }) => Boolean(payload)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.retrieveSketchProject(payload ?? 0).pipe(
          mergeMap(response => [
            apiActions.sketchToImage.retrieveSketchProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listSketchProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.listSketchProject)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const params = next ? { next: state.api.sketchToImage.lastSketchProjectsListReq?.next } : {}
      const extraParams = next ? undefined : (param as SketchProjectListReq)
      return { next, params, extraParams, type: action.type }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.listSketchProject(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.listSketchProjectResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listSketchOutputEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.listSketchOutput)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, loadingMessage } = action.payload
      const projectId = param?.project || 0
      const params = next
        ? { next: state.api.sketchToImage.outputList[projectId]?.lastListReq?.next }
        : {}
      const extraParams = next ? undefined : (param as SketchOutputListReq)
      return { next, params, extraParams, type: action.type, param, loadingMessage }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type, param, loadingMessage }) =>
      merge(
        of(sharedActions.setLoading({ loading: loadingMessage ?? true, type })),
        ApiUtils.sketchToImage.listSketchOutput(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.listSketchOutputResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const generateSketchProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.generateSketchProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      canTrain: apiSelectors.equity(state)?.upsellState?.canTrain ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrain }) => !canTrain),
          map(() =>
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'sketch',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            })
          )
        ),
        of(param).pipe(
          filter(({ canTrain }) => Boolean(canTrain)),
          map(({ payload, type }) => {
            const { style, custom_style = '', input, genre, id, style_image } = payload
            const formData = new FormData()
            formData.append('input', input as Blob)
            formData.append('custom_style', custom_style)
            if (style) {
              formData.append('style', `${style}`)
            }
            formData.append('genre', `${genre}`)

            if (style_image) {
              formData.append('style_image', `${style_image}`)
            }

            return { formData, type, id, payload }
          }),
          mergeMap(({ formData, type, id, payload }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.sketchToImage.generateSketchProject(id, formData).pipe(
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.sketchToImage.generateSketchProjectResponse(response.data)
                ]),
                catchError(err =>
                  of(
                    sharedActions.setError({
                      error: err.response,
                      type,
                      req: {
                        ...payload,
                        input: { size: payload.input?.size, type: payload.input?.type }
                      }
                    })
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const uploadSketchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.uploadSketch)),
    map(({ payload, type }) => {
      const { id, image } = payload
      const formData = new FormData()

      const error =
        (image?.size ?? 0) > values.MAX_IMAGE_SIZE
          ? errorMessage.ERROR_UPLOAD_TOO_LARGE.content
          : undefined

      formData.append('image', image as Blob)

      return { formData, type, id, payload, error }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ error }) => Boolean(error)),
          tap(({ error }) => {
            SentryUtils.captureMessage(
              `Unable To Upload Sketch`,
              {
                error
              },
              'log'
            )
          }),
          map(({ error }) =>
            dialogActions.openDialog({
              [ErrorDialog.ERROR]: {
                dialogName: ErrorDialog.ERROR,
                title: `Unable To Upload Images`,
                content: error
              }
            })
          )
        ),
        of(param).pipe(
          filter(({ error }) => !Boolean(error)),
          mergeMap(({ formData, type, id }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.sketchToImage.uploadSketch(id, formData).pipe(
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.sketchToImage.uploadSketchResponse(response.data)
                ]),
                catchError(err => of(sharedActions.setError({ error: err.response, type })))
              )
            )
          )
        )
      )
    )
  )

const updateThresholdEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.updateThreshold)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      currentThreshold: apiSelectors.currentSketchProject(state).sketch_threshold
    })),
    debounceTime(800),
    filter(({ payload, currentThreshold }) => payload.threshold !== currentThreshold),
    map(({ payload, type }) => {
      const { id } = payload

      return { type, id, payload }
    }),
    switchMap(({ type, id, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.updateThreshold(id, payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.updateThresholdResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const removeSketchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.removeSketch)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.removeSketch(payload.id, payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.removeSketchResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const deleteSketchOutputEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.sketchToImage.deleteSketchOutput)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      payload: action.payload,
      currentSketchProject: apiSelectors.currentSketchProject(state),
      sketchGenreData: apiSelectors.sketchGenreData(state)
    })),

    switchMap(({ type, payload, currentSketchProject, sketchGenreData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.sketchToImage.deleteSketchOutput(payload).pipe(
          tap(() => {
            const projectData = DataUtils.getProjectParam<'sketch_project'>('sketch_project', {
              sketchProject: currentSketchProject ?? undefined,
              sketchGenreData: sketchGenreData
            })
            MixPanelUtils.track<'PROJECT__SKETCH_DO_ACTION'>('Project Sketch - Do Action', {
              ...projectData,
              do_action_type: 'delete_result',
              do_action_value: payload
            })
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.sketchToImage.deleteSketchOutputResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const sketchToImageEpic = [
  deleteSketchOutputEpic,
  removeSketchEpic,
  uploadSketchEpic,
  updateThresholdEpic,
  listSketchOutputEpic,
  deleteSketchProject,
  createSketchProjectEpic,
  updateSketchProjectEpic,
  retrieveSketchProjectEpic,
  listSketchStylesEpic,
  listSketchGenreEpic,
  generateSketchProjectEpic,
  listSketchProjectEpic
]

export default sketchToImageEpic
