import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _compact from 'lodash/compact'
import { merge, of } from 'rxjs'
import { combineEpics, Epic } from 'redux-observable'
import Braintree from 'braintree-web'
import {
  filter,
  map,
  mergeMap,
  withLatestFrom,
  take,
  startWith,
  delay,
  tap,
  debounceTime
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, isActionOf, getType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { appSelectors } from 'duck/AppDuck'
import { apiSelectors, apiActions, sharedActions } from 'duck/ApiDuck'
import { values } from 'appConstants'
import MixPanelUtils from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import { TRY_MAX_COUNT, AddCreditSource } from './Models'
import { dialogActions, dialogSelectors, MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import { Channel, PaymentReq } from 'models/ApiModels'
import { ROUTE_CLOSE_TAB } from 'routes'
import { brainTreePaymentActions, brainTreePaymentSelector } from './BraintreePayment'

// Constants
const NAMESPACE = '@@page/MonetizationDialog/AddCredit'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const Utils = {
  /* If using paypal, then submitted as braintree */
  adjustSelectedChannel: (selectedChannel: number, channelData?: { [key: string]: Channel }) => {
    const braintreeChannelId = channelData?.[values.BRAINTREE_CHANNEL_NAME]?.id ?? 0
    const paypalChannelId = channelData?.[values.PAYPAL_CHANNEL_NAME]?.id

    return selectedChannel === paypalChannelId ? braintreeChannelId : selectedChannel
  },
  roundToIncrement: (value: number, increment: number) =>
    value % increment !== 0
      ? Math.ceil(value) + (increment - (Math.ceil(value) % increment))
      : value
}

export const CHANNEL_MANUAL_REDIRECT = [
  values.PAYPAL_CHANNEL_NAME,
  values.ALIPAY_CHANNEL_NAME,
  values.COINBASE_CHANNEL_NAME
]

// Actions
export const addCreditActions = {
  setPaymentLoading: createAction(creator('SET_PAYMENT_LOADING'))<boolean | string>(),

  setFormData: createAction(creator('SET_FORM_DATA'))<Partial<AddCreditState['formData']>>(),
  resetForm: createAction(creator('RESET_FORM'))(),
  submitBrainTreePayment: createAction(creator('SUBMIT_BRAINTREE_PAYMENT'))(),

  submitPayment: createAction(creator('SUBMIT_PAYMENT'))<Pick<PaymentReq, 'nonce'>>(),
  submitPaymentResponse: createAction(creator('SUBMIT_PAYMENT_RESPONSE'))(),

  getInitialMonetizationData: createAction(creator('GET_INITIAL_MONETIZATION_DATA'))(),
  verifyPayment: createAction(creator('VERIFY_PAYMENT'))<{
    paymentId: number
    maxCount: number
    intervalDelay: number
    loadingText?: string
    isRepeat?: boolean
  }>(),
  resetPayment: createAction(creator('RESET_PAYMENT'))(),

  incrementVerifyCount: createAction(creator('INCREMENT_VERIFY_COUNT'))(),
  resetVerifyCount: createAction(creator('RESET_VERIFY_COUNT'))(),
  setAddCreditSuccess: createAction(creator('SET_ADD_CREDIT_SUCCESS'))<boolean>()
}

// Selectors
const selectAddCredit = (state: RootState) => state.container.monetizationDialog.addCredit

const selectAddCreditForm = createSelector(
  selectAddCredit,
  apiSelectors.creditBalance,
  apiSelectors.equity,
  apiSelectors.productsDataAddCredit,
  dialogSelectors.dialogDatum[MonetizationDialog.ADD_CREDIT],
  apiSelectors.configVariables,
  (addCredit, creditBalance, equity, product, dialogData, configVariables) => {
    const coinbase_minimum_amount = configVariables?.coinbase_minimum_amount ?? 0
    const creditConsumed = dialogData?.additionalAction?.creditConsumed ?? 0
    const formData = addCredit.formData
    const price = equity?.cost_per_credit ?? 0
    const currency = product?.currency as string
    const quantity = formData.quantity as number
    const isCoinbase = formData.selectedChannel === values.COINBASE_CHANNEL_NAME
    const quantityMin =
      isCoinbase && formData.quantityRange[0] < coinbase_minimum_amount
        ? coinbase_minimum_amount
        : formData.quantityRange[0]
    const isCoinbaseMinError = isCoinbase && quantity < coinbase_minimum_amount

    return {
      ...formData,
      current_balance: creditBalance,
      quantityRange: [quantityMin, formData.quantityRange[1]],
      total: price * quantity,
      price,
      currency,
      isCoinbaseMinError,
      ending_balance: creditBalance + quantity - creditConsumed
    }
  }
)

export const addCreditSelectors = {
  addCredit: selectAddCredit,
  addCreditForm: selectAddCreditForm,
  brainTreeData: createSelector(selectAddCredit, addCredit => addCredit.brainTree),
  hostedFieldInstance: createSelector(
    selectAddCredit,
    addCredit => addCredit.brainTree.hostedFieldInstance
  ),
  shouldDisplayManualRedirect: createSelector(
    selectAddCreditForm,
    apiSelectors.currentPaymentData,
    selectAddCredit,
    (addCreditForm, currentPaymentData, addCredit) => {
      const selectedChannel = addCreditForm.selectedChannel
      const hosted_url = currentPaymentData?.details?.hosted_url
      const paymentLoading = addCredit.paymentLoading

      return CHANNEL_MANUAL_REDIRECT.includes(selectedChannel) && hosted_url && paymentLoading
    }
  ),
  paymentRequest: createSelector(
    apiSelectors.productsDataAddCredit,
    selectAddCreditForm,
    apiSelectors.channelData,
    apiSelectors.configVariables,
    (product, addCreditFormData, channelData, configVariables) => {
      const buy_credit_increment = configVariables?.buy_credit_increment ?? 1
      const selectedChannel = addCreditFormData.selectedChannel
      const channelId = Utils.adjustSelectedChannel(
        channelData?.[addCreditFormData.selectedChannel]?.id ?? 0,
        channelData
      )
      const paymentReq: PaymentReq = {
        save_as_default: addCreditFormData.saveAsPaymentDefault,
        product: product?.id ?? 0,
        quantity:
          Utils.roundToIncrement(addCreditFormData?.quantity || 0, buy_credit_increment) /
          buy_credit_increment,
        channel: channelId,
        redirect_url: CHANNEL_MANUAL_REDIRECT.includes(selectedChannel)
          ? ROUTE_CLOSE_TAB
          : undefined
      }

      return paymentReq
    }
  )
}

// Reducer

export type AddCreditState = {
  formData: {
    saveAsPaymentDefault: boolean
    requiredCredit?: number
    quantityRange: [number, number]
    quantity: number
    selectedChannel: Channel['name']
    source?: AddCreditSource
  }
  brainTree: {
    shouldGetNonce: boolean
    hasEmpty: boolean
    cardError?: string
    clientInstance?: Braintree.Client
    hostedFieldInstance?: Braintree.HostedFields
  }
  paymentLoading: string | boolean
  addCreditSuccess: boolean
  verifyCount: number
}

export const INITIAL: AddCreditState = {
  formData: {
    saveAsPaymentDefault: true,
    requiredCredit: undefined,
    quantityRange: [5, 999],
    quantity: 20, //in credit
    selectedChannel: values.BRAINTREE_CHANNEL_NAME,
    source: undefined
  },
  brainTree: {
    shouldGetNonce: false,
    hasEmpty: true,
    clientInstance: undefined,
    cardError: undefined,
    hostedFieldInstance: undefined
  },
  paymentLoading: false,
  verifyCount: 0,
  addCreditSuccess: false
}

const reducer = produce((state: AddCreditState, { type, payload }) => {
  switch (type) {
    case getType(addCreditActions.setPaymentLoading): {
      const value = payload as ActionType<typeof addCreditActions.setPaymentLoading>['payload']

      state.paymentLoading = value
      return
    }
    case getType(addCreditActions.resetPayment): {
      state.paymentLoading = INITIAL.paymentLoading
      state.verifyCount = INITIAL.verifyCount
      state.addCreditSuccess = INITIAL.addCreditSuccess
      return
    }
    case getType(addCreditActions.setFormData): {
      const data = payload as ActionType<typeof addCreditActions.setFormData>['payload']
      const currentFormData = state.formData
      state.formData = { ...currentFormData, ...data }
      return
    }

    case getType(addCreditActions.resetForm): {
      state.formData = { ...INITIAL.formData }
      state.paymentLoading = INITIAL.paymentLoading
      state.verifyCount = INITIAL.verifyCount
      state.addCreditSuccess = INITIAL.addCreditSuccess
      return
    }
    case getType(addCreditActions.setAddCreditSuccess): {
      const value = payload as ActionType<typeof addCreditActions.setAddCreditSuccess>['payload']

      state.addCreditSuccess = value
      return
    }
    case getType(addCreditActions.incrementVerifyCount): {
      state.verifyCount = state.verifyCount + 1
      return
    }
    case getType(addCreditActions.resetVerifyCount): {
      state.verifyCount = INITIAL.verifyCount
      return
    }
    default:
      break
  }
}, INITIAL)

// Epics

const listenOnAddCreditOpened: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        dialogActions.openDialog,
        dialogActions.addDialog,
        dialogActions.addDialogOverlay
      ])
    ),
    filter(({ payload }) => Boolean(payload['monetization.addCredit'])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      state
    })),
    map(({ payload, state }) => {
      const requiredCredit = payload['monetization.addCredit']?.requiredCredit
      const buy_credit_increment = apiSelectors.configVariables(state)?.buy_credit_increment ?? 1
      const min = Utils.roundToIncrement(INITIAL.formData.quantityRange[0], buy_credit_increment)
      const max = Utils.roundToIncrement(INITIAL.formData.quantityRange[1], buy_credit_increment)

      return {
        min,
        max,
        requiredCredit: requiredCredit ? Math.ceil(requiredCredit) : undefined,
        requiredCreditRounded: requiredCredit
          ? Utils.roundToIncrement(requiredCredit, buy_credit_increment)
          : undefined,
        source: payload['monetization.addCredit']?.source
      }
    }),
    mergeMap(({ requiredCredit, requiredCreditRounded, source, min, max }) => [
      addCreditActions.setFormData({
        quantityRange: requiredCreditRounded
          ? [requiredCreditRounded, INITIAL.formData.quantityRange[1]]
          : [min, max],
        requiredCredit,
        source,
        quantity: requiredCreditRounded ? requiredCreditRounded : INITIAL.formData.quantity
      }),
      addCreditActions.setAddCreditSuccess(false)
    ])
  )

const getInitialMonetizationDataEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(addCreditActions.getInitialMonetizationData)),
    withLatestFrom(state$),
    mergeMap(([_, state]) =>
      merge(
        of(state).pipe(
          filter(() => !apiSelectors.hasProducts(state)),
          map(() => apiActions.payment.retrieveProducts())
        ),
        of(state).pipe(
          filter(() => !apiSelectors.hasChannels(state)),
          map(() => apiActions.payment.retrieveChannels())
        ),
        of(state).pipe(
          mergeMap(() => [
            apiActions.payment.braintreeRetrievePaymentMethod(),
            apiActions.payment.retrieveCreditOverview()
          ])
        )
      )
    )
  )

const submitBrainTreePaymentEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(addCreditActions.submitBrainTreePayment)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      braintreeData: brainTreePaymentSelector.brainTreePayment(state),
      hasBraintreePaymentMethod: apiSelectors.hasBraintreePaymentMethod(state)
    })),
    map(({ braintreeData, hasBraintreePaymentMethod }) => {
      return {
        isChangePaymentMethod: braintreeData.isChangePaymentMethod,
        hasBraintreePaymentMethod
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(
            ({ isChangePaymentMethod, hasBraintreePaymentMethod }) =>
              hasBraintreePaymentMethod && !isChangePaymentMethod
          ),
          map(() => addCreditActions.submitPayment({ nonce: undefined }))
        ),
        of(param).pipe(
          filter(
            ({ isChangePaymentMethod, hasBraintreePaymentMethod }) =>
              !hasBraintreePaymentMethod || isChangePaymentMethod
          ),
          mergeMap(() =>
            action$.pipe(
              /* Step 2: Create Payment intent in the server */
              filter(isActionOf(brainTreePaymentActions.getNonceResponse)),
              take(1),
              mergeMap(({ payload }) =>
                _compact([
                  payload.nonce && addCreditActions.submitPayment({ nonce: payload.nonce }),
                  !payload.nonce && brainTreePaymentActions.setCardError(payload.error?.message),
                  !payload.nonce && addCreditActions.setPaymentLoading(false)
                ])
              ),
              startWith(brainTreePaymentActions.getNonce())
            )
          )
        )
      )
    )
  )

const submitPaymentEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addCreditActions.submitPayment)),
    withLatestFrom(state$),

    /* Step 1: construct payment input */
    map(([action, state]) => {
      const addCreditFormData = addCreditSelectors.addCreditForm(state)
      const selectedChannel = addCreditFormData.selectedChannel

      const paymentReq = addCreditSelectors.paymentRequest(state)

      return { paymentReq: { ...paymentReq, nonce: action.payload.nonce }, selectedChannel }
    }),
    filter(({ paymentReq }) => Boolean(paymentReq.channel)),
    mergeMap(param =>
      merge(
        of(addCreditActions.setPaymentLoading('Processing Payment...')),
        of(param).pipe(
          mergeMap(({ paymentReq }) =>
            action$.pipe(
              /* Step 2: Create Payment intent in the server */
              filter(isActionOf(apiActions.payment.createPaymentResponse)),
              take(1),
              withLatestFrom(state$),
              map(() => addCreditActions.submitPaymentResponse()),
              startWith(apiActions.payment.createPayment(paymentReq))
            )
          )
        )
      )
    )
  )

const submitPaymentResponseEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(addCreditActions.submitPaymentResponse)),
    withLatestFrom(state$),
    map(([_, state]) => {
      return {
        currentPaymentData: apiSelectors.currentPaymentData(state)
      }
    }),
    tap(({ currentPaymentData }) => {
      const hosted_url = currentPaymentData?.details?.hosted_url
      if (hosted_url) {
        window.open(hosted_url)
      }
    }),
    mergeMap(({ currentPaymentData }) => [
      addCreditActions.verifyPayment({
        paymentId: currentPaymentData.id,
        maxCount: 0,
        intervalDelay: 2000,
        loadingText: 'Waiting for payment completed'
      }),
      apiActions.payment.braintreeRetrievePaymentMethod(),
      addCreditActions.resetVerifyCount()
    ])
  )

/* 
  Verify Successfull payment in the server 
*/

const verifyPaymentEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addCreditActions.verifyPayment)),
    map(({ payload }) => payload),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(param => !param.isRepeat),
          map(param =>
            addCreditActions.setPaymentLoading(param.loadingText ?? 'Verifying Payment...')
          )
        ),
        of(param).pipe(
          mergeMap(({ paymentId, maxCount, intervalDelay, loadingText }) =>
            action$.pipe(
              filter(isActionOf(apiActions.payment.retrievePaymentResponse)),
              take(1),
              mergeMap(({ payload }) =>
                merge(
                  of(payload).pipe(
                    filter(payload => payload?.result?.status === 'pending'),
                    withLatestFrom(state$),
                    map(([_, state]) => ({
                      verifyCount: addCreditSelectors.addCredit(state).verifyCount,
                      paymentLoading: addCreditSelectors.addCredit(state).paymentLoading
                    })),
                    filter(({ paymentLoading }) => Boolean(paymentLoading)),
                    filter(({ verifyCount }) => verifyCount < maxCount || maxCount === 0),
                    delay(intervalDelay),
                    mergeMap(() => [
                      addCreditActions.incrementVerifyCount(),
                      addCreditActions.verifyPayment({
                        paymentId,
                        intervalDelay,
                        maxCount,
                        loadingText,
                        isRepeat: true
                      })
                    ])
                  ),
                  of(payload).pipe(
                    filter(payload => payload?.result?.status !== 'succeed'),
                    withLatestFrom(state$),
                    map(([payload, state]) => {
                      const addCreditState = addCreditSelectors.addCredit(state)
                      const verifyCount = addCreditState.verifyCount
                      const status = payload?.result?.status
                      const httpError = payload?.error?.statusText
                      let errorMessage = ''

                      if (verifyCount >= TRY_MAX_COUNT) {
                        errorMessage = `Your payment is successful, but the verification process takes too long, try to close this dialog and check your balance. Please contact us at ${values.PLAYFORM_CONTACT_EMAIL} if you have any problems.`
                      } else if (status === 'failed') {
                        errorMessage = `There something wrong when processing your payment. Please contact us at ${values.PLAYFORM_CONTACT_EMAIL} to get assistance.`
                      } else if (status) {
                        errorMessage = `Your payment is ${status}`
                      } else if (httpError) {
                        errorMessage = `Failed to verify the payment. Try to close this dialog and check your balance. Please contact us at ${values.PLAYFORM_CONTACT_EMAIL} if you have any problems. Error: ${httpError}`
                      }
                      return {
                        error: payload.error,
                        verifyCount,
                        errorMessage,
                        status,
                        addCreditState
                      }
                    }),
                    filter(({ verifyCount, status }) => {
                      if (status === 'pending' && (verifyCount < maxCount || maxCount === 0)) {
                        return false
                      }
                      return true
                    }),
                    tap(({ errorMessage, addCreditState, error }) => {
                      SentryUtils.captureMessage(
                        `Error Payment - ${errorMessage}`,
                        { addCreditState, error },
                        'fatal'
                      )
                    }),
                    mergeMap(({ errorMessage }) => [
                      addCreditActions.setPaymentLoading(false),
                      apiActions.payment.retrieveCreditOverview(),
                      brainTreePaymentActions.setCardError(errorMessage)
                    ])
                  ),

                  // If success and confirmed
                  of(payload).pipe(
                    filter(payload => payload?.result?.status === 'succeed'),
                    withLatestFrom(state$),
                    map(([_, state]) => ({
                      projectData: appSelectors.projectData(state) ?? {},
                      addCreditForm: addCreditSelectors.addCreditForm(state),
                      additionalAction:
                        dialogSelectors.dialogDatum[MonetizationDialog.ADD_CREDIT](state)
                          ?.additionalAction?.action
                    })),
                    tap(({ addCreditForm, projectData }) => {
                      MixPanelUtils.track<'USER__ADD_CREDIT'>('User - Add Credit', {
                        ...projectData,
                        price: addCreditForm['price'],
                        price_total: addCreditForm['total'],
                        quantity: addCreditForm['quantity'],
                        current_balance: addCreditForm['ending_balance'],
                        previous_balance: addCreditForm['current_balance'],
                        channel: addCreditForm.selectedChannel
                      })

                      FacebookPixelUtils.track<'BUY_TRAINING_CREDIT'>('buy_training_credit', {
                        price: addCreditForm['price'],
                        price_total: addCreditForm['total'],
                        quantity: addCreditForm['quantity']
                      })
                    }),
                    mergeMap(({ additionalAction }) =>
                      _compact([
                        additionalAction,
                        addCreditActions.setAddCreditSuccess(true),
                        apiActions.payment.retrieveCreditOverview(),
                        addCreditActions.setPaymentLoading(false),
                        eventEmiterActions.emit({
                          [AppEvents.ADD_CREDIT_SUCCESS]: {
                            event: AppEvents.ADD_CREDIT_SUCCESS
                          }
                        })
                      ])
                    )
                  )
                )
              ),
              startWith(apiActions.payment.retrievePayment(paymentId))
            )
          )
        )
      )
    )
  )

const setAddCreditFormDataEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(addCreditActions.setFormData)),
    debounceTime(1000),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: appSelectors.projectData(state) ?? {},
      quantity: action.payload.quantity ?? 0
    })),
    mergeMap(() => [brainTreePaymentActions.setCardError('')])
  )

export const listenOnErrorPayment: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(({ payload }) => payload.type === getType(apiActions.payment.createPayment)),
    map(() => addCreditActions.setPaymentLoading(false))
  )

export const epics = combineEpics(
  submitBrainTreePaymentEpic,
  listenOnAddCreditOpened,
  submitPaymentResponseEpic,
  setAddCreditFormDataEpic,
  submitPaymentEpic,
  getInitialMonetizationDataEpic,
  verifyPaymentEpic
)
export default reducer
