import React, { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal as ModalBase, ModalProps as ModalBaseProps } from '@mui/base/Modal'
import Fade from '@mui/material/Fade'
import { Route, Routes } from 'react-router-dom'
import AppPageBanner from './AppPageBanner'
import NavBarSubpage from 'containers/NavBar/NavBarSubpage'
import { RootState } from 'duck'
import { appActions, appSelectors } from 'duck/AppDuck'
import TrainProjectPage from 'containers/TrainProjectPage/Loadable'
import ProArtFilterPage from 'containers/ProArtFilterPage/Loadable'
import MixImagePage from 'containers/MixImagePage/Loadable'
import NewUpscalePanel from 'containers/UpscalePanel/NewUpscalePanel'
import SketchToImagePage from 'containers/SketchToImagePage/Loadable'
import TextToImageProjectPage from 'containers/TextToImageProjectPage/Loadable'
import TextToVideoProjectPage from 'containers/TextToVideoProjectPage/Loadable'
import AiAppProjectPage from 'containers/AiAppProjectPage/Loadable'
import SketchTextToImagePage from 'containers/SketchTextToImagePage/Loadable'
import { useInSubpage } from 'utils/Hooks'
import { route, NEW_PROJECT_ACTION } from 'routes'
import styles from './App.module.scss'

const SUBPAGE_TRANSITION_TIMEOUT = 300

export type AppSubpageProps = {}

const ModalSlot: ModalBaseProps['slots'] = {
  root: 'div'
}

// const AppSubpageBackdrop = () => {
//   return null
//   // return <div className={styles.AppSubpageBackdrop}></div>
// }

const AppSubpage: React.FC<AppSubpageProps> = () => {
  const dispatch = useDispatch()
  const hasSubPage = useSelector((state: RootState) => appSelectors.hasSubPage(state))
  const setHasSubPage = appActions.setHasSubPage

  const isInSubpage = useInSubpage()
  const open = Boolean(hasSubPage)
  const handleClose = () => dispatch(setHasSubPage(false))

  useLayoutEffect(() => {
    if (isInSubpage) dispatch(setHasSubPage(true))
    else dispatch(setHasSubPage(false))
  }, [dispatch, isInSubpage, setHasSubPage])

  return (
    <ModalBase
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={handleClose}
      className={styles.AppSubpageModal}
      // BackdropComponent={undefined}
      slots={ModalSlot}
      disableEscapeKeyDown
      closeAfterTransition
      disablePortal
    >
      <Fade timeout={SUBPAGE_TRANSITION_TIMEOUT} in={open} appear={open}>
        <div className={styles.AppSubpage}>
          <NavBarSubpage />
          <AppPageBanner />

          <Routes>
            <Route path={`${route.TRAIN_PROJECTS.getPath(2)}`}>
              <Route index element={<TrainProjectPage />} />
              <Route path={`${route.TRAIN_PROJECTS.paths[2]}`} element={<TrainProjectPage />}>
                <Route path={`${route.TRAIN_PROJECTS.paths[3]}`} element={<TrainProjectPage />}>
                  <Route path={`${route.TRAIN_PROJECTS.paths[4]}`} element={<TrainProjectPage />} />
                </Route>
              </Route>
            </Route>
            <Route path={route.MIX_PROJECTS.getPath(2)}>
              <Route index element={<MixImagePage />} />
              <Route path={route.MIX_PROJECTS.paths[2]} element={<MixImagePage />} />
            </Route>
            <Route path={route.SKETCH_PROJECTS.getPath(2)} element={<SketchToImagePage />} />
            <Route path={route.PRO_FILTER_PROJECTS.getPath(2)} element={<ProArtFilterPage />} />
            <Route
              path={route.UPSCALES.getPath(1, `/${NEW_PROJECT_ACTION}`)}
              element={<NewUpscalePanel />}
            />
            <Route
              path={route.TEXT_TO_IMAGE_PROJECTS.getPath(2)}
              element={<TextToImageProjectPage />}
            />
            <Route
              path={route.TEXT_TO_VIDEO_PROJECTS.getPath(2)}
              element={<TextToVideoProjectPage />}
            />
            <Route path={route.GENERIC_APP_PROJECTS.getPath(2)}>
              <Route index element={<AiAppProjectPage />} />

              <Route path={`${route.GENERIC_APP_PROJECTS.paths[2]}`} element={<AiAppProjectPage />}>
                <Route
                  path={`${route.GENERIC_APP_PROJECTS.paths[3]}`}
                  element={<AiAppProjectPage />}
                ></Route>
              </Route>
            </Route>

            <Route
              path={route.SKETCHTEXT_TO_IMAGE_PROJECTS.getPath(2)}
              element={<SketchTextToImagePage />}
            />
          </Routes>
        </div>
      </Fade>
    </ModalBase>
  )
}

export default AppSubpage
