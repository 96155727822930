import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { GeneralDialogItemProps } from '..'
import { RootState, RootActionType } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import Button from 'components/Button'
import { values } from 'appConstants'
import DialogWrapper from '../DialogWrapper'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import styles from '../Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  changeEmailData: appSelectors.changeEmail.changeEmail(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog
    },
    dispatch
  )

type DialogEmailSuccessfullyChangedProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
> &
  GeneralDialogItemProps

const DialogEmailSuccessfullyChanged: React.FC<DialogEmailSuccessfullyChangedProps> = props => {
  const { activeDialog, changeEmailData, onClose } = props
  const { formData } = changeEmailData

  return (
    <DialogWrapper
      dialogConfig={{
        className: clsx(styles.EmailDialog, styles.ActionRight)
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">Email address successfully changed!</Typography>}
      actions={
        <Button color="secondary" onClick={onClose}>
          Finish
        </Button>
      }
      content={
        <Fragment>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              To log into Playform, you will now use your new address:
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography color="secondary" variant="body2">
              {formData?.changedEmail ?? ''}
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              If you didn’t ask to change your sign-in email, it’s possible that someone is trying
              to access your account. If this is the case, please email us at
              <a
                href={`mailto:${values.PLAYFORM_CONTACT_EMAIL}`}
              >{` ${values.PLAYFORM_CONTACT_EMAIL} `}</a>
              right away.
            </Typography>
          </div>
        </Fragment>
      }
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogEmailSuccessfullyChanged)
