import { RootState } from 'duck'
import { createSelector } from 'reselect'
import projectsSelectors, { projectSummaryData } from './ProjectsSelectors'
import mixImageSelectors from './MixImageSelectors'
import engineSelectors from './EngineSelectors'
import collectionSelectors from './CollectionSelectors'
import paymentSelectors from './PaymentSelectors'
import socialSelectors from './SocialSelectors'
import userSelectors from './UserSelectors'
import sketchToImageSelectors from './SketchToImageSelectors'
import imageEnhancementSelectors from './ImageEnhancementSelectors'
import proArtFilterSelectors from './ProArtFilterSelectors'
import textToImageSelectors from './TextToImageSelectors'
import { apiActions } from '../index'
import { getType } from 'typesafe-actions'
import { firebaseActions } from 'duck/FirebaseDuck'
import { completeMineProjectData } from 'utils/DataProcessingUtils'
import genericAppSelectors from './GenericAppSelectors'
import sketchTextToImageSelectors from './SketchTextToImageSelectors'

const errors = (state: RootState) => {
  return state?.api?.shared?.errors ?? {}
}
const updatePhoneNumberError = (state: RootState) => {
  return state.api.shared.errors[getType(firebaseActions.updatePhoneNumber)]
}

const verifyPhoneError = (state: RootState) => {
  return state.api.shared.errors[getType(firebaseActions.verifyPhone)]
}

const imageListFetchState = (state: RootState) => {
  return state?.api?.shared?.imageListFetchState ?? {}
}

const artMineState = (state: RootState) => {
  return state?.api?.artMine ?? {}
}

const artMineProjectData = createSelector(artMineState, artMine => artMine.mineProject.data)
const artMineSignatureData = createSelector(artMineState, artMine => artMine.signatures)
const artMineConfig = createSelector(artMineState, artMine => artMine.mineConfig)
const selectCurrentUserAddress = createSelector(artMineState, artMine => artMine.accounts?.[0])
const artMineProjectListId = createSelector(artMineState, artMine => artMine.mineProject.list)
const artMineProjectList = createSelector(artMineProjectData, artMineProjectListId, (data, list) =>
  list.map(id => {
    const item = data[id]

    return completeMineProjectData(item)
  })
)

const error = {
  'projects.listRandomImages': createSelector(
    errors,
    errors => errors[getType(apiActions.projects.listRandomImages)]
  ),
  'mixImage.listRandomImages': createSelector(
    errors,
    errors => errors[getType(apiActions.mixImage.listRandomImages)]
  )
}

const loadings = (state: RootState) => {
  return state?.api?.shared?.loadings ?? {}
}

const loading = {
  'users.update': createSelector(loadings, loadings => loadings[getType(apiActions.users.update)]),
  'users.retrieve': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.retrieve)]
  ),
  'users.updateUiExtras': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.updateUiExtras)]
  ),
  'users.sendQuestion': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.sendQuestion)]
  ),
  'users.retrieveEquity': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.retrieveEquity)]
  ),
  'users.retrieveEquityConfig': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.retrieveEquityConfig)]
  ),
  'users.listNotification': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.listNotification)]
  ),
  'users.listNotificationTemplate': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.listNotificationTemplate)]
  ),
  'users.listNotificationPreferences': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.listNotificationPreferences)]
  ),
  'users.updatePhone': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.updatePhone)]
  ),
  'users.checkPhoneUsed': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.checkPhoneUsed)]
  ),
  'users.updateAlias': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.updateAlias)]
  ),
  'users.uploadUserPicture': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.users.uploadUserPicture)]
  ),
  'projects.retrieveOutputImages': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.retrieveOutputImages)]
  ),
  'projects.createInferenceRecord': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.createInferenceRecord)]
  ),
  'projects.copy': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.copy)]
  ),
  'projects.delete': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.delete)]
  ),
  'projects.retrieve': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.retrieve)]
  ),
  'projects.update': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.update)]
  ),
  'projects.listRandomImages': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.listRandomImages)]
  ),
  'projects.generateProjectMix': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.generateProjectMix)]
  ),
  'projects.generateRandomProjectMix': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.generateRandomProjectMix)]
  ),
  'projects.retrieveProjectMix': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.retrieveProjectMix)]
  ),
  'projects.createProjectMix': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.createProjectMix)]
  ),

  'imageEnhancement.retrieveUpscaleImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.retrieveUpscaleImage)]
  ),
  'imageEnhancement.createUpscaleImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.createUpscaleImage)]
  ),
  'imageEnhancement.retrieveUserImageUpscales': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.retrieveUserImageUpscales)]
  ),
  'imageEnhancement.listUpscaleImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.listUpscaleImage)]
  ),
  'imageEnhancement.retrieveAdjustedImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.retrieveAdjustedImage)]
  ),
  'imageEnhancement.createAdjustedImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.createAdjustedImage)]
  ),
  'imageEnhancement.connectAdjustedImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.connectAdjustedImage)]
  ),
  'imageEnhancement.deleteAdjustedImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.imageEnhancement.deleteAdjustedImage)]
  ),
  'collections.create': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.collections.create)]
  ),
  'collections.list': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.collections.list)]
  ),
  'collections.update': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.collections.update)]
  ),
  'collections.retrieve': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.collections.retrieve)]
  ),
  'collections.listTags': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.collections.listTags)]
  ),
  'collections.toggleCollectionBookmark': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.collections.toggleCollectionBookmark)]
  ),
  'collections.listImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.collections.listImage)]
  ),
  'inputs.moveCollection': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.inputs.moveCollection)]
  ),
  'inputs.retrieve': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.inputs.retrieve)]
  ),
  'inputs.update': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.inputs.update)]
  ),
  'projects.list': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.list)]
  ),
  'projects.create': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.create)]
  ),
  'projects.stop': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.stop)]
  ),
  'projects.start': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.start)]
  ),
  'projects.more': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.more)]
  ),
  'projects.listActiveProjects': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.listActiveProjects)]
  ),
  'projects.toggleUserImageBookmark': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.toggleUserImageBookmark)]
  ),
  'projects.listUserImageBookmark': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.projects.listUserImageBookmark)]
  ),
  'payment.braintreeRetrievePaymentMethod': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.braintreeRetrievePaymentMethod)]
  ),
  'payment.braintreeRemovePaymentMethod': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.braintreeRemovePaymentMethod)]
  ),
  'payment.braintreeCreatePaymentMethod': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.braintreeCreatePaymentMethod)]
  ),
  'payment.listCreditChangeLog': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.listCreditChangeLog)]
  ),
  'payment.retrieveChannels': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.retrieveChannels)]
  ),
  'payment.retrieveCreditOverview': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.retrieveCreditOverview)]
  ),

  'payment.retrieveProducts': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.retrieveProducts)]
  ),
  'payment.retrievePayment': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.retrievePayment)]
  ),

  'payment.cancelSubscription': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.cancelSubscription)]
  ),
  'payment.createSubscription': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.createSubscription)]
  ),
  'payment.updateSubscription': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.updateSubscription)]
  ),
  'payment.createDownloadImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.createDownloadImage)]
  ),
  'payment.listDownloadImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.listDownloadImage)]
  ),
  'payment.retrieveDownloadImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.payment.retrieveDownloadImage)]
  ),
  'payment.downloadingImage': createSelector(
    loadings,
    loadings =>
      loadings[getType(apiActions.payment.retrieveDownloadImage)] ||
      loadings[getType(apiActions.payment.createDownloadImage)]
  ),
  'engine.listEngineConfig': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.engine.listEngineConfig)]
  ),
  'engine.createClip': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.engine.createClip)]
  ),
  'engine.listClip': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.engine.listClip)]
  ),
  'engine.retrieveClip': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.engine.retrieveClip)]
  ),
  'engine.updateClip': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.engine.updateClip)]
  ),
  'engine.generateClipVideo': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.engine.generateClipVideo)]
  ),
  'engine.generateClipPreview': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.engine.generateClipPreview)]
  ),
  'sketchToImage.generateSketchProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.generateSketchProject)]
  ),
  'sketchToImage.createSketchProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.createSketchProject)]
  ),
  'sketchToImage.updateSketchProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.updateSketchProject)]
  ),
  'sketchToImage.listSketchProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.listSketchProject)]
  ),
  'sketchToImage.listSketchStyles': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.listSketchStyles)]
  ),
  'sketchToImage.listSketchGenre': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.listSketchGenre)]
  ),
  'sketchToImage.listSketchOutput': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.listSketchOutput)]
  ),
  'sketchToImage.uploadSketch': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.uploadSketch)]
  ),
  'sketchToImage.updateThreshold': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.updateThreshold)]
  ),
  'sketchToImage.removeSketch': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchToImage.removeSketch)]
  ),
  'social.createPost': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.createPost)]
  ),
  'social.listPost': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listPost)]
  ),
  'social.listUserPost': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listUserPost)]
  ),
  'social.listUserProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listUserProject)]
  ),
  'social.listUserCollection': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listUserCollection)]
  ),
  'social.listFeaturedPost': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listFeaturedPost)]
  ),
  'social.listThread': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listThread)]
  ),
  'social.listBannerImages': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listBannerImages)]
  ),
  'social.listComment': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.listComment)]
  ),
  'social.createComment': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.createThread)]
  ),
  'social.createThread': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.social.createThread)]
  ),

  'mixImage.retrieveOriginImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.retrieveOriginImage)]
  ),
  'mixImage.listRandomImages': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.listRandomImages)]
  ),
  'mixImage.retrieveSpaceImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.retrieveSpaceImage)]
  ),
  'mixImage.listMixImageGenre': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.listMixImageGenre)]
  ),
  'mixImage.createMixImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.createMixImage)]
  ),
  'mixImage.generateMixImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.generateMixImage)]
  ),
  'mixImage.listMixImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.listMixImage)]
  ),
  'mixImage.generateMixImagePreview': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.generateMixImagePreview)]
  ),
  'mixImage.createUploadImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.createUploadImage)]
  ),
  'mixImage.retrieveMixImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.retrieveMixImage)]
  ),
  'mixImage.listUploadImage': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.mixImage.listUploadImage)]
  ),
  'proArtFilter.generateProArtFilter': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.generateProArtFilter)]
  ),
  'proArtFilter.createProArtFilter': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.createProArtFilter)]
  ),
  'proArtFilter.updateProArtFilter': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.updateProArtFilter)]
  ),
  'proArtFilter.listProArtFilter': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.listProArtFilter)]
  ),
  'proArtFilter.listProArtFilterStyles': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.listProArtFilterStyles)]
  ),
  'proArtFilter.listProArtFilterGenres': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.listProArtFilterGenres)]
  ),
  'proArtFilter.uploadProArtFilterContent': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.uploadProArtFilterContent)]
  ),
  'proArtFilter.listProArtFilterOutput': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.proArtFilter.listProArtFilterOutput)]
  ),
  'artMine.createArtMineProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.artMine.createArtMineProject)]
  ),
  'artMine.retrieveSignature': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.artMine.retrieveSignature)]
  ),
  'artMine.retrieveConfig': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.artMine.retrieveConfig)]
  ),
  'artMine.listUserArtmineProjects': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.artMine.listUserArtmineProjects)]
  ),
  'textToImage.createTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.textToImage.createTIProject)]
  ),
  'textToImage.listTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.textToImage.listTIProject)]
  ),
  'textToImage.listTIProjectOutput': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.textToImage.listTIProjectOutput)]
  ),
  'textToImage.generateTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.textToImage.generateTIProject)]
  ),

  'genericApp.createGenericAppProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.genericApp.createGenericAppProject)]
  ),
  'genericApp.listGenericAppProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.genericApp.listGenericAppProject)]
  ),
  'genericApp.listGenericAppOutput': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.genericApp.listGenericAppOutput)]
  ),
  'sketchTextToImage.listSTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.listSTIProject)]
  ),
  'sketchTextToImage.createSTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.createSTIProject)]
  ),
  'sketchTextToImage.generateSTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.generateSTIProject)]
  ),
  'sketchTextToImage.retrieveSTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.retrieveSTIProject)]
  ),
  'sketchTextToImage.listSTIProjectOutput': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.listSTIProjectOutput)]
  ),
  'sketchTextToImage.uploadSketch': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.uploadSketch)]
  ),
  'sketchTextToImage.updateThreshold': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.updateThreshold)]
  ),
  'sketchTextToImage.removeSketch': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.removeSketch)]
  ),
  'sketchTextToImage.updateSTIProject': createSelector(
    loadings,
    loadings => loadings[getType(apiActions.sketchTextToImage.updateSTIProject)]
  )
}

const isUserHasProject = createSelector(
  projectSummaryData,
  mixImageSelectors.mixImageProjectListId,
  sketchToImageSelectors.sketchProjectListId,
  (projectSummaryData, mixImageProjectListId, sketchProjectListId) => {
    const hasMixProject = Boolean(mixImageProjectListId?.length)
    const hasSketchProject = Boolean(sketchProjectListId?.length)

    const projectTotal = projectSummaryData?.project_count_total
    return projectTotal >= 1 || hasMixProject || hasSketchProject
  }
)

const userHasSubscriptionNoPaymentMethod = (state: RootState) => {
  return state?.api?.users.userHasSubscriptionNoPaymentMethod
}

export const apiSelectors = {
  isUserHasProject,
  error,
  loading,
  errors,
  currentUserAddress: selectCurrentUserAddress,
  artMineProjectData,
  artMineSignatureData,
  artMineProjectList,
  artMineConfig,
  userHasSubscriptionNoPaymentMethod,
  verifyPhoneError,
  updatePhoneNumberError,
  loadings,
  imageListFetchState,
  ...textToImageSelectors,
  ...imageEnhancementSelectors,
  ...userSelectors,
  ...projectsSelectors,
  ...mixImageSelectors,
  ...collectionSelectors,
  ...paymentSelectors,
  ...socialSelectors,
  ...sketchToImageSelectors,
  ...engineSelectors,
  ...proArtFilterSelectors,
  ...genericAppSelectors,
  ...sketchTextToImageSelectors
}
