import { TextTransform, UrlUtils } from 'utils/TextUtils'
import produce from 'immer'
import {
  ProArtFilterStyle,
  ProArtFilterGenerateReq,
  ProArtFilterCreateReq,
  ProArtFilterUploadContentReq
} from 'models/ApiModels'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  map,
  withLatestFrom,
  mergeMap,
  tap,
  startWith,
  take,
  delay,
  ignoreElements,
  switchMap
} from 'rxjs/operators'
import _find from 'lodash/find'
import _toNumber from 'lodash/toNumber'
import _compact from 'lodash/compact'
import _toInteger from 'lodash/toInteger'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { merge, of } from 'rxjs'
import { createSelector } from 'reselect'
import { values, urlParam } from 'appConstants'
import { appSelectors } from 'duck/AppDuck'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import { route } from 'routes'
import { replace } from 'redux-first-history'
import MixPanelUtils, { DataUtils, Events } from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { errorUtils } from 'utils/DataProcessingUtils'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
import { ImageEditData } from 'components/ImageCropper/Models'

// Constants
const NAMESPACE = '@@page/ProArtFilterPage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export type ViewType = '' | 'styles' | 'upload' | 'result'
export const GENERATING_POOL_INTERVAL = 2000
export const ADDITIONAL_THRESHOLD_GENERATE = 4 //in second
export const LOAD_OUTPUT_AFTER_GENERATE_LOADING = 'load_output_after_generate'

export type LogProArtFilterDoActionParam = Pick<
  Events['PROJECT__PROARTFILTER_DO_ACTION']['properties'],
  'do_action_type' | 'do_action_value'
>

type GenerateParam = { enableMask: boolean; mask?: File | null }

// Actions
export const actions = {
  retrieveProArtFilterStyle: createAction(creator('RETRIEVE_PROARTFILTER_STYLE'))(),
  uploadContent: createAction(creator('UPLOAD_CONTENT'))<{
    uploadParam: Pick<ProArtFilterUploadContentReq, 'content'>
    generateParam: GenerateParam
  }>(),
  updateGenre: createAction(creator('UPDATE_GENRE'))<number>(),
  setCurrentProFilterId: createAction(creator('SET_CURRENT_PRO_FILTER_ID'))<number | undefined>(),

  addCustomStyles: createAction(creator('ADD_CUSTOM_STYLE_ORIGINAL'))<File[]>(),
  updateCustomStyle: createAction(creator('UPDATE_CUSTOM_STYLE'))<{
    index: number
    data: Partial<CustomStyle>
  }>(),
  removeCustomStyle: createAction(creator('REMOVE_CUSTOM_STYLE'))<{ index: number }>(),

  updateFormData: createAction(creator('UPDATE_FORM_DATA'))<
    Partial<
      Pick<
        FormData,
        | 'content_weight'
        | 'style_weight'
        | 'genre'
        | 'imageFile'
        | 'imageFileForUpload'
        | 'imageFileCropped'
        | 'imageFileEdit'
      >
    >
  >(),

  setStyle: createAction(creator('SET_STYLE'))<ListIdType>(),
  handleError: createAction(creator('HANDLE_ERROR'))<any>(),
  createProArtFilterProject: createAction(
    creator('CREATE_PROARTFILTER_PROJECT')
  )<ProArtFilterCreateReq>(),
  logProArtFilterDoAction: createAction(
    creator('LOG_PROARTFILTER_DO_ACTION')
  )<LogProArtFilterDoActionParam>(),
  startOverForm: createAction(creator('START_OVER_FORM'))(),
  downloadAll: createAction(creator('DOWNLOAD_ALL'))(),
  setScrollToTop: createAction(creator('SCROLL_TO_TOP'))<boolean>(),
  loadAllResult: createAction(creator('LOAD_ALL_RESULT'))(),
  generateImage: createAction(creator('GENERATE_IMAGE'))<GenerateParam>(),
  setActiveView: createAction(creator('SET_ACTIVE_VIEW'))<ViewType>(),
  setGenerating: createAction(creator('SET_GENERATING'))<boolean>()
}

export type ListIdType = {
  id: number
  source: 'style' | 'custom_style'
}
export const SEPARATOR = '-_-'

export const proArtFilterUtils = {
  getListIdKey: (param: ListIdType) => `${param.source}${SEPARATOR}${param.id}`,
  getListIdObject: (listIdKey: string): ListIdType => {
    const param = listIdKey.split(SEPARATOR)

    return {
      id: _toNumber(param[1]),
      source: param[0] as ListIdType['source']
    }
  },
  getFileName: (input?: File) => {
    const splited = input?.name?.split('.') ?? []

    return input ? `${splited[0].substring(0, 20)}.${splited[splited.length - 1]}` : ''
  },
  extractStyleName: (
    formData: FormData,
    customStyles: ProArtFilterPageState['customStyles'],
    proArtFilterStyle?: ProArtFilterStyle[]
  ): string => {
    const { style_key } = formData
    const { source, id } = proArtFilterUtils.getListIdObject(style_key)

    if (source === 'custom_style') {
      return `${source}-${customStyles?.[id]?.original.name ?? ''}`
    }

    if (source === 'style') {
      return proArtFilterStyle?.find(proArtFilterStyle => proArtFilterStyle.id === id)?.name ?? ''
    }
    return ''
  },
  getGenerateParam: (
    formData: FormData,
    customStyles: ProArtFilterPageState['customStyles'],
    generateParam: GenerateParam
  ): Pick<ProArtFilterGenerateReq, 'style' | 'custom_style' | 'mask'> => {
    const { style_key } = formData
    const { id, source } = proArtFilterUtils.getListIdObject(style_key)

    const mask = generateParam.enableMask ? generateParam.mask : undefined

    if (source === 'custom_style') {
      return { custom_style: customStyles?.[id]?.file, mask }
    } else {
      return { style: id ?? 0, mask }
    }
  }
}

// Selectors
const selectProArtFilterPage = (state: RootState) => state.container.proArtFilterPage

const selectSelectedGenre = createSelector(
  apiSelectors.currentProArtFilterProject,
  apiSelectors.proArtFilterGenres,
  (currentProArtFilterProject, proArtFilterGenres) => {
    const genre = currentProArtFilterProject?.genre
    return _find(proArtFilterGenres, value => value.id === genre)
  }
)

const selectFormData = createSelector(
  selectProArtFilterPage,
  apiSelectors.currentProArtFilterProject,
  (proArtFilterPage, currentProArtFilterProject) =>
    currentProArtFilterProject?.id
      ? proArtFilterPage.formData[currentProArtFilterProject.id ?? 0] ?? INITIAL_FORM_DATA
      : INITIAL_FORM_DATA
)
const selectIsGenerating = createSelector(
  selectProArtFilterPage,
  proArtFilterPage => proArtFilterPage.isGenerating
)
const selectActiveView = createSelector(
  selectProArtFilterPage,
  proArtFilterPage => proArtFilterPage.activeView
)

const selectCustomStyles = createSelector(selectProArtFilterPage, value => value.customStyles)

const selectCurrentStyleData = createSelector(
  selectFormData,
  selectCustomStyles,
  apiSelectors.currentProArtFilterProject,
  apiSelectors.proArtFilterStyles,
  (formData, customStyles, currentProArtFilterProject, proArtFilterStyles) => {
    const { style_key } = formData
    const { id, source } = proArtFilterUtils.getListIdObject(style_key)
    const currentCustomStyle = currentProArtFilterProject?.custom_style
    const currentStyle = currentProArtFilterProject?.style

    if (!style_key && currentCustomStyle) {
      return { image: currentCustomStyle.file, name: 'Uploaded Style', isLastUsedStyle: true }
    }

    if (!style_key && currentStyle) {
      const currentStyleData = proArtFilterStyles.find(value => value.id === currentStyle)
      return { image: currentStyleData?.cover, name: currentStyleData?.name }
    }

    if (source === 'style') {
      const proArtFilterStyle = proArtFilterStyles.find(value => value.id === id)
      return { image: proArtFilterStyle?.cover, name: proArtFilterStyle?.name }
    }
    if (source === 'custom_style') {
      return {
        id,
        customStyle: customStyles?.[id],
        name: proArtFilterUtils.getFileName(customStyles?.[id]?.original)
      }
    }
  }
)

export const selectors = {
  activeView: selectActiveView,
  formData: selectFormData,
  customStyles: selectCustomStyles,
  unprocessedCustomStyles: createSelector(
    selectCustomStyles,
    customStyles =>
      customStyles
        ?.map((value, index) => ({ ...value, index }))
        .filter(value => !Boolean(value.file)) ?? []
  ),
  styleKey: createSelector(selectFormData, formData => formData.style_key),
  hasImageFile: createSelector(selectFormData, formData =>
    Boolean(formData.imageFileCropped || formData.imageFile)
  ),
  imageFile: createSelector(selectFormData, formData => formData.imageFile),
  imageFileEdit: createSelector(selectFormData, formData => formData.imageFileEdit),
  imageFileCropped: createSelector(selectFormData, formData => formData.imageFileCropped),
  selectedGenre: selectSelectedGenre,
  lastOutputRequest: createSelector(
    apiSelectors.currentProArtFilterProjectOutputList,
    outputList => outputList?.lastListReq
  ),
  isGenerating: selectIsGenerating,
  generateCount: createSelector(
    selectProArtFilterPage,
    proArtFilterPage => proArtFilterPage.generateCount
  ),
  generateLoading: createSelector(
    selectIsGenerating,
    apiSelectors.loading['proArtFilter.generateProArtFilter'],
    apiSelectors.loading['proArtFilter.listProArtFilterOutput'],
    (isGenerating, generateProArtFilterLoading, listProArtFilterOutputLoading) =>
      Boolean(isGenerating || generateProArtFilterLoading) ||
      Boolean(listProArtFilterOutputLoading === LOAD_OUTPUT_AFTER_GENERATE_LOADING)
  ),
  hasImage: createSelector(
    apiSelectors.currentProArtFilterProject,
    selectFormData,
    (currentProArtFilterProject, formData) => {
      const content = currentProArtFilterProject?.content
      const imageFile = formData.imageFile

      const hasUploadedImage = Boolean(content)
      const hasImageFile = Boolean(imageFile)
      const hasImage = hasUploadedImage || hasImageFile

      return hasImage
    }
  ),
  scrollToTop: createSelector(
    selectProArtFilterPage,
    proArtFilterPage => proArtFilterPage.scrollToTop
  ),
  selectedGenreText: createSelector(selectSelectedGenre, selectedGenre => selectedGenre?.name),
  hasCurrentStyleData: createSelector(
    selectFormData,
    selectCurrentStyleData,
    (formData, currentStyleData) =>
      Boolean(formData.style_key) &&
      Boolean(currentStyleData?.image || currentStyleData?.customStyle)
  ),
  currentStyleData: selectCurrentStyleData
}

export const STYLE_WEIGHT_RANGE = {
  min: 0,
  max: 2
}
export const CONTENT_WEIGHT_RANGE = {
  min: 0,
  max: 2
}

export type FormData = {
  style_key: string
  imageFile?: File
  imageFileCropped?: File
  imageFileForUpload?: File
  imageFileEdit?: ImageEditData
  genre: number
} & Pick<ProArtFilterGenerateReq, 'content_weight' | 'style_weight'>

export type CustomStyle = { file?: File; original: File; edit?: ImageEditData }

export type ProArtFilterPageState = {
  customStyles?: CustomStyle[]
  currentProFilterId?: number
  formData: Record<number, FormData>
  activeView: ViewType
  isGenerating: boolean
  generateCount?: number
  scrollToTop: boolean
}

export const INITIAL_FORM_DATA: FormData = {
  style_key: '',
  content_weight: 1.2,
  style_weight: 0.8,
  genre: 0
}

export const INITIAL: ProArtFilterPageState = {
  customStyles: [],
  currentProFilterId: undefined,
  formData: {},
  isGenerating: false,
  generateCount: undefined,
  activeView: 'styles',
  scrollToTop: false
}

const reducer = produce((state: ProArtFilterPageState, { type, payload }) => {
  switch (type) {
    case getType(actions.updateCustomStyle): {
      const { index, data } = payload as ActionType<typeof actions.updateCustomStyle>['payload']

      const customStyles = state.customStyles ?? []

      if (customStyles[index]) {
        customStyles[index] = {
          ...customStyles[index],
          ...data
        }

        state.customStyles = customStyles
      }

      return
    }
    case getType(actions.addCustomStyles): {
      const files = payload as ActionType<typeof actions.addCustomStyles>['payload']

      state.customStyles = [
        ...files.map(file => ({ original: file }), ...(state.customStyles ?? []))
      ]
      return
    }
    case getType(actions.removeCustomStyle): {
      const { index } = payload as ActionType<typeof actions.removeCustomStyle>['payload']

      const customStyles = state.customStyles ?? []
      customStyles.splice(index, 1)
      state.customStyles = [...customStyles]
      return
    }
    case getType(actions.setStyle): {
      const payloadTyped = payload as ActionType<typeof actions.setStyle>['payload']
      const key = proArtFilterUtils.getListIdKey(payloadTyped)
      const { currentProFilterId } = state

      if (currentProFilterId) {
        const currentStyleKey = state.formData[currentProFilterId].style_key
        if (currentStyleKey && currentStyleKey === key) {
          state.formData[currentProFilterId].style_key = INITIAL_FORM_DATA.style_key
        } else {
          state.formData[currentProFilterId].style_key = key
        }
      }
      return
    }
    case getType(actions.startOverForm): {
      const { currentProFilterId } = state

      if (currentProFilterId) {
        state.formData[currentProFilterId] = {
          ...(state.formData[currentProFilterId] ?? INITIAL_FORM_DATA),
          style_key: INITIAL_FORM_DATA.style_key,
          style_weight: INITIAL_FORM_DATA.style_weight,
          content_weight: INITIAL_FORM_DATA.content_weight
        }
      }
      return
    }
    case getType(actions.updateFormData): {
      const payloadTyped = payload as ActionType<typeof actions.updateFormData>['payload']
      const { currentProFilterId } = state

      if (currentProFilterId) {
        state.formData[currentProFilterId] = {
          ...(state.formData[currentProFilterId] ?? INITIAL_FORM_DATA),
          ...payloadTyped
        }
      }
      return
    }
    case getType(actions.setActiveView): {
      const payloadTyped = payload as ActionType<typeof actions.setActiveView>['payload']

      state.activeView = payloadTyped
      return
    }
    case getType(actions.setCurrentProFilterId): {
      const payloadTyped = payload as ActionType<typeof actions.setCurrentProFilterId>['payload']

      state.currentProFilterId = payloadTyped
      return
    }
    case getType(actions.setGenerating): {
      const generating = payload as ActionType<typeof actions.setGenerating>['payload']

      state.isGenerating = generating
      state.generateCount = generating
        ? (state.generateCount ?? 0) + GENERATING_POOL_INTERVAL / 1000
        : undefined
      return
    }
    case getType(actions.setScrollToTop): {
      const scrollToTop = payload as ActionType<typeof actions.setScrollToTop>['payload']

      state.scrollToTop = scrollToTop
      return
    }
    default:
  }
}, INITIAL)

// Epics

const createProArtFilterProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.createProArtFilterProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      name: action.payload.name,
      styleId: action.payload.styleId
    })),
    filter(({ name }) => Boolean(name)),
    switchMap(({ name, styleId }) =>
      action$.pipe(
        filter(isActionOf(apiActions.proArtFilter.createProArtFilterResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({
          genreData: apiSelectors.proArtFilterGenreData(state),
          data: action.payload,
          styleIdParam: styleId ? `?${urlParam.STYLE}=${styleId}` : ''
        })),
        tap(({ data, genreData }) => {
          MixPanelUtils.track<'PROJECT__CREATE'>(
            'Project - Create',
            DataUtils.getProjectParam<'transfer_project'>('transfer_project', {
              proArtFilterProject: data,
              proArtFilterGenreData: genreData
            })
          )
          FacebookPixelUtils.track<'CREATE_PROARTFILTER'>('create_stylize')
        }),
        map(({ data, styleIdParam }) =>
          replace(`${route.PRO_FILTER_PROJECTS.getUrl({ id: data?.id })}${styleIdParam}`)
        ),
        startWith(
          apiActions.proArtFilter.createProArtFilter({
            name
          })
        )
      )
    )
  )

const retrieveProArtFilterStyleEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.retrieveProArtFilterStyle)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      genre: apiSelectors.currentProArtFilterProject(state)?.genre,
      routerLocation: appSelectors.routerLocation(state)
    })),
    filter(({ genre }) => Boolean(genre)),
    mergeMap(({ genre = 0, routerLocation }) =>
      action$.pipe(
        filter(isActionOf(apiActions.proArtFilter.listProArtFilterStylesResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({ payload: action.payload, state })),
        map(({ payload, state }) => {
          const styleId = UrlUtils.getParam(routerLocation?.search ?? '', urlParam.STYLE) ?? ''
          const selected = styleId ? styleId : values.DEFAULT_SELECTED_STYLE_ID
          const selectedInteger = _toInteger(selected)
          const hasCurrentStyleData = selectors.hasCurrentStyleData(state)

          const styleIds = payload.results?.map(value => value.id)

          return {
            styleId: selectedInteger,
            hasCurrentStyleData,
            isExist: styleIds?.includes(selectedInteger)
          }
        }),
        filter(({ isExist, hasCurrentStyleData }) => !hasCurrentStyleData && Boolean(isExist)),
        mergeMap(({ styleId }) => [
          actions.setStyle({ id: styleId, source: 'style' }),
          actions.setActiveView('upload')
        ]),
        startWith(apiActions.proArtFilter.listProArtFilterStyles({ genre }))
      )
    )
  )

const generateImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.generateImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      customStyles: selectors.customStyles(state),
      formData: selectors.formData(state),
      proArtFilterStyles: apiSelectors.proArtFilterStyles(state),
      currentProArtFilterProject: apiSelectors.currentProArtFilterProject(state),
      proArtFilterGenreData: apiSelectors.proArtFilterGenreData(state)
    })),
    map(
      ({
        payload,
        customStyles,
        currentProArtFilterProject,
        proArtFilterStyles,
        formData,
        proArtFilterGenreData
      }) => {
        const id = currentProArtFilterProject?.id || 0

        const styleName = proArtFilterUtils.extractStyleName(
          formData,
          customStyles,
          proArtFilterStyles
        )
        const { source } = proArtFilterUtils.getListIdObject(formData.style_key)

        return {
          payload,
          currentProArtFilterProject,
          proArtFilterGenreData,
          styleName,
          uploadContentParam: {
            content: formData.imageFileForUpload ?? new File([], '')
          },
          hasUploadContentParam: Boolean(formData.imageFileForUpload),
          source,
          updateGenreParam: { id, genre: formData.genre },
          generateParam: {
            id,
            style_weight: formData.style_weight,
            content_weight: formData.content_weight,
            ...proArtFilterUtils.getGenerateParam(formData, customStyles, payload)
          }
        }
      }
    ),
    filter(({ generateParam: { id, custom_style, style } }) => {
      const hasId = Boolean(id)
      const hasStyle = Boolean(custom_style) || Boolean(style)

      return hasId && hasStyle
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ hasUploadContentParam }) => hasUploadContentParam),
          map(({ uploadContentParam, payload }) =>
            actions.uploadContent({ uploadParam: uploadContentParam, generateParam: payload })
          )
        ),
        of(param).pipe(
          filter(({ hasUploadContentParam }) => !hasUploadContentParam),
          mergeMap(
            ({
              generateParam,
              styleName,
              source,
              updateGenreParam,
              proArtFilterGenreData,
              currentProArtFilterProject,
              payload
            }) =>
              action$.pipe(
                filter(isActionOf(apiActions.proArtFilter.updateProArtFilterResponse)),
                take(1),
                mergeMap(() =>
                  action$.pipe(
                    filter(isActionOf(apiActions.proArtFilter.generateProArtFilterResponse)),
                    take(1),
                    tap(() => {
                      const projectData = DataUtils.getProjectParam<'transfer_project'>(
                        'transfer_project',
                        {
                          proArtFilterProject: currentProArtFilterProject,
                          proArtFilterGenreData
                        }
                      )
                      MixPanelUtils.track<'PROJECT__STYLE_GENERATE'>(
                        'Project ProArtFilter - Generate',
                        {
                          ...projectData,
                          style_transfer_enable_mask: Boolean(payload.enableMask),
                          style_transfer_style: styleName,
                          style_transfer_source: source,
                          style_transfer_content_effect: generateParam.content_weight,
                          style_transfer_style_value: generateParam.style_weight
                        }
                      )
                    }),
                    ignoreElements(),
                    startWith(apiActions.proArtFilter.generateProArtFilter(generateParam))
                  )
                ),
                startWith(apiActions.proArtFilter.updateProArtFilter(updateGenreParam))
              )
          )
        )
      )
    )
  )

const uploadContentEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.uploadContent)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      currentProArtFilterProjectId: apiSelectors.currentProArtFilterId(state),
      uploadParam: action.payload.uploadParam,
      generateParam: action.payload.generateParam
    })),
    mergeMap(({ currentProArtFilterProjectId, uploadParam, generateParam }) =>
      action$.pipe(
        filter(isActionOf(apiActions.proArtFilter.uploadProArtFilterContentResponse)),
        take(1),
        mergeMap(() => [
          actions.generateImage(generateParam),
          actions.updateFormData({
            imageFile: undefined,
            imageFileForUpload: undefined,
            imageFileCropped: undefined
          })
        ]),
        startWith(
          apiActions.proArtFilter.uploadProArtFilterContent({
            id: currentProArtFilterProjectId,
            ...uploadParam
          })
        )
      )
    )
  )

const updateGenreEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.updateGenre)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      id: apiSelectors.currentProArtFilterId(state),
      genre: action.payload
    })),
    map(payload => apiActions.proArtFilter.updateProArtFilter(payload))
  )

const addCustomStyleEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.addCustomStyles)),
    withLatestFrom(state$),
    map(([action, state]) => {
      return {
        fileCount: action.payload.length,
        formData: selectors.formData(state),
        customStyles: selectors.customStyles(state)
      }
    }),
    map(({ formData, fileCount, customStyles }) => ({
      fileCount,
      shouldSelectNewCustomStyle: !Boolean(formData.style_key) && Boolean(customStyles?.length)
    })),
    mergeMap(({ fileCount, shouldSelectNewCustomStyle }) =>
      _compact([
        shouldSelectNewCustomStyle && actions.setStyle({ id: 0, source: 'custom_style' }),
        actions.logProArtFilterDoAction({
          do_action_type: 'add_custom_style',
          do_action_value: `${fileCount}`
        })
      ])
    )
  )

const downloadAllEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.downloadAll, actions.loadAllResult])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      outputs: apiSelectors.currentProArtFilterProject(state)?.outputs ?? [],
      currentProArtFilterId: apiSelectors.currentProArtFilterId(state),
      lastRequest: selectors.lastOutputRequest(state)
    })),
    mergeMap(({ lastRequest, currentProArtFilterId, outputs }) =>
      merge(
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.proArtFilter.listProArtFilterOutputResponse)),
              take(1),
              map(() => actions.loadAllResult()),
              startWith(
                apiActions.proArtFilter.listProArtFilterOutput({
                  param: { project: currentProArtFilterId, limit: 10 },
                  next: true
                })
              )
            )
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          map(() =>
            snackBarActions.show({
              content: `Load all Stylize results...`,
              actionText: `${outputs.length} / ${lastRequest?.count}`
            })
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => !Boolean(lastRequest && lastRequest.next)),
          withLatestFrom(state$),
          map(([_, state]) => ({
            proArtFilterProjectName: apiSelectors.currentProArtFilterProject(state)?.name ?? '',
            outputs: apiSelectors.currentProArtFilterProject(state)?.outputs ?? []
          })),
          map(({ outputs, proArtFilterProjectName }) => ({
            files: outputs.map(output => ({
              fileUrl: output?.image?.file ?? '',
              imageName: `stylize-result_${proArtFilterProjectName}_${output?.image?.id}.${UrlUtils.getFileTypeFromUrl(output?.image?.file ?? '')}`
            })),
            proArtFilterProjectName
          })),
          map(({ files, proArtFilterProjectName }) =>
            downloaderActions.multiple.executeDownloadMultipleImage({
              files,
              fileName: `stylize_${proArtFilterProjectName}`
            })
          )
        )
      )
    )
  )

/* 

- Listen when upload list received
  - When failed, the status will become UNKNOWN
  - So when generate pooling is already running in sometimes, 
    then status become UNKNOWN, then stop the pooling
*/
const listenOnRetrieveProArtFilterEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.proArtFilter.retrieveProArtFilterResponse,
        apiActions.proArtFilter.generateProArtFilterResponse
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isCurrentUserFree: apiSelectors.isCurrentUserFree(state),
      isGenerating: selectors.isGenerating(state),
      generateCount: selectors.generateCount(state),
      currentProArtFilterProject: apiSelectors.currentProArtFilterProject(state),
      id: action.payload.id
    })),
    map(
      ({ isGenerating, generateCount = 0, currentProArtFilterProject, id, isCurrentUserFree }) => {
        const currentProArtFilterProjectId = currentProArtFilterProject?.id ?? 0
        const status = currentProArtFilterProject?.status
        const expect_finished = currentProArtFilterProject?.expect_finished ?? 0
        const queue_length = currentProArtFilterProject?.queue_length ?? 0

        const hasQueue =
          expect_finished && generateCount < expect_finished + ADDITIONAL_THRESHOLD_GENERATE

        const isGeneratingButFinished = isGenerating && status === 'FINISHED'
        const shouldContinueGenerateUnknownStatus = hasQueue && status === 'UNKNOWN'
        const isProcessing = status === 'PROCESSING'
        const shouldShowErrorDialog =
          isGenerating &&
          !shouldContinueGenerateUnknownStatus &&
          !isProcessing &&
          !isGeneratingButFinished

        return {
          errorExtra: {
            elapsed_pooling: generateCount,
            expect_finished,
            queue_length
          },
          isCurrentUserFree,
          currentProArtFilterProjectId,
          id,
          isGeneratingButFinished,
          shouldContinueGenerateUnknownStatus,
          isProcessing,
          shouldShowErrorDialog
        }
      }
    ),
    tap(({ shouldShowErrorDialog, errorExtra }) => {
      if (shouldShowErrorDialog) {
        SentryUtils.captureMessage(
          `Unable To Generate Stylize Filter in expected time`,
          errorExtra,
          'error'
        )
      }
    }),
    filter(({ currentProArtFilterProjectId, id }) => currentProArtFilterProjectId === id),
    mergeMap(
      ({
        currentProArtFilterProjectId,
        isGeneratingButFinished,
        shouldContinueGenerateUnknownStatus,
        isProcessing,
        shouldShowErrorDialog,
        isCurrentUserFree
      }) =>
        _compact([
          actions.setGenerating(shouldContinueGenerateUnknownStatus || isProcessing),
          isGeneratingButFinished
            ? apiActions.proArtFilter.listProArtFilterOutput({
                param: { project: currentProArtFilterProjectId, limit: 30 },
                loadingMessage: LOAD_OUTPUT_AFTER_GENERATE_LOADING
              })
            : null,
          isGeneratingButFinished
            ? apiActions.proArtFilter.resetProArtFilterQueueData(currentProArtFilterProjectId)
            : null,
          isGeneratingButFinished && isCurrentUserFree ? apiActions.users.retrieveEquity() : null,
          isGeneratingButFinished ? actions.setScrollToTop(true) : null,
          shouldShowErrorDialog
            ? dialogActions.openDialog({
                [ErrorDialog.ERROR]: {
                  dialogName: ErrorDialog.ERROR,
                  title: 'Unable To Generate Stylize Filter Result',
                  content: [
                    `We're unable to generate Stylize Filter result in expected time.`,
                    `Please try again in few minutes`,
                    `if this keeps happening, contact us by click on the "Question" button or send an email to ${values.PLAYFORM_CONTACT_EMAIL}.`
                  ]
                }
              })
            : null
        ])
    )
  )
const listenRetrieveProArtFilterErrorEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(({ payload }) => payload.type === getType(apiActions.proArtFilter.retrieveProArtFilter)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      error: action.payload,
      isGenerating: selectors.isGenerating(state)
    })),
    mergeMap(({ isGenerating, error }) =>
      _compact([
        actions.setGenerating(false),
        isGenerating &&
          dialogActions.addDialog({
            [ErrorDialog.ERROR]: {
              dialogName: ErrorDialog.ERROR,
              title: `Unable to retrieve Stylize Filter data`,
              content: [
                errorUtils.flattenMessage(error).toString(),
                'Generating process is still working in the background, the result will be available when it finished. Try to refresh this page to see the finished results'
              ]
            }
          })
      ])
    )
  )

const setScrollToTopEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.setScrollToTop)),
    filter(({ payload }) => Boolean(payload)),
    delay(1000),
    map(() => actions.setScrollToTop(false))
  )

const setCurrentProArtFilterProjectEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.proArtFilter.setCurrentProArtFilterProject)),
    map(({ payload }) => payload),
    map(payload => actions.setCurrentProFilterId(payload))
  )

const logProArtFilterDoActionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.logProArtFilterDoAction)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      currentProArtFilterProject: apiSelectors.currentProArtFilterProject(state),
      proArtFilterGenreData: apiSelectors.proArtFilterGenreData(state)
    })),
    tap(({ currentProArtFilterProject, proArtFilterGenreData, ...payload }) => {
      const projectData = DataUtils.getProjectParam<'transfer_project'>('transfer_project', {
        proArtFilterProject: currentProArtFilterProject ?? undefined,
        proArtFilterGenreData
      })
      MixPanelUtils.track<'PROJECT__PROARTFILTER_DO_ACTION'>('Project ProArtFilter - Do Action', {
        ...projectData,
        ...payload
      })
    }),
    ignoreElements()
  )

export const epics = combineEpics(
  setCurrentProArtFilterProjectEpic,
  logProArtFilterDoActionEpic,
  listenRetrieveProArtFilterErrorEpic,
  setScrollToTopEpic,
  updateGenreEpic,
  listenOnRetrieveProArtFilterEpic,
  uploadContentEpic,
  createProArtFilterProjectEpic,
  retrieveProArtFilterStyleEpic,
  generateImageEpic,
  downloadAllEpic,
  addCustomStyleEpic
)

export default reducer
