import { Dispatch } from 'react'
import { AnyAction } from 'redux'
import { DialogActionType } from 'duck/AppDuck/DialogDuck'
import { ActionTypeParam } from 'duck/AppDuck'

export const DialogUtils = {
  /* 
  - Execute action in dialog. 
  - Action can be function, or redux action, 
  - If it's function, then call the function. 
  - If it's a redux action, then dispatch it.  
*/
  executeAction: (actions: DialogActionType['actions'], dispatch: Dispatch<AnyAction>) => {
    actions?.forEach(actionItem => {
      if (typeof actionItem === 'function') {
        actionItem()
      }
      if ((actionItem as ActionTypeParam).type) {
        dispatch(actionItem as ActionTypeParam)
      }
    })
  }
}
