import { useSelector } from 'react-redux'
import { RootState } from 'duck'
import { useDelayedData } from 'utils/Hooks'
import { dialogSelectors } from 'duck/AppDuck/DialogDuck'
import { values } from 'appConstants'

export const useDialogDetector = (dialogName: string | string[]) => {
  const activeDialog = useSelector((state: RootState) => dialogSelectors.activeDialog(state))
  const open =
    typeof dialogName === 'string'
      ? activeDialog === dialogName
      : Array.isArray(dialogName)
        ? dialogName.includes(activeDialog)
        : false

  const openDelayedState = useDelayedData(open, values.DIALOG_TRANSITION_DURATION)

  return open || openDelayedState
}
