import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import { connect } from 'react-redux'
import _toNumber from 'lodash/toNumber'
import { Dispatch, bindActionCreators } from 'redux'
import GrainIcon from '@mui/icons-material/Grain'

import GridLabelTwo from 'components/GridLabel/GridLabelTwo'
import Slider from 'components/Slider'
import Input from 'components/Input'
import { RootState, RootActionType } from 'duck'
import { actions, selectors } from '../../duck'
import { FORM_RANGES } from 'containers/UpscalePanel/Models'
import { ReduxProps } from 'utils/Types'

import styles from '../UpscaleGenerate.module.scss'

const INPUT_CLASSES = {
  input: styles.UpscaleControlsInput
}

const GRID_LABEL_CLASSES = {
  label: 'text-white-med-on-dark'
}

const mapStateToProps = (state: RootState) => ({
  currentFormData: selectors.currentFormData(state),
  disabled: selectors.disableForm(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      updateFormData: actions.updateFormData
    },
    dispatch
  )

type UpscaleControlsProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const checkDenoiseFormValidity = (value: string, originalValue: number) => {
  const localContent = _toNumber(value)

  const [min, max] = FORM_RANGES['smooth']

  if (isNaN(localContent)) {
    return { valid: false, changeTo: originalValue }
  } else if (localContent > max) {
    return { valid: false, changeTo: max }
  } else if (localContent < min) {
    return { valid: false, changeTo: min }
  }
  return { valid: true, changeTo: 0 }
}

const UpscaleDenoiseForm: React.FC<UpscaleControlsProps> = props => {
  const { disabled, updateFormData, currentFormData } = props
  const [min, max, initialSmooth, step] = FORM_RANGES['smooth']

  const { formData } = currentFormData
  const smooth = formData?.smooth ?? initialSmooth
  const showSmooth = formData?.showSmooth ?? false

  const [denoiseForm, setDenoiseForm] = useState<string>(`${smooth}`)

  useEffect(() => {
    setDenoiseForm(`${smooth}`)
  }, [smooth])

  const updateDenoiseTimeoutRef = useRef<number | undefined>(undefined)

  const updateDenoiseFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const formValue = e.target.value
      setDenoiseForm(formValue)

      const { valid, changeTo } = checkDenoiseFormValidity(formValue, smooth)

      if (valid) {
        updateFormData({ smooth: _toNumber(formValue) })

        window.clearTimeout(updateDenoiseTimeoutRef.current)
      } else {
        updateDenoiseTimeoutRef.current = window.setTimeout(() => {
          updateFormData({ smooth: changeTo ?? initialSmooth })
          setDenoiseForm(`${changeTo}`)
        }, 1000)
      }
    },
    [initialSmooth, smooth, updateFormData]
  )

  return (
    <>
      <GridLabelTwo
        classes={GRID_LABEL_CLASSES}
        label={
          <>
            <GrainIcon />
            <Typography className="ml-2 break-words" variant="overline">
              DENOISE
            </Typography>
          </>
        }
        content={
          <Switch
            disabled={Boolean(disabled)}
            checked={Boolean(showSmooth)}
            onChange={(e, checked) =>
              updateFormData({
                showSmooth: checked
              })
            }
            name="denoise"
          />
        }
        borderless
      />

      <div className={clsx(!showSmooth && 'hidden', 'mt-2')}>
        <Grid alignItems="center" container>
          <Grid className="pl-8 pr-2" xs item>
            <Slider
              value={smooth}
              step={step}
              disabled={disabled}
              min={min}
              max={max}
              onChange={(_, value) => {
                updateFormData({ smooth: value as number })
              }}
            />
          </Grid>

          <Grid xs="auto" item>
            <Input
              color="secondary"
              value={denoiseForm}
              onChange={updateDenoiseFormValue}
              disabled={Boolean(disabled)}
              className={clsx(styles.UpscaleControlsInputRoot)}
              classes={INPUT_CLASSES}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(UpscaleDenoiseForm)
