import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { route } from 'routes'
import NavBarGeneral from './NavBarGeneral'
import { RootState } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import NavbarProjectFirst from './NavBarProjectFirst'
import { useIsFromPretrainedModelButton } from 'utils/Hooks'
import { usePortalInit } from 'components/Portal'
import styles from './NavBar.module.scss'

const mapStateToProps = (state: RootState) => ({
  routerLocation: appSelectors.routerLocation(state),
  navBarProps: appSelectors.navBarProps(state)
})

export type NavBarProps = ReturnType<typeof mapStateToProps> & {
  authenticating?: boolean
}

const NavBar: React.FC<NavBarProps> = ({ routerLocation, navBarProps, authenticating }) => {
  const search = routerLocation?.search
  const isFromPretrainedModelButton = useIsFromPretrainedModelButton(search)
  const navbarRef = useRef<HTMLDivElement | null>(null)
  const additionalContent = navBarProps?.additionalContent || undefined

  usePortalInit('navbar', navbarRef)

  return (
    <div ref={navbarRef} className={styles.NavBar}>
      <Routes>
        <Route
          path={`${route.ROOT.paths[0]}*`}
          element={<NavBarGeneral authenticating={authenticating} />}
        />
        {isFromPretrainedModelButton ? (
          <Route path={`${route.EXPLORE.paths[0]}/*`} element={<NavbarProjectFirst />} />
        ) : null}
      </Routes>

      {additionalContent}
    </div>
  )
}

export default connect(mapStateToProps)(NavBar)
