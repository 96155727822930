import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  switchMap,
  withLatestFrom,
  mergeMap,
  delay,
  tap
} from 'rxjs/operators'

import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import _get from 'lodash/get'
import _toNumber from 'lodash/toNumber'
import { of, merge } from 'rxjs'
import { generateImagesKey, isCollectionDraft } from 'utils/DataProcessingUtils'
import { CollectionListReq, PaginationRequestParams } from 'models/ApiModels'
import { getDeleteCollectionTemplate } from 'models/ApiModelsDummy'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { AUTOSAVE_INTERVAL } from './index'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'

const createCollectionEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.create)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.collections.create(payload).pipe(
          mergeMap(response => [
            apiActions.collections.createResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listCollectionsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.list)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, reloadList = false, listType } = action.payload
      let params: { next?: string } = {}

      if (!reloadList) {
        params = {
          next: state.api.collections.collectionLists[listType].lastReq?.next
        }
      }

      const extraParams = reloadList ? (param as CollectionListReq) : undefined

      return {
        reloadList,
        params,
        extraParams,
        type: action.type,
        payload: action.payload,
        listType
      }
    }),
    filter(({ reloadList, params }) => {
      if (!reloadList && !params.next) {
        return false
      }

      return true
    }),
    switchMap(({ reloadList, params, extraParams, type, listType }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        of(listType).pipe(
          filter(listType => listType !== 'recently_used'),
          mergeMap(() =>
            ApiUtils.collections.list(params, extraParams).pipe(
              mergeMap(response => [
                apiActions.collections.listResponse({
                  data: response.data,
                  reloadList,
                  listType
                }),
                sharedActions.setLoading({ loading: false, type })
              ]),
              catchError(err =>
                of(err).pipe(
                  mergeMap(err => [
                    sharedActions.setError({
                      error: err.response,
                      type,
                      req: { params, extraParams }
                    }),
                    sharedActions.setLoading({ loading: false, type })
                  ])
                )
              )
            )
          )
        ),
        of(listType).pipe(
          filter(listType => listType === 'recently_used'),
          mergeMap(() =>
            ApiUtils.collections.recentlyUsedList(params, extraParams).pipe(
              mergeMap(response => [
                apiActions.collections.listResponse({
                  data: response.data,
                  reloadList,
                  listType
                }),
                sharedActions.setLoading({ loading: false, type })
              ]),
              catchError(err =>
                of(err).pipe(
                  mergeMap(err => [
                    sharedActions.setError({
                      error: err.response,
                      type,
                      req: { params, extraParams }
                    }),
                    sharedActions.setLoading({ loading: false, type })
                  ])
                )
              )
            )
          )
        )
      )
    )
  )

const retrieveCollectionsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.retrieve)),
    filter(({ payload }) => Boolean(payload)),
    mergeMap(({ type, payload }) =>
      ApiUtils.collections.retrieve(payload).pipe(
        map(response => apiActions.collections.retrieveResponse(response.data)),
        catchError(err =>
          of(err).pipe(
            mergeMap(err =>
              merge(
                of(sharedActions.setError({ error: err.response, type, req: payload })),
                of(err).pipe(
                  filter(err => _get(err, 'response.status', 0) === 404),
                  map(() =>
                    apiActions.collections.retrieveResponse(getDeleteCollectionTemplate(payload))
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const copyCollectionsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.copy)),
    switchMap(({ type, payload }) =>
      ApiUtils.collections.copy('', payload).pipe(
        map(response =>
          apiActions.collections.copyResponse({
            collection: response.data,
            copiedFrom: payload.collection
          })
        ),
        catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
      )
    )
  )

const updateCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, $store) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.update)),
    debounceTime(AUTOSAVE_INTERVAL),
    withLatestFrom($store),
    /* Update collection's is_draft state */
    map(([action, state]) => {
      const collectionData = apiSelectors.collections(state)[action.payload.id]
      const silentUpdate = action.payload.silentUpdate
      const categoriesObject = apiSelectors.categoriesObject(state)
      const is_draft = isCollectionDraft({ ...collectionData, ...action.payload })

      return {
        silentUpdate,
        categoriesObject,
        type: action.type,
        payload: { ...action.payload, is_draft }
      }
    }),
    switchMap(({ type, payload, silentUpdate, categoriesObject }) =>
      merge(
        of(
          sharedActions.setLoading({
            loading: silentUpdate ? false : 'Update Collection Data...',
            type
          })
        ),
        ApiUtils.collections.update(payload.id, payload).pipe(
          tap(response => {
            const trackData = DataUtils.getCollectionData({
              collection: response.data,
              category: categoriesObject
            })

            const deleted_count = payload.exclude_images?.length

            MixPanelUtils.track<'COLLECTION__UPDATE'>('Collection - Update Collection', {
              ...trackData,
              updated_forms: DataUtils.getObjectKeys(payload),
              deleted_count
            })
          }),
          mergeMap(response => [
            apiActions.collections.updateResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const deleteCollectionEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.delete)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      collectionData: apiSelectors.collections(state)[action.payload],
      categoriesObject: apiSelectors.categoriesObject(state),
      ...action
    })),
    switchMap(({ type, payload, collectionData, categoriesObject }) =>
      ApiUtils.collections.delete(payload).pipe(
        tap(() => {
          const trackData = DataUtils.getCollectionData({
            collection: collectionData,
            category: categoriesObject
          })
          MixPanelUtils.track<'COLLECTION__DELETE'>('Collection - Delete', trackData)
        }),
        map(() => apiActions.collections.deleteResponse(payload)),
        catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
      )
    )
  )

const listCategoriesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.listCategories)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      hasCurrentUser: Boolean(apiSelectors.currentUserId(state)),
      type: action.type
    })),
    filter(({ hasCurrentUser }) => hasCurrentUser),
    switchMap(({ type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.collections.listCategories().pipe(
          mergeMap(response => [
            apiActions.collections.listCategoriesResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type })))
        )
      )
    )
  )

const listTagsEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.listTags)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.collections.listTags(payload).pipe(
          mergeMap(response => [
            apiActions.collections.listTagsResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const removeTagEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.removeTag)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      collectionData: apiSelectors.collections(state)[action.payload.collectionId],
      categoriesObject: apiSelectors.categoriesObject(state),
      ...action
    })),
    mergeMap(({ type, payload, collectionData, categoriesObject }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.collections.removeTag(payload.collectionId, payload.tag).pipe(
          tap(() => {
            const trackData = DataUtils.getCollectionData({
              collection: collectionData,
              category: categoriesObject
            })
            MixPanelUtils.track<'COLLECTION__UPDATE_TAG'>('Collection - Update Collection Tag', {
              ...trackData,
              update_tag_type: 'remove',
              update_tag_value: payload.tag.name
            })
          }),
          concatMap(response => [
            apiActions.collections.removeTagResponse({
              collectionId: payload.collectionId,
              tags: response.data
            }),
            sharedActions.setLoading({ loading: false, type }),
            apiActions.collections.onTagsUpdated({ collectionId: payload.collectionId })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const addTagEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.addTag)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      collectionData: apiSelectors.collections(state)[action.payload.collectionId],
      categoriesObject: apiSelectors.categoriesObject(state),
      ...action
    })),
    mergeMap(({ type, payload, collectionData, categoriesObject }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.collections.addTag(payload.collectionId, payload.tag).pipe(
          tap(() => {
            const trackData = DataUtils.getCollectionData({
              collection: collectionData,
              category: categoriesObject
            })
            MixPanelUtils.track<'COLLECTION__UPDATE_TAG'>('Collection - Update Collection Tag', {
              ...trackData,
              update_tag_type: 'add',
              update_tag_value: payload.tag.name
            })
          }),
          concatMap(response => [
            apiActions.collections.addTagResponse({
              collectionId: payload.collectionId,
              tags: response.data
            }),
            sharedActions.setLoading({ loading: false, type }),
            apiActions.collections.onTagsUpdated({ collectionId: payload.collectionId })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateThumbnailEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.updateThumbnail)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.collections.updateThumbnail(payload).pipe(
          mergeMap(() => [
            apiActions.collections.updateThumbnailResponse(),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

/* Make sure is_draft state updated when tags changed */
const onTagsUpdatedEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.onTagsUpdated)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const collectionId = action.payload.collectionId
      const collectionData = apiSelectors.collections(state)[collectionId]
      const is_draft = collectionData?.is_draft
      const tags = collectionData?.tags || []

      let shouldUpdated = false
      if (is_draft && tags.length) {
        shouldUpdated = true
      }
      if (!is_draft && !tags.length) {
        shouldUpdated = true
      }

      return {
        id: collectionId,
        shouldUpdated
      }
    }),
    filter(({ shouldUpdated }) => shouldUpdated),
    map(({ id }) => apiActions.collections.update({ id, silentUpdate: true }))
  )

const toggleCollectionBookmarkEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.toggleCollectionBookmark)),
    withLatestFrom(store),
    map(([action, store]) => ({
      type: action.type,
      collection: apiSelectors.collections(store)[action.payload.collectionId],
      collectionCategories: apiSelectors.categoriesObject(store),
      isLoading: Boolean(apiSelectors.loading['collections.toggleCollectionBookmark'](store))
    })),
    map(({ type, collection, collectionCategories, isLoading }) => ({
      collection,
      type,
      trackData: DataUtils.getCollectionData({
        collection,
        category: collectionCategories
      }),
      isLoading
    })),
    filter(({ collection, isLoading }) => Boolean(collection) && !isLoading),
    mergeMap(({ collection, trackData, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        of(collection).pipe(
          filter(({ bookmark }) => Boolean(bookmark)),
          map(() =>
            apiActions.collections.toggleCollectionBookmarkInstantResponse({
              collectionId: collection.id,
              bookmark: false
            })
          )
        ),
        of(collection).pipe(
          filter(({ bookmark }) => Boolean(bookmark) && _toNumber(bookmark) > 0),
          mergeMap(collection =>
            merge(
              ApiUtils.collections.deleteCollectionBookmark(collection.bookmark ?? 0).pipe(
                tap(() => {
                  MixPanelUtils.track<'COLLECTION__BOOKMARK'>('Collection - Bookmark', {
                    ...trackData,
                    bookmark_action_type: 'unsave'
                  })
                }),
                mergeMap(() => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.collections.toggleCollectionBookmarkResponse({})
                ]),
                catchError(err =>
                  of(err).pipe(
                    mergeMap(err => [
                      sharedActions.setLoading({ loading: false, type }),
                      sharedActions.setError({ error: err.response, type, req: collection.id }),
                      apiActions.collections.toggleCollectionBookmarkInstantResponse({
                        collectionId: collection.id,
                        bookmark: true
                      })
                    ])
                  )
                )
              )
            )
          )
        ),
        of(collection).pipe(
          filter(({ bookmark }) => !Boolean(bookmark)),
          mergeMap(collection =>
            merge(
              of(
                apiActions.collections.toggleCollectionBookmarkInstantResponse({
                  collectionId: collection.id,
                  bookmark: true
                })
              ),
              ApiUtils.collections.createCollectionBookmark({ item: collection.id }).pipe(
                tap(() => {
                  MixPanelUtils.track<'COLLECTION__BOOKMARK'>('Collection - Bookmark', {
                    ...trackData,
                    bookmark_action_type: 'save'
                  })
                }),
                mergeMap(response =>
                  merge(
                    of(sharedActions.setLoading({ loading: false, type })),
                    of(
                      apiActions.collections.toggleCollectionBookmarkResponse({
                        data: response.data
                      })
                    ),
                    of(snackBarActions.show({ content: 'Collection Saved!' })),
                    of(response).pipe(
                      delay(1000),
                      map(() => snackBarActions.close())
                    )
                  )
                ),
                catchError(err =>
                  of(err).pipe(
                    mergeMap(err => [
                      sharedActions.setLoading({ loading: false, type }),
                      sharedActions.setError({ error: err.response, type, req: collection.id }),
                      apiActions.collections.toggleCollectionBookmarkInstantResponse({
                        collectionId: collection.id,
                        bookmark: false
                      })
                    ])
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const createImagesEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.createImage)),
    filter(action => Boolean(action?.payload?.file) && Boolean(action.payload.collection)),
    map(action => {
      const formData = new FormData()
      formData.append('file', action.payload.file)
      formData.append('collection', `${action.payload.collection}`)

      return { formData, action }
    }),
    mergeMap(({ formData, action }) =>
      ApiUtils.collections.createImage(formData).pipe(
        map(response =>
          apiActions.collections.createImageResponse({ data: response.data, req: action.payload })
        ),
        catchError(err =>
          of(
            sharedActions.setError({
              error: err.response,
              req: action.payload,
              type: action.type
            })
          )
        )
      )
    )
  )
const listImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.collections.listImage)),
    withLatestFrom(store),
    /* To determine whether extract first data, or continue pagination
     * We need this because the nature of image data structure,
     * This code is to choose which imageList need to be updated.
     */
    map(([action, state]) => {
      const { param, next } = action.payload
      let firstParam: PaginationRequestParams | undefined
      const extraParam = param
      let currentState

      // If next true, then fetch the pagination data
      if (next) {
        currentState = (state.api?.collections?.images ?? {})[generateImagesKey(param)]
        if (currentState && currentState.next) {
          firstParam = { next: currentState.next }
        }
      }

      const paramKey = generateImagesKey(param)
      const imageListFetchState = apiSelectors.imageListFetchState(state)
      const isStillFetching = Boolean(imageListFetchState[paramKey])

      return { next, action, firstParam, extraParam, paramKey, isStillFetching }
    }),
    filter(({ isStillFetching }) => !isStillFetching),
    filter(({ next, firstParam }) => {
      /* If doesn't have next value (pagination is in end) */
      if (next) {
        if (!firstParam) return false
        if (!firstParam.next) return false
      }
      return true
    }),
    mergeMap(({ action, firstParam, extraParam, paramKey }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        of(sharedActions.setImageListFetchState({ key: paramKey, value: true })),
        ApiUtils.collections.listImage(firstParam, extraParam).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type: action.type }),
            sharedActions.setImageListFetchState({ key: paramKey, value: false }),
            apiActions.collections.listImageResponse({
              data: response.data,
              req: action.payload.param,
              next: action.payload.next
            })
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                req: action.payload.param,
                type: action.type
              })
            )
          )
        )
      )
    )
  )
const collectionEpics = [
  createImagesEpic,
  listImagesEpic,
  createCollectionEpic,
  retrieveCollectionsEpic,
  listCollectionsEpic,
  copyCollectionsEpic,
  updateCollectionEpic,
  deleteCollectionEpic,
  listCategoriesEpic,
  listTagsEpic,
  removeTagEpic,
  addTagEpic,
  updateThumbnailEpic,
  onTagsUpdatedEpic,
  toggleCollectionBookmarkEpic
]

export default collectionEpics
