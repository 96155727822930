import produce from 'immer'
import { ImageSetType, CollectionListReq, CollectionOrderingType } from 'models/ApiModels'
import { actions } from './actions'
import { ActionType, getType } from 'typesafe-actions'

// Reducer
export type UploadProgressType = {
  isUploading: boolean
  uploadStarted: boolean
  currentBatchProcessed: number
  total: number
  succeed: number
  failed: number
}
export const checkIsRecentlyUsed = (listParams: CollectionListReq) =>
  listParams.ordering === 'last_used' || listParams.ordering === '-last_used'

export type BottomSheetBarTabs =
  | null
  | 'upload'
  | 'my_collection'
  | 'public_collections'
  | 'bookmarked_collections'

export type CollectionSelectorPanelState = {
  hideCollFooter: boolean
  openUploadWindow: boolean
  currentTab: BottomSheetBarTabs
  selectedImageSet: ImageSetType
  isSheetBarExpanded: boolean
  fileReceivedQueued: File[] | null
  searchLoading: boolean
  openedCollection?: number
  bookmarkedCollection: {
    listParams: CollectionListReq
  }
  publicCollection: {
    listParams: CollectionListReq
    selectedCategory: string
  }
  myCollection: {
    listParams: CollectionListReq
    selectedCategory: string
    previousOrdering: CollectionOrderingType
  }
}

const initial: CollectionSelectorPanelState = {
  hideCollFooter: false,
  openUploadWindow: false,
  currentTab: null,
  isSheetBarExpanded: false,
  selectedImageSet: 'inspiration',
  fileReceivedQueued: null,
  searchLoading: false,
  openedCollection: 0,
  publicCollection: {
    selectedCategory: 'all',
    listParams: {
      ordering: '-modified',
      scope: 'public',
      search: ''
    }
  },
  bookmarkedCollection: {
    listParams: {
      ordering: '-modified',
      scope: 'bookmark',
      search: ''
    }
  },
  myCollection: {
    selectedCategory: 'all',
    previousOrdering: '-last_used',
    listParams: {
      ordering: '-modified',
      scope: 'current_user',
      search: ''
    }
  }
}

export const reducer = produce((state: CollectionSelectorPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setHideCollFooter): {
      const value = payload as ActionType<typeof actions.setHideCollFooter>['payload']
      state.hideCollFooter = value
      return
    }
    case getType(actions.setOpenUploadWindow): {
      const value = payload as ActionType<typeof actions.setOpenUploadWindow>['payload']
      state.openUploadWindow = value
      return
    }
    case getType(actions.expandSheetBar): {
      const { expanded } = payload as ActionType<typeof actions.expandSheetBar>['payload']

      state.isSheetBarExpanded = expanded
      return
    }
    case getType(actions.setCurrentTab):
      const value = payload as ActionType<typeof actions.setCurrentTab>['payload']

      state.currentTab = value
      return
    case getType(actions.setSelectedImageSet): {
      const value = payload as ActionType<typeof actions.setSelectedImageSet>['payload']

      state.selectedImageSet = value
      return
    }
    case getType(actions.setOpenedCollection): {
      const value = payload as ActionType<typeof actions.setOpenedCollection>['payload']

      state.openedCollection = value
      return
    }
    case getType(actions.setSearchLoading): {
      const value = payload as ActionType<typeof actions.setSearchLoading>['payload']

      state.searchLoading = value
      return
    }
    /* Public Collection Used block */
    case getType(actions.publicCollection.setSortBy): {
      const value = payload as ActionType<typeof actions.publicCollection.setSortBy>['payload']

      state.publicCollection.listParams.ordering = value
      return
    }
    case getType(actions.publicCollection.setSearchKeyword): {
      const value = payload as ActionType<
        typeof actions.publicCollection.setSearchKeyword
      >['payload']

      state.publicCollection.listParams.search = value
      return
    }
    case getType(actions.publicCollection.setSelectedCategory): {
      const value = payload as ActionType<
        typeof actions.publicCollection.setSelectedCategory
      >['payload']

      state.publicCollection.selectedCategory = value
      return
    }

    /* My Collection block block */
    case getType(actions.myCollection.setSelectedCategory): {
      const value = payload as ActionType<
        typeof actions.myCollection.setSelectedCategory
      >['payload']
      state.myCollection.selectedCategory = value
      return
    }
    case getType(actions.myCollection.setSortBy): {
      const value = payload as ActionType<typeof actions.myCollection.setSortBy>['payload']
      state.myCollection.listParams.ordering = value

      return
    }
    case getType(actions.myCollection.setSearchKeyword): {
      const value = payload as ActionType<typeof actions.myCollection.setSearchKeyword>['payload']
      state.myCollection.listParams.search = value
      return
    }

    /* Bookmark collection block block */
    case getType(actions.bookmarkCollection.setSortBy): {
      const value = payload as ActionType<typeof actions.bookmarkCollection.setSortBy>['payload']
      state.bookmarkedCollection.listParams.ordering = value

      return
    }
    case getType(actions.bookmarkCollection.setSearchKeyword): {
      const value = payload as ActionType<
        typeof actions.bookmarkCollection.setSearchKeyword
      >['payload']
      state.bookmarkedCollection.listParams.search = value
      return
    }
    default:
  }
}, initial)

export default reducer
