import './index.scss'

import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { configureStore } from './store'
import App from './containers/AppPage'
import * as serviceWorker from './serviceWorker' // Emoji styles
import MixPanelUtils from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import TwitterConvTrkr from 'react-twitter-conversion-tracker'
import SwiperCore, { Mousewheel, Autoplay, Scrollbar } from 'swiper'
import { values } from 'appConstants'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/free-mode'

if (process.env.NODE_ENV === 'production') {
  const disableReactDevTools = (): void => {
    const noop = (): void => undefined
    const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__

    if (typeof DEV_TOOLS === 'object') {
      for (const [key, value] of Object.entries(DEV_TOOLS)) {
        if (key === 'renderers') DEV_TOOLS[key] = []
        else DEV_TOOLS[key] = typeof value === 'function' ? noop : null
      }
    }
  }

  disableReactDevTools()
}

SentryUtils.init()
MixPanelUtils.init()
FacebookPixelUtils.init()

TwitterConvTrkr.init(values.REACT_APP_TWITTER_CONVERSION_ID)

export const { store, history } = configureStore()

// install Swiper components
SwiperCore.use([Autoplay, Mousewheel, Scrollbar])

const root = createRoot(document.getElementById('root') ?? new HTMLElement())

root.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>
)

if (module.hot) {
  module.hot.accept('./containers/AppPage', () => {
    root.render(
      <Provider store={store}>
        <App history={history} />
      </Provider>
    )
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
