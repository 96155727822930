import React from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Button from 'components/Button'
import {
  dialogSelectors,
  InformationDialog,
  InformationDialogNameType
} from 'duck/AppDuck/DialogDuck'
import { TutorialVideo } from 'appConstants'
import SigninPanel from 'containers/Signin/SigninPanel'
import { useBreakpoint } from 'utils/Hooks'
import DialogWrapper, { DialogWrapperProps } from './DialogWrapper'
import { RootState } from 'duck'
import { GeneralDialogItemProps } from '.'
import { DialogProps } from 'components/Dialog'
import IncreaseGasDialog from './IncreaseGasDialog '

import styles from './Dialogs.module.scss'

const DEFAULT_VALUE: {
  dialogConfig: DialogWrapperProps['dialogConfig']
  actionCreator: (onClose: DialogProps['onClose'], text?: string) => DialogWrapperProps['actions']
} = {
  dialogConfig: {
    contentDividers: false,
    className: styles.InformationDialog
  },
  actionCreator: (onClose: DialogProps['onClose'], text = 'OK') => (
    <Button color="secondary" size="small" onClick={onClose}>
      {text}
    </Button>
  )
}

const TransitionSlideComponent = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: any
  },
  ref: any
) {
  if (props.children) return <Slide direction="up" ref={ref} {...props} children={props.children} />

  return null
})

const Information: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[InformationDialog.INFORMATION](state)
  )

  return (
    <DialogWrapper
      dialogConfig={{ ...DEFAULT_VALUE.dialogConfig }}
      activeDialog={activeDialog}
      actions={DEFAULT_VALUE.actionCreator?.(onClose, dialogData?.closeText)}
      onClose={onClose}
      title={dialogData?.title}
      content={
        <div className={clsx(styles.ContentGroup, styles.GreyText)}>
          <Typography variant="body2">{dialogData?.content}</Typography>
        </div>
      }
    />
  )
}

const CollectionRemoved: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  return (
    <DialogWrapper
      dialogConfig={{ ...DEFAULT_VALUE.dialogConfig }}
      activeDialog={activeDialog}
      actions={DEFAULT_VALUE.actionCreator?.(onClose, 'OK, GOT IT')}
      onClose={onClose}
      title={<Typography variant="h6">Collection removed</Typography>}
      content={
        <div className={styles.ContentGroup}>
          <Typography variant="body2">
            You can always add this collection again by clicking “Add Collection” and selecting
            “Recently Used”.
          </Typography>
        </div>
      }
    />
  )
}

const GeneratingImagesFromProject: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        className: styles.TutorialDialog,
        contentDividers: true
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h6">Generating images from projects</Typography>}
      content={
        <>
          <div className={styles.ContentGroup}>
            <video autoPlay src={TutorialVideo.generate} className={styles.Video} />
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              You can use any trained project to do inference—that means being able to quickly and
              easily generate new images without needing to wait for a project to learn from its
              inputs. It will reuse a project’s previous learnings to reinterpret an image you
              upload.
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              <b>TIP</b>: Images closer to the Inspirations will usually translate more
              successfully, as the project was trained on those input images.
            </Typography>
          </div>
        </>
      }
    />
  )
}

const LoginRequired: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const { breakpoint } = useBreakpoint()
  const isExtrasmallscreen = breakpoint === 'xs'

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        maxWidth: false,
        fullWidth: true,
        className: styles.LoginRequiredDialog,
        fullScreen: isExtrasmallscreen,
        TransitionComponent: isExtrasmallscreen ? TransitionSlideComponent : undefined,
        classes: {
          content: isExtrasmallscreen ? 'flex items-center' : ''
        }
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <>
          {isExtrasmallscreen ? (
            <Button className={styles.ButtonClose} onClick={onClose} icon>
              <CloseIcon fontSize="inherit" />
            </Button>
          ) : null}

          <div className={clsx(isExtrasmallscreen && 'm-auto', 'pb-9', styles.SignInContainer)}>
            <SigninPanel isDialogMode />
          </div>
        </>
      }
    />
  )
}

export const InformationDialogs: Record<
  InformationDialogNameType,
  React.FC<GeneralDialogItemProps>
> = {
  [InformationDialog.INFORMATION]: Information,
  [InformationDialog.INCREASE_GAS]: IncreaseGasDialog as React.FC<GeneralDialogItemProps>,
  [InformationDialog.COLLECTION_REMOVED]: CollectionRemoved,
  [InformationDialog.GENERATING_IMAGES_FROM_PROJECT]: GeneratingImagesFromProject,
  [InformationDialog.LOGIN_REQUIRED]: LoginRequired
}
