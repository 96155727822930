import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { merge, of } from 'rxjs'
import {
  filter,
  mergeMap,
  map,
  startWith,
  take,
  withLatestFrom,
  debounceTime,
  switchMap,
  delay,
  tap,
  ignoreElements
} from 'rxjs/operators'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { RootState, RootActionType } from 'duck'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'
import {
  PostCreateReq,
  AdjustedImage,
  ProjectObjectType,
  BookmarkCreateReq,
  AdjustedImageControl
} from 'models/ApiModels'
import {
  ControlType,
  CONTROL_MOBILE_LIST,
  IMAGE_CONTROL_DEFAULT,
  IMAGE_CONTROL_DEFAULT_ARRAY,
  TOOLS_DATA
} from './Models'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { createSelector } from 'reselect'
import { appActions, appSelectors } from 'duck/AppDuck'
import _toNumber from 'lodash/toNumber'
import _values from 'lodash/values'
import _isEqual from 'lodash/isEqual'
import _omit from 'lodash/omit'
import { ConfirmationDialog, dialogActions } from 'duck/AppDuck/DialogDuck'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { Fragment } from 'react'
import MixPanelUtils, { BookmarkLocation } from 'utils/MixPanelUtils'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'

// Constants
const NAMESPACE = '@@page/ImageEnhancementPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const actions = {
  setSource: createAction(creator('SET_SOURCE'))<{
    source: ProjectObjectType
  }>(),
  initImageEnhancement: createAction(creator('INIT_IMAGE_ENHANCEMENT'))<{
    userImageId: number
    source: ProjectObjectType
  }>(),
  setSelectedUserImageId: createAction(creator('SET_SELECTED_USER_IMAGE_ID'))<{
    userImageId: number | undefined
  }>(),
  setCurrentAdjustedImageId: createAction(creator('SET_CURRENT_ADJUSTED_IMAGE_ID'))<{
    adjustedImageId: number | undefined
  }>(),
  setControls: createAction(creator('SET_CONTROLS'))<AdjustedImage['controls']>(),
  openUpscale: createAction(creator('OPEN_UPSCALE'))(),
  updateControls: createAction(creator('UPDATE_CONTROLS'))<Partial<AdjustedImage['controls']>>(),
  revertToOriginal: createAction(creator('REVERT_TO_ORIGINAL'))(),
  revertChanges: createAction(creator('REVERT_CHANGES'))(),
  apply: createAction(creator('APPLY'))<{
    closeOnFinish?: boolean
    dontNotifyOnFinish?: boolean
  }>(),
  changesApplied: createAction(creator('CHANGES_APPLIED'))(),
  closeDialog: createAction(creator('CLOSE_DIALOG'))(),
  resetData: createAction(creator('RESET_DATA'))(),
  resetDataDelayed: createAction(creator('RESET_DATA_DELAYED'))(),
  setIsChanged: createAction(creator('SET_IS_CHANGED'))<boolean>(),
  setShowSharePanel: createAction(creator('SET_SHOW_SHARE_PANEL'))<boolean>(),
  editedControlType: {
    show: createAction(creator('EDITED_CONTROL_TYPE_SHOW'))<ControlType>(),
    cancel: createAction(creator('EDITED_CONTROL_TYPE_CANCEL'))(),
    done: createAction(creator('EDITED_CONTROL_TYPE_DONE'))()
  }
}

// Selectors
const selectImageEnhancementPanel = (state: RootState) => state.container.imageEnhancementPanel
const selectSelectedUserImageId = createSelector(
  selectImageEnhancementPanel,
  panel => panel.selectedUserImageId
)
const selectCurrentAdjustedImageId = createSelector(
  selectImageEnhancementPanel,
  panel => panel.currentAdjustedImageId
)
const selectCurrentUserImage = createSelector(
  selectSelectedUserImageId,
  apiSelectors.userImages,
  (userImageId, userImages) => (userImageId ? userImages[userImageId] : undefined)
)

const selectOriginalAdjustedImage = createSelector(
  selectCurrentUserImage,
  apiSelectors.adjustedImages,
  apiSelectors.userImages,
  (userImage, adjustedImages, userImages) => {
    const adjustedImage = userImage?.adjust ? adjustedImages[userImage?.adjust] : undefined

    return adjustedImage
      ? {
          ...adjustedImage,
          image: userImages[adjustedImage.image?.id ?? 0] ?? adjustedImage.image,
          output: userImages[adjustedImage.output?.id ?? 0] ?? adjustedImage.output
        }
      : undefined
  }
)

const selectCurrentAdjustedImage = createSelector(
  selectCurrentAdjustedImageId,
  apiSelectors.adjustedImages,
  apiSelectors.userImages,
  (adjustedImageId, adjustedImages, userImages) => {
    const adjustedImage = adjustedImageId ? adjustedImages[adjustedImageId] : undefined

    return adjustedImage
      ? {
          ...adjustedImage,
          image: userImages[adjustedImage.image?.id ?? 0] ?? adjustedImage.image,
          output: userImages[adjustedImage.output?.id ?? 0] ?? adjustedImage.output
        }
      : undefined
  }
)

const selectControls = createSelector(selectImageEnhancementPanel, panel => panel.controls)

const selectControl = createSelector(
  selectImageEnhancementPanel,
  (_: RootState, controlType: ControlType) => controlType,
  (panel, controlType) => panel.controls[controlType]
)

const selectDisplayedUserImage = createSelector(
  selectCurrentAdjustedImage,
  selectCurrentUserImage,
  (adjustedImage, currentUserImage) => {
    return adjustedImage?.output ?? adjustedImage?.image ?? currentUserImage
  }
)

const selectSource = createSelector(selectImageEnhancementPanel, panel => panel.source)
const selectShareRelatedData = createSelector(
  selectSource,
  apiSelectors.currentProject,
  apiSelectors.currentMixImageProject,
  apiSelectors.currentSketchProject,
  apiSelectors.currentProArtFilterProject,
  apiSelectors.currentTextToImageProject,
  apiSelectors.currentSketchTextToImageProject,
  (
    source,
    currentProject,
    currentMixImageProject,
    currentSketchProject,
    currentProArtFilterProject,
    currentTextToImageProject,
    currentSketchTextToImageProject
  ) => {
    const SHARE_RELATED_DEFAULT: PostCreateReq['related'] = { id: 0, type: 'training_project' }

    const data: { [key in ProjectObjectType]?: PostCreateReq['related'] } = {
      training_project: {
        id: currentProject?.id ?? 0,
        type: currentProject?.object_type ?? ''
      },
      pretrain_mix_project: {
        id: currentMixImageProject?.id ?? 0,
        type: currentMixImageProject?.object_type ?? ''
      },
      transfer_project: {
        id: currentProArtFilterProject?.id ?? 0,
        type: currentProArtFilterProject?.object_type ?? ''
      },
      sketch_project: {
        id: currentSketchProject?.id ?? 0,
        type: currentSketchProject?.object_type ?? ''
      },
      t2i_project: {
        id: currentTextToImageProject?.id ?? 0,
        type: currentTextToImageProject?.object_type ?? ''
      },
      ST2I_project: {
        id: currentSketchTextToImageProject?.id ?? 0,
        type: currentSketchTextToImageProject?.object_type ?? ''
      }
    }

    return (source ? data[source] : undefined) ?? SHARE_RELATED_DEFAULT
  }
)

const selectProjectName = createSelector(
  selectSource,
  apiSelectors.currentProject,
  apiSelectors.currentMixImageProject,
  apiSelectors.currentSketchProject,
  apiSelectors.currentProArtFilterProject,
  apiSelectors.currentTextToImageProject,

  (
    source,
    currentProject,
    currentMixImageProject,
    currentSketchProject,
    currentProArtFilterProject,
    currentTextToImageProject
  ) => {
    const data: { [key in ProjectObjectType]?: string } = {
      training_project: currentProject?.name,
      pretrain_mix_project: currentMixImageProject?.name,
      transfer_project: currentProArtFilterProject?.name,
      sketch_project: currentSketchProject?.name,
      t2i_project: currentTextToImageProject?.name
    }

    return (source ? data[source] : undefined) ?? ''
  }
)

const selectDownloadData = createSelector(
  selectProjectName,
  selectSource,
  selectCurrentUserImage,
  selectDisplayedUserImage,
  (projectName, source, currentUserImage, displayedUserImage) => {
    if (!displayedUserImage) {
      return
    }

    const fileName = `enhanced-${projectName}-${currentUserImage?.id ?? ''}`

    const data: {
      [key in ProjectObjectType]?: ActionType<
        typeof downloaderActions.single.downloadPaidUserImage
      >['payload']
    } = {
      training_project: {
        userImage: displayedUserImage,
        fileName,
        downloadLocation: 'Train Project - Download In Enhanced Panel'
      },
      pretrain_mix_project: {
        userImage: displayedUserImage,
        fileName,
        downloadLocation: 'Mix Image - Download In Enhanced Panel'
      },
      transfer_project: {
        userImage: displayedUserImage,
        fileName,
        downloadLocation: 'ProArtFilter - Download'
      },
      sketch_project: {
        userImage: displayedUserImage,
        fileName,
        downloadLocation: 'Sketch Project - Download'
      },
      t2i_project: {
        userImage: displayedUserImage,
        fileName,
        downloadLocation: 'Text To Image - Download'
      },
      app_project: {
        userImage: displayedUserImage,
        fileName,
        downloadLocation: 'Ai App Project - Download'
      },
      ST2I_project: {
        userImage: displayedUserImage,
        fileName,
        downloadLocation: 'Sketch Text To Image - Download'
      }
    }

    return source ? data[source] : undefined
  }
)

const selectIsChanged = createSelector(selectImageEnhancementPanel, panel => panel.isChanged)
const selectShareData = createSelector(
  selectShareRelatedData,
  selectDisplayedUserImage,
  (selectedItemShareRelatedData, userImage) => {
    return {
      related: selectedItemShareRelatedData,
      entity: {
        id: userImage?.id ?? 0,
        type: userImage?.object_type ?? 'user_image'
      }
    }
  }
)

const selectBookmarkData = createSelector(
  selectSource,
  apiSelectors.currentProject,
  apiSelectors.currentMixImageProject,
  apiSelectors.currentSketchProject,
  apiSelectors.currentProArtFilterProject,
  apiSelectors.currentTextToImageProject,
  apiSelectors.currentGenericAppProject,
  apiSelectors.currentSketchTextToImageProject,
  selectCurrentUserImage,
  apiSelectors.userImages,
  (
    source,
    currentProject,
    currentMixImageProject,
    currentSketchProject,
    currentProArtFilterProject,
    currentTextToImageProject,
    currentGenericAppProject,
    currentSketchTextToImageProject,
    userImage,
    userImages
  ) => {
    const data: {
      [key in ProjectObjectType]?: {
        bookmarkLocation: BookmarkLocation
        scope: BookmarkCreateReq<'user-image'>['scope']
      }
    } = {
      training_project: {
        bookmarkLocation: 'Train Project - Image Enhancement Panel',
        scope: currentProject?.bookmark_scope ?? ''
      },
      pretrain_mix_project: {
        bookmarkLocation: 'Mix Image - Image Enhancement Panel',
        scope: currentMixImageProject?.bookmark_scope ?? ''
      },
      transfer_project: {
        bookmarkLocation: 'ProArtFilter - Result List',
        scope: currentProArtFilterProject?.bookmark_scope ?? ''
      },
      sketch_project: {
        bookmarkLocation: 'Sketch To Image - Result List',
        scope: currentSketchProject?.bookmark_scope ?? ''
      },
      t2v_project: {
        bookmarkLocation: 'Text To Video - Result List',
        scope: currentTextToImageProject?.bookmark_scope ?? ''
      },
      t2i_project: {
        bookmarkLocation: 'Text To Image - Result List',
        scope: currentTextToImageProject?.bookmark_scope ?? ''
      },
      app_project: {
        bookmarkLocation: 'Text To Image - Result List',
        scope: currentGenericAppProject?.bookmark_scope ?? ''
      },
      ST2I_project: {
        bookmarkLocation: 'Sketch Text To Image - Result',
        scope: currentSketchTextToImageProject?.bookmark_scope ?? ''
      }
    }

    const item = userImage?.id ?? 0
    const datum = source ? data[source] : undefined
    const bookmarkRequest: BookmarkCreateReq<'user-image'> = {
      scope: datum?.scope ?? '',
      item
    }
    return {
      bookmarkLocation: datum?.bookmarkLocation ?? 'Sketch To Image - Result List',
      bookmarkRequest,
      isBookmarked: Boolean(userImages[item]?.bookmark)
    }
  }
)

export const selectors = {
  selectedUserImageId: selectSelectedUserImageId,
  imageEnhancement: selectImageEnhancementPanel,
  currentUserImage: selectCurrentUserImage,
  currentAdjustedImage: selectCurrentAdjustedImage,
  originalAdjustedImage: selectOriginalAdjustedImage,
  controls: selectControls,
  source: selectSource,
  controlArray: createSelector(selectControls, controls =>
    Object.keys(controls).map(control => ({
      filter: control as AdjustedImageControl,
      values: [_toNumber(controls[control as AdjustedImageControl])]
    }))
  ),
  control: selectControl,
  displayedUserImage: selectDisplayedUserImage,
  isChanged: selectIsChanged,
  editedControlType: createSelector(selectImageEnhancementPanel, panel => panel.editedControlType),
  showSharePanel: createSelector(selectImageEnhancementPanel, panel => panel.showSharePanel),
  canRevertChanges: selectIsChanged,
  canApply: createSelector(
    selectIsChanged,
    apiSelectors.loading['imageEnhancement.connectAdjustedImage'],
    apiSelectors.loading['imageEnhancement.deleteAdjustedImage'],
    (isChanged, loading, loading2) => isChanged && !loading && !loading2
  ),
  downloadData: selectDownloadData,
  shareData: selectShareData,
  bookmarkData: selectBookmarkData,
  canRevertToOriginal: createSelector(selectControls, controls => {
    const currentValues = _values(controls)
    return !_isEqual(IMAGE_CONTROL_DEFAULT_ARRAY, currentValues)
  }),
  controlMobileList: createSelector(selectControls, controls => {
    return CONTROL_MOBILE_LIST.map(controlKey => ({
      controlKey,
      label: TOOLS_DATA[controlKey]?.label,
      Icon: TOOLS_DATA[controlKey]?.Icon ?? Fragment,
      isActive: controls[controlKey] !== IMAGE_CONTROL_DEFAULT[controlKey]
    }))
  })
}

// reducers
export type ImageEnhancementPanelState = {
  source?: ProjectObjectType
  selectedUserImageId?: number
  currentAdjustedImageId?: number
  controls: AdjustedImage['controls']
  isChanged: boolean
  showSharePanel: boolean
  editedControlType: {
    show?: ControlType
    lastValue?: number
  }
}

export const INITIAL_DATA: ImageEnhancementPanelState = {
  source: undefined,
  selectedUserImageId: undefined,
  currentAdjustedImageId: undefined,
  controls: IMAGE_CONTROL_DEFAULT,
  isChanged: false,
  showSharePanel: false,
  editedControlType: {
    show: undefined,
    lastValue: undefined
  }
}

const reducer = produce((state: ImageEnhancementPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setSelectedUserImageId): {
      const data = payload as ActionType<typeof actions.setSelectedUserImageId>['payload']

      state.selectedUserImageId = data.userImageId
      return
    }
    case getType(actions.setSource): {
      const { source } = payload as ActionType<typeof actions.setSource>['payload']

      state.source = source
      return
    }
    case getType(actions.setCurrentAdjustedImageId): {
      const data = payload as ActionType<typeof actions.setCurrentAdjustedImageId>['payload']

      state.currentAdjustedImageId = data.adjustedImageId
      return
    }
    case getType(actions.setIsChanged): {
      const data = payload as ActionType<typeof actions.setIsChanged>['payload']

      state.isChanged = data
      return
    }
    case getType(actions.setControls): {
      const data = payload as ActionType<typeof actions.setControls>['payload']
      state.controls = data
      return
    }
    case getType(actions.setShowSharePanel): {
      const showSharePanel = payload as ActionType<typeof actions.setShowSharePanel>['payload']
      state.showSharePanel = showSharePanel
      return
    }
    case getType(actions.updateControls): {
      const data = payload as ActionType<typeof actions.updateControls>['payload']

      state.controls = {
        ...state.controls,
        ...data
      }
      state.isChanged = true
      return
    }
    case getType(actions.resetData): {
      state.currentAdjustedImageId = INITIAL_DATA.currentAdjustedImageId
      state.selectedUserImageId = INITIAL_DATA.selectedUserImageId
      state.controls = INITIAL_DATA.controls
      state.isChanged = INITIAL_DATA.isChanged
      state.source = INITIAL_DATA.source

      return
    }
    case getType(actions.editedControlType.show): {
      const data = payload as ActionType<typeof actions.editedControlType.show>['payload']

      state.editedControlType.show = data
      state.editedControlType.lastValue = _toNumber(state.controls[data])

      return
    }
    case getType(actions.editedControlType.cancel): {
      const lastValue = state.editedControlType.lastValue ?? 0
      const controlType = state.editedControlType.show
      if (controlType) {
        state.controls = {
          ...state.controls,
          [controlType]: lastValue
        }
      }

      state.editedControlType.show = undefined
      state.editedControlType.lastValue = undefined

      return
    }
    case getType(actions.editedControlType.done): {
      state.editedControlType.show = undefined
      state.editedControlType.lastValue = undefined
      return
    }
  }
}, INITIAL_DATA)

// Epics

const listenOnAppEvent: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    map(({ payload }) => payload),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ [AppEvents.OPEN_IMAGE_ENHANCEMENT]: event }) => Boolean(event)),
          map(({ [AppEvents.OPEN_IMAGE_ENHANCEMENT]: event }) => event?.payload),
          map(payload =>
            actions.initImageEnhancement({
              userImageId: payload?.userImageId ?? 0,
              source: payload?.source ?? 'training_project'
            })
          )
        ),
        of(param).pipe(
          filter(({ [AppEvents.POST_SHARED]: event }) => Boolean(event)),
          mergeMap(() => [
            actions.apply({ closeOnFinish: true, dontNotifyOnFinish: true }),
            actions.setShowSharePanel(false)
          ])
        )
      )
    )
  )

const setShowSharePanelEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.setShowSharePanel)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      showSharePanel: action.payload,
      shareData: selectors.shareData(state)
    })),
    filter(({ showSharePanel }) => showSharePanel),
    map(({ shareData }) =>
      eventEmiterActions.emit({
        [AppEvents.INIT_SHARE]: { event: AppEvents.INIT_SHARE, payload: shareData }
      })
    )
  )

const initImageEnhancementPanelEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.initImageEnhancement)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      source: action.payload.source,
      userImageId: action.payload.userImageId,
      userImages: apiSelectors.userImages(state),
      adjustedImages: apiSelectors.adjustedImages(state)
    })),
    filter(({ userImageId }) => Boolean(userImageId)),
    map(({ source, userImageId, userImages, adjustedImages }) => ({
      userImageId,
      source,
      userImages,
      userImage: userImages[userImageId],
      adjustedImages
    })),
    mergeMap(param =>
      merge(
        of(actions.setSource({ source: param?.source })),
        of(actions.setSelectedUserImageId({ userImageId: param?.userImageId ?? 0 })),
        of(actions.setCurrentAdjustedImageId({ adjustedImageId: undefined })),
        of(param).pipe(
          filter(
            ({ userImage, adjustedImages }) =>
              Boolean(userImage.adjust) && Boolean(adjustedImages[userImage.adjust ?? 0])
          ),
          mergeMap(({ userImage, adjustedImages }) => [
            actions.setControls(
              adjustedImages[userImage.adjust ?? 0]?.controls ?? INITIAL_DATA.controls
            ),
            actions.setCurrentAdjustedImageId({ adjustedImageId: userImage.adjust ?? 0 })
          ])
        ),
        of(param).pipe(
          filter(
            ({ userImage, adjustedImages }) =>
              Boolean(userImage.adjust) && !Boolean(adjustedImages[userImage.adjust ?? 0])
          ),
          mergeMap(({ userImage }) =>
            action$.pipe(
              filter(isActionOf(apiActions.imageEnhancement.retrieveAdjustedImageResponse)),
              take(1),
              map(({ payload }) => payload),
              mergeMap(payload => [
                actions.setControls(_omit(payload.controls, 'sharp')),
                actions.setCurrentAdjustedImageId({ adjustedImageId: payload.id })
              ]),
              startWith(
                apiActions.imageEnhancement.retrieveAdjustedImage({
                  adjustedImageId: userImage.adjust ?? 0
                })
              )
            )
          )
        )
      )
    )
  )

const applyEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.apply)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      closeOnFinish: action.payload.closeOnFinish,
      dontNotifyOnFinish: action.payload.dontNotifyOnFinish,
      originalAdjustedImage: selectors.originalAdjustedImage(state),
      currentAdjustedImageId: selectors.currentAdjustedImage(state)?.id ?? 0,
      currentAdjustedImageControls: selectors.currentAdjustedImage(state)?.controls,
      selectedUserImageId: selectors.selectedUserImageId(state),
      userImages: selectors.currentUserImage(state),
      projectData: appSelectors.projectData(state)
    })),
    filter(({ selectedUserImageId }) => Boolean(selectedUserImageId)),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ currentAdjustedImageId }) => Boolean(currentAdjustedImageId)),
          mergeMap(
            ({
              currentAdjustedImageId,
              currentAdjustedImageControls,
              projectData,
              selectedUserImageId,
              closeOnFinish,
              dontNotifyOnFinish
            }) =>
              action$.pipe(
                filter(isActionOf(apiActions.imageEnhancement.connectAdjustedImageResponse)),
                take(1),
                mergeMap(() =>
                  merge(
                    of(dontNotifyOnFinish).pipe(
                      filter(dontNotifyOnFinish => !Boolean(dontNotifyOnFinish)),
                      map(() =>
                        snackBarActions.show({
                          content: `Changes Applied`,
                          actionText: ''
                        })
                      )
                    ),
                    of(closeOnFinish).pipe(
                      delay(2000),
                      map(() => snackBarActions.close())
                    ),
                    of({ projectData, currentAdjustedImageControls }).pipe(
                      tap(({ projectData, currentAdjustedImageControls }) => {
                        MixPanelUtils.track<'PROJECT__APPLY_ENHANCE_IMAGE'>(
                          'Project - Apply Enhance Image',
                          {
                            ...projectData,
                            ...(currentAdjustedImageControls ?? {})
                          }
                        )
                      }),
                      ignoreElements()
                    ),
                    of(actions.setIsChanged(false)),
                    of(closeOnFinish).pipe(
                      filter(closeOnFinish => Boolean(closeOnFinish)),
                      map(() => dialogActions.closeAllDialog())
                    ),
                    of(closeOnFinish).pipe(
                      filter(closeOnFinish => Boolean(closeOnFinish)),
                      delay(300),
                      map(() => actions.resetData())
                    ),
                    of(actions.changesApplied())
                  )
                ),
                startWith(
                  apiActions.imageEnhancement.connectAdjustedImage({
                    id: currentAdjustedImageId,
                    image: selectedUserImageId ?? 0
                  })
                )
              )
          )
        ),
        of(param).pipe(
          filter(({ originalAdjustedImage, currentAdjustedImageId, selectedUserImageId }) =>
            Boolean(originalAdjustedImage && selectedUserImageId && !currentAdjustedImageId)
          ),
          mergeMap(
            ({ originalAdjustedImage, selectedUserImageId, closeOnFinish, dontNotifyOnFinish }) =>
              action$.pipe(
                filter(isActionOf(apiActions.imageEnhancement.deleteAdjustedImageResponse)),
                take(1),
                mergeMap(() =>
                  merge(
                    of(dontNotifyOnFinish).pipe(
                      filter(dontNotifyOnFinish => !Boolean(dontNotifyOnFinish)),
                      map(() =>
                        snackBarActions.show({
                          content: `Changes Applied`,
                          actionText: ''
                        })
                      )
                    ),
                    of(closeOnFinish).pipe(
                      delay(2000),
                      map(() => snackBarActions.close())
                    ),
                    of(actions.setIsChanged(false)),
                    of(closeOnFinish).pipe(
                      filter(closeOnFinish => Boolean(closeOnFinish)),
                      map(() => dialogActions.closeAllDialog())
                    ),
                    of(closeOnFinish).pipe(
                      filter(closeOnFinish => Boolean(closeOnFinish)),
                      delay(300),
                      map(() => actions.resetData())
                    ),
                    of(actions.changesApplied())
                  )
                ),
                startWith(
                  apiActions.imageEnhancement.deleteAdjustedImage({
                    adjustedImageId: originalAdjustedImage?.id ?? 0,
                    userImageId: selectedUserImageId ?? 0
                  })
                )
              )
          )
        )
      )
    )
  )

const updateControlEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.updateControls, actions.editedControlType.cancel])),
    debounceTime(500),
    withLatestFrom(state$),
    map(([_, state]) => ({
      userImageId: selectors.currentUserImage(state)?.id ?? 0,
      controls: selectors.controls(state)
    })),
    filter(({ userImageId }) => Boolean(userImageId)),
    switchMap(({ userImageId, controls }) =>
      action$.pipe(
        filter(isActionOf(apiActions.imageEnhancement.createAdjustedImageResponse)),
        take(1),
        map(({ payload }) => payload),
        map(payload => actions.setCurrentAdjustedImageId({ adjustedImageId: payload.id })),
        startWith(
          apiActions.imageEnhancement.createAdjustedImage({
            image: userImageId,
            controls
          })
        )
      )
    )
  )

const openUpscaleEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.openUpscale)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      currentDisplayedImage: selectors.displayedUserImage(state),
      canApply: selectors.canApply(state)
    })),

    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canApply }) => !canApply),
          map(({ currentDisplayedImage }) =>
            appActions.openUpscaleImage({
              userImageId: currentDisplayedImage?.id ?? 0,
              useAddDialog: true
            })
          )
        ),
        of(param).pipe(
          filter(({ canApply }) => canApply),
          switchMap(() =>
            action$.pipe(
              filter(isActionOf(actions.changesApplied)),
              take(1),
              withLatestFrom(state$),
              map(([_, state]) => ({
                currentDisplayedImage: selectors.displayedUserImage(state)
              })),
              map(({ currentDisplayedImage }) =>
                appActions.openUpscaleImage({
                  userImageId: currentDisplayedImage?.id ?? 0,
                  useAddDialog: true
                })
              ),
              startWith(actions.apply({ closeOnFinish: false, dontNotifyOnFinish: true }))
            )
          )
        )
      )
    )
  )

const revertChangesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.revertChanges)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      originalAdjustedImage: selectors.originalAdjustedImage(state)
    })),
    mergeMap(({ originalAdjustedImage }) => [
      actions.setIsChanged(false),
      actions.setControls(originalAdjustedImage?.controls ?? INITIAL_DATA.controls),
      actions.setCurrentAdjustedImageId({ adjustedImageId: originalAdjustedImage?.id })
    ])
  )
const resetDataDelayed: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.resetDataDelayed)),
    delay(300),
    map(() => actions.resetData())
  )

const revertToOriginalEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.revertToOriginal)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      originalAdjustedImage: selectors.originalAdjustedImage(state),
      currentAdjustedImage: selectors.currentAdjustedImage(state),
      currentUserImageId: selectors.currentUserImage(state)?.id
    })),
    mergeMap(param =>
      merge(
        of(actions.setControls(INITIAL_DATA.controls)),
        of(actions.setIsChanged(true)),
        of(actions.setCurrentAdjustedImageId({ adjustedImageId: undefined }))
      )
    )
  )

const closeDialogEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.closeDialog)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      isChanged: selectors.isChanged(state)
    })),
    mergeMap(({ isChanged }) =>
      merge(
        of(isChanged).pipe(
          filter(isChanged => Boolean(isChanged)),
          map(() =>
            dialogActions.addDialogOverlay({
              [ConfirmationDialog.CONFIRMATION]: {
                dialogName: ConfirmationDialog.CONFIRMATION,
                title: 'You have unsaved changes.',
                content: 'Do you want to exit without saving your changes?',
                swapActionPosition: true,
                cancelActionText: 'NO',
                yesAction: {
                  label: 'YES',
                  actions: [dialogActions.closeAllDialog(), actions.resetDataDelayed()]
                }
              }
            })
          )
        ),
        of(isChanged).pipe(
          filter(isChanged => !Boolean(isChanged)),
          map(() => dialogActions.closeAllDialog())
        ),
        of(isChanged).pipe(
          filter(isChanged => !Boolean(isChanged)),
          delay(300),
          map(() => actions.resetData())
        )
      )
    )
  )

export const epics = combineEpics(
  openUpscaleEpic,
  closeDialogEpic,
  setShowSharePanelEpic,
  revertToOriginalEpic,
  listenOnAppEvent,
  updateControlEpic,
  applyEpic,
  revertChangesEpic,
  resetDataDelayed,
  initImageEnhancementPanelEpic
)

export default reducer
