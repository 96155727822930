import React from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import AddIcon from '@mui/icons-material/Add'
import { useDropzone } from 'react-dropzone'
import { useDelayedLoading, useBreakpoint, useDimension } from 'utils/Hooks'
import { RootActionType, RootState } from 'duck'
import { actions, selectors, Utils } from '../../duck'
import { apiSelectors } from 'duck/ApiDuck'
import LargeImagePreview from 'components/LargeImagePreview'
import { appSelectors } from 'duck/AppDuck'
import Button from 'components/Button'
import { CircularLoading } from 'components/Loading'
import { values } from 'appConstants'
import { ReduxProps } from 'utils/Types'
import { getImageSize } from 'utils/DataProcessingUtils'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './ImagePreview.module.scss'

const mapStateToProps = (state: RootState) => ({
  userDeviceInfo: appSelectors.userDeviceInfo(state),
  upscaleImage: selectors.upscaleImage(state),
  imageFile: selectors.imageFile(state),
  mode: selectors.mode(state),
  currentUpscaleRecordText: selectors.currentUpscaleRecordText(state),
  retrieveUserImageUpscalesLoading:
    apiSelectors.loading['imageEnhancement.retrieveUserImageUpscales'](state),
  selectedMethod: selectors.selectedMethod(state),
  upscaleLoading: selectors.upscaleLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      setImageFile: actions.setImageFile,
      reset: actions.reset
    },
    dispatch
  )

type ImagePreviewProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const ImagePreview: React.FC<ImagePreviewProps> = props => {
  const {
    reset,
    setImageFile,
    mode,
    imageFile,
    userDeviceInfo,
    upscaleLoading,
    upscaleImage,
    retrieveUserImageUpscalesLoading,
    currentUpscaleRecordText,
    selectedMethod
  } = props

  const hasImage = upscaleImage?.smallImage || imageFile
  const isTouchDevice = userDeviceInfo?.summary?.isTouchDevice
  const retrieveUserImageUpscalesLoadingDelayed = useDelayedLoading(
    retrieveUserImageUpscalesLoading
  )

  const showStartOverButton = mode === 'standalone' && hasImage
  const showUploadPanel = !hasImage && mode === 'standalone'

  const loadingUserImage = retrieveUserImageUpscalesLoadingDelayed
    ? 'Loading Upscale Data'
    : undefined

  const { isSmallscreenAbove } = useBreakpoint()

  const [containerRef, containerDimension] = useDimension({ liveMeasure: true })
  const { width, height } = containerDimension
  const uploadLoading = false

  const dropzoneState = useDropzone({
    accept: values.UPLOAD_ACCEPT,
    multiple: false,
    disabled: uploadLoading,
    onDrop: files => {
      getImageSize(files[0]).then(({ width, height }) =>
        setImageFile({ file: files[0], width, height })
      )
    }
  })

  const ButtonReset = (
    <div className="absolute left-0 top-full mt-3 w-full text-center sm:bottom-full sm:top-auto sm:mb-3 sm:mt-0">
      <Button color="error" size="small" onClick={() => reset()}>
        CLEAR IMAGE AND START OVER
      </Button>
    </div>
  )

  return (
    <div className="relative h-full" ref={containerRef}>
      {!isSmallscreenAbove && showStartOverButton ? ButtonReset : null}

      <div
        className={clsx(
          'relative flex flex-col items-center',
          !isSmallscreenAbove && 'justify-center'
        )}
        style={{
          width,
          height: isSmallscreenAbove ? height : undefined
        }}
      >
        {isSmallscreenAbove && showStartOverButton ? ButtonReset : null}

        <div className="relative z-2" style={{ width, height: width }}>
          {showUploadPanel ? (
            <div
              {...dropzoneState.getRootProps()}
              className={clsx(
                'flex h-full w-full flex-col items-center justify-center',
                !uploadLoading && 'cursor-pointer',
                dropzoneState.isDragAccept && stylesGlobal.BgColorSecondaryFocus,
                styles.UpscaleUploadButton
              )}
            >
              <input {...dropzoneState.getInputProps()} />

              <span className="flex w-full flex-col items-center justify-center">
                {!uploadLoading ? (
                  <AddIcon
                    color="secondary"
                    className={clsx('mb-2', styles.UpscaleUploadButtonIcon)}
                  />
                ) : (
                  <CircularLoading className="mb-3" size={20} loading disableShrink />
                )}

                <Typography variant="button" className="text-secondary">
                  {!dropzoneState.isDragAccept && !uploadLoading ? 'UPLOAD IMAGE' : null}
                  {!dropzoneState.isDragAccept && uploadLoading ? 'UPLOADING IMAGE' : null}
                  {dropzoneState.isDragAccept ? 'DRAG AND DROP TO UPLOAD' : null}
                </Typography>
              </span>
            </div>
          ) : (
            <LargeImagePreview
              size={{ width, height: width }}
              loading={upscaleLoading ?? loadingUserImage}
              imageFile={imageFile?.file}
              image={
                (selectedMethod ? upscaleImage?.largeImage : upscaleImage?.smallImage) ??
                upscaleImage?.smallImage
              }
            />
          )}

          {Boolean(upscaleImage?.isAdjusted) ? (
            <div className="absolute left-0 top-0 ml-2 mt-2 bg-primary px-3 py-0.5">
              <Typography variant="body2">ENHANCED</Typography>
            </div>
          ) : null}
        </div>

        <div className="mx-auto mt-2 flex w-full justify-between">
          {!upscaleLoading && selectedMethod && upscaleImage?.largeImage ? (
            <span className="mr-2">
              <ZoomInIcon className="mr-1 align-middle text-white-med-on-dark" />
              <Typography
                variant="body2"
                className="mb-2 inline text-right text-white-med-on-dark"
                component="div"
              >
                {Utils.getZoomMethodText(isTouchDevice)}
              </Typography>
            </span>
          ) : null}

          {!selectedMethod && !showUploadPanel ? (
            <Typography
              variant="body2"
              className="mb-2 inline text-right text-white-med-on-dark"
              component="div"
            >
              Select one of the upscale method
            </Typography>
          ) : null}

          <div>
            {!upscaleLoading && selectedMethod ? (
              <Typography
                variant="body2"
                className="text-right text-white-med-on-dark"
                component="div"
              >
                {upscaleImage?.largeImage
                  ? currentUpscaleRecordText
                  : 'Click Upscale to generate preview'}
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(ImagePreview)
