import React from 'react'
import clsx from 'clsx'
import LogoIcon from 'components/Icon/LogoIcon'
import styles from './Loading.module.scss'

type LoadingType = {
  size?: 'small' | 'extraSmall'
  className?: string
}

const LogoLoading: React.FC<LoadingType> = ({ size, className }) => {
  const svgClass =
    size === 'small'
      ? styles.LogoLoadingSmall
      : size === 'extraSmall'
        ? styles.LogoLoadingExtraSmall
        : styles.LogoLoading

  return (
    <div className={clsx(styles.LogoLoadingContainer, className)}>
      <LogoIcon className={svgClass} />
    </div>
  )
}

export * from './LoadingPage'
export * from './CircularLoading'
export default LogoLoading
