import React from 'react'
import { useSelector } from 'react-redux'
import { appSelectors } from 'duck/AppDuck'
import { RootState } from 'duck'
import { FormDialog } from 'duck/AppDuck/DialogDuck'
import { useBooleanDelayedData } from 'utils/Hooks'
import loadable from 'utils/Loadable'

const ProcessesPanel = loadable(() => import(/* webpackChunkName: "ProcessesPanel" */ './index'))

const ProcessesPanelContainer: React.FC<{
  floating?: boolean
}> = props => {
  const activeDialog = useSelector((state: RootState) => appSelectors.dialog.activeDialog(state))

  const showDialog = useBooleanDelayedData(
    !props.floating || (props.floating && activeDialog === FormDialog.NEW_PROJECT)
  )

  if (!showDialog) return null

  return <ProcessesPanel {...props} />
}

export default ProcessesPanelContainer
