import { apiActions, SketchToImageActionType } from '../actions'
import { SharedActionsType } from '../sharedActions'
import {
  SketchStyleListResponse,
  SketchProject,
  GenreListResponse,
  SketchProjectListResponse,
  SketchOutputListResponse,
  SketchOutputImage
} from 'models/ApiModels'
import { createReducer, getType } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _without from 'lodash/without'
import _uniq from 'lodash/uniq'

export type SketchToImageState = {
  currentSketchProjectId?: number
  sketchGenres: GenreListResponse | null
  sketchStyles: SketchStyleListResponse | null
  sketchProjectList: number[] | null
  lastSketchProjectsListReq: SketchProjectListResponse | null
  sketchProjects: {
    [sketchProjectId: string]: SketchProject
  }
  outputList: {
    [sketchProjectId: string]: {
      lastListReq: SketchOutputListResponse
      list: number[]
    }
  }
  outputs: { [sketchOutputId: number]: SketchOutputImage }
}

export const initialSketchToImageState: SketchToImageState = {
  currentSketchProjectId: undefined,
  sketchGenres: null,
  sketchStyles: null,
  sketchProjectList: null,
  lastSketchProjectsListReq: null,
  sketchProjects: {},
  outputList: {},
  outputs: {}
}
export const sketchToImageReducer = createReducer<
  SketchToImageState,
  SketchToImageActionType | SharedActionsType
>(initialSketchToImageState)
  .handleAction(apiActions.sketchToImage.setCurrentSketchProject, (state, action) => {
    const id = action?.payload
    return {
      ...state,
      currentSketchProjectId: id
    }
  })
  .handleAction(apiActions.sketchToImage.listSketchGenreResponse, (state, action) => {
    return {
      ...state,
      sketchGenres: action.payload
    }
  })
  .handleAction(apiActions.sketchToImage.listSketchStylesResponse, (state, action) => {
    return {
      ...state,
      sketchStyles: action.payload
    }
  })
  .handleAction(apiActions.sketchToImage.listSketchProjectResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.sketchProjectList || [] : []

    const combinedResults = results.map(result => ({
      ...(state.sketchProjects[result.id] ?? {}),
      ...result
    }))
    return {
      ...state,
      sketchProjectList: [...prevData, ..._map(results, result => result.id)],
      lastSketchProjectsListReq: data,
      sketchProjects: {
        ...state.sketchProjects,
        ..._keyBy(combinedResults, result => result.id)
      }
    }
  })
  .handleAction(apiActions.sketchToImage.listSketchOutputResponse, (state, action) => {
    const { data, next, req } = action.payload

    const projectId = req?.project || 0
    const results = data?.results ?? []
    const prevData = next ? state.outputList[projectId]?.list || [] : []

    return {
      ...state,
      outputList: {
        ...state.outputList,
        [projectId]: {
          lastListReq: data,
          list: _uniq([...prevData, ..._map(results, result => result.id)])
        }
      },
      outputs: {
        ...state.outputs,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(
    [
      apiActions.sketchToImage.createSketchProjectResponse,
      apiActions.sketchToImage.generateSketchProjectResponse,
      apiActions.sketchToImage.retrieveSketchProjectResponse,
      apiActions.sketchToImage.updateSketchProjectResponse,
      apiActions.sketchToImage.updateThresholdResponse,
      apiActions.sketchToImage.uploadSketchResponse,
      apiActions.sketchToImage.removeSketchResponse
    ],
    (state, action) => {
      const isCreate = action.type === getType(apiActions.sketchToImage.createSketchProjectResponse)
      const isRetrieve =
        action.type === getType(apiActions.sketchToImage.retrieveSketchProjectResponse)

      const id = action.payload?.id || 0
      const currentData = state.sketchProjects[id]
      const outputImages = action.payload?.outputs || []
      const prevList = state.outputList[id]?.list || []

      return {
        ...state,
        sketchProjects: {
          ...state.sketchProjects,
          [id]: { ...currentData, ...action.payload }
        },
        outputList: isRetrieve
          ? state.outputList
          : {
              ...state.outputList,
              [id]: {
                ...state.outputList[id],
                list: _uniq([..._map(outputImages, result => result.image.id), ...prevList])
              }
            },
        sketchProjectList: isCreate
          ? [id, ...(state.sketchProjectList ?? [])]
          : state.sketchProjectList,
        outputs: {
          ...state.outputs,
          ..._keyBy(outputImages, result => result.image.id)
        }
      }
    }
  )
  .handleAction(apiActions.sketchToImage.deleteSketchProjectResponse, (state, action) => {
    const { [action.payload]: deletedProject, ...sketchProjects } = state.sketchProjects
    const sketchProjectList = _without(state.sketchProjectList || [], deletedProject.id)

    return {
      ...state,
      sketchProjectList,
      sketchProjects
    }
  })
  .handleAction(apiActions.sketchToImage.resetSketchQueueData, (state, action) => {
    const payload = action.payload
    const currentProject = state.sketchProjects[payload]
    return currentProject
      ? {
          ...state,
          sketchProjects: {
            ...state.sketchProjects,
            [payload]: {
              ...currentProject,
              queue_length: undefined,
              expect_finished: undefined
            }
          }
        }
      : state
  })
  .handleAction(apiActions.sketchToImage.deleteSketchOutputResponse, (state, action) => {
    const sketchProjectId = state.currentSketchProjectId ?? 0
    const currentList = state.outputList[sketchProjectId].list
    const newList = _without(currentList || [], action.payload)
    return {
      ...state,
      outputList: {
        ...state.outputList,
        [sketchProjectId]: {
          ...state.outputList[sketchProjectId],
          list: newList
        }
      }
    }
  })
