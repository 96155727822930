import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { push } from 'redux-first-history'
import { combineEpics, Epic } from 'redux-observable'
import { map, filter, withLatestFrom, startWith, mergeMap, take } from 'rxjs/operators'
import { of, merge } from 'rxjs'
import { name } from 'appConstants'

import { RootState, RootActionType } from 'duck'
import { apiActions } from 'duck/ApiDuck'
import { createAction, getType, isActionOf } from 'typesafe-actions'
import { route } from 'routes'

// Constants
const NAMESPACE = '@@panel/StartProjectPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const actions = {
  setSelectedStartProject: createAction(creator('SET_SELECTED_START_PROJECT'))<string>(),
  continue: createAction(creator('CONTINUE'))()
}

// Selectors
const selectStartProjectPanel = (state: RootState) => state.container.startProjectPanel

export const selectors = {
  startProjectPanel: selectStartProjectPanel
}

// Reducer
export type StartProjectPanelState = {
  selectedStartProject: string
}
const initial: StartProjectPanelState = {
  selectedStartProject: ''
}

const reducer = produce((state: StartProjectPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setSelectedStartProject): {
      state.selectedStartProject = payload
      return
    }
    default:
  }
}, initial)

// Epics

const continueEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.continue)),
    withLatestFrom(state$),
    map(([_, state]) => selectors.startProjectPanel(state).selectedStartProject),
    mergeMap(selectedStartProject =>
      merge(
        of(selectedStartProject).pipe(
          filter(selectedStartProject => selectedStartProject === 'sketch_to_image'),
          map(() =>
            push(
              `${route.SKETCH_PROJECTS.getUrl()}?name=${
                name.FIRST_TIME_DEFAULT_SKETCH_PROJECT_NAME
              }`
            )
          )
        ),
        of(selectedStartProject).pipe(
          filter(selectedStartProject => selectedStartProject !== 'sketch_to_image'),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.createResponse)),
              take(1),
              map(({ payload }) => push(route.TRAIN_PROJECTS.getUrl({ id: payload.id }))),
              startWith(
                apiActions.projects.create({
                  name: name.FIRST_TIME_DEFAULT_PROJECT_NAME,
                  is_private: true,
                  category: selectedStartProject
                })
              )
            )
          )
        ),
        of(actions.setSelectedStartProject(''))
      )
    )
  )
export const epics = combineEpics(continueEpic)
export default reducer
