// The Global Epic, reducer, and actions, later will be moved here
// For now, it's only contain selector

import { reducers } from './reducers'
export * from './selectors'
export * from './actions'
export * from './sharedActions'
export * from './epics'
export * from './reducers'

export default reducers
