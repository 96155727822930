import { TextTransform, UrlUtils } from 'utils/TextUtils'
import produce from 'immer'
import { createSelector } from 'reselect'
import _filter from 'lodash/filter'
import _cloneDeep from 'lodash/cloneDeep'
import _compact from 'lodash/compact'
import { combineEpics, Epic } from 'redux-observable'
import _keys from 'lodash/keys'
import { values, urlParam, name } from 'appConstants'

import {
  withLatestFrom,
  map,
  filter,
  mergeMap,
  startWith,
  take,
  concatMap,
  tap,
  debounceTime
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { push } from 'redux-first-history'
import {
  ControlInfluence,
  AnchorType,
  BookmarkCreateReq,
  UserImage,
  MixImageGenerateReq
} from 'models/ApiModels'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { createAction, isActionOf, getType, ActionType } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { route } from 'routes'
import { actions, selectors } from '.'
import {
  applyExtraAttributeConfig,
  extraAttributeActions,
  extraAttributeSelectors
} from './ExtraAttributes'
import { projectMixActions } from './ProjectMix'
import MixPanelUtils, { DataUtils, DownloadLocation } from 'utils/MixPanelUtils'
import { appActions } from 'duck/AppDuck'
import { panelsActions, panelsSelectors } from './Panels'
import {
  GridImage,
  CreateNewMixParam,
  UpdateInfluenceParam,
  UpdateEditParam,
  ANCHOR_PATTERN,
  GRID_ARRAY,
  BookmarkDataType,
  ShareMixData,
  MAX_ANCHOR,
  EditType,
  INITIAL_CONTROL,
  INITIAL_ANCHOR,
  SetEditPreviewParam,
  MixPageWithoutPaidDownload,
  PanelWithoutPaidDownload
} from './Models'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import { errorUtils } from 'utils/DataProcessingUtils'
import SentryUtils from 'utils/SentryUtils'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'

/*
There are two cases is_resample should always set to true .
- In one anchor mode, user changed anchor.
- User switch from multi-anchors mode to one anchor mode.
*/

// Constants
const NAMESPACE = '@@page/MixImagePanel/MixImage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)
export const GENERATING_POOL_INTERVAL = 2000
export const GENERATING_PREVIEW_POOL_INTERVAL = 1000
export const ADDITIONAL_THRESHOLD_GENERATE = 4 //in second

export const Utils = {
  getGenerateInterval: (generateType: MixImageProjectData['generateData']['generateType']) =>
    generateType === 'mix' ? GENERATING_POOL_INTERVAL : GENERATING_PREVIEW_POOL_INTERVAL,

  getGenerateErrorDialogText: (
    generateType: MixImageProjectData['generateData']['generateType']
  ) => {
    const titleText = generateType === 'mix' ? 'mix image result' : 'image preview'
    return {
      [ErrorDialog.ERROR]: {
        dialogName: ErrorDialog.ERROR,
        title: `Unable To Generate ${TextTransform.textCase(titleText, 'titlecase')}`,
        content: [
          `We're unable to generate ${titleText} in expected time.`,
          `Please try again in few minutes`,
          `if this keeps happening, contact us by click on the "Question" button or send an email to ${values.PLAYFORM_CONTACT_EMAIL}.`
        ]
      }
    }
  },
  createAnchor: (param: Partial<AnchorType>): AnchorType => {
    const anchor = _cloneDeep({ ...INITIAL_ANCHOR, ...param })
    const hasEdit = Boolean(_keys(anchor?.extra_attributes).length)

    return {
      ...anchor,
      labels: hasEdit ? anchor.labels : {},
      extra_attributes: hasEdit ? anchor?.extra_attributes : {}
    }
  },
  getName: () => {
    return (
      UrlUtils.getParam(window.location.search ?? '', urlParam.NAME) ||
      name.DEFAULT_MIX_IMAGE_PROJECT_NAME
    )
  },
  getKey: (id: MixImageState['id'], source: MixImageState['source']) => `${source}_${id}`
}

// Actions
export const mixImageActions = {
  initMixImagePanel: createAction(creator('INIT_MIX_IMAGE_PANEL'))<
    Pick<MixImageState, 'id' | 'source' | 'isNewProject'>
  >(),
  createNewProject: createAction(creator('CREATE_NEW_PROJECT'))(),
  createNewMix: createAction(creator('CREATE_NEW_MIX'))<CreateNewMixParam>(),
  pushToMixTab: createAction(creator('PUSH_TO_MIX_TAB'))(),
  setSelectedGrid: createAction(creator('SET_SELECTED_GRID'))<number>(),
  setSelectedReplaceGrid: createAction(creator('SET_SELECTED_REPLACE_GRID'))<number | undefined>(),
  setReplaceCandidate: createAction(creator('SET_REPLACE_CANDIDATE'))<number | undefined>(),
  resetReplaceGrid: createAction(creator('RESET_REPLACE_GRID'))(),
  setSelectedSaved: createAction(creator('SET_SELECTED_SAVED'))<number | undefined>(),
  download: createAction(creator('DOWNLOAD'))<{
    downloadLocation: DownloadLocation
    image?: UserImage
  }>(),
  undo: createAction(creator('UNDO'))(),
  redo: createAction(creator('REDO'))(),
  triggerUndo: createAction(creator('TRIGGER_UNDO'))(),
  storeUndo: createAction(creator('STORE_UNDO'))(),

  //Anchor Manipulation
  addAnchor: createAction(creator('ADD_ANCHOR'))<Partial<AnchorType>>(),
  addAnchorWithoutRegenerate: createAction(creator('ADD_ANCHOR_WITHOUT_REGENERATE'))<
    Partial<AnchorType>
  >(),
  removeAnchor: createAction(creator('REMOVE_ANCHOR'))<number>(),
  replaceAnchor: createAction(creator('REPLACE_ANCHOR'))<[number, Partial<AnchorType>]>(),
  swapAnchor: createAction(creator('SWAP_ANCHOR'))<[number, number]>(),

  setIsAttributeChanged: createAction(creator('SET_IS_ATTRIBUTE_CHANGED'))<boolean>(),
  setIsAnchorChanged: createAction(creator('SET_IS_ANCHOR_CHANGED'))<boolean>(),
  generateGrid: createAction(creator('GENERATE_GRID'))(),
  generateResampleGrid: createAction(creator('GENERATE_RESAMPLE_GRID'))(),
  saveGenerateGridResult: createAction(creator('SAVE_GENERATE_GRID_RESULT'))<UserImage[]>(),
  updateInfluence: createAction(creator('UPDATE_INFLUENCE'))<UpdateInfluenceParam>(),
  setIsIlustrative: createAction(creator('SET_IS_ILUSTRATIVE'))<boolean>(),

  updateEdit: createAction(creator('UPDATE_EDIT'))<UpdateEditParam>(),
  setEditPreview: createAction(creator('SET_EDIT_PREVIEW'))<SetEditPreviewParam>(),
  generateEditPreview: createAction(creator('GENERATE_EDIT_PREVIEW'))(),
  setGenerateEditPreviewHasQueue: createAction(
    creator('SET_GENERATE_EDIT_PREVIEW_QUEUE')
  )<boolean>(),
  startOver: createAction(creator('START_OVER'))(),
  setExpandExtraAttributePanel: createAction(
    creator('SET_EXPAND_EXTRA_ATTRIBUTE_PANEL')
  )<boolean>(),
  setGenerating: createAction(creator('SET_GENERATING'))<boolean>(),
  setEnableRegenerate: createAction(creator('SET_ENABLE_REGENERATE'))<boolean>(),
  setGenerateType: createAction(creator('SET_GENERATE_TYPE'))<
    Pick<MixImageProjectData['generateData'], 'generateType' | 'anchorIndex'>
  >()
}

// Selectors
const selectMixImage = (state: RootState) => state.container.mixImagePanel.mixImage

const selectSource = createSelector(selectMixImage, mixImage => mixImage.source)
const selectMixImageId = createSelector(selectMixImage, mixImage => mixImage.id)

const selectMixImageData = createSelector(selectMixImage, mixImage => {
  const initial = _cloneDeep(INITIAL_MIX_IMAGE_PROJECT_DATA)
  const key = Utils.getKey(mixImage.id, mixImage.source)
  return mixImage.id ? mixImage.data[key] ?? initial : initial
})

const selectAnchors = createSelector(selectMixImageData, data => data.mixImageData.anchors)
const selectIsIlustrative = createSelector(
  selectMixImageData,
  data => data.mixImageData.is_illustrative
)
const selectOutputs = createSelector(selectMixImageData, data => data.mixImageData.outputs)

const selectEdits = createSelector(selectMixImageData, data => data.mixImageData.edits)

const selectGridImagesPage = createSelector(
  selectAnchors,
  apiSelectors.userImages,
  selectOutputs,
  selectEdits,
  (anchors, userImages, outputs, edits) => {
    const outputImages = outputs.map(id => userImages[id])
    const anchorPattern = ANCHOR_PATTERN[anchors.length] || []
    const gridImages = GRID_ARRAY.map((_, index) => {
      const anchorIndex = anchorPattern.findIndex(value => value === index)
      const isAnchor = anchorIndex >= 0
      const anchorValue = anchors[anchorIndex]
      const anchorId = anchorValue?.id

      const anchorImage = userImages?.[anchorId]
      const outputImage = outputImages[index]
      const image = isAnchor ? anchorImage : outputImage
      const edit = edits[image?.id ?? 0]

      const data: GridImage = {
        imageId: image?.id ?? 0,
        index,
        image: userImages[edit?.previewId ?? 0] || image,
        outputImage,
        edit,
        anchorIndex,
        isAnchor
      }
      return data
    })

    return gridImages
  }
)
const selectGridImagesProject = createSelector(
  selectAnchors,
  selectOutputs,
  apiSelectors.userImages,
  (anchors, outputs, userImages) => {
    const outputsData = outputs.map(id => userImages[id])

    const anchorLength = anchors.length
    const anchorPattern = ANCHOR_PATTERN[anchorLength] || []
    const gridImages = GRID_ARRAY.map((_, index) => {
      const anchorIndex = anchorPattern.findIndex(value => value === index)
      const isAnchor = anchorIndex >= 0
      const anchorValue = anchors[anchorIndex]

      const anchorId = anchorValue?.id
      const anchorImage = userImages?.[anchorId]
      const outputImage = outputsData[index]

      const data: GridImage = {
        index,
        image: isAnchor ? anchorImage : outputImage,
        outputImage,
        anchorIndex,
        isAnchor
      }
      return data
    })

    return gridImages
  }
)
const selectGridImages = createSelector(
  selectSource,
  selectGridImagesPage,
  selectGridImagesProject,
  (source, gridImagesPage, gridImagesProject) =>
    source === 'train' ? gridImagesProject : gridImagesPage
)

const selectSelectedGrid = createSelector(
  selectMixImageData,
  data => data.mixImageData.selectedGrid
)
const selectSelectedSaved = createSelector(selectMixImageData, data => data.selectedSaved)

const selectSelectedSavedData = createSelector(
  selectSelectedSaved,
  apiSelectors.userImageBookmarks,
  (selectedSavedId, userImageBookmarks) => {
    return selectedSavedId ? userImageBookmarks[selectedSavedId] : undefined
  }
)
const selectBookmarkScope = createSelector(
  apiSelectors.currentProject,
  apiSelectors.currentMixImageProject,
  selectSource,
  (currentProject, currentMixProject, source) => {
    const projectScope = currentProject?.bookmark_scope
    const mixProjectScope = currentMixProject?.bookmark_scope

    return source === 'train' ? projectScope : mixProjectScope
  }
)

const selectSelectedReplaceGrid = createSelector(
  selectMixImageData,
  data => data.selectedReplaceGrid
)

const selectSelectedGridData = createSelector(
  selectSelectedGrid,
  selectGridImages,
  (selectedGrid, gridImages) => {
    return selectedGrid !== undefined ? gridImages[selectedGrid] : null
  }
)

const selectSelectedGridBookmarkData = createSelector(
  selectSelectedGridData,
  selectBookmarkScope,
  (selectedGridData, bookmarkScope) => {
    const item = selectedGridData?.image

    const bookmarkRequest: BookmarkCreateReq<'user-image'> = {
      scope: bookmarkScope,
      item: item?.id ?? 0
    }
    const bookmark = item?.bookmark

    const bookmarkData: BookmarkDataType = {
      isBookmarkAvailable: Boolean(item?.id),
      bookmarkRequest,
      bookmark,
      isBookmarked: Boolean(bookmark)
    }

    return bookmarkData
  }
)
const selectShareRelated = createSelector(
  apiSelectors.currentProject,
  apiSelectors.currentMixImageProject,
  selectSource,
  (currentProject, currentMixProject, source) => {
    return source === 'train'
      ? {
          id: currentProject?.id ?? 0,
          type: currentProject?.object_type ?? ''
        }
      : {
          id: currentMixProject?.id,
          type: currentMixProject?.object_type
        }
  }
)

const selectSelectedGridShareData = createSelector(
  selectSelectedGridData,
  selectShareRelated,
  (selectedGridData, shareRelated) => {
    const shareData: ShareMixData = {
      related: shareRelated,
      entity: {
        id: selectedGridData?.image?.id ?? 0,
        type: selectedGridData?.image?.object_type || 'user_image'
      }
    }
    return shareData
  }
)

const selectSelectedReplaceGridData = createSelector(
  selectSelectedReplaceGrid,
  selectGridImages,
  (selectedReplaceGrid, gridImages) => {
    return selectedReplaceGrid !== undefined ? gridImages[selectedReplaceGrid] : null
  }
)

const selectEditedExtraAttributesValues = createSelector(
  selectSelectedGridData,
  selectEdits,
  (selectedGridData, edits) => {
    const imageId = selectedGridData?.imageId ?? 0

    return edits[imageId]?.extra_attributes
  }
)
const selectEditedExtraAttributesLabels = createSelector(
  selectSelectedGridData,
  selectedGridData => {
    return selectedGridData?.edit?.labels ?? {}
  }
)

const selectControls = createSelector(selectAnchors, anchors =>
  anchors.map(anchor => anchor.controls ?? [])
)
const selectCurrentControl = createSelector(
  selectSelectedGridData,
  selectControls,
  (selectedGridData, controls) => controls[selectedGridData?.anchorIndex ?? -1]
)
const selectGenerateData = createSelector(
  selectMixImageData,
  mixImageData => mixImageData.generateData
)
const selectIsGenerating = createSelector(
  selectGenerateData,
  generateData => generateData.isGenerating
)
const selectGenerateType = createSelector(
  selectGenerateData,
  generateData => generateData.generateType
)

const selectGenerateLoading = createSelector(
  selectIsGenerating,
  apiSelectors.loading['mixImage.generateMixImage'],
  (isGenerating, generateMixImageLoading) => Boolean(isGenerating || generateMixImageLoading)
)

export const mixImageSelectors = {
  mixImage: selectMixImage,
  mixImageId: selectMixImageId,
  mixImageConfig: createSelector(selectSource, source => {
    return {
      enableFaceEdit: source === 'standalone',
      hideColorTexture: source === 'train',
      hideStyle: source === 'train'
    }
  }),
  source: selectSource,
  mixImageData: selectMixImageData,
  isGenerating: selectIsGenerating,
  generateType: selectGenerateType,
  generateLoading: selectGenerateLoading,
  generateMixImageLoading: createSelector(
    selectGenerateLoading,
    selectGenerateType,
    (generateLoading, generateType) => generateLoading && generateType === 'mix'
  ),
  generateMixImagePreviewLoading: createSelector(
    selectGenerateLoading,
    selectGenerateType,
    (generateLoading, generateType) =>
      generateLoading &&
      (generateType === 'mix-preview' ||
        generateType === 'extra-attribute-preview' ||
        generateType === 'panel')
  ),
  generateCount: createSelector(selectGenerateData, generateData => generateData.generateCount),
  replaceCandidate: createSelector(selectMixImageData, data => data.replaceCandidate),
  hasReplaceCandidate: createSelector(
    selectMixImageData,
    data => data.replaceCandidate !== undefined
  ),
  isCanAddMoreMix: createSelector(
    selectMixImageData,
    data => data.mixImageData.anchors.length < MAX_ANCHOR
  ),
  bookmarkScope: selectBookmarkScope,
  shareRelated: selectShareRelated,
  selectedGrid: selectSelectedGrid,
  selectedSaved: selectSelectedSaved,
  selectedSavedData: selectSelectedSavedData,
  selectedReplaceGrid: selectSelectedReplaceGrid,
  anchors: selectAnchors,
  selectedGridData: selectSelectedGridData,
  selectedGridBookmarkData: selectSelectedGridBookmarkData,
  selectedGridShareData: selectSelectedGridShareData,
  selectedReplaceGridData: selectSelectedReplaceGridData,
  isAnchorSelected: createSelector(
    selectSelectedGridData,
    selectedGridData => selectedGridData?.isAnchor
  ),
  hasUndo: createSelector(
    selectMixImageData,
    data => data.mixImageDataPrevious.length && data.mixImageDataPrevious[0].anchors.length
  ),
  hasRedo: createSelector(selectMixImageData, data => data.mixImageDataNext.length),
  hasAnchors: createSelector(selectAnchors, anchor => anchor.length),
  gridImages: selectGridImages,
  gridImagesPage: selectGridImagesPage,
  hasOutputs: createSelector(selectOutputs, outputs => Boolean(outputs.length)),
  isAttributeChanged: createSelector(selectMixImageData, data => data.isAttributeChanged),
  isAnchorChanged: createSelector(selectMixImageData, data => data.isAnchorChanged),
  editedExtraAttributesValues: selectEditedExtraAttributesValues,
  editedExtraAttributesLabels: selectEditedExtraAttributesLabels,
  currentControl: selectCurrentControl,
  hasOnlyOneAnchor: createSelector(selectAnchors, anchors => anchors.length <= 1),
  anchorCount: createSelector(selectAnchors, anchors => anchors.length),
  expandExtraAttributePanel: createSelector(
    selectMixImageData,
    data => data.expandExtraAttributePanel
  ),
  isIlustrative: selectIsIlustrative,
  enableRegenerate: createSelector(selectMixImageData, data => data.enableRegenerate)
}

export type MixImageData = {
  shouldUseResample: boolean
  selectedGrid?: number
  anchors: AnchorType[]
  is_illustrative: boolean
  outputs: number[]
  edits: {
    [imageId: number]: EditType
  }
}
export type SavedStateType = { [id: number]: number | boolean } //Number is the id of saved image

export type MixImageProjectData = {
  generateData: {
    generateType?: 'mix-preview' | 'mix' | 'extra-attribute-preview' | 'panel'
    isGenerating: boolean
    generateCount?: number
    anchorIndex?: number
  }

  selectedSaved?: number
  savedLoaded: boolean
  expandExtraAttributePanel: boolean
  selectedReplaceGrid?: number
  replaceCandidate?: number
  isAnchorChanged: boolean
  isAttributeChanged: boolean
  enableRegenerate: boolean
  mixImageData: MixImageData
  generateEditPreviewHasQueue: boolean
  temporaryMixImageData?: MixImageData
  mixImageDataPrevious: MixImageData[]
  mixImageDataNext: MixImageData[]
}

// Reducer
export type MixImageState = {
  id?: number
  source?: 'standalone' | 'train'
  isNewProject?: boolean
  data: { [projectKey: string]: MixImageProjectData }
}

export const InfluenceConfig: {
  [key in keyof ControlInfluence]?: {
    SHOW_ON_ANCHOR_COUNT?: number
    MIN?: number
    MAX?: number
    STEP?: number
    DECIMAL?: number
  }
} = {
  style_weight: {
    MIN: 0,
    MAX: 2,
    STEP: 0.01,
    DECIMAL: 2
  },
  content_weight: {
    MIN: 0,
    MAX: 2,
    STEP: 0.01,
    DECIMAL: 2
  }
}

export const INITIAL_MIX_IMAGE_PROJECT_DATA: MixImageProjectData = {
  generateData: {
    isGenerating: false,
    generateCount: undefined,
    generateType: 'mix',
    anchorIndex: undefined
  },

  savedLoaded: false,
  expandExtraAttributePanel: false,
  selectedReplaceGrid: undefined,
  replaceCandidate: undefined,
  isAnchorChanged: false,
  isAttributeChanged: false,
  enableRegenerate: false,
  mixImageData: {
    shouldUseResample: false,
    selectedGrid: undefined,
    anchors: [],
    is_illustrative: false,
    outputs: [],
    edits: {}
  },
  generateEditPreviewHasQueue: false,
  temporaryMixImageData: undefined,
  mixImageDataPrevious: [],
  mixImageDataNext: []
}

export const INITIAL: MixImageState = {
  id: undefined,
  source: undefined,
  data: {}
}

const reducer = produce((state: MixImageState, { type, payload }) => {
  const id = state.id ?? 0

  switch (type) {
    case getType(mixImageActions.initMixImagePanel): {
      const param = payload as ActionType<typeof mixImageActions.initMixImagePanel>['payload']
      if (param) {
        const key = Utils.getKey(param.id, param.source)
        state.data[key] = state.data[key] ?? INITIAL_MIX_IMAGE_PROJECT_DATA
        state.id = param.id
        state.source = param.source
      }
      return
    }

    case getType(mixImageActions.setSelectedGrid): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].mixImageData.selectedGrid = payload
      return
    }

    case getType(mixImageActions.setSelectedReplaceGrid): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].selectedReplaceGrid = payload
      return
    }
    case getType(mixImageActions.setIsAttributeChanged): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].isAttributeChanged = payload
      return
    }
    case getType(mixImageActions.saveGenerateGridResult): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const outputs = payload as ActionType<
        typeof mixImageActions.saveGenerateGridResult
      >['payload']

      const outputsId = outputs?.map(output => output.id) ?? []
      state.data[key].mixImageData.outputs = outputsId

      state.data[key].isAnchorChanged = false
      return
    }
    case getType(mixImageActions.updateInfluence): {
      if (!id) return
      const dataKey = Utils.getKey(id, state.source)

      const { index, key, value } = payload as ActionType<
        typeof mixImageActions.updateInfluence
      >['payload']

      if (!state.data[dataKey].temporaryMixImageData) {
        state.data[dataKey].temporaryMixImageData = _cloneDeep(state.data[dataKey].mixImageData)
      }

      state.data[dataKey].mixImageData.anchors[index].controls = {
        ...(state.data[dataKey].mixImageData.anchors[index].controls ?? {}),
        [key]: value
      }
      state.data[dataKey].isAttributeChanged = true

      return
    }
    case getType(mixImageActions.setIsIlustrative): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      if (!state.data[key].temporaryMixImageData) {
        state.data[key].temporaryMixImageData = _cloneDeep(state.data[key].mixImageData)
      }

      state.data[key].mixImageData.is_illustrative = payload
      state.data[key].mixImageData.shouldUseResample = true
      state.data[key].isAttributeChanged = true
      return
    }
    case getType(mixImageActions.triggerUndo): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const temporaryMixImageData = state.data[key]?.temporaryMixImageData
      if (temporaryMixImageData) {
        state.data[key].mixImageDataPrevious = [
          _cloneDeep(temporaryMixImageData),
          ...state.data[key].mixImageDataPrevious
        ]
        state.data[key].mixImageDataNext = []
        state.data[key].temporaryMixImageData = undefined
      }

      return
    }
    case getType(mixImageActions.undo): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const [oldData, ...restData] = state.data[key].mixImageDataPrevious
      const currentValue = _cloneDeep(state.data[key].mixImageData)

      state.data[key].mixImageDataPrevious = restData
      state.data[key].mixImageData = oldData
      const anchorCount = oldData.anchors.length

      state.data[key].mixImageDataNext = [currentValue, ...state.data[key].mixImageDataNext]

      state.data[key].mixImageData.selectedGrid = ANCHOR_PATTERN[anchorCount][0] //Select first anchor
      state.data[key].isAnchorChanged = true

      return
    }
    case getType(mixImageActions.redo): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const currentValue = _cloneDeep(state.data[key].mixImageData)
      const [nextData, ...restData] = state.data[key].mixImageDataNext

      state.data[key].mixImageDataPrevious = [currentValue, ...state.data[key].mixImageDataPrevious]
      state.data[key].mixImageData = nextData
      state.data[key].mixImageDataNext = restData

      return
    }
    case getType(mixImageActions.addAnchorWithoutRegenerate):
    case getType(mixImageActions.addAnchor): {
      if (!id) return

      const enableUndo = type === getType(mixImageActions.addAnchor)

      const key = Utils.getKey(id, state.source)

      const anchorPayload = payload as ActionType<typeof mixImageActions.addAnchor>['payload']

      const isMax = state.data[key].mixImageData.anchors.length >= MAX_ANCHOR

      if (enableUndo) {
        state.data[key].mixImageDataPrevious = [
          _cloneDeep(state.data[key].mixImageData),
          ...state.data[key].mixImageDataPrevious
        ]
        state.data[key].mixImageDataNext = []
      }

      if (isMax) {
        state.data[key].mixImageData.anchors[MAX_ANCHOR - 1] = Utils.createAnchor(anchorPayload)
      } else {
        state.data[key].mixImageData.anchors = [
          ...state.data[key].mixImageData.anchors,
          Utils.createAnchor(anchorPayload)
        ]
      }

      const anchorCount = state.data[key].mixImageData.anchors.length

      state.data[key].mixImageData.selectedGrid = ANCHOR_PATTERN[anchorCount][anchorCount - 1] //Select last anchor
      state.data[key].isAnchorChanged = true

      if (anchorCount === 1) {
        state.data[key].mixImageData.shouldUseResample = true
      }

      return
    }
    case getType(mixImageActions.setReplaceCandidate): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].replaceCandidate = payload

      return
    }
    case getType(mixImageActions.createNewMix): {
      const createMixPayload = payload as ActionType<typeof mixImageActions.createNewMix>['payload']

      const { anchor, projectId } = createMixPayload
      const currentId = projectId ?? id
      const currentKey = Utils.getKey(currentId, state.source)

      if (currentId) {
        const mixImageData = state.data[currentKey]?.mixImageData
        if (mixImageData) {
          state.data[currentKey].mixImageDataPrevious = [
            _cloneDeep(mixImageData),
            ...state.data[currentKey].mixImageDataPrevious
          ]
          state.data[currentKey].mixImageDataNext = []
        } else {
          state.data[currentKey] = _cloneDeep(INITIAL_MIX_IMAGE_PROJECT_DATA)
        }

        state.data[currentKey].mixImageData.anchors = [Utils.createAnchor(anchor)]

        const anchorCount = state.data[currentKey].mixImageData.anchors.length

        state.data[currentKey].mixImageData.selectedGrid =
          ANCHOR_PATTERN[anchorCount][anchorCount - 1]
        state.data[currentKey].isAnchorChanged = true
        state.data[currentKey].mixImageData.outputs = []

        state.data[currentKey].mixImageData.shouldUseResample = true
      }

      return
    }
    case getType(mixImageActions.removeAnchor): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const { mixImageData } = state.data[key]
      const { anchors } = mixImageData
      if (payload >= 0 && payload < MAX_ANCHOR) {
        const deletedAnchor = payload
        const originalAnchorCount = anchors.length

        state.data[key].mixImageDataPrevious = [
          _cloneDeep(state.data[key].mixImageData),
          ...state.data[key].mixImageDataPrevious
        ]
        state.data[key].mixImageDataNext = []

        anchors.splice(payload, 1)

        /*
         * Shuffle Anchor
         *  Reference
         *  '0_1_2_3-0_1_3': [1, 2, 4], //no change
         *  '0_1_2_3-0_2_3': [0, 3, 2],
         *  '0_1_2_3-2_3_4': [3, 1, 2],
         *  '0_1_2-0_2': [0, 2], //no change
         *  '0_1_2-1_2': [2, 1]
         */
        let anchorsAfterDeleted = [...anchors]

        if (deletedAnchor === 1 && originalAnchorCount === 4) {
          anchorsAfterDeleted = [
            anchorsAfterDeleted[0],
            anchorsAfterDeleted[2],
            anchorsAfterDeleted[1]
          ]
        }
        if (deletedAnchor === 0 && originalAnchorCount === 4) {
          anchorsAfterDeleted = [
            anchorsAfterDeleted[2],
            anchorsAfterDeleted[0],
            anchorsAfterDeleted[1]
          ]
        }
        if (deletedAnchor === 0 && originalAnchorCount === 3) {
          anchorsAfterDeleted = [anchorsAfterDeleted[1], anchorsAfterDeleted[0]]
        }
        state.data[key].mixImageData.anchors = anchorsAfterDeleted

        const anchorCount = state.data[key].mixImageData.anchors.length
        state.data[key].mixImageData.selectedGrid = ANCHOR_PATTERN[anchorCount][anchorCount - 1]
        state.data[key].isAnchorChanged = true

        if (anchorCount === 1) {
          state.data[key].mixImageData.shouldUseResample = true
        }
      }

      return
    }
    case getType(mixImageActions.replaceAnchor): {
      if (!id) return
      const key = Utils.getKey(id, state.source)
      const replaceAnchorPayload = payload as ActionType<
        typeof mixImageActions.replaceAnchor
      >['payload']

      const [order, replaceWith] = replaceAnchorPayload
      const { mixImageData } = state.data[key]
      const { anchors } = mixImageData

      state.data[key].mixImageDataPrevious = [
        _cloneDeep(mixImageData),
        ...state.data[key].mixImageDataPrevious
      ]
      state.data[key].mixImageDataNext = []

      anchors.splice(order, 1, Utils.createAnchor(replaceWith))
      state.data[key].mixImageData.anchors = [...anchors]
      state.data[key].isAnchorChanged = true

      if (anchors.length === 1) {
        state.data[key].mixImageData.shouldUseResample = true
      }

      return
    }
    case getType(mixImageActions.swapAnchor): {
      if (!id) return
      const key = Utils.getKey(id, state.source)
      const swapAnchorPayload = payload as ActionType<typeof mixImageActions.swapAnchor>['payload']

      const [swap1, swap2] = swapAnchorPayload
      state.data[key].mixImageDataPrevious = [
        _cloneDeep(state.data[key].mixImageData),
        ...state.data[key].mixImageDataPrevious
      ]
      state.data[key].mixImageDataNext = []

      const { mixImageData } = state.data[key]
      const { anchors } = mixImageData

      const anchor1Value = _cloneDeep(anchors[swap1])
      const anchor2Value = _cloneDeep(anchors[swap2])
      anchors[swap2] = anchor1Value
      anchors[swap1] = anchor2Value

      state.data[key].mixImageData.anchors = [...anchors]
      state.data[key].isAnchorChanged = true

      return
    }
    case getType(mixImageActions.setSelectedSaved): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].selectedSaved = payload
      return
    }
    case getType(mixImageActions.generateGrid): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].mixImageData.shouldUseResample = false
      return
    }
    case getType(mixImageActions.storeUndo): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const { mixImageData } = state.data[key]

      state.data[key].mixImageDataPrevious = [
        _cloneDeep(mixImageData),
        ...state.data[key].mixImageDataPrevious
      ]
      return
    }
    case getType(mixImageActions.generateResampleGrid): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].mixImageData.shouldUseResample = false
      return
    }

    case getType(mixImageActions.updateEdit): {
      if (!id) return
      const key = Utils.getKey(id, state.source)
      const typedPayload = payload as ActionType<typeof mixImageActions.updateEdit>['payload']

      const { isReplace, imageId, extra_attributes, labels, previewId, isAnchor } = typedPayload

      if (!state.data[key].temporaryMixImageData) {
        state.data[key].temporaryMixImageData = _cloneDeep(state.data[key].mixImageData)
      }

      const currentData = state.data[key].mixImageData.edits[imageId] ?? {}
      const currentExtraAttributes = currentData?.extra_attributes ?? {}
      const currentExtraAttributeLabels = currentData?.labels ?? {}

      state.data[key].mixImageData.edits[imageId] = {
        ...currentData,
        previewId: previewId ?? currentData.previewId,
        extra_attributes: isReplace
          ? extra_attributes
          : { ...currentExtraAttributes, ...extra_attributes },
        labels: isReplace ? labels : { ...currentExtraAttributeLabels, ...labels }
      }
      if (isAnchor) {
        state.data[key].enableRegenerate = true
      }

      return
    }
    case getType(mixImageActions.setEditPreview): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const typedPayload = payload as ActionType<typeof mixImageActions.setEditPreview>['payload']

      const { previewImageId, imageId } = typedPayload

      const currentData = state.data[key].mixImageData.edits[imageId] ?? {}

      state.data[key].mixImageData.edits[imageId] = {
        ...currentData,
        previewId: previewImageId
      }

      return
    }
    case getType(mixImageActions.setExpandExtraAttributePanel): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].expandExtraAttributePanel = payload
      return
    }
    case getType(mixImageActions.setGenerateEditPreviewHasQueue): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      state.data[key].generateEditPreviewHasQueue = payload
      return
    }
    case getType(mixImageActions.setGenerating): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const generating = payload as ActionType<typeof mixImageActions.setGenerating>['payload']
      const generateInterval = Utils.getGenerateInterval(state.data[key].generateData.generateType)

      state.data[key].generateData.isGenerating = generating
      state.data[key].generateData.generateCount =
        (state.data[key].generateData.generateCount ?? 0) + generateInterval / 1000

      if (!generating) {
        state.data[key].generateData.generateCount = undefined
        state.data[key].generateData.generateType = undefined
      }
      return
    }
    case getType(mixImageActions.setGenerateType): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const { generateType, anchorIndex } = payload as ActionType<
        typeof mixImageActions.setGenerateType
      >['payload']

      state.data[key].generateData.generateType = generateType
      state.data[key].generateData.anchorIndex = anchorIndex
      return
    }
    case getType(mixImageActions.setEnableRegenerate): {
      if (!id) return
      const key = Utils.getKey(id, state.source)

      const enableRegenerate = payload as ActionType<
        typeof mixImageActions.setEnableRegenerate
      >['payload']
      state.data[key].enableRegenerate = enableRegenerate

      return
    }
    default:
  }
}, INITIAL)

// Epics

/* 
  Execute on mount 
  - Fetch genre list
  - Initiate Start panel
*/

const initMixImagePanelPageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(mixImageActions.initMixImagePanel)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      id: action.payload.id,
      isNewProject: action.payload.isNewProject,
      source: action.payload.source,
      genreList: apiSelectors.mixImageGenres(state),
      hasAnchors: mixImageSelectors.hasAnchors(state),
      anchors: mixImageSelectors.anchors(state)
    })),
    filter(({ source }) => source === 'standalone'),
    mergeMap(({ id, genreList, isNewProject }) =>
      merge(
        of(id).pipe(
          filter(() => !Boolean(genreList.length)),
          mergeMap(id =>
            action$.pipe(
              filter(isActionOf(apiActions.mixImage.listMixImageGenreResponse)),
              take(1),
              filter(() => Boolean(isNewProject)),
              map(() => mixImageActions.createNewProject()),
              startWith(apiActions.mixImage.listMixImageGenre({ offset: 0, limit: 30 }))
            )
          )
        ),
        of(id).pipe(
          filter(() => Boolean(genreList.length) && Boolean(isNewProject)),
          map(() => mixImageActions.createNewProject())
        ),
        of(id).pipe(
          filter(id => Boolean(id)),
          mergeMap(id =>
            action$.pipe(
              filter(isActionOf(apiActions.mixImage.retrieveMixImageResponse)),
              take(1),
              withLatestFrom(state$),
              map(([action, state]) => ({
                hasAnchor: mixImageSelectors.hasAnchors(state),
                payload: action.payload
              })),
              map(({ payload, hasAnchor }) => ({
                genre: payload.genre,
                outputs: payload.outputs,
                anchors:
                  payload?.outputs?.length && !hasAnchor
                    ? _filter(
                        ANCHOR_PATTERN[1].map(
                          value =>
                            ({
                              id: payload.outputs?.[value]?.id,
                              controls: INITIAL_CONTROL
                            }) as AnchorType
                        ),
                        value => Boolean(value.id)
                      )
                    : []
              })),
              concatMap(({ genre, outputs, anchors }) =>
                _compact([
                  actions.setSelectedGenre(genre),
                  mixImageActions.saveGenerateGridResult(outputs ?? []),
                  ...anchors.map(anchor => mixImageActions.addAnchorWithoutRegenerate(anchor))
                ])
              ),
              startWith(apiActions.mixImage.retrieveMixImage(id ?? 0))
            )
          )
        )
      )
    )
  )

const createNewProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixImageActions.createNewProject)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const name = Utils.getName()
      const mixImageGenreList = apiSelectors.mixImageGenres(state)
      const selectedGenre = mixImageGenreList?.[0]?.id

      return { name, selectedGenre }
    }),
    filter(({ name, selectedGenre }) => Boolean(name && selectedGenre)),
    mergeMap(({ selectedGenre, name }) =>
      action$.pipe(
        filter(isActionOf(apiActions.mixImage.createMixImageResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({
          data: action.payload,
          mixGenreData: apiSelectors.mixImageGenreData(state)
        })),
        tap(({ data, mixGenreData }) => {
          MixPanelUtils.track<'PROJECT__CREATE'>(
            'Project - Create',
            DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
              mixProject: data,
              mixGenreData
            })
          )
          FacebookPixelUtils.track<'CREATE_FACEMIX'>('create_facemix')
        }),
        map(({ data }) => data.id),
        concatMap(id => [
          actions.setSelectedGenre(selectedGenre),
          push(route.MIX_PROJECTS.getUrl({ id }))
        ]),
        startWith(apiActions.mixImage.createMixImage({ name, genre: selectedGenre }))
      )
    )
  )

const pushToMixTabEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixImageActions.pushToMixTab)),
    withLatestFrom(state$),
    map(([_, state]) => {
      return {
        source: mixImageSelectors.source(state),
        id: mixImageSelectors.mixImageId(state)
      }
    }),
    filter(({ source }) => source === 'standalone'),
    map(({ id }) => appActions.pushTo(route.MIX_PROJECTS.getUrl({ id, subRoute: 'mix' })))
  )

/* 
  If only have one genre, then select first genre and show start panel  
*/
const resetReplaceGridEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(mixImageActions.resetReplaceGrid)),
    mergeMap(() => [
      mixImageActions.setReplaceCandidate(undefined),
      mixImageActions.setSelectedReplaceGrid(undefined)
    ])
  )

const startOverEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(mixImageActions.startOver)),
    map(() => panelsActions.bottomSheet.setShowBottomSheet('start-over'))
  )

const generateGridEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([mixImageActions.generateGrid, mixImageActions.generateResampleGrid])),
    withLatestFrom(state$),
    map(([action, state]) => {
      const isGenerating = mixImageSelectors.isGenerating(state)
      const source = mixImageSelectors.source(state)
      const mixImageProjectId = mixImageSelectors.mixImageId(state) ?? 0
      const anchors = mixImageSelectors.anchors(state)
      const isIlustrative = mixImageSelectors.isIlustrative(state)
      const anchorAdjusted = anchors.map(anchor => {
        return applyExtraAttributeConfig(anchor)
      })

      const isResample = action.type === getType(mixImageActions.generateResampleGrid)

      return {
        isGenerating,
        source,
        data: {
          anchors: anchorAdjusted,
          id: mixImageProjectId,
          isIlustrative,
          isResample
        }
      }
    }),
    filter(({ isGenerating }) => !isGenerating),
    map(({ source, data }) => {
      const { anchors, id, isIlustrative, isResample } = data
      let adjustedAnchor: MixImageGenerateReq['anchors'] = anchors

      // Substract value for 1 anchor, to have reverse effect
      if (anchors.length === 1) {
        adjustedAnchor = anchors.map(anchor => {
          const oldControl = anchor.controls
          const newControl = {
            ...oldControl,
            style_weight:
              (InfluenceConfig.style_weight?.MAX ?? 2) - (oldControl?.style_weight ?? 0),
            content_weight:
              (InfluenceConfig.content_weight?.MAX ?? 2) - (oldControl?.content_weight ?? 0)
          }
          return {
            ...anchor,
            controls: newControl
          }
        })

        const resampleParam = isResample ? { is_resample: true } : {}

        return {
          source,
          data: {
            id,
            anchors: adjustedAnchor,
            is_illustrative: isIlustrative,
            ...resampleParam
          }
        }
      }

      return {
        source,
        data: {
          id,
          anchors: adjustedAnchor,
          is_illustrative: undefined,
          is_resample: undefined
        }
      }
    }),
    filter(({ data }) => Boolean(data.id)),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ source }) => source === 'train'),
          map(({ data }) => projectMixActions.generateMixProject(data))
        ),
        of(param).pipe(
          filter(({ source }) => source === 'standalone'),
          mergeMap(({ data }) =>
            action$.pipe(
              filter(isActionOf([apiActions.mixImage.generateMixImageResponse])),
              take(1),
              withLatestFrom(state$),
              tap(([, state]) => {
                const mixProject = apiSelectors.currentMixImageProject(state)
                const mixGenreData = apiSelectors.mixImageGenreData(state)

                MixPanelUtils.track<'PROJECT__MIX_GENERATE'>('Project Mix - Generate Mix', {
                  ...DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
                    mixProject,
                    mixGenreData
                  }),
                  anchor_count: data.anchors.length,
                  is_illustrative: data.is_illustrative,
                  is_resample: data.is_resample
                })
              }),
              mergeMap(([action]) => [
                mixImageActions.setEnableRegenerate(false),
                mixImageActions.setIsAttributeChanged(false),
                mixImageActions.setGenerateType({ generateType: 'mix' })
              ]),
              startWith(apiActions.mixImage.generateMixImage(data))
            )
          )
        )
      )
    )
  )

const onAnchorChangeEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        mixImageActions.createNewMix,
        mixImageActions.removeAnchor,
        mixImageActions.addAnchor,
        mixImageActions.swapAnchor,
        mixImageActions.replaceAnchor,
        mixImageActions.setIsIlustrative
      ])
    ),
    withLatestFrom(state$),
    map(([_, state]) => ({
      shouldUseResample: mixImageSelectors.mixImageData(state).mixImageData.shouldUseResample
    })),
    map(({ shouldUseResample }) =>
      shouldUseResample ? mixImageActions.generateResampleGrid() : mixImageActions.generateGrid()
    )
  )

const downloadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixImageActions.download)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      mixImageProjectId: mixImageSelectors.mixImageId(state),
      image: action.payload.image,
      downloadLocation: action.payload.downloadLocation,
      pageTab: selectors.pageTab(state),
      source: mixImageSelectors.source(state),
      showBottomSheet: panelsSelectors.showBottomSheet(state),
      activeTab: panelsSelectors.activeTab(state)
    })),
    filter(({ image }) => Boolean(image)),
    map(
      ({
        mixImageProjectId,
        image,
        pageTab,
        activeTab,
        showBottomSheet,
        source,
        downloadLocation
      }) => {
        const projectIdentity = mixImageProjectId ?? 'project'

        const fileName = `mix-image-${projectIdentity}-${image?.id}`
        const isFreePanel = activeTab && PanelWithoutPaidDownload.includes(activeTab)
        const isFreePage = MixPageWithoutPaidDownload.includes(pageTab)
        const isFreePanelOrPage = showBottomSheet ? isFreePanel : isFreePage
        const isFree = source === 'train' || isFreePanelOrPage

        return { image, fileName, isFree, downloadLocation }
      }
    ),
    mergeMap(({ image, fileName, isFree, downloadLocation }) =>
      _compact([
        image && !isFree
          ? downloaderActions.single.downloadPaidUserImage({
              downloadLocation,
              userImage: image,
              fileName
            })
          : undefined,
        image && isFree
          ? downloaderActions.single.downloadSingleImage({ downloadLocation, image, fileName })
          : undefined
      ])
    )
  )

const setSelectedGridEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(mixImageActions.setSelectedGrid)),
    withLatestFrom(state$),
    map(([_, state]) => selectors.sidebarTab(state)),
    filter(sidebarTab => sidebarTab !== 'preview'),
    map(() => actions.setSidebarTab('preview'))
  )

const updateFormEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        mixImageActions.updateInfluence,
        mixImageActions.setIsIlustrative,
        mixImageActions.updateEdit
      ])
    ),
    debounceTime(800),
    map(() => mixImageActions.triggerUndo())
  )
const updateEditEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(mixImageActions.updateEdit)),
    filter(({ payload }) => !payload.previewId),
    debounceTime(600),
    map(() => mixImageActions.generateEditPreview())
  )

const generateEditPreviewEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(mixImageActions.generateEditPreview)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const selectedGridData = mixImageSelectors.selectedGridData(state)
      const project = mixImageSelectors.mixImageId(state) ?? 0

      const generateMixImageLoading = mixImageSelectors.generateMixImageLoading(state)
      const generateMixImagePreviewLoading = mixImageSelectors.generateMixImagePreviewLoading(state)
      return {
        generateMixImageLoading,
        generateMixImagePreviewLoading,
        project,
        anchor: {
          id: selectedGridData?.imageId ?? 0,
          extra_attributes: selectedGridData?.edit?.extra_attributes ?? {},
          controls: INITIAL_ANCHOR.controls
        }
      }
    }),
    filter(({ generateMixImageLoading }) => !generateMixImageLoading),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ generateMixImagePreviewLoading }) => Boolean(generateMixImagePreviewLoading)),
          map(() => mixImageActions.setGenerateEditPreviewHasQueue(true))
        ),
        of(param).pipe(
          filter(({ generateMixImagePreviewLoading }) => !Boolean(generateMixImagePreviewLoading)),
          mergeMap(param =>
            action$.pipe(
              filter(isActionOf(apiActions.mixImage.generateMixImagePreviewResponse)),
              take(1),
              withLatestFrom(state$),
              map(([_, state]) => {
                return mixImageSelectors.mixImageData(state).generateEditPreviewHasQueue
              }),
              mergeMap(hasQueue =>
                _compact([
                  mixImageActions.setGenerateType({ generateType: 'mix-preview' }),
                  mixImageActions.setGenerateEditPreviewHasQueue(false),
                  hasQueue && mixImageActions.generateEditPreview()
                ])
              ),
              startWith(
                apiActions.mixImage.generateMixImagePreview({
                  id: param.project,
                  anchor: applyExtraAttributeConfig(param?.anchor ?? INITIAL_ANCHOR)
                })
              )
            )
          )
        )
      )
    )
  )

/* 

- Listen when upload list received
  - When failed, the status will become UNKNOWN
  - So when generate pooling is already running in sometimes, 
    then status become UNKNOWN, then stop the pooling

  TODO : change isGenerating to track whether using preview or generate. 
*/

const listenOnRetrieveMixImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.mixImage.retrieveMixImageResponse,
        apiActions.mixImage.generateMixImageResponse,
        apiActions.mixImage.generateMixImagePreviewResponse
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isCurrentUserFree: apiSelectors.isCurrentUserFree(state),
      isGenerating: mixImageSelectors.isGenerating(state),
      generateType: mixImageSelectors.generateType(state),
      generateCount: mixImageSelectors.generateCount(state),
      currentMixImageProject: apiSelectors.currentMixImageProject(state),
      id: action.payload.id,
      selectedGridData: mixImageSelectors.selectedGridData(state),
      extraAttributePreviewHasQueue: extraAttributeSelectors.extraAttribute(state).previewHasQueue,
      generateEditPreviewHasQueue:
        mixImageSelectors.mixImageData(state).generateEditPreviewHasQueue,
      panelHasQueue: panelsSelectors.generateResultHasQueue(state),
      type: action.type,
      selectedPanelImage: panelsSelectors.selectedItem(state)?.imageId
    })),
    filter(
      ({ isGenerating, type }) =>
        !(!isGenerating && type === getType(apiActions.mixImage.retrieveMixImageResponse))
    ),
    map(({ isGenerating, generateCount = 0, currentMixImageProject, ...restProps }) => {
      const currentMixImageProjectId = currentMixImageProject?.id ?? 0
      const status = currentMixImageProject?.status
      const expect_finished = currentMixImageProject?.expect_finished ?? 0
      const queue_length = currentMixImageProject?.queue_length ?? 0

      const hasQueue =
        expect_finished && generateCount < expect_finished + ADDITIONAL_THRESHOLD_GENERATE

      const isGeneratingButFinished = isGenerating && status === 'FINISHED'
      const shouldContinueGenerateUnknownStatus = hasQueue && status === 'UNKNOWN'
      const isProcessing = status === 'PROCESSING'
      const shouldShowErrorDialog =
        isGenerating &&
        !shouldContinueGenerateUnknownStatus &&
        !isProcessing &&
        !isGeneratingButFinished

      return {
        errorExtra: {
          elapsed_pooling: generateCount,
          expect_finished,
          queue_length
        },
        currentMixImageProjectId,
        isGeneratingButFinished,
        shouldContinueGenerateUnknownStatus,
        isProcessing,
        shouldShowErrorDialog,
        currentMixImageProject,
        ...restProps
      }
    }),
    tap(({ shouldShowErrorDialog, errorExtra }) => {
      if (shouldShowErrorDialog) {
        SentryUtils.captureMessage(
          `Unable To Generate Mix Image in expected time`,
          errorExtra,
          'error'
        )
      }
    }),
    filter(({ currentMixImageProjectId, id }) => currentMixImageProjectId === id),
    mergeMap(param =>
      merge(
        of(param).pipe(
          mergeMap(
            ({
              shouldShowErrorDialog,
              shouldContinueGenerateUnknownStatus,
              isProcessing,
              generateType
            }) =>
              _compact([
                mixImageActions.setGenerating(shouldContinueGenerateUnknownStatus || isProcessing),
                shouldShowErrorDialog && generateType === 'mix'
                  ? mixImageActions.setEnableRegenerate(true)
                  : undefined,
                shouldShowErrorDialog
                  ? dialogActions.openDialog(Utils.getGenerateErrorDialogText(generateType))
                  : null
              ])
          )
        ),
        of(param).pipe(
          filter(({ generateType }) => generateType === 'mix'),
          mergeMap(
            ({
              currentMixImageProjectId,
              isGeneratingButFinished,
              isCurrentUserFree,
              currentMixImageProject
            }) =>
              _compact([
                isGeneratingButFinished
                  ? mixImageActions.saveGenerateGridResult(currentMixImageProject.outputs ?? [])
                  : null,
                isGeneratingButFinished
                  ? apiActions.mixImage.resetMixImageQueueData(currentMixImageProjectId)
                  : null,
                isGeneratingButFinished && isCurrentUserFree
                  ? apiActions.users.retrieveEquity()
                  : null
              ])
          )
        ),
        of(param).pipe(
          filter(({ generateType }) => generateType === 'mix-preview'),
          mergeMap(
            ({
              isGeneratingButFinished,
              isCurrentUserFree,
              currentMixImageProject,
              generateEditPreviewHasQueue,
              selectedGridData
            }) =>
              _compact([
                isGeneratingButFinished
                  ? mixImageActions.setEditPreview({
                      previewImageId: currentMixImageProject.preview?.id ?? 0,
                      imageId: selectedGridData?.imageId ?? 0
                    })
                  : null,
                isGeneratingButFinished && selectedGridData?.isAnchor
                  ? mixImageActions.setEnableRegenerate(true)
                  : null,
                isGeneratingButFinished
                  ? mixImageActions.setGenerateEditPreviewHasQueue(false)
                  : null,
                isGeneratingButFinished && generateEditPreviewHasQueue
                  ? mixImageActions.generateEditPreview()
                  : null,
                isGeneratingButFinished && isCurrentUserFree
                  ? apiActions.users.retrieveEquity()
                  : null
              ])
          )
        ),
        of(param).pipe(
          filter(({ generateType }) => generateType === 'extra-attribute-preview'),
          mergeMap(
            ({ extraAttributePreviewHasQueue, isGeneratingButFinished, currentMixImageProject }) =>
              _compact([
                isGeneratingButFinished &&
                  extraAttributeActions.setPreview(currentMixImageProject?.preview?.id),
                isGeneratingButFinished && extraAttributeActions.setPreviewHasQueue(false),
                isGeneratingButFinished &&
                  extraAttributePreviewHasQueue &&
                  extraAttributeActions.refreshPreview()
              ])
          )
        ),
        of(param).pipe(
          filter(({ generateType }) => generateType === 'panel'),
          mergeMap(
            ({
              panelHasQueue,
              isGeneratingButFinished,
              currentMixImageProject,
              selectedPanelImage
            }) =>
              _compact([
                isGeneratingButFinished &&
                  selectedPanelImage &&
                  currentMixImageProject.preview &&
                  panelsActions.updateResult({
                    imageId: selectedPanelImage,
                    result: currentMixImageProject.preview
                  }),
                isGeneratingButFinished && panelsActions.setGenerateResultHasQueue(false),
                isGeneratingButFinished && panelHasQueue && panelsActions.generateResult()
              ])
          )
        )
      )
    )
  )

const listenRetrieveMixImageErrorEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(({ payload }) => payload.type === getType(apiActions.mixImage.retrieveMixImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      error: action.payload,
      isGenerating: mixImageSelectors.isGenerating(state)
    })),
    mergeMap(({ isGenerating, error }) =>
      _compact([
        mixImageActions.setGenerating(false),
        isGenerating &&
          dialogActions.addDialog({
            [ErrorDialog.ERROR]: {
              dialogName: ErrorDialog.ERROR,
              title: `Unable to retrieve mix image data`,
              content: [
                errorUtils.flattenMessage(error).toString(),
                'Generating process is still working in the background, the result will be available when it finished. Try to refresh this page to see the finished results'
              ]
            }
          })
      ])
    )
  )

export const mixImageEpics = combineEpics(
  listenOnRetrieveMixImageEpic,
  listenRetrieveMixImageErrorEpic,
  pushToMixTabEpic,
  updateFormEpic,
  onAnchorChangeEpic,
  resetReplaceGridEpic,
  updateEditEpic,
  downloadEpic,
  startOverEpic,
  generateGridEpic,
  createNewProjectEpic,
  setSelectedGridEpic,
  initMixImagePanelPageEpic,
  generateEditPreviewEpic
)

export default reducer
