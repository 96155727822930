import { AdjustedImage, AdjustedImageControl } from 'models/ApiModels'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import Brightness5Icon from '@mui/icons-material/Brightness5'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import InvertColorsIcon from '@mui/icons-material/InvertColors'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import _values from 'lodash/values'
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff'
import CameraIcon from '@mui/icons-material/Camera'
import GrainIcon from '@mui/icons-material/Grain'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import TonalityIcon from '@mui/icons-material/Tonality'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'

export type ControlType = AdjustedImageControl

export type ControlRangetType = {
  [key in keyof AdjustedImage['controls']]?: [
    min: number,
    max: number,
    step: number,
    multiplier?: number
  ]
}

export type ToolDataType = {
  [key in keyof AdjustedImage['controls']]?: {
    label: string
    Icon?: any
    IconStart?: any
    IconEnd?: any
  }
}

export const CONTROL_MOBILE_LIST: ControlType[] = [
  'contrast',
  'brightness',
  'saturation',
  'exposure',
  'temperature',
  'tint',
  'smooth'
]

export const CONTROL_DESKTOP_LIST: ControlType[] = [
  'contrast',
  'brightness',
  'saturation',
  'exposure',
  'temperature',
  'tint'
]

export const IMAGE_CONTROL_RANGE: ControlRangetType = {
  contrast: [0, 2, 0.01],
  brightness: [-100, 100, 1],
  saturation: [0, 2, 0.01],
  exposure: [0, 2, 0.01],
  temperature: [-1, 1, 0.01, -1],
  tint: [-0.5, 0.5, 0.01],
  smooth: [0, 10, 1]
}

export const IMAGE_CONTROL_DEFAULT: AdjustedImage['controls'] = {
  auto_contrast: false,
  contrast: 1,
  brightness: 0,
  saturation: 1,
  exposure: 1,
  temperature: 0,
  tint: 0,
  smooth: 0,
  deblocking: false
}

export const IMAGE_CONTROL_DEFAULT_ARRAY = _values(IMAGE_CONTROL_DEFAULT)

export const TOOLS_DATA: ToolDataType = {
  contrast: {
    label: 'Contrast',
    Icon: TonalityIcon,
    IconStart: Brightness1Icon,
    IconEnd: TonalityIcon
  },
  brightness: {
    label: 'Brightness',
    Icon: Brightness7Icon,
    IconStart: Brightness5Icon,
    IconEnd: Brightness7Icon
  },
  saturation: {
    label: 'Saturation',
    Icon: InvertColorsIcon,
    IconStart: InvertColorsOffIcon,
    IconEnd: InvertColorsIcon
  },
  exposure: {
    label: 'Exposure',
    Icon: CameraIcon,
    IconStart: CameraIcon,
    IconEnd: RadioButtonUncheckedIcon
  },
  temperature: {
    label: 'Temperature',
    Icon: AcUnitIcon,
    IconStart: AcUnitIcon,
    IconEnd: WbSunnyIcon
  },
  tint: {
    label: 'Tint',
    Icon: FormatColorFillIcon,
    IconStart: FormatColorFillIcon,
    IconEnd: FormatColorFillIcon
  },
  smooth: {
    label: 'Denoise',
    Icon: GrainIcon
  }
}
