import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import Img from 'components/Img'
import ImgEmptyThumbnail from 'components/Img/ImgEmptyThumbnail'
import { CircularLoading } from 'components/Loading'
import DropdownMenu from 'components/DropdownMenu'
import { Utils } from '../duck'
import { UpscaleImage } from 'models/ApiModels'
import { UPSCALE_LIST_CONFIG } from 'containers/UpscalePanel/Models'
import { UpscaleListProps } from './UpscaleList'
import styles from '../UpscalePanel.module.scss'

type UpscaleCardProps = {
  item: UpscaleImage
  style?: CSSProperties
  deleteUpscaleImage: (id: number) => void
} & Pick<UpscaleListProps, 'onClickItem'>

const UpscaleCard: React.FC<UpscaleCardProps> = props => {
  const { deleteUpscaleImage, onClickItem, item, style } = props

  const id = item.id
  const isLoading = !Boolean(item.output)

  const methodText = UPSCALE_LIST_CONFIG[item.method].title
  const resolutionText = Utils.getResolutionText(item)
  return (
    <div
      className={clsx('relative h-full select-none bg-dialog', styles.UpscaleCard)}
      style={style}
    >
      <ButtonBase
        className="h-full w-full bg-lighter"
        onClick={() => {
          onClickItem(id)
        }}
      >
        {item.image ? (
          <Img image={item.image} objectFit="cover" fullWidth fullHeight />
        ) : (
          <ImgEmptyThumbnail />
        )}
      </ButtonBase>

      <Typography
        variant="overline"
        component="div"
        className="pointer-events-none absolute left-2 top-2 z-2 flex bg-primary px-3 py-[0.3125rem] leading-none text-white-med-on-dark"
      >
        {methodText}
      </Typography>

      <div className="absolute inset-x-0 bottom-0 z-2 bg-dialog p-2">
        <div className="flex">
          <div className="w-px min-w-0 flex-auto cursor-pointer" onClick={() => onClickItem(id)}>
            <Typography className="mb-2" variant="subtitle1" component="div">
              {Utils.getUpscaleTitle(item)}
            </Typography>

            <Typography
              className="normal-case text-white-med-on-dark"
              variant="overline"
              component="div"
            >
              {`${resolutionText}`}
            </Typography>
          </div>

          {!isLoading ? (
            <div className="flex items-center">
              <DropdownMenu
                CustomButtonComponent={({ buttonRef, ...restProps }) => (
                  <IconButton {...restProps} ref={buttonRef} size="small" disableRipple>
                    <MoreHorizIcon />
                  </IconButton>
                )}
                className={styles.UpscaleCardMenuMore}
                items={[
                  {
                    key: 'delete',
                    label: 'Delete',
                    onClick: () => deleteUpscaleImage(id)
                  }
                ]}
                placement="bottom-end"
              />
            </div>
          ) : null}
        </div>
      </div>

      {isLoading ? (
        <div
          onClick={() => onClickItem(id)}
          className={clsx(
            'absolute inset-0 z-1 flex cursor-pointer items-center justify-center pb-16',
            styles.UpscaleCardLoading
          )}
        >
          <CircularLoading size={32} loading disableShrink />
        </div>
      ) : null}
    </div>
  )
}

export default UpscaleCard
