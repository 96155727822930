import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import { useExecuteOnChange } from 'utils/Hooks'
import { usePageViewTracker } from './AppPageHooks'
import { dialogActions, MonetizationDialog } from 'duck/AppDuck/DialogDuck'

const AppPageLocationAddons = () => {
  const dispatch = useDispatch()
  const location = useSelector((state: RootState) => appSelectors.routerLocation(state))
  const hasUpscaleDialog = useSelector((state: RootState) =>
    appSelectors.dialog.hasUpscaleDialog(state)
  )
  const activeDialog = useSelector((state: RootState) => appSelectors.dialog.activeDialog(state))
  const activeDialogOverlay = useSelector((state: RootState) =>
    appSelectors.dialog.activeDialogOverlay(state)
  )
  const hasSubscriptionDialog =
    activeDialog === MonetizationDialog.SUBSCRIPTION_PANEL ||
    activeDialogOverlay === MonetizationDialog.SUBSCRIPTION_PANEL

  usePageViewTracker({ location })
  // useReferralInit(location)

  /* Close Upscale Panel when page changes */
  const pathname = location?.pathname ?? ''
  const closeDialogIfRouteChange = useCallback(() => {
    if (hasUpscaleDialog || hasSubscriptionDialog) dispatch(dialogActions.closeDialog())
  }, [hasUpscaleDialog, hasSubscriptionDialog, dispatch])

  useExecuteOnChange({
    executor: closeDialogIfRouteChange,
    executeOn: pathname
  })
  /* END */

  return null
}

export default AppPageLocationAddons
