import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const ClappedIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon className={clsx(className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="m7.5 15.221-1.5 1.5 3.5 3.5 1.72-1.721s2.5 0 3.5-1l4.78-4.78c.167-.166.4-.6 0-1s-.833-.166-1 0l-3 3s-.22.28-.5 0c-.28-.279 0-.5 0-.5l4.5-4.5c.167-.166.4-.6 0-1s-.833-.166-1 0l-4.5 4.5s-.22.28-.5 0c-.28-.279 0-.5 0-.5l5-5c.167-.166.4-.6 0-1s-.833-.166-1 0l-5 5s-.22.28-.5 0c-.22-.22 0-.499 0-.499l4-4c.167-.167.4-.6 0-1s-.803-.167-.955 0L9.5 11.72c.5-1 2-3.721 2-3.721s.28-.5 0-.78c-.5-.5-1 .28-1 .28s-2.5 4-3 4.721c-.5.72 0 3 0 3Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.217 5.634A.7.7 0 0 0 14 5.221c-.4-.4-.803-.167-.955 0l-1.092 1.083c.09.06.176.131.254.21.232.231.342.49.388.728l1.622-1.608Zm-2.899 1.457c.06.027.121.069.182.13.28.28 0 .78 0 .78l-.396.72c-.138.253-.302.553-.473.87C11.09 8.74 11.5 8 11.5 8s.28-.5 0-.78a.609.609 0 0 0-.182-.129Zm-1.303 3.644a47.335 47.335 0 0 1 0 0ZM15.76 6.05a.8.8 0 0 1 .24.17c.392.392.176.815.01.99l.005-.005c.112-.12.244-.352.196-.61A.715.715 0 0 0 16 6.22a.8.8 0 0 0-.24-.17ZM13.5 12.72h.001s-.019.015-.042.042l.002-.002a.35.35 0 0 1 .039-.04Zm1.5 1.5H15s-.019.015-.042.042l.002-.002a.35.35 0 0 1 .039-.04Zm-6.4-5.571C9.072 7.775 9.5 7 9.5 7s.28-.5 0-.78c-.5-.5-1 .28-1 .28s-2.5 4-3 4.721c-.5.72 0 3 0 3l-1.5 1.5.793.793 1.628-1.628a12.206 12.206 0 0 1-.104-.8 7.099 7.099 0 0 1-.025-1.166c.027-.357.103-.86.386-1.269.235-.337.97-1.493 1.664-2.594l.256-.407ZM10.268 2v2.5h-1V2h1ZM6.707 3l1.768 1.768-.707.707L6 3.707 6.707 3Z"
      />
    </SvgIcon>
  )
}

export default ClappedIcon
