import _toInteger from 'lodash/toInteger'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import { RootState } from 'duck'
import { SketchGenre, SketchProject } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { adjustedImages, userImages } from './CommonSelectors'

const currentSketchProjectId = (state: RootState) =>
  _toInteger(state.api.sketchToImage.currentSketchProjectId)

const sketchToImage = (state: RootState) => {
  return state.api.sketchToImage
}
const sketchStyles = createSelector(
  sketchToImage,
  sketchToImage => sketchToImage.sketchStyles?.results ?? []
)
const sketchGenres = createSelector(
  sketchToImage,
  sketchToImage => sketchToImage.sketchGenres?.results ?? []
)
/* Object key version */
const initial: { [id: number]: SketchGenre } = {}
const sketchGenreData = createSelector(sketchGenres, sketchGenres =>
  _reduce(
    sketchGenres,
    (result, value) => {
      result[value.id] = value
      return result
    },
    initial
  )
)
const hasSketchGenres = createSelector(sketchGenres, sketchGenres => Boolean(sketchGenres.length))

const sketchProjects = createSelector(sketchToImage, sketchToImage => sketchToImage.sketchProjects)
const sketchProjectListId = createSelector(
  sketchToImage,
  sketchToImage => sketchToImage.sketchProjectList
)
const sketchProjectList = createSelector(
  sketchProjects,
  sketchProjectListId,
  (sketchProjects, sketchProjectListId) =>
    _map(sketchProjectListId, id => sketchProjects?.[id] || {})
)
const outputList = createSelector(sketchToImage, sketchToImage => sketchToImage.outputList)
const currentSketchProjectOutputList = createSelector(
  outputList,
  currentSketchProjectId,
  (outputList, currentSketchProjectId) => outputList[currentSketchProjectId]
)

const currentSketchProjectOutputListCount = createSelector(
  currentSketchProjectOutputList,
  currentOutputList => {
    return currentOutputList?.lastListReq?.count
  }
)

const sketchToImageOutputs = createSelector(sketchToImage, sketchToImage => sketchToImage.outputs)

const currentSketchProject = createSelector(
  sketchProjects,
  currentSketchProjectId,
  currentSketchProjectOutputList,
  sketchToImageOutputs,
  userImages,
  adjustedImages,
  (
    sketchProjects,
    currentSketchProjectId,
    sketchOutput,
    sketchToImageOutputs,
    userImages,
    adjustedImages
  ): SketchProject => {
    const sketchProject = sketchProjects?.[currentSketchProjectId]

    const outputs = sketchOutput?.list?.map(sketchOutputId => {
      const currentOutput = sketchToImageOutputs[sketchOutputId]
      const imageId = currentOutput.image.id
      const image = userImages[imageId] ?? currentOutput.image

      return {
        ...currentOutput,
        image: {
          ...image,
          adjustData: adjustedImages[image.adjust ?? 0]
        },
        bookmark: userImages[imageId].bookmark
      }
    })

    return { ...sketchProject, outputs }
  }
)

const isCurrentSketchOutputHasNext = createSelector(
  currentSketchProjectId,
  outputList,
  (currentSketchProjectId, outputList) => {
    const sketchOutput = outputList?.[currentSketchProjectId]
    if (sketchOutput?.lastListReq) {
      return Boolean(sketchOutput.lastListReq?.next ?? null)
    } else {
      return null
    }
  }
)

const sketchToImageSelectors = {
  sketchToImageOutputs,
  currentSketchProjectOutputList,
  sketchProjectListId,
  sketchStyles,
  sketchProjects,
  sketchGenres,
  hasSketchGenres,
  sketchGenreData,
  currentSketchProject,
  currentSketchProjectOutputListCount,
  currentSketchProjectId,
  sketchProjectList,
  isCurrentSketchOutputHasNext
}

export default sketchToImageSelectors
