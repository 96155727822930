import produce from 'immer'
import { TextTransform } from 'utils/TextUtils'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  withLatestFrom,
  map,
  mergeMap,
  take,
  startWith,
  concatMap,
  tap
} from 'rxjs/operators'
import _compact from 'lodash/compact'
import { RootState, RootActionType } from 'duck'
import { getType, isActionOf, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { of, merge } from 'rxjs'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { actions } from './index'
import { MixImageGenerateReq, GenerateRandomProjectMixRequest, AnchorType } from 'models/ApiModels'
import { mixImageActions } from './MixImage'
import { ANCHOR_PATTERN, INITIAL_CONTROL } from './Models'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { panelsActions } from './Panels'
import { eventEmiterActions, AppEvents } from 'duck/AppDuck/EventEmitterDuck'

// Constants
const NAMESPACE = '@@page/MixImagePanel/ProjectMix'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const projectMixActions = {
  setProjectId: createAction(creator('SET_PROJECT_ID'))<number>(),
  initProjectMix: createAction(creator('INIT_PROJECT_MIX'))<{
    startExploreMode: ProjectMixDataState['startExploreMode'] | undefined
    anchorToBeAdded?: Partial<AnchorType>
  }>(),
  setStartExploreMode: createAction(creator('SET_START_EXPLORE_MODE'))<
    ProjectMixDataState['startExploreMode'] | undefined
  >(),
  generateMixProject: createAction(creator('GENERATE_MIX_PROJECT'))<MixImageGenerateReq>(),
  generatRandomProjectMix: createAction(
    creator('GENERATE_MIX_RANDOM_PROJECT')
  )<GenerateRandomProjectMixRequest>()
}

// Selectors
const selectProjectMix = (state: RootState) => state.container.mixImagePanel.projectMix
const selectProjectId = createSelector(selectProjectMix, projectMix => projectMix.projectId)

const selectStartExploreMode = createSelector(
  selectProjectMix,
  selectProjectId,
  (projectMix, projectId = 0) => {
    return projectMix.data[projectId]?.startExploreMode
  }
)
export const projectMixSelectors = {
  startExploreMode: selectStartExploreMode
}

export type ProjectMixDataState = {
  startExploreMode?: 'create-mix' | 'start-from-random-mix' | 'refine'
}
export type ProjectMixState = {
  projectId?: number
  data: { [projectId: number]: ProjectMixDataState }
}

export const initialData: ProjectMixDataState = {
  startExploreMode: undefined
}

const initialState: ProjectMixState = {
  projectId: undefined,
  data: {}
}

const reducer = produce((state: ProjectMixState, { type, payload }) => {
  const projectId = state.projectId
  switch (type) {
    case getType(projectMixActions.setProjectId): {
      state.projectId = payload
      state.data[payload] = state.data?.[payload || 0] || initialData
      return
    }
    case getType(projectMixActions.setStartExploreMode): {
      if (!projectId) return
      state.data[projectId].startExploreMode = payload
      return
    }
    default:
  }
}, initialState)

// Epics

/*
  - create new mix if not available
    - If create-mix then open bottomsheetbar
    - If start from random mix then
*/

/* If already has mix project */
const initProjectMixEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(projectMixActions.initProjectMix)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projects: apiSelectors.projects(state),
      startExploreMode: action.payload.startExploreMode,
      anchorToBeAdded: action.payload.anchorToBeAdded,
      projectId: selectProjectId(state)
    })),
    map(param => ({
      ...param,
      currentProject: param.projects[param.projectId ?? 0]
    })),
    filter(({ currentProject }) => Boolean(currentProject?.mix)),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(
            ({ startExploreMode }) =>
              startExploreMode === 'create-mix' || startExploreMode === 'refine'
          ),
          mergeMap(({ startExploreMode }) =>
            _compact([
              projectMixActions.setStartExploreMode('create-mix'),
              startExploreMode === 'create-mix' &&
                panelsActions.bottomSheet.setShowBottomSheet('add-mix')
            ])
          )
        ),

        of(param).pipe(
          filter(({ startExploreMode }) => startExploreMode === 'start-from-random-mix'),
          map(({ currentProject }) =>
            projectMixActions.generatRandomProjectMix({ id: currentProject?.mix ?? 0 })
          )
        ),
        of(param).pipe(
          mergeMap(({ anchorToBeAdded }) =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.retrieveProjectMixResponse)),
              take(1),
              filter(() => Boolean(anchorToBeAdded)),
              mergeMap(() =>
                _compact([
                  anchorToBeAdded &&
                    mixImageActions.createNewMix({
                      anchor: anchorToBeAdded
                    })
                ])
              ),
              startWith(apiActions.projects.retrieveProjectMix(param.currentProject?.mix ?? 0))
            )
          )
        ),
        of(apiActions.projects.retrieveProjectMix(param.currentProject?.mix ?? 0))
      )
    )
  )

/* If don't have mix project, then create it */
const initProjectMixEmptyEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(projectMixActions.initProjectMix)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projects: apiSelectors.projects(state),
      startExploreMode: action.payload,
      projectId: selectProjectId(state)
    })),
    map(param => ({
      ...param,
      currentProject: param.projects[param.projectId ?? 0]
    })),
    filter(({ currentProject }) => !Boolean(currentProject?.mix)),
    mergeMap(({ currentProject, startExploreMode }) =>
      action$.pipe(
        filter(isActionOf(apiActions.projects.createProjectMixResponse)),
        take(1),
        map(() => projectMixActions.initProjectMix(startExploreMode)),
        startWith(apiActions.projects.createProjectMix({ project: currentProject?.id }))
      )
    )
  )

/* Generate Mix Image project */
const generateMixProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(projectMixActions.generateMixProject)),
    map(({ payload }) => ({
      id: payload.id,
      is_resample: payload.is_resample || false,

      //Transform standalone anchor into project anchor
      anchors: payload.anchors.map(anchor => ({
        id: anchor.id,
        weight: anchor.controls.content_weight
      }))
    })),
    mergeMap(generateParam =>
      action$.pipe(
        filter(isActionOf(apiActions.projects.generateProjectMixResponse)),
        take(1),
        withLatestFrom(state$),
        tap(([_, state]) => {
          const trainProject = apiSelectors.currentProject(state)

          MixPanelUtils.track<'PROJECT__MIX_GENERATE'>('Project Mix - Generate Mix', {
            ...DataUtils.getProjectParam<'training_project'>('training_project', {
              trainProject
            }),
            anchor_count: generateParam.anchors.length,
            is_resample: generateParam.is_resample
          })
        }),
        mergeMap(([action]) => [
          mixImageActions.setIsAttributeChanged(false),
          mixImageActions.saveGenerateGridResult(action.payload.outputs ?? [])
        ]),
        startWith(apiActions.projects.generateProjectMix(generateParam))
      )
    )
  )

/* Generate Mix Image project */
const generatRandomProjectMixEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(projectMixActions.generatRandomProjectMix)),
    mergeMap(({ payload }) =>
      action$.pipe(
        filter(isActionOf(apiActions.projects.generateRandomProjectMixResponse)),
        take(1),
        map(({ payload }) => ({
          outputs: payload.outputs,
          anchors: ANCHOR_PATTERN[4].map(
            value =>
              ({
                id: payload.outputs?.[value]?.id,
                controls: INITIAL_CONTROL
              }) as AnchorType
          )
        })),
        concatMap(({ outputs, anchors }) => [
          mixImageActions.setIsAttributeChanged(false),
          projectMixActions.setStartExploreMode('start-from-random-mix'),
          ...anchors.map(anchor => mixImageActions.addAnchorWithoutRegenerate(anchor)),
          mixImageActions.saveGenerateGridResult(outputs ?? [])
        ]),
        startWith(apiActions.projects.generateRandomProjectMix(payload))
      )
    )
  )

const listenOnRefineEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    filter(({ payload }) => Boolean(payload[AppEvents.ON_CLICK_REFINE])),
    withLatestFrom(state$),
    map(([action, state]) => ({ payload: action.payload[AppEvents.ON_CLICK_REFINE], state })),
    map(({ payload }) => payload?.payload),
    filter(param => Boolean(param?.imageId) && Boolean(param?.projectId)),
    concatMap(param => [
      projectMixActions.setProjectId(param?.projectId ?? 0),
      actions.logDoAction({ actionType: 'refine' }),
      projectMixActions.initProjectMix({
        startExploreMode: 'refine',
        anchorToBeAdded: {
          id: param?.imageId ?? 0,
          controls: INITIAL_CONTROL
        }
      })
    ])
  )

export const projectMixEpics = combineEpics(
  listenOnRefineEpic,
  initProjectMixEpic,
  initProjectMixEmptyEpic,
  generateMixProjectEpic,
  generatRandomProjectMixEpic
)
export default reducer
