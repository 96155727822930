import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import Grid, { GridProps } from '@mui/material/Grid'

import stylesGlobal from 'stylesGlobal.module.scss'

type GridLabelTwoProps = GridProps & {
  borderless?: boolean
  className?: string
  label: ReactNode
  labelColor?: 'light'
  content: ReactNode
  classes?: {
    label?: string
    content?: string
  }
}

const GridLabelTwo: React.FC<GridLabelTwoProps> = props => {
  const { borderless, classes, className, content, label, labelColor } = props

  return (
    <Grid
      className={clsx(!borderless ? stylesGlobal.BorderBottomOnSurface : '', className)}
      alignItems="center"
      container
    >
      <Grid
        className={twMerge(
          'flex items-center',
          labelColor === 'light' && 'text-white-med-on-dark',
          classes?.label
        )}
        xs
        item
        zeroMinWidth
      >
        {label}
      </Grid>

      <Grid xs="auto" className={classes?.content} item>
        {content}
      </Grid>
    </Grid>
  )
}

export default GridLabelTwo
