import React from 'react'
import clsx from 'clsx'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Helmet from 'react-helmet'

import Button from 'components/Button'
import Link from 'components/Link'
import Page from 'components/Page'
import { route } from 'routes'
import { values } from 'appConstants'
import { UrlUtils } from 'utils/TextUtils'
import MixPanelUtils from 'utils/MixPanelUtils'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './App.module.scss'

interface ErrorBoundaryProps {
  hasError: boolean
  errorMessage: string
}

const ErrorPage: React.FC<{ errorMessage: string }> = ({ errorMessage }) => {
  return (
    <Page className="flex h-full items-center justify-center">
      <Helmet>
        <title>Playform - An Unexpected Error Has Occured</title>
      </Helmet>

      <Card className={clsx(stylesGlobal.BorderOnSurface, styles.ErrorPageBox)}>
        <Typography variant="h5" className="mb-6" paragraph>
          An Unexpected Error Has Occured
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          We're sorry for the inconvenience. This error has been reported to Us, and we'll take a
          look into this error.
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          {`If you have a question, you can contact us at : `}
          <Link to={`mailto:${values.PLAYFORM_CONTACT_EMAIL}`}>
            {values.PLAYFORM_CONTACT_EMAIL}
          </Link>
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          Meanwhile, you can continue to use this app by reload the page.
        </Typography>

        <div className="mb-6 mt-11 text-center">
          <Button
            onClick={() => {
              UrlUtils.replaceState(route.EXPLORE.getUrl())
              window.location.reload()
            }}
            color="secondary"
          >
            Reload Page
          </Button>
        </div>

        <Typography variant="caption" component="div">
          Error Message : {errorMessage}
        </Typography>
      </Card>
    </Page>
  )
}

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, ErrorBoundaryProps> {
  constructor(props: Readonly<{ children: React.ReactNode }>) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  static getDerivedStateFromError(error: Error) {
    MixPanelUtils.track<'USER__ERROR_BOUNDARY'>('User - Error Boundary', {
      content: error.message,
      value: error.name
    })
    return { hasError: true, errorMessage: error.message }
  }

  componentDidCatch = (error: unknown) => {
    // Error gets sent to server here

    // Set error state to false here
    this.setState({ hasError: false })
  }

  render() {
    return this.state.hasError ? (
      <ErrorPage errorMessage={this.state.errorMessage} />
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
