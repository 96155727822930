import React from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { RootState, RootActionType } from 'duck'
import { appSelectors } from 'duck/AppDuck'
import Dialog from 'components/Dialog'
import ImagePreview from './ImagePreview'
import styles from './UpscaleDetail.module.scss'
import Banner from 'components/Banner'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import { ReduxProps } from 'utils/Types'

const DIALOG_CLASSES = {
  paper: clsx('w-full', styles.DialogUpscaleDetail)
}

const mapStateToProps = (state: RootState) => ({
  userDeviceInfo: appSelectors.userDeviceInfo(state),
  banner: appSelectors.banner.banner(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeBanner: bannerActions.close
    },
    dispatch
  )

type UpscaleDetailDesktopProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  onClose: () => void
  open?: boolean
  children: JSX.Element
}

const UpscaleDetailDesktop: React.FC<UpscaleDetailDesktopProps> = props => {
  const { banner, children, open = false, userDeviceInfo, onClose, closeBanner } = props
  const isTouchDevice = userDeviceInfo?.summary?.isTouchDevice

  return (
    <Dialog open={open} onClose={onClose} classes={DIALOG_CLASSES} disableContentSpacing fullWidth>
      <div>
        <Banner {...banner} mode="dialog" onClose={closeBanner} />
      </div>

      <div className={clsx('flex', styles.UpscaleDetailDesktopContainer)}>
        <div className="w-1/100 min-w-0 flex-auto">
          <ImagePreview open={open} isTouchDevice={isTouchDevice} />
        </div>
        {children}
      </div>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpscaleDetailDesktop)
