import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { ChangeEmailDialog, dialogActions } from './DialogDuck'
import { firebaseActions, firebaseSelectors } from 'duck/FirebaseDuck'
import _get from 'lodash/get'
import {
  filter,
  withLatestFrom,
  map,
  mergeMap,
  catchError,
  tap,
  ignoreElements
} from 'rxjs/operators'
import { combineEpics, Epic } from 'redux-observable'
import { RootActionType, RootState } from 'duck'
import { ActionKeyCreator, UrlUtils } from 'utils/TextUtils'
import { apiSelectors } from 'duck/ApiDuck'
import { ApiUtils, LocalStorage } from 'utils'
import validator, { VALIDATION_MESSAGES } from 'utils/Validator'
import { of, merge, from, concat } from 'rxjs'
import produce from 'immer'
import { createSelector } from 'reselect'
import { LSKey } from 'appConstants'
import { getFirebaseAuth } from 'utils/FirebaseUtils'
import { errorUtils } from 'utils/DataProcessingUtils'
import MixPanelUtils from 'utils/MixPanelUtils'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'

//Constant
const ERROR_MESSAGE = {
  SENT_CURRENT_EMAIL_FAILED: {
    title: `Can't send link to current email`
  },
  EMAIL_NOT_AVAILABLE: {
    title: `The email address you entered is not valid`,
    message: [
      `The new email address you entered is already being used by an existing account`,
      ` Please enter a different email address.`
    ]
  },
  EMAIL_ALREADY_CONFIRMED: {
    title: `Failed to change the email`,
    message: `The new email address has already been confirmed`
  },
  SUBMIT_EMAIL_FAILED: {
    title: `Failed to submit the new email`
  },
  CHANGE_EMAIL_FAILED: {
    title: `Failed to change the email`
  },
  EMAIL_SIMILAR: {
    message: `Please enter an email address that differs from your previous address.`
  },
  MISSING_REQUIRED_DATA: {
    message: `Can't submit the email because missing required data. Please try to reopen link from an email or click "Submit Email" again.`
  },
  FAILED_AUTH_AFTER_LOGIN: {
    message:
      'Failed to authenticate using the link because of an unknown error. Please try login manually with your new email in the login page.',
    title: `Failed to authenticate after change email`
  }
}

type FormDataInput = Partial<ChangeEmailState['formData']>

// Actions
export const changeEmailActions = {
  didMountApp: createAction('@@page/App/changeEmail/DID_MOUNT_APP')(),
  didMountLoginPage: createAction('@@page/App/changeEmail/DID_MOUNT_LOGIN_PAGE')(),
  didMountAfterLogin: createAction('@@page/App/changeEmail/DID_MOUNT_AFTER_LOGIN')(),
  setFormData: createAction('@@page/App/changeEmail/SET_FORM_DATA')<FormDataInput>(),
  setFormError: createAction('@@page/App/changeEmail/SET_FORM_ERROR')<FormDataInput>(),
  resetFormErrors: createAction('@@page/App/changeEmail/RESET_FORM_ERRORS')(),
  submitChangeEmailRequest: createAction('@@page/App/changeEmail/SUBMIT_CHANGE_EMAIL_REQUEST')(),
  resubmitChangeEmailRequest: createAction(
    '@@page/App/changeEmail/RESUBMIT_CHANGE_EMAIL_REQUEST'
  )(),
  submitEmail: createAction('@@page/App/changeEmail/SUBMIT_EMAIL')(),
  resubmitEmail: createAction('@@page/App/changeEmail/RESUBMIT_EMAIL')(),
  setLoading: createAction('@@page/App/changeEmail/SET_LOADING')<boolean>(),
  openEmailDialog: createAction('@@page/App/changeEmail/OPEN_EMAIL_DIALOG')(),
  afterSubmitEmail: createAction('@@page/App/changeEmail/AFTER_SUBMIT_EMAIL')(),
  setGeneralError: createAction('@@page/App/changeEmail/SET_GENERAL_ERROR')<ChangeEmailError>()
}

export type ChangeEmailActions = ActionType<typeof changeEmailActions>
export type ChangeEmailError = {
  title: string
  message: string | string[]
}
// Selectors
const selectChangeEmail = (state: RootState) => state.container.appPage.changeEmail

export const selectors = {
  changeEmail: selectChangeEmail,
  isLoading: createSelector(selectChangeEmail, changeEmail => changeEmail?.loading ?? false),

  formData: createSelector(selectChangeEmail, changeEmail => changeEmail?.formData ?? {}),
  formError: createSelector(selectChangeEmail, changeEmail => changeEmail?.formError ?? {}),
  generalError: createSelector(selectChangeEmail, changeEmail => changeEmail.generalError),
  didMountAfterLogin: createSelector(
    selectChangeEmail,
    changeEmail => changeEmail?.didMountAfterLogin
  )
}

type FormData<Type> = {
  changedEmail?: Type
  secret?: Type
  email?: Type
}

// Reducer
export type ChangeEmailState = {
  didMountApp: boolean
  didMountAfterLogin: boolean
  didMountLoginPage: boolean
  formData: FormData<string>
  formError: FormData<string | boolean>
  generalError: null | ChangeEmailError
  loading: boolean
}

const initialState: ChangeEmailState = {
  didMountApp: false,
  didMountAfterLogin: false,
  didMountLoginPage: false,
  formData: {},
  formError: {},
  generalError: null,
  loading: false
}
const reducer = produce((state: ChangeEmailState, { type, payload }) => {
  switch (type) {
    case getType(changeEmailActions.didMountApp): {
      state.didMountApp = true
      return
    }
    case getType(changeEmailActions.didMountLoginPage): {
      state.didMountLoginPage = true
      return
    }
    case getType(changeEmailActions.didMountAfterLogin): {
      state.didMountAfterLogin = true
      return
    }
    case getType(changeEmailActions.setFormData): {
      const formData = payload as ActionType<typeof changeEmailActions.setFormData>['payload']

      state.formData = {
        ...state.formData,
        ...formData
      }
      return
    }
    case getType(changeEmailActions.setFormError): {
      const formError = payload as ActionType<typeof changeEmailActions.setFormError>['payload']

      state.formError = {
        ...state.formError,
        ...formError
      }
      return
    }
    case getType(changeEmailActions.resetFormErrors): {
      state.formError = initialState.formError
      return
    }
    case getType(changeEmailActions.setLoading): {
      state.loading = payload
      return
    }
    case getType(changeEmailActions.setGeneralError): {
      state.generalError = payload
      return
    }
    default:
  }
}, initialState)

const openChangeEmailDialogEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(changeEmailActions.didMountAfterLogin)),
    map(() => LocalStorage.getJSON(LSKey.QUERY_PARAM)),
    filter(({ action }) => _get(action, 'key') === getType(changeEmailActions.openEmailDialog)),
    tap(() => LocalStorage.remove(LSKey.QUERY_PARAM)),
    mergeMap(({ secret }) =>
      concat(
        of(
          dialogActions.openDialog({
            [ChangeEmailDialog.CHANGE_EMAIL]: { dialogName: ChangeEmailDialog.CHANGE_EMAIL }
          })
        ),
        of(
          changeEmailActions.setFormData({
            secret
          })
        )
      )
    )
  )

const openAfterSubmitEmailEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([changeEmailActions.didMountAfterLogin, changeEmailActions.didMountLoginPage])
    ),
    map(() => LocalStorage.getJSON(LSKey.QUERY_PARAM)),
    filter(({ action }) => _get(action, 'key') === getType(changeEmailActions.afterSubmitEmail)),
    tap(() => LocalStorage.remove(LSKey.QUERY_PARAM)),
    mergeMap(data =>
      concat(
        of(
          changeEmailActions.setFormData({
            changedEmail: data?.action?.email
          })
        ),

        /* If success */
        of(data).pipe(
          filter(({ status }) => status === 'succeed'),
          tap(data => {
            MixPanelUtils.track<'USER__CHANGE_EMAIL_SUCCESS'>('User - Change Email Success')
            MixPanelUtils.updatePeople({
              $email: _get(data, 'action.email')
            })
          }),
          map(() =>
            dialogActions.openDialog({
              [ChangeEmailDialog.EMAIL_SUCCESSFULY_CHANGED]: {
                dialogName: ChangeEmailDialog.EMAIL_SUCCESSFULY_CHANGED
              }
            })
          )
        ),
        /* If failed */
        of(data).pipe(
          filter(({ status }) => status !== 'succeed'),
          mergeMap(({ errorMsg }) =>
            concat(
              of(errorMsg).pipe(
                filter(
                  () => errorMsg !== 'email_already_confirmed' && errorMsg !== 'email_not_available'
                ),
                map(() =>
                  changeEmailActions.setGeneralError({
                    message: errorMsg,
                    ...ERROR_MESSAGE.CHANGE_EMAIL_FAILED
                  })
                )
              ),
              of(errorMsg).pipe(
                filter(() => errorMsg === 'email_already_confirmed'),
                map(() => changeEmailActions.setGeneralError(ERROR_MESSAGE.EMAIL_ALREADY_CONFIRMED))
              ),
              of(errorMsg).pipe(
                filter(() => errorMsg === 'email_not_available'),
                map(() => changeEmailActions.setGeneralError(ERROR_MESSAGE.EMAIL_NOT_AVAILABLE))
              ),
              of(
                dialogActions.openDialog({
                  [ChangeEmailDialog.ERROR_DIALOG]: { dialogName: ChangeEmailDialog.ERROR_DIALOG }
                })
              )
            )
          )
        )
      )
    )
  )

/* Store action, this will be read  later by another epic */

const readActionParamEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(changeEmailActions.didMountApp)),
    map(() => UrlUtils.getActionParam(window.location.search)),
    filter(({ key }) => Boolean(key)),
    map(action => {
      const params = new URLSearchParams(window.location.search)
      const secret = params.get('amp;secret') || params.get('secret')
      const status = params.get('amp;status') || params.get('status')
      const errorMsg = params.get('amp;error_msg') || params.get('error_msg')

      return { action, secret, status, errorMsg }
    }),
    tap(data => LocalStorage.saveJSON(LSKey.QUERY_PARAM, data)),
    ignoreElements()
  )

const authenticateAfterEmailChangeEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(changeEmailActions.didMountApp)),
    map(() => UrlUtils.getActionParam(window.location.search)),
    filter(action => _get(action, 'key') === getType(changeEmailActions.afterSubmitEmail)),
    map(() => isSignInWithEmailLink(getFirebaseAuth(), window.location.href)),
    filter(isSignIn => isSignIn),
    map(isSignIn => {
      let email: string | null = LocalStorage.get(LSKey.EMAIL_FOR_LINK_LOGIN) || ''
      if (!email && isSignIn) {
        email = window.prompt('Please provide your new email for confirmation')
      }
      return { isSignIn, email }
    }),
    mergeMap(({ email }) =>
      concat(
        of(firebaseActions.setLoading(true)),
        from(signInWithEmailLink(getFirebaseAuth(), email as string, window.location.href)).pipe(
          tap(() => LocalStorage.remove(LSKey.EMAIL_FOR_LINK_LOGIN)),
          ignoreElements()
        ),
        of(firebaseActions.setLoading(false))
      )
    ),
    catchError(err =>
      concat(
        of(
          dialogActions.openDialog({
            [ChangeEmailDialog.ERROR_DIALOG]: { dialogName: ChangeEmailDialog.ERROR_DIALOG }
          })
        ),
        of(firebaseActions.setLoading(false)),
        of(changeEmailActions.setGeneralError(ERROR_MESSAGE.FAILED_AUTH_AFTER_LOGIN))
      )
    )
  )

const getSubmitActionCallback = (userId: string) => {
  const baseUrl = process.env.REACT_APP_FIREBASE_APP_LINK_REDIRECT
  const param = {
    key: getType(changeEmailActions.openEmailDialog),
    userId //We don't use this, but useful to scramble action code
  }

  return `${baseUrl}/?action=${ActionKeyCreator.generate(param)}`
}

/* API Definition 
    Change email API: POST /api/users/{id}/change_email/
    args:
    - email (string)
    - cb (url used to redirect back, when user click link in email)
    return:
    - status (succeed/failed)
    - error_msg (only if failed)

    Extra query parameters when cb is called:
    - status (succeed/failed)
    - error_msg (only if failed)

    verify API: POST /api/users/{id}/verify/
    args: cb (url to redirect to after verification)
    return:
    - status (succeed/failed)
    - error_msg
    extra query param on cb:
    - secret
    verify secret API: POST /api/users/{id}/verify_secret/
    args: secret
    return:Cob
    - status (succeed/failed)
    - error_msg

*/

const submitChangeEmailRequestEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        changeEmailActions.submitChangeEmailRequest,
        changeEmailActions.resubmitChangeEmailRequest
      ])
    ),
    withLatestFrom(state$),
    map(([param, state]) => ({
      email: apiSelectors.currentUserEmail(state) || '',
      userId: firebaseSelectors.userId(state) || '',
      cb: getSubmitActionCallback(firebaseSelectors.userId(state) || ''),
      successDialog: isActionOf(changeEmailActions.submitChangeEmailRequest)
        ? ChangeEmailDialog.SENT_CURRENT_EMAIL
        : ChangeEmailDialog.RESENT_CURRENT_EMAIL
    })),
    filter(({ email, userId }) => Boolean(email) && Boolean(userId)),
    mergeMap(({ email, userId, cb, successDialog }) =>
      concat(
        of(changeEmailActions.setLoading(true)),
        ApiUtils.users.verifyChangeEmail(userId, { cb }).pipe(
          tap(() => {
            MixPanelUtils.track<'USER__CHANGE_EMAIL_REQUEST'>('User - Change Email Request')
          }),
          map(() => dialogActions.openDialog({ [successDialog]: { dialogName: successDialog } })),
          catchError(err =>
            concat(
              of(
                changeEmailActions.setGeneralError({
                  ...ERROR_MESSAGE.SENT_CURRENT_EMAIL_FAILED,
                  message: errorUtils.flattenMessage({ error: err.response })
                })
              ),
              of(
                dialogActions.openDialog({
                  [ChangeEmailDialog.ERROR_DIALOG]: { dialogName: ChangeEmailDialog.ERROR_DIALOG }
                })
              )
            )
          )
        ),
        of(changeEmailActions.setLoading(false))
      )
    )
  )

const getSubmitEmailCallback = (email: string) => {
  const baseUrl = process.env.REACT_APP_FIREBASE_APP_LINK_REDIRECT
  const param = {
    key: getType(changeEmailActions.afterSubmitEmail),
    email
  }

  return `${baseUrl}/?action=${ActionKeyCreator.generate(param)}`
}

const submitEmailEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([changeEmailActions.submitEmail, changeEmailActions.resubmitEmail])),
    withLatestFrom(state$),
    map(([param, state]) => ({
      formData: selectors.formData(state),
      currentEmail: apiSelectors.currentUserEmail(state),
      userId: firebaseSelectors.userId(state) || '',
      successDialog: isActionOf(changeEmailActions.submitEmail)
        ? ChangeEmailDialog.CONFIRM_NEW_EMAIL
        : ChangeEmailDialog.RESENT_CONFIRM_NEW_EMAIL
    })),
    map(({ formData, ...others }) => ({
      email: formData.email,
      secret: formData.secret,
      cb: getSubmitEmailCallback(_get(formData, 'email', '')),
      ...others
    })),
    //Validate email
    map(({ email, currentEmail, secret, ...others }) => {
      let errorEmail = ''
      const notEmpty = validator.required(email)
      const emailValid = validator.email(email ?? '')
      const emailDifferent = email !== currentEmail

      if (!notEmpty) {
        errorEmail = VALIDATION_MESSAGES.required
      } else if (!emailValid) {
        errorEmail = VALIDATION_MESSAGES.email
      } else if (!emailDifferent) {
        errorEmail = ERROR_MESSAGE.EMAIL_SIMILAR.message
      }

      if (!secret) {
        errorEmail = ERROR_MESSAGE.MISSING_REQUIRED_DATA.message
      }

      return { email, errorEmail, secret, ...others }
    }),
    mergeMap(data =>
      merge(
        of(data).pipe(
          filter(({ errorEmail }) => Boolean(errorEmail)),
          map(({ errorEmail }) => changeEmailActions.setFormError({ email: errorEmail }))
        ),
        of(data).pipe(
          filter(({ errorEmail, userId }) => !Boolean(errorEmail) && Boolean(userId)),
          mergeMap(({ email = '', userId, secret = '', cb, successDialog }) =>
            concat(
              of(changeEmailActions.setLoading(true)),
              ApiUtils.users.changeEmail(userId || '', { cb, email, secret }).pipe(
                map(resp => resp.data),
                mergeMap(resp =>
                  concat(
                    of(resp).pipe(
                      filter(({ status }) => status === 'succeed'),
                      tap(() => {
                        LocalStorage.save(LSKey.EMAIL_FOR_LINK_LOGIN, email)
                      }),
                      map(() =>
                        dialogActions.addDialog({
                          [successDialog]: { dialogName: successDialog }
                        })
                      )
                    ),
                    of(resp).pipe(
                      filter(({ status }) => status === 'failed'),
                      mergeMap(resp =>
                        concat(
                          of(resp).pipe(
                            filter(({ error_msg }) => error_msg === 'email_not_available'),
                            map(() =>
                              changeEmailActions.setGeneralError({
                                ...ERROR_MESSAGE.EMAIL_NOT_AVAILABLE
                              })
                            )
                          ),
                          of(resp).pipe(
                            filter(({ error_msg }) => error_msg !== 'email_not_available'),
                            map(({ error_msg }) =>
                              changeEmailActions.setGeneralError({
                                message: error_msg,
                                ...ERROR_MESSAGE.SUBMIT_EMAIL_FAILED
                              })
                            )
                          ),
                          of(
                            dialogActions.addDialog({
                              [ChangeEmailDialog.ERROR_DIALOG]: {
                                dialogName: ChangeEmailDialog.ERROR_DIALOG
                              }
                            })
                          )
                        )
                      )
                    )
                  )
                )
              ),
              of(changeEmailActions.setLoading(false))
            )
          ),
          catchError(err =>
            concat(
              of(changeEmailActions.setLoading(false)),
              of(
                changeEmailActions.setGeneralError({
                  ...ERROR_MESSAGE.SUBMIT_EMAIL_FAILED,
                  message: errorUtils.flattenMessage({ error: err.response })
                })
              ),
              of(
                dialogActions.addDialog({
                  [ChangeEmailDialog.ERROR_DIALOG]: { dialogName: ChangeEmailDialog.ERROR_DIALOG }
                })
              )
            )
          )
        )
      )
    )
  )

export const epics = combineEpics(
  openChangeEmailDialogEpic,
  openAfterSubmitEmailEpic,
  readActionParamEpic,
  authenticateAfterEmailChangeEpic,
  submitChangeEmailRequestEpic,
  submitEmailEpic
)

export default reducer
