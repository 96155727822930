import { ActionType, createAction } from 'typesafe-actions'
import { XOR } from 'ts-xor'
import {
  Collection,
  CollectionListReq,
  CollectionCopyReq,
  CollectionCreateReq,
  CollectionUpdateReq,
  ImageCreateReq,
  ImageListReq,
  TrainOutputImagesReq,
  PaginationResponse,
  TrainProject,
  TrainProjectCreateReq,
  TrainProjectUpdateReq,
  ProjectCopyReq,
  ImageType,
  UpdateUserReq,
  User,
  TrainOutputImageResponse,
  TrainProjectListReq,
  PermissionsType,
  PaymentReq,
  ProductsResponse,
  Payment,
  ApiErrorBody,
  EngineConfigResponse,
  TrainProjectStartReq,
  ProjectSummary,
  PaginationRequestParams,
  InferenceRecord,
  InferenceRecordCreateReq,
  AddInferenceInputReq,
  InferenceStartReq,
  Category,
  TagListReq,
  Tag,
  InputImageSet,
  InputImageSetUpdateReq,
  MoveCollectionReq,
  MoveCollectionRes,
  SketchStyleListResponse,
  SketchProjectCreateReq,
  SketchProject,
  SketchStylesListReq,
  QuestionCreateReq,
  Question,
  PostListResponse,
  PostCreateReq,
  PostListReq,
  Post,
  PageParams,
  GenreListResponse,
  ClapResponse,
  ClapReq,
  Comment,
  CommentCreateReq,
  Thread,
  ThreadCreateReq,
  ThreadListResponse,
  CommentListResponse,
  CommentListReq,
  ThreadListReq,
  SketchProjectListReq,
  GenerateSketchProjectReq,
  SketchProjectListResponse,
  SketchProjectUpdateReq,
  SketchOutputListReq,
  SketchOutputListResponse,
  SpaceImageRequest,
  SpaceImage,
  OriginImageRequest,
  MixImageGenreListResponse,
  MixImageGenerateReq,
  MixImageCreateReq,
  MixImageProject,
  MixImageListResponse,
  Equity,
  SubscriptionCreateReq,
  CheckPhoneUsedReq,
  UpdatePhoneResponse,
  MixImageUpdateReq,
  UserUiExtrasType,
  UploadImage,
  ProjectMixCreateRequest,
  ProjectMix,
  GenerateProjectMixRequest,
  GenerateRandomProjectMixRequest,
  ProjectMixRandomListRequest,
  ProjectMixRandomListResponse,
  ListClipReq,
  Clip,
  UpdateClipReq,
  CreateClipReq,
  DeleteClipRequest,
  MixImageGeneratePreviewReq,
  UserImage,
  CreateUploadImageReq,
  PostUpdateReq,
  BannerImage,
  TrainProjectMoreRequest,
  UploadSketchRequest,
  UpdateThresholdRequest,
  RemoveSketchRequest,
  ListCreditChangeLogResponse,
  CreditOverview,
  Bookmark,
  BookmarkCreateReq,
  UserImageBookmarkListResponse,
  UserImageBookmarkListReq,
  ProArtFilterGenreListResponse,
  ProArtFilterStyleListReq,
  ProArtFilterListResponse,
  ProArtFilterProject,
  ProArtFilterCreateReq,
  ProArtFilterUpdateReq,
  ProArtFilterGenerateReq,
  ProArtFilterUploadContentReq,
  ProArtFilterStyleListResponse,
  ProArtFilterOutputListReq,
  ProArtFilterOutputListResponse,
  EquityConfig,
  PreSignupEmailReq,
  ProArtFilterBanner,
  AdjustedImage,
  AdjustedImageCreateReq,
  AdjustedImageConnectReq,
  UpscaleImage,
  UpscaleImageCreateReq,
  ListUpscaleImageReq,
  UpscaleMethod,
  ConfigVariables,
  DownloadImage,
  CreateDownloadImageReq,
  Channel,
  NotificationListReq,
  Notification,
  NotificationUpdateReq,
  GenerateClipReq,
  MineProject,
  CreateMineProjectReq,
  ArtMineConfig,
  Signature,
  ListMineProjectReq,
  BrainTreeTokenResponse,
  BraintreeCreatePaymentMethodReq,
  BraintreePaymentMethod,
  Subscription,
  SubscriptionUpdateReq,
  NotificationTemplate,
  NotificationPreference,
  NotificationPreferenceUpdateReq,
  NotificationPreferenceCreateReq,
  UpdateUserAliasReq,
  UploadUserPictureReq,
  TIProjectOutputListReq,
  TIProjectListResponse,
  TIProject,
  TIProjectCreateReq,
  TIProjectGenerateReq,
  TIProjectOutputListResponse,
  TIProjectUpdateReq,
  TIProjectListReq,
  TVProject,
  TVProjectCreateReq,
  TVProjectGenerateReq,
  TVProjectListReq,
  TVProjectListResponse,
  TVProjectOutputListReq,
  TVProjectOutputListResponse,
  TVProjectUpdateReq,
  GenericApp,
  GenericAppListResponse,
  GenericAppOutputImageListReq,
  GenericAppOutputImageListResponse,
  GenericAppProject,
  GenericAppProjectCreateReq,
  GenericAppProjectListResponse,
  GenericAppProjectUpdateReq,
  SketchTextProject,
  SketchTextCreateReq,
  SketchTextUpdateReq,
  SketchTextGenerateReq,
  SketchTextListOutputReq,
  SketchTextOutputImage,
  SketchTextUploadReq,
  SketchTextUpdateThresholdReq,
  SketchTextRemoveSketchReq,
  SketchTextGenre,
  SketchTextProcess,
  SketchTextModel,
  SketchTextStyles
} from 'models/ApiModels'
import { CollectionListType } from './reducers'
import { EventProperties } from 'utils/MixPanelUtils'
import { CryptoStateType } from 'utils/CryptoUtils/Models'

export const users = {
  setUserHasSubscriptionNoPaymentMethod: createAction('@@api/users/RETRIEVE_USER')<boolean>(),
  retrieve: createAction('@@api/users/RETRIEVE_USER')<{ uid: string; isFirstTime?: boolean }>(),
  submitPresignupEmail: createAction('@@api/users/SUBMIT_PRESIGNUP_EMAIL')<PreSignupEmailReq>(),
  retrieveResponse: createAction('@@api/users/RETRIEVE_USER_RESPONSE')<User>(),
  retrievePermission: createAction('@@/users/RETRIEVE_PERMISSION')(),
  retrievePermissionResponse: createAction(
    '@@/users/RETRIEVE_PERMISSION_RESPONSE'
  )<PermissionsType>(),

  update: createAction('@@api/users/UPDATE_USER')<UpdateUserReq>(),
  updateUiExtras: createAction('@@api/users/UPDATE_UI_EXTRAS')<UserUiExtrasType>(),
  updateAlias: createAction('@@api/users/UPDATE_ALIAS')<UpdateUserAliasReq>(),
  uploadUserPicture: createAction('@@api/users/UPDATE_USER_PICTURE')<UploadUserPictureReq>(),
  updateResponse: createAction('@@api/users/UPDATE_USER_RESPONSE')<User>(),

  sendQuestion: createAction('@@api/users/SEND_QUESTION')<QuestionCreateReq>(),
  sendQuestionResponse: createAction('@@api/users/SEND_QUESTION_RESPONSE')<Question>(),
  retrieveEquity: createAction('@@api/users/RETRIEVE_EQUITY')(),
  retrieveEquityResponse: createAction('@@api/users/RETRIEVE_EQUITY_RESPONSE')<Equity>(),
  retrieveEquityConfig: createAction('@@api/users/RETRIEVE_EQUITY_CONFIG')(),
  retrieveEquityConfigResponse: createAction('@@api/users/RETRIEVE_EQUITY_CONFIG_RESPONSE')<
    PaginationResponse<EquityConfig>
  >(),
  create: createAction('@@api/users/RETRIEVE_EQUITY_RESPONSE')<Equity>(),
  checkPhoneUsed: createAction('@@api/users/CHECK_PHONE_USED')<CheckPhoneUsedReq>(),
  checkPhoneUsedResponse: createAction('@@api/users/CHECK_PHONE_USED_RESPONSE')<boolean>(),
  updatePhone: createAction('@@api/users/UPDATE_PHONE')(),
  updatePhoneResponse: createAction('@@api/users/UPDATE_PHONE_RESPONSE')<UpdatePhoneResponse>(),
  retrieveConfigVariable: createAction('@@api/users/RETRIEVE_CONFIG_VARIABLE')(),
  retrieveConfigVariableResponse: createAction(
    '@@api/users/RETRIEVE_CONFIG_VARIABLE_RESPONSE'
  )<ConfigVariables>(),
  retrieveUnreadNotificationCount: createAction('@@api/users/RETRIEVE_UNREAD_NOTIFICATION_COUNT')(),
  retrieveUnreadNotificationCountResponse: createAction(
    '@@api/users/RETRIEVE_UNREAD_NOTIFICATION_COUNT_RESPONSE'
  )<number>(),
  listNotification: createAction('@@api/users/LIST_NOTIFICATION')<{
    param: NotificationListReq
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listNotificationResponse: createAction('@@api/users/LIST_NOTIFICATION_RESPONSE')<{
    data: PaginationResponse<Notification>
    req: NotificationListReq
    next?: boolean
    silentUpdate?: boolean
  }>(),
  retrieveNotification: createAction('@@api/users/RETRIEVE_NOTIFICATION')<number>(),
  retrieveNotificationResponse: createAction(
    '@@api/users/RETRIEVE_NOTIFICATION_RESPONSE'
  )<Notification>(),
  deleteNotification: createAction('@@api/users/DELETE_NOTIFICATION')<number>(),
  deleteNotificationResponse: createAction('@@api/users/DELETE_NOTIFICATION_RESPONSE')<number>(),
  updateNotification: createAction('@@api/users/UPDATE_NOTIFICATION')<NotificationUpdateReq>(),
  updateNotificationResponse: createAction(
    '@@api/users/UPDATE_NOTIFICATION_RESPONSE'
  )<Notification>(),
  listNotificationTemplate: createAction('@@api/users/LIST_NOTIFICATION_TEMPLATE')(),
  listNotificationTemplateResponse: createAction(
    '@@api/users/LIST_NOTIFICATION_TEMPLATE_RESPONSE'
  )<{
    data: PaginationResponse<NotificationTemplate>
  }>(),
  listNotificationPreferences: createAction('@@api/users/LIST_NOTIFICATION_PREFERENCES')(),
  listNotificationPreferencesResponse: createAction(
    '@@api/users/LIST_NOTIFICATION_PREFERENCES_RESPONSE'
  )<{ data: PaginationResponse<NotificationPreference> }>(),
  createNotificationPreferences: createAction(
    '@@api/users/CREATE_NOTIFICATION_PREFERENCES'
  )<NotificationPreferenceCreateReq>(),
  createNotificationPreferencesResponse: createAction(
    '@@api/users/CREATE_NOTIFICATION_PREFERENCES_RESPONSE'
  )<NotificationPreference>(),
  updateNotificationPreferences: createAction('@@api/users/UPDATE_NOTIFICATION_PREFERENCES')<{
    req: NotificationPreferenceUpdateReq
    template: number
  }>(),
  updateNotificationPreferencesResponse: createAction(
    '@@api/users/UPDATE_NOTIFICATION_PREFERENCES_RESPONSE'
  )<NotificationPreference>()
}

export type UserActionsType = ActionType<typeof users>

export const projects = {
  setCurrentProject: createAction('@@api/projects/SET_CURRENT_PROJECT')<number | undefined>(),
  list: createAction('@@api/projects/LIST')<{
    param: TrainProjectListReq | null
    reloadList?: boolean
  }>(),
  listResponse: createAction('@@api/projects/LIST_RESPONSE')<{
    data: PaginationResponse<TrainProject>
    reloadList?: boolean
    req?: TrainProjectListReq | null
  }>(),
  listActiveProjects: createAction('@@api/projects/LIST_ACTIVE_PROJECTS')(),
  listActiveProjectsResponse: createAction('@@api/projects/LIST_ACTIVE_PROJECTS_RESPONSE')<
    PaginationResponse<TrainProject>
  >(),
  retrieve: createAction('@@api/projects/RETRIEVE')<{
    id: number
    silentUpdate?: boolean
  }>(),
  retrieveResponse: createAction('@@api/projects/RETRIEVE_RESPONSE')<{
    data: TrainProject
    silentUpdate?: boolean
  }>(),

  create: createAction('@@api/projects/CREATE')<TrainProjectCreateReq>(),
  createResponse: createAction('@@api/projects/CREATE_RESPONSE')<TrainProject>(),

  delete: createAction('@@api/projects/DELETE')<number>(),
  deleteResponse: createAction('@@api/projects/DELETE_RESPONSE')<number>(),

  copy: createAction('@@api/projects/COPY')<ProjectCopyReq>(),
  copyResponse: createAction('@@api/projects/COPY_RESPONSE')<TrainProject>(),

  update: createAction('@@api/projects/UPDATE')<TrainProjectUpdateReq>(),
  updateResponse: createAction('@@api/projects/UPDATE_RESPONSE')<TrainProject>(),

  start: createAction('@@api/projects/START')<TrainProjectStartReq>(),
  startResponse: createAction('@@api/projects/START_RESPONSE')<TrainProject>(),

  more: createAction('@@api/projects/MORE')<TrainProjectMoreRequest>(),
  moreResponse: createAction('@@api/projects/MORE_RESPONSE')<TrainProject>(),

  stop: createAction('@@api/projects/STOP')<string | number>(),
  stopResponse: createAction('@@api/projects/STOP_RESPONSE')<TrainProject>(),

  retrieveSummary: createAction('@@api/projects/RETRIEVE_SUMMARY')(),
  retrieveSummaryResponse: createAction(
    '@@api/projects/RETRIEVE_SUMMARY_RESPONSE'
  )<ProjectSummary>(),
  updateThumbnail: createAction('@@api/projects/UPDATE_THUMBNAIL')<number>(),
  updateThumbnailResponse: createAction('@@api/projects/UPDATE_THUMBNAIL_RESPONSE')(),

  retrieveOutputImages: createAction('@@api/projects/RETRIEVE_OUTPUT_IMAGES')<{
    data: TrainOutputImagesReq
    next?: boolean
  }>(),
  retrieveOutputImagesResponse: createAction('@@api/projects/RETRIEVE_OUTPUT_IMAGES_RESPONSE')<{
    data: TrainOutputImageResponse
    req: TrainOutputImagesReq
  }>(),

  createProjectMix: createAction('@@api/projects/CREATE_PROJECT_MIX')<ProjectMixCreateRequest>(),
  createProjectMixResponse: createAction(
    '@@api/projects/CREATE_PROJECT_MIX_RESPONSE'
  )<ProjectMix>(),

  listRandomImages: createAction('@@api/projects/LIST_RANDOM_IMAGES')<{
    next?: boolean
    params?: ProjectMixRandomListRequest
  }>(),
  listRandomImagesResponse: createAction('@@api/projects/LIST_RANDOM_IMAGES_RESPONSE')<{
    data: ProjectMixRandomListResponse
    next?: boolean
    params?: ProjectMixRandomListRequest
  }>(),

  retrieveProjectMix: createAction('@@api/projects/RETRIEVE_PROJECT_MIX')<number>(),
  retrieveProjectMixResponse: createAction(
    '@@api/projects/RETRIEVE_PROJECT_MIX_RESPONSE'
  )<ProjectMix>(),
  generateProjectMix: createAction(
    '@@api/projects/GENERATE_PROJECT_MIX'
  )<GenerateProjectMixRequest>(),
  generateProjectMixResponse: createAction(
    '@@api/projects/GENERATE_PROJECT_MIX_RESPONSE'
  )<ProjectMix>(),

  generateRandomProjectMix: createAction(
    '@@api/projects/GENERATE_RANDOM_PROJECT_MIX'
  )<GenerateRandomProjectMixRequest>(),
  generateRandomProjectMixResponse: createAction(
    '@@api/projects/GENERATE_RANDOM_PROJECT_MIX_RESPONSE'
  )<ProjectMix>(),

  /* Generic Bookmark */
  toggleUserImageBookmark: createAction('@@api/projects/TOGGLE_USER_IMAGE_BOOKMARK')<
    BookmarkCreateReq<'user-image'> &
      Pick<EventProperties['USER_IMAGE_BOOKMARK'], 'bookmark_location'>
  >(),
  toggleUserImageBookmarkInstantResponse: createAction(
    '@@api/projects/TOGGLE_USER_IMAGE_BOOKMARK_INSTANT_RESPONSE'
  )<{
    userImageId: number
    bookmark: boolean
  }>(),
  toggleUserImageBookmarkResponse: createAction(
    '@@api/projects/TOGGLE_USER_IMAGE_BOOKMARK_RESPONSE'
  )<
    XOR<
      {
        data?: Bookmark<'bookmark-create'> | null
      },
      {
        deletedData?: {
          bookmarkId: number
          userImageId: number
          scope: string
        }
      }
    >
  >(),

  listUserImageBookmark: createAction('@@api/projects/LIST_USER_IMAGE_BOOKMARK')<{
    param: UserImageBookmarkListReq
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listUserImageBookmarkResponse: createAction('@@api/projects/LIST_USER_IMAGE_BOOKMARK_RESPONSE')<{
    data: UserImageBookmarkListResponse
    next?: boolean
    req?: UserImageBookmarkListReq | null
    silentUpdate?: boolean
  }>(),

  //Inference
  retrieveInference: createAction('@@api/results/RETRIEVE_INFERENCE')<number>(),
  retrieveInferenceResponse: createAction(
    '@@api/results/RETRIEVE_INFERENCE_RESPONSE'
  )<InferenceRecord>(),
  createInferenceRecord: createAction(
    '@@api/results/CREATE_INFERENCE_RECORD'
  )<InferenceRecordCreateReq>(),
  createInferenceRecordResponse: createAction(
    '@@api/results/CREATE_INFERENCE_RECORD_RESPONSE'
  )<InferenceRecord>(),
  addInferenceInput: createAction('@@api/results/ADD_INFERENCE_INPUT')<AddInferenceInputReq>(),
  addInferenceInputResponse: createAction('@@api/results/ADD_INFERENCE_INPUT_RESPONSE')<{
    data: InferenceRecord
    req: AddInferenceInputReq
  }>(),
  startInference: createAction('@@api/results/START_INFERENCE')<InferenceStartReq>(),
  startInferenceResponse: createAction('@@api/results/START_INFERENCE_RESPONSE')<InferenceRecord>()
}
export type ProjectsActionsType = ActionType<typeof projects>

export const imageEnhancement = {
  listUpscaleImage: createAction('@@api/imageEnhancement/LIST_UPSCALE_RECORD')<{
    param: ListUpscaleImageReq
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listUpscaleImageResponse: createAction('@@api/imageEnhancement/LIST_UPSCALE_RECORD_RESPONSE')<{
    data: PaginationResponse<UpscaleImage>
    next?: boolean
    req?: ListUpscaleImageReq | null
    silentUpdate?: boolean
  }>(),
  retrieveUserImageUpscales: createAction('@@api/imageEnhancement/RETRIEVE_USER_IMAGE_UPSCALE')<{
    userImageId: number
  }>(),
  retrieveUserImageUpscalesResponse: createAction(
    '@@api/imageEnhancement/RETRIEVE_USER_IMAGE_UPSCALE_RESPONSE'
  )<{
    upscaleImages: UpscaleImage[]
    userImageUpscales: { [key in UpscaleMethod]: number | undefined }
    userImageId: number
  }>(),
  retrieveUpscaleImage: createAction('@@api/imageEnhancement/RETRIEVE_UPSCALE_IMAGE')<number>(),
  retrieveUpscaleImageResponse: createAction(
    '@@api/imageEnhancement/RETRIEVE_UPSCALE_IMAGE_RESPONSE'
  )<{
    data: UpscaleImage
    req: number
  }>(),
  createUpscaleImage: createAction(
    '@@api/imageEnhancement/CREATE_UPSCALE_IMAGE'
  )<UpscaleImageCreateReq>(),
  createUpscaleImageResponse: createAction('@@api/imageEnhancement/CREATE_UPSCALE_IMAGE_RESPONSE')<{
    data: UpscaleImage
    req: UpscaleImageCreateReq
  }>(),
  deleteUpscaleImage: createAction('@@api/imageEnhancement/DELETE_UPSCALE_IMAGE')<{
    upscaleImageId: number
  }>(),
  deleteUpscaleImageResponse: createAction('@@api/imageEnhancement/DELETE_UPSCALE_IMAGE_RESPONSE')<{
    upscaleImageId: number
  }>(),
  createAdjustedImage: createAction(
    '@@api/imageEnhancement/CREATE_ADJUSTED_IMAGE'
  )<AdjustedImageCreateReq>(),
  createAdjustedImageResponse: createAction(
    '@@api/imageEnhancement/CREATE_ADJUSTED_IMAGE_RESPONSE'
  )<AdjustedImage>(),
  connectAdjustedImage: createAction(
    '@@api/imageEnhancement/CONNECT_ADJUSTED_IMAGE'
  )<AdjustedImageConnectReq>(),
  connectAdjustedImageResponse: createAction(
    '@@api/imageEnhancement/CONNECT_ADJUSTED_IMAGE_RESPONSE'
  )<{ data: AdjustedImage; req: AdjustedImageConnectReq }>(),
  deleteAdjustedImage: createAction('@@api/imageEnhancement/DELETE_ADJUSTED_IMAGE')<{
    adjustedImageId: number
    userImageId: number
  }>(),
  deleteAdjustedImageResponse: createAction(
    '@@api/imageEnhancement/DELETE_ADJUSTED_IMAGE_RESPONSE'
  )<{ adjustedImageId: number; userImageId: number }>(),
  retrieveAdjustedImage: createAction('@@api/imageEnhancement/RETRIEVE_ADJUSTED_IMAGE')<{
    adjustedImageId: number
  }>(),
  retrieveAdjustedImageResponse: createAction(
    '@@api/imageEnhancement/RETRIEVE_ADJUSTED_IMAGE_RESPONSE'
  )<AdjustedImage>(),
  checkOnUserImagesAdjust: createAction('@@api/imageEnhancement/CHECK_ON_USER_IMAGE_ADJUST')<{
    userImages?: UserImage[]
    userImage?: UserImage
  }>()
}
export type ImageEnhancementActionsType = ActionType<typeof imageEnhancement>

export const collections = {
  setCurrentCollection: createAction('@@api/collections/SET_CURRENT_COLLECTION')<
    number | undefined
  >(),
  create: createAction('@@api/collections/CREATE')<CollectionCreateReq>(),
  createResponse: createAction('@@api/collections/CREATE_RESPONSE')<Collection>(),

  list: createAction('@@api/collections/LIST')<{
    param: CollectionListReq | null
    listType: CollectionListType
    reloadList?: boolean
  }>(),
  listResponse: createAction('@@api/collections/LIST_RESPONSE')<{
    data: PaginationResponse<Collection>
    reloadList?: boolean
    listType: CollectionListType
  }>(),
  copy: createAction('@@api/collections/COPY')<CollectionCopyReq>(),
  copyResponse: createAction('@@api/collections/COPY_RESPONSE')<{
    collection: Collection
    copiedFrom: number
  }>(),

  retrieve: createAction('@@api/collections/RETRIEVE')<number>(),
  retrieveResponse: createAction('@@api/collections/RETRIEVE_RESPONSE')<Collection>(),
  update: createAction('@@api/collections/UPDATE')<
    CollectionUpdateReq & { silentUpdate?: boolean }
  >(),
  updateResponse: createAction('@@api/collections/UPDATE_RESPONSE')<Collection>(),

  delete: createAction('@@api/collections/DELETE')<string>(),
  deleteResponse: createAction('@@api/collections/DELETE_RESPONSE')<string>(),

  listCategories: createAction('@@api/collections/LIST_CATEGORIES')(),
  listCategoriesResponse: createAction('@@api/collections/LIST_CATEGORIES_RESPONSE')<
    PaginationResponse<Category>
  >(),
  listTags: createAction('@@api/collections/LIST_TAGS')<TagListReq>(),
  listTagsResponse: createAction('@@api/collections/LIST_TAGS_RESPONSE')<
    PaginationResponse<string>
  >(),
  addTag: createAction('@@api/collections/ADD_TAG')<{ collectionId: number; tag: Tag }>(),
  addTagResponse: createAction('@@api/collections/ADD_TAG_RESPONSE')<{
    collectionId: number
    tags: string[]
  }>(),
  removeTag: createAction('@@api/collections/REMOVE_TAG')<{
    collectionId: number
    tag: Tag
  }>(),
  removeTagResponse: createAction('@@api/collections/REMOVE_TAG_RESPONSE')<{
    collectionId: number
    tags: string[]
  }>(),
  onTagsUpdated: createAction('@@api/collections/ON_TAGS_UPDATED')<{
    collectionId: number
  }>(),
  updateThumbnail: createAction('@@api/collections/UPDATE_THUMBNAIL')<number>(),
  updateThumbnailResponse: createAction('@@api/collections/UPDATE_THUMBNAIL_RESPONSE')(),

  // Collection bookmark
  toggleCollectionBookmark: createAction('@@api/collections/TOGGLE_COLLECTION_BOOKMARK')<{
    collectionId: number
  }>(),
  toggleCollectionBookmarkInstantResponse: createAction(
    '@@api/collections/TOGGLE_COLLECTION_BOOKMARK_INSTANT_RESPONSE'
  )<{
    collectionId: number
    bookmark: boolean
  }>(),
  toggleCollectionBookmarkResponse: createAction(
    '@@api/collections/TOGGLE_COLLECTION_BOOKMARK_RESPONSE'
  )<{
    data?: Bookmark<'bookmark-create'> | null
  }>(),

  createImage: createAction('@@api/collection/CREATE_IMAGE')<ImageCreateReq>(),
  createImageResponse: createAction('@@api/collection/CREATE_IMAGE_RESPONSE')<{
    data: ImageType
    req: ImageCreateReq
  }>(),
  listImage: createAction('@@api/collection/LIST_IMAGE')<{ param: ImageListReq; next?: boolean }>(),
  listImageResponse: createAction('@@api/collection/LIST_IMAGE_RESPONSE')<{
    data: PaginationResponse<ImageType>
    req: ImageListReq
    next?: boolean
  }>()
}
export type CollectionActionsType = ActionType<typeof collections>

export const inputs = {
  retrieve: createAction('@@api/inputs/RETRIEVE')<number>(),
  retrieveResponse: createAction('@@api/inputs/RETRIEVE_RESPONSE')<InputImageSet>(),

  update: createAction('@@api/inputs/UPDATE')<InputImageSetUpdateReq>(),
  updateResponse: createAction('@@api/inputs/UPDATE_RESPONSE')<InputImageSet>(),

  moveCollection: createAction('@@api/inputs/MOVE_COLLECTION')<MoveCollectionReq>(),
  moveCollectionResponse: createAction('@@api/inputs/MOVE_COLLECTION_RESPONSE')<MoveCollectionRes>()
}
export type InputsActionType = ActionType<typeof inputs>

export const payment = {
  retrieveProducts: createAction('@@api/payment/RETRIEVE_PRODUCTS')(),
  retrieveProductsResponse: createAction(
    '@@api/payment/RETRIEVE_PRODUCTS_RESPONSE'
  )<ProductsResponse>(),
  retrieveChannels: createAction('@@api/payment/RETRIEVE_CHANNELS')(),
  retrieveChannelsResponse: createAction('@@api/payment/RETRIEVE_CHANNELS_RESPONSE')<
    PaginationResponse<Channel>
  >(),
  createPayment: createAction('@@api/payment/CREATE_PAYMENT')<PaymentReq>(),
  createPaymentResponse: createAction('@@api/payment/CREATE_PAYMENT_RESPONSE')<Payment>(),
  retrievePayment: createAction('@@api/payment/RETRIEVE_PAYMENT')<number>(),
  retrievePaymentResponse: createAction('@@api/payment/RETRIEVE_PAYMENT_RESPONSE')<{
    result?: Payment
    error?: ApiErrorBody | null
  }>(),
  listCreditChangeLog: createAction('@@api/payment/LIST_CREDIT_CHANGE_LOG')<{
    param: PaginationRequestParams | null
    reloadList?: boolean
  }>(),
  listCreditChangeLogResponse: createAction('@@api/payment/LIST_CREDIT_CHANGE_LOG_RESPONSE')<{
    data: ListCreditChangeLogResponse
    reloadList?: boolean
  }>(),
  retrieveCreditOverview: createAction('@@api/payment/RETRIEVE_FIRST_TRAINING_TIME_HISTORIES')(),
  retrieveCreditOverviewResponse: createAction(
    '@@api/payment/RETRIEVE_FIRST_TRAINING_TIME_HISTORIES_RESPONSE'
  )<CreditOverview>(),

  listSubscription: createAction('@@api/payment/LIST_SUBSCRIPTION')(),
  listSubscriptionResponse: createAction('@@api/payment/LIST_SUBSCRIPTION_RESPONSE')<
    PaginationResponse<Subscription>
  >(),

  createSubscription: createAction('@@api/payment/CREATE_SUBSCRIPTION')<SubscriptionCreateReq>(),
  createSubscriptionResponse: createAction(
    '@@api/payment/CREATE_SUBSCRIPTION_RESPONSE'
  )<Subscription>(),
  updateSubscription: createAction('@@api/payment/UPDATE_SUBSCRIPTION')<SubscriptionUpdateReq>(),
  updateSubscriptionResponse: createAction(
    '@@api/payment/UPDATE_SUBSCRIPTION_RESPONSE'
  )<Subscription>(),
  cancelSubscription: createAction('@@api/payment/CANCEL_SUBSCRIPTION')<number>(),
  cancelSubscriptionResponse: createAction('@@api/payment/CANCEL_SUBSCRIPTION_RESPONSE')(),

  retrieveDownloadImage: createAction('@@api/payment/RETRIEVE_DOWNLOAD_IMAGE')<number>(),
  retrieveDownloadImageResponse: createAction('@@api/payment/RETRIEVE_DOWNLOAD_IMAGE_RESPONSE')<{
    data: DownloadImage
    image: number
  }>(),
  listDownloadImage: createAction('@@api/payment/LIST_DOWNLOAD_IMAGE')<{
    param: PaginationRequestParams | null
    reloadList?: boolean
  }>(),
  listDownloadImageResponse: createAction('@@api/payment/LIST_DOWNLOAD_IMAGE_RESPONSE')<{
    data: PaginationResponse<DownloadImage>
    reloadList?: boolean
  }>(),
  createDownloadImage: createAction(
    '@@api/payment/CREATE_DOWNLOAD_IMAGE'
  )<CreateDownloadImageReq>(),
  createDownloadImageResponse: createAction('@@api/payment/CREATE_DOWNLOAD_IMAGE_RESPONSE')<{
    data: DownloadImage
    image: number
    originalImage?: number
  }>(),

  //Braintree & Paypal API
  braintreeGenerateToken: createAction('@@api/payment/BRAINTREE_GENERATE_TOKEN')(),
  braintreeGenerateTokenResponse: createAction(
    '@@api/payment/BRAINTREE_GENERATE_TOKEN_RESPONSE'
  )<BrainTreeTokenResponse>(),

  braintreeRemovePaymentMethod: createAction('@@api/payment/BRAINTREE_REMOVE_PAYMENT_METHOD')(),
  braintreeRemovePaymentMethodResponse: createAction(
    '@@api/payment/BRAINTREE_REMOVE_PAYMENT_METHOD_RESPONSE'
  )(),

  braintreeCreatePaymentMethod: createAction(
    '@@api/payment/BRAINTREE_CREATE_PAYMENT_METHOD'
  )<BraintreeCreatePaymentMethodReq>(),
  braintreeCreatePaymentMethodResponse: createAction(
    '@@api/payment/BRAINTREE_CREATE_PAYMENT_METHOD_RESPONSE'
  )<BraintreePaymentMethod>(),

  braintreeRetrievePaymentMethod: createAction('@@api/payment/BRAINTREE_RETRIEVE_PAYMENT_METHOD')(),
  braintreeRetrievePaymentMethodResponse: createAction(
    '@@api/payment/BRAINTREE_RETRIEVE_PAYMENT_METHOD_RESPONSE'
  )<BraintreePaymentMethod>()
}

export type PaymentActionType = ActionType<typeof payment>

export const engine = {
  listEngineConfig: createAction('@@api/engine/LIST_ENGINE_CONFIG')(),
  listEngineConfigResponse: createAction(
    '@@api/engine/LIST_ENGINE_CONFIG_RESPONSE'
  )<EngineConfigResponse>(),
  listClip: createAction('@@api/engine/LIST_CLIP')<{
    param: ListClipReq
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listClipResponse: createAction('@@api/engine/LIST_CLIP_RESPONSE')<{
    data: PaginationResponse<Clip>
    next?: boolean
    request: ListClipReq
  }>(),

  retrieveClip: createAction('@@api/engine/RETRIEVE_CLIP')<number>(),
  retrieveClipResponse: createAction('@@api/engine/RETRIEVE_CLIP_RESPONSE')<Clip>(),

  updateClip: createAction('@@api/engine/UPDATE_CLIP')<UpdateClipReq>(),
  updateClipResponse: createAction('@@api/engine/UPDATE_CLIP_RESPONSE')<Clip>(),

  createClip: createAction('@@api/engine/CREATE_CLIP')<CreateClipReq>(),
  createClipResponse: createAction('@@api/engine/CREATE_CLIP_RESPONSE')<Clip>(),

  deleteClip: createAction('@@api/engine/DELETE_CLIP')<DeleteClipRequest>(),
  deleteClipResponse: createAction('@@api/engine/DELETE_CLIP_RESPONSE')<DeleteClipRequest>(),

  copyClip: createAction('@@api/engine/COPY_CLIP')<UpdateClipReq>(),
  copyClipResponse: createAction('@@api/engine/COPY_CLIP_RESPONSE')<Clip>(),

  generateClipVideo: createAction('@@api/engine/GENERATE_CLIP_VIDEO')<GenerateClipReq>(),
  generateClipVideoResponse: createAction('@@api/engine/GENERATE_CLIP_VIDEO_RESPONSE')<number>(),

  generateClipPreview: createAction('@@api/engine/GENERATE_CLIP_PREVIEW')<number>(),
  generateClipPreviewResponse: createAction('@@api/engine/GENERATE_CLIP_PREVIEW_RESPONSE')()
}
export type EngineActionType = ActionType<typeof engine>

export const sketchToImage = {
  setCurrentSketchProject: createAction('@@api/sketchToImage/SET_CURRENT_SKETCH_PROJECT')<
    number | undefined
  >(),
  listSketchProject: createAction('@@api/sketchToImage/LIST_SKETCH_PROJECT')<{
    param: SketchProjectListReq | null
    next?: boolean
  }>(),
  listSketchProjectResponse: createAction('@@api/sketchToImage/LIST_SKETCH_PROJECT_RESPONSE')<{
    data: SketchProjectListResponse
    next?: boolean
    req?: SketchProjectListReq | null
  }>(),
  listSketchOutput: createAction('@@api/sketchToImage/LIST_SKETCH_OUTPUT')<{
    param: SketchOutputListReq | null
    next?: boolean
    loadingMessage?: string
  }>(),
  resetSketchQueueData: createAction('@@api/sketchToImage/RESET_SKETCH_QUEUE')<number>(),
  listSketchOutputResponse: createAction('@@api/sketchToImage/LIST_SKETCH_OUTPUT_RESPONSE')<{
    data: SketchOutputListResponse
    next?: boolean
    req?: SketchOutputListReq | null
  }>(),
  retrieveSketchProject: createAction('@@api/sketchToImage/RETRIEVE_SKETCH_PROJECT')<number>(),
  retrieveSketchProjectResponse: createAction(
    '@@api/sketchToImage/RETRIEVE_SKETCH_PROJECT_RESPONSE'
  )<SketchProject>(),
  createSketchProject: createAction(
    '@@api/sketchToImage/CREATE_SKETCH_PROJECT'
  )<SketchProjectCreateReq>(),
  createSketchProjectResponse: createAction(
    '@@api/sketchToImage/CREATE_SKETCH_PROJECT_RESPONSE'
  )<SketchProject>(),
  updateSketchProject: createAction('@@api/sketchToImage/UPDATE_SKETCH_PROJECT')<{
    data: SketchProjectUpdateReq
    silentUpdate?: boolean
  }>(),
  updateSketchProjectResponse: createAction(
    '@@api/sketchToImage/UPDATE_SKETCH_PROJECT_RESPONSE'
  )<SketchProject>(),
  deleteSketchProject: createAction('@@api/sketchToImage/DELETE_SKETCH_PROJECT')<number>(),
  deleteSketchProjectResponse: createAction(
    '@@api/mixImage/DELETE_SKETCH_PROJECT_RESPONSE'
  )<number>(),
  listSketchStyles: createAction('@@api/sketchToImage/LIST_SKETCH_STYLES')<SketchStylesListReq>(),
  listSketchStylesResponse: createAction(
    '@@api/sketchToImage/LIST_SKETCH_STYLES_RESPONSE'
  )<SketchStyleListResponse>(),
  listSketchGenre: createAction('@@api/sketchToImage/LIST_SKETCH_GENRE')<PageParams>(),
  listSketchGenreResponse: createAction(
    '@@api/sketchToImage/LIST_SKETCH_GENRE_RESPONSE'
  )<GenreListResponse>(),
  generateSketchProject: createAction(
    '@@api/sketchToImage/GENERATE_SKETCH_PROJECT'
  )<GenerateSketchProjectReq>(),
  generateSketchProjectResponse: createAction(
    '@@api/sketchToImage/GENERATE_SKETCH_PROJECT_RESPONSE'
  )<SketchProject>(),

  uploadSketch: createAction('@@api/sketchToImage/UPLOAD_SKETCH')<UploadSketchRequest>(),
  uploadSketchResponse: createAction('@@api/sketchToImage/UPLOAD_SKETCH_RESPONSE')<SketchProject>(),

  updateThreshold: createAction('@@api/sketchToImage/UPDATE_THRESHOLD')<UpdateThresholdRequest>(),
  updateThresholdResponse: createAction(
    '@@api/sketchToImage/UPDATE_THRESHOLD_RESPONSE'
  )<SketchProject>(),

  removeSketch: createAction('@@api/sketchToImage/REMOVE_SKETCH')<RemoveSketchRequest>(),
  removeSketchResponse: createAction('@@api/sketchToImage/REMOVE_SKETCH_RESPONSE')<SketchProject>(),
  deleteSketchOutput: createAction('@@api/sketchToImage/DELETE_SKETCH_OUTPUT')<number>(),
  deleteSketchOutputResponse: createAction(
    '@@api/sketchToImage/DELETE_SKETCH_OUTPUT_RESPONSE'
  )<number>()
}
export type SketchToImageActionType = ActionType<typeof sketchToImage>

export const social = {
  retrieveUserByAlias: createAction('@@api/users/RETRIEVE_BY_ALIAS')<{ alias: string }>(),
  retrieveUserByAliasResponse: createAction('@@api/users/RETRIEVE_BY_ALIAS_RESPONSE')<User>(),

  listBannerImages: createAction('@@api/social/LIST_BANNER_IMAGES')(),
  listBannerImagesResponse: createAction('@@api/social/LIST_BANNER_IMAGES_RESPONSE')<{
    data: PaginationResponse<BannerImage>
  }>(),
  setCurrentPost: createAction('@@api/social/SET_CURRENT_POST')<number | undefined>(),
  listPost: createAction('@@api/social/LIST_POST')<{
    param: PostListReq | null
    reloadList?: boolean
  }>(),
  listPostResponse: createAction('@@api/social/LIST_POST_RESPONSE')<{
    data: PostListResponse
    reloadList?: boolean
    req?: PostListReq | null
  }>(),
  listFeaturedPost: createAction('@@api/social/LIST_FEATURED_POST')(),
  listFeaturedPostResponse: createAction('@@api/social/LIST_FEATURED_POST_RESPONSE')<{
    data: PostListResponse
  }>(),
  retrievePost: createAction('@@api/social/RETRIEVE_POST')<number>(),
  retrievePostResponse: createAction('@@api/social/RETRIEVE_POST_RESPONSE')<Post>(),
  createPost: createAction('@@api/social/CREATE_POST')<PostCreateReq>(),
  createPostResponse: createAction('@@api/social/CREATE_POST_RESPONSE')<Post>(),
  updatePost: createAction('@@api/social/UPDATE_POST')<PostUpdateReq>(),
  updatePostResponse: createAction('@@api/social/UPDATE_POST_RESPONSE')<Post>(),

  setCurrentUserProfile: createAction('@@api/social/SET_CURRENT_USER_PROFILE')<{
    userId: string
  }>(),

  listUserPost: createAction('@@api/social/LIST_USER_POST')<{
    param: PostListReq | null
    reloadList?: boolean
  }>(),

  listUserPostResponse: createAction('@@api/social/LIST_USER_POST_RESPONSE')<{
    data: PostListResponse
    reloadList?: boolean
    req?: PostListReq | null
  }>(),

  listUserProject: createAction('@@api/social/LIST_USER_PROJECT')<{
    param: TrainProjectListReq | null
    reloadList?: boolean
  }>(),
  listUserProjectResponse: createAction('@@api/social/LIST_USER_PROJECT_RESPONSE')<{
    data: PaginationResponse<TrainProject>
    reloadList?: boolean
    req?: TrainProjectListReq | null
  }>(),

  listUserCollection: createAction('@@api/social/LIST_USER_COLLECTION')<{
    param: CollectionListReq | null
    reloadList?: boolean
  }>(),
  listUserCollectionResponse: createAction('@@api/social/LIST_USER_COLLECTION_RESPONSE')<{
    data: PaginationResponse<Collection>
    reloadList?: boolean
    req?: CollectionListReq | null
  }>(),

  deletePost: createAction('@@api/social/DELETE_POST')<number>(),
  deletePostResponse: createAction('@@api/social/DELETE_POST_RESPONSE')<number>(),
  toggleClap: createAction('@@api/social/TOGGLE_CLAP')<ClapReq>(),
  toggleClapInstantResponse: createAction('@@api/social/TOGGLE_CLAP_INSTANT_RESPONSE')<{
    req: ClapReq
    clapType: 'remove' | 'add'
  }>(),
  toggleClapResponse: createAction('@@api/social/TOGGLE_CLAP_RESPONSE')<{
    req: ClapReq
    data: ClapResponse
    clapType: 'remove' | 'add'
  }>(),

  togglePostBookmark: createAction('@@api/social/TOGGLE_POST_BOOKMARK')<{ postId: number }>(),
  togglePostBookmarkInstantResponse: createAction(
    '@@api/social/TOGGLE_POST_BOOKMARK_INSTANT_RESPONSE'
  )<{
    postId: number
    bookmark: boolean
  }>(),
  togglePostBookmarkResponse: createAction('@@api/social/TOGGLE_POST_BOOKMARK_RESPONSE')<{
    data?: Bookmark<'bookmark-create'> | null
  }>(),
  createComment: createAction('@@api/social/CREATE_COMMENT')<CommentCreateReq>(),
  createCommentResponse: createAction('@@api/social/CREATE_COMMENT_RESPONSE')<Comment>(),
  createThread: createAction('@@api/social/CREATE_THREAD')<ThreadCreateReq>(),
  createThreadResponse: createAction('@@api/social/CREATE_THREAD_RESPONSE')<Thread>(),
  listThread: createAction('@@api/social/LIST_THREAD')<{
    param: ThreadListReq | null
    reloadList?: boolean
  }>(),
  listThreadResponse: createAction('@@api/social/LIST_THREAD_RESPONSE')<{
    data: ThreadListResponse
    reloadList?: boolean
    req?: ThreadListReq | null
  }>(),
  listComment: createAction('@@api/social/LIST_COMMENT')<{
    param: CommentListReq | null
    reloadList?: boolean
  }>(),
  listCommentResponse: createAction('@@api/social/LIST_COMMENT_RESPONSE')<{
    data: CommentListResponse
    reloadList?: boolean
    req?: CommentListReq | null
  }>(),
  resetNewThreadHighlight: createAction('@@api/social/RESET_NEW_THREAD_HIGHLIGHT')<number>(),
  resetNewCommentHighlight: createAction('@@api/social/RESET_NEW_COMMENT_HIGHLIGHT')<number>()
}
export type SocialActionType = ActionType<typeof social>

export const mixImage = {
  setCurrentMixProject: createAction('@@api/mixImage/SET_CURRENT_MIX_PROJECT')<
    number | undefined
  >(),
  retrieveSpaceImage: createAction('@@api/mixImage/RETRIEVE_SPACE_IMAGE')<SpaceImageRequest>(),
  setSpaceImageFetchState: createAction(
    '@@api/mixImage/SET_SPACE_IMAGE_FETCH_STATE'
  )<SpaceImageRequest>(),
  retrieveSpaceImageResponse: createAction('@@api/mixImage/RETRIEVE_SPACE_IMAGE_RESPONSE')<{
    data: SpaceImage
    req: SpaceImageRequest
  }>(),

  retrieveOriginImage: createAction('@@api/mixImage/RETRIEVE_ORIGIN_IMAGE')<OriginImageRequest>(),
  retrieveOriginImageResponse: createAction('@@api/mixImage/RETRIEVE_ORIGIN_IMAGE_RESPONSE')<{
    data: UserImage
    req: OriginImageRequest
  }>(),
  listRandomImages: createAction('@@api/mixImage/LIST_RANDOM_IMAGES')<{
    next?: boolean
    project?: number
  }>(),
  listRandomImagesResponse: createAction('@@api/mixImage/LIST_RANDOM_IMAGES_RESPONSE')<{
    data: UserImage[]
    next?: boolean
    project?: number
  }>(),
  resetMixImageQueueData: createAction('@@api/mixImage/RESET_MIX_IMAGE_QUEUE')<number>(),
  retrieveMixImage: createAction('@@api/mixImage/RETRIEVE_MIX_IMAGE')<number>(),
  retrieveMixImageResponse: createAction(
    '@@api/mixImage/RETRIEVE_MIX_IMAGE_RESPONSE'
  )<MixImageProject>(),
  deleteMixImage: createAction('@@api/mixImage/DELETE_MIX_IMAGE')<number>(),
  deleteMixImageResponse: createAction('@@api/mixImage/DELETE_MIX_IMAGE_RESPONSE')<number>(),

  createMixImage: createAction('@@api/mixImage/CREATE_MIX_IMAGE')<MixImageCreateReq>(),
  createMixImageResponse: createAction(
    '@@api/mixImage/CREATE_MIX_IMAGE_RESPONSE'
  )<MixImageProject>(),
  updateMixImage: createAction('@@api/mixImage/UPDATE_MIX_IMAGE')<MixImageUpdateReq>(),
  updateMixImageResponse: createAction(
    '@@api/mixImage/UPDATE_MIX_IMAGE_RESPONSE'
  )<MixImageProject>(),

  listMixImage: createAction('@@api/mixImage/LIST_MIX_IMAGE')<{
    param: PaginationRequestParams
    next?: boolean
  }>(),
  listMixImageResponse: createAction('@@api/mixImage/LIST_MIX_IMAGE_RESPONSE')<{
    data: MixImageListResponse
    next?: boolean
  }>(),

  listMixImageGenre: createAction('@@api/mixImage/LIST_MIX_IMAGE_GENRE')<PageParams>(),
  listMixImageGenreResponse: createAction(
    '@@api/mixImage/LIST_MIX_IMAGE_GENRE_RESPONSE'
  )<MixImageGenreListResponse>(),

  generateMixImage: createAction('@@api/mixImage/GENERATE_MIX_IMAGES')<MixImageGenerateReq>(),
  generateMixImageResponse: createAction(
    '@@api/mixImage/GENERATE_MIX_IMAGES_PROJECT_RESPONSE'
  )<MixImageProject>(),

  generateMixImagePreview: createAction(
    '@@api/mixImage/GENERATE_MIX_IMAGE_PREVIEW'
  )<MixImageGeneratePreviewReq>(),
  generateMixImagePreviewResponse: createAction(
    '@@api/mixImage/GENERATE_MIX_IMAGE_PREVIEW_RESPONSE'
  )<MixImageProject>(),
  updateThumbnail: createAction('@@api/mixImage/UPDATE_THUMBNAIL')<number>(),
  updateThumbnailResponse: createAction('@@api/mixImage/UPDATE_THUMBNAIL_RESPONSE')<number>(),
  retrieveUploadImage: createAction('@@api/mixImage/RETRIEVE_UPLOAD_IMAGE')<number>(),
  retrieveUploadImageResponse: createAction(
    '@@api/mixImage/RETRIEVE_UPLOAD_IMAGE_RESPONSE'
  )<UploadImage>(),
  deleteUploadImage: createAction('@@api/mixImage/DELETE_UPLOAD_IMAGE')<number>(),
  deleteUploadImageResponse: createAction('@@api/mixImage/DELETE_UPLOAD_IMAGE_RESPONSE')<number>(),
  createUploadImage: createAction('@@api/mixImage/CREATE_UPLOAD_IMAGE')<CreateUploadImageReq>(),
  createUploadImageResponse: createAction('@@api/mixImage/CREATE_UPLOAD_IMAGE_RESPONSE')<{
    data: UploadImage
  }>(),
  listUploadImage: createAction('@@api/mixImage/LIST_UPLOAD_IMAGE')<{
    param: PageParams
    next?: boolean
    silentUpdate?: boolean
  }>(),
  listUploadImageResponse: createAction('@@api/mixImage/LIST_UPLOAD_IMAGE_RESPONSE')<{
    data: PaginationResponse<UploadImage>
    next?: boolean
  }>()
}

export type MixImageActionType = ActionType<typeof mixImage>

export const proArtFilter = {
  setCurrentProArtFilterProject: createAction(
    '@@api/proArtFilter/SET_CURRENT_PRO-ART-FILTER_PROJECT'
  )<number | undefined>(),
  listProArtFilterGenres: createAction(
    '@@api/proArtFilter/LIST_PRO-ART-FILTER_GENRES'
  )<PageParams>(),
  listProArtFilterGenresResponse: createAction(
    '@@api/proArtFilter/LIST_PRO-ART-FILTER_GENRES_RESPONSE'
  )<ProArtFilterGenreListResponse>(),
  retrieveProArtFilter: createAction('@@api/proArtFilter/RETRIEVE_PRO-ART-FILTER')<number>(),
  retrieveProArtFilterResponse: createAction(
    '@@api/proArtFilter/RETRIEVE_PRO-ART-FILTER_RESPONSE'
  )<ProArtFilterProject>(),

  listProArtFilterStyles: createAction(
    '@@api/proArtFilter/LIST_PRO-ART-FILTER_STYLES'
  )<ProArtFilterStyleListReq>(),
  listProArtFilterStylesResponse: createAction(
    '@@api/proArtFilter/LIST_PRO-ART-FILTER_STYLES_RESPONSE'
  )<ProArtFilterStyleListResponse>(),
  listProArtFilter: createAction('@@api/proArtFilter/LIST_PRO-ART-FILTER')<{
    param: PaginationRequestParams
    next?: boolean
  }>(),
  listProArtFilterResponse: createAction('@@api/proArtFilter/LIST_PRO-ART-FILTER_RESPONSE')<{
    data: ProArtFilterListResponse
    next?: boolean
  }>(),
  resetProArtFilterQueueData: createAction(
    '@@api/proArtFilter/RESET_PRO-ART-FILTER_QUEUE'
  )<number>(),
  createProArtFilter: createAction(
    '@@api/proArtFilter/CREATE_PRO-ART-FILTER'
  )<ProArtFilterCreateReq>(),
  createProArtFilterResponse: createAction(
    '@@api/proArtFilter/CREATE_PRO-ART-FILTER_RESPONSE'
  )<ProArtFilterProject>(),
  uploadProArtFilterContent: createAction(
    '@@api/proArtFilter/UPLOAD_PRO-ART-FILTER_CONTENT'
  )<ProArtFilterUploadContentReq>(),
  uploadProArtFilterContentResponse: createAction(
    '@@api/proArtFilter/UPLOAD_PRO-ART-FILTER_CONTENT_RESPONSE'
  )<ProArtFilterProject>(),
  updateProArtFilter: createAction(
    '@@api/proArtFilter/UPDATE_PRO-ART-FILTER'
  )<ProArtFilterUpdateReq>(),
  updateProArtFilterResponse: createAction(
    '@@api/proArtFilter/UPDATE_PRO-ART-FILTER_RESPONSE'
  )<ProArtFilterProject>(),
  generateProArtFilter: createAction(
    '@@api/proArtFilter/GENERATE_PRO-ART-FILTER'
  )<ProArtFilterGenerateReq>(),
  generateProArtFilterResponse: createAction(
    '@@api/proArtFilter/GENERATE_PRO-ART-FILTER_RESPONSE'
  )<ProArtFilterProject>(),
  deleteProArtFilter: createAction('@@api/proArtFilter/DELETE_PRO-ART-FILTER')<number>(),
  deleteProArtFilterResponse: createAction(
    '@@api/proArtFilter/DELETE_PRO-ART-FILTER_RESPONSE'
  )<number>(),
  deleteProArtFilterOutput: createAction(
    '@@api/proArtFilter/DELETE_PRO-ART-FILTER_OUTPUT'
  )<number>(),
  deleteProArtFilterOutputResponse: createAction(
    '@@api/proArtFilter/DELETE_PRO-ART-FILTER_OUTPUT_RESPONSE'
  )<number>(),
  cleanOutputs: createAction('@@api/proArtFilter/CLEAN_OUTPUTS')<number>(),
  cleanOutputsResponse: createAction('@@api/proArtFilter/CLEAN_OUTPUTS_RESPONSE')<number>(),
  listProArtFilterOutput: createAction('@@api/proArtFilter/LIST_PRO-ART-FILTER_OUTPUT')<{
    param: ProArtFilterOutputListReq | null
    next?: boolean
    loadingMessage?: string
  }>(),
  listProArtFilterOutputResponse: createAction(
    '@@api/proArtFilter/LIST_PRO-ART-FILTER_OUTPUT_RESPONSE'
  )<{
    data: ProArtFilterOutputListResponse
    next?: boolean
    req?: ProArtFilterOutputListReq | null
  }>(),
  listProArtFilterBanner: createAction('@@api/proArtFilter/LIST_PRO-ART-FILTER_BANNER')(),
  listProArtFilterBannerResponse: createAction(
    '@@api/proArtFilter/LIST_PRO-ART-FILTER_BANNER_RESPONSE'
  )<{
    data: PaginationResponse<ProArtFilterBanner>
  }>()
}

export type ProArtFilterActionType = ActionType<typeof proArtFilter>

export const textToImage = {
  setCurrentTextToImageProject: createAction('@@api/textToImage/SET_CURRENT-TEXT-IMAGE_PROJECT')<
    number | undefined
  >(),
  listTIProject: createAction('@@api/textToImage/LIST_TI_PROJECT')<{
    param: TIProjectListReq | null
    next?: boolean
  }>(),
  listTIProjectResponse: createAction('@@api/textToImage/LIST_TI_PROJECT_RESPONSE')<{
    data: TIProjectListResponse
    next?: boolean
    req?: TIProjectListReq | null
  }>(),
  retrieveTIProject: createAction('@@api/textToImage/RETRIEVE_TI_PROJECT')<number>(),
  retrieveTIProjectResponse: createAction(
    '@@api/textToImage/RETRIEVE_TI_PROJECT_RESPONSE'
  )<TIProject>(),
  createTIProject: createAction('@@api/textToImage/CREATE_TI_PROJECT')<TIProjectCreateReq>(),
  createTIProjectResponse: createAction(
    '@@api/textToImage/CREATE_TI_PROJECT_RESPONSE'
  )<TIProject>(),

  updateTIProject: createAction('@@api/textToImage/UPDATE_TI_PROJECT')<TIProjectUpdateReq>(),
  updateTIProjectResponse: createAction(
    '@@api/textToImage/UPDATE_TI_PROJECT_RESPONSE'
  )<TIProject>(),

  deleteTIProject: createAction('@@api/textToImage/DELETE_TI_PROJECT')<number>(),
  deleteTIProjectResponse: createAction('@@api/textToImage/DELETE_TI_PROJECT_RESPONSE')<number>(),

  generateTIProject: createAction('@@api/textToImage/GENERATE_TI_PROJECT')<TIProjectGenerateReq>(),
  generateTIProjectResponse: createAction(
    '@@api/textToImage/GENERATE_TI_PROJECT_RESPONSE'
  )<TIProject>(),

  listTIProjectOutput: createAction('@@api/textToImage/LIST_TI_PROJECT_OUTPUT')<{
    param: TIProjectOutputListReq | null
    next?: boolean
    loadingMessage?: string
  }>(),
  listTIProjectOutputResponse: createAction('@@api/textToImage/LIST_TI_PROJECT_OUTPUT_RESPONSE')<{
    data: TIProjectOutputListResponse
    next?: boolean
    req?: TIProjectOutputListReq | null
  }>(),

  deleteTIProjectOutput: createAction('@@api/textToImage/DELETE_TI_PROJECT_OUTPUT')<number>(),
  deleteTIProjectOutputResponse: createAction(
    '@@api/textToImage/DELETE_TI_PROJECT_OUTPUT_RESPONSE'
  )<number>(),

  deleteAllTIProjectOutput: createAction(
    '@@api/textToImage/DELETE_ALL_TI_PROJECT_OUTPUT'
  )<number>(),
  deleteAllTIProjectOutputResponse: createAction(
    '@@api/textToImage/DELETE_ALL_TI_PROJECT_OUTPUT_RESPONSE'
  )<number>(),
  resetTextToImageQueueData: createAction('@@api/textToImage/RESET_TEXT_TO_IMAGE_QUEUE')<number>()
}

export type TextToImageActionType = ActionType<typeof textToImage>

export const textToVideo = {
  setCurrentTextVideoProject: createAction('@@api/textToVideo/SET_CURRENT-TEXT-VIDEO_PROJECT')<
    number | undefined
  >(),
  listTVProject: createAction('@@api/textToVideo/LIST_TV_PROJECT')<{
    param: TVProjectListReq | null
    next?: boolean
  }>(),
  listTVProjectResponse: createAction('@@api/textToVideo/LIST_TV_PROJECT_RESPONSE')<{
    data: TVProjectListResponse
    next?: boolean
    req?: TVProjectListReq | null
  }>(),
  retrieveTVProject: createAction('@@api/textToVideo/RETRIEVE_TV_PROJECT')<number>(),
  retrieveTVProjectResponse: createAction(
    '@@api/textToVideo/RETRIEVE_TV_PROJECT_RESPONSE'
  )<TVProject>(),
  createTVProject: createAction('@@api/textToVideo/CREATE_TV_PROJECT')<TVProjectCreateReq>(),
  createTVProjectResponse: createAction(
    '@@api/textToVideo/CREATE_TV_PROJECT_RESPONSE'
  )<TVProject>(),

  updateTVProject: createAction('@@api/textToVideo/UPDATE_TV_PROJECT')<TVProjectUpdateReq>(),
  updateTVProjectResponse: createAction(
    '@@api/textToVideo/UPDATE_TV_PROJECT_RESPONSE'
  )<TVProject>(),

  deleteTVProject: createAction('@@api/textToVideo/DELETE_TV_PROJECT')<number>(),
  deleteTVProjectResponse: createAction('@@api/textToVideo/DELETE_TV_PROJECT_RESPONSE')<number>(),

  generateTVProject: createAction('@@api/textToVideo/GENERATE_TV_PROJECT')<TVProjectGenerateReq>(),
  generateTVProjectResponse: createAction(
    '@@api/textToVideo/GENERATE_TV_PROJECT_RESPONSE'
  )<TVProject>(),

  listTVProjectOutput: createAction('@@api/textToVideo/LIST_TV_PROJECT_OUTPUT')<{
    param: TVProjectOutputListReq | null
    next?: boolean
    loadingMessage?: string
  }>(),
  listTVProjectOutputResponse: createAction('@@api/textToVideo/LIST_TV_PROJECT_OUTPUT_RESPONSE')<{
    data: TVProjectOutputListResponse
    next?: boolean
    req?: TVProjectOutputListReq | null
  }>(),

  deleteTVProjectOutput: createAction('@@api/textToVideo/DELETE_TV_PROJECT_OUTPUT')<number>(),
  deleteTVProjectOutputResponse: createAction(
    '@@api/textToVideo/DELETE_TV_PROJECT_OUTPUT_RESPONSE'
  )<number>(),

  deleteAllTVProjectOutput: createAction(
    '@@api/textToVideo/DELETE_ALL_TV_PROJECT_OUTPUT'
  )<number>(),
  deleteAllTVProjectOutputResponse: createAction(
    '@@api/textToVideo/DELETE_ALL_TV_PROJECT_OUTPUT_RESPONSE'
  )<number>(),
  resetTextToVideoQueueData: createAction('@@api/textToVideo/RESET_TEXT_TO_VIDEO_QUEUE')<number>()
}

export type TextToVideoActionType = ActionType<typeof textToVideo>

export const genericApp = {
  setCurrentGenericAppProject: createAction('@@api/genericApp/SET_CURRENT_GENERIC_APP_PROJECT')<
    number | undefined
  >(),
  listGenericApp: createAction('@@api/genericApp/LIST_GENERIC_APP')<{
    param: PaginationRequestParams | null
    next?: boolean
  }>(),
  listGenericAppResponse: createAction('@@api/genericApp/LIST_GENERIC_APP_RESPONSE')<{
    data: GenericAppListResponse
    next?: boolean
    req?: PaginationRequestParams | null
  }>(),
  retrieveGenericApp: createAction('@@api/genericApp/RETRIEVE_GENERIC_APP')<number>(),
  retrieveGenericAppResponse: createAction(
    '@@api/genericApp/RETRIEVE_GENERIC_APP_RESPONSE'
  )<GenericApp>(),

  listGenericAppProject: createAction('@@api/genericApp/LIST_GENERIC_APP_PROJECT')<{
    param: PaginationRequestParams | null
    next?: boolean
  }>(),
  listGenericAppProjectResponse: createAction(
    '@@api/genericApp/LIST_GENERIC_APP_PROJECT_RESPONSE'
  )<{
    data: GenericAppProjectListResponse
    next?: boolean
    req?: PaginationRequestParams | null
  }>(),
  retrieveGenericAppProject: createAction(
    '@@api/genericApp/RETRIEVE_GENERIC_APP_PROJECT'
  )<number>(),
  retrieveGenericAppProjectResponse: createAction(
    '@@api/genericApp/RETRIEVE_GENERIC_APP_PROJECT_RESPONSE'
  )<GenericAppProject>(),
  createGenericAppProject: createAction(
    '@@api/genericApp/CREATE_GENERIC_APP_PROJECT'
  )<GenericAppProjectCreateReq>(),
  createGenericAppProjectResponse: createAction(
    '@@api/genericApp/CREATE_GENERIC_APP_PROJECT_RESPONSE'
  )<GenericAppProject>(),

  updateGenericAppProject: createAction(
    '@@api/genericApp/UPDATE_GENERIC_APP_PROJECT'
  )<GenericAppProjectUpdateReq>(),
  updateGenericAppProjectResponse: createAction(
    '@@api/genericApp/UPDATE_GENERIC_APP_PROJECT_RESPONSE'
  )<GenericAppProject>(),

  deleteGenericAppProject: createAction('@@api/genericApp/DELETE_GENERIC_APP_PROJECT')<number>(),
  deleteGenericAppProjectResponse: createAction(
    '@@api/genericApp/DELETE_GENERIC_APP_PROJECT_RESPONSE'
  )<number>(),

  cleanOutputs: createAction('@@api/genericApp/CLEAN_OUTPUTS')<number>(),
  cleanOutputsResponse: createAction('@@api/genericApp/CLEAN_OUTPUTS_RESPONSE')<number>(),

  listGenericAppOutput: createAction('@@api/genericApp/LIST_GENERIC_APP_OUTPUT')<{
    param: GenericAppOutputImageListReq | null
    next?: boolean
    loadingMessage?: string
  }>(),
  listGenericAppOutputResponse: createAction('@@api/genericApp/LIST_GENERIC_APP_OUTPUT_RESPONSE')<{
    data: GenericAppOutputImageListResponse
    next?: boolean
    req?: GenericAppOutputImageListReq | null
  }>(),

  deleteGenericAppProjectOutput: createAction(
    '@@api/genericApp/DELETE_GENERIC_APP_PROJECT_OUTPUT'
  )<number>(),
  deleteGenericAppProjectOutputResponse: createAction(
    '@@api/genericApp/DELETE_GENERIC_APP_PROJECT_OUTPUT_RESPONSE'
  )<number>(),
  deleteAllGenericAppProjectOutput: createAction(
    '@@api/genericApp/DELETE_ALL_GENERIC_APP_PROJECT_OUTPUT'
  )<number>(),
  deleteAllGenericAppProjectOutputResponse: createAction(
    '@@api/genericApp/DELETE_ALL_GENERIC_APP_PROJECT_OUTPUT_RESPONSE'
  )<number>()
}
export type GenericAppActionType = ActionType<typeof genericApp>

export const artMine = {
  listUserArtmineProjects: createAction('@@api/artMine/LIST_USER_ARTMINE_PROJECT')<{
    param: ListMineProjectReq
    next?: boolean
  }>(),
  listUserArtmineProjectsResponse: createAction(
    '@@api/artMine/LIST_USER_ARTMINE_PROJECT_RESPONSE'
  )<{
    req: ListMineProjectReq
    data: PaginationResponse<MineProject>
    next?: boolean
  }>(),
  createArtMineProject: createAction('@@api/artMine/CREATE_MINE_PROJECT')<CreateMineProjectReq>(),
  createArtMineProjectResponse: createAction(
    '@@api/artMine/CREATE_MINE_PROJECT_RESPONSE'
  )<MineProject>(),
  retrieveConfig: createAction('@@api/artMine/RETRIEVE_CONFIG')(),
  retrieveConfigResponse: createAction('@@api/artMine/RETRIEVE_CONFIG_RESPONSE')<ArtMineConfig>(),
  retrieveSignature: createAction('@@api/artMine/RETRIEVE_SIGNATURE')<number>(),
  retrieveSignatureResponse: createAction('@@api/artMine/RETRIEVE_SIGNATURE_RESPONSE')<{
    signature: Signature
    projectId: number
  }>(),
  setAccount: createAction('@@page/App/SET_ACCOUNT')<CryptoStateType['accounts']>(),
  retrieveProject: createAction('@@api/artMine/RETRIEVE_PROJECT')<number>(),
  retrieveProjectResponse: createAction('@@api/artMine/RETRIEVE_PROJECT_RESPONSE')<MineProject>(),
  deleteArtMineProject: createAction('@@api/artMine/DELETE_ARTMINE_PROJECT')<number>(),
  deleteArtMineProjectResponse: createAction(
    '@@api/artMine/DELETE_ARTMINE_PROJECT_RESPONSE'
  )<number>(),
  startTransaction: createAction('@@api/artMine/START_TRANSACTION')<number>(),
  startTransactionResponse: createAction('@@api/artMine/START_TRANSACTION_RESPONSE')<MineProject>()
}

export type ArtMineActionType = ActionType<typeof artMine>

export const sketchTextToImage = {
  setCurrentSketchTextToImageProject: createAction(
    '@@api/sketchTextToImage/SET_CURRENT-SKETCH_TEXT-IMAGE_PROJECT'
  )<number | undefined>(),
  listSTIProject: createAction('@@api/sketchTextToImage/LIST_STI_PROJECT')<{
    param: PaginationRequestParams | null
    next?: boolean
  }>(),
  listSTIProjectResponse: createAction('@@api/sketchTextToImage/LIST_STI_PROJECT_RESPONSE')<{
    data: PaginationResponse<SketchTextProject>
    next?: boolean
    req?: PaginationRequestParams | null
  }>(),
  retrieveSTIProject: createAction('@@api/sketchTextToImage/RETRIEVE_STI_PROJECT')<number>(),
  retrieveSTIProjectResponse: createAction(
    '@@api/sketchTextToImage/RETRIEVE_STI_PROJECT_RESPONSE'
  )<SketchTextProject>(),
  createSTIProject: createAction(
    '@@api/sketchTextToImage/CREATE_STI_PROJECT'
  )<SketchTextCreateReq>(),
  createSTIProjectResponse: createAction(
    '@@api/sketchTextToImage/CREATE_STI_PROJECT_RESPONSE'
  )<SketchTextProject>(),

  updateSTIProject: createAction('@@api/sketchTextToImage/UPDATE_STI_PROJECT')<{
    data: SketchTextUpdateReq
    silentUpdate?: boolean
  }>(),
  updateSTIProjectResponse: createAction(
    '@@api/sketchTextToImage/UPDATE_STI_PROJECT_RESPONSE'
  )<SketchTextProject>(),

  deleteSTIProject: createAction('@@api/sketchTextToImage/DELETE_STI_PROJECT')<number>(),
  deleteSTIProjectResponse: createAction(
    '@@api/sketchTextToImage/DELETE_STI_PROJECT_RESPONSE'
  )<number>(),

  generateSTIProject: createAction(
    '@@api/sketchTextToImage/GENERATE_STI_PROJECT'
  )<SketchTextGenerateReq>(),
  generateSTIProjectResponse: createAction(
    '@@api/sketchTextToImage/GENERATE_STI_PROJECT_RESPONSE'
  )<SketchTextProject>(),

  listSTIProjectOutput: createAction('@@api/sketchTextToImage/LIST_STI_PROJECT_OUTPUT')<{
    param: SketchTextListOutputReq | null
    next?: boolean
    loadingMessage?: string
  }>(),
  listSTIProjectOutputResponse: createAction(
    '@@api/sketchTextToImage/LIST_STI_PROJECT_OUTPUT_RESPONSE'
  )<{
    data: PaginationResponse<SketchTextOutputImage>
    next?: boolean
    req?: SketchTextListOutputReq | null
  }>(),
  deleteSTIProjectOutput: createAction(
    '@@api/sketchTextToImage/DELETE_STI_PROJECT_OUTPUT'
  )<number>(),
  deleteSTIProjectOutputResponse: createAction(
    '@@api/sketchTextToImage/DELETE_STI_PROJECT_OUTPUT_RESPONSE'
  )<number>(),
  resetSketchTextToImageQueueData: createAction(
    '@@api/sketchTextToImage/RESET_TEXT_TO_IMAGE_QUEUE'
  )<number>(),
  uploadSketch: createAction('@@api/sketchTextToImage/UPLOAD_SKETCH')<SketchTextUploadReq>(),
  uploadSketchResponse: createAction(
    '@@api/sketchTextToImage/UPLOAD_SKETCH_RESPONSE'
  )<SketchTextProject>(),

  updateThreshold: createAction(
    '@@api/sketchTextToImage/UPDATE_THRESHOLD'
  )<SketchTextUpdateThresholdReq>(),
  updateThresholdResponse: createAction(
    '@@api/sketchTextToImage/UPDATE_THRESHOLD_RESPONSE'
  )<SketchTextProject>(),

  removeSketch: createAction('@@api/sketchTextToImage/REMOVE_SKETCH')<SketchTextRemoveSketchReq>(),
  removeSketchResponse: createAction(
    '@@api/sketchTextToImage/REMOVE_SKETCH_RESPONSE'
  )<SketchTextProject>(),

  cleanOutputs: createAction('@@api/sketchTextToImage/CLEAN_OUTPUTS')<number>(),
  cleanOutputsResponse: createAction('@@api/sketchTextToImage/CLEAN_OUTPUTS_RESPONSE')<number>(),
  listSTIGenre: createAction('@@api/sketchTextToImage/LIST_STI_GENRE')<PageParams>(),
  listSTIGenreResponse: createAction('@@api/sketchToImage/LIST_STI_GENRE_RESPONSE')<
    PaginationResponse<SketchTextGenre>
  >(),
  listSTIProcess: createAction('@@api/sketchTextToImage/LIST_STI_PROCESS')<PageParams>(),
  listSTIProcessResponse: createAction('@@api/sketchToImage/LIST_STI_PROCESS_RESPONSE')<
    PaginationResponse<SketchTextProcess>
  >(),
  listSTIModel: createAction('@@api/sketchTextToImage/LIST_STI_MODEL')<PageParams>(),
  listSTIModelResponse: createAction('@@api/sketchToImage/LIST_STI_MODEL_RESPONSE')<
    PaginationResponse<SketchTextModel>
  >(),
  listSTIStyles: createAction('@@api/sketchTextToImage/LIST_STI_STYLES')<PageParams>(),
  listSTIStylesResponse: createAction('@@api/sketchToImage/LIST_STI_STYLES_RESPONSE')<
    PaginationResponse<SketchTextStyles>
  >()
}

export type SketchTextToImageActionType = ActionType<typeof sketchTextToImage>

export const apiActions = {
  artMine,
  users,
  projects,
  collections,
  imageEnhancement,
  inputs,
  payment,
  engine,
  sketchToImage,
  social,
  mixImage,
  proArtFilter,
  textToImage,
  textToVideo,
  genericApp,
  sketchTextToImage
}
