import { Collection, ProArtFilterStyle, UserVideo } from './ApiModels'

import ImgScrambledSquare1 from 'assets/images/dummy/scrambled/square/scrambled-square-1.jpg'
import ImgScrambledSquare2 from 'assets/images/dummy/scrambled/square/scrambled-square-2.jpg'
import ImgScrambledSquare3 from 'assets/images/dummy/scrambled/square/scrambled-square-3.jpg'
import ImgScrambledSquare4 from 'assets/images/dummy/scrambled/square/scrambled-square-4.jpg'
import ImgScrambledSquare5 from 'assets/images/dummy/scrambled/square/scrambled-square-5.jpg'
import ImgScrambledSquare6 from 'assets/images/dummy/scrambled/square/scrambled-square-6.jpg'
import ImgScrambledSquare7 from 'assets/images/dummy/scrambled/square/scrambled-square-7.jpg'
import ImgScrambledSquare8 from 'assets/images/dummy/scrambled/square/scrambled-square-8.jpg'
import ImgScrambledSquare9 from 'assets/images/dummy/scrambled/square/scrambled-square-9.jpg'
import ImgScrambledSquare10 from 'assets/images/dummy/scrambled/square/scrambled-square-10.jpg'
import ImgScrambledSquare11 from 'assets/images/dummy/scrambled/square/scrambled-square-11.jpg'
import ImgScrambledSquare12 from 'assets/images/dummy/scrambled/square/scrambled-square-12.jpg'
import ImgScrambledSquare13 from 'assets/images/dummy/scrambled/square/scrambled-square-13.jpg'
import ImgScrambledSquare14 from 'assets/images/dummy/scrambled/square/scrambled-square-14.jpg'
import ImgScrambledSquare15 from 'assets/images/dummy/scrambled/square/scrambled-square-15.jpg'
import ImgScrambledSquare16 from 'assets/images/dummy/scrambled/square/scrambled-square-16.jpg'

const ImgScrambled = {
  square: {
    alt: '',
    width: 600,
    height: 600,
    column: 4,
    row: 4,
    files: [
      {
        x: 3,
        y: 3,
        rotate: 180,
        src: ImgScrambledSquare16,
        width: 600,
        height: 600
      },
      {
        x: 2,
        y: 3,
        src: ImgScrambledSquare15,
        width: 600,
        height: 600
      },
      {
        x: 1,
        y: 3,
        rotate: 90,
        src: ImgScrambledSquare14,
        width: 600,
        height: 600
      },
      {
        x: 0,
        y: 3,
        rotate: -90,
        src: ImgScrambledSquare13,
        width: 600,
        height: 600
      },
      {
        x: 3,
        y: 2,
        rotate: 90,
        src: ImgScrambledSquare12,
        width: 600,
        height: 600
      },
      {
        x: 2,
        y: 2,
        src: ImgScrambledSquare11,
        width: 600,
        height: 600
      },
      {
        x: 1,
        y: 2,
        rotate: 90,
        src: ImgScrambledSquare10,
        width: 600,
        height: 600
      },
      {
        x: 0,
        y: 2,
        rotate: -90,
        src: ImgScrambledSquare9,
        width: 600,
        height: 600
      },
      {
        x: 3,
        y: 1,
        rotate: 180,
        src: ImgScrambledSquare8,
        width: 600,
        height: 600
      },
      {
        x: 2,
        y: 1,
        rotate: 90,
        src: ImgScrambledSquare7,
        width: 600,
        height: 600
      },
      {
        x: 1,
        y: 1,
        rotate: -90,
        src: ImgScrambledSquare6,
        width: 600,
        height: 600
      },
      {
        x: 0,
        y: 1,
        rotate: 180,
        src: ImgScrambledSquare5,
        width: 600,
        height: 600
      },
      {
        x: 3,
        y: 0,
        rotate: -90,
        src: ImgScrambledSquare4,
        width: 600,
        height: 600
      },
      {
        x: 2,
        y: 0,
        src: ImgScrambledSquare3,
        width: 600,
        height: 600
      },
      {
        x: 1,
        y: 0,
        rotate: 90,
        src: ImgScrambledSquare2,
        width: 600,
        height: 600
      },
      {
        x: 0,
        y: 0,
        rotate: -90,
        src: ImgScrambledSquare1,
        width: 600,
        height: 600
      }
    ]
  }
}

export const getDeleteCollectionTemplate = (collectionId: number): Collection => ({
  id: collectionId,
  name: '',
  user: {
    email: '',
    first_name: '',
    id: '',
    last_name: '',
    alias: ''
  },
  is_draft: false,
  is_private: false,
  tags: [],
  category: 1,
  username: '',
  thumbnail: null,
  created: '',
  modified: '',
  count: 0,
  bookmark: 0,
  deleted_at: '2019-10-21T06:23:57.458768Z'
})

export const STYLIZE_STYLES: ProArtFilterStyle[] = [
  {
    id: 1,
    name: 'Style Test',
    codename: 'style1',
    cover: `https://picsum.photos/id/${1}/${400}/${400}`,
    genre: 1,
    group: 'Style Group',
    group_order: 1
    // order: 1
  },
  {
    id: 2,
    name: 'Style Test',
    codename: 'style2',
    cover: `https://picsum.photos/id/${2}/${400}/${400}`,
    genre: 1,
    group: 'Style Group',
    group_order: 1
    // order: 1
  },
  {
    id: 3,
    name: 'Style Test',
    codename: 'style3',
    cover: `https://picsum.photos/id/${3}/${400}/${400}`,
    genre: 1,
    group: 'Style Group',
    group_order: 1
    // order: 1
  }
]

export const USER_VIDEO_DUMMY: UserVideo = {
  id: 0,
  file: '',
  resolution: '1280×720',
  fps: 0,
  seconds: 0,
  ext: '',
  compression: ''
}

export const getScrambledImage = () => {
  return ImgScrambled.square
}
