import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConfirmationDialog,
  ConfirmationDialogNameType,
  DialogActionType,
  dialogSelectors
} from 'duck/AppDuck/DialogDuck'
import { minuteToHourText } from 'utils/math'
import DialogWrapper, { DialogWrapperProps } from './DialogWrapper'
import { GeneralDialogItemProps } from '.'
import { RootState } from 'duck'
import { DialogUtils } from './Utils'
import { DialogProps } from 'components/Dialog'
import Button from 'components/Button'
import Img from 'components/Img'
import styles from './Dialogs.module.scss'

export type ActionCreatorParam = {
  swapActionPosition?: boolean
  onClose: DialogProps['onClose']
  yesAction?: DialogActionType
  cancelActionText?: string
  dispatch: ReturnType<typeof useDispatch>
}

const DEFAULT_VALUE: {
  dialogConfig: DialogWrapperProps['dialogConfig']
  actionCreator: (param: ActionCreatorParam) => DialogWrapperProps['actions']
} = {
  dialogConfig: {
    withDivider: false,
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    className: styles.ConfirmationDialog
  },
  actionCreator: ({ onClose, yesAction, dispatch, cancelActionText, swapActionPosition }) => (
    <Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={onClose}
        className={clsx(!swapActionPosition && 'mr-4', swapActionPosition && 'order-1')}
      >
        {cancelActionText ?? 'CANCEL'}
      </Button>

      {yesAction ? (
        <Button
          size="small"
          color="secondary"
          className={swapActionPosition ? 'mr-4' : undefined}
          onClick={e => {
            onClose?.()
            DialogUtils.executeAction(yesAction.actions, dispatch)
          }}
        >
          {yesAction.label ?? 'Yes'}
        </Button>
      ) : null}
    </Fragment>
  )
}
const Confirmation: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.CONFIRMATION](state)
  )
  const thumbnail = dialogData?.thumbnail
  const content = dialogData?.content ?? ''
  const title = dialogData?.title ?? ''
  const yesAction = dialogData?.yesAction
  const cancelActionText = dialogData?.cancelActionText
  const swapActionPosition = dialogData?.swapActionPosition

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        disableTitleBottomSpacing: title ? true : false,
        fullWidth: true
      }}
      title={title}
      actions={DEFAULT_VALUE.actionCreator({
        onClose,
        dispatch,
        yesAction,
        cancelActionText,
        swapActionPosition
      })}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body2">{content}</Typography>
          </div>
          {thumbnail ? (
            <div className={clsx(styles.ContentGroup, styles.Image)}>
              <Img image={thumbnail} />
            </div>
          ) : null}
        </>
      }
    />
  )
}

const UnsavedChanges: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.UNSAVED_CHANGES](state)
  )
  const yesAction = dialogData?.yesAction

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        className: '',
        disableActionTopSpacing: true,
        disableTitleBottomSpacing: true,
        fullWidth: true,
        maxWidth: 'xs'
      }}
      title={<Typography variant="subtitle1">You have unsaved changes.</Typography>}
      actions={DEFAULT_VALUE.actionCreator({ onClose, dispatch, yesAction })}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <div className={clsx(styles.ContentGroup, styles.GreyText)}>
          <Typography variant="subtitle2">Do you want to discard your changes?</Typography>
        </div>
      }
    />
  )
}

const RemoveDefaultCard: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.REMOVE_PAYMENT_METHOD](state)
  )

  const yesAction = dialogData?.yesAction

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig
      }}
      title={<Typography variant="subtitle1">Remove current card?</Typography>}
      actions={DEFAULT_VALUE.actionCreator({ onClose, dispatch, yesAction })}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <div className={clsx(styles.ContentGroup)}>
          <Typography variant="body2">
            Are you sure you'd like to remove the credit card from your account? You can re-add a
            card at any time.
          </Typography>
        </div>
      }
    />
  )
}
const DeleteProject: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.DELETE_PROJECT](state)
  )

  const projectName = dialogData?.content?.projectName
  const projectNameText = projectName ? `"${projectName}"` : 'this project'
  const yesAction = dialogData?.yesAction

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig
      }}
      title={<Typography variant="subtitle1">Delete Project?</Typography>}
      actions={DEFAULT_VALUE.actionCreator({ onClose, dispatch, yesAction })}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body2">{`This action is permanent. Are you sure you want to delete ${projectNameText}?`}</Typography>
          </div>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body2">
              You will no longer be able to access your project or its result images.
            </Typography>
          </div>
        </>
      }
    />
  )
}

const DeleteCollection: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.DELETE_COLLECTION](state)
  )

  const collectionName = dialogData?.content?.collectionName
  const collectionNameText = collectionName ? `"${collectionName}"` : 'this collection'
  const yesAction = dialogData?.yesAction

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig
      }}
      title={<Typography variant="subtitle1">Delete Collection?</Typography>}
      actions={DEFAULT_VALUE.actionCreator({ onClose, dispatch, yesAction })}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <div className={clsx(styles.ContentGroup, styles.GreyText)}>
          <Typography variant="body2">{`This action is permanent. Are you sure you want to delete ${collectionNameText}?`}</Typography>
        </div>
      }
    />
  )
}

const Logout: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.LOGOUT](state)
  )
  const yesAction = dialogData?.yesAction

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig
      }}
      title={<Typography variant="subtitle1">Confirm Log Out</Typography>}
      actions={DEFAULT_VALUE.actionCreator({ onClose, dispatch, yesAction })}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <Fragment>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body2">Are you sure you’d like to log out?</Typography>
          </div>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body2">
              Your work is saved, and your projects will continue training while you’re logged out.
            </Typography>
          </div>
        </Fragment>
      }
    />
  )
}

const Pause: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.PAUSE](state)
  )
  const yesAction = dialogData?.yesAction
  const content = dialogData?.content

  const projectName = content?.projectName
  const unusedCredit = content?.unusedCredit
  const unusedCreditText =
    unusedCredit == null ? '' : `(${minuteToHourText(unusedCredit, 'number')})`

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        withDivider: true,
        fullSizeActionButton: true
      }}
      title={<Typography variant="h6">Pausing a Project</Typography>}
      actions={
        <Fragment>
          {yesAction && (
            <Button
              variant="contained"
              color="secondary"
              onClick={e => {
                onClose?.()
                DialogUtils.executeAction(yesAction.actions, dispatch)
              }}
              fullWidth
            >
              PAUSE TRAINING
            </Button>
          )}
        </Fragment>
      }
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <Fragment>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">{`You are about to pause training for "${projectName}".`}</Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">
              You will be able to resume training at any time, from the results pages of your
              projects.
            </Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body2">{`Any unused credits ${unusedCreditText} will be refunded to your account.`}</Typography>
          </div>
        </Fragment>
      }
    />
  )
}

const DeletePostAdmin: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.DELETE_POST_ADMIN](state)
  )

  const yesAction = dialogData?.yesAction

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        className: '',
        classes: {
          paper: styles.DeletePostDialogPaper
        },
        fullWidth: true,
        maxWidth: false,
        withDivider: true,
        fullSizeActionButton: true,
        disableActionSpacing: true,
        disableTitleBottomSpacing: true,
        showFloatingClose: false
      }}
      title={<Typography variant="h6">Admin - Deleting this post</Typography>}
      actions={
        yesAction ? (
          <Button
            variant="contained"
            color="error"
            onClick={e => {
              onClose?.()
              DialogUtils.executeAction(yesAction.actions, dispatch)
            }}
            fullWidth
          >
            DELETE POST
          </Button>
        ) : undefined
      }
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <div className="py-3">
          <Typography variant="body2">{`You will be permanently deleting this user's post without their consent.`}</Typography>
          <Typography variant="body2">{`This action is non-reversible.`}</Typography>
        </div>
      }
    />
  )
}
const DeletePostOwner: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dispatch = useDispatch()
  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ConfirmationDialog.DELETE_POST_OWNER](state)
  )
  const yesAction = dialogData?.yesAction

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        className: '',
        classes: {
          paper: styles.DeletePostDialogSmallPaper
        },
        fullWidth: true,
        maxWidth: false,
        disableTitleBottomSpacing: true,
        disableActionSpacing: true,
        showFloatingClose: false
      }}
      title={<Typography variant="h6">Deleting this post</Typography>}
      actions={
        yesAction ? (
          <Button
            variant="contained"
            color="error"
            onClick={e => {
              onClose?.()
              DialogUtils.executeAction(yesAction.actions, dispatch)
            }}
            fullWidth
          >
            DELETE POST
          </Button>
        ) : undefined
      }
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <div className="mb-4">
          <Typography variant="body2">{`Permanently delete your post?`}</Typography>
        </div>
      }
    />
  )
}
export const ConfirmationDialogs: Record<
  ConfirmationDialogNameType,
  React.FC<GeneralDialogItemProps>
> = {
  [ConfirmationDialog.CONFIRMATION]: Confirmation,
  [ConfirmationDialog.REMOVE_PAYMENT_METHOD]: RemoveDefaultCard,
  [ConfirmationDialog.DELETE_PROJECT]: DeleteProject,
  [ConfirmationDialog.DELETE_COLLECTION]: DeleteCollection,
  [ConfirmationDialog.LOGOUT]: Logout,
  [ConfirmationDialog.PAUSE]: Pause,
  [ConfirmationDialog.DELETE_POST_ADMIN]: DeletePostAdmin,
  [ConfirmationDialog.DELETE_POST_OWNER]: DeletePostOwner,
  [ConfirmationDialog.UNSAVED_CHANGES]: UnsavedChanges
}
