import { Transition, Clip, OutputVideo } from 'models/ApiModels'
import _map from 'lodash/map'
import _compact from 'lodash/compact'
import { values } from 'appConstants'
import { GetText } from 'utils/TextUtils'

interface PartialClip {
  keyframes: Clip['keyframes']
  transitions: Clip['transitions']
  preview?: Clip['preview']
  output?: Clip['output']
}

export const Utils = {
  getDerivedClipData: (clipData: PartialClip) => {
    let accumulation = 0
    const keyframesTimePosition = _map(clipData?.keyframes, (keyframe, index) => {
      accumulation += clipData?.transitions?.[index - 1]?.seconds ?? 0
      return accumulation
    })

    const clipKeyframeLength = clipData?.keyframes?.length ?? 0
    const clipTransitionLength = clipData?.transitions?.length ?? 0

    const clipDuration =
      clipData?.output?.video?.seconds ??
      clipData?.preview?.seconds ??
      keyframesTimePosition[keyframesTimePosition.length - 1]

    const isMaxedDuration = clipDuration > values.MAXIMUM_CLIP_DURATION
    const isHasEnoughKeyframe = clipKeyframeLength > 1 && Boolean(clipTransitionLength)
    const isMaxedKeyframe = clipKeyframeLength > values.MAXIMUM_KEYFRAME_COUNT

    return {
      keyframesTimePosition,
      clipDuration,
      isMaxedKeyframe,
      isMaxedDuration,
      isHasEnoughKeyframe,
      clipKeyframeLength
    }
  },
  isClipRenderable: (clip: Clip | undefined) => (clip?.keyframes?.length ?? 0) > 1,
  getClipListKey: GetText.clipListKey,
  getFirstOutputs: (clipOutputs?: OutputVideo[]) =>
    clipOutputs
      ? _compact(clipOutputs.map(output => (output?.video?.id ? output : undefined)))[0]
      : undefined
}

export const EaseFnOptions: {
  label: string
  value: Transition['ease_fn']
}[] = [
  {
    label: 'Linear In Out',
    value: 'LinearInOut'
  },
  {
    label: 'Cubic Ease In',
    value: 'CubicEaseIn'
  },
  {
    label: 'Cubic Ease In Out',
    value: 'CubicEaseInOut'
  },
  {
    label: 'Cubic Ease Out',
    value: 'CubicEaseOut'
  },
  {
    label: 'Sine Ease In',
    value: 'SineEaseIn'
  },
  {
    label: 'Sine Ease In Out',
    value: 'SineEaseInOut'
  },
  {
    label: 'Sine Ease Out',
    value: 'SineEaseOut'
  },
  {
    label: 'Elastic Ease In',
    value: 'ElasticEaseIn'
  },
  {
    label: 'Elastic Ease Out',
    value: 'ElasticEaseOut'
  },
  {
    label: 'Elastic Ease In Out',
    value: 'ElasticEaseInOut'
  },
  {
    label: 'Bounce Ease In',
    value: 'BounceEaseIn'
  },
  {
    label: 'Bounce Ease Out',
    value: 'BounceEaseOut'
  },
  {
    label: 'Bounce Ease In Out',
    value: 'BounceEaseInOut'
  }
]
