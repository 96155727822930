import { Store, applyMiddleware, createStore } from 'redux'

import { RootActionType } from 'duck'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createBrowserHistory } from 'history'
import { createEpicMiddleware } from 'redux-observable'
import { epics, RootState, createRootReducer } from 'duck'
import { initializeFirebase } from './utils/FirebaseUtils'
import { createReduxHistoryContext } from 'redux-first-history'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
})

const composeEnhancers = composeWithDevTools({})

export function configureStore() {
  initializeFirebase()

  const epicMiddleWare = createEpicMiddleware<RootActionType, RootActionType, RootState>()
  const middlewares = [routerMiddleware, epicMiddleWare]

  const rootReducer = createRootReducer(routerReducer)

  const store = createStore(
    rootReducer,
    undefined,
    composeEnhancers(applyMiddleware(...middlewares))
  ) as Store<RootState>

  epicMiddleWare.run(epics)

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./duck', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return { store, history: createReduxHistory(store) }
}
