import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _toInteger from 'lodash/toInteger'
import _keys from 'lodash/keys'
import _reduce from 'lodash/reduce'
import _cloneDeep from 'lodash/cloneDeep'
import { ActionType } from 'typesafe-actions'
import { combineEpics, Epic } from 'redux-observable'
import {
  withLatestFrom,
  map,
  mergeMap,
  take,
  startWith,
  delay,
  switchMap,
  filter,
  tap,
  ignoreElements
} from 'rxjs/operators'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { RootState, RootActionType } from 'duck'
import { of, merge } from 'rxjs'
import { getType, isActionOf, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import {
  TrainProject,
  SelectedCollectionsImagesType,
  SelectedCollectionImagesType,
  ImageSetType,
  MoveCollectionReq,
  InputImageSet,
  Collection
} from 'models/ApiModels'
import {
  generateImagesKey,
  getDuplicateCollections,
  SelectUtils,
  SelectedFormat
} from 'utils/DataProcessingUtils'
import { dialogActions, ErrorDialog, InformationDialog } from 'duck/AppDuck/DialogDuck'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'

const NAMESPACE = '@@panel/InputPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export const actions = {
  retrieveInputImageSet: createAction(creator('RETRIEVE_INPUT_IMAGE_SET'))<ImageSetType>(),
  retrieveInitialImages: createAction(creator('RETRIEVE_INITIAL_IMAGES'))<ImageSetType>(),
  showCollectionDialog: createAction(creator('SHOW_COLLECTION_DIALOG'))<
    undefined | CollectionDialogParam
  >(),
  loadMoreImages: createAction(creator('LOAD_MORE_IMAGES'))(),
  saveChanges: createAction(creator('SAVE_CHANGES'))(),
  selectImage: createAction(creator('SELECT_IMAGES'))<number>(),
  setSelection: createAction(creator('SET_SELECTION'))<SelectedCollectionImagesType>(),
  resetActiveCollection: createAction(creator('RESET_SELECTION'))(),
  selectAllImages: createAction(creator('SELECT_ALL_IMAGES'))(),
  deselectAllImages: createAction(creator('DESELECT_ALL_IMAGES'))(),
  removeFromProject: createAction(creator('REMOVE_FROM_PROJECT'))<RemoveProjectParam | null>(),
  undoRemove: createAction(creator('UNDO_REMOVE'))<UndoRemoveParam | null>(),
  moveCollection: createAction(creator('MOVE_COLLECTION'))<MoveCollectionReq>()
}

type GetImagesCountParam = {
  collection?: Collection
  input?: InputImageSet | null
}

export const Utils = {
  getObjectArrayCount: (input: { [collectionId: number]: number[] }) =>
    _reduce(
      input,
      (result, value) => {
        result += value.length
        return result
      },
      0
    ),
  getImagesCount: (param: GetImagesCountParam) => {
    const { collection, input } = param
    if (!collection || !input) {
      return 0
    }
    const images = input?.images ?? {}
    return images[collection?.id || 0].length
  },
  getKey: (state: InputPanelState) => {
    const { collectionDialog } = state
    if (collectionDialog) {
      const { collectionId, inputId } = collectionDialog
      return generateImagesKey({
        collection: collectionId,
        inputs: inputId
      })
    }
    return ''
  },
  getInitialSelectedImages: (imageSet: Set<number>) => {
    const selected = _reduce<number, SelectedFormat>(
      Array.from(imageSet.values()),
      (result, value) => {
        result[value] = true
        return result
      },
      {}
    )
    return {
      selected,
      direction: 'normal' as SelectedCollectionImagesType['direction'],
      changed: false
    }
  },
  getSelectedInputAndCollection: (
    currentProject: TrainProject,
    collectionDialog?: InputPanelState['collectionDialog']
  ) => {
    if (collectionDialog && currentProject) {
      const inputId = collectionDialog?.inputId ?? 0
      let input: InputImageSet | undefined = undefined
      if (inputId === currentProject?.aestheticData?.id) {
        input = currentProject?.aestheticData
      }
      if (inputId === currentProject?.inspirationData?.id) {
        input = currentProject?.inspirationData
      }

      const collection = input?.collections?.[collectionDialog?.collectionId ?? 0]
      return {
        input,
        collection
      }
    }
    return undefined
  },
  /* 
    Decide to include exclude or include images 
  */
  handleIncludedImages: (selectedImages: SelectedCollectionImagesType) => {
    const { selected, direction } = selectedImages
    //If inverse, then use excluded_images
    if (direction === 'inverse') {
      return {
        exclude_images: SelectUtils.getSelectedList(selected)
      }
    } else {
      return {
        include_images: SelectUtils.getSelectedList(selected)
      }
    }
  }
}

const selectInputPanel = (state: RootState) => state.container.trainProjectPage.inputPanel
const selectCollectionDialog = createSelector(
  selectInputPanel,
  inputPanel => inputPanel.collectionDialog
)

const selectSelectedCollection = createSelector(
  apiSelectors.currentProject,
  selectCollectionDialog,
  (currentProject, collectionDialog) => {
    return Utils.getSelectedInputAndCollection(currentProject, collectionDialog)
  }
)

export const selectors = {
  inputPanel: selectInputPanel,
  selectedCollection: selectSelectedCollection,
  collectionDialog: selectCollectionDialog,
  selectedImages: createSelector(selectInputPanel, inputPanel => {
    const { selectedCollectionImages } = inputPanel
    const key = Utils.getKey(inputPanel)
    return selectedCollectionImages[key]
  })
}

// Reducer
export type CollectionDialogParam = { collectionId: number; inputId: number; imageSet: Set<number> }
export type RemoveProjectParam = Omit<CollectionDialogParam, 'imageSet'>
export type UndoRemoveParam = RemoveProjectParam

export type InputPanelState = {
  collectionDialog?: CollectionDialogParam
  selectedCollectionImages: SelectedCollectionsImagesType
  loadingText: string
}

const initialSelectedImages = (imageSet: Set<number>) => ({
  selected: {},
  direction: 'normal',
  changed: false
})

const initial: InputPanelState = {
  collectionDialog: undefined,
  selectedCollectionImages: {},
  loadingText: ''
}

const reducer = produce((state: InputPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.showCollectionDialog): {
      const typedPayload = payload as ActionType<typeof actions.showCollectionDialog>['payload']

      state.collectionDialog = typedPayload

      const key = Utils.getKey({ ...state, collectionDialog: payload })

      if (!Boolean(key)) return

      state.selectedCollectionImages[key] =
        state.selectedCollectionImages[key] || Utils.getInitialSelectedImages(payload.imageSet)

      return
    }
    case getType(actions.selectImage): {
      const typedPayload = payload as ActionType<typeof actions.selectImage>['payload']

      const key = Utils.getKey(state)

      if (!Boolean(key)) return

      const currentSelected = state.selectedCollectionImages[key] || initialSelectedImages
      state.selectedCollectionImages[key] = {
        ...currentSelected,
        changed: true,
        selected: {
          ...currentSelected.selected,
          [typedPayload]: !Boolean(currentSelected.selected[typedPayload])
        }
      }
      return
    }
    case getType(actions.selectAllImages): {
      const key = Utils.getKey(state)

      if (!Boolean(key)) return

      state.selectedCollectionImages[key] = {
        selected: {},
        direction: 'inverse',
        changed: true
      }
      return
    }
    case getType(actions.setSelection): {
      const typedPayload = payload as ActionType<typeof actions.setSelection>['payload']

      const key = Utils.getKey(state)

      if (!Boolean(key)) return
      state.selectedCollectionImages[key] = typedPayload

      return
    }
    case getType(actions.deselectAllImages): {
      const key = Utils.getKey(state)

      if (!Boolean(key)) return

      state.selectedCollectionImages[key] = {
        selected: {},
        direction: 'normal',
        changed: true
      }
      return
    }
    case getType(actions.saveChanges): {
      state.loadingText = 'SAVING CHANGES...'
      const key = Utils.getKey(state)

      if (!Boolean(key)) return

      state.selectedCollectionImages[key] = {
        ...state.selectedCollectionImages[key],
        changed: false
      }
      return
    }
    case getType(actions.removeFromProject): {
      state.loadingText = 'REMOVING COLLECTION...'
      return
    }

    default:
  }
}, initial)

// Epics
type InputPanelActionType = RootActionType | ActionType<typeof actions>

/* Get Inspiration and Aesthetic Data */
const retrieveInputImageSetEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.retrieveInputImageSet)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      imageSet: action.payload,
      currentProject: apiSelectors.currentProject(state)
    })),
    filter(({ currentProject }) => Boolean(currentProject?.id)),
    map(({ imageSet, currentProject }) => ({ inputId: currentProject?.[imageSet] ?? 0 })),
    filter(({ inputId }) => Boolean(inputId)),
    map(({ inputId }) => apiActions.inputs.retrieve(inputId))
  )

// Get collection after aesthetic and inspiration data received, and fetch the images.
const retrieveInitialImagesEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.retrieveInitialImages)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      imageSet: action.payload,
      projectData: apiSelectors.currentProject(state)
    })),
    filter(({ projectData }) => Boolean(projectData?.id ?? 0)),
    map(({ projectData, imageSet }) => {
      let id = 0
      let collections: InputImageSet['collections'] = {}
      if (imageSet === 'aesthetic') {
        id = projectData?.aestheticData?.id ?? 0
        collections = projectData?.aestheticData?.collections ?? {}
      }
      if (imageSet === 'inspiration') {
        id = projectData?.inspirationData?.id ?? 0
        collections = projectData?.inspirationData?.collections ?? {}
      }
      return {
        id,
        collections
      }
    }),
    filter(({ id }) => Boolean(id)),
    mergeMap(({ collections, id }) =>
      of(..._keys(collections)).pipe(
        map(collectionId => ({
          inputs: id || undefined,
          collection: _toInteger(collectionId),
          limit: 80
        })),
        filter(({ collection, inputs }) => Boolean(collection) && Boolean(inputs)),
        map(param => apiActions.collections.listImage({ param }))
      )
    )
  )

//  Get more collection when triggered by scroll or dialog loaded,
const loadMoreImagesEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.loadMoreImages)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      selectedCollection: selectors.selectedCollection(state)
    })),
    map(({ selectedCollection }) => ({
      collection: selectedCollection?.collection?.id ?? 0,
      inputs: selectedCollection?.input?.id ?? 0
    })),
    filter(({ collection, inputs }) => Boolean(collection) && Boolean(inputs)),
    switchMap(param => [apiActions.collections.listImage({ param, next: true })])
  )

const removeFromProjectEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.removeFromProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      param: {
        id: action.payload?.inputId ?? 0,
        collection: action.payload?.collectionId ?? 0,
        include_images: [],
        update_existing: false
      },
      currentProjectId: apiSelectors.currentProjectId(state),
      currentProject: apiSelectors.currentProject(state),
      collection: _cloneDeep(apiSelectors.collections(state)[action.payload?.collectionId ?? 0]),
      categoriesObject: apiSelectors.categoriesObject(state)
    })),
    filter(({ param }) => Boolean(param.id) && Boolean(param.collection)),
    mergeMap(({ param, currentProjectId, currentProject, collection, categoriesObject }) =>
      action$.pipe(
        filter(isActionOf(apiActions.inputs.updateResponse)),
        take(1),
        withLatestFrom(state$),
        map(([, state]) => apiSelectors.user(state)),
        tap(() => {
          const collectionData = DataUtils.getCollectionData({
            collection,
            category: categoriesObject
          })
          const projectData = DataUtils.getProjectParam<'training_project'>('training_project', {
            trainProject: currentProject
          })

          MixPanelUtils.track<'PROJECT__REMOVE_COLLECTION'>('Project - Remove Collection', {
            ...collectionData,
            ...projectData
          })
        }),

        //Flow to display dialog
        filter(userData => !(userData?.ui_extras?.is_saw_collection_remove_confirm ?? false)),
        delay(600),
        mergeMap(() => [
          dialogActions.openDialog({
            [InformationDialog.COLLECTION_REMOVED]: {
              dialogName: InformationDialog.COLLECTION_REMOVED
            }
          }),
          apiActions.users.updateUiExtras({ is_saw_collection_remove_confirm: true }),
          apiActions.projects.updateThumbnail(currentProjectId)
        ]),
        startWith(apiActions.inputs.update(param))
      )
    )
  )
const saveChangesEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.saveChanges)),
    withLatestFrom(state$),
    map(([, state]) => ({
      collectionDialog: selectors.collectionDialog(state),
      state
    })),
    filter(({ collectionDialog }) => Boolean(collectionDialog)),
    map(({ collectionDialog, state }) => ({
      collectionDialog: collectionDialog as CollectionDialogParam,
      state,
      includedImages: Utils.handleIncludedImages(selectors.selectedImages(state)),
      userData: apiSelectors.user(state),
      currentProjectId: apiSelectors.currentProjectId(state),
      currentProject: apiSelectors.currentProject(state),
      collections: apiSelectors.collections(state),
      categoriesObject: apiSelectors.categoriesObject(state)
    })),
    map(
      ({
        collectionDialog,
        includedImages,
        userData,
        currentProjectId,
        currentProject,
        collections,
        categoriesObject
      }) => ({
        userData,
        isRemoveAction:
          Boolean(includedImages?.include_images) &&
          !Boolean(includedImages?.include_images?.length),
        param: {
          id: collectionDialog?.inputId,
          collection: collectionDialog?.collectionId,
          update_existing: true,
          ...includedImages
        },
        currentProjectId,
        currentProject,
        collections,
        categoriesObject
      })
    ),
    filter(({ param }) => Boolean(param.id) && Boolean(param.collection)),
    mergeMap(
      ({
        param,
        userData,
        isRemoveAction,
        currentProjectId,
        currentProject,
        collections,
        categoriesObject
      }) =>
        action$.pipe(
          filter(isActionOf(apiActions.inputs.updateResponse)),
          take(1),
          tap(() => {
            const collectionData = DataUtils.getCollectionData({
              collection: collections[param.collection],
              category: categoriesObject
            })
            const projectData = DataUtils.getProjectParam<'training_project'>('training_project', {
              trainProject: currentProject
            })

            MixPanelUtils.track<'PROJECT__UPDATE_COLLECTION'>('Project - Update Collection', {
              ...collectionData,
              ...projectData,
              included_images_count: param.include_images?.length ?? 0
            })
          }),
          mergeMap(() =>
            merge(
              //Flow to display dialog
              of({ param, userData, isRemoveAction }).pipe(
                filter(
                  ({ userData }) =>
                    !(userData?.ui_extras?.is_saw_collection_remove_confirm ?? false)
                ),
                filter(({ isRemoveAction }) => Boolean(isRemoveAction)),
                delay(600),
                mergeMap(() => [
                  dialogActions.openDialog({
                    [InformationDialog.COLLECTION_REMOVED]: {
                      dialogName: InformationDialog.COLLECTION_REMOVED
                    }
                  }),
                  apiActions.users.updateUiExtras({ is_saw_collection_remove_confirm: true })
                ])
              ),
              //Normal flow
              of({ param, userData }).pipe(
                mergeMap(() => [
                  actions.resetActiveCollection(),
                  apiActions.collections.listImage({
                    param: {
                      inputs: param.id,
                      collection: _toInteger(param.collection),
                      limit: 80
                    }
                  }),
                  actions.showCollectionDialog(undefined)
                ])
              ),
              of(apiActions.projects.updateThumbnail(currentProjectId))
            )
          ),
          // This one is executed first, and code above is listening finished collection.
          startWith(apiActions.inputs.update(param))
        )
    )
  )
const resetActiveCollectionEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.resetActiveCollection)),
    withLatestFrom(state$),
    map(([, state]) => selectors.selectedCollection(state)),
    map(param => ({
      collectionId: param?.collection?.id ?? 0,
      input: param?.input
    })),
    filter(({ collectionId, input }) => Boolean(collectionId) && Boolean(input)),
    map(({ input, collectionId }) => input?.imagesSet?.[collectionId] ?? new Set([])),
    map(imageSet => actions.setSelection(Utils.getInitialSelectedImages(imageSet)))
  )

const moveCollectionEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.moveCollection)),
    map(action => action.payload),
    map(param => apiActions.inputs.moveCollection(param))
  )

const undoRemoveEpic: Epic<InputPanelActionType, InputPanelActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.undoRemove)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      currentProject: apiSelectors.currentProject(state),
      collectionId: action.payload?.collectionId ?? 0,
      inputId: action.payload?.inputId ?? 0
    })),
    filter(({ collectionId, inputId }) => Boolean(collectionId && inputId)),
    map(({ collectionId, inputId, currentProject }) => {
      const imageSet = currentProject?.aesthetic === inputId ? 'aesthetic' : 'inspiration'
      const duplicates = getDuplicateCollections(currentProject, [collectionId], imageSet)
      const process = currentProject?.categoryName

      return {
        process,
        duplicates,
        param: {
          id: inputId,
          collection: collectionId,
          exclude_images: [],
          update_existing: true
        }
      }
    }),
    mergeMap(({ param, process, duplicates }) =>
      merge(
        of(duplicates).pipe(
          filter(duplicates => Boolean(duplicates.length)),
          map(() =>
            dialogActions.openDialog({
              [ErrorDialog.REUSED_COLLECTION]: {
                dialogName: ErrorDialog.REUSED_COLLECTION,
                content: { process }
              }
            })
          )
        ),
        of(duplicates).pipe(
          filter(duplicates => !Boolean(duplicates.length)),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf([apiActions.inputs.updateResponse])),
              take(1),
              withLatestFrom(state$),
              tap(([_, state]) => {
                const currentProject = apiSelectors.currentProject(state)
                const collection = apiSelectors.collections(state)[param.collection]
                const categoriesObject = apiSelectors.categoriesObject(state)

                const collectionData = DataUtils.getCollectionData({
                  collection,
                  category: categoriesObject
                })
                const projectData = DataUtils.getProjectParam<'training_project'>(
                  'training_project',
                  {
                    trainProject: currentProject
                  }
                )

                MixPanelUtils.track<'PROJECT__UNDO_REMOVE_COLLECTION'>(
                  'Project - Undo Remove Collection',
                  {
                    ...collectionData,
                    ...projectData
                  }
                )
              }),
              ignoreElements(),
              startWith(apiActions.inputs.update(param))
            )
          )
        )
      )
    )
  )

export const epics = combineEpics(
  undoRemoveEpic,
  loadMoreImagesEpic,
  retrieveInputImageSetEpic,
  retrieveInitialImagesEpic,
  saveChangesEpic,
  resetActiveCollectionEpic,
  removeFromProjectEpic,
  moveCollectionEpic
)
export default reducer
