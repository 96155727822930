import React from 'react'
import clsx from 'clsx'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'

import Button from 'components/Button'

import styles from './Loading.module.scss'

type LoadingType = CircularProgressProps & {
  loading?: boolean
  onClickPause?: Function
  onClickPlay?: Function
  pause?: boolean
  play?: boolean
}

export const CircularLoading: React.FC<LoadingType> = props => {
  const {
    className,
    disableShrink,
    loading = false,
    onClickPause,
    onClickPlay,
    pause,
    size = 40,
    value,
    variant
  } = props
  const isDeterminate = variant === 'determinate' && value && value >= 0

  if (!isDeterminate && !loading) return null

  const loadingComponent = (
    <CircularProgress
      color="secondary"
      size={size}
      disableShrink={disableShrink}
      value={value}
      variant={variant}
      aria-labelledby="circularProgress"
    />
  )

  if (className || isDeterminate) {
    return (
      <div className={clsx(styles.CircularLoading, className)}>
        {loading || isDeterminate ? loadingComponent : null}
        {onClickPlay && onClickPause ? (
          <div className={styles.CircularLoadingLabelCenter}>
            {onClickPlay && pause ? (
              <Button
                color="secondary"
                className={styles.CircularLoadingButtonCenter}
                onClick={() => {
                  onClickPlay()
                }}
                icon
              >
                <PlayCircleFilledIcon />
              </Button>
            ) : null}

            {onClickPause && !pause ? (
              <Button
                color="secondary"
                className={styles.CircularLoadingButtonCenter}
                onClick={() => {
                  onClickPause()
                }}
                icon
              >
                <PauseCircleFilledIcon />
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }

  return loadingComponent
}
