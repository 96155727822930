import React, { MouseEventHandler } from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from 'react-router-dom'
import styles from './Link.module.scss'

type LinkColorProps = 'primary' | 'secondary' | 'light'

const setColor = (color?: LinkColorProps) => {
  switch (color) {
    case 'primary':
      return styles.ColorPrimary
    case 'secondary':
      return styles.ColorSecondary
    case 'light':
      return styles.ColorLight
    default:
      return styles.ColorSecondary
  }
}

type LinkComponentProps = {
  addOnRight?: React.ReactNode
  children?: React.ReactNode
  className?: string
  color?: LinkColorProps
  href?: string
  onClick?: MouseEventHandler
  target?: LinkProps['target']
  to?: string
  name?: string
}

const LinkComponent: React.FC<LinkComponentProps> = props => {
  const { addOnRight, children, color, onClick, target, to } = props

  if (!children) return null

  const LinkContent = addOnRight ? (
    <span className={styles.LinkWithAddOn}>
      <span className={styles.LinkWithAddOnContent}>{children}</span>
      {addOnRight}
    </span>
  ) : (
    children
  )

  if (to) {
    return (
      <Link
        className={clsx(styles.Link, props.className, setColor(color))}
        to={to}
        target={target}
        onClick={onClick}
      >
        {LinkContent}
      </Link>
    )
  }

  return (
    <a
      className={clsx(styles.Link, props.className, setColor(color))}
      href={props.href}
      target={target}
      onClick={onClick}
    >
      {LinkContent}
    </a>
  )
}

export default LinkComponent
