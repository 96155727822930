import { TextTransform, UrlUtils } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import {
  withLatestFrom,
  map,
  filter,
  delay,
  ignoreElements,
  tap,
  debounceTime,
  mergeMap
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import {
  PostListReq,
  PostScope,
  PostListOrderingReq,
  TrainProjectListReq,
  TrainProjectOrderingType,
  PostListOrderingReqList,
  CollectionListReq
} from 'models/ApiModels'
import { apiActions, apiSelectors, CollectionListType } from 'duck/ApiDuck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { ExploreParamType, MAIN_PAGE, route } from 'routes'
import _compact from 'lodash/compact'
import _toInteger from 'lodash/toInteger'
import { push } from 'redux-first-history'
import { DropdownItem } from 'components/DropdownMenu'
import { sortUtils } from 'utils/DataProcessingUtils'
import { SessionStorage } from 'utils'
import { LSKey } from 'appConstants'
import { appActions } from 'duck/AppDuck'

// Constants
const NAMESPACE = '@@page/ExplorePage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const actions = {
  initiateData: createAction(creator('INITIATE_DATA'))<ExploreParamType & { urlParam: string }>(),
  openPage: createAction(creator('OPEN_PAGE'))<{
    id?: number
    subRoute?: PageMode
    featured?: ExploreParamType['featured']
  }>(),
  onUnmount: createAction(creator('ON_UNMOUNT'))(),
  setItem: createAction(creator('SET_ITEM'))<{
    id: number | undefined
    featured?: ExploreParamType['featured']
  }>(),
  setMode: createAction(creator('SET_MODE'))<PageMode>(),
  updateUrl: createAction(creator('UPDATE_URL'))(),
  post: {
    reload: createAction(creator('POST/RELOAD'))(),
    setSortBy: createAction(creator('POST/SET_SORT_BY'))<PostListOrderingReq>()
  },
  project: {
    reload: createAction(creator('PROJECT/RELOAD'))(),
    setSortBy: createAction(creator('PROJECT/SET_SORT_BY'))<TrainProjectOrderingType>()
  },
  collection: {
    reload: createAction(creator('COLLECTION/RELOAD'))(),
    updateSearch: createAction(creator('COLLECTION/UPDATE_SEARCH'))<string>()
  },
  findImageToShare: createAction(creator('FIND_IMAGE_TO_SHARE'))()
}

// Selectors

const selectExplorePage = (state: RootState) => state.container.explorePage

const selectPostListParams = createSelector(
  selectExplorePage,
  explorePage => explorePage.post.listParams
)
const selectPostScopeList = createSelector(
  selectExplorePage,
  explorePage => explorePage.post.scopeList
)
const selectPostOrderingList = createSelector(
  selectExplorePage,
  explorePage => explorePage.post.orderingList
)

const selectProjectListParams = createSelector(
  selectExplorePage,
  explorePage => explorePage.project.listParams
)

const selectProjectOrderingList = createSelector(
  selectExplorePage,
  selectProjectListParams,
  (explorePage, listParams) => {
    const { sortDirection } = sortUtils.toObject(listParams.ordering)
    const orderingList = explorePage.project.orderingList

    return sortDirection === '-'
      ? orderingList?.map(value => {
          if (value.key === 'name') {
            return { ...value, label: 'Z-A' }
          }
          if (value.key === 'modified') {
            return { ...value, label: 'Most Recent' }
          }
          return value
        })
      : orderingList
  }
)

const selectMode = createSelector(selectExplorePage, explorePage => explorePage.mode)

const selectCollection = createSelector(selectExplorePage, explorePage => explorePage.collection)

export const selectors = {
  postloaded: createSelector(selectExplorePage, explorePage => explorePage.post.loaded),
  mode: selectMode,
  modeList: createSelector(selectExplorePage, explorePage => explorePage.modeList),
  modeListForTabMenu: createSelector(selectExplorePage, explorePage => explorePage.modeList),
  postListParams: selectPostListParams,
  postScopeList: selectPostScopeList,
  postOrderingList: selectPostOrderingList,
  projectloaded: createSelector(selectExplorePage, explorePage => explorePage.project.loaded),
  projectListParams: selectProjectListParams,
  projectOrderingList: selectProjectOrderingList,
  collectionLoaded: createSelector(selectExplorePage, explorePage => explorePage.collection.loaded),
  collection: selectCollection,
  collectionSearchData: createSelector(selectCollection, collection => ({
    showSearch: collection.showSearch,
    search: collection.listParams.search
  })),
  selectItemId: createSelector(
    selectMode,
    apiSelectors.currentPostId,
    apiSelectors.currentProjectId,
    apiSelectors.currentCollectionId,
    (mode, postId, projectId, collections) => {
      const ids: { [key in PageMode]?: number } = {
        posts: postId,
        filters: postId,
        projects: projectId,
        collections: collections
      }

      return ids[mode ?? 'post'] ?? 0
    }
  )
}

// Reducer
export type ScopeListType = {
  label: string
  value: PostScope
}
export type OrderingListType<OrderingType> = {
  label: string
  value: OrderingType
}
export type ProjectState = {
  loaded: boolean
  listParams: TrainProjectListReq
  orderingList: DropdownItem[]
}

export type PostState = {
  loaded: boolean

  scopeList: ScopeListType[]
  orderingList: OrderingListType<PostListOrderingReq>[]
  listParams: PostListReq
}
export const PAGE_MODE = {
  FEATURED: 'featured',
  POSTS: 'posts',
  FILTERS: 'filters',
  PROJECTS: 'projects',
  COLLECTIONS: 'collections'
} as const
export const FEATURED_LIST = [PAGE_MODE.FEATURED] as const

export const PAGE_MODE_LIST = [
  '',
  PAGE_MODE.FEATURED,
  PAGE_MODE.POSTS,
  PAGE_MODE.FILTERS,
  PAGE_MODE.PROJECTS,
  PAGE_MODE.COLLECTIONS
] as const

export type PageMode = (typeof PAGE_MODE_LIST)[number]
export type FeaturedType = (typeof FEATURED_LIST)[number]
export type ExplorePageState = {
  mode: PageMode
  modeList: {
    value: PageMode | ''
    label: string
    hide?: boolean
  }[]
  project: ProjectState
  post: PostState
  collection: {
    showSearch: boolean
    loaded: boolean
    listParams: CollectionListReq
    listType: CollectionListType
  }
}

const initial: ExplorePageState = {
  mode: '',
  modeList: [
    {
      value: '',
      label: '',
      hide: true
    },
    {
      value: 'posts',
      label: 'Posts'
    },
    {
      value: 'filters',
      label: 'Filters',
      hide: true // TODO show when available
    },
    {
      value: 'projects',
      label: 'Projects'
    },
    {
      value: 'collections',
      label: 'Collections'
    }
  ],
  // filters: {
  //   loaded: false,
  //   showSearch: false,
  //   listParams: {
  //     search: '',
  //     scope: 'public',
  //     ordering: '-modified'
  //   },
  //   listType: 'explore_page'
  // },
  collection: {
    loaded: false,
    showSearch: false,
    listParams: {
      search: '',
      scope: 'public',
      ordering: '-modified'
    },
    listType: 'explore_page'
  },
  project: {
    loaded: false,
    listParams: {
      ordering: '-modified',
      scope: 'public',
      limit: 20
    },
    orderingList: [
      {
        key: 'name',
        label: 'A-Z'
      },
      {
        key: 'modified',
        label: 'Oldest'
      }
    ]
  },
  post: {
    loaded: false,
    scopeList: [
      {
        label: 'All posts',
        value: 'all'
      },
      {
        label: 'My posts',
        value: 'private'
      },
      {
        label: 'Saved posts',
        value: 'bookmark'
      }
    ],
    orderingList: [
      {
        label: 'Trending',
        value: 'trending'
      },
      {
        label: 'Recent',
        value: 'recent'
      }
    ],
    listParams: {
      ordering: 'recent',
      scope: 'public',
      limit: 30
    }
  }
}

const reducer = produce((state: ExplorePageState, { type, payload }) => {
  switch (type) {
    case getType(actions.setMode): {
      const mode = payload as ActionType<typeof actions.setMode>['payload']

      state.mode = mode
      return
    }
    case getType(actions.post.setSortBy): {
      state.post.listParams.ordering = payload
      return
    }

    case getType(actions.post.reload): {
      state.post.loaded = true
      return
    }
    case getType(actions.project.reload): {
      state.project.loaded = true
      return
    }
    case getType(actions.collection.reload): {
      state.collection.loaded = true
      return
    }
    case getType(actions.collection.updateSearch): {
      const keyword = payload as ActionType<typeof actions.collection.updateSearch>['payload']

      state.collection.listParams.search = keyword
      return
    }

    case getType(actions.project.setSortBy): {
      state.project.listParams.ordering = payload
      return
    }
    case getType(actions.initiateData): {
      const { subRoute, urlParam } = payload as ActionType<typeof actions.initiateData>['payload']

      const data = UrlUtils.paramToObject(urlParam, ['mode', 'ordering'])
      const subRouteValid = PAGE_MODE_LIST.includes(subRoute)
      const mode: PageMode = subRouteValid ? subRoute : ''
      const ordering = data['ordering']

      if (mode) {
        state.mode = mode
      }

      if (mode === 'posts') {
        if (PostListOrderingReqList.includes(ordering as PostListOrderingReq)) {
          state.post.listParams.ordering = ordering as PostListOrderingReq
        }
      }

      return
    }
    default:
  }
}, initial)

// Epics

const initiateDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.initiateData)),
    withLatestFrom(state$),
    map(([action, state]) => {
      //Old explore URL use subRoute param as post id, so here we put fallback to it.
      const legacyUrlId = _toInteger(action.payload.subRoute)

      const mode = selectors.mode(state)
      const id = _toInteger(action.payload.id) || legacyUrlId
      const reloadActions: { [key in PageMode]?: Function } = {
        posts: actions.post.reload,
        filters: actions.post.reload,
        projects: actions.project.reload,
        collections: actions.collection.reload
      }

      const setIdAction: { [key in PageMode]?: Function } = {
        posts: apiActions.social.setCurrentPost,
        filters: apiActions.social.setCurrentPost,
        projects: apiActions.projects.setCurrentProject,
        collections: apiActions.collections.setCurrentCollection
      }
      return { mode, reloadActions, setIdAction, id }
    }),
    filter(({ mode }) => Boolean(mode)),
    mergeMap(({ mode, reloadActions, id, setIdAction }) =>
      _compact([reloadActions[mode]?.(), id && setIdAction[mode]?.(id)])
    )
  )

const getUrlParam = (
  state: RootState,
  mode?: PageMode,
  id?: number,
  featured?: ExploreParamType['featured']
) => {
  const postListParams = selectors.postListParams(state)

  let param: string = ''

  if (mode === 'posts') {
    param = `${param}${UrlUtils.objectToParam({
      ordering: postListParams.ordering
    })}`
  }

  return {
    url: `${route.EXPLORE.getUrl({ id, subRoute: mode, featured })}?${param}`
  }
}

const onChangeParameterEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.post.setSortBy, actions.project.setSortBy])),
    debounceTime(200),
    withLatestFrom(state$),
    map(([action, state]) => {
      const mode = selectors.mode(state)
      const id = selectors.selectItemId(state)

      return getUrlParam(state, mode, id)
    }),
    tap(({ url }) => {
      UrlUtils.replaceState(url)
      SessionStorage.save(LSKey.PROJECT_PAGE_SOURCE, url)
    }),
    ignoreElements()
  )

const openPageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.openPage)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const featured = action.payload.featured
      const subRoute = action.payload.subRoute ?? selectors.mode(state)
      return getUrlParam(state, subRoute, action.payload.id, featured)
    }),
    tap(({ url }) => {
      SessionStorage.save(LSKey.PROJECT_PAGE_SOURCE, url)
    }),
    map(({ url }) => appActions.pushTo(url))
  )

const setItemEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.setItem])),
    withLatestFrom(state$),
    map(([action, state]) => {
      const isFeatured = Boolean(action.payload.featured)
      const mode = isFeatured ? 'posts' : selectors.mode(state)

      const { payload } = action

      return { mode, id: payload.id }
    }),
    mergeMap(({ mode, id }) => [
      apiActions.social.setCurrentPost(mode === 'posts' || mode === 'featured' ? id : undefined),
      apiActions.projects.setCurrentProject(mode === 'projects' ? id : undefined),
      apiActions.collections.setCurrentCollection(mode === 'collections' ? id : undefined)
    ])
  )

const loadPostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.post.reload)),
    withLatestFrom(state$),
    delay(300),
    map(([_, state]) => {
      const listParams = selectors.postListParams(state)
      return { listParams }
    }),
    map(({ listParams }) => apiActions.social.listPost({ param: listParams, reloadList: true }))
  )

const onUnmountEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.onUnmount)),
    mergeMap(() => [
      apiActions.social.setCurrentPost(undefined),
      apiActions.projects.setCurrentProject(undefined),
      apiActions.collections.setCurrentCollection(undefined)
    ])
  )

const reloadPostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.post.setSortBy)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.postListParams(state)
      return { listParams }
    }),
    map(({ listParams }) => apiActions.social.listPost({ param: listParams, reloadList: true }))
  )

const reloadProjectsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([actions.project.setSortBy, actions.project.reload])),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.projectListParams(state)
      return { listParams }
    }),
    map(({ listParams }) => apiActions.projects.list({ param: listParams, reloadList: true }))
  )
const reloadCollectionsEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.collection.reload)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const collection = selectors.collection(state)
      return collection
    }),
    map(({ listType, listParams }) =>
      apiActions.collections.list({ param: listParams, reloadList: true, listType })
    )
  )
const updateSearchEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.collection.updateSearch)),
    debounceTime(1200),
    map(() => actions.collection.reload())
  )

const findImageToShareEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.findImageToShare)),
    withLatestFrom(state$),
    map(([_]) => push(MAIN_PAGE))
  )

export const epics = combineEpics(
  onChangeParameterEpic,
  openPageEpic,
  onUnmountEpic,
  setItemEpic,
  updateSearchEpic,
  initiateDataEpic,
  loadPostEpic,
  findImageToShareEpic,
  reloadPostEpic,
  reloadCollectionsEpic,
  reloadProjectsEpic
)
export default reducer
