import React, { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { RootState, RootActionType } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import { ReduxProps } from 'utils/Types'
import { addCreditActions, brainTreePaymentSelector, brainTreePaymentActions } from '../../duck'
import BrainTreeHostedField from './BraintreeHostedField'
import CreditCardBrandIcon, { CreditCardBrandIconProps } from 'components/Icon/CreditCardBrandIcon'
import { BraintreePaymentMethod } from 'models/ApiModels'
import Typography from 'components/Typography'
import Button from 'components/Button'

const Utils = {
  getPaymentMethodText: (paymentMethod?: BraintreePaymentMethod | null) => {
    return paymentMethod ? (
      <>
        {paymentMethod?.card_type}
        <span className="ml-2 block">
          ••••&nbsp;
          {paymentMethod?.masked_number?.substring(paymentMethod?.masked_number?.length - 4)}
        </span>
      </>
    ) : (
      ''
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  brainTreePayment: brainTreePaymentSelector.brainTreePayment(state),
  brainTreePaymentMethod: apiSelectors.brainTreePaymentMethod(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      getInitialMonetizationData: addCreditActions.getInitialMonetizationData,
      setChangePaymentMethod: brainTreePaymentActions.setChangePaymentMethod
    },
    dispatch
  )

export type CardPanelProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  className?: string
  loadingListener?: (loading?: boolean | string) => void
  hideErrorMessage?: boolean
  hide?: boolean
  disableChangeCard?: boolean
}

const CardPanel: React.FC<CardPanelProps> = ({
  className,
  hide,
  hideErrorMessage,
  disableChangeCard,
  brainTreePayment,
  brainTreePaymentMethod,
  setChangePaymentMethod,
  getInitialMonetizationData
}) => {
  useEffect(() => {
    getInitialMonetizationData()
  }, [getInitialMonetizationData])

  const { isChangePaymentMethod } = brainTreePayment
  const hasCardDetail = Boolean(brainTreePaymentMethod?.masked_number)
  const showCardPlaceholderRaw = isChangePaymentMethod ? false : hasCardDetail
  const showCardPlaceholder = disableChangeCard ? false : showCardPlaceholderRaw

  return !hide ? (
    <>
      {showCardPlaceholder && (
        <div className={twMerge('flex items-center justify-between', className)}>
          <div className="flex items-center">
            <CreditCardBrandIcon
              type={brainTreePaymentMethod?.card_type as CreditCardBrandIconProps['type']}
              className="mr-2"
            />

            <Typography
              variant="body1"
              className="pointer-events-none flex select-none flex-wrap text-left"
            >
              {Utils.getPaymentMethodText(brainTreePaymentMethod)}
            </Typography>
          </div>

          <Button onClick={() => setChangePaymentMethod(true)} color="secondary">
            CHANGE CARD
          </Button>
        </div>
      )}

      <BrainTreeHostedField
        className={className}
        hide={showCardPlaceholder}
        hideErrorMessage={hideErrorMessage}
      />
    </>
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPanel)
