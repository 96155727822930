import { TextTransform, UrlUtils } from 'utils/TextUtils'
import produce from 'immer'
import _compact from 'lodash/compact'
import _toNumber from 'lodash/toNumber'
import { combineEpics, Epic } from 'redux-observable'
import {
  filter,
  map,
  withLatestFrom,
  ignoreElements,
  delay,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import {
  GenerateResolution,
  TIProjectCreateReq,
  TIProjectGenerateReq,
  GENERATE_RESOLUTION_PAIR,
  BASE_GENERATE_SIZE,
  REFINEMENT_STEPS
} from 'models/ApiModels'
import { replace } from 'redux-first-history'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'
import { route } from 'routes'
import { of, merge, from } from 'rxjs'
import { errorUtils } from 'utils/DataProcessingUtils'
import MixPanelUtils, { DataUtils, Events } from 'utils/MixPanelUtils'
import SentryUtils from 'utils/SentryUtils'
import { values } from 'appConstants'
import { SelectBaseItemType } from 'components/Select/SelectBase'
import { ImageEditData } from 'components/ImageCropper'
import { ShowUpsellParam } from 'duck/AppDuck/BannerDuck'

// Constants
const NAMESPACE = '@@page/TextToImagePage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)
export const GENERATING_POOL_INTERVAL = 2000
export const ADDITIONAL_THRESHOLD_GENERATE = 40 //in second
export const LOAD_OUTPUT_AFTER_GENERATE_LOADING = 'load_output_after_generate'

export const ADVANCED_OPTIONS_UPSELL_CONFIG: ShowUpsellParam = {
  dismissable: true,
  upsellLocation: 'global',
  contentMode: 'text-image-advanced',
  position: 'float-bottom-banner-overlay'
}

export type LogTextToImageDoActionParam = Pick<
  Events['PROJECT__TEXT_TO_IMAGE_DO_ACTION']['properties'],
  'do_action_type' | 'do_action_value'
>

export type FormData = Omit<TIProjectGenerateReq, 'id' | 'width' | 'height' | 'image'> & {
  resolution?: string
  imageFileOriginal?: File
  imageFileCropped?: File
  imageFileEdit?: ImageEditData
  image?: number
}

export const Utils = {
  getResolutionText: (data: GenerateResolution): string => `${data.width}-${data.height}`,
  getGenerateParamFromText: (
    data?: string
  ): Omit<GenerateResolution, 'aspectRatio'> | undefined => {
    const split = data && data.split('-')
    return split
      ? {
          width: _toNumber(split[0]),
          height: _toNumber(split[1])
        }
      : undefined
  }
}

// Actions
export const textToImageActions = {
  initTextToImage: createAction(creator('INIT_TEXT_TO_IMAGE'))<{
    id: number
  }>(),
  createTIProject: createAction(creator('CREATE_TI_PROJECT'))<TIProjectCreateReq>(),
  updateFormData: createAction(creator('UPDATE_FORM_DATA'))<Partial<FormData>>(),
  removeBaseImage: createAction(creator('REMOVE_BASE_IMAGE'))(),
  addBaseImage: createAction(creator('ADD_BASE_IMAGE'))<number>(),
  setBaseImage: createAction(creator('SET_BASE_IMAGE'))<number>(),
  generate: createAction(creator('GENERATE'))(),
  setOpenResultView: createAction(creator('OPEN_RESULT_VIEW'))<boolean>(),
  downloadAll: createAction(creator('DOWNLOAD_ALL'))(),
  loadAllResult: createAction(creator('LOAD_ALL_RESULT'))(),
  setScrollToTop: createAction(creator('SCROLL_TO_TOP'))<boolean>(),
  setGenerating: createAction(creator('SET_GENERATING'))<boolean>(),
  copyText: createAction(creator('COPY_TEXT'))<string>(),
  logTextToImageDoAction: createAction(
    creator('LOG_TEXT_TO_IMAGE_DO_ACTION')
  )<LogTextToImageDoActionParam>()
}

export const GENERATE_RESOLUTION_OPTIONS: SelectBaseItemType<string>[] =
  GENERATE_RESOLUTION_PAIR.map(value => ({
    label: `${value.width} x ${value.height} (${value.aspectRatio[0]}:${value.aspectRatio[1]})`,
    value: Utils.getResolutionText(value)
  }))

export const REFINEMENT_STEP_OPTIONS: SelectBaseItemType<number>[] = REFINEMENT_STEPS.map(
  value => ({
    label: `${value}`,
    value: _toNumber(value)
  })
)

export const INITIAL_FORM_DATA: FormData = {
  steps: 50,
  anchor: undefined,
  image: undefined,
  input: undefined,
  resolution: GENERATE_RESOLUTION_OPTIONS[0].value
}

// Selectors
const selectTextToImage = (state: RootState) => state.container.textToImagePage
const id = createSelector(selectTextToImage, textToImage => textToImage.id)
const formData = createSelector(selectTextToImage, textToImage => textToImage.formData)

const currentFormData = createSelector(id, formData, (id, formData) =>
  id ? formData[id] : undefined
)

const selectCurrentImage = createSelector(
  currentFormData,
  apiSelectors.userImages,
  (formData, userImages) => ({
    imageFileOriginal: formData?.imageFileOriginal,
    imageFileCropped: formData?.imageFileCropped,
    imageFileEdit: formData?.imageFileEdit,
    image: userImages[formData?.image ?? 0]
  })
)
const selectHasCurrentImage = createSelector(
  selectCurrentImage,
  currentImage => !!(currentImage.imageFileCropped ?? currentImage.image)
)

const selectIsGenerating = createSelector(
  selectTextToImage,
  textToImage => textToImage.isGenerating
)

export const textToImageSelectors = {
  currentFormData,
  currentInput: createSelector(currentFormData, formData => formData?.input),
  currentResolution: createSelector(
    currentFormData,
    formData => formData?.resolution ?? INITIAL_FORM_DATA.resolution
  ),
  currentStep: createSelector(
    currentFormData,
    formData => formData?.steps ?? INITIAL_FORM_DATA.steps
  ),
  currentAnchor: createSelector(
    currentFormData,
    apiSelectors.textToImageOutputs,
    (formData, textToImageOutputs) => textToImageOutputs[formData?.anchor ?? 0]
  ),
  currentImage: selectCurrentImage,
  hasCurrentInput: createSelector(
    currentFormData,
    selectHasCurrentImage,
    (formData, hasCurrentImage) => Boolean(formData?.input || hasCurrentImage)
  ),
  openResultView: createSelector(selectTextToImage, data => data.openResultView),
  scrollToTop: createSelector(selectTextToImage, data => data.scrollToTop),
  textToImage: selectTextToImage,
  lastOutputRequest: createSelector(
    apiSelectors.currentTextToImageProjectOutputList,
    outputList => outputList?.lastListReq
  ),
  generateLoading: createSelector(
    selectIsGenerating,
    apiSelectors.loading['textToImage.generateTIProject'],
    apiSelectors.loading['textToImage.listTIProjectOutput'],
    (isGenerating, generateTIProjectLoading, listTIProjectOutputLoading) =>
      Boolean(isGenerating || generateTIProjectLoading) ||
      Boolean(listTIProjectOutputLoading === LOAD_OUTPUT_AFTER_GENERATE_LOADING)
  ),
  isGenerating: selectIsGenerating,
  generateCount: createSelector(
    selectTextToImage,
    proArtFilterPage => proArtFilterPage.generateCount
  )
}

// Reducer
export type TextToImageState = {
  id?: number
  openResultView?: boolean
  isGenerating: boolean
  generateCount?: number
  scrollToTop: boolean
  formData: Record<number, FormData>
}

export const INITIAL: TextToImageState = {
  id: undefined,
  isGenerating: false,
  generateCount: 0,
  openResultView: false,
  scrollToTop: false,
  formData: {}
}

const reducer = produce((state: TextToImageState, { type, payload }) => {
  switch (type) {
    case getType(textToImageActions.initTextToImage): {
      const { id } = payload as ActionType<typeof textToImageActions.initTextToImage>['payload']

      state.id = id

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA)
          }
        }
      }

      return
    }
    case getType(textToImageActions.setOpenResultView): {
      const openResultView = payload as ActionType<
        typeof textToImageActions.setOpenResultView
      >['payload']

      state.openResultView = openResultView
      return
    }
    case getType(textToImageActions.updateFormData): {
      const id = state.id
      const formData = payload as ActionType<typeof textToImageActions.updateFormData>['payload']

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            ...formData
          }
        }
      }
      return
    }
    case getType(textToImageActions.removeBaseImage): {
      const id = state.id

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            image: undefined,
            imageFileOriginal: undefined,
            imageFileCropped: undefined,
            imageFileEdit: undefined
          }
        }
      }
      return
    }
    case getType(textToImageActions.setBaseImage): {
      const id = state.id
      const baseImage = payload as ActionType<typeof textToImageActions.setBaseImage>['payload']

      if (id) {
        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            image: baseImage,
            imageFileOriginal: undefined,
            imageFileCropped: undefined,
            imageFileEdit: undefined
          }
        }
      }
      return
    }
    case getType(textToImageActions.setGenerating): {
      const generating = payload as ActionType<typeof textToImageActions.setGenerating>['payload']

      state.isGenerating = generating
      state.generateCount = generating
        ? (state.generateCount ?? 0) + GENERATING_POOL_INTERVAL / 1000
        : undefined
      return
    }
    case getType(textToImageActions.setScrollToTop): {
      const scrollToTop = payload as ActionType<typeof textToImageActions.setScrollToTop>['payload']

      state.scrollToTop = scrollToTop
      return
    }
  }
}, INITIAL)

// Epics

const createTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(textToImageActions.createTIProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      name: action.payload.name
    })),
    filter(({ name }) => Boolean(name)),
    switchMap(({ name }) =>
      action$.pipe(
        filter(isActionOf(apiActions.textToImage.createTIProjectResponse)),
        take(1),
        withLatestFrom(state$),
        map(([action, state]) => ({
          data: action.payload
        })),
        tap(({ data }) => {
          MixPanelUtils.track<'PROJECT__CREATE'>(
            'Project - Create',
            DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
              textToImageProject: data
            })
          )
        }),
        map(({ data }) => replace(`${route.TEXT_TO_IMAGE_PROJECTS.getUrl({ id: data?.id })}`)),
        startWith(
          apiActions.textToImage.createTIProject({
            name
          })
        )
      )
    )
  )

const generateImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(textToImageActions.generate)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      canAccessT2IAdvanceOptions:
        apiSelectors.equity(state)?.upsellState?.canAccessT2IAdvanceOptions,
      formData: textToImageSelectors.currentFormData(state),
      hasCurrentInput: textToImageSelectors.hasCurrentInput(state),
      currentTextToImageProject: apiSelectors.currentTextToImageProject(state)
    })),
    map(({ currentTextToImageProject, formData, hasCurrentInput, canAccessT2IAdvanceOptions }) => {
      const id = currentTextToImageProject?.id || 0
      const generateResolution = Utils.getGenerateParamFromText(formData?.resolution)
      const generateParam: TIProjectGenerateReq = {
        id,
        input: formData?.input,
        image: formData?.imageFileCropped ?? formData?.imageFileOriginal ?? formData?.image ?? null,
        height: generateResolution?.height ?? BASE_GENERATE_SIZE,
        width: generateResolution?.width ?? BASE_GENERATE_SIZE
      }
      if (canAccessT2IAdvanceOptions && formData?.anchor) {
        generateParam['anchor'] = formData?.anchor
      }
      if (canAccessT2IAdvanceOptions && formData?.steps) {
        generateParam['steps'] = formData?.steps
      }

      return {
        currentTextToImageProject,
        generateParam,
        hasCurrentInput
      }
    }),
    filter(({ generateParam: { id, input }, hasCurrentInput }) => {
      const hasId = Boolean(id)

      return hasId && hasCurrentInput
    }),
    mergeMap(({ generateParam }) =>
      action$.pipe(
        filter(isActionOf(apiActions.textToImage.generateTIProjectResponse)),
        take(1),
        tap(({ payload }) => {
          const projectData = DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
            textToImageProject: payload
          })

          MixPanelUtils.track<'PROJECT__TEXT_TO_IMAGE_GENERATE'>(
            'Project Text To Image - Generate Image',
            {
              ...projectData,
              text_to_image_input: generateParam.input ?? '',
              text_to_image_resolution: `${generateParam.width} x ${generateParam.height}`,
              text_to_image_has_image_input: !!generateParam.image
            }
          )
        }),
        ignoreElements(),
        startWith(apiActions.textToImage.generateTIProject(generateParam))
      )
    )
  )

const downloadAllEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([textToImageActions.downloadAll, textToImageActions.loadAllResult])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      outputs: apiSelectors.currentTextToImageProject(state)?.outputs ?? [],
      currentTextToImageProjectId: apiSelectors.currentTextToImageProjectId(state),
      lastRequest: textToImageSelectors.lastOutputRequest(state)
    })),
    mergeMap(({ lastRequest, currentTextToImageProjectId = 0, outputs }) =>
      merge(
        of({ lastRequest, currentTextToImageProjectId }).pipe(
          filter(({ lastRequest, currentTextToImageProjectId }) =>
            Boolean(lastRequest && lastRequest.next && currentTextToImageProjectId)
          ),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.textToImage.listTIProjectOutputResponse)),
              take(1),
              map(() => textToImageActions.loadAllResult()),
              startWith(
                apiActions.textToImage.listTIProjectOutput({
                  param: { project: currentTextToImageProjectId, limit: 30 },
                  next: true
                })
              )
            )
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => Boolean(lastRequest && lastRequest.next)),
          map(() =>
            snackBarActions.show({
              content: `Load all Text To Art results...`,
              actionText: `${outputs.length} / ${lastRequest?.count}`
            })
          )
        ),
        of(lastRequest).pipe(
          filter(lastRequest => !Boolean(lastRequest && lastRequest.next)),
          withLatestFrom(state$),
          map(([_, state]) => ({
            textToImageProjectName: apiSelectors.currentTextToImageProject(state)?.name ?? '',
            outputs: apiSelectors.currentProArtFilterProject(state)?.outputs ?? []
          })),
          map(({ outputs, textToImageProjectName }) => ({
            files: outputs.map(output => ({
              fileUrl: output?.image?.file ?? '',
              imageName: `text-to-art-result_${textToImageProjectName}_${output?.image?.id}.${UrlUtils.getFileTypeFromUrl(output?.image?.file ?? '')}`
            })),
            textToImageProjectName
          })),
          map(({ files, textToImageProjectName }) =>
            downloaderActions.multiple.executeDownloadMultipleImage({
              files,
              fileName: `text-to-art_${textToImageProjectName}`
            })
          )
        )
      )
    )
  )

/* 

- Listen when upload list received
  - When failed, the status will become UNKNOWN
  - So when generate pooling is already running in sometimes, 
    then status become UNKNOWN, then stop the pooling
*/

const listenOnRetrieveTextToImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.textToImage.retrieveTIProjectResponse,
        apiActions.textToImage.generateTIProjectResponse
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isCurrentUserFree: apiSelectors.isCurrentUserFree(state),
      isGenerating: textToImageSelectors.isGenerating(state),
      generateCount: textToImageSelectors.generateCount(state),
      currentTextToImageProject: apiSelectors.currentTextToImageProject(state),
      id: action.payload.id
    })),
    map(({ isGenerating, generateCount = 0, currentTextToImageProject, id, isCurrentUserFree }) => {
      const currentTextToImageProjectId = currentTextToImageProject?.id ?? 0
      const status = currentTextToImageProject?.status
      const expect_finished = currentTextToImageProject?.expect_finished ?? 0
      const queue_length = currentTextToImageProject?.queue_length ?? 0

      const hasQueue = !!(
        expect_finished && generateCount < expect_finished + ADDITIONAL_THRESHOLD_GENERATE
      )

      const isGeneratingButFinished = isGenerating && status === 'FINISHED'
      const shouldContinueGenerateUnknownStatus = hasQueue && status === 'UNKNOWN'
      const isProcessing = status === 'PROCESSING'
      const shouldShowErrorDialog =
        isGenerating &&
        !shouldContinueGenerateUnknownStatus &&
        !isProcessing &&
        !isGeneratingButFinished

      return {
        errorExtra: {
          elapsed_pooling: generateCount,
          expect_finished,
          queue_length
        },
        isCurrentUserFree,
        currentTextToImageProjectId,
        id,
        isGeneratingButFinished,
        shouldContinueGenerateUnknownStatus,
        isProcessing,
        shouldShowErrorDialog
      }
    }),
    tap(({ shouldShowErrorDialog, errorExtra }) => {
      if (shouldShowErrorDialog) {
        SentryUtils.captureMessage(
          `Unable To Generate Text To Image in expected time`,
          errorExtra,
          'error'
        )
      }
    }),
    filter(({ currentTextToImageProjectId, id }) => currentTextToImageProjectId === id),
    mergeMap(
      ({
        currentTextToImageProjectId,
        isGeneratingButFinished,
        shouldContinueGenerateUnknownStatus,
        isProcessing,
        shouldShowErrorDialog,
        isCurrentUserFree
      }) =>
        _compact([
          textToImageActions.setGenerating(shouldContinueGenerateUnknownStatus || isProcessing),
          isGeneratingButFinished
            ? apiActions.textToImage.listTIProjectOutput({
                param: { project: currentTextToImageProjectId, limit: 30 },
                loadingMessage: LOAD_OUTPUT_AFTER_GENERATE_LOADING
              })
            : null,
          isGeneratingButFinished
            ? apiActions.textToImage.resetTextToImageQueueData(currentTextToImageProjectId)
            : null,
          isGeneratingButFinished && isCurrentUserFree ? apiActions.users.retrieveEquity() : null,
          isGeneratingButFinished ? textToImageActions.setScrollToTop(true) : null,
          shouldShowErrorDialog
            ? dialogActions.openDialog({
                [ErrorDialog.ERROR]: {
                  dialogName: ErrorDialog.ERROR,
                  title: 'Unable To Generate Text To Art  Result',
                  content: [
                    `We're unable to generate Text To Art Filter result in expected time.`,
                    `Please try again in few minutes`,
                    `if this keeps happening, contact us by click on the "Question" button or send an email to ${values.PLAYFORM_CONTACT_EMAIL}.`
                  ]
                }
              })
            : null
        ])
    )
  )
const listenRetrieveTextToImageErrorEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(({ payload }) => payload.type === getType(apiActions.textToImage.retrieveTIProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      error: action.payload,
      isGenerating: textToImageSelectors.isGenerating(state)
    })),
    mergeMap(({ isGenerating, error }) =>
      _compact([
        textToImageActions.setGenerating(false),
        isGenerating &&
          dialogActions.addDialog({
            [ErrorDialog.ERROR]: {
              dialogName: ErrorDialog.ERROR,
              title: `Unable to retrieve Text To Art Project data`,
              content: [
                errorUtils.flattenMessage(error).toString(),
                'Generating process is still working in the background, the result will be available when it finished. Try to refresh this page to see the finished results'
              ]
            }
          })
      ])
    )
  )

const setScrollToTopEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(textToImageActions.setScrollToTop)),
    filter(({ payload }) => Boolean(payload)),
    delay(1000),
    map(() => textToImageActions.setScrollToTop(false))
  )

const setCurrentTextToImageProjectEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.setCurrentTextToImageProject)),
    map(({ payload }) => textToImageActions.initTextToImage({ id: _toNumber(payload) }))
  )

const retrieveTIProjectListener: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.retrieveTIProjectResponse)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      currentProject: apiSelectors.currentTextToImageProject(state)
    })),
    filter(({ payload, currentProject }) => payload.id === currentProject?.id),
    map(({ currentProject }) =>
      textToImageActions.updateFormData({ image: currentProject?.image?.id })
    )
  )

const logTextToImageDoActionEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(textToImageActions.logTextToImageDoAction)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      currentTextToImageProject: apiSelectors.currentTextToImageProject(state)
    })),
    tap(({ currentTextToImageProject, ...payload }) => {
      const projectData = DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
        textToImageProject: currentTextToImageProject ?? undefined
      })
      MixPanelUtils.track<'PROJECT__TEXT_TO_IMAGE_DO_ACTION'>('Project Text To Image - Do Action', {
        ...projectData,
        ...payload
      })
    }),
    ignoreElements()
  )

const copyTextEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(textToImageActions.copyText)),
    mergeMap(({ payload }) =>
      from(navigator.clipboard.writeText(payload)).pipe(
        mergeMap(param =>
          merge(
            of(snackBarActions.show({ content: 'The input text copied into your clipboard!' })),
            of(
              textToImageActions.logTextToImageDoAction({
                do_action_type: 'copy_prompt',
                do_action_value: payload
              })
            ),
            of(param).pipe(
              delay(1500),
              map(() => snackBarActions.close())
            )
          )
        )
      )
    )
  )

const addBaseImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(textToImageActions.addBaseImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      adjustedImages: apiSelectors.adjustedImages(state),
      imageId: action.payload,
      userImages: apiSelectors.userImages(state)
    })),
    map(({ adjustedImages, imageId, userImages }) => {
      const imageData = userImages[imageId]
      const adjustedImage = adjustedImages[imageData?.adjust ?? 0]
      return {
        imageData,
        adjustedImage,
        hasAdjustedImageNotFetched: !!imageData.adjust && !adjustedImage,
        hasAdjustedImageFetched: !!imageData.adjust && !!adjustedImage,
        noAdjustedImage: !imageData.adjust
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ hasAdjustedImageNotFetched }) => hasAdjustedImageNotFetched),
          mergeMap(({ imageData }) =>
            action$.pipe(
              filter(isActionOf(apiActions.imageEnhancement.retrieveAdjustedImageResponse)),
              take(1),
              map(({ payload }) => textToImageActions.setBaseImage(payload?.output?.id ?? 0)),
              startWith(
                apiActions.imageEnhancement.retrieveAdjustedImage({
                  adjustedImageId: imageData.adjust ?? 0
                })
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ hasAdjustedImageFetched }) => hasAdjustedImageFetched),
          map(({ adjustedImage }) =>
            textToImageActions.setBaseImage(adjustedImage?.output?.id ?? 0)
          )
        ),
        of(param).pipe(
          filter(({ noAdjustedImage }) => noAdjustedImage),
          map(({ imageData }) => textToImageActions.setBaseImage(imageData?.id ?? 0))
        )
      )
    )
  )

export const epics = combineEpics(
  addBaseImageEpic,
  generateImageEpic,
  copyTextEpic,
  logTextToImageDoActionEpic,
  setCurrentTextToImageProjectEpic,
  setScrollToTopEpic,
  listenRetrieveTextToImageErrorEpic,
  listenOnRetrieveTextToImageEpic,
  downloadAllEpic,
  retrieveTIProjectListener,
  createTIProjectEpic
)
export default reducer
