import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appSelectors } from 'duck/AppDuck'
import { RootState } from 'duck'
import { InformationDialogs } from './InformationDialog'
import { ConfirmationDialogs } from './ConfirmationDialog'
import { ErrorDialogs } from './ErrorDialog'
import { values } from 'appConstants'
import { useDelayedData } from 'utils/Hooks'
import { dialogActions, DialogNameType, InformationDialogNameType } from 'duck/AppDuck/DialogDuck'
import { ChangeEmailDialogs } from './changeEmail'
import { DialogProps } from 'components/Dialog'

export type GeneralDialogItemProps = {
  activeDialog?: DialogNameType
  onClose: DialogProps['onClose']
}

export const DialogItemList: Record<InformationDialogNameType, React.FC<GeneralDialogItemProps>> = {
  ...InformationDialogs,
  ...ErrorDialogs,
  ...ConfirmationDialogs,
  ...ChangeEmailDialogs
}

const GeneralDialogs = () => {
  const dispatch = useDispatch()
  const activeDialog = useSelector((state: RootState) =>
    appSelectors.dialog.activeDialogOverlay(state)
  )

  const onClose = useCallback<NonNullable<DialogProps['onClose']>>(() => {
    dispatch(dialogActions.closeDialog())
  }, [dispatch])

  const activeDialogDelayed = useDelayedData(activeDialog, values.DIALOG_TRANSITION_DURATION)

  const DialogItem = DialogItemList[activeDialogDelayed as InformationDialogNameType]

  return DialogItem ? <DialogItem activeDialog={activeDialog} onClose={onClose} /> : null
}

export default GeneralDialogs
