import React, { useRef, useEffect } from 'react'
import { Layer, Rect, Stage } from 'react-konva'
import { Stage as StageClass } from 'konva/lib/Stage'
import { Layer as LayerClass } from 'konva/lib/Layer'

type CanvasProps = {
  className?: string
  width: number
  height: number
  getStage?: Function
  getLayer?: Function
  layers?: readonly string[]
  fill?: string
}

const Canvas: React.FC<CanvasProps> = props => {
  const { width, className, height, getStage, getLayer, layers, fill } = props
  const layerRef = useRef<LayerClass | null>(null)
  const stageRef = useRef<StageClass | null>(null)
  const currentLayer = layerRef.current
  const currentStage = stageRef.current

  useEffect(() => {
    getLayer?.(currentLayer)
  }, [currentLayer, getLayer])

  useEffect(() => {
    getStage?.(currentStage)
  }, [currentStage, getStage])

  return (
    <Stage className={className} ref={stageRef} width={width} height={height}>
      {fill ? (
        <Layer>
          <Rect width={width} height={height} fill={fill} />
        </Layer>
      ) : null}

      {layers?.length ? (
        <>
          {layers.map(value => (
            <Layer fill={fill} id={value} key={value} />
          ))}
        </>
      ) : (
        <Layer ref={layerRef} fill={fill} id={'maman'} />
      )}
    </Stage>
  )
}

export default Canvas
