import _map from 'lodash/map'
import { PageMode, FeaturedType } from 'containers/ExplorePage/duck'
import { MixPageTabType } from 'containers/MixImagePanel/duck/Models'

// Primary Route
export const EXPLORE = '/explore'
export const NOTIFICATION = '/notification'
export const USER_DEBUG = '/internal/debugger'
export const SIGN_IN = '/login'
export const ACCOUNT = '/account'
export const ARTISTS = '/artists'

// Primary Home & Project Route
export const TRAIN_PROJECTS = 'trainings'
export const MIX_PROJECTS = 'mixes'
export const SKETCH_PROJECTS = 'sketches'
export const PRO_FILTER_PROJECTS = 'stylizes'
export const COLLECTIONS = 'collections'
export const SAVED_RESULTS = 'saved-results'
export const UPSCALES = 'upscales'
export const DOWNLOADS = 'downloads'
export const NFTS = 'nfts'
export const TEXT_TO_IMAGE_PROJECTS = 'text-to-images'
export const TEXT_TO_VIDEO_PROJECTS = 'text-to-videos'
export const GENERIC_APP_PROJECTS = 'ai-apps'
export const SKETCHTEXT_TO_IMAGE_PROJECTS = 'sketchtext-to-images'

// Legacy Route
export const TRAIN_PROJECT = '/project'
export const STYLIZE = '/pro-art-filter'
export const MIX_IMAGE = '/mix-image'
export const SKETCH_TO_IMAGE = '/sketch-to-image'

// Sub route
export const SUB_INPUT = 'input'
export const SUB_RESULT = 'result'
export const SUB_GENERATE = 'generate'
export const SUB_MIXIMAGE = 'mix-image'
export const SUB_SAVED = 'saved'
export const SUB_VIDEO = 'video'

//Param
export const routeParam = 'route'
export const subRouteParam = 'subRoute'
export const featuredParam = 'featured'
export const orderParam = 'order'
export const batchParam = 'batch'
export const idParam = 'id'
export const legacyProjectKeyParam = 'projectKey'
export const artistUrlParam = 'artistUrl'
export const resultIdParam = 'resultId'

//Action
export const CLOSE_TAB_ACTION = 'close-tab'
export const NEW_PROJECT_ACTION = 'new'
export const CREATE_NEW_PROJECT_ACTION = 'create'

export const NAVBAR_MENU = [EXPLORE, NOTIFICATION, GENERIC_APP_PROJECTS, SIGN_IN, ''] as const
export type NavbarMenuType = (typeof NAVBAR_MENU)[number]

export const RouteHomeList = [
  TRAIN_PROJECTS,
  MIX_PROJECTS,
  SKETCH_PROJECTS,
  PRO_FILTER_PROJECTS,
  TEXT_TO_IMAGE_PROJECTS,
  TEXT_TO_VIDEO_PROJECTS,
  GENERIC_APP_PROJECTS,
  SKETCHTEXT_TO_IMAGE_PROJECTS,
  COLLECTIONS,
  SAVED_RESULTS,
  UPSCALES,
  DOWNLOADS,
  NFTS
] as const

export const RouteProjectPageList = [
  GENERIC_APP_PROJECTS,
  TRAIN_PROJECTS,
  MIX_PROJECTS,
  SKETCH_PROJECTS,
  PRO_FILTER_PROJECTS,
  TEXT_TO_IMAGE_PROJECTS,
  TEXT_TO_VIDEO_PROJECTS,
  SKETCHTEXT_TO_IMAGE_PROJECTS
] as const

export type RouteHomeType = (typeof RouteHomeList)[number]
export type RouteProjectsPageType = (typeof RouteProjectPageList)[number]

export const SUB_ROUTE_RESULT_LIST = [
  SUB_INPUT,
  SUB_RESULT,
  SUB_GENERATE,
  SUB_MIXIMAGE,
  SUB_SAVED,
  SUB_VIDEO
] as const
export type SubRouteResultType = (typeof SUB_ROUTE_RESULT_LIST)[number]

export const SUB_ROUTE_MIX_IMAGE_LIST = [SUB_VIDEO] as const

export type SubRouteMixImageType = (typeof SUB_ROUTE_MIX_IMAGE_LIST)[number]

export const SUB_ROUTE_UPSCALE_PAGE = ['edit'] as const
export type SubRouteUpscaleType = (typeof SUB_ROUTE_UPSCALE_PAGE)[number]

export const SUB_ROUTE_ARTISTS = ['projects', 'collections', 'posts', 'about'] as const
export type SubRouteArtistsType = (typeof SUB_ROUTE_ARTISTS)[number]

export const SUB_ROUTE_GENERICAPPPROJECT_LIST = [SUB_GENERATE, SUB_RESULT, SUB_SAVED] as const
export type SubRouteGenericAppProjectType = (typeof SUB_ROUTE_GENERICAPPPROJECT_LIST)[number]

export interface DetailParamType {
  id?: string
}
export type HomeParamType = DetailParamType & {
  route: RouteHomeType
  subRoute?: SubRouteUpscaleType
}

export type ExploreParamType = DetailParamType & {
  subRoute: PageMode
  featured?: FeaturedType
}

export type PostParamType = {
  postId: string
  subRoute: PageMode
}

export type MixImageProjectParamType = DetailParamType & {
  subRoute?: MixPageTabType
}

export type GenericAppProjectParamType = DetailParamType & {
  subRoute?: SubRouteGenericAppProjectType
  resultId?: number
}

export type UpscalesParamType = DetailParamType & {
  subRoute?: SubRouteUpscaleType
}
export type ProfileParamType = {
  subRoute: string
}

export type AccountParamType = {
  subRoute: string
}
export type ArtistParamType = {
  artistUrl: string
  subRoute?: SubRouteArtistsType
}

export type ProjectResultParamType = DetailParamType & {
  subRoute?: SubRouteResultType
  order?: string
  batch?: string
}

const RouteList = [
  'ROOT',
  'TRAIN_PROJECTS',
  'MIX_PROJECTS',
  'SKETCH_PROJECTS',
  'PRO_FILTER_PROJECTS',
  'TEXT_TO_IMAGE_PROJECTS',
  'TEXT_TO_VIDEO_PROJECTS',
  'GENERIC_APP_PROJECTS',
  'SKETCHTEXT_TO_IMAGE_PROJECTS',

  'COLLECTIONS',
  'SAVED_RESULTS',
  'UPSCALES',
  'DOWNLOADS',
  'NFTS',
  'PROFILE',
  'ARTISTS',

  'EXPLORE',
  'PLAYGROUND',
  'UI_SHOWCASE',
  'USER_DEBUG',
  'WELCOME',
  'SIGN_IN',
  'PROCESSES',
  'NOT_FOUND',

  /* Legacy route, */
  'TRAIN_PROJECT',
  'SKETCH_TO_IMAGE',
  'MIX_IMAGE',
  'STYLIZE'
] as const

export type RouteType = (typeof RouteList)[number]

export type RouteTypes = {
  [key in RouteType]: {
    paths: string[]
    getPath: (paramCount?: number, additionalString?: string) => string
    getUrl: Function
  }
}

const getPathFactory =
  (routeTypes: RouteType) =>
  (paramCount: number = route[routeTypes].paths.length, additionalString = '') =>
    `${constructChainParam(route[routeTypes].paths.slice(0, paramCount))}${additionalString}`

const constructChainParam = (params: Array<any>) => {
  let text = ''
  for (let i = 0; i < params.length; i++) {
    const param = params[i]
    if (!param) {
      break
    } else {
      text = i === 0 ? `${param}` : `${text}/${param}`
    }
  }
  return text
}

export const route: RouteTypes = {
  ROOT: {
    paths: [`/`, `:${routeParam}`, `:${idParam}`, `:${subRouteParam}`],
    getUrl: (param?: HomeParamType) => {
      const routeParam = param?.route
      const subRoute = param?.subRoute
      const id = param?.id
      return constructChainParam([route.ROOT.paths[0], routeParam, id, subRoute])
    },
    getPath: getPathFactory('ROOT')
  },
  TRAIN_PROJECTS: {
    paths: [
      `/${TRAIN_PROJECTS}`,
      `:${idParam}`,
      `:${subRouteParam}`,
      `:${batchParam}`,
      `:${orderParam}`
    ],
    getPath: getPathFactory('TRAIN_PROJECTS'),
    getUrl: (param?: ProjectResultParamType) => {
      const id = param?.id
      const subRoute = param?.subRoute
      const order = param?.order
      const batch = param?.batch

      return constructChainParam([route.TRAIN_PROJECTS.paths[0], id, subRoute, batch, order])
    }
  },
  MIX_PROJECTS: {
    paths: [`/${MIX_PROJECTS}`, `:${idParam}`, `:${subRouteParam}`],
    getPath: getPathFactory('MIX_PROJECTS'),
    getUrl: (param?: MixImageProjectParamType) => {
      const mixImageProjectId = param?.id
      const subRoute = param?.subRoute
      return constructChainParam([route.MIX_PROJECTS.paths[0], mixImageProjectId, subRoute])
    }
  },
  SKETCH_PROJECTS: {
    paths: [`/${SKETCH_PROJECTS}`, `:${idParam}`],
    getPath: getPathFactory('SKETCH_PROJECTS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id
      return constructChainParam([route.SKETCH_PROJECTS.paths[0], id])
    }
  },
  PRO_FILTER_PROJECTS: {
    paths: [`/${PRO_FILTER_PROJECTS}`, `:${idParam}`],
    getPath: getPathFactory('PRO_FILTER_PROJECTS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id
      return constructChainParam([route.PRO_FILTER_PROJECTS.paths[0], id])
    }
  },
  TEXT_TO_IMAGE_PROJECTS: {
    paths: [`/${TEXT_TO_IMAGE_PROJECTS}`, `:${idParam}`],
    getPath: getPathFactory('TEXT_TO_IMAGE_PROJECTS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id
      return constructChainParam([route.TEXT_TO_IMAGE_PROJECTS.paths[0], id])
    }
  },
  TEXT_TO_VIDEO_PROJECTS: {
    paths: [`/${TEXT_TO_VIDEO_PROJECTS}`, `:${idParam}`],
    getPath: getPathFactory('TEXT_TO_VIDEO_PROJECTS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id
      return constructChainParam([route.TEXT_TO_VIDEO_PROJECTS.paths[0], id])
    }
  },
  GENERIC_APP_PROJECTS: {
    paths: [`/${GENERIC_APP_PROJECTS}`, `:${idParam}`, `:${subRouteParam}`, `:${resultIdParam}`],
    getPath: getPathFactory('GENERIC_APP_PROJECTS'),
    getUrl: (param?: GenericAppProjectParamType) => {
      const id = param?.id
      const subRoute = param?.subRoute
      const resultId = param?.resultId

      return constructChainParam([route.GENERIC_APP_PROJECTS.paths[0], id, subRoute, resultId])
    }
  },
  SKETCHTEXT_TO_IMAGE_PROJECTS: {
    paths: [
      `/${SKETCHTEXT_TO_IMAGE_PROJECTS}`,
      `:${idParam}`,
      `:${subRouteParam}`,
      `:${resultIdParam}`
    ],
    getPath: getPathFactory('SKETCHTEXT_TO_IMAGE_PROJECTS'),
    getUrl: (param?: GenericAppProjectParamType) => {
      const id = param?.id
      const subRoute = param?.subRoute
      const resultId = param?.resultId

      return constructChainParam([
        route.SKETCHTEXT_TO_IMAGE_PROJECTS.paths[0],
        id,
        subRoute,
        resultId
      ])
    }
  },

  COLLECTIONS: {
    paths: [`/${COLLECTIONS}`, `:${idParam}`],
    getPath: getPathFactory('COLLECTIONS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id ?? ''
      return constructChainParam([route.COLLECTIONS.paths[0], id])
    }
  },
  SAVED_RESULTS: {
    paths: [`/${SAVED_RESULTS}`, `:${idParam}`],
    getPath: getPathFactory('SAVED_RESULTS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id ?? ''
      return constructChainParam([route.SAVED_RESULTS.paths[0], id])
    }
  },
  UPSCALES: {
    paths: [`/${UPSCALES}`, `:${idParam}`, `:${subRouteParam}`],
    getPath: getPathFactory('UPSCALES'),
    getUrl: (param?: UpscalesParamType, search: string = '') => {
      const id = param?.id ?? ''
      const subRoute = param?.subRoute

      return `${constructChainParam([route.UPSCALES.paths[0], id, subRoute])}${search}`
    }
  },
  NFTS: {
    paths: [`/${NFTS}`, `:${idParam}`],
    getPath: getPathFactory('NFTS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id ?? ''
      return constructChainParam([route.NFTS.paths[0], id])
    }
  },
  DOWNLOADS: {
    paths: [`/${DOWNLOADS}`, `:${idParam}`],
    getPath: getPathFactory('DOWNLOADS'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id ?? ''
      return constructChainParam([route.DOWNLOADS.paths[0], id])
    }
  },
  PROFILE: {
    paths: [`${ACCOUNT}`, `${subRouteParam}`],
    getPath: getPathFactory('PROFILE'),
    getUrl: (param: ProfileParamType = { subRoute: '' }) => {
      const { subRoute } = param
      return constructChainParam([route.PROFILE.paths[0], subRoute])
    }
  },
  ARTISTS: {
    paths: [`${ARTISTS}`, `:${artistUrlParam}`, `:${subRouteParam}`],
    getPath: getPathFactory('ARTISTS'),
    getUrl: (param: ArtistParamType = { subRoute: undefined, artistUrl: '' }) => {
      const { subRoute, artistUrl } = param
      return constructChainParam([route.ARTISTS.paths[0], artistUrl, subRoute])
    }
  },
  EXPLORE: {
    paths: [`${EXPLORE}`, `:${subRouteParam}`, `:${idParam}`, `${featuredParam}`],
    getPath: getPathFactory('EXPLORE'),
    getUrl: (param?: ExploreParamType) => {
      const id = param?.id ?? ''
      const subRoute = param?.subRoute ?? ''
      const featured = param?.featured

      return constructChainParam([route.EXPLORE.paths[0], subRoute, id, featured])
    }
  },
  UI_SHOWCASE: {
    paths: [`/uishowcase`],
    getPath: getPathFactory('UI_SHOWCASE'),
    getUrl: () => route.UI_SHOWCASE.paths[0]
  },
  USER_DEBUG: {
    paths: [`${USER_DEBUG}`],
    getPath: getPathFactory('USER_DEBUG'),
    getUrl: () => route.USER_DEBUG.paths[0]
  },
  PLAYGROUND: {
    paths: [`/playground`],
    getPath: getPathFactory('PLAYGROUND'),
    getUrl: () => route.PLAYGROUND.paths[0]
  },
  WELCOME: {
    paths: ['/welcome'],
    getPath: getPathFactory('WELCOME'),
    getUrl: () => route.WELCOME.paths[0]
  },
  SIGN_IN: {
    paths: [SIGN_IN],
    getPath: getPathFactory('SIGN_IN'),
    getUrl: () => route.SIGN_IN.paths[0]
  },
  PROCESSES: {
    paths: ['/processes'],
    getPath: getPathFactory('PROCESSES'),
    getUrl: () => route.PROCESSES.paths[0]
  },
  NOT_FOUND: {
    paths: ['/404'],
    getPath: getPathFactory('NOT_FOUND'),
    getUrl: () => route.NOT_FOUND.paths[0]
  },

  /* -- Legacy project Routes -- */
  TRAIN_PROJECT: {
    paths: [
      `/${TRAIN_PROJECT}`,
      `:${idParam}`,
      `:${subRouteParam}`,
      `:${batchParam}`,
      `:${orderParam}`
    ],
    getPath: getPathFactory('TRAIN_PROJECT'),
    getUrl: (param?: ProjectResultParamType) => {
      const id = param?.id
      const subRoute = param?.subRoute
      const order = param?.order
      const batch = param?.batch
      return constructChainParam([route.TRAIN_PROJECTS.paths[0], id, subRoute, batch, order])
    }
  },
  SKETCH_TO_IMAGE: {
    paths: [`${SKETCH_TO_IMAGE}`, `:${idParam}`],
    getPath: getPathFactory('SKETCH_TO_IMAGE'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id
      return constructChainParam([SKETCH_TO_IMAGE, id])
    }
  },
  MIX_IMAGE: {
    paths: [`${MIX_IMAGE}`, `:${idParam}`, `:${subRouteParam}`],
    getPath: getPathFactory('MIX_IMAGE'),
    getUrl: (param?: MixImageProjectParamType) => {
      const mixImageProjectId = param?.id
      const subRoute = param?.subRoute
      return constructChainParam([MIX_IMAGE, mixImageProjectId, subRoute])
    }
  },
  STYLIZE: {
    paths: [`${STYLIZE}`, `${idParam}`],
    getPath: getPathFactory('STYLIZE'),
    getUrl: (param?: DetailParamType) => {
      const id = param?.id
      return constructChainParam([STYLIZE, id])
    }
  }
}

export const routeUtils = {
  getRoutesArray: () => {
    return _map(route, routeItem => routeItem.getUrl())
  },
  isInHomePage: (path: string) => {
    let isInHome = false
    RouteHomeList.forEach(route => {
      if (path.includes(route)) {
        isInHome = true
      }
    })
    return isInHome
  }
}

export const MAIN_PAGE = route.SKETCHTEXT_TO_IMAGE_PROJECTS.getUrl()
export const INITIAL_ROUTE = `${route.EXPLORE.getUrl({ subRoute: 'posts' })}?ordering=recent`
export const ROUTE_AFTER_LOGIN = INITIAL_ROUTE
export const ROUTE_CLOSE_TAB = `${window.location.href}?action=${CLOSE_TAB_ACTION}`
