import React from 'react'
import Helmet from 'react-helmet'

import UpscalePanel from '.'

const NewUpscalePanel: React.FC = () => {
  return (
    <div className="flex h-1/100 min-h-0 flex-auto flex-col">
      <Helmet>
        <title>{`Playform - Upscale Image`}</title>
      </Helmet>
      <UpscalePanel mode="standalone" position="static" />
    </div>
  )
}

export default NewUpscalePanel
