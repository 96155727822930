import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  mergeMap,
  withLatestFrom,
  tap
} from 'rxjs/operators'
import { ApiUtils } from 'utils'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { TIProjectListReq, TIProjectOutputListReq } from 'models/ApiModels'
import { push } from 'redux-first-history'
import { route } from 'routes'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'

const deleteTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.deleteTIProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      projectData: apiSelectors.textToImageProjects(state)[action.payload]
    })),
    switchMap(({ type, payload, projectData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.textToImage.deleteTIProject(payload).pipe(
          tap(() => {
            MixPanelUtils.track<'PROJECT__DELETE'>(
              'Project - Delete',
              DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
                textToImageProject: projectData
              })
            )
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.textToImage.deleteTIProjectResponse(payload),
            apiActions.users.retrieveEquity(),
            push(route.TEXT_TO_IMAGE_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.createTIProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.textToImage.createTIProject(payload).pipe(
          concatMap(response => [
            apiActions.textToImage.createTIProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const updateTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.updateTIProject)),
    switchMap(({ type, payload }) =>
      merge(
        of(
          sharedActions.setLoading({
            loading: true,
            type
          })
        ),
        ApiUtils.textToImage.updateTIProject(payload.id, payload).pipe(
          tap(response => {
            MixPanelUtils.track<'PROJECT__RENAME'>(
              'Project - Rename',
              DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
                textToImageProject: response.data
              })
            )
          }),
          concatMap(response => [
            apiActions.textToImage.updateTIProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        apiActions.textToImage.retrieveTIProject,
        apiActions.textToImage.setCurrentTextToImageProject
      ])
    ),
    filter(({ payload }) => Boolean(payload)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.textToImage.retrieveTIProject(payload ?? 0).pipe(
          mergeMap(response => [
            apiActions.textToImage.retrieveTIProjectResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.listTIProject)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const params = next ? { next: state.api.textToImage.textToImageProjects.lastReq?.next } : {}
      const extraParams = next ? undefined : (param as TIProjectListReq)
      return { next, params, extraParams, type: action.type }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.textToImage.listTIProject(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.textToImage.listTIProjectResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const listTIProjectOutputEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.listTIProjectOutput)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, loadingMessage } = action.payload
      const projectId = param?.project || 0
      const params = next
        ? { next: state.api.textToImage.outputLists[projectId]?.lastListReq?.next }
        : {}
      const extraParams = next ? undefined : (param as TIProjectOutputListReq)
      return { next, params, extraParams, type: action.type, param, loadingMessage }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type, param, loadingMessage }) =>
      merge(
        of(sharedActions.setLoading({ loading: loadingMessage ?? true, type })),
        ApiUtils.textToImage.listTIProjectOutput(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),

            apiActions.textToImage.listTIProjectOutputResponse({
              data: response.data,
              next,
              req: param
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const generateTIProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.generateTIProject)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      canTrain: apiSelectors.equity(state)?.upsellState?.canTrain ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrain }) => !canTrain),
          map(() =>
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'sketch',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            })
          )
        ),
        of(param).pipe(
          filter(({ canTrain }) => Boolean(canTrain)),
          map(({ payload, type }) => {
            const { id, input = '', width, height, image, anchor, steps } = payload
            const formData = new FormData()

            const isFormData = typeof image === 'object'
            const imageCasted = !isFormData ? `${image}` : image

            anchor && formData.append('anchor', `${anchor}`)
            steps && formData.append('steps', `${steps}`)
            formData.append('input', input)
            formData.append('width', `${width}`)
            formData.append('height', `${height}`)
            formData.append('image', imageCasted ?? '')

            return { id, payload, type, formData, isFormData }
          }),
          mergeMap(param =>
            merge(
              of(sharedActions.setLoading({ loading: true, type: param.type })),
              of(param).pipe(
                mergeMap(() =>
                  merge(
                    of(param).pipe(
                      filter(({ isFormData }) => isFormData),
                      mergeMap(({ type, payload, formData, id }) =>
                        ApiUtils.textToImage.generateTIProjectFormData(id, formData).pipe(
                          concatMap(response => [
                            sharedActions.setLoading({ loading: false, type }),
                            apiActions.textToImage.generateTIProjectResponse(response.data)
                          ]),
                          catchError(err =>
                            of(
                              sharedActions.setError({
                                error: err.response,
                                type,
                                req: payload
                              })
                            )
                          )
                        )
                      )
                    ),
                    of(param).pipe(
                      filter(({ isFormData }) => !isFormData),
                      mergeMap(({ type, payload, id }) =>
                        ApiUtils.textToImage.generateTIProject(id, payload).pipe(
                          concatMap(response => [
                            sharedActions.setLoading({ loading: false, type }),
                            apiActions.textToImage.generateTIProjectResponse(response.data)
                          ]),
                          catchError(err =>
                            of(
                              sharedActions.setError({
                                error: err.response,
                                type,
                                req: payload
                              })
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const deleteTIProjectOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.deleteTIProjectOutput)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      payload: action.payload,
      currentTextToImageProject: apiSelectors.currentTextToImageProject(state)
    })),

    switchMap(({ type, payload, currentTextToImageProject }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.textToImage.deleteTIProjectOutput(payload).pipe(
          tap(() => {
            if (currentTextToImageProject) {
              const projectData = DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
                textToImageProject: currentTextToImageProject
              })
              MixPanelUtils.track<'PROJECT__TEXT_TO_IMAGE_DO_ACTION'>(
                'Project Text To Image - Do Action',
                {
                  ...projectData,
                  do_action_type: 'delete_result',
                  do_action_value: payload
                }
              )
            }
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.textToImage.deleteTIProjectOutputResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const deleteAllTIProjectOutputEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.textToImage.deleteAllTIProjectOutput)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: action.type,
      payload: action.payload,
      currentTextToImageProject: apiSelectors.currentTextToImageProject(state)
    })),
    switchMap(({ type, payload, currentTextToImageProject }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.textToImage.deleteAllTIProjectOutput(payload).pipe(
          tap(() => {
            if (currentTextToImageProject) {
              const projectData = DataUtils.getProjectParam<'t2i_project'>('t2i_project', {
                textToImageProject: currentTextToImageProject
              })
              MixPanelUtils.track<'PROJECT__TEXT_TO_IMAGE_DO_ACTION'>(
                'Project Text To Image - Do Action',
                {
                  ...projectData,
                  do_action_type: 'clear_all_result',
                  do_action_value: payload
                }
              )
            }
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.textToImage.deleteAllTIProjectOutputResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const textToImageEpics = [
  deleteAllTIProjectOutputEpic,
  listTIProjectEpic,
  deleteTIProjectOutputEpic,
  generateTIProjectEpic,
  listTIProjectOutputEpic,
  deleteTIProjectEpic,
  createTIProjectEpic,
  retrieveTIProjectEpic,
  updateTIProjectEpic
]

export default textToImageEpics
