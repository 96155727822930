import { apiActions, SocialActionType } from '../actions'
import { SharedActionsType, sharedActions } from '../sharedActions'
import {
  PaginationResponse,
  Post,
  Comment,
  Thread,
  ThreadListResponse,
  CommentListResponse,
  BannerImage,
  TrainProject,
  Collection,
  User
} from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _without from 'lodash/without'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _reduce from 'lodash/reduce'
import _uniq from 'lodash/uniq'

export type CommentListData = {
  lists?: number[]
  lastRequest?: CommentListResponse
}
export type ThreadListData = {
  lists?: number[]
  lastRequest?: ThreadListResponse
}

export type SocialState = {
  currentPostId?: number
  postList?: number[]
  featuredPostList?: number[]
  lastPostListReq?: PaginationResponse<Post>
  bannerImages?: BannerImage[]
  posts: {
    [postsId: number]: Post
  }
  comments: {
    [commentId: number]: Comment
  }
  threads: {
    [threadId: number]: Thread
  }
  threadLists: {
    [postId: number]: ThreadListData
  }
  commentLists: {
    [threadId: number]: CommentListData
  }

  currentUserProfile?: string
  profileLists: {
    [user: string]: {
      userData: User
      postList?: number[]
      lastPostListReq?: PaginationResponse<Post>
      projectList?: number[]
      lastProjectListReq?: PaginationResponse<TrainProject>
      collectionList?: number[]
      lastCollectionListReq?: PaginationResponse<Collection>
    }
  }
}

export const initialSocialState: SocialState = {
  currentPostId: undefined,
  postList: undefined,
  bannerImages: undefined,
  featuredPostList: undefined,
  lastPostListReq: undefined,
  posts: {},
  comments: {},
  commentLists: {},

  threads: {},
  threadLists: {},

  currentUserProfile: undefined,
  profileLists: {}
}

export const socialReducer = createReducer<SocialState, SocialActionType | SharedActionsType>(
  initialSocialState
)
  .handleAction(apiActions.social.setCurrentPost, (state, action) => {
    return {
      ...state,
      currentPostId: action.payload
    }
  })
  .handleAction(apiActions.social.listPostResponse, (state, action) => {
    const { data, reloadList } = action.payload

    const results = data?.results ?? []
    const prevData = reloadList ? [] : state.postList ?? []
    const postList = _uniq([...prevData, ..._map(results, result => result.id)])
    const initial: SocialState['posts'] = {}

    return {
      ...state,
      postList,
      lastPostListReq: data,
      posts: {
        ...state.posts,
        ..._reduce(
          results,
          (data, result) => {
            data[result.id] = _merge(state.posts[result.id] ?? {}, result)
            return data
          },
          initial
        )
      }
    }
  })
  .handleAction(apiActions.social.listFeaturedPostResponse, (state, action) => {
    const { data } = action.payload

    const results = data?.results ?? []
    const featuredPostList = _map(results, result => result.id)
    const initial: SocialState['posts'] = {}

    return {
      ...state,
      featuredPostList,
      posts: {
        ...state.posts,
        ..._reduce(
          results,
          (data, result) => {
            data[result.id] = _merge(state.posts[result.id] ?? {}, result)
            return data
          },
          initial
        )
      }
    }
  })
  .handleAction(apiActions.social.createPostResponse, (state, action) => {
    const data = action.payload
    return {
      ...state,
      posts: {
        ...state.posts,
        [data.id]: data
      },
      postList: [data.id, ...(state.postList ?? [])]
    }
  })

  .handleAction(apiActions.social.retrievePostResponse, (state, action) => {
    const data = action.payload
    return {
      ...state,
      posts: {
        ...state.posts,
        [data.id]: data
      }
    }
  })
  .handleAction(apiActions.social.deletePostResponse, (state, action) => {
    const id = action.payload
    const { [id]: deletedPost, ...posts } = state.posts
    const postList = _without(state.postList || [], id)
    const featuredPostList = _without(state.featuredPostList || [], id)

    return {
      ...state,
      currentPostId: undefined,
      posts,
      postList,
      featuredPostList
    }
  })
  .handleAction(apiActions.social.updatePostResponse, (state, action) => {
    const id = action.payload.id
    const post = action.payload
    const isFeatured = post.is_featured
    const featuredPostList = state.featuredPostList
    const newFeaturedPostList = isFeatured
      ? _uniq([...(featuredPostList ?? []), id])
      : _without(featuredPostList, id)

    return {
      ...state,
      posts: {
        ...state.posts,
        [id]: { ...(state.posts[id] ?? {}), ...post }
      },
      featuredPostList: newFeaturedPostList
    }
  })
  .handleAction(apiActions.social.toggleClapInstantResponse, (state, action) => {
    const { req, clapType } = action.payload
    const post = state.posts[req.post]
    const currentCount = post.clap_counts ?? 0

    return {
      ...state,
      posts: {
        ...state.posts,
        [req.post]: {
          ...post,
          clap_counts: clapType === 'add' ? currentCount + 1 : currentCount - 1,
          is_clapped: clapType === 'add'
        }
      }
    }
  })
  .handleAction(apiActions.social.toggleClapResponse, (state, action) => {
    const { req, clapType, data } = action.payload

    if (data.status === 0) {
      const post = state.posts[req.post]
      const currentCount = post.clap_counts

      return {
        ...state,
        posts: {
          ...state.posts,
          [req.post]: {
            ...post,
            is_clapped: clapType !== 'add',
            clap_counts: clapType === 'add' ? currentCount - 1 : currentCount + 1
          }
        }
      }
    } else {
      return state
    }
  })
  .handleAction(apiActions.social.togglePostBookmarkInstantResponse, (state, action) => {
    const { postId, bookmark } = action.payload
    const post = state.posts[postId]

    return {
      ...state,
      posts: {
        ...state.posts,
        [postId]: {
          ...post,
          bookmark: bookmark ? -1 : null
        }
      }
    }
  })
  .handleAction(apiActions.social.togglePostBookmarkResponse, (state, action) => {
    const { data } = action.payload
    const post = data?.item ? state.posts[data?.item] : undefined

    return post
      ? {
          ...state,
          posts: {
            ...state.posts,
            [post.id]: { ...post, bookmark: data?.id ?? null }
          }
        }
      : state
  })
  .handleAction(apiActions.social.createCommentResponse, (state, action) => {
    const comment = action.payload
    const commentList = state.commentLists[comment.thread] ?? {}

    return {
      ...state,
      comments: {
        ...state.comments,
        [comment.id]: {
          ...comment,
          isNewData: true
        }
      },
      commentLists: {
        ...state.commentLists,
        [comment.thread]: {
          ...commentList,
          lists: [...(commentList.lists ?? []), comment.id]
        }
      }
    }
  })
  .handleAction(apiActions.social.createThreadResponse, (state, action) => {
    const thread = action.payload

    const threadList = state.threadLists[thread.post] ?? {}
    return {
      ...state,
      threads: {
        ...state.threads,
        [thread.id]: {
          ...thread,
          isNewData: true
        }
      },
      threadLists: {
        ...state.threadLists,
        [thread.post]: {
          ...threadList,
          lists: [thread.id, ...(threadList.lists ?? [])]
        }
      }
    }
  })
  .handleAction(apiActions.social.resetNewCommentHighlight, (state, action) => {
    const id = action.payload
    const comment = state.comments[id]
    return {
      ...state,
      comments: {
        ...state.comments,
        [id]: {
          ...comment,
          isNewData: false
        }
      }
    }
  })
  .handleAction(apiActions.social.resetNewThreadHighlight, (state, action) => {
    const id = action.payload
    const thread = state.threads[id]
    return {
      ...state,
      threads: {
        ...state.threads,
        [id]: {
          ...thread,
          isNewData: false
        }
      }
    }
  })
  .handleAction(apiActions.social.listThreadResponse, (state, action) => {
    const { data, reloadList, req } = action.payload

    const postId = req?.post || 0
    const results = data?.results ?? []
    const prevData = reloadList ? [] : state.threadLists[postId]?.lists ?? []
    const threadList = _uniq([...prevData, ..._map(results, result => result.id)])

    return {
      ...state,
      threadLists: {
        ...state.threadLists,
        [postId]: {
          lists: threadList,
          lastRequest: data
        }
      },
      threads: {
        ...state.threads,
        ..._keyBy(results, results => results.id)
      }
    }
  })
  .handleAction(apiActions.social.listCommentResponse, (state, action) => {
    const { data, reloadList, req } = action.payload

    const threadId = req?.thread || 0
    const results = data?.results ?? []
    const prevData = reloadList ? [] : state.commentLists[threadId]?.lists ?? []
    const commentList = _uniq([...prevData, ..._map(results, result => result.id)])

    return {
      ...state,
      commentLists: {
        ...state.commentLists,
        [threadId]: {
          lists: commentList,
          lastRequest: data
        }
      },
      comments: {
        ...state.comments,
        ..._keyBy(results, results => results.id)
      }
    }
  })
  .handleAction(apiActions.social.listBannerImagesResponse, (state, action) => {
    const data = action.payload?.data?.results ?? []
    return {
      ...state,
      bannerImages: data
    }
  })
  .handleAction(apiActions.social.listUserProjectResponse, (state, action) => {
    const { data, reloadList, req } = action.payload
    const user = req?.user

    if (user) {
      const results = data.results ?? []

      const currentUserList = state.profileLists[user] ?? {}
      const prevData = reloadList ? [] : currentUserList.projectList ?? []

      return {
        ...state,
        profileLists: {
          ...state.profileLists,
          [user]: {
            ...currentUserList,
            projectList: [...prevData, ..._map(results, result => result.id)],
            lastProjectListReq: data
          }
        }
      }
    } else {
      return state
    }
  })
  .handleAction(apiActions.social.setCurrentUserProfile, (state, action) => {
    const { userId } = action.payload
    return {
      ...state,
      currentUserProfile: userId
    }
  })
  .handleAction(apiActions.social.listUserCollectionResponse, (state, action) => {
    const { data, reloadList, req } = action.payload
    const user = req?.user

    if (user) {
      const results = data.results ?? []

      const currentUserList = state.profileLists[user] ?? {}
      const prevData = reloadList ? [] : currentUserList.collectionList ?? []

      return {
        ...state,
        profileLists: {
          ...state.profileLists,
          [user]: {
            ...currentUserList,
            collectionList: [...prevData, ..._map(results, result => result.id)],
            lastCollectionListReq: data
          }
        }
      }
    } else {
      return state
    }
  })
  .handleAction(apiActions.social.listUserPostResponse, (state, action) => {
    const { data, reloadList, req } = action.payload
    const user = req?.user

    if (user) {
      const results = data.results ?? []

      const currentUserList = state.profileLists[user] ?? {}
      const prevData = reloadList ? [] : currentUserList.postList ?? []
      const initial: SocialState['posts'] = {}

      return {
        ...state,
        posts: {
          ...state.posts,
          ..._reduce(
            results,
            (data, result) => {
              data[result.id] = _merge(state.posts[result.id] ?? {}, result)
              return data
            },
            initial
          )
        },
        profileLists: {
          ...state.profileLists,
          [user]: {
            ...currentUserList,
            postList: [...prevData, ..._map(results, result => result.id)],
            lastPostListReq: data
          }
        }
      }
    } else {
      return state
    }
  })
  .handleAction(apiActions.social.retrieveUserByAliasResponse, (state, action) => {
    const { id } = action.payload

    const currentUserList = state.profileLists[id] ?? {}

    return {
      ...state,
      profileLists: {
        ...state.profileLists,
        [id]: {
          ...currentUserList,
          userData: action.payload
        }
      }
    }
  })
  .handleAction(sharedActions.reset, () => initialSocialState)
