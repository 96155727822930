import React from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Button from 'components/Button'
import { CircularLoading } from 'components/Loading'
import { appSelectors } from 'duck/AppDuck'
import { RootActionType, RootState } from 'duck'
import { dialogActions, MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import { apiSelectors } from 'duck/ApiDuck/selectors'
import { brainTreePaymentActions } from '../duck'
import MonetizationDialogTemplate from '../MonetizationDialogTemplate'
import { ReduxProps } from 'utils/Types'
import styles from './DialogContinueInactiveSubscription.module.scss'
import CurrentPaymentMethods from '../components/CurrentPaymentMethods'
import { GetText } from 'utils/TextUtils'

const mapStateToProps = (state: RootState) => ({
  updateSubscriptionLoading: apiSelectors.loading['payment.updateSubscription'](state),
  hasBraintreePaymentMethod: apiSelectors.hasBraintreePaymentMethod(state),
  frozen_balance: apiSelectors.creditOverview(state)?.frozen_balance,
  activeDialog: appSelectors.dialog.activeDialog(state)
})
const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      addDialog: dialogActions.addDialog,
      reactivateSubscription: brainTreePaymentActions.reactivateSubscription,
      resetData: brainTreePaymentActions.resetData
    },
    dispatch
  )
type DialogContinueInactiveSubscriptionProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
>

const DialogContinueInactiveSubscription: React.FC<DialogContinueInactiveSubscriptionProps> = ({
  closeDialog,
  resetData,
  addDialog,
  reactivateSubscription,
  updateSubscriptionLoading,
  hasBraintreePaymentMethod,
  activeDialog,
  frozen_balance
}) => {
  const showDialog = activeDialog === MonetizationDialog.CONTINUE_INACTIVE_SUBSCRIPTION
  const showLoading = updateSubscriptionLoading
  const disableSave = Boolean(updateSubscriptionLoading) || !hasBraintreePaymentMethod
  const title = 'Check Your Subscription'

  return (
    <MonetizationDialogTemplate
      open={showDialog}
      contentDividers={true}
      disableActionSpacing={true}
      scroll={'body'}
      onClose={() => {
        closeDialog()
        resetData()
      }}
      titleContent={<Typography variant="h6">{title}</Typography>}
      actionContent={
        <div className={styles.DialogSaveCardActions}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => reactivateSubscription()}
            disabled={Boolean(disableSave)}
            fullWidth
          >
            {'REACTIVATE SUBSCRIPTION'}
          </Button>
        </div>
      }
    >
      <div
        className={clsx('text-center', styles.DialogSaveCardContent, showLoading && '!invisible')}
      >
        {GetText.inactiveSubscriptionText(frozen_balance).map((value, index) => (
          <Typography variant="body2" key={index} className="mb-1.5">
            {value}
          </Typography>
        ))}

        <CurrentPaymentMethods
          onClickChangePaymentMethod={() =>
            addDialog({
              [MonetizationDialog.SAVE_CARD]: {
                dialogName: MonetizationDialog.SAVE_CARD
              }
            })
          }
        />
      </div>

      {showLoading && (
        <div className={styles.DialogSaveCardLoadingWrapper}>
          <CircularLoading loading disableShrink />
        </div>
      )}
    </MonetizationDialogTemplate>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogContinueInactiveSubscription)
