import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const FashionIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon className={clsx(className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M21.6022 17.9879L13.0022 11.6356V10.7394C14.6522 10.2568 15.8022 8.60228 15.4322 6.75077C15.1722 5.46062 14.1322 4.38713 12.8222 4.09168C10.5422 3.57956 8.50221 5.28334 8.50221 7.45001H10.5022C10.5022 6.63259 11.1722 5.97274 12.0022 5.97274C12.8322 5.97274 13.5022 6.63259 13.5022 7.45001C13.5022 8.27728 12.8122 8.94698 11.9722 8.92728C11.4322 8.91743 11.0022 9.37046 11.0022 9.90228V11.6356L2.40221 17.9879C1.63221 18.5591 2.04221 19.7606 3.00221 19.7606H12.0022H21.0022C21.9622 19.7606 22.3722 18.5591 21.6022 17.9879ZM6.00221 17.7909L12.0022 13.3591L18.0022 17.7909H6.00221Z" />
    </SvgIcon>
  )
}

export default FashionIcon
