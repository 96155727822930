import _toInteger from 'lodash/toInteger'
import _reduce from 'lodash/reduce'
import { RootState } from 'duck'
import { ProArtFilterGenre, ProArtFilterOutput } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { adjustedImages, userImages } from './CommonSelectors'

const currentProArtFilterId = (state: RootState) =>
  _toInteger(state.api.proArtFilter.currentProArtFilterId)

const proArtFilter = (state: RootState) => {
  return state.api.proArtFilter
}
const proArtFilterStyles = createSelector(
  proArtFilter,
  proArtFilter => proArtFilter.proArtFilterStyles?.results ?? []
)

const proArtFilterGenres = createSelector(
  proArtFilter,
  proArtFilter => proArtFilter.proArtFilterGenres?.results ?? []
)

/* Object key version */
const initial: { [id: number]: ProArtFilterGenre } = {}
const proArtFilterGenreData = createSelector(proArtFilterGenres, proArtFilterGenres =>
  _reduce(
    proArtFilterGenres,
    (result, value) => {
      result[value.id] = value
      return result
    },
    initial
  )
)

const hasProArtFilterGenres = createSelector(proArtFilterGenres, proArtFilterGenres =>
  Boolean(proArtFilterGenres.length)
)

const proArtFilterProjects = createSelector(
  proArtFilter,
  proArtFilter => proArtFilter.proArtFilterProjects.data
)
const proArtFilterProjectListId = createSelector(
  proArtFilter,
  proArtFilter => proArtFilter.proArtFilterProjects.list
)

const proArtFilterProjectList = createSelector(
  proArtFilterProjects,
  proArtFilterProjectListId,
  (proArtFilterProjects, proArtFilterProjectListId) =>
    proArtFilterProjectListId.map(id => proArtFilterProjects?.[id] || {})
)
const outputLists = createSelector(proArtFilter, proArtFilter => proArtFilter.outputLists)
const proArtFilterOutputs = createSelector(proArtFilter, proArtFilter => proArtFilter.outputs)

const currentProArtFilterProjectOutputList = createSelector(
  outputLists,
  currentProArtFilterId,
  (outputList, currentProArtFilterId) => outputList[currentProArtFilterId]
)

const currentProArtFilterProjectOutputCount = createSelector(
  currentProArtFilterProjectOutputList,
  currentOutputList => currentOutputList?.lastListReq?.count
)

const currentProArtFilterProject = createSelector(
  proArtFilterProjects,
  currentProArtFilterId,
  currentProArtFilterProjectOutputList,
  userImages,
  proArtFilterOutputs,
  proArtFilterGenres,
  adjustedImages,
  (
    proArtFilterProjects,
    currentProArtFilterId,
    currentProArtFilterProjectOutputList,
    userImages,
    proArtFilterOutputs,
    proArtFilterGenres,
    adjustedImages
  ) => {
    const proArtFilterProject = proArtFilterProjects?.[currentProArtFilterId]
    const currentOutputs = currentProArtFilterProjectOutputList?.list ?? []

    const outputs: ProArtFilterOutput[] = currentOutputs?.map(proArtFilterOutputId => {
      const currentOutput = proArtFilterOutputs[proArtFilterOutputId]
      const imageId = currentOutput.image.id
      const image = userImages[imageId] ?? currentOutput.image

      return {
        ...currentOutput,
        image: {
          ...image,
          adjustData: adjustedImages[image.adjust ?? 0]
        },
        bookmark: userImages[imageId]?.bookmark ?? 0
      }
    })

    const defaultGenreName = 'fine' // Set process with name Fine as default
    const defaultGenreId =
      proArtFilterGenres.find(genre => genre.name.toLowerCase() === defaultGenreName)?.id || 0

    return proArtFilterProject
      ? {
          ...proArtFilterProject,
          outputs,
          genre: defaultGenreId
        }
      : undefined
  }
)

const isCurrentProArtFilterOutputHasNext = createSelector(
  currentProArtFilterProjectOutputList,
  currentProArtFilterProjectOutputList =>
    Boolean(currentProArtFilterProjectOutputList?.lastListReq?.next)
)

const proArtFilterBanners = createSelector(
  proArtFilter,
  proArtFilter => proArtFilter.proArtFilterBanner?.results
)
const isProArtFilterBannerRetrieved = createSelector(proArtFilter, proArtFilter =>
  Boolean(proArtFilter.proArtFilterBanner)
)
const proArtFilterSelectors = {
  currentProArtFilterProjectOutputList,
  currentProArtFilterId,
  isCurrentProArtFilterOutputHasNext,
  proArtFilterProjects,
  currentProArtFilterProjectOutputCount,
  proArtFilterProjectList,
  currentProArtFilterProject,
  proArtFilterStyles,
  proArtFilterGenres,
  proArtFilterGenreData,
  hasProArtFilterGenres,
  proArtFilterProjectListId,
  proArtFilterBanners,
  isProArtFilterBannerRetrieved
}

export default proArtFilterSelectors
