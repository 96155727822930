import { TextTransform, Format } from 'utils/TextUtils'
import produce from 'immer'
import _forEach from 'lodash/forEach'
import _compact from 'lodash/compact'
import _keys from 'lodash/keys'
import _isNil from 'lodash/isNil'
import { combineEpics, Epic } from 'redux-observable'
import { merge, of } from 'rxjs'
import { filter, mergeMap, map, withLatestFrom, tap, startWith, take } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { appActions, appSelectors } from 'duck/AppDuck'
import { createSelector } from 'reselect'
import { apiSelectors, apiActions, INITIAL_USER_IMAGE_UPSCALE } from 'duck/ApiDuck'
import {
  ScaleList,
  UpscaleImage,
  UpscaleMethod,
  UpscaleImageCreateReq,
  ListUpscaleImageReq
} from 'models/ApiModels'
import MixPanelUtils, { DownloadLocation } from 'utils/MixPanelUtils'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'
import {
  UpscaleFormData,
  DEFAULT_FORM_DATA,
  UPSCALE_LIST_CONFIG,
  UpscaleListConfig,
  UPSCALE_LIST,
  UPSCALED_FORM_DATA_CHANGED_WHITE_LIST,
  FORM_RANGES,
  DownloadPricingGroup
} from './Models'
import { errorMessage, values } from 'appConstants'
import { dialogActions, ErrorDialog } from 'duck/AppDuck/DialogDuck'
import SentryUtils from 'utils/SentryUtils'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import { Params } from 'react-router'
import { UpscalesParamType, SUB_ROUTE_UPSCALE_PAGE } from 'routes'
import userSelectors from 'duck/ApiDuck/selectors/UserSelectors'

// Constants
const NAMESPACE = '@@page/UpscalePanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

type DownloadTextParam = {
  downloadingImage: boolean | string | undefined
  price: number
  hasDownload: boolean
}

export const Utils = {
  getParam: (params: Params<keyof UpscalesParamType>): UpscalesParamType | undefined => {
    const subRoute = SUB_ROUTE_UPSCALE_PAGE.includes(
      (params.subRoute ?? '') as NonNullable<UpscalesParamType['subRoute']>
    )
      ? (params.subRoute as UpscalesParamType['subRoute'])
      : undefined

    return {
      id: params.id,
      subRoute: subRoute
    }
  },
  getUpscaleText: (upscaleImage?: UpscaleImage, upscaleListConfig?: UpscaleListConfig) => {
    const method = upscaleImage?.method
    const controls = upscaleImage?.controls

    if (method) {
      const texture = Math.round((controls?.texture ?? 0) * 100) / 100
      const textureText =
        texture && texture !== FORM_RANGES.texture[2] ? `, Texture: ${texture}` : ''
      const scaleText = upscaleImage?.scale ? ` ${upscaleImage?.scale}x` : ''
      const deblockingText = controls?.deblocking ? ', Jpeg Deblocking' : ''

      const smooth = Math.round((controls?.smooth ?? 0) * 100) / 100
      const smoothText = smooth && smooth !== FORM_RANGES.smooth[2] ? `, Denoise: ${smooth}` : ''

      return upscaleImage
        ? `${upscaleListConfig?.title}${scaleText}${textureText}${smoothText}${deblockingText}`
        : ''
    }

    return ''
  },
  getZoomMethodText: (isTouchDevice: boolean) =>
    !isTouchDevice ? 'Scroll over image to zoom' : 'Pinch over the image to zoom ',
  getUpscaleTitle: (upscaleImage?: UpscaleImage) => `Image ${upscaleImage?.image.id}`,
  getResolutionText: (upscaleImage?: UpscaleImage) =>
    `${(upscaleImage?.scale ?? 0) * (upscaleImage?.image?.width ?? 0)} x ${
      (upscaleImage?.scale ?? 0) * (upscaleImage?.image?.height ?? 0)
    } (${upscaleImage?.scale ?? 1}x)`,
  getDownloadText: (param: DownloadTextParam) => {
    const { downloadingImage, price, hasDownload } = param

    const pluralPrefixPrice = price > 0 ? 'S' : ''

    if (Boolean(downloadingImage)) {
      return 'DOWNLOADING'
    }
    if (hasDownload) {
      return 'DOWNLOAD'
    }
    if (price > 0) {
      return `DOWNLOAD FOR ${Format.creditAmount(price)} CREDIT${pluralPrefixPrice}`
    }
    if (price < 0) {
      return `DOWNLOAD NOT AVAILABLE`
    }
    if (price === 0) {
      return `DOWNLOAD FOR FREE`
    }
  }
}

type NextActionType = 'closeDialog'

// Actions
export const actions = {
  setMode: createAction(creator('SET_MODE'))<UpscalePanelState['mode']>(),

  setImageFile: createAction(creator('SET_IMAGE_FILE'))<UpscalePanelState['imageFile']>(),

  setSelectedUserImageId: createAction(creator('SET_SELECTED_USER_IMAGE_ID'))<number>(),
  setSelectedMethod: createAction(creator('SET_SELECTED_MODE'))<UpscaleMethod | undefined>(),
  retrieveUpscaleData: createAction(creator('RETRIEVE_UPSCALE_DATA'))<
    'upscaleList' | 'upscaleGenerate'
  >(),
  submitUpscaleImage: createAction(creator('SUBMIT_UPSCALE_IMAGE'))(),
  updateFormData: createAction(creator('UPDATE_FORM_DATA'))<Partial<UpscaleFormData>>(),
  reset: createAction(creator('RESET'))(),
  setFormData: createAction(creator('SET_FORM_DATA'))<{
    [key in UpscaleMethod]: UpscaleImage | undefined
  }>(),
  download: createAction(creator('DOWNLOAD'))<{
    price: number
    downloadLocation: DownloadLocation
    showPaidSnackBar?: boolean
    upscaleId?: number
    nextAction?: NextActionType
  }>(),
  downloadCurrentUpscale: createAction(creator('DOWNLOAD_CURRENT_UPSCALE'))<{
    showPaidSnackBar?: boolean
    nextAction?: NextActionType
  }>(),
  upscaleList: {
    setShowEditPanel: createAction(creator('UPSCALE_LIST/SET_SHOW_EDIT_PANEL'))<{
      imageId: number | undefined
      selectedMethod?: UpscaleMethod
    }>(),
    showEdit: createAction(creator('UPSCALE_LIST/SHOW_EDIT'))<{
      upscaleImageId: number | undefined
    }>(),
    resetShowEditPanel: createAction(creator('UPSCALE_LIST/RESET_SHOW_EDIT_PANEL'))(),
    setOpenUpscaleList: createAction(creator('UPSCALE_LIST/SET_OPEN_UPSCALE_LIST'))<boolean>(),
    setOpenedUpscaleImage: createAction(creator('UPSCALE_LIST/SET_OPENED_UPSCALE_IMAGE'))<
      number | undefined
    >(),
    loadUpscaleList: createAction(creator('UPSCALE_LIST/LOAD_UPSCALE_LIST'))(),
    setLoaded: createAction(creator('UPSCALE_LIST/SET_LOADED'))<boolean>(),
    updateListParam: createAction(creator('UPSCALE_LIST/UPDATE_LIST_PARAM'))<
      Partial<Omit<ListUpscaleImageReq, 'next' | 'limit' | 'offset'>>
    >()
  }
}

// Selectors
const selectUpscalePanel = (state: RootState) => state.container.upscalePanel

const selectCurrentUpscaleListConfig = createSelector(selectUpscalePanel, upscalePanel => {
  const { selectedMethod } = upscalePanel
  return selectedMethod ? UPSCALE_LIST_CONFIG[selectedMethod] : undefined
})

const selectSelectedUserImageIdOriginal = createSelector(selectUpscalePanel, upscalePanel => {
  return upscalePanel.selectedUserImageId
})
const selectSelectedUserImageId = createSelector(
  selectSelectedUserImageIdOriginal,
  apiSelectors.userImages,
  apiSelectors.adjustedImages,
  (id, userImages, adjustedImages) => {
    const image = userImages[id ?? 0]
    const adjustedImage = adjustedImages[image?.adjust ?? 0]?.output
    return adjustedImage?.id ?? image?.id
  }
)

const selectSelectedMethod = createSelector(selectUpscalePanel, upscalePanel => {
  return upscalePanel.selectedMethod
})

const selectFormData = createSelector(selectUpscalePanel, upscalePanel => {
  return upscalePanel.formData
})

const selectUserImageUpscaleDataForSetFormData = createSelector(
  apiSelectors.userImageUpscales,
  apiSelectors.upscaleImages,
  selectSelectedUserImageId,
  (userImageUpscales, upscaleImages, userImageId) => {
    const userImageUpscale = userImageId
      ? userImageUpscales[userImageId] ?? INITIAL_USER_IMAGE_UPSCALE
      : INITIAL_USER_IMAGE_UPSCALE

    type ResultType = { [key in UpscaleMethod]: UpscaleImage | undefined }

    const result: ResultType = UPSCALE_LIST.reduce((result, method) => {
      const upscaleImage = userImageUpscale[method]
        ? upscaleImages[userImageUpscale[method] ?? 0]
        : undefined

      result[method] = upscaleImage

      return result
    }, {} as ResultType)

    return result
  }
)

const selectUserImageUpscaleData = createSelector(
  apiSelectors.userImageUpscales,
  apiSelectors.upscaleImages,
  selectSelectedUserImageId,
  selectFormData,
  (userImageUpscales, upscaleImages, userImageId, formData) => {
    const userImageUpscale = userImageId
      ? userImageUpscales[userImageId] ?? INITIAL_USER_IMAGE_UPSCALE
      : INITIAL_USER_IMAGE_UPSCALE

    type ResultType = { [key in UpscaleMethod]: UpscaleImage | undefined }

    const result: ResultType = UPSCALE_LIST.reduce((result, method) => {
      const currentFormData = formData[method]
      const upscaleImage = userImageUpscale[method]
        ? upscaleImages[userImageUpscale[method] ?? 0]
        : undefined

      result[method] = upscaleImage?.scale === currentFormData.scale ? upscaleImage : undefined

      return result
    }, {} as ResultType)

    return result
  }
)

const selectUpscaleListLoadingState = createSelector(
  selectUserImageUpscaleData,
  userImageUpscaleData => {
    type ResultType = { [key in UpscaleMethod]: boolean | string }

    const result: ResultType = UPSCALE_LIST.reduce((result, method) => {
      result[method] = Boolean(
        userImageUpscaleData[method] && !userImageUpscaleData[method]?.output
      )
        ? 'Upscaling Image'
        : false
      return result
    }, {} as ResultType)

    return result
  }
)

const selectShouldRetrieveUpscaleData = createSelector(selectUserImageUpscaleData, upscaleData => {
  let shouldRetrieve = false

  Object.keys(upscaleData).forEach(key => {
    const upscaleDatum = upscaleData[key as UpscaleMethod]

    if (upscaleDatum && !upscaleDatum.output) {
      shouldRetrieve = true
    }
  })

  return shouldRetrieve
})

const selectUpscaleImageData = createSelector(
  selectUserImageUpscaleData,
  selectSelectedMethod,
  (userImageUpscaleData, selectedMethod) =>
    selectedMethod ? userImageUpscaleData[selectedMethod] : undefined
)

const selectUpscaleImage = createSelector(
  selectSelectedUserImageIdOriginal,
  selectSelectedUserImageId,
  selectUpscaleImageData,
  apiSelectors.userImages,
  (selectedUserImageIdOriginal, selectedUserImageId, upscaleData, userImages) => {
    const userImage = selectedUserImageIdOriginal
      ? userImages[selectedUserImageIdOriginal]
      : undefined
    const upscaledUserImage = selectedUserImageId ? userImages[selectedUserImageId] : undefined

    const largeImage = upscaleData?.output?.id ? upscaleData?.output : undefined

    return {
      isAdjusted: Boolean(userImage?.adjust),
      userImage,
      smallImage: upscaledUserImage,
      largeImage
    }
  }
)

const selectSelectedFormData = createSelector(selectUpscalePanel, upscalePanel => {
  const { selectedMethod, formData } = upscalePanel

  return selectedMethod ? formData[selectedMethod] : undefined
})

const selectImageFile = createSelector(selectUpscalePanel, upscalePanel => upscalePanel.imageFile)
const selectMode = createSelector(selectUpscalePanel, upscalePanel => upscalePanel.mode)

const selectDownloadUpscalePricing = createSelector(
  apiSelectors.downloadUpscalePricing,
  selectMode,
  selectUpscaleImageData,
  (downloadUpscalePricing, mode, upscaleImageData) => {
    return mode === 'standalone' || upscaleImageData?.is_user_upload
      ? downloadUpscalePricing.pricingStandalone
      : downloadUpscalePricing.pricingEmbed
  }
)

const selectCurrentFormData = createSelector(
  selectSelectedFormData,
  selectUpscaleImage,
  selectCurrentUpscaleListConfig,
  selectImageFile,
  // selectDownloadUpscalePricing,
  (
    selectedFormData,
    upscaleImage,
    upscaleListConfig,
    imageFile
    // downloadUpscalePricing
  ) => {
    const width = upscaleImage?.smallImage?.width ?? imageFile?.width ?? 0
    const height = upscaleImage?.smallImage?.height ?? imageFile?.height ?? 0

    const formItems = {
      scale: _compact(
        ScaleList.map(scale => {
          // const price = downloadUpscalePricing[scale]
          const widthScaled = width * scale
          const heightScaled = height * scale
          const isSizeValid =
            widthScaled <= values.MAX_UPSCALE_SIZE && heightScaled <= values.MAX_UPSCALE_SIZE

          return isSizeValid
            ? {
                label: `${widthScaled}x${heightScaled} (${scale}x)`,
                value: `${scale}`
              }
            : undefined
        })
      ),
      extension: [
        {
          label: '.jpeg',
          value: '.jpeg'
        }
      ],
      enableTexture: upscaleListConfig?.enableTexture
    }

    return { formData: selectedFormData, formItems }
  }
)
const selectUpscaleLoading = createSelector(
  selectUpscaleListLoadingState,
  selectSelectedMethod,
  apiSelectors.loading['imageEnhancement.createUpscaleImage'],
  (list, method, createLoading) => {
    const loading = method ? list[method] : undefined
    return Boolean(createLoading) || loading
  }
)

const selectIsCurrentUpscaling = createSelector(
  selectUpscaleListLoadingState,
  selectSelectedMethod,
  (list, method) => {
    const loading = method ? list[method] : undefined
    return loading
  }
)

const selectDownloadPricing = createSelector(
  apiSelectors.creditBalance,
  selectDownloadUpscalePricing,
  (creditBalance, downloadUpscalePricing) => {
    const result: DownloadPricingGroup = ScaleList.reduce((data, scale) => {
      const price = downloadUpscalePricing[scale] ?? 0
      const endBalance = creditBalance - price

      data[scale] = {
        creditBalance,
        price,
        endBalance,
        hasEnoughBalance: endBalance >= 0
      }

      return data
    }, {} as DownloadPricingGroup)

    return result
  }
)

export const selectors = {
  selectedUserImageId: selectSelectedUserImageId,
  disableForm: createSelector(
    apiSelectors.loading['imageEnhancement.retrieveUserImageUpscales'],
    selectUpscaleLoading,
    (loading, loading2) => Boolean(loading) || Boolean(loading2)
  ),
  imageFile: selectImageFile,
  mode: selectMode,
  isCurrentUpscaling: selectIsCurrentUpscaling,
  upscaleLoading: selectUpscaleLoading,
  upscaleListLoadingState: selectUpscaleListLoadingState,
  shouldRetrieveUpscaleData: selectShouldRetrieveUpscaleData,
  upscalePanel: selectUpscalePanel,
  upscaleImageData: selectUpscaleImageData,
  selectedMethod: selectSelectedMethod,
  userImageUpscaleData: selectUserImageUpscaleData,
  openUpscaleList: createSelector(
    selectUpscalePanel,
    upscalePanel => upscalePanel.upscaleList.openUpscaleList
  ),

  hasLargeImage: createSelector(selectUpscaleImage, upscaleImage =>
    Boolean(upscaleImage.largeImage)
  ),
  currentUpscaleListConfig: selectCurrentUpscaleListConfig,
  upscaleImage: selectUpscaleImage,
  currentFormData: selectCurrentFormData,
  isCurrentFormDataChanged: createSelector(
    selectCurrentFormData,
    currentFormData => currentFormData.formData?.isChanged
  ),
  currentUpscaleRecordText: createSelector(
    selectUpscaleImageData,
    selectCurrentUpscaleListConfig,
    (upscaleImageData, upscaleConfig) => {
      return Utils.getUpscaleText(upscaleImageData, upscaleConfig)
    }
  ),
  currentDownloadPricing: createSelector(
    selectDownloadPricing,
    selectCurrentFormData,
    (downloadPricing, formData) => {
      const scale = formData?.formData?.scale ?? 2

      return downloadPricing[scale]
    }
  ),
  downloadPricing: selectDownloadPricing,
  upscaleRequest: createSelector(
    selectUpscaleImage,
    selectCurrentFormData,
    selectSelectedMethod,
    selectImageFile,
    (upscaleImage, currentFormData, selectedMethod, imageFile) => {
      const image = upscaleImage.smallImage?.id
      const image_file = imageFile?.file

      const result: UpscaleImageCreateReq = {
        image_file: !image ? image_file : undefined,
        image,
        method: selectedMethod ? selectedMethod : 'bicubic',
        scale: currentFormData.formData?.scale ?? DEFAULT_FORM_DATA.scale,
        controls: {
          texture: currentFormData.formData?.showTexture
            ? currentFormData.formData?.texture
            : undefined,
          smooth: currentFormData.formData?.showSmooth
            ? currentFormData.formData?.smooth
            : undefined,
          deblocking: currentFormData.formData?.deblocking ?? false
        }
      }

      return result
    }
  ),
  upscaleListListParam: createSelector(selectUpscalePanel, upscalePanel => {
    return upscalePanel.upscaleList.listParams
  }),
  upscaleListLoaded: createSelector(selectUpscalePanel, upscalePanel => {
    return upscalePanel.upscaleList.loaded
  }),
  openedUpscaleId: createSelector(selectUpscalePanel, upscalePanel => {
    return upscalePanel.upscaleList.openedUpscaleId
  }),
  showEditPanel: createSelector(selectUpscalePanel, upscalePanel => {
    return upscalePanel.upscaleList.showEditPanel
  }),
  openedUpscale: createSelector(
    selectUpscalePanel,
    apiSelectors.upscaleImages,
    (upscalePanel, upscaleImages) => {
      const id = upscalePanel.upscaleList.openedUpscaleId
      return id ? upscaleImages[id] : undefined
    }
  )
}
// Reducer
export type UpscalePanelState = {
  mode: 'embed' | 'standalone'
  imageFile?: {
    file: File
    width: number
    height: number
  }
  selectedUserImageId?: number
  selectedMethod?: UpscaleMethod
  formData: {
    [key in UpscaleMethod]: UpscaleFormData
  }
  upscaleList: {
    showEditPanel: boolean
    previousData: {
      selectedUserImage: number | undefined
      mode: UpscalePanelState['mode']
    }
    openUpscaleList: boolean
    loaded: boolean
    openedUpscaleId?: number
    listParams: ListUpscaleImageReq
  }
}
export const INITIAL: UpscalePanelState = {
  mode: 'embed',
  imageFile: undefined,
  selectedUserImageId: undefined,
  selectedMethod: undefined,
  formData: {
    bicubic: { ...DEFAULT_FORM_DATA },
    waifu2x: { ...DEFAULT_FORM_DATA },
    drn: { ...DEFAULT_FORM_DATA },
    enh: { ...DEFAULT_FORM_DATA }
  },
  upscaleList: {
    showEditPanel: false,
    previousData: {
      selectedUserImage: undefined,
      mode: 'embed'
    },
    openUpscaleList: false,
    loaded: false,
    openedUpscaleId: undefined,
    listParams: {
      limit: 30,
      scale: undefined,
      image: undefined,
      method: undefined,
      ordering: '-created'
    }
  }
}

const reducer = produce((state: UpscalePanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setMode): {
      const mode = payload as ActionType<typeof actions.setMode>['payload']

      state.mode = mode
      return
    }
    case getType(actions.setImageFile): {
      const file = payload as ActionType<typeof actions.setImageFile>['payload']

      state.imageFile = file
      return
    }

    case getType(actions.setSelectedUserImageId): {
      const imageId = payload as ActionType<typeof actions.setSelectedUserImageId>['payload']

      state.selectedUserImageId = imageId
      return
    }
    case getType(actions.upscaleList.setShowEditPanel): {
      const { imageId, selectedMethod } = payload as ActionType<
        typeof actions.upscaleList.setShowEditPanel
      >['payload']

      if (imageId) {
        state.upscaleList.showEditPanel = true
        state.upscaleList.previousData.selectedUserImage = state.selectedUserImageId
        state.upscaleList.previousData.mode = state.mode
        state.selectedMethod = selectedMethod
      }
      return
    }
    case getType(actions.upscaleList.resetShowEditPanel): {
      state.upscaleList.showEditPanel = false
      state.upscaleList.previousData.selectedUserImage = undefined
      return
    }
    case getType(actions.upscaleList.setOpenUpscaleList): {
      const openUpscaleList = payload as ActionType<
        typeof actions.upscaleList.setOpenUpscaleList
      >['payload']

      state.upscaleList.openUpscaleList = openUpscaleList
      return
    }
    case getType(actions.setSelectedMethod): {
      const mode = payload as ActionType<typeof actions.setSelectedMethod>['payload']
      state.selectedMethod = mode
      return
    }
    case getType(actions.setFormData): {
      const data = payload as ActionType<typeof actions.setFormData>['payload']

      _keys(data).forEach(key => {
        const mode = key as UpscaleMethod

        const controls = data[mode]?.controls
        const scale = data[mode]?.scale ?? DEFAULT_FORM_DATA.scale

        if (controls) {
          state.formData[mode] = {
            ...state.formData[mode],
            texture: controls.texture ?? DEFAULT_FORM_DATA.texture,
            smooth: controls.smooth ?? DEFAULT_FORM_DATA.smooth,
            deblocking: controls.deblocking ?? DEFAULT_FORM_DATA.deblocking,
            scale,
            isChanged: false,
            showSmooth:
              !_isNil(controls.smooth) && Boolean(controls.smooth !== DEFAULT_FORM_DATA.smooth),
            showTexture:
              !_isNil(controls.texture) && Boolean(controls.texture !== DEFAULT_FORM_DATA.texture)
          }
        }
      })

      return
    }

    case getType(actions.updateFormData): {
      const formData = payload as ActionType<typeof actions.updateFormData>['payload']
      const { selectedMethod } = state
      //Check if there any change

      if (selectedMethod) {
        let isChanged = state.formData[selectedMethod].isChanged

        _forEach(formData, (value, key) => {
          if (
            UPSCALED_FORM_DATA_CHANGED_WHITE_LIST.includes(key as keyof UpscaleFormData) &&
            value !== undefined
          ) {
            isChanged = true
          }
        })

        state.formData[selectedMethod] = {
          ...state.formData[selectedMethod],
          isChanged: formData.isChanged !== undefined ? formData.isChanged : isChanged,
          ...formData
        }

        // Smooth and texture are exclusive, only one of them is set.
        if (formData.showSmooth) {
          state.formData[selectedMethod].showTexture = false
          state.formData[selectedMethod].texture = DEFAULT_FORM_DATA.texture
        }
        if (formData.showTexture) {
          state.formData[selectedMethod].showSmooth = false
          state.formData[selectedMethod].smooth = DEFAULT_FORM_DATA.smooth
        }
      }
      return
    }
    case getType(actions.upscaleList.setOpenedUpscaleImage): {
      const upscaleImageId = payload as ActionType<
        typeof actions.upscaleList.setOpenedUpscaleImage
      >['payload']
      state.upscaleList.openedUpscaleId = upscaleImageId
      return
    }
    case getType(actions.upscaleList.setLoaded): {
      const loaded = payload as ActionType<typeof actions.upscaleList.setLoaded>['payload']
      state.upscaleList.loaded = loaded
      return
    }
    case getType(actions.upscaleList.updateListParam): {
      const updateListParam = payload as ActionType<
        typeof actions.upscaleList.updateListParam
      >['payload']

      state.upscaleList.listParams = { ...state.upscaleList.listParams, ...updateListParam }
      return
    }
    case getType(actions.reset): {
      state.imageFile = INITIAL.imageFile
      state.formData = INITIAL.formData
      state.selectedMethod = INITIAL.selectedMethod
      state.selectedUserImageId = INITIAL.selectedUserImageId
      return
    }
  }
}, INITIAL)

// Epics

const listenOnAppEvent: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    map(({ payload }) => payload),
    filter(({ [AppEvents.OPEN_UPSCALE_IMAGE]: event }) => Boolean(event)),
    map(({ [AppEvents.OPEN_UPSCALE_IMAGE]: event }) => event?.payload),
    mergeMap(payload => [
      actions.setSelectedUserImageId(payload?.userImageId ?? 0),
      actions.setMode('embed')
    ])
  )

const setSelectedUserImageIdEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.setSelectedUserImageId)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      userImageId: selectors.selectedUserImageId(state)
    })),
    filter(({ userImageId }) => Boolean(userImageId)),
    mergeMap(({ userImageId }) =>
      action$.pipe(
        filter(isActionOf(apiActions.imageEnhancement.retrieveUserImageUpscalesResponse)),
        take(1),
        withLatestFrom(state$),
        map(([_, state]) => ({
          userImageUpscaleData: selectUserImageUpscaleDataForSetFormData(state)
        })),
        map(({ userImageUpscaleData }) => actions.setFormData(userImageUpscaleData)),
        startWith(
          apiActions.imageEnhancement.retrieveUserImageUpscales({
            userImageId: userImageId ?? 0
          })
        )
      )
    )
  )

const showEditEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.upscaleList.showEdit)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      upscaleImageId: action.payload.upscaleImageId,
      upscaleImages: apiSelectors.upscaleImages(state)
    })),
    map(({ upscaleImages, upscaleImageId }) => ({
      upscaleImageId,
      upscaleImage: upscaleImages[upscaleImageId ?? 0]
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ upscaleImageId }) => !Boolean(upscaleImageId)),
          map(() => actions.upscaleList.setShowEditPanel({ imageId: undefined }))
        ),
        of(param).pipe(
          filter(
            ({ upscaleImage, upscaleImageId }) => Boolean(upscaleImageId) && Boolean(upscaleImage)
          ),
          map(({ upscaleImage }) =>
            actions.upscaleList.setShowEditPanel({
              imageId: upscaleImage?.image.id,
              selectedMethod: upscaleImage?.method
            })
          )
        ),
        of(param).pipe(
          filter(
            ({ upscaleImage, upscaleImageId }) => Boolean(upscaleImageId) && !Boolean(upscaleImage)
          ),
          mergeMap(({ upscaleImageId = 0 }) =>
            action$.pipe(
              filter(isActionOf(apiActions.imageEnhancement.retrieveUpscaleImageResponse)),
              take(1),
              map(() => actions.upscaleList.showEdit({ upscaleImageId })),
              startWith(apiActions.imageEnhancement.retrieveUpscaleImage(upscaleImageId))
            )
          )
        )
      )
    )
  )

const setShowEditPanelEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.upscaleList.setShowEditPanel)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      imageId: action.payload.imageId,
      previousData: selectUpscalePanel(state).upscaleList.previousData
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ imageId }) => Boolean(imageId)),
          map(({ imageId }) =>
            eventEmiterActions.emit({
              [AppEvents.OPEN_UPSCALE_IMAGE]: {
                event: AppEvents.OPEN_UPSCALE_IMAGE,
                payload: { userImageId: imageId ?? 0 }
              }
            })
          )
        ),
        of(param).pipe(
          filter(({ imageId }) => !Boolean(imageId)),
          mergeMap(({ previousData }) =>
            _compact([
              actions.upscaleList.resetShowEditPanel(),
              previousData.mode === 'embed' &&
                previousData.selectedUserImage &&
                appActions.openUpscaleImage({ userImageId: previousData.selectedUserImage ?? 0 }),
              previousData.mode === 'standalone' &&
                previousData.selectedUserImage &&
                actions.setSelectedUserImageId(previousData.selectedUserImage ?? 0),
              actions.setMode(previousData.mode)
            ])
          )
        )
      )
    )
  )

const updateListParamEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.upscaleList.updateListParam)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      listParam: selectors.upscaleListListParam(state)
    })),
    map(({ listParam }) => apiActions.imageEnhancement.listUpscaleImage({ param: listParam }))
  )

const retrieveUpscaleDataEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.retrieveUpscaleData)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const source = action.payload

      if (source === 'upscaleGenerate') {
        const upscaleData = selectors.userImageUpscaleData(state)
        const retrievedData: number[] = []

        Object.keys(upscaleData).forEach(key => {
          const upscaleDatum = upscaleData[key as UpscaleMethod]

          if (upscaleDatum && !upscaleDatum.output) {
            retrievedData.push(upscaleDatum.id)
          }
        })

        return retrievedData
      }

      if (source === 'upscaleList') {
        return apiSelectors.unfinishedUpscaleImageList(state)
      }
      return []
    }),
    mergeMap(retrievedData =>
      retrievedData.map(id => apiActions.imageEnhancement.retrieveUpscaleImage(id))
    )
  )

const showUpsellBanner = bannerActions.upsell.show({
  dismissable: true,
  upsellLocation: 'global',
  contentMode: 'upscale-advanced',
  position: 'float-bottom-banner-overlay'
})

const submitUpscaleImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.submitUpscaleImage)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      projectData: appSelectors.projectData(state),
      request: selectors.upscaleRequest(state),
      mode: selectors.mode(state),
      currentUpscaleImageId: selectors.upscaleImageData(state)?.id ?? 0,
      isUpscaleUnavailable:
        selectors.currentDownloadPricing(state).price < 0 ||
        !userSelectors.equity(state)?.upsellState?.canUpscale
    })),
    filter(
      ({ request }) => Boolean(request.image || request.image_file) && Boolean(request.method)
    ),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ isUpscaleUnavailable }) => isUpscaleUnavailable),
          map(() => showUpsellBanner)
        ),
        of(param).pipe(
          filter(({ isUpscaleUnavailable }) => !isUpscaleUnavailable),
          mergeMap(param =>
            merge(
              of(param).pipe(
                filter(({ currentUpscaleImageId }) => Boolean(currentUpscaleImageId)),
                map(({ currentUpscaleImageId }) =>
                  apiActions.imageEnhancement.deleteUpscaleImage({
                    upscaleImageId: currentUpscaleImageId
                  })
                )
              ),
              of(param).pipe(
                mergeMap(({ request, mode }) =>
                  action$.pipe(
                    filter(isActionOf(apiActions.imageEnhancement.createUpscaleImageResponse)),
                    take(1),
                    tap(() => {
                      const { projectData } = param

                      projectData &&
                        MixPanelUtils.track<'PROJECT__UPSCALE_IMAGE'>('Project - Upscale Image', {
                          ...projectData,
                          upscale_image_source: mode,
                          image_id: request.image ?? -1,
                          upscale_scale: request.scale,
                          upscale_texture: request.controls.texture,
                          upscale_mode: request.method,
                          upscale_denoise: request.controls.smooth,
                          upscale_deblocking: request.controls.deblocking
                        })
                    }),
                    mergeMap(({ payload }) =>
                      _compact([
                        actions.updateFormData({ isChanged: false }),
                        !request.image
                          ? actions.setSelectedUserImageId(payload.data.image.id)
                          : undefined
                      ])
                    ),
                    startWith(
                      apiActions.imageEnhancement.createUpscaleImage({
                        ...request,
                        method: request.method as UpscaleMethod
                      })
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )

const downloadCurrentUpscaleEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.downloadCurrentUpscale)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const upscaleImageData = selectors.upscaleImageData(state)
      const price = selectors.currentDownloadPricing(state)?.price ?? 0

      const result: ActionType<typeof actions.download>['payload'] = {
        downloadLocation: 'Upscale - Upscale Generate',
        price,
        showPaidSnackBar: action.payload.showPaidSnackBar,
        upscaleId: upscaleImageData?.id ?? 0,
        nextAction: action.payload.nextAction
      }
      return result
    }),
    map(param => actions.download(param))
  )

const downloadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.download)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const upscaleImage = apiSelectors.upscaleImages(state)[action.payload.upscaleId ?? 0]

      return {
        ...action.payload,
        projectData: appSelectors.projectData(state) ?? {},
        upscaleImage,
        formData: selectors.currentFormData(state).formData,
        currentUpscaleListConfig: selectors.currentUpscaleListConfig(state),
        request: selectors.upscaleRequest(state)
      }
    }),
    map(({ formData, currentUpscaleListConfig, upscaleImage, ...restParam }) => ({
      textureText: formData?.showTexture ? `-texture_${formData?.texture}` : '',
      denoiseText: formData?.showSmooth ? `-denoise_${formData?.smooth}` : '',
      deblockingText: formData?.deblocking ? `-deblocking_enabled` : '',
      scaleText: `${formData?.scale}x`,
      titleText: currentUpscaleListConfig?.title ?? '',
      idText: upscaleImage.output.id ?? '',
      extension: formData?.extension,
      upscaleImage,
      ...restParam
    })),
    map(
      ({
        upscaleImage,
        textureText,
        denoiseText,
        scaleText,
        deblockingText,
        titleText,
        idText,
        extension,
        ...restParam
      }) => ({
        upscaleImageId: upscaleImage.id,
        userImage: upscaleImage.output,
        fileName: `upscaled-${titleText}-${idText}-${scaleText}${textureText}${denoiseText}${deblockingText}${extension}`,
        ...restParam
      })
    ),
    mergeMap(
      ({
        userImage,
        nextAction,
        fileName,
        upscaleImageId,
        showPaidSnackBar = false,
        downloadLocation,
        price
      }) =>
        action$.pipe(
          filter(isActionOf(downloaderActions.single.payAndDownloadResponse)),
          take(1),
          mergeMap(() =>
            _compact([
              nextAction === 'closeDialog' ? dialogActions.closeDialog() : undefined,
              apiActions.imageEnhancement.retrieveUpscaleImage(upscaleImageId)
            ])
          ),
          startWith(
            downloaderActions.single.payAndDownload({
              price,
              downloadLocation,
              showPaidSnackBar,
              userImage,
              fileName
            })
          )
        )
    )
  )

const loadUpscaleListEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.upscaleList.loadUpscaleList)),
    withLatestFrom(state$),
    map(([_, state]) => {
      const listParams = selectors.upscaleListListParam(state)
      return { listParams }
    }),
    mergeMap(({ listParams }) =>
      action$.pipe(
        filter(isActionOf(apiActions.imageEnhancement.listUpscaleImageResponse)),
        take(1),
        map(() => actions.upscaleList.setLoaded(true)),
        startWith(apiActions.imageEnhancement.listUpscaleImage({ param: listParams, next: false }))
      )
    )
  )

const setOpenedUpscaleImageEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.upscaleList.setOpenedUpscaleImage)),
    filter(({ payload }) => Boolean(payload)),
    map(({ payload = 0 }) => {
      return payload
    }),
    filter(id => Boolean(id)),
    map(id => apiActions.imageEnhancement.retrieveUpscaleImage(id ?? 0))
  )

const setImageFileEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.setImageFile)),
    mergeMap(({ payload }) =>
      merge(
        of(payload).pipe(
          filter(payload => (payload?.file.size ?? 0) > values.MAX_IMAGE_SIZE),
          tap(payload => {
            SentryUtils.captureMessage(
              `Unable To Upload Images - ${errorMessage.ERROR_UPLOAD_TOO_LARGE.content}`,
              { name: payload?.file.name, size: payload?.file.size },
              'error'
            )
          }),
          mergeMap(() => [
            dialogActions.openDialog({
              [ErrorDialog.ERROR]: {
                dialogName: ErrorDialog.ERROR,
                title: `Unable To Upload Images`,
                content: errorMessage.ERROR_UPLOAD_TOO_LARGE.content
              }
            }),
            actions.reset()
          ])
        ),
        of(payload).pipe(
          filter(
            payload =>
              (payload?.width ?? 0) * 2 > values.MAX_UPSCALE_SIZE ||
              (payload?.height ?? 0) * 2 > values.MAX_UPSCALE_SIZE
          ),
          tap(payload => {
            SentryUtils.captureMessage(
              `Unable To Upload Images - ${'Image size is too large'}`,
              {
                name: payload?.file.name,
                size: payload?.file.size,
                width: payload?.width,
                height: payload?.height
              },
              'error'
            )
          }),
          mergeMap(() => [
            dialogActions.openDialog({
              [ErrorDialog.ERROR]: {
                dialogName: ErrorDialog.ERROR,
                title: `Unable To Upload Images `,
                content: `The image size is too large to be upscaled. Playform support upscale up to ${Format.number(
                  values.MAX_UPSCALE_SIZE
                )} px.`
              }
            }),
            actions.reset()
          ])
        )
      )
    )
  )

export const epics = combineEpics(
  showEditEpic,
  setImageFileEpic,
  setSelectedUserImageIdEpic,
  setShowEditPanelEpic,
  downloadEpic,
  downloadCurrentUpscaleEpic,
  updateListParamEpic,
  retrieveUpscaleDataEpic,
  setOpenedUpscaleImageEpic,
  listenOnAppEvent,
  submitUpscaleImageEpic,
  loadUpscaleListEpic
)
export default reducer
