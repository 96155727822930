import { ReactElement } from 'react'
import { CollectionEnoughStateType } from 'duck/ApiDuck/selectors/ProjectsSelectors'
import { ActionTypeParam } from '..'
import { XOR } from 'ts-xor'
import { DialogProps } from 'components/Dialog'
import { AddCreditSource } from 'containers/MonetizationDialog/duck/Models'
import { PayloadAction } from 'typesafe-actions'
import { ImageEditData, ImageCropperProps } from 'components/ImageCropper'
import { CropperSize } from 'containers/AppPage/Dialogs/ImageCropperDialog'
import { ProjectObjectType, UserImage } from 'models/ApiModels'

export type DialogContentCommand = 'use-in-new-project'

export type ImageCropperContent = {
  title?: string
  description?: string
  imageEdit?: ImageEditData
  onSave?: ImageCropperProps['onSave']
  imageFile?: File
  cropperSize?: CropperSize
  aspectRatios?: [number, number][]
  enableAspectRatio?: boolean
}

export type DialogActionType = {
  label?: string
  actions?: (ActionTypeParam | Function)[]
}

export type DialogPayloadBasic<Name extends DialogNameType> = {
  dialogName: Name
  title?: undefined
  content?: undefined
  onClose?: Function
}
export type DialogPayloadComplete<Name extends DialogNameType, Content = undefined> = {
  dialogName: Name
  title?: string
  content?: Content
  children?: ReactElement
  onClose?: Function
}
export type DialogPayloadConfirmation<
  Name extends DialogNameType,
  Content = undefined
> = DialogPayloadComplete<Name, Content> & {
  cancelActionText?: string
  thumbnail?: UserImage
  classes?: DialogProps['classes']
  swapActionPosition?: boolean
  yesAction: DialogActionType
}

export const ErrorDialog = {
  ERROR: 'error',
  PROJECT_NOT_FOUND: 'error.projectNotFound',
  NOT_ENOUGH_GAS_PRICE: 'error.notEnoughGasPrice',
  UPLOAD_FAILED: 'error.uploadFailed',
  NOT_ENOUGH_IMAGES: 'error.notEnoughImages',
  TOO_MANY_IMAGES: 'error.tooManyImages',
  REUSED_COLLECTION: 'error.reusedCollection',
  REFERRAL_ERROR: 'error.referralError',
  PHONE_UNVERIFIED: 'error.phoneUnverified'
} as const
export const InformationDialog = {
  INFORMATION: 'information',
  COLLECTION_REMOVED: 'information.collectionRemoved',
  GENERATING_IMAGES_FROM_PROJECT: 'information.generating_images_from_project',
  LOGIN_REQUIRED: 'information.loginRequired',
  INCREASE_GAS: 'information.increaseGas'
} as const

export const ConfirmationDialog = {
  CONFIRMATION: 'confirmation',
  REMOVE_PAYMENT_METHOD: 'confirmation.removePaymentMethod',
  DELETE_PROJECT: 'confirmation.deleteProject',
  DELETE_COLLECTION: 'confirmation.deleteCollection',
  LOGOUT: 'confirmation.logout',
  PAUSE: 'confirmation.pause',
  DELETE_POST_ADMIN: 'confirmation.deletePostAdmin',
  DELETE_POST_OWNER: 'confirmation.deletePostOwner',
  UNSAVED_CHANGES: 'confirmation.unsavedChanges'
} as const

export const ChangeEmailDialog = {
  CHANGE_EMAIL: 'changeEmail',
  CONFIRM_NEW_EMAIL: 'changeEmail.confirmNewEmail',
  CONFIRM_NEW_EMAIL_HELP: 'changeEmail.confirmNewEmailHelp',
  EMAIL_SUCCESSFULY_CHANGED: 'changeEmail.emailSuccessfulyChanged',
  ERROR_DIALOG: 'changeEmail.errorDialog',
  RESENT_CONFIRM_NEW_EMAIL: 'changeEmail.resentConfirmNewEmail',
  RESENT_CURRENT_EMAIL: 'changeEmail.resentCurrentEmail',
  SENT_CURRENT_EMAIL: 'changeEmail.sentCurrentEmail',
  SENT_CURRENT_EMAIL_HELP: 'changeEmail.sentCurrentEmailHelp'
} as const

export const MonetizationDialog = {
  ADD_CREDIT: 'monetization.addCredit',
  TRAINING_SETUP: 'monetization.trainingSetup',
  FIRST_TRAINING_SETUP: 'monetization.firstTrainingSetup',
  SAVE_CARD: 'monetization.saveCard',
  WARNING_CONTINUE_TRAINING: 'monetization.warningContinueTraining',
  IMAGE_DOWNLOAD: 'monetization.imageDownload',
  SUBSCRIPTION_PANEL: 'monetization.subscriptionPanel',
  CONTINUE_SUBSCRIPTION: 'monetization.continueSubscription',
  CONTINUE_INACTIVE_SUBSCRIPTION: 'monetization.continueInactiveSubscription'
} as const

export const FormDialog = {
  EMAIL_FOR_SIGNIN_LINK: 'formDialog.emailForSigninLink',
  NEW_PROJECT: 'formDialog.newProject',
  PHONE_VERIFICATION: 'formDialog.phoneVerification',
  SEND_QUESTION: 'formDialog.sendQuestion',
  SEND_QUESTION_SUCCESS: 'formDialog.sendQuestionSuccess',
  SHARE_FEED: 'socialDialog.shareFeed',
  EMAIL_PREFERENCES: 'email.emailPreferences',
  UPSCALE_RESULT: 'formDialog.upscale',
  IMAGE_ENHANCEMENT: 'formDialog.imageEnhancement',
  IMAGE_CROPPER: 'formDialog.imageCropper',
  SELL_AS_NFT_INFO: 'formDialog.sellAsNftInfo',
  SELL_AS_NFT_FORM: 'formDialog.sellAsNftForm',
  METAMASK_WALLET: 'formDialog.metamaskWallet',
  TEXT_TO_IMAGE_USER_AGREEMENT: 'formDialog.textToImageUserAgreement'
} as const

export type DialogTypeMap = {
  //Error Dialog
  [ErrorDialog.ERROR]: DialogPayloadComplete<'error', string | (string | undefined)[]>
  [ErrorDialog.PROJECT_NOT_FOUND]: DialogPayloadBasic<'error.projectNotFound'>
  [ErrorDialog.NOT_ENOUGH_GAS_PRICE]: DialogPayloadBasic<'error.notEnoughGasPrice'>
  [ErrorDialog.UPLOAD_FAILED]: DialogPayloadComplete<
    'error.uploadFailed',
    { customTitle?: string; errorList?: string[]; count?: number }
  >
  [ErrorDialog.NOT_ENOUGH_IMAGES]: DialogPayloadComplete<
    'error.notEnoughImages',
    CollectionEnoughStateType
  >
  [ErrorDialog.TOO_MANY_IMAGES]: DialogPayloadComplete<
    'error.tooManyImages',
    CollectionEnoughStateType
  >
  [ErrorDialog.REUSED_COLLECTION]: DialogPayloadComplete<
    'error.reusedCollection',
    { process?: string }
  >
  [ErrorDialog.REFERRAL_ERROR]: DialogPayloadBasic<'error.referralError'>
  [ErrorDialog.PHONE_UNVERIFIED]: DialogPayloadBasic<'error.phoneUnverified'>

  // Information Dialog
  [InformationDialog.INFORMATION]: DialogPayloadComplete<'information', string> & {
    closeText?: string
  }
  [InformationDialog.INCREASE_GAS]: DialogPayloadBasic<'information.increaseGas'> & {
    continueAction: ActionTypeParam
    continueText: string
  }
  [InformationDialog.COLLECTION_REMOVED]: DialogPayloadBasic<'information.collectionRemoved'>
  [InformationDialog.GENERATING_IMAGES_FROM_PROJECT]: DialogPayloadBasic<'information.generating_images_from_project'>
  [InformationDialog.LOGIN_REQUIRED]: DialogPayloadBasic<'information.loginRequired'>

  // Confirmation
  [ConfirmationDialog.CONFIRMATION]: DialogPayloadConfirmation<
    'confirmation',
    ReactElement | string
  >

  [ConfirmationDialog.REMOVE_PAYMENT_METHOD]: DialogPayloadConfirmation<'confirmation.removePaymentMethod'>
  [ConfirmationDialog.DELETE_PROJECT]: DialogPayloadConfirmation<
    'confirmation.deleteProject',
    { projectName: string }
  >
  [ConfirmationDialog.DELETE_COLLECTION]: DialogPayloadConfirmation<
    'confirmation.deleteCollection',
    { collectionName: string }
  >
  [ConfirmationDialog.LOGOUT]: DialogPayloadConfirmation<'confirmation.logout'>

  [ConfirmationDialog.PAUSE]: DialogPayloadConfirmation<
    'confirmation.pause',
    { projectName: string; unusedCredit: number | null }
  >
  [ConfirmationDialog.DELETE_POST_ADMIN]: DialogPayloadConfirmation<'confirmation.deletePostAdmin'>
  [ConfirmationDialog.DELETE_POST_OWNER]: DialogPayloadConfirmation<'confirmation.deletePostOwner'>
  [ConfirmationDialog.UNSAVED_CHANGES]: DialogPayloadConfirmation<'confirmation.unsavedChanges'>

  // Change Email
  [ChangeEmailDialog.CHANGE_EMAIL]: DialogPayloadBasic<'changeEmail'>
  [ChangeEmailDialog.CONFIRM_NEW_EMAIL]: DialogPayloadBasic<'changeEmail.confirmNewEmail'>
  [ChangeEmailDialog.CONFIRM_NEW_EMAIL_HELP]: DialogPayloadBasic<'changeEmail.confirmNewEmailHelp'>
  [ChangeEmailDialog.EMAIL_SUCCESSFULY_CHANGED]: DialogPayloadBasic<'changeEmail.emailSuccessfulyChanged'>
  [ChangeEmailDialog.ERROR_DIALOG]: DialogPayloadBasic<'changeEmail.errorDialog'>
  [ChangeEmailDialog.RESENT_CONFIRM_NEW_EMAIL]: DialogPayloadBasic<'changeEmail.resentConfirmNewEmail'>
  [ChangeEmailDialog.RESENT_CURRENT_EMAIL]: DialogPayloadBasic<'changeEmail.resentCurrentEmail'>
  [ChangeEmailDialog.SENT_CURRENT_EMAIL]: DialogPayloadBasic<'changeEmail.sentCurrentEmail'>
  [ChangeEmailDialog.SENT_CURRENT_EMAIL_HELP]: DialogPayloadBasic<'changeEmail.sentCurrentEmailHelp'>

  //Monetization
  [MonetizationDialog.ADD_CREDIT]: DialogPayloadBasic<'monetization.addCredit'> & {
    requiredCredit?: number
    source: AddCreditSource
    additionalAction?: {
      creditConsumed: number
      text: string
      textAfterSuccess: string
      action: PayloadAction<string, any>
    }
  }
  [MonetizationDialog.TRAINING_SETUP]: DialogPayloadBasic<'monetization.trainingSetup'>
  [MonetizationDialog.FIRST_TRAINING_SETUP]: DialogPayloadBasic<'monetization.firstTrainingSetup'>
  [MonetizationDialog.SAVE_CARD]: DialogPayloadBasic<'monetization.saveCard'>
  [MonetizationDialog.CONTINUE_SUBSCRIPTION]: DialogPayloadBasic<'monetization.continueSubscription'>
  [MonetizationDialog.CONTINUE_INACTIVE_SUBSCRIPTION]: DialogPayloadBasic<'monetization.continueInactiveSubscription'>
  [MonetizationDialog.WARNING_CONTINUE_TRAINING]: DialogPayloadBasic<'monetization.warningContinueTraining'>
  [MonetizationDialog.IMAGE_DOWNLOAD]: DialogPayloadBasic<'monetization.imageDownload'> & {
    price: number
    downloadAction: PayloadAction<string, any>
  }
  [MonetizationDialog.SUBSCRIPTION_PANEL]: DialogPayloadBasic<'monetization.subscriptionPanel'> & {
    fullPage?: boolean
  }

  //Form Dialog
  [FormDialog.EMAIL_FOR_SIGNIN_LINK]: DialogPayloadBasic<'formDialog.emailForSigninLink'>
  [FormDialog.NEW_PROJECT]: DialogPayloadComplete<'formDialog.newProject', DialogContentCommand>
  [FormDialog.PHONE_VERIFICATION]: DialogPayloadBasic<'formDialog.phoneVerification'>
  [FormDialog.SEND_QUESTION]: DialogPayloadBasic<'formDialog.sendQuestion'>
  [FormDialog.SEND_QUESTION_SUCCESS]: DialogPayloadBasic<'formDialog.sendQuestionSuccess'>
  [FormDialog.SHARE_FEED]: DialogPayloadBasic<'socialDialog.shareFeed'>
  [FormDialog.EMAIL_PREFERENCES]: DialogPayloadBasic<'email.emailPreferences'>
  [FormDialog.UPSCALE_RESULT]: DialogPayloadBasic<'formDialog.upscale'>
  [FormDialog.METAMASK_WALLET]: DialogPayloadBasic<'formDialog.metamaskWallet'>
  [FormDialog.IMAGE_ENHANCEMENT]: DialogPayloadBasic<'formDialog.imageEnhancement'>
  [FormDialog.IMAGE_CROPPER]: DialogPayloadComplete<'formDialog.imageCropper', ImageCropperContent>
  [FormDialog.SELL_AS_NFT_INFO]: DialogPayloadComplete<
    'formDialog.sellAsNftInfo',
    { id: number; projectType: ProjectObjectType }
  >
  [FormDialog.SELL_AS_NFT_FORM]: DialogPayloadComplete<
    'formDialog.sellAsNftForm',
    XOR<{ id: number; projectType: ProjectObjectType }, { artMineProjectId: number }>
  >
  [FormDialog.TEXT_TO_IMAGE_USER_AGREEMENT]: DialogPayloadBasic<'formDialog.textToImageUserAgreement'>
}

export type DialogType = keyof DialogTypeMap

type ErrorDialogNameList = keyof typeof ErrorDialog
type ConfirmationDialogNameList = keyof typeof ConfirmationDialog
type ChangeEmailDialogNameList = keyof typeof ChangeEmailDialog
type InformationDialogNameList = keyof typeof InformationDialog
type MonetizationDialogNameList = keyof typeof MonetizationDialog
type FormDialogNameList = keyof typeof FormDialog

export type ErrorDialogNameType = (typeof ErrorDialog)[ErrorDialogNameList]
export type ConfirmationDialogNameType = (typeof ConfirmationDialog)[ConfirmationDialogNameList]
export type ChangeEmailDialogNameType = (typeof ChangeEmailDialog)[ChangeEmailDialogNameList]
export type InformationDialogNameType = (typeof InformationDialog)[InformationDialogNameList]
export type MonetizationDialogNameType = (typeof MonetizationDialog)[MonetizationDialogNameList]
export type FormDialogNameType = (typeof FormDialog)[FormDialogNameList]

export const MonetizationDialogNameArray: MonetizationDialogNameType[] = [
  MonetizationDialog.TRAINING_SETUP,
  MonetizationDialog.FIRST_TRAINING_SETUP,
  MonetizationDialog.ADD_CREDIT,
  MonetizationDialog.SAVE_CARD,
  MonetizationDialog.WARNING_CONTINUE_TRAINING
]

export type DialogNameList =
  | ErrorDialogNameList
  | ConfirmationDialogNameList
  | ChangeEmailDialogNameList
  | InformationDialogNameList
  | MonetizationDialogNameList
  | FormDialogNameList

export type DialogNameType =
  | ErrorDialogNameType
  | ConfirmationDialogNameType
  | ChangeEmailDialogNameType
  | InformationDialogNameType
  | MonetizationDialogNameType
  | FormDialogNameType
