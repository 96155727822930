import React from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import _isNil from 'lodash/isNil'
import { RootState, RootActionType } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import Button from 'components/Button'
import { dialogActions, MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import { actions, Utils } from '../duck'
import { useBreakpoint } from 'utils/Hooks'
import { selectors } from '../duck'
import { DEFAULT_FORM_DATA, UPSCALE_LIST_CONFIG } from '../Models'
import { Format } from 'utils/TextUtils'
import ButtonText from 'components/Button/ButtonText'
import { appActions } from 'duck/AppDuck'
import { UpscaleDetailProps } from '.'
import { ReduxProps } from 'utils/Types'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './UpscaleDetail.module.scss'

const mapStateToProps = (state: RootState) => ({
  creditBalance: apiSelectors.creditBalance(state),
  downloadPricing: selectors.downloadPricing(state),
  openedUpscale: selectors.openedUpscale(state),
  downloadingImage: apiSelectors.loading['payment.downloadingImage'](state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      pushTo: appActions.pushTo,
      addDialog: dialogActions.addDialog,
      download: actions.download,
      setShowEditPanel: actions.upscaleList.setShowEditPanel
    },
    dispatch
  )

type UpscaleDetailContentProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  Pick<UpscaleDetailProps, 'onClickEditUpscale'>

const UpscaleDetailContent: React.FC<UpscaleDetailContentProps> = props => {
  const {
    openedUpscale,
    downloadPricing,
    downloadingImage,
    onClickEditUpscale,
    addDialog,
    download
  } = props

  const { isMediumscreenAbove } = useBreakpoint()
  const scale = openedUpscale?.scale ?? 2
  const { price, creditBalance, endBalance, hasEnoughBalance } = downloadPricing[scale]
  const methodText = openedUpscale?.method ? UPSCALE_LIST_CONFIG[openedUpscale.method].title : ''

  const isStillLoading = !Boolean(openedUpscale?.output)
  const isFree = price === 0
  const hasDownload = Boolean(openedUpscale?.output?.download)
  const isNotAvailable = price < 0 && !hasDownload
  const disableDownloadButton =
    isStillLoading ||
    Boolean(downloadingImage) ||
    (!hasEnoughBalance && !hasDownload) ||
    isNotAvailable

  return (
    <div className={clsx('flex flex-col', styles.UpscaleDetailContent)}>
      <div className="overflow-auto px-6 pb-9 pt-6 md:h-1/100 md:min-h-0 md:flex-auto">
        <div className="mb-6 flex">
          <div className="w-1/100 min-w-0 flex-auto">
            <Typography variant="h6">{Utils.getUpscaleTitle(openedUpscale)}</Typography>
          </div>

          <div>
            <Button
              className="-mr-2 -mt-1.5"
              color="secondary"
              size="small"
              onClick={() => {
                onClickEditUpscale(openedUpscale?.id ?? 0)
                // if (variant === 'home') {
                //   pushTo(route.UPSCALES.getUrl({ id: openedUpscale?.id ?? 0, subRoute: 'edit' }))
                // } else {
                //   setShowEditPanel({
                //     imageId: openedUpscale?.image.id,
                //     selectedMethod: openedUpscale?.method
                //   })
                // }
              }}
            >
              EDIT UPSCALE
            </Button>
          </div>
        </div>

        <div className="mb-2">
          <Grid spacing={1} container>
            <Grid sm item>
              <div className="mb-4">
                <Typography variant="overline" className="text-white-med-on-dark" component="div">
                  RESOLUTION
                </Typography>

                <Typography variant="body1" component="div">
                  {Utils.getResolutionText(openedUpscale)}
                </Typography>
              </div>

              <div className="mb-4">
                <Typography variant="overline" className="text-white-med-on-dark" component="div">
                  UPSCALING METHOD
                </Typography>

                <Typography variant="body1" component="div">
                  {methodText}
                </Typography>
              </div>

              <div className="mb-4">
                <Typography variant="overline" className="text-white-med-on-dark" component="div">
                  FILE TYPE
                </Typography>

                <Typography variant="body1" component="div">
                  .jpeg
                </Typography>
              </div>
            </Grid>

            <Grid sm item>
              <div className="mb-4">
                <Typography variant="overline" className="text-white-med-on-dark" component="div">
                  JPEG Deblocking
                </Typography>

                <Typography variant="body1" component="div">
                  {openedUpscale?.controls.deblocking ? 'Enabled' : 'Disabled'}
                </Typography>
              </div>

              {!_isNil(openedUpscale?.controls.texture) &&
              openedUpscale?.controls.texture !== DEFAULT_FORM_DATA['texture'] ? (
                <div className="mb-4">
                  <Typography variant="overline" className="text-white-med-on-dark" component="div">
                    TEXTURE
                  </Typography>

                  <Typography variant="body1" component="div">
                    {openedUpscale?.controls.texture}
                  </Typography>
                </div>
              ) : null}

              {!_isNil(openedUpscale?.controls.smooth) &&
              openedUpscale?.controls.smooth !== DEFAULT_FORM_DATA['smooth'] ? (
                <div className="mb-4">
                  <Typography variant="overline" className="text-white-med-on-dark" component="div">
                    DENOISE
                  </Typography>

                  <Typography variant="body1" component="div">
                    {openedUpscale?.controls.smooth}
                  </Typography>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        {hasDownload ? (
          <div className={clsx('flex pt-6', stylesGlobal.BorderTopOnSurface)}>
            <Typography variant="body1" component="div">
              You’ve purchased and downloaded this image before, you can re-download with no extra
              cost
            </Typography>
          </div>
        ) : (
          <>
            <div className={clsx('flex pt-6', stylesGlobal.BorderTopOnSurface)}>
              <div>
                <Typography variant={isMediumscreenAbove ? 'body1' : 'caption'} component="span">
                  Current Balance
                </Typography>

                {!hasEnoughBalance ? (
                  <>
                    {!isMediumscreenAbove ? <br /> : null}
                    <ButtonText
                      className={clsx('px-0.5 py-1', isMediumscreenAbove && 'ml-2.5')}
                      onClick={() =>
                        addDialog({
                          [MonetizationDialog.ADD_CREDIT]: {
                            dialogName: MonetizationDialog.ADD_CREDIT,
                            source: 'download',
                            requiredCredit: Math.abs(endBalance)
                          }
                        })
                      }
                    >
                      <Typography color="secondary" variant="overline" component="span">
                        BUY MORE CREDIT
                      </Typography>
                    </ButtonText>
                  </>
                ) : null}
              </div>

              <Typography
                variant="h6"
                component="div"
                color={!hasEnoughBalance ? 'error' : undefined}
                className="w-1/100 min-w-0 flex-auto break-words pl-2 text-right"
              >
                {`${Format.creditAmount(creditBalance)} Credits`}
              </Typography>
            </div>

            <div className="mt-4 flex">
              <Typography variant={isMediumscreenAbove ? 'body1' : 'caption'} component="div">
                Download Without {!isMediumscreenAbove ? <br /> : null} Watermark
              </Typography>

              <Typography
                variant="h6"
                component="div"
                className="w-1/100 min-w-0 flex-auto break-words pl-2 text-right"
              >
                {isNotAvailable
                  ? ' - '
                  : isFree
                    ? 'Free'
                    : `-${Format.creditAmount(price ?? 0)} Credits`}
              </Typography>
            </div>
          </>
        )}
      </div>

      <div>
        <div
          className={clsx(
            'px-6 py-3',
            styles.UpscaleDetailActionHelper,
            !isStillLoading && 'text-center'
          )}
        >
          {isStillLoading ? (
            <Typography className="mt-7 text-white-med-on-dark" variant="caption" component="div">
              {openedUpscale?.scale && openedUpscale?.scale > 4
                ? 'Please wait, higher resolution upscales need a little more time to process.'
                : ''}
            </Typography>
          ) : (
            <Typography variant="caption" className="text-white-med-on-dark">
              {hasEnoughBalance
                ? 'See '
                : 'You don’t have enough credits to download this image! Buy more credits or see '}
              <ButtonText
                color="secondary"
                onClick={() => {
                  addDialog({
                    [MonetizationDialog.SUBSCRIPTION_PANEL]: {
                      dialogName: MonetizationDialog.SUBSCRIPTION_PANEL,
                      fullPage: true
                    }
                  })
                }}
              >
                PLAN AND PRICING
              </ButtonText>
              {` for unlimited downloads`}
            </Typography>
          )}
        </div>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            download({
              downloadLocation: 'Upscale - Upscale Dialog',
              price,
              showPaidSnackBar: !isFree,
              upscaleId: openedUpscale?.id
            })
          }}
          disabled={disableDownloadButton}
          fullWidth
        >
          {isNotAvailable
            ? 'DOWNLOAD NOT AVAILABLE'
            : Boolean(downloadingImage)
              ? 'DOWNLOADING'
              : hasDownload || isFree
                ? 'DOWNLOAD'
                : `PAY & DOWNLOAD`}
        </Button>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpscaleDetailContent)
