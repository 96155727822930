import { FormDialog } from 'duck/AppDuck/DialogDuck'
import loadable from 'utils/Loadable'
import { useDialogDetector } from 'components/Dialog/Hooks'

const NFTSellInfo = loadable(() => import(/* webpackChunkName: "NFTSellInfo" */ './index'))

const NFTSellInfoContainer = () => {
  const open = useDialogDetector(FormDialog.SELL_AS_NFT_INFO)

  if (!open) return null

  return <NFTSellInfo />
}

export default NFTSellInfoContainer
