import React, { useCallback, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import preval from 'preval.macro'
import { ReduxProps } from 'utils/Types'
import { MAIN_PAGE, NavbarMenuType, NOTIFICATION, route } from 'routes'
import { useWindowDimension } from 'utils/Hooks'
import LogoWrapper from './LogoWrapper'
import { NameUtils } from 'utils/TextUtils'
import { dialogActions, InformationDialog } from 'duck/AppDuck/DialogDuck'
import { RootState, RootActionType } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import { appSelectors, appActions } from 'duck/AppDuck'
import loadable from 'utils/Loadable'
import { User } from 'models/ApiModels'

import stylesGlobal from 'stylesGlobal.module.scss'
import styles from './NavBarGeneral.module.scss'

const NavBarMobile = loadable(
  () => import(/* webpackChunkName: "NavBarGeneralNavBarMobile" */ './NavBarMobile')
)
const NavBarDesktop = loadable(
  () => import(/* webpackChunkName: "NavBarGeneralNavBarDesktop" */ './NavBarDesktop')
)
const NotificationsPanel = loadable(
  () => import(/* webpackChunkName: "NavBarGeneralNotificationsPanel" */ './NotificationsPanel')
)

const ROUTE_WITH_MAIN_MENU = [route.WELCOME.getUrl()]

const classesNavDrawer = {
  paper: clsx('h-full w-full bg-primary', styles.NavNotificationsDrawerPaper)
}

const mapStateToProps = (state: RootState) => ({
  routerLocation: appSelectors.routerLocation(state),
  user: apiSelectors.user(state),
  activeMenu: appSelectors.activeMenu(state),
  unreadCount: apiSelectors.unreadCount(state),
  isUserLoading: appSelectors.isUserLoading(state),
  isAuthorized: appSelectors.isAuthorized(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      openDialog: dialogActions.openDialog,
      setActiveMenu: appActions.setActiveMenu
    },
    dispatch
  )

export type NavBarGeneralProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  authenticating?: boolean
}

export type NavbarDesktopProps = Pick<
  NavBarGeneralProps,
  'isAuthorized' | 'unreadCount' | 'setActiveMenu' | 'activeMenu' | 'openDialog'
> & {
  isOpen?: boolean
  loading?: boolean
  routeValue?: TabRouteType
  showMainMenu?: boolean
  userName?: string
  userAlias?: User['alias']
}

export type NavbarMobileProps = NavbarDesktopProps & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export type TabRouteType = {
  title: string
  path: NavbarMenuType
}

export const TabRoute: {
  [routeName: string]: TabRouteType
} = {
  explore: {
    title: 'Explore',
    path: route.EXPLORE.getUrl()
  },
  profile: {
    title: 'Profile',
    path: route.PROFILE.getUrl()
  },
  home: {
    title: 'Home',
    path: MAIN_PAGE
  },
  login: {
    title: 'Login',
    path: route.SIGN_IN.getUrl()
  }
}

const NavBarGeneral: React.FC<NavBarGeneralProps> = props => {
  const {
    isAuthorized,
    isUserLoading,
    routerLocation,
    user,
    authenticating,
    unreadCount,
    activeMenu,
    setActiveMenu,
    openDialog
  } = props
  const windowSize = useWindowDimension()
  const [isOpen, setOpen] = useState(false)
  const currentRoute = routerLocation?.pathname.replace(/\/+$/, '') // Remove trailing slash
  const dateTimeStamp = useRef(preval`module.exports = new Date().toLocaleString();`)
  const isInArtistsPage = currentRoute?.includes(route.ARTISTS.getUrl())

  const onContextMenu = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault()
      openDialog({
        [InformationDialog.INFORMATION]: {
          dialogName: InformationDialog.INFORMATION,
          title: `Playform 2.0`,
          content: `BUILD DATE: ${dateTimeStamp.current}`
        }
      })
    },
    [dateTimeStamp, openDialog]
  )

  const useNavBarDesktop = (windowSize.width ?? 0) >= 1200
  const routeValue = useMemo(() => {
    let routeValue

    if (isInArtistsPage) {
      routeValue = TabRoute.profile
    } else {
      for (const key of Object.keys(TabRoute)) {
        if (currentRoute?.includes(TabRoute[key].path)) {
          routeValue = TabRoute[key]
          break
        }
      }
    }

    return routeValue || TabRoute.home
  }, [currentRoute, isInArtistsPage])

  const isRouteWithoutMainMenu = ROUTE_WITH_MAIN_MENU.includes(currentRoute)
  const isLoadingFinished = authenticating === false

  return (
    <AppBar
      className={clsx(stylesGlobal.BorderBottomOnSurface, styles.NavBarInner)}
      color="default"
      position="relative"
    >
      <div className="flex justify-between">
        {useNavBarDesktop || (!useNavBarDesktop && !isLoadingFinished) ? (
          <LogoWrapper
            onContextMenu={onContextMenu}
            onClick={
              isLoadingFinished
                ? () => {
                    if (activeMenu) setActiveMenu('')
                  }
                : undefined
            }
          />
        ) : null}

        {isLoadingFinished && useNavBarDesktop ? (
          <NavBarDesktop
            openDialog={openDialog}
            unreadCount={unreadCount}
            showMainMenu={!isRouteWithoutMainMenu}
            loading={typeof isUserLoading === 'string' ? Boolean(isUserLoading) : isUserLoading}
            isAuthorized={isAuthorized}
            activeMenu={activeMenu || routeValue?.path}
            setActiveMenu={setActiveMenu}
            userName={NameUtils.getSlicedName(user)}
            userAlias={user?.alias}
          />
        ) : null}

        {isLoadingFinished && !useNavBarDesktop ? (
          <NavBarMobile
            openDialog={openDialog}
            unreadCount={unreadCount}
            showMainMenu={!isRouteWithoutMainMenu}
            loading={Boolean(isUserLoading)}
            isAuthorized={isAuthorized}
            userName={NameUtils.getSlicedName(user)}
            setOpen={setOpen}
            isOpen={isOpen}
            activeMenu={activeMenu || routeValue?.path}
            setActiveMenu={setActiveMenu}
            userAlias={user?.alias}
          />
        ) : null}
      </div>

      {isLoadingFinished ? (
        <Drawer
          className={styles.NavDrawer}
          anchor="right"
          open={activeMenu === NOTIFICATION}
          onClose={() => {
            setActiveMenu('')
          }}
          classes={classesNavDrawer}
        >
          <div className={clsx('h-full', styles.NavDrawerContent)} role="presentation">
            <NotificationsPanel
              handleCloseMenu={() => {
                setOpen(false)
                setActiveMenu('')
              }}
            />
          </div>
        </Drawer>
      ) : null}
    </AppBar>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarGeneral)
