import { RootState } from 'duck'
import { createSelector } from 'reselect'
import { firebaseActions, THIRD_PARTY_SIGN_IN } from '.'
import { getType } from 'typesafe-actions'
import { SignInProvider } from './actions'
/* Return Firebase user data */
const selectFirebase = (state: RootState) => {
  return state.firebase
}

const selectFirebaseUser = (state: RootState) => {
  return state.firebase.firebaseUser
}
const selectIsLoading = createSelector(selectFirebase, firebase => firebase.loading)
const selectIsAfterLogout = createSelector(selectFirebase, firebase => firebase.isAfterLogout)

const selectPhoneVerificationId = createSelector(
  selectFirebase,
  firebase => firebase.phoneVerificationId
)
const hasUnverifiedPhone = createSelector(selectPhoneVerificationId, phoneVerificationId =>
  Boolean(phoneVerificationId)
)
const selectCurrentUserFirebaseUid = createSelector(
  selectFirebaseUser,
  firebaseUser => firebaseUser?.uid
)
const selectUserPhoneNumber = createSelector(
  selectFirebaseUser,
  selectPhoneVerificationId, //Force update when phone verification change
  firebaseUser => firebaseUser?.phoneNumber
)
const selectPendingCredential = createSelector(
  selectFirebase,
  firebase => firebase?.pendingCredential
)

const selectIdToken = createSelector(selectFirebase, firebase => firebase?.idToken)

const selectFirebaseErr = createSelector(selectFirebase, firebase => firebase?.firebaseErr)

const selectIsCanUnlinkThirdParty = createSelector(selectFirebase, firebase => {
  const providerData = firebase.firebaseUser?.providerData ?? []

  //Email link using "password"
  const hasEmailLink = Boolean(providerData.find(value => value?.providerId === 'password'))
  const hasMultipleThirdParty =
    providerData.filter(
      value => value?.providerId && THIRD_PARTY_SIGN_IN.includes(value.providerId as SignInProvider)
    ).length > 1

  return hasEmailLink || hasMultipleThirdParty
})

const loadings = (state: RootState) => {
  return state.api.shared.loadings || {}
}

const loading = {
  'firebaseActions.updatePhoneNumber': createSelector(
    loadings,
    loadings => loadings[getType(firebaseActions.updatePhoneNumber)]
  ),
  'firebaseActions.removePhone': createSelector(
    loadings,
    loadings => loadings[getType(firebaseActions.removePhone)]
  ),
  'firebaseActions.verifyPhone': createSelector(
    loadings,
    loadings => loadings[getType(firebaseActions.verifyPhone)]
  )
}

export const firebaseSelectors = {
  firebaseUser: selectFirebaseUser,
  userId: createSelector(selectFirebaseUser, firebaseUser => {
    return firebaseUser?.uid
  }),
  idToken: selectIdToken,
  isCanUnlinkThirdParty: selectIsCanUnlinkThirdParty,
  currentEmail: createSelector(selectFirebase, firebase => firebase.currentEmail),
  firebaseErr: selectFirebaseErr,
  pendingCredential: selectPendingCredential,
  loading,
  hasUnverifiedPhone,
  phoneVerificationId: selectPhoneVerificationId,
  isLoading: selectIsLoading,
  userPhoneNumber: selectUserPhoneNumber,
  isAfterLogout: selectIsAfterLogout,
  currentUserFirebaseUid: selectCurrentUserFirebaseUid
}
