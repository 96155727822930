/*
 * Container for LocalStorage
 *
 *
 * Provide standarized command to add remove and get data from local storage.
 * Also provided some text obsfuscator so data that saved in localstorage not in plain text
 */

/* Some simple text obfuscator */

const doTheThing = (key: number, data: string | number | null): string => {
  let me = `${data}` // unlink reference

  const newKey = Number(String(Number(key))) === key ? Number(key) : 13 // optionaly provide key for symmetric-like-""encryption"".

  const step = me
    .split('') // to array of characters.
    .map(c => c.charCodeAt(0)) // to array of numbers (each is character's ASCII value)
    .map(i => i ^ newKey) // eslint-disable-line no-bitwise
  me = String.fromCharCode.apply(undefined, step) // one-liner trick: array-of-numbers to array-of-characters (ASCII value), join to single string. may result in buffer-overflow on long string!
  return me
}

const k = 231

const sessionStorage = window.sessionStorage

export const get = (key: string) => {
  try {
    const data = sessionStorage.getItem(key)
    if (data) {
      return doTheThing(k, data)
    }
    return ''
  } catch (e) {
    return ''
  }
}

export const save = (key: string, data: string | number) => {
  try {
    if (key && (data || data === 0)) {
      sessionStorage.setItem(key, doTheThing(k, data))
      return true
    }
    return false
  } catch (e) {
    return false
  }
}
export const saveJSON = (key: string, data: object) => {
  try {
    if (key && data) {
      sessionStorage.setItem(key, doTheThing(k, JSON.stringify(data)))
      return true
    }
    throw new Error()
  } catch (e) {
    return false
  }
}
export const getJSON = (key: string) => {
  try {
    const data = doTheThing(k, sessionStorage.getItem(key))
    return JSON.parse(data)
  } catch (e) {
    return ''
  }
}
export const remove = (key: string | string[]) => {
  try {
    if (key) {
      if (Array.isArray(key)) {
        key.forEach(x => {
          sessionStorage.removeItem(x)
        })
      } else {
        sessionStorage.removeItem(key)
      }
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
export const clear = () => {
  try {
    localStorage.clear()
  } catch (e) {
    return false
  }
}

export const utils = {
  //Get data that contains particular string pattern
  getContains: (contains: string) => {
    const results: any[] = []
    const keys = Object.keys(sessionStorage) || []

    keys.forEach(val => {
      if (val.includes(contains)) {
        results.push(get(val))
      }
    })

    return results
  }
}
