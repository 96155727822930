import React, { memo, useRef } from 'react'

import { getElementScroll, useElementScrollInit, useElementScroll } from './Hooks'

type ChilrenFunctionType = (
  scrollRef: React.MutableRefObject<HTMLDivElement | null>
) => React.ReactElement

type ElementScrollProps = {
  fpsLimit?: number
  onBottom?: Function
  onBottomThrottled?: Function
  name: string
  children: ChilrenFunctionType
  offsetBottom?: number
  onScroll?: Function
  onScrollX?: Function
}

const ElementScroll: React.FC<ElementScrollProps> = memo(props => {
  const { children, ...restProps } = props
  const optionalScrollContainerRef = useRef<HTMLDivElement | null>(null)

  useElementScrollInit(props.name, optionalScrollContainerRef)
  useElementScroll(restProps)

  return children(optionalScrollContainerRef)
})

export { getElementScroll, useElementScroll, useElementScrollInit }

export default ElementScroll
