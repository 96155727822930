import { forwardRef, Fragment, ReactNode, Ref, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { useElementScrollInit } from 'components/ElementScroll'
import styles from './Page.module.scss'

const emptyArray: any = []

export type VariantType = 'no-content-padding'

export type PageProps = {
  // autoHeight?: boolean
  children?: ReactNode
  className?: string
  contentClassName?: string
  fullWidth?: boolean
  disableContentSpacing?: boolean
  marginTop?: number
  subpage?: boolean
  variants?: VariantType[]
}

const VarianStyeList = {
  'no-content-padding': styles.PageContentNoPadding
}

const Page = forwardRef((props: PageProps, ref: Ref<any>) => {
  const {
    children,
    className,
    contentClassName,
    fullWidth,
    marginTop = 0,
    variants = emptyArray,
    disableContentSpacing,
    subpage
  } = props
  const pageRef = useRef<HTMLElement | null>(null)
  const pageRefAdjusted = ref || pageRef
  const variantsList = useMemo(
    () => variants.map((variant: VariantType) => VarianStyeList[variant]),
    [variants]
  )

  useElementScrollInit(subpage ? 'Subpage' : 'Page', pageRefAdjusted)

  return (
    <Fragment>
      <div
        className={clsx(
          styles.Page,
          // autoHeight && styles.PageAutoHeight,
          fullWidth && styles.PageFullWidth,
          ...variantsList,
          disableContentSpacing && styles.PageContentNoPadding,
          className
        )}
        ref={pageRefAdjusted}
        style={{ marginTop }}
      >
        <div className={clsx(styles.PageContent, contentClassName)}>{children}</div>
      </div>
    </Fragment>
  )
})

// const PageWithScrollEvent = forwardRef((props: PageProps, ref: Ref<any>) => {
//   // useElementScrollInit('Page', props.pageScrollRef)

//   return <PageContent ref={ref} {...props} />
// })

// const Page = forwardRef((props: PageProps, ref: Ref<any>) => {
//   return <PageContent ref={ref} {...props} />
// })

export default Page
