/**
 * Asynchronously loads the component for dashboardpage
 */

import loadable from 'utils/Loadable'
import { LoadingPage } from 'components/Loading'

export default loadable(() => import(/* webpackChunkName: "HomePage" */ './index'), {
  fallback: <LoadingPage delay={1} />
})
