import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { RootState, RootActionType } from 'duck'
import { subscriptionActions } from './duck'
import { useDelayedData } from 'utils/Hooks'
import { appSelectors } from 'duck/AppDuck'
import { MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import { ReduxProps } from 'utils/Types'
import loadable from 'utils/Loadable'

const SubscriptionPanel = loadable(
  () => import(/* webpackChunkName: "SubscriptionPanel" */ './index')
)

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state),
  activeDialogOverlay: appSelectors.dialog.activeDialogOverlay(state),
  dialogData: appSelectors.dialog.dialogData(state)[MonetizationDialog.SUBSCRIPTION_PANEL]
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      setStep: subscriptionActions.setStep,
      initSubscriptionPanel: subscriptionActions.initSubscriptionPanel,
      resetSubscriptionPage: subscriptionActions.resetSubscriptionPage
    },
    dispatch
  )

export type SubscriptionPanelContainerProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
>

const SubscriptionPanelContainer: React.FC<SubscriptionPanelContainerProps> = props => {
  const {
    initSubscriptionPanel,
    resetSubscriptionPage,
    setStep,
    activeDialog,
    dialogData,
    activeDialogOverlay
  } = props

  const open =
    activeDialog === MonetizationDialog.SUBSCRIPTION_PANEL ||
    activeDialogOverlay === MonetizationDialog.SUBSCRIPTION_PANEL
  const fullPage = dialogData?.fullPage
  const onClose = dialogData?.onClose
  const openDelayed = useDelayedData(open, 500)

  useEffect(() => {
    if (open) {
      initSubscriptionPanel()
    }
  }, [initSubscriptionPanel, open])

  const onCloseDialog = useCallback(() => {
    onClose?.()
    setStep(undefined)
    resetSubscriptionPage()
  }, [resetSubscriptionPage, setStep, onClose])

  if (!open && !openDelayed) return null

  return <SubscriptionPanel open={open} fullPage={fullPage} onClose={onCloseDialog} />
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPanelContainer)
