import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import React from 'react'

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition
    }
  ]
}

function useDNDProviderElement(props: { children: React.ReactNode }) {
  if (!props.children) return null
  return <DndProvider options={HTML5toTouch}>{props.children}</DndProvider>
}

export default function DragAndDrop(props: { children: React.ReactNode }) {
  const DNDElement = useDNDProviderElement(props)
  return <>{DNDElement}</>
}
