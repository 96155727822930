import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { CircularLoading } from 'components/Loading'
import { RootState, RootActionType } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import { appSelectors } from 'duck/AppDuck'
import { GeneralDialogItemProps } from '..'
import DialogWrapper from '../DialogWrapper'
import { dialogActions } from 'duck/AppDuck/DialogDuck'
import { changeEmailActions } from 'duck/AppDuck/ChangeEmailDuck'
import Button from 'components/Button'
import styles from '../Dialogs.module.scss'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  activeDialog: appSelectors.dialog.activeDialog(state),
  changeEmailData: appSelectors.changeEmail.changeEmail(state),
  userEmail: apiSelectors.currentUserEmail(state),
  dialogData: appSelectors.dialog.dialogData(state),
  changeEmailLoading: appSelectors.changeEmail.isLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      resubmitEmail: changeEmailActions.resubmitEmail
    },
    dispatch
  )

type DialogConfirmNewEmailHelpProps = ReduxProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps
> &
  GeneralDialogItemProps

const DialogConfirmNewEmailHelp: React.FC<DialogConfirmNewEmailHelpProps> = props => {
  const { activeDialog, changeEmailLoading, onClose, closeDialog, resubmitEmail } = props

  return (
    <DialogWrapper
      dialogConfig={{
        className: styles.EmailDialog
      }}
      activeDialog={activeDialog}
      onClose={onClose}
      title={<Typography variant="h5">Having trouble?</Typography>}
      actions={
        <Fragment>
          <Button disabled={changeEmailLoading} color="secondary" onClick={() => resubmitEmail()}>
            Resend Email
          </Button>

          <Button disabled={changeEmailLoading} color="secondary" onClick={() => closeDialog()}>
            OK
          </Button>
        </Fragment>
      }
      content={
        <Fragment>
          {changeEmailLoading ? (
            <CircularLoading className={styles.LoadingStyle} loading={true} />
          ) : (
            <Fragment>
              <div className={clsx(styles.ContentGroup, styles.DottedList, styles.CompactList)}>
                <span>
                  <Typography variant="body2">Common fixes:</Typography>
                </span>
                <ul>
                  <li>
                    <Typography variant="body2">
                      Checking if the email was marked as spam or filtered.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">Check your internet connection.</Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Check that you did not misspell your email.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Check that your inbox space is not running out or other inbox issues.
                    </Typography>
                  </li>
                </ul>
              </div>
              <div className={styles.ContentGroup}>
                <Typography variant="body2">
                  If these steps didn’t work, you can resend the email. Doing so will deactivate the
                  link in the older email.
                </Typography>
              </div>
            </Fragment>
          )}
        </Fragment>
      }
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirmNewEmailHelp)
