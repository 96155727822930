import { apiActions, GenericAppActionType } from '../actions'
import { SharedActionsType } from '../sharedActions'
import {
  GenericApp,
  GenericAppListResponse,
  GenericAppProject,
  GenericAppProjectListResponse,
  GenericAppOutputImageListResponse,
  GenericAppOutputImage
} from 'models/ApiModels'
import { createReducer, getType } from 'typesafe-actions'
import _map from 'lodash/map'
import _keyBy from 'lodash/keyBy'
import _uniq from 'lodash/uniq'
import _without from 'lodash/without'

export type GenericAppState = {
  currentGenericApp?: number
  genericApp: {
    list: number[]
    data: {
      [id: number]: GenericApp
    }
    lastReq?: GenericAppListResponse
  }
  genericAppProject: {
    list: number[]
    data: {
      [id: number]: GenericAppProject
    }
    lastReq?: GenericAppProjectListResponse
  }
  outputLists: {
    [genericAppProjectId: string]:
      | {
          lastListReq: GenericAppOutputImageListResponse
          list: number[]
        }
      | undefined
  }
  outputs: { [genericAppOutputId: number]: GenericAppOutputImage }
}

export const INITIAL_STATE: GenericAppState = {
  currentGenericApp: undefined,
  genericApp: {
    list: [],
    data: {},
    lastReq: undefined
  },
  genericAppProject: {
    list: [],
    data: {},
    lastReq: undefined
  },
  outputLists: {},
  outputs: {}
}

export const genericAppReducers = createReducer<
  GenericAppState,
  GenericAppActionType | SharedActionsType
>(INITIAL_STATE)
  .handleAction(apiActions.genericApp.setCurrentGenericAppProject, (state, action) => {
    const id = action?.payload

    return {
      ...state,
      currentGenericApp: id
    }
  })
  .handleAction(apiActions.genericApp.listGenericAppResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.genericApp.list || [] : []

    const combinedResults = results.map(result => ({
      ...(state.genericApp.data[result.id] ?? {}),
      ...result
    }))
    return {
      ...state,
      genericApp: {
        list: [...prevData, ..._map(results, result => result.id)],
        lastReq: data,
        data: {
          ...state.genericApp.data,
          ..._keyBy(combinedResults, result => result.id)
        }
      }
    }
  })
  .handleAction([apiActions.genericApp.retrieveGenericAppResponse], (state, action) => {
    const id = action.payload?.id || 0
    const currentData = state.genericApp.data[id] ?? {}

    return {
      ...state,
      genericApp: {
        ...state.genericApp,
        data: {
          ...state.genericApp.data,
          [id]: {
            ...currentData,
            ...action.payload
          }
        }
      }
    }
  })
  .handleAction(apiActions.genericApp.listGenericAppProjectResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.genericAppProject.list || [] : []

    const combinedResults = results.map(result => ({
      ...(state.genericAppProject.data[result.id] ?? {}),
      ...result
    }))
    return {
      ...state,
      genericAppProject: {
        list: [...prevData, ..._map(results, result => result.id)],
        lastReq: data,
        data: {
          ...state.genericAppProject.data,
          ..._keyBy(combinedResults, result => result.id)
        }
      }
    }
  })
  .handleAction(apiActions.genericApp.listGenericAppOutputResponse, (state, action) => {
    const { data, next, req } = action.payload

    const projectId = req?.project || 0
    const results = data?.results ?? []
    const prevData = next ? state.outputLists[projectId]?.list || [] : []

    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [projectId]: {
          lastListReq: data,
          list: _uniq([...prevData, ..._map(results, result => result.id)])
        }
      },
      outputs: {
        ...state.outputs,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(
    [
      apiActions.genericApp.createGenericAppProjectResponse,
      apiActions.genericApp.retrieveGenericAppProjectResponse,
      apiActions.genericApp.updateGenericAppProjectResponse
    ],
    (state, action) => {
      const isCreate =
        action.type === getType(apiActions.genericApp.createGenericAppProjectResponse)
      const id = action.payload?.id || 0
      const currentData = state.genericAppProject.data[id] ?? {}
      const currentList = state.genericAppProject.list ?? []

      return {
        ...state,
        genericAppProject: {
          ...state.genericAppProject,
          data: {
            ...state.genericAppProject.data,
            [id]: {
              ...currentData,
              ...action.payload
            }
          },
          list: isCreate ? [id, ...currentList] : currentList
        }
      }
    }
  )
  .handleAction(apiActions.genericApp.deleteGenericAppProjectResponse, (state, action) => {
    const { [action.payload]: deletedProject, ...genericAppProjects } = state.genericAppProject.data

    const itProjectList = _without(state.genericAppProject.list || [], deletedProject.id)

    return {
      ...state,
      genericAppProject: {
        ...state.genericAppProject,
        data: genericAppProjects,
        list: itProjectList
      }
    }
  })
  .handleAction(apiActions.genericApp.deleteGenericAppProjectOutputResponse, (state, action) => {
    const currentGenericApp = state.currentGenericApp ?? 0
    const currentList = state.outputLists[currentGenericApp]?.list ?? []
    const newList = _without(currentList || [], action.payload)
    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [currentGenericApp]: {
          ...state.outputLists[currentGenericApp],
          list: newList
        }
      }
    }
  })
  .handleAction(apiActions.genericApp.deleteAllGenericAppProjectOutputResponse, (state, action) => {
    const currentGenericApp = state.currentGenericApp ?? 0

    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [currentGenericApp]: undefined
      }
    }
  })
