import { DialogActions, dialogActions } from './actions'
import { createReducer, getType } from 'typesafe-actions'
import MixPanelUtils from 'utils/MixPanelUtils'
import { DialogNameType, DialogTypeMap } from './Models'

/*
  Show Dialog Duck
  - Features
  -- Open multiple dialog
  -- Error Dialog
  -- Customizable Content
*/

export type DialogState = {
  data: Partial<DialogTypeMap>
  show: DialogNameType[]
  showOverlay?: DialogNameType
}

const initialState: DialogState = {
  data: {},
  show: [],
  showOverlay: undefined
}

const reducer = createReducer<DialogState, DialogActions>(initialState)
  .handleAction(
    [dialogActions.addDialog, dialogActions.openDialog, dialogActions.addDialogOverlay],
    (state, action) => {
      const isOpen = action.type === getType(dialogActions.openDialog)
      const isOverlay = action.type === getType(dialogActions.addDialogOverlay)
      const payload = action.payload
      const keysRaw = Object.keys(payload)
      const keys = keysRaw as DialogNameType[]

      keys.forEach(value => {
        MixPanelUtils.track<'USER__OPEN_DIALOG'>('User - Open Dialog', {
          dialog_name: value,
          dialog_content: `${payload?.[value]?.title} - ${payload?.[value]?.content}`
        })
      })

      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        },
        show: isOverlay ? state.show : isOpen ? [...keys] : [...keys, ...state.show],
        showOverlay: isOverlay ? keys[0] : state.showOverlay
      }
    }
  )
  .handleAction(dialogActions.closeDialog, state => {
    const newShow = [...state.show]
    newShow.shift()

    return state.showOverlay
      ? {
          ...state,
          showOverlay: undefined
        }
      : {
          ...state,
          show: newShow
        }
  })
  .handleAction(dialogActions.closeAllDialog, state => {
    return {
      ...state,
      data: {},
      show: [],
      showOverlay: undefined
    }
  })

export default reducer
