import creditCardType from 'credit-card-type'
import _toNumber from 'lodash/toNumber'

export const getCreditCardType = (value?: string) => {
  if (!value) return undefined

  return creditCardType(value)
}

/* toNumber with NaN fallback */
export const toNumber = (value?: any) => {
  if (typeof value === 'undefined') return 0

  const result = _toNumber(value)

  return !isNaN(result) ? result : 0
}
