import { RootState } from 'duck'
import { TIOutputImage } from 'models/ApiModels'
import { createSelector } from 'reselect'
import { adjustedImages, userImages } from './CommonSelectors'

/* User related data */

const textToImage = (state: RootState) => {
  return state.api?.textToImage
}

const currentTextToImageProjectId = createSelector(textToImage, value => value.currentTextToImage)
const textToImageProjectsData = createSelector(textToImage, value => value.textToImageProjects.data)

const currentTextToImageProjectRaw = createSelector(
  currentTextToImageProjectId,
  textToImageProjectsData,
  (id, data) => (id ? data[id] : undefined)
)

const textToImageProjects = createSelector(
  textToImage,
  textToImage => textToImage.textToImageProjects.data
)
const textToImageProjectListId = createSelector(
  textToImage,
  textToImage => textToImage.textToImageProjects.list
)

const textToImageProjectList = createSelector(
  textToImageProjects,
  textToImageProjectListId,
  (textToImageProjects, textToImageProjectListId) =>
    textToImageProjectListId.map(id => textToImageProjects?.[id] || {})
)
const outputLists = createSelector(textToImage, textToImage => textToImage.outputLists)
const textToImageOutputs = createSelector(textToImage, textToImage => textToImage.outputs)

const currentTextToImageProjectOutputList = createSelector(
  outputLists,
  currentTextToImageProjectId,
  (outputList, currentTextToImageProjectId) =>
    currentTextToImageProjectId ? outputList[currentTextToImageProjectId] : undefined
)

const currentTextToImageProjectOutputCount = createSelector(
  currentTextToImageProjectOutputList,
  currentOutputList => currentOutputList?.lastListReq?.count
)

const currentTextToImageProject = createSelector(
  currentTextToImageProjectRaw,
  currentTextToImageProjectOutputList,
  userImages,
  textToImageOutputs,
  adjustedImages,
  (
    currentTextToImageProjectRaw,
    currentTextToImageProjectOutputList,
    userImages,
    textToImageOutputs,
    adjustedImages
  ) => {
    const currentOutputs = currentTextToImageProjectOutputList?.list ?? []
    const outputs: TIOutputImage[] = currentOutputs?.map(sketchOutputId => {
      const currentOutput = textToImageOutputs[sketchOutputId]
      const imageId = currentOutput?.image?.id
      const image = userImages[imageId] ?? currentOutput?.image

      return {
        ...currentOutput,
        image: {
          ...image,
          adjustData: adjustedImages[image.adjust ?? 0]
        },
        bookmark: userImages[imageId]?.bookmark ?? 0
      }
    })

    return currentTextToImageProjectRaw ? { ...currentTextToImageProjectRaw, outputs } : undefined
  }
)

const isCurrentTextToImageOutputHasNext = createSelector(
  currentTextToImageProjectOutputList,
  currentProArtFilterProjectOutputList =>
    Boolean(currentProArtFilterProjectOutputList?.lastListReq?.next)
)

const textToImageSelectors = {
  currentTextToImageProjectId,
  textToImageProjectList,
  textToImageOutputs,
  currentTextToImageProjectOutputList,
  currentTextToImageProjectOutputCount,
  currentTextToImageProject,
  textToImage,
  textToImageProjects,
  isCurrentTextToImageOutputHasNext
}

export default textToImageSelectors
