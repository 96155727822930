import React, { MouseEventHandler } from 'react'
import clsx from 'clsx'
import { Dispatch, Action } from 'redux'
import SnackbarMaterial, { SnackbarOrigin as SnackbarMaterialOrigin } from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { SnackbarAction } from 'duck/AppDuck/SnackBarDuck'
import { values } from 'appConstants'
import { useDelayedData } from 'utils/Hooks'
import Button from 'components/Button'

import styles from './Snackbar.module.scss'

const DEFAULT_ANCHOR_ORIGIN: SnackbarMaterialOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
}

const CLASSES_SNACKBAR_CONTENT = {
  action: styles.SnackbarAction
}

type SnackbarType = {
  show?: boolean
  content?: string
  onClose?: (event: React.SyntheticEvent<any> | Event, reason: string) => void
  dismissable?: boolean
  className?: string
  actionText?: string
  onClick?: MouseEventHandler
  onClickAction?: SnackbarAction
  dispatch?: Dispatch<Action<any>>
}

const renderContent = (content?: string) => {
  return <Typography variant="body1">{content}</Typography>
}

type ActionComponentProps = Pick<
  SnackbarType,
  'onClick' | 'onClickAction' | 'actionText' | 'dispatch'
>

const ActionComponent: React.FC<ActionComponentProps> = props => {
  const { onClick, onClickAction, actionText, dispatch } = props
  const onClickSelected = onClickAction ? () => dispatch?.(onClickAction) : onClick
  if (onClickSelected) {
    return (
      <Button onClick={onClickSelected}>
        <Typography variant="caption">{actionText}</Typography>
      </Button>
    )
  }
  return <Typography variant="caption">{actionText}</Typography>
}

const Snackbar: React.FC<SnackbarType> = props => {
  const {
    show = false,
    content,
    actionText,
    onClickAction,
    className,
    dismissable = false,
    onClick,
    onClose,
    dispatch
  } = props

  return (
    <SnackbarMaterial
      anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
      className={clsx(styles.Snackbar, className)}
      open={show}
      onClose={dismissable ? onClose : undefined}
    >
      <SnackbarContent
        className={clsx(styles.SnackbarContent, !actionText && styles.WithoutAction)}
        classes={CLASSES_SNACKBAR_CONTENT}
        message={renderContent(content)}
        action={
          actionText ? (
            <ActionComponent
              onClick={onClick}
              onClickAction={onClickAction}
              actionText={actionText}
              dispatch={dispatch}
            />
          ) : undefined
        }
      />
    </SnackbarMaterial>
  )
}

const SnackbarContainer: React.FC<SnackbarType> = props => {
  const contentState = useDelayedData(props.content, values.DIALOG_TRANSITION_DURATION)

  if (!props.show && !contentState) return null

  return <Snackbar {...props} content={contentState} />
}

export default SnackbarContainer
