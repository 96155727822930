import React, { MouseEventHandler } from 'react'
import clsx from 'clsx'
import { route } from 'routes'
import { Link as RouterLink } from 'react-router-dom'
import { useBreakpoint } from 'utils/Hooks'
import { LogoIcon } from 'components/Icon'
import Logo from 'assets/images/playform-logo.svg'
import { ImgPure } from 'components/Img'
import styles from './NavBarGeneral.module.scss'

type LogoWrapperProps = {
  onContextMenu?: MouseEventHandler
  onClick?: MouseEventHandler
}

const LogoWrapper: React.FC<LogoWrapperProps> = ({ onContextMenu, onClick }) => {
  const { isMediumscreenAbove } = useBreakpoint()
  return (
    <RouterLink
      className="relative my-auto mr-9.5 flex h-12 items-center text-white-med-on-dark md:h-14 md:pl-6"
      to={route.ROOT.paths[0]}
      onClick={onClick}
    >
      <LogoIcon className="ml-1.5 h-9.5 w-9.5 md:hidden" alt="Playform" />
      <ImgPure src={Logo} className="hidden h-7 md:block" alt="Playform" title="Playform" />
      {onContextMenu && isMediumscreenAbove ? (
        <span
          onContextMenu={onContextMenu}
          className={clsx(
            'absolute top-4.5 hidden h-4.5 w-4.5 opacity-50 md:block',
            styles.LogoPlayformAbout
          )}
        ></span>
      ) : null}
    </RouterLink>
  )
}

export default LogoWrapper
