import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { merge, of } from 'rxjs'
import { combineEpics, Epic } from 'redux-observable'
import { filter, map, mergeMap, withLatestFrom, take, startWith, tap } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, isActionOf, getType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { appSelectors } from 'duck/AppDuck'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { DISABLE_ADVANCED_SETTING } from 'appConstants'
import {
  AdvancedControlKeyType,
  ContinueAbleProjectStatus,
  EngineConfigFreeformList,
  EngineConfigKeyType,
  EngineConfigCreativeMorphList,
  TrainProjectStartReq,
  AdvancedControlReq
} from 'models/ApiModels'
import { dialogActions, MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import MixPanelUtils, { DataUtils, Events } from 'utils/MixPanelUtils'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'
import { DEFAULT_SAVE_INTERVAL, SAVE_INTERVAL_MULTIPLIER_MAP } from './Models'
import { addCreditSelectors } from './AddCredit'

// Constants
const NAMESPACE = '@@page/MonetizationDialog/TrainingForm'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const trainingFormActions = {
  setTraningLoading: createAction(creator('SET_TRAINING_LOADING'))<boolean | string>(),
  getInitialEngineConfigData: createAction(creator('GET_INITIAL_ENGINE_CONFIG_DATA'))(),
  getInitialTrainingSetupData: createAction(creator('GET_INITIAL_TRAINING_SETUP_DATA'))(),
  setFormData: createAction(creator('SET_FORM_DATA'))<Partial<TrainingFormState['formData']>>(),
  setInitialFormData: createAction(creator('SET_INITIAL_FORM_DATA'))(),
  resetForm: createAction(creator('RESET_FORM'))(),
  startTraining: createAction(creator('START_TRAINING'))(),
  startTrainingWithoutDialog: createAction(creator('START_TRAINING_WITHOUT_DIALOG'))()
}

// Selectors
const selectTrainingFormState = (state: RootState) =>
  state.container.monetizationDialog.trainingForm

const selectTrainingForm = createSelector(
  selectTrainingFormState,
  apiSelectors.creditBalance,
  apiSelectors.engineConfigData,
  apiSelectors.currentProject,
  apiSelectors.inspirationCount,
  apiSelectors.engineConfigSummary,
  apiSelectors.configVariables,
  (
    trainingForm,
    creditBalance,
    engineConfigData,
    currentProject,
    inspirationCount,
    engineConfigSummary,
    configVariables
  ) => {
    const engineConfig = engineConfigData[currentProject.category]
    /* For style migration model, their outputs number is depend on 
       inspiration number. So this api will return 0 for that. Need frontend to directly show 
       inspiration number on start training dialog. 
    */
    const isStart = currentProject.status === 'drafted'
    const current_balance = creditBalance
    const { doubleInputCategory } = engineConfigSummary
    const batch_outputs = doubleInputCategory.includes(currentProject.category)
      ? inspirationCount
      : engineConfig?.batch_outputs ?? 0 //outputs number in each batch

    const batch_time_cost = engineConfig?.batch_time_cost ?? 0 //int, time in minutes in each batch, should be used as minimum unit when charge user
    const initial_batches = isStart ? engineConfig?.start_recommend_batches ?? 0 : undefined //int, minimum batches needed in first training
    const initial_time_cost = isStart ? engineConfig?.start_recommend_time_cost ?? 0 : undefined //int, time in minutes in first training, recommended time
    const minimum_time_cost = isStart
      ? engineConfig?.start_time_cost_floor ?? 0
      : engineConfig?.more_time_cost_floor ?? 0 // Minimum
    const unit_time_cost = engineConfig?.unit_time_cost ?? 0 //Use this as a step in slider
    const maximum_time_cost = isStart
      ? engineConfig?.start_time_cost_cap ?? 0
      : engineConfig?.more_time_cost_cap ?? 0

    const formData = trainingForm.formData
    const minutes = (formData['minutes'] ?? 0) as number
    const save_interval = formData.save_interval ?? DEFAULT_SAVE_INTERVAL

    const save_interval_multiplier = SAVE_INTERVAL_MULTIPLIER_MAP[save_interval] ?? 1

    const number_of_snapshots_base = Math.floor(minutes / batch_time_cost)
    const number_of_snapshots = number_of_snapshots_base * save_interval_multiplier
    const number_of_iterations = (engineConfig?.save_interval || 0) * number_of_snapshots_base

    const number_of_result_images = batch_outputs * number_of_snapshots * save_interval_multiplier

    const training_minutes_per_atx_credit = configVariables?.training_minutes_per_atx_credit
    const cost_of_training = training_minutes_per_atx_credit
      ? minutes * (1 / training_minutes_per_atx_credit)
      : NaN

    return {
      ...formData,
      number_of_snapshots,
      number_of_iterations,
      number_of_result_images,

      minutes,
      cost_of_training,
      ending_balance: current_balance - cost_of_training,
      current_balance,
      initial_batches,
      initial_time_cost,
      minimum_time_cost,
      maximum_time_cost,
      unit_time_cost: unit_time_cost <= 0 ? 15 : unit_time_cost,
      target_resolution: engineConfig?.target_resolution ?? ''
    }
  }
)

export const trainingFormSelectors = {
  trainingForm: selectTrainingForm,
  enabledAdvancedTrainingSetting: createSelector(apiSelectors.currentProject, currentProject => {
    const enabledList: AdvancedControlKeyType[] = []
    const projectCategory = currentProject.category as EngineConfigKeyType
    const isFreeform = EngineConfigFreeformList.includes(projectCategory)
    const isCreativeMorph = EngineConfigCreativeMorphList.includes(projectCategory)
    // const isDraft = currentProject.status === 'drafted'

    if (isFreeform) {
      enabledList.push('save_interval')
      enabledList.push('mirror')
    }
    // if (isFreeform && isDraft) {
    //   enabledList.push('details')
    // }

    if (isCreativeMorph) {
      enabledList.push('save_interval')
    }

    return DISABLE_ADVANCED_SETTING ? [] : enabledList
  }),
  shouldDisplayBackButton: createSelector(
    appSelectors.dialog.activeDialog,
    appSelectors.dialog.hasMultipleDialog,
    addCreditSelectors.addCredit,
    (activeDialog, hasMultipleDialog, addCredit) => {
      const { paymentLoading, addCreditSuccess } = addCredit

      return (
        activeDialog !== MonetizationDialog.TRAINING_SETUP &&
        hasMultipleDialog &&
        !paymentLoading &&
        !addCreditSuccess
      )
    }
  ),

  startTrainingLoading: createSelector(
    apiSelectors.loading['projects.start'],
    apiSelectors.loading['projects.more'],
    apiSelectors.loading['projects.update'],
    (projectsStartLoading, projectsMoreLoading, projectsUpdateLoading) =>
      Boolean(projectsStartLoading || projectsMoreLoading || projectsUpdateLoading)
  ),

  fetchDataLoading: createSelector(
    apiSelectors.loading['payment.retrieveChannels'],
    apiSelectors.loading['payment.retrieveProducts'],
    apiSelectors.loading['engine.listEngineConfig'],
    apiSelectors.hasEngineConfig,
    (
      paymentRetrieveChannelsLoading,
      paymentRetrieveProductsLoading,
      listEngineConfigLoading,
      hasEngineConfig
    ) =>
      Boolean(
        paymentRetrieveChannelsLoading ||
          paymentRetrieveProductsLoading ||
          (listEngineConfigLoading && !hasEngineConfig)
      )
  )
}

// Reducer

export type TrainingFormState = {
  trainingLoading: boolean
  formData: Omit<TrainProjectStartReq, 'id'>
}

const INITIAL: TrainingFormState = {
  formData: {
    minutes: 0,
    mirror: true,
    details: 1,
    save_interval: DEFAULT_SAVE_INTERVAL
  },
  trainingLoading: false
}

const reducer = produce((state: TrainingFormState, { type, payload }) => {
  switch (type) {
    case getType(trainingFormActions.setFormData): {
      const data = payload as ActionType<typeof trainingFormActions.setFormData>['payload']
      const currentFormData = state.formData
      state.formData = { ...currentFormData, ...data }
      return
    }
    case getType(trainingFormActions.resetForm): {
      state.formData = INITIAL.formData
      return
    }
    case getType(trainingFormActions.setTraningLoading): {
      const loading = payload as ActionType<typeof trainingFormActions.setTraningLoading>['payload']

      state.trainingLoading = Boolean(loading)
      return
    }
    default:
      break
  }
}, INITIAL)

// Epics

const setInitialFormDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(trainingFormActions.setInitialFormData)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      hasEngineConfig: apiSelectors.hasEngineConfig(state),
      trainingForm: trainingFormSelectors.trainingForm(state),
      type: action.type,
      userData: apiSelectors.user(state)
    })),
    filter(({ hasEngineConfig }) => hasEngineConfig),
    map(({ trainingForm }) => ({
      newMinutes: trainingForm?.initial_time_cost || trainingForm?.minimum_time_cost
    })),
    map(({ newMinutes }) =>
      trainingFormActions.setFormData({
        minutes: newMinutes
      })
    )
  )

const getInitialTrainingSetupDataEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        trainingFormActions.getInitialEngineConfigData,
        trainingFormActions.getInitialTrainingSetupData
      ])
    ),
    withLatestFrom(state$),
    map(([action, state]) => ({
      hasEngineConfig: apiSelectors.hasEngineConfig(state),
      trainingForm: trainingFormSelectors.trainingForm(state),
      type: action.type,
      userData: apiSelectors.user(state)
    })),
    mergeMap(engineConfigState =>
      merge(
        /* if has engine config */
        of(engineConfigState).pipe(
          filter(({ hasEngineConfig }) => hasEngineConfig),
          map(() => trainingFormActions.setInitialFormData())
        ),
        /* If doesnt have engine config, then fetch it*/
        of(engineConfigState).pipe(
          filter(({ hasEngineConfig }) => !hasEngineConfig),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.engine.listEngineConfigResponse)),
              take(1),
              withLatestFrom(state$),
              map(() => trainingFormActions.setInitialFormData()),
              startWith(apiActions.engine.listEngineConfig())
            )
          )
        ),
        of(engineConfigState).pipe(
          filter(({ type }) => type === getType(trainingFormActions.getInitialTrainingSetupData)),
          map(() => apiActions.payment.retrieveCreditOverview())
        )
      )
    )
  )
const startTrainingWithoutDialogEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(trainingFormActions.startTrainingWithoutDialog)),
    mergeMap(() =>
      action$.pipe(
        filter(isActionOf(apiActions.payment.retrieveCreditOverviewResponse)),
        take(1),
        mergeMap(() =>
          action$.pipe(
            filter(isActionOf(trainingFormActions.setFormData)),
            take(1),
            mergeMap(() =>
              action$.pipe(
                filter(isActionOf(apiActions.projects.startResponse)),
                take(1),
                mergeMap(() => [
                  dialogActions.closeAllDialog(),
                  apiActions.users.updateUiExtras({ is_did_initial_project_creation: true })
                ]),
                startWith(trainingFormActions.startTraining())
              )
            ),
            startWith(trainingFormActions.getInitialEngineConfigData())
          )
        ),
        startWith(apiActions.payment.retrieveCreditOverview())
      )
    )
  )

const startTrainingEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(trainingFormActions.startTraining)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const trainingForm = trainingFormSelectors.trainingForm(state)
      const enabledAdvancedTrainingSetting =
        trainingFormSelectors.enabledAdvancedTrainingSetting(state)
      const endingBalance = trainingForm?.ending_balance as number
      const minutes = trainingForm?.minutes as number

      const currentProject = apiSelectors.currentProject(state)

      const mixPanelParam: Events['PROJECT__CONTINUE_TRAINING']['properties'] = {
        ...DataUtils.getProjectParam<'training_project'>('training_project', {
          trainProject: currentProject
        }),
        quantity: minutes ?? 0,
        current_balance: endingBalance ?? 0,
        previous_balance: trainingForm?.current_balance ?? 0,
        number_of_snapshots: trainingForm?.number_of_snapshots ?? 0,
        number_of_results: trainingForm?.number_of_result_images ?? 0,
        prev_number_of_snapshots: currentProject.total_snapshot ?? 0,
        prev_number_of_results: currentProject.outputs_count ?? 0,
        resolution: trainingForm?.target_resolution
      }

      return {
        mixPanelParam,
        endingBalance,
        projectStatus: currentProject?.status ?? 'drafted',
        projectId: currentProject?.id ?? 0,
        minutes,
        advancedSetting: enabledAdvancedTrainingSetting.reduce((result, value) => {
          if (value === 'details') {
            result.details = trainingForm['details']
          }
          if (value === 'mirror') {
            result.mirror = trainingForm['mirror']
          }
          if (value === 'save_interval') {
            result.save_interval = trainingForm['save_interval']
          }
          return result
        }, {} as Partial<AdvancedControlReq>),
        ui_extras: currentProject?.ui_extras ?? {}
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ endingBalance }) => endingBalance >= 0),
          filter(
            ({ projectStatus }) =>
              projectStatus === 'drafted' || ContinueAbleProjectStatus.includes(projectStatus)
          ),
          mergeMap(param =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.updateResponse)),
              take(1),
              mergeMap(() =>
                merge(
                  of(param).pipe(
                    filter(({ endingBalance }) => endingBalance >= 0),
                    filter(({ projectStatus }) => projectStatus === 'drafted'),
                    mergeMap(({ projectId, minutes, advancedSetting }) =>
                      action$.pipe(
                        filter(isActionOf(apiActions.projects.startResponse)),
                        take(1),
                        tap(() => {
                          MixPanelUtils.track<'PROJECT__START_TRAINING'>(
                            'Project - Start Training',
                            param.mixPanelParam
                          )
                          FacebookPixelUtils.track<'START_TRAINING'>('start_training')
                        }),
                        mergeMap(() => [
                          dialogActions.closeAllDialog(),
                          apiActions.payment.retrieveCreditOverview()
                        ]),
                        startWith(
                          apiActions.projects.start({
                            id: projectId,
                            minutes,
                            ...advancedSetting
                          })
                        )
                      )
                    )
                  ),
                  of(param).pipe(
                    filter(({ endingBalance }) => endingBalance >= 0),
                    filter(({ projectStatus }) =>
                      ContinueAbleProjectStatus.includes(projectStatus)
                    ),
                    mergeMap(({ projectId, minutes, advancedSetting }) =>
                      action$.pipe(
                        filter(isActionOf(apiActions.projects.moreResponse)),
                        take(1),
                        tap(() => {
                          MixPanelUtils.track<'PROJECT__CONTINUE_TRAINING'>(
                            'Project - Continue Training',
                            param.mixPanelParam
                          )
                        }),
                        mergeMap(() => [
                          dialogActions.closeAllDialog(),
                          apiActions.payment.retrieveCreditOverview()
                        ]),
                        startWith(
                          apiActions.projects.more({
                            id: projectId,
                            minutes,
                            ...advancedSetting
                          })
                        )
                      )
                    )
                  )
                )
              ),
              startWith(
                apiActions.projects.update({
                  id: param.projectId,
                  ui_extras: { ...param.ui_extras, is_finish_checked: false }
                })
              )
            )
          )
        ),
        of(param).pipe(
          filter(({ endingBalance }) => endingBalance < 0),
          map(({ endingBalance }) =>
            dialogActions.addDialog({
              [MonetizationDialog.ADD_CREDIT]: {
                dialogName: MonetizationDialog.ADD_CREDIT,
                source: 'train',
                requiredCredit: Math.abs(endingBalance)
              }
            })
          )
        )
      )
    )
  )

export const epics = combineEpics(
  setInitialFormDataEpic,
  startTrainingEpic,
  startTrainingWithoutDialogEpic,
  getInitialTrainingSetupDataEpic
)
export default reducer
