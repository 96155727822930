import { ActionType, createReducer, getType, isActionOf, createAction } from 'typesafe-actions'
import _compact from 'lodash/compact'
import { dialogActions } from './DialogDuck/actions'
import { RootActionType, RootState } from 'duck'
import { Epic, combineEpics } from 'redux-observable'
import {
  withLatestFrom,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  delay,
  concatMap,
  tap
} from 'rxjs/operators'
import { firebaseActions } from 'duck/FirebaseDuck'
import { apiActions, apiSelectors, sharedActions } from 'duck/ApiDuck'
import { appSelectors } from '.'
import { merge, of } from 'rxjs'
import { LocalStorage } from 'utils'
import { LSKey } from 'appConstants'
import MixPanelUtils from 'utils/MixPanelUtils'
import { route } from 'routes'
import { AppEvents, eventEmiterActions } from './EventEmitterDuck'
import FacebookPixelUtils from 'utils/FacebookPixelUtils'

const TIMEOUT = 15

// Actions
export const phoneActions = {
  checkUpdatePhone: createAction('@@page/App/phone/CHECK_UPDATE_PHONE')(),
  updatePhoneNumber: createAction('@@page/App/phone/UPDATE_PHONE_NUMBER')<string>(),
  setStep: createAction('@@page/App/phone/SET_STEP')<PhoneState['step']>(),
  loadUnverifiedPhone: createAction('@@page/App/phone/LOAD_UNVERIFIED_PHONE')(),
  setUnverifiedPhone: createAction('@@page/App/phone/SET_UNVERIFIED_PHONE')<string>(),
  updateVerificationCode: createAction('@@page/App/phone/UPDATE_VERIFICATION_CODE')<string>(),
  submitPhone: createAction('@@page/App/phone/SUBMIT_PHONE')(),
  setResendTimeout: createAction('@@page/App/phone/SET_RESEND_TIMOUT')<number>(),
  setError: createAction('@@page/App/phone/SET_ERROR')<string>(),
  removePhone: createAction('@@page/App/phone/REMOVE_PHONE')<'dialog' | 'page'>(),
  resetVerification: createAction('@@page/App/phone/RESET_VERIFICATION')(),
  submitVerificationCode: createAction('@@page/App/phone/SUBMIT_VERIFICATION_CODE')()
}

export type PhoneActions = ActionType<typeof phoneActions>

// Selectors
const selectPhone = (state: RootState) => state.container.appPage.phone

export const phoneSelectors = {
  phone: selectPhone
}

// Reducer
export type PhoneState = {
  phoneNumber: string
  step: 'first_add' | 'verify_code' | 'verified' | 'saved'
  verificationCode: string
  resendTimeout: number
  error: string
  unverifiedPhone: string
}

const initialState: PhoneState = {
  phoneNumber: '',
  step: 'first_add',
  verificationCode: '',
  error: '',
  resendTimeout: 0,
  unverifiedPhone: ''
}

const reducer = createReducer<PhoneState, PhoneActions>(initialState)
  .handleAction(phoneActions.setStep, (state, { payload }) => ({
    ...state,
    step: payload,
    error: ''
  }))
  .handleAction(phoneActions.updatePhoneNumber, (state, { payload }) => ({
    ...state,
    phoneNumber: payload,
    error: ''
  }))
  .handleAction(phoneActions.updateVerificationCode, (state, { payload }) => ({
    ...state,
    verificationCode: payload,
    error: ''
  }))
  .handleAction(phoneActions.resetVerification, state => ({
    ...state,
    phoneNumber: initialState.phoneNumber,
    verificationCode: initialState.verificationCode,
    error: initialState.error,
    resendTimeout: initialState.resendTimeout,
    unverifiedPhone: initialState.unverifiedPhone
  }))
  .handleAction(phoneActions.setError, (state, { payload }) => ({
    ...state,
    error: payload
  }))
  .handleAction(phoneActions.setResendTimeout, (state, { payload }) => ({
    ...state,
    resendTimeout: payload
  }))
  .handleAction(phoneActions.setUnverifiedPhone, (state, { payload }) => ({
    ...state,
    unverifiedPhone: payload
  }))
// Epic

const submitPhoneEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(phoneActions.submitPhone)),
    withLatestFrom(state$),
    map(([, state]) => ({
      phoneNumber: `+${phoneSelectors.phone(state).phoneNumber}`,
      location: appSelectors.routerLocation(state)
    })),
    //First Step : call backend to make sure phone not registered yet
    mergeMap(({ phoneNumber, location }) =>
      action$.pipe(
        filter(isActionOf(apiActions.users.checkPhoneUsedResponse)),
        take(1),
        mergeMap(({ payload }) =>
          merge(
            of(payload).pipe(
              filter(isPhoneUsed => isPhoneUsed),
              map(() =>
                phoneActions.setError(
                  `This phone number has been used for another account, do you want to try verifying another number?`
                )
              )
            ),
            of(payload).pipe(
              filter(isPhoneUsed => !isPhoneUsed),
              mergeMap(() =>
                action$.pipe(
                  filter(isActionOf(firebaseActions.setPhoneVerificationId)),
                  take(1),
                  tap(() => {
                    const isInWelcomePage = (location?.pathname ?? '').includes(
                      route.WELCOME.getUrl()
                    )
                    MixPanelUtils.track<'USER__SUBMIT_PHONE'>('User - Submit Phone', {
                      submit_location: isInWelcomePage ? 'welcome_page' : 'profile_page',
                      phone_number: phoneNumber
                    })
                    LocalStorage.save(LSKey.UNVERIFIED_PHONE_DATA, phoneNumber)
                  }),
                  mergeMap(() => [
                    phoneActions.setStep('verify_code'),
                    phoneActions.setResendTimeout(TIMEOUT)
                  ]),
                  startWith(firebaseActions.updatePhoneNumber({ phoneNumber }))
                )
              )
            )
          )
        ),
        startWith(apiActions.users.checkPhoneUsed({ phone: phoneNumber }))
      )
    )
  )

const submitVerificationCodeEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(phoneActions.submitVerificationCode)),
    withLatestFrom(state$),
    map(([, state]) => ({
      verificationCode: phoneSelectors.phone(state).verificationCode,
      has_redeem_free_credit: apiSelectors.user(state)?.ui_extras?.has_redeem_free_credit
    })),
    mergeMap(({ verificationCode, has_redeem_free_credit }) =>
      action$.pipe(
        filter(isActionOf(firebaseActions.verifyPhoneResponse)),
        take(1),
        tap(() => {
          LocalStorage.remove(LSKey.UNVERIFIED_PHONE_DATA)
        }),
        map(({ payload }) => ({ ...payload })),
        mergeMap(({ phoneVerificationId, firebaseUser }) =>
          action$.pipe(
            filter(isActionOf(apiActions.users.updatePhoneResponse)),
            take(1),
            tap(({ payload }) => {
              MixPanelUtils.track<'USER__PHONE_VERIFIED'>('User - Phone Verified', {
                has_redeem_free_credit,
                phone_number: firebaseUser?.phoneNumber ?? ''
              })
              Boolean(payload.send_credits) &&
                FacebookPixelUtils.track<'CLAIM_FREE_CREDIT'>('claim_free_credit')
            }),
            mergeMap(({ payload }) =>
              _compact([
                phoneActions.setStep('verified'),
                phoneActions.resetVerification(),
                apiActions.users.updateUiExtras({ has_redeem_free_credit: true }),
                Boolean(payload.send_credits) &&
                  eventEmiterActions.emit({
                    [AppEvents.GET_CREDIT_AFTER_PHONE_VERIFIED]: {
                      event: AppEvents.GET_CREDIT_AFTER_PHONE_VERIFIED
                    }
                  }),
                Boolean(payload.send_credits) && apiActions.payment.retrieveCreditOverview()
              ])
            ),
            startWith(apiActions.users.updatePhone())
          )
        ),
        startWith(firebaseActions.verifyPhone(verificationCode))
      )
    )
  )

const removePhoneEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(phoneActions.removePhone)),
    mergeMap(({ payload }) =>
      action$.pipe(
        filter(isActionOf(firebaseActions.setFirebaseUser)),
        take(1),
        mergeMap(param =>
          action$.pipe(
            filter(isActionOf(apiActions.users.updatePhoneResponse)),
            take(1),
            tap(({ payload }) => {
              MixPanelUtils.track<'USER__UNLINK_PHONE'>('User - Unlink Phone')
              MixPanelUtils.updatePeople({ $phone: '' })
            }),
            concatMap(() => [
              dialogActions.closeDialog(),
              firebaseActions.reloadUser(),
              //Call twice to update UI
              firebaseActions.setPhoneVerificationId('anytext'),
              firebaseActions.setPhoneVerificationId('')
            ]),
            startWith(apiActions.users.updatePhone())
          )
        ),
        startWith(firebaseActions.removePhone())
      )
    )
  )

const resetVerificationEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(phoneActions.resetVerification)),
    tap(() => {
      LocalStorage.remove(LSKey.UNVERIFIED_PHONE_DATA)
    }),
    mergeMap(() => [
      sharedActions.resetError(getType(firebaseActions.verifyPhone)),
      sharedActions.resetError(getType(firebaseActions.updatePhoneNumber)),
      firebaseActions.setPhoneVerificationId('')
    ])
  )
/* 
  make sure phone number between firebase and playform server is in sync, 
  called after each authorization 
*/
const checkUpdatePhoneEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(phoneActions.checkUpdatePhone)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      user: appSelectors.users(state).user,
      firebaseUser: appSelectors.firebase(state).firebaseUser
    })),
    //If both empty, don't need to synchronize
    filter(({ firebaseUser, user }) => Boolean(firebaseUser?.phoneNumber) || Boolean(user?.phone)),
    filter(({ user, firebaseUser }) => user?.phone !== firebaseUser?.phoneNumber),
    map(() => apiActions.users.updatePhone())
  )

const loadUnverifiedPhoneEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(phoneActions.loadUnverifiedPhone)),
    map(() => {
      return LocalStorage.get(LSKey.UNVERIFIED_PHONE_DATA)
    }),
    mergeMap(unverifiedPhone => [
      phoneActions.updatePhoneNumber(unverifiedPhone),
      phoneActions.setUnverifiedPhone(unverifiedPhone || '')
    ])
  )

const setResendTimeoutEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(phoneActions.setResendTimeout)),
    withLatestFrom(state$),
    map(([, state]) => phoneSelectors.phone(state).resendTimeout),
    filter(resendTimeout => resendTimeout > 0),
    delay(1000),
    map(resendTimeout => phoneActions.setResendTimeout(resendTimeout - 1))
  )

//Reset loading indicator, to anticipate captcha appear and use close it.
const setStepEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(phoneActions.setStep)),
    map(action => action.payload),
    mergeMap(step =>
      _compact([
        step === 'first_add' &&
          sharedActions.setLoading({
            loading: false,
            type: getType(firebaseActions.updatePhoneNumber)
          }),
        step === 'verify_code' &&
          sharedActions.setLoading({ loading: false, type: getType(firebaseActions.verifyPhone) })
      ])
    )
  )

export const phoneEpics = combineEpics(
  submitPhoneEpic,
  checkUpdatePhoneEpic,
  loadUnverifiedPhoneEpic,
  setStepEpic,
  resetVerificationEpic,
  setResendTimeoutEpic,
  submitVerificationCodeEpic,
  removePhoneEpic
)

export default reducer
