import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import { filter, map, withLatestFrom, ignoreElements } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { ActionType, getType, isActionOf, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'

// Constants
const NAMESPACE = '@@page/TextToVideoPage'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export type Scene = { prompt: string; duration: number }
export type FormData = {
  scenes: Scene[]
  prompt: string
}

// Actions
export const textToVideoActions = {
  initTextToVideo: createAction(creator('INIT_TEXT_TO_VIDEO'))<{
    id: number
  }>(),
  scene: {
    update: createAction(creator('UPDATE_SCENE'))<{ index: number; scene: Partial<Scene> }>(),
    add: createAction(creator('UPDATE_SCENE'))(),
    delete: createAction(creator('UPDATE_SCENE'))<{ index: number }>()
  },
  generate: createAction(creator('GENERATE'))(),
  setOpenResultView: createAction(creator('OPEN_RESULT_VIEW'))<boolean>()
}

// Selectors
const selectTextToVideo = (state: RootState) => state.container.textToVideoPage
const id = createSelector(selectTextToVideo, textToVideo => textToVideo.id)
const formData = createSelector(selectTextToVideo, textToVideo => textToVideo.formData)

const currentFormData = createSelector(id, formData, (id, formData) =>
  id ? formData[id] : undefined
)

export const textToVideoSelectors = {
  currentFormData,
  selectScene: createSelector(
    currentFormData,
    (_: RootState, index: number) => index,
    (formData, index) => formData?.scenes[index]
  ),
  currentPrompt: createSelector(currentFormData, formData => formData?.prompt),
  openResultView: createSelector(selectTextToVideo, data => data.openResultView),
  currentSceneLength: createSelector(currentFormData, formData => formData?.scenes.length ?? 1),

  textToVideo: selectTextToVideo
}
// Reducer
export type TextToVideoState = {
  id?: number
  openResultView?: boolean
  formData: Record<number, FormData>
}

export const INITIAL_SCENES: Scene = {
  duration: 1000,
  prompt: ''
}

export const INITIAL_FORM_DATA: FormData = {
  prompt: '',
  scenes: [INITIAL_SCENES]
}

export const INITIAL: TextToVideoState = {
  id: undefined,
  openResultView: false,
  formData: {}
}

const reducer = produce((state: TextToVideoState, { type, payload }) => {
  switch (type) {
    case getType(textToVideoActions.initTextToVideo): {
      const { id } = payload as ActionType<typeof textToVideoActions.initTextToVideo>['payload']

      state.id = id
      return
    }
    case getType(textToVideoActions.setOpenResultView): {
      const openResultView = payload as ActionType<
        typeof textToVideoActions.setOpenResultView
      >['payload']

      state.openResultView = openResultView
      return
    }
    case getType(textToVideoActions.scene.update): {
      const id = state.id
      const { index, scene } = payload as ActionType<
        typeof textToVideoActions.scene.update
      >['payload']

      if (id) {
        const newScenes = state.formData[id].scenes ?? []

        newScenes[index] = {
          ...newScenes[index],
          ...scene
        }

        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            scenes: newScenes
          }
        }
      }
      return
    }
    case getType(textToVideoActions.scene.add): {
      const id = state.id

      if (id) {
        const currentScenes = state.formData[id].scenes ?? []

        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            scenes: [...currentScenes, INITIAL_SCENES]
          }
        }
      }
      return
    }
    case getType(textToVideoActions.scene.delete): {
      const id = state.id
      const { index } = payload as ActionType<typeof textToVideoActions.scene.delete>['payload']

      if (id) {
        const newScenes = state.formData[id].scenes ?? []

        newScenes.splice(index, 1)

        state.formData = {
          ...state.formData,
          [id]: {
            ...(state.formData[id] ?? INITIAL_FORM_DATA),
            scenes: newScenes
          }
        }
      }
      return
    }
  }
}, INITIAL)

// Epics
const generateEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(textToVideoActions.generate)),
    withLatestFrom(state$),
    map(([_, state]) => textToVideoSelectors.currentFormData(state)),
    ignoreElements()
  )

export const epics = combineEpics(generateEpic)
export default reducer
