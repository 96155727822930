import { apiActions, SketchTextToImageActionType } from '../actions'
import { SharedActionsType } from '../sharedActions'
import {
  SketchTextProject,
  SketchTextOutputImage,
  PaginationResponse,
  SketchTextGenre,
  SketchTextModel,
  SketchTextProcess,
  SketchTextStyles
} from 'models/ApiModels'
import { createReducer, getType } from 'typesafe-actions'
import _map from 'lodash/map'
import _keyBy from 'lodash/keyBy'
import _uniq from 'lodash/uniq'
import _without from 'lodash/without'

export type SketchTextToImageState = {
  currentSketchTextProject?: number
  sketchTextGenres: PaginationResponse<SketchTextGenre> | null
  sketchTextProcess: PaginationResponse<SketchTextProcess> | null
  sketchTextModel: PaginationResponse<SketchTextModel> | null
  sketchTextStyles: PaginationResponse<SketchTextStyles> | null
  sketchTextProjects: {
    list: number[]
    data: {
      [id: number]: SketchTextProject
    }
    lastReq?: PaginationResponse<SketchTextProject>
  }
  outputLists: {
    [sketchTextProject: string]:
      | {
          lastListReq: PaginationResponse<SketchTextOutputImage>
          list: number[]
        }
      | undefined
  }
  outputs: { [sketchTextToImageOutput: number]: SketchTextOutputImage }
}

export const INITIAL_STATE: SketchTextToImageState = {
  currentSketchTextProject: undefined,
  sketchTextProjects: {
    list: [],
    data: {},
    lastReq: undefined
  },
  sketchTextStyles: null,
  sketchTextGenres: null,
  sketchTextProcess: null,
  sketchTextModel: null,
  outputLists: {},
  outputs: {}
}

export const sketchTextToImageReducers = createReducer<
  SketchTextToImageState,
  SketchTextToImageActionType | SharedActionsType
>(INITIAL_STATE)
  .handleAction(
    apiActions.sketchTextToImage.setCurrentSketchTextToImageProject,
    (state, action) => {
      const id = action?.payload
      return {
        ...state,
        currentSketchTextProject: id
      }
    }
  )
  .handleAction(apiActions.sketchTextToImage.listSTIGenreResponse, (state, action) => {
    return {
      ...state,
      sketchTextGenres: action.payload
    }
  })
  .handleAction(apiActions.sketchTextToImage.listSTIModelResponse, (state, action) => {
    return {
      ...state,
      sketchTextModel: action.payload
    }
  })
  .handleAction(apiActions.sketchTextToImage.listSTIStylesResponse, (state, action) => {
    return {
      ...state,
      sketchTextStyles: action.payload
    }
  })
  .handleAction(apiActions.sketchTextToImage.listSTIProcessResponse, (state, action) => {
    return {
      ...state,
      sketchTextProcess: action.payload
    }
  })
  .handleAction(apiActions.sketchTextToImage.listSTIProjectResponse, (state, action) => {
    const { data, next } = action.payload

    const results = data?.results ?? []
    const prevData = next ? state.sketchTextProjects.list || [] : []

    const combinedResults = results.map(result => ({
      ...(state.sketchTextProjects.data[result.id] ?? {}),
      ...result
    }))
    return {
      ...state,
      sketchTextProjects: {
        list: [...prevData, ..._map(results, result => result.id)],
        lastReq: data,
        data: {
          ...state.sketchTextProjects.data,
          ..._keyBy(combinedResults, result => result.id)
        }
      }
    }
  })
  .handleAction(apiActions.sketchTextToImage.listSTIProjectOutputResponse, (state, action) => {
    const { data, next, req } = action.payload

    const projectId = req?.project || 0
    const results = data?.results ?? []
    const prevData = next ? state.outputLists[projectId]?.list || [] : []

    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [projectId]: {
          lastListReq: data,
          list: _uniq([...prevData, ..._map(results, result => result.id)])
        }
      },
      outputs: {
        ...state.outputs,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(
    [
      apiActions.sketchTextToImage.createSTIProjectResponse,
      apiActions.sketchTextToImage.retrieveSTIProjectResponse,
      apiActions.sketchTextToImage.generateSTIProjectResponse,
      apiActions.sketchTextToImage.updateSTIProjectResponse,
      apiActions.sketchTextToImage.uploadSketchResponse,
      apiActions.sketchTextToImage.updateThresholdResponse,
      apiActions.sketchTextToImage.removeSketchResponse
    ],
    (state, action) => {
      const isCreate =
        action.type === getType(apiActions.sketchTextToImage.createSTIProjectResponse)
      const id = action.payload?.id || 0
      const currentData = state.sketchTextProjects.data[id] ?? {}
      const currentList = state.sketchTextProjects.list ?? []

      return {
        ...state,
        sketchTextProjects: {
          ...state.sketchTextProjects,
          data: {
            ...state.sketchTextProjects.data,
            [id]: {
              ...currentData,
              ...action.payload
            }
          },
          list: isCreate ? [id, ...currentList] : currentList
        }
      }
    }
  )
  .handleAction(apiActions.sketchTextToImage.updateThreshold, (state, action) => {
    const id = action.payload?.id || 0
    const currentData = state.sketchTextProjects.data[id] ?? {}

    return {
      ...state,
      sketchTextProjects: {
        ...state.sketchTextProjects,
        data: {
          ...state.sketchTextProjects.data,
          [id]: {
            ...currentData,
            sketch_threshold_high: action.payload.sketch_threshold_high,
            sketch_threshold_low: action.payload.sketch_threshold_low
          }
        }
      }
    }
  })
  .handleAction(apiActions.sketchTextToImage.deleteSTIProjectResponse, (state, action) => {
    const { [action.payload]: deletedProject, ...sketchTextProjects } =
      state.sketchTextProjects.data

    const itProjectList = _without(state.sketchTextProjects.list || [], deletedProject.id)

    return {
      ...state,
      sketchTextProjects: {
        ...state.sketchTextProjects,
        data: sketchTextProjects,
        list: itProjectList
      }
    }
  })
  .handleAction(apiActions.sketchTextToImage.resetSketchTextToImageQueueData, (state, action) => {
    const payload = action.payload
    const currentProject = state.sketchTextProjects.data[payload]
    return currentProject
      ? {
          ...state,
          sketchTextProjects: {
            ...state.sketchTextProjects,
            data: {
              ...state.sketchTextProjects.data,
              [payload]: {
                ...currentProject,
                queue_length: undefined,
                expect_finished: undefined
              }
            }
          }
        }
      : state
  })
  .handleAction(apiActions.sketchTextToImage.deleteSTIProjectOutputResponse, (state, action) => {
    const currentSketchTextProjectId = state.currentSketchTextProject ?? 0
    const currentList = state.outputLists[currentSketchTextProjectId]?.list ?? []
    const newList = _without(currentList || [], action.payload)
    return {
      ...state,
      outputLists: {
        ...state.outputLists,
        [currentSketchTextProjectId]: {
          ...state.outputLists[currentSketchTextProjectId],
          list: newList
        }
      }
    }
  })
  .handleAction(apiActions.sketchTextToImage.cleanOutputsResponse, state => {
    const projectId = state.currentSketchTextProject

    return projectId
      ? {
          ...state,
          outputLists: {
            ...state.outputLists,
            [projectId]: {
              ...state,
              list: []
            }
          }
        }
      : state
  })
