import React, { forwardRef, MouseEvent, Ref } from 'react'
import { useNavigate, To } from 'react-router-dom'
import clsx from 'clsx'
import ButtonBasematerial, {
  ButtonBaseProps as ButtonBaseMaterialProps
} from '@mui/material/ButtonBase'
import styles from './Button.module.scss'

type ButtonBaseProps = ButtonBaseMaterialProps & {
  className?: string
  withoutHover?: boolean
  href?: string
  target?: string
  to?: To
}

type ButtonBaseInnerProps = ButtonBaseProps & {
  setRef?: any
}

const ButtonBase: React.FC<ButtonBaseInnerProps> = ({
  className = '',
  children,
  withoutHover,
  setRef,
  ...restProps
}) => (
  <ButtonBasematerial
    ref={setRef}
    className={clsx(!withoutHover && styles.ButtonBase, className)}
    {...restProps}
  >
    {children}
  </ButtonBasematerial>
)

const ButtonBaseLink: React.FC<ButtonBaseInnerProps> = props => {
  const { className, onClick, setRef, to, ...restProps } = props
  const navigate = useNavigate()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    if (to) navigate(to)
  }

  return (
    <ButtonBase
      {...restProps}
      LinkComponent="button"
      setRef={setRef}
      className={clsx(styles.ButtonBase, className)}
      onClick={handleClick}
    />
  )
}

const ButtonBaseContainer = (props: ButtonBaseProps, ref: Ref<HTMLButtonElement>) => {
  if (props.to) return <ButtonBaseLink setRef={ref} {...props} />

  return <ButtonBase setRef={ref} {...props} />
}

export default forwardRef(ButtonBaseContainer)
