import { Vector2d } from 'konva/lib/types'
import _reduce from 'lodash/reduce'

export type ScaleImageParam = {
  width: number
  height: number
  containerWidth: number
}
export const NumUtils = {
  clamp: (val: number, min: number, max: number) => {
    return val > max ? max : val < min ? min : val
  },
  sumValues: (input: { [key: string]: number }) =>
    _reduce(
      input,
      (result, value) => {
        result += value
        return result
      },
      0
    ),
  randomBetween: (min: number, max: number, dec?: number) => {
    let decFactor = 1
    if (dec) {
      for (let i = 0; i < dec; i += 1) {
        decFactor *= 10
      }
    }
    return (
      Math.round(Math.random() * (max * decFactor - min * decFactor) + min * decFactor) / decFactor
    )
  },
  scaleImage: (param: ScaleImageParam) => {
    const { width, height, containerWidth } = param
    return ((height || 1) / (width || 1)) * containerWidth
  }
}

export const pickSmallest = (...param: number[]) => Math.min(...param)
export const pickLargest = (...param: number[]) => Math.max(...param)

export type HourFormat = {
  hour: number
  minute: number
}
export const minuteToHour = (minutes: number): HourFormat => ({
  hour: minutes > 0 ? Math.floor(minutes / 60) : Math.ceil(minutes / 60),
  minute: minutes % 60
})

export const hourToMinute = (props: HourFormat) => props.hour * 60 + props.minute

export const minuteToHourText = (
  minutes: number,
  mode: 'text' | 'number' = 'text',
  textMode: 'long' | 'short' = 'short'
) => {
  if (isNaN(minutes)) {
    return mode === 'number' ? '00:00' : ''
  }

  const hourPrefix = textMode === 'short' ? 'hr' : 'hour'
  const hoursPrefix = textMode === 'short' ? 'hrs' : 'hours'
  const minutePrefix = textMode === 'short' ? 'min' : 'minute'
  const minutesPrefix = textMode === 'short' ? 'mins' : 'minutes'
  const hourMinuteSpaceText = textMode === 'short' ? ' ' : ' and '

  const hourFormat = minuteToHour(minutes)
  const minusIndicator = minutes < 0 ? '-' : ''
  const hour = Math.abs(hourFormat.hour)
  const minute = Math.abs(hourFormat.minute)
  const hourText = hour > 1 ? hoursPrefix : hourPrefix
  const minuteText = minute > 1 ? minutesPrefix : minutePrefix
  const hourHourtext = hour > 0 ? `${hour} ${hourText}` : ''
  const minuteMinuteText = minute > 0 ? `${minute} ${minuteText}` : ''
  const hourMinuteSpace = minuteMinuteText && hourHourtext ? hourMinuteSpaceText : ''

  if (mode === 'text') {
    return `${minusIndicator}${hourHourtext}${hourMinuteSpace}${minuteMinuteText}${
      !hourHourtext && !minuteMinuteText ? `0 ${hourPrefix}` : ''
    }`
  } else {
    let minuteFormatted = minute === 0 ? '00' : minute < 10 ? `0${minute}` : minute
    return `${hour}:${minuteFormatted}`
  }
}

export type Box = {
  x: number
  y: number
  width: number
  height: number
}

export const VectorUtils = {
  isIntersect: (r1: Box, r2: Box) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    )
  },
  getDistance: (p1: Vector2d, p2: Vector2d) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
  }
}
