import { FormDialog } from 'duck/AppDuck/DialogDuck'
import loadable from 'utils/Loadable'
import { useDialogDetector } from 'components/Dialog/Hooks'

const ImageCropperDialog = loadable(
  () => import(/* webpackChunkName: "ImageCropperDialog" */ './index')
)

const ImageCropperDialogContainer = () => {
  const open = useDialogDetector(FormDialog.IMAGE_CROPPER)

  if (!open) return null

  return <ImageCropperDialog />
}

export default ImageCropperDialogContainer
