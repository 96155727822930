import { ActionType, createAction } from 'typesafe-actions'
import { FileReceivedParam } from 'containers/CollectionEditorPanel/duck'
import { ShareData } from 'containers/ShareFeedPanel/duck'
import { ProjectObjectType, UserImage } from 'models/ApiModels'

export const AppEvents = {
  ADD_CREDIT_SUCCESS: 'add_credit_success',
  COLLECTION_DELETED: 'collection_deleted',
  ON_FILE_RECEIVED: 'on_file_received',
  COLLECTION_ADDED: 'collection_added',
  INIT_SHARE: 'init_share',
  POST_SHARED: 'post_shared',
  GET_CREDIT_AFTER_PHONE_VERIFIED: 'get_credit_after_phone_verified',
  OPEN_UPSCALE_IMAGE: 'upscaleImage',
  OPEN_IMAGE_ENHANCEMENT: 'imageEnhancement',
  ON_CLICK_REFINE: 'on_click_refine',
  ON_CLICK_ADD_MIX_STYLE: 'on_click_add_mix_style',
  ON_RECEIVE_MIX_STYLE: 'on_receive_mix_style'
} as const

type AppEventsList = keyof typeof AppEvents
export type AppEventsType = (typeof AppEvents)[AppEventsList]

export type AppEventData<Name extends AppEventsType, PayloadType = undefined> = {
  event: Name
  payload?: PayloadType
}

export type AppEventPayloadMap = {
  [AppEvents.POST_SHARED]: AppEventData<'post_shared'>
  [AppEvents.ADD_CREDIT_SUCCESS]: AppEventData<'add_credit_success'>
  [AppEvents.COLLECTION_DELETED]: AppEventData<'collection_deleted'>
  [AppEvents.ON_FILE_RECEIVED]: AppEventData<'on_file_received', FileReceivedParam>
  [AppEvents.COLLECTION_ADDED]: AppEventData<'collection_added'>
  [AppEvents.INIT_SHARE]: AppEventData<
    'init_share',
    Pick<ShareData, 'entity' | 'related'> & { enableBackButton?: boolean }
  >
  [AppEvents.GET_CREDIT_AFTER_PHONE_VERIFIED]: AppEventData<'get_credit_after_phone_verified'>
  [AppEvents.OPEN_UPSCALE_IMAGE]: AppEventData<
    'upscaleImage',
    {
      userImageId: number
    }
  >
  [AppEvents.OPEN_IMAGE_ENHANCEMENT]: AppEventData<
    'imageEnhancement',
    {
      userImageId: number
      source: ProjectObjectType
    }
  >
  [AppEvents.ON_CLICK_REFINE]: AppEventData<
    'on_click_refine',
    {
      imageId: number
      projectId: number
    }
  >
  [AppEvents.ON_CLICK_ADD_MIX_STYLE]: AppEventData<'on_click_add_mix_style'>
  [AppEvents.ON_RECEIVE_MIX_STYLE]: AppEventData<'on_receive_mix_style', UserImage>
}

// Actions
export const eventEmiterActions = {
  emit: createAction('@@page/App/AppEventEmitter/EMIT')<Partial<AppEventPayloadMap>>()
}

export type EventEmitterActions = ActionType<typeof eventEmiterActions>
