import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const SlackIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon
      className={clsx(className)}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.362 11.11c0 .926-.756 1.681-1.681 1.681S1 12.036 1 11.111c0-.926.756-1.681 1.68-1.681h1.682v1.68ZM5.208 11.11c0-.925.756-1.68 1.68-1.68.926 0 1.682.755 1.682 1.68v4.209c0 .925-.756 1.68-1.681 1.68s-1.681-.755-1.681-1.68V11.11ZM6.889 4.362c-.925 0-1.681-.756-1.681-1.681S5.964 1 6.888 1c.926 0 1.682.756 1.682 1.68v1.682H6.889ZM6.89 5.208c.924 0 1.68.756 1.68 1.681S7.814 8.57 6.89 8.57H2.68C1.757 8.57 1 7.814 1 6.89c0-.926.756-1.681 1.68-1.681h4.21ZM13.639 6.89c0-.926.755-1.681 1.68-1.681.925 0 1.681.755 1.681 1.68 0 .925-.756 1.681-1.68 1.681h-1.681V6.89ZM12.791 6.89c0 .924-.755 1.68-1.68 1.68-.926 0-1.681-.756-1.681-1.68V2.68c0-.924.755-1.68 1.68-1.68.926 0 1.681.756 1.681 1.68v4.21ZM11.11 13.639c.926 0 1.681.755 1.681 1.68 0 .925-.755 1.681-1.68 1.681-.926 0-1.681-.756-1.681-1.68v-1.681h1.68ZM11.11 12.792c-.925 0-1.68-.756-1.68-1.681s.755-1.68 1.68-1.68h4.209c.925 0 1.68.755 1.68 1.68 0 .925-.755 1.68-1.68 1.68H11.11Z" />
    </SvgIcon>
  )
}

export default SlackIcon
