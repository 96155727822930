import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { BraintreePayPalButtonsComponentProps } from '@paypal/react-paypal-js'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import Button from 'components/Button'
import { CircularLoading } from 'components/Loading'
import { appSelectors } from 'duck/AppDuck'
import { RootActionType, RootState } from 'duck'
import { dialogActions, MonetizationDialog } from 'duck/AppDuck/DialogDuck'
import { apiSelectors } from 'duck/ApiDuck/selectors'
import { brainTreePaymentActions, brainTreePaymentSelector } from '../duck'
import MonetizationDialogTemplate from '../MonetizationDialogTemplate'
import { ReduxProps } from 'utils/Types'
import { TextTransform } from 'utils/TextUtils'
import { BraintreePaymentMethod } from 'models/ApiModels'
import PaypalButton from '../components/PaypalButton'
import styles from './DialogContinueSubscription.module.scss'
import ChangePaymentMethod from '../components/ChangePaymentMethod'

const STYLE_PAYPALBUTTON: BraintreePayPalButtonsComponentProps['style'] = { label: 'paypal' }

const mapStateToProps = (state: RootState) => ({
  braintreeCreatePaymentMethodLoading:
    apiSelectors.loading['payment.braintreeCreatePaymentMethod'](state),
  hasBraintreePaymentMethod: apiSelectors.hasBraintreePaymentMethod(state),
  brainTreePayment: brainTreePaymentSelector.brainTreePayment(state),
  equity: apiSelectors.equity(state),
  activeDialog: appSelectors.dialog.activeDialog(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      closeDialog: dialogActions.closeDialog,
      submitPaypalPaymentMethod: brainTreePaymentActions.submitPaypalPaymentMethod,
      submitCardPaymentMethod: brainTreePaymentActions.submitCardPaymentMethod,
      resetData: brainTreePaymentActions.resetData
    },
    dispatch
  )
type DialogContinueSubscriptionProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const DialogContinueSubscription: React.FC<DialogContinueSubscriptionProps> = ({
  closeDialog,
  resetData,
  submitCardPaymentMethod,
  submitPaypalPaymentMethod,
  braintreeCreatePaymentMethodLoading,
  hasBraintreePaymentMethod,
  brainTreePayment,
  activeDialog,
  equity
}) => {
  const showDialog = activeDialog === MonetizationDialog.CONTINUE_SUBSCRIPTION
  const { hasEmpty, cardError, cardLoading, isChangePaymentMethod } = brainTreePayment
  const showLoading = cardLoading || braintreeCreatePaymentMethodLoading

  const [selectedMethod, setSelectedMethod] = useState<BraintreePaymentMethod['type']>('creditcard')

  const disableSave =
    Boolean(hasEmpty || cardError || (hasBraintreePaymentMethod && !isChangePaymentMethod)) ||
    cardLoading ||
    braintreeCreatePaymentMethodLoading

  const title = 'Add Payment Method'
  const type = TextTransform.textCase(equity?.type, 'titlecase')
  return (
    <MonetizationDialogTemplate
      open={showDialog}
      contentDividers={true}
      disableActionSpacing={true}
      scroll={'body'}
      onClose={() => {
        closeDialog()
        resetData()
      }}
      titleContent={<Typography variant="h6">{title}</Typography>}
      actionContent={
        <div className={styles.DialogSaveCardActions}>
          {selectedMethod === 'creditcard' ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => submitCardPaymentMethod(undefined)}
              disabled={Boolean(disableSave)}
              fullWidth
            >
              {'RESUME SUBSCRIPTION'}
            </Button>
          ) : null}

          {selectedMethod === 'paypal' ? (
            <PaypalButton
              style={STYLE_PAYPALBUTTON}
              onNonceAvailable={nonce => submitPaypalPaymentMethod({ nonce })}
            />
          ) : null}
        </div>
      }
    >
      <div
        className={clsx(
          'select-none text-center',
          styles.DialogSaveCardContent,
          showLoading && 'hidden'
        )}
      >
        <Typography variant="body2" className="mb-6">
          {`Currently, you subscribed to Playform ${type} subscription. To continue the subscription, you're required to add a payment method.`}
        </Typography>
        <ChangePaymentMethod
          setSelectedMethod={setSelectedMethod}
          selectedMethod={selectedMethod}
        />
      </div>

      {showLoading && (
        <div className={styles.DialogSaveCardLoadingWrapper}>
          <CircularLoading loading disableShrink />

          {typeof cardLoading === 'string' && (
            <Typography className="mt-6" variant="h6" component="div">
              {cardLoading}
            </Typography>
          )}
        </div>
      )}
    </MonetizationDialogTemplate>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogContinueSubscription)
