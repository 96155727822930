import mixpanel from 'mixpanel-browser'
import { apiSelectors } from 'duck/ApiDuck'
import _reduce from 'lodash/reduce'
import {
  AdjustedImage,
  Category,
  Channel,
  ClipProjectType,
  Collection,
  Equity,
  ImageSetType,
  MixImageProject,
  Post,
  ProcessType,
  ProjectObjectType,
  ProjectSummary,
  SketchProject,
  ProArtFilterProject,
  Transition,
  UpscaleImageCreateReq,
  User,
  TrainProject,
  MineProject,
  TIProject,
  TVProject,
  SketchTextProject
} from 'models/ApiModels'
import { UpsellLocation, UpsellBannerState } from 'duck/AppDuck/BannerDuck'
import { CollectionSourceType } from 'containers/CollectionEditorPanel/duck'
import { ListIdType, SketchSourceType } from 'containers/SketchToImagePanel/duck'
import { ListIdType as StyleListIdType } from 'containers/ProArtFilterPage/duck'
import { SignInProvider } from 'duck/FirebaseDuck'
import dayjs from 'dayjs'
import { UpscalePanelState } from 'containers/UpscalePanel/duck'
import { NFTProjectType, STEP_NAMES } from 'containers/NFTSellPanel/duck'

/* 
  Event that need to be updated 
  PROJECT__ADD_COLLECTION_TO_PROJECT: {
*/

export const ProjectProperties = {
  PROJECT_ID: 'project_id',
  IMAGE_ID: 'image_id',
  IMAGE_SET: 'image_set',
  PROJECT_NAME: 'project_name',
  PROJECT_PROCESS_GROUP: 'project_process_group',
  PROJECT_PROCESS_TYPE: 'project_process_type',
  PROJECT_SECTION: 'project_section',

  NUMBER_OF_SNAPSHOTS: 'number_of_snapshots',
  NUMBER_OF_RESULTS: 'number_of_results',
  INCLUDED_IMAGES_COUNT: 'included_images_count',

  PREV_NUMBER_OF_SNAPSHOTS: 'prev_number_of_snapshots',
  PREV_NUMBER_OF_RESULTS: 'prev_number_of_results',
  RESOLUTION: 'resolution',

  //Download
  DOWNLOAD_FILE_TYPE: 'download_file_type',
  DOWNLOAD_LOCATION: 'download_location',

  //Bookmark
  BOOKMARK_ACTION_TYPE: 'bookmark_action_type',
  BOOKMARK_LOCATION: 'bookmark_location',
  BOOKMARK_SCOPE: 'bookmark_scope',
  BOOKMARK_OBJECT_TYPE: 'bookmark_object_type'
} as const

export const MixProjectProperties = {
  ANCHOR_COUNT: 'anchor_count',
  IS_RESAMPLE: 'is_resample',
  IS_ILUSTRATIVE: 'is_illustrative',
  EXTRA_ATTRIBUTE_LABELS: 'extra_attribute_labels',
  EXTRA_ATTRIBUTE_MODE: 'extra_attribute_mode',
  ANCHOR_CHANGE_MODE: 'anchor_change_mode',
  ANCHOR_CHANGE_SOURCE: 'anchor_change_source',
  DO_ACTION_TYPE: 'do_action_type',
  REFRESHED_UPLOAD_COUNT: 'refreshed_upload_count'
} as const

export const SketchProjectProperties = {
  SKETCH_STYLE: 'sketch_style',
  STYLE_SOURCE: 'style_source',
  SKETCH_SOURCE: 'sketch_source',
  DO_ACTION_TYPE: 'do_action_type',
  DO_ACTION_VALUE: 'do_action_value'
} as const

export const TextToImageProperties = {
  TEXT_TO_IMAGE_INPUT: 'text_to_image_input',
  TEXT_TO_IMAGE_RESOLUTION: 'text_to_image_resolution',
  TEXT_TO_IMAGE_HAS_IMAGE_INPUT: 'text_to_image_has_image_input',
  TEXT_TO_IMAGE_MODEL: 'text_to_image_model',
  TEXT_TO_IMAGE_PROCESS: 'text_to_image_process'
} as const

export const ProArtFilterProjectProperties = {
  PROARTFILTER_STYLE: 'style_transfer_style',
  PROARTFILTER_STYLE_VALUE: 'style_transfer_style_value',
  PROARTFILTER_CONTENT_EFFECT: 'style_transfer_content_effect',
  PROARTFILTER_ENABLE_MASK: 'style_transfer_enable_mask',
  PROARTFILTER_SOURCE: 'style_transfer_source',
  DO_ACTION_TYPE: 'do_action_type',
  DO_ACTION_VALUE: 'do_action_value'
} as const

export const CollectionProperties = {
  COLLECTION_ID: 'collection_id',
  COLLECTION_NAME: 'collection_name',
  COLLECTION_CATEGORY: 'collection_category',
  COLLECTION_OWNER_ID: 'collection_owner_id',
  COLLECTION_IS_PRIVATE: 'collection_is_private',
  IMAGE_COUNT: 'image_count',
  IMAGE_SET: 'image_set',
  ADD_COLLETION_SOURCE: 'add_collection_source',
  UPLOAD_SUBMIT_COUNT: 'upload_submit_count',
  UPLOADED_COUNT: 'uploaded_count',
  IS_CANCEL: 'is_cancel',
  DELETED_COUNT: 'deleted_count',
  UPDATE_TAG_TYPE: 'update_tag_type',
  UPDATE_TAG_VALUE: 'update_tag_value'
} as const

export const TransactionProperties = {
  PRICE: 'price',
  PRICE_TOTAL: 'price_total',
  QUANTITY: 'quantity',
  CURRENT_BALANCE: 'current_balance',
  PREVIOUS_BALANCE: 'previous_balance'
} as const

export const VideoProperties = {
  VIDEO_DURATION: 'video_duration',
  KEYFRAME_COUNT: 'keyframe_count',
  FRAME_COUNT: 'frame_count',
  TRANSITION_TYPE: 'transition_type',
  ADD_KEYFRAME_SOURCE: 'add_keyframe_source',
  CLIP_ID: 'clip_id',
  HAS_PREVIEW: 'has_preview',
  CLIP_TYPE: 'clip_type',
  UPDATE_CLIP_TYPE: 'update_clip_type',
  UPDATE_KEYFRAME_TYPE: 'update_keyframe_type'
} as const

export const UpscaleProperties = {
  IMAGE_ID: 'image_id',
  UPSCALE_SCALE: 'upscale_scale',
  UPSCALE_TEXTURE: 'upscale_texture',
  UPSCALE_DEBLOCKING: 'upscale_deblocking',
  UPSCALE_DENOISE: 'upscale_denoise',
  UPSCALE_MODE: 'upscale_mode',
  UPSCALE_IMAGE_SOURCE: 'upscale_image_source'
} as const

export const SocialProperties = {
  POST_ID: 'post_id',
  POST_OWNER_ID: 'post_owner_id',
  CLAP_COUNT: 'clap_count',
  COMMENT_TYPE: 'comment_type',
  COMMENT_LENGTH: 'comment_length',
  REPLY_TO: 'reply_to',
  THREAD_ID: 'thread_id',
  CLAP_TYPE: 'clap_type'
} as const

export const ReferralProperties = {
  VISITOR_STATUS: 'visitor_status',
  SHARE_MESSAGE: 'share_message',
  WIDGET_NAME: 'widget_name'
} as const

export const NftProperties = {
  NETWORK: 'network',
  LICENSE: 'license',
  END_PRICE: 'end_price',
  START_PRICE: 'start_price',
  SELL_AS_NFT_SOURCE: 'sell_ass_nft_source',
  IMAGE_COUNT: 'image_count',
  FORM_STEP: 'form_step'
} as const

export const Properties = {
  SUBMIT_LOCATION: 'submit_location',
  HAS_REDEEM_FREE_CREDIT: 'has_redeem_free_credit',
  PHONE_NUMBER: 'phone_number',
  $EMAIL: '$email',
  PROFILE_ALIAS: 'profile_alias',
  PROFILE_EMAIL: 'profile_email',
  PROFILE_NAME: 'profile_name',
  SIGN_IN_PROVIDER: 'sign_in_provider',
  UPDATED_FORMS: 'updated_forms',
  PAGE_PARAM: 'page_param',
  CONTENT: 'content',
  CREATED: 'created',
  VALUE: 'value',
  DIALOG_NAME: 'dialog_name',
  DIALOG_CONTENT: 'dialog_content',
  CURRENT_SUBSCRIPTION: 'current_subscription',
  PREV_SUBSCRIPTION: 'prev_subscription',
  SUBSCRIPTION_FORM_TYPE: 'subscription_form_type',
  UPSELL_LOCATION: 'upsell_location',
  UPSELL_CONTENT_MODE: 'upsell_content_mode',
  ...SketchProjectProperties,
  ...CollectionProperties,
  ...ProjectProperties,
  ...TransactionProperties,
  ...MixProjectProperties,
  ...VideoProperties,
  ...UpscaleProperties,
  ...SocialProperties,
  ...ReferralProperties,
  ...ProArtFilterProjectProperties,
  ...NftProperties,
  ...TextToImageProperties
} as const

const P = Properties

export type PropertyTypeList = keyof typeof Properties
export type PropertyType = (typeof Properties)[PropertyTypeList]
export type UpdateClipType = 'duplicate' | 'delete' | 'rename'

export type BookmarkLocation =
  | 'Train Project - Result'
  | 'Train Project - Saved'
  | 'Train Project - Inference'
  | 'Train Project - Image Enhancement Panel'
  | 'Mix Image - Bottomsheet Sidebar'
  | 'Mix Image - Sidebar'
  | 'Mix Image - Sidebar Saved'
  | 'Mix Image - Panel Edit Mobile'
  | 'Mix Image - Image Enhancement Panel'
  | 'Video - Sidebar'
  | 'Home - Saved Results'
  | 'Sketch To Image - Result List'
  | 'ProArtFilter - Result List'
  | 'Text To Image - Result List'
  | 'Text To Video - Result List'
  | 'AI App Project - Result'
  | 'Sketch Text To Image - Result'

export type DownloadLocation =
  | 'Train Project - Download This Snapshot'
  | 'Train Project - Download Selected Images'
  | 'Train Project - Download All Snapshot'
  | 'Train Project - Download Single Result Image'
  | 'Train Project - Download Single Saved Image'
  | 'Train Project - Download All Saved Image'
  | 'Train Project - Download Selected Saved Image'
  | 'Train Project - Download Single Inference'
  | 'Train Project - Download All Inference'
  | 'Train Project - Download In Enhanced Panel'
  | 'Mix Image - Bottomsheet Sidebar Download'
  | 'Mix Image - Sidebar Download'
  | 'Mix Image - Sidebar Saved Download'
  | 'Mix Image - Download In Enhanced Panel'
  | 'Video - Sidebar Download'
  | 'Video - Download Rendered File'
  | 'Sketch Project - Download'
  | 'Text To Image - Download'
  | 'Text To Video - Download'
  | 'ProArtFilter - Download'
  | 'Home - Saved Results'
  | 'Home - My Download'
  | 'Upscale - Upscale Generate'
  | 'Upscale - Upscale Dialog'
  | 'Ai App Project - Download'
  | 'Sketch Text To Image - Download'

export type MixDoActionType =
  | 'init_project_mix'
  | 'create_from_random'
  | 'create_new_mix'
  | 'undo'
  | 'redo'
  | 'extra_attribute_open'
  | 'extra_attribute_undo'
  | 'extra_attribute_redo'
  | 'extra_attribute_revert'
  | 'start_over'
  | 'resample'
  | 'upload_image'
  | 'delete_upload_image'
  | 'refresh_unfinished_upload'
  | 'regenerate'
  | 'refine'
  | ''

export type SketchDoActionType =
  | 'draw_line'
  | 'erase'
  | 'undo'
  | 'clear'
  | 'upload_sketch'
  | 'clear_sketch'
  | 'toggle_symmetry'
  | 'crop_background_sketch'
  | 'toggle_guide'
  | 'reuse_style'
  | 'remove_reused_style'
  | 'delete_result'

export type ProArtFilterDoActionType =
  | 'clear_style_transfer_output'
  | 'delete_result'
  | 'crop_image'
  | 'update_style_value'
  | 'update_composition_effect_value'
  | 'upload_image'
  | 'update_process'
  | 'add_custom_style'

export type TextToImageDoActionType = 'delete_result' | 'clear_all_result' | 'copy_prompt'

type DefaultPropertyType = string | string[] | object | number

export type EventProperties = {
  EMAIL: {
    [P.$EMAIL]: DefaultPropertyType
  }
  PROJECT: {
    [P.PROJECT_NAME]: DefaultPropertyType
    [P.PROJECT_ID]: number
    [P.PROJECT_PROCESS_GROUP]: ProjectObjectType
    [P.PROJECT_PROCESS_TYPE]: ProcessType | string
  }
  TRANSACTION: {
    [P.PRICE]: DefaultPropertyType
    [P.PRICE_TOTAL]: DefaultPropertyType
    [P.QUANTITY]: DefaultPropertyType
    [P.CURRENT_BALANCE]: DefaultPropertyType
    [P.PREVIOUS_BALANCE]: DefaultPropertyType
  }
  SUBSCRIPTION: {
    [P.CURRENT_SUBSCRIPTION]: string
    [P.PREV_SUBSCRIPTION]: string
    [P.SUBSCRIPTION_FORM_TYPE]: string
  }
  UPDATED_FORMS: {
    [P.UPDATED_FORMS]: string[]
  }
  TRAINING_PARAM: EventProperties['PROJECT'] & {
    [P.QUANTITY]: number
    [P.CURRENT_BALANCE]: number
    [P.PREVIOUS_BALANCE]: number
    [P.NUMBER_OF_SNAPSHOTS]: number
    [P.NUMBER_OF_RESULTS]: number
    [P.PREV_NUMBER_OF_SNAPSHOTS]: number
    [P.PREV_NUMBER_OF_RESULTS]: number
    [P.RESOLUTION]: string
  }
  CLIP_DATA: {
    [P.VIDEO_DURATION]: number
    [P.KEYFRAME_COUNT]: number
    [P.FRAME_COUNT]: number
    [P.CLIP_ID]: number
    [P.CLIP_TYPE]: ClipProjectType
    [P.HAS_PREVIEW]?: boolean
  }
  KEYFRAME_DATA: {
    [P.CLIP_ID]: number
    [P.UPDATE_KEYFRAME_TYPE]:
      | 'add_keyframe'
      | 'delete_keyframe'
      | 'update_transition_type'
      | 'update_transition_time'
    [P.TRANSITION_TYPE]?: Transition['ease_fn']
    [P.ADD_KEYFRAME_SOURCE]?: string
  }
  COLLECTION_DATA: {
    [P.COLLECTION_ID]: number
    [P.COLLECTION_NAME]: string
    [P.COLLECTION_CATEGORY]: string
    [P.COLLECTION_OWNER_ID]: string
    [P.COLLECTION_IS_PRIVATE]?: boolean
    [P.IMAGE_COUNT]: number
  }
  MIX_PROJECT: {
    [P.ANCHOR_COUNT]: number
    [P.IS_RESAMPLE]?: boolean
    [P.IS_ILUSTRATIVE]?: boolean
  }
  UPSCALE: {
    [P.IMAGE_ID]: number
    [P.UPSCALE_SCALE]: UpscaleImageCreateReq['scale']
    [P.UPSCALE_IMAGE_SOURCE]: UpscalePanelState['mode']
    [P.UPSCALE_TEXTURE]: UpscaleImageCreateReq['controls']['texture']
    [P.UPSCALE_DEBLOCKING]: UpscaleImageCreateReq['controls']['deblocking']
    [P.UPSCALE_DENOISE]: UpscaleImageCreateReq['controls']['smooth']
    [P.UPSCALE_MODE]?: UpscaleImageCreateReq['method']
  }
  DOWNLOAD: {
    [P.DOWNLOAD_FILE_TYPE]: 'image' | 'video'
    [P.DOWNLOAD_LOCATION]: DownloadLocation
  }
  PURCHASE_DOWNLOAD_IMAGE: {
    [P.DOWNLOAD_LOCATION]: DownloadLocation
    [P.PRICE]: number
  }
  PROJECT_BOOKMARK: {
    [P.BOOKMARK_ACTION_TYPE]: 'save' | 'unsave'
    [P.BOOKMARK_LOCATION]: BookmarkLocation
    [P.BOOKMARK_SCOPE]: string
    [P.BOOKMARK_OBJECT_TYPE]: string
  }
  USER_IMAGE_BOOKMARK: {
    [P.BOOKMARK_ACTION_TYPE]: 'save' | 'unsave'
    [P.BOOKMARK_LOCATION]: BookmarkLocation
    [P.BOOKMARK_SCOPE]: string
  }
  POST_DATA: {
    [P.POST_ID]: number
    [P.POST_OWNER_ID]: string
    [P.CLAP_COUNT]: number
    [P.PROJECT_ID]: number
    [P.PROJECT_PROCESS_GROUP]: string
    [P.PROJECT_PROCESS_TYPE]: string
  }
  ARTMINE_PROJECT_DATA: {
    [P.PROJECT_ID]: number
    [P.PROJECT_NAME]: string
    [P.NETWORK]: MineProject['network']
    [P.LICENSE]: MineProject['license']
    [P.END_PRICE]: number
    [P.START_PRICE]: number
    [P.SELL_AS_NFT_SOURCE]: NFTProjectType | undefined
    [P.IMAGE_COUNT]: number
    [P.FORM_STEP]: STEP_NAMES
  }
}

export type Events = {
  USER__FIREBASE_ERROR: {
    name: 'User - Firebase Error'
    properties: {
      [P.CONTENT]: string
      [P.VALUE]: string
      [P.$EMAIL]: string
    }
  }
  USER__ERROR_BOUNDARY: {
    name: 'User - Error Boundary'
    properties: {
      [P.CONTENT]: string
      [P.VALUE]: string
    }
  }
  USER__PAGE_VIEW: {
    name: 'User - Page View'
    properties: undefined
  }
  USER__SIGNUP: {
    name: 'User - Signup'
    properties: EventProperties['EMAIL']
  }
  USER__LOGIN: {
    name: 'User - Login'
    properties: EventProperties['EMAIL'] & {
      [Properties.SIGN_IN_PROVIDER]: SignInProvider
    }
  }
  USER__LOGOUT: {
    name: 'User - Logout'
    properties: undefined
  }
  USER__UPDATE_PROFILE: {
    name: 'User - Update Profile'
    properties: EventProperties['UPDATED_FORMS']
  }
  USER__OPEN_PROFILE: {
    name: 'User - Open Profile'
    properties: {
      [Properties.PROFILE_ALIAS]: string
      [Properties.PROFILE_EMAIL]: string
      [Properties.PROFILE_NAME]: string
    }
  }
  USER__SHARE_PROFILE: {
    name: 'User - Share Profile'
    properties: {
      [Properties.PROFILE_ALIAS]: string
      [Properties.PROFILE_EMAIL]: string
      [Properties.PROFILE_NAME]: string
    }
  }
  USER__AUTHENTICATE: {
    name: 'User - Authenticate'
    properties: undefined
  }
  USER__ADD_CREDIT: {
    name: 'User - Add Credit'
    properties: Partial<EventProperties['PROJECT']> &
      EventProperties['TRANSACTION'] & { channel: Channel['name'] }
  }
  USER__ON_CLICK_BACK_TO_TRAINING_DETAILS: {
    name: 'User - On Click Back To Training Detail'
    properties: Partial<EventProperties['PROJECT']> &
      Pick<EventProperties['TRANSACTION'], 'quantity'>
  }
  USER__SUBMIT_PHONE: {
    name: 'User - Submit Phone'
    properties: { [P.SUBMIT_LOCATION]: 'welcome_page' | 'profile_page'; [P.PHONE_NUMBER]: string }
  }
  USER__PHONE_VERIFIED: {
    name: 'User - Phone Verified'
    properties: {
      [P.HAS_REDEEM_FREE_CREDIT]: boolean | undefined
      [P.PHONE_NUMBER]: string
    }
  }
  USER__REFERRAL_OPEN_DIALOG: {
    name: 'User - Referral Open Dialog'
    properties: { [P.WIDGET_NAME]?: string }
  }
  USER__REFERRAL_SHARE: {
    name: 'User - Referral Share'
    properties: { [P.SHARE_MESSAGE]?: string }
  }
  USER__UNLINK_PHONE: { name: 'User - Unlink Phone'; properties: undefined }
  USER__CHANGE_EMAIL_REQUEST: { name: 'User - Change Email Request'; properties: undefined }
  USER__CHANGE_EMAIL_SUCCESS: { name: 'User - Change Email Success'; properties: undefined }
  USER__OPEN_DIALOG: {
    name: 'User - Open Dialog'
    properties: { [P.DIALOG_NAME]: DefaultPropertyType; [P.DIALOG_CONTENT]: string }
  }
  USER__CHANGE_CARD: { name: 'User - Change Card'; properties: undefined }
  USER__REMOVE_CARD: { name: 'User - Remove Card'; properties: undefined }
  USER__OPEN_SUBSCRIBE_PAGE: {
    name: 'User - Open Subscribe Page'
    properties: Pick<EventProperties['SUBSCRIPTION'], 'current_subscription'>
  }
  USER__OPEN_SUBSCRIBE_FORM: {
    name: 'User - Open Subscribe Form'
    properties: Pick<
      EventProperties['SUBSCRIPTION'],
      'current_subscription' | 'subscription_form_type'
    >
  }
  USER__UPDATE_SUBSCRIPTION: {
    name: 'User - Update Subscription'
    properties: Pick<EventProperties['SUBSCRIPTION'], 'current_subscription' | 'prev_subscription'>
  }
  USER__REACTIVATE_SUBSCRIPTION: {
    name: 'User - Reactivate Subscription'
    properties: Pick<EventProperties['SUBSCRIPTION'], 'current_subscription'>
  }
  USER__CANCEL_SUBSCRIPTION: {
    name: 'User - Cancel Subscription'
    properties: Pick<EventProperties['SUBSCRIPTION'], 'current_subscription'>
  }
  USER__SUBMIT_QUESTION: {
    name: 'User - Submit Question'
    properties: {
      [P.CONTENT]: string
    }
  }
  USER__SHOW_UPSELL_BANNER: {
    name: 'User - Show Upsell Banner'
    properties: {
      [P.UPSELL_LOCATION]: UpsellLocation
      [P.UPSELL_CONTENT_MODE]: UpsellBannerState['contentMode']
    }
  }
  PROJECT__CREATE: {
    name: 'Project - Create'
    properties: EventProperties['PROJECT']
  }
  PROJECT__DUPLICATE: {
    name: 'Project - Duplicate'
    properties: EventProperties['PROJECT']
  }
  PROJECT__COPY_LINK: {
    name: 'Project - Copy Link'
    properties: EventProperties['PROJECT']
  }
  PROJECT__CHANGE_SCOPE: {
    name: 'Project - Change Scope'
    properties: EventProperties['PROJECT']
  }
  PROJECT__RENAME: {
    name: 'Project - Rename'
    properties: EventProperties['PROJECT']
  }
  PROJECT__DELETE: { name: 'Project - Delete'; properties: EventProperties['PROJECT'] }

  PROJECT__START_TRAINING: {
    name: 'Project - Start Training'
    properties: EventProperties['TRAINING_PARAM']
  }
  PROJECT__CONTINUE_TRAINING: {
    name: 'Project - Continue Training'
    properties: EventProperties['TRAINING_PARAM']
  }
  PROJECT__CLICK_ADD_COLLECTION: {
    name: 'Project - Click Add Collection'
    properties: EventProperties['PROJECT'] & {
      [P.IMAGE_SET]?: ImageSetType
    }
  }

  PROJECT__CLICK_ADD_COLLECTION_OPTIONS: {
    name: 'Project - Click Add Collection Options'
    properties: EventProperties['PROJECT'] & {
      [P.IMAGE_SET]?: ImageSetType
      [P.ADD_COLLETION_SOURCE]?: CollectionSourceType
    }
  }
  PROJECT__ADD_COLLECTION_TO_PROJECT: {
    name: 'Project - Add Collection To Project'
    properties: EventProperties['PROJECT'] &
      EventProperties['COLLECTION_DATA'] & {
        [P.IMAGE_SET]?: ImageSetType
        [P.ADD_COLLETION_SOURCE]?: CollectionSourceType
      }
  }

  PROJECT__UPDATE_COLLECTION: {
    name: 'Project - Update Collection'
    properties: EventProperties['PROJECT'] &
      EventProperties['COLLECTION_DATA'] & {
        [P.INCLUDED_IMAGES_COUNT]?: number
      }
  }
  PROJECT__REMOVE_COLLECTION: {
    name: 'Project - Remove Collection'
    properties: EventProperties['PROJECT'] & EventProperties['COLLECTION_DATA']
  }
  PROJECT__UNDO_REMOVE_COLLECTION: {
    name: 'Project - Undo Remove Collection'
    properties: EventProperties['PROJECT'] & EventProperties['COLLECTION_DATA']
  }
  PROJECT__MIX_GENERATE: {
    name: 'Project Mix - Generate Mix'
    properties: EventProperties['PROJECT'] & EventProperties['MIX_PROJECT']
  }
  PROJECT__MIX_APPLY_FACE_EDIT: {
    name: 'Project Mix - Apply Face Edit'
    properties: EventProperties['PROJECT'] & {
      [P.EXTRA_ATTRIBUTE_LABELS]: string[]
      [P.EXTRA_ATTRIBUTE_MODE]: string
    }
  }

  PROJECT__MIX_UPDATE_ANCHOR: {
    name: 'Project Mix - Update Anchor'
    properties: EventProperties['PROJECT'] & {
      [P.ANCHOR_CHANGE_MODE]: string
      [P.ANCHOR_CHANGE_SOURCE]: string
    }
  }

  PROJECT__MIX_DO_ACTION: {
    name: 'Project Mix - Do Action'
    properties: EventProperties['PROJECT'] & {
      [P.DO_ACTION_TYPE]: MixDoActionType
    }
  }
  PROJECT__MIX_UPLOAD_IMAGE: {
    name: 'Project Mix - Upload Image'
    properties: EventProperties['PROJECT'] & {
      [P.REFRESHED_UPLOAD_COUNT]?: number
    }
  }
  PROJECT__MIX_REFRESH_UNFINISHED_UPLOAD: {
    name: 'Project Mix - Refresh Unfinished Upload'
    properties: EventProperties['PROJECT'] & {
      [P.REFRESHED_UPLOAD_COUNT]?: number
    }
  }
  PROJECT__SKETCH_GENERATE: {
    name: 'Project Sketch - Generate Sketch'
    properties: EventProperties['PROJECT'] & {
      [P.SKETCH_STYLE]: string
      [P.STYLE_SOURCE]: ListIdType['source']
      [P.SKETCH_SOURCE]: SketchSourceType
    }
  }
  PROJECT__SKETCH_DO_ACTION: {
    name: 'Project Sketch - Do Action'
    properties: EventProperties['PROJECT'] & {
      [P.DO_ACTION_TYPE]: SketchDoActionType
      [P.DO_ACTION_VALUE]?: number | string
    }
  }
  PROJECT__PROARTFILTER_DO_ACTION: {
    name: 'Project ProArtFilter - Do Action'
    properties: EventProperties['PROJECT'] & {
      [P.DO_ACTION_TYPE]: ProArtFilterDoActionType
      [P.DO_ACTION_VALUE]?: number | string
    }
  }

  PROJECT__STYLE_GENERATE: {
    name: 'Project ProArtFilter - Generate'
    properties: EventProperties['PROJECT'] & {
      [P.PROARTFILTER_STYLE_VALUE]: number
      [P.PROARTFILTER_CONTENT_EFFECT]: number
      [P.PROARTFILTER_STYLE]: string
      [P.PROARTFILTER_SOURCE]: StyleListIdType['source']
      [P.PROARTFILTER_ENABLE_MASK]: boolean
    }
  }
  PROJECT__TEXT_TO_IMAGE_GENERATE: {
    name: 'Project Text To Image - Generate Image'
    properties: EventProperties['PROJECT'] & {
      [P.TEXT_TO_IMAGE_INPUT]?: string
      [P.TEXT_TO_IMAGE_RESOLUTION]: string
      [P.TEXT_TO_IMAGE_HAS_IMAGE_INPUT]?: boolean
      [P.TEXT_TO_IMAGE_MODEL]?: string
      [P.TEXT_TO_IMAGE_PROCESS]?: string
    }
  }
  PROJECT__SUBMIT_TEXT_TO_IMAGE_AGREEMENT: {
    name: 'Project Text To Image - Submit Text To Image Agreement'
    properties: undefined
  }
  PROJECT__TEXT_TO_IMAGE_DO_ACTION: {
    name: 'Project Text To Image - Do Action'
    properties: EventProperties['PROJECT'] & {
      [P.DO_ACTION_TYPE]: TextToImageDoActionType
      [P.DO_ACTION_VALUE]?: number | string
    }
  }
  PROJECT__GENERATE_VIDEO_PREVIEW: {
    name: 'Project Video - Generate Video Preview'
    properties: EventProperties['PROJECT'] & EventProperties['CLIP_DATA']
  }
  PROJECT__GENERATE_VIDEO_PREVIEW_FINISHED: {
    name: 'Project Video - Generate Video Preview Finished'
    properties: EventProperties['PROJECT'] & EventProperties['CLIP_DATA']
  }
  PROJECT__GENERATE_VIDEO: {
    name: 'Project Video - Generate Video'
    properties: EventProperties['PROJECT'] & EventProperties['CLIP_DATA']
  }
  PROJECT__GENERATE_VIDEO_FINISHED: {
    name: 'Project Video - Generate Video Finished'
    properties: EventProperties['PROJECT'] & EventProperties['CLIP_DATA']
  }
  PROJECT__CREATE_CLIP: {
    name: 'Project Video - Create Clip'
    properties: EventProperties['PROJECT'] & {
      [P.CLIP_ID]: EventProperties['CLIP_DATA']['clip_id']
      [P.CLIP_TYPE]: ClipProjectType
    }
  }
  PROJECT__UPDATE_CLIP: {
    name: 'Project Video - Update Clip'
    properties: EventProperties['PROJECT'] & {
      [P.CLIP_ID]: EventProperties['CLIP_DATA']['clip_id']
    } & {
      [P.UPDATE_CLIP_TYPE]: UpdateClipType
    }
  }
  PROJECT__UPDATE_KEYFRAME: {
    name: 'Project Video - Update Keyframe'
    properties: EventProperties['PROJECT'] & EventProperties['KEYFRAME_DATA']
  }
  PROJECT__UPSCALE_IMAGE: {
    name: 'Project - Upscale Image'
    properties: EventProperties['PROJECT'] & EventProperties['UPSCALE']
  }
  PROJECT__DOWNLOAD: {
    name: 'Project - Download'
    properties: Partial<EventProperties['PROJECT']> & EventProperties['DOWNLOAD']
  }
  PROJECT__PURCHASE_DOWNLOAD_IMAGE: {
    name: 'Project - Purchase Download Image'
    properties: Partial<EventProperties['PROJECT']> & EventProperties['PURCHASE_DOWNLOAD_IMAGE']
  }
  PROJECT__APPLY_ENHANCE_IMAGE: {
    name: 'Project - Apply Enhance Image'
    properties: Partial<EventProperties['PROJECT']> & Partial<AdjustedImage['controls']>
  }
  PROJECT__USER_IMAGE_BOOKMARK: {
    name: 'Project - User Image Bookmark'
    properties: Partial<EventProperties['PROJECT']> & EventProperties['USER_IMAGE_BOOKMARK']
  }
  COLLECTION__BOOKMARK: {
    name: 'Collection - Bookmark'
    properties: EventProperties['COLLECTION_DATA'] & { [P.BOOKMARK_ACTION_TYPE]: 'save' | 'unsave' }
  }
  COLLECTION__UPLOAD_FINISHED: {
    name: 'Collection - Upload Finished'
    properties: EventProperties['COLLECTION_DATA'] & {
      [P.UPLOAD_SUBMIT_COUNT]: number
      [P.IS_CANCEL]: boolean
      [P.UPLOADED_COUNT]: number
    }
  }
  COLLECTION__CREATE: {
    name: 'Collection - Create'
    properties: EventProperties['COLLECTION_DATA'] & {
      [P.UPLOAD_SUBMIT_COUNT]: number
    }
  }
  COLLECTION__UPDATE: {
    name: 'Collection - Update Collection'
    properties: EventProperties['COLLECTION_DATA'] &
      EventProperties['UPDATED_FORMS'] & { [P.DELETED_COUNT]?: number }
  }
  COLLECTION__UPDATE_TAG: {
    name: 'Collection - Update Collection Tag'
    properties: EventProperties['COLLECTION_DATA'] & {
      [P.UPDATE_TAG_TYPE]: 'remove' | 'add'
      [P.UPDATE_TAG_VALUE]: string
    }
  }
  COLLECTION__DELETE: {
    name: 'Collection - Delete'
    properties: EventProperties['COLLECTION_DATA']
  }
  SOCIAL__DELETE_POST: {
    name: 'Social - Delete Post'
    properties: EventProperties['POST_DATA']
  }
  SOCIAL__UPDATE_POST: {
    name: 'Social - Update Post'
    properties: EventProperties['POST_DATA'] & { [P.UPDATED_FORMS]: string[] }
  }

  SOCIAL__BOOKMARK: {
    name: 'Social - Bookmark'
    properties: EventProperties['POST_DATA'] & { [P.BOOKMARK_ACTION_TYPE]: 'save' | 'unsave' }
  }
  SOCIAL__SHARE: {
    name: 'Social - Share'
    properties: EventProperties['POST_DATA']
  }
  SOCIAL__COMMENT: {
    name: 'Social - Comment'
    properties: EventProperties['POST_DATA'] & {
      [P.COMMENT_TYPE]: 'thread' | 'comment'
      [P.COMMENT_LENGTH]?: number
      [P.REPLY_TO]?: number
      [P.THREAD_ID]?: number
    }
  }
  SOCIAL__OPEN_DETAIL: { name: 'Social - Open Detail'; properties: EventProperties['POST_DATA'] }
  SOCIAL__CLAP: {
    name: 'Social - Clap'
    properties: EventProperties['POST_DATA'] & { [P.CLAP_TYPE]: 'add' | 'remove' }
  }
  NFT__OPEN_SELL_AS_NFT: {
    name: 'NFT - Open Sell As NFT'
    properties: Pick<EventProperties['ARTMINE_PROJECT_DATA'], 'sell_ass_nft_source'>
  }
  NFT__OPEN_TRY_RESUBMIT: {
    name: 'NFT - Open Try Resubmit'
    properties: Pick<EventProperties['ARTMINE_PROJECT_DATA'], 'project_name' | 'project_id'>
  }
  NFT__DELETE_PROJECT: {
    name: 'NFT - Delete Project'
    properties: undefined
  }
  NFT__CONNECT_TO_METAMASK: {
    name: 'NFT - Connect To Metamask'
    properties: Pick<EventProperties['ARTMINE_PROJECT_DATA'], 'network'>
  }
  NFT__SET_FORM_STEP: {
    name: 'NFT - Set Form Step'
    properties: Pick<
      EventProperties['ARTMINE_PROJECT_DATA'],
      'sell_ass_nft_source' | 'project_id' | 'form_step'
    >
  }
  NFT__SUBMIT: {
    name: 'NFT - Submit'
    properties: Omit<EventProperties['ARTMINE_PROJECT_DATA'], 'sell_ass_nft_source' | 'project_id'>
  }
  NFT__SUBMIT_SUCCESSFULL: {
    name: 'NFT - Submit Successfull'
    properties: Omit<EventProperties['ARTMINE_PROJECT_DATA'], 'sell_ass_nft_source' | 'form_step'>
  }
  NFT__RESUBMIT: {
    name: 'NFT - Resubmit'
    properties: Omit<EventProperties['ARTMINE_PROJECT_DATA'], 'sell_ass_nft_source' | 'form_step'>
  }
  NFT__RESUBMIT_SUCCESSFULL: {
    name: 'NFT - Resubmit Successfull'
    properties: Omit<EventProperties['ARTMINE_PROJECT_DATA'], 'sell_ass_nft_source' | 'form_step'>
  }
}

type EventType = keyof Events

export type SetPeopleData = {
  first_name: string
  last_name: string
  $email: string
  portfolio?: string
  $phone?: string
  sign_up_date?: string
  sign_in_provider?: SignInProvider[]

  subscription?: Equity['type']
  subscription_interval?: Equity['interval']
  subscription_canceled?: Equity['canceled']
  subscription_expire?: Equity['expire']
  storage_usage?: Equity['storage_usage']
  pre_train_limit?: Equity['pre_train_limit']
  pre_train_usage?: Equity['pre_train_usage']
} & User['ui_extras']

export type UpdatePeopleData = Partial<SetPeopleData> & {
  last_login_date?: string
  credit_balance?: boolean
  subscription?: string
  referral_from_name?: string
  referral_from_email?: string
  referral_from_id?: string
} & Partial<ProjectSummary>

export const IncremenableValue = ['dollar_spent'] as const
export type IncrementType = (typeof IncremenableValue)[number]

type GetProjectParamTypeList = {
  training_project: {
    trainProject: TrainProject
  }
  pretrain_mix_project: {
    mixGenreData?: ReturnType<typeof apiSelectors.mixImageGenreData>
    mixProject: MixImageProject
  }
  sketch_project: {
    sketchProject: SketchProject
    sketchGenreData?: ReturnType<typeof apiSelectors.sketchGenreData>
  }
  training_mix_project: {
    trainProject: TrainProject
  }
  transfer_project: {
    proArtFilterGenreData?: ReturnType<typeof apiSelectors.proArtFilterGenreData>
    proArtFilterProject?: ProArtFilterProject
  }
  t2i_project: {
    textToImageProject?: TIProject
  }
  t2v_project: {
    textToVideoProject: TVProject
  }
  ST2I_project: {
    sketchTextToImageProject: SketchTextProject
  }
}

type GetProjectParamType<T extends ProjectObjectType> = T extends 'training_project'
  ? GetProjectParamTypeList['training_project']
  : T extends 'pretrain_mix_project'
    ? GetProjectParamTypeList['pretrain_mix_project']
    : T extends 'sketch_project'
      ? GetProjectParamTypeList['sketch_project']
      : T extends 'training_mix_project'
        ? GetProjectParamTypeList['training_mix_project']
        : T extends 't2i_project'
          ? GetProjectParamTypeList['t2i_project']
          : T extends 'ST2I_project'
            ? GetProjectParamTypeList['ST2I_project']
            : GetProjectParamTypeList['transfer_project']

/* To make sure the data is consistent */
export const DataUtils = {
  formatDate: (input?: string) => (input ? dayjs(input).toISOString() : undefined),
  getObjectKeys: (object: object) => {
    const objectKeys = _reduce(
      object,
      (result, value, key) => {
        if (value !== undefined && value !== null) {
          result.push(key)
        }
        return result
      },
      [] as string[]
    )
    return objectKeys
  },
  getUpscaleParameter: (data?: Equity) =>
    data
      ? `${data?.type}${data?.interval ? ' - ' : ''}${data?.interval ?? ''}${
          data?.canceled ? ' - canceled' : ''
        }`
      : '',
  getSubscriptionType: (data?: Equity) =>
    data
      ? `${data?.type}${data?.interval ? ' - ' : ''}${data?.interval ?? ''}${
          data?.canceled ? ' - canceled' : ''
        }`
      : '',
  getProjectParam: <T extends ProjectObjectType>(
    processGroup: T,
    param: GetProjectParamType<T>
  ): EventProperties['PROJECT'] => {
    if (processGroup === 'training_project') {
      const { trainProject } = param as GetProjectParamTypeList['training_project']
      return {
        project_id: trainProject?.id,
        project_name: trainProject?.name,
        project_process_group: processGroup,
        project_process_type: trainProject?.category
      }
    }
    if (processGroup === 'pretrain_mix_project') {
      const { mixProject, mixGenreData } = param as GetProjectParamTypeList['pretrain_mix_project']
      return {
        project_id: mixProject?.id,
        project_name: mixProject?.name,
        project_process_group: processGroup,
        project_process_type: mixGenreData?.[mixProject?.genre ?? 0]?.codename ?? ''
      }
    }
    if (processGroup === 'sketch_project') {
      const { sketchProject, sketchGenreData } = param as GetProjectParamTypeList['sketch_project']
      return {
        project_id: sketchProject?.id,
        project_name: sketchProject?.name,
        project_process_group: processGroup,
        project_process_type: sketchGenreData?.[sketchProject?.genre ?? 0]?.codename ?? ''
      }
    }
    if (processGroup === 'training_mix_project') {
      const { trainProject } = param as GetProjectParamTypeList['training_mix_project']
      return {
        project_id: trainProject?.id,
        project_name: trainProject?.name,
        project_process_group: processGroup,
        project_process_type: trainProject?.category
      }
    }
    if (processGroup === 'transfer_project') {
      const { proArtFilterProject, proArtFilterGenreData } =
        param as GetProjectParamTypeList['transfer_project']
      const genre = proArtFilterGenreData?.[proArtFilterProject?.genre ?? 0]
      return {
        project_id: proArtFilterProject?.id ?? 0,
        project_name: proArtFilterProject?.name ?? '',
        project_process_group: processGroup,
        project_process_type: genre?.codename ?? ''
      }
    }
    if (processGroup === 't2v_project') {
      const { textToVideoProject } = param as GetProjectParamTypeList['t2v_project']

      return {
        project_id: textToVideoProject?.id ?? 0,
        project_name: textToVideoProject?.name ?? '',
        project_process_group: processGroup,
        project_process_type: ''
      }
    }
    if (processGroup === 't2i_project') {
      const { textToImageProject } = param as GetProjectParamTypeList['t2i_project']
      return {
        project_id: textToImageProject?.id ?? 0,
        project_name: textToImageProject?.name ?? '',
        project_process_group: processGroup,
        project_process_type: ''
      }
    }
    if (processGroup === 'ST2I_project') {
      const { sketchTextToImageProject } = param as GetProjectParamTypeList['ST2I_project']
      return {
        project_id: sketchTextToImageProject?.id ?? 0,
        project_name: sketchTextToImageProject?.name ?? '',
        project_process_group: processGroup,
        project_process_type: ''
      }
    }
    return {
      project_id: 0,
      project_name: '',
      project_process_group: 'training_project',
      project_process_type: ''
    }
  },
  getCollectionData: ({
    collection,
    category
  }: {
    collection?: Collection
    category?: { [id: number]: Category }
  }) => {
    const categoryName = category?.[collection?.id ?? 0]?.name
    return {
      collection_id: collection?.id ?? 0,
      collection_name: collection?.name ?? '',
      collection_category: categoryName ?? '',
      collection_owner_id: collection?.user.id ?? '',
      collection_is_private: collection?.is_private,
      image_count: collection?.count ?? 0
    }
  },
  getPostData: ({ post }: { post?: Post }): EventProperties['POST_DATA'] => {
    return {
      post_id: post?.id ?? 0,
      post_owner_id: post?.user?.id ?? '',
      clap_count: post?.clap_counts ?? 0,
      project_id: post?.related?.id ?? 0,
      project_process_group: post?.related?.category ?? '',
      project_process_type: post?.related?.object_type ?? ''
    }
  }
}

const hasToken = Boolean(process.env.REACT_APP_MIX_PANEL_TOKEN)
const MixPanelUtils = {
  init: () => {
    const token = process.env.REACT_APP_MIX_PANEL_TOKEN
    if (token) {
      mixpanel.init(token)
    }
  },
  identify: (userId?: string) => {
    hasToken && mixpanel.identify(userId ? userId : undefined)
  },
  timeEvent: (event: EventType) => {
    hasToken && mixpanel.time_event(event)
  },
  track: <T extends EventType>(
    eventName: Events[T]['name'],
    properties?: Events[T]['properties']
  ) => {
    hasToken && mixpanel.track(eventName, properties)
  },
  onLogout: () => {
    hasToken && mixpanel.reset()
  },
  setPeople: (data: SetPeopleData) => {
    const dataAdjusted: SetPeopleData = {
      ...data,
      subscription_expire: DataUtils.formatDate(data.subscription_expire),
      sign_up_date: DataUtils.formatDate(data.sign_up_date)
    }

    hasToken && mixpanel.people.set_once(dataAdjusted)
  },
  updatePeople: (data: UpdatePeopleData) => {
    const dataAdjusted: UpdatePeopleData = {
      ...data,
      subscription_expire: DataUtils.formatDate(data.subscription_expire),
      last_login_date: DataUtils.formatDate(data.last_login_date),
      sign_up_date: DataUtils.formatDate(data.sign_up_date)
    }
    hasToken && mixpanel.people.set(dataAdjusted)
  },
  incrementValue: (key: IncrementType, value: number) => {
    hasToken && mixpanel.people.increment(key, value)
  }
}

export default MixPanelUtils
