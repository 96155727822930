import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'duck'
import { useBreakpoint, useDelayedData } from 'utils/Hooks'
import { appSelectors } from 'duck/AppDuck'
import { values } from 'appConstants'
import loadable from 'utils/Loadable'

const ImageEnhancementPanelDesktop = loadable(
  () =>
    import(/* webpackChunkName: "ImageEnhancementPanelDesktop" */ './ImageEnhancementPanelDesktop')
)
const ImageEnhancementPanelMobile = loadable(
  () =>
    import(/* webpackChunkName: "ImageEnhancementPanelMobile" */ './ImageEnhancementPanelMobile')
)

const ImageEnhancementPanel: React.FC = () => {
  const { isSmallscreenAbove } = useBreakpoint()
  const hasImageEnhancementDialog = useSelector((state: RootState) =>
    appSelectors.dialog.hasImageEnhancementDialog(state)
  )

  const openState = useDelayedData(hasImageEnhancementDialog, values.DIALOG_TRANSITION_DURATION)

  if (!hasImageEnhancementDialog && !openState) return null

  return isSmallscreenAbove ? <ImageEnhancementPanelDesktop /> : <ImageEnhancementPanelMobile />
}

export default ImageEnhancementPanel
