import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import useInterval from 'use-interval'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import UpscaleCard from '../components/UpscaleCard'
// import {
//   FixedSizeList,
//   ListVirtualizedConfigsProps,
//   ListVirtualizedClassesProps,
//   RenderItemProps
// } from 'components/ListVirtu'
import {
  ListVirtualizedConfigsProps,
  ListVirtualizedClassesProps,
  RenderItemProps
} from 'components/ListVirtualized'
import FixedSizeList from 'components/ListVirtualized/FixedSizeList'
import { RootState, RootActionType } from 'duck'
import { actions, selectors, UpscalePanelState, INITIAL } from '../duck'
import { apiActions, apiSelectors } from 'duck/ApiDuck'
import { ConfirmationDialog, dialogActions } from 'duck/AppDuck/DialogDuck'
import { UpscaleImage } from 'models/ApiModels'
import { GENERATING_POOL_INTERVAL_2, UPSCALE_LIST_CONFIG } from '../Models'
import ButtonText from 'components/Button/ButtonText'
import AddProjectCard from 'containers/HomePage/components/AddProjectCard'
import { useExecuteOnChange } from 'utils/Hooks'
import { ReduxProps } from 'utils/Types'
import styles from './Components.module.scss'

const CONFIG_GRID = {
  xs: {
    defaultColumnWidth: 250,
    paddingX: 24
  },
  sm: {
    paddingX: 24
  },
  lg: {
    maxWidth: 1440 + 72 + 72
  },
  xl: {
    maxWidth: 1440 + 72 + 72
  },
  default: {
    defaultColumnWidth: 300,
    rowHeight: 300,
    paddingY: 24,
    paddingX: 72,
    gutter: 24
  }
}

type UpscaleWithVariant = UpscaleImage | 'add'

const Utils = {
  getEmptyText: (listParam: UpscalePanelState['upscaleList']['listParams']) => {
    const result: {
      canReset: boolean
      emptyText: string
    } = {
      canReset: false,
      emptyText: 'You have no active upscales.'
    }

    if (listParam.scale !== undefined) {
      result.canReset = true
      result.emptyText = `There are no upscale in ${listParam.scale}x scales`
    }

    if (listParam.method !== undefined) {
      result.canReset = true
      result.emptyText = `There are no upscale with ${
        UPSCALE_LIST_CONFIG[listParam.method].title
      } method`
    }

    return result
  }
}

const mapStateToProps = (state: RootState) => ({
  listUpscaleImageLoading: apiSelectors.loading['imageEnhancement.listUpscaleImage'](state),
  upscaleImageList: apiSelectors.upscaleImageList(state),
  hasUnfinishedUpscaleImage: apiSelectors.hasUnfinishedUpscaleImage(state),
  upscaleListListParam: selectors.upscaleListListParam(state),
  upscaleListLoaded: selectors.upscaleListLoaded(state),
  openedUpscaleId: selectors.openedUpscaleId(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      loadUpscaleList: actions.upscaleList.loadUpscaleList,
      updateListParam: actions.upscaleList.updateListParam,
      retrieveUpscaleData: actions.retrieveUpscaleData,
      listUpscaleImage: apiActions.imageEnhancement.listUpscaleImage,
      addDialog: dialogActions.addDialogOverlay,
      setLoaded: actions.upscaleList.setLoaded
    },
    dispatch
  )

export type UpscaleListProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  classes?: {
    grid: ListVirtualizedClassesProps
  }
  resetToDefault?: boolean
  virtualized?: boolean
  gridConfig?: ListVirtualizedConfigsProps
  scrollRef?: MutableRefObject<HTMLDivElement | null>
  showNewProjectButton?: boolean
  onClickItem: (upscaleId: number) => void
}

const UpscaleList = (props: UpscaleListProps) => {
  const {
    listUpscaleImage,
    addDialog,
    loadUpscaleList,
    retrieveUpscaleData,
    updateListParam,
    setLoaded,
    onClickItem,
    classes,
    openedUpscaleId,
    resetToDefault,
    upscaleListListParam,
    listUpscaleImageLoading,
    upscaleImageList,
    upscaleListLoaded,
    hasUnfinishedUpscaleImage,
    virtualized,
    gridConfig,
    scrollRef,
    showNewProjectButton
  } = props

  const upscaleListAdjusted = useMemo(() => {
    if (showNewProjectButton) {
      return ['add' as 'add', ...upscaleImageList]
    } else {
      return upscaleImageList
    }
  }, [showNewProjectButton, upscaleImageList])

  const deleteUpscaleImage = useCallback(
    (upscaleImageId: number) => {
      addDialog({
        [ConfirmationDialog.CONFIRMATION]: {
          dialogName: ConfirmationDialog.CONFIRMATION,
          title: 'Delete Upscale?',
          content: (
            <div>
              <Typography variant="body1" className="pb-4">
                This action is permanent. Are you sure you want to delete this upscale?
              </Typography>
              <Typography variant="body1">
                You will no longer be able to download or preview this upscale result.
              </Typography>
            </div>
          ),
          yesAction: {
            label: 'DELETE',
            actions: [apiActions.imageEnhancement.deleteUpscaleImage({ upscaleImageId })]
          }
        }
      })
    },
    [addDialog]
  )

  const renderItem = useCallback(
    (props: RenderItemProps<UpscaleWithVariant>) => {
      const { index } = props
      const item = upscaleListAdjusted[index]

      if (!item) return null

      return item === 'add' ? (
        <div key="add" className="relative h-full">
          <AddProjectCard />
        </div>
      ) : (
        <UpscaleCard
          key={item.id}
          deleteUpscaleImage={deleteUpscaleImage}
          item={item}
          onClickItem={onClickItem}
        />
      )
    },
    [deleteUpscaleImage, onClickItem, upscaleListAdjusted]
  )

  const loadMoreUpscaleImage = useCallback(() => {
    listUpscaleImage({ param: upscaleListListParam, next: true })
  }, [listUpscaleImage, upscaleListListParam])

  useInterval(
    () => {
      if (hasUnfinishedUpscaleImage) {
        retrieveUpscaleData('upscaleList')
      }
    },
    hasUnfinishedUpscaleImage && !openedUpscaleId ? GENERATING_POOL_INTERVAL_2 : null,
    false
  )

  const showEmpty = !showNewProjectButton && !upscaleImageList.length && upscaleListLoaded

  useEffect(() => {
    if (!upscaleListLoaded) {
      loadUpscaleList()
    }
  }, [loadUpscaleList, upscaleListLoaded])

  const resetListParam = useCallback(() => {
    const { canReset } = Utils.getEmptyText(upscaleListListParam)
    if (resetToDefault && canReset) {
      setLoaded(false)
      updateListParam(INITIAL.upscaleList.listParams)
    }
  }, [resetToDefault, setLoaded, updateListParam, upscaleListListParam])

  useExecuteOnChange({
    executor: resetListParam,
    executeOn: 'true'
  })

  return (
    <div className={styles.UpscaleListContent}>
      {showEmpty ? (
        <div className="px-6 pb-6 pt-30 text-center text-white-med-on-dark">
          <Typography variant="subtitle2">
            {Utils.getEmptyText(upscaleListListParam).emptyText}
          </Typography>

          {Utils.getEmptyText(upscaleListListParam).canReset ? (
            <ButtonText
              color="secondary"
              onClick={() => {
                setLoaded(false)
                updateListParam(INITIAL.upscaleList.listParams)
              }}
            >
              Reset Filter
            </ButtonText>
          ) : null}
        </div>
      ) : null}

      <FixedSizeList<UpscaleWithVariant>
        classes={classes?.grid}
        onEndOfGrid={loadMoreUpscaleImage}
        dataLength={upscaleListAdjusted.length}
        renderItem={renderItem}
        className={showEmpty ? 'hidden' : undefined}
        loading={Boolean(listUpscaleImageLoading)}
        config={gridConfig || CONFIG_GRID}
        scrollRef={scrollRef}
        loadingPosition={virtualized ? 'fixed' : undefined}
        autoHeight={virtualized}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpscaleList)
