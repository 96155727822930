import { combineEpics } from 'redux-observable'

import mixVideoReducers, { MixVideoState, mixVideoEpics } from './MixVideo'
import renderVideoReducer, { RenderVideoState, renderVideoEpics } from './RenderVideo'
import { combineReducers } from 'redux'

// Epics
export const mixVideoPanelEpics = combineEpics(renderVideoEpics, mixVideoEpics)

export type MixVideoPanelState = {
  mixVideo: MixVideoState
  renderVideo: RenderVideoState
}

export default combineReducers<MixVideoPanelState>({
  mixVideo: mixVideoReducers,
  renderVideo: renderVideoReducer
})

export * from './MixVideo'
export * from './RenderVideo'
