import _toInteger from 'lodash/toInteger'
import { RootState } from 'duck'

export const user = (state: RootState) => {
  return state.api.users.user
}

export const userImages = (state: RootState) => {
  return state.api.projects?.userImages ?? {}
}

export const projects = (state: RootState) => {
  return state.api.projects?.projects ?? {}
}

export const collections = (state: RootState) => {
  return state.api.collections.collections ?? {}
}

export const currentMixImageProjectId = (state: RootState) =>
  _toInteger(state.api.mixImage.currentMixImageId)

export const adjustedImages = (state: RootState) => {
  return state.api.imageEnhancement?.adjustedImages ?? {}
}
