import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'

import _toNumber from 'lodash/toNumber'
import { useSelector } from 'react-redux'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import Typography from '@mui/material/Typography'
import { selectors, Utils } from '../duck'
import { RootState } from 'duck'
import { apiSelectors } from 'duck/ApiDuck'
import LargeImagePreview from 'components/LargeImagePreview'
import { ImageType } from 'models/ApiModels'

const PLACEHOLDER_IMAGE: ImageType = {
  id: 1,
  file: 'sample',
  height: 0,
  width: 0
}

const ImagePreview: React.FC<{ open: boolean; isTouchDevice: boolean }> = props => {
  const { open, isTouchDevice } = props
  const openedUpscale = useSelector((state: RootState) => selectors.openedUpscale(state))
  const retrieveLoading = useSelector((state: RootState) =>
    apiSelectors.loading['imageEnhancement.retrieveUpscaleImage'](state)
  )
  const isRetrieved = retrieveLoading && _toNumber(retrieveLoading) === openedUpscale?.id

  const largeImage = openedUpscale?.output
  const smallImage = openedUpscale?.image

  const image = largeImage ?? smallImage ?? PLACEHOLDER_IMAGE

  return (
    <AutoSizer>
      {(props: { width: number; height: number }) => {
        const { width, height } = props
        return (
          <div>
            <LargeImagePreview
              noBorder
              size={{ width, height }}
              disablePreview={!openedUpscale?.output}
              loading={Boolean(!openedUpscale?.output || isRetrieved)}
              image={open ? image : PLACEHOLDER_IMAGE}
            />
            <div className="absolute bottom-0 left-0 mb-2 ml-2">
              <ZoomInIcon className="mr-1 align-middle text-white-med-on-dark" />

              <Typography
                variant="body2"
                className="mb-2 text-right text-white-med-on-dark"
                component="span"
              >
                {Utils.getZoomMethodText(isTouchDevice)}
              </Typography>
            </div>
          </div>
        )
      }}
    </AutoSizer>
  )
}

export default ImagePreview
