import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { TextTransform, TextCaseProps } from 'utils/TextUtils'
import { twMerge } from 'tailwind-merge'

import styles from './SelectBase.module.scss'

export type SelectBaseItemType<Value extends string | number = string> = {
  key?: number | string
  disabled?: boolean
  label?: string | React.ReactNode
  value?: Value
}

export type SelectBaseProps<Value extends string | number = string> = Omit<
  SelectProps,
  'onChange' | 'renderValue'
> & {
  borderless?: boolean
  classNameIcon?: string
  classNameMenu?: string
  classNameSelect?: string
  inputAlign?: 'right'
  items?: SelectBaseItemType<Value>[]
  menuTextTransform?: TextCaseProps['format']
  onChange?: (value: Value) => void
  renderValue?: (value: Value) => React.ReactNode
  size?: 'small'
}

function SelectBase<Value extends string | number = string>(props: SelectBaseProps<Value>) {
  const {
    autoWidth,
    borderless,
    children,
    className,
    classNameIcon,
    classNameMenu,
    classNameSelect,
    fullWidth,
    inputAlign,
    items,
    menuTextTransform = 'titlecase',
    size,
    value,
    disabled,
    placeholder,
    MenuProps,
    onChange,
    renderValue,
    variant = 'standard'
  } = props
  const [isOpen, setOpen] = useState(false)
  const handleChange = useCallback(
    (event: SelectChangeEvent<any>) => {
      onChange?.(event.target.value as Value)
    },
    [onChange]
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const renderValueAdjusted = useCallback<NonNullable<SelectProps['renderValue']>>(
    value => {
      return renderValue?.(value as Value)
    },
    [renderValue]
  )

  const SelectBaseItems = useMemo(() => {
    if (!items) return undefined

    const result = items.map((item, index) => {
      return (
        <MenuItem key={item.key || index + 1} value={item.value}>
          <Typography variant="body2" className={styles.MenuItemLabel}>
            {menuTextTransform && item.label
              ? TextTransform.textCase(String(item.label), menuTextTransform)
              : item.label}
          </Typography>
        </MenuItem>
      )
    })

    return placeholder
      ? [
          <MenuItem key="-1" value="">
            <em>{placeholder}</em>
          </MenuItem>,
          ...result
        ]
      : result
  }, [items, menuTextTransform, placeholder])

  return (
    <Select
      className={twMerge(
        'relative p-0',
        styles.SelectBase,
        borderless ? styles.SelectBaseBorderless : '',
        fullWidth ? '!w-full' : '',
        isOpen && styles.SelectBaseOpen,
        size === 'small' ? styles.SelectBaseSmall : '',
        className
      )}
      disabled={disabled}
      autoWidth={autoWidth}
      disableUnderline={true}
      value={value}
      onChange={handleChange}
      onClose={handleClose}
      onOpen={handleOpen}
      renderValue={renderValue ? renderValueAdjusted : undefined}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
          ...MenuProps?.anchorOrigin
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
          ...MenuProps?.transformOrigin
        },
        // getContentAnchorEl: null,
        classes: {
          ...MenuProps?.classes,
          paper: clsx(styles.SelectBasePaper, classNameMenu, MenuProps?.classes?.paper)
        }
      }}
      classes={{
        select: clsx(
          styles.SelectBaseInput,
          inputAlign === 'right' ? '!text-right' : '',
          classNameSelect
        ),
        icon: clsx(styles.SelectBaseIcon, disabled && '!hidden', classNameIcon)
      }}
      variant={variant}
    >
      {SelectBaseItems || children}
    </Select>
  )
}

export default SelectBase
