import React from 'react'
import Typography from '@mui/material/Typography'
import LinkComponent from '@mui/material/Link'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { dialogSelectors, ErrorDialog, ErrorDialogNameType } from 'duck/AppDuck/DialogDuck'
import { link } from 'appConstants'
import { route } from 'routes'
import Button from 'components/Button'
import DialogWrapper, { DialogWrapperProps } from './DialogWrapper'
import { GeneralDialogItemProps } from '.'
import { RootState } from 'duck'
import { DialogProps } from 'components/Dialog'
import { MINE_PROJECT_SUBMIT_WAIT_TIME } from 'utils/DataProcessingUtils'
import styles from './Dialogs.module.scss'

const DEFAULT_VALUE: {
  dialogConfig: DialogWrapperProps['dialogConfig']
  actionCreator: (onClose: DialogProps['onClose'], text?: string) => DialogWrapperProps['actions']
} = {
  dialogConfig: {
    contentDividers: false,
    disableTitleBottomSpacing: true
  },
  actionCreator: (onClose: DialogProps['onClose'], text = 'DISMISS') => (
    <Button color="secondary" size="small" onClick={onClose}>
      {text}
    </Button>
  )
}

const Error: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ErrorDialog.ERROR](state)
  )

  const content = dialogData?.content ?? ''
  const title = dialogData?.title ?? ''

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig
      }}
      actions={DEFAULT_VALUE.actionCreator(onClose)}
      activeDialog={activeDialog}
      title={title ? <Typography variant="h6">{title}</Typography> : undefined}
      onClose={onClose}
      content={
        <div className={clsx(styles.ContentGroup, styles.GreyText)}>
          {Array.isArray(content) ? (
            content.map((value, index) => {
              return value ? (
                <Typography key={index} variant="body2">
                  {value}
                </Typography>
              ) : null
            })
          ) : (
            <Typography variant="body2">{content}</Typography>
          )}
        </div>
      }
    />
  )
}

const ErrorNotEnoughGasPrice: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig
      }}
      actions={DEFAULT_VALUE.actionCreator(onClose)}
      activeDialog={activeDialog}
      title={<Typography variant="h6">Not Enough Gas Price</Typography>}
      onClose={onClose}
      content={
        <div className={clsx(styles.ContentGroup)}>
          <Typography variant="body2">
            The submission process failed because of not enough gas prices. Please try to increase
            the gas price to make sure the transaction successful.
          </Typography>
          <Typography variant="body2">
            You can check this page for more information about how to increase gas price :
            <a href={link.ARTMINE_CHANGE_GAS_PRICE}>{` ${link.ARTMINE_CHANGE_GAS_PRICE}`}</a>
          </Typography>
          <Typography variant="body2" className="mt-3">
            {`The project is saved and you can resubmit the project on My NFT page after ${MINE_PROJECT_SUBMIT_WAIT_TIME} minutes from the last submit`}
          </Typography>
        </div>
      }
    />
  )
}

const ProjectNotFound: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        disableActionTopSpacing: true
      }}
      actions={DEFAULT_VALUE.actionCreator(onClose)}
      title={<Typography variant="h6">Project Not Found</Typography>}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body2">We were unable to find your project.</Typography>
          </div>
          <div
            className={clsx(
              styles.ContentGroup,
              styles.DottedList,
              styles.CompactList,
              styles.GreyText
            )}
          >
            <Typography variant="body2">Why you might be seeing this:</Typography>
            <ul>
              <li>
                <Typography color="secondary" variant="body2">
                  The project you are attempting to access does not exist.
                </Typography>
              </li>
              <li>
                <Typography color="secondary" variant="body2">
                  The project is private, and you do not have access privileges.
                </Typography>
              </li>
            </ul>
          </div>
        </>
      }
    />
  )
}

const UploadFailed: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ErrorDialog.UPLOAD_FAILED](state)
  )

  const content = dialogData?.content

  const count = content?.count ?? 0
  const errorList = content?.errorList ?? []
  const customTitle = content?.customTitle ?? ''

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        disableActionTopSpacing: true
      }}
      title={customTitle ? customTitle : `${count} files failed to upload.`}
      activeDialog={activeDialog}
      actions={DEFAULT_VALUE.actionCreator(onClose, 'OK, GOT IT')}
      onClose={onClose}
      content={
        <>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body1">These files failed for the following reasons:</Typography>
          </div>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <ul>
              {errorList.map((value, index) => (
                <li key={index}>
                  <Typography variant="body1">{value}</Typography>
                </li>
              ))}
            </ul>
          </div>
        </>
      }
    />
  )
}

const NotEnoughImages: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ErrorDialog.NOT_ENOUGH_IMAGES](state)
  )

  const content = dialogData?.content

  const minInspiration = content?.minInspiration ?? 0
  const minAesthetic = content?.minAesthetic ?? 0
  const isDoubleInput = content?.isDoubleInput

  let errorText

  if (isDoubleInput) {
    if (minInspiration === minAesthetic) {
      errorText = `For best results when training, Playform needs at least ${minInspiration} images in both Inspirations and Style.`
    } else {
      errorText = `For best results when training, Playform needs at least ${minInspiration} images for Inspirations and ${minAesthetic} images for Style.`
    }
  } else {
    errorText = `For best results when training, Playform needs at least ${minInspiration} Inspirations images.`
  }

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig
      }}
      title={<Typography variant="h6">Not enough images.</Typography>}
      activeDialog={activeDialog}
      onClose={onClose}
      actions={DEFAULT_VALUE.actionCreator(onClose, 'OK, GOT IT')}
      content={
        <>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body1">{errorText}</Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body1">If you need a starting point:</Typography>
          </div>
          <div className={clsx(styles.ContentGroup, styles.DottedList, styles.GreyText)}>
            <ul>
              <li>
                <Typography variant="body1">
                  <LinkComponent
                    color="secondary"
                    href={link.OUR_DATASET}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Browse our image datasets
                  </LinkComponent>{' '}
                  for collections to start off with.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <LinkComponent
                    color="secondary"
                    href={link.USE_AN_EXTENSION}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Use an extension
                  </LinkComponent>{' '}
                  to help you easily download large amounts of images off the internet.
                </Typography>
              </li>
            </ul>
          </div>
        </>
      }
    />
  )
}

const TooManyImages: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ErrorDialog.TOO_MANY_IMAGES](state)
  )

  const content = dialogData?.content

  const maxAesthetic = content?.maxAesthetic ?? 0
  const maxInspiration = content?.maxInspiration ?? 0
  const isDoubleInput = content?.isDoubleInput ?? 0
  const inspirationCount = content?.inspirationCount ?? 0
  const aestheticCount = content?.aestheticCount ?? 0

  let errorText
  let countList = []

  if (isDoubleInput) {
    if (maxAesthetic === maxInspiration) {
      errorText = `Maximum images allowed for training is ${maxAesthetic} images in both Inspirations and Style.`
    } else {
      errorText = `Maximum images allowed for training is ${maxInspiration} images for Inspirations and ${maxAesthetic} images for Style.`
    }
    countList = [
      {
        count: inspirationCount,
        name: 'Inspirations'
      },
      {
        count: aestheticCount,
        name: 'Style'
      }
    ]
  } else {
    errorText = `Maximum images allowed for training is ${maxInspiration} Inspirations images.`
    countList = [
      {
        count: inspirationCount,
        name: 'Inspirations'
      }
    ]
  }

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        disableActionTopSpacing: true
      }}
      title={<Typography variant="h6">Too many images.</Typography>}
      actions={DEFAULT_VALUE.actionCreator(onClose, 'OK, GOT IT')}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body1">{errorText}</Typography>
          </div>
          <div className={styles.ContentGroup}>
            <Typography variant="body1">Currently you have :</Typography>
          </div>
          <div className={clsx(styles.ContentGroup, styles.DottedList, styles.GreyText)}>
            <ul>
              {countList.map(values => (
                <li key={`${values.name}-${values.count}`}>
                  <Typography variant="body1">{`${values.name}: ${values.count} images`}</Typography>
                </li>
              ))}
            </ul>
          </div>
        </>
      }
    />
  )
}

const ReusedCollection: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  const dialogData = useSelector((state: RootState) =>
    dialogSelectors.dialogDatum[ErrorDialog.REUSED_COLLECTION](state)
  )

  const content = dialogData?.content
  const process = content?.process ?? ''

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        disableActionTopSpacing: true
      }}
      title={<Typography variant="h6">Reused Collection</Typography>}
      actions={DEFAULT_VALUE.actionCreator(onClose, 'OK, GOT IT')}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body1">
              {`For best results when training, the ${process} model needs different collections
              for Inspiration and Style.`}
            </Typography>
          </div>
          <div className={clsx(styles.ContentGroup, styles.GreyText)}>
            <Typography variant="body1">
              If you are looking to create new interpretations of a single collection, you can use
              Freeform model.
            </Typography>
          </div>
        </>
      }
    />
  )
}

const ReferralError: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        disableActionTopSpacing: true
      }}
      actions={DEFAULT_VALUE.actionCreator(onClose)}
      title={<Typography variant="h6">Referral form failed to load</Typography>}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <>
          <Typography variant="body2">Why you might be seeing this:</Typography>

          <ul className="pl-6">
            <li>
              <Typography variant="body2">
                Blocked by ad blocker. Please turn off ad blocker first.
              </Typography>
            </li>

            <li>
              <Typography variant="body2">Unstable connection. Try to reload the page.</Typography>
            </li>
          </ul>
        </>
      }
    />
  )
}

const PhoneUnverified: React.FC<GeneralDialogItemProps> = props => {
  const { activeDialog, onClose } = props

  return (
    <DialogWrapper
      dialogConfig={{
        ...DEFAULT_VALUE.dialogConfig,
        disableActionSpacing: true,
        fullWidth: true
      }}
      title={<Typography variant="h6">Phone Unverified</Typography>}
      activeDialog={activeDialog}
      onClose={onClose}
      content={
        <Typography className="pb-6" variant="body2">
          Phone verification need to be completed first.
        </Typography>
      }
      actions={
        <Button
          variant="contained"
          color="secondary"
          to={route.PROFILE.getUrl()}
          onClick={event => {
            onClose?.()
          }}
          fullWidth
        >
          GO TO PROFILE
        </Button>
      }
    />
  )
}

export const ErrorDialogs: Record<ErrorDialogNameType, React.FC<GeneralDialogItemProps>> = {
  [ErrorDialog.ERROR]: Error,
  [ErrorDialog.PROJECT_NOT_FOUND]: ProjectNotFound,
  [ErrorDialog.UPLOAD_FAILED]: UploadFailed,
  [ErrorDialog.NOT_ENOUGH_IMAGES]: NotEnoughImages,
  [ErrorDialog.TOO_MANY_IMAGES]: TooManyImages,
  [ErrorDialog.REUSED_COLLECTION]: ReusedCollection,
  [ErrorDialog.REFERRAL_ERROR]: ReferralError,
  [ErrorDialog.PHONE_UNVERIFIED]: PhoneUnverified,
  [ErrorDialog.NOT_ENOUGH_GAS_PRICE]: ErrorNotEnoughGasPrice
}
