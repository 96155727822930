import * as ApiActions from './actions'
import { ActionType, createAction } from 'typesafe-actions'
import {
  ApiErrorBody,
  CollectionListReq,
  CollectionCopyReq,
  CollectionCreateReq,
  CollectionUpdateReq,
  ImageCreateReq,
  ImageListReq,
  TrainOutputImagesReq,
  TrainProjectCreateReq,
  TrainProjectUpdateReq,
  UpdateUserReq,
  TrainProjectListReq,
  InferenceRecordCreateReq,
  InferenceStartReq,
  AddInferenceInputReq,
  InputImageSet
} from 'models/ApiModels'
import { firebaseActions } from 'duck/FirebaseDuck'
import { FirebaseError } from 'firebase/app'

export type RequestType =
  | ActionType<typeof ApiActions>['type']
  | ActionType<typeof firebaseActions>['type']

export type RequestDataType =
  | UpdateUserReq
  | TrainProjectListReq
  | TrainProjectCreateReq
  | TrainProjectUpdateReq
  | CollectionCreateReq
  | CollectionListReq
  | CollectionCopyReq
  | CollectionUpdateReq
  | InputImageSet
  | ImageCreateReq
  | ImageListReq
  | TrainOutputImagesReq
  | InferenceRecordCreateReq
  | InferenceStartReq
  | AddInferenceInputReq
  | number
  | string
  | void
  | FormData
  | object

export type ErrorBundleType = {
  firebaseError?: FirebaseError
  error?: ApiErrorBody | null
  req?: RequestDataType
  type: RequestType
  metadata?: any
}

export const sharedActions = {
  reset: createAction('@@shared/RESET')(),
  setError: createAction('@@shared/SET_ERROR')<ErrorBundleType>(),
  cancelRequest: createAction('@@shared/CANCEL_REQUEST')<RequestType>(),
  resetError: createAction('@@shared/RESET_ERROR')<RequestType | RequestType[]>(),
  resetAllErrors: createAction('@@shared/RESET_ALL_ERRORS')(),
  setLoading: createAction('@@shared/SET_LOADING')<{
    loading: boolean | string
    type: RequestType
  }>(),
  setImageListFetchState: createAction('@@shared/SET_IMAGE_LIST_FETCH_STATE')<{
    key: string
    value: boolean
  }>()
}

export type SharedActionsType = ActionType<typeof sharedActions>
