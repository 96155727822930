import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { createSelector } from 'reselect'
import { combineEpics, Epic } from 'redux-observable'
import { getType, createAction, isActionOf, ActionType } from 'typesafe-actions'
import { withLatestFrom, ignoreElements, map, filter, tap } from 'rxjs/operators'
import { combineReducers } from 'redux'

import { RootActionType, RootState } from 'duck'
import panelsReducer, { PanelsState, panelsEpics, panelsActions } from './Panels'
import mixImageProjectReducer, { ProjectMixState, projectMixEpics } from './ProjectMix'
import extraAttributeReducer, { ExtraAttributeState, extraAttributesEpic } from './ExtraAttributes'
import mixImageReducer, { MixImageState, mixImageEpics, mixImageSelectors } from './MixImage'
import { apiSelectors } from 'duck/ApiDuck'
import MixPanelUtils, { DataUtils, MixDoActionType } from 'utils/MixPanelUtils'
import { MixPageTabType, MixSidebarTabType } from './Models'

// Constants
const NAMESPACE = '@@page/MixImagePanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const actions = {
  setSelectedGenre: createAction(creator('SET_SELECTED_GENRE'))<number>(),
  setPageTab: createAction(creator('SET_MIX_TAB'))<MixPageTabType>(),
  setSidebarTab: createAction(creator('SET_SIDEBAR_TAB'))<MixSidebarTabType>(),
  logUpdateAnchor: createAction(creator('LOG_UPDATE_ANCHOR'))<{
    update_source: MixPageTabType | 'saved_sidebar' | 'mix_page_drag' | 'saved_sidebar_drag' | ''
    update_type: 'removeAnchor' | 'addAnchor' | 'swapAnchor' | 'replaceAnchor'
  }>(),
  logDoAction: createAction(creator('LOG_DO_ACTION'))<{ actionType: MixDoActionType }>()
}

// Selectors
const selectMixImage = (state: RootState) => state.container.mixImagePanel.root
const selectSidebarTab = createSelector(selectMixImage, mixImage => mixImage.sidebarTab)

export const selectSelectedGenre = createSelector(
  selectMixImage,
  mixImage => mixImage.selectedGenre
)
const selectPageTab = createSelector(selectMixImage, mixImage => mixImage.pageTab)

export const selectors = {
  mixImage: selectMixImage,
  sidebarTab: selectSidebarTab,
  selectedGenre: selectSelectedGenre,
  pageTab: selectPageTab
}
// Reducer
export type MixImageRootState = {
  selectedGenre: number
  sidebarTab: MixSidebarTabType
  pageTab: MixPageTabType
}

export const initial: MixImageRootState = {
  sidebarTab: 'preview',
  selectedGenre: 0,
  pageTab: 'mix'
}

const reducer = produce((state: MixImageRootState, { type, payload }) => {
  switch (type) {
    case getType(actions.setSidebarTab): {
      const typedPayload = payload as ActionType<typeof actions.setSidebarTab>['payload']

      state.sidebarTab = typedPayload
      return
    }
    case getType(actions.setPageTab): {
      const typedPayload = payload as ActionType<typeof actions.setPageTab>['payload']

      state.pageTab = typedPayload
      return
    }
    case getType(actions.setSelectedGenre): {
      const typedPayload = payload as ActionType<typeof actions.setSelectedGenre>['payload']

      state.selectedGenre = typedPayload
      return
    }
    default:
  }
}, initial)

// Epics
type MixImageType = RootActionType | ActionType<typeof actions>

const logUpdateAnchor: Epic<MixImageType, MixImageType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.logUpdateAnchor)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      update_source: action.payload.update_source,
      update_type: action.payload.update_type,
      mixGenreData: apiSelectors.mixImageGenreData(state),
      currentProject: apiSelectors.currentProject(state),
      currentMixProject: apiSelectors.currentMixImageProject(state),
      source: mixImageSelectors.source(state)
    })),
    tap(
      ({ mixGenreData, source, currentMixProject, currentProject, update_source, update_type }) => {
        const projectData =
          source === 'standalone'
            ? DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
                mixProject: currentMixProject,
                mixGenreData
              })
            : DataUtils.getProjectParam<'training_project'>('training_project', {
                trainProject: currentProject
              })

        MixPanelUtils.track<'PROJECT__MIX_UPDATE_ANCHOR'>('Project Mix - Update Anchor', {
          ...projectData,
          anchor_change_mode: update_type,
          anchor_change_source: update_source
        })
      }
    ),
    ignoreElements()
  )

const logDoAction: Epic<MixImageType, MixImageType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.logDoAction)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      actionType: action.payload.actionType,
      mixGenreData: apiSelectors.mixImageGenreData(state),
      currentProject: apiSelectors.currentProject(state),
      currentMixProject: apiSelectors.currentMixImageProject(state),
      source: mixImageSelectors.source(state)
    })),
    tap(({ mixGenreData, source, currentMixProject, currentProject, actionType }) => {
      const projectData =
        source === 'standalone'
          ? DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
              mixProject: currentMixProject,
              mixGenreData
            })
          : DataUtils.getProjectParam<'training_project'>('training_project', {
              trainProject: currentProject
            })

      MixPanelUtils.track<'PROJECT__MIX_DO_ACTION'>('Project Mix - Do Action', {
        ...projectData,
        do_action_type: actionType
      })
    }),
    ignoreElements()
  )

const selectedItemEpic: Epic<MixImageType, MixImageType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([panelsActions.explore.setSelectedImage, panelsActions.setSelectedImage])),
    map(() => actions.setSidebarTab('preview'))
  )

export const epics = combineEpics(
  logDoAction,
  selectedItemEpic,
  logUpdateAnchor,
  mixImageEpics,
  extraAttributesEpic,
  projectMixEpics,
  panelsEpics
)

export type MixImagePanelState = {
  root: MixImageRootState
  extraAttribute: ExtraAttributeState
  mixImage: MixImageState
  projectMix: ProjectMixState
  panels: PanelsState
}

export default combineReducers<MixImagePanelState>({
  root: reducer,
  panels: panelsReducer,
  mixImage: mixImageReducer,
  extraAttribute: extraAttributeReducer,
  projectMix: mixImageProjectReducer
})
