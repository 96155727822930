import React, { useEffect, useState } from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import clsx from 'clsx'

import { firebaseActions, firebaseSelectors } from 'duck/FirebaseDuck'
import Loading from 'components/Loading'
import { RootState, RootActionType } from 'duck'
import Validator, { VALIDATION_MESSAGES } from 'utils/Validator'
import { appSelectors } from 'duck/AppDuck'
import { useDelayedRender, useBreakpoint } from 'utils/Hooks'
import SigninForm from './SigninForm'
import {
  CheckEmailContent,
  ResendEmailContent,
  CheckResendEmailContent,
  LoginError,
  AccountExist
} from './Components'
import styles from './Signin.module.scss'
import { ReduxProps } from 'utils/Types'
import { apiSelectors } from 'duck/ApiDuck'

const mapStateToProps = (state: RootState) => ({
  firebaseErr: firebaseSelectors.firebaseErr(state),
  pendingCredential: firebaseSelectors.pendingCredential(state),
  isUserLoading: appSelectors.isUserLoading(state),
  isLoading: firebaseSelectors.isLoading(state),
  currentEmail: firebaseSelectors.currentEmail(state),
  phoneValidationCreditText: apiSelectors.phoneValidationCreditText(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      requestLogin: firebaseActions.requestLogin,
      setPendingCredential: firebaseActions.setPendingCredential,
      setError: firebaseActions.setError,
      requestAuth: firebaseActions.requestAuth,
      signinWith: firebaseActions.signinWith
    },
    dispatch
  )

export type SigninPanelProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  isDialogMode?: boolean
}

export type SigninModalStatus =
  | 'EMAIL_FORM'
  | 'CHECK_EMAIL'
  | 'RESEND_EMAIL'
  | 'CHECK_RESEND_EMAIL'
  | 'ERROR'
  | 'ACCOUNT_EXIST'

const SigninPanel: React.FC<SigninPanelProps> = props => {
  const {
    pendingCredential,
    firebaseErr,
    isLoading,
    isUserLoading,
    isDialogMode = false,
    currentEmail,
    phoneValidationCreditText,
    signinWith,
    requestAuth,
    requestLogin,
    setError,
    setPendingCredential
  } = props
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [signinModalStatus, setSigninModalStatus] = useState<SigninModalStatus>('EMAIL_FORM')
  const shouldRender = useDelayedRender(1)
  const { isSmallscreenAbove } = useBreakpoint()

  useEffect(() => {
    requestAuth()
  }, [requestAuth])

  useEffect(() => {
    const emailValid = Validator.email(email)
    if (email && !emailValid) {
      setEmailError(VALIDATION_MESSAGES.email)
    } else {
      setEmailError('')
    }
  }, [email])

  useEffect(() => {
    if (pendingCredential) {
      setSigninModalStatus('ACCOUNT_EXIST')
    } else if (firebaseErr) {
      setSigninModalStatus('ERROR')
    }
  }, [firebaseErr, pendingCredential])

  if (!shouldRender) return null /* Render delayed to get real breakpoint */

  return (
    <>
      {isLoading || isUserLoading ? (
        <div className={clsx('mx-auto my-9 flex flex-col', styles.SigninBox)}>
          <div className={styles.LoadingContainer}>
            <Loading />
          </div>
        </div>
      ) : (
        <>
          {signinModalStatus === 'EMAIL_FORM' ? (
            <SigninForm
              phoneValidationCreditText={phoneValidationCreditText}
              email={email}
              requestLogin={() => {
                setSigninModalStatus('CHECK_EMAIL')
                requestLogin({ email })
              }}
              signinWith={signinWith}
              isDialogMode={isDialogMode}
              setEmail={setEmail}
              emailError={emailError}
              firebaseErr={firebaseErr}
              isSmallscreenAbove={isSmallscreenAbove}
            />
          ) : null}
          {signinModalStatus === 'CHECK_EMAIL' ? (
            <CheckEmailContent
              isDialogMode={isDialogMode}
              setNeedHelp={() => setSigninModalStatus('RESEND_EMAIL')}
              goBack={() => {
                setSigninModalStatus('EMAIL_FORM')
              }}
              email={email || currentEmail || ''}
            />
          ) : null}
          {signinModalStatus === 'RESEND_EMAIL' ? (
            <ResendEmailContent
              isDialogMode={isDialogMode}
              handleResendEmail={() => {
                setSigninModalStatus('CHECK_RESEND_EMAIL')
                requestLogin({ email: email || currentEmail || '' })
              }}
              goBack={() => setSigninModalStatus('CHECK_EMAIL')}
            />
          ) : null}
          {signinModalStatus === 'CHECK_RESEND_EMAIL' ? (
            <CheckResendEmailContent
              isDialogMode={isDialogMode}
              setNeedHelp={() => setSigninModalStatus('RESEND_EMAIL')}
              goBack={() => {
                setSigninModalStatus('EMAIL_FORM')
              }}
              email={email || currentEmail || ''}
            />
          ) : null}
          {signinModalStatus === 'ERROR' && firebaseErr ? (
            <LoginError
              isDialogMode={isDialogMode}
              goBack={() => {
                setError({ error: null, action: 'signin-panel' })
                setSigninModalStatus('EMAIL_FORM')
              }}
              error={firebaseErr}
            />
          ) : null}
          {signinModalStatus === 'ACCOUNT_EXIST' && pendingCredential ? (
            <AccountExist
              setSigninModalStatus={setSigninModalStatus}
              requestLogin={requestLogin}
              signinWith={signinWith}
              isDialogMode={isDialogMode}
              goBack={() => {
                setError({ error: null, action: 'signin-panel' })
                setPendingCredential(undefined)
                setSigninModalStatus('EMAIL_FORM')
              }}
              pendingCredential={pendingCredential}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninPanel)
