import React from 'react'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'
import styles from './Components.module.scss'
import { RootActionType, RootState } from 'duck'
import { connect } from 'react-redux'
import { apiSelectors } from 'duck/ApiDuck/selectors'
import { ReduxProps } from 'utils/Types'
import { BraintreePaymentMethod } from 'models/ApiModels'
import { useBreakpoint } from 'utils/Hooks'
import { bindActionCreators, Dispatch } from 'redux'
import { brainTreePaymentActions } from '../duck/BraintreePayment'

const Utils = {
  getPaymentMethodText: (paymentMethod?: BraintreePaymentMethod | null) => {
    if (paymentMethod?.type === 'creditcard') {
      return (
        <div className="ml-2 block">
          <Typography variant="body2">
            <b>{'Credit Card'}</b>
          </Typography>
          <Typography variant="body2">{`${paymentMethod?.card_type} •••• ${paymentMethod?.masked_number?.substring(
            paymentMethod?.masked_number?.length - 4
          )}`}</Typography>
        </div>
      )
    }
    if (paymentMethod?.type === 'paypal') {
      return (
        <div>
          <Typography variant="body2">
            <b>{'Paypal'}</b>
          </Typography>
          <Typography variant="body2">{paymentMethod?.masked_number}</Typography>
        </div>
      )
    }
    return (
      <div>
        <Typography variant="body2">
          <i>You have no payment method added</i>
        </Typography>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  brainTreePaymentMethod: apiSelectors.brainTreePaymentMethod(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      setChangePaymentMethod: brainTreePaymentActions.setChangePaymentMethod
    },
    dispatch
  )

type DialogSaveCardProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  onClickChangePaymentMethod?: () => void
  className?: string
  changeButtonText?: string
}

const CurrentPaymentMethods: React.FC<DialogSaveCardProps> = ({
  brainTreePaymentMethod,
  className,
  changeButtonText,
  setChangePaymentMethod,
  onClickChangePaymentMethod
}) => {
  const { isSmallscreenAbove } = useBreakpoint()

  const changeButtonTextDefault = brainTreePaymentMethod?.type
    ? 'Change Payment Method'
    : 'Add Payment Method'

  return (
    <div className={className}>
      <div>
        <Typography variant="overline">Current Payment Method</Typography>
      </div>
      <div className={styles.CurrentPaymentMethod}>
        {Utils.getPaymentMethodText(brainTreePaymentMethod)}
      </div>
      <Button
        onClick={() => {
          setChangePaymentMethod(true)
          onClickChangePaymentMethod?.()
        }}
        color={'secondary'}
        size={!isSmallscreenAbove ? 'small' : undefined}
      >
        <Typography variant={isSmallscreenAbove ? 'button' : 'overline'}>
          {changeButtonText || changeButtonTextDefault}
        </Typography>
      </Button>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPaymentMethods)
