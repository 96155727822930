import { createTheme } from '@mui/material/styles'

const globalMuiClassNames = {
  active: '.Mui-active',
  checked: '.Mui-checked',
  completed: '.Mui-completed',
  disabled: '.Mui-disabled',
  error: '.Mui-error',
  expanded: '.Mui-expanded',
  focusVisible: '.Mui-focusVisible',
  focused: '.Mui-focused',
  required: '.Mui-required',
  selected: '.Mui-selected'
}
export const fontSizeBase = 16
export const convertPixelToRem = (value: number) => {
  return value / fontSizeBase + 'rem'
}
const convertPixelToLineHeight = (value: number, fontSize: number) => {
  return value / fontSize
}

export const primary = {
  main: '#121212',
  light: '#383838',
  dark: '#000000',
  contrastText: 'rgba(255, 255, 255, 0.87)'
}

export const secondary = {
  main: '#f9bd19',
  light: '#383838',
  dark: '#c18d00',
  contrastText: '#000000',
  yellow: '#c5dd2e'
}

export const color = {
  colorPrimary: 'rgba(255, 255, 255, 0.87)',
  colorSecondary: '#f9bd19',
  colorSecondaryHover2: '#f9b723',
  colorSecondaryHover:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), rgba(249, 189, 25, 0.12);',
  medWhiteTextOnDark: 'rgba(255, 255, 255, 0.6)',
  hiWhiteTextOnDark: 'rgba(255, 255, 255, 0.87)',
  disabledWhiteText: 'rgba(255, 255, 255, 0.38)',
  primary: {
    main: 'rgba(255, 255, 255, 0.87)',
    light: 'rgba(255, 255, 255, 0.6)',
    dark: 'rgba(255, 255, 255, 0.6)',

    //
    hover: 'rgba(249, 189, 25, 0.04)',
    hoverText: 'rgba(249, 189, 25, 0.88)',
    focus: 'rgba(249, 189, 25, 0.12)',
    overlay: 'rgba(249, 189, 25, 0.24)',
    selectedOverlay: 'rgba(249, 189, 25, 0.08)',
    ripple: 'rgba(249, 189, 25, 0.1)',
    disabledColor: 'rgba(255, 255, 255, 0.38)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)'
  },
  secondary: {
    main: '#f9bd19',
    light: '#f9b723',
    dark: '#f9b723'
  },
  error: {
    main: '#F3798F',
    light: '#C9546A',
    dark: '#C9546A'
  },
  success: {
    main: '#c5dd2e',
    light: '#B7CE22',
    dark: '#B7CE22'
  },
  text: {
    primary: 'rgba(255, 255, 255, 0.87)',
    secondary: 'rgba(255, 255, 255, 0.6)',
    // icon: ,
    disabled: 'rgba(255, 255, 255, 0.38)'
  },
  selectedOverlay: 'rgba(249, 189, 25, 0.24)',
  surfaces: {
    background: '#121212',
    backgroundLight: '#272727',
    dialog: '#383838'
  },
  black: '#000000'
}

export const border = {
  onSurface: '1px solid rgba(255, 255, 255, 0.12)',
  secondary: `1px solid ${secondary.main}`,
  medWhiteTextOnDark: `1px solid ${color.medWhiteTextOnDark}`
}

export const error = {
  main: '#F3798F'
}

export const typography = {
  fontFamily: 'Space Grotesk',
  fontWeightLight: 300,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontWeightRegular: 400,
  h1: {
    // fontSize: '96px',
    lineHeight: convertPixelToLineHeight(112, 96),
    letterSpacing: convertPixelToRem(-1.5),
    fontWeight: 300
  },
  h2: {
    // fontSize: '60px',
    lineHeight: convertPixelToLineHeight(72, 60),
    letterSpacing: convertPixelToRem(-0.5),
    fontWeight: 300
  },
  h3: {
    // fontSize: '48px',
    // lineHeight: '56px'
  },
  h4: {
    // fontSize: '34px',
    lineHeight: convertPixelToLineHeight(34, 36)
  },
  h5: {
    // fontSize: '24px',
    lineHeight: 1,
    letterSpacing: convertPixelToRem(0.18)
  },
  h6: {
    // fontSize: '20px',
    lineHeight: convertPixelToLineHeight(24, 20),
    letterSpacing: convertPixelToRem(0.15),
    fontWeight: 500
  },
  subtitle1: {
    // fontSize: '16px',
    // lineHeight: '24px',
    lineHeight: convertPixelToLineHeight(24, 16),
    letterSpacing: convertPixelToRem(0.15)
  },
  subtitle2: {
    // fontSize: '14px',
    lineHeight: convertPixelToLineHeight(24, 14),
    letterSpacing: convertPixelToRem(0.13),
    fontWeight: 500
  },
  body1: {
    // fontSize: '16px',
    // lineHeight: '24px',
    lineHeight: convertPixelToLineHeight(24, 16),
    letterSpacing: convertPixelToRem(0.5)
  },
  body2: {
    // fontSize: '14px',
    // lineHeight: '20px'
    lineHeight: convertPixelToLineHeight(20, 14),
    letterSpacing: convertPixelToRem(0.25)
  },
  button: {
    fontSize: 14,
    // lineHeight: '16px',
    lineHeight: convertPixelToLineHeight(16, 14),
    letterSpacing: convertPixelToRem(1.25),
    fontWeight: 500
  },
  caption: {
    // fontSize: '12px',
    // lineHeight: '16px',
    lineHeight: convertPixelToLineHeight(16, 12),
    letterSpacing: convertPixelToRem(0.4)
  },
  overline: {
    fontSize: convertPixelToRem(10),
    lineHeight: convertPixelToLineHeight(16, 10),
    letterSpacing: convertPixelToRem(1.5),
    fontWeight: 500
  }
}
/* Because ThemeOptions type object doesn't allow add keyframe variable 
   So, it's how to work around id. 
*/
// const createThemeExtended = (options?: any) => {
//   return createTheme(options as ThemeOptions)
// }

/*
  Default Theme
  https://mui.com/customization/default-theme/
*/
const playformTheme = createTheme({
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920
    }
  },
  palette: {
    mode: 'dark',
    primary: color.primary,
    secondary: color.secondary,
    error: color.error,
    text: color.text,
    success: color.success,
    divider: 'rgba(255, 255, 255, 0.12)'
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        // color: 'transparent'
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableTouchRipple: true // No more ripple, on the whole application 💣!
      }
      // styleOverrides: {
      //   root: {
      //   }
      // }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          touchAction: 'manipulation',
          textTransform: 'none',
          ...typography.button
          // textTransform: 'uppercase'
          // '&$disabled': {
          //   color: 'rgba(255, 255, 255, 0.18)'
          // }
        },
        sizeSmall: {
          padding: convertPixelToRem(10)
          // lineHeight: 16 / 14 // convertPixelToLineHeight(16, 14)
        },
        sizeMedium: {
          padding: convertPixelToRem(16)
        },
        sizeLarge: {
          padding: `${convertPixelToRem(28)} ${convertPixelToRem(16)}`
          // lineHeight: 16 / 15 // convertPixelToLineHeight(16, 14)
        },
        outlinedSizeSmall: {
          padding: convertPixelToRem(9)
        },
        outlinedSizeMedium: {
          padding: convertPixelToRem(15)
        },
        outlinedSizeLarge: {
          padding: `${convertPixelToRem(27)} ${convertPixelToRem(15)}`
        },
        //
        contained: {
          color: 'rgba(0, 0, 0, 0.87)'
        },
        //
        startIcon: {
          '>*:nth-of-type(1)': {
            fontSize: convertPixelToRem(24)
          },
          // To make icon align with text
          marginTop: -4,
          marginBottom: -4
        },
        endIcon: {
          '>*:nth-of-type(1)': {
            fontSize: convertPixelToRem(24)
          },
          // To make icon align with text
          marginTop: -4,
          marginBottom: -4
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 2000
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: primary.contrastText
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: primary.main
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...typography.body2,
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
          border: border.onSurface,
          borderRadius: 0,
          color: primary.contrastText
        },
        deleteIcon: {
          color: 'rgba(255, 255, 255, 0.6)',
          width: convertPixelToRem(15),
          height: convertPixelToRem(15)
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: color.colorPrimary
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 8,
          fontSize: convertPixelToRem(14)
        }
      }
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       'MuiInput-root:hover:not($disabled):before': {
    //         background: '#f00',
    //         borderBottom: '1px solid rgba(255, 255, 255, 0.6)'
    //       }
    //     }
    //   }
    // },
    MuiInput: {
      styleOverrides: {
        underline: {
          color: primary.contrastText,
          // paddingBottom: convertPixelToRem(8),
          '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.12)'
          },
          [`&:hover:not(${globalMuiClassNames.disabled}):before`]: {
            borderBottom: '1px solid rgba(255, 255, 255, 0.6)'
          }
          // '&:after': {
          //   borderBottom: `2px solid ${color.colorPrimary}`,
          //   backfaceVisibility: 'hidden'
          // }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.07)'
          // '&$disabled': {
          //   background: 'none',
          //   backgroundColor: 'transparent'
          // }
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: 'none'
          }
        }
        // underline: {
        //   '&:before': {
        //     borderBottom: '1px solid rgba(255, 255, 255, 0.6)'
        //   },
        //   '&:after': {
        //     borderBottom: `2px solid ${color.hiWhiteTextOnDark}`,
        //     backfaceVisibility: 'hidden'
        //   },
        //   '&:hover:before': {
        //     borderBottom: `1px solid rgba(255, 255, 255, 0.6)`
        //   },
        //   '&$focused:before': {
        //     borderBottom: `2px solid ${color.hiWhiteTextOnDark}`
        //   },
        //   '&$disabled:before': {
        //     borderBottomStyle: 'none',
        //     border: 'none'
        //   }
        // }
        // secondary: {
        //   '&$underline:after': {
        //     borderBottom: `2px solid ${color.colorSecondary}`
        //   },
        //   '&$focused:before': {
        //     borderBottom: `2px solid ${color.colorSecondary}`
        //   }
        // }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '2px'
        },
        colorSecondary: {
          backgroundColor: 'rgba(249, 189, 25, 0.2)'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          height: '100%'
        }
        // scrollable: {
        //   overflowY: 'hidden',
        //   overscrollBehaviorX: 'auto'
        // }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: convertPixelToRem(14),
          lineHeight: convertPixelToRem(16),
          letterSpacing: '1.25px',
          textTransform: 'uppercase'
        },
        textColorInherit: {
          '&$disabled': {
            opacity: '0.12'
          }
        },
        textColorSecondary: {
          color: secondary.main
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: convertPixelToRem(12),
          backgroundColor: color.surfaces.dialog,
          border: border.onSurface,
          color: color.medWhiteTextOnDark,
          fontSize: '0.75em',
          lineHeight: 1
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
          //
          // 'caret-color': secondary.main,
          // '&$notchedOutline': {
          //   borderColor: '#383838'
          // }
          // [`&:hover:not(${globalMuiClassNames.disabled}):not(${globalMuiClassNames.focused})
          //:not(${globalMuiClassNames.error}) .MuiOutlinedInput-notchedOutline`]:
          [`&:hover .MuiOutlinedInput-notchedOutline`]: {
            borderColor: color.disabledWhiteText
          },
          [`&${globalMuiClassNames.focused} .MuiOutlinedInput-notchedOutline`]: {
            borderColor: color.disabledWhiteText,
            borderWidth: 1
          }
        },
        colorSecondary: {
          [`&:hover .MuiOutlinedInput-notchedOutline`]: {
            borderColor: color.secondary.main
          },
          [`&${globalMuiClassNames.focused} .MuiOutlinedInput-notchedOutline`]: {
            borderColor: color.secondary.main
          }
        },
        notchedOutline: {
          borderColor: '#383838'
        }
      }
    },
    // MuiTouchRipple: {
    //   styleOverrides: {
    //     rippleVisible: {
    //       opacity: 0.1,
    //       animation: `mui-ripple-enter 550ms ease-in-out`,
    //       animationName: '$mui-ripple-enter'
    //     }
    //     // '@keyframes mui-ripple-enter': {
    //     //   '0%': {
    //     //     transform: 'scale(0)',
    //     //     opacity: 0.01
    //     //   },
    //     //   '100%': {
    //     //     transform: 'scale(1)',
    //     //     opacity: 0.1
    //     //   }
    //     // }
    //   }
    // },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: convertPixelToRem(6)
        },
        sizeMedium: {
          padding: convertPixelToRem(12)
        },
        sizeLarge: {
          padding: convertPixelToRem(24)
        },
        colorPrimary: {
          color: undefined
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: color.disabledWhiteText
          // '&$focused': {
          //   color: color.colorSecondary
          // }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          [`&${globalMuiClassNames.focused}`]: {
            color: color.medWhiteTextOnDark
          }
        },
        colorSecondary: {
          [`&${globalMuiClassNames.focused}`]: {
            color: color.secondary.main
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: color.surfaces.backgroundLight
        },
        paperWidthSm: {
          maxWidth: 560
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: color.medWhiteTextOnDark
        },
        colorPrimary: {
          color: color.hiWhiteTextOnDark,
          '&$checked': {
            color: color.hiWhiteTextOnDark
          }
        },
        colorSecondary: {
          color: secondary.main
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiInput-input': {
            paddingRight: convertPixelToRem(28)
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          padding: `${convertPixelToRem(5)} 0`,
          height: convertPixelToRem(2)
          // color: color.medWhiteTextOnDark,
          // '&.Mui-disabled': {
          //   opacity: 0.38,
          //   color: undefined
          // }
        },
        rail: {
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
          opacity: 'unset'
        },
        thumb: {
          width: convertPixelToRem(12),
          height: convertPixelToRem(12)
        },
        thumbColorPrimary: {
          backgroundColor: '#ffffff'
        },
        colorSecondary: {
          // '& .MuiSlider-track': {
          //   opacity: '0.5'
          // }
        }
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary'
      }
    }
  },
  shape: {
    borderRadius: 0
  }
})

export default playformTheme
