import React from 'react'
import clsx from 'clsx'
import SvgIcon from '@mui/material/SvgIcon'
import { IconProps } from './index'

const FreeformIcon: React.FC<IconProps> = ({ className, alt }) => {
  return (
    <SvgIcon className={clsx(className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM13.2 4.8a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0ZM6.6 7.2a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2ZM20.4 12a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0ZM4.8 13.2a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4ZM18 6.6a.6.6 0 1 1-1.2 0 .6.6 0 0 1 1.2 0ZM17.4 18a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Zm-4.2 1.2a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0ZM6.6 18a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2Zm4.8-8.4a1.8 1.8 0 1 1-3.6 0 1.8 1.8 0 0 1 3.6 0Zm-1.8 6.6a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6Zm6.6-1.8a1.8 1.8 0 1 1-3.6 0 1.8 1.8 0 0 1 3.6 0Zm-1.8-3a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6Z"
      />
    </SvgIcon>
  )
}

export default FreeformIcon
