import SelectAllIcon from '@mui/icons-material/SelectAll'
import GestureIcon from '@mui/icons-material/Gesture'
import ArrowBulbIcon from 'components/Icon/ArrowBulbIcon'
import RoundRoundIcon from 'components/Icon/RoundRoundIcon'

import { ProcessGroup } from './duck'

/* Constants */

export const PROCESS_GROUP_MIX: ProcessGroup = {
  key: 'mix',
  title: 'Mix',
  subtitle: 'Explore vast latent spaces',
  icon: SelectAllIcon
}

export const PROCESS_GROUP_STYLIZE: ProcessGroup = {
  key: 'style_transfer',
  title: 'Stylize Filter',
  subtitle: 'Transform an uploaded image to replicate any style of your choice',
  icon: RoundRoundIcon
}

export const PROCESS_GROUP_SKETCH: ProcessGroup = {
  key: 'sketch',
  title: 'Sketch',
  subtitle: 'Instantly create artworks',
  icon: GestureIcon
}

export const PROCESS_GROUP_TRAIN: ProcessGroup = {
  key: 'train',
  title: 'Train a Model',
  subtitle: 'Teach an AI using your own images',
  icon: ArrowBulbIcon
}

export const PROCESS_GROUP_DATA: ProcessGroup[] = [
  PROCESS_GROUP_MIX,
  PROCESS_GROUP_SKETCH,
  PROCESS_GROUP_TRAIN
]
