// import Bowser from 'bowser'
import * as ReactDeviceDetect from 'react-device-detect'

/*
  Bowser Output example:
  {
  browser: {
    name: "Internet Explorer"
    version: "11.0"
  },
  os: {
    name: "Windows"
    version: "NT 6.3"
    versionName: "8.1"
  },
  platform: {
    type: "desktop"
  },
  engine: {
    name: "Trident"
    version: "7.0"
  }
}
*/

const getData = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  // const BowserResult = Bowser.getParser(window.navigator.userAgent)
  // const BowserResultParsed = BowserResult.getResult()

  const Platform = {
    // ...BowserResultParsed,
    // bowser: BowserResult,
    ...ReactDeviceDetect,
    isOnline: navigator.onLine,
    // isChrome: /chrome/.test(userAgent),
    // isExplorer: /msie/.test(userAgent),
    isIE11: /rv:11/.test(userAgent),
    // isFirefox: /firefox/.test(userAgent),
    // isSafari: /safari/.test(userAgent),
    isOpera: /opr/.test(userAgent),
    // isEdgeDesktop: /edge/.test(userAgent),
    // isEdgeiOS: /edgios/.test(userAgent),
    // isEdgeAndroid: /edga/.test(userAgent),
    // isIOS: /ipad|iphone|ipod/.test(userAgent),
    // isMobile: /mobile/.test(userAgent),
    isDarkMode: false,
    isStandalone: false,
    whereIsShare: '',
    isTouchDevice: () => {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0
    }
  }

  // if (Platform.isChrome && Platform.isSafari) {
  //   Platform.isSafari = false
  // }

  // if (
  //   Platform.isChrome &&
  //   (Platform.isEdgeDesktop || Platform.isEdgeiOS || Platform.isEdgeAndroid)
  // ) {
  //   Platform.isChrome = false
  // }

  // if (
  //   Platform.isSafari &&
  //   (Platform.isEdgeDesktop || Platform.isEdgeiOS || Platform.isEdgeAndroid)
  // ) {
  //   Platform.isSafari = false
  // }

  // if (Platform.isChrome && Platform.isOpera) {
  //   Platform.isChrome = false
  // }

  if (/ipad/.test(userAgent)) {
    Platform.whereIsShare = 'top'
  }

  if (window.matchMedia('(display-mode: standalone)').matches) Platform.isStandalone = true
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) Platform.isDarkMode = true

  return Platform
}

const Platform = getData()

export default Platform
