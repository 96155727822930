import React, { useState, useEffect, useCallback } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import _toNumber from 'lodash/toNumber'
import Dialog from 'components/Dialog'
import UpscaleDetailDesktop from './UpscaleDetailDesktop'
import UpscaleDetailMobile from './UpscaleDetailMobile'
import { useBreakpoint } from 'utils/Hooks'
import { selectors, actions } from '../duck'
import { RootState, RootActionType } from 'duck'
import { values } from 'appConstants'
import UpscaleGenerate from '../UpscaleGenerate'
import UpscaleDetailContent from './UpscaleDetailContent'
import { UpscalesParamType } from 'routes'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  openedUpscaleId: selectors.openedUpscaleId(state),
  showEditPanel: selectors.showEditPanel(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      showEdit: actions.upscaleList.showEdit,
      setOpenedUpscaleImage: actions.upscaleList.setOpenedUpscaleImage,
      setShowEditPanel: actions.upscaleList.setShowEditPanel
    },
    dispatch
  )

export type UpscaleDetailProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  upscaleParam?: UpscalesParamType
  onClickEditUpscale: (upscaleId: number) => void
  onClose?: () => void
  onCloseEdit?: () => void
}

const UpscaleDetail: React.FC<UpscaleDetailProps> = props => {
  const {
    showEditPanel,
    openedUpscaleId,
    upscaleParam,
    onCloseEdit,
    onClose,
    showEdit,
    setOpenedUpscaleImage,
    setShowEditPanel,
    onClickEditUpscale
  } = props
  const [open, setOpen] = useState<boolean>(Boolean(openedUpscaleId))

  const id = _toNumber(upscaleParam?.id)
  const subRoute = upscaleParam?.subRoute

  useEffect(() => {
    setOpenedUpscaleImage(id)
  }, [id, setOpenedUpscaleImage])

  useEffect(() => {
    if (id && subRoute === 'edit') {
      showEdit({ upscaleImageId: id })
    }

    if (id && subRoute !== 'edit') {
      showEdit({ upscaleImageId: undefined })
    }
  }, [id, showEdit, subRoute])

  const { isSmallscreenAbove } = useBreakpoint()

  useEffect(() => {
    if (openedUpscaleId) {
      setOpen(Boolean(openedUpscaleId))
    }
  }, [openedUpscaleId])

  const onClickClose = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      onClose?.()
      setOpenedUpscaleImage(undefined)
    }, values.DIALOG_TRANSITION_DURATION)
  }, [onClose, setOpenedUpscaleImage])

  return (
    <>
      {isSmallscreenAbove ? (
        <UpscaleDetailDesktop onClose={onClickClose} open={open}>
          <UpscaleDetailContent onClickEditUpscale={onClickEditUpscale} />
        </UpscaleDetailDesktop>
      ) : (
        <UpscaleDetailMobile open={open} onClose={onClickClose}>
          <UpscaleDetailContent onClickEditUpscale={onClickEditUpscale} />
        </UpscaleDetailMobile>
      )}

      <Dialog
        open={showEditPanel}
        transition="slideLeft"
        maxWidth={false}
        disableContentSpacing
        fullScreen
      >
        <UpscaleGenerate
          onClose={() => {
            onCloseEdit?.()
            setShowEditPanel({ imageId: undefined })
          }}
          hideListLink
        />
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpscaleDetail)
