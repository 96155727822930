import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import _compact from 'lodash/compact'
import { withLatestFrom, map, filter, mergeMap, concatMap, startWith, take } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { apiSelectors, apiActions, sharedActions } from 'duck/ApiDuck'
import { Post, Comment, Thread } from 'models/ApiModels'
import { commentUtils } from 'utils/DataProcessingUtils'
import { merge, of } from 'rxjs'

// Constants
const NAMESPACE = '@@panel/SocialPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// -- Type Definition
export type CollectionScopeType = {
  collectionId: number
  isPublic: boolean
}

export type FeedDetailData = {
  postId: number
  postData?: Post
}

// Actions
export const actions = {
  setFeedDetailData: createAction(creator('SET_FEED_DETAIL_DATA'))<FeedDetailData | undefined>(),
  redirectToPostPage: createAction(creator('REDIRECT_TO_POST_PAGE'))<number>(),
  feedDetailActions: {
    openCollection: createAction(creator('FEED_DETAIL_ACTIONS/OPEN_COLLECTION'))<
      number | undefined
    >()
  },
  commentActions: {
    submitComment: createAction(creator('COMMENT_ACTIONS/SUBMIT_COMMENT'))(),
    resetCommentData: createAction(creator('COMMENT_ACTIONS/RESET_COMMENT_DATA'))(),
    updateCommentBody: createAction(creator('COMMENT_ACTIONS/UPDATE_COMMENT_BODY'))<string>(),
    setReplyTo: createAction(creator('COMMENT_ACTIONS/SET_REPLY_TO'))<
      Pick<CommentDataType, 'reply_to' | 'thread'>
    >(),
    loadThread: createAction(creator('COMMENT_ACTIONS/LOAD_THREAD'))<{
      reloadList: boolean
    }>(),
    loadMoreComment: createAction(creator('COMMENT_ACTIONS/LOAD_MORE_COMMENT'))<{
      thread: number
    }>(),
    setError: createAction(creator('COMMENT_ACTIONS/SET_ERROR'))<boolean>()
  }
}

// Selectors
const selectSocialPanel = (state: RootState) => state.container.socialPanel

const selectFeedDetailDataRaw = createSelector(
  selectSocialPanel,
  socialPanel => socialPanel.feedDetailData
)
const selecteOpenedCollection = createSelector(
  selectSocialPanel,
  socialPanel => socialPanel.openedCollection
)
const selectFeedDetailData = createSelector(
  selectFeedDetailDataRaw,
  apiSelectors.currentPost,
  (feedDetailData, currentPost) => {
    if (feedDetailData) {
      return {
        ...feedDetailData,
        postData: currentPost
      }
    } else {
      return undefined
    }
  }
)
const selectCommentData = createSelector(
  selectSocialPanel,
  apiSelectors.threads,
  apiSelectors.comments,
  (socialPanel, threads, comments) => {
    const commentData = socialPanel.commentData
    const threadData = threads[commentData.thread || 0]
    const replyToData = comments[commentData.reply_to || 0]
    return { ...socialPanel.commentData, threadData, replyToData }
  }
)

export const selectors = {
  socialPanel: selectSocialPanel,
  feedDetailData: selectFeedDetailData,
  openedCollection: selecteOpenedCollection,
  commentData: selectCommentData
}

// Reducer

export type CommentDataType = {
  thread?: number
  reply_to?: number
  body: string
  threadData?: Thread
  replyToData?: Comment
  previousReplyTo?: number
  error?: boolean
}
export type SocialPanelState = {
  openedCollection?: number
  feedDetailData?: FeedDetailData
  commentData: CommentDataType
  commentDataBackup: CommentDataType
}

const initialCommentData = {
  body: ''
}
const initial: SocialPanelState = {
  openedCollection: undefined,
  feedDetailData: undefined,
  commentData: initialCommentData,
  commentDataBackup: initialCommentData
}

const reducer = produce((state: SocialPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setFeedDetailData): {
      const feedDetailData = payload as ActionType<typeof actions.setFeedDetailData>['payload']

      state.feedDetailData = feedDetailData
      return
    }
    case getType(actions.feedDetailActions.openCollection): {
      const openedCollection = payload as ActionType<
        typeof actions.feedDetailActions.openCollection
      >['payload']

      state.openedCollection = openedCollection
      return
    }
    case getType(actions.commentActions.setReplyTo): {
      const data = payload as ActionType<typeof actions.commentActions.setReplyTo>['payload']

      const prevReplyTo = state.commentData.reply_to
      state.commentData = {
        ...state.commentData,
        ...data,
        previousReplyTo: prevReplyTo || state.commentData.previousReplyTo,
        error: false
      }
      return
    }
    case getType(actions.commentActions.updateCommentBody): {
      const commentBody = payload as ActionType<
        typeof actions.commentActions.updateCommentBody
      >['payload']

      state.commentData.body = commentBody
      state.commentData.error = false
      return
    }
    case getType(actions.commentActions.resetCommentData): {
      state.commentDataBackup = { ...state.commentData }
      state.commentData = initialCommentData
      return
    }
    case getType(actions.commentActions.setError): {
      const error = payload as ActionType<typeof actions.commentActions.setError>['payload']
      state.commentData = { ...state.commentDataBackup, error }
      return
    }
    default:
  }
}, initial)

// Epics

const submitComment: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.commentActions.submitComment)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      ...selectors.commentData(state),
      post: selectors.feedDetailData(state)?.postId
    })),
    filter(({ body }) => Boolean(body)),
    map(commentData => ({
      ...commentData,
      thread: commentData.thread || 0,
      reply_to: commentData.reply_to || undefined,
      post: commentData.post || 0
    })),
    mergeMap(commentData =>
      merge(
        //Reply to thread
        of(commentData).pipe(
          filter(({ thread }) => Boolean(thread)),
          concatMap(({ thread, reply_to, body }) => [
            apiActions.social.createComment({ thread, reply_to, body }),
            actions.commentActions.resetCommentData()
          ])
        ),
        //Reply to post
        of(commentData).pipe(
          filter(({ thread, post }) => !Boolean(thread) && Boolean(post)),
          concatMap(({ body, post }) => [
            apiActions.social.createThread({ post, body }),
            actions.commentActions.resetCommentData()
          ])
        )
      )
    )
  )

const listenSubmitCommentThreadErrorEpic: Epic<
  RootActionType,
  RootActionType,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(sharedActions.setError)),
    filter(
      action =>
        action.payload.type === getType(apiActions.social.createComment) ||
        action.payload.type === getType(apiActions.social.createThread)
    ),
    map(() => actions.commentActions.setError(true))
  )

const setReplyToEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.commentActions.setReplyTo)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      commentData: selectors.commentData(state),
      comments: apiSelectors.comments(state)
    })),
    map(({ commentData, comments }) => {
      const prevComment = comments?.[commentData?.previousReplyTo || 0]
      return { commentData, prevComment }
    }),
    map(({ commentData, prevComment }) =>
      commentUtils.insertMention(commentData.replyToData, commentData.body, prevComment)
    ),
    map(body => actions.commentActions.updateCommentBody(body))
  )

const setFeedDetailDataEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.setFeedDetailData)),
    filter(({ payload }) => Boolean(payload?.postId)),
    mergeMap(({ payload }) => [
      apiActions.social.retrievePost(payload?.postId ?? 0),
      actions.commentActions.loadThread({ reloadList: true }),
      actions.commentActions.resetCommentData()
    ])
  )

const loadMoreComment: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.commentActions.loadMoreComment)),
    filter(({ payload }) => Boolean(payload?.thread)),
    map(({ payload }) =>
      apiActions.social.listComment({
        param: { thread: payload?.thread ?? 0, limit: 4 }
      })
    )
  )
const loadThreadEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.commentActions.loadThread)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      postId: selectors.feedDetailData(state)?.postId,
      reloadList: action.payload.reloadList
    })),
    filter(({ postId }) => Boolean(postId)),
    mergeMap(({ postId, reloadList }) =>
      action$.pipe(
        filter(isActionOf(apiActions.social.listThreadResponse)),
        take(1),
        map(({ payload }) => payload.data.results ?? []),
        map(threads =>
          threads.map(thread => {
            if (thread.comments.length) {
              return apiActions.social.listComment({
                param: {
                  thread: thread.id,
                  offset: 0,
                  limit: 3
                },
                reloadList: true
              })
            }
            return null
          })
        ),
        map(actions => _compact(actions)),
        concatMap(results => results),
        startWith(
          apiActions.social.listThread({
            reloadList,
            param: { post: postId ?? 0 }
          })
        )
      )
    )
  )

export const epics = combineEpics(
  loadThreadEpic,
  setReplyToEpic,
  submitComment,
  listenSubmitCommentThreadErrorEpic,
  loadMoreComment,
  setFeedDetailDataEpic
)
export default reducer
