import { Vector2d, IFrame } from 'konva/lib/types'
import { ImageStateManagerType } from '.'

export type CameraSpeed = {
  x: number
  y: number
  yDir: boolean
  xDir: boolean
}

export type AnimationState = {
  currentTime: number
  cameraSpeed: CameraSpeed
  currentCameraPos: Vector2d
  prevCameraPos: Vector2d
}
export type AnimationStateManagerType = ReturnType<typeof AnimationStateManager>

const INITIAL_CAMERA_SPEED: CameraSpeed = { x: 0, y: 0, yDir: true, xDir: true }
const DECELERATE_RATE = 3

export function AnimationStateManager(param: { imageState: ImageStateManagerType }) {
  const { imageState } = param
  const state: AnimationState = {
    currentTime: 0,
    cameraSpeed: INITIAL_CAMERA_SPEED,
    currentCameraPos: { x: 0, y: 0 },
    prevCameraPos: { x: 0, y: 0 }
  }

  const Utils = {
    cameraSpeed: (data?: Partial<CameraSpeed>) => {
      if (data) {
        state.cameraSpeed = { ...state.cameraSpeed, ...data }
      }
      return state.cameraSpeed
    }
  }

  return {
    updateCameraPosition: (cameraPos: Vector2d) => {
      state.prevCameraPos = { ...state.currentCameraPos }
      state.currentCameraPos = { ...cameraPos }
    },
    triggerMomentum: () => {
      const cameraPosSpeed = {
        x: state.currentCameraPos.x - state.prevCameraPos.x,
        y: state.currentCameraPos.y - state.prevCameraPos.y
      }

      const xSpeed = Math.abs(cameraPosSpeed.x * 25)
      const ySpeed = Math.abs(cameraPosSpeed.y * 25)

      const xDir = cameraPosSpeed.x > 0
      const yDir = cameraPosSpeed.y > 0

      state.cameraSpeed = { x: xSpeed, y: ySpeed, yDir: yDir, xDir: xDir }
    },
    isAnimate: () => {
      return state.cameraSpeed.x > 0 || state.cameraSpeed.y > 0
    },
    currentTime: (time?: number) => {
      if (time) {
        state.currentTime = time
      }
      return state.currentTime
    },
    cameraSpeed: (data?: Partial<CameraSpeed>) => {
      return Utils.cameraSpeed(data)
    },
    resetCameraSpeed: () => {
      state.cameraSpeed = INITIAL_CAMERA_SPEED
    },
    animate: (param: { frame?: IFrame }) => {
      const { frame } = param
      const isAnimate = state.cameraSpeed.x > 0 || state.cameraSpeed.y > 0
      const cameraSpeed = state.cameraSpeed

      if (isAnimate) {
        const timeDiff = frame?.timeDiff || 0
        const currentCamera = imageState.camera()
        if (cameraSpeed.x > 0) {
          const direction = cameraSpeed.xDir ? 1 : -1
          const deltaX = (direction * (cameraSpeed.x * timeDiff)) / 1000
          const currentSpeed = Math.floor(
            cameraSpeed.x - (cameraSpeed.x * DECELERATE_RATE * timeDiff) / 1000
          )

          imageState.camera({ x: currentCamera.x + deltaX })
          Utils.cameraSpeed({ x: currentSpeed > 0 ? currentSpeed : 0 })
        }
        if (cameraSpeed.y > 0) {
          const direction = cameraSpeed.yDir ? 1 : -1
          const deltaY = (direction * (cameraSpeed.y * timeDiff)) / 1000
          const currentSpeed = Math.floor(
            cameraSpeed.y - (cameraSpeed.y * DECELERATE_RATE * timeDiff) / 1000
          )

          imageState.camera({ y: currentCamera.y + deltaY })
          Utils.cameraSpeed({ y: currentSpeed > 0 ? currentSpeed : 0 })
        }
      }
      return isAnimate
    }
  }
}
