import { firebaseActions, SignInProvider } from './actions'

import { ActionType, createReducer } from 'typesafe-actions'
import _filter from 'lodash/filter'
import { ApiUtils } from 'utils'
import { FirebaseError } from 'firebase/app'
import { AuthCredential, UserInfo } from 'firebase/auth'

type Action = ActionType<typeof firebaseActions>

export type FirebaseState = {
  readonly idToken: string | null
  readonly loading: boolean
  readonly currentEmail?: string
  readonly firebaseUser: {
    phoneNumber?: string
    uid?: string
    creationTime?: string
    lastSignInTime?: string
    providerData?: (UserInfo | null)[]
  } | null
  readonly firebaseErr: FirebaseError | null
  readonly isAfterLogout: boolean
  readonly phoneVerificationId?: string
  readonly pendingCredential:
    | {
        currentSignInProvider: SignInProvider
        signinProviders: SignInProvider[]
        email: string
        credential: AuthCredential | undefined
      }
    | undefined
}

const initialState: FirebaseState = {
  currentEmail: undefined,
  loading: true,
  firebaseUser: null,
  firebaseErr: null,
  isAfterLogout: false,
  phoneVerificationId: undefined,
  pendingCredential: undefined,
  idToken: null
}

export const reducer = createReducer<FirebaseState, Action>(initialState)
  .handleAction(firebaseActions.setLoading, (state, action) => ({
    ...state,
    loading: action.payload
  }))
  .handleAction(firebaseActions.setError, (state, action) => ({
    ...state,
    firebaseErr: action.payload.error
  }))
  .handleAction(firebaseActions.requestLogin, (state, action) => ({
    ...state,
    currentEmail: action.payload.email
  }))
  .handleAction(firebaseActions.setFirebaseUser, (state, action) => ({
    ...state,
    firebaseUser: {
      phoneNumber: action.payload?.phoneNumber ?? undefined,
      uid: action.payload?.uid ?? undefined,
      creationTime: action.payload?.metadata?.creationTime,
      lastSignInTime: action.payload?.metadata?.lastSignInTime,
      providerData: action.payload?.providerData
    }
  }))
  .handleAction(firebaseActions.unlinkSignInResponse, (state, action) => ({
    ...state,
    firebaseUser: {
      ...state.firebaseUser,
      providerData: _filter(
        state.firebaseUser?.providerData ?? [],
        provider => provider?.providerId !== action.payload.providerId
      )
    }
  }))
  .handleAction(firebaseActions.setPhoneVerificationId, (state, action) => ({
    ...state,
    phoneVerificationId: action.payload
  }))
  .handleAction(firebaseActions.setPendingCredential, (state, action) => ({
    ...state,
    pendingCredential: action.payload
  }))
  .handleAction(firebaseActions.setIdToken, (state, action) => {
    action.payload && ApiUtils.setAuthCookie(action.payload)
    return {
      ...state,
      idToken: action.payload
    }
  })
  .handleAction(firebaseActions.reset, state => ({
    ...state,
    currentEmail: undefined,
    loading: false,
    firebaseUser: null,
    firebaseErr: null,
    isAfterLogout: true
  }))
