import { useEffect, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'
import _debounce from 'lodash/debounce'
import _throttle from 'lodash/throttle'

const isBrowser = typeof window !== `undefined`

const elementsScroll: any = {}
const DEFAULT_OFFSET_BOTTOM = 150

type useElementScrollType = {
  fpsLimit?: number
  name?: string
  onBottom?: Function
  onBottomThrottled?: Function
  onScroll?: Function
  onScrollX?: Function
  offsetBottom?: number
}

export const useElementScrollInit = (name?: string, ref?: React.Ref<HTMLElement | null>) => {
  const [isInit, setInit] = useState(false)
  useIsomorphicLayoutEffect(() => {
    if (ref && name && !elementsScroll[name]?.current) {
      elementsScroll[name] = ref

      setInit(true)
    }

    return () => {
      if (isInit && name && elementsScroll[name]) {
        delete elementsScroll[name]
      }
    }
  }, [isInit, name, ref, setInit])
}

export const useElementScroll = (props: useElementScrollType) => {
  const {
    fpsLimit = 60,
    name,
    onBottom,
    onBottomThrottled,
    onScroll,
    onScrollX,
    offsetBottom
  } = props
  const currentScrollPosition = useRef({
    x: 0,
    y: 0
  })
  const timerHelper = useRef({
    now: 0,
    startTime: 0,
    then: 0,
    elapsed: 0
  })
  const scrollNode = name === 'window' ? window : name ? elementsScroll[name]?.current : undefined
  const nameAdjusted = scrollNode ? name : undefined

  useEffect(() => {
    if (!isBrowser || !nameAdjusted) {
      return undefined
    }

    const useWindow = nameAdjusted === 'window'
    const scrollNode = useWindow
      ? window
      : nameAdjusted
        ? elementsScroll[nameAdjusted]?.current
        : undefined

    if (scrollNode && (onScroll || onScrollX || onBottom || onBottomThrottled)) {
      const fpsInterval = 1000 / fpsLimit

      const debouncedOnBottom = onBottom
        ? _debounce(onBottom as (...args: any) => any, 250)
        : undefined
      const throttledOnBottom = onBottomThrottled
        ? _throttle(onBottomThrottled as (...args: any) => any, 250)
        : undefined

      const animate = (newtime: number) => {
        const scrollNode = useWindow
          ? window
          : nameAdjusted
            ? elementsScroll[nameAdjusted]?.current
            : undefined

        // stop
        if (!timerHelper?.current || !scrollNode) {
          return
        }

        // If still scrolling, request another frame
        if (
          currentScrollPosition.current.y !==
            (useWindow ? scrollNode.scrollY : scrollNode.scrollTop) ||
          currentScrollPosition.current.x !==
            (useWindow ? scrollNode.scrollX : scrollNode.scrollLeft)
        ) {
          requestAnimationFrame(animate)
        }

        // calc elapsed time since last loop
        timerHelper.current.now = newtime
        timerHelper.current.elapsed = timerHelper.current.now - timerHelper.current.then

        // if enough time has elapsed, draw the next frame
        if (timerHelper.current.elapsed > fpsInterval) {
          // Get ready for next frame by setting then=now, but...
          // Also, adjust for fpsInterval not being multiple of 16.67
          timerHelper.current.then =
            timerHelper.current.now - (timerHelper.current.elapsed % fpsInterval)

          //
          const offsetBottomAdjusted =
            offsetBottom || offsetBottom === 0
              ? offsetBottom
              : !offsetBottom
                ? DEFAULT_OFFSET_BOTTOM
                : 0

          // Events
          if (
            (onScroll || throttledOnBottom) &&
            currentScrollPosition.current.y !==
              (useWindow ? scrollNode.scrollY : scrollNode.scrollTop)
          ) {
            onScroll?.(
              useWindow ? scrollNode.scrollY : scrollNode.scrollTop,
              scrollNode,
              currentScrollPosition.current.y <
                (useWindow ? scrollNode.scrollY : scrollNode.scrollTop)
                ? 'down'
                : 'up'
            )

            if (
              throttledOnBottom &&
              (useWindow
                ? scrollNode.scrollY > scrollNode.innerHeight - offsetBottomAdjusted
                : scrollNode.scrollTop + scrollNode.clientHeight >
                  scrollNode.scrollHeight - offsetBottomAdjusted)
            )
              throttledOnBottom()
          }

          if (
            onScrollX &&
            currentScrollPosition.current.x !==
              (useWindow ? scrollNode.scrollX : scrollNode.scrollLeft)
          )
            onScrollX(
              useWindow ? scrollNode.scrollX : scrollNode.scrollLeft,
              scrollNode,
              currentScrollPosition.current.x <
                (useWindow ? scrollNode.scrollX : scrollNode.scrollLeft)
                ? 'right'
                : 'left'
            )

          if (
            debouncedOnBottom &&
            currentScrollPosition.current.y !==
              (useWindow ? scrollNode.scrollY : scrollNode.scrollTop) &&
            (useWindow
              ? scrollNode.scrollY > scrollNode.innerHeight - offsetBottomAdjusted
              : scrollNode.scrollTop + scrollNode.clientHeight >
                scrollNode.scrollHeight - offsetBottomAdjusted)
          ) {
            debouncedOnBottom()
          }

          //
          currentScrollPosition.current = {
            x: useWindow ? scrollNode.scrollX : scrollNode.scrollLeft,
            y: useWindow ? scrollNode.scrollY : scrollNode.scrollTop
          }
        }
      }

      const handleOnScroll = () => {
        requestAnimationFrame(animate)
      }

      scrollNode?.addEventListener('scroll', handleOnScroll)

      return () => {
        scrollNode?.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [onScroll, onScrollX, onBottom, onBottomThrottled, nameAdjusted, fpsLimit, offsetBottom])
}

export const getElementScroll = (name?: string) => {
  if (!name || !elementsScroll[name]) return undefined

  return elementsScroll[name]
}
