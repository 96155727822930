import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import _map from 'lodash/map'
import _toNumber from 'lodash/toNumber'
import { CanvasBuilderProps, Size } from './CanvasBuilder'
import { BASE_GRID_SIZE } from '.'
import { Line } from 'konva/lib/shapes/Line'
import { GridStateManagerType, gridUtils } from './utils'
import { color } from 'playformTheme'
import { Group } from 'konva/lib/Group'

export type GridCanvasProps = Pick<CanvasBuilderProps, 'layer' | 'debugMode'> & {
  gridState: GridStateManagerType
}

type GridMap = {
  pos: Vector2d
  x: number //Grid count X
  y: number //Grid count Y
}
type RenderVisibleGridParam = {
  camera: Vector2d
  zoom: number
  withGroupLabel: boolean
  stageSize: Size
}

const renderVisibleGrid = (param: RenderVisibleGridParam) => {
  const { camera, zoom, stageSize, withGroupLabel } = param
  const gridSize = (zoom / 100) * BASE_GRID_SIZE

  const gridCount = {
    x: Math.ceil(stageSize.width / gridSize),
    y: Math.ceil(stageSize.height / gridSize)
  }
  const gridOffset = {
    x: camera.x % gridSize,
    y: camera.y % gridSize
  }
  //Generate grid map
  const gridMaps: GridMap[] = []
  if (withGroupLabel) {
    for (let x = -1; x < gridCount.x; x++) {
      for (let y = -1; y < gridCount.y; y++) {
        const xReal = x - (camera.x - gridOffset.x) / gridSize
        const yReal = y - (camera.y - gridOffset.y) / gridSize
        const gridMap = {
          pos: {
            x: x * gridSize + gridOffset.x + gridSize / 2,
            y: y * gridSize + gridOffset.y + gridSize / 2
          },
          x: xReal,
          y: yReal
        }
        gridMaps.push(gridMap)
      }
    }
  }

  let yPoints: number[] = []
  for (let y = 0; y < gridCount.y; y++) {
    const yPoint = y * gridSize + gridOffset.y
    const yPointNext = (y + 1) * gridSize + gridOffset.y
    yPoints.push(-1, yPoint, stageSize.width + 1, yPoint, stageSize.width + 1, yPointNext)
  }

  let xPoints: number[] = []
  for (let x = 0; x < gridCount.x; x++) {
    const xPoint = x * gridSize + gridOffset.x
    const xPointNext = (x + 1) * gridSize + gridOffset.x
    xPoints.push(xPoint, -1, xPoint, stageSize.height + 1, xPointNext, stageSize.height + 1)
  }

  return { xPoints, yPoints, gridMaps }
}

type UpdateGridParam = {
  camera: Vector2d
  stageSize: Size
  zoom: number
  xLine: Line
  yLine: Line
  gridMapGroup: Konva.Group
  withGroupLabel: boolean
}
const updateGrid = (param: UpdateGridParam) => {
  const { camera, stageSize, zoom, xLine, yLine, withGroupLabel, gridMapGroup } = param
  const { xPoints, yPoints, gridMaps } = renderVisibleGrid({
    camera,
    stageSize,
    zoom,
    withGroupLabel
  })
  xLine.points(xPoints)
  yLine.points(yPoints)
  if (gridMaps.length) {
    gridMapGroup.destroyChildren()
    gridMaps.forEach(gridMap => {
      gridMapGroup.add(
        new Konva.Text({
          text: `${gridMap.x}, ${gridMap.y}`,
          x: gridMap.pos.x,
          y: gridMap.pos.y
        })
      )
    })
  }
}

const Grid = (props: GridCanvasProps) => {
  const { layer, gridState, debugMode } = props

  const baseGrid = new Konva.Group({
    x: 0,
    y: 0
  })
  const gridMapGroup = new Konva.Group({
    x: 0,
    y: 0
  })
  const xLine = new Konva.Line({
    points: [],
    stroke: 'orange',
    opacity: 1
  })
  const yLine = new Konva.Line({
    points: [],
    stroke: 'orange',
    opacity: 1
  })

  const imageGroup = new Konva.Group({
    x: 0,
    y: 0
  })
  const selectedRect = new Konva.Rect({
    x: 0,
    y: 0,
    stroke: color.primary.main,
    strokeWidth: 3,
    width: 0,
    visible: false,
    height: 0
  })

  baseGrid.add(imageGroup)
  baseGrid.add(gridMapGroup)
  baseGrid.add(selectedRect)
  if (debugMode) {
    baseGrid.add(xLine)
    baseGrid.add(yLine)
  }

  let previousZoom = 0

  return {
    get: () => {
      return baseGrid
    },
    /* Called when image updated */
    updateImage: () => {
      const levelGroups = imageGroup.children ?? []
      const input = gridState.displayedImages()
      const zoom = gridState.zoom()
      input.forEach((displayedImages, index) => {
        const isDisplayed = input.length - 1 === index //Only display 1 last iteration
        // const isDisplayed = input.length - 1 === index ||
        // input.length - 2 === index //Only display 2 last iteration

        let levelGroup = levelGroups[index] as Group
        if (!levelGroup) {
          levelGroup = new Konva.Group({
            x: 0,
            y: 0
          })
          imageGroup.add(levelGroup)
        }
        if (!isDisplayed) {
          levelGroup.destroyChildren()
        } else {
          const imageNodes = levelGroup.children ?? []
          const imageNodeIds = _map(imageNodes, imageNode => imageNode.id())
          const displayedImageIds = _map(displayedImages, displayedImage => `${displayedImage.id}`)

          //Add Image if not available
          displayedImages.forEach(displayedImage => {
            const hasImage = imageNodeIds.includes(`${displayedImage.id}`)
            if (!hasImage) {
              const image = new Konva.Image({
                id: `${displayedImage.id}`,
                image: undefined,
                ...gridUtils.getImagePosition(zoom, displayedImage.gridX, displayedImage.gridY),
                ...gridUtils.getImageSize(zoom, displayedImage.gridWidth, displayedImage.gridHeight)
              })

              if (debugMode) {
                image.stroke('red')
                image.strokeWidth(10)
              }

              const imageObj = new Image()
              imageObj.onload = function () {
                image.image(imageObj)
                layer.batchDraw()
              }
              imageObj.src = displayedImage.file

              levelGroup.add(image)
            }
          })

          //Delete image if it's not displayed anymore
          imageNodes.forEach(imageNode => {
            const shouldKeep = displayedImageIds.includes(imageNode.id())

            if (!shouldKeep) {
              imageNode.destroy()
            }
          })
        }
        layer.batchDraw()
      })
    },
    /* Called when data updated */
    draw: (noDrawLayer?: boolean) => {
      const stageSize = gridState.stageSize()
      const zoom = gridState.zoom()
      const camera = gridState.camera()
      const selectedImage = gridState.selectedImage()
      const displayedImagesFlat = gridState.displayedImagesFlat()
      const isZoomChanged = zoom !== previousZoom
      const gridWidth = gridUtils.getGridSize(zoom)
      previousZoom = zoom

      if (debugMode) {
        if (zoom > 800) {
          updateGrid({
            camera,
            stageSize,
            zoom,
            xLine,
            yLine,
            withGroupLabel: zoom > 1500,
            gridMapGroup
          })
        } else {
          xLine.points([])
          yLine.points([])
          gridMapGroup.destroyChildren()
        }
      }

      if (isZoomChanged) {
        imageGroup.children?.forEach(layerGroup =>
          (layerGroup as Group)?.children?.forEach(image => {
            const id = _toNumber(image.id())
            const displayedImage = displayedImagesFlat[id]
            const { x, y } = gridUtils.getImagePosition(
              zoom,
              displayedImage?.gridX || 0,
              displayedImage?.gridY || 0
            )
            const { width, height } = gridUtils.getImageSize(
              zoom,
              displayedImage?.gridWidth || 0,
              displayedImage?.gridHeight || 0
            )
            image.x(x)
            image.y(y)
            image.width(width)
            image.height(height)
          })
        )
      }

      if (selectedImage) {
        const screenPosition = gridUtils.getGridPositionInGridGroup({
          zoom,
          camera,
          grid: {
            x: selectedImage.pos_x,
            y: selectedImage.pos_y
          }
        })

        const positionOffset = (zoom / 100) * 0.05

        selectedRect.width(gridWidth)
        selectedRect.height(gridWidth)
        selectedRect.x(screenPosition.x + positionOffset)
        selectedRect.y(screenPosition.y + positionOffset)
        selectedRect.visible(true)
      } else {
        selectedRect.width(0)
        selectedRect.height(0)
        selectedRect.visible(false)
      }

      imageGroup.x(camera.x)
      imageGroup.y(camera.y)

      !noDrawLayer && layer.batchDraw()
    },
    getGridCoordinate: (screenLocation: Vector2d) => {
      const zoom = gridState.zoom()
      const camera = gridState.camera()
      const screenOffset = gridState.screenOffset()

      return gridUtils.getGridCoordinate({ camera, zoom, screenLocation, screenOffset })
    }
  }
}

export default Grid
