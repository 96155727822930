/**
 * Asynchronously loads the component for TextToImageVideoPage
 */

import loadable from 'utils/Loadable'
import { LoadingPage } from 'components/Loading'

export default loadable(() => import(/* webpackChunkName: "TextToImageVideoPage" */ './index'), {
  fallback: <LoadingPage />
})
