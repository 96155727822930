import {
  Collection,
  CollectionCopyReq,
  CollectionCreateReq,
  CollectionUpdateReq,
  ImageListReq,
  InferenceRecordCreateReq,
  InferenceRecord,
  InferenceStartReq,
  TrainOutputImagesReq,
  PaginationRequestParams,
  PaginationResponse,
  TrainProject,
  TrainProjectCreateReq,
  TrainProjectUpdateReq,
  UpdateUserReq,
  User,
  Notification,
  CollectionListReq,
  ProjectCopyReq,
  ChangeEmailReq,
  ChangeEmailResponse,
  VerifyReq,
  PaymentReq,
  Payment,
  ProductsResponse,
  EngineConfigResponse,
  TrainProjectStartReq,
  ProjectSummary,
  ListCreditChangeLogResponse,
  TagListReq,
  Category,
  Tag,
  InputImageSet,
  MoveCollectionReq,
  MoveCollectionRes,
  InputImageSetUpdateReq,
  SketchProject,
  SketchStyleListResponse,
  SketchStylesListReq,
  Question,
  QuestionCreateReq,
  Post,
  PostCreateReq,
  PostListReq,
  PostListResponse,
  GenreListResponse,
  PageParams,
  ClapResponse,
  ClapReq,
  CommentCreateReq,
  Thread,
  ThreadCreateReq,
  ThreadListResponse,
  CommentListReq,
  ThreadListReq,
  Comment,
  CommentListResponse,
  SketchProjectListReq,
  SketchProjectListResponse,
  SketchProjectCreateReq,
  SketchProjectUpdateReq,
  SketchOutputListResponse,
  SketchOutputListReq,
  SpaceImage,
  SpaceImageRequest,
  OriginImageRequest,
  MixImageGenreListResponse,
  MixImageGenerateReq,
  MixImageCreateReq,
  MixImageListResponse,
  MixImageProject,
  SubscriptionCreateReq,
  Equity,
  CheckPhoneUsedReq,
  UpdatePhoneResponse,
  MixImageUpdateReq,
  UploadImage,
  ProjectMixCreateRequest,
  ProjectMix,
  GenerateProjectMixRequest,
  GenerateRandomProjectMixRequest,
  ProjectMixRandomListRequest,
  ProjectMixRandomListResponse,
  Clip,
  UpdateClipReq,
  CreateClipReq,
  ListClipReq,
  Bookmark,
  BookmarkCreateReq,
  MixImageGeneratePreviewReq,
  UserImage,
  TrainOutputImageResponse,
  ImageType,
  PostUpdateReq,
  BannerImage,
  TrainProjectMoreRequest,
  UpdateThresholdRequest,
  RemoveSketchRequest,
  CreditOverview,
  UserImageBookmarkListResponse,
  UserImageBookmarkListReq,
  ProArtFilterGenreListResponse,
  ProArtFilterListResponse,
  ProArtFilterProject,
  ProArtFilterCreateReq,
  ProArtFilterUpdateReq,
  ProArtFilterStyleListResponse,
  ProArtFilterStyleListReq,
  ProArtFilterOutputListResponse,
  ProArtFilterOutputListReq,
  EquityConfig,
  PreSignupEmailReq,
  ProArtFilterBanner,
  AdjustedImage,
  AdjustedImageCreateReq,
  AdjustedImageConnectReq,
  ListUpscaleImageReq,
  UpscaleImage,
  ConfigVariables,
  DownloadImage,
  CreateDownloadImageReq,
  Channel,
  NotificationUpdateReq,
  NotificationListReq,
  GenerateClipReq,
  ArtMineConfig,
  MineProject,
  Signature,
  CreateMineProjectReq,
  ListMineProjectReq,
  BrainTreeTokenResponse,
  BraintreeCreatePaymentMethodReq,
  BraintreePaymentMethod,
  Subscription,
  SubscriptionUpdateReq,
  NotificationTemplate,
  NotificationPreference,
  NotificationPreferenceUpdateReq,
  NotificationPreferenceCreateReq,
  UpdateUserAliasReq,
  TIProjectListResponse,
  TIProject,
  TIProjectCreateReq,
  TIProjectOutputListResponse,
  TIProjectOutputListReq,
  TIProjectUpdateReq,
  TIProjectListReq,
  TIProjectGenerateReq,
  GenericApp,
  GenericAppListResponse,
  GenericAppProjectListResponse,
  GenericAppProject,
  GenericAppProjectCreateReq,
  GenericAppOutputImageListResponse,
  GenericAppOutputImageListReq,
  GenericAppProjectUpdateReq,
  SketchTextProject,
  SketchTextCreateReq,
  SketchTextUpdateReq,
  SketchTextOutputListReq,
  SketchTextOutputImage,
  SketchTextUpdateThresholdReq,
  SketchTextRemoveSketchReq,
  SketchTextGenre,
  SketchTextProcess,
  SketchTextModel,
  SketchTextStyles
} from 'models/ApiModels'

import { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import axios from 'axios-observable'
import urljoin from 'url-join'
import _omit from 'lodash/omit'
import { GetText } from './TextUtils'
import { SessionStorage } from 'utils'
import { LSKey } from 'appConstants'

export const AUTH_TOKEN_NAME = 'aican_auth'
export const API_BASE_URL = process.env.REACT_APP_API_URL
export const DEFAULT_API_TIMEOUT = 300 * 1000

// largely inspired by https://gist.github.com/alfonmga/6474f6adb6ed8dee8bc8bf8627c0ae1
export function getAuthToken(): string {
  const b64str = Cookies.get(AUTH_TOKEN_NAME)

  if (b64str === undefined) {
    return ''
  }

  const token = JSON.parse(atob(b64str))

  return `JWT ${token}`
}

export function setAuthCookie(token: string, expires: number | Date = 365) {
  Cookies.set(AUTH_TOKEN_NAME, btoa(JSON.stringify(token)), {
    expires
  })
}

export function removeAuthCookie() {
  Cookies.remove(AUTH_TOKEN_NAME)
}

axios.defaults.baseURL = API_BASE_URL
axios.defaults.xsrfCookieName = axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

function includeAuthToken(reqConfig: AxiosRequestConfig): AxiosRequestConfig {
  reqConfig.headers.Authorization = getAuthToken()
  reqConfig.headers['Content-Type'] = 'application/json'
  reqConfig.headers['Accept'] = 'application/json, text/plain, */*'

  const userDebug = SessionStorage.get(LSKey.USER_DEBUG_EMAIL)

  if (userDebug) {
    reqConfig.headers['X-DEBUG-USER'] = userDebug
  }

  return reqConfig
}

axios.interceptors.request.use(includeAuthToken)

const axiosNoAuth = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json, text/plain, */*' },
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'X-CSRFToken',
  withCredentials: true
})

const usersUrl = '/api/users/'
const usersPreSignupUrl = 'api/users/pre-signup/'
const usersAliasUrl = '/api/users/alias'
const changeAliasAction = 'change-alias/'

const checkPhoneUsedUrl = '/api/users/check-phone-used/'
const updatePhoneUrl = '/api/users/update-phone/'
const changeEmailAction = 'change_email/'
const verifyChangeEmailAction = 'verify/'
const permissionUrl = '/api/permissions/'
const questionUrl = '/api/feedback/'
const equityUrl = '/api/membership/equity/'
const notificationUrl = '/api/notify/notifications/'
const notificationTemplateUrl = '/api/notify/notification-templates/'
const notificationPreferencesUrl = '/api/notify/notification-preferences/'
const equityConfigUrl = '/api/membership/equity-configs/'
const configVariablesUrl = `/api/config-variables/`

const projectsUrl = '/api/training/projects/'
const startProjectsAction = 'start/'
const moreAction = 'more/'
const stopProjectAction = 'stop/'
const projectsSummaryUrl = '/api/training/projects/summary/'

const inputsUrl = '/api/training/inputs/'
const moveAction = 'move/'

const updateThumbnail = 'update-thumbnail/'

const collectionCategoriesUrl = '/api/collection/categories/'
const collectionsUrl = '/api/collection/collections/'
const collectionImagesUrl = '/api/collection/images/'
const recentlyUsedCollectionsUrl = '/api/collection/usage/'
const collectionTagsUrl = '/api/collection/tags/'
const copyAction = 'copy/'
const addTagAction = 'add-tag/'
const removeTagAction = 'remove-tag/'

const trainingOutputsUrl = '/api/training/outputs/'

//Inference
const inferenceRecordUrl = '/api/prediction/inferences/'
const addInferenceInputAction = 'add-input/'
const startInferenceAction = 'start/'

const legacyProjectUrl = '/api/dreams/'

//Payment related API
const methodUrl = `/api/pay/methods/`
const productsUrl = `/api/pay/products/`
const paymentsUrl = `/api/pay/payments/`

const braintreeGenerateTokenUrl = `/api/pay/methods/braintree/generate-token/`
const braintreeRemovePaymentMethodUrl = `/api/pay/methods/braintree/remove-payment-method/`
const braintreePaymentMethodUrl = `/api/pay/methods/braintree/payment-method/`
const braintreeCreatePaymentMethodUrl = `/api/pay/methods/braintree/create-payment-method/`

const paypalGenerateTokenUrl = `/api/pay/methods/paypal/generate-token/`

const channelsUrl = `/api/pay/channels/`

const cardDetailUrl = '/api/pay/methods/card-detail/'

//New Subscription API.
const subscriptionUrl = '/api/pay/subscriptions/'
const cancelAction = 'cancel/'

const creditChangeLogsUrl = `/api/credit/changelogs/`
const creditOverviewUrl = `/api/credit/overview/`
const downloadImageUrl = `/api/credit/download-images/`

//Engine API
const engineRandomImagesUrl = `/api/engine/random-images/`
const engineMixUrl = `/api/engine/mix/`
const engineConfigUrl = `/api/engine/engine-configs/`
const clipsUrl = '/api/engine/clips/'

const previewAction = 'preview/'

//Enhancement
const upscaleUrl = `/api/enhancement/upscale-images/`
const adjustedImagesUrl = `/api/enhancement/adjusted-images/`
const connectAction = `connect/`

//Sketch to Image
const sketchStylesUrl = `/api/sketch/styles/`
const sketchGenreUrl = `/api/sketch/genres/`
const sketchOutputUrl = `/api/sketch/output-images/`
const sketchProjectsUrl = `/api/sketch/projects/`
const generateAction = 'generate/'

const generateRandomAction = 'generate-random/'

//Social API
const bannerUrl = `/api/social/banners/`
const postUrl = '/api/social/posts/'
const clapUrl = '/api/social/clap/'
const removeClapUrl = '/api/social/clap/remove/'

const threadUrl = '/api/social/threads/'
const commentUrl = '/api/social/comments/'

//bookmark
const postBookmarkUrl = '/api/bookmark/post/'
const collectionBookmarkUrl = '/api/bookmark/collection/'
const userImageBookmarkUrl = '/api/bookmark/user-image/'

//Latent space
const spaceImageUrl = '/api/latent/space-images/'
const randomImageUrl = '/api/latent/random-images/'
const originImageUrl = '/api/latent/origin-images/'

const mixImageGenresUrl = '/api/latent/genres/'
const mixImageProjectsUrl = '/api/latent/projects/'

const mixImageUploadImagesUrl = '/api/latent/upload-images/'

const saveAction = 'save/'
const removeAction = 'remove/'
const updateThumbnailAction = 'update-thumbnail/'
const uploadAction = 'upload/'
const cleanOutputsAction = 'clean-outputs/'

//Style transfer
const proArtFilterGenresUrl = `/api/transfer/genres/`
const proArtFilterStylesUrl = `/api/transfer/styles/`
const proArtFilterProjectsUrl = `/api/transfer/projects/`
const proArtFilterOutputsUrl = `/api/transfer/outputs/`
const proArtFilterBannersUrl = `/api/social/stylize-banners/`

//Artmine
const artMineProjectUrl = `/api/artmine/projects/`
const artMineConfigUrl = '/api/artmine/configs/'
const signatureAction = 'signature/'
const startTransactionAction = 'start-transaction/'

//TextToImage
const textToImageUrl = `/api/text-to-image/projects/`
const textToImageOutputUrl = `/api/text-to-image/outputs/`

//Generic APP
const genericAppUrl = `/api/app/apps/`
const genericAppProjectUrl = '/api/app/projects/'
const genericAppOutputsUrl = '/api/app/outputs/'

//SketchText
const sketchTextGenreUrl = `/api/sketchtxt/genres/`
const sketchTextUrl = `/api/sketchtxt/projects/`
const sketchTextOutputUrl = `/api/sketchtxt/outputs/`
const sketchTextProcessUrl = `/api/sketchtxt/process/`
const sketchTextModelUrl = `/api/sketchtxt/models/`
const sketchTextRandom = `api/sketchtxt/prompts/random`
const sketchTextStylesUrl = `/api/sketchtxt/styles/`

export type TOP_API_URLS =
  | typeof usersUrl
  | typeof usersAliasUrl
  | typeof usersPreSignupUrl
  | typeof checkPhoneUsedUrl
  | typeof notificationUrl
  | typeof notificationPreferencesUrl
  | typeof notificationTemplateUrl
  | typeof updatePhoneUrl
  | typeof projectsUrl
  | typeof questionUrl
  | typeof equityUrl
  | typeof equityConfigUrl
  | typeof configVariablesUrl
  | typeof inputsUrl
  | typeof collectionsUrl
  | typeof collectionCategoriesUrl
  | typeof collectionImagesUrl
  | typeof collectionTagsUrl
  | typeof inferenceRecordUrl
  | typeof legacyProjectUrl
  | typeof permissionUrl
  | typeof channelsUrl
  | typeof methodUrl
  | typeof cardDetailUrl
  | typeof productsUrl
  | typeof paymentsUrl
  | typeof braintreeRemovePaymentMethodUrl
  | typeof braintreePaymentMethodUrl
  | typeof braintreeCreatePaymentMethodUrl
  | typeof braintreeGenerateTokenUrl
  | typeof paypalGenerateTokenUrl
  | typeof subscriptionUrl
  | typeof engineConfigUrl
  | typeof engineRandomImagesUrl
  | typeof engineMixUrl
  | typeof upscaleUrl
  | typeof adjustedImagesUrl
  | typeof clipsUrl
  | typeof projectsSummaryUrl
  | typeof creditChangeLogsUrl
  | typeof trainingOutputsUrl
  | typeof creditOverviewUrl
  | typeof downloadImageUrl
  | typeof recentlyUsedCollectionsUrl
  | typeof sketchStylesUrl
  | typeof sketchGenreUrl
  | typeof sketchOutputUrl
  | typeof sketchProjectsUrl
  | typeof postUrl
  | typeof bannerUrl
  | typeof clapUrl
  | typeof removeClapUrl
  | typeof postBookmarkUrl
  | typeof collectionBookmarkUrl
  | typeof userImageBookmarkUrl
  | typeof commentUrl
  | typeof threadUrl
  | typeof spaceImageUrl
  | typeof originImageUrl
  | typeof randomImageUrl
  | typeof mixImageGenresUrl
  | typeof mixImageProjectsUrl
  | typeof mixImageUploadImagesUrl
  | typeof proArtFilterGenresUrl
  | typeof proArtFilterStylesUrl
  | typeof proArtFilterProjectsUrl
  | typeof proArtFilterOutputsUrl
  | typeof proArtFilterBannersUrl
  | typeof artMineProjectUrl
  | typeof artMineConfigUrl
  | typeof textToImageUrl
  | typeof textToImageOutputUrl
  | typeof genericAppUrl
  | typeof genericAppProjectUrl
  | typeof genericAppOutputsUrl
  | typeof sketchTextUrl
  | typeof sketchTextGenreUrl
  | typeof sketchTextOutputUrl
  | typeof sketchTextProcessUrl
  | typeof sketchTextModelUrl
  | typeof sketchTextRandom
  | typeof sketchTextStylesUrl

export type URL_ACTIONS =
  | typeof cancelAction
  | typeof changeAliasAction
  | typeof startProjectsAction
  | typeof updateThumbnail
  | typeof removeTagAction
  | typeof addTagAction
  | typeof copyAction
  | typeof moveAction
  | typeof moreAction
  | typeof addInferenceInputAction
  | typeof startInferenceAction
  | typeof stopProjectAction
  | typeof changeEmailAction
  | typeof verifyChangeEmailAction
  | typeof generateAction
  | typeof generateRandomAction
  | typeof updateThumbnailAction
  | typeof saveAction
  | typeof removeAction
  | typeof previewAction
  | typeof uploadAction
  | typeof cleanOutputsAction
  | typeof connectAction
  | typeof signatureAction
  | typeof startTransactionAction

const listCreator =
  <Res, P = {}>(url: TOP_API_URLS) =>
  (params?: PaginationRequestParams, extraParams?: P) =>
    params && params.next
      ? axios.get<Res>(params.next, { params: extraParams })
      : axios.get<Res>(url, { params: Object.assign({}, params, extraParams) })

const retrieveCreator =
  <T>(url: TOP_API_URLS) =>
  (id: string | number) =>
    axios.get<T>(urljoin(url, id.toString(), '/'))

const updateCreator =
  <Res, Req>(url: TOP_API_URLS, method: 'patch' | 'put' = 'patch') =>
  (id: string | number, data: Req) => {
    const axiosFunction = method === 'patch' ? axios.patch : axios.put
    return axiosFunction<Res>(urljoin(url, id.toString(), '/'), data)
  }

const createCreator =
  <Res, Req>(url: TOP_API_URLS, additionalParam?: object) =>
  (data: Req) =>
    axios.post<Res>(url, data, { ...additionalParam }) // <--Using spread syntax because of canceling problem https://github.com/zhaosiyang/axios-observable/issues/11

const deleteCreator =
  <T = {}>(url: TOP_API_URLS) =>
  (id: string | number) =>
    axios.delete<T>(urljoin(url, id.toString(), '/'))

const urlActionCreator =
  <Res, Req = {}>(url: TOP_API_URLS, action: URL_ACTIONS) =>
  (id: string | number, data?: Req, customBaseUrl: string = '') =>
    axios.post<Res>(urljoin(`${customBaseUrl}${url}`, id.toString(), action), data)

export const users = {
  submitPresignupEmail: createCreator<null, PreSignupEmailReq>(usersPreSignupUrl),
  permission: () => axios.get<string[]>(permissionUrl),
  retrieve: retrieveCreator<User>(usersUrl),
  retrieveByAlias: retrieveCreator<User>(usersAliasUrl),
  updateAlias: urlActionCreator<User, UpdateUserAliasReq>(usersUrl, changeAliasAction),
  uploadUserPicture: updateCreator<User, FormData>(usersUrl),
  update: updateCreator<User, UpdateUserReq>(usersUrl),
  changeEmail: urlActionCreator<ChangeEmailResponse, ChangeEmailReq>(usersUrl, changeEmailAction),
  verifyChangeEmail: urlActionCreator<ChangeEmailResponse, VerifyReq>(
    usersUrl,
    verifyChangeEmailAction
  ),
  sendQuestion: createCreator<Question, QuestionCreateReq>(questionUrl),
  checkPhoneUsed: createCreator<boolean, CheckPhoneUsedReq>(checkPhoneUsedUrl),
  updatePhone: createCreator<UpdatePhoneResponse, undefined>(updatePhoneUrl),
  retrieveEquity: () => axios.get<Equity>(equityUrl),
  retrieveEquityConfig: listCreator<PaginationResponse<EquityConfig>>(equityConfigUrl),
  retrieveConfigVariable: () => axiosNoAuth.get<ConfigVariables>(configVariablesUrl),
  listNotification: listCreator<PaginationResponse<Notification>, NotificationListReq>(
    notificationUrl
  ),
  retrieveNotification: retrieveCreator<Notification>(notificationUrl),
  deleteNotification: deleteCreator<Notification>(notificationUrl),
  updateNotification: updateCreator<Notification, NotificationUpdateReq>(notificationUrl),
  listNotificationTemplate:
    listCreator<PaginationResponse<NotificationTemplate>>(notificationTemplateUrl),
  listNotificationPreferences: listCreator<PaginationResponse<NotificationPreference>>(
    notificationPreferencesUrl
  ),
  createNotificationPreferences: createCreator<
    NotificationPreference,
    NotificationPreferenceCreateReq
  >(notificationPreferencesUrl),
  updateNotificationPreferences: updateCreator<
    NotificationPreference,
    NotificationPreferenceUpdateReq
  >(notificationPreferencesUrl)
}

export const projects = {
  list: listCreator<PaginationResponse<TrainProject>>(projectsUrl),
  listWithUrlSearchParams: (params: URLSearchParams, next?: string) => {
    if (next) {
      return axios.get<PaginationResponse<TrainProject>>(next)
    } else {
      return axios.get<PaginationResponse<TrainProject>>(projectsUrl, { params })
    }
  },
  retrieve: retrieveCreator<TrainProject>(projectsUrl),
  create: createCreator<TrainProject, TrainProjectCreateReq>(projectsUrl),
  update: updateCreator<TrainProject, TrainProjectUpdateReq>(projectsUrl),
  delete: deleteCreator<TrainProject>(projectsUrl),
  start: urlActionCreator<TrainProject, TrainProjectStartReq>(projectsUrl, startProjectsAction),
  copy: (req: ProjectCopyReq) =>
    axios.post<TrainProject>(
      urljoin(projectsUrl, `${req.project}`, copyAction),
      _omit(req, ['project'])
    ),
  more: urlActionCreator<TrainProject, TrainProjectMoreRequest>(projectsUrl, moreAction),
  stop: urlActionCreator<TrainProject>(projectsUrl, stopProjectAction),
  retrieveLegacyProject: retrieveCreator<any>(legacyProjectUrl),
  retrieveSummary: () => axios.get<ProjectSummary>(projectsSummaryUrl),
  updateThumbnail: urlActionCreator<any, any>(projectsUrl, updateThumbnail),
  retrieveOutputImages: listCreator<TrainOutputImageResponse, TrainOutputImagesReq>(
    trainingOutputsUrl
  ),
  createProjectMix: createCreator<ProjectMix, ProjectMixCreateRequest>(engineMixUrl),
  listRandomImages: listCreator<ProjectMixRandomListResponse, ProjectMixRandomListRequest>(
    engineRandomImagesUrl
  ),

  retrieveProjectMix: retrieveCreator<ProjectMix>(engineMixUrl),
  generateProjectMix: urlActionCreator<ProjectMix, GenerateProjectMixRequest>(
    engineMixUrl,
    generateAction
  ),
  generateRandomProjectMix: urlActionCreator<ProjectMix, GenerateRandomProjectMixRequest>(
    engineMixUrl,
    generateRandomAction
  ),

  //User Image bookmark
  createUserImageBookmark: createCreator<
    Bookmark<'bookmark-create'>,
    BookmarkCreateReq<'user-image'>
  >(userImageBookmarkUrl),
  listUserImageBookmark: listCreator<UserImageBookmarkListResponse, UserImageBookmarkListReq>(
    userImageBookmarkUrl
  ),
  deleteUserImageBookmark: deleteCreator<Bookmark<'user-image'>>(userImageBookmarkUrl),

  //Inference
  retrieveInference: retrieveCreator<InferenceRecord>(inferenceRecordUrl),
  createInferenceRecord: createCreator<InferenceRecord, InferenceRecordCreateReq>(
    inferenceRecordUrl
  ),
  addInferenceInput: (id: number, data: FormData) =>
    axios.post<InferenceRecord>(
      urljoin(inferenceRecordUrl, id.toString(), addInferenceInputAction, '/'),
      data
    ),
  startInference: urlActionCreator<InferenceRecord, InferenceStartReq>(
    inferenceRecordUrl,
    startInferenceAction
  )
}

export const imageEnhancement = {
  listUpscaleImage: listCreator<PaginationResponse<UpscaleImage>, ListUpscaleImageReq>(upscaleUrl),
  deleteUpscaleImage: deleteCreator<UpscaleImage>(upscaleUrl),
  createUpscaleImage: createCreator<UpscaleImage, FormData>(upscaleUrl),
  retrieveUpscaleImage: retrieveCreator<UpscaleImage>(upscaleUrl),
  createAdjustedImage: createCreator<AdjustedImage, AdjustedImageCreateReq>(adjustedImagesUrl),
  connectAdjustedImage: urlActionCreator<AdjustedImage, AdjustedImageConnectReq>(
    adjustedImagesUrl,
    connectAction
  ),
  deleteAdjustedImage: deleteCreator<AdjustedImage>(adjustedImagesUrl),
  retrieveAdjustedImage: retrieveCreator<AdjustedImage>(adjustedImagesUrl)
}

export const inputs = {
  retrieve: retrieveCreator<InputImageSet>(inputsUrl),
  update: updateCreator<InputImageSet, InputImageSetUpdateReq>(inputsUrl, 'put'),
  moveCollection: urlActionCreator<MoveCollectionRes, MoveCollectionReq>(inputsUrl, moveAction)
}

export const collections = {
  create: createCreator<Collection, CollectionCreateReq>(collectionsUrl),
  list: listCreator<PaginationResponse<Collection>, CollectionListReq>(collectionsUrl),
  recentlyUsedList: listCreator<PaginationResponse<Collection>, CollectionListReq>(
    recentlyUsedCollectionsUrl
  ),
  copy: urlActionCreator<Collection, CollectionCopyReq>(collectionsUrl, copyAction),
  updateThumbnail: urlActionCreator<any, any>(collectionsUrl, updateThumbnail),
  retrieve: retrieveCreator<Collection>(collectionsUrl),
  update: updateCreator<Collection, CollectionUpdateReq>(collectionsUrl),
  delete: deleteCreator<Collection>(collectionsUrl),
  listCategories: listCreator<PaginationResponse<Category>>(collectionCategoriesUrl),
  listTags: listCreator<PaginationResponse<string>, TagListReq>(collectionTagsUrl),
  addTag: urlActionCreator<string[], Tag>(collectionsUrl, addTagAction),
  removeTag: urlActionCreator<string[], Tag>(collectionsUrl, removeTagAction),

  createCollectionBookmark: createCreator<
    Bookmark<'bookmark-create'>,
    BookmarkCreateReq<'collection'>
  >(collectionBookmarkUrl),
  deleteCollectionBookmark: deleteCreator<Bookmark<'collection'>>(collectionBookmarkUrl),

  createImage: createCreator<ImageType, FormData>(collectionImagesUrl),
  listImage: listCreator<PaginationResponse<ImageType>, ImageListReq>(collectionImagesUrl)
}

export const payment = {
  retrieveProducts: () => axios.get<ProductsResponse>(productsUrl),
  retrieveChannels: () => axios.get<PaginationResponse<Channel>>(channelsUrl),
  createPayment: createCreator<Payment, PaymentReq>(paymentsUrl),
  retrievePayment: retrieveCreator<Payment>(paymentsUrl),
  listCreditChangeLog: listCreator<ListCreditChangeLogResponse>(creditChangeLogsUrl),

  listSubscription: listCreator<PaginationResponse<Subscription>>(subscriptionUrl),
  createSubscription: createCreator<Subscription, SubscriptionCreateReq>(subscriptionUrl),
  updateSubscription: updateCreator<Subscription, SubscriptionUpdateReq>(subscriptionUrl),
  cancelSubscription: urlActionCreator<null, null>(subscriptionUrl, cancelAction),

  retrieveCreditOverview: () => axios.get<CreditOverview>(creditOverviewUrl),

  braintreeGenerateToken: createCreator<BrainTreeTokenResponse, undefined>(
    braintreeGenerateTokenUrl
  ),
  braintreeRemovePaymentMethod: createCreator<undefined, undefined>(
    braintreeRemovePaymentMethodUrl
  ),
  braintreeCreatePaymentMethod: createCreator<
    BraintreePaymentMethod,
    BraintreeCreatePaymentMethodReq
  >(braintreeCreatePaymentMethodUrl),
  braintreeRetrievePaymentMethod: () =>
    axios.get<BraintreePaymentMethod>(braintreePaymentMethodUrl),

  retrieveDownloadImage: retrieveCreator<DownloadImage>(downloadImageUrl),
  createDownloadImage: createCreator<DownloadImage, CreateDownloadImageReq>(downloadImageUrl),
  listDownloadImage: listCreator<PaginationResponse<DownloadImage>, PaginationRequestParams>(
    downloadImageUrl
  )
}

export const engine = {
  listEngineConfig: listCreator<EngineConfigResponse>(engineConfigUrl),
  listClip: listCreator<PaginationResponse<Clip>, ListClipReq>(clipsUrl),
  retrieveClip: retrieveCreator<Clip>(clipsUrl),
  updateClip: updateCreator<Clip, UpdateClipReq>(clipsUrl),
  createClip: createCreator<Clip, CreateClipReq>(clipsUrl),
  deleteClip: deleteCreator<Clip>(clipsUrl),
  copyClip: urlActionCreator<Clip, null>(clipsUrl, copyAction),
  generateClipVideo: urlActionCreator<null, GenerateClipReq>(clipsUrl, generateAction),
  generateClipPreview: urlActionCreator<null, null>(clipsUrl, previewAction)
}

export const sketchToImage = {
  listSketchProject: listCreator<SketchProjectListResponse, SketchProjectListReq>(
    sketchProjectsUrl
  ),
  retrieveSketchProject: retrieveCreator<SketchProject>(sketchProjectsUrl),
  createSketchProject: createCreator<SketchProject, SketchProjectCreateReq>(sketchProjectsUrl),
  listSketchOutput: listCreator<SketchOutputListResponse, SketchOutputListReq>(sketchOutputUrl),
  deleteSketchOutput: deleteCreator<SketchProject>(sketchOutputUrl),
  updateSketchProject: updateCreator<SketchProject, Omit<SketchProjectUpdateReq, 'id'>>(
    sketchProjectsUrl
  ),
  generateSketchProject: urlActionCreator<SketchProject, FormData>(
    sketchProjectsUrl,
    generateAction
  ),
  listSketchStyles: listCreator<SketchStyleListResponse, SketchStylesListReq>(sketchStylesUrl),
  listSketchGenre: listCreator<GenreListResponse, PageParams>(sketchGenreUrl),
  deleteSketchProject: deleteCreator<SketchProject>(sketchProjectsUrl),
  uploadSketch: urlActionCreator<SketchProject, FormData>(sketchProjectsUrl, uploadAction),
  updateThreshold: urlActionCreator<SketchProject, UpdateThresholdRequest>(
    sketchProjectsUrl,
    uploadAction
  ),
  removeSketch: urlActionCreator<SketchProject, RemoveSketchRequest>(
    sketchProjectsUrl,
    uploadAction
  )
}
export const social = {
  listBannerImages: listCreator<PaginationResponse<BannerImage>, null>(bannerUrl),
  createPost: createCreator<Post, PostCreateReq>(postUrl),
  retrievePost: retrieveCreator<Post>(postUrl),
  updatePost: updateCreator<Post, PostUpdateReq>(postUrl),
  deletePost: deleteCreator<Post>(postUrl),
  listPost: listCreator<PostListResponse, PostListReq>(postUrl),
  createClap: createCreator<ClapResponse, ClapReq>(clapUrl),
  removeClap: createCreator<ClapResponse, ClapReq>(removeClapUrl),

  createPostBookmark: createCreator<Bookmark<'bookmark-create'>, BookmarkCreateReq<'post'>>(
    postBookmarkUrl
  ),
  deletePostBookmark: deleteCreator<Bookmark<'post'>>(postBookmarkUrl),

  //Comments Block
  createComment: createCreator<Comment, CommentCreateReq>(commentUrl),
  createThread: createCreator<Thread, ThreadCreateReq>(threadUrl),

  listThread: listCreator<ThreadListResponse, ThreadListReq>(threadUrl),
  listComment: listCreator<CommentListResponse, CommentListReq>(commentUrl)
}

export const mixImage = {
  retrieveSpaceImage: (param: SpaceImageRequest) =>
    axios.get<SpaceImage>(urljoin(spaceImageUrl, GetText.spaceImageNaturalKey(param), '/')),
  retrieveOriginImage: (param: OriginImageRequest) =>
    axios.get<UserImage>(
      urljoin(originImageUrl, GetText.originImageKey(param), '/'),
      param.project
        ? {
            params: { project: param.project },
            headers: {
              'Cache-Control': 'no-cache'
            }
          }
        : undefined
    ),
  listRandomImages: listCreator<UserImage[], null>(randomImageUrl),
  createMixImage: createCreator<MixImageProject, MixImageCreateReq>(mixImageProjectsUrl),
  updateMixImage: updateCreator<MixImageProject, MixImageUpdateReq>(mixImageProjectsUrl),
  listMixImage: listCreator<MixImageListResponse, PageParams>(mixImageProjectsUrl),
  retrieveMixImage: retrieveCreator<MixImageProject>(mixImageProjectsUrl),
  deleteMixImage: deleteCreator<MixImageProject>(mixImageProjectsUrl),

  listMixImageGenre: listCreator<MixImageGenreListResponse, PageParams>(mixImageGenresUrl),

  generateMixImage: urlActionCreator<MixImageProject, MixImageGenerateReq>(
    mixImageProjectsUrl,
    generateAction
  ),
  updateThumbnail: urlActionCreator<null>(mixImageProjectsUrl, updateThumbnailAction),
  generateMixImagePreview: urlActionCreator<MixImageProject, MixImageGeneratePreviewReq>(
    mixImageProjectsUrl,
    previewAction
  ),
  retrieveUploadImage: retrieveCreator<UploadImage>(mixImageUploadImagesUrl),
  createUploadImage: createCreator<UploadImage, FormData>(mixImageUploadImagesUrl),
  deleteUploadImage: deleteCreator(mixImageUploadImagesUrl),
  listUploadImage: listCreator<PaginationResponse<UploadImage>, PageParams>(mixImageUploadImagesUrl)
}

export const proArtFilter = {
  listProArtFilterGenres: listCreator<ProArtFilterGenreListResponse, null>(proArtFilterGenresUrl),
  listProArtFilterStyles: listCreator<ProArtFilterStyleListResponse, ProArtFilterStyleListReq>(
    proArtFilterStylesUrl
  ),
  listProArtFilterOutput: listCreator<ProArtFilterOutputListResponse, ProArtFilterOutputListReq>(
    proArtFilterOutputsUrl
  ),
  retrieveProArtFilter: retrieveCreator<ProArtFilterProject>(proArtFilterProjectsUrl),
  listProArtFilter: listCreator<ProArtFilterListResponse, null>(proArtFilterProjectsUrl),
  createProArtFilter: createCreator<ProArtFilterProject, ProArtFilterCreateReq>(
    proArtFilterProjectsUrl
  ),
  updateProArtFilter: updateCreator<ProArtFilterProject, ProArtFilterUpdateReq>(
    proArtFilterProjectsUrl
  ),
  uploadProArtFilterContent: updateCreator<ProArtFilterProject, FormData>(proArtFilterProjectsUrl),
  generateProArtFilter: urlActionCreator<ProArtFilterProject, FormData>(
    proArtFilterProjectsUrl,
    generateAction
  ),
  deleteProArtFilter: deleteCreator(proArtFilterProjectsUrl),
  deleteProArtFilterOutput: deleteCreator(proArtFilterOutputsUrl),
  cleanOutputs: urlActionCreator(proArtFilterProjectsUrl, cleanOutputsAction),
  listProArtFilterBanner: listCreator<PaginationResponse<ProArtFilterBanner>, null>(
    proArtFilterBannersUrl
  )
}

export const artMine = {
  listUserArtmineProjects: listCreator<PaginationResponse<MineProject>, ListMineProjectReq>(
    artMineProjectUrl
  ),
  deleteArtMineProject: deleteCreator(artMineProjectUrl),
  createArtMineProject: createCreator<MineProject, CreateMineProjectReq>(artMineProjectUrl),
  retrieveConfig: () => axios.get<ArtMineConfig>(artMineConfigUrl),
  startTransaction: urlActionCreator<MineProject>(artMineProjectUrl, startTransactionAction),
  retrieveSignature: (projectId: number) =>
    axios.get<Signature>(urljoin(artMineProjectUrl, `${projectId}`, signatureAction)),
  retrieveProject: retrieveCreator<MineProject>(artMineProjectUrl)
}

export const textToImage = {
  listTIProject: listCreator<TIProjectListResponse, TIProjectListReq>(textToImageUrl),
  retrieveTIProject: retrieveCreator<TIProject>(textToImageUrl),
  createTIProject: createCreator<TIProject, TIProjectCreateReq>(textToImageUrl),
  deleteTIProject: deleteCreator<undefined>(textToImageUrl),
  updateTIProject: updateCreator<TIProject, TIProjectUpdateReq>(textToImageUrl),
  generateTIProjectFormData: urlActionCreator<TIProject, FormData>(textToImageUrl, generateAction),
  generateTIProject: urlActionCreator<TIProject, TIProjectGenerateReq>(
    textToImageUrl,
    generateAction
  ),
  listTIProjectOutput: listCreator<TIProjectOutputListResponse, TIProjectOutputListReq>(
    textToImageOutputUrl
  ),
  deleteTIProjectOutput: deleteCreator<undefined>(textToImageOutputUrl),
  deleteAllTIProjectOutput: urlActionCreator<undefined>(textToImageUrl, cleanOutputsAction)
}

export const genericApp = {
  listGenericApp: listCreator<GenericAppListResponse, PaginationRequestParams>(genericAppUrl),
  retrieveGenericApp: retrieveCreator<GenericApp>(genericAppUrl),

  listGenericAppProject: listCreator<GenericAppProjectListResponse, PaginationRequestParams>(
    genericAppProjectUrl
  ),
  retrieveGenericAppProject: retrieveCreator<GenericAppProject>(genericAppProjectUrl),
  createGenericAppProject: createCreator<GenericAppProject, GenericAppProjectCreateReq>(
    genericAppProjectUrl
  ),
  deleteGenericAppProject: deleteCreator<undefined>(genericAppProjectUrl),
  updateGenericAppProject: updateCreator<GenericAppProject, GenericAppProjectUpdateReq>(
    genericAppProjectUrl
  ),
  cleanOutputs: urlActionCreator(genericAppProjectUrl, cleanOutputsAction),

  listGenericAppOutput: listCreator<
    GenericAppOutputImageListResponse,
    GenericAppOutputImageListReq
  >(genericAppOutputsUrl),
  deleteGenericAppProjectOutput: deleteCreator<undefined>(genericAppOutputsUrl),
  deleteAllGenericAppProjectOutput: urlActionCreator<undefined>(textToImageUrl, cleanOutputsAction)
}

export const sketchTextToImage = {
  listSTIProject: listCreator<PaginationResponse<SketchTextProject>, PaginationRequestParams>(
    sketchTextUrl
  ),
  listSTIGenre: listCreator<PaginationResponse<SketchTextGenre>, PageParams>(sketchTextGenreUrl),
  listSTIProcess: listCreator<PaginationResponse<SketchTextProcess>, PageParams>(
    sketchTextProcessUrl
  ),
  listSTIModel: listCreator<PaginationResponse<SketchTextModel>, PageParams>(sketchTextModelUrl),
  listSTIStyles: listCreator<PaginationResponse<SketchTextStyles>, PageParams>(sketchTextStylesUrl),
  retrieveSTIProject: retrieveCreator<SketchTextProject>(sketchTextUrl),
  retrieveRandomPrompt: () => axios.get<{ id: number; text: string }>(sketchTextRandom),
  createSTIProject: createCreator<SketchTextProject, SketchTextCreateReq>(sketchTextUrl),
  deleteSTIProject: deleteCreator<undefined>(sketchTextUrl),
  updateSTIProject: updateCreator<SketchTextProject, SketchTextUpdateReq>(sketchTextUrl),
  generateSTIProjectFormData: urlActionCreator<SketchTextProject, FormData>(
    sketchTextUrl,
    generateAction
  ),
  listSTIProjectOutput: listCreator<
    PaginationResponse<SketchTextOutputImage>,
    SketchTextOutputListReq
  >(sketchTextOutputUrl),
  uploadSketch: urlActionCreator<SketchTextProject, FormData>(sketchTextUrl, uploadAction),
  updateThreshold: urlActionCreator<SketchTextProject, SketchTextUpdateThresholdReq>(
    sketchTextUrl,
    uploadAction
  ),
  removeSketch: urlActionCreator<SketchTextProject, SketchTextRemoveSketchReq>(
    sketchTextUrl,
    uploadAction
  ),
  cleanOutputs: urlActionCreator(sketchTextUrl, cleanOutputsAction),
  deleteSTIProjectOutput: deleteCreator<undefined>(sketchTextOutputUrl)
}
