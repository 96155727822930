import {
  MutableRefObject,
  useCallback,
  useEffect,
  useContext,
  useRef,
  useState,
  useMemo
} from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import playformTheme from 'playformTheme'
import _map from 'lodash/map'
import _throttle from 'lodash/throttle'
import _debounce from 'lodash/debounce'
import _isNill from 'lodash/isNil'
import { BookmarkUtils, UrlUtils } from '../TextUtils'
import { useMatch } from 'react-router'
import { values } from 'appConstants'
import { CollectionListReq, Category } from 'models/ApiModels'
import { sortUtils } from '../DataProcessingUtils'
import { emptyFunction } from 'utils'
import { DropdownItem } from 'components/DropdownMenu'
import { apiActions } from 'duck/ApiDuck'
import { BreakpointContext, WindowDimensionContext } from 'appContext'
import { NEW_PROJECT_ACTION, route, SUB_RESULT } from 'routes'

type useExecuteOnChangeParam<OnChangeOnType = string> = {
  executor: () => void
  executeOn?: OnChangeOnType
}
/* Hook to execute certain function if some variable changed */
export const useExecuteOnChange = <OnChangeOnType>(
  param: useExecuteOnChangeParam<OnChangeOnType>
) => {
  const { executor, executeOn } = param
  const previousExecuteOn = usePrevious<OnChangeOnType>(executeOn)

  useEffect(() => {
    if (!_isNill(executeOn) && executeOn !== previousExecuteOn) {
      executor()
    }
  }, [executor, previousExecuteOn, executeOn])
}

type UseSavedListParam = {
  listUserImageBookmarkLoading?: string | boolean
  scope: string
  listLength?: number
  listUserImageBookmark: typeof apiActions.projects.listUserImageBookmark
  hide?: boolean
}

/*  */
export * from './useWindowDimensionDetector'
export * from './useDimension'

/*  */
export const useSavedList = (param: UseSavedListParam) => {
  const { scope, listLength, hide, listUserImageBookmarkLoading, listUserImageBookmark } = param

  const listUserImageBookmarkLoadingRef = useRefEffect(listUserImageBookmarkLoading)
  const adjustedScope = BookmarkUtils.adjustBookmarkScope(scope)

  useEffect(() => {
    if (!listLength && !hide && scope) {
      listUserImageBookmark({
        next: false,
        param: {
          scope: adjustedScope,
          limit: 30,
          offset: 0
        }
      })
    }
  }, [adjustedScope, listLength, hide, scope, listUserImageBookmark])

  const loadMoreSavedImages = useCallback(() => {
    if (!listUserImageBookmarkLoadingRef.current) {
      listUserImageBookmark({
        next: true,
        param: {
          scope: adjustedScope,
          limit: 30,
          offset: 0
        }
      })
    }
  }, [adjustedScope, listUserImageBookmark, listUserImageBookmarkLoadingRef])

  return {
    loadMoreSavedImages
  }
}

/*  */
export function useRefEffect<ReturnType>(value: any) {
  const ref = useRef<ReturnType>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}

/*  */
export function usePrevious<ReturnType>(value: any) {
  const ref = useRef<ReturnType>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function usePreventClose(shouldPrevent: boolean, beforeClose: Function) {
  const beforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (shouldPrevent) {
        beforeClose()
        e.preventDefault()
        e.returnValue = ''
      }
    },
    [shouldPrevent, beforeClose]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
    return () => window.removeEventListener('beforeunload', beforeUnload)
  }, [beforeUnload, shouldPrevent])
}

// const isClient = typeof window === 'object'
// const getSize = () => ({
//   width: isClient ? window.innerWidth : undefined,
//   height: isClient ? window.innerHeight : undefined
// })

// export function useWindowSize() {
//   const [windowSize, setWindowSize] = useState(getSize())

//   const handleResize = useCallback(() => {
//     setWindowSize(getSize())
//   }, [])

//   useEffect(() => {
//     if (!isClient) {
//       return
//     }

//     window.addEventListener('resize', handleResize)
//     return () => window.removeEventListener('resize', handleResize)
//   }, [handleResize])

//   return windowSize
// }

// Use Event listener, inspiration from : https://usehooks.com/useEventListener/
export function useEventListener(eventName: string, handler: Function, element: any = window) {
  const savedHandler = useRef<Function>(emptyFunction)

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event: Event) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

/* Get Media Query Breakpoint key hook */
const theme = playformTheme
const breakpointKeys = [...theme.breakpoints.keys]
const breakpointKeysReversed = [...breakpointKeys].reverse()

export const useWidth = () => {
  if (!theme.breakpoints) return undefined

  return (
    breakpointKeysReversed.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))

      return !output && matches ? key : output
    }, '') || 'xs'
  )
}

export const useBreakpoint = () => {
  return useContext(BreakpointContext)
}

export const useWindowDimension = () => {
  return useContext(WindowDimensionContext)
}

/* Check if the url search is coming from Pre trained model */
export const useIsFromPretrainedModelButton = (search?: string) => {
  const isFromPretrainedModelButton = useMemo(() => {
    if (search) {
      const { source } = UrlUtils.getActionParam(search, 'source') ?? {}
      if (source === values.SOURCE_FROM_PRETRAINED) {
        return true
      }
    }
    return false
  }, [search])

  return isFromPretrainedModelButton
}

/*  Get list of sort by collection*/
const sortByData = [
  {
    key: 'name',
    label: 'A-Z'
  },
  {
    key: 'modified',
    label: 'Date'
  }
]

export const useSortByCollectionListData = (
  listParams: CollectionListReq,
  additionalData: DropdownItem[] = []
) => {
  const sortByDataAdjusted = useMemo(() => {
    const { sortDirection } = sortUtils.toObject(listParams.ordering)

    return sortDirection === '-'
      ? _map(sortByData, value => (value.key === 'name' ? { ...value, label: 'Z-A' } : value))
      : sortByData
  }, [listParams.ordering])

  return [...additionalData, ...sortByDataAdjusted]
}

export const useCollectionCategoriesDropdownData = (categories: Category[]) => {
  const categoriesValue = useMemo(
    () =>
      _map([{ id: 'all', name: 'All Categories' }, ...categories], value => ({
        value: `${value.id}`,
        label: value.name
      })),

    [categories]
  )

  return categoriesValue
}

/*
 * Make loading only appear after certain of time.
 */

export const useDelayedLoading = (
  loading?: boolean | string,
  trueTimeout: number = 300,
  falseTimeout: number = 500
) => {
  const [delayedLoading, setDelayedLoading] = useState(loading)
  const timeoutId = useRef(0)

  useEffect(() => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current)
    }
    const delay = loading ? trueTimeout : falseTimeout

    timeoutId.current = window.setTimeout(() => {
      setDelayedLoading(loading)
    }, delay)

    return () => {
      if (timeoutId.current) window.clearTimeout(timeoutId.current)
    }
  }, [falseTimeout, loading, trueTimeout])

  return delayedLoading
}

export const useDelayedData = <DataType>(data?: DataType, timeout: number = 250) => {
  const [delayedParam, setDelayedParam] = useState<DataType | undefined>(data)
  const timeoutId = useRef(0)

  useEffect(() => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current)
    }
    if (data) {
      setDelayedParam(data)
    } else {
      timeoutId.current = window.setTimeout(() => {
        setDelayedParam(data)
      }, timeout)
    }

    return () => {
      if (timeoutId.current) window.clearTimeout(timeoutId.current)
    }
  }, [data, timeout])
  return delayedParam
}

export const useBooleanDelayedData = (dataProp: boolean, timeout?: number) => {
  const data = dataProp
  const dataState = useDelayedData(dataProp, timeout)

  return !(!data && !dataState)
}

export const useDelayedRender = (timeout: number = 300, shouldStartCount: boolean = true) => {
  const [shouldRender, setIsShouldRender] = useState<boolean>(false)
  const timeoutId = useRef(0)

  useEffect(() => {
    if (timeoutId.current || !shouldStartCount) {
      window.clearTimeout(timeoutId.current)
    }
    timeoutId.current = window.setTimeout(() => {
      if (shouldStartCount) {
        setIsShouldRender(true)
      }
    }, timeout)

    return () => {
      if (timeoutId.current) window.clearTimeout(timeoutId.current)
    }
  }, [shouldRender, shouldStartCount, timeout])

  return shouldRender
}

/* Hide Menu Hooks */
type UseHideMenuParam = {
  menuHeight: number
}

const BASE_MENU_POSITION = 100

export const useHideMenu = (param: UseHideMenuParam) => {
  const { menuHeight = 54 } = param
  /* Hide menu Code */
  const [menuPosition, setMenuPosition] = useState(BASE_MENU_POSITION)
  const prevScrollTop = useRef(0)
  const menuPositionTop = useRef(0)
  const returnValue = useRef({ handleScroll: (scrollTop: number) => {}, menuPosition })

  const handleScroll = useCallback(
    (scrollTop: number = 0) => {
      const deltaScroll = prevScrollTop.current - scrollTop

      if (scrollTop < menuHeight && menuPositionTop.current !== 100) {
        menuPositionTop.current = 100
        setMenuPosition(100)
      } else {
        if (deltaScroll > 0 && !menuPositionTop.current) {
          menuPositionTop.current = 100
          setMenuPosition(100)
        } else if (deltaScroll < 0 && scrollTop >= menuHeight && menuPositionTop.current) {
          menuPositionTop.current = 0
          setMenuPosition(0)
        }
      }
      prevScrollTop.current = scrollTop
    },
    [menuHeight]
  )

  returnValue.current.handleScroll = handleScroll
  returnValue.current.menuPosition = menuPosition

  return returnValue.current
}

/*  */
export const useExecute = <Parameter, ReturnType>(
  func: (param: Parameter) => ReturnType,
  param: Parameter
) => {
  return func(param)
}

export const useInSketchPage = () => {
  const match = useMatch(route.SKETCH_PROJECTS.getPath())

  return Boolean(match)
}

export const useInSavedResultPage = () => {
  const match = useMatch(route.SAVED_RESULTS.getPath())

  return Boolean(match)
}

export const useInExplorePage = () => {
  const match = useMatch(`${route.EXPLORE.getPath(1)}/*`)

  return Boolean(match)
}
export const useInExploreSubPage = () => {
  const match = useMatch(`${route.EXPLORE.getPath(2)}/*`)

  return Boolean(match)
}

export const useInProFilterPage = () => {
  const match = useMatch(route.PRO_FILTER_PROJECTS.getPath())
  return Boolean(match)
}

export const useInTrainProjectPage = () => {
  const match = useMatch(`${route.TRAIN_PROJECTS.getPath(2)}/*`)

  return Boolean(match)
}
export const useInTrainProjectResultPage = () => {
  const match = useMatch(`${route.TRAIN_PROJECTS.getPath(2)}/${SUB_RESULT}/*`)

  return Boolean(match)
}

export const useInMixProjectsPage = () => {
  const match = useMatch(`${route.MIX_PROJECTS.getPath(2)}/*`)

  return Boolean(match)
}

export const useInNewUpscalePage = () => {
  const match = useMatch(route.UPSCALES.getPath(1, `/${NEW_PROJECT_ACTION}`))

  return Boolean(match)
}

export const useInTextToImagePage = () => {
  const matchImage = useMatch(`${route.TEXT_TO_IMAGE_PROJECTS.getPath(2)}/*`)

  return Boolean(matchImage)
}

export const useInTextToVideoPage = () => {
  const matchVideo = useMatch(`${route.TEXT_TO_VIDEO_PROJECTS.getPath(2)}/*`)

  return Boolean(matchVideo)
}

export const useInGenericAppProjectPage = () => {
  const matchImage = useMatch(`${route.GENERIC_APP_PROJECTS.getPath(2)}/*`)

  return Boolean(matchImage)
}

export const useInSketchTextToImage = () => {
  const matchImage = useMatch(`${route.SKETCHTEXT_TO_IMAGE_PROJECTS.getPath(2)}/*`)

  return Boolean(matchImage)
}

export const useIsInSignInPage = () => {
  const match = useMatch(route.SIGN_IN.getPath())

  return Boolean(match)
}

export const useIsWelcomePage = () => {
  const match = useMatch(route.WELCOME.getPath())

  return Boolean(match)
}

export const useInSubpage = () => {
  const inSketchPage = useInSketchPage()
  const inProFilterPage = useInProFilterPage()
  const inTrainProjectPage = useInTrainProjectPage()
  const inMixProjectsPage = useInMixProjectsPage()
  const inNewUpscalesPage = useInNewUpscalePage()
  const inTextToImagePage = useInTextToImagePage()
  const inGenericAppProjectPage = useInGenericAppProjectPage()
  const inSketchTextToImage = useInSketchTextToImage()

  const match =
    inSketchPage ||
    inProFilterPage ||
    inTrainProjectPage ||
    inMixProjectsPage ||
    inNewUpscalesPage ||
    inTextToImagePage ||
    inGenericAppProjectPage ||
    inSketchTextToImage

  return Boolean(match)
}

/* Scroll event hooks using requestAnimationFrame */
const DEFAULT_OFFSET_BOTTOM = 150
const isBrowser = typeof window !== `undefined`

type useScrollType = {
  ref?: MutableRefObject<any>
  useWindow?: boolean
  // element?: HTMLDivElement | Window | null
  fpsLimit?: number
  onBottom?: Function
  onBottomThrottled?: Function
  onScroll?: Function
  onScrollX?: Function
  offsetBottom?: number
}

export const useScroll = (props: useScrollType) => {
  const {
    fpsLimit = 60,
    onBottom,
    onBottomThrottled,
    onScroll,
    onScrollX,
    offsetBottom,
    ref,
    useWindow
  } = props
  const currentScrollPosition = useRef({
    x: 0,
    y: 0
  })
  const timerHelper = useRef({
    now: 0,
    startTime: 0,
    then: 0,
    elapsed: 0
  })

  useEffect(() => {
    if (!isBrowser) return undefined

    const scrollNode = useWindow ? window : ref?.current || undefined

    // console.log('useEffectScroll', scrollNode, onBottom)

    if (scrollNode && (onScroll || onScrollX || onBottom || onBottomThrottled)) {
      // console.log('useEffectScroll if')
      const fpsInterval = 1000 / fpsLimit

      const debouncedOnBottom = onBottom
        ? _debounce(onBottom as (...args: any) => any, 250)
        : undefined
      const throttledOnBottom = onBottomThrottled
        ? _throttle(onBottomThrottled as (...args: any) => any, 250)
        : undefined

      const animate = (newtime: number) => {
        // stop
        if (!timerHelper?.current || !scrollNode) {
          return
        }

        // If still scrolling, request another frame
        if (
          currentScrollPosition.current.y !== (useWindow ? window.scrollY : scrollNode.scrollTop) ||
          currentScrollPosition.current.x !== (useWindow ? window.scrollX : scrollNode.scrollLeft)
        ) {
          requestAnimationFrame(animate)
        }

        // calc elapsed time since last loop
        timerHelper.current.now = newtime
        timerHelper.current.elapsed = timerHelper.current.now - timerHelper.current.then

        // if enough time has elapsed, draw the next frame
        if (timerHelper.current.elapsed > fpsInterval) {
          // Get ready for next frame by setting then=now, but...
          // Also, adjust for fpsInterval not being multiple of 16.67
          timerHelper.current.then =
            timerHelper.current.now - (timerHelper.current.elapsed % fpsInterval)

          //
          const offsetBottomAdjusted =
            offsetBottom || offsetBottom === 0
              ? offsetBottom
              : !offsetBottom
                ? DEFAULT_OFFSET_BOTTOM
                : 0

          // Events
          if (
            (onScroll || throttledOnBottom) &&
            currentScrollPosition.current.y !== (useWindow ? window.scrollY : scrollNode.scrollTop)
          ) {
            onScroll?.(
              useWindow ? window.scrollY : scrollNode.scrollTop,
              scrollNode,
              currentScrollPosition.current.y < (useWindow ? window.scrollY : scrollNode.scrollTop)
                ? 'down'
                : 'up'
            )

            if (
              throttledOnBottom &&
              (useWindow
                ? window.scrollY > window.innerHeight - offsetBottomAdjusted
                : scrollNode.scrollTop + scrollNode.clientHeight >
                  scrollNode.scrollHeight - offsetBottomAdjusted)
            )
              throttledOnBottom()
          }

          if (
            onScrollX &&
            currentScrollPosition.current.x !== (useWindow ? window.scrollX : scrollNode.scrollLeft)
          )
            onScrollX(
              useWindow ? window.scrollX : scrollNode.scrollLeft,
              scrollNode,
              currentScrollPosition.current.x < (useWindow ? window.scrollX : scrollNode.scrollLeft)
                ? 'right'
                : 'left'
            )

          if (
            debouncedOnBottom &&
            currentScrollPosition.current.y !==
              (useWindow ? window.scrollY : scrollNode.scrollTop) &&
            (useWindow
              ? window.scrollY > window.innerHeight - offsetBottomAdjusted
              : scrollNode.scrollTop + scrollNode.clientHeight >
                scrollNode.scrollHeight - offsetBottomAdjusted)
          ) {
            debouncedOnBottom()
          }

          //
          currentScrollPosition.current = {
            x: useWindow ? window.scrollX : scrollNode.scrollLeft,
            y: useWindow ? window.scrollY : scrollNode.scrollTop
          }
        }
      }

      const handleOnScroll = () => {
        requestAnimationFrame(animate)
      }

      // console.log('addEventListener scroll')

      useWindow
        ? window.addEventListener('scroll', handleOnScroll)
        : scrollNode?.addEventListener('scroll', handleOnScroll)

      return () => {
        // console.log('removeEventListener scroll')
        useWindow
          ? window.removeEventListener('scroll', handleOnScroll)
          : scrollNode?.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [onScroll, onScrollX, onBottom, onBottomThrottled, fpsLimit, offsetBottom, ref, useWindow])
}
