import {
  catchError,
  filter,
  switchMap,
  mergeMap,
  withLatestFrom,
  map,
  concatMap,
  exhaustMap,
  takeUntil,
  tap
} from 'rxjs/operators'

import { ApiUtils } from 'utils'
import _compact from 'lodash/compact'
import _pick from 'lodash/pick'
import { Epic } from 'redux-observable'
import { RootState, RootActionType } from 'duck'
import { getType, isActionOf } from 'typesafe-actions'
import { of, merge } from 'rxjs'
import { apiActions, sharedActions, apiSelectors } from 'duck/ApiDuck'
import { PaginationRequestParams, PageParams } from 'models/ApiModels'
import { GetText } from 'utils/TextUtils'
import { push } from 'redux-first-history'
import { route } from 'routes'
import MixPanelUtils, { DataUtils } from 'utils/MixPanelUtils'
import { bannerActions } from 'duck/AppDuck/BannerDuck'

const retrieveSpaceImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.retrieveSpaceImage)),
    withLatestFrom(state$),
    map(([action, state]) => {
      const key = GetText.spaceImageNaturalKey(action.payload)
      const spaceImage = apiSelectors.spaceImage(state)
      return {
        isCurrentlyFetch: spaceImage.currentFetchState[key],
        ...action
      }
    }),
    filter(({ isCurrentlyFetch }) => !Boolean(isCurrentlyFetch)),
    mergeMap(({ type, payload }) =>
      merge(
        of(apiActions.mixImage.setSpaceImageFetchState(payload)),
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.retrieveSpaceImage(payload).pipe(
          mergeMap(response => [
            apiActions.mixImage.retrieveSpaceImageResponse({ data: response.data, req: payload }),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveOriginImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.retrieveOriginImage)),
    filter(({ payload }) => Boolean(payload.pos_x >= 0) && Boolean(payload.pos_y >= 0)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.retrieveOriginImage(payload).pipe(
          mergeMap(response =>
            _compact([
              apiActions.mixImage.retrieveOriginImageResponse({
                data: response.data,
                req: payload
              }),
              sharedActions.setLoading({ loading: false, type })
            ])
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listRandomImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.listRandomImages)),
    withLatestFrom(store$),
    map(([action]) => {
      const { next = false } = action.payload
      return { next, type: action.type, project: action.payload.project, payload: action.payload }
    }),
    exhaustMap(({ next, type, project, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.listRandomImages().pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mixImage.listRandomImagesResponse({
              data: response.data,
              next,
              project
            })
          ]),
          takeUntil(
            action$.pipe(
              filter(isActionOf(sharedActions.cancelRequest)),
              filter(({ payload }) => payload === getType(apiActions.mixImage.listRandomImages))
            )
          ),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const listMixImageGenreEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.listMixImageGenre)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.listMixImageGenre(payload).pipe(
          concatMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mixImage.listMixImageGenreResponse(response.data)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const createMixImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.createMixImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.createMixImage(payload).pipe(
          concatMap(response => [
            apiActions.mixImage.createMixImageResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const updateMixImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.updateMixImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.updateMixImage(payload.id, payload).pipe(
          withLatestFrom(state$),
          tap(([response, state]) => {
            const mixGenreData = apiSelectors.mixImageGenreData(state)
            if (payload.name) {
              MixPanelUtils.track<'PROJECT__RENAME'>(
                'Project - Rename',
                DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
                  mixProject: response.data,
                  mixGenreData
                })
              )
            }
          }),
          concatMap(([response]) => [
            apiActions.mixImage.updateMixImageResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const retrieveMixImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.retrieveMixImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.retrieveMixImage(payload).pipe(
          mergeMap(response => [
            apiActions.imageEnhancement.checkOnUserImagesAdjust({
              userImages: response.data?.outputs ?? []
            }),
            apiActions.mixImage.retrieveMixImageResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const deleteMixImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.deleteMixImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      projectData: { ...(apiSelectors.mixImageProjects(state)?.[action.payload] ?? {}) },
      mixImageGenreData: apiSelectors.mixImageGenreData(state),
      ...action
    })),
    switchMap(({ type, payload, projectData, mixImageGenreData }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.deleteMixImage(payload).pipe(
          tap(() => {
            MixPanelUtils.track<'PROJECT__DELETE'>(
              'Project - Delete',
              DataUtils.getProjectParam<'pretrain_mix_project'>('pretrain_mix_project', {
                mixProject: projectData,
                mixGenreData: mixImageGenreData
              })
            )
          }),
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mixImage.deleteMixImageResponse(payload),
            apiActions.users.retrieveEquity(),
            push(route.MIX_PROJECTS.getUrl())
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const updateThumbnailEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.updateThumbnail)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.updateThumbnail(payload).pipe(
          map(() => apiActions.mixImage.updateThumbnailResponse(payload)),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )
const generateMixImagesEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.generateMixImage)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      canTrain: apiSelectors.equity(state)?.upsellState?.canTrain ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrain }) => !canTrain),
          map(() =>
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'mix-image',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            })
          )
        ),
        of(param).pipe(
          filter(({ canTrain }) => Boolean(canTrain)),
          switchMap(({ type, payload }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.mixImage.generateMixImage(payload.id, payload).pipe(
                concatMap(response => [
                  sharedActions.setLoading({ loading: false, type }),
                  apiActions.mixImage.generateMixImageResponse(response.data)
                ]),
                catchError(err =>
                  of(sharedActions.setError({ error: err.response, type, req: payload }))
                )
              )
            )
          )
        )
      )
    )
  )

const generateMixImagePreviewEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.generateMixImagePreview)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action,
      canTrain: apiSelectors.equity(state)?.upsellState?.canTrain ?? true
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ canTrain }) => !canTrain),
          map(() =>
            bannerActions.upsell.show({
              dismissable: true,
              upsellLocation: 'mix-image',
              contentMode: 'used-up',
              position: 'float-bottom-banner-overlay'
            })
          )
        ),
        of(param).pipe(
          filter(({ canTrain }) => Boolean(canTrain)),
          switchMap(({ type, payload }) =>
            merge(
              of(sharedActions.setLoading({ loading: true, type })),
              ApiUtils.mixImage.generateMixImagePreview(payload.id, payload).pipe(
                mergeMap(response => [
                  apiActions.mixImage.generateMixImagePreviewResponse(response.data),
                  sharedActions.setLoading({ loading: false, type })
                ]),
                catchError(err =>
                  of(sharedActions.setError({ error: err.response, type, req: payload }))
                )
              )
            )
          )
        )
      )
    )
  )
const listMixImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.listMixImage)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false } = action.payload
      const params = next ? { next: state.api.mixImage.mixImageProject.lastReq?.next } : {}
      const extraParams = next ? undefined : (param as PaginationRequestParams)
      return { next, params, extraParams, type: action.type }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.listMixImage(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mixImage.listMixImageResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )

const retrieveUploadImageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.retrieveUploadImage)),
    mergeMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.retrieveUploadImage(payload).pipe(
          mergeMap(response => [
            apiActions.mixImage.retrieveUploadImageResponse(response.data),
            sharedActions.setLoading({ loading: false, type })
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const createUploadImagesEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.createUploadImage)),
    filter(action => Boolean(action.payload.image)),
    map(action => {
      const formData = new FormData()
      formData.append('image', action.payload.image)
      formData.append('genre', `${action.payload.genre}`)

      return { formData, action, payload: action.payload }
    }),
    mergeMap(({ formData, action, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type: action.type })),
        ApiUtils.mixImage.createUploadImage(formData).pipe(
          mergeMap(response => [
            apiActions.mixImage.createUploadImageResponse({
              data: response.data
            }),
            sharedActions.setLoading({ loading: false, type: action.type })
          ]),
          catchError(err =>
            of(
              sharedActions.setError({
                error: err.response,
                type: action.type,
                req: {
                  ...payload,
                  file: _pick(payload.image, 'name', 'lastModified', 'size', 'type', 'slice')
                }
              })
            )
          )
        )
      )
    )
  )

const listUploadImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, store) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.listUploadImage)),
    withLatestFrom(store),
    map(([action, state]) => {
      const { param, next = false, silentUpdate } = action.payload
      const params = next ? { next: state.api.mixImage.uploadImages?.lastReq?.next } : {}
      const extraParams = next ? undefined : (param as PageParams)
      return { next, params, extraParams, type: action.type, silentUpdate }
    }),
    filter(({ next, params }) => {
      if (next && !params.next) {
        return false
      }
      return true
    }),
    switchMap(({ next, params, extraParams, type, silentUpdate }) =>
      merge(
        of({ silentUpdate }).pipe(
          filter(({ silentUpdate }) => !Boolean(silentUpdate)),
          map(() => sharedActions.setLoading({ loading: true, type: type }))
        ),
        ApiUtils.mixImage.listUploadImage(params, extraParams).pipe(
          mergeMap(response => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mixImage.listUploadImageResponse({
              data: response.data,
              next
            })
          ]),
          catchError(err =>
            of(sharedActions.setError({ error: err.response, type, req: { params, extraParams } }))
          )
        )
      )
    )
  )
const deleteUploadImageEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.mixImage.deleteUploadImage)),
    switchMap(({ type, payload }) =>
      merge(
        of(sharedActions.setLoading({ loading: true, type })),
        ApiUtils.mixImage.deleteUploadImage(payload).pipe(
          concatMap(() => [
            sharedActions.setLoading({ loading: false, type }),
            apiActions.mixImage.deleteUploadImageResponse(payload)
          ]),
          catchError(err => of(sharedActions.setError({ error: err.response, type, req: payload })))
        )
      )
    )
  )

const mixImageEpics = [
  deleteUploadImageEpic,
  updateMixImageEpic,
  listRandomImagesEpic,
  createMixImageEpic,
  retrieveSpaceImageEpic,
  retrieveUploadImageEpic,
  retrieveOriginImageEpic,
  listMixImageGenreEpic,
  generateMixImagePreviewEpic,
  generateMixImagesEpic,
  retrieveMixImageEpic,
  deleteMixImageEpic,
  updateThumbnailEpic,
  listMixImageEpic,
  createUploadImagesEpic,
  listUploadImageEpic
]

export default mixImageEpics
