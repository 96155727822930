import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import _compact from 'lodash/compact'
import { combineEpics, Epic } from 'redux-observable'
import { withLatestFrom, map, mergeMap, filter, startWith, take } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import {
  EngineConfigKeyType,
  MixGenreKeyType,
  SketchGenreKeyType,
  ProcessUIData,
  ProcessGroupType,
  ProcessType,
  ProArtFilterGenreKeyType
} from 'models/ApiModels'
import { PROCESS_GROUP_STYLIZE } from './ProcessesData'
import {
  name,
  MIX_GENRE_ADDITIONAL_DATA,
  SKETCH_GENRE_ADDITIONAL_DATA,
  PROJECT_CATEGORY_ADDITIONAL_DATA,
  STYLIZE_GENRE_ADDITIONAL_DATA
} from 'appConstants'
import { merge, of } from 'rxjs'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { appActions } from 'duck/AppDuck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { IconProps } from 'components/Icon'
import { dialogActions } from 'duck/AppDuck/DialogDuck'

// Constants
const NAMESPACE = '@@page/ProcessesPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

export type ProcessGroup = {
  key: ProcessGroupType
  title: string
  subtitle: string
  icon: React.FC
}
export interface ProcessItem {
  desc?: string
  name: string
  codename: ProcessType
  is_active?: boolean
  in_beta?: boolean
  icon?: React.FC<IconProps>
  hide?: boolean
}

export type OpenDetailType = {
  processGroup: ProcessGroupType
  process: ProcessType
  processData?: ProcessItem & ProcessUIData
}

const Utils = {
  getDefaultName: (isUserHasProject: boolean, processGroup: ProcessGroupType) => {
    if (processGroup === 'sketch') {
      return isUserHasProject
        ? name.DEFAULT_SKETCH_PROJECT_NAME
        : name.FIRST_TIME_DEFAULT_SKETCH_PROJECT_NAME
    } else if (processGroup === 'mix') {
      return name.DEFAULT_MIX_IMAGE_PROJECT_NAME
    } else if (processGroup === 'style_transfer') {
      return name.DEFAULT_STYLIZE_PROJECT_NAME
    } else {
      return isUserHasProject
        ? name.DEFAULT_TRAIN_PROJECT_NAME
        : name.FIRST_TIME_DEFAULT_PROJECT_NAME
    }
  }
}

// Actions
export const actions = {
  setOpenDetail: createAction(creator('SET_OPEN_DETAIL'))<OpenDetailType | undefined>(),
  createProject: createAction(creator('CREATE_PROJECT'))(),
  loadInitialData: createAction(creator('LOAD_INITIAL_DATA'))()
}

// Selectors
const selectProcessesPanel = (state: RootState) => state.container.processesPanel

const selectProcessesData = createSelector(
  apiSelectors.sketchGenres,
  apiSelectors.mixImageGenres,
  apiSelectors.engineConfigs,
  apiSelectors.proArtFilterGenres,
  apiSelectors.genericAppProjectList,
  (
    sketchGenres,
    mixImageGenres,
    engineConfigs,
    proArtFilterGenres,
    genericAppProjectList
  ): { [key in ProcessGroupType]?: OpenDetailType['processData'][] } => ({
    mix: _map(mixImageGenres, genre => ({
      ...genre,
      ...(MIX_GENRE_ADDITIONAL_DATA[genre.codename as MixGenreKeyType] ?? {}),
      codename: genre.codename as MixGenreKeyType
    })),
    style_transfer: [
      _map(proArtFilterGenres, genre => ({
        ...genre,
        ...(STYLIZE_GENRE_ADDITIONAL_DATA[genre.codename as ProArtFilterGenreKeyType] ?? {}),
        codename: genre.codename as ProArtFilterGenreKeyType,
        name: PROCESS_GROUP_STYLIZE.title
      }))[0]
    ],
    sketch: _map(sketchGenres, genre => ({
      ...genre,
      ...(SKETCH_GENRE_ADDITIONAL_DATA[genre.codename as SketchGenreKeyType] ?? {}),
      codename: genre.codename as SketchGenreKeyType
    })),
    train: _sortBy(
      _map(engineConfigs, engineConfig => ({
        ...engineConfig,
        ...(PROJECT_CATEGORY_ADDITIONAL_DATA[engineConfig.codename as EngineConfigKeyType] ?? {}),
        codename: engineConfig.codename as EngineConfigKeyType
      })),
      value => value.in_beta
    )
  })
)
const selectProcessesDataFlat = createSelector(
  selectProcessesData,
  (
    processesData
  ): {
    [codename: string]: OpenDetailType['processData']
  } => {
    const result: { [key: string]: OpenDetailType['processData'] } = {}

    _map(processesData, processesGroup => {
      _map(processesGroup, processes => {
        result[processes?.codename ?? ''] = processes
      })
    })
    return result
  }
)

const selectOpenDetailRaw = createSelector(selectProcessesPanel, panel => panel.openDetail)
const selectOpenDetail = createSelector(
  selectProcessesDataFlat,
  selectOpenDetailRaw,
  (processData, openDetail) =>
    openDetail
      ? {
          ...openDetail,
          processData: processData[openDetail.process]
        }
      : undefined
)

export const selectors = {
  openDetail: selectOpenDetail,
  processesData: selectProcessesData,
  processesDataFlat: selectProcessesDataFlat
}

export type ProcessesPanelState = {
  openDetail?: OpenDetailType
}

const initial: ProcessesPanelState = {
  openDetail: undefined
}

const reducer = produce((state: ProcessesPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setOpenDetail): {
      const value = payload as ActionType<typeof actions.setOpenDetail>['payload']
      state.openDetail = value
      return
    }
    default:
  }
}, initial)

// Epics

const loadInitialDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadInitialData)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      hasProArtFilterGenre: apiSelectors.hasProArtFilterGenres(state),
      hasSketchGenre: apiSelectors.hasSketchGenres(state),
      hasSketchTextGenres: apiSelectors.hasSketchTextGenres(state),
      hasGenericAppDataList: apiSelectors.hasGenericAppDataList(state),
      hasMixImageGenre: apiSelectors.hasMixImageGenre(state),
      hasEngineConfig: apiSelectors.hasEngineConfig(state),
      retrieveEngineConfigLoading: apiSelectors.loading['engine.listEngineConfig'](state)
    })),
    mergeMap(
      ({
        hasSketchTextGenres,
        hasGenericAppDataList,
        hasProArtFilterGenre,
        hasSketchGenre,
        hasMixImageGenre,
        hasEngineConfig,
        retrieveEngineConfigLoading
      }) =>
        _compact([
          !hasGenericAppDataList &&
            apiActions.genericApp.listGenericApp({ param: { limit: 30, offset: 0 } }),
          !hasProArtFilterGenre &&
            apiActions.proArtFilter.listProArtFilterGenres({ limit: 30, offset: 0 }),
          !hasSketchGenre && apiActions.sketchToImage.listSketchGenre({ limit: 30, offset: 0 }),
          !hasSketchTextGenres &&
            apiActions.sketchTextToImage.listSTIGenre({ limit: 30, offset: 0 }),
          !hasMixImageGenre && apiActions.mixImage.listMixImageGenre({ limit: 30, offset: 0 }),
          !hasEngineConfig && !retrieveEngineConfigLoading && apiActions.engine.listEngineConfig()
        ])
    )
  )

const createProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.createProject)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      openDetail: selectors.openDetail(state),
      isUserHasProject: apiSelectors.isUserHasProject(state)
    })),
    filter(({ openDetail }) => Boolean(openDetail)),
    map(({ openDetail, isUserHasProject }) => {
      return {
        name: Utils.getDefaultName(isUserHasProject, openDetail?.processGroup ?? 'mix'),
        processGroup: openDetail?.processGroup ?? 'mix',
        process: openDetail?.process ?? 'morph'
      }
    }),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ processGroup }) => processGroup !== 'train'),
          mergeMap(param => [
            appActions.projectAction.createProject(param),
            actions.setOpenDetail(undefined),
            dialogActions.closeAllDialog()
          ])
        ),
        of(param).pipe(
          filter(({ processGroup }) => processGroup === 'train'),
          mergeMap(param =>
            action$.pipe(
              filter(isActionOf(apiActions.projects.createResponse)),
              take(1),
              mergeMap(() => [actions.setOpenDetail(undefined), dialogActions.closeAllDialog()]),
              startWith(appActions.projectAction.createProject(param))
            )
          )
        )
      )
    )
  )

export const epics = combineEpics(loadInitialDataEpic, createProjectEpic)
export default reducer
