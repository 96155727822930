import { TextTransform } from 'utils/TextUtils'
import produce from 'immer'
import { combineEpics, Epic } from 'redux-observable'
import _compact from 'lodash/compact'
import {
  withLatestFrom,
  map,
  filter,
  mergeMap,
  concatMap,
  startWith,
  take,
  delay
} from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { TrainProject, PostCreateReq, UserImage } from 'models/ApiModels'
import { constructProjectData } from 'utils/DataProcessingUtils'
import { appSelectors } from 'duck/AppDuck'
import { merge, of } from 'rxjs'
import { push } from 'redux-first-history'
import { route } from 'routes'
import { AppEvents, eventEmiterActions } from 'duck/AppDuck/EventEmitterDuck'
import { dialogActions, FormDialog } from 'duck/AppDuck/DialogDuck'
import { snackBarActions } from 'duck/AppDuck/SnackBarDuck'

// Constants
const NAMESPACE = '@@panel/ShareFeedPanel'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// -- Type Definition
export type CollectionScopeType = {
  collectionId: number
  isPublic: boolean
}
export type ShareData = {
  entity: PostCreateReq['entity']
  related: PostCreateReq['related']
  userImage?: UserImage
  projectData?: TrainProject
}

const INITIAL_SHARE_DATA: ShareData = {
  entity: { id: 0, type: '' },
  related: { id: 0, type: 'training_project' }
}

export const Utils = {
  generateFormDataKey: (shareData?: ShareData) => {
    return `${shareData?.entity?.type}_${shareData?.entity?.id ?? 0}`
  },
  getImageData: (shareData?: ShareData) => {
    const type = shareData?.entity.type

    if (type === 'user_image') {
      return shareData?.userImage?.adjustData?.output ?? shareData?.userImage
    }

    return undefined
  }
}

// Actions
export const actions = {
  setShareData: createAction(creator('SET_SHARE_DATA'))<ShareData | undefined>(),
  createPost: createAction(creator('CREATE_POST'))(),
  redirectToPostPage: createAction(creator('REDIRECT_TO_POST_PAGE'))<number>(),
  shareFormActions: {
    initFormData: createAction(creator('SHARED_FORM_ACTIONS/INIT_FORM_DATA'))<ShareData>(),
    updateContent: createAction(creator('SHARED_FORM_ACTIONS/UPDATE_CONTENT'))<string>(),
    setIsProjectPrivate: createAction(
      creator('SHARED_FORM_ACTIONS/SET_IS_PROJECT_PRIVATE')
    )<boolean>(),
    setIsCollectionsPrivate: createAction(
      creator('SHARED_FORM_ACTIONS/SET_IS_COLLECTIONS_PRIVATE')
    )<CollectionScopeType>(),
    resetForm: createAction(creator('SHARED_FORM_ACTIONS/RESET_ACTION'))()
  }
}

// Selectors
const selectShareFeedPanel = (state: RootState) => state.container.shareFeedPanel
const selectShareDataRaw = createSelector(selectShareFeedPanel, shareFeed => shareFeed.shareData)
const selectShareData = createSelector(
  selectShareDataRaw,
  apiSelectors.projects,
  apiSelectors.inputs,
  apiSelectors.currentUserId,
  apiSelectors.userImages,
  apiSelectors.adjustedImages,
  (shareData, projects, inputs, currentUserId, userImages, adjustedImages) => {
    const newShareData: ShareData = shareData ? { ...shareData } : INITIAL_SHARE_DATA

    const entity = shareData?.entity
    const related = shareData?.related

    const type = related?.type
    const relatedId = related?.id

    if (entity?.type === 'user_image') {
      const userImage = userImages[entity?.id ?? 0]
      const userImageAdjusted = userImage
        ? { ...userImage, adjustData: adjustedImages[userImage.adjust ?? 0] }
        : undefined
      newShareData['userImage'] = userImageAdjusted
      newShareData['entity'] = {
        ...newShareData['entity'],
        id: userImageAdjusted?.adjustData?.output?.id ?? entity?.id
      }
    }

    if (type === 'training_project' && relatedId) {
      const project = constructProjectData({
        projectId: relatedId,
        projects,
        inputs,
        currentUserId
      })
      newShareData['projectData'] = project
    }

    return newShareData
  }
)
const selectShareFormData = createSelector(selectShareFeedPanel, socialPanel => {
  const shareData = socialPanel.shareData
  if (shareData) {
    const key = Utils.generateFormDataKey(shareData)
    return socialPanel.shareFormData?.[key]
  }
  return undefined
})

export const selectors = {
  socialPanel: selectShareFeedPanel,
  shareFormData: selectShareFormData,
  shareData: selectShareData,
  hasEntityData: createSelector(selectShareData, value => Boolean(value?.entity?.id))
}

// Reducer
export type ShareFormDataType = {
  content: string
  isProjectPrivate: boolean
  isCollectionPrivate: { [collectionId: number]: boolean }
}

export type ShareFeedPanelState = {
  shareData?: ShareData
  shareFormData: {
    [outputImageId: string]: ShareFormDataType
  }
}
const initialShareFormData: ShareFormDataType = {
  content: '',
  isProjectPrivate: false,
  isCollectionPrivate: {}
}

const initial: ShareFeedPanelState = {
  shareData: undefined,
  shareFormData: {}
}

const reducer = produce((state: ShareFeedPanelState, { type, payload }) => {
  switch (type) {
    case getType(actions.setShareData): {
      const shareData = payload as ActionType<typeof actions.setShareData>['payload']

      state.shareData = shareData
      return
    }
    case getType(actions.shareFormActions.resetForm): {
      const shareData = state.shareData
      const formKey = Utils.generateFormDataKey(shareData)
      state.shareFormData[formKey] = initialShareFormData
      return
    }
    case getType(actions.shareFormActions.initFormData): {
      const shareData = state.shareData
      const formKey = Utils.generateFormDataKey(shareData)
      if (!state.shareFormData[formKey]) {
        state.shareFormData[formKey] = initialShareFormData
      }
      return
    }
    case getType(actions.shareFormActions.updateContent): {
      const content = payload as ActionType<
        typeof actions.shareFormActions.updateContent
      >['payload']

      const shareData = state.shareData
      const formKey = Utils.generateFormDataKey(shareData)
      if (state.shareFormData[formKey]) {
        state.shareFormData[formKey].content = content
      }
      return
    }
    case getType(actions.shareFormActions.setIsProjectPrivate): {
      const isProjectPrivate = payload as ActionType<
        typeof actions.shareFormActions.setIsProjectPrivate
      >['payload']

      const shareData = state.shareData
      const formKey = Utils.generateFormDataKey(shareData)
      state.shareFormData[formKey].isProjectPrivate = isProjectPrivate
      return
    }
    case getType(actions.shareFormActions.setIsCollectionsPrivate): {
      const data = payload as ActionType<
        typeof actions.shareFormActions.setIsCollectionsPrivate
      >['payload']

      const shareData = state.shareData
      const formKey = Utils.generateFormDataKey(shareData)
      const currentData = state.shareFormData[formKey].isCollectionPrivate
      state.shareFormData[formKey].isCollectionPrivate = {
        ...currentData,
        [data.collectionId]: data.isPublic
      }
      return
    }

    default:
  }
}, initial)

// Epics

const listenOnAppEvent: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(eventEmiterActions.emit)),
    mergeMap(({ payload }) =>
      merge(
        of(payload).pipe(
          filter(({ [AppEvents.INIT_SHARE]: event }) => Boolean(event)),
          map(({ [AppEvents.INIT_SHARE]: event }) => event?.payload),
          filter(shareData => Boolean(shareData)),
          concatMap(shareData =>
            _compact([
              actions.setShareData(shareData),
              shareData && actions.shareFormActions.initFormData(shareData)
            ])
          )
        )
      )
    )
  )

const initFormDataEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.shareFormActions.initFormData)),
    withLatestFrom(state$),
    map(([_, state]) => selectors.shareData(state)),
    mergeMap(shareData =>
      merge(
        of(shareData).pipe(
          filter(shareData => shareData?.related?.type === 'training_project'),
          map(shareData => ({ isPrivate: Boolean(shareData?.projectData?.is_private) })),
          mergeMap(({ isPrivate }) => [actions.shareFormActions.setIsProjectPrivate(isPrivate)])
        ),
        of(
          apiActions.imageEnhancement.checkOnUserImagesAdjust({
            userImages: _compact([shareData.userImage])
          })
        )
      )
    )
  )

const redirectToPostPageEpic: Epic<RootActionType, RootActionType, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.redirectToPostPage)),
    concatMap(({ payload }) => [
      snackBarActions.close(),
      push(
        `${route.EXPLORE.getUrl({
          subRoute: 'posts',
          id: payload
        })}?mode=posts&ordering=recent&scope=all`
      )
    ])
  )

const createPostEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.createPost)),
    withLatestFrom(state$),
    map(([_, state]) => {
      return {
        activeDialog: appSelectors.dialog.activeDialog(state),
        shareData: selectors.shareData(state),
        formData: selectors.shareFormData(state)
      }
    }),
    map(({ shareData, formData, ...restParam }) => {
      const createParam: PostCreateReq = {
        entity: shareData.entity,
        related: shareData.related,
        description: formData?.content ?? ''
      }
      let shouldUpdateProject = false
      let updateProjectParam = {
        id: 0,
        is_private: false
      }
      if (shareData?.related.type === 'training_project') {
        const isProjectPrivate = shareData?.projectData?.is_private
        const isFormPrivate = formData?.isProjectPrivate ?? false

        shouldUpdateProject = Boolean(
          isProjectPrivate !== isFormPrivate && shareData?.projectData?.is_private
        )
        updateProjectParam = {
          id: shareData?.projectData?.id ?? 0,
          is_private: isFormPrivate
        }
      }

      return { createParam, shouldUpdateProject, updateProjectParam, ...restParam }
    }),
    mergeMap(({ createParam, shouldUpdateProject, updateProjectParam, activeDialog }) =>
      merge(
        of(createParam).pipe(
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(apiActions.social.createPostResponse)),
              take(1),
              concatMap(({ payload }) =>
                _compact([
                  eventEmiterActions.emit({
                    [AppEvents.POST_SHARED]: {
                      event: AppEvents.POST_SHARED
                    }
                  }),
                  actions.shareFormActions.resetForm(),
                  activeDialog === FormDialog.SHARE_FEED && dialogActions.closeDialog(),
                  snackBarActions.show({
                    show: true,
                    content: 'You’ve successfully posted!',
                    actionText: 'SEE POST',
                    onClickAction: actions.redirectToPostPage(payload.id)
                  })
                ])
              ),
              startWith(apiActions.social.createPost(createParam))
            )
          )
        ),
        of({ shouldUpdateProject, updateProjectParam }).pipe(
          filter(({ shouldUpdateProject, updateProjectParam }) =>
            Boolean(shouldUpdateProject && updateProjectParam.id)
          ),
          map(() => apiActions.projects.update(updateProjectParam))
        ),
        of(createParam).pipe(
          delay(6000),
          map(() => snackBarActions.close())
        )
      )
    )
  )
export const epics = combineEpics(
  listenOnAppEvent,
  initFormDataEpic,
  createPostEpic,
  redirectToPostPageEpic
)
export default reducer
