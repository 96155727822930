import {
  apiActions,
  ProjectsActionsType,
  MixImageActionType,
  EngineActionType,
  SketchToImageActionType,
  ProArtFilterActionType,
  ImageEnhancementActionsType,
  PaymentActionType,
  SocialActionType,
  TextToImageActionType,
  GenericAppActionType,
  SketchTextToImageActionType
} from '../actions'
import { SharedActionsType, sharedActions } from '../sharedActions'
import {
  PaginationResponse,
  TrainProject,
  ProjectSummary,
  ProjectMix,
  UserImage,
  Bookmark,
  SnapshotType,
  DEFAULT_BOOKMARK_SCOPE,
  UserImageBookmarkListResponse,
  InferenceRecord,
  TrainOutputImageResponse
} from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _flattenDeep from 'lodash/flattenDeep'
import _map from 'lodash/map'
import _without from 'lodash/without'
import _uniq from 'lodash/uniq'
import _compact from 'lodash/compact'
import { generateRequestHistoryKey, groupResultSnapshot } from 'utils/DataProcessingUtils'
import { BookmarkUtils } from 'utils/TextUtils'

export type BookmarkListData = {
  list?: number[]
  lastRequest?: UserImageBookmarkListResponse
}

export const getUserImageBookmarkPlaceholder = (
  userImage: UserImage,
  scope: string
): Bookmark<'user-image'> => ({
  created: '',
  id: userImage.bookmark ?? 0,
  item: userImage,
  scope,
  user: 0
})

export type ProjectsState = {
  currentProjectId?: number
  projectList: number[] | null
  projectActiveList: number[] | null
  projectPublicList: number[] | null
  lastProjectPublicListReq: PaginationResponse<TrainProject> | null
  lastListReq: PaginationResponse<TrainProject> | null
  projects: {
    [projectId: number]: TrainProject
  }
  projectMixes: {
    [projectMixId: number]: ProjectMix
  }
  projectSummaryRetrieved: boolean
  projectSummary: ProjectSummary | null

  userImages: { [userImageId: number]: UserImage }
  trainOutputImages: {
    [projectId: string]: {
      sampleImage?: UserImage //1 sampling of image, for decide the resolution of this project
      //For batch data, separated by order and batch
      snapshotData?: SnapshotType
      requestHistory?: {
        [id: string]: TrainOutputImageResponse
      }
    }
  }
  randomImagesLists: {
    [projectId: number]: number[]
  }
  randomMixImageLists: {
    [mixProjectId: number]: number[]
  }
  userImageBookmarks: {
    [bookmarkId: number]: Bookmark<'user-image'>
  }
  userImageBookmarkLists: {
    [bookmarkScope: string]: BookmarkListData | undefined
  }
  inferences: {
    [id: number]: InferenceRecord
  }
}

export const initialProjectsState: ProjectsState = {
  currentProjectId: undefined,
  projectList: null,
  projectActiveList: null,
  projectPublicList: null,
  lastProjectPublicListReq: null,
  lastListReq: null,
  projects: {},
  projectMixes: {},
  projectSummaryRetrieved: false,
  projectSummary: null,

  userImages: {},
  trainOutputImages: {},
  randomImagesLists: {},
  randomMixImageLists: {},

  userImageBookmarks: {},
  userImageBookmarkLists: {},

  inferences: {}
}

export const projectsReducer = createReducer<
  ProjectsState,
  | ProjectsActionsType
  | SharedActionsType
  | MixImageActionType
  | EngineActionType
  | SketchToImageActionType
  | ProArtFilterActionType
  | ImageEnhancementActionsType
  | PaymentActionType
  | SocialActionType
  | TextToImageActionType
  | GenericAppActionType
  | SketchTextToImageActionType
>(initialProjectsState)
  .handleAction(apiActions.projects.setCurrentProject, (state, action) => {
    const id = action?.payload
    return {
      ...state,
      currentProjectId: id
    }
  })
  .handleAction(apiActions.projects.listResponse, (state, action) => {
    const { data, reloadList, req } = action.payload

    const results = data.results ?? []

    const scope = req?.scope ?? 'current_user'

    let addedData = {}
    if (scope === 'public') {
      const prevData = reloadList ? [] : state.projectPublicList ?? []
      addedData = {
        projectPublicList: [...prevData, ..._map(results, result => result.id)],
        lastProjectPublicListReq: data
      }
    }
    if (scope === 'current_user') {
      const prevData = reloadList ? [] : state.projectList ?? []
      addedData = {
        projectList: [...prevData, ..._map(results, result => result.id)],
        lastListReq: data
      }
    }
    /* combine results with current data, to prevent flashing progress*/
    const combinedResults = results.map(result => ({
      ...(state.projects[result.id] ?? {}),
      ...result
    }))

    return {
      ...state,
      ...addedData,
      projects: {
        ...state.projects,
        ..._keyBy(combinedResults, result => result.id)
      }
    }
  })
  .handleAction(apiActions.projects.listActiveProjectsResponse, (state, action) => {
    const results = action?.payload?.results ?? []
    return {
      ...state,
      projectActiveList: _map(results, result => result.id),
      projects: {
        ...state.projects,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(apiActions.projects.retrieveResponse, (state, action) => {
    const { data } = action.payload
    const currentData = state.projects[data.id]
    const combinedData = currentData ? { ...currentData, ...data } : data

    return {
      ...state,
      projects: {
        ...state.projects,
        [data.id]: combinedData
      }
    }
  })
  .handleAction(
    [
      apiActions.projects.startResponse,
      apiActions.projects.moreResponse,
      apiActions.projects.updateResponse,
      apiActions.projects.stopResponse
    ],
    (state, action) => {
      const currentData = state.projects[action.payload.id]
      const combinedData = currentData ? { ...currentData, ...action.payload } : action.payload
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.id]: combinedData
        }
      }
    }
  )
  .handleAction(
    [apiActions.projects.createResponse, apiActions.projects.copyResponse],
    (state, action) => {
      const currentData = state.projects[action.payload.id]
      const combinedData = currentData ? { ...currentData, ...action.payload } : action.payload

      return {
        ...state,
        projectList: [action.payload.id, ...(state.projectList || [])],
        projects: {
          ...state.projects,
          [action.payload.id]: combinedData
        }
      }
    }
  )
  .handleAction(apiActions.projects.deleteResponse, (state, action) => {
    const { [action.payload]: deletedProject, ...projects } = state.projects
    const projectList = _without(state.projectList || [], deletedProject.id)
    return {
      ...state,
      projectList,
      projects
    }
  })
  .handleAction(apiActions.projects.retrieveSummaryResponse, (state, action) => {
    return {
      ...state,
      projectSummaryRetrieved: true,
      projectSummary: { ...action.payload }
    }
  })
  .handleAction(apiActions.projects.retrieveOutputImagesResponse, (state, action) => {
    const { data, req } = action.payload
    const currentResult = state.trainOutputImages[req.project] || {}
    const { results, ...requestHistoryItem } = data
    const requestHistory = currentResult.requestHistory ? { ...currentResult.requestHistory } : {}
    const requestHistoryKey = generateRequestHistoryKey(req)

    requestHistory[requestHistoryKey] = requestHistoryItem

    const userImages = results?.map(result => result.image) ?? []

    const currentData = state.trainOutputImages[req.project]
    return {
      ...state,
      trainOutputImages: {
        ...state.trainOutputImages,
        [req.project]: {
          ...currentData,
          sampleImage: userImages[0],
          snapshotData: groupResultSnapshot(results, currentResult.snapshotData),
          requestHistory
        }
      },
      userImages: {
        ...state.userImages,
        ..._keyBy(userImages, userImage => userImage?.id ?? 0)
      }
    }
  })
  .handleAction(apiActions.projects.createProjectMixResponse, (state, action) => {
    const projectMix = action.payload
    const projectData = state.projects[projectMix.project]

    return {
      ...state,
      projectMixes: {
        ...state.projectMixes,
        [projectMix.id]: projectMix
      },
      projects: projectData
        ? {
            ...state.projects,
            [projectMix.project]: {
              ...projectData,
              mix: projectMix.id
            }
          }
        : state.projects
    }
  })
  .handleAction(
    [
      apiActions.projects.retrieveProjectMixResponse,
      apiActions.projects.generateProjectMixResponse,
      apiActions.projects.generateRandomProjectMixResponse
    ],
    (state, action) => {
      const projectMix = action.payload
      const outputs = action.payload?.outputs ?? []
      return {
        ...state,
        projectMixes: {
          ...state.projectMixes,
          [projectMix.id]: projectMix
        },
        userImages: {
          ...state.userImages,
          ..._keyBy(outputs, output => output.id)
        }
      }
    }
  )
  .handleAction(apiActions.projects.listRandomImagesResponse, (state, action) => {
    const { data, next, params } = action.payload
    const project = params?.project ?? 0

    const results = data.results ?? []
    const prevData = next ? state.randomImagesLists[project] ?? [] : []

    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(results, result => result.id)
      },
      randomImagesLists: {
        ...state.randomImagesLists,
        [project]: _uniq([...prevData, ..._map(results, result => result.id)])
      }
    }
  })
  .handleAction(apiActions.projects.listUserImageBookmarkResponse, (state, action) => {
    const { data, next, req } = action.payload
    const scope = req?.scope ?? DEFAULT_BOOKMARK_SCOPE

    const results = data.results ?? []
    const prevData = next ? state.userImageBookmarkLists[scope]?.list ?? [] : []

    const userImages = results.reduce(
      (resultReduce, datum) => {
        resultReduce[datum.item.id] = {
          ...datum.item,
          bookmark: datum.id
        }
        return resultReduce
      },
      {} as ProjectsState['userImages']
    )

    const userImageBookmarks = results.reduce(
      (resultReduce, datum) => {
        resultReduce[datum.id] = {
          ...datum,
          item: {
            ...datum.item,
            bookmark: datum.id
          }
        }

        return resultReduce
      },
      {} as ProjectsState['userImageBookmarks']
    )

    return {
      ...state,
      userImages: {
        ...state.userImages,
        ...userImages
      },
      userImageBookmarks: {
        ...state.userImageBookmarks,
        ...userImageBookmarks
      },
      userImageBookmarkLists: {
        ...state.userImageBookmarkLists,
        [scope]: {
          lastRequest: data,
          list: _uniq([...prevData, ..._map(results, result => result.id)])
        }
      }
    }
  })
  .handleAction(apiActions.projects.toggleUserImageBookmarkInstantResponse, (state, action) => {
    const { userImageId, bookmark } = action.payload
    const userImage = state.userImages[userImageId]
    const userImageUpdated = { ...userImage, bookmark: bookmark ? -1 : null }

    const bookmarkId = userImage.bookmark
    const bookmarkData = bookmarkId ? state.userImageBookmarks[bookmarkId] : undefined

    return {
      ...state,
      userImages: {
        ...state.userImages,
        [userImageId]: userImageUpdated
      },
      userImageBookmarks: bookmarkData
        ? {
            ...state.userImageBookmarks,
            [bookmarkData.id]: { ...bookmarkData, item: userImageUpdated }
          }
        : state.userImageBookmarks
    }
  })
  .handleAction(apiActions.projects.toggleUserImageBookmarkResponse, (state, action) => {
    const { data, deletedData } = action.payload

    /* If bookmark created */
    if (data) {
      const userImage = state.userImages[data?.item]
      const scope = data.scope
      const adjustedScope = BookmarkUtils.adjustBookmarkScope(scope ?? '')

      if (userImage) {
        const userImageUpdated = { ...userImage, bookmark: data?.id ?? null }

        const bookmarkId = data.id
        const bookmarkData =
          state.userImageBookmarks[bookmarkId ?? 0] ??
          getUserImageBookmarkPlaceholder(userImageUpdated, data.scope)

        return {
          ...state,
          userImages: {
            ...state.userImages,
            [userImage.id]: userImageUpdated
          },
          userImageBookmarks: bookmarkData
            ? {
                ...state.userImageBookmarks,
                [bookmarkData.id]: { ...bookmarkData, ...data, item: userImageUpdated }
              }
            : state.userImageBookmarks,
          userImageBookmarkLists: {
            ...state.userImageBookmarkLists,
            [adjustedScope]: state.userImageBookmarkLists[adjustedScope]
              ? {
                  ...state.userImageBookmarkLists[adjustedScope],
                  list: [
                    bookmarkData?.id ?? 0,
                    ...(state.userImageBookmarkLists[adjustedScope]?.list ?? [])
                  ]
                }
              : undefined,
            [DEFAULT_BOOKMARK_SCOPE]: state.userImageBookmarkLists[DEFAULT_BOOKMARK_SCOPE]
              ? {
                  ...state.userImageBookmarkLists[DEFAULT_BOOKMARK_SCOPE],
                  list: [
                    bookmarkData?.id ?? 0,
                    ...(state.userImageBookmarkLists[DEFAULT_BOOKMARK_SCOPE]?.list ?? [])
                  ]
                }
              : undefined
          }
        }
      }
    }

    /* If bookmark deleted */
    if (deletedData) {
      const bookmarkData = state.userImageBookmarks[deletedData.bookmarkId]
      const userImageData = state.userImages[deletedData.userImageId]
      const scope = deletedData.scope
      const adjustedScope = BookmarkUtils.adjustBookmarkScope(scope ?? '')

      return {
        ...state,
        userImages: userImageData
          ? { ...state.userImages, [userImageData.id]: { ...userImageData, bookmark: null } }
          : state.userImages,
        userImageBookmarks: bookmarkData
          ? {
              ...state.userImageBookmarks,
              [bookmarkData.id]: {
                ...bookmarkData,
                item: { ...bookmarkData.item, bookmark: null }
              }
            }
          : state.userImageBookmarks,

        userImageBookmarkLists: {
          ...state.userImageBookmarkLists,
          [adjustedScope]: state.userImageBookmarkLists[adjustedScope]
            ? {
                ...state.userImageBookmarkLists[adjustedScope],
                list: _without(
                  state.userImageBookmarkLists[adjustedScope]?.list ?? [],
                  deletedData.bookmarkId
                )
              }
            : undefined,
          [DEFAULT_BOOKMARK_SCOPE]: state.userImageBookmarkLists[DEFAULT_BOOKMARK_SCOPE]
            ? {
                ...state.userImageBookmarkLists[DEFAULT_BOOKMARK_SCOPE],
                list: _without(
                  state.userImageBookmarkLists[DEFAULT_BOOKMARK_SCOPE]?.list ?? [],
                  deletedData.bookmarkId
                )
              }
            : undefined
        }
      }
    }

    return state
  })
  /*  Mix Image Actions */
  .handleAction(apiActions.mixImage.listRandomImagesResponse, (state, action) => {
    const { data, next, project = 0 } = action.payload

    const results = data ?? []
    const prevData = next ? state.randomMixImageLists[project] : []

    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(results, result => result.id)
      },
      randomMixImageLists: {
        ...state.randomMixImageLists,
        [project]: _uniq([...prevData, ..._map(results, result => result.id)])
      }
    }
  })
  .handleAction(
    [apiActions.mixImage.generateMixImageResponse, apiActions.mixImage.retrieveMixImageResponse],
    (state, action) => {
      const mixImageProject = action.payload
      const { outputs = [], preview } = mixImageProject
      return {
        ...state,
        userImages: {
          ...state.userImages,
          ..._keyBy(_compact([...outputs, preview]), output => output.id)
        }
      }
    }
  )
  .handleAction(apiActions.mixImage.retrieveOriginImageResponse, (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      userImages: {
        ...state.userImages,
        [data.id]: data
      }
    }
  })
  .handleAction(apiActions.mixImage.generateMixImagePreviewResponse, (state, action) => {
    const { preview } = action.payload
    return preview
      ? {
          ...state,
          userImages: {
            ...state.userImages,
            [preview.id]: preview
          }
        }
      : state
  })
  .handleAction(apiActions.mixImage.listUploadImageResponse, (state, action) => {
    const { data } = action.payload
    const results = data.results || []

    const userImages = results.map(result => result.image)

    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(userImages, result => result.id)
      }
    }
  })
  .handleAction(apiActions.mixImage.createUploadImageResponse, (state, action) => {
    const { data } = action.payload

    return {
      ...state,
      userImages: {
        ...state.userImages,
        [data.image.id]: data.image
      }
    }
  })
  .handleAction(apiActions.mixImage.retrieveUploadImageResponse, (state, action) => {
    const data = action.payload

    return {
      ...state,
      userImages: {
        ...state.userImages,
        [data.image.id]: data.image
      }
    }
  })
  .handleAction(
    [
      apiActions.engine.copyClipResponse,
      apiActions.engine.createClipResponse,
      apiActions.engine.updateClipResponse,
      apiActions.engine.retrieveClipResponse
    ],
    (state, action) => {
      const clip = action.payload

      const userImages = state.userImages
      const keyframesMerged = clip.keyframes.map(userImage => {
        const currentUserImage = userImages[userImage.id]
        return currentUserImage ? { ...currentUserImage, ...userImage } : userImage
      })

      return {
        ...state,
        userImages: {
          ...state.userImages,
          ..._keyBy(keyframesMerged, keyframe => keyframe.id)
        }
      }
    }
  )
  .handleAction(apiActions.engine.listClipResponse, (state, action) => {
    const results = action.payload.data.results
    const keyframes = _flattenDeep(results?.map(result => result.keyframes))
    const userImages = state.userImages
    const keyframesMerged = keyframes.map(userImage => {
      const currentUserImage = userImages[userImage.id]
      return currentUserImage ? { ...currentUserImage, ...userImage } : userImage
    })

    return {
      ...state,
      userImages: {
        ...userImages,
        ..._keyBy(keyframesMerged, keyframe => keyframe.id)
      }
    }
  })
  .handleAction(apiActions.sketchToImage.listSketchOutputResponse, (state, action) => {
    const { data } = action.payload

    const results = data?.results ?? []
    const outputImages = results.map(result => result.image)
    const bookmarks = _compact(
      outputImages.map(output =>
        output.bookmark
          ? { id: output.bookmark, user: 0, item: output, scope: '', created: '' }
          : undefined
      )
    )
    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(outputImages, result => result.id)
      },
      userImageBookmarks: {
        ...state.userImageBookmarks,
        ..._keyBy(bookmarks, result => result.id)
      }
    }
  })
  .handleAction(
    [
      apiActions.sketchToImage.createSketchProjectResponse,
      apiActions.sketchToImage.generateSketchProjectResponse,
      apiActions.sketchToImage.retrieveSketchProjectResponse,
      apiActions.sketchToImage.updateSketchProjectResponse
    ],
    (state, action) => {
      const outputSketch = action.payload?.outputs || []
      const outputImages = outputSketch.map(output => output.image)

      return {
        ...state,
        userImages: {
          ...state.userImages,
          ..._keyBy(outputImages, result => result.id)
        }
      }
    }
  )
  .handleAction(
    [
      apiActions.projects.createInferenceRecordResponse,
      apiActions.projects.retrieveInferenceResponse,
      apiActions.projects.startInferenceResponse
    ],
    (state, action) => {
      const { payload } = action
      const data = { ...payload }
      const outputs = data.outputs ?? []
      const inputs = data.inputs ?? []

      return {
        ...state,
        inferences: {
          ...state.inferences,
          [payload.id]: { ...data }
        },
        userImages: {
          ...state.userImages,
          ..._keyBy(outputs, result => result.id),
          ..._keyBy(inputs, result => result.id)
        }
      }
    }
  )
  .handleAction(apiActions.proArtFilter.listProArtFilterOutputResponse, (state, action) => {
    const { data } = action.payload

    const results = data?.results ?? []
    const outputImages = results.map(result => result.image)
    const bookmarks = _compact(
      outputImages.map(output =>
        output.bookmark
          ? { id: output.bookmark, user: 0, item: output, scope: '', created: '' }
          : undefined
      )
    )
    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(outputImages, result => result.id)
      },
      userImageBookmarks: {
        ...state.userImageBookmarks,
        ..._keyBy(bookmarks, result => result.id)
      }
    }
  })
  .handleAction(apiActions.textToImage.retrieveTIProjectResponse, (state, action) => {
    const image = action.payload.image

    return image
      ? {
          ...state,
          userImages: {
            ...state.userImages,
            [image.id]: image
          }
        }
      : state
  })
  .handleAction(apiActions.textToImage.listTIProjectOutputResponse, (state, action) => {
    const outputs = action.payload.data.results ?? []
    const outputImages = outputs.map(value => value.image)
    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(outputImages, output => output.id)
      }
    }
  })

  .handleAction(apiActions.genericApp.retrieveGenericAppProjectResponse, (state, action) => {
    const image = action.payload.image

    return image
      ? {
          ...state,
          userImages: {
            ...state.userImages,
            [image.id]: image
          }
        }
      : state
  })
  .handleAction(apiActions.genericApp.listGenericAppOutputResponse, (state, action) => {
    const outputs = action.payload.data.results ?? []
    const outputImages = outputs.map(value => value.image)
    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(outputImages, output => output.id)
      }
    }
  })

  .handleAction(apiActions.projects.addInferenceInputResponse, (state, action) => {
    const { data } = action.payload
    const outputs = data.outputs ?? []
    const inputs = data.inputs ?? []

    return {
      ...state,
      inferences: {
        ...state.inferences,
        [data.id]: { ...data },
        ..._keyBy(outputs, result => result.id),
        ..._keyBy(inputs, result => result.id)
      }
    }
  })
  .handleAction(
    [
      apiActions.imageEnhancement.retrieveAdjustedImageResponse,
      apiActions.imageEnhancement.createAdjustedImageResponse
    ],
    (state, action) => {
      const { output } = action.payload

      return {
        ...state,
        userImages: {
          ...state.userImages,
          [output.id]: output
        }
      }
    }
  )
  .handleAction(apiActions.imageEnhancement.connectAdjustedImageResponse, (state, action) => {
    const { data, req } = action.payload

    const currentUserImages = state.userImages[req.image]

    return currentUserImages
      ? {
          ...state,
          userImages: {
            ...state.userImages,
            [req.image]: {
              ...currentUserImages,
              adjust: data.id
            }
          }
        }
      : state
  })
  .handleAction(apiActions.imageEnhancement.deleteAdjustedImageResponse, (state, action) => {
    const { userImageId } = action.payload

    const currentUserImages = state.userImages[userImageId]

    return currentUserImages
      ? {
          ...state,
          userImages: {
            ...state.userImages,
            [userImageId]: {
              ...currentUserImages,
              adjust: null
            }
          }
        }
      : state
  })
  .handleAction(
    [
      apiActions.imageEnhancement.retrieveUpscaleImageResponse,
      apiActions.imageEnhancement.createUpscaleImageResponse
    ],
    (state, action) => {
      const { data } = action.payload

      const image = { [data.image.id]: data.image }
      const outputId = data.output?.id
      const output = outputId ? { [outputId]: data.output } : {}

      return {
        ...state,
        userImages: {
          ...state.userImages,
          ...image,
          ...output
        }
      }
    }
  )
  .handleAction(apiActions.imageEnhancement.listUpscaleImageResponse, (state, action) => {
    const { data } = action.payload

    const userImages = data.results?.map(value => value.image)

    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(userImages, result => result.id)
      }
    }
  })
  .handleAction(apiActions.imageEnhancement.retrieveUserImageUpscalesResponse, (state, action) => {
    const { upscaleImages } = action.payload
    const userImages = upscaleImages.map(value => value.image)

    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(userImages, result => result.id)
      }
    }
  })
  .handleAction(apiActions.payment.createDownloadImageResponse, (state, action) => {
    const { data, image, originalImage } = action.payload

    const currentUserImages = state.userImages[image]
    const currentOriginalUserImages = state.userImages[originalImage ?? image]
    const currentAdjustedImages = state.userImages[currentUserImages.adjust ?? image]

    return currentUserImages
      ? {
          ...state,
          userImages: {
            ...state.userImages,
            [image]: {
              ...currentUserImages,
              download: data.id
            },
            [originalImage ?? image]: {
              ...currentOriginalUserImages,
              download: data.id
            },
            [currentUserImages.adjust ?? image]: {
              ...currentAdjustedImages,
              download: data.id
            }
          }
        }
      : state
  })
  .handleAction(apiActions.social.listUserProjectResponse, (state, action) => {
    const { data } = action.payload

    const results = data.results ?? []

    /* combine results with current data, to prevent flashing progress*/
    const combinedResults = results.map(result => ({
      ...(state.projects[result.id] ?? {}),
      ...result
    }))

    return {
      ...state,
      projects: {
        ...state.projects,
        ..._keyBy(combinedResults, result => result.id)
      }
    }
  })
  .handleAction(apiActions.sketchTextToImage.listSTIProjectOutputResponse, (state, action) => {
    const { data } = action.payload

    const results = data?.results ?? []
    const outputImages = results.map(result => result.image)
    const bookmarks = _compact(
      outputImages.map(output =>
        output.bookmark
          ? { id: output.bookmark, user: 0, item: output, scope: '', created: '' }
          : undefined
      )
    )
    return {
      ...state,
      userImages: {
        ...state.userImages,
        ..._keyBy(outputImages, result => result.id)
      },
      userImageBookmarks: {
        ...state.userImageBookmarks,
        ..._keyBy(bookmarks, result => result.id)
      }
    }
  })
  .handleAction(sharedActions.reset, () => initialProjectsState)
