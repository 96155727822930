/* eslint-disable tailwindcss/no-custom-classname */
import { CSSProperties, forwardRef, memo, useMemo, useRef, RefObject } from 'react'
import {
  FixedSizeList as ListWindow,
  FixedSizeListProps as ListWindowProps,
  ListChildComponentProps,
  ListOnScrollProps,
  areEqual
} from 'react-window'
import { twMerge } from 'tailwind-merge'
import clsx from 'clsx'
import { toNumber } from 'utils/NumberUtils'
import { FixedSizeListProps } from '../index'
import { ListConfigType } from '../../index'

const DEFAULT_COLUMN_WIDTH = 0

type ListProps<Item> = Pick<ListWindowProps, 'height' | 'width'> &
  Pick<FixedSizeListProps<Item>, 'debug' | 'onScroll' | 'renderItem'> & {
    config: ListConfigType
    containerRef: RefObject<HTMLDivElement | null>
    dataLength: number
    onBottom?: () => void
    setRef?: React.MutableRefObject<any>
  }

const ListItem = memo((props: ListChildComponentProps) => {
  const { data: dataProps, style, index } = props
  const {
    columnCount,
    columnWidth,

    configMaxWidth,
    configGutter,
    configPaddingY,
    configPaddingTop,
    configPaddingBottom,
    configPaddingX,
    configPaddingLeft,
    configPaddingRight,

    renderItem,
    rowHeight,
    width
  } = dataProps

  const useMaxWidth = configMaxWidth && width > configMaxWidth
  const gutter = configGutter
  const paddingY = configPaddingY
  const paddingTop = configPaddingTop || configPaddingY || 0
  // const paddingBottom = toNumber(config.paddingBottom || config.paddingY) || 0

  const paddingX = configPaddingX
  const paddingLeft = configPaddingLeft || configPaddingX || 0
  const paddingRight = configPaddingRight || configPaddingX || 0

  if (columnCount > 1) {
    const rowInnerStyle: CSSProperties = {}
    const ChildrenComponent: any[] = []

    if (paddingTop || paddingY || paddingLeft || paddingRight || paddingX || gutter) {
      rowInnerStyle.paddingRight = !useMaxWidth ? paddingRight || paddingX : 0
      rowInnerStyle.paddingLeft = !useMaxWidth ? paddingLeft || paddingX : 0

      if (gutter) {
        rowInnerStyle.left = -(gutter / 2)
        rowInnerStyle.right = -(gutter / 2)
        rowInnerStyle.width = gutter ? `calc(100% + ${gutter}px)` : '100%'
      }
    }

    for (let j = 0; j < columnCount; j++) {
      const adjustedIndex = index * columnCount + j

      ChildrenComponent[j] = (
        // data[adjustedIndex] ? (
        <div
          key={adjustedIndex}
          className="h-full w-full"
          style={
            gutter
              ? {
                  padding: gutter / 2
                }
              : undefined
          }
        >
          {renderItem({
            // item: data[adjustedIndex],
            index: adjustedIndex,
            width: columnWidth,
            height: rowHeight
          })}
        </div>
      )
      // ) : (
      //   <div
      //     key={adjustedIndex}
      //     className="h-full w-full"
      //     style={
      //       gutter
      //         ? {
      //             padding: gutter / 2
      //           }
      //         : undefined
      //     }
      //   ></div>
      // )
    }

    return (
      <div style={style}>
        <div style={rowInnerStyle} className="relative flex h-full">
          {ChildrenComponent}
        </div>
      </div>
    )
  }

  let rowStyle: CSSProperties | undefined = undefined
  let rowInnerStyle: CSSProperties | undefined = undefined

  if (paddingTop || paddingY || paddingLeft || paddingRight || paddingX || gutter) {
    rowInnerStyle = {}

    if (paddingTop || paddingY || paddingLeft || paddingRight || paddingX) {
      rowInnerStyle.paddingRight = paddingRight || paddingX
      rowInnerStyle.paddingLeft = paddingLeft || paddingX
    }

    if (gutter) {
      rowStyle = {
        ...style,
        top: toNumber(style.top) - gutter / 2,
        paddingTop: gutter / 2,
        paddingBottom: gutter / 2
      }
    }
  }

  return (
    <div style={rowStyle || style}>
      <div className="h-full w-full" style={rowInnerStyle}>
        {renderItem({
          index,
          width: columnWidth,
          height: rowHeight
        })}
      </div>
    </div>
  )
}, areEqual)

function List<Item>(props: ListProps<Item>) {
  const { config, dataLength, height, onBottom, onScroll, renderItem, setRef, width } = props

  /* Config */
  const configMaxWidth = toNumber(config.maxWidth)
  const configGutter = toNumber(config.gutter ?? 0)
  const configPaddingY = toNumber(config.paddingY) || 0
  const configPaddingTop = toNumber(config.paddingTop || config.paddingY) || 0
  const configPaddingBottom = toNumber(config.paddingBottom || config.paddingY) || 0
  const configPaddingX = toNumber(config.paddingX) || 0
  const configPaddingLeft = toNumber(config.paddingLeft || config.paddingX) || 0
  const configPaddingRight = toNumber(config.paddingRight || config.paddingX) || 0
  const configColumns = config.columns ? toNumber(config.columns) : undefined
  const configDefaultColumnWidth = config.defaultColumnWidth
    ? toNumber(config.defaultColumnWidth)
    : undefined
  const configSquare = config.square
  const configRowHeight = toNumber(config.rowHeight)
  /* Config END */

  const outerRef = useRef<HTMLElement>(null)
  const outerRefAdjusted = setRef || outerRef
  const innerRef = useRef<HTMLElement>(null)

  // const listScrollableElement = outerRef.current
  const scrollbarSize = 0 //useMemo(() => {
  //   if (listScrollableElement) {
  //     return listScrollableElement?.offsetWidth - listScrollableElement?.clientWidth
  //   }

  //   return 0
  // }, [listScrollableElement])

  const adjustedWidth =
    configMaxWidth && toNumber(width) > configMaxWidth
      ? configMaxWidth
      : toNumber(width) - scrollbarSize
  const gutter = configGutter

  const adjustedOffsetLeft =
    configMaxWidth && toNumber(width) > configMaxWidth
      ? (toNumber(width) - scrollbarSize - adjustedWidth) / 2
      : 0

  const paddingY = configPaddingY
  const paddingTop = configPaddingTop
  const paddingBottom = configPaddingBottom
  const paddingX = configPaddingX
  const paddingLeft = configPaddingLeft
  const paddingRight = configPaddingRight

  const columnCount = configColumns
    ? configColumns
    : configDefaultColumnWidth
      ? Math.floor(adjustedWidth / (configDefaultColumnWidth + gutter || DEFAULT_COLUMN_WIDTH))
      : 1

  const adjustedColumnWidth = Math.floor(
    (adjustedWidth -
      (!adjustedOffsetLeft ? (paddingLeft || paddingX) + (paddingRight || paddingX) : 0) -
      gutter * (columnCount - 1)) /
      columnCount
  )
  const rowCount = Math.ceil(dataLength / columnCount)

  const adjustedRowHeight = configSquare ? adjustedColumnWidth : configRowHeight

  const outerElementType = useMemo(() => {
    // if (!classNameOuterElement && !outerElementTypeProps) return undefined

    return forwardRef((props: any, ref) => {
      const { children, ...restProps } = props

      return (
        <div
          ref={ref}
          {...restProps}
          className={twMerge(
            'outerElementType overflow-x-hidden !overflow-y-scroll',
            props.className
          )}
        >
          {children}
        </div>
      )
    })
  }, [])

  const innerElementType = useMemo(() => {
    return forwardRef((props: any, ref) => {
      const { style, children, ...restProps } = props
      const useCustomStyle = Boolean(
        adjustedWidth || gutter || paddingBottom || paddingTop || paddingY
      )

      return (
        <div
          ref={ref}
          style={
            useCustomStyle
              ? {
                  ...style,
                  maxWidth: adjustedWidth,
                  height: gutter ? style.height - gutter / 2 : style.height,
                  marginBottom:
                    paddingBottom || paddingY
                      ? (paddingBottom || paddingY) - (columnCount < 2 ? gutter / 2 : 0)
                      : gutter
                        ? -gutter / 2
                        : style.marginBottom,
                  marginTop:
                    paddingTop || paddingY || gutter
                      ? (paddingTop || paddingY) - gutter / 2
                      : style.marginTop
                }
              : style
          }
          {...restProps}
          className={clsx('innerElementType relative overflow-hidden', useCustomStyle && 'mx-auto')}
        >
          {children}
        </div>
      )
    })
  }, [gutter, paddingBottom, paddingTop, paddingY, adjustedWidth, columnCount])

  const handleScroll = useMemo(() => {
    if (!onScroll && !onBottom) return undefined

    return (props: ListOnScrollProps) => {
      if (
        onScroll &&
        outerRefAdjusted.current &&
        props.scrollOffset + outerRefAdjusted.current.clientHeight >=
          outerRefAdjusted.current.scrollHeight - adjustedRowHeight * 2
      )
        onScroll({
          clientHeight: outerRefAdjusted.current.clientHeight,
          scrollHeight: outerRefAdjusted.current.scrollHeight,
          scrollTop: outerRefAdjusted.current.scrollTop
        })

      if (
        onBottom &&
        outerRefAdjusted.current &&
        props.scrollOffset + outerRefAdjusted.current.clientHeight >=
          outerRefAdjusted.current.scrollHeight - adjustedRowHeight * 2
      )
        onBottom()
    }
  }, [onBottom, outerRefAdjusted, onScroll, adjustedRowHeight])

  return (
    <ListWindow
      outerRef={outerRefAdjusted}
      innerRef={innerRef}
      overscanCount={(Math.floor(toNumber(height) / adjustedRowHeight) + 1) * 3}
      onScroll={handleScroll}
      innerElementType={innerElementType}
      outerElementType={outerElementType}
      height={height}
      width={width}
      itemCount={rowCount}
      itemSize={adjustedRowHeight + gutter}
      itemData={{
        columnWidth: adjustedColumnWidth,
        columnCount,
        configGutter,
        configMaxWidth,
        configPaddingY,
        configPaddingTop,
        configPaddingBottom,
        configPaddingX,
        configPaddingLeft,
        configPaddingRight,

        width,
        renderItem,
        rowHeight: adjustedRowHeight
      }}
    >
      {ListItem}
    </ListWindow>
  )
}

export default List
