import React from 'react'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import Dialog, { DialogProps } from 'components/Dialog'
import styles from './Dialogs.module.scss'
import { GeneralDialogItemProps } from '.'

export type DialogConfig = Pick<
  DialogProps,
  | 'disableContentSpacing'
  | 'disableActionSpacing'
  | 'disableActionTopSpacing'
  | 'disableTitleBottomSpacing'
  | 'hideDialogClose'
  | 'showFloatingClose'
  | 'maxWidth'
  | 'fullWidth'
  | 'fullScreen'
  | 'TransitionComponent'
> &
  Partial<{
    classes: DialogProps['classes']
    contentDividers?: boolean
    contentDividerTop?: boolean
    contentDividerBottom?: boolean
    disableBackdropClick: boolean
    className: string
    disableEscapeKeyDown: boolean
    withDivider: boolean
    showTitleClose: boolean
    hideDialogClose: boolean
    fullSizeActionButton: boolean
  }>

export type DialogWrapperProps = Pick<GeneralDialogItemProps, 'onClose' | 'activeDialog'> & {
  dialogConfig?: DialogConfig
  content?: React.ReactNode
  actions?: React.ReactNode
  title?: React.ReactNode
}

const DialogWrapper: React.FC<DialogWrapperProps> = props => {
  const { dialogConfig, content, actions, activeDialog, title, onClose } = props

  return (
    <Dialog
      open={Boolean(activeDialog)}
      className={clsx(styles.Dialogs, dialogConfig?.className)}
      classes={{
        ...dialogConfig?.classes,
        content: clsx(
          dialogConfig?.classes?.content,
          dialogConfig?.withDivider && styles.DialogContent
        ),
        actions: dialogConfig?.classes?.actions
      }}
      fullScreen={dialogConfig?.fullScreen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      contentDividers={dialogConfig?.contentDividers}
      contentDividerTop={dialogConfig?.contentDividerTop}
      contentDividerBottom={dialogConfig?.contentDividerBottom}
      disableTitleBottomSpacing={dialogConfig?.disableTitleBottomSpacing}
      disableBackdropClick={dialogConfig?.disableBackdropClick || false}
      disableContentSpacing={dialogConfig?.disableContentSpacing}
      disableEscapeKeyDown={dialogConfig?.disableEscapeKeyDown || false}
      disableActionSpacing={dialogConfig?.disableActionSpacing}
      disableActionTopSpacing={dialogConfig?.disableActionTopSpacing}
      fullWidth={dialogConfig?.fullWidth}
      maxWidth={dialogConfig?.maxWidth}
      showFloatingClose={dialogConfig?.showFloatingClose}
      showTitleClose={dialogConfig?.showTitleClose}
      TransitionComponent={dialogConfig?.TransitionComponent}
      titleContent={
        title ? (
          <>{typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}</>
        ) : undefined
      }
      actionContent={actions}
    >
      {content}
    </Dialog>
  )
}

export default DialogWrapper
