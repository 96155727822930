import {
  DownloadPricing,
  ScaleList,
  ScaleType,
  UpscaleImage,
  UpscaleMethod,
  UpscaleMethodList
} from 'models/ApiModels'

export const GENERATING_POOL_INTERVAL = 2000
export const GENERATING_POOL_INTERVAL_2 = 5000
export const ALL_TEXT = 'all' as const

export const FORM_RANGES: {
  [key in keyof Required<Pick<UpscaleFormData, 'texture' | 'smooth'>>]: [
    min: number,
    max: number,
    initial: number,
    step: number
  ]
} = {
  texture: [0, 1, 0.3, 0.01],
  smooth: [0, 10, 0, 1]
}
export const DEFAULT_FORM_DATA: UpscaleFormData = {
  scale: 2 as UpscaleImage['scale'],
  texture: FORM_RANGES.texture?.[2],
  deblocking: true,
  smooth: FORM_RANGES.smooth?.[2],
  isChanged: false,
  extension: '.jpeg',
  showSmooth: false,
  showTexture: false
}

export type UpscaleFormData = UpscaleImage['controls'] & {
  scale: UpscaleImage['scale']
  isChanged: boolean
  extension: '.jpeg'
  showSmooth: boolean
  showTexture: boolean
}

export const UPSCALED_FORM_DATA_CHANGED_WHITE_LIST: (keyof UpscaleFormData)[] = [
  'texture',
  'showSmooth',
  'showTexture',
  'deblocking',
  'scale',
  'smooth',
  'extension'
]

export type UpscaleListConfig = {
  title: string
  subtitle: string
  enableTexture?: boolean
}

export const UPSCALE_LIST_CONFIG: { [key in UpscaleMethod]: UpscaleListConfig } = {
  bicubic: {
    title: 'Bicubic',
    subtitle: 'A typical upscaling algorithm.'
  },
  waifu2x: {
    title: 'Graphic & Illustration',
    subtitle: 'AI upscaling with smoothing and denoising effects.'
  },
  enh: {
    title: 'Painterly',
    subtitle: 'AI upscaling with fine texture added.',
    enableTexture: true
  },
  drn: {
    title: 'Photorealistic',
    subtitle: 'AI upscaling with enhancing for more lifelike results.',
    enableTexture: true
  }
}
export type ScaleOption = {
  label: string
  value: UpscaleImage['scale'] | typeof ALL_TEXT
}
export const FILTER_LIST_OPTIONS: {
  sort: {
    label: string
    key: string
  }[]
  methods: {
    label: string
    value: UpscaleMethod | typeof ALL_TEXT
  }[]
  scales: ScaleOption[]
} = {
  sort: [
    {
      key: 'created',
      label: 'Date'
    }
  ],
  methods: [
    { label: 'All Method', value: 'all' },
    ...UpscaleMethodList.map(value => ({ label: UPSCALE_LIST_CONFIG[value].title, value }))
  ],
  scales: [
    { label: 'All Scales', value: 'all' },
    ...ScaleList.map(value => ({ label: `${value}x`, value }))
  ]
}

export const UPSCALE_LIST: UpscaleMethod[] = ['bicubic', 'waifu2x', 'enh', 'drn']

export type DownloadPricingGroup = {
  [key in ScaleType]: DownloadPricing
}
