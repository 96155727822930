import {
  TrainOutputImage,
  ImageListReq,
  TrainOutputImagesReq,
  TrainProject,
  InputImageSet,
  Collection,
  TrainProjectProgress,
  ImageSetType,
  EngineConfigData,
  Post,
  Comment,
  SnapshotType,
  MineProject
} from 'models/ApiModels'
import _forEach from 'lodash/pickBy'
import _toInteger from 'lodash/toInteger'
import _map from 'lodash/map'
import _countBy from 'lodash/countBy'
import _compact from 'lodash/compact'
import _slice from 'lodash/slice'
import _keys from 'lodash/keys'
import _trim from 'lodash/trim'
import _filter from 'lodash/filter'
import _toNumber from 'lodash/toNumber'
import _startCase from 'lodash/startCase'
import _intersection from 'lodash/intersection'
import { ErrorBundleType } from 'duck/ApiDuck'
import { minuteToHourText } from './math'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { TextTransform } from './TextUtils'
import { name as nameConstant, LSKey } from 'appConstants'
import { RootState } from 'duck'
import { LEGACY_PROJECT_CATEGORY_TITLE } from 'appConstants'
import { CollectionSourceType } from 'containers/CollectionEditorPanel/duck'
import SentryUtils from './SentryUtils'

export const roundTransitionTime = (value: number) => _toInteger(Math.round(value * 10)) / 10

/*
 *  Accept array of images, that contain order and batch, and transform into 2 dimensional data :
 *  From  [{id:x, order:x, batch:x}, ...{}]  to
 *  batch: [
 *    [batch]: [imageOrder1, imageOrder2, imageOrder3]
 *  ]
 *
 * Also accept previous data, that will be combined with new data.
 *
 */

export const groupResultSnapshot = (
  newData?: Array<TrainOutputImage> | null,
  currentData?: SnapshotType | null
): SnapshotType => {
  const result = currentData ? [...currentData] : []

  newData?.forEach(value => {
    const currentBatch = result[value.batch] ? [...result[value.batch]] : []
    currentBatch[value.order] = value.image?.id ?? 0
    result[value.batch] = currentBatch
  })

  return result
}
/*
  Generate key for current collection that still uploaded 
*/
export const getCurrentCollectionKey = (projectId: number, source: CollectionSourceType) => {
  return `${LSKey.CURRENT_COLLECTION}_${projectId}_${source}`
}

/*
 * Generate key for images reducer object
 */

export const generateImagesKey = (req: ImageListReq): string => {
  if (req.inputs) {
    return `${req.collection}_${req.inputs}`
  }
  return `${req.collection}`
}

/*
 * Generate request history key for batch fetching.
 * This data used to keep track the pagination.
 */

export const generateRequestHistoryKey = (req: TrainOutputImagesReq): string => {
  if (req.batch || req.batch === 0) {
    return `${req.ordering}-${req.batch}`
  }
  if (req.order || req.order === 0) {
    return `${req.ordering}-${req.order}`
  }

  return ''
}

/*
 * Selection Method Abstraction
 * Strategy to select all item, without adding all images into selectedObject
 */
export type SelectDirection = 'normal' | 'inverse'
export type SelectedFormat = { [id: number]: boolean }

export const SelectUtils = {
  isSelected: (
    value: number,
    selected?: SelectedFormat,
    selectDirection: SelectDirection = 'normal'
  ) => {
    if (!selected) {
      return false
    }
    const data = selected && selected[value]

    if (data) {
      return selectDirection !== 'inverse'
    }
    return selectDirection === 'inverse'
  },
  countSelected: (
    selected: SelectedFormat,
    total: number = 0,
    selectDirection: SelectDirection = 'normal'
  ) => {
    const trueLength = _countBy(selected, value => value).true || 0

    if (selectDirection === 'inverse') {
      const length = total - trueLength
      return length < 0 ? 0 : length
    }
    return trueLength
  },
  /* Convert object format into array */
  getSelectedList: (selected: SelectedFormat) => {
    const result: number[] = []

    _forEach(selected, (value: Boolean, key: string) => {
      if (value) {
        result.push(_toInteger(key))
      }
    })

    return result
  }
}

/*
 * Convert Ordering format between -{orderingKey}  to
 * {sortDirection: '', sortValue: ''} back and forth
 */

export type SortObject = {
  sortDirection: string
  sortValue: string
}

export const sortUtils = {
  toObject: (value: string = '') => {
    let sortDirection
    let sortValue
    if (value[0] === '-') {
      sortDirection = '-'
      sortValue = value.substring(1, value.length)
    } else {
      sortDirection = ''
      sortValue = value
    }
    return {
      sortDirection,
      sortValue
    }
  },
  toString: (value: SortObject) => `${value.sortDirection}${value.sortValue}`
}

const SERVER_INFO_STRING_LIST = ['nginx', 'ubuntu']

export type ContentMode = 'flat' | 'list'
export const CONTENT_MODE: { FLAT: ContentMode; LIST: ContentMode } = {
  FLAT: 'flat',
  LIST: 'list'
}

export const errorUtils = {
  getCode: (errorBundle?: ErrorBundleType | { error: AxiosResponse }) => {
    return errorBundle?.error?.status
  },
  flattenErrorMessageObject: (errorMessage: { [key: string]: Array<string> | string }) => {
    const errorKeys = Object.keys(errorMessage)
    let result = ''
    _forEach(errorKeys, (key, index: number) => {
      const errorText = errorMessage[key]
      const separator = !result || index === errorKeys.length - 1 ? '' : ', '
      if (Array.isArray(errorText)) {
        _forEach(errorText, (val, index: number) => {
          const separator = !result || index === errorText.length - 1 ? '' : ', '
          result = `${result}${separator}${val}`
        })
      } else {
        result = `${result}${separator}${errorText}`
      }
    })
    return TextTransform.stripHtml(result)
  },
  convertErrorMessageObjectToList: (errorMessage: { [key: string]: Array<string> | string }) => {
    const errorKeys = Object.keys(errorMessage)
    const result: string[] = []
    _forEach(errorKeys, key => {
      const errorTitle = _startCase(key.replaceAll('_', ' '))
      const errorText = errorMessage[key]

      if (Array.isArray(errorText)) {
        errorText.forEach(error => result.push(`${errorTitle} : ${TextTransform.stripHtml(error)}`))
      } else {
        result.push(`${errorTitle} : ${TextTransform.stripHtml(errorText)}`)
      }
    })
    return result
  },
  /*
   *  Collect value key error massage into flat string. Each error separated by comma.
   */
  flattenMessage: (
    errorBundle?: ErrorBundleType | { error: AxiosResponse } | null,
    contentMode?: ContentMode
  ) => {
    const errorMessage = errorBundle?.error?.data

    if (!errorMessage) {
      return errorUtils.sanitizeServerVersion(
        TextTransform.stripHtml(errorBundle?.error?.statusText ?? '')
      )
    }
    if (typeof errorMessage === 'string') {
      return errorUtils.sanitizeServerVersion(TextTransform.stripHtml(errorMessage))
    }
    return contentMode === 'list'
      ? errorUtils.convertErrorMessageObjectToList(errorMessage)
      : errorUtils.flattenErrorMessageObject(errorMessage)
  },
  flattenBlobMessage: async (file: Blob) => {
    try {
      const readerResult = await fileReader(file)
      const errorMessage = JSON.parse(readerResult as string)

      return errorUtils.flattenErrorMessageObject(errorMessage)
    } catch (e) {
      console.log(e)
      return ''
    }
  },
  //If contain information about server, then skip it
  sanitizeServerVersion: (input: string) => {
    let hasString = false
    SERVER_INFO_STRING_LIST.forEach(value => {
      if (input.toLowerCase().includes(value)) {
        hasString = true
      }
    })

    return !hasString ? input : ''
  }
}

/*
 *  Extract sample of image thumbnail for displaying strip
 */
const MAX_SELECTED = 30 //Maximum image selected
export const imageCollectionSampleExtractor = (collections?: InputImageSet['collections']) => {
  if (!collections) return

  let result: string[] = []
  let someEmptyFlag = false

  _forEach(collections, (value: Collection) => {
    const imagesData = value.imagesData
    someEmptyFlag = !Boolean(imagesData)
    const imageList = _slice(imagesData?.results ?? [], 0, 10)
    result = [...result, ..._compact(_map(imageList, collection => collection?.thumbnail ?? ''))]
  })

  // If some data still empty,mean it's still loaded, then dont return data.
  // This is for efficiency
  if (someEmptyFlag) {
    return undefined
  }

  const diff = result.length - MAX_SELECTED
  if (diff > 0) {
    const step = result.length / MAX_SELECTED
    for (let i = 0; i < diff; i++) {
      let stepRounded = i % 2 === 0 ? Math.ceil(step) : Math.floor(step)
      result[stepRounded * i] = ''
    }
    result = _compact(result)
  }
  return result
}

export const ProjectProgressUtils = {
  getTimeBasedProgress: (projectProgress: TrainProjectProgress) => {
    const startedAt = projectProgress?.started_at
      ? dayjs(projectProgress?.started_at).format('MM/DD/YYYY, h:mm A')
      : ''
    const minutes = projectProgress?.minutes ?? 0
    const progress = projectProgress?.percent ?? 0

    const currentMinutes = Math.round(progress * minutes)

    return {
      startedAt,
      minutesFormatted: minuteToHourText(minutes, 'number'),
      minutesText: minuteToHourText(minutes),
      currentMinutesFormatted: minuteToHourText(currentMinutes, 'number'),
      progress: !isNaN(progress) ? progress : 0
    }
  },
  getSnapshotBasedProgress: (param: Pick<TrainProject, 'total_snapshot' | 'current_snapshot'>) => {
    const { total_snapshot, current_snapshot } = param

    return {
      progress: current_snapshot / total_snapshot
    }
  }
}

export const fileReader = (file: Blob) => {
  const fileReader = new FileReader()

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error('Problem parsing file'))
    }

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.readAsText(file)
  })
}
export const getImageSize = (
  file: File
): Promise<{ id: number; width: number; height: number; fileUrl: string }> =>
  new Promise(resolve => {
    const fileUrl = URL.createObjectURL(file)
    const img = new Image()

    img.onload = () => {
      resolve({
        id: -file.size,
        height: img.height,
        width: img.width,
        fileUrl
      })
    }
    img.src = fileUrl
  })

/* Draft when the collection still use default name, 
   have no category or have no tags 
*/
export const isCollectionDraft = (collection?: Collection) => {
  if (!Boolean(collection?.category)) {
    return true
  }
  const tags = collection?.tags || []
  if (!tags.length) {
    return true
  }
  const name = collection?.name
  if (!name) {
    return true
  }
  if (name === nameConstant.DEFAULT_COLLECTION_NAME) {
    return true
  }

  return false
}

/* Check whether user adding a collection that have been already added in anoter inputset */

const toNumbers = (input: string[]): number[] => _map(input, val => _toNumber(val))

export const getDuplicateCollections = (
  currentProject: TrainProject,
  collectionIds: number[],
  selectedImageSet: ImageSetType
) => {
  const collections =
    selectedImageSet === 'aesthetic'
      ? currentProject?.inspirationData?.collections ?? {}
      : currentProject?.aestheticData?.collections ?? {}

  const tags =
    selectedImageSet === 'aesthetic'
      ? currentProject?.inspirationData?.tags ?? {}
      : currentProject?.aestheticData?.tags ?? {}

  const currentCollections = toNumbers(_keys(collections))
  const nonRemovedCollections =
    currentCollections?.filter(collectionId => !tags[collectionId]?.removed) ?? []

  return _intersection(collectionIds, nonRemovedCollections)
}

/* Convert base64 string to File string */

export const b64toFile = (input: string, fileName?: string) => {
  const sliceSize = 512
  const splited = input.split(';')
  const contentType = splited?.[0]?.split(':')[1] ?? ''
  const b64Data = splited?.[1]?.split(',')?.[1] ?? ''
  const extension = contentType.split('/')[1]

  if (!contentType || !b64Data) {
    return null
  }

  let byteCharacters = atob(b64Data)

  let byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)

    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  return new File(byteArrays, `${fileName || 'sampleName'}.${extension}`, { type: contentType })
}

export const toDataURL = (
  url?: string,
  callback?: (result: string | ArrayBuffer | null) => void,
  onError?: () => void
) => {
  if (!url) return
  let img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    let canvas = document.createElement('CANVAS') as HTMLCanvasElement
    let ctx = canvas.getContext('2d')
    let dataURL
    canvas.height = img.height
    canvas.width = img.width
    ctx?.drawImage(img, 0, 0)
    dataURL = canvas.toDataURL()
    callback?.(dataURL)
  }
  img.onerror = (e: Event | string) => {
    SentryUtils.captureMessage(
      `Failed when convert image into DataURL.`,
      { image: url, e },
      'error'
    )
    onError?.()
  }
  img.src = url
}

export const toBlob = (url?: string, callback?: (result: Blob) => void, onError?: () => void) => {
  if (!url) return
  let img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    let canvas = document.createElement('CANVAS') as HTMLCanvasElement
    let ctx = canvas.getContext('2d')
    canvas.height = img.height
    canvas.width = img.width
    ctx?.drawImage(img, 0, 0)
    canvas.toBlob(data => {
      if (!data) return
      callback?.(data)
    })
  }
  img.onerror = (e: Event | string) => {
    SentryUtils.captureMessage(
      `Failed when convert image into DataURL.`,
      { image: url, e },
      'error'
    )
    onError?.()
  }
  img.src = url
}

export type ConstructProjectDataParam = {
  projectId: number
  projects: RootState['api']['projects']['projects']
  inputs?: RootState['api']['inputs']['inputs']
  images?: RootState['api']['collections']['images']
  currentUserId?: string | null
  engineConfigData?: EngineConfigData
}

export const constructProjectData = (param: ConstructProjectDataParam): TrainProject => {
  const {
    projectId,
    projects,
    inputs = {},
    images = {},
    currentUserId,
    engineConfigData = {}
  } = param

  const project = projects[projectId] || {}
  const aesthetic = inputs?.[project.aesthetic ?? 0]
  const inspiration = inputs?.[project.inspiration ?? 0]

  const aestheticImagesSet: InputImageSet['imagesSet'] = {}
  const inspirationImagesSet: InputImageSet['imagesSet'] = {}

  _keys(inspiration?.collections ?? {}).forEach((value: string) => {
    const collectionId = _toInteger(value)
    inspirationImagesSet[collectionId] = new Set<number>(inspiration?.images?.[collectionId] ?? [])
  })
  _keys(aesthetic?.collections ?? {}).forEach((value: string) => {
    const collectionId = _toInteger(value)
    aestheticImagesSet[collectionId] = new Set<number>(aesthetic?.images?.[collectionId] ?? [])
  })

  const aestheticImagesData: InputImageSet['imagesData'] = {}
  const inspirationImagesData: InputImageSet['imagesData'] = {}

  const aestheticImagesThumbnail: InputImageSet['imagesThumbnail'] = {}
  const inspirationImagesThumbnail: InputImageSet['imagesThumbnail'] = {}

  // Add images data to aesthetic collection
  _keys(aesthetic?.collections ?? {}).forEach((value: string) => {
    const collectionId = _toInteger(value)
    const imagesData =
      images[
        generateImagesKey({
          collection: collectionId,
          inputs: aesthetic.id
        })
      ]
    const imageSet = aestheticImagesSet[collectionId] ?? new Set([])
    aestheticImagesData[collectionId] = imagesData
    aestheticImagesThumbnail[collectionId] = _filter(imagesData?.results ?? [], value =>
      imageSet.has(value.id)
    )
  })

  // Add images data to inspiration collection
  _keys(inspiration?.collections ?? {}).forEach((value: string) => {
    const collectionId = _toInteger(value)
    const imagesData =
      images[
        generateImagesKey({
          collection: collectionId,
          inputs: inspiration.id
        })
      ]
    const imageSet = inspirationImagesSet[collectionId] ?? new Set([])
    inspirationImagesData[collectionId] = imagesData
    inspirationImagesThumbnail[collectionId] = _filter(imagesData?.results ?? [], value =>
      imageSet.has(value.id)
    )
  })

  const aestheticData = {
    ...aesthetic,
    imagesData: aestheticImagesData,
    imagesSet: aestheticImagesSet,
    imagesThumbnail: aestheticImagesThumbnail
  }
  const inspirationData = {
    ...inspiration,
    imagesData: inspirationImagesData,
    imagesSet: inspirationImagesSet,
    imagesThumbnail: inspirationImagesThumbnail
  }

  const isProjectOwner = _toNumber(project?.user?.id) === _toNumber(currentUserId)

  const categoryName = engineConfigData
    ? engineConfigData?.[project.category]?.name || LEGACY_PROJECT_CATEGORY_TITLE
    : ''

  return {
    ...project,
    aestheticData,
    inspirationData,
    isProjectOwner,
    categoryName
  }
}

type GetPostShowOptionType = {
  engineConfigData: EngineConfigData
  post?: Post
  currentUserId: string | undefined
  isCurrentUserStaff?: boolean
}

export const getPostShowOption = (param: GetPostShowOptionType) => {
  const { engineConfigData, post, currentUserId, isCurrentUserStaff } = param

  const isProjectPrivate = post?.related?.is_private ?? true
  const hasRelated = Boolean(post?.related)
  const isFeatured = post?.is_featured
  const userOwner = post?.user?.id
  const isProjectOwner = userOwner && _toNumber(userOwner) === _toNumber(currentUserId)

  const canInference = Boolean(
    engineConfigData[post?.related?.category ?? '']?.can_inference ?? false
  )

  const showGenerateButton = currentUserId && (!isProjectPrivate || isProjectOwner) && canInference
  const showProjectButton = (!isProjectPrivate || isProjectOwner) && hasRelated
  const showDeleteButton = isProjectOwner || isCurrentUserStaff
  const showFeaturedButton = currentUserId && (isFeatured || isCurrentUserStaff)

  return {
    showFeaturedButton,
    showDeleteButton,
    isProjectOwner,
    showGenerateButton,
    showProjectButton
  }
}

/* 
  TODO
  There a codification for comment 
  - format : {{type,param1:value;param2:value}}
*/

const extractTextFromJson = (value: string, variable: string) => {
  try {
    let parsed = JSON.parse(value)
    if (parsed && typeof parsed === 'object') {
      return parsed?.['variable']
    } else {
      return value
    }
  } catch (e) {
    return value
  }
}

const extractName = (comment?: Comment) =>
  comment ? `${comment?.user?.first_name} ${comment?.user?.last_name}` : ''

export const commentUtils = {
  /* Insert mention */
  insertMention: (commentReplyTo: Comment, body: string, previousReplyToComment?: Comment) => {
    const name = extractName(commentReplyTo)
    const previousName = extractName(previousReplyToComment)
    const bodyCleaned = _trim(body.replace(`@${previousName}`, ''))
    const mention = name ? `@${name} ` : ''
    return `${mention}${bodyCleaned}`
  },
  //TODO
  previewComment: (body: string) => {
    const bodySplitted = body.split('||')
    return bodySplitted.reduce((result, value) => {
      return `${result} ${extractTextFromJson(value, 'text')}`
    })
  },
  /* TODO If has reply_to, then convert it to code */
  insertReplyToCode: (commentReplyTo: Comment, body: string) => {
    const replyToId = commentReplyTo.id
    const name = `${commentReplyTo?.user?.first_name} ${commentReplyTo?.user?.last_name}`
    const replyData = {
      type: 'mention',
      id: replyToId,
      name: name
    }

    return `{{${JSON.stringify(replyData)}}} ${body}`
  },
  transformCodeTotext: () => {}
}

export const MINE_PROJECT_SUBMIT_WAIT_TIME = 20

export const completeMineProjectData = (item: MineProject): MineProject => {
  const isProcessing = !item.is_published
  const request_transaction = item.request_transaction

  const isFailed =
    (isProcessing && !request_transaction) ||
    (isProcessing &&
      dayjs(request_transaction).add(MINE_PROJECT_SUBMIT_WAIT_TIME, 'minute').isBefore(dayjs()))

  return { ...item, isFailed, isProcessing: !isFailed && isProcessing }
}
