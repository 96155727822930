import Base from './Base'
import LargeImage from './LargeImage'
import Konva from 'konva'
import {
  gridUtils,
  ImageStateManager,
  ImageStateManagerType,
  ImageState,
  AnimationStateManager,
  AnimationStateManagerType,
  ImagePosition
} from './utils'
import { MAX_ZOOM_VALUE, MIN_ZOOM_VALUE } from '.'
import { Layer } from 'konva/lib/Layer'
import { Stage } from 'konva/lib/Stage'
import { Vector2d } from 'konva/lib/types'
import { ImageType } from 'models/ApiModels'
import { DEFAULT_ZOOM_LEVEL } from 'components/InfinityGrid'

Konva.pixelRatio = 1

export type Size = {
  width: number
  height: number
}

export type CanvasBuilderProps = {
  layer: Layer
  stage: Stage
  stageSize: Size
  camera: Vector2d
}

export type ParentListenerType = 'clickTapListener' | 'zoomListener' | 'cameraListener'
export type ParentListeners = { [key in ParentListenerType]?: Function }

export type ListenerType =
  | 'clickTapListener'
  | 'dragMoveListener'
  | 'zoomListener'
  | 'clickListener'

export type Listeners = { [key in ListenerType]?: Function }

export type CanvasBuilderConfig = Pick<CanvasBuilderProps, 'stageSize'>

const CanvasBuilder = (props: CanvasBuilderProps) => {
  const { layer, stage, stageSize } = props

  const initial: ImageState = {
    maxCamera: {
      x1: 0,
      y1: 0,
      x2: Infinity,
      y2: Infinity
    },
    stageSize,
    zoom: DEFAULT_ZOOM_LEVEL,
    camera: gridUtils.getInitialCamera(stageSize),
    lastImagePosition: {}
  }
  const imageState: ImageStateManagerType = ImageStateManager(initial)
  const animationState: AnimationStateManagerType = AnimationStateManager({ imageState })

  const parentListeners: ParentListeners = {}
  const listeners: Listeners = {
    dragMoveListener: (deltaCamera: Vector2d) => {
      const currentCamera = imageState.camera()
      const newCamera = imageState.camera({
        x: currentCamera.x + deltaCamera.x,
        y: currentCamera.y + deltaCamera.y
      })
      animationState.updateCameraPosition(newCamera)
      parentListeners?.cameraListener?.(newCamera, imageState.zoom(), 'drag move listener')
      grid.draw()
      base.draw()
    },
    zoomListener: (zoomValue: number, newCameraPos?: Vector2d) => {
      if (MAX_ZOOM_VALUE > zoomValue && MIN_ZOOM_VALUE < zoomValue) {
        const zoom = imageState.zoom(zoomValue)
        imageState.camera(newCameraPos ? newCameraPos : undefined)
        parentListeners?.cameraListener?.(newCameraPos, zoom, 'zoom')
        parentListeners?.zoomListener?.(zoom)
        grid.draw()
        base.draw()
      }
    }
  }

  //Initialize
  const base = Base({ listeners, layer, imageState, stage, animationState })
  const grid = LargeImage({ layer, imageState })
  layer.add(grid.get())
  layer.add(base.get())
  layer.imageSmoothingEnabled(false)

  //Init animation
  const animation = new Konva.Animation(frame => {
    const isAnimate = animationState.animate({ frame })

    if (isAnimate) {
      parentListeners?.cameraListener?.(imageState.camera(), imageState.zoom(), 'animation move')
      grid.draw(true)
      base.draw(true)
    }
    return isAnimate
  }, layer)

  animation.start()
  return {
    setStageSize: (param: Size) => {
      imageState.stageSize({ ...param })
      grid.draw()
      base.draw()
    },
    updateImage: (image?: ImageType, onLoadCallback?: () => void, onError?: () => void) => {
      if (image) {
        imageState.image(image)
        grid.updateImage(onLoadCallback, onError)
        grid.draw()
        base.draw()
      }
      return imageState.image()
    },
    setZoom: (zoomValue: number) => {
      const zoomCenter = {
        x: stageSize.width / 2,
        y: stageSize.height / 2
      }
      imageState.camera(
        gridUtils.getCameraPosAfterZoom({
          zoomCenter,
          newZoom: zoomValue,
          oldZoom: imageState.zoom(),
          camera: imageState.camera()
        })
      )
      const zoom = imageState.zoom(zoomValue)
      grid.draw()
      base.draw()
      parentListeners?.zoomListener?.(zoom)
    },
    setHome: (home: ImageState['home']) => {
      imageState.home(home)
    },
    disablePreview: (disable?: boolean) => {
      imageState.disablePreview(disable)
    },
    getHome: () => {
      return imageState.home()
    },
    goHome: () => {
      const home = imageState.home()

      imageState.camera(home?.camera)
      imageState.zoom(home?.zoom)
      parentListeners?.cameraListener?.(imageState.camera(), imageState.zoom())
      parentListeners?.zoomListener?.(imageState.zoom())
      grid.draw()
      base.draw()
    },
    getZoom: () => {
      return imageState.zoom()
    },
    getCamera: () => {
      return imageState.camera()
    },
    setCamera: (camera: Vector2d) => {
      imageState.camera(camera)
      grid.draw()
      base.draw()
    },
    imagePosition: (): ImagePosition | undefined => {
      return imageState.imagePosition()
    },
    setListener: (listenerType: ParentListenerType, listener: Function) => {
      parentListeners[listenerType] = listener
    }
  }
}
export default CanvasBuilder
