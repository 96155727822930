import { ChangeEmailDialogNameType, ChangeEmailDialog } from 'duck/AppDuck/DialogDuck'
import DialogChangeEmail from './DialogChangeEmail'
import DialogConfirmNewEmail from './DialogConfirmNewEmail'
import DialogConfirmNewEmailHelp from './DialogConfirmNewEmailHelp'
import DialogEmailSuccessfullyChanged from './DialogEmailSuccessfullyChanged'
import DialogError from './DialogError'
import DialogResentConfirmNewEmail from './DialogResentConfirmNewEmail'
import DialogResentCurrentEmail from './DialogResentCurrentEmail'
import DialogSentCurrentEmail from './DialogSentCurrentEmail'
import DialogSentCurrentEmailHelp from './DialogSentCurrentEmailHelp'

export const ChangeEmailDialogs: Record<ChangeEmailDialogNameType, Function> = {
  [ChangeEmailDialog.CHANGE_EMAIL]: DialogChangeEmail,
  [ChangeEmailDialog.CONFIRM_NEW_EMAIL]: DialogConfirmNewEmail,
  [ChangeEmailDialog.CONFIRM_NEW_EMAIL_HELP]: DialogConfirmNewEmailHelp,
  [ChangeEmailDialog.EMAIL_SUCCESSFULY_CHANGED]: DialogEmailSuccessfullyChanged,
  [ChangeEmailDialog.ERROR_DIALOG]: DialogError,
  [ChangeEmailDialog.RESENT_CONFIRM_NEW_EMAIL]: DialogResentConfirmNewEmail,
  [ChangeEmailDialog.RESENT_CURRENT_EMAIL]: DialogResentCurrentEmail,
  [ChangeEmailDialog.SENT_CURRENT_EMAIL]: DialogSentCurrentEmail,
  [ChangeEmailDialog.SENT_CURRENT_EMAIL_HELP]: DialogSentCurrentEmailHelp
}
