import ReactPixel from 'react-facebook-pixel'

const REACT_APP_FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID ?? ''

const hasPixelId = Boolean(REACT_APP_FACEBOOK_PIXEL_ID)

export type EventProperties = {
  EMAIL: {
    email: string
  }
  BUY_TRAINING_CREDIT: {
    price: number
    quantity: number
    price_total: number
  }
}

export type Events = {
  USER__SIGNUP: {
    name: 'User - Signup'
    properties: EventProperties['EMAIL']
  }
  USER__LOGIN: {
    name: 'User - Login'
    properties: EventProperties['EMAIL']
  }
  CREATE_FACEMIX: {
    name: 'create_facemix'
    properties: undefined
  }
  CREATE_SKETCH_TEXT_TO_IMAGE: {
    name: 'create_st2i'
    properties: undefined
  }
  CREATE_PROARTFILTER: {
    name: 'create_stylize'
    properties: undefined
  }
  CREATE_SKETCH: {
    name: 'create_sketch'
    properties: undefined
  }
  CREATE_TRAIN: {
    name: 'create_train'
    properties: undefined
  }
  BUY_SUBSCRIPTION_PLUS: {
    name: 'buy_subscription_plus'
    properties: undefined
  }
  BUY_SUBSCRIPTION_PRO: {
    name: 'buy_subscription_pro'
    properties: undefined
  }
  BUY_SUBSCRIPTION_STUDIO: {
    name: 'buy_subscription_studio'
    properties: undefined
  }
  BUY_TRAINING_CREDIT: {
    name: 'buy_training_credit'
    properties: EventProperties['BUY_TRAINING_CREDIT']
  }
  START_TRAINING: {
    name: 'start_training'
    properties: undefined
  }
  CLAIM_FREE_CREDIT: {
    name: 'claim_free_credit'
    properties: undefined
  }
}

type EventType = keyof Events

const FacebookPixelUtils = {
  init: () => {
    if (hasPixelId) {
      ReactPixel.init(REACT_APP_FACEBOOK_PIXEL_ID, undefined, { debug: true, autoConfig: false })
      ReactPixel.pageView()
    }
  },
  track: <T extends EventType>(title: Events[T]['name'], data?: Events[T]['properties']) => {
    hasPixelId && ReactPixel.trackCustom(title, data)
  }
}

export default FacebookPixelUtils
