import React from 'react'
import Helmet from 'react-helmet'
import Loading from 'components/Loading'
import { useDelayedRender } from 'utils/Hooks'
import clsx from 'clsx'

import styles from './Loading.module.scss'

const DEFAULT_DELAY = 400

type LoadingPageProps = {
  className?: string
  delay?: number
  disablePageTitle?: boolean
}

export const LoadingPage: React.FC<LoadingPageProps> = props => {
  const { className, delay = DEFAULT_DELAY, disablePageTitle } = props

  const shouldRender = useDelayedRender(delay)

  return (
    <>
      {!disablePageTitle ? (
        <Helmet>
          <title>Playform - Loading....</title>
        </Helmet>
      ) : null}

      {shouldRender ? (
        <div className={clsx(styles.LoadingPage, className)}>
          <Loading />
        </div>
      ) : null}
    </>
  )
}
