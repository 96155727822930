import { Vector2d } from 'konva/lib/types'
import { BASE_GRID_SIZE, IMAGE_GRID_COUNT_Y, InfinityGridProps } from '../index'
import { Size } from '../CanvasBuilder'
import { DisplayedImage, GridState } from './GridStateManager'
import { SpaceImageRequest } from 'models/ApiModels'
import { GetText } from 'utils/TextUtils'
import { VectorUtils } from 'utils/math'

export type GetGridCoordinateParam = {
  camera: Vector2d
  screenOffset: Vector2d
  zoom: number
  screenLocation: Vector2d //screen coordinate
}
export type GetGridInScreenCountParam = {
  zoom: number
  stageSize: Size
}
export type GetGridPositionInGridGroupParam = {
  zoom: number
  camera: Vector2d
  grid: Vector2d
}
export type GetShowingImageParam = Pick<
  InfinityGridProps,
  'spaceImages' | 'spaceImageList' | 'currentFetchState'
> & {
  grid: Vector2d
  gridCount: Size
  gridSize: number
}

export const gridUtils = {
  getInitialCamera: (zoom: number, stageSize: Size) => {
    const gridSize = (zoom / 100) * BASE_GRID_SIZE
    const halfImageHeight = (gridSize * IMAGE_GRID_COUNT_Y) / 2
    const halfImageWidth = (gridSize * IMAGE_GRID_COUNT_Y) / 2

    return {
      x: stageSize.width / 2 - halfImageWidth,
      y: stageSize.height / 2 - halfImageHeight
    }
  },
  /* Get mouse xy when on click */
  getClientXY: (event: any) => {
    const firstTouch = event?.touches?.[0] || event?.changedTouches?.[0]

    if (firstTouch) {
      return [firstTouch.clientX || 0, firstTouch.clientY || 0]
    } else {
      return [event?.clientX || 0, event?.clientY || 0]
    }
  },
  getCameraPosAfterZoom: (param: {
    zoomCenter: Vector2d
    oldZoom: number
    newZoom: number
    camera: Vector2d
  }) => {
    const { zoomCenter, oldZoom, newZoom, camera } = param
    const mousePointTo = {
      x: zoomCenter.x / oldZoom - camera.x / oldZoom,
      y: zoomCenter.y / oldZoom - camera.y / oldZoom
    }

    return {
      x: -(mousePointTo.x - zoomCenter.x / newZoom) * newZoom || 0,
      y: -(mousePointTo.y - zoomCenter.y / newZoom) * newZoom || 0
    }
  },
  getGridSize: (zoom: number) => (zoom / 100) * BASE_GRID_SIZE,
  /* Convert point in screen into grid x,y coordinate */
  getGridCoordinate: (param: GetGridCoordinateParam) => {
    const { camera, zoom, screenLocation, screenOffset } = param
    const gridSize = gridUtils.getGridSize(zoom)

    const screenLocationAdj = {
      x: screenLocation.x - screenOffset.x,
      y: screenLocation.y - screenOffset.y
    }

    const gridOffsetX = camera.x % gridSize
    const gridOffsetY = camera.y % gridSize

    const x = Math.floor((screenLocationAdj.x - gridOffsetX) / gridSize)
    const y = Math.floor((screenLocationAdj.y - gridOffsetY) / gridSize)

    const xReal = Math.round(x - (camera.x - gridOffsetX) / gridSize)
    const yReal = Math.round(y - (camera.y - gridOffsetY) / gridSize)

    return { x: xReal, y: yReal }
  },
  /* Convert grid coordinate into point in canvas grid group  */
  getGridPositionInGridGroup: (param: GetGridPositionInGridGroupParam) => {
    const { camera, zoom, grid } = param
    const gridSize = gridUtils.getGridSize(zoom)
    const x = camera.x + grid.x * gridSize
    const y = camera.y + grid.y * gridSize
    return { x, y }
  },
  /* Count how many grid visible in the screen */
  getGridInScreenCount: ({ zoom, stageSize }: GetGridInScreenCountParam) => {
    const gridSize = gridUtils.getGridSize(zoom)

    return {
      width: Math.ceil(stageSize.width / gridSize),
      height: Math.ceil(stageSize.height / gridSize)
    }
  },
  /*  Calculate Image size, based on zoom level and how many grid that image have */
  getImageSize: (zoom: number, gridWidth: number, gridHeight: number) => {
    return {
      width: ((BASE_GRID_SIZE * zoom) / 100) * gridWidth,
      height: ((BASE_GRID_SIZE * zoom) / 100) * gridHeight
    }
  },
  /* Get Position of image, in relative position on image group */
  getImagePosition: (zoom: number, gridX: number, gridY: number) => {
    return {
      x: ((BASE_GRID_SIZE * zoom) / 100) * gridX,
      y: ((BASE_GRID_SIZE * zoom) / 100) * gridY
    }
  },
  /* Get showing images based on zoom and camera, and available data
   *
   *    The return type is
   *    [
   *     [ //Level 0
   *       displayed image
   *      displayed image
   *    ],
   *    [ //Level 1
   *      displayed image
   *     displayed image
   *    ],
   *   etc
   *  ]
   */
  getDisplayedImage: (
    {
      currentFetchState,
      spaceImageList,
      spaceImages,
      grid,
      gridSize,
      gridCount
    }: GetShowingImageParam,
    source?: string
  ): {
    displayedImages: DisplayedImage[][]
    displayedImagesFlat: GridState['displayedImagesFlat']
    requestQueue: SpaceImageRequest[]
  } => {
    const displayedImages: DisplayedImage[][] = []
    const requestQueue: SpaceImageRequest[] = []
    displayedImages.push([])

    //Decide which level 0 image showing
    spaceImageList.forEach(spaceImageId => {
      const spaceImage = spaceImages[spaceImageId]
      const spaceImageBox = {
        x: spaceImage.pos_x,
        y: spaceImage.pos_y,
        width: spaceImage.total_grid_x,
        height: spaceImage.total_grid_y
      }
      const isIntersect = VectorUtils.isIntersect(spaceImageBox, {
        ...grid,
        ...gridCount
      })

      if (isIntersect) {
        displayedImages[0].push({
          id: spaceImage.id,
          file: spaceImage.file,
          gridX: spaceImage.pos_x,
          gridY: spaceImage.pos_y,
          gridWidth: spaceImage.total_grid_x,
          gridHeight: spaceImage.total_grid_y,
          scaleX: 1,
          scaleY: 1
        })
      }
    })

    /*
     * Decide level other than 0 to showing, based on level 0
     *  - Step 1 - Iterate level 0 images.
     *  - Step 2 - Iterate to level 1 images find image that intersect with the viewport
     *  - Step 2.1 - Add the jpg into displayed image array, and Check if the image is available,
     *               if yes, then add it into request queue.
     *  - Step 2.2 - If Available, and shouldZoom still true, then repeat the iteration,
     *               find image that intersect.
     *  - Step 3 - Return displayed image array and requestQueue
     */

    displayedImages[0].forEach(displayedImage => {
      const firstLevelImage = spaceImages[displayedImage.id]
      const imageGridSize = Math.ceil(firstLevelImage.width / firstLevelImage.total_grid_x) //The grid size in particular image

      let shouldIncreaseLevel = imageGridSize < gridSize
      let level = 1
      let imageArray = [firstLevelImage]

      // Walking per level
      while (shouldIncreaseLevel) {
        //Walking to each image intersect in each level
        const currentImageGridSizes = []
        const newImageArray = []

        for (const image of imageArray) {
          const childrens = image.children
          const childrenGridCountX = image.total_grid_x / image.level_grid_x
          const childrenGridCountY = image.total_grid_y / image.level_grid_y

          currentImageGridSizes.push(Math.ceil(image.width / image.total_grid_x))

          //Walking to images's children find children that intersect with view port
          for (const children of childrens) {
            const spaceImageBox = {
              x:
                (children?.pos_x ?? 0) * childrenGridCountX +
                image.start_grid_x +
                firstLevelImage.start_grid_x,
              y:
                (children?.pos_y ?? 0) * childrenGridCountY +
                image.start_grid_y +
                firstLevelImage.start_grid_y,
              width: childrenGridCountX,
              height: childrenGridCountY
            }

            const isIntersect = VectorUtils.isIntersect(spaceImageBox, {
              ...grid,
              ...gridCount
            })

            if (isIntersect) {
              //If children data not available, then add into fetch queue
              const childrenSpaceImages = spaceImages[children.id]
              if (!childrenSpaceImages) {
                const childrenData = {
                  parent_image_id: image.id,
                  pos_x: children?.pos_x ?? 0,
                  pos_y: children?.pos_y ?? 0
                }
                //Only fetch data that haven't been fetched.
                //This prevent infinity loop
                const childrenKey = GetText.spaceImageNaturalKey(childrenData)
                if (!currentFetchState[childrenKey]) {
                  requestQueue.push(childrenData)
                }
              } else {
                displayedImages[level] = displayedImages[level] || []
                displayedImages[level].push({
                  id: childrenSpaceImages.id,
                  file: childrenSpaceImages.file,
                  gridX: childrenSpaceImages.start_grid_x + firstLevelImage.pos_x,
                  gridY: childrenSpaceImages.start_grid_y + firstLevelImage.pos_y,
                  gridWidth: childrenSpaceImages.total_grid_x,
                  gridHeight: childrenSpaceImages.total_grid_y,
                  scaleX: childrenSpaceImages.total_grid_x / firstLevelImage.total_grid_x,
                  scaleY: childrenSpaceImages.total_grid_y / firstLevelImage.total_grid_y
                })
                newImageArray.push(childrenSpaceImages)
              }
            }
          }
        }
        //Prepare data for the next looping
        const currentImageGridSize = Math.max(...currentImageGridSizes)
        imageArray = [...newImageArray]
        shouldIncreaseLevel = currentImageGridSize < gridSize && Boolean(imageArray.length)
        level = level + 1
      }
    })

    const displayedImagesFlat: { [id: number]: DisplayedImage } = {}

    displayedImages.forEach(displayedImage =>
      displayedImage.forEach(item => (displayedImagesFlat[item.id] = item))
    )
    return { displayedImages, displayedImagesFlat, requestQueue }
  }
}
