import React, { forwardRef, Ref } from 'react'
import clsx from 'clsx'
import TextFieldMaterial, {
  StandardTextFieldProps as StandardTextFieldMaterialProps,
  FilledTextFieldProps as FilledTextFieldMaterialProps,
  OutlinedTextFieldProps as OutlinedTextFieldMaterialProps
} from '@mui/material/TextField'
// import InputMaterial, { InputProps as InputMaterialProps } from '@mui/material/Input'

import styles from './TextField.module.scss'

type TextFieldColorProps = 'primary' | 'secondary' | 'primary-alt' | 'secondary-alt'
// type TextFieldMaterialProps = StandardTextFieldProps |
// FilledTextFieldProps | OutlinedTextFieldProps

// type TextFieldProps = TextFieldMaterialProps & {
//   color?: any
//   // variant?: TextFieldVariantProps
// }

type StandardTextFieldProps = Omit<StandardTextFieldMaterialProps, 'color'>

type FilledTextFieldProps = Omit<FilledTextFieldMaterialProps, 'color'>

type OutlinedTextFieldProps = Omit<OutlinedTextFieldMaterialProps, 'color'>

export type TextFieldProps = (
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps
) & {
  color?: TextFieldColorProps
}

const setColor = (color?: TextFieldColorProps) => {
  if (color === 'primary' || color === 'primary-alt') return 'primary'
  else if (color === 'secondary' || color === 'secondary-alt') return 'secondary'
  else return undefined
}

const setStyles = (color?: TextFieldColorProps, variant?: TextFieldProps['variant']) => {
  let stylesClassName = ''

  if (variant === 'filled' && color === 'primary-alt') {
    stylesClassName = styles.TextFieldFilledPrimaryAlt
  } else if (variant === 'filled' && color === 'secondary-alt') {
    stylesClassName = styles.TextFieldFilledSecondaryAlt
  }

  return stylesClassName
}

const TextField = (props: TextFieldProps, ref: Ref<any>) => {
  const { className, color, ...restProps } = props
  const stylesClassName = setStyles(color, restProps.variant)
  const colorAdjusted = setColor(color)

  return (
    <TextFieldMaterial
      {...restProps}
      ref={ref}
      className={clsx(className, styles.TextField, stylesClassName)}
      color={colorAdjusted}
    />
  )
}

export default forwardRef(TextField)
