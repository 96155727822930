import { TextTransform, Format, TimeFieldUtils } from 'utils/TextUtils'
import produce from 'immer'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import _filter from 'lodash/filter'
import _without from 'lodash/without'
import _take from 'lodash/take'
import _compact from 'lodash/compact'
import { combineEpics, Epic } from 'redux-observable'
import { filter, withLatestFrom, map, mergeMap, tap, delay, take, startWith } from 'rxjs/operators'
import { RootState, RootActionType } from 'duck'
import { isActionOf, getType, ActionType, createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { mixVideoSelectors, ClipIdParam, mixVideoActions, UPSELL_VIDEO_PARAM } from './MixVideo'
import { apiSelectors, apiActions } from 'duck/ApiDuck'
import { UserVideo, Clip, GenerateClipReq } from 'models/ApiModels'
import { Utils } from './Utils'
import { merge, of } from 'rxjs'
import dayjs from 'dayjs'
import MixPanelUtils, { DataUtils, DownloadLocation } from 'utils/MixPanelUtils'
import { downloaderActions } from 'duck/AppDuck/DownloaderDuck'
import { USER_VIDEO_DUMMY } from 'models/ApiModelsDummy'
import { ClipVideoOutput, MAXIMUM_SHOWING_TAB, RENDER_VIDEO_FORM_OPTIONS } from './Models'
import { bannerActions } from 'duck/AppDuck/BannerDuck'

// Constants
const NAMESPACE = '@@page/MixVideoPanel/RenderVideo'
const creator = TextTransform.constCreatorMaker(NAMESPACE)

// Actions
export const renderVideoActions = {
  retrieveAllClips: createAction(creator('RETRIEVE_ALL_CLIPS'))(),
  setSelectedTab: createAction(creator('SET_SELECT_TAB'))<{ clipId: number | undefined }>(),
  setShowSidebarRender: createAction(creator('SET_SHOW_SIDEBAR_RENDER'))<boolean>(),
  updateFormData: createAction(creator('UPDATE_FORM_DATA'))<Partial<FormData>>(),
  retrieveClip: createAction(creator('RETRIEVE_CLIP'))<{ clipId: number }>(),
  generateClip: createAction(creator('GENERATE_CLIP'))<{ clipId: number }>(),
  setGenerateStart: createAction(creator('SET_GENERATE_START'))<{ clipId: number }>(),
  setGenerateFinished: createAction(creator('SET_GENERATE_FINISHED'))<{ clipId: number }>(),
  poolingGeneratingClip: createAction(creator('POOLING_GENERATING_CLIP'))(),
  downloadClip: createAction(creator('DOWNLOAD_CLIP'))<{
    clip: Clip
    outputIndex: number
    downloadLocation: DownloadLocation
  }>(),
  exportClip: createAction(creator('EXPORT_CLIP'))<ClipIdParam>()
}

// Selectors
const selectRenderVideo = (state: RootState) => state.container.mixVideoPanel.renderVideo
const selectSelectedClipId = createSelector(
  selectRenderVideo,
  renderVideo => renderVideo.selectedClipId
)

const selectShowSidebarRender = createSelector(
  selectRenderVideo,
  renderVideo => renderVideo.showSidebarRender
)

const selectActiveTab = createSelector(
  mixVideoSelectors.activeClips,
  mixVideoSelectors.currentClipListIds,
  apiSelectors.clips,
  (activeClips, currentClipListIds, clips) => {
    const clipIds = _uniq([...activeClips, ...currentClipListIds])
    const clipsData = _map(clipIds, clipId => clips[clipId])

    return _take(_filter(clipsData, Utils.isClipRenderable), MAXIMUM_SHOWING_TAB)
  }
)

const selectClipsGenerating = createSelector(
  selectRenderVideo,
  renderVideo => renderVideo.clipsGenerating
)

const selectSelectedClipData = createSelector(
  selectSelectedClipId,
  apiSelectors.clips,
  selectClipsGenerating,
  (selectedClip, clips, clipsGenerating) => {
    const clipRaw = clips[selectedClip ?? 0]
    const output = Utils.getFirstOutputs(clipRaw?.outputs)

    const clip = { ...clipRaw, output }

    const { keyframesTimePosition, clipDuration } = Utils.getDerivedClipData(clip)
    const isGenerating = clipsGenerating.includes(selectedClip ?? 0)

    return {
      ...clip,
      keyframesTimePosition,
      clipDuration,
      isGenerating
    }
  }
)

const selectClipsWithOutput = createSelector(mixVideoSelectors.currentClipList, clips => {
  const clipsWithOutput = _filter(clips, clip => Boolean(clip.output))

  return _map(clipsWithOutput, clip => ({
    ...clip,
    ...Utils.getDerivedClipData(clip)
  }))
})

const selectClipVideoOutputs = createSelector(selectClipsWithOutput, clipsWithOutput => {
  const results: ClipVideoOutput[] = []

  clipsWithOutput.forEach(clip => {
    const outputs = clip.outputs ?? []
    outputs.forEach((output, index) => {
      const { created, video } = output
      video &&
        results.push({
          clip,
          video,
          outputIndex: index,
          key: `${video?.id ?? clip?.id ?? index}`,
          name: `${clip?.name ?? ''}`,
          numFrame: Math.round((video?.seconds ?? 9) * (video?.fps ?? 0)),
          length: TimeFieldUtils.secondToText(video?.seconds),
          date: created ? Format.formatDate(created) : '',
          unixDate: created ? dayjs(created).valueOf() : 0
        })
    })
  })

  results.sort((value1, value2) => value2.unixDate - value1.unixDate)

  return results
})

const selectFormData = createSelector(
  selectRenderVideo,
  selectSelectedClipData,
  (renderVideo, clip) => {
    const clipId = renderVideo.selectedClipId ?? 0
    const formData = renderVideo.formData[clipId] ?? INITIAL_FORM_DATA
    return {
      ...formData,
      totalClipFrame: formData.fps * clip.clipDuration
    }
  }
)

export const renderVideoSelectors = {
  renderVideo: selectRenderVideo,
  activeTab: selectActiveTab,
  selectedClipId: selectSelectedClipId,
  selectedClipData: selectSelectedClipData,
  showSidebarRender: selectShowSidebarRender,
  hasGeneratingClips: createSelector(selectClipsGenerating, clipsGenerating =>
    Boolean(clipsGenerating.length)
  ),
  formData: selectFormData,
  clipsWithOutput: selectClipsWithOutput,
  clipVideoOutputs: selectClipVideoOutputs
}

export type RenderState = {
  clipId: number
  status: 'queue' | 'in-progress' | 'finished'
  formData: RenderVideoState['formData']
  clip?: Clip
}

// Reducer
type FormData = Pick<UserVideo, 'resolution' | 'fps' | 'ext' | 'compression'> & {
  showSmooth: boolean
  smooth: number
  mode: GenerateClipReq['mode']
}

export type RenderVideoState = {
  selectedClipId?: number
  showSidebarRender?: boolean
  formData: Record<number, FormData>
  clipsGenerating: number[]
}

const INITIAL_FORM_DATA: FormData = {
  smooth: RENDER_VIDEO_FORM_OPTIONS['smooth'].initial,
  showSmooth: false,
  mode: 'fit',
  resolution: '1280×720',
  fps: 30,
  ext: 'mp4',
  compression: 'H.264'
}

export const INITIAL_RENDER_VIDEO_STATE: RenderVideoState = {
  selectedClipId: undefined,
  showSidebarRender: undefined,
  formData: {},
  clipsGenerating: []
}

const reducer = produce((state: RenderVideoState, { type, payload }) => {
  switch (type) {
    case getType(renderVideoActions.setSelectedTab): {
      const typedPayload = payload as ActionType<
        typeof renderVideoActions.setSelectedTab
      >['payload']
      state.selectedClipId = typedPayload.clipId
      if (state.selectedClipId && !state.formData[state.selectedClipId]) {
        state.formData[state.selectedClipId] = INITIAL_FORM_DATA
      }
      return
    }
    case getType(renderVideoActions.setShowSidebarRender): {
      state.showSidebarRender = payload
      return
    }
    case getType(renderVideoActions.setGenerateStart): {
      const typedPayload = payload as ActionType<
        typeof renderVideoActions.setGenerateStart
      >['payload']

      state.clipsGenerating = [...state.clipsGenerating, typedPayload.clipId]
      return
    }
    case getType(renderVideoActions.setGenerateFinished): {
      const typedPayload = payload as ActionType<
        typeof renderVideoActions.setGenerateStart
      >['payload']

      state.clipsGenerating = _without(state.clipsGenerating, typedPayload.clipId)
      return
    }
    case getType(renderVideoActions.updateFormData): {
      const typedPayload = payload as ActionType<
        typeof renderVideoActions.updateFormData
      >['payload']

      const clipId = state.selectedClipId

      if (clipId) {
        state.formData[clipId] = {
          ...(state.formData[clipId] ?? INITIAL_FORM_DATA),
          ...typedPayload
        }
      }
      return
    }
    default:
  }
}, INITIAL_RENDER_VIDEO_STATE)

// Epics

const retrieveAllClips: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(renderVideoActions.retrieveAllClips)),
    withLatestFrom(state$),
    delay(1000),
    map(([_, state]) => ({
      clips: mixVideoSelectors.currentClipList(state)
    })),
    //Choose the one that haven't been fetched or doesnt has output
    map(({ clips }) => clips.filter(clip => !Boolean(clip.outputs?.length))),
    map(filteredClips => filteredClips.map(clip => apiActions.engine.retrieveClip(clip?.id ?? 0))),
    mergeMap(actions => actions)
  )

const generateClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(renderVideoActions.generateClip)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      clipId: action.payload.clipId,
      equity: apiSelectors.equity(state),
      clipData: apiSelectors.clips(state),
      formData: renderVideoSelectors.formData(state),
      projectType: mixVideoSelectors.projectType(state),
      currentProject: apiSelectors.currentProject(state)
    })),
    mergeMap(param =>
      merge(
        of(param).pipe(
          filter(({ equity }) => !equity?.upsellState?.canGenerateClip),
          map(() => bannerActions.upsell.show(UPSELL_VIDEO_PARAM))
        ),
        of(param).pipe(
          filter(({ equity }) => Boolean(equity?.upsellState?.canGenerateClip)),
          tap(({ clipData, formData, clipId, currentProject, projectType }) => {
            const clipDatum = clipData[clipId]
            const { clipDuration } = Utils.getDerivedClipData(clipDatum)

            MixPanelUtils.track<'PROJECT__GENERATE_VIDEO'>('Project Video - Generate Video', {
              ...DataUtils.getProjectParam<'training_project'>('training_project', {
                trainProject: currentProject
              }),
              video_duration: clipDuration,
              keyframe_count: clipDatum?.keyframes?.length ?? 0,
              frame_count: Math.round((formData?.fps ?? 0) * clipDuration),
              clip_type: projectType ?? 'training_project',
              clip_id: clipId
            })
          }),
          mergeMap(({ clipId, formData }) =>
            merge(
              action$.pipe(
                filter(isActionOf(apiActions.engine.generateClipVideoResponse)),
                take(1),
                map(() => renderVideoActions.setGenerateStart({ clipId })),
                startWith(
                  apiActions.engine.generateClipVideo({
                    id: clipId,
                    resolution: formData.resolution,
                    mode: formData.mode,
                    smooth: formData.showSmooth
                      ? formData.smooth
                      : RENDER_VIDEO_FORM_OPTIONS.smooth.initial
                  })
                )
              )
            )
          )
        )
      )
    )
  )

const poolingGeneratingClipEpic: Epic<RootActionType, RootActionType, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(renderVideoActions.poolingGeneratingClip)),
    withLatestFrom(state$),
    map(([_, state]) => ({
      clipGenerating: selectClipsGenerating(state)
    })),
    mergeMap(({ clipGenerating }) =>
      _compact([..._map(clipGenerating, clipId => apiActions.engine.retrieveClip(clipId))])
    )
  )

const retrieveClipListener: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(apiActions.engine.retrieveClipResponse)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      clipId: action.payload.id,
      isGenerating: selectClipsGenerating(state).includes(action.payload.id),
      hasGeneratedOutput: Boolean(action.payload.outputs?.[0]?.video?.id)
    })),
    filter(({ isGenerating, hasGeneratedOutput }) => isGenerating && hasGeneratedOutput),
    map(({ clipId }) => renderVideoActions.setGenerateFinished({ clipId }))
  )
const downloadClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(renderVideoActions.downloadClip)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      currentProjectName: apiSelectors.currentProject(state)?.name,
      currentMixProjectName: apiSelectors.currentMixImageProject(state)?.name,
      clipVideo: action.payload.clip.outputs?.[action.payload.outputIndex],
      clipName: action.payload.clip.name,
      downloadLocation: action.payload.downloadLocation
    })),
    map(({ clipVideo, ...restParam }) => ({
      ...restParam,
      clipVideo: clipVideo?.video,
      clipExtension: clipVideo?.video?.ext
    })),
    filter(({ clipVideo }) => Boolean(clipVideo)),
    map(
      ({
        clipName,
        currentProjectName,
        currentMixProjectName,
        downloadLocation,
        clipVideo,
        clipExtension
      }) => {
        const projectName = currentProjectName || currentMixProjectName
        const projectIdentity = `CLIP_${clipName}`

        const fileName = `${projectIdentity}-${projectName}.${clipExtension}`
        return { clipVideo, fileName, downloadLocation }
      }
    ),
    map(({ clipVideo, downloadLocation, fileName }) =>
      downloaderActions.single.downloadSingleVideo({
        downloadLocation,
        video: clipVideo ?? USER_VIDEO_DUMMY,
        fileName
      })
    )
  )

const retrieveClipEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([renderVideoActions.setSelectedTab, renderVideoActions.retrieveClip])),
    withLatestFrom(state$),
    map(([action, state]) => ({
      clip: apiSelectors.clips(state)[action.payload.clipId ?? 0],
      clipId: action.payload.clipId
    })),
    map(({ clip, clipId }) => ({
      /*
        1. Have clip id
        2. But either not have clip data
        4. Output not yet fetched
      */
      shouldFetch: clipId && (!clip || (clip && clip?.outputs?.length)),
      clipId
    })),
    filter(({ shouldFetch, clipId }) => Boolean(shouldFetch) && Boolean(clipId)),
    map(({ clipId }) => apiActions.engine.retrieveClip(clipId ?? 0))
  )

const exportProjectEpic: Epic<RootActionType, RootActionType, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(renderVideoActions.exportClip)),
    withLatestFrom(state$),
    map(([action, state]) => ({
      clipId: action.payload.clipId,
      projectKey: mixVideoSelectors.projectKey(state)
    })),
    mergeMap(({ clipId }) => [
      mixVideoActions.setShowRenderVideo(true),
      renderVideoActions.setSelectedTab({ clipId })
    ])
  )

export const renderVideoEpics = combineEpics(
  retrieveAllClips,
  downloadClipEpic,
  poolingGeneratingClipEpic,
  retrieveClipListener,
  exportProjectEpic,
  generateClipEpic,
  retrieveClipEpic
)
export default reducer
