import { apiActions, PaymentActionType } from '../actions'
import { SharedActionsType, sharedActions } from '../sharedActions'
import {
  Payment,
  ProductsResponse,
  CreditOverview,
  CreditChangeLog,
  ListCreditChangeLogResponse,
  DownloadImage,
  PaginationResponse,
  Channel,
  BrainTreeTokenResponse,
  BraintreePaymentMethod,
  Subscription,
  RUNNING_SUBSCRIPTION_LIST
} from 'models/ApiModels'
import { createReducer } from 'typesafe-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'

export type PaymentState = {
  products?: ProductsResponse

  subscriptionList?: PaginationResponse<Subscription>
  currentSubscription?: Subscription

  channels?: PaginationResponse<Channel>
  paymentData?: { [id: number]: Payment }
  currentPayment?: number
  creditChangeLogList?: number[]
  lastCreditChangeLogRequest?: ListCreditChangeLogResponse
  creditChangeLogs: {
    [creditChangeLogId: string]: CreditChangeLog
  }
  brainTreeToken?: BrainTreeTokenResponse
  brainTreePaymentMethod?: BraintreePaymentMethod
  creditOverview?: CreditOverview
  downloadImage: {
    list: number[]
    data: { [id: number]: DownloadImage }
    lastReq?: PaginationResponse<DownloadImage>
  }
}
export const initialPaymentState: PaymentState = {
  products: undefined,
  channels: undefined,
  paymentData: undefined,
  currentPayment: 0,
  creditChangeLogList: undefined,
  lastCreditChangeLogRequest: undefined,
  creditChangeLogs: {},
  brainTreePaymentMethod: undefined,
  brainTreeToken: undefined,
  creditOverview: undefined,
  downloadImage: {
    list: [],
    data: {},
    lastReq: undefined
  }
}

export const paymentReducer = createReducer<PaymentState, PaymentActionType | SharedActionsType>(
  initialPaymentState
)
  .handleAction(apiActions.payment.createPaymentResponse, (state, action) => {
    return {
      ...state,
      currentPayment: action.payload.id,
      paymentData: {
        ...state.paymentData,
        [action.payload.id]: { ...action.payload }
      }
    }
  })
  .handleAction(apiActions.payment.retrievePaymentResponse, (state, action) => {
    const result = action?.payload?.result
    if (result) {
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          [result.id]: result
        }
      }
    }
    return state
  })
  .handleAction(apiActions.payment.retrieveProductsResponse, (state, action) => {
    return {
      ...state,
      products: {
        ...action.payload
      }
    }
  })
  .handleAction(apiActions.payment.retrieveChannelsResponse, (state, action) => {
    return {
      ...state,
      channels: { ...action.payload }
    }
  })
  .handleAction(apiActions.payment.listCreditChangeLogResponse, (state, action) => {
    const { data, reloadList } = action.payload

    const prevData = reloadList ? [] : state?.creditChangeLogList ?? []
    const results = data.results ?? []
    return {
      ...state,
      creditChangeLogList: [...prevData, ..._map(results, result => result.id)],
      lastCreditChangeLogRequest: data,
      creditChangeLogs: {
        ...state.creditChangeLogs,
        ..._keyBy(results, result => result.id)
      }
    }
  })
  .handleAction(apiActions.payment.retrieveCreditOverviewResponse, (state, action) => {
    return {
      ...state,
      creditOverview: action.payload
    }
  })
  .handleAction(
    [
      apiActions.payment.retrieveDownloadImageResponse,
      apiActions.payment.createDownloadImageResponse
    ],
    (state, action) => {
      return {
        ...state,
        downloadImage: {
          ...state.downloadImage,
          data: {
            ...state.downloadImage.data,
            [action.payload.data.id]: action.payload.data
          }
        }
      }
    }
  )
  .handleAction(apiActions.payment.braintreeGenerateTokenResponse, (state, action) => {
    return {
      ...state,
      brainTreeToken: action.payload
    }
  })
  .handleAction(
    [
      apiActions.payment.braintreeRetrievePaymentMethodResponse,
      apiActions.payment.braintreeCreatePaymentMethodResponse
    ],
    (state, action) => {
      return {
        ...state,
        brainTreePaymentMethod: action.payload
      }
    }
  )
  .handleAction(apiActions.payment.braintreeRemovePaymentMethodResponse, (state, action) => {
    return {
      ...state,
      brainTreePaymentMethod: undefined
    }
  })
  .handleAction(apiActions.payment.listSubscriptionResponse, (state, action) => {
    const result = action.payload.results
    const activeSubscriptions = result?.filter(value =>
      RUNNING_SUBSCRIPTION_LIST.includes(value.status)
    )

    return {
      ...state,
      currentSubscription: activeSubscriptions?.[0],
      subscriptionList: action.payload
    }
  })
  .handleAction(
    [apiActions.payment.createSubscriptionResponse, apiActions.payment.updateSubscriptionResponse],
    (state, action) => {
      return {
        ...state,
        currentSubscription: action.payload
      }
    }
  )
  .handleAction(apiActions.payment.listDownloadImageResponse, (state, action) => {
    const { data, reloadList } = action.payload

    const prevData = reloadList ? [] : state?.downloadImage.list ?? []
    const results = data.results ?? []
    return {
      ...state,
      downloadImage: {
        ...state.downloadImage,
        list: [...prevData, ..._map(results, result => result.id)],
        data: {
          ...state.downloadImage.data,
          ..._keyBy(results, result => result.id)
        },
        lastReq: data
      }
    }
  })
  .handleAction(sharedActions.reset, () => initialPaymentState)
