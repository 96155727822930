import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import Popper, { PopperPlacementType, PopperProps } from '@mui/material/Popper'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import MoreVert from '@mui/icons-material/MoreVert'
import Paper from '@mui/material/Paper'

import Button, { ButtonProps, ButtonColorProps } from 'components/Button'

import styles from './DropdownMenuStyle.module.scss'

export type DropdownItem<Key = number | string, OnClick = Function> = {
  key: Key
  hide?: boolean
  disabled?: boolean
  label?: string | React.ReactNode
  onClick?: OnClick
  variant?: string
  isDelayedAction?: boolean //Action executed after menu close, to fix bug share project
}

export type DropdownMenuProps = {
  buttonLabel?: string | React.ReactNode
  buttonLabelSize?: 'small'
  classes?: {
    button?: string
    menu?: string
    popper?: string
  }
  CustomButtonComponent?: React.FC<Pick<ButtonProps, 'disabled' | 'onClick'> & { buttonRef?: any }>
  items: DropdownItem[]
  className?: string
  placement?: PopperPlacementType
  disabled?: boolean
  disablePortal?: boolean
  color?: ButtonColorProps
  modifiers?: PopperProps['modifiers']
}
type DropdownMenuItemsProps = { items: DropdownItem[]; handleClose: (event: any) => void }

const DropdownMenuItems: React.FC<DropdownMenuItemsProps> = ({ items, handleClose }) => {
  return (
    <React.Fragment>
      {items.map((item, index) =>
        !item.hide ? (
          <MenuItem
            onClick={e => {
              item.onClick && !item.disabled && item.onClick(e)
              handleClose(e)
            }}
            className={clsx(
              styles.MenuItem,
              item.disabled && styles.Disabled,
              item.variant === 'error' && styles.MenuItemError
            )}
            key={item.key || index}
          >
            {item.label}
          </MenuItem>
        ) : null
      )}
    </React.Fragment>
  )
}

type DropdownButtonProps = Partial<
  Pick<DropdownMenuProps, 'className' | 'buttonLabel' | 'buttonLabelSize' | 'color'>
> & {
  setAnchorEl: any // ButtonProps['buttonRef']
  open: boolean
  onClick: ButtonProps['onClick']
  disabled: boolean
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  className,
  setAnchorEl,
  open,
  buttonLabel,
  buttonLabelSize,
  onClick,
  disabled,
  color = 'secondary'
}) => {
  if (buttonLabel) {
    return (
      <Button
        ref={setAnchorEl}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        className={clsx(styles.DropdownButton, className)}
        onClick={onClick}
        disabled={disabled}
        color={color}
        size={buttonLabelSize}
        endIcon={<ArrowDropDown className={styles.ButtonLabelArrow} />}
      >
        {buttonLabel}
      </Button>
    )
  }

  return (
    <Button
      ref={setAnchorEl}
      aria-owns={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      onClick={onClick}
      className={clsx(styles.ButtonIconContainer, className)}
      disableRipple
      disabled={disabled}
      color={color}
      size={buttonLabelSize}
      icon
    >
      <MoreVert />
    </Button>
  )
}

const DropdownMenu: React.FC<DropdownMenuProps> = (
  props = {
    items: [],
    placement: 'bottom-end'
  }
) => {
  const {
    buttonLabel,
    buttonLabelSize,
    disabled = false,
    CustomButtonComponent,
    items,
    classes,
    className,
    placement,
    disablePortal = false,
    color,
    modifiers
  } = props

  const [open, setOpen] = useState(false)

  // Technicall, anchorEl is a Node, but the typings for popperprops are off?
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl'] | null>(null)

  const handleToggle = useCallback(
    (e: any) => {
      setOpen(!open)
    },
    [open]
  )

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent) => {
      // @ts-ignore
      if (anchorEl && anchorEl.contains(event.target)) {
        return
      }

      // To fix positioning issue
      window.setTimeout(() => setOpen?.(false), 0)
    },
    [anchorEl, setOpen]
  )

  return (
    <div
      className={clsx(
        styles.DropdownContainer,
        disablePortal && styles.DropdownPortalLess,
        className
      )}
    >
      {CustomButtonComponent ? (
        <CustomButtonComponent buttonRef={setAnchorEl} onClick={handleToggle} disabled={disabled} />
      ) : (
        <DropdownButton
          className={classes?.button}
          setAnchorEl={setAnchorEl}
          buttonLabel={buttonLabel}
          buttonLabelSize={buttonLabelSize}
          open={open}
          onClick={handleToggle}
          disabled={disabled}
          color={color}
        />
      )}
      <Popper
        open={open}
        placement={placement}
        anchorEl={anchorEl}
        transition
        disablePortal={disablePortal}
        id="menu-list-grow"
        modifiers={modifiers}
        className={classes?.popper}
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes?.menu}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className={styles.DropdownMenu}>
                    <DropdownMenuItems items={items} handleClose={handleClose} />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        }}
      </Popper>
    </div>
  )
}

export default DropdownMenu
