import React, { useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'
import styles from './Banner.module.scss'
import { BannerAction, BannerState } from 'duck/AppDuck/BannerDuck'
import { AnyAction, Dispatch } from 'redux'
import { ActionTypeParam } from 'duck/AppDuck'
import { useDispatch } from 'react-redux'

export type BannerProps = {
  className?: string
  placeholderClassName?: string
  show?: boolean
  message?: string
  content?: React.ReactNode
  mode?: 'hidden' | 'float' | 'dialog' | 'cta' | 'static'
  onClose?: Function
  actions?: BannerState['generalBanner']['actions']
  disableAnimaton?: boolean
  hasActiveDialog?: boolean //Prevent showing when dialog open
  autoCloseDuration?: number // in milisecond
  mountOnEnter?: boolean
  unmountOnExit?: boolean
}

export const BannerUtils = {
  /* 
    - Execute action in banner. 
    - Action can be function, or redux action, 
    - If it's function, then call the function. 
    - If it's a redux action, then dispatch it.  
  */
  executeAction: (action: BannerAction['onClick'] | Function, dispatch: Dispatch<AnyAction>) => {
    if (typeof action === 'function') {
      action()
    }
    if ((action as ActionTypeParam).type) {
      dispatch(action as ActionTypeParam)
    }
  }
}

const Banner: React.FC<BannerProps> = props => {
  const {
    onClose,
    hasActiveDialog,
    className,
    message,
    actions,
    mode,
    content,
    placeholderClassName,
    autoCloseDuration = 0,
    disableAnimaton = false,
    mountOnEnter,
    unmountOnExit
  } = props
  const [show, setShow] = useState(false)
  const timeoutId = useRef(0)

  const shouldShow = !(mode !== 'dialog' && hasActiveDialog)
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldShow) {
      setShow(Boolean(props.show))
    } else {
      setShow(false)
    }
    if (props.show && autoCloseDuration && shouldShow) {
      timeoutId.current = window.setTimeout(() => {
        setShow(false)
        onClose && onClose()
      }, autoCloseDuration)
    }
    if (!props.show) {
      window.clearTimeout(timeoutId.current)
    }

    return () => {
      window.clearTimeout(timeoutId.current)
    }
  }, [autoCloseDuration, onClose, props.show, shouldShow])

  const showing = show && mode !== 'hidden'

  const hasActions = actions?.length

  return (
    <Collapse
      in={showing}
      timeout={disableAnimaton ? 0 : 250}
      mountOnEnter={mountOnEnter === false ? false : true}
      unmountOnExit={unmountOnExit === false ? false : true}
    >
      <div
        className={clsx(
          styles.BannerPlaceholder,
          placeholderClassName,
          mode === 'float' ? styles.Float : '',
          mode === 'dialog' ? styles.DialogBlend : '',
          mode === 'cta' ? styles.CTA : '',
          mode === 'static' ? styles.Static : ''
        )}
      >
        <div className={clsx(styles.Banner, className)}>
          <Collapse in={showing} timeout={disableAnimaton ? 0 : 'auto'}>
            <div className={clsx(styles.BannerInner, !hasActions && styles.FullSize)}>
              <div>
                {Boolean(content) ? content : <Typography variant="body2">{message}</Typography>}
              </div>
              {hasActions ? (
                <div className={styles.ActionButton}>
                  {actions?.map((value, index) => (
                    <Button
                      key={index}
                      color={value.color ?? 'secondary'}
                      variant={value.variant}
                      className={value.className}
                      size="small"
                      onClick={() => {
                        BannerUtils.executeAction(value.onClick, dispatch)
                      }}
                    >
                      {value.label}
                    </Button>
                  ))}
                </div>
              ) : null}
            </div>
          </Collapse>
        </div>
      </div>
    </Collapse>
  )
}

export default Banner
