import Konva from 'konva/lib/Core'
import { AnimationStateManagerType, gridUtils, ImageStateManagerType } from './utils'
import { Listeners, CanvasBuilderProps } from './CanvasBuilder'
import _throttle from 'lodash/throttle'
import { KonvaEventObject } from 'konva/lib/Node'
import { VectorUtils } from 'utils/math'

const INITIAL_START_POSITION = { clientX: 0, clientY: 0 }

export type GridCanvasProps = Pick<CanvasBuilderProps, 'layer' | 'stage'> & {
  listeners: Listeners
  imageState: ImageStateManagerType
  animationState: AnimationStateManagerType
}

const Base = (props: GridCanvasProps) => {
  const { listeners, layer, imageState, stage, animationState } = props
  Konva.hitOnDragEnabled = true
  let lastDist = 0

  const baseGroup = new Konva.Group({
    x: 0,
    y: 0
  })

  stage.draggable(true)
  stage.dragBoundFunc(function (pos) {
    return {
      x: stage.position().y,
      y: stage.position().y
    }
  })

  //Drag
  let startPosition = INITIAL_START_POSITION

  stage.on('dragstart', (e: any) => {
    const disablePreview = imageState.disablePreview()
    if (disablePreview) return

    const [clientX, clientY] = gridUtils.getClientXY(e.evt)
    startPosition = { clientX, clientY }
    animationState.resetCameraSpeed()
  })

  stage.on(
    'dragmove',
    _throttle((e: any) => {
      const disablePreview = imageState.disablePreview()
      if (disablePreview) return

      const [clientX, clientY] = gridUtils.getClientXY(e.evt)
      const deltaPos = {
        x: clientX - startPosition.clientX,
        y: clientY - startPosition.clientY
      }
      listeners.dragMoveListener?.(deltaPos)
      startPosition = { clientX, clientY }
    }, 30)
  )

  stage.on('click tap', (e: KonvaEventObject<MouseEvent>) => {
    const [clientX, clientY] = gridUtils.getClientXY(e.evt)
    listeners.clickTapListener?.({ x: clientX, y: clientY })
  })

  stage.on('mousedown touchstart tap', function () {
    animationState.resetCameraSpeed()
  })
  stage.on('dragend', function (e) {
    const disablePreview = imageState.disablePreview()
    if (disablePreview) return

    if (lastDist === 0) {
      animationState.triggerMomentum()
    }
  })
  stage.on(
    'touchmove',
    _throttle((e: any) => {
      const disablePreview = imageState.disablePreview()
      if (disablePreview) return

      e.evt.preventDefault()
      const touch1 = e.evt?.touches?.[0]
      const touch2 = e.evt?.touches?.[1]

      if (touch1 && touch2) {
        animationState.resetCameraSpeed()
        let dist = VectorUtils.getDistance(
          { x: touch1.screenX, y: touch1.screenY },
          { x: touch2.screenX, y: touch2.screenY }
        )

        if (lastDist) {
          const currentZoom = imageState.zoom()

          const newZoom = (currentZoom * dist) / lastDist
          const zoomCenter = {
            x: touch1.clientX || 0,
            y: touch1.clientY || 0
          }
          const newCameraPos = gridUtils.getCameraPosAfterZoom({
            zoomCenter,
            newZoom,
            oldZoom: currentZoom,
            camera: imageState.camera()
          })

          const minZoom = imageState.home()?.zoom ?? 1
          const adjustedZoom = newZoom < minZoom ? minZoom : newZoom
          const adjustedCameraPos = newZoom < minZoom ? imageState.camera() : newCameraPos

          listeners.zoomListener?.(adjustedZoom, adjustedCameraPos)
        }

        lastDist = dist
      }
    }, 30)
  )

  stage.on('touchend', function () {
    const disablePreview = imageState.disablePreview()
    if (disablePreview) return

    animationState.resetCameraSpeed()
    lastDist = 0
  })

  const scaleBy = 1.03
  stage.on('wheel', (e: any) => {
    e.evt.preventDefault()

    const disablePreview = imageState.disablePreview()
    if (disablePreview) return

    animationState.resetCameraSpeed()

    const currentZoom = imageState.zoom()
    const currentCamera = imageState.camera()

    const newZoom = e.evt.deltaY < 0 ? currentZoom * scaleBy : currentZoom / scaleBy

    const zoomCenter = {
      x: stage?.getPointerPosition()?.x || 0,
      y: stage?.getPointerPosition()?.y || 0
    }
    const newCameraPos = gridUtils.getCameraPosAfterZoom({
      zoomCenter,
      newZoom,
      oldZoom: currentZoom,
      camera: currentCamera
    })

    const minZoom = imageState.home()?.zoom ?? 1
    const adjustedZoom = newZoom < minZoom ? minZoom : newZoom
    const adjustedCameraPos = newZoom < minZoom ? currentCamera : newCameraPos

    listeners.zoomListener?.(adjustedZoom, adjustedCameraPos)
  })

  return {
    get: () => {
      return baseGroup
    },
    draw: (noDrawLayer?: boolean) => {
      !noDrawLayer && layer.batchDraw()
    }
  }
}

export default Base
